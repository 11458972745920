import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CardText, Col, Row, Button, Table } from "reactstrap";
import moment from "moment";
import axios from "axios";
import base_url from "./../../api/bootapi";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, setdashboardcheck, logout } from "./../features/userSlice";
import { removeAdmin } from "./../features/adminSlice";
import Modal from "react-modal";
import "./../css/Responsive.css";
import { Multiselect } from "multiselect-react-dropdown";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import AddIcon from "@mui/icons-material/Add";
import Select from "@mui/material/Select";
import Pagination from "@mui/material/Pagination";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { useStyles } from "../../Utilities/CSSUtilities";
import callsaveauditapi from "../../services/auditservice";
import sort from "./../images/sort.svg";
import { check1pointpermission } from "../Permission/permission";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    minWidth: "400px",
    maxHeight: "500px",
    transform: "translate(-50%, -50%)",
  },
};

function Ambasador(props) {
  const classes = useStyles();
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isw_user_role = localStorage.getItem("isw_user_role");
  const jwtToken = "Bearer " + user.isw_user_token;
  const ipaddress = localStorage.getItem("AdminIpAddress");
  const [deleteuser, setDeleteuser] = useState(null);
  const [pageno, setpageno] = useState(0);
  const [getpageno, setgetpageno] = useState();
  const [sortState, setsortState] = useState("addedOn");
  const [direction, setdirection] = useState("DESC");
  const [rows, setrows] = React.useState(50);
  const [display1, setdisplay] = useState("inline");
  const [backdropopen, setbackdropopen] = useState(false);
  const isw_user_name = localStorage.getItem("isw_user_name");
  const isw_adminId = localStorage.getItem("isw_adminId");
  const isw_user_email = localStorage.getItem("isw_user_email");

  const [datefilter, setdatefilter] = useState();
  const [totalUser, settotalUser] = useState();
  const [countnumber, setcountnumber] = useState();
  const [numberofElements, setnumberofElements] = useState();
  const [allAmbasador, setAllAmbasador] = useState();
  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        navigate("/");
      }
    });
    getPermissions();
  }, [rows]);

  useEffect(() => {
    let width = document.querySelector("table")?.offsetWidth;
    if (document.querySelector(".scroller")) {
      document.querySelector(".scroller").style.width = `${width}px`;
    }
  }, [allAmbasador]);

  let scrolling = false;
  const scrolldiv = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow .table-responsive");
    table.scrollTo(scroller.scrollLeft, 0);
  };

  const scrolltable = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow  .table-responsive");
    scroller.scrollTo(table.scrollLeft, 0);
  };

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);
    var available_resource = resource_obj.includes(30);
    if (check1pointpermission(1300, permission_obj, resource_obj)) {
      getAllAmbasador(pageno);
      dispatch(setdashboardcheck(30));
      getAllInstitute();
    } else {
      navigate("/errorPage");
    }
  };

  const createadminpage = () => {
    navigate("/create-ambassador");
  };

  const handlepage = (event, value) => {
    setpageno(value - 1);
    getAllAmbasador(value - 1);
  };

  const handleRows = (event) => {
    setrows(event.target.value);
  };

  const getAllAmbasador = (value1) => {
    setbackdropopen(true);
    axios
      .get(`${base_url.api2}/getUserByRoleIdWithPage`, {
        headers: {
          Authorization: jwtToken,
          roleId: 2,
          columnName: sortState,
          direction: direction,
          page: Number(value1),
          size: Number(rows),
        },
      })
      .then((response) => {
        settotalUser(response.data.data.totalElements);
        setcountnumber(response.data.data.number);
        setnumberofElements(response.data.data.numberOfElements);
        setgetpageno(response.data.data.totalPages);
        setAllAmbasador(response.data.data.content);
        setbackdropopen(false);
        setsearch(false);
        setdatefilter(false);
        try {
          document.getElementById("myForm").reset();
        } catch (error) {
          //console.log(error);
        }
      })
      .catch((error) => {
        setbackdropopen(false);
        handle403error(error);
      });
  };

  const [allProvider, setallProvider] = useState();
  const getAllInstitute = (value1) => {
    axios
      .get(`${base_url.api2}/findAllInstitution`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        //console.log(response.data.data);
        setallProvider(response.data.data);
      })
      .catch((error) => {
        handle403error(error);
      });
  };

  const sortClient = (e) => {
    setbackdropopen(true);
    setsortState(e.target.id);
    if (direction == "DESC") {
      setdirection("ASC");
    } else if (direction == "ASC") {
      setdirection("DESC");
    }
    axios
      .get(`${base_url.api2}/getUserByRoleIdWithPage`, {
        headers: {
          Authorization: jwtToken,
          roleId: 2,
          columnName: e.target.id,
          direction: direction,
          page: Number(pageno),
          size: Number(rows),
        },
      })
      .then((response) => {
        setgetpageno(response.data.data.totalPages);
        setAllAmbasador(response.data.data.content);
        setbackdropopen(false);
        setsearch(false);
        try {
          document.getElementById("myForm").reset();
        } catch (error) {
          //console.log(error);
        }
      })
      .catch((error) => {
        setbackdropopen(false);
        handle403error(error);
      });
  };

  const deleteCurrentUser = (elem) => {
    axios
      .delete(`${base_url.api2}/deleteUserByUsername`, {
        headers: {
          Authorization: jwtToken,
          username: deleteuser,
        },
      })
      .then((response) => {
        //console.log("Delete", deleteuser);
        //console.log(response.data.data.message);
        toast.success(response.data.data.message);
        setIsOpen(false);
        dispatch(
          removeAdmin({
            id: deleteuser,
          })
        );
      })
      .catch((error) => {
        toast.error(JSON.stringify(error.response.data.message));
        handle403error(error);
      });
  };

  var subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const openModal = (elem) => {
    //console.log(elem.target.id);
    setDeleteuser(elem.target.id);
    setIsOpen(true);
  };

  const [selecteduser, setselecteduser] = useState();
  const [selecteduser2, setselecteduser2] = useState();
  const [selectedUserProvider, setselectedUserProvider] = useState();
  const [officeState, setOffice] = useState();
  const [modalIsOpen2, setIsOpen2] = React.useState(false);
  const [manageProviderState, setmanageProviderState] = useState();
  const [manageProviderStateEmail, setmanageProviderStateEmail] = useState();
  const openModal2 = (elem, p) => {
    //console.log(elem.target.id);
    setmanageProviderState(p.firstName + " " + p.lastName);
    setmanageProviderStateEmail(p.email);
    // setmanageProviderState()
    axios
      .get(`${base_url.api2}/getUserByUsername`, {
        headers: {
          Authorization: jwtToken,
          username: p.username,
        },
      })
      .then((response) => {
        setselectedUserProvider(response.data.userInstitutionData);
        setOffice(response.data.userOfficeData);
      })
      .catch((error) => {
        handle403error(error);
      });
    setselecteduser2(p.username);
    setselecteduser(elem.target.id);
    setIsOpen2(true);
  };

  const onSelect = (selectedList, selectedItem) => {
    setselectProvider(false);
    const client_obj = [];
    for (let i = 0; i < selectedList.length; i++) {
      let data = {
        id: 0,
        institutionId: Number(selectedList[i].institutionId),
        institutionName: selectedList[i].institutionName,
        userId: Number(selecteduser),
      };
      client_obj.push(data);
    }
    setselectedUserProvider(client_obj);
  };

  const onRemove = (selectedList, removedItem) => {
    const client_obj = [];
    for (let i = 0; i < selectedList.length; i++) {
      let data = {
        id: 0,
        institutionId: Number(selectedList[i].institutionId),
        institutionName: selectedList[i].institutionName,
        userId: Number(selecteduser),
      };
      client_obj.push(data);
    }
    setselectedUserProvider(client_obj);
    //console.log(selectedList);
    //console.log(removedItem);
  };

  const [selectProvider, setselectProvider] = useState();
  const submitProvider = (e) => {
    if (selectedUserProvider.length != 0) {
      axios
        .post(
          `${base_url.api2}/assignInstitutionToUser`,
          selectedUserProvider,
          {
            headers: {
              Authorization: jwtToken,
              userId: Number(selecteduser),
            },
          }
        )
        .then((response) => {
          //console.log(response.data.data);
          toast.success(response.data.message);
          let logs =
            isw_user_name +
            " Updated Provider for Ambassador " +
            manageProviderState +
            " (" +
            manageProviderStateEmail +
            ").";
          callsaveauditapi(logs, dispatch, navigate);
          setIsOpen2(false);
          getAllAmbasador(pageno);
        })
        .catch((error) => {
          toast.error(JSON.stringify(error.response.data.message));
          handle403error(error);
        });
    } else {
      setselectProvider(true);
    }
  };

  const [modalIsOpen3, setIsOpen3] = React.useState(false);
  const openModal3 = (elem, p) => {
    if (p.status === 1) {
      //console.log("called inactive");
      let data = {
        email: elem.target.id,
        status: Number(0),
      };
      axios
        .post(`${base_url.api2}/updateUserDataByEmail`, data, {
          headers: {
            Authorization: jwtToken,
          },
        })
        .then((response) => {
          //console.log(response.data.data);
          toast.success(
            "Brand Ambasador " +
              p.firstName +
              " " +
              p.lastName +
              " Status Inactive"
          );
          // setIsOpen3(false);
          getAllAmbasador(pageno);
        })
        .catch((error) => {
          toast.error(JSON.stringify(error.response.data.message));
          handle403error(error);
        });
    } else {
      let data = {
        email: elem.target.id,
        status: Number(1),
      };
      axios
        .post(`${base_url.api2}/updateUserDataByEmail`, data, {
          headers: {
            Authorization: jwtToken,
          },
        })
        .then((response) => {
          //console.log(response.data.data);
          // toast.success(response.data.message);
          toast.success(
            "Brand Ambasador " +
              p.firstName +
              " " +
              p.lastName +
              " Status Active"
          );
          // setIsOpen3(false);
          getAllAmbasador(pageno);
        })
        .catch((error) => {
          toast.error(JSON.stringify(error.response.data.message));
          handle403error(error);
        });
    }
    setselecteduser(elem.target.id);
    // setIsOpen3(true);
  };

  const [searchdata, setsearch] = useState();
  const [searchinput, setsearchinput] = useState();
  const handlesearch = (e, value) => {
    setbackdropopen(true);
    // setdisplay("display");
    setpageno(value - 1);
    if (datefilter) {
      axios
        .post(`${base_url.api2}/searchUserByRoleIdAndDate`, datesrange, {
          headers: {
            Authorization: jwtToken,
            roleId: 2,
            columnName: sortState,
            direction: direction,
            page: Number(value - 1),
            size: Number(rows),
          },
        })
        .then((response) => {
          //console.log(response.data.data);
          var client_admin = response.data.data.content;
          setAllAmbasador(response.data.data.content);
          setgetpageno(response.data.data.totalPages);
          setdatefilter(true);
          setbackdropopen(false);
        })
        .catch((error) => {
          setbackdropopen(false);
          handle403error(error);
        });
    } else {
      axios
        .get(`${base_url.api2}/searchUser`, {
          headers: {
            Authorization: jwtToken,
            searchQuery: searchinput,
            columnName: sortState,
            direction: direction,
            page: Number(value - 1),
            size: Number(rows),
          },
        })
        .then((response) => {
          //console.log(response.data.data);
          var client_admin = response.data.data.content;
          setAllAmbasador(response.data.data.content);
          setbackdropopen(false);
        })
        .catch((error) => {
          setbackdropopen(false);
          handle403error(error);
        });
    }
  };
  const [client_admin_users, setClientDetails] = useState(null);
  const submit = (e) => {
    setbackdropopen(true);
    setdisplay("none");
    let srchdata = e.target[0].value;
    setsearchinput(srchdata);
    setpageno(0);
    axios
      .get(`${base_url.api2}/searchUser`, {
        headers: {
          Authorization: jwtToken,
          searchQuery: srchdata,
          columnName: sortState,
          direction: direction,
          page: Number(0),
          size: Number(rows),
        },
      })
      .then((response) => {
        //console.log(response.data.data);
        var client_admin = response.data.data.content;
        setAllAmbasador(response.data.data.content);
        // dispatch(getAdmin(client_admin));
        settotalUser(response.data.data.totalElements);
        setcountnumber(response.data.data.number);
        setnumberofElements(response.data.data.numberOfElements);
        setgetpageno(response.data.data.totalPages);
        setsearch(true);
        setbackdropopen(false);
      })
      .catch((error) => {
        // toast.error(JSON.stringify(error.response.data.message));
        setbackdropopen(false);
        handle403error(error);
      });
  };

  const afterOpenModal = () => {
    subtitle.style.color = "#f00";
  };

  const closeModal = () => {
    setIsOpen(false);
    setIsOpen2(false);
    setIsOpen3(false);
  };

  const submitrows = (e) => {
    setbackdropopen(true);
    setpageno(0);
    if (searchinput != null) {
      axios
        .get(`${base_url.api2}/searchUser`, {
          headers: {
            Authorization: jwtToken,
            searchQuery: searchinput,
            columnName: sortState,
            direction: direction,
            page: Number(0),
            size: Number(rows),
          },
        })
        .then((response) => {
          //console.log(response.data.data);
          var client_admin = response.data.data.content;
          setAllAmbasador(response.data.data.content);
          setgetpageno(response.data.data.totalPages);
          // dispatch(getAdmin(client_admin));
          setbackdropopen(false);
        })
        .catch((error) => {
          setbackdropopen(false);
          handle403error(error);
        });
    } else {
      getAllAmbasador(0);
    }
  };

  const submitstatus = (e) => {
    let email = e.target[0].value;
    let status = Number(e.target[1].value);
    let office = [];
    let data = {
      email,
      status,
      office,
    };
    //console.log(data);
    axios
      .post(`${base_url.api2}/updateUserByEmail`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        //console.log(response.data.data);
        toast.success(response.data.message);
        setIsOpen3(false);
        getAllAmbasador(pageno);
      })
      .catch((error) => {
        handle403error(error);
        toast.error(JSON.stringify(error.response.data.message));
      });
  };

  const handleEvent = (event, picker) => {
    //console.log(picker.startDate);
  };

  const handleCallback = (start, end, label) => {
    //console.log(start, end, label);
  };

  const [datesrange, setdatesrange] = useState();
  const [datevalue, setdatevalue] = useState("Click to open");
  const handleApply = (event, picker) => {
    setbackdropopen(true);
    setdatevalue(
      `${picker.startDate.format("DD-MM-YYYY")} to ${picker.endDate.format(
        "DD-MM-YYYY"
      )}`
    );
    setpageno(0);
    let data = {
      addedOnFromDate: picker.startDate.format("YYYY-MM-DDTHH:MM:ss"),
      addedOnToDate: picker.endDate.format("YYYY-MM-DDTHH:MM:ss"),
    };
    setdatesrange(data);
    axios
      .post(`${base_url.api2}/searchUserByRoleIdAndDate`, data, {
        headers: {
          Authorization: jwtToken,
          roleId: 2,
          columnName: sortState,
          direction: direction,
          page: Number(0),
          size: Number(rows),
        },
      })
      .then((response) => {
        //console.log(response.data.data);
        var client_admin = response.data.data.content;
        setAllAmbasador(response.data.data.content);
        setgetpageno(response.data.data.totalPages);
        settotalUser(response.data.data.totalElements);
        setcountnumber(response.data.data.number);
        setnumberofElements(response.data.data.numberOfElements);
        setpageno(0);
        setdatefilter(true);
        setbackdropopen(false);
        // dispatch(getAdmin(client_admin));
      })
      .catch((error) => {
        setbackdropopen(false);
        handle403error(error);
      });
  };
  const editambassador = (elem) => {
    var provider_id = elem.target.id;
    navigate("/edit-ambassador/" + provider_id);
  };

  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            navigate("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };
  return (
    <div>
      <ToastContainer />
      <Backdrop className={classes.backdrop} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* <div className="commoncomponent_cardcss"> */}
      <div
        class="page-head d-flex justify-content-between p-4"
        style={{ marginTop: "4.3rem", marginBottom: "-20px" }}
      >
        <ul class="breadcrums">
          <h3 class="page-title bold">Ambassador</h3>
          <Link to="/" class="b-link">
            Home
          </Link>
          <span className="s_span ">&raquo;</span>
          <a class="b-link">Other</a>
          <span className="s_span ">&raquo;</span>
          <Link to="/ambassador" class="b-link active">
            Ambasador
          </Link>
        </ul>
        {isw_user_role == 1 ? (
          <a
            className="sent-button"
            onClick={createadminpage}
            style={{ float: "right" }}
          >
            Add
          </a>
        ) : null}
      </div>
      <div>
        <Row>
          {/* <Col md={6}>
              <CardText
                className={classes.headtext}
                style={{ marginTop: "-10px" }}
              >
                <strong>Manage Ambassador</strong>
              </CardText>
            </Col> */}
          {/* <Col md={6} className="d-flex justify-content-end">
              {isw_user_role == 1 ? (
                <button
                  className="btn listing_addbutton"
                  onClick={createadminpage}
                  style={{float:"right"}}
                >
                  <AddIcon className="listing_addbutton_i" /> Add
                </button>
              ) : null}
            </Col> */}
        </Row>
        <div
          className="newlisting_headertabdesign"
          style={{
            padding: "1.5rem",
            borderRight: "1.5rem solid #e9ebf2",
            borderLeft: "1.5rem solid #e9ebf2",
            borderTop: "1.5rem solid #e9ebf2",
            marginBottom: "0",
          }}
        >
          <Row style={{ marginTop: "10px", marginBottom: "-15px" }}>
            <Col md={2}>
              <div>
                <form
                  className="master"
                  onSubmit={(e) => {
                    e.preventDefault();
                    submitrows(e);
                  }}
                >
                  <label className="area_rowslable">Limit:</label>
                  <br />
                  <select
                    className="form-control"
                    name="branch"
                    value={rows}
                    onChange={handleRows}
                    style={{ backgroundColor: "#f5f5f9", width: "100%" }}
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </form>
              </div>
            </Col>
            <Col md={2}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  marginTop: "-8px",
                }}
              >
                <label
                  className="area_rowslable_my"
                  style={{ fontWeight: "bold" }}
                >
                  DateDate:
                </label>
                <div
                  className="clearsearchclass"
                  style={{ width: "100%", marginTop: "0px" }}
                >
                  <form
                    id="myForm"
                    className="d-flex"
                    style={{ position: "relative" }}
                  >
                    <DateRangePicker
                      onEvent={handleEvent}
                      onCallback={handleCallback}
                      onApply={handleApply}
                    >
                      <p
                        className="datepickerrangevaluetextone"
                        style={{
                          backgroundColor: "#f5f5f9",
                          fontSize: "14px",
                          color: "#6d7290",
                          padding: "10px",
                          width: "100%",
                        }}
                      >
                        {datevalue}
                      </p>
                    </DateRangePicker>
                    {/* {datefilter ? (
                    <div style={{ float: "left" }} className="s_datefilter">
                      <a
                        className={classes.clrsrch}
                        onClick={() => {
                          setdatefilter(false);
                          setdisplay("inline");
                          setdatevalue("Click to open");
                          setdirection("DESC");
                          setpageno(0);
                          getAllAmbasador(0);
                        }}
                      >
                        Clear Search
                      </a>
                    </div>
                  ) : null} */}
                    <span
                      className="listpagesearch_button"
                      style={{
                        top: "7px",
                        position: "absolute",
                        right: "5px",
                        pointerEvents: "none",
                      }}
                    >
                      <i className="fa fa-calendar"></i>
                    </span>
                  </form>
                </div>
              </div>
            </Col>
            <Col md={5}>{null}</Col>
            <Col md={2}>
              <div
                className="clearsearchclass"
                style={{ marginTop: "20px", marginRight: "-90px" }}
              >
                <form
                  id="myForm"
                  className="d-flex"
                  onSubmit={(e) => {
                    e.preventDefault();
                    submit(e);
                  }}
                >
                  <input
                    className="form-control"
                    placeholder="Search.."
                    name="search"
                  />
                  <button className="btn listpagesearch_button" type="submit">
                    <i className="fa fa-search"></i>
                  </button>
                </form>
              </div>
              {searchdata ? (
                <a
                  className={classes.clrsrch2}
                  onClick={() => {
                    setdisplay("inline");
                    setdirection("DESC");
                    setsearch(false);
                    setpageno(0);
                    getAllAmbasador(0);
                  }}
                >
                  Clear Search
                </a>
              ) : null}
            </Col>
          </Row>
        </div>
        <div
          className="newlisting_headertabdesign"
          style={{
            padding: "1.5rem",
            border: "1.5rem solid #e9ebf2",
            marginTop: "0",
            marginBottom: "0",
          }}
        >
          <Row className="s_pagi">
            <Col md={6}>
              {allAmbasador?.length ? (
                <p className="s_para">
                  {countnumber * rows + 1} to{" "}
                  {countnumber * rows + numberofElements} of {totalUser} records
                </p>
              ) : (
                <p className="s_para">No Record Found</p>
              )}
            </Col>
            <Col md={6}>
              {/* {isw_user_role == 1 ? (
                <a
                  className="btn btn-success"
                  onClick={createadminpage}
                  style={{ float: "right" }}
                >
                  Add
                </a>
              ) : null} */}
            </Col>
          </Row>
          <div className="above-scroller" onScroll={scrolldiv}>
            <div className="scroller"></div>
          </div>
          <div className="displaytablerow">
            {/* <Table responsive striped onScroll={scrolltable}> */}
            <Table
              className="workload card-table table-borderless"
              responsive
              striped
              onScroll={scrolltable}
            >
              <thead>
                <tr>
                  <th style={{ minWidth: "100px" }}>IMAGE</th>
                  <th style={{ minWidth: "180px" }}>
                    NAME{" "}
                    <img
                      src={sort}
                      className="sorting"
                      // className={display}
                      id="firstName"
                      onClick={sortClient}
                      style={{
                        cursor: "pointer",
                        display: `${display1}`,
                        height: "10px",
                      }}
                    />
                  </th>
                  <th style={{ minWidth: "140px" }}>
                    CONTACT{" "}
                    <img
                      src={sort}
                      className="sorting"
                      id="email"
                      // className={display}
                      onClick={sortClient}
                      style={{
                        cursor: "pointer",
                        display: `${display1}`,
                        height: "10px",
                      }}
                    />
                  </th>
                  <th style={{ minWidth: "200px" }}>
                    ADDRESS{" "}
                    <img
                      src={sort}
                      className="sorting"
                      id="state"
                      // className={display}

                      onClick={sortClient}
                      style={{
                        cursor: "pointer",
                        display: `${display1}`,
                        height: "10px",
                      }}
                    />
                  </th>
                  <th style={{ minWidth: "150px" }}>
                    CREATED{" "}
                    <img
                      src={sort}
                      className="sorting"
                      id="addedOn"
                      // className={display}

                      onClick={sortClient}
                      style={{
                        cursor: "pointer",
                        display: `${display1}`,
                        height: "10px",
                      }}
                    />
                  </th>
                  <th style={{ minWidth: "150px" }}>
                    LAST LOGIN{" "}
                    <img
                      src={sort}
                      className="sorting"
                      id="lastLogin"
                      // className={display}
                      onClick={sortClient}
                      style={{
                        cursor: "pointer",
                        display: `${display1}`,
                        height: "10px",
                      }}
                    />
                  </th>
                  <th style={{ minWidth: "110px" }}>
                    STATUS{" "}
                    <img
                      src={sort}
                      className="sorting"
                      id="status"
                      onClick={sortClient}
                      style={{
                        cursor: "pointer",
                        display: `${display1}`,
                        height: "10px",
                      }}
                    />
                  </th>
                  <th style={{ minWidth: "140px" }}>ACTION</th>
                </tr>
              </thead>
              <tbody>
                {allAmbasador != null ? (
                  allAmbasador &&
                  allAmbasador.map((p) => (
                    <tr key={p.id}>
                      <td>
                        <img src={p.photo} alt="" style={{ height: "50px" }} />
                      </td>
                      <td>
                        {p.firstName} {p.lastName}
                      </td>
                      <td>
                        {p.email}
                        <br />
                        {p.phone == 0 ? " " : p.phone}
                      </td>
                      <td>
                        {p.state}
                        <br />
                        {p.country}
                      </td>
                      <td>
                        {p.addedOn
                          ? moment(p.addedOn.split(".")[0]).format("DD-MMM-YY")
                          : null}
                      </td>
                      <td>
                        {p.lastLogin
                          ? moment(p.lastLogin.split(".")[0]).format(
                              "DD-MMM-YY hh.mm a"
                            )
                          : null}
                      </td>
                      <td>
                        <Link
                          id={p.username}
                          onClick={(e) => {
                            openModal3(e, p);
                          }}
                        >
                          {p.status == "1" ? "Active" : "Inactive"}
                        </Link>
                        <Modal
                          isOpen={modalIsOpen3}
                          // onAfterOpen={afterOpenModal}
                          onRequestClose={closeModal}
                          style={customStyles}
                          contentLabel="Example Modal"
                        >
                          <form
                            onSubmit={(e) => {
                              e.preventDefault();
                              submitstatus(e);
                            }}
                          >
                            <div className="form-inner-admin">
                              <h3 style={{ color: "black" }}>Update Status</h3>
                              <div className="form-group creatadmin">
                                <Row>
                                  <Col>
                                    <label>User Name:</label>
                                    <input
                                      type="text"
                                      className="form-control underline-input"
                                      value={selecteduser}
                                      readOnly
                                      required
                                    />
                                  </Col>
                                </Row>
                              </div>
                              <div className="form-group creatadmin">
                                <Row>
                                  <Col>
                                    <label>Status:</label>
                                    <select
                                      defaultValue={
                                        p.status == 1 ? "Active" : "InActive"
                                      }
                                      className={classes.selectdrop}
                                      name="status"
                                      required
                                    >
                                      <option value="1">Active</option>
                                      <option value="0">InActive</option>
                                    </select>
                                  </Col>
                                </Row>
                              </div>
                              <div className="form-group creatadmin">
                                <Row>
                                  <Col md={6}>
                                    <button
                                      type="submit"
                                      className="btn listing_addbutton"
                                    >
                                      Update
                                    </button>
                                  </Col>
                                  <Col md={6}>
                                    <Button
                                      color="secondary"
                                      onClick={closeModal}
                                    >
                                      Cancel
                                    </Button>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </form>
                        </Modal>
                      </td>
                      <td id={p.id}>
                        <Link
                          id={p.id}
                          onClick={(e) => {
                            e.preventDefault();
                            openModal2(e, p);
                          }}
                        >
                          Manage Provider
                        </Link>
                        {/* &nbsp; | &nbsp;
                          <Link id={p.email} onClick={editambassador}>
                            Edit
                          </Link> */}
                        <Modal
                          isOpen={modalIsOpen2}
                          // onAfterOpen={afterOpenModal}
                          onRequestClose={closeModal}
                          style={customStyles}
                          contentLabel="Example Modal"
                        >
                          <form
                            onSubmit={(e) => {
                              e.preventDefault();
                              submitProvider(e);
                            }}
                          >
                            <div className="form-inner-admin">
                              <h3 style={{ color: "black" }}>
                                Update Provider
                              </h3>
                              <div className="form-group creatadmin">
                                <Row>
                                  <Col>
                                    <label>User Name:</label>
                                    <input
                                      type="text"
                                      className="form-control underline-input"
                                      value={selecteduser2}
                                      readOnly
                                      required
                                    />
                                  </Col>
                                </Row>
                              </div>
                              <div className="form-group creatadmin">
                                <Row>
                                  <Col>
                                    <label>Select Provider:</label>
                                    {selectedUserProvider != null ? (
                                      <Multiselect
                                        options={allProvider}
                                        selectedValues={selectedUserProvider} // Preselected value to persist in dropdown
                                        onSelect={onSelect}
                                        onRemove={onRemove}
                                        displayValue="institutionName"
                                        required
                                      />
                                    ) : (
                                      <Multiselect
                                        options={allProvider}
                                        // selectedValues={selectedUserProvider} // Preselected value to persist in dropdown
                                        onSelect={onSelect}
                                        onRemove={onRemove}
                                        displayValue="institutionName"
                                        required
                                      />
                                    )}
                                  </Col>
                                </Row>
                                <br />
                                {selectProvider ? (
                                  <p style={{ color: "red", fontSize: "10px" }}>
                                    Select Atleast One Provider
                                  </p>
                                ) : null}
                              </div>
                              <div className="form-group creatadmin">
                                <Row className="justify-content-end">
                                  <div>
                                    <button
                                      type="submit"
                                      className="btn listing_addbutton mr-1"
                                    >
                                      Update
                                    </button>
                                  </div>
                                  <div>
                                    <Button
                                      color="secondary"
                                      onClick={closeModal}
                                    >
                                      Cancel
                                    </Button>
                                  </div>
                                </Row>
                              </div>
                            </div>
                          </form>
                        </Modal>
                      </td>
                    </tr>
                  ))
                ) : (
                  <p className={classes.headertekst}>{null}</p>
                )}
              </tbody>
            </Table>
          </div>
          <Row className="s_pagirow">
            <Col md={6}>
              <div>
                <form>
                  <div style={{ float: "left", clear: "both" }}>
                    <FormControl
                      variant="standard"
                      style={{
                        display: "inline-block",
                        marginRight: "25px",
                        minWidth: "50px",
                      }}
                    >
                      <select
                        className={classes.selectlimit}
                        labelId="simple-select-label"
                        id="simple-select"
                        value={rows}
                        onChange={handleRows}
                      >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                    </FormControl>
                    {allAmbasador?.length ? (
                      <p
                        className="s_spanPage"
                        style={{ display: "inline-block" }}
                      >
                        {countnumber * rows + 1} to{" "}
                        {countnumber * rows + numberofElements} of {totalUser}{" "}
                        records
                      </p>
                    ) : (
                      <p
                        className="s_spanPage"
                        style={{ display: "inline-block" }}
                      >
                        No Record Found
                      </p>
                    )}
                  </div>
                </form>
              </div>
            </Col>
            <Col md={6}>
              <div className={classes.root} style={{ float: "right" }}>
                {searchdata || datefilter ? (
                  <Pagination
                    count={getpageno}
                    onChange={handlesearch}
                    shape="rounded"
                  />
                ) : (
                  <Pagination
                    count={getpageno}
                    onChange={handlepage}
                    shape="rounded"
                  />
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
}

export default Ambasador;
