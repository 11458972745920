import React, { useState } from "react";
import Modal from "react-modal";
import { Button } from "reactstrap";
import { customStyles } from "../../../Utilities/CSSUtilities";
import base_url from "../../../api/bootapi";
import axios from "axios";
import callsaveauditapi from "../../../services/auditservice";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getcollege,
  removecollege,
  selectcollege,
} from "./../../features/collegeSlice";

const ProviderArchiveModal = ({
  username,
  status,
  jwtToken,
  handle403error,
  allclientdetails,
  statusstate,
  pageno,
  p,
  institutionId,
  header,
}) => {
  const [changestatusmodal, setchangestatusmodal] = useState(false);
  const openmodal = () => setchangestatusmodal(true);
  const isw_user_name = localStorage.getItem("isw_user_name");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [deleteCollege, setDeleteCollege] = useState(null);
  const [deleteProvidername, setdeleteProviderName] = useState();
  const [modal, setModal] = useState(false);
  const [deleteProviderStatus, setdeleteProviderStatus] = useState();
  const toggle = (elem, p) => {
    //console.log(elem.target.id);
    setDeleteCollege(elem.target.id);
    if (p) {
      setdeleteProviderName(p.name);
      if (p.status == 1) {
        setdeleteProviderStatus(0);
      } else {
        setdeleteProviderStatus(1);
      }
    }
    setModal(!modal);
  };

  const ArchiveCurrentCollege = (elem) => {
    let status1;
    if (status === 0) {
      status1 = 1;
    } else {
      status1 = 0;
    }
    let data = {
      institutionId: institutionId,
      status: status1,
    };
    axios
      .put(`${base_url.api2}/updateInstitutionByIntitutionId`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        let logs =
          isw_user_name +
          " Updated Status of Provider " +
          deleteProvidername +
          ".";
        callsaveauditapi(logs, dispatch, navigate);
        dispatch(
          removecollege({
            id: deleteCollege,
          })
        );
        setchangestatusmodal(false);
        setModal(!modal);
      })
      .catch((error) => {
        toast.error(JSON.stringify(error.response.data.message));
        handle403error(error);
        setchangestatusmodal(false);
      });
  };
  return (
    <>
      <span
        style={{ cursor: "pointer" }}
        id={username}
        onClick={() => openmodal(username)}
      >
        {header}
      </span>
      <Modal
        isOpen={changestatusmodal}
        onRequestClose={() => setchangestatusmodal(false)}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <h4 style={{ marginTop: "10px" }}>
          Are you sure you want to {status === 1 ? "Archive" : "Unarchive"}{" "}
          current record ?{" "}
        </h4>
        <div
          style={{
            display: "flex",
            marginTop: "20px",
            justifyContent: "flex-end",
            gap: "10px",
            marginBottom: "10px",
          }}
        >
          <button
            className="btn listing_addbutton ml-1"
            onClick={ArchiveCurrentCollege}
          >
            Yes
          </button>
          <button
            className="btn btn-secondary cancel_margin"
            onClick={() => setchangestatusmodal(false)}
          >
            No
          </button>
        </div>
      </Modal>
    </>
  );
};

export default ProviderArchiveModal;
