import React, { useState, useEffect } from "react";
// import { CKEditor } from '@ckeditor/ckeditor5-react';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { useNavigate } from "react-router-dom";
import { Card, CardText, Col, Row, Button } from "reactstrap";
import axios from "axios";
import base_url from "./../../../api/bootapi";
import { toast } from "react-toastify";
import "./../../css/LoginForm.css";
import { useDispatch, useSelector } from "react-redux";
import "./../../css/Responsive.css";
import {
  setdashboardcheck,
  logout,
  selectUser,
} from "./../../features/userSlice";
import {
  useStyles,
  editorConfiguration,
} from "../../../Utilities/CSSUtilities";
import callsaveauditapi from "../../../services/auditservice";
import {
  check4pointpermission,
  checksettingpermission,
} from "../../Permission/permission";

function CreateProforma(props) {
  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [descriptionState, setdescriptionState] = useState(null);
  const [checkname, setcheckname] = useState(true);
  const [checktemplate, setchecktemplate] = useState(true);
  const user = useSelector(selectUser);
  const ipaddress = localStorage.getItem("AdminIpAddress");
  const isw_user_name = localStorage.getItem("isw_user_name");
  const isw_adminId = localStorage.getItem("isw_adminId");
  const isw_user_email = localStorage.getItem("isw_user_email");
  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        navigate("/");
      }
    });
    getPermissions();
  }, []);

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user?.isw_role_permissions.length; i++) {
      resource_obj.push(user?.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user?.isw_user_permissions.length; i++) {
      permission_obj.push(user?.isw_user_permissions[i].permissionId);
    }
    var available_resource = resource_obj.includes(28);
    var available_permission = permission_obj.includes(341);

    // if (check4pointpermission(1558, 1563, permission_obj, resource_obj)) {
    //     dispatch(setdashboardcheck(28));
    // } else {
    //     //console.log("No");
    //     navigate("/errorPage");
    // }
  };

  const submit = (e) => {
    const isoDateString = new Date().toISOString();
    const datenow = isoDateString.split(".")[0];
    let addedOn = datenow;
    let id = 0;
    let name = e.target[0].value;
    let status = e.target[1].value;
    let template = descriptionState;
    let data = {
      addedOn,
      id,
      name,
      status,
      template,
    };
    //console.log(data);
    if (!name || !template) {
      if (!name) {
        setcheckname(false);
        setchecktemplate(true);
      } else if (!template) {
        setcheckname(true);
        setchecktemplate(false);
      }
    } else {
      postTemplate(data);
    }
  };

  const postTemplate = (data) => {
    axios
      .post(`${base_url.api2}/addProforma`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        if (response.data.status_code == 200) {
          toast.success("Success");

          let log =
            isw_user_name +
            " Created Proforma " +
            response.data.data.name +
            ".";
          callsaveauditapi(log, dispatch, navigate);
          navigate("/proforma");
        } else {
          toast.error(response.message);
        }
      })
      .catch((err) => {
        handle403error(err);
      });
  };

  const goback = () => {
    navigate("/proforma");
  };
  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            navigate("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };

  return (
    <div>
      <Card className="commoncomponent_cardcss">
        <div>
          <Row>
            <Col md={6} style={{ float: "left" }}>
              <CardText className={classes.headtext} style={{ float: "left" }}>
                {/* <CardText> */}
                <strong>Proforma</strong>
              </CardText>
            </Col>
            <Col md={6} className="d-flex justify-content-end">
              <button className="btn listing_addbutton" onClick={goback}>
                &#x2630; List
              </button>
            </Col>
          </Row>
          <form
            id="myForm"
            onSubmit={(e) => {
              e.preventDefault();
              submit(e);
            }}
          >
            <div className="form-inner-admin">
              <div className="form-group creatadmin">
                <Row>
                  <Col md={6}>
                    <label className="area_rowslable_my">
                      Name<span style={{ color: "red" }}>*</span>:
                    </label>
                    <input
                      type="text"
                      className="form-control underline-input"
                    />
                    {!checkname && (
                      <p className="errormessage">Name is required</p>
                    )}
                  </Col>
                  <Col md={6}>
                    <label className="area_rowslable_my">
                      Status<span style={{ color: "red" }}>*</span>:
                    </label>
                    <select className={classes.selectdrop} name="status">
                      {/* <select name="status"> */}
                      <option value="1">Active</option>
                      <option value="0">Inactive</option>
                    </select>
                  </Col>
                </Row>
              </div>
              <div className="form-group creatadmin">
                <label className="area_rowslable_my">
                  Template<span style={{ color: "red" }}>*</span>:
                </label>
                <br />
                <br />
                <CKEditor
                  editor={ClassicEditor}
                  config={editorConfiguration}
                  data="<p></p>"
                  onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                    //console.log("Editor is ready to use!", editor);
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setdescriptionState(data);
                    //console.log(event);
                  }}
                  onBlur={(event, editor) => {
                    //console.log("Blur.", editor);
                  }}
                  onFocus={(event, editor) => {
                    //console.log("Focus.", editor);
                  }}
                />
                {!checktemplate && (
                  <p className="errormessage">Template is required</p>
                )}
              </div>
              <Row>
                <Col md={6}>{null}</Col>
                <Col md={6} className="d-flex justify-content-end">
                  <Button
                    color="secondary"
                    onClick={() => {
                      try {
                        document.getElementById("myForm").reset();
                      } catch (error) {
                        //console.log(error);
                      }
                    }}
                  >
                    Reset
                  </Button>
                  <button type="submit" className="btn listing_addbutton ml-1">
                    Submit
                  </button>
                </Col>
              </Row>
            </div>
          </form>
        </div>
      </Card>
    </div>
  );
}

export default CreateProforma;
