import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useNavigate, Link } from "react-router-dom";
import { getAdmin, selectAdmin } from "./components/features/adminSlice";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import base_url from "./api/bootapi";
import {
  login,
  logout,
  selectUser,
  setdashboardcheck,
  setOfficeId,
} from "./components/features/userSlice";
import axios from "axios";
import { getClient } from "./components/features/clientSlice";
import logosvg from "./components/images/login-illus.svg";
import moment from "moment";
import nepcomlogo from "./components/images/nepcoms-logo 1.png";

function LogIn(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const admin2 = useSelector(selectAdmin);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [inputtype, setinputtype] = useState("password");

  const [loginData, setLoginData] = useState({ username: "", password: "" });
  const [permissionData, setPermissionData] = useState(null);
  const [loginresult, setLoginresult] = useState(null);
  const [statusstate, setstatusstate] = useState(1);
  const [defaultBranch, setDefaultBranch] = useState();

  useEffect(() => {
    setTimeout(() => {
      if (localStorage.getItem("from_403_status") === "call_toast") {
        toast.warn("Session Expired");
      }
      localStorage.removeItem("from_403_status");
    }, 1000);
  }, []);
  function changeLogInData(e) {
    setLoginData({ ...loginData, [e.target.name]: e.target.value });
  }

  const handleSuccessfulLogin = (result, datenow, name) => {
    setPermissionData(result.roles_resources);
    //const name = result.data.firstName + " " + result.data.lastName;
    //console.log('User name',name)
    localStorage.setItem("isw_user_role_name", result.data.roleName);
    localStorage.setItem("isw_user_role_code", result.data.roleCode);
    localStorage.setItem("isw_user_token", result.jwtToken);
    localStorage.setItem("isw_user_email", result.data.email);
    localStorage.setItem("isw_adminId", result.data.id);
    localStorage.setItem("isw_user_name", name);
    localStorage.setItem("isw_first_name", result.data.firstName);
    localStorage.setItem("isw_user_role", result.data.roleId);

    // const permission_obj = [];
    // if (result.userPermissionData.length === 0) {
    //   for (let i = 0; i < result.roles_resources.length; i++) {
    //     permission_obj.push(result.roles_resources[i].resourceId);
    //   }
    // }
    // console.log("94--->", permission_obj);
    // localStorage.setItem(
    //   "isw_user_permissions",
    //   JSON.stringify(permission_obj)
    // );

    dispatch(setdashboardcheck(0));
    // let sorteduserdata = result.userOfficeData.sort((a, b) =>
    //   a.officeName.localeCompare(b.officeName)
    // );
    axios
      .get(`${base_url.api2}/getUserByUsername`, {
        headers: {
          Authorization: "Bearer " + result.jwtToken,
          username: result.data.email,
        },
      })
      .then(
        (response) => {
          let ofc_data = response.data.userOfficeData;
          let ofcData_obj = [];

          for (let i = 0; i < ofc_data.length; i++) {
            const element = ofc_data[i];

            let data = {
              value: ofc_data[i].officeId,
              label: ofc_data[i].officeName,
              country: ofc_data[i].country,
              city: ofc_data[i].city,
              officeCode: ofc_data[i].officeCode,
            };

            ofcData_obj.push(data);
          }

          // Access the properties of the first element directly
          let defaultBranchData = {};

          if (ofcData_obj.length > 0) {
            defaultBranchData.firstOfficeId = ofcData_obj[0].value;
            defaultBranchData.firstOfficeName = ofcData_obj[0].label;
            defaultBranchData.firstCountry = ofcData_obj[0].country;
            defaultBranchData.firstCity = ofcData_obj[0].city;
            defaultBranchData.firstOfficeCode = ofcData_obj[0].officeCode;
          }
          setDefaultBranch(defaultBranchData);
          const defaultBranchDataString = JSON.stringify(defaultBranchData);
          localStorage.setItem("default_branch_data", defaultBranchDataString);
          dispatch(setOfficeId(defaultBranchData));
        },
        (error) => {
          //console.log(error);
          // toast.error(JSON.stringify(error.response.data.message));
        }
      )
      .catch((error) => {
        //console.log(error);
      });

    dispatch(
      login({
        isw_user_token: result.jwtToken,
        isw_user_email: result.data.email,
        isw_adminId: result.data.id,
        isw_user_name: name,
        isw_first_name: result.data.firstName,
        isw_role_permissions: result.roles_resources,
        isw_user_permissions: result.userPermissionData,
        isw_user_role: result.data.roleId,
        isw_available_Office: result.userOfficeData,
      })
    );
    let last_data = {
      email: result.data.email,
      lastLogin: datenow,
    };

    axios
      .post(`${base_url.api2}/updateUserDataByEmail`, last_data, {
        headers: {
          Authorization: "Bearer " + result.jwtToken,
        },
      })
      .then(
        (response) => {
          //console.log(response.data);
        },
        (error) => {
          //console.log(error);
          // toast.error(JSON.stringify(error.response.data.message));
        }
      );

    //Getting admin details
    axios
      .get(`${base_url.api2}/getAllUsers`, {
        headers: {
          Authorization: "Bearer " + result.jwtToken,
          status: 1,
          columnName: "addedOn",
          direction: "DESC",
          page: 0,
          size: 50,
        },
      })
      .then(
        (response) => {
          //console.log("Dispatch Admin response", response.data.data.content);
          var client_admin = response.data.data.content;
          //console.log(response.data.data.content);
          dispatch(getAdmin(client_admin));
          //console.log("Form login", admin2);
        },
        (error) => {
          // toast.error(JSON.stringify(error.response.data.message));
          //console.log(error);
        }
      );

    // Getting College Details
    // axios
    //   .get(`${base_url.api2}/getAllInstitution`, {
    //     headers: {
    //       Authorization: "Bearer " + result.jwtToken,
    //       // username: result.data.email,
    //       status: 1,
    //     },
    //   })
    //   .then(
    //     (response) => {
    //       //console.log("Dispatch College response", response);
    //       var client_college = response.data.data.content;
    //       dispatch(getcollege(client_college));
    //     },
    //     (error) => {
    //       // toast.error(JSON.stringify(error.response.data.message));
    //       //console.log(error);
    //     }
    //   );

    //Getting course details
    // axios
    //   .get(`${base_url.api2}/getAllCourses`, {
    //     headers: {
    //       Authorization: "Bearer " + result.jwtToken,
    //       // username: result.data.email,
    //       status: 1,
    //     },
    //   })
    //   .then(
    //     (response) => {
    //       //console.log("Dispatch course response", response);
    //       var client_course = response.data.data.content;
    //       dispatch(getcourse(client_course));
    //     },
    //     (error) => {
    //       // toast.error(JSON.stringify(error.response.data.message));
    //       //console.log(error);
    //     }
    //   );

    // Getting all clients
    axios
      .get(`${base_url.api3}/getAllClients`, {
        headers: {
          Authorization: "Bearer " + result.jwtToken,
          username: result.data.email,
        },
      })
      .then(
        (response) => {
          //console.log("Client", response);
          const client_obj = [];
          for (let i = 0; i < response.data.data.content.length; i++) {
            const element = response.data.data.content[i];
            client_obj.push(element);
          }
          dispatch(getClient(client_obj));
        },
        (error) => {
          // toast.error(JSON.stringify(error.response.data.message));
          //console.log(error);
        }
      );

    toast.success("Success");
    setLoginresult(result);
    // authentication.onAuthentication();
    // props.navigate('/dashboard');
    axios
      .get("https://geolocation-db.com/json/")
      // .get("https://geolocation-db.com/jsonp/")
      .then((res) => {
        let ipaddress = res.data.IPv4;
        localStorage.setItem("AdminIpAddress", res.data.IPv4);
        let createdByName = name + " (" + result.data.email + ")";

        let todayDate = new Date();
        let fmt = "YYYY-MM-DD HH:mm:ss";
        let m = moment.utc(todayDate, fmt);
        let createdAt = m.local().format(fmt);

        let auditdata = {
          adminIp: ipaddress,
          createdById: result.data.id,
          createdByName: createdByName,
          id: 0,
          log: name + " logged in.",
          createdAt,
        };
        axios
          .post(`${base_url.api4}/saveAudit`, auditdata, {
            headers: {
              Authorization: "Bearer " + result.jwtToken,
            },
          })
          .then((response) => {
            //console.log(response);
          })
          .catch((error) => {
            //console.log(error);
            // toast.error(JSON.stringify(error.response.data.message));
          });
      })
      .catch((err) => {
        console.log(err);
      });

    setTimeout(
      () =>
        // props.navigate({ pathname: "/dashboard", state: result }),
        navigate("/"),
      1000
    );
  };

  const onSubmit = (data2) => {
    const isoDateString = new Date().toISOString();
    const datenow = isoDateString.split(".")[0];

    let username = data2.username.trim();
    let password = data2.password.trim();

    let authenticateData = {
      username,
      password,
    };

    fetch(base_url["api3"] + "/authenticate", {
      method: "POST",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify(authenticateData),
    })
      .then((r) => r.json())
      .then((result) => {
        if (result.status_code == "202") {
          // console.log("70----->", result);
          //console.log('At login',result.roles_resources);
          const name = result.data.firstName + " " + result.data.lastName;
          handleSuccessfulLogin(result, datenow, name);
        } else if (result.status_code == "400") {
          toast.error("Your account is deactivated");
        } else if (result.status_code == "401" || result.status_code == "403") {
          toast.error("Invalid username or password");
          //console.log(result);
        }
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  // const goBack = () => {
  //   navigate("/forgotpage");
  // };

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const openForgotPassModal = (elem) => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const [forgotUser, setForgotUser] = useState(false);
  const forgotPassword = (e) => {
    //console.log(e.target[0].value);
    axios
      .get(`${base_url.api2}/forgotAdminPassword`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          email: e.target[0].value.trim(),
        },
      })
      .then(
        (response) => {
          setIsOpen(false);
          toast.success("Reset Password Link has been sent to your Email Id");
        },
        (error) => {
          setForgotUser(true);
          // toast.error("User Does Not Exist");
          // setIsOpen(false);
        }
      );
  };

  return (
    <>
      <div className="login-page d-flex justify-content-center align-items-center">
        <ToastContainer limit={1} />
        <div className="form-container">
          <div className="row">
            <div className="col-xl-6 col-lg-12">
              <div className="login-image text-center">
                <div className="logo-container">
                  {/*                   <a>
                    <img
                      src={nepcomlogo}
                      alt="hello1"
                      style={{
                        height: "45%",
                        width: "55%",
                        marginBottom: "30px",
                      }}
                    />
                  </a> */}
                </div>
                <div className="img-container">
                  <img src={logosvg} alt="hello2" />
                </div>
              </div>
            </div>

            <div className="col-xl-6 col-lg-12">
              <form
                action=""
                className="login-form"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="form-head">
                  <h3 className="form-title">Login</h3>
                  <p className="form-desc">
                    Please enter the credential below to login.
                  </p>
                </div>

                <div className="form-group">
                  <div className="input-grp">
                    <i className="icon">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <g clipPath="url(#clip0_342_113)">
                          <path
                            d="M14.9318 12.0922L21 15.9285V8.094L14.9318 12.0922Z"
                            fill="#17B978"
                          />
                          <path
                            d="M3 8.094V15.9285L9.06825 12.0922L3 8.094Z"
                            fill="#17B978"
                          />
                          <path
                            d="M19.8749 5.8125H4.12494C3.56357 5.8125 3.11807 6.231 3.03369 6.76988L11.9999 12.6773L20.9662 6.76988C20.8818 6.231 20.4363 5.8125 19.8749 5.8125Z"
                            fill="#17B978"
                          />
                          <path
                            d="M13.9013 12.7717L12.3094 13.8202C12.2149 13.8821 12.108 13.9125 12 13.9125C11.892 13.9125 11.7851 13.8821 11.6906 13.8202L10.0988 12.7706L3.03601 17.238C3.12264 17.7724 3.56589 18.1875 4.12501 18.1875H19.875C20.4341 18.1875 20.8774 17.7724 20.964 17.238L13.9013 12.7717Z"
                            fill="#17B978"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_342_113">
                            <rect
                              width="18"
                              height="18"
                              fill="white"
                              transform="translate(3 3)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </i>
                    <input
                      className="form-control"
                      type="email"
                      placeholder="Email"
                      // value={loginData.username}
                      name="username"
                      onChange={changeLogInData}
                      // required
                      ref={register({ required: true })}
                    />
                    {errors.username && (
                      <p className="errormessage">Username is Required</p>
                    )}
                  </div>
                  <div className="input-grp">
                    <i className="icon">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M18.375 9.75H17.25V8.24999C17.25 5.35512 14.8949 3 12 3C9.10509 3 6.75001 5.35512 6.75001 8.24999V9.75H5.62501C5.41773 9.75 5.25 9.91773 5.25 10.125V19.5C5.25 20.3273 5.92271 21 6.75001 21H17.25C18.0773 21 18.75 20.3273 18.75 19.5V10.125C18.75 9.91773 18.5823 9.75 18.375 9.75ZM13.1228 17.5836C13.1345 17.6894 13.1005 17.7956 13.0294 17.8751C12.9584 17.9546 12.8566 18 12.75 18H11.25C11.1435 18 11.0416 17.9546 10.9706 17.8751C10.8995 17.7957 10.8655 17.6895 10.8772 17.5836L11.1138 15.4563C10.7296 15.1769 10.5 14.7349 10.5 14.25C10.5 13.4227 11.1727 12.75 12 12.75C12.8273 12.75 13.5 13.4227 13.5 14.25C13.5 14.7349 13.2704 15.1769 12.8863 15.4563L13.1228 17.5836ZM15 9.75H9.00001V8.24999C9.00001 6.59582 10.3458 5.25 12 5.25C13.6542 5.25 15 6.59582 15 8.24999V9.75Z"
                          fill="#17B978"
                        />
                      </svg>
                    </i>
                    <input
                      className="form-control"
                      type={inputtype}
                      placeholder="Password"
                      // value={loginData.password}
                      name="password"
                      onChange={changeLogInData}
                      ref={register()}
                      required
                    />
                    {errors.password && (
                      <p className="errormessage">Password is Required</p>
                    )}
                  </div>
                </div>

                <div className="form-group button-container">
                  <button
                    type="submit"
                    className="btn login-btn"
                    style={{ width: "100%" }}
                  >
                    LOGIN
                  </button>
                </div>
              </form>

              <div className="text-right">
                <Link to="/forgotpage" className="forget-password-link">
                  Forgot password
                </Link>
              </div>
              {/* <Modal
              isOpen={modalIsOpen}
              // onAfterOpen={afterOpenModal}
              onRequestClose={closeModal}
              style={customStyles2}
              contentLabel="Example Modal"
            >
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  forgotPassword(e);
                }}
              >
                <div>
                  <h3 style={{ color: "black" }}>Forgot Password</h3>
                  <div className="form-group creatadmin">
                    <Row>
                      <Col>
                        <label>User Name:</label>
                        <input
                          type="text"
                          className="form-control underline-input"
                          required
                        />
                        {forgotUser && (
                          <p className="errormessage">User Does Not Exist</p>
                        )}
                      </Col>
                    </Row>
                  </div>
                  <div className="form-group creatadmin">
                    <Row style={{ float: "right" }}>
                      <Button
                        style={{
                          marginTop: "20px",
                          marginRight: "10px",
                        }}
                        type="submit"
                        color="primary"
                      >
                        Submit
                      </Button>
                      <Button
                        style={{
                          marginTop: "20px",
                          marginRight: "10px",
                        }}
                        color="secondary"
                        onClick={closeModal}
                      >
                        Cancel
                      </Button>
                    </Row>
                  </div>
                </div>
              </form>
            </Modal> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LogIn;
