import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../features/userSlice";
import base_url from "../../api/bootapi";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate, useParams } from "react-router-dom";
import { useStyles } from "../../Utilities/CSSUtilities";
import { check1pointpermission } from "../Permission/permission";
import { toast } from "react-toastify";
import { logout } from "../features/userSlice";

const AgentAgreementDetail = (props) => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const jwtToken = "Bearer " + user.isw_user_token;
  const classes = useStyles();
  const navigate = useNavigate();
  const [backdrop, setbackdrop] = useState(false);
  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);

  useEffect(() => {
    getPermissions();
  }, []);

  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            navigate("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };
  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);

    if (check1pointpermission(729, permission_obj, resource_obj)) {
    } else {
      navigate("/errorPage");
    }
  };

  const goBack = () => {
    navigate("/agent");
  };

  const downloadAll = (agentId, agentAgreementPath) => {
    //console.log(agentId);
    //console.log(agentAgreementPath);
    setbackdrop(true);
    let file_name =
      agentAgreementPath.split("amazonaws.com/")[
        agentAgreementPath.split("amazonaws.com/").length - 1
      ];
    const header = {
      method: "GET",
      headers: {
        Authorization: jwtToken,
        agentId: agentId,
        fileName: file_name,
      },
    };

    var url = `${base_url.api3}/downloadAgentTransactionalAgreementByInstitutionIdAndFileName`;
    fetch(url, header)
      .then((response) => readAllChunks(response))
      .then(async function (response) {
        //console.log(response);
        const downloadFileType = localStorage.getItem(
          "agent_attachment_file_content_type"
        );
        //console.log(downloadFileType);
        var blob = new Blob(response, { type: downloadFileType });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = file_name;
        link.click();
        setbackdrop(false);
      })
      .catch((error) => {
        setbackdrop(false);
        handle403error(error);
        //console.log(error);
      });
  };

  const readAllChunks = (readableStream) => {
    localStorage.setItem(
      "agent_attachment_file_content_type",
      readableStream.headers.get("content-type")
    );
    const reader = readableStream.body.getReader();
    const chunks = [];

    function pump() {
      return reader
        .read()
        .then(({ value, done }) => {
          if (done) {
            return chunks;
          }
          chunks.push(value);
          return pump();
        })
        .catch((error) => {
          setbackdrop(false);
          handle403error(error);
        });
    }
    return pump();
  };

  return (
    <div style={{ margin: "10px 20px" }}>
      <Backdrop className={classes.backdrop} open={backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="d-flex justify-content-end">
        <button
          className="btn listing_addbutton"
          style={{
            marginTop: "-35px",
            width: "120px",
            height: "40px",
            textDecoration: "underline",
          }}
          onClick={goBack}
        >
          Agent List
        </button>
      </div>
      <Table
        className=" workload card-table table-borderless"
        style={{ marginTop: "20px" }}
        responsive
        striped
      >
        <TableHead style={{ backgroundColor: "cornflowerblue" }}>
          <TableRow>
            <TableCell>SN</TableCell>
            <TableCell>Name</TableCell>
            {check1pointpermission(725, permissionData, rolepermission) ? (
              <TableCell align="right">View</TableCell>
            ) : null}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.transactionalarrangementlist?.map((p, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row">
                {index + 1}
              </TableCell>
              <TableCell component="th" scope="row">
                {p.agentAgreementname}
              </TableCell>
              <TableCell align="right">
                {check1pointpermission(725, permissionData, rolepermission) ? (
                  <a
                    onClick={() => {
                      downloadAll(props?.agentId, p.agentAgreementPath);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    View
                  </a>
                ) : null}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default AgentAgreementDetail;
