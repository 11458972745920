import React, { useState, useEffect } from "react";
import "../css/AddInvoiceDetails.css";
import "../css/Responsive.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import base_url from "../../api/bootapi";
import { toast } from "react-toastify";
import moment from "moment";
import { useDispatch } from "react-redux";
import { logout } from "../features/userSlice";
import NepcomServices from "../../assets/images/nepcoms-n.png";
import NepcomLogo from "../../assets/images/Nepcoms-01.png";

const selectheight = "35px";
const selectWidth = "100%";
const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
  },
  selectdrop: {
    width: selectWidth,
    height: selectheight,
  },
}));

const GeneralPrintInvoice = () => {
  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  const classes = useStyles();
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isw_user_name = localStorage.getItem("isw_user_name");
  const isoDateString = new Date().toISOString();
  const datenow = isoDateString.split(".")[0];
  const [courseName, setcourseName] = useState();
  const [configkey, setconfigkey] = useState();
  const [logokey, setlogokey] = useState();
  const [addresskey, setaddresskey] = useState();
  const [configkeybank, setconfigkeybank] = useState();
  const [stateCust, setstateCust] = useState();
  const [commissiontotal, setcommissionTotal] = useState();
  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        navigate("/");
      }
    });
    getadminaddress();
    getaddresslogo();
    getInvoicelogo();
    getadminbankdetails();
    getinvoicedetails();
  }, []);

  const getadminbankdetails = () => {
    axios
      .get(`${base_url.api3}/getByConfigKey`, {
        headers: {
          configKey: "isw_bank_details",
        },
      })
      .then((res) => {
        setconfigkeybank(res.data.data.configValue);
      })
      .catch((err) => {
        //console.log(err);
        handle403error(err);
      });
  };
  const getadminaddress = () => {
    axios
      .get(`${base_url.api3}/getByConfigKey`, {
        headers: {
          configKey: "isw_address",
        },
      })
      .then((res) => {
        setconfigkey(res.data.data.configValue);
      })
      .catch((err) => {
        //console.log(err);
        handle403error(err);
      });
  };

  const getaddresslogo = () => {
    axios
      .get(`${base_url.api3}/getByConfigKey`, {
        headers: {
          configKey: "invoice_logo",
        },
      })
      .then((res) => {
        setlogokey(res.data.data.configValue);
        //console.log("configValue------->", res.data.data.configValue);
      })
      .catch((err) => {
        //console.log(err);
        handle403error(err);
      });
  };

  const getInvoicelogo = () => {
    axios
      .get(`${base_url.api3}/getByConfigKey`, {
        headers: {
          configKey: "watermark_logo",
        },
      })
      .then((res) => {
        setaddresskey(res.data.data.configValue);
        //console.log("configValue------->", res.data.data.configValue);
      })
      .catch((err) => {
        //console.log(err);
        handle403error(err);
      });
  };

  const [superAgentData, setSuperAgentData] = useState();
  const getinvoicedetails = () => {
    axios
      .get(`${base_url.api2}/getInvoiceById`, {
        headers: {
          Authorization: jwtToken,
          invoiceId: params.invoice_id,
        },
      })
      .then((res) => {
        setstateCust(res.data.data);
        setcommissionTotal(res.data.data.totalCommission);
        axios
          .get(`${base_url.api2}/getEnquiryById`, {
            headers: {
              Authorization: jwtToken,
              enquiryId: res.data.data.enquiryId,
            },
          })
          .then((res) => {
            setcourseName(res.data.data.courseName);
            let superAgentId = res.data.data.superAgentId;
            if (superAgentId != null) {
              axios
                .get(`${base_url.api3}/getAgentById`, {
                  headers: {
                    Authorization: jwtToken,
                    agentId: Number(superAgentId),
                  },
                })
                .then((resp1) => {
                  setSuperAgentData(resp1.data.data);
                });
            }
          });
      })
      .catch((err) => {
        handle403error(err);
      });
  };

  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            navigate("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };
  return (
    <div style={{ padding: "50px 80px", background: "white" }}>
      <div className="printinvoice_headerrightbox">
        {/* <div>
          <div dangerouslySetInnerHTML={{ __html: configkey }} />
        </div> */}
        <img src={logokey} style={{ width: "251px", height: "90px" }} />
        <h3
          style={{
            fontWeight: "bold",
            marginRight: "50px",
            marginTop: "15px",
            fontSize: "38px",
          }}
        >
          TAX INVOICE
        </h3>
      </div>
      <hr className="printinvoice_horiline" />
      <div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            style={{
              border: "2px solid #737272",
              borderRadius: "5px",
              padding: "5px",
            }}
          >
            <h3
              className="nomarginbottom"
              style={{ textTransform: "uppercase", fontWeight: "bold" }}
            >
              {stateCust?.invoiceStatus}
            </h3>
          </div>
        </div>
        <div className="print_header printinvoice_header">
          <div style={{ flex: "0.7" }}>
            <h4 style={{ fontWeight: "bold", textAlign: "left" }}>
              INVOICE TO :{" "}
            </h4>
            {/* <> */}
            {/* {((stateCust?.commisionType == "commissionInvoiceNet" || "commissionInvoiceGross") && (superAgentData?.agentId != null)) ? (
                <>
                  <div className="printinvoice_headerrightbox">
                    {/* <Typography style={{ flex: 0.3 }}>Name:</Typography> *
                    <h6 style={{ fontWeight: "bold" }}>
                      {superAgentData?.name}
                    </h6>
                  </div>
                  <div style={{ display: "flex" }}>
                    <h6 style={{ fontWeight: "bold" }}>ID:</h6>
                    <h6>{stateCust?.externalId}</h6>
                  </div>
                  <div className="printinvoice_headerrightbox">
                    {/* <Typography style={{ flex: 0.3 }}>Course:</Typography> *
                    <h6>{stateCust?.courseName}</h6>
                  </div>
                  <div className="printinvoice_headerrightbox">
                    {/* <Typography style={{ flex: 0.3 }}>Provider:</Typography> *
                    <h6>{stateCust?.serviceProvider}</h6>
                  </div>
                </>
              ) : ( */}
            {/* <> */}
            <div className="printinvoice_headerrightbox">
              {/* <Typography style={{ flex: 0.3 }}>Student Name:</Typography> */}
              <h6 style={{ fontWeight: "bold" }}>
                {stateCust?.clientUsername}
              </h6>
            </div>
            <div style={{ display: "flex" }}>
              <h6 style={{ fontWeight: "bold" }}>ID:</h6>
              <h6>{stateCust?.externalId}</h6>
            </div>
            <div className="printinvoice_headerrightbox">
              {/* <Typography style={{ flex: 0.3 }}>Course:</Typography> */}
              <h6>{stateCust?.courseName}</h6>
            </div>
            <div className="printinvoice_headerrightbox">
              {/* <Typography style={{ flex: 0.3 }}>Provider:</Typography> */}
              <h6>{stateCust?.serviceProvider}</h6>
            </div>
            {/* </>
              )}
            </> */}
          </div>
          <div
            style={{
              flex: "0.3",
              display: "flex",
              alignItems: "end",
              justifyContent: "end",
            }}
          >
            <div
              className="printinvoice_headerrightboxtop"
              style={{ textAlign: "left" }}
            >
              <div className="printinvoice_headerrightboxnew">
                <Typography variant="body1" style={{ marginLeft: "10px" }}>
                  INVOICE NO :
                </Typography>
                <Typography variant="body1">#{stateCust?.id}</Typography>
              </div>
              <div className="printinvoice_headerrightboxnew">
                <Typography variant="body1" style={{ marginLeft: "10px" }}>
                  INVOICE DATE :
                </Typography>
                <Typography variant="body1">
                  {moment(stateCust?.invoiceCreatedDate).format("DD/MM/YYYY")}
                </Typography>
              </div>
              <div className="printinvoice_headerrightboxnew print_invoicetotaldue">
                <Typography variant="body1" style={{ marginLeft: "10px" }}>
                  TOTAL DUE :{/* {`Total Due (in ${stateCust?.currency}):`} */}
                </Typography>
                {/* <Typography variant="body1">${Number(stateCust?.totalDue).toFixed(2)}</Typography> */}
                <Typography variant="body1">
                  (In AUD) {Number(stateCust?.totalDue).toFixed(2)}
                </Typography>
              </div>
              <div className="printinvoice_headerrightboxnew">
                <Typography variant="body1" style={{ marginLeft: "10px" }}>
                  DUE DATE :
                </Typography>
                <Typography variant="body1">
                  {moment(stateCust?.invoiceDueDate).format("DD/MM/YYYY")}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="print_middle" style={{ marginBottom: "20px" }}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: "bolder" }}>
                  DESCRIPTION
                </TableCell>
                <TableCell align="right" style={{ fontWeight: "bolder" }}>
                  FEE
                </TableCell>
                <TableCell align="right" style={{ fontWeight: "bolder" }}>
                  TAX(%)
                </TableCell>
                <TableCell align="right" style={{ fontWeight: "bolder" }}>
                  TAX AMOUNT
                </TableCell>
                <TableCell align="right" style={{ fontWeight: "bolder" }}>
                  TOTAL <br />
                  <span>(INCLUDING TAX)</span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {stateCust?.invoiceDetails.map((p, index) => (
                <TableRow key={index + 1}>
                  <TableCell component="th" scope="row">
                    <Typography>{p.description}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography>
                      {p.amount ? p.amount.toFixed(2) : Number(0).toFixed(2)}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography>
                      {p.taxPercentage
                        ? p.taxPercentage.toFixed(2)
                        : Number(0).toFixed(2)}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography>
                      {p.taxAmount
                        ? p.taxAmount.toFixed(2)
                        : Number(0).toFixed(2)}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography>
                      {p.totalAmount
                        ? p.totalAmount.toFixed(2)
                        : Number(0).toFixed(2)}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableHead className="printinvoice_tr">
              <TableRow>
                <TableCell>
                  <Typography>{`Total(in ${stateCust?.currency}):`}</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography>
                    {Number(stateCust?.netAmount).toFixed(2)}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography>{null}</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography>
                    {stateCust?.totalTax
                      ? stateCust?.totalTax.toFixed(2)
                      : Number(0).toFixed(2)}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography>
                    {stateCust?.totalAmountIncludingTax
                      ? stateCust?.totalAmountIncludingTax.toFixed(2)
                      : Number(0).toFixed(2)}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
      </div>
      <div
        className="print_footer"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div className="print_footerbankdetails" style={{ flex: 0.6 }}>
          <div className="printinvoice_paymentdetails">
            <Typography
              style={{
                fontWeight: "bolder",
                fontSize: "25px",
              }}
            >
              PAYMENT DETAILS
            </Typography>
          </div>
          <div
            style={{ fontWeight: "bold", fontSize: "20px", textAlign: "left" }}
          >
            <div dangerouslySetInnerHTML={{ __html: configkeybank }} />
          </div>
        </div>

        <div
          className="print_footersummary"
          style={{ flex: "0.4", textAlign: "left" }}
        >
          <div style={{ backgroundColor: "#737373", padding: "10px" }}>
            <h4 style={{ color: "white" }}>Invoice Summery (In AUD)</h4>
          </div>
          <div
            className="printinvoice_divlisting"
            style={{ backgroundColor: "rgba(0,0,0,.2)" }}
          >
            <h5 className="printinvoice_divlistingtextfirst">
              Fee received from client:
            </h5>
            <h5 className="printinvoice_divlistingtextsecond">
              {stateCust?.netAmount.toFixed(2) || Number(0).toFixed(2)}
            </h5>
          </div>
          <div className="printinvoice_divlisting">
            <h5 className="printinvoice_divlistingtextfirst">Tax:</h5>
            <h5 className="printinvoice_divlistingtextsecond">
              {stateCust?.totalTax.toFixed(2) || Number(0).toFixed(2)}
            </h5>
          </div>
          <div
            className="printinvoice_divlisting"
            style={{ backgroundColor: "rgba(0,0,0,.2)" }}
          >
            <h5 className="printinvoice_divlistingtextfirst">
              Total claimed (inc. Tax):
            </h5>
            <h5 className="printinvoice_divlistingtextsecond">
              {(
                Number(stateCust?.netAmount) + Number(stateCust?.totalTax)
              ).toFixed(2) || Number(0).toFixed(2)}
            </h5>
          </div>

          {/* <div className="printinvoice_divlisting">
            <h5
              style={{ fontWeight: "bold" }}
              className="printinvoice_divlistingtextfirst"
            >
              Net Fee Transferred :
            </h5>
            <h5
              style={{ fontWeight: "bold" }}
              className="printinvoice_divlistingtextsecond"
            >
              {Number(stateCust?.netAmount) - Number(stateCust?.totalTax).toFixed(2) || Number(0).toFixed(2)}
            </h5>
          </div> */}
        </div>
      </div>
      {addresskey && (
        <img
          alt="Nepcoms Services"
          src={addresskey}
          className="printinvoice_footerimage"
        />
      )}
      <div style={{ position: "absolute", width: "86%", bottom: "40px" }}>
        <hr className="printinvoice_horiline nomarginbottom" />
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <p
              className="nomarginbottom"
              style={{ fontWeight: "bolder", fontSize: 18, textAlign: "left" }}
            >
              Nepcoms Services
            </p>
            <div style={{ fontWeight: "bold", textAlign: "left" }}>
              <div dangerouslySetInnerHTML={{ __html: configkey }} />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              flexDirection: "column",
              textAlign: "left",
            }}
          >
            <p className="nomarginbottom">Printed By: {isw_user_name}</p>

            <p>
              Printed On: {moment().date()},{moment(datenow).format("MMMM")}{" "}
              {moment().year()}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralPrintInvoice;
