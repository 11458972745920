import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { Card, CardText, Col, Row, Button } from "reactstrap";
import axios from "axios";
import base_url from "./../../api/bootapi";
import { ToastContainer, toast } from "react-toastify";
import "./../css/LoginForm.css";
import "./../css/Responsive.css";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { useSelector, useDispatch } from "react-redux";
import { logout, selectUser, setdashboardcheck } from "../features/userSlice";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { makeStyles } from "@mui/styles";
import callsaveauditapi from "../../services/auditservice";
import {
  check4pointpermission,
  check1pointpermission,
} from "../Permission/permission";

const drawerWidth = 300;
const drawerWidth2 = 60;
const selectheight = "35px";
const selectWidth = "100%";
const headtxt = 30;

const useStyles = makeStyles((theme) => ({
  toolbar: {
    marginLeft: drawerWidth,
    marginRight: drawerWidth2,
  },
  selectdrop: {
    width: selectWidth,
    height: selectheight,
  },
  headtext: {
    fontSize: headtxt,
  },
  createBtn2: {
    color: "white",
    textDecoration: "none",
  },
  createBtn: {
    float: "left",
  },
  backdrop: {
    // zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    zIndex: 1000,
  },
}));

function EditOffice(props) {
  let params = useParams();
  //console.log(params.admin_username);
  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  const username = localStorage.getItem("isw_user_email");
  const logedinadmin = localStorage.getItem("isw_adminId");
  const classes = useStyles();
  const [stateCust, setstateCust] = useState({});
  const [availableroles, setAvailableroles] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [region, setregion] = useState();
  const [country1, setcountry] = useState();
  const [setComment, setsetComment] = useState();
  const user = useSelector(selectUser);
  const ipaddress = localStorage.getItem("AdminIpAddress");
  const isw_user_name = localStorage.getItem("isw_user_name");
  const isw_adminId = localStorage.getItem("isw_adminId");
  const isw_user_email = localStorage.getItem("isw_user_email");

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        navigate("/");
      }
    });
    getPermissions();
  }, []);

  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);

    if (check1pointpermission(606, permission_obj, resource_obj)) {
      //if (available_permission){
      dispatch(setdashboardcheck(21));
      let id = params.admin_username;
      getRoles();
      getOfficeById(id);
      // } else {
      //   navigate("/errorPage");
      // }
    } else {
      navigate("/errorPage");
    }
  };

  const getRoles = () => {
    axios
      .get(`${base_url.api3}/listAllRoles`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        setAvailableroles(response.data.data);
      })
      .catch((error) => {
        handle403error(error);
        toast.error(JSON.stringify(error.response.data.message));
      });
  };
  //console.log(availableroles);

  const getOfficeById = () => {
    axios
      .get(`${base_url.api1}/getOfficeById`, {
        headers: {
          Authorization: jwtToken,
          officeId: params.office_ids,
        },
      })
      .then((response) => {
        if (response.data.status_code == "200") {
          let office = response.data.data;
          setstateCust({
            adminUserId: logedinadmin,
            city: office.city,
            contactPerson: office.contactPerson,
            country: office.country,
            emailId: office.emailId,
            officeId: office.officeId,
            officeName: office.officeName,
            phone: office.phone,
            latitude: office.latitude,
            longitude: office.longitude,
            state: office.state,
            street: office.street,
            zipCode: office.zipCode,
            officeCode: office.officeCode,
            comment: office.comment,
            status: office.status,
          });
          setcountry(office.country);
          setregion(office.state);
        }
      })
      .catch((error) => {
        handle403error(error);
        toast.error(JSON.stringify(error.response.data.message));
      });
  };
  //console.log(stateCust);
  const inputEvent = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setstateCust((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const putCustomer = (e) => {
    const isoDateString = new Date().toISOString();
    const datenow = isoDateString.split(".")[0];
    stateCust.updatedOn = datenow;
    stateCust.country = country1;
    stateCust.state = region;
    stateCust.officeCode = stateCust.officeCode.toUpperCase();
    stateCust.comment = setComment;
    axios
      .post(`${base_url.api1}/updateOfficeById`, stateCust, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        toast.success("Success");

        let log =
          isw_user_name +
          " Updated Branch " +
          response.data.data.officeName +
          ".";
        callsaveauditapi(log, dispatch, navigate);
        navigate("/office");
      })
      .catch((error) => {
        handle403error(error);
        toast.error(JSON.stringify(error.response.data.message));
      });
  };

  const goback = () => {
    navigate("/office");
  };

  const selectCountry = (val) => {
    setcountry(val);
  };
  const selectRegion = (val) => {
    setregion(val);
  };

  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            navigate("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };
  return (
    <div>
      <ToastContainer />
      {/* <Dashboard/> */}
      {/* <Card className="commoncomponent_cardcss"> */}
      <div
        className="page-head d-flex justify-content-between p-4 "
        style={{ marginTop: "5rem" }}
      >
        <div className="breadcrums">
          <h3 className="page-title bold">Branch</h3>
          <Link to="/" className="b-link">
            Home
          </Link>
          -<a className="b-link">Partner</a>-
          <a className="b-link active">Provider Edit</a>
        </div>
        <a onClick={goback} className="sent-button">
          LIST
        </a>
      </div>
      <div style={{ padding: "1.5rem", border: "1.5rem solid #e9ebf2" }}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            putCustomer(e);
          }}
        >
          <div className="form-inner-admin">
            {/* <Row>
              <Col md={4}>
                <CardText
                  className={classes.headtext}
                  style={{ float: "left" }}
                >
                  <strong>Branch</strong>
                </CardText>
              </Col>
              <Col md={4}>{null}</Col>
              <Col md={4} className="d-flex justify-content-end">
                <button className="btn listing_addbutton" onClick={goback}>
                  &#x2630; List
                </button>
              </Col>
            </Row> */}
            <div className="form-group creatadmin">
              <Row>
                <Col md={3}>
                  <label className="area_rowslable_my">
                    Branch <span style={{ color: "red" }}>*</span>:
                  </label>
                  {/* {params.office_ids == "20" ? ( */}
                  {params.office_ids == "13" ? (
                    <input
                      type="text"
                      value={stateCust.officeName}
                      name="officeName"
                      onChange={inputEvent}
                      style={{ backgroundColor: "#f5f5f9" }}
                      className="form-control form-control-sm"
                      readOnly
                    />
                  ) : (
                    <input
                      type="text"
                      value={stateCust.officeName}
                      name="officeName"
                      onChange={inputEvent}
                      style={{ backgroundColor: "#f5f5f9" }}
                      className="form-control form-control-sm"
                      required
                    />
                  )}
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">
                    Email<span style={{ color: "red" }}>*</span>:
                  </label>
                  <input
                    type="email"
                    value={stateCust.emailId}
                    pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,3}$"
                    name="emailId"
                    style={{ backgroundColor: "#f5f5f9" }}
                    onChange={inputEvent}
                    className="form-control form-control-sm"
                    required
                  />
                </Col>

                <Col md={3}>
                  <label className="area_rowslable_my">Contact Person:</label>
                  <input
                    type="text"
                    value={stateCust.contactPerson}
                    name="contactPerson"
                    onChange={inputEvent}
                    style={{ backgroundColor: "#f5f5f9" }}
                    className="form-control form-control-sm"
                  />
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">Contact No.:</label>
                  <input
                    type="text"
                    value={stateCust.phone}
                    name="phone"
                    onChange={inputEvent}
                    style={{ backgroundColor: "#f5f5f9" }}
                    className="form-control form-control-sm"
                  />
                </Col>
              </Row>
            </div>
            <div className="form-group creatadmin">
              <Row>
                <Col md={3}>
                  <label className="area_rowslable_my">
                    Address<span style={{ color: "red" }}>*</span>:
                  </label>
                  <input
                    type="text"
                    value={stateCust.street}
                    name="street"
                    onChange={inputEvent}
                    style={{ backgroundColor: "#f5f5f9" }}
                    className="form-control form-control-sm"
                    required
                  />
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">
                    City<span style={{ color: "red" }}>*</span>:
                  </label>
                  <input
                    type="text"
                    value={stateCust.city}
                    name="city"
                    onChange={inputEvent}
                    style={{ backgroundColor: "#f5f5f9" }}
                    className="form-control form-control-sm"
                    required
                  />
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">
                    Country<span style={{ color: "red" }}>*</span>:
                  </label>
                  <CountryDropdown
                    className="form-control underline-input countrydropdown2"
                    defaultOptionLabel="- Select Country -"
                    value={country1}
                    style={{ backgroundColor: "#f5f5f9" }}
                    onChange={(val) => selectCountry(val)}
                    required
                  />
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">
                    State<span style={{ color: "red" }}>*</span>:
                  </label>
                  {country1 == "Nepal" ? (
                    <select
                      className={classes.selectdrop}
                      name="gender"
                      value={region}
                      style={{ backgroundColor: "#f5f5f9" }}
                      onInput={(e) => selectRegion(e.target.value)}
                    >
                      <option value="Province No. 1">Province No. 1</option>
                      <option value="Madhesh Province">Madhesh Province</option>
                      <option value="Bagmati Province">Bagmati Province</option>
                      <option value="Gandaki Province">Gandaki Province</option>
                      <option value="Lumbini Province">Lumbini Province</option>
                      <option value="Karnali Province">Karnali Province</option>
                      <option value="Sudurpashchim Province">
                        Sudurpashchim Province
                      </option>
                    </select>
                  ) : (
                    <RegionDropdown
                      className="form-control underline-input countrydropdown2"
                      defaultOptionLabel="- Select State -"
                      country={country1}
                      value={region}
                      style={{ backgroundColor: "#f5f5f9" }}
                      onChange={(val) => selectRegion(val)}
                      required
                    />
                  )}
                </Col>
              </Row>
            </div>
            <div className="form-group creatadmin">
              <Row>
                <Col md={3}>
                  <label className="area_rowslable_my">
                    Latitude<span style={{ color: "red" }}>*</span>:
                  </label>
                  <input
                    type="number"
                    value={stateCust.latitude}
                    name="latitude"
                    onChange={inputEvent}
                    style={{ backgroundColor: "#f5f5f9" }}
                    className="form-control form-control-sm"
                    step="any"
                    required
                  />
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">
                    Longitude<span style={{ color: "red" }}>*</span>:
                  </label>
                  <input
                    type="number"
                    value={stateCust.longitude}
                    name="longitude"
                    onChange={inputEvent}
                    style={{ backgroundColor: "#f5f5f9" }}
                    className="form-control form-control-sm"
                    step="any"
                    required
                  />
                </Col>

                <Col md={3}>
                  <label className="area_rowslable_my">
                    Branch Code<span style={{ color: "red" }}>*</span>:
                  </label>
                  <input
                    type="text"
                    value={stateCust.officeCode}
                    name="officeCode"
                    maxLength={7}
                    onChange={inputEvent}
                    style={{ backgroundColor: "#f5f5f9" }}
                    className="form-control form-control-sm"
                    required
                  />
                </Col>

                <Col md={3}>
                  <label className="area_rowslable_my">
                    PostCode<span style={{ color: "red" }}>*</span>:
                  </label>
                  <input
                    type="text"
                    value={stateCust.zipCode}
                    name="zipCode"
                    onChange={inputEvent}
                    style={{ backgroundColor: "#f5f5f9" }}
                    className="form-control form-control-sm"
                    required
                  />
                </Col>
              </Row>
            </div>
            <div className="form-group creatadmin">
              <Row>
                <Col md={3}>
                  <label className="area_rowslable_my">
                    Status<span style={{ color: "red" }}>*</span>:
                  </label>
                  <select
                    defaultValue={stateCust.status == 1 ? "Active" : "InActive"}
                    className={classes.selectdrop}
                    name="status"
                    value={stateCust.status}
                    onChange={inputEvent}
                    style={{ backgroundColor: "#f5f5f9" }}
                    required
                  >
                    <option value="1">Active</option>
                    <option value="0">InActive</option>
                  </select>
                </Col>
              </Row>
            </div>
            <div className="form-group creatadmin">
              <Row>
                <Col md={12}>
                  <label className="area_rowslable_my">Remarks:</label>
                  <TextareaAutosize
                    rowsMax={6}
                    style={{ height: "100px", backgroundColor: "#f5f5f9" }}
                    defaultValue={stateCust?.comment}
                    className="form-control underline-input"
                    onChange={(e) => {
                      //console.log(e.target.value);
                      setsetComment(e.target.value);
                    }}
                  />
                </Col>
              </Row>
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <button className="sent-button" type="submit">
              Update
            </button>
          </div>
        </form>
      </div>
      {/* </Card> */}
    </div>
  );
}

export default EditOffice;
