import React from "react";
import { CardBody, CardSubtitle } from "reactstrap";
import WarningIcon from '@mui/icons-material/Warning';
// import Dashboard from "./NewDashboard";
function ErrorPage() {
  return (
    <div style={{ marginLeft: "300px" }}>
      {/* <Dashboard /> */}
      <div className="commoncomponent_cardcss">
        <CardBody>
          <CardSubtitle className="font-weight-bold"></CardSubtitle>
          <WarningIcon style={{ color: "red", fontSize: "50px" }} />
          UnAuthorized Access! You dont have the permission to access this page.
        </CardBody>
      </div>
    </div>
  );
}

export default ErrorPage;
