import React from "react";
import { Link, useNavigate } from "react-router-dom";

const PageNotFound = () => {
    const history = useNavigate();
    return (
        <div className="wrap-error">
            <div className="d-flex align-items-center h-100">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-8 offset-sm-2 text-center text-white">
                            <h1 className="pgf-no-container">
                                <span>4</span>
                                <span>0</span>
                                <span>4</span>
                            </h1>
                            <h5 className="">Oops! Page not found</h5>
                            <p className="mb-4">
                                We are sorry, but the page you requested was not found
                            </p>
                            <button className="btn btn-secondary" onClick={() => history("/")}>
                                Go home
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PageNotFound;
