import axios from "axios";
import base_url from "./../api/bootapi";
import moment from "moment";
import { toast } from "react-toastify";
import { logout } from "../components/features/userSlice";
const callsaveauditapi = (log, dispatch, navigate) => {
  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  const isw_user_name = localStorage.getItem("isw_user_name");
  const isw_adminId = localStorage.getItem("isw_adminId");
  const isw_user_email = localStorage.getItem("isw_user_email");
  const ipaddress = localStorage.getItem("AdminIpAddress");
  let createdByName = isw_user_name + " (" + isw_user_email + ")";

  let todayDate = new Date();
  let fmt = "YYYY-MM-DD HH:mm:ss";
  let m = moment.utc(todayDate, fmt);
  let createdAt = m.local().format(fmt);

  let auditdata = {
    adminIp: ipaddress,
    createdById: isw_adminId,
    createdByName: createdByName,
    id: 0,
    log,
    createdAt,
  };

  try {
    axios
      .post(`${base_url.api4}/saveAudit`, auditdata, {
        headers: { Authorization: jwtToken },
      })
      .then((response) => {})
      .catch((error) => {
        if (error) {
          if (error.response) {
            if (Number(error?.response?.status) === 403) {
              setTimeout(() => {
                dispatch(logout());
                localStorage.clear();
                localStorage.setItem("from_403_status", "call_toast");
                navigate("/");
                toast.warn("Session Expired");
              }, 1000);
            }
          }
        }
        //console.log("Error", error);
      });
  } catch (e) {
    //console.log("Error", e);
  }
};

export default callsaveauditapi;
