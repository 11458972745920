import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CardText, Col, Row, Table, Button } from "reactstrap";
import moment from "moment";
import axios from "axios";
import base_url from "../../api/bootapi";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import { Typography } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import "../css/Responsive.css";
import {
  getworkflowType,
  setdashboardcheck,
  selectUser,
  gettabvalue,
  logout,
} from "../features/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectdashboard } from "../features/dashboardSlice";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { useStyles, customStyles } from "../../Utilities/CSSUtilities";
import Modal from "react-modal";
import callsaveauditapi from "../../services/auditservice";
import { checkworkloadpermission } from "../Permission/permission";
import sort from "./../images/sort.svg";
import calender from "./../../components/images/calender.png";
import AdmissionArchive from "./../Modal/Masters/AdmisisionArchive";
import { check1pointpermission } from "./../Permission/permission";
import { CSVLink, CSVDownload } from "react-csv";
import jsPDF from "jspdf";
import AdmissionViewMoreModal from "./../Modal/Masters/AdmissionViewMoreModal";
import DatePicker from "react-datepicker";
import { format } from "date-fns";

function AdmisisonWorkFlowLists(props) {
  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  const user = useSelector(selectUser);
  //console.log(user);
  const isw_user_role = localStorage.getItem("isw_user_role");
  const dispatch = useDispatch();
  const csvLink = useRef();
  const [deleteEnquiry, setDeleteEnquiry] = useState(null);
  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalIsOpen2, setIsOpen2] = React.useState(false);
  const [backdropopen, setbackdropopen] = useState(false);
  const navigate = useNavigate();
  const [sortState, setSortState] = useState("enquiry_id");
  const [direction, setdirection] = useState("DESC");
  const dashboardvalue = useSelector(selectdashboard);
  const [rows, setrows] = React.useState(50);
  const [pageno, setpageno] = useState(0);
  const [enquiryData, setEnquiry] = useState(null);
  const [display1, setdisplay] = useState("inline");
  const [paginationpageno, setpaginationpageno] = useState(1);
  const ipaddress = localStorage.getItem("AdminIpAddress");
  const isw_user_name = localStorage.getItem("isw_user_name");
  const isw_adminId = localStorage.getItem("isw_adminId");
  const isw_user_email = localStorage.getItem("isw_user_email");
  const [deletedUser, setdeletedUser] = useState();
  const [dateFilter, setDateFilter] = useState();
  const [totalUser, settotalUser] = useState();
  const [countnumber, setcountnumber] = useState();
  const [numberofElements, setnumberofElements] = useState();
  const [dataForDownload, setDataForDownload] = useState([]);
  const [bDownloadReady, setDownloadReady] = useState(false);
  const [selectedOffice, setselectedOffice] = useState(
    Number(user?.isw_user_role) === 1 || Number(user?.isw_user_role) === 6
      ? "all"
      : user?.isw_available_Office[0]?.officeId
  );

  let new_office_id;

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        navigate("/");
      }
    });
    const office_obj = [];
    for (let i = 0; i < user?.isw_available_Office?.length; i++) {
      const element = user?.isw_available_Office?.[i];
      //console.log(element);
      office_obj.push(element);
    }
    let new_id = Array.prototype.map
      .call(office_obj, function (item) {
        return item.officeId;
      })
      .join(",");
    //console.log("Line number 86", new_id);
    new_office_id = new_id;
    getPermissions();
  }, []);

  useEffect(() => {
    if (localStorage.getItem("admissionWorkflowPageNo")) {
      let currentPage = localStorage.getItem("admissionWorkflowPageNo");
      setpageno(currentPage);
      setpaginationpageno(Number(currentPage) + 1);
      getAllEnquiry(currentPage);
    } else {
      setpageno(0);
      setpaginationpageno(1);
      getAllEnquiry(0);
    }
  }, [rows]);

  useEffect(() => {
    if (csvLink && csvLink.current && bDownloadReady) {
      csvLink.current.link.click();
      setDownloadReady(false);
    }
  }, [bDownloadReady]);

  const handleAction = (actionType) => {
    setbackdropopen(true);
    if (actionType === "DOWNLOAD") {
      //console.log(jwtToken);
      setbackdropopen(true);
      axios
        .get(`${base_url.api2}/getEnquiryByWorkflowType`, {
          headers: {
            Authorization: jwtToken,
            workflowType: "Admission",
            currentStage: selectedCurrentStage,
            enquiryStatus: enquiryStatusData,
            officeId: selectedOfficeId,
            intake: selectedIntake,
            createdStartDate: createdStartDate,
            createdEndDate: createdToDate,
            institutionId: selectedProvider,
            query: searchData,
            columnName: sortState,
            direction: direction,
            page: Number(0),
            size: Number(pdflength),
          },
        })
        .then((response) => {
          setDataForDownload(response.data.data.content);
          setDownloadReady(true);
          setbackdropopen(false);
        })
        .catch((error) => {
          setDataForDownload();
          setDownloadReady(true);
          setbackdropopen(false);
          handle403error(error);
        });
    }
  };

  useEffect(() => {
    let width = document.querySelector("table")?.offsetWidth;
    //console.log(document.querySelector("table"));
    if (document.querySelector(".scroller")) {
      //console.log(document.querySelector("scroller"));
      document.querySelector(".scroller").style.width = `${width}px`;
    }
  }, [enquiryData]);

  let scrolling = false;
  const scrolldiv = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow .table-responsive");
    table.scrollTo(scroller.scrollLeft, 0);
  };

  const scrolltable = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow  .table-responsive");
    scroller.scrollTo(table.scrollLeft, 0);
  };

  const handleRows = (event) => {
    setrows(event.target.value);
  };

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);

    if (check1pointpermission(401, permission_obj, resource_obj)) {
      dispatch(setdashboardcheck(7));
      getCurrentStage(selectedOffice);
      getAllProvider(selectedOffice);
      getAllIntake(selectedOffice);
      getConsltant();
      getAllOffice();
      let headData = {
        Authorization: jwtToken,
        workflowType: "Admission",
        currentStage: selectedCurrentStage,
        enquiryStatus: enquiryStatusData,
        officeId: selectedOfficeId,
        intake: selectedIntake,
        createdStartDate: createdStartDate,
        createdEndDate: createdToDate,
        institutionId: selectedProvider,
        query: searchData,
        columnName: sortState,
        direction: direction,
        page: Number(pageno),
        size: Number(rows),
      };
    } else {
      navigate("/errorPage");
    }
  };

  const [allOffice, setOffice] = useState();
  const getAllOffice = () => {
    axios
      .get(`${base_url.api1}/findAllOffice`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        setOffice(response.data.data);
      })
      .catch((error) => {
        setOffice();
        //console.log("error-->", error);
        handle403error(error);
      });
  };

  const [assigneeData, setAssigneeData] = useState();
  const getConsltant = () => {
    axios
      .get(`${base_url.api2}/getUsersByRoleId`, {
        headers: {
          Authorization: jwtToken,
          roleId: 4,
          direction: "ASC",
          columnName: "firstName",
          status: 1,
        },
      })
      .then((res) => {
        let apiData = res.data.data;
        const consultant_obj = [];
        for (let i = 0; i < apiData.length; i++) {
          let data = {
            value: apiData[i].id,
            label: apiData[i].first_name + " " + apiData[i].last_name,
          };
          consultant_obj.push(data);
        }
        //console.log(consultant_obj);
        setAssigneeData(consultant_obj);
      })
      .catch((error) => {
        setAssigneeData();
        //console.log("error-->", error);
        handle403error(error);
      });
  };

  const [getpageno, setgetpageno] = useState();
  const handlePage = (event, value) => {
    setpaginationpageno(value);
    setpageno(value - 1);
    if (assigneeState) {
      let headData = {
        Authorization: jwtToken,
        workflowType: "Admission",
        assigneeId: selectedAssigneeData,
        currentStage: selectedCurrentStage,
        enquiryStatus: enquiryStatusData,
        officeId: selectedOfficeId,
        intake: selectedIntake,
        createdStartDate: createdStartDate,
        createdEndDate: createdToDate,
        institutionId: selectedProvider,
        query: searchData,
        columnName: sortState,
        direction: direction,
        page: Number(value - 1),
        size: Number(rows),
      };
      getAllData(headData);
    } else {
      let headData = {
        Authorization: jwtToken,
        workflowType: "Admission",
        currentStage: selectedCurrentStage,
        enquiryStatus: enquiryStatusData,
        officeId: selectedOfficeId,
        intake: selectedIntake,
        createdStartDate: createdStartDate,
        createdEndDate: createdToDate,
        institutionId: selectedProvider,
        query: searchData,
        columnName: sortState,
        direction: direction,
        page: Number(value - 1),
        size: Number(rows),
      };
      getAllData(headData);
    }
  };

  const [allCurrentStage, setallCurrentStage] = useState();
  const getCurrentStage = (selectedOffice) => {
    if (selectedOffice === "all" || selectedOffice == null) {
      axios
        .get(`${base_url.api2}/getByWorkflowTypeBasedOnCurrentStage`, {
          headers: {
            Authorization: jwtToken,
            workflowType: "Admission",
          },
        })
        .then((response) => {
          setallCurrentStage(response.data.data);
        })
        .catch((error) => {
          setallCurrentStage();
          //console.log(JSON.stringify(error.response.data.message));
          handle403error(error);
        });
    } else {
      axios
        .get(
          `${base_url.api2}/getByWorkflowTypeAndOfficeIdBasedOnCurrentStage`,
          {
            headers: {
              Authorization: jwtToken,
              workflowType: "Admission",
              officeId: Number(selectedOffice),
            },
          }
        )
        .then((response) => {
          setallCurrentStage(response.data.data);
        })
        .catch((error) => {
          setallCurrentStage();
          //console.log("error-->", error);
          handle403error(error);
        });
    }
  };

  const [allProvider, setAllProvider] = useState();
  const getAllProvider = (selectedOffice) => {
    if (selectedOffice === "all") {
      axios
        .get(`${base_url.api2}/getByWorkflowTypeBasedOnInstitution`, {
          headers: {
            Authorization: jwtToken,
            workflowType: "Admission",
          },
        })
        .then((response) => {
          setAllProvider(response.data.data);
        })
        .catch((error) => {
          setAllProvider([]);
          //console.log("error-->", error);
          handle403error(error);
        });
    } else {
      axios
        .get(
          `${base_url.api2}/getByWorkflowTypeAndOfficeIdBasedOnInstitution`,
          {
            headers: {
              Authorization: jwtToken,
              workflowType: "Admission",
              officeId: Number(selectedOffice),
            },
          }
        )
        .then((response) => {
          setAllProvider(response.data.data);
        })
        .catch((error) => {
          setAllProvider([]);
          //console.log("error-->", error);
          handle403error(error);
        });
    }
  };

  const [allIntake, setAllIntake] = useState();
  const getAllIntake = (selectedOffice) => {
    if (selectedOffice === "all") {
      axios
        .get(`${base_url.api2}/getByWorkflowTypeBasedOnIntake`, {
          headers: {
            Authorization: jwtToken,
            workflowType: "Admission",
          },
        })
        .then((response) => {
          let intakeData = response.data.data;
          let intakeObj = [];
          for (let i = 0; i < intakeData.length; i++) {
            let data = {
              name: moment(intakeData[i].intake).format("YYYY-MM"),
              val: moment(intakeData[i].intake).format("YYYYMM"),
              intake: intakeData[i].intake,
            };
            intakeObj.push(data);
          }
          let newintakeObj = intakeObj.sort(function (a, b) {
            return b.val - a.val;
          });
          setAllIntake(newintakeObj);
        })
        .catch((error) => {
          setAllIntake();
          //console.log("error-->", error);
          handle403error(error);
        });
    } else {
      axios
        .get(`${base_url.api2}/getByWorkflowTypeAndOfficeIdBasedOnIntake`, {
          headers: {
            Authorization: jwtToken,
            workflowType: "Admission",
            officeId: Number(selectedOffice),
          },
        })
        .then((response) => {
          let intakeData = response.data.data;
          let intakeObj = [];
          for (let i = 0; i < intakeData.length; i++) {
            let data = {
              name: moment(intakeData[i].intake).format("YYYY-MM"),
              val: moment(intakeData[i].intake).format("YYYYMM"),
              intake: intakeData[i].intake,
            };
            intakeObj.push(data);
          }
          let newintakeObj = intakeObj.sort(function (a, b) {
            return b.val - a.val;
          });
          setAllIntake(newintakeObj);
        })
        .catch((error) => {
          setAllIntake();
          //console.log("error-->", error);
          handle403error(error);
        });
    }
  };

  const getAllEnquiry = (value1) => {
    setbackdropopen(true);
    let office_id;
    if (selectedOfficeId) {
      office_id = selectedOfficeId;
    } else {
      const office_obj = [];
      for (let i = 0; i < user?.isw_available_Office?.length; i++) {
        const element = user?.isw_available_Office?.[i];
        //console.log(element);
        office_obj.push(element);
      }
      let new_id = Array.prototype.map
        .call(office_obj, function (item) {
          return item.officeId;
        })
        .join(",");
      office_id = new_id;
      setSelectedOfficeId(new_id);
    }
    if (
      Number(user?.isw_user_role) === 1 ||
      Number(user?.isw_user_role) === 6
    ) {
      if (value1 == parseInt(value1)) {
        // globalGetAllEnquiry(value1);
        if (assigneeState) {
          //console.log("coming--->innerif line NO 424");
          let headData = {
            Authorization: jwtToken,
            workflowType: "Admission",
            assigneeId: selectedAssigneeData,
            currentStage: selectedCurrentStage,
            enquiryStatus: enquiryStatusData,
            officeId: office_id,
            intake: selectedIntake,
            createdStartDate: createdStartDate,
            createdEndDate: createdToDate,
            institutionId: selectedProvider,
            query: searchData,
            columnName: sortState,
            direction: direction,
            page: Number(value1),
            size: Number(rows),
          };
          getAllData(headData);
        } else {
          //console.log("coming--->innerelse line NO 444");
          let headData = {
            Authorization: jwtToken,
            workflowType: "Admission",
            // assigneeId: null,
            currentStage: selectedCurrentStage,
            enquiryStatus: enquiryStatusData,
            officeId: office_id,
            intake: selectedIntake,
            createdStartDate: createdStartDate,
            createdEndDate: createdToDate,
            institutionId: selectedProvider,
            query: searchData,
            columnName: sortState,
            direction: direction,
            page: Number(value1),
            size: Number(rows),
          };
          getAllData(headData);
        }
      } else {
        // globalGetAllEnquiry(pageno);
        //console.log("coming--->outerinnerelse line NO 466");
        if (assigneeState) {
          let headData = {
            Authorization: jwtToken,
            workflowType: "Admission",
            assigneeId: selectedAssigneeData,
            currentStage: selectedCurrentStage,
            enquiryStatus: enquiryStatusData,
            officeId: office_id,
            intake: selectedIntake,
            createdStartDate: createdStartDate,
            createdEndDate: createdToDate,
            institutionId: selectedProvider,
            query: searchData,
            columnName: sortState,
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          };
          getAllData(headData);
        } else {
          //console.log("coming--->outerelse line NO 487");
          let headData = {
            Authorization: jwtToken,
            workflowType: "Admission",
            assigneeId: null,
            currentStage: selectedCurrentStage,
            enquiryStatus: enquiryStatusData,
            officeId: office_id,
            intake: selectedIntake,
            createdStartDate: createdStartDate,
            createdEndDate: createdToDate,
            institutionId: selectedProvider,
            query: searchData,
            columnName: sortState,
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          };
          getAllData(headData);
        }
      }
    } else {
      if (value1 == parseInt(value1)) {
        // globalGetAllEnquiryOfficeId(value1);
        //console.log("coming--->innerif line NO 511");
        if (assigneeState) {
          let headData = {
            Authorization: jwtToken,
            workflowType: "Admission",
            assigneeId: selectedAssigneeData,
            currentStage: selectedCurrentStage,
            enquiryStatus: enquiryStatusData,
            officeId: office_id,
            intake: selectedIntake,
            createdStartDate: createdStartDate,
            createdEndDate: createdToDate,
            institutionId: selectedProvider,
            query: searchData,
            columnName: sortState,
            direction: direction,
            page: Number(value1),
            size: Number(rows),
          };
          getAllData(headData);
        } else {
          //console.log("coming--->innerif line NO 523");
          let headData = {
            Authorization: jwtToken,
            workflowType: "Admission",
            //assigneeId: null,
            currentStage: selectedCurrentStage,
            enquiryStatus: enquiryStatusData,
            officeId: office_id,
            intake: selectedIntake,
            createdStartDate: createdStartDate,
            createdEndDate: createdToDate,
            institutionId: selectedProvider,
            query: searchData,
            columnName: sortState,
            direction: direction,
            page: Number(value1),
            size: Number(rows),
          };
          getAllData(headData);
        }
      } else {
        // globalGetAllEnquiryOfficeId(pageno);
        //console.log("coming--->innerif line NO 554");
        if (assigneeState) {
          let headData = {
            Authorization: jwtToken,
            workflowType: "Admission",
            assigneeId: selectedAssigneeData,
            currentStage: selectedCurrentStage,
            enquiryStatus: enquiryStatusData,
            officeId: office_id,
            intake: selectedIntake,
            createdStartDate: createdStartDate,
            createdEndDate: createdToDate,
            institutionId: selectedProvider,
            query: searchData,
            columnName: sortState,
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          };
          getAllData(headData);
        } else {
          let headData = {
            Authorization: jwtToken,
            workflowType: "Admission",
            assigneeId: null,
            currentStage: selectedCurrentStage,
            enquiryStatus: enquiryStatusData,
            officeId: office_id,
            intake: selectedIntake,
            createdStartDate: createdStartDate,
            createdEndDate: createdToDate,
            institutionId: selectedProvider,
            query: searchData,
            columnName: sortState,
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          };
          getAllData(headData);
        }
      }
    }
  };

  const classes = useStyles();

  const viewWorkflow = (p, e) => {
    localStorage.setItem("admissionWorkflowPageNo", pageno);
    localStorage.setItem("selected_client_username", p.clientUsername);
    localStorage.setItem("selected_client_id", p.clientId);
    localStorage.setItem("prev_path2", "/admission-enquiry");
    localStorage.setItem("prev_path", "/admission-enquiry");
    dispatch(getworkflowType(p.workflowType));
    dispatch(gettabvalue(1));
    if (!p.workflowId) {
      navigate(
        "/view-enquiry-workflowList/" + p.clientId + "/" + p.clientUsername
      );
    } else {
      navigate(
        "/view-enquiry-workflow/" +
          p.clientId +
          "/" +
          p.enquiryId +
          "/" +
          p.clientUsername
      );
    }
  };

  const [searchStatus, setSearchStatus] = useState();
  const [searchData, setSearchData] = useState(null);
  const submitSearch = (e) => {
    setbackdropopen(true);
    let srchdata = e.target[0].value.trim();
    setSearchData(srchdata);
    setpageno(0);
    setSearchStatus(true);

    if (assigneeState) {
      let headData = {
        Authorization: jwtToken,
        workflowType: "Admission",
        assigneeId: selectedAssigneeData,
        currentStage: selectedCurrentStage,
        enquiryStatus: enquiryStatusData,
        officeId: selectedOfficeId,
        intake: selectedIntake,
        createdStartDate: createdStartDate,
        createdEndDate: createdToDate,
        institutionId: selectedProvider,
        query: srchdata,
        columnName: sortState,
        direction: direction,
        page: Number(0),
        size: Number(rows),
      };
      getAllData(headData);
    } else {
      let headData = {
        Authorization: jwtToken,
        workflowType: "Admission",
        currentStage: selectedCurrentStage,
        enquiryStatus: enquiryStatusData,
        officeId: selectedOfficeId,
        intake: selectedIntake,
        createdStartDate: createdStartDate,
        createdEndDate: createdToDate,
        institutionId: selectedProvider,
        query: srchdata,
        columnName: sortState,
        direction: direction,
        page: Number(0),
        size: Number(rows),
      };
      getAllData(headData);
    }
  };
  const sortWorkflow = (e) => {
    setSortState(e.target.id);
    if (direction == "DESC") {
      setdirection("ASC");
    } else if (direction == "ASC") {
      setdirection("DESC");
    }
    // getAllEnquiry(pageno);
    if (isw_user_role == 1 || isw_user_role == 6) {
      // globalGetAllEnquiry(pageno, e.target.id);
      if (assigneeState) {
        let headData = {
          Authorization: jwtToken,
          workflowType: "Admission",
          assigneeId: selectedAssigneeData,
          currentStage: selectedCurrentStage,
          enquiryStatus: enquiryStatusData,
          officeId: selectedOfficeId,
          intake: selectedIntake,
          createdStartDate: createdStartDate,
          createdEndDate: createdToDate,
          institutionId: selectedProvider,
          query: searchData,
          columnName: e.target.id,
          direction: direction,
          page: Number(pageno),
          size: Number(rows),
        };
        getAllData(headData);
      } else {
        let headData = {
          Authorization: jwtToken,
          workflowType: "Admission",
          currentStage: selectedCurrentStage,
          enquiryStatus: enquiryStatusData,
          officeId: selectedOfficeId,
          intake: selectedIntake,
          createdStartDate: createdStartDate,
          createdEndDate: createdToDate,
          institutionId: selectedProvider,
          query: searchData,
          columnName: e.target.id,
          direction: direction,
          page: Number(pageno),
          size: Number(rows),
        };
        getAllData(headData);
      }
    } else {
      // globalGetAllEnquiryOfficeId(pageno, e.target.id);
      if (assigneeState) {
        let headData = {
          Authorization: jwtToken,
          workflowType: "Admission",
          assigneeId: selectedAssigneeData,
          currentStage: selectedCurrentStage,
          enquiryStatus: enquiryStatusData,
          officeId: selectedOfficeId,
          intake: selectedIntake,
          createdStartDate: createdStartDate,
          createdEndDate: createdToDate,
          institutionId: selectedProvider,
          query: searchData,
          columnName: e.target.id,
          direction: direction,
          page: Number(pageno),
          size: Number(rows),
        };
        getAllData(headData);
      } else {
        let headData = {
          Authorization: jwtToken,
          workflowType: "Admission",
          currentStage: selectedCurrentStage,
          enquiryStatus: enquiryStatusData,
          officeId: selectedOfficeId,
          intake: selectedIntake,
          createdStartDate: createdStartDate,
          createdEndDate: createdToDate,
          institutionId: selectedProvider,
          query: searchData,
          columnName: e.target.id,
          direction: direction,
          page: Number(pageno),
          size: Number(rows),
        };
        getAllData(headData);
      }
    }
  };

  const [selectedOfficeId, setSelectedOfficeId] = useState(null);
  const [officeSelected, setOfficeSelected] = useState(false);
  const handleChangeOffice = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("value");

    getCurrentStage(optionElementId);
    getAllProvider(optionElementId);
    getAllIntake(optionElementId);

    if (optionElementId == "all") {
      const office_obj = [];
      for (let i = 0; i < user?.isw_available_Office?.length; i++) {
        const element = user?.isw_available_Office?.[i];
        //console.log(element);
        office_obj.push(element);
      }
      let new_id = Array.prototype.map
        .call(office_obj, function (item) {
          return item.officeId;
        })
        .join(",");
      //console.log(new_id);
      setOfficeSelected(false);
      setSelectedOfficeId(new_id);
      setselectedOffice(null);
      if (assigneeState) {
        let headData = {
          Authorization: jwtToken,
          workflowType: "Admission",
          assigneeId: selectedAssigneeData,
          currentStage: selectedCurrentStage,
          enquiryStatus: enquiryStatusData,
          officeId: Array.prototype.map
            .call(office_obj, function (item) {
              return item.officeId;
            })
            .join(","),
          intake: selectedIntake,
          createdStartDate: createdStartDate,
          createdEndDate: createdToDate,
          institutionId: selectedProvider,
          query: searchData,
          columnName: sortState,
          direction: direction,
          page: Number(0),
          size: Number(rows),
        };
        getAllData(headData);
      } else {
        let headData = {
          Authorization: jwtToken,
          workflowType: "Admission",
          currentStage: selectedCurrentStage,
          enquiryStatus: enquiryStatusData,
          officeId: Array.prototype.map
            .call(office_obj, function (item) {
              return item.officeId;
            })
            .join(","),
          intake: selectedIntake,
          createdStartDate: createdStartDate,
          createdEndDate: createdToDate,
          institutionId: selectedProvider,
          query: searchData,
          columnName: sortState,
          direction: direction,
          page: Number(0),
          size: Number(rows),
        };
        getAllData(headData);
      }
    } else {
      setOfficeSelected(true);
      setSelectedOfficeId(optionElementId);
      setselectedOffice(optionElementId);
      //console.log(optionElementId);
      if (assigneeState) {
        let headData = {
          Authorization: jwtToken,
          workflowType: "Admission",
          assigneeId: selectedAssigneeData,
          currentStage: selectedCurrentStage,
          enquiryStatus: enquiryStatusData,
          officeId: optionElementId,
          intake: selectedIntake,
          createdStartDate: createdStartDate,
          createdEndDate: createdToDate,
          institutionId: selectedProvider,
          query: searchData,
          columnName: sortState,
          direction: direction,
          page: Number(0),
          size: Number(rows),
        };
        getAllData(headData);
      } else {
        let headData = {
          Authorization: jwtToken,
          workflowType: "Admission",
          currentStage: selectedCurrentStage,
          enquiryStatus: enquiryStatusData,
          officeId: optionElementId,
          intake: selectedIntake,
          createdStartDate: createdStartDate,
          createdEndDate: createdToDate,
          institutionId: selectedProvider,
          query: searchData,
          columnName: sortState,
          direction: direction,
          page: Number(0),
          size: Number(rows),
        };
        getAllData(headData);
      }
    }
  };

  //Filter based Pagination-----------------------------------
  const [selectedCurrentStage, setSelectedCurrentStage] = useState(null);
  const [currentStageState, setCurrentStageState] = useState();
  const handleChangeCurrentStage = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementName = optionElement.getAttribute("value");

    if (optionElementName == "") {
      setCurrentStageState(false);
      setSelectedCurrentStage(null);
      if (assigneeState) {
        let headData = {
          Authorization: jwtToken,
          workflowType: "Admission",
          assigneeId: selectedAssigneeData,
          enquiryStatus: enquiryStatusData,
          currentStage: null,
          officeId: selectedOfficeId,
          intake: selectedIntake,
          createdStartDate: createdStartDate,
          createdEndDate: createdToDate,
          institutionId: selectedProvider,
          query: searchData,
          columnName: sortState,
          direction: direction,
          page: Number(0),
          size: Number(rows),
        };
        getAllData(headData);
      } else {
        let headData = {
          Authorization: jwtToken,
          workflowType: "Admission",
          currentStage: null,
          officeId: selectedOfficeId,
          enquiryStatus: enquiryStatusData,
          intake: selectedIntake,
          createdStartDate: createdStartDate,
          createdEndDate: createdToDate,
          institutionId: selectedProvider,
          query: searchData,
          columnName: sortState,
          direction: direction,
          page: Number(0),
          size: Number(rows),
        };
        getAllData(headData);
      }
    } else {
      setCurrentStageState(true);
      setSelectedCurrentStage(optionElementName);
      if (assigneeState) {
        let headData = {
          Authorization: jwtToken,
          workflowType: "Admission",
          assigneeId: selectedAssigneeData,
          enquiryStatus: enquiryStatusData,
          currentStage: optionElementName,
          officeId: selectedOfficeId,
          intake: selectedIntake,
          createdStartDate: createdStartDate,
          createdEndDate: createdToDate,
          institutionId: selectedProvider,
          query: searchData,
          columnName: sortState,
          direction: direction,
          page: Number(0),
          size: Number(rows),
        };
        getAllData(headData);
      } else {
        let headData = {
          Authorization: jwtToken,
          workflowType: "Admission",
          currentStage: optionElementName,
          enquiryStatus: enquiryStatusData,
          officeId: selectedOfficeId,
          intake: selectedIntake,
          createdStartDate: createdStartDate,
          createdEndDate: createdToDate,
          institutionId: selectedProvider,
          query: searchData,
          columnName: sortState,
          direction: direction,
          page: Number(0),
          size: Number(rows),
        };
        getAllData(headData);
      }
    }
    // setFilterPagination("CurrentStage");

    // if (selectedOffice === "all" || selectedOffice == null) {
    //   if (optionElementName == "") {
    //     setFilterPaginationData(false);
    //     globalGetAllEnquiry(pageno);
    //   } else {
    //     setFilterPaginationData(true);
    //     globalGetEnquiryByCurrentStage(optionElementName, 0);
    //   }
    // } else {
    //   if (optionElementName == "") {
    //     setFilterPaginationData(false);
    //     globalGetAllEnquiryOfficeId(pageno);
    //   } else {
    //     setFilterPaginationData(true);
    //     globalGetEnquiryByCurrentStageOfficeId(optionElementName, 0);
    //   }
    // }
  };

  const [selectedProvider, setSelectedProvider] = useState(null);
  const [providerState, setProviderState] = useState();
  const handleChangeProvider = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("value");

    if (optionElementId == "") {
      setProviderState(false);
      setSelectedProvider(null);
      if (assigneeState) {
        let headData = {
          Authorization: jwtToken,
          workflowType: "Admission",
          assigneeId: selectedAssigneeData,
          currentStage: selectedCurrentStage,
          enquiryStatus: enquiryStatusData,
          officeId: selectedOfficeId,
          intake: selectedIntake,
          createdStartDate: createdStartDate,
          createdEndDate: createdToDate,
          institutionId: null,
          query: searchData,
          columnName: sortState,
          direction: direction,
          page: Number(0),
          size: Number(rows),
        };
        getAllData(headData);
      } else {
        let headData = {
          Authorization: jwtToken,
          workflowType: "Admission",
          currentStage: selectedCurrentStage,
          enquiryStatus: enquiryStatusData,
          officeId: selectedOfficeId,
          intake: selectedIntake,
          createdStartDate: createdStartDate,
          createdEndDate: createdToDate,
          institutionId: null,
          query: searchData,
          columnName: sortState,
          direction: direction,
          page: Number(0),
          size: Number(rows),
        };
        getAllData(headData);
      }
    } else {
      setProviderState(true);
      setSelectedProvider(optionElementId);
      if (assigneeState) {
        let headData = {
          Authorization: jwtToken,
          workflowType: "Admission",
          assigneeId: selectedAssigneeData,
          currentStage: selectedCurrentStage,
          enquiryStatus: enquiryStatusData,
          officeId: selectedOfficeId,
          intake: selectedIntake,
          createdStartDate: createdStartDate,
          createdEndDate: createdToDate,
          institutionId: optionElementId,
          query: searchData,
          columnName: sortState,
          direction: direction,
          page: Number(0),
          size: Number(rows),
        };
        getAllData(headData);
      } else {
        let headData = {
          Authorization: jwtToken,
          workflowType: "Admission",
          currentStage: selectedCurrentStage,
          enquiryStatus: enquiryStatusData,
          officeId: selectedOfficeId,
          intake: selectedIntake,
          createdStartDate: createdStartDate,
          createdEndDate: createdToDate,
          institutionId: optionElementId,
          query: searchData,
          columnName: sortState,
          direction: direction,
          page: Number(0),
          size: Number(rows),
        };
        getAllData(headData);
      }
    }
    // setFilterPagination("ChangeProvider");

    // if (selectedOffice === "all" || selectedOffice == null) {
    //   if (optionElementId == "") {
    //     setFilterPaginationData(false);
    //     globalGetAllEnquiry(pageno);
    //   } else {
    //     setFilterPaginationData(true);
    //     globalGetEnquiryByInstitutionId(optionElementId, 0);
    //   }
    // } else {
    //   if (optionElementId == "") {
    //     setFilterPaginationData(false);
    //     globalGetAllEnquiryOfficeId(pageno, sortState, selectedOffice);
    //   } else {
    //     setFilterPaginationData(true);
    //     globalGetEnquiryByInstitutionIdofficeId(optionElementId, 0);
    //   }
    // }
  };

  const [selectedIntake, setSelectedIntake] = useState(null);
  const [intakeState, setIntakeState] = useState();
  const handleChangeIntake = (date) => {
    const intakeDate = date ? format(date, "MMM-yyyy") : null;
    setbackdropopen(true);

    setIntakeState(true);
    if (assigneeState) {
      let headData = {
        Authorization: jwtToken,
        workflowType: "Admission",
        assigneeId: selectedAssigneeData,
        currentStage: selectedCurrentStage,
        enquiryStatus: enquiryStatusData,
        officeId: selectedOfficeId,
        intake: intakeDate,
        createdStartDate: createdStartDate,
        createdEndDate: createdToDate,
        institutionId: selectedProvider,
        query: searchData,
        columnName: sortState,
        direction: direction,
        page: Number(0),
        size: Number(rows),
      };
      getAllData(headData);
    } else {
      let headData = {
        Authorization: jwtToken,
        workflowType: "Admission",
        currentStage: selectedCurrentStage,
        enquiryStatus: enquiryStatusData,
        officeId: selectedOfficeId,
        intake: intakeDate,
        createdStartDate: createdStartDate,
        createdEndDate: createdToDate,
        institutionId: selectedProvider,
        query: searchData,
        columnName: sortState,
        direction: direction,
        page: Number(0),
        size: Number(rows),
      };
      getAllData(headData);
    }

    // setFilterPagination("ChangeIntake");

    // if (isw_user_role == 1 || isw_user_role == 6) {
    //   if (optionElementName == "") {
    //     setFilterPaginationData(false);
    //     globalGetAllEnquiry(pageno);
    //   } else {
    //     setFilterPaginationData(true);
    //     globalGetEnquiryByIntake(optionElementName, 0);
    //   }
    // } else {
    //   if (optionElementName == "") {
    //     setFilterPaginationData(false);
    //     globalGetAllEnquiryOfficeId(pageno, sortState, selectedOffice);
    //   } else {
    //     setFilterPaginationData(true);
    //     globalGetEnquiryByIntakeOfficeId(optionElementName, 0);
    //   }
    // }
  };

  const [enquiryStatusState, setEnquiryStatusState] = useState();
  const [enquiryStatusData, setEnquiryStatusData] = useState(null);
  const handleChangeEnquiryStatus = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("value");
    if (optionElementId == "") {
      setEnquiryStatusData(null);
      setEnquiryStatusState(false);
      if (assigneeState) {
        let headData = {
          Authorization: jwtToken,
          workflowType: "Admission",
          assigneeId: selectedAssigneeData,
          enquiryStatus: null,
          currentStage: selectedCurrentStage,
          officeId: selectedOfficeId,
          intake: selectedIntake,
          createdStartDate: createdStartDate,
          createdEndDate: createdToDate,
          institutionId: selectedProvider,
          query: searchData,
          columnName: sortState,
          direction: direction,
          page: Number(0),
          size: Number(rows),
        };
        getAllData(headData);
      } else {
        let headData = {
          Authorization: jwtToken,
          workflowType: "Admission",
          enquiryStatus: null,
          currentStage: selectedCurrentStage,
          officeId: selectedOfficeId,
          intake: selectedIntake,
          createdStartDate: createdStartDate,
          createdEndDate: createdToDate,
          institutionId: selectedProvider,
          query: searchData,
          columnName: sortState,
          direction: direction,
          page: Number(0),
          size: Number(rows),
        };
        getAllData(headData);
      }
    } else {
      setEnquiryStatusData(optionElementId);
      setEnquiryStatusState(true);
      if (assigneeState) {
        let headData = {
          Authorization: jwtToken,
          workflowType: "Admission",
          assigneeId: selectedAssigneeData,
          enquiryStatus: optionElementId,
          currentStage: selectedCurrentStage,
          officeId: selectedOfficeId,
          intake: selectedIntake,
          createdStartDate: createdStartDate,
          createdEndDate: createdToDate,
          institutionId: selectedProvider,
          query: searchData,
          columnName: sortState,
          direction: direction,
          page: Number(0),
          size: Number(rows),
        };
        getAllData(headData);
      } else {
        let headData = {
          Authorization: jwtToken,
          workflowType: "Admission",
          enquiryStatus: optionElementId,
          currentStage: selectedCurrentStage,
          officeId: selectedOfficeId,
          intake: selectedIntake,
          createdStartDate: createdStartDate,
          createdEndDate: createdToDate,
          institutionId: selectedProvider,
          query: searchData,
          columnName: sortState,
          direction: direction,
          page: Number(0),
          size: Number(rows),
        };
        getAllData(headData);
      }
    }
  };

  const [datesRange, setDatesRange] = useState();
  const [createdStartDate, setCreatedStartDate] = useState(null);
  const [createdToDate, setCreatedEndDate] = useState(null);

  const [visaexpirydatevalue, setvisaexpirydatevalue] =
    useState("Click to open");
  const handlevisaexpirydateApply = (event, picker) => {
    setbackdropopen(true);
    setvisaexpirydatevalue(
      `${picker.startDate.format("DD-MM-YYYY")} to ${picker.endDate.format(
        "DD-MM-YYYY"
      )}`
    );
    setpageno(0);
    setDateFilter(true);
    //console.log(event, picker);
    let data = {
      visaStartDate: picker.startDate.format("YYYY-MM-DD"),
      visaEndDate: picker.endDate.format("YYYY-MM-DD"),
    };
    setDatesRange(data);
    setpageno(0);

    if (assigneeState) {
      let headData = {
        Authorization: jwtToken,
        workflowType: "Admission",
        assigneeId: selectedAssigneeData,
        currentStage: selectedCurrentStage,
        enquiryStatus: enquiryStatusData,
        officeId: selectedOfficeId,
        intake: selectedIntake,
        visaStartDate: picker.startDate.format("YYYY-MM-DD"),
        visaEndDate: picker.endDate.format("YYYY-MM-DD"),
        institutionId: selectedProvider,
        query: searchData,
        columnName: sortState,
        direction: direction,
        page: Number(0),
        size: Number(rows),
      };
      getAllData(headData);
    } else {
      let headData = {
        Authorization: jwtToken,
        workflowType: "Admission",
        currentStage: selectedCurrentStage,
        enquiryStatus: enquiryStatusData,
        officeId: selectedOfficeId,
        intake: selectedIntake,
        visaStartDate: picker.startDate.format("YYYY-MM-DD"),
        visaEndDate: picker.endDate.format("YYYY-MM-DD"),
        institutionId: selectedProvider,
        query: searchData,
        columnName: sortState,
        direction: direction,
        page: Number(0),
        size: Number(rows),
      };
      getAllData(headData);
    }
  };

  const [dateValue, setDateValue] = useState("dd-mm-yy");
  const handleApply = (event, picker) => {
    setbackdropopen(true);
    setDateValue(
      `${picker.startDate.format("DD-MM-YYYY")} to ${picker.endDate.format(
        "DD-MM-YYYY"
      )}`
    );
    setpageno(0);
    setDateFilter(true);
    //console.log(event, picker);
    let data = {
      createdStartDate: picker.startDate.format("YYYY-MM-DDT00:00:00"),
      createdEndDate: picker.endDate.format("YYYY-MM-DDT23:59:59"),
    };
    setCreatedStartDate(picker.startDate.format("YYYY-MM-DDT00:00:00"));
    setCreatedEndDate(picker.endDate.format("YYYY-MM-DDT23:59:59"));
    setDatesRange(data);
    setpageno(0);

    if (assigneeState) {
      let headData = {
        Authorization: jwtToken,
        workflowType: "Admission",
        assigneeId: selectedAssigneeData,
        currentStage: selectedCurrentStage,
        enquiryStatus: enquiryStatusData,
        officeId: selectedOfficeId,
        intake: selectedIntake,
        createdStartDate: picker.startDate.format("YYYY-MM-DD"),
        createdEndDate: picker.endDate.format("YYYY-MM-DD"),
        institutionId: selectedProvider,
        query: searchData,
        columnName: sortState,
        direction: direction,
        page: Number(0),
        size: Number(rows),
      };
      getAllData(headData);
    } else {
      let headData = {
        Authorization: jwtToken,
        workflowType: "Admission",
        currentStage: selectedCurrentStage,
        enquiryStatus: enquiryStatusData,
        officeId: selectedOfficeId,
        intake: selectedIntake,
        createdStartDate: picker.startDate.format("YYYY-MM-DD"),
        createdEndDate: picker.endDate.format("YYYY-MM-DD"),
        institutionId: selectedProvider,
        query: searchData,
        columnName: sortState,
        direction: direction,
        page: Number(0),
        size: Number(rows),
      };
      getAllData(headData);
    }
  };

  const [assigneeState, setAssigneeState] = useState();
  const [selectedAssigneeData, setSelectedAssigneeData] = useState(null);
  const onSelectWorkload = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementName = optionElement.getAttribute("value");
    //console.log(optionElementName);
    const consultant_obj = [];
    // for (let i = 0; i < selectedList.length; i++) {
    //   consultant_obj.push(selectedList[i].value);
    // }
    //console.log("Assignee id --", consultant_obj);
    if (consultant_obj.length > 0) {
      setSelectedAssigneeData(optionElementName);
      setAssigneeState(true);

      let headData = {
        Authorization: jwtToken,
        workflowType: "Admission",
        assigneeId: optionElementName,
        currentStage: selectedCurrentStage,
        enquiryStatus: enquiryStatusData,
        officeId: selectedOfficeId,
        intake: selectedIntake,
        createdStartDate: createdStartDate,
        createdEndDate: createdToDate,
        institutionId: selectedProvider,
        query: searchData,
        columnName: sortState,
        direction: direction,
        page: Number(0),
        size: Number(rows),
      };
      getAllData(headData);
    } else {
      setAssigneeState(false);
      let headData = {
        Authorization: jwtToken,
        workflowType: "Admission",
        assigneeId: optionElementName,
        currentStage: selectedCurrentStage,
        enquiryStatus: enquiryStatusData,
        officeId: selectedOfficeId,
        intake: selectedIntake,
        createdStartDate: createdStartDate,
        createdEndDate: createdToDate,
        institutionId: selectedProvider,
        query: searchData,
        columnName: sortState,
        direction: direction,
        page: Number(0),
        size: Number(rows),
      };
      getAllData(headData);
    }
  };

  const openModal = (elem, p) => {
    setdeletedUser(p.clientUsername);
    setDeleteEnquiry(elem.target.id);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen2(false);
    setIsOpen(false);
  };

  const deleteenquiry = (elem) => {
    //console.log("Page", pageno);
    axios
      .delete(`${base_url.api2}/deleteEnquiryById`, {
        headers: {
          Authorization: jwtToken,
          enquiryId: deleteEnquiry,
        },
      })
      .then((response) => {
        toast.success(response.data.data.message);
        let logs = isw_user_name + " Archived Enquiry of " + deletedUser;
        callsaveauditapi(logs, dispatch, navigate);
        getAllEnquiry(pageno);
        setIsOpen(false);
      })
      .catch((error) => {
        toast.error(JSON.stringify(error.response.data.message));
        //console.log("error-->", error);
        handle403error(error);
      });
  };

  const [pdflength, setpdflength] = useState();
  const [pdfHeader, setPdfHeader] = useState();
  const [pdfRow, setPdfRow] = useState([]);

  const getAllData = (headData) => {
    //console.log(headData);
    setbackdropopen(true);
    axios
      .get(`${base_url.api2}/getEnquiryByWorkflowType`, {
        headers: headData,
      })
      .then((response) => {
        setgetpageno(response.data.data.totalPages);
        settotalUser(response.data.data.totalElements);
        headData.size = response.data.data.totalElements;
        if (response.data.data.totalElements == 0) {
          headData.size = Number(50);
          setpdflength(50);
        } else {
          // setpdflength(response.data.data.totalElements);
          setpdflength(rows);
        }
        headData.size = Number(50);
        getPdfData(headData);
        setPdfHeader(headData);
        setcountnumber(response.data.data.number);
        setnumberofElements(response.data.data.numberOfElements);
        setEnquiry(response.data.data.content);
        setbackdropopen(false);
      })
      .catch((error) => {
        setgetpageno(0);
        settotalUser(0);
        setcountnumber(0);
        setnumberofElements(0);
        setEnquiry();
        setbackdropopen(false);
        //console.log("error-->", error);
        handle403error(error);
      });
  };

  const getPdfData = (headData) => {
    //console.log("1635------------>", headData);
    setbackdropopen(true);
    axios
      .get(`${base_url.api2}/getEnquiryByWorkflowType`, {
        headers: headData,
      })
      .then((response) => {
        setPdfRow(response.data.data.content);
        setbackdropopen(false);
      })
      .catch((error) => {
        setPdfRow();
        setbackdropopen(false);
        //console.log("error-->", error);
        handle403error(error);
      });
  };

  const exportPdfLeadList = () => {
    let headData = {
      Authorization: jwtToken,
      workflowType: "Admission",
      currentStage: selectedCurrentStage,
      enquiryStatus: enquiryStatusData,
      officeId: selectedOfficeId,
      intake: selectedIntake,
      createdStartDate: createdStartDate,
      createdEndDate: createdToDate,
      institutionId: selectedProvider,
      query: searchData,
      columnName: sortState,
      direction: direction,
      page: Number(0),
      size: Number(pdflength),
    };
    setbackdropopen(true);
    //console.log(headData);
    axios
      .get(`${base_url.api2}/getEnquiryByWorkflowType`, {
        headers: headData,
      })
      .then((response) => {
        setPdfRow(response.data.data.content);
        const doc = new jsPDF();
        // autoTable(doc, { html: '#myTable' })
        var col = [
          "Client Name",
          "Source",
          "Status",
          "Current Stage",
          "Institution Name",
          "Course",
          "Counsellor",
        ];
        var rows = [];
        response.data.data.content?.forEach((element) => {
          var temp = [
            element.clientName + "\n" + element.clientUsername,
            element.enquirySource,
            element.enquiryStatus,
            element.currentStage,
            element.institutionName,
            element.courseName,
            element.consultantName,
          ];
          rows.push(temp);
        });
        doc.autoTable(col, rows, { pageBreak: "auto" });
        // doc.autoTable(col, rows, { pageBreak: 'auto' });
        doc.save("AdmissionWorkflow_list.pdf");
        setbackdropopen(false);
      })
      .catch((error) => {
        setPdfRow();
        setbackdropopen(false);
        //console.log("error-->", error);
        handle403error(error);
      });
  };

  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            navigate("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };
  const customStyles = {
    control: (provided, state) => ({ ...provided, minHeight: "16px" }),
  };

  const [hover, setHover] = useState(false); // initial false

  const onHover = (e) => {
    e.preventDefault();
    setHover(true); // turn true
    //console.log("hovered");
  };

  const onHoverOver = (e) => {
    e.preventDefault(); // turn false
    setHover(false);
  };

  return (
    <div>
      <ToastContainer />
      <Backdrop className={classes.backdrop} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* <div className="commoncomponent_cardcss"> */}
      <div style={{ marginTop: "-10px" }}>
        {/* <Row>
            <Col md={6}>
              <CardText
                className={classes.headtext}
                style={{ marginTop: "-10px" }}
              >
                <strong>Admission Followup sheet</strong>
              </CardText>
            </Col>
          </Row> */}
        {/* <div
          class="page-head d-flex justify-content-between p-4"
          style={{ marginTop: "5rem", marginBottom: "-12px" }}
        >
          <ul class="breadcrums">
            <h3 class="page-title bold">Admission Followup Sheet</h3>
            <Link to="/" class="b-link">
              Home
            </Link>
             <span className="s_span ">&raquo;</span><a class="b-link">Workload</a>
             <span className="s_span ">&raquo;</span>
            <Link to="/admission-enquiry" class="b-link active">
              Admission
            </Link>
          </ul>
        </div> */}
        <div class="page-head d-flex justify-content-between p-4">
          <ul class="breadcrums">
            <h3 class="page-title bold">Admission Followup Sheet</h3>
            <Link to="/" class="b-link">
              Home
            </Link>
            <span className="s_span ">&raquo;</span>
            <a class="b-link">Workload</a>
            <span className="s_span ">&raquo;</span>
            <Link to="/admission-enquiry" class="b-link active">
              Admission
            </Link>
          </ul>
        </div>
        <div
          class="card p-4"
          style={{
            padding: "1.5rem",
            borderRight: "1.5rem solid #e9ebf2",
            borderLeft: "1.5rem solid #e9ebf2",
            borderTop: "1.5rem solid #e9ebf2",
          }}
        >
          <div class="form-grp row">
            <div class="input-grp col-md-2">
              <label for="" className="s_label_color">
                Limit
              </label>
              <select
                className="form-control"
                name="branch"
                value={rows}
                onChange={handleRows}
                style={{ width: "95%", backgroundColor: "#f5f5f9" }}
                class="form-select"
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
            <div class="input-grp col-md-2" style={{ marginTop: "10px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <label
                  className="area_rowslable_my"
                  style={{ fontWeight: "bold" }}
                >
                  Created Date
                </label>
                <div
                  className="clearsearchclass"
                  style={{ width: "100%", marginTop: "0px" }}
                >
                  <form className="d-flex" style={{ position: "relative" }}>
                    <DateRangePicker onApply={handleApply}>
                      <p
                        className="datepickerrangevaluetextone"
                        style={{
                          backgroundColor: "#f5f5f9",
                          width: "95%",
                          position: "relative",
                        }}
                      >
                        {dateValue}
                      </p>
                    </DateRangePicker>
                    {/* {dateFilter ? (
                      <div style={{ float: "left", width: "95%" }} className="s_datefilter">
                        <a
                          className={classes.clrsrch}
                          onClick={() => {
                            setDateValue("Click to open");
                            setdisplay("inline");
                            setdirection("DESC");
                            setDateFilter(false);
                            let headData = {
                              Authorization: jwtToken,
                              workflowType: "Admission",
                              assigneeId: selectedAssigneeData,
                              currentStage: selectedCurrentStage,
                              enquiryStatus: enquiryStatusData,
                              officeId: selectedOfficeId,
                              intake: selectedIntake,
                              createdStartDate: null,
                              createdEndDate: null,
                              institutionId: selectedProvider,
                              query: searchData,
                              columnName: sortState,
                              direction: direction,
                              page: Number(0),
                              size: Number(rows),
                            };
                            getAllData(headData);
                          }}
                        >
                          Clear Search
                        </a>
                      </div>
                    ) : null} */}
                    <span
                      className="listpagesearch_button"
                      style={{
                        top: "10px",
                        position: "absolute",
                        right: "20px",
                        pointerEvents: "none",
                      }}
                    >
                      <i className="fa fa-calendar"></i>
                    </span>
                  </form>
                </div>
              </div>
            </div>
            <div class="input-grp col-md-2">
              <label className="s_label_color">Branch</label>
              <select
                className="s_form-control"
                onChange={handleChangeOffice}
                style={{ width: "95%", backgroundColor: "#f5f5f9" }}
                name="branch"
              >
                {/* <option value="">- Select Branch -</option> */}
                {Number(user?.isw_user_role) === 1 ||
                Number(user?.isw_user_role) === 6 ||
                Number(user?.isw_user_role) === 10 ||
                Number(user?.isw_user_role) === 3 ||
                Number(user?.isw_user_role) === 2 ||
                Number(user?.isw_user_role) === 5 ||
                Number(user?.isw_user_role) === 4 ? (
                  <option value="all">Show All</option>
                ) : null}
                {user?.isw_available_Office?.map((p, index) => (
                  <option key={index} id={p.officeName} value={p.officeId}>
                    {p.officeName}
                    {" - "}
                    {p.officeCode}
                  </option>
                ))}
              </select>
            </div>
            <div class="input-grp col-md-2">
              <label className="s_label_color">Consultant</label>
              <select
                className="s_form-control"
                onChange={onSelectWorkload}
                name="assigneeId"
                style={{ width: "95%", backgroundColor: "#f5f5f9" }}
              >
                <option value="null">Select Consultant</option>
                {assigneeData?.map((p, index) => (
                  <option key={index} value={p.value}>
                    {p.label}
                  </option>
                ))}
              </select>
            </div>
            <div class="input-grp col-md-2">
              <label className="s_label_color">Application Status</label>
              <select
                className="s_form-control"
                name="subagent"
                onChange={handleChangeEnquiryStatus}
                style={{ width: "95%", backgroundColor: "#f5f5f9" }}
              >
                <option value="">- Select Application Status -</option>
                <option value="Enquiry">Application Processing</option>
                <option value="Processing">Processing</option>
                <option value="OnGoing">OnGoing</option>
                <option value="Completed">Completed</option>
                <option value="RefundProcessing">Refund Processing</option>
                {/* <option value="Discontinued">Discontinued</option> */}
                <option value="DiscontinueProcessing">
                  Discontinue Processing
                </option>
                <option value="DiscontinueComplete">
                  Discontinue Complete
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-2"></div>
          <div class="form-grp row pt-3" style={{ marginTop: "-20px" }}>
            {/* <Col md={2}>
                <label className="area_rowslable">Provider</label>
                <select
                  className="s_form-control"
                  onChange={handleChangeProvider}
                  name="institutionId"
                  style={{ backgroundColor: "#f5f5f9", width: "207.64px" }}
                >
                  <option value="">Select Provider</option>
                  {allProvider?.map((p, index) => (
                    <option key={index} value={p.institutionId}>
                      {p.institutionName}
                    </option>
                  ))}
                </select>
              </Col> */}
            <div class="input-grp col-md-2">
              <label className="s_label_color">Current Status</label>
              <select
                className="s_form-control"
                onChange={handleChangeCurrentStage}
                name="currentStage"
                style={{ width: "95%", backgroundColor: "#f5f5f9" }}
              >
                <option value="">Select Current Stage</option>
                {allCurrentStage?.map((p, index) => (
                  <option key={index} value={p.currentStage}>
                    {p.currentStage}
                  </option>
                ))}
              </select>
            </div>

            <div class="input-grp col-md-2">
              <label className="s_label_color">Provider</label>
              <select
                className="s_form-control"
                onChange={handleChangeProvider}
                name="institutionId"
                style={{ width: "95%", backgroundColor: "#f5f5f9" }}
              >
                <option value="">Select Provider</option>
                {allProvider?.map((p, index) => (
                  <option key={index} value={p.institutionId}>
                    {p.institutionName}
                  </option>
                ))}
              </select>
            </div>
            <div class="input-grp col-md-2" style={{ marginTop: "10px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <label
                  className="area_rowslable_my"
                  style={{ fontWeight: "bold" }}
                >
                  Visa Expiry Date
                </label>
                <div
                  className="clearsearchclass"
                  style={{ width: "100%", marginTop: "0px" }}
                >
                  <form
                    // id="myForm"
                    className="d-flex"
                    style={{ position: "relative" }}
                  >
                    <DateRangePicker onApply={handlevisaexpirydateApply}>
                      <p
                        className="datepickerrangevaluetextone"
                        style={{
                          backgroundColor: "#f5f5f9",
                          width: "95%",
                          position: "relative",
                        }}
                      >
                        {visaexpirydatevalue}
                      </p>
                    </DateRangePicker>
                    <span
                      className="listpagesearch_button"
                      style={{
                        top: "10px",
                        position: "absolute",
                        right: "20px",
                        pointerEvents: "none",
                      }}
                    >
                      <i className="fa fa-calendar"></i>
                    </span>
                  </form>
                </div>
              </div>
            </div>
            <div class="input-grp col-md-2">
              <label className="s_label_color">Intake</label>
              {/* <select
                className="s_form-control"
                onChange={handleChangeIntake}
                name="intake"
                style={{ width: "95%", backgroundColor: "#f5f5f9" }}
              >
                <option value="">Select Intake</option>
                {allIntake?.map((p, index) => (
                  <option key={index} value={p.intake}>
                    {p.name}
                  </option>
                ))}
              </select> */}
              <DatePicker
                selected={selectedIntake ? new Date(selectedIntake) : null}
                onChange={(date) => {
                  setSelectedIntake(date);
                  handleChangeIntake(date);
                }}
                dateFormat="MMM-yyyy" // Format for display
                showMonthYearPicker
                dropdownMode="select"
                placeholderText="Select Date"
                style={{ width: "95%", backgroundColor: "#f5f5f9" }}
              />
            </div>
            <div class="input-grp col-md-2">
              <div
                className="clearsearchclass"
                style={{ width: "95%", marginTop: "35px" }}
              >
                <form
                  id="myForm"
                  className="d-flex"
                  onSubmit={(e) => {
                    e.preventDefault();
                    submitSearch(e);
                  }}
                >
                  <input
                    className="form-control"
                    placeholder="Search.."
                    name="search"
                  />
                  <button className="btn listpagesearch_button" type="submit">
                    <i className="fa fa-search"></i>
                  </button>
                </form>
              </div>
              {searchStatus ? (
                <a
                  className={classes.clrsrch}
                  onClick={() => {
                    try {
                      document.getElementById("myForm").reset();
                    } catch (error) {
                      //console.log(error);
                    }
                    setdisplay("inline");
                    setSearchData(null);
                    setSearchStatus(false);
                    setpageno(0);
                    let headData = {
                      Authorization: jwtToken,
                      workflowType: "Admission",
                      assigneeId: selectedAssigneeData,
                      currentStage: selectedCurrentStage,
                      enquiryStatus: enquiryStatusData,
                      officeId: selectedOfficeId,
                      intake: selectedIntake,
                      createdStartDate: createdStartDate,
                      createdEndDate: createdToDate,
                      institutionId: selectedProvider,
                      query: searchData,
                      columnName: sortState,
                      direction: direction,
                      page: Number(0),
                      size: Number(rows),
                    };
                    getAllData(headData);
                  }}
                >
                  Clear Search
                </a>
              ) : null}
            </div>
          </div>
        </div>
        <div
          className="s_newlisting_headertabdesign"
          style={{
            padding: "1.5rem",
            border: "1.5rem solid #e9ebf2",
            marginTop: "0",
            marginBottom: "0",
          }}
        >
          <Row className="s_pagi">
            <Col md={6}>
              {enquiryData?.length ? (
                <div className="s_para" style={{ marginBottom: "15px" }}>
                  {countnumber * rows + 1} to{" "}
                  {countnumber * rows + numberofElements} of {totalUser} records
                </div>
              ) : (
                <div className="s_para" style={{ marginBottom: "15px" }}>
                  No Record Found
                </div>
              )}
            </Col>
            {check1pointpermission(405, permissionData, rolepermission) ? (
              <Col md={6} style={{ marginTop: "10px" }}>
                <div style={{ display: "flex", justifyContent: "right" }}>
                  <button
                    className="btn listing_addbutton"
                    onClick={exportPdfLeadList}
                  >
                    Export PDF
                  </button>

                  <button
                    type="button"
                    className="btn listing_addbutton"
                    style={{ marginLeft: "25px" }}
                    onClick={(e) => handleAction("DOWNLOAD")}
                  >
                    Export CSV
                  </button>
                  <CSVLink
                    data={dataForDownload}
                    filename="AdmissionWorkflow_list.csv"
                    className="hidden"
                    ref={csvLink}
                    target="_blank"
                  />
                </div>
              </Col>
            ) : null}
          </Row>
          <div className="above-scroller" onScroll={scrolldiv}>
            <div className="scroller"></div>
          </div>
          <div className="displaytablerow s_table">
            <Table
              className=" workload card-table table-borderless"
              // style={{ width: "100%" }}
              responsive
              striped
              onScroll={scrolltable}
            >
              <thead className="s_sticky">
                <tr style={{ color: "#6d7290" }}>
                  <th style={{ width: "60px", textAlign: "center" }}>SN</th>
                  <th style={{ minWidth: "95px" }}>
                    DATE
                    <img
                      src={sort}
                      className="sorting"
                      id="client_name"
                      onClick={sortWorkflow}
                      style={{
                        cursor: "pointer",
                        display: `${display1}`,
                        height: "10px",
                      }}
                    />
                  </th>
                  <th style={{ minWidth: "120px" }}>
                    CLIENT {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="client_name"
                      onClick={sortWorkflow}
                      style={{
                        cursor: "pointer",
                        display: `${display1}`,
                        height: "10px",
                      }}
                    />
                  </th>
                  <th style={{ minWidth: "90px" }}>
                    CONTACT {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="client_name"
                      onClick={sortWorkflow}
                      style={{
                        cursor: "pointer",
                        display: `${display1}`,
                        height: "10px",
                      }}
                    />
                  </th>
                  <th style={{ minWidth: "100px" }}>
                    SOURCE {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="enquiry_source"
                      onClick={sortWorkflow}
                      style={{
                        cursor: "pointer",
                        display: `${display1}`,
                        height: "10px",
                      }}
                    />
                  </th>
                  <th style={{ minWidth: "80px" }}>
                    STATUS {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="enquiry_status"
                      onClick={sortWorkflow}
                      style={{
                        cursor: "pointer",
                        display: `${display1}`,
                        height: "10px",
                      }}
                    />
                  </th>
                  <th style={{ minWidth: "125px" }}>
                    CURRENT STAGE {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="current_stage"
                      onClick={sortWorkflow}
                      style={{
                        cursor: "pointer",
                        display: `${display1}`,
                        height: "10px",
                      }}
                    />
                  </th>
                  <th style={{ minWidth: "120px" }}>
                    PROVIDER {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="institution_name"
                      onClick={sortWorkflow}
                      style={{
                        cursor: "pointer",
                        display: `${display1}`,
                        height: "10px",
                      }}
                    />
                  </th>
                  <th style={{ minWidth: "120px" }}>
                    COURSE {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="course_name"
                      onClick={sortWorkflow}
                      style={{
                        cursor: "pointer",
                        display: `${display1}`,
                        height: "10px",
                      }}
                    />
                  </th>
                  <th style={{ minWidth: "100px" }}>
                    INTAKE {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="intake"
                      onClick={sortWorkflow}
                      style={{
                        cursor: "pointer",
                        display: `${display1}`,
                        height: "10px",
                      }}
                    />
                  </th>
                  <th style={{ minWidth: "90px" }}>
                    BRANCH {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="office_name"
                      onClick={sortWorkflow}
                      style={{
                        cursor: "pointer",
                        display: `${display1}`,
                        height: "10px",
                      }}
                    />
                  </th>
                  <th style={{ minWidth: "125px" }}>
                    CONSULTANT
                    <img
                      src={sort}
                      className="sorting"
                      id="consultant_name"
                      onClick={sortWorkflow}
                      style={{
                        cursor: "pointer",
                        display: `${display1}`,
                        height: "10px",
                      }}
                    />
                  </th>
                  <th style={{ minWidth: "115px" }}>
                    SUBAGENT {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="sub_agent"
                      onClick={sortWorkflow}
                      style={{
                        cursor: "pointer",
                        display: `${display1}`,
                        height: "10px",
                      }}
                    />
                  </th>
                  <th style={{ minWidth: "115px" }}>
                    SUPERAGENT {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="super_agent"
                      onClick={sortWorkflow}
                      style={{
                        cursor: "pointer",
                        display: `${display1}`,
                        height: "10px",
                      }}
                    />
                  </th>
                  <th style={{ minWidth: "100px" }}>
                    UPDATED DATE
                    <img
                      src={sort}
                      className="sorting"
                      id="updated"
                      onClick={sortWorkflow}
                      style={{
                        cursor: "pointer",
                        display: `${display1}`,
                        height: "10px",
                      }}
                    />
                  </th>
                  <th style={{ minWidth: "120px" }}>
                    FOLLOW-UP
                    <img
                      src={sort}
                      className="sorting"
                      id="follow_up_date"
                      onClick={sortWorkflow}
                      style={{
                        cursor: "pointer",
                        display: `${display1}`,
                        height: "10px",
                      }}
                    />
                  </th>
                  <th style={{ minWidth: "110px" }}>
                    REMARKS {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="remarks"
                      onClick={sortWorkflow}
                      style={{
                        cursor: "pointer",
                        display: `${display1}`,
                        height: "10px",
                      }}
                    />
                  </th>
                  <th style={{ minWidth: "100px" }}>ACTION</th>
                </tr>
              </thead>
              {enquiryData != null ? (
                <tbody>
                  {enquiryData?.map((p, index) => (
                    <tr key={p.enquiryId} style={{ color: "#6d7290" }}>
                      <td style={{ width: "50px", textAlign: "center" }}>
                        {countnumber * rows + 1 + index}
                      </td>
                      <td>
                        {p.createdDate
                          ? moment(p.createdDate.split(".")[0]).format(
                              "DD-MMM-YY"
                            )
                          : null}
                      </td>
                      <td>
                        <Link
                          className="greeneffect"
                          onClick={(e) => viewWorkflow(p, e)}
                          style={{
                            width: "130px",
                            wordWrap: "break-word",
                            display: "inline-block",
                          }}
                          to={
                            `/view-enquiry-workflow/` +
                            p.clientId +
                            `/` +
                            p.enquiryId +
                            `/` +
                            p.clientUsername
                          }
                        >
                          {p.clientName}
                        </Link>
                        <br />
                        <div
                          style={{
                            width: "130px",
                            wordWrap: "break-word",
                            display: "inline-block",
                          }}
                        >
                          {p.clientUsername}
                        </div>
                      </td>
                      <td>
                        <div
                          style={{
                            width: "90px",
                            wordWrap: "break-word",
                            display: "inline-block",
                          }}
                        >
                          {p.clientPhoneNumber == 0 ? " " : p.clientPhoneNumber}
                        </div>
                      </td>
                      <td>{p.enquirySource}</td>
                      {p.enquiryStatus === "Discontinued" ? (
                        <td class="text-danger">
                          <div
                            style={{
                              width: "80px",
                              wordWrap: "break-word",
                              display: "inline-block",
                            }}
                          >
                            {p.enquiryStatus}
                          </div>
                        </td>
                      ) : (
                        <>
                          {p.enquiryStatus === "Completed" ? (
                            <td style={{ color: "green" }}>
                              <div
                                style={{
                                  width: "80px",
                                  wordWrap: "break-word",
                                  display: "inline-block",
                                }}
                              >
                                {p.enquiryStatus}
                              </div>
                            </td>
                          ) : (
                            <>
                              {p.enquiryStatus === null ? (
                                <td>Enquiry</td>
                              ) : (
                                <td>
                                  <div
                                    style={{
                                      width: "80px",
                                      wordWrap: "break-word",
                                      display: "inline-block",
                                    }}
                                  >
                                    {p.enquiryStatus}
                                  </div>
                                </td>
                              )}
                            </>
                          )}
                        </>
                      )}
                      <td>
                        <div
                          style={{
                            width: "130px",
                            wordWrap: "break-word",
                            display: "inline-block",
                          }}
                        >
                          {p.currentStage}
                        </div>
                      </td>
                      <td>
                        <div
                          style={{
                            width: "115px",
                            wordWrap: "break-word",
                            display: "inline-block",
                          }}
                        >
                          {p.institutionName}
                        </div>
                      </td>
                      <td>
                        <div
                          style={{
                            width: "115px",
                            wordWrap: "break-word",
                            display: "inline-block",
                          }}
                        >
                          {p.courseName}
                        </div>
                      </td>
                      <td>{p.intake}</td>
                      <td>
                        <div
                          style={{
                            width: "90px",
                            wordWrap: "break-word",
                            display: "inline-block",
                          }}
                        >
                          {p.officeName}
                        </div>
                        <br />
                        <div
                          style={{
                            width: "90px",
                            wordWrap: "break-word",
                            display: "inline-block",
                          }}
                        >
                          {p.country}
                        </div>
                      </td>
                      <td>
                        <>
                          <div class="s_tooltip">
                            {p.consultantName != null
                              ? p.consultantName.split(" ")[0]
                              : ""}
                            <span class="s_tooltiptext">
                              {p.consultantName}
                            </span>
                          </div>
                        </>
                      </td>
                      <td>
                        <div
                          style={{
                            width: "115px",
                            wordWrap: "break-word",
                            display: "inline-block",
                          }}
                        >
                          {p.subAgent?.split(" (")[1]?.slice(0, -1)}
                        </div>
                        {/* <br />
                        <div style={{width:"130px", wordWrap:"break-word",display:"inline-block"}}>{p.subAgent?.split(" (")[0]}</div> */}
                      </td>
                      <td>
                        <div
                          style={{
                            width: "115px",
                            wordWrap: "break-word",
                            display: "inline-block",
                          }}
                        >
                          {p.superAgent?.split(" (")[1]?.slice(0, -1)}
                        </div>
                        {/* <br />
                        <div style={{width:"130px", wordWrap:"break-word",display:"inline-block"}}>{p.superAgent?.split(" (")[0]}</div> */}
                      </td>
                      <td>
                        {p.updated
                          ? moment(p.updated.split(".")[0]).format("DD-MMM-YY")
                          : null}
                        {/* <br />
                        {p.updated
                          ? moment(p.updated.split(".")[0]).format("hh.mm a ")
                          : null} */}
                      </td>
                      <td>
                        {p.followUpDate
                          ? moment(p.followUpDate.split(".")[0]).format(
                              "DD-MMM-YY"
                            )
                          : null}
                        {/* <br />
                        {p.followUpDate
                          ? moment(p.followUpDate.split(".")[0]).format(
                            "hh.mm a"
                          )
                          : null} */}
                      </td>
                      <td>
                        {p.clientDetail?.remarks}
                        {p.clientDetail?.remarks != "" ? (
                          <AdmissionViewMoreModal
                            p={p}
                            toast={toast}
                            id={p.id}
                            handle403error={handle403error}
                            sortState={sortState}
                            direction={direction}
                            pageno={pageno}
                            rows={rows}
                          />
                        ) : null}
                      </td>
                      {/* <td> */}
                      {/* <div
                          style={{
                            position: "relative",
                            textAlign: "center",
                          }}
                        >
                          <div
                            className="workflowdashboard_fa-ellipsis"
                            data-toggle="dropdown"
                          >
                            <i
                              className="fa fa-ellipsis-v "
                              aria-hidden="true"
                            ></i>
                          </div>
                           <div className="dropdown-menu workflowdashboarddropdown">
                            <p>
                              <Link
                                color="danger"
                                id={p.enquiryId}
                                onClick={(e) => {
                                  e.preventDefault();
                                  openModal(e, p);
                                }}
                              >
                                Archive
                              </Link>
                            </p>
                          </div>
                        </div> */}
                      {/* <button
                          className="role-deletebtn"
                          id={p.enquiryId}
                          onClick={(e) => {
                            e.preventDefault();
                            openModal(e, p);
                          }}
                        >
                          ARCHIVE
                        </button> */}
                      {/* <AdmissionArchive
                          deleteEnquiry={p.enquiryId}
                          deletedUser={deletedUser}
                          getAllEnquiry={getAllEnquiry}
                          setIsOpen={setIsOpen}
                          handle403error={handle403error}
                          jwtToken={jwtToken}
                        /> */}
                      {/* 
                        <Modal
                          isOpen={modalIsOpen}
                          onRequestClose={closeModal}
                          style={customStyles}
                          contentLabel="Example Modal"
                        >
                          <h5>
                            Are you sure you want to delete current record?
                          </h5>
                          <div style={{ float: "right" }}>
                            <Button color="danger" onClick={deleteenquiry}>
                              Delete
                            </Button>
                            &nbsp;&nbsp;&nbsp;
                            <Button color="secondary" onClick={closeModal}>
                              Cancel
                            </Button>
                          </div>
                        </Modal> */}
                      {/* </td> */}
                      <td id={p.id}>
                        <div
                          style={{
                            position: "relative",
                            textAlign: "center",
                          }}
                        >
                          <div
                            className="workflowdashboard_fa-ellipsis"
                            data-toggle="dropdown"
                            style={{ width: "50px" }}
                          >
                            <i
                              className="fa fa-ellipsis-v "
                              aria-hidden="true"
                            ></i>
                          </div>
                          <div className="dropdown-menu workflowdashboarddropdown">
                            <ul style={{ margin: "0px" }} className="ulclass">
                              <li>
                                <p>
                                  <Link
                                    id={p.clientUsername}
                                    //onClick={(e) => viewClient(e, p)}
                                    to={`/edit-client/` + p.clientUsername}
                                    title="Edit"
                                    className="aclass"
                                  >
                                    Edit
                                  </Link>
                                </p>
                              </li>
                              <li>
                                <p>
                                  {p.status === 0 ? (
                                    <AdmissionArchive
                                      deleteEnquiry={p.enquiryId}
                                      deletedUser={deletedUser}
                                      getAllEnquiry={getAllEnquiry}
                                      setIsOpen={setIsOpen}
                                      handle403error={handle403error}
                                      jwtToken={jwtToken}
                                    />
                                  ) : (
                                    <AdmissionArchive
                                      deleteEnquiry={p.enquiryId}
                                      deletedUser={deletedUser}
                                      getAllEnquiry={getAllEnquiry}
                                      setIsOpen={setIsOpen}
                                      handle403error={handle403error}
                                      jwtToken={jwtToken}
                                    />
                                  )}
                                </p>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <Typography>{null}</Typography>
              )}
            </Table>
            <Row className="s_pagirow">
              <Col md={6}>
                <div>
                  <form>
                    <div style={{ float: "left" }}>
                      <FormControl
                        variant="standard"
                        style={{
                          display: "inline-block",
                          marginRight: "25px",
                          minWidth: "50px",
                        }}
                      >
                        <select
                          className={classes.selectlimit}
                          labelId="simple-select-label"
                          id="simple-select"
                          value={rows}
                          onChange={handleRows}
                        >
                          <option value={10}>10</option>
                          <option value={20}>20</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                        </select>
                      </FormControl>
                      {enquiryData?.length ? (
                        <div className="s_spanPage">
                          {countnumber * rows + 1} to{" "}
                          {countnumber * rows + numberofElements} of {totalUser}{" "}
                          records
                        </div>
                      ) : (
                        <div className="s_spanPage">No Record Found</div>
                      )}
                    </div>
                  </form>
                </div>
              </Col>
              <Col md={6}>
                <div className={classes.root} style={{ float: "right" }}>
                  <Pagination
                    page={paginationpageno}
                    count={getpageno}
                    onChange={handlePage}
                    shape="rounded"
                  />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
}

export default AdmisisonWorkFlowLists;
