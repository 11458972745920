import React, { useEffect, useState } from "react";
import { Card, CardText, Col, Row } from "reactstrap";
import axios from "axios";
import base_url from "../../api/bootapi";
import { useNavigate, useParams, Link } from "react-router-dom";
import "../../../node_modules/react-checkbox-tree/lib/react-checkbox-tree.css";
import CheckboxTree from "react-checkbox-tree";
import "../css/Responsive.css";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "../../Utilities/CSSUtilities";
import callsaveauditapi from "../../services/auditservice";
import { logout, selectUser, setdashboardcheck } from "../features/userSlice";
import {
  check4pointpermission,
  check1pointpermission,
} from "./../Permission/permission";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import Checkbox from "@mui/material/Checkbox";
import { makeStyles, withStyles } from "@mui/styles";
import Badge from "@mui/material/Badge";
import { Avatar, Typography, Button } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import LocalCafeIcon from "@mui/icons-material/LocalCafe";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import EmailIcon from "@mui/icons-material/Email";
import MarkunreadMailboxIcon from "@mui/icons-material/MarkunreadMailbox";
import EditIcon from "@mui/icons-material/Edit";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import moment from "moment";

import PropTypes from "prop-types";
// import UserPermissionsTab from "./UserPermissionsTab";
import UserClient from "./UserClient";
import UserPermissionsTab_new from "./UserPermissionTab_new";
import ClientImage from "./../images/client-dashboard/client-image.png";
import { customStyles } from "../../Utilities/CSSUtilities";
import Modal from "react-modal";

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    // color: theme.palette.common.black,
  },
  tooltip: {
    // backgroundColor: theme.palette.common.black,
  },
}));

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: "#44b700",
    color: "#44b700",
    // boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "$ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}))(Badge);

function BootstrapTooltip(props) {
  const classes2 = useStylesBootstrap();
  return <Tooltip arrow classes={classes2} {...props} />;
}

function TabPanel(props) {
  return (
    <div
      role="tabpanel"
      hidden={props.value !== props.index}
      id={`simple-tabpanel-${props.index}`}
      aria-labelledby={`simple-tab-${props.index}`}
    >
      {props.value === props.index && props.component}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const UserPermissions = () => {
  const user = useSelector(selectUser);
  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  const classes = useStyles();
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [backdropopen, setbackdropopen] = useState(false);
  const [userData, setUserData] = useState();
  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);
  const [userOfficeData, setUserOfficeData] = useState();
  const isw_user_role = localStorage.getItem("isw_user_role");
  const isw_user_name = localStorage.getItem("isw_user_name");

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        navigate("/");
      }
    });
    getPermissions();
    getActiveGroups();
  }, []);

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);

    if (check1pointpermission(601, permission_obj, resource_obj)) {
      dispatch(setdashboardcheck(19));
      getUserData();
    } else {
      navigate("/errorPage");
    }
  };

  const [userRole, setUserRole] = useState();

  const getUserRolebyId = (roleId) => {
    axios
      .get(`${base_url.api3}/getRolesResourcesByRoleId`, {
        headers: {
          Authorization: jwtToken,
          roleId: Number(roleId),
        },
      })
      .then((res) => {
        //console.log(res.data.data);
        let resourceId = [];
        for (let i = 0; i < res.data.data.length; i++) {
          resourceId.push(res.data.data[i].resourceId);
        }
        console.log("173--->", resourceId);
        setUserRole(resourceId);
      })
      .catch((err) => {
        let resourceId = [0];
        setUserRole(resourceId);
        handle403error(err);
      });
  };

  const getActiveGroups = () => {
    axios
      .get(`${base_url.api1}/getAllActiveGroups`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        let group_data = response.data.data;
        const group_obj = [];

        getUserRolebyId(response.data.data[0].id);
      })
      .catch((error) => {
        handle403error(error);
      });
  };

  const getUserData = () => {
    axios
      .get(`${base_url.api2}/getUserByUsername`, {
        headers: {
          Authorization: jwtToken,
          username: params.admin_email,
        },
      })
      .then((response) => {
        setUserData(response.data.data);
        //console.log(response.data.data);
        setuserstatus(response.data.data.status);
        if (response.data.data.status === 1) setuserStatusText("Unarchive");
        else setuserStatusText("Archive");
        let ofc_data = [];
        for (let i = 0; i < response.data.userOfficeData.length; i++) {
          ofc_data.push(response.data.userOfficeData[i].officeName);
        }
        setUserOfficeData(response.data.userOfficeData);
      })
      .catch((err) => {
        handle403error(err);
      });
  };

  const editAdmin = () => {
    localStorage.setItem(
      "adminPage",
      "/user-permissions/" + params.admin_id + "/" + params.admin_email
    );
    navigate("/edit-users/" + params.admin_email);
  };

  // const [value, setValue] = React.useState(tabvalue);
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    //console.log("Value", newValue);
    // dispatch(gettabvalue(newValue));
    setValue(newValue);
  };

  const goback = () => {
    navigate("/users");
  };

  const [userstatus, setuserstatus] = useState();
  const [userstatususername, setuserstatususername] = useState();
  const [changestatusmodal, setchangestatusmodal] = useState(false);
  const [userStatusText, setuserStatusText] = useState();

  const deleteUser = () => {
    const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
    let status1;
    //console.log("client status is : "+clientstatus)
    if (userstatus === 0) {
      status1 = 1;
    } else {
      status1 = 0;
    }
    let data = {
      username: userstatususername,
      status: status1,
    };
    axios
      .post(`${base_url.api2}/updateUserByEmail`, data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        toast.success("Status Changed");
        setuserstatus(response.data.data.status);
        if (response.data.data.status === 1) setuserStatusText("Unarchive");
        else setuserStatusText("Archive");
        setchangestatusmodal(false);
        let logs =
          isw_user_name +
          " Updated status of User " +
          response.data.data.email +
          " to " +
          response.data.data.status +
          ".";
        // callsaveauditapi(logs,dispatch, navigate);
        // getClientDetails();
      })
      .catch((error) => {
        toast.error(JSON.stringify(error.response.data.message));
        handle403error(error);
      });
  };

  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            navigate("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };
  return (
    <>
      <div class="sp lead-page" style={{ marginTop: "50px" }}>
        <div
          class="page-head d-flex justify-content-between p-4"
          style={{ marginTop: "5rem" }}
        >
          <ul class="breadcrums">
            <h3 class="page-title bold">User Permission</h3>
            <Link to="/" class="b-link">
              Home
            </Link>
            &raquo;<a class="b-link">Access Level</a>&raquo;
            <a class="b-link active">User Permission</a>
          </ul>
          <a
            className="sent-button"
            onClick={goback}
            style={{ float: "right" }}
          >
            List
          </a>
        </div>
        <div class="page-card_container p-4">
          <div class="content-wrapper">
            <div class="card client-profile" id="cardClient">
              <div class="minimize-profile">
                <span
                  onClick={() => {
                    let card = document.getElementById("cardClient");
                    card.style.display = "none";
                    let cardNum = document.getElementById("cardNumber");
                    cardNum.style.marginLeft = "0px";
                    let right_sp = document.getElementById("right_span");
                    right_sp.style.visibility = "visible";
                  }}
                >
                  {" "}
                  &#171;{" "}
                </span>
              </div>
              <div class="options">
                <input type="checkbox" id="option-checkbox" class="checkbox" />
                <label class="icon" for="option-checkbox">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <rect width="24" height="24" rx="6" fill="white" />
                    <path
                      d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                      stroke="#B1B1CA"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M12 8C12.5523 8 13 7.55228 13 7C13 6.44772 12.5523 6 12 6C11.4477 6 11 6.44772 11 7C11 7.55228 11.4477 8 12 8Z"
                      stroke="#B1B1CA"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M12 18C12.5523 18 13 17.5523 13 17C13 16.4477 12.5523 16 12 16C11.4477 16 11 16.4477 11 17C11 17.5523 11.4477 18 12 18Z"
                      stroke="#B1B1CA"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </label>
                <div class="options-content">
                  <ul>
                    <>
                      <li>
                        <Link
                          onClick={editAdmin}
                          to={`/edit-users/` + params.admin_email}
                        >
                          Edit User
                        </Link>
                      </li>
                    </>
                    <li>
                      {userstatus === 1 ? (
                        <a
                          onClick={() => {
                            // deleteUser(p.status, p.username)
                            setuserstatus(userData.status);
                            setuserstatususername(userData.username);
                            setchangestatusmodal(true);
                          }}
                        >
                          Archive
                        </a>
                      ) : (
                        <a
                          onClick={() => {
                            // deleteUser(p.status, p.username)
                            setuserstatus(userData.status);
                            setuserstatususername(userData.username);
                            setchangestatusmodal(true);
                          }}
                        >
                          Unarchive
                        </a>
                      )}
                    </li>
                  </ul>
                </div>
                <Modal
                  isOpen={changestatusmodal}
                  onRequestClose={() => setchangestatusmodal(false)}
                  style={customStyles}
                  contentLabel="Example Modal"
                >
                  <h4 style={{ marginTop: "10px" }}>
                    Are you sure you want to{" "}
                    {userstatus === 1 ? "Archive" : "Unarchive"} current record
                    ?{" "}
                  </h4>
                  <div
                    style={{
                      display: "flex",
                      marginTop: "20px",
                      justifyContent: "flex-end",
                      gap: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    <button
                      className="btn listing_addbutton ml-1"
                      onClick={deleteUser}
                    >
                      Yes
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <button
                      className="btn btn-secondary cancel_margin"
                      onClick={() => setchangestatusmodal(false)}
                    >
                      No
                    </button>
                  </div>
                </Modal>
              </div>
              <div class="client-img">
                <img src={ClientImage} alt="" />
                <h2 class="mt-2">
                  {" "}
                  {userData?.firstName} {userData?.lastName}
                </h2>
                {userstatus === 0 ? (
                  <>
                    <div className="dummy_contentleftpersonal archive_btn">
                      <span style={{ color: "red" }}>{userStatusText} </span>
                    </div>
                  </>
                ) : null}
                <hr />
                {/* {Number(isw_user_role) !== 6 &&
              Number(userData?.roleId) === 6 ? null : (
                <EditIcon
                  className="icon-margin dummy_contentlefticon"
                  fontSize="small"
                  color="primary"
                  style={{ cursor: "pointer" }}
                  onClick={editAdmin}
                />
              )} */}
              </div>
              <div class="personal-details">
                <div>
                  <Typography color="primary" variant="body1">
                    Name
                  </Typography>
                  <p>
                    {userData?.firstName
                      ? userData?.firstName + " " + userData?.lastName
                      : "-"}
                  </p>
                </div>
                <div>
                  <Typography color="primary" variant="body1">
                    Email
                  </Typography>
                  <p>{userData?.email ? userData?.email : "-"}</p>
                </div>
                <div>
                  <Typography color="primary" variant="body1">
                    Phone
                  </Typography>
                  <p>{userData?.phone ? userData?.phone : "-"}</p>
                </div>
                <div>
                  <Typography color="primary" variant="body1">
                    Role
                  </Typography>
                  <p>{userData?.roleName ? userData?.roleName : "-"}</p>
                </div>
                <div>
                  <Typography color="primary" variant="body1">
                    Offices
                  </Typography>
                  <p>
                    {/* {userOfficeData? userOfficeData.toString() : "-"} */}
                    {userOfficeData != null
                      ? userOfficeData &&
                        userOfficeData?.map((p, index) => <p>{p.officeName}</p>)
                      : "-"}
                  </p>
                </div>
              </div>
            </div>
            <div class="card client-details" id="cardNumber">
              <div class="maximize-profile">
                <span
                  onClick={() => {
                    let cardNum = document.getElementById("cardNumber");
                    cardNum.style.marginLeft = "20px";
                    let right_sp = document.getElementById("right_span");
                    right_sp.style.visibility = "hidden";
                    let card = document.getElementById("cardClient");
                    card.style.display = "block";
                  }}
                  id="right_span"
                  style={{ visibility: "hidden" }}
                >
                  {" "}
                  &#187;{" "}
                </span>
              </div>
              {/* <AppBar position="static" color="default"> */}
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                <Tab className="tab-bold" label="Clients" {...a11yProps(0)} />
                <Tab
                  className="tab-bold"
                  label="Permissions"
                  {...a11yProps(1)}
                />
              </Tabs>
              {/* </AppBar> */}
              <br />
              <TabPanel value={value} index={0} component={<UserClient />} />
              <TabPanel
                className="tabpanel"
                value={value}
                index={1}
                component={<UserPermissionsTab_new userRole={userRole} />}
                // component={<UserPermissionsTab />}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserPermissions;
