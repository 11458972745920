import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { CardText, Col, Row, Button, Table } from "reactstrap";
import moment from "moment";
import axios from "axios";
import base_url from "../../api/bootapi";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Pagination from "@mui/material/Pagination";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import AddIcon from "@mui/icons-material/Add";
import { ToastContainer, toast } from "react-toastify";
import Modal from "react-modal";
import "../css/Responsive.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setdashboardcheck, selectUser, logout } from "../features/userSlice";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { CountryDropdown } from "react-country-region-selector";
import { customStyles, useStyles } from "../../Utilities/CSSUtilities";
import PasswordStrengthBar from "react-password-strength-bar";
import callsaveauditapi from "../../services/auditservice";
import {
  getagentpermission,
  check4pointpermission,
  check1pointpermission,
} from "../Permission/permission";
import sort from "./../images/sort.svg";
import AgentEmailModal from "../Modal/AgentEmailModal";
import DeleteAgentModal from "../Modal/Masters/DeleteAgentModal";
import { CSVLink, CSVDownload } from "react-csv";
import jsPDF from "jspdf";

const UpdatePassword = ({ p, setbackdropopen, handle403error }) => {
  const [passwordmodal, setpasswordmodal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handlepasswordmodal = () => {
    setpasswordmodal(!passwordmodal);
    setchkPassState();
  };
  const [inputtype, setinputtype] = useState("password");
  const [chkPassState, setchkPassState] = useState();
  const isw_user_name = localStorage.getItem("isw_user_name");
  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");

  const changepassword = (e) => {
    setbackdropopen(true);
    let username = e.target[0].value;
    let password;
    password = chkPassState.trim();
    let data = {
      username,
      password,
    };

    axios
      .post(`${base_url.api3}/updateAgentPasswordByEmail`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        toast.success("Password Changed Successfully");
        let log =
          isw_user_name + " Updated passowrd of agent " + username + ".";
        callsaveauditapi(log, dispatch, navigate);
        setbackdropopen(false);
        handlepasswordmodal();
      })
      .catch((err) => {
        let log =
          isw_user_name +
          " failed to Updated passowrd of agent " +
          username +
          ".";
        callsaveauditapi(log, dispatch, navigate);
        setbackdropopen(false);
        handlepasswordmodal();
        handle403error(err);
        if (err.response) {
          toast.error(err?.response?.data?.message);
        } else {
          toast.error("Something Went Wrong!!");
        }
      });
  };

  return (
    <div>
      <span onClick={handlepasswordmodal}>Change Password</span>

      <Modal
        isOpen={passwordmodal}
        onRequestClose={handlepasswordmodal}
        style={customStyles}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            changepassword(e);
          }}
        >
          <div>
            <h3 style={{ color: "black" }}>Change Password</h3>
            <div className="form-group createadmin">
              <Row>
                <Col>
                  <label>User Name:</label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    value={p.email}
                    readOnly
                  />
                </Col>
              </Row>
            </div>
            <div className="form-group creatadmin">
              <Row>
                <Col>
                  <label>Password:</label>
                  <div style={{ position: "relative" }}>
                    <input
                      type={inputtype}
                      value={chkPassState}
                      onChange={(e) => setchkPassState(e.target.value)}
                      className="form-control underline-input"
                      required
                    />
                    {inputtype === "password" ? (
                      <i
                        className="fa fa-eye-slash fonticonpositioning"
                        onClick={() => setinputtype("text")}
                        aria-hidden="true"
                      ></i>
                    ) : (
                      <i
                        className="fa fa-eye fonticonpositioning"
                        onClick={() => setinputtype("password")}
                        aria-hidden="true"
                      ></i>
                    )}
                  </div>
                  <PasswordStrengthBar password={chkPassState} />
                </Col>
              </Row>
            </div>
            <div className="form-group creatadmin">
              <Row className="justify-content-end">
                <button
                  className="btn btn-secondary"
                  onClick={handlepasswordmodal}
                >
                  Cancel
                </button>
                <button type="submit" className="btn listing_addbutton ml-1">
                  Update
                </button>
              </Row>
            </div>
          </div>
        </form>
      </Modal>
    </div>
  );
};

let selected_AgId = localStorage.getItem("selected_agentId");
let selected_AgName = localStorage.getItem("selected_agentName");
const AgentEmailVerify = ({
  p,
  setbackdropopen,
  getallagentlist,
  pageno,
  handle403error,
}) => {
  const [verifymodal, setverifymodal] = useState(false);
  const handleverifymodal = () => setverifymodal(!verifymodal);
  const isw_user_name = localStorage.getItem("isw_user_name");
  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const verifyuser = () => {
    setbackdropopen(true);
    let username = p.email;
    let data = {
      username,
      emailVerificationStatus: 1,
    };

    axios
      .put(`${base_url.api3}/updateAgentByUsername`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        toast.success("Agent Verified");
        let log = isw_user_name + " verified agent " + username + ".";
        callsaveauditapi(log, dispatch, navigate);
        setbackdropopen(false);
        handleverifymodal();
        getallagentlist(pageno);
      })
      .catch((err) => {
        let log = isw_user_name + " failed to verify agent " + username + ".";
        callsaveauditapi(log, dispatch, navigate);
        setbackdropopen(false);
        handleverifymodal();
        if (err.response) {
          toast.error(err?.response?.data?.message);
        } else {
          toast.error("Something Went Wrong!!");
        }
        handle403error(err);
      });
  };
  return (
    <div>
      <span onClick={handleverifymodal}>Verify Agent</span>
      <Modal
        isOpen={verifymodal}
        onRequestClose={handleverifymodal}
        style={customStyles}
      >
        <h4>Are you sure you want to verify the Agent ?</h4>
        <div className="d-flex justify-content-end">
          <Button color="secondary" onClick={handleverifymodal}>
            Cancel
          </Button>
          <Button className="btn listing_addbutton ml-1" onClick={verifyuser}>
            Confirm
          </Button>
        </div>
      </Modal>
    </div>
  );
};

function Agents(props) {
  const [country1, setcountry] = useState();
  const [backdropopen, setbackdropopen] = useState(false);
  const user = useSelector(selectUser);
  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  const username = localStorage.getItem("isw_user_email");
  const isw_user_name = localStorage.getItem("isw_user_name");
  const [deleteuser, setDeleteuser] = useState(null);
  const [deleteuseremail, setDeleteuseremail] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [agent_users, setAgentDetails] = useState(null);
  const isw_user_role_name = localStorage.getItem("isw_user_role_name");

  const [pageno, setpageno] = useState(0);
  const [paginationpageno, setpaginationpageno] = useState(1);
  const [getpageno, setgetpageno] = useState();
  const [sortState, setsortState] = useState("agentId");
  const [direction, setdirection] = useState("DESC");
  const [rows, setrows] = React.useState(50);
  const [countryfilter, setcountryfilter] = useState(false);

  const datepast = moment().subtract(1, "M").format("YYYY-MM-DDThh:mm:ss");
  const isoDateString = new Date().toISOString();
  const datenow = isoDateString.split(".")[0];
  const [datefilter, setdatefilter] = useState();
  const [totalUser, settotalUser] = useState();
  const [countnumber, setcountnumber] = useState();
  const [numberofElements, setnumberofElements] = useState();
  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        navigate("/");
      }
    });
    setbackdropopen(true);
    getPermissions();
  }, [rows]);

  const [myrows, setmyrows] = React.useState(20000);
  const [dataForDownload, setDataForDownload] = useState([]);
  const [bDownloadReady, setDownloadReady] = useState(false);
  const csvLink = useRef();

  useEffect(() => {
    if (csvLink && csvLink.current && bDownloadReady) {
      csvLink.current.link.click();
      setDownloadReady(false);
    }
  }, [bDownloadReady]);

  useEffect(() => {
    let width = document.querySelector("table")?.offsetWidth;
    if (document.querySelector(".scroller")) {
      document.querySelector(".scroller").style.width = `${width}px`;
    }
  }, [agent_users]);

  let scrolling = false;
  const scrolldiv = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow .table-responsive");
    table.scrollTo(scroller.scrollLeft, 0);
  };

  const scrolltable = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow  .table-responsive");
    scroller.scrollTo(table.scrollLeft, 0);
  };

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);

    if (check1pointpermission(718, permission_obj, resource_obj)) {
      if (localStorage.getItem("agentPageNo")) {
        let currentPage = localStorage.getItem("agentPageNo");
        setpageno(currentPage);
        setpaginationpageno(Number(currentPage) + 1);
        getallagentlist(currentPage);
      } else {
        setpageno(0);
        setpaginationpageno(1);
        getallagentlist(0);
      }
      // } else {
      //   navigate("/errorPage");
      // }
    } else {
      navigate("/errorPage");
    }
  };

  const createAgent = () => {
    localStorage.setItem("agent_prev_path", "/agent");
    navigate("/create-agent");
  };
  const handlepage = (event, value) => {
    setpageno(value - 1);
    setpaginationpageno(value);
    getallagentlist(value - 1);
  };
  const handleRows = (event) => {
    setrows(event.target.value);
  };

  const handleAction = (actionType) => {
    setbackdropopen(true);
    if (actionType === "DOWNLOAD") {
      axios
        .get(`${base_url.api3}/findAllActiveAgent`, {
          headers: {
            Authorization: jwtToken,
            columnName: sortState,
            direction: direction,
            page: Number(0),
            size: Number(pdflength),
          },
        })
        .then(
          (response) => {
            //console.log(response.data.data.content);
            setpdflength(response.data.data.totalElements);
            setDataForDownload(response.data.data.content);
            setDownloadReady(true);
            setbackdropopen(false);
          },
          (error) => {
            setDataForDownload();
            setDownloadReady(true);
            setbackdropopen(false);
          }
        );
    }
  };

  const [pdflength, setpdflength] = useState(50);
  const [pdfHeader, setPdfHeader] = useState();
  const [pdfRow, setPdfRow] = useState([]);

  const getallagentlist = (value1) => {
    setbackdropopen(true);
    axios
      .get(`${base_url.api3}/findAllActiveAgent`, {
        headers: {
          Authorization: jwtToken,
          columnName: sortState,
          direction: direction,
          page: Number(value1),
          size: Number(rows),
        },
      })
      .then(
        (response) => {
          //console.log(response.data.data);
          getPdfData();
          var agent_data = response.data.data.content;
          if (response.data.data.totalElements == 0) {
            setpdflength(50);
          } else {
            setpdflength(rows);
          }
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          setAgentDetails(agent_data);
          setgetpageno(response.data.data.totalPages);
          setsearch(false);
          setbackdropopen(false);
          setdatefilter(false);
          try {
            document.getElementById("myForm").reset();
          } catch (error) {
            //console.log(error);
          }
        },
        (error) => {
          setbackdropopen(false);
          handle403error(error);
          // toast.error(JSON.stringify(error.response.data.message));
          //console.log(JSON.stringify(error.response));
        }
      );
  };

  const getPdfData = () => {
    setbackdropopen(true);
    axios
      .get(`${base_url.api3}/findAllActiveAgent`, {
        headers: {
          Authorization: jwtToken,
          columnName: sortState,
          direction: direction,
          page: Number(0),
          size: Number(pdflength),
        },
      })
      .then((response) => {
        setPdfRow(response.data.data.content);
        setbackdropopen(false);
      })
      .catch((error) => {
        setPdfRow();
        setbackdropopen(false);
        handle403error(error);
      });
  };

  const exportPdfAgentList = () => {
    let headData = {
      Authorization: jwtToken,
      columnName: sortState,
      direction: direction,
      page: Number(0),
      size: Number(pdflength),
    };
    setbackdropopen(true);
    axios
      .get(`${base_url.api3}/findAllActiveAgent`, {
        headers: headData,
      })
      .then((response) => {
        setPdfRow(response.data.data.content);
        const doc = new jsPDF();
        // autoTable(doc, { html: '#myTable' })
        var col = ["Agent Details", "Address", "Country", "addedOn"];
        var rows = [];
        response.data.data.content?.forEach((element) => {
          var temp = [
            element.name +
              "\n" +
              element.email +
              "\n" +
              "Phone: " +
              element.phone,
            "City: " + element.city + "\n" + "State: " + element.state,
            element.country,
            "SST: " + moment(element.addedOn).format("DD/MM/YYYY hh:mm:ss"),
          ];
          rows.push(temp);
        });
        doc.autoTable(col, rows, { pageBreak: "auto" });
        // doc.autoTable(col, rows, { pageBreak: 'auto' });
        doc.save("Agent_list.pdf");
        setbackdropopen(false);
      })
      .catch((error) => {
        setPdfRow();
        setbackdropopen(false);
        handle403error(error);
      });
  };

  const deleteCurrentUser = (id, name) => {
    axios
      .delete(`${base_url.api3}/deleteAgentById`, {
        headers: {
          Authorization: jwtToken,
          agentId: selected_AgId,
        },
      })
      .then(
        (response) => {
          //console.log(response.data.message);
          toast.success("Record Deleted Successfully");
          closeModal();
          getallagentlist(pageno);
        },
        (error) => {
          handle403error(error);
        }
      );
  };

  const deleteFromAlluser = (deleteuseremail) => {
    axios
      .delete(`${base_url.api2}/deleteUserByUsername`, {
        headers: {
          Authorization: jwtToken,
          username: deleteuseremail,
        },
      })
      .then(
        (response) => {
          //console.log(response.data.message);
          toast.success(response.data.message);
          getallagentlist(pageno);
          setIsOpen(!modalIsOpen);
        },
        (error) => {
          toast.error(JSON.stringify(error.response.data.message));
          //console.log(JSON.stringify(error.response));
        }
      );
  };

  const editUser = (p) => {
    //console.log(p);
    localStorage.setItem("agentPageNo", pageno);
    localStorage.setItem("agent_prev_path", "/agent");
    navigate("/edit-agent/" + p.agentId + "/" + p.email);
  };

  const classes = useStyles();

  var subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const openModal = (p) => {
    setDeleteuser(p.agentId);
    setDeleteuseremail(p.email);
    setIsOpen(true);
  };

  const afterOpenModal = () => {
    subtitle.style.color = "#f00";
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const [searchdata, setsearch] = useState();
  const [searchinput, setsearchinput] = useState();
  const submit = (e) => {
    setbackdropopen(true);

    let srchdata = e.target[0].value;
    setsearchinput(srchdata);
    setpageno(0);
    axios
      .get(`${base_url.api3}/searchAgent`, {
        headers: {
          Authorization: jwtToken,
          searchQuery: srchdata,
          columnName: sortState,
          direction: direction,
          page: Number(0),
          size: Number(rows),
        },
      })
      .then(
        (response) => {
          //console.log(response.data.data);
          var agent_data = response.data.data.content;
          // for (let i = 0; i < response.data.data.numberOfElements; i++) {
          //   agent_data[i].snId= response.data.data.number*rows+i;
          // }
          setAgentDetails(agent_data);
          setgetpageno(response.data.data.totalPages);
          setsearch(true);
          setbackdropopen(false);
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
        },
        (error) => {
          setbackdropopen(false);
          handle403error(error);
        }
      );
  };
  const handlesearch = (e, value) => {
    setbackdropopen(true);
    setpaginationpageno(value);
    //console.log(value);
    if (datefilter) {
      axios
        .post(`${base_url.api3}/searchAgentByDate`, datesrange, {
          headers: {
            Authorization: jwtToken,
            columnName: sortState,
            direction: direction,
            page: Number(value - 1),
            size: Number(rows),
          },
        })
        .then(
          (response) => {
            var agent_data = response.data.data.content;
            // for (let i = 0; i < response.data.data.numberOfElements; i++) {
            //   agent_data[i].snId= response.data.data.number*rows+i;
            // }
            setAgentDetails(agent_data);
            setgetpageno(response.data.data.totalPages);
            setsearch(false);
            setbackdropopen(false);
          },
          (error) => {
            setbackdropopen(false);
            handle403error(error);
          }
        );
    } else {
      axios
        .get(`${base_url.api3}/searchAgent`, {
          headers: {
            Authorization: jwtToken,
            searchQuery: searchinput,
            columnName: sortState,
            direction: direction,
            page: Number(value - 1),
            size: Number(rows),
          },
        })
        .then(
          (response) => {
            //console.log(response.data.data);
            var agent_data = response.data.data.content;
            // for (let i = 0; i < response.data.data.numberOfElements; i++) {
            //   agent_data[i].snId= response.data.data.number*rows+i;
            // }
            setAgentDetails(agent_data);
          },
          (error) => {
            handle403error(error);
          }
        );
    }
  };
  const submitrows = (e) => {
    if (searchinput != null) {
      axios
        .get(`${base_url.api3}/searchAgent`, {
          headers: {
            Authorization: jwtToken,
            searchQuery: searchinput,
            columnName: sortState,
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          },
        })
        .then(
          (response) => {
            //console.log(response.data.data);
            var agent_data = response.data.data.content;
            // for (let i = 0; i < response.data.data.numberOfElements; i++) {
            //   agent_data[i].snId= response.data.data.number*rows+i;
            // }
            setAgentDetails(agent_data);
            setgetpageno(response.data.data.totalPages);
          },
          (error) => {
            handle403error(error);
          }
        );
    } else {
      getallagentlist(0);
    }
  };

  const sortClient = (e) => {
    setsortState(e.target.id);
    if (direction == "DESC") {
      setdirection("ASC");
    } else if (direction == "ASC") {
      setdirection("DESC");
    }
    // getallEnquiry(pageno);
    axios
      .get(`${base_url.api3}/getAllAgent`, {
        headers: {
          Authorization: jwtToken,
          columnName: e.target.id,
          direction: direction,
          page: Number(pageno),
          size: Number(rows),
        },
      })
      .then(
        (response) => {
          //console.log(response);
          var agent_data = response.data.data.content;
          // for (let i = 0; i < response.data.data.numberOfElements; i++) {
          //   agent_data[i].snId= response.data.data.number*rows+i;
          // }
          setAgentDetails(agent_data);
          setgetpageno(response.data.data.totalPages);
          setsearch(false);
          try {
            document.getElementById("myForm").reset();
          } catch (error) {
            //console.log(error);
          }
        },
        (error) => {
          handle403error(error);
        }
      );
  };

  const handleEvent = (event, picker) => {
    //console.log(picker.startDate);
  };

  const handleCallback = (start, end, label) => {
    //console.log(start, end, label);
  };

  const [datesrange, setdatesrange] = useState();
  const [datevalue, setdatevalue] = useState("Click to open");

  const handleApply = (event, picker) => {
    setdatevalue(
      `${picker.startDate.format("DD-MM-YYYY")} to ${picker.endDate.format(
        "DD-MM-YYYY"
      )}`
    );
    setbackdropopen(true);

    // setdatesrange(null);
    setpageno(0);
    //console.log(event, picker);
    let data = {
      addedOnFromDate: picker.startDate.format("YYYY-MM-DDTHH:MM:ss"),
      addedOnToDate: picker.endDate.format("YYYY-MM-DDTHH:MM:ss"),
    };
    setdatesrange(data);
    axios
      .post(`${base_url.api3}/searchAgentByDate`, data, {
        headers: {
          Authorization: jwtToken,
          columnName: sortState,
          direction: direction,
          page: Number(0),
          size: Number(rows),
        },
      })
      .then(
        (response) => {
          var agent_data = response.data.data.content;
          // for (let i = 0; i < response.data.data.numberOfElements; i++) {
          //   agent_data[i].snId= response.data.data.number*rows+i;
          // }
          setAgentDetails(agent_data);
          setgetpageno(response.data.data.totalPages);
          setsearch(false);
          setdatefilter(true);
          setbackdropopen(false);
        },
        (error) => {
          // toast.error(JSON.stringify(error.response.data.message));
          setbackdropopen(false);
          handle403error(error);
        }
      );
  };
  const [agenttype, setagenttype] = useState(false);
  const [agentvalue, setagentvalue] = useState(false);
  const searchbyagent = (event) => {
    setbackdropopen(true);

    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const value = optionElement.getAttribute("value");
    setagentvalue(value);
    if (value === "all") {
      getallagentlist(pageno);
    } else {
      axios
        .get(`${base_url.api3}/getAgentByAgentType`, {
          headers: {
            Authorization: jwtToken,
            agentType: value,
            columnName: sortState,
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          },
        })
        .then(
          (response) => {
            //console.log(response);

            setagenttype(true);
            var agent_data = response.data.data.content;
            // for (let i = 0; i < response.data.data.numberOfElements; i++) {
            //   agent_data[i].snId= response.data.data.number*rows+i;
            // }
            setAgentDetails(agent_data);
            setgetpageno(response.data.data.totalPages);
            setsearch(false);
            setdatefilter(false);
            setbackdropopen(false);

            try {
              document.getElementById("myForm").reset();
            } catch (error) {
              //console.log(error);
            }
          },
          (error) => {
            setAgentDetails(null);
            setbackdropopen(false);
            handle403error(error);
          }
        );
    }
  };
  const selectCountry = (val) => {
    setbackdropopen(true);
    setcountryfilter(true);
    setcountry(val);
    if (val) {
      axios
        .get(`${base_url.api3}/getAgentByCountry`, {
          headers: {
            Authorization: jwtToken,
            country: val,
            columnName: sortState,
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          },
        })
        .then((response) => {
          var agent_data = response.data.data.content;
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          // for (let i = 0; i < response.data.data.numberOfElements; i++) {
          //   agent_data[i].snId= response.data.data.number*rows+i;
          // }
          setAgentDetails(agent_data);
          setgetpageno(response.data.data.totalPages);
          setsearch(false);
          setdatefilter(false);
          setbackdropopen(false);
          try {
            document.getElementById("myForm").reset();
          } catch (error) {
            //console.log(error);
          }
        })
        .catch((err) => {
          setAgentDetails();
          setbackdropopen(false);
          handle403error(err);
        });
    } else {
      getallagentlist(pageno);
    }
  };
  const viewAgent = (elem) => {
    localStorage.setItem("agentPageNo", pageno);
    var client_username = elem.target.id;
    // props.navigate.replace({ pathname: '/edit-Client/'+client_username })
    navigate("/view-agent/" + client_username);
  };
  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            navigate("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };
  return (
    <div>
      <ToastContainer />
      <Backdrop className={classes.backdrop} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* <div className="commoncomponent_cardcss"> */}
      <div
        class="page-head d-flex justify-content-between p-4"
        style={{ marginTop: "4.3rem", marginBottom: "-20px" }}
      >
        <ul class="breadcrums">
          <h3 class="page-title bold">Agent</h3>
          <Link to="/" class="b-link">
            Home
          </Link>
          <span className="s_span ">&raquo;</span>
          <a class="b-link">Partner</a>
          <span className="s_span ">&raquo;</span>
          <Link to="/agent" class="b-link active">
            Agent
          </Link>
        </ul>

        {check1pointpermission(719, permissionData, rolepermission) ? (
          <a
            className="sent-button"
            onClick={createAgent}
            style={{ float: "right" }}
          >
            Add
          </a>
        ) : null}
      </div>
      <div>
        {/* <Row>
            <Col md={6}>
              <CardText
                className={classes.headtext}
                style={{ marginTop: "-10px" }}
              >
                <strong>Agent</strong>
              </CardText>
            </Col>
            <Col md={6} className="d-flex justify-content-end">
              {permissionData?.includes(107) ? (
                <button className="btn listing_addbutton" onClick={createAgent}>
                  <AddIcon className="listing_addbutton_i" /> Add
                </button>
              ) : null}
            </Col>
          </Row> */}
        <div
          className="newlisting_headertabdesign"
          style={{
            padding: "1.5rem",
            borderRight: "1.5rem solid #e9ebf2",
            borderLeft: "1.5rem solid #e9ebf2",
            borderTop: "1.5rem solid #e9ebf2",
            marginBottom: "0",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
                alignItems: "center",
              }}
            >
              <div>
                <form
                  className="master"
                  onSubmit={(e) => {
                    e.preventDefault();
                    submitrows(e);
                  }}
                >
                  <div
                    style={{
                      marginTop: "-10px",
                      width: "fit-content",
                      marginRight: "14px",
                    }}
                  >
                    <label className="area_rowslable_skill">Limit</label>
                    <br />
                    {/* <FormControl className={classes.formControl}>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={rows}
                        onChange={handleRows}
                        style={{
                          border: "2px solid #ced4da ",
                          paddingTop: "5px",
                          backgroundColor: "#f5f5f9",
                        }}
                      >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                      </Select>
                    </FormControl> */}
                    <select
                      className="form-control"
                      name="branch"
                      value={rows}
                      onChange={handleRows}
                      style={{ backgroundColor: "#f5f5f9", width: "95%" }}
                    >
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                </form>
              </div>
              {/* <div style={{ marginTop: "-10px", marginRight: "14px" }}>
                <label className="area_rowslable_skill">Date</label>
                <div className="clearsearchclass" style={{ width: "200px", marginTop: "0px" }}>
                  <form
                    id="myForm"
                    className="d-flex"
                    style={{ position: "relative" }}
                  >
                    <DateRangePicker
                      onEvent={handleEvent}
                      onCallback={handleCallback}
                      onApply={handleApply}
                    >
                      <p
                        className="datepickerrangevaluetext"
                        style={{ backgroundColor: "#f5f5f9", fontSize: "14px", color: "#6d7290", padding: "10px", width: "100%" }}
                      >
                        {datevalue}
                      </p>
                    </DateRangePicker> */}
              {/* {datefilter ? (
                  <div>
                    <a
                      className={classes.clrsrch}
                      onClick={() => {
                        setdatevalue("Click to open");
                        setdirection("DESC");
                        setpageno(0);
                        getallagentlist(0);
                      }}
                    >
                      Clear Filter
                    </a>
                  </div>
                ) : null} */}
              {/* <span
                      className="listpagesearch_button"
                      style={{
                        top: "10px",
                        position: "absolute",
                        right: "10px",
                        pointerEvents: "none",
                      }}
                    >
                      <i className="fa fa-calendar"></i>
                    </span>
                  </form>
                </div>
              </div> */}
              <div style={{ marginTop: "-10px", marginRight: "14px" }}>
                <label className="area_rowslable_skill">Agent Type</label>
                <select
                  value={agentvalue}
                  className="form-control"
                  onChange={searchbyagent}
                  style={{ backgroundColor: "#f5f5f9" }}
                >
                  <option value="all">All</option>
                  <option value="Sub Agent">Sub Agent</option>
                  <option value="Super Agent">Super Agent</option>
                </select>
                {agenttype ? (
                  <div>
                    <a
                      style={{
                        textDecoration: "none",
                        margin: "10px 0",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setagenttype(false);
                        setagentvalue("all");
                        setdirection("DESC");
                        getallagentlist(0);
                      }}
                    >
                      Clear Search
                    </a>
                  </div>
                ) : null}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  marginTop: "-10px",
                }}
              >
                <label
                  className="area_rowslable_my"
                  style={{ fontWeight: "bold" }}
                >
                  Country
                </label>
                <CountryDropdown
                  style={{
                    width: "200px",
                    height: "35px",
                    backgroundColor: "#f5f5f9",
                  }}
                  defaultOptionLabel="- Select Country -"
                  className="form-control underline-input countrydropdown2"
                  value={country1}
                  onChange={(val) => selectCountry(val)}
                />
                {countryfilter ? (
                  <div>
                    <a
                      style={{
                        textDecoration: "none",
                        margin: "10px 0",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setcountry("all");
                        setcountryfilter(false);
                        setbackdropopen(true);
                        getallagentlist(0);
                      }}
                    >
                      Clear Search
                    </a>
                  </div>
                ) : null}
              </div>
            </div>
            <div>
              <div className="clearsearchclass" style={{ marginTop: "8px" }}>
                <form
                  id="myForm"
                  className="d-flex"
                  onSubmit={(e) => {
                    e.preventDefault();
                    submit(e);
                  }}
                >
                  <input
                    className="form-control"
                    placeholder="Search.."
                    name="search"
                  />
                  <button className="btn listpagesearch_button" type="submit">
                    <i className="fa fa-search"></i>
                  </button>
                </form>
              </div>
              {searchdata ? (
                <a
                  className={classes.clrsrch}
                  onClick={() => {
                    setpageno(0);
                    getallagentlist(0);
                  }}
                >
                  Clear Search
                </a>
              ) : null}
            </div>
          </div>
          {/* </Col>
              </Row> */}
        </div>
        <div
          className="newlisting_headertabdesign"
          style={{
            padding: "1.5rem",
            border: "1.5rem solid #e9ebf2",
            marginTop: "0",
            marginBottom: "0",
          }}
        >
          <Row className="s_pagi">
            <Col md={6}>
              {agent_users?.length ? (
                <p className="s_para">
                  {countnumber * rows + 1} to{" "}
                  {countnumber * rows + numberofElements} of {totalUser} records
                </p>
              ) : (
                <p className="s_para">No Record Found</p>
              )}
            </Col>
            {/* <Col md={6}>
              {permissionData?.includes(50) ? (
                <a
                  className="sent-button"
                  onClick={createAgent}
                  style={{ float: "right" }}
                >
                  Add
                </a>
              ) : null}
            </Col> */}
            <Col md={6} style={{ marginTop: "10px" }}>
              <div style={{ display: "flex", justifyContent: "right" }}>
                {check1pointpermission(731, permissionData, rolepermission) ? (
                  <button
                    className="btn listing_addbutton"
                    onClick={exportPdfAgentList}
                  >
                    Export PDF
                  </button>
                ) : null}
                {check1pointpermission(731, permissionData, rolepermission) ? (
                  <>
                    {dataForDownload != null ? (
                      <>
                        <button
                          type="button"
                          style={{ marginLeft: "25px" }}
                          className="btn listing_addbutton"
                          onClick={(e) => handleAction("DOWNLOAD")}
                        >
                          Export CSV
                        </button>
                        <CSVLink
                          data={dataForDownload}
                          filename="Agent_list.csv"
                          className="hidden"
                          ref={csvLink}
                          target="_blank"
                        />
                      </>
                    ) : null}
                  </>
                ) : null}
              </div>
            </Col>
          </Row>
          <div className="above-scroller" onScroll={scrolldiv}>
            <div className="scroller"></div>
          </div>
          <div className="displaytablerow">
            {/* <Table responsive striped onScroll={scrolltable}> */}
            <Table
              className="workload card-table table-borderless"
              responsive
              striped
              onScroll={scrolltable}
            >
              <thead className="s_sticky">
                <tr>
                  <th style={{ textAlign: "center", minWidth: "50px" }}>SN</th>
                  <th style={{ minWidth: "330px" }}>
                    NAME
                    {/* <UnfoldMoreIcon  */}
                    <img
                      src={sort}
                      className="sorting"
                      id="name"
                      onClick={sortClient}
                    />
                  </th>
                  <th style={{ minWidth: "210px" }}>
                    ADDRESS{" "}
                    <img
                      src={sort}
                      className="sorting"
                      id="state"
                      onClick={sortClient}
                    />
                  </th>
                  <th style={{ minWidth: "130px" }}>
                    COUNTRY{" "}
                    <img
                      src={sort}
                      className="sorting"
                      id="country"
                      onClick={sortClient}
                    />
                  </th>
                  <th style={{ minWidth: "110px" }}>TYPE </th>
                  <th style={{ minWidth: "130px" }}>
                    CREATED{" "}
                    <img
                      src={sort}
                      className="sorting"
                      id="addedOn"
                      onClick={sortClient}
                    />
                  </th>
                  <th style={{ minWidth: "20px" }}>ACTION</th>
                </tr>
              </thead>
              <tbody>
                {agent_users != null ? (
                  agent_users &&
                  agent_users.map((p, index) => (
                    <tr key={p.agentId}>
                      <td style={{ textAlign: "center", width: "50px" }}>
                        {countnumber * rows + 1 + index}
                      </td>
                      <td>
                        {check1pointpermission(
                          729,
                          permissionData,
                          rolepermission
                        ) ? (
                          <Link
                            className="greeneffect"
                            id={p.email}
                            // onClick={viewAgent}
                            to={`/view-agent/` + p.agentId + `/` + p.email}
                          >
                            {p.name}
                          </Link>
                        ) : (
                          <Link
                            className="greeneffect"
                            id={p.email}
                            //onClick={viewAgent}
                            to={`/view-agent/` + p.agentId + `/` + p.email}
                          >
                            {p.name}
                          </Link>
                        )}
                        <br />
                        {p.email}
                      </td>
                      <td>
                        {p.city}
                        <br />
                        {p.state}
                      </td>
                      <td>{p.country}</td>
                      {p.subAgent === 1 &&
                      p.superAgent === 1 &&
                      p.referal === 1 ? (
                        <td>
                          Sub Agent
                          <br />
                          Super Agent
                          <br />
                          Referal
                        </td>
                      ) : (
                        <>
                          {p.subAgent === 1 && p.superAgent === 1 ? (
                            <td>
                              Sub Agent
                              <br />
                              Super Agent
                            </td>
                          ) : (
                            <>
                              {p.subAgent === 1 && p.referal === 1 ? (
                                <td>
                                  Sub Agent
                                  <br />
                                  Referal
                                </td>
                              ) : (
                                <>
                                  {p.superAgent === 1 && p.referal === 1 ? (
                                    <td>
                                      Super Agent
                                      <br />
                                      Referal
                                    </td>
                                  ) : (
                                    <>
                                      {p.superAgent === 1 ? (
                                        <td>Super Agent</td>
                                      ) : (
                                        <>
                                          {p.subAgent === 1 ? (
                                            <td>Sub Agent</td>
                                          ) : (
                                            <td>Referal</td>
                                          )}
                                        </>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                      <td>
                        {p.addedOn
                          ? moment(p.addedOn.split(".")[0]).format("DD-MMM-YY")
                          : null}
                        {/* <br />
                        {p.addedOn
                          ? moment(p.addedOn.split(".")[0]).format(" hh.mm a")
                          : null} */}
                      </td>
                      <td id={p.agentId}>
                        {check4pointpermission(
                          720,
                          723,
                          permissionData,
                          rolepermission
                        ) ? (
                          <div
                            style={{
                              position: "relative",
                              textAlign: "left",
                            }}
                          >
                            <div
                              className="workflowdashboard_fa-ellipsis"
                              data-toggle="dropdown"
                            >
                              <i
                                className="fa fa-ellipsis-v "
                                aria-hidden="true"
                              ></i>
                            </div>
                            <div
                              className="dropdown-menu workflowdashboarddropdown"
                              style={{ width: "180px" }}
                            >
                              <ul style={{ margin: "0px" }} className="ulclass">
                                {/* {user.isw_user_role === 1 || user.isw_user_role === 6 ? ( */}
                                {check1pointpermission(
                                  732,
                                  permissionData,
                                  rolepermission
                                ) ? (
                                  <li>
                                    <p>
                                      <AgentEmailModal
                                        username={p.email}
                                        jwtToken={jwtToken}
                                        toast={toast}
                                        isw_user_name={isw_user_name}
                                        getallagentlist={getallagentlist}
                                        pageno={pageno}
                                      />
                                    </p>
                                  </li>
                                ) : null}
                                {/* ) : null} */}
                                {check1pointpermission(
                                  720,
                                  permissionData,
                                  rolepermission
                                ) ? (
                                  <li>
                                    <p>
                                      <Link
                                        id={p.email}
                                        // onClick={editUser}
                                        to={
                                          `/edit-agent/` +
                                          p.agentId +
                                          `/` +
                                          p.email
                                        }
                                        className="aclass"
                                      >
                                        Edit
                                      </Link>
                                    </p>
                                  </li>
                                ) : null}
                                {check1pointpermission(
                                  722,
                                  permissionData,
                                  rolepermission
                                ) ? (
                                  <li>
                                    <p>
                                      <UpdatePassword
                                        p={p}
                                        setbackdropopen={setbackdropopen}
                                        handle403error={handle403error}
                                      />
                                    </p>
                                  </li>
                                ) : null}
                                {check1pointpermission(
                                  721,
                                  permissionData,
                                  rolepermission
                                ) ? (
                                  <>
                                    <li>
                                      <p>
                                        {Number(p.emailVerificationStatus) ===
                                          0 && (
                                          <AgentEmailVerify
                                            p={p}
                                            setbackdropopen={setbackdropopen}
                                            getallagentlist={getallagentlist}
                                            pageno={pageno}
                                            handle403error={handle403error}
                                          />
                                        )}
                                      </p>
                                    </li>
                                  </>
                                ) : null}
                                {check1pointpermission(
                                  723,
                                  permissionData,
                                  rolepermission
                                ) ? (
                                  // <p>
                                  //   <Link
                                  //     id={p.agentId}
                                  //     onClick={() => {
                                  //       openModal(p);
                                  //     }}
                                  //   >
                                  //     Delete
                                  //   </Link>
                                  // </p>
                                  <li>
                                    <p>
                                      <DeleteAgentModal
                                        deleteCurrentUser={deleteCurrentUser}
                                        name={p.name}
                                        id={p.agentId}
                                        p={p}
                                        deleteuser={deleteuser}
                                      />
                                    </p>
                                  </li>
                                ) : null}
                              </ul>
                            </div>
                          </div>
                        ) : null}
                        {/* <Modal
                          isOpen={modalIsOpen}
                          onRequestClose={closeModal}
                          style={customStyles}
                          contentLabel="Example Modal"
                        >
                          <h5>
                            Are you sure you want to delete current record?
                          </h5>
                          <div style={{ float: "right" }}>
                            <Button color="danger" onClick={deleteCurrentUser}>
                              Delete
                            </Button>
                            &nbsp;&nbsp;&nbsp;
                            <Button color="secondary" onClick={closeModal}>
                              Cancel
                            </Button>
                          </div>
                        </Modal> */}
                      </td>
                    </tr>
                  ))
                ) : (
                  <p className={classes.headertekst}>{null}</p>
                )}
              </tbody>
            </Table>
          </div>
          <Row>
            <Col md={6}>
              <form>
                <div style={{ float: "left", clear: "both" }}>
                  <FormControl
                    variant="standard"
                    style={{
                      display: "inline-block",
                      marginRight: "25px",
                      minWidth: "50px",
                    }}
                  >
                    <select
                      className={classes.selectlimit}
                      labelId="simple-select-label"
                      id="simple-select"
                      value={rows}
                      onChange={handleRows}
                    >
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                    </select>
                  </FormControl>
                  {agent_users?.length ? (
                    <div
                      className="s_spanPage"
                      style={{ display: "inline-block" }}
                    >
                      {countnumber * rows + 1} to{" "}
                      {countnumber * rows + numberofElements} of {totalUser}{" "}
                      records
                    </div>
                  ) : (
                    <span
                      className="s_spanPage"
                      style={{ display: "inline-block" }}
                    >
                      No Record Found
                    </span>
                  )}
                </div>
              </form>
            </Col>
            <Col md={6}>
              <div className={classes.root} style={{ float: "right" }}>
                {searchdata || datefilter ? (
                  <Pagination
                    page={paginationpageno}
                    count={getpageno}
                    onChange={handlesearch}
                    shape="rounded"
                  />
                ) : (
                  <Pagination
                    page={paginationpageno}
                    count={getpageno}
                    onChange={handlepage}
                    shape="rounded"
                  />
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
}

export default Agents;
