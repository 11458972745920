import React, { useState } from "react";
import Modal from "react-modal";
import { Button } from "reactstrap";
import { customStyles } from "../../../Utilities/CSSUtilities";

const CourseViewMoreModal = ({ p }) => {
  const [deletemodal, setdeletemodal] = useState(false);
  const [mark, setmark] = useState(false);
  const opendeletemodal = () => {
    setdeletemodal(true);
    setmark(p.remarks);
  };

  const closedeletemodal = () => setdeletemodal(false);
  //console.log(p);
  return (
    <>
      <button
        style={{ cursor: "pointer" }}
        className="role-deletebtn"
        onClick={opendeletemodal}
      >
        View More
      </button>
      <Modal
        isOpen={deletemodal}
        onRequestClose={closedeletemodal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <h4>Remark</h4>
        {mark ? (
          <textarea
            style={{ minWidth: "600px" }}
            className="form-control"
            value={mark}
            rows={10}
            readonly
          />
        ) : (
          <p> No Record Available</p>
        )}
        <div
          style={{
            display: "flex",
            margin: "10px 0",
            justifyContent: "flex-end",
          }}
        >
          <Button color="secondary" onClick={() => setdeletemodal(false)}>
            Cancel
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default CourseViewMoreModal;
