import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Card, CardText, Col, Row, Button } from "reactstrap";
import axios from "axios";
import base_url from "./../../api/bootapi";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@mui/styles";
import "./../css/LoginForm.css";
import { selectAdmin } from "./../features/adminSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, setdashboardcheck, logout } from "../features/userSlice";
import "./../css/Responsive.css";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import Select from "react-select";
import DatePicker from "react-datepicker";
import callsaveauditapi from "../../services/auditservice";
import { getofficepermission } from "../Permission/permission";

const drawerWidth = 300;
const drawerWidth2 = 60;
const selectheight = "35px";
const selectWidth = "100%";
const headtxt = 30;

const useStyles = makeStyles((theme) => ({
  toolbar: {
    marginLeft: drawerWidth,
    marginRight: drawerWidth2,
  },
  selectdrop: {
    width: selectWidth,
    height: selectheight,
  },
  headtext: {
    fontSize: headtxt,
  },
  createBtn2: {
    color: "white",
    textDecoration: "none",
  },
  createBtn: {
    float: "left",
  },
  backdrop: {
    // zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    zIndex: 1000,
  },
}));

function EditOfficeUser(props) {
  let params = useParams();
  const admin = useSelector(selectAdmin);
  const navigate = useNavigate();
  const username2 = params.user_email;
  const [country1, setcountry] = useState();
  const [region, setregion] = useState();
  const user = useSelector(selectUser);
  const [dateofbirthdate, setdateofbirthdate] = useState(null);

  const particularUser = [];
  admin.map((item) => {
    if (item.username === username2) {
      particularUser.push(item);
    }
  });
  //console.log("Particular User", particularUser);
  const dispatch = useDispatch();
  //console.log(params.admin_username);
  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  const username = localStorage.getItem("isw_user_email");
  const classes = useStyles();
  const [stateCust, setstateCust] = useState({});
  const [availableroles, setAvailableroles] = useState(null);
  const [officeState, setOffice] = useState();
  const [officeState2, setOffice2] = useState();
  const [officeset1, setofficeset1] = useState();
  const ipaddress = localStorage.getItem("AdminIpAddress");
  const isw_user_name = localStorage.getItem("isw_user_name");
  const isw_adminId = localStorage.getItem("isw_adminId");
  const isw_user_email = localStorage.getItem("isw_user_email");
  const isw_user_role = localStorage.getItem("isw_user_role");

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        navigate("/");
      }
    });
    getPermissions();
  }, []);

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    // var available_resource = resource_obj.includes(21);
    // var available_permission = permission_obj.includes(6);
    if (getofficepermission(permission_obj, resource_obj)) {
      //if (available_permission){
      dispatch(setdashboardcheck(21));
      getUser();
      getRoles();
      getOffice();
      getallSpecificRoles();
      // } else {
      //   navigate("/errorPage");
      // }
    } else {
      navigate("/errorPage");
    }
  };

  const [specificroles, setSpecificRoles] = useState();
  const getallSpecificRoles = () => {
    axios
      .get(`${base_url.api3}/getActiveSpecificRoles`)
      .then((res) => {
        setSpecificRoles(res.data.data);
      })
      .catch((err) => {
        handle403error(err);
      });
  };

  const getRoles = () => {
    axios
      .get(`${base_url.api3}/listAllRoles`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        setAvailableroles(response.data.data);
      })
      .catch((error) => {
        handle403error(error);
      });
  };
  //console.log(availableroles);

  const [availableoffice, setAvailableOffice] = useState(null);
  const getOffice = () => {
    axios
      .get(`${base_url.api1}/findAllOffice`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        let ofc_data = response.data.data;
        const office_obj = [];
        for (let i = 0; i < ofc_data.length; i++) {
          const ofc_data2 = ofc_data[i];
          //console.log(ofc_data2);
          let data = {
            officeCode: ofc_data[i].officeCode,
            value: ofc_data[i].officeId,
            label: ofc_data[i].officeName,
            country: ofc_data[i].country,
            city: ofc_data[i].city,
          };
          office_obj.push(data);
        }
        //console.log(response.data.data);
        setAvailableOffice(office_obj);
      })
      .catch((error) => {
        handle403error(error);
      });
  };

  const inputEvent = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setstateCust((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const getUser = () => {
    axios
      .get(`${base_url.api2}/getUserByUsername`, {
        headers: {
          Authorization: jwtToken,
          username: username2,
        },
      })
      .then((response) => {
        let userData = response.data.data;
        let ofc_data = response.data.userOfficeData;
        let ofcData_obj = [];
        for (let i = 0; i < ofc_data.length; i++) {
          const element = ofc_data[i];
          let data = {
            value: ofc_data[i].officeId,
            label: ofc_data[i].officeName,
            country: ofc_data[i].country,
            city: ofc_data[i].city,
          };
          ofcData_obj.push(data);
        }
        setOffice(ofcData_obj);
        setOffice2(ofcData_obj);
        setcountry(userData.country);
        setregion(userData.state);
        setdateofbirthdate(userData.dob);
        setstateCust({
          username: userData.username,
          firstName: userData.firstName,
          lastName: userData.lastName,
          gender: userData.gender,
          email: userData.email,
          phone: userData.phone,
          address: userData.address,
          street: userData.street,
          pincode: userData.pincode,
          city: userData.city,
          country: userData.country,
          state: userData.state,
          nationality: userData.nationality,
          passport: userData.passport,
          roleId: userData.roleId,
          roleCode: userData.roleCode,
          photo: userData.photo,
          dob: userData.dob,
          status: userData.status,
          ownerUser: userData.ownerUser,
          addedOn: userData.addedOn,
          updatedOn: userData.updatedOn,
          lastLogin: userData.lastLogin,
          roleName: userData.roleName,
          id: userData.id,
        });
        if (userData.roleId == 6) {
          setAvailableroles([
            {
              id: 6,
              roleCode: "SADM",
              roleName: "Super Admin",
              status: 1,
            },
          ]);
        }
      })
      .catch((error) => {
        toast.error(JSON.stringify(error.response.data.message));
        handle403error(error);
      });
  };

  const selectCountry = (val) => {
    setcountry(val);
  };
  const selectRegion = (val) => {
    setregion(val);
  };
  const putCustomer = (e) => {
    const isoDateString = new Date().toISOString();
    const datenow = isoDateString.split(".")[0];
    stateCust.updatedOn = datenow;
    stateCust.country = country1;
    stateCust.nationality = country1;
    stateCust.passport = country1;
    stateCust.state = region;
    stateCust.dob = dateofbirthdate
      ? moment(dateofbirthdate).format("YYYY-MM-DDThh:mm:ss")
      : null;
    let officeIdarray = [];
    const ofc_obj = [];
    for (let i = 0; i < officeState2.length; i++) {
      let data = {
        officeId: Number(officeState[i].value),
        officeName: officeState[i].label,
        country: officeState[i].country,
        city: officeState[i].city,
        officeCode: officeState[i].officeCode,
      };
      officeIdarray.push(officeState[i].value);
      ofc_obj.push(data);
    }
    //console.log(ofc_obj);
    stateCust.officeId = officeIdarray.join(",");
    stateCust.office = officeState2;

    //console.log("From Statecust is", stateCust);
    if (ofc_obj != 0) {
      axios
        .post(`${base_url.api2}/updateUserByEmail`, stateCust, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: jwtToken,
          },
        })
        .then((response) => {
          //console.log(response.data.data.data);
          toast.success("Success");
          // dispatch(
          //   updateAdmin({
          //     id: stateCust.username,
          //     data: response.data.data.data,
          //   })
          // );

          let log =
            isw_user_name +
            " Updated User " +
            response.data.data.firstName +
            " " +
            response.data.data.lastName +
            " of with Role " +
            response.data.data.roleName +
            ".";
          callsaveauditapi(log, dispatch, navigate);
          navigate("/office-agents/" + params.office_id);
        })
        .catch((error) => {
          handle403error(error);
          toast.error(JSON.stringify(error.response.data.message));
        });
    } else {
      setofficeset1(true);
    }
  };

  const onSelect = (selectedList, selectedItem) => {
    //console.log(selectedList);
    const office_obj = [];
    for (let i = 0; i < selectedList.length; i++) {
      let data = {
        officeId: Number(selectedList[i].value),
        officeName: selectedList[i].label,
        country: availableoffice[i].country,
        city: availableoffice[i].city,
        officeCode: availableoffice[i].officeCode,
      };
      office_obj.push(data);
    }
    // setAvailableOffice()
    //console.log(availableoffice);
    //console.log(office_obj);
    setOffice(selectedList);
    setOffice2(office_obj);
    setofficeset1(false);
  };

  const goback = () => {
    // navigate("/office-agents/" + params.office_id);
    navigate(-1);
  };

  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            navigate("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };
  return (
    <div>
      <ToastContainer />
      {/* <Card className="commoncomponent_cardcss"> */}
      <div
        className="page-head d-flex justify-content-between p-4 "
        style={{ marginTop: "5rem" }}
      >
        <div className="breadcrums">
          <h3 className="page-title bold">Users</h3>
          <a href="index.html" className="b-link">
            Home
          </a>
          -
          <a href="client-dashboard.html" className="b-link">
            Access Level
          </a>
          -
          <a href="#" className="b-link active">
            User Edit
          </a>
        </div>
        <a onClick={goback} className="sent-button">
          List
        </a>
      </div>
      <div style={{ padding: "1.5rem", border: "1.5rem solid #e9ebf2" }}>
        {availableroles == null ? (
          <Backdrop className={classes.backdrop} open>
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              putCustomer(e);
            }}
          >
            <div className="form-inner-admin">
              {/* <Row>
                <Col md={6}>
                  <CardText className={classes.headtext}>
                    <strong>Users</strong>
                  </CardText>
                </Col>
                <Col md={6} className="d-flex justify-content-end">
                  <button className="btn listing_addbutton" onClick={goback}>
                    &#x2630; List
                  </button>
                </Col>
              </Row> */}
              <div className="form-group creatadmin">
                <Row>
                  <Col md={4}>
                    <label className="area_rowslable_my">
                      First Name<span style={{ color: "red" }}>*</span>:
                    </label>
                    <input
                      type="text"
                      value={stateCust.firstName}
                      name="firstName"
                      onChange={inputEvent}
                      style={{ backgroundColor: "#f5f5f9" }}
                      className="form-control form-control-sm"
                      required
                    />
                  </Col>
                  <Col md={4}>
                    <label className="area_rowslable_my">
                      Last Name<span style={{ color: "red" }}>*</span>:
                    </label>
                    <input
                      type="text"
                      value={stateCust.lastName}
                      name="lastName"
                      style={{ backgroundColor: "#f5f5f9" }}
                      onChange={inputEvent}
                      className="form-control form-control-sm"
                      required
                    />
                  </Col>
                  <Col md={4}>
                    <label className="area_rowslable_my">
                      Gender<span style={{ color: "red" }}>*</span>:
                    </label>
                    <select
                      defaultValue={stateCust.gender}
                      className={classes.selectdrop}
                      name="gender"
                      style={{ backgroundColor: "#f5f5f9" }}
                      value={stateCust.gender}
                      onChange={inputEvent}
                      // id={stateCust.gender}
                      required
                    >
                      <option value="Female">Female</option>
                      <option value="Male">Male</option>
                      <option value="Others">Others</option>
                    </select>
                  </Col>
                </Row>
              </div>
              <div className="form-group creatadmin">
                <Row>
                  <Col md={4}>
                    <label className="area_rowslable_my">
                      Email / Username<span style={{ color: "red" }}>*</span>:
                    </label>
                    <input
                      type="email"
                      value={stateCust.email}
                      style={{ backgroundColor: "#f5f5f9" }}
                      name="username"
                      onChange={(e) => {
                        const value = e.target.value;
                        stateCust.email = value;
                        inputEvent(e);
                      }}
                      className="form-control form-control-sm"
                      readOnly
                      required
                    />
                  </Col>
                  <Col md={4}>
                    <label className="area_rowslable_my">
                      Contact No<span style={{ color: "red" }}></span>:
                    </label>
                    <input
                      type="text"
                      value={stateCust.phone}
                      name="phone"
                      style={{ backgroundColor: "#f5f5f9" }}
                      onChange={inputEvent}
                      className="form-control form-control-sm"
                    />
                  </Col>
                  <Col md={4}>
                    <label className="area_rowslable_my">
                      Address<span style={{ color: "red" }}>*</span>:
                    </label>
                    <input
                      type="text"
                      value={stateCust.address}
                      name="address"
                      style={{ backgroundColor: "#f5f5f9" }}
                      onChange={inputEvent}
                      className="form-control form-control-sm"
                      required
                    />
                  </Col>
                </Row>
              </div>
              <div className="form-group creatadmin">
                <Row>
                  <Col md={4}>
                    <label className="area_rowslable_my">
                      Country<span style={{ color: "red" }}>*</span>:
                    </label>
                    <CountryDropdown
                      className="form-control underline-input countrydropdown2"
                      defaultOptionLabel="- Select Country -"
                      value={country1}
                      style={{ backgroundColor: "#f5f5f9" }}
                      onChange={(val) => selectCountry(val)}
                      required
                    />
                  </Col>
                  <Col md={4}>
                    <label className="area_rowslable_my">
                      State<span style={{ color: "red" }}>*</span>:
                    </label>
                    {country1 == "Nepal" ? (
                      <select
                        className={classes.selectdrop}
                        name="gender"
                        style={{ backgroundColor: "#f5f5f9" }}
                        value={region}
                        onChange={(e) => selectRegion(e.target.value)}
                      >
                        <option value="Province No. 1">Province No. 1</option>
                        <option value="Madhesh Province">
                          Madhesh Province
                        </option>
                        <option value="Bagmati Province">
                          Bagmati Province
                        </option>
                        <option value="Gandaki Province">
                          Gandaki Province
                        </option>
                        <option value="Lumbini Province">
                          Lumbini Province
                        </option>
                        <option value="Karnali Province">
                          Karnali Province
                        </option>
                        <option value="Sudurpashchim Province">
                          Sudurpashchim Province
                        </option>
                      </select>
                    ) : (
                      <RegionDropdown
                        className="form-control underline-input countrydropdown2"
                        defaultOptionLabel="- Select State -"
                        country={country1}
                        value={region}
                        style={{ backgroundColor: "#f5f5f9" }}
                        onChange={(val) => selectRegion(val)}
                        required
                      />
                    )}
                  </Col>
                  <Col md={2}>
                    <label className="area_rowslable_my">
                      City<span style={{ color: "red" }}>*</span>:
                    </label>
                    <input
                      type="text"
                      value={stateCust.city}
                      style={{ backgroundColor: "#f5f5f9" }}
                      name="city"
                      onChange={inputEvent}
                      className="form-control form-control-sm"
                      required
                    />
                  </Col>
                  <Col md={2}>
                    <label className="area_rowslable_my">
                      PostCode<span style={{ color: "red" }}>*</span>:
                    </label>
                    <input
                      type="text"
                      value={stateCust.pincode}
                      name="pincode"
                      style={{ backgroundColor: "#f5f5f9" }}
                      onChange={inputEvent}
                      className="form-control form-control-sm"
                      required
                    />
                  </Col>
                </Row>
              </div>
              <div className="form-group creatadmin">
                <Row>
                  <Col md={4}>
                    <label className="area_rowslable_my">Date of Birth:</label>
                    <DatePicker
                      selected={
                        dateofbirthdate ? new Date(dateofbirthdate) : null
                      }
                      onChange={(date) => setdateofbirthdate(date)}
                      dateFormat="dd-MM-yyyy"
                      showYearDropdown
                      dropdownMode="select"
                      placeholderText="Select Date"
                      style={{ backgroundColor: "#f5f5f9" }}
                    />
                  </Col>
                  <Col md={4}>
                    <label className="area_rowslable_my">
                      Role<span style={{ color: "red" }}>*</span>:
                    </label>
                    {isw_user_role == 1 || isw_user_role == 6 ? (
                      <select
                        defaultValue={stateCust.roleName}
                        value={stateCust.roleName}
                        className={classes.selectdrop}
                        id={stateCust.roleId}
                        name="roleName"
                        style={{ backgroundColor: "#f5f5f9" }}
                        onChange={(e) => {
                          const index = e.target.selectedIndex;
                          const optionElement = e.target.childNodes[index];
                          const optionElementId =
                            optionElement.getAttribute("id");
                          const optionElementvalue =
                            optionElement.getAttribute("value");
                          const optionElementvalue2 =
                            optionElement.getAttribute("roleCode");
                          setstateCust.roleId = optionElementId;
                          setstateCust.roleName = optionElementvalue;
                          stateCust.roleCode = optionElementvalue2;
                          inputEvent(e);
                        }}
                        required
                      >
                        {availableroles.map((cuntitem, index) => (
                          <option
                            key={index + 1}
                            id={cuntitem.roleId}
                            roleCode={cuntitem.roleCode}
                            value={cuntitem.roleName}
                          >
                            {cuntitem.roleName}-{cuntitem.roleCode}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <select
                        defaultValue={stateCust.roleName}
                        value={stateCust.roleName}
                        className={classes.selectdrop}
                        id={stateCust.roleId}
                        style={{ backgroundColor: "#f5f5f9" }}
                        name="roleName"
                        onChange={(e) => {
                          const index = e.target.selectedIndex;
                          const optionElement = e.target.childNodes[index];
                          const optionElementId =
                            optionElement.getAttribute("id");
                          const optionElementvalue =
                            optionElement.getAttribute("value");
                          const optionElementvalue2 =
                            optionElement.getAttribute("roleCode");
                          setstateCust.roleId = optionElementId;
                          setstateCust.roleName = optionElementvalue;
                          stateCust.roleCode = optionElementvalue2;
                          inputEvent(e);
                        }}
                        required
                      >
                        {specificroles.map((cuntitem, index) => (
                          <option
                            key={index + 1}
                            id={cuntitem.roleId}
                            roleCode={cuntitem.roleCode}
                            value={cuntitem.roleName}
                            style={{ backgroundColor: "#f5f5f9" }}
                          >
                            {cuntitem.roleName}-{cuntitem.roleCode}
                          </option>
                        ))}
                      </select>
                    )}
                  </Col>
                  <Col md={4}>
                    <label className="area_rowslable_my">
                      Status<span style={{ color: "red" }}>*</span>:
                    </label>
                    {stateCust?.roleId == 6 ? (
                      <select
                        defaultValue={
                          stateCust.status == 1 ? "Active" : "InActive"
                        }
                        className={classes.selectdrop}
                        name="status"
                        style={{ backgroundColor: "#f5f5f9" }}
                        value={stateCust.status}
                        onChange={inputEvent}
                        required
                      >
                        <option value="1">Active</option>
                      </select>
                    ) : (
                      <select
                        defaultValue={
                          stateCust.status == 1 ? "Active" : "InActive"
                        }
                        className={classes.selectdrop}
                        name="status"
                        style={{ backgroundColor: "#f5f5f9" }}
                        value={stateCust.status}
                        onChange={inputEvent}
                        required
                      >
                        <option value="1">Active</option>
                        <option value="0">InActive</option>
                      </select>
                    )}
                  </Col>
                </Row>
              </div>
              <div className="form-group creatadmin">
                <Row>
                  <Col md={12}>
                    <label className="area_rowslable_my">
                      Select Branch<span style={{ color: "red" }}>*</span>:
                    </label>
                    <br />
                    <br />

                    <Select
                      value={officeState}
                      isMulti
                      style={{ backgroundColor: "#f5f5f9" }}
                      name="officeName"
                      options={availableoffice}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={onSelect}
                    />
                  </Col>
                </Row>
                <br />
                {officeset1 ? (
                  <p style={{ color: "red", fontSize: "10px" }}>
                    Select Atleast One Branch
                  </p>
                ) : null}
              </div>
            </div>
            <Row>
              <Col md={6}>{null}</Col>
              <Col md={6} className="d-flex justify-content-end">
                <button type="submit" className="btn listing_addbutton ml-1">
                  Update
                </button>
              </Col>
            </Row>
          </form>
        )}
        {/* </Card> */}
      </div>
    </div>
  );
}

export default EditOfficeUser;
