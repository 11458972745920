import React, { useState, useEffect } from "react";
import axios from "axios";
import base_url from "../../api/bootapi";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CardText, Col, Row, Button } from "reactstrap";
import Pagination from "@mui/material/Pagination";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { selectdashboard } from "../features/dashboardSlice";
import { selectUser, logout } from "../features/userSlice";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { useSelector, useDispatch } from "react-redux";
import { useStyles } from "../../Utilities/CSSUtilities";
import Chart from "react-google-charts";
import { toast } from "react-toastify";

const CourseReport = () => {
  const dashboardvalue = useSelector(selectdashboard);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const user = useSelector(selectUser);
  const [totalUser, settotalUser] = useState();
  const [countnumber, setcountnumber] = useState();
  const [numberofElements, setnumberofElements] = useState();
  const [rows, setrows] = React.useState(20);
  const params = useParams();
  const jwtToken = "Bearer " + user.isw_user_token;
  const [pageno, setpageno] = useState(0);
  const [reportresult, setreportresult] = useState();
  const [getpageno, setgetpageno] = useState();
  const [data, setdatedata] = useState([["CreatedDate", "Count"]]);
  const currentdate = moment().format("YYYY-MM-DD");
  const datepast = moment().subtract(1, "M").format("YYYY-MM-DD");
  const handlepage = (event, value) => {
    setpageno(value - 1);
    getcoursereport(value - 1);
  };

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        navigate("/");
      }
    });
    getcoursereport(pageno);
    getcount();
  }, []);

  const getcount = () => {
    axios
      .get(`${base_url.api2}/getEnquiryCountByCourseIdAndDate`, {
        headers: {
          Authorization: jwtToken,
          courseId: params.course_id,
          createdToDate: currentdate,
          createdFromDate: datepast,
        },
      })
      .then((res) => {
        for (let i = 0; i < res.data.data.length; i++) {
          let newarr = [];
          newarr[0] = moment(res.data.data[i].createdDate).format("DD-MM-YYYY");
          newarr[1] = Number(res.data.data[i].enquiryCount);
          setdatedata((prev) => [...prev, newarr]);
        }
      })
      .catch((err) => {
        handle403error(err);
      });
  };
  const getcoursereport = (value) => {
    axios
      .get(`${base_url.api2}/getEnquiryByCourseId`, {
        headers: {
          Authorization: jwtToken,
          courseId: params.course_id,
          columnName: "created",
          direction: "DESC",
          page: value,
          size: Number(rows),
        },
      })
      .then((res) => {
        setreportresult(res.data.data.content);
        setgetpageno(res.data.data.totalPages);
        settotalUser(res.data.data.totalElements);
        setcountnumber(res.data.data.number);
        setnumberofElements(res.data.data.numberOfElements);
      })
      .catch((err) => {
        handle403error(err);
      });
  };
  const handleEvent = (event, picker) => {
    //console.log(picker.startDate);
  };

  const handleCallback = (start, end, label) => {
    //console.log(start, end, label);
  };
  const [datevalue, setdatevalue] = useState("Click to open");
  const handleApply = (event, picker) => {
    setdatevalue(
      `${picker.startDate.format("DD-MM-YYYY")} to ${picker.endDate.format(
        "DD-MM-YYYY"
      )}`
    );
    axios
      .get(`${base_url.api2}/getEnquiryCountByCourseIdAndDate`, {
        headers: {
          Authorization: jwtToken,
          courseId: params.course_id,
          createdToDate: picker.endDate.format("YYYY-MM-DD"),
          createdFromDate: picker.startDate.format("YYYY-MM-DD"),
        },
      })
      .then((res) => {
        for (let i = 0; i < res.data.data.length; i++) {
          let newarr = [];
          newarr[0] = moment(res.data.data[i].createdDate).format("DD-MM-YYYY");
          newarr[1] = Number(res.data.data[i].enquiryCount);
          setdatedata((prev) => [...prev, newarr]);
        }
      })
      .catch((err) => {
        handle403error(err);
      });
  };
  const goback = () => {
    navigate("/course");
  };
  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            navigate("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };
  return (
    <div style={{ margin: "20px" }}>
      <div>
        <Row>
          <Col md={6} style={{ textAlign: "left" }}>
            <div style={{ marginTop: "-10px", marginRight: "14px" }}>
              <label className="location_status">
                <strong>Date:</strong>
              </label>
              <br />
              <DateRangePicker
                onEvent={handleEvent}
                onCallback={handleCallback}
                onApply={handleApply}
              >
                <p
                  className="datepickerrangevaluetext"
                  style={{ backgroundColor: "#f5f5f9" }}
                >
                  {datevalue}
                </p>
              </DateRangePicker>
            </div>
          </Col>
          <Col md={6} className="d-flex justify-content-end">
            <button className="btn listing_addbutton" onClick={goback}>
              {/* &#x2630; */}
              List
            </button>
          </Col>
        </Row>
        <div className="row providerreport">
          <div className="col-md-12">
            {data[1] ? (
              <Chart
                chartType="PieChart"
                loader={<div>Loading Chart</div>}
                data={data}
                options={{
                  title: "Enquiry count based on Provider",
                  pieHole: 0.4,
                }}
                rootProps={{ "data-testid": "1" }}
              />
            ) : null}
          </div>
        </div>
        <div className="quotation_table">
          <TableContainer style={{ marginTop: "10px" }} component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead style={{ backgroundColor: "cornflowerblue" }}>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }} align="center">
                    SN
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Client</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Workflow</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Current Stage
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Consultant
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Status</TableCell>
                  <TableCell style={{ fontWeight: "bold" }} align="right">
                    AddedOn
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {reportresult?.map((p, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row" align="center">
                      {countnumber * rows + 1 + index}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {p.clientName}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {p.workflowName}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {p.currentStage}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {p.consultantName}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {p.enquiryStatus}
                    </TableCell>
                    <TableCell component="th" scope="row" align="right">
                      {p.created
                        ? moment(p.created.split(".")[0]).format("DD-MMM-YY")
                        : null}
                      <br />
                      {p.created
                        ? moment(p.created.split(".")[0]).format("hh.mm a")
                        : null}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <Row>
          <Col md={6} style={{ textAlign: "left" }}>
            {reportresult?.length ? (
              <p style={{ marginTop: "15px" }}>
                {countnumber * rows + 1} to{" "}
                {countnumber * rows + numberofElements} of {totalUser} records
              </p>
            ) : (
              <p className="formfooterrecordstyle">No Record Found</p>
            )}
          </Col>
          <Col md={6}>
            <div className={classes.root} style={{ float: "right" }}>
              <Pagination
                count={getpageno}
                onChange={handlepage}
                shape="rounded"
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default CourseReport;
