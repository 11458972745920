import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import Modal from "react-modal";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate, useParams } from "react-router-dom";
import attachmenturl from "../../api/attachmenturl";
import { customStyles } from "../../Utilities/CSSUtilities";
import axios from "axios";
import base_url from "../../api/bootapi";
import { Row, Col } from "reactstrap";
import { selectUser } from "../features/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "../../Utilities/CSSUtilities";

import { toast } from "react-toastify";

import { logout } from "../features/userSlice";
import {
  getproviderpermission,
  check4pointpermission,
  check1pointpermission,
} from "../Permission/permission";

const ApplicationDetail = (props) => {
  const user = useSelector(selectUser);
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [deleteapplicationmodal, setdeleteapplicationmodal] = useState(false);
  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  const [deleteid, setdeleteid] = useState();
  const [backdrop, setbackdrop] = useState(false);
  let params = useParams();
  const provider_id = params.provider_id;
  const goBack = () => {
    navigate("/provider");
  };

  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);

  useEffect(() => {
    getPermissions();
  }, []);

  useEffect(() => {
    let width = document.querySelector("table")?.offsetWidth;
    if (document.querySelector(".scroller")) {
      document.querySelector(".scroller").style.width = `${width}px`;
    }
  }, [props.transactionalarrangementlist]);

  let scrolling = false;
  const scrolldiv = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow .table-responsive");
    table.scrollTo(scroller.scrollLeft, 0);
  };

  const scrolltable = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow  .table-responsive");
    scroller.scrollTo(table.scrollLeft, 0);
  };

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);

    if (check1pointpermission(706, permission_obj, resource_obj)) {
    } else {
      navigate("/errorPage");
    }
  };

  const deletestudentappform = () => {
    axios
      .delete(`${base_url.api2}/deleteInstitutionTransactionalAgreement`, {
        headers: {
          Authorization: jwtToken,
        },
        data: [deleteid],
      })
      .then((res) => {
        setdeleteapplicationmodal(false);
        props.getCollegeById();
      })
      .catch((err) => {
        handle403error(err);
      });
  };

  const downloadAllTransactionalAgreement = (applicationFormPath) => {
    setbackdrop(true);
    let file_name =
      applicationFormPath.split("amazonaws.com/")[
        applicationFormPath.split("amazonaws.com/").length - 1
      ];
    const header = {
      method: "GET",
      headers: {
        Authorization: jwtToken,
        institutionId: provider_id,
        fileName: file_name,
      },
    };

    var url = `${base_url.api2}/downloadInstitutionTransactionalAgreementByInstitutionIdAndFileName`;
    fetch(url, header)
      .then((response) => readAllChunks(response))
      .then(async function (response) {
        //console.log(response);
        const downloadFileType = localStorage.getItem(
          "provider_application_attachment_file_content_type"
        );
        //console.log(downloadFileType);
        var blob = new Blob(response, { type: downloadFileType });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = file_name;
        link.click();
        setbackdrop(false);
      })
      .catch((error) => {
        setbackdrop(false);
        //console.log(error);
        handle403error(error);
      });
  };

  const readAllChunks = (readableStream) => {
    localStorage.setItem(
      "provider_application_attachment_file_content_type",
      readableStream.headers.get("content-type")
    );
    const reader = readableStream.body.getReader();
    const chunks = [];

    function pump() {
      return reader
        .read()
        .then(({ value, done }) => {
          if (done) {
            return chunks;
          }
          chunks.push(value);
          return pump();
        })
        .catch((err) => {
          handle403error(err);
        });
    }
    return pump();
  };
  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            navigate("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };

  return (
    <div style={{ margin: "0 20px" }}>
      <Backdrop className={classes.backdrop} open={backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="d-flex justify-content-end my-1">
        <button className="btn listing_addbutton" onClick={goBack}>
          {/* &#x2630;  */}
          List
        </button>
      </div>
      <TableContainer style={{ marginTop: "10px" }} component={Paper}>
        <Table
          className="workload card-table table-borderless"
          responsive
          striped
        >
          <TableHead style={{ backgroundColor: "cornflowerblue" }}>
            <TableRow>
              <TableCell>SN</TableCell>
              <TableCell>Name</TableCell>
              <TableCell align="right">Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.transactionalarrangementlist?.map((p, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {index + 1}
                </TableCell>
                <TableCell component="th" scope="row">
                  {check1pointpermission(
                    714,
                    permissionData,
                    rolepermission
                  ) ? (
                    <a
                      onClick={() => {
                        downloadAllTransactionalAgreement(
                          p.transactionalArrangementName
                        );
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {p.transactionalArrangementName}
                    </a>
                  ) : (
                    <>{p.transactionalArrangementName}</>
                  )}
                </TableCell>
                {check1pointpermission(715, permissionData, rolepermission) ? (
                  <TableCell
                    align="right"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setdeleteapplicationmodal(true);
                      setdeleteid(p.id);
                    }}
                  >
                    Delete
                  </TableCell>
                ) : null}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Modal
        isOpen={deleteapplicationmodal}
        onRequestClose={() => setdeleteapplicationmodal(false)}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <h4 style={{ marginTop: "10px" }}>
          Are you sure you want to delete current record ?{" "}
        </h4>
        <div
          style={{
            display: "flex",
            marginTop: "20px",
            justifyContent: "flex-end",
            gap: "10px",
            marginBottom: "10px",
          }}
        >
          <button
            className="btn listing_addbutton ml-1"
            onClick={deletestudentappform}
          >
            Yes
          </button>
          <button
            className="btn btn-secondary cancel_margin"
            onClick={() => setdeleteapplicationmodal(false)}
          >
            No
          </button>
        </div>
      </Modal>
      <Row>
        <Col md={6} style={{ textAlign: "left" }}>
          {props.transactionalarrangementlist?.length > 0 ? (
            <p style={{ marginTop: "15px" }}>
              {props.transactionalarrangementlist?.length} to{" "}
              {props.transactionalarrangementlist?.length} of{" "}
              {props.transactionalarrangementlist?.length} records
            </p>
          ) : (
            <p className="formfooterrecordstyle">No Record Found</p>
          )}
        </Col>
        <Col md={6}>{null}</Col>
      </Row>
    </div>
  );
};

export default ApplicationDetail;
