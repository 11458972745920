import React, { useEffect, useState } from "react";
import { Typography } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { makeStyles } from '@mui/styles';
import "./../../css/MainDashboard.css";

import { selectUser, logout } from "../../features/userSlice";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import base_url from "../../../api/bootapi";
import { toast } from "react-toastify";
import Chart from "react-google-charts";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    // marginTop: theme.spacing(2),
  },
}));
const MainDashboardBodybottomskillassessment = (props) => {
  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  const user = useSelector(selectUser);
  const classes = useStyles();
  const [filterresult, setfilterresult] = useState("Intake");
  const [filterclientresult, setfilterclientresult] = useState([]);
  const datepast = moment().subtract(1, "M").format("YYYY-MM-DDThh:mm:ss");
  const isoDateString = new Date().toISOString();
  const datenow = isoDateString.split(".")[0];
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        navigate("/");
      }
    });
    setfilterresult("Intake");
    getintakeresult(props.officeid);
  }, [props.officeid]);

  const getintakeresult = (value) => {
    setfilterclientresult([]);
    if (value) {
      if (value === "all") {
        axios
          .get(`${base_url.api1}/getEnquiryClientCountBasedOnIntake`, {
            headers: {
              Authorization: jwtToken,
            },
          })
          .then((res) => {
            for (let i = 0; i < res.data.data.length; i++) {
              if (res.data.data[i].workflowType === props.workflowType) {
                setfilterclientresult((prev) => [...prev, res.data.data[i]]);
              }
            }
            // setpiechartdata(data);
          })
          .catch((err) => {
            handle403error(err);
          });
      } else {
        axios
          .get(
            `${base_url.api1}/getClientEnquiryCountByOfficeIdBasedOnIntake`,
            {
              headers: {
                Authorization: jwtToken,
                officeId: Number(props.officeid),
              },
            }
          )
          .then((res) => {
            for (let i = 0; i < res.data.data.length; i++) {
              if (res.data.data[i].workflowType === props.workflowType) {
                setfilterclientresult((prev) => [...prev, res.data.data[i]]);
              }
            }
            // setpiechartdata(data);
          })
          .catch((err) => {
            handle403error(err);
          });
      }
    }
  };
  const handlefilter = (e) => {
    let filter = e.target.value;
    setfilterresult(e.target.value);
    setfilterclientresult([]);
    if (props.officeid === "all") {
      if (filter === "Intake") {
        axios
          .get(`${base_url.api1}/getEnquiryClientCountBasedOnIntake`, {
            headers: {
              Authorization: jwtToken,
            },
          })
          .then((res) => {
            for (let i = 0; i < res.data.data.length; i++) {
              if (res.data.data[i].workflowType === props.workflowType) {
                setfilterclientresult((prev) => [...prev, res.data.data[i]]);
              }
            }
            // setpiechartdata(data);
          })
          .catch((err) => {
            handle403error(err);
          });
      } else if (filter === "SuperAgent") {
        axios
          .get(`${base_url.api1}/getClientCountBasedOnSuperAgent`, {
            headers: {
              Authorization: jwtToken,
            },
          })
          .then((res) => {
            for (let i = 0; i < res.data.data.length; i++) {
              if (res.data.data[i].workflowType === props.workflowType) {
                setfilterclientresult((prev) => [...prev, res.data.data[i]]);
              }
            }
            // setpiechartdata(data);
          })
          .catch((err) => {
            handle403error(err);
          });
      } else if (filter === "SubAgent") {
        axios
          .get(`${base_url.api1}/getClientCountBasedOnSubAgent`, {
            headers: {
              Authorization: jwtToken,
            },
          })
          .then((res) => {
            for (let i = 0; i < res.data.data.length; i++) {
              if (res.data.data[i].workflowType === props.workflowType) {
                setfilterclientresult((prev) => [...prev, res.data.data[i]]);
              }
            }
            // setpiechartdata(data);
          })
          .catch((err) => {
            handle403error(err);
          });
      } else if (filter === "Provider") {
        axios
          .get(`${base_url.api1}/getEnquiryInstitutionCount`, {
            headers: {
              Authorization: jwtToken,
            },
          })
          .then((res) => {
            for (let i = 0; i < res.data.data.length; i++) {
              if (res.data.data[i].workflowType === props.workflowType) {
                setfilterclientresult((prev) => [...prev, res.data.data[i]]);
              }
            }
            // setpiechartdata(data);
          })
          .catch((err) => {
            handle403error(err);
          });
      }
    } else {
      if (filter === "Intake") {
        axios
          .get(
            `${base_url.api1}/getClientEnquiryCountByOfficeIdBasedOnIntake`,
            {
              headers: {
                Authorization: jwtToken,
                officeId: Number(props.officeid),
              },
            }
          )
          .then((res) => {
            for (let i = 0; i < res.data.data.length; i++) {
              if (res.data.data[i].workflowType === props.workflowType) {
                setfilterclientresult((prev) => [...prev, res.data.data[i]]);
              }
            }
            // setpiechartdata(data);
          })
          .catch((err) => {
            handle403error(err);
          });
      } else if (filter === "SuperAgent") {
        axios
          .get(`${base_url.api1}/getClientCountBasedOnSuperAgentByOfficeId`, {
            headers: {
              Authorization: jwtToken,
              officeId: Number(props.officeid),
            },
          })
          .then((res) => {
            for (let i = 0; i < res.data.data.length; i++) {
              if (res.data.data[i].workflowType === props.workflowType) {
                setfilterclientresult((prev) => [...prev, res.data.data[i]]);
              }
            }
            // setpiechartdata(data);
          })
          .catch((err) => {
            handle403error(err);
          });
      } else if (filter === "SubAgent") {
        axios
          .get(`${base_url.api1}/getClientCountBasedOnSubAgentByOfficeId`, {
            headers: {
              Authorization: jwtToken,
              officeId: Number(props.officeid),
            },
          })
          .then((res) => {
            for (let i = 0; i < res.data.data.length; i++) {
              if (res.data.data[i].workflowType === props.workflowType) {
                setfilterclientresult((prev) => [...prev, res.data.data[i]]);
              }
            }
            // setpiechartdata(data);
          })
          .catch((err) => {
            handle403error(err);
          });
      } else if (filter === "Provider") {
        axios
          .get(`${base_url.api1}/getEnquiryInstitutionCountByOfficeId`, {
            headers: {
              Authorization: jwtToken,
              officeId: Number(props.officeid),
            },
          })
          .then((res) => {
            for (let i = 0; i < res.data.data.length; i++) {
              if (res.data.data[i].workflowType === props.workflowType) {
                setfilterclientresult((prev) => [...prev, res.data.data[i]]);
              }
            }
            // setpiechartdata(data);
          })
          .catch((err) => {
            handle403error(err);
          });
      }
    }
  };
  const handleEvent = (event, picker) => {
    console.log(picker.startDate);
  };

  const handleCallback = (start, end, label) => {
    console.log(start, end, label);
  };

  const [datevalue, setdatevalue] = useState("Click to open");
  const handleApply = (event, picker) => {
    setdatevalue(
      `${picker.startDate.format("DD-MM-YYYY")} to ${picker.endDate.format(
        "DD-MM-YYYY"
      )}`
    );
    setfilterclientresult([]);
    if (props.officeid === "all") {
      axios
        .get(`${base_url.api1}/getClientCountBasedOnDate`, {
          headers: {
            Authorization: jwtToken,
            createdFromDate: picker.startDate.format("YYYY-MM-DD HH:MM:ss"),
            createdToDate: picker.endDate.format("YYYY-MM-DD HH:MM:ss"),
          },
        })
        .then((res) => {
          for (let i = 0; i < res.data.data.length; i++) {
            if (res.data.data[i].workflowType === props.workflowType) {
              setfilterclientresult((prev) => [...prev, res.data.data[i]]);
            }
          }
        })
        .catch((err) => {
          handle403error(err);
        });
    } else {
      axios
        .get(`${base_url.api1}/getClientCountByOfficeIdBasedOnDate`, {
          headers: {
            Authorization: jwtToken,
            officeId: Number(props.officeid),
            createdFromDate: picker.startDate.format("YYYY-MM-DD HH:MM:ss"),
            createdToDate: picker.endDate.format("YYYY-MM-DD HH:MM:ss"),
          },
        })
        .then((res) => {
          for (let i = 0; i < res.data.data.length; i++) {
            if (res.data.data[i].workflowType === props.workflowType) {
              setfilterclientresult((prev) => [...prev, res.data.data[i]]);
            }
          }
        })
        .catch((err) => {
          handle403error(err);
        });
    }
  };
  const data = [["Name", "Count"]];
  // console.log("data", data);
  if (filterresult === "Intake") {
    for (let i = 0; i < filterclientresult?.length; i++) {
      let newarr = [];
      newarr[0] = filterclientresult[i]?.intake;
      newarr[1] = Number(filterclientresult[i]?.enquiryClientCount);
      data.push(newarr);
    }
  } else if (filterresult === "SuperAgent") {
    for (let i = 0; i < filterclientresult?.length; i++) {
      let newarr = [];
      newarr[0] = filterclientresult[i]?.superAgent;
      newarr[1] = Number(filterclientresult[i]?.clientCount);
      data.push(newarr);
    }
  } else if (filterresult === "SubAgent") {
    for (let i = 0; i < filterclientresult?.length; i++) {
      let newarr = [];
      newarr[0] = filterclientresult[i]?.subAgent;
      newarr[1] = Number(filterclientresult[i]?.clientCount);
      data.push(newarr);
    }
  } else if (filterresult === "Provider") {
    for (let i = 0; i < filterclientresult?.length; i++) {
      let newarr = [];
      newarr[0] = filterclientresult[i]?.institutionName;
      newarr[1] = Number(filterclientresult[i]?.enquiryInstitutionCount);
      data.push(newarr);
    }
  } else if (filterresult === "DateFilter") {
    for (let i = 0; i < filterclientresult?.length; i++) {
      let newarr = [];
      newarr[0] = filterclientresult[i]?.workflowName;
      newarr[1] = Number(filterclientresult[i]?.clientCount);
      data.push(newarr);
    }
  }

  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            navigate("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };
  return (
    <div className="bodytop">
      <div className="maindashboard_select">
        <FormControl className={`${classes.formControl} office_dropdown`}>
          <InputLabel htmlFor="age-native-simple">Filter</InputLabel>
          <Select
            native
            value={filterresult}
            id="demo-simple-select3"
            onChange={(e) => handlefilter(e)}
          >
            <option value="Intake">Intake</option>
            <option value="SuperAgent">Super Agent</option>
            <option value="SubAgent">Sub Agent</option>
            <option value="Provider">Provider</option>
            <option value="DateFilter">DateFilter</option>
          </Select>
        </FormControl>
        {filterresult === "DateFilter" ? (
          <>
            <br />
            <label>
              <strong>Date:</strong>
            </label>
            <DateRangePicker
              onEvent={handleEvent}
              onCallback={handleCallback}
              onApply={handleApply}
            >
              <p className="datepickerrangevaluetext">{datevalue}</p>
            </DateRangePicker>
          </>
        ) : null}
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="dashboardmiddle_belowone">
            <Typography variant="h6">
              SKILL ASSESSMENT {filterresult.toUpperCase()} ENQUIRY{" "}
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                // borderBottom: "1px solid black",
                padding: "10px",
              }}
            >
              <Typography variant="h5" color="primary">
                Workflow Name
              </Typography>
              <Typography variant="h5" color="primary">
                {filterresult} Count
              </Typography>
            </div>
            {filterclientresult?.length > 0 ? (
              <>
                {filterclientresult?.map((p, index) => (
                  <div
                    key={index + 1}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      borderBottom: "1px solid black",
                      padding: "10px",
                    }}
                  >
                    {filterresult === "Intake" ? (
                      <>
                        <Typography variant="body1" color="primary">
                          {p.intake}
                        </Typography>
                        <Typography variant="body1" color="primary">
                          {p.enquiryClientCount}
                        </Typography>
                      </>
                    ) : (
                      <>
                        {filterresult === "SuperAgent" ? (
                          <>
                            <Typography variant="body1" color="primary">
                              {p.superAgent}
                            </Typography>
                            <Typography variant="body1" color="primary">
                              {p.clientCount}
                            </Typography>
                          </>
                        ) : (
                          <>
                            {filterresult === "SubAgent" ? (
                              <>
                                <Typography variant="body1" color="primary">
                                  {p.subAgent}
                                </Typography>
                                <Typography variant="body1" color="primary">
                                  {p.clientCount}
                                </Typography>
                              </>
                            ) : (
                              <>
                                {filterresult === "Provider" ? (
                                  <>
                                    <Typography variant="body1" color="primary">
                                      {p.institutionName}
                                    </Typography>
                                    <Typography variant="body1" color="primary">
                                      {p.enquiryInstitutionCount}
                                    </Typography>
                                  </>
                                ) : (
                                  <>
                                    {filterresult === "DateFilter" ? (
                                      <>
                                        <Typography
                                          variant="body1"
                                          color="primary"
                                        >
                                          {p.workflowName}
                                        </Typography>
                                        <Typography
                                          variant="body1"
                                          color="primary"
                                        >
                                          {p.clientCount}
                                        </Typography>
                                      </>
                                    ) : null}
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                ))}
              </>
            ) : (
              <p className="dashboardnodataptag">No Data</p>
            )}
          </div>
        </div>
        <div className="col-md-6">
          <div className="dashboardmiddle_belowone">
            <Typography variant="h6">{filterresult} Client Enquiry</Typography>
            <div>
              <Chart
                width={"500px"}
                height={"300px"}
                chartType="PieChart"
                loader={<div>Loading Chart</div>}
                data={data}
                options={{
                  title: "",
                  pieHole: 0.4,
                }}
                rootProps={{ "data-testid": "1" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainDashboardBodybottomskillassessment;
