import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Card, CardSubtitle, CardText, Col, Row, Button } from "reactstrap";
import axios from "axios";
import base_url from "../../api/bootapi";
import { toast } from "react-toastify";
import moment from "moment";
import { addtoClient } from "../features/clientSlice";
import { useDispatch, useSelector } from "react-redux";
import "./../css/Responsive.css";
import {
  getworkflowType,
  setdashboardcheck,
  selectUser,
  gettabvalue,
  logout,
} from "../features/userSlice";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useForm } from "react-hook-form";
import PasswordStrengthBar from "react-password-strength-bar";
import { useStyles } from "../../Utilities/CSSUtilities";
// import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/style.css";
import DatePicker from "react-datepicker";
import callsaveauditapi from "../../services/auditservice";
import {
  check4pointpermission,
  check1pointpermission,
} from "./../Permission/permission";
import { format } from "date-fns";

const CreateProspect = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  const classes = useStyles();
  const [region, setregion] = useState();
  const [country1, setcountry] = useState();
  const [country1Passport, setcountryPassport] = useState();
  const [backdropopen, setbackdropopen] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [value, setValue] = React.useState("");
  const [subagentid, setsubagentid] = useState();
  const [superagentid, setSuperAgentId] = useState();
  const [Insurancedate, setInsurancedate] = useState();
  const isw_user_name = localStorage.getItem("isw_user_name");
  const isw_adminId = localStorage.getItem("isw_adminId");
  const isw_user_email = localStorage.getItem("isw_user_email");
  const [password, setpassword] = useState();
  const [confirmpassword, setconfirmpassword] = useState();
  const [subagentname, setsubagentname] = useState();
  const [superagentname, setSuperAgentName] = useState();
  const [dateofbirthdate, setdateofbirthdate] = useState(null);
  const [passportissuedate, setpassportissuedate] = useState(null);
  const [passportexpirydate, setpassportexpirydate] = useState(null);
  const [visaissuedate, setvisaissuedate] = useState(null);
  const [visaexpirydate, setvisaexpirydate] = useState(null);
  const [healthcoverdate, sethealthcoverdate] = useState(null);
  const [insuranceexpirydate, setinsuranceexpirydate] = useState(null);
  const [membershipexpirydate, setmembershipexpirydate] = useState(null);
  const isoDateString = new Date().toISOString();
  const datenow = isoDateString.split(".")[0];
  const user2 = useSelector(selectUser);
  const [inputtype, setinputtype] = useState("password");
  const [inputtype2, setinputtype2] = useState("password");
  const ipaddress = localStorage.getItem("AdminIpAddress");
  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);
  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        navigate("/");
      }
    });
    getPermissions();
  }, []);

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);

    if (check1pointpermission(301, permission_obj, resource_obj)) {
      dispatch(setdashboardcheck(5));
      getIntake();
      getOffice();
      getVisa();
      getHealth();
      getsubagent();
      getHealthCover();
      getSuperAgent();
      getClientSource();
    } else {
      //console.log('No access to the page');
      navigate("/errorPage");
    }
  };

  const [allIntake, setAllIntake] = useState();
  const getIntake = () => {
    axios
      .get(`${base_url.api1}/getAllIntake`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        let intakeData = response.data.data;
        let intakeObj = [];
        for (let i = 0; i < intakeData.length; i++) {
          let data = {
            createdIntake: moment(intakeData[i].createdIntake).format(
              "YYYY-MM"
            ),
            val: moment(intakeData[i].name).format("YYYYMM"),
            id: intakeData[i].id,
            name: intakeData[i].name,
            status: intakeData[i].status,
          };
          intakeObj.push(data);
        }
        let newintakeObj = intakeObj.sort(function (a, b) {
          return b.val - a.val;
        });
        setAllIntake(newintakeObj);
      })
      .catch((error) => {
        handle403error(error);
      });
  };

  const [allVisa, setallVisa] = useState();
  const getVisa = () => {
    axios
      .get(`${base_url.api1}/getAllVisaType`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        setallVisa(response.data.data);
      })
      .catch((error) => {
        handle403error(error);
      });
  };

  const [allClientSource, setClientSource] = useState();
  const getClientSource = () => {
    axios
      .get(`${base_url.api1}/getAllClientSourceUsingQuery`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        setClientSource(response.data.data);
      })
      .catch((error) => {
        handle403error(error);
      });
  };

  const [allHealth, setallHealth] = useState();
  const getHealth = () => {
    axios
      .get(`${base_url.api1}/findAllInsurance`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        setallHealth(response.data.data);
      })
      .catch((error) => {
        handle403error(error);
      });
  };
  const [allHealthCover, setallHealthCover] = useState();
  const getHealthCover = () => {
    axios
      .get(`${base_url.api1}/getAllHealthCoverType`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        setallHealthCover(response.data.data);
      })
      .catch((error) => {
        handle403error(error);
      });
  };
  const [subagent, setsubagent] = useState();
  const getsubagent = () => {
    axios
      .get(`${base_url.api3}/findAgentsForDropDownList`, {
        headers: {
          Authorization: jwtToken,
          agentType: "Sub Agent",
        },
      })
      .then((response) => {
        setsubagent(response.data.data);
      })
      .catch((error) => {
        handle403error(error);
      });
  };
  const [superAgentData, setSuperAgent] = useState();
  const getSuperAgent = () => {
    axios
      .get(`${base_url.api3}/findAgentsForDropDownList`, {
        headers: {
          Authorization: jwtToken,
          agentType: "Super Agent",
        },
      })
      .then((response) => {
        setSuperAgent(response.data.data);
      })
      .catch((error) => {
        handle403error(error);
      });
  };

  const [availableoffice, setAvailableOffice] = useState(null);
  const getOffice = () => {
    axios
      .get(`${base_url.api1}/findAllOffice`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        //console.log(response.data.data);
        setAvailableOffice(response.data.data);
      })
      .catch((error) => {
        handle403error(error);
      });
  };

  const [availableconsultant, setConsultant] = useState();
  // const getConsultant = (p) => {
  //   axios
  //     .get(`${base_url.api2}/getUserByRoleId`, {
  //       headers: {
  //         Authorization: jwtToken,
  //         roleId: 4,
  //         direction: "ASC",
  //         columnName: "firstName",
  //       },
  //     })
  //     .then(
  //       (res) => {
  //         setConsultant(res.data.data);
  //       },
  //       (error) => {
  //         // toast.error(JSON.stringify(error.response.data.message));
  //         //console.log(JSON.stringify(error.response));
  //       }
  //     );
  // };

  const [selectedOfficeid, setselectedOfficeid] = useState(null);
  const [selectedOfficename, setselectedOfficename] = useState(null);
  const [selectedOfficecode, setselectedOfficecode] = useState(null);
  const handleChangeOffice = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("id");
    const optionElementName = optionElement.getAttribute("value");
    const optionElementofficecode = optionElement.getAttribute("officecode");
    setselectedOfficeid(optionElementId);
    setselectedOfficename(optionElementName);
    setselectedOfficecode(optionElementofficecode);
    //console.log(optionElementofficecode);
    //console.log(user?.isw_available_Office);
    axios
      .get(`${base_url.api2}/getUsersSelectByOfficeIdAndStatus`, {
        headers: {
          Authorization: jwtToken,
          officeId: Number(optionElementId),
          status: 1,
        },
      })
      .then((response) => {
        setConsultant(response.data.data);
      })
      .catch((error) => {
        toast.error(JSON.stringify(error.response.data.message));
        handle403error(error);
      });
  };

  // const [selectedhealthid, setselectedhealthid] = useState(null);
  const [selectedhealthname, setselectedhealthname] = useState(null);
  const handleChangeHealth = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("id");
    const optionElementName = optionElement.getAttribute("value");
    //console.log(optionElementName);
    // setselectedhealthid(optionElementId);
    setselectedhealthname(optionElementName);
  };

  // const [selectedhealthid, setselectedhealthid] = useState(null);
  const [selectedIntakename, setselectedIntakename] = useState(null);
  const handleChangeIntake = (date) => {
    const intakeDate = date ? format(date, "MMM-yyyy") : null;
    //console.log(optionElementName);
    // setselectedhealthid(optionElementId);
    setselectedIntakename(intakeDate);
  };

  // const [selectedhealthid, setselectedhealthid] = useState(null);
  const [selectedClientSource, setselectedClientSource] = useState(null);
  const handleClientSource = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("id");
    const optionElementName = optionElement.getAttribute("value");
    //console.log(optionElementName);
    // setselectedhealthid(optionElementId);
    if (optionElementName === "Sub Agent") {
      //console.log("hello Sangam1");
    } else if (optionElementName === "Referral") {
      //console.log("hello Sangam2");
    } else {
      //console.log("hello Sangam3");
    }
    setselectedClientSource(optionElementName);
  };

  const [selectedConsultantid, setselectedConsultantid] = useState(null);
  const [selectedConsultantName, setselectedConsultantName] = useState(null);
  const [selectedConsultantemail, setselectedConsultantemail] = useState(null);
  const handleChangeConsultant = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("id");
    const optionElementName = optionElement.getAttribute("consultantname");
    const optionElementconsultantName = optionElement.getAttribute("value");
    setselectedConsultantid(optionElementId);
    setselectedConsultantName(optionElementName);
    setselectedConsultantemail(optionElementconsultantName);
  };

  const goback = () => {
    var client_prev_path = localStorage.getItem("client_prev_path");
    navigate(client_prev_path);
  };

  const selectCountry = (val) => {
    setcountry(val);
  };
  const selectCountryPassport = (val) => {
    setcountryPassport(val);
  };
  const selectRegion = (val) => {
    setregion(val);
  };
  const [errorsecondaryemail, seterrorsecondaryemail] = useState(false);
  const onSubmit = (data2) => {
    if (data2.secondaryemail === data2.email) {
      seterrorsecondaryemail(true);
      return false;
    }
    seterrorsecondaryemail(false);

    // if (data2.confirmpassword === chkPassState || chkPassState === "") {
    const isoDateString = new Date().toISOString();
    const datenow = isoDateString.split(".")[0];
    let addedOn = datenow;
    let address = data2.street;
    let alias = data2.alias;
    let ban = 0;
    let city = data2.city;
    let clientSource = selectedClientSource;
    let comment = data2.comment;
    let company = "string";
    let contactPreference = value;
    let country = country1;
    let currentlyStudyingInstitutionName = data2.institutionname;
    let dob = dateofbirthdate
      ? moment(dateofbirthdate).format("YYYY-MM-DDThh:mm:ss")
      : null;
    let email = data2.email.trim();
    let firstName = data2.firstname.trim();
    let gender = data2.gender;
    let id = 0;
    let insuranceExpiryDate = insuranceexpirydate
      ? moment(insuranceexpirydate).format("YYYY-MM-DDThh:mm:ss")
      : null;
    let insuranceIssueDate = healthcoverdate
      ? moment(healthcoverdate).format("YYYY-MM-DDThh:mm:ss")
      : null;
    let insuranceProvider = data2.insuranceprovider;
    let insuranceType = data2.insurancetype;
    let intake = selectedIntakename;
    let lastName = data2.lastname.trim();
    let membershipExpiry = membershipexpirydate
      ? moment(membershipexpirydate).format("YYYY-MM-DDThh:mm:ss")
      : null;
    let membershipNumber = Number(data2.membershipnumber);
    let middleName = data2.middlename.trim();
    let nationality = country1;
    let passport = country1Passport;
    let passportExpiryDate = passportexpirydate
      ? moment(passportexpirydate).format("YYYY-MM-DDThh:mm:ss")
      : null;
    let passportIssueDate = passportissuedate
      ? moment(passportissuedate).format("YYYY-MM-DDThh:mm:ss")
      : null;
    let passportNumber = data2.passportnumber;
    let phone = data2.phone;
    let secondaryphone = data2.secondaryphone;
    let photo = "string";
    let pincode = data2.pincode;
    let policyNumber = data2.policynumber;
    let secondaryEmail = data2.secondaryemail.trim();
    let state = region;
    let status = Number(data2.status);
    let street = data2.street;
    let subAgent = data2.subagent;
    let subAgentId = Number(subagentid);
    let superAgent = data2.superagent;
    let superAgentId = Number(superagentid);
    let username = data2.email.trim();
    let usi = data2.usi;
    let visaExpiry = visaexpirydate
      ? moment(visaexpirydate).format("YYYY-MM-DDThh:mm:ss")
      : null;
    let visaIssueDate = visaissuedate
      ? moment(visaissuedate).format("YYYY-MM-DDThh:mm:ss")
      : null;
    let visaType = data2.visaType;
    let consultantId = Number(selectedConsultantid);
    let consultantName = selectedConsultantName;
    let consultantEmail = selectedConsultantemail;
    // let lastLogin = datenow;
    let officeId = Number(selectedOfficeid);
    let officeName = selectedOfficename;
    let officeCode = selectedOfficecode;
    let addedOfficeId = Number(selectedOfficeid);
    let addedOfficeName = selectedOfficename;
    let addedOfficeCode = selectedOfficecode;
    let createdBy = isw_user_name + " (" + isw_user_email + ")";
    // let password = data2.password;
    let password = chkPassState ? chkPassState.trim() : "String";

    // if (chkPass == false) {
    //   password = chkPassState.trim();
    // }
    let passwordRecoveryCode = "String";
    let passwordRecoveryDate = datenow;
    let roleId = 8;
    let updatedOn = datenow;
    let clientRelation = data2.clientRelation;
    let clientRelativeName = data2.clientRelativeName;
    let clientRelativeEmail = data2.clientRelativeEmail;
    let clientRelativePhone = data2.clientRelativePhone;
    let deposit = checkBtn;

    let clientDetail = {
      addedOn,
      address,
      alias,
      ban,
      city,
      clientSource,
      comment,
      company,
      contactPreference,
      country,
      currentlyStudyingInstitutionName,
      dob,
      email,
      firstName,
      gender,
      id,
      insuranceExpiryDate,
      insuranceIssueDate,
      insuranceProvider,
      insuranceType,
      intake,
      lastName,
      membershipExpiry,
      membershipNumber,
      middleName,
      nationality,
      passport,
      passportExpiryDate,
      passportIssueDate,
      passportNumber,
      phone,
      secondaryphone,
      photo,
      pincode,
      policyNumber,
      secondaryEmail,
      state,
      status,
      street,
      subAgent,
      superAgent,
      subAgentId,
      superAgentId,
      username,
      usi,
      visaExpiry,
      visaIssueDate,
      visaType,
      subAgentName: subagentname,
      superAgentName: superagentname,
      deposit,
    };

    let data = {
      addedOn,
      address,
      alias,
      ban,
      city,
      clientDetail,
      clientSource,
      consultantId,
      consultantName,
      consultantEmail,
      contactPreference,
      country,
      email,
      firstName,
      id,
      insuranceExpiryDate,
      insuranceIssueDate,
      insuranceProvider,
      insuranceType,
      phone,
      secondaryphone,
      lastName,
      middleName,
      officeCode,
      officeId,
      officeName,
      addedOfficeCode,
      addedOfficeId,
      addedOfficeName,
      createdBy,
      password,
      passwordRecoveryCode,
      passwordRecoveryDate,
      pincode,
      policyNumber,
      roleId,
      secondaryEmail,
      state,
      status,
      updatedOn,
      username,
      visaExpiry,
      visaIssueDate,
      visaType,
      clientRelation,
      clientRelativeName,
      clientRelativeEmail,
      clientRelativePhone,
      deposit,
      client: 0,
      prospect: 0,
    };

    console.log("593--->", data);
    postCustomer(data, data2.enquiryLeadType);
    // } else {
    //   alert("Confirm Password is not equal to password");
    // }
  };
  const handleSaveApplication = (data2) => {
    if (data2.secondaryemail === data2.email) {
      seterrorsecondaryemail(true);
      return false;
    }
    seterrorsecondaryemail(false);

    // if (data2.confirmpassword === chkPassState || chkPassState === "") {
    const isoDateString = new Date().toISOString();
    const datenow = isoDateString.split(".")[0];
    let addedOn = datenow;
    let address = data2.street;
    let alias = data2.alias;
    let ban = 0;
    let city = data2.city;
    let clientSource = selectedClientSource;
    let comment = data2.comment;
    let company = "string";
    let contactPreference = value;
    let country = country1;
    let currentlyStudyingInstitutionName = data2.institutionname;
    let dob = dateofbirthdate
      ? moment(dateofbirthdate).format("YYYY-MM-DDThh:mm:ss")
      : null;
    let email = data2.email.trim();
    let firstName = data2.firstname.trim();
    let gender = data2.gender;
    let id = 0;
    let insuranceExpiryDate = insuranceexpirydate
      ? moment(insuranceexpirydate).format("YYYY-MM-DDThh:mm:ss")
      : null;
    let insuranceIssueDate = healthcoverdate
      ? moment(healthcoverdate).format("YYYY-MM-DDThh:mm:ss")
      : null;
    let insuranceProvider = data2.insuranceprovider;
    let insuranceType = data2.insurancetype;
    let intake = selectedIntakename;
    let lastName = data2.lastname.trim();
    let membershipExpiry = membershipexpirydate
      ? moment(membershipexpirydate).format("YYYY-MM-DDThh:mm:ss")
      : null;
    let membershipNumber = Number(data2.membershipnumber);
    let middleName = data2.middlename.trim();
    let nationality = country1;
    let passport = country1Passport;
    let passportExpiryDate = passportexpirydate
      ? moment(passportexpirydate).format("YYYY-MM-DDThh:mm:ss")
      : null;
    let passportIssueDate = passportissuedate
      ? moment(passportissuedate).format("YYYY-MM-DDThh:mm:ss")
      : null;
    let passportNumber = data2.passportnumber;
    let phone = data2.phone;
    let secondaryphone = data2.secondaryphone;
    let photo = "string";
    let pincode = data2.pincode;
    let policyNumber = data2.policynumber;
    let secondaryEmail = data2.secondaryemail.trim();
    let state = region;
    let status = Number(data2.status);
    let street = data2.street;
    let subAgent = data2.subagent;
    let subAgentId = Number(subagentid);
    let superAgent = data2.superagent;
    let superAgentId = Number(superagentid);
    let username = data2.email.trim();
    let usi = data2.usi;
    let visaExpiry = visaexpirydate
      ? moment(visaexpirydate).format("YYYY-MM-DDThh:mm:ss")
      : null;
    let visaIssueDate = visaissuedate
      ? moment(visaissuedate).format("YYYY-MM-DDThh:mm:ss")
      : null;
    let visaType = data2.visaType;
    let consultantId = Number(selectedConsultantid);
    let consultantName = selectedConsultantName;
    let consultantEmail = selectedConsultantemail;
    // let lastLogin = datenow;
    let officeId = Number(selectedOfficeid);
    let officeName = selectedOfficename;
    let officeCode = selectedOfficecode;
    let addedOfficeId = Number(selectedOfficeid);
    let addedOfficeName = selectedOfficename;
    let addedOfficeCode = selectedOfficecode;
    let createdBy = isw_user_name + " (" + isw_user_email + ")";
    // let password = data2.password;
    let password = chkPassState ? chkPassState.trim() : "String";

    // if (chkPass == false) {
    //   password = chkPassState.trim();
    // }
    let passwordRecoveryCode = "String";
    let passwordRecoveryDate = datenow;
    let roleId = 8;
    let updatedOn = datenow;
    let clientRelation = data2.clientRelation;
    let clientRelativeName = data2.clientRelativeName;
    let clientRelativeEmail = data2.clientRelativeEmail;
    let clientRelativePhone = data2.clientRelativePhone;
    let deposit = checkBtn;

    let clientDetail = {
      addedOn,
      address,
      alias,
      ban,
      city,
      clientSource,
      comment,
      company,
      contactPreference,
      country,
      currentlyStudyingInstitutionName,
      dob,
      email,
      firstName,
      gender,
      id,
      insuranceExpiryDate,
      insuranceIssueDate,
      insuranceProvider,
      insuranceType,
      intake,
      lastName,
      membershipExpiry,
      membershipNumber,
      middleName,
      nationality,
      passport,
      passportExpiryDate,
      passportIssueDate,
      passportNumber,
      phone,
      secondaryphone,
      photo,
      pincode,
      policyNumber,
      secondaryEmail,
      state,
      status,
      street,
      subAgent,
      superAgent,
      subAgentId,
      superAgentId,
      username,
      usi,
      visaExpiry,
      visaIssueDate,
      visaType,
      subAgentName: subagentname,
      superAgentName: superagentname,
      deposit,
    };

    let data = {
      addedOn,
      address,
      alias,
      ban,
      city,
      clientDetail,
      clientSource,
      consultantId,
      consultantName,
      consultantEmail,
      contactPreference,
      country,
      email,
      firstName,
      id,
      insuranceExpiryDate,
      insuranceIssueDate,
      insuranceProvider,
      insuranceType,
      phone,
      secondaryphone,
      lastName,
      middleName,
      officeCode,
      officeId,
      officeName,
      addedOfficeCode,
      addedOfficeId,
      addedOfficeName,
      createdBy,
      password,
      passwordRecoveryCode,
      passwordRecoveryDate,
      pincode,
      policyNumber,
      roleId,
      secondaryEmail,
      state,
      status,
      updatedOn,
      username,
      visaExpiry,
      visaIssueDate,
      visaType,
      clientRelation,
      clientRelativeName,
      clientRelativeEmail,
      clientRelativePhone,
      deposit,
      client: 0,
      prospect: 0,
    };
    //console.log(data);
    console.log("client detail", data);
    postCustomerNavigate(data, data2.enquiryLeadType);
    // } else {
    //   alert("Confirm Password is not equal to password");
    // }
  };
  const [respclientid, setrespclientid] = useState();
  const [respclientemail, setrespclientemail] = useState();
  const postCustomer = (data, enquiryLeadType) => {
    setbackdropopen(true);
    axios
      .post(`${base_url.api3}/createClient`, data, {
        headers: {
          enquiryLeadType: enquiryLeadType,
        },
      })
      .then((response) => {
        toast.success("Success");
        setrespclientid(response.data.data.id);
        setrespclientemail(response.data.data.email);
        let logs =
          isw_user_name +
          " Created Prospect " +
          response.data.data.firstName +
          " " +
          response.data.data.middleName +
          " " +
          response.data.data.lastName +
          "(" +
          response.data.data.email +
          ").";
        callsaveauditapi(logs, dispatch, navigate);
        setbackdropopen(false);
        navigate("/prospect-list");
      })
      .catch((error) => {
        toast.error(JSON.stringify(error.response.data.message));
        handle403error(error);
        setbackdropopen(false);
      });
  };

  const postCustomerNavigate = (data, enquiryLeadType) => {
    setbackdropopen(true);
    axios
      .post(`${base_url.api3}/createClient`, data, {
        headers: {
          enquiryLeadType: enquiryLeadType,
        },
      })
      .then((response) => {
        setbackdropopen(false);
        toast.success("Success");
        setrespclientid(response.data.data.id);
        setrespclientemail(response.data.data.email);

        let logs =
          isw_user_name +
          " Created Prospect " +
          response.data.data.firstName +
          " " +
          response.data.data.middleName +
          " " +
          response.data.data.lastName +
          "(" +
          response.data.data.email +
          ").";
        callsaveauditapi(logs, dispatch, navigate);
        console.log("Client ID--->", response.data.data.id);
        console.log("Client Email---->", response.data.data.email);
        gotoApplication(response.data.data.id, response.data.data.email);
      })
      .catch((error) => {
        toast.error(JSON.stringify(error.response.data.message));
        handle403error(error);
        setbackdropopen(false);
      });
  };

  const [chkPass, setchkPass] = useState();
  const [chkPassState, setchkPassState] = useState();
  // const handlePassword = (e) => {
  //   var decimal =
  //     /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
  //   //console.log(e.target.value);
  //   if (e.target.value.match(decimal)) {
  //     setchkPass(false);
  //     setchkPassState(e.target.value);
  //     return true;
  //   } else {
  //     setchkPass(true);
  //     // setchkPassState(e.target.value);
  //     return false;
  //   }
  // };

  const gotoApplication = (id, email) => {
    localStorage.setItem("prev_path", "/create-prospect");
    localStorage.setItem("selected_client_id", id);
    dispatch(getworkflowType("Admission"));
    dispatch(gettabvalue(0));
    navigate("/view-enquiry-workflowList/" + id + "/" + email);
  };

  const [checkboxstate, setState] = React.useState({
    deposit: false,
  });
  const checkboxchange = (event) => {
    setState({ ...checkboxstate, [event.target.name]: event.target.checked });
  };

  const [checkBtn, setcheckBtn] = useState();

  function getCheckboxStatus(event) {
    var status = document.getElementById("flexCheckDefault").checked;
    if (status) {
      setState({ ...checkboxstate, [event.target.name]: event.target.checked });
      //console.log(event.target.checked, status);
      setcheckBtn(1);
    } else {
      setState({
        ...checkboxstate,
        [event.target.name]: event.target.unchecked,
      });
      //console.log(event.target.checked, status);
      setcheckBtn(0);
    }
  }

  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            navigate("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };
  return (
    <div>
      <Backdrop className={classes.backdrop} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* <Card className="commoncomponent_cardcss"> */}
      <CardSubtitle className="font-weight-bold"></CardSubtitle>
      <div
        className="page-head d-flex justify-content-between p-4 "
        style={{ marginTop: "4.6rem" }}
      >
        <div className="breadcrums">
          <h3 className="page-title bold">Manage Candidates</h3>
          <Link to="/" className="b-link">
            Home
          </Link>
          <span className="s_span ">&raquo;</span>
          <a className="b-link">Candidate</a>
          <span className="s_span ">&raquo;</span>
          <Link to="/prospect-list" className="b-link">
            List
          </Link>
          <span className="s_span ">&raquo;</span>
          <Link to="/create-prospect" className="b-link active">
            Add
          </Link>
        </div>
        <button onClick={goback} className="btn listing_addbutton">
          Back
        </button>
      </div>
      <div style={{ padding: "1.5rem", border: "1.5rem solid #e9ebf2" }}>
        {/* <Row>
            <Col md={6}>
              <CardText className={classes.headtext}>
                <strong>Client</strong>
              </CardText>
            </Col>
            <Col md={6} className="d-flex justify-content-end">
              <button className="btn listing_addbutton" onClick={goback}>
                &#x2630; List
              </button>
            </Col>
          </Row> */}
        {/* <CardText className={classes.headtext}>Create Admin User</CardText> */}
        <form id="myForm" onSubmit={handleSubmit(onSubmit)}>
          <div className="form-inner-admin" style={{ marginTop: "-25px" }}>
            <div className="form-group creatadmin2">
              <div
                style={{
                  display: "flex",
                  marginTop: "10px",
                  paddingBottom: "30px",
                }}
              >
                <p className="provider_containertitle">Office Use</p>
                {/* <hr style={{ flex: "1", marginLeft: "20px" }} /> */}
              </div>
              <Row>
                <Col md={3}>
                  <label className="area_rowslable_skill">ID</label>
                  <input
                    className="form-control underline-input"
                    name="clientid"
                    type="text"
                    value={respclientid}
                    readOnly
                  />
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_skill">
                    Branch<span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    style={{ backgroundColor: "#f5f5f9" }}
                    className="form-control"
                    onInput={handleChangeOffice}
                    name="branch"
                    ref={register({ required: true })}
                    // required
                  >
                    <option value="">- Select Branch -</option>
                    {user2?.isw_available_Office?.map((p, index) => (
                      <option
                        key={p.officeId}
                        id={p.officeId}
                        value={p.officeName}
                        officecode={p.officeCode}
                      >
                        {p.officeName}
                      </option>
                    ))}
                  </select>
                  {errors.branch && (
                    <p className="errormessage">Branch is Required</p>
                  )}
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_skill">
                    Consultant<span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    style={{ backgroundColor: "#f5f5f9" }}
                    className="form-control"
                    onInput={handleChangeConsultant}
                    name="consultant"
                    ref={register({ required: true })}
                    // required
                  >
                    <option value="">- Select Consultant -</option>
                    {availableconsultant?.map((cuntitem) => (
                      <option
                        key={cuntitem.id}
                        value={cuntitem.email}
                        id={cuntitem.id}
                        consultantname={`${cuntitem.first_name} ${cuntitem.last_name}`}
                      >
                        {cuntitem.first_name} {cuntitem.last_name}
                      </option>
                    ))}
                  </select>
                  {errors.consultant && (
                    <p className="errormessage">Consultant is Required</p>
                  )}
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_skill">
                    Client Source<span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    style={{ backgroundColor: "#f5f5f9" }}
                    name="clientsource"
                    className="form-control"
                    onInput={handleClientSource}
                    ref={register({ required: true })}
                    // required
                  >
                    <option value="">- Select Source -</option>
                    {allClientSource?.map((cuntitem) => (
                      <option
                        key={cuntitem.id}
                        value={cuntitem.name}
                        id={cuntitem.id}
                      >
                        {cuntitem.name}
                      </option>
                    ))}
                  </select>
                  {errors.clientsource && (
                    <p className="errormessage">ClientSource is Required</p>
                  )}
                </Col>
              </Row>
            </div>
            <div style={{ display: "flex", marginTop: "10px" }}>
              <p className="provider_containertitle">Personal Details</p>
              {/* <hr style={{ flex: "1", marginLeft: "20px" }} /> */}
            </div>
            <div className="form-group creatadmin2">
              <div className="d-flex justify-content-start">
                <Col md={3}>
                  <label className="area_rowslable_skill">
                    First Name<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="firstname"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register({ required: true })}
                    // required
                  />
                  {errors.firstname && (
                    <p className="errormessage">FirstName is Required</p>
                  )}
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_skill">Middle Name</label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="middlename"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register}
                  />
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_skill">
                    Last Name<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="lastname"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register({ required: true })}
                    // required
                  />
                  {errors.lastname && (
                    <p className="errormessage">Lastname is Required</p>
                  )}
                </Col>
                {selectedClientSource === "Sub Agent" ? (
                  <Col md={3}>
                    <label className="area_rowslable_skill">Sub Agent</label>
                    <select
                      style={{ backgroundColor: "#f5f5f9" }}
                      className="form-control"
                      name="subagent"
                      onInput={(event) => {
                        const index = event.target.selectedIndex;
                        const optionElement = event.target.childNodes[index];
                        const optionElementId =
                          optionElement.getAttribute("id");
                        const optionElementrealId =
                          optionElement.getAttribute("realid");
                        setsubagentname(optionElementId);
                        setsubagentid(optionElementrealId);
                      }}
                      ref={register}
                    >
                      <option value="">- Select sub agent -</option>
                      {subagent?.map((cuntitem) => (
                        <option
                          key={cuntitem.id}
                          value={cuntitem.username}
                          id={cuntitem.name}
                          realid={cuntitem.id}
                        >
                          {cuntitem.name}
                        </option>
                      ))}
                    </select>
                  </Col>
                ) : selectedClientSource === "Referer" ? (
                  <Col md={3}>
                    <label className="area_rowslable_skill">Referer</label>
                    <select
                      style={{ backgroundColor: "#f5f5f9" }}
                      className="form-control"
                      name="subagent"
                      onInput={(event) => {
                        const index = event.target.selectedIndex;
                        const optionElement = event.target.childNodes[index];
                        const optionElementId =
                          optionElement.getAttribute("id");
                        const optionElementrealId =
                          optionElement.getAttribute("realid");
                        setsubagentname(optionElementId);
                        setsubagentid(optionElementrealId);
                      }}
                      ref={register}
                    >
                      <option value="">- Select sub agent -</option>
                      {subagent?.map((cuntitem) => (
                        <option
                          key={cuntitem.id}
                          value={cuntitem.username}
                          id={cuntitem.name}
                          realid={cuntitem.id}
                        >
                          {cuntitem.name}
                        </option>
                      ))}
                    </select>
                  </Col>
                ) : (
                  <div></div>
                )}
                <Col md={3}>
                  <label className="area_rowslable_skill">
                    Gender<span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    style={{ backgroundColor: "#f5f5f9" }}
                    className="form-control"
                    required
                    name="gender"
                    ref={register({ required: true })}
                  >
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </select>
                  {errors.gender && (
                    <p className="errormessage">Gender is Required</p>
                  )}
                </Col>
              </div>
            </div>
            <div className="form-group creatadmin2">
              <div className="d-flex justify-content-start">
                <Col md={3}>
                  <label className="area_rowslable_skill">Date Of Birth</label>
                  <DatePicker
                    selected={
                      dateofbirthdate ? new Date(dateofbirthdate) : null
                    }
                    onChange={(date) => {
                      setdateofbirthdate(date);
                      //console.log(date);
                    }}
                    dateFormat="dd-MM-yyyy"
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="Select Date"
                  />
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_skill">Alias</label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="alias"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register}
                    // required
                  />
                </Col>
                <Col md={3}></Col>
              </div>
            </div>
            <div style={{ display: "flex", marginTop: "10px" }}>
              <p className="provider_containertitle">Contact Details</p>
              {/* <hr style={{ flex: "1", marginLeft: "20px" }} /> */}
            </div>
            <div className="form-group creatadmin2">
              <Row>
                <Col md={3}>
                  <label className="area_rowslable_skill">
                    Email<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="email"
                    autoComplete="off"
                    style={{ backgroundColor: "#f5f5f9" }}
                    className="form-control underline-input"
                    name="email"
                    ref={register({ required: true })}
                    // required
                  />
                  {errors.email && (
                    <p className="errormessage">Email is Required</p>
                  )}
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_skill">
                    Secondary Email
                  </label>
                  <input
                    type="email"
                    autoComplete="off"
                    className="form-control underline-input"
                    name="secondaryemail"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register}
                    // required
                  />
                  {errorsecondaryemail && (
                    <p className="errormessage">
                      SecondaryEmail and Email should not be same{" "}
                    </p>
                  )}
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_skill">
                    Phone (primary)
                  </label>
                  {/* <PhoneInput
                        // country={'us'}
                        value={phonevalue}
                        onChange={(phone) => setphonevalue(phone)}
                      /> */}
                  <input
                    type="number"
                    onWheel={(event) => event.currentTarget.blur()}
                    className="form-control underline-input"
                    name="phone"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register}
                    // required
                  />
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_skill">
                    Phone (secondary)
                  </label>
                  <input
                    type="number"
                    onWheel={(event) => event.currentTarget.blur()}
                    className="form-control underline-input"
                    name="secondaryphone"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register}
                  />
                </Col>
              </Row>
            </div>
            <div className="form-group creatadmin2">
              <Row>
                <Col md={3}>
                  <label className="area_rowslable_skill">Password</label>
                  {/* <input
                        type="password"
                        className="form-control underline-input"
                        name="password"
                        onChange={handlePassword}
                        required
                      />
                      {chkPass ? (
                        <p className="errormessage">
                          Password must have at least 8 characters with at least
                          one lowercase letter, one uppercase letter, one
                          numeric digit, and one special character
                        </p>
                      ) : null} */}
                  <div style={{ position: "relative" }}>
                    <input
                      type={inputtype}
                      className="form-control underline-input"
                      value={chkPassState}
                      autoComplete="new-password"
                      style={{ backgroundColor: "#f5f5f9" }}
                      onChange={(e) => {
                        setchkPassState(e.target.value);
                        setchkPass(false);
                      }}
                      // required
                    />
                    {inputtype === "password" ? (
                      <i
                        className="fa fa-eye-slash fonticonpositioning"
                        onClick={() => setinputtype("text")}
                        aria-hidden="true"
                        style={{ marginTop: "16px" }}
                      ></i>
                    ) : (
                      <i
                        className="fa fa-eye fonticonpositioning"
                        onClick={() => setinputtype("password")}
                        aria-hidden="true"
                        style={{ marginTop: "16px" }}
                      ></i>
                    )}
                  </div>
                  <PasswordStrengthBar password={chkPassState} />
                  {/* {errors.password && (
                        <p className="errormessage">
                          Password must have at least 8 characters
                        </p>
                      )} */}
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_skill">
                    Confirm Password
                  </label>
                  <div style={{ position: "relative" }}>
                    <input
                      type={inputtype2}
                      onChange={(e) => setconfirmpassword(e.target.value)}
                      className="form-control underline-input"
                      style={{ backgroundColor: "#f5f5f9" }}
                      name="confirmpassword"
                      ref={register}
                      // required
                    />
                    {inputtype2 === "password" ? (
                      <i
                        className="fa fa-eye-slash fonticonpositioning"
                        onClick={() => setinputtype2("text")}
                        aria-hidden="true"
                        style={{ marginTop: "16px" }}
                      ></i>
                    ) : (
                      <i
                        className="fa fa-eye fonticonpositioning"
                        onClick={() => setinputtype2("password")}
                        aria-hidden="true"
                        style={{ marginTop: "16px" }}
                      ></i>
                    )}
                  </div>
                  {confirmpassword && confirmpassword !== chkPassState && (
                    <p className="errormessage">Password not matching</p>
                  )}
                </Col>
                <Col md={3}>
                  <div className="d-flex flex-column align-items-start">
                    <div>
                      <label className="area_rowslable_skill">
                        Contact Preference
                      </label>
                    </div>

                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          class="form-check checkbox-group"
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          <input
                            type="radio"
                            name="contact"
                            id="phone"
                            value="phone"
                            style={{ backgroundColor: "#f5f5f9" }}
                            onChange={(e) => setValue(e.target.value)}
                            checked={value === "phone"}
                          />
                          <label
                            className="area_rowslable_skill mt-2 ml-1"
                            for="phone"
                          >
                            Phone
                          </label>
                        </div>
                        <div
                          class="form-check checkbox-group"
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          <input
                            type="radio"
                            name="contact"
                            id="email"
                            onChange={(e) => setValue(e.target.value)}
                            value="email"
                            style={{
                              backgroundColor: "#f5f5f9",
                              marginLeft: "30px",
                            }}
                            checked={value === "email"}
                          />
                          <label
                            className="area_rowslable_skill mt-2 ml-1"
                            for="email"
                          >
                            Email
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={3}>
                  <div
                    class="container"
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <input
                      type="checkbox"
                      checked={checkboxstate.deposit}
                      onChange={getCheckboxStatus}
                      id="flexCheckDefault"
                      name="deposit"
                    />
                    <label
                      class="checkmark"
                      for="flexCheckDefault"
                      style={{ marginTop: "10px" }}
                    ></label>
                    <label
                      class="form-check-label boxes"
                      for="flexCheckDefault"
                      style={{
                        marginLeft: "15px",
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: "#071a52",
                        marginTop: "7px",
                      }}
                    >
                      Deposit
                    </label>
                  </div>
                  <br />
                </Col>
              </Row>
            </div>
            <div style={{ display: "flex", marginTop: "10px" }}>
              <p className="provider_containertitle">Address</p>
              {/* <hr style={{ flex: "1", marginLeft: "20px" }} /> */}
            </div>
            <div className="form-group creatadmin2">
              <Row>
                <Col md={3}>
                  <label className="area_rowslable_skill">
                    Street<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="street"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register({ required: true })}
                    // required
                  />
                  {errors.street && (
                    <p className="errormessage">Street is Required</p>
                  )}
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_skill">
                    City/Suburb<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="city"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register({ required: true })}
                    // required
                  />
                  {errors.city && (
                    <p className="errormessage">City is Required</p>
                  )}
                </Col>

                <Col md={3}>
                  <label className="area_rowslable_skill">
                    Country<span style={{ color: "red" }}>*</span>
                  </label>
                  <CountryDropdown
                    defaultOptionLabel="- Select Country -"
                    className="form-control underline-input countrydropdown2"
                    value={country1}
                    style={{ backgroundColor: "#f5f5f9" }}
                    onChange={(val) => selectCountry(val)}
                    required
                  />
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_skill">
                    State<span style={{ color: "red" }}>*</span>
                  </label>
                  {country1 == "Nepal" ? (
                    <select
                      style={{ backgroundColor: "#f5f5f9" }}
                      className="form-control"
                      onChange={(e) => selectRegion(e.target.value)}
                    >
                      <option value="">- Select Province -</option>
                      <option value="Province No. 1">Province No. 1</option>
                      <option value="Madhesh Province">Madhesh Province</option>
                      <option value="Bagmati Province">Bagmati Province</option>
                      <option value="Gandaki Province">Gandaki Province</option>
                      <option value="Lumbini Province">Lumbini Province</option>
                      <option value="Karnali Province">Karnali Province</option>
                      <option value="Sudurpashchim Province">
                        Sudurpashchim Province
                      </option>
                    </select>
                  ) : (
                    <RegionDropdown
                      defaultOptionLabel="- Select State -"
                      className="form-control underline-input countrydropdown2"
                      country={country1}
                      value={region}
                      style={{ backgroundColor: "#f5f5f9" }}
                      onChange={(val) => selectRegion(val)}
                      required
                    />
                  )}
                </Col>
              </Row>
            </div>
            <div className="form-group creatadmin2">
              <div className="d-flex justify-content-start">
                <Col md={3}>
                  <label className="area_rowslable_skill">
                    PostCode<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="pincode"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register({ required: true })}
                    // required
                  />
                  {errors.pincode && (
                    <p className="errormessage">PostCode is Required</p>
                  )}
                </Col>
              </div>
            </div>
            <div style={{ display: "flex", marginTop: "10px" }}>
              <p className="provider_containertitle">
                Passport/Visa Application
              </p>
              {/* <hr style={{ flex: "1", marginLeft: "20px" }} /> */}
            </div>
            <div className="form-group creatadmin2">
              <Row>
                <Col md={3}>
                  <label className="area_rowslable_skill">
                    Country of Passport
                  </label>
                  <CountryDropdown
                    style={{ backgroundColor: "#f5f5f9" }}
                    defaultOptionLabel="- Select Country -"
                    className="form-control underline-input countrydropdown2"
                    value={country1Passport}
                    onChange={(val) => selectCountryPassport(val)}
                  />
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_skill">
                    Passport Number
                  </label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="passportnumber"
                    ref={register}
                    style={{ backgroundColor: "#f5f5f9" }}
                    // required
                  />
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_skill">
                    Passport Issue Date
                  </label>
                  <DatePicker
                    style={{ backgroundColor: "#f5f5f9" }}
                    selected={
                      passportissuedate ? new Date(passportissuedate) : null
                    }
                    onChange={(date) => setpassportissuedate(date)}
                    dateFormat="dd-MM-yyyy"
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="Select Date"
                  />
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_skill">
                    Passport Expiry Date
                  </label>
                  <DatePicker
                    style={{ backgroundColor: "#f5f5f9" }}
                    selected={
                      passportexpirydate ? new Date(passportexpirydate) : null
                    }
                    onChange={(date) => setpassportexpirydate(date)}
                    minDate={new Date(passportissuedate)}
                    dateFormat="dd-MM-yyyy"
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="Select Date"
                  />
                </Col>
              </Row>
            </div>
            <div className="form-group creatadmin2">
              <div className="d-flex justify-content-start">
                <Col md={3}>
                  <label className="area_rowslable_skill">Visa Type</label>
                  <select
                    style={{ backgroundColor: "#f5f5f9" }}
                    className="form-control"
                    ref={register}
                    name="visaType"
                    // required
                  >
                    <option value="">- Select Visa Type -</option>
                    {allVisa?.map((cuntitem) => (
                      <option
                        key={cuntitem.id}
                        value={cuntitem.name}
                        id={cuntitem.id}
                      >
                        {cuntitem.name}
                      </option>
                    ))}
                  </select>
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_skill">
                    Visa Issue Date
                  </label>
                  <DatePicker
                    style={{ backgroundColor: "#f5f5f9" }}
                    selected={visaissuedate ? new Date(visaissuedate) : null}
                    onChange={(date) => setvisaissuedate(date)}
                    dateFormat="dd-MM-yyyy"
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="Select Date"
                  />
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_skill">Visa Expiry</label>
                  <DatePicker
                    style={{ backgroundColor: "#f5f5f9" }}
                    selected={visaexpirydate ? new Date(visaexpirydate) : null}
                    onChange={(date) => setvisaexpirydate(date)}
                    minDate={new Date(visaissuedate)}
                    dateFormat="dd-MM-yyyy"
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="Select Date"
                  />
                </Col>
              </div>
            </div>
            <div style={{ display: "flex", marginTop: "10px" }}>
              <p className="provider_containertitle">Health Insurance</p>
              {/* <hr style={{ flex: "1", marginLeft: "20px" }} /> */}
            </div>
            <div className="form-group creatadmin2">
              <Row>
                <Col md={3}>
                  <label className="area_rowslable_skill">
                    Insurance Provider
                  </label>
                  <select
                    style={{ backgroundColor: "#f5f5f9" }}
                    className="form-control"
                    onInput={handleChangeHealth}
                    ref={register}
                    name="insuranceprovider"
                    // required
                  >
                    <option value="">- Select Insurance Provider -</option>
                    {allHealth?.map((cuntitem) => (
                      <option
                        key={cuntitem.id}
                        value={cuntitem.name}
                        id={cuntitem.id}
                      >
                        {cuntitem.name}
                      </option>
                    ))}
                  </select>
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_skill">Policy Number</label>
                  <input
                    style={{ backgroundColor: "#f5f5f9" }}
                    type="string"
                    className="form-control underline-input"
                    name="policynumber"
                    ref={register}
                    // required
                  />
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_skill">
                    Health Cover Type
                  </label>
                  <select
                    style={{ backgroundColor: "#f5f5f9" }}
                    className="form-control"
                    ref={register}
                    name="insurancetype"
                    // required
                  >
                    <option value="">- Select Health Cover Type -</option>
                    {allHealthCover?.map((cuntitem) => (
                      <option
                        key={cuntitem.id}
                        value={cuntitem.name}
                        id={cuntitem.id}
                      >
                        {cuntitem.name}
                      </option>
                    ))}
                  </select>
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_skill">
                    Insurance Issue Date
                  </label>
                  <DatePicker
                    style={{ backgroundColor: "#f5f5f9" }}
                    selected={
                      healthcoverdate ? new Date(healthcoverdate) : null
                    }
                    onChange={(date) => sethealthcoverdate(date)}
                    dateFormat="dd-MM-yyyy"
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="Select Date"
                  />
                </Col>
              </Row>
            </div>
            <div className="form-group creatadmin2">
              <div className="d-flex justify-content-start">
                <Col md={3}>
                  <label className="area_rowslable_skill">
                    Insurance Expiry Date
                  </label>
                  <DatePicker
                    style={{ backgroundColor: "#f5f5f9" }}
                    selected={
                      insuranceexpirydate ? new Date(insuranceexpirydate) : null
                    }
                    onChange={(date) => setinsuranceexpirydate(date)}
                    dateFormat="dd-MM-yyyy"
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="Select Date"
                  />
                </Col>
              </div>
            </div>
            <div style={{ display: "flex", marginTop: "10px" }}>
              <p className="provider_containertitle">Agent</p>
              {/* <hr style={{ flex: "1", marginLeft: "20px" }} /> */}
            </div>
            <div className="form-group creatadmin2">
              <div className="d-flex justify-content-start">
                <Col md={3}>
                  <label className="area_rowslable_skill">Super Agent</label>
                  <select
                    style={{ backgroundColor: "#f5f5f9" }}
                    className="form-control"
                    name="superagent"
                    onInput={(event) => {
                      const index = event.target.selectedIndex;
                      const optionElement = event.target.childNodes[index];
                      const optionElementId = optionElement.getAttribute("id");
                      const optionElementrealId =
                        optionElement.getAttribute("realid");
                      //console.log(optionElementId);

                      setSuperAgentName(optionElementId);
                      setSuperAgentId(optionElementrealId);
                    }}
                    ref={register}
                  >
                    <option value="">- Select super agent -</option>
                    {superAgentData?.map((cuntitem) => (
                      <option
                        key={cuntitem.id}
                        value={cuntitem.username}
                        id={cuntitem.name}
                        realid={cuntitem.id}
                      >
                        {cuntitem.name}
                      </option>
                    ))}
                  </select>
                </Col>
              </div>
            </div>

            <div style={{ display: "flex", marginTop: "10px" }}>
              <p className="provider_containertitle">Others</p>
              {/* <hr style={{ flex: "1", marginLeft: "20px" }} /> */}
            </div>
            <div className="form-group creatadmin2">
              <Row>
                <Col md={3}>
                  <label className="area_rowslable_skill">USI</label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="usi"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register}
                    // required
                  />
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_skill">
                    Membership Number
                  </label>
                  <input
                    type="number"
                    style={{ backgroundColor: "#f5f5f9" }}
                    onWheel={(event) => event.currentTarget.blur()}
                    className="form-control underline-input"
                    name="membershipnumber"
                    ref={register}
                    // required
                  />
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_skill">
                    Membership Expiry Date
                  </label>
                  <DatePicker
                    style={{ backgroundColor: "#f5f5f9" }}
                    selected={
                      membershipexpirydate
                        ? new Date(membershipexpirydate)
                        : null
                    }
                    onChange={(date) => setmembershipexpirydate(date)}
                    dateFormat="dd-MM-yyyy"
                    minDate={new Date(datenow)}
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="Select Date"
                  />
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_skill">
                    Enquiry Lead Type<span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    className={classes.selectdrop}
                    name="enquiryLeadType"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register({ required: true })}
                  >
                    <option value="HotLead">Hot Lead</option>
                    <option value="Converted">Converted Lead</option>
                    <option value="ColdLead">Cold Lead</option>
                    <option value="WarmLead">Warm Lead</option>
                  </select>
                  {errors.enquiryLeadType && (
                    <p className="errormessage">
                      Enquiry Lead Type is Required
                    </p>
                  )}
                </Col>
              </Row>
            </div>
            <div className="form-group creatadmin2">
              <Row>
                <Col md={3}>
                  <label className="area_rowslable_skill">Comment</label>
                  <input
                    type="textarea"
                    className="form-control"
                    style={{ backgroundColor: "#f5f5f9" }}
                    name="comment"
                    ref={register}
                  />
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_skill">
                    Institution Name
                  </label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="institutionname"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register}
                    // required
                  />
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_skill">Intake</label>
                  {/* <select
                    style={{ backgroundColor: "#f5f5f9" }}
                    name="intake"
                    className="form-control"
                    onInput={handleChangeIntake}
                    ref={register}
                    // required
                  >
                    <option value="">- Select Intake -</option>
                    {allIntake?.map((cuntitem) => (
                      <option
                        key={cuntitem.id}
                        value={cuntitem.name}
                        id={cuntitem.id}
                      >
                        {cuntitem.createdIntake}
                      </option>
                    ))}
                  </select> */}
                  <DatePicker
                    selected={
                      selectedIntakename ? new Date(selectedIntakename) : null
                    }
                    onChange={(date) => {
                      setselectedIntakename(date);
                      handleChangeIntake(date);
                    }}
                    dateFormat="MMM-yyyy" // Format for display
                    showMonthYearPicker
                    dropdownMode="select"
                    placeholderText="Select Date"
                    style={{ backgroundColor: "#f5f5f9" }}
                  />
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_skill">Status</label>
                  <select
                    style={{ backgroundColor: "#f5f5f9" }}
                    className={classes.selectdrop}
                    name="status"
                    ref={register}
                  >
                    <option value="1">Active</option>
                    <option value="0">Inactive</option>
                  </select>
                </Col>
              </Row>
            </div>
            <div style={{ display: "flex", marginTop: "10px" }}>
              <p className="provider_containertitle">
                Client Emergency Contact Details
              </p>
              {/* <hr style={{ flex: "1", marginLeft: "20px" }} /> */}
            </div>
            <div className="form-group creatadmin2">
              <Row>
                <Col md={3}>
                  <label className="area_rowslable_skill">Name</label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="clientRelativeName"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register}
                  />
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_skill">
                    Relationship With Client
                  </label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="clientRelation"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register}
                  />
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_skill">Email</label>
                  <input
                    type="email"
                    autoComplete="off"
                    className="form-control underline-input"
                    name="clientRelativeEmail"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register}
                    // required
                  />
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_skill">Phone</label>
                  <input
                    type="number"
                    onWheel={(event) => event.currentTarget.blur()}
                    className="form-control underline-input"
                    name="clientRelativePhone"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register}
                  />
                </Col>
              </Row>
            </div>

            <Row>
              <Col md={6}>{null}</Col>
              <Col md={6} className="d-flex justify-content-end">
                <button
                  type="button"
                  onClick={handleSubmit(handleSaveApplication)}
                  className="btn listing_addbutton ml-3 mr-3"
                >
                  Save and Start Application
                </button>
                <Button
                  color="secondary"
                  style={{
                    border: "1px solid #17b978",
                    color: "#17b978",
                    fontWeight: "600",
                    float: "left",
                    backgroundColor: "white",
                    marginLeft: "2px",
                  }}
                  onClick={() => {
                    try {
                      document.getElementById("myForm").reset();
                    } catch (error) {
                      //console.log(error);
                    }
                  }}
                >
                  Reset
                </Button>
                <button
                  type="submit"
                  style={{
                    backgroundColor: "#17b978",
                    color: "#fff",
                    fontWeight: "600",
                  }}
                  className="btn listing_addbutton ml-3"
                >
                  Save
                </button>
              </Col>
            </Row>
          </div>
        </form>
      </div>
      {/* </Card> */}
    </div>
  );
};

export default CreateProspect;
