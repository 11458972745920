import React, { useContext, useEffect, useState, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardSubtitle,
  CardText,
  Col,
  Row,
  Button,
  Table,
} from "reactstrap";
import moment from "moment";
import axios from "axios";
import base_url from "./../../../api/bootapi";
import { ToastContainer } from "react-toastify";

import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import {
  selectUser,
  setdashboardcheck,
  logout,
} from "./../../features/userSlice";
import Pagination from "@mui/material/Pagination";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import Backdrop from "@mui/material/Backdrop";

import { toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import "./../../css/Responsive.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { CSVLink, CSVDownload } from "react-csv";
import jsPDF from "jspdf";
import { customStyles, useStyles } from "../../../Utilities/CSSUtilities";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import {
  checkreportpermission,
  check4pointpermission,
  check1pointpermission,
} from "../../Permission/permission";
import sort from "../../images/sort.svg";

function SkillAssessmentProviderReport(props) {
  const classes = useStyles();
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const jwtToken = "Bearer " + user.isw_user_token;
  const [pageNo, setPageNo] = useState(0);
  const [sortState, setSortState] = useState("institution_name");
  const [direction, setDirection] = useState("DESC");
  const [rows, setRows] = React.useState(50);
  const [totalUser, setTotalUser] = useState();
  const [countNumber, setCountNumber] = useState();
  const [numberofElements, setNumberOfElements] = useState();
  // const datepast = moment().subtract(3, "M").format("YYYY-MM-DDThh:mm:ss");
  const isoDateString = new Date().toISOString();
  const [backdropopen, setbackdropopen] = useState(true);

  const [subAgentData, setSubAgentData] = useState();
  const [subAgentPageNo, setSubAgentPageNo] = useState();
  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);
  const [pdfRow, setPdfRow] = useState([]);

  const [display1, setDisplay] = useState("inline");

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        navigate("/");
      }
    });
    getPermissions();
  }, []);

  useEffect(() => {
    let width = document.querySelector("table")?.offsetWidth;
    if (document.querySelector(".scroller")) {
      document.querySelector(".scroller").style.width = `${width}px`;
    }
  }, [subAgentData]);

  const [dataForDownload, setDataForDownload] = useState([]);
  const [bDownloadReady, setDownloadReady] = useState(false);
  const csvLink = useRef();
  useEffect(() => {
    if (csvLink && csvLink.current && bDownloadReady) {
      csvLink.current.link.click();
      setDownloadReady(false);
    }
  }, [bDownloadReady]);

  const handleAction = (actionType) => {
    let headData = {
      Authorization: jwtToken,
      workflowType: "SkillAssessment",
      columnName: sortState,
      direction: direction,
      page: Number(0),
      size: Number(pdflength),
    };
    if (actionType === "DOWNLOAD") {
      setbackdropopen(true);
      axios
        .get(`${base_url.api4}/getProviderWorkflowSummeryReport`, {
          headers: headData,
        })
        .then((response) => {
          //console.log(response.data.data.content);
          setDataForDownload(response.data.data.content);
          setDownloadReady(true);
          setbackdropopen(false);
        })
        .catch((error) => {
          setDataForDownload();
          setDownloadReady(true);
          setbackdropopen(false);
          handle403error(error);
        });
    }
  };

  let scrolling = false;
  const scrolldiv = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow .table-responsive");
    table.scrollTo(scroller.scrollLeft, 0);
  };

  const scrolltable = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow  .table-responsive");
    scroller.scrollTo(table.scrollLeft, 0);
  };

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);

    let headData = {
      Authorization: jwtToken,
      workflowType: "SkillAssessment",
      columnName: sortState,
      direction: direction,
      page: Number(0),
      size: Number(rows),
    };

    if (check1pointpermission(1071, permission_obj, resource_obj)) {
      //if(available_permission) {
      getProviderWiseSummery(headData);
      dispatch(setdashboardcheck(77));
      // } else {
      //   navigate("/errorPage");
      // }
    } else {
      navigate("/errorPage");
    }
  };

  const handlePageLead = (event, value) => {
    setPageNo(value - 1);
    if (searchStatus) {
      let headData = {
        Authorization: jwtToken,
        workflowType: "SkillAssessment",
        columnName: sortState,
        direction: direction,
        query: searchData,
        page: Number(value - 1),
        size: Number(rows),
      };
      getProviderWiseSummery(headData);
    } else {
      let headData = {
        Authorization: jwtToken,
        workflowType: "SkillAssessment",
        columnName: sortState,
        direction: direction,
        page: Number(value - 1),
        size: Number(rows),
      };
      getProviderWiseSummery(headData);
    }
  };

  const handleRows = (event) => {
    setRows(event.target.value);
    if (searchStatus) {
      let headData = {
        Authorization: jwtToken,
        workflowType: "SkillAssessment",
        columnName: sortState,
        direction: direction,
        query: searchData,
        page: Number(0),
        size: Number(event.target.value),
      };
      getProviderWiseSummery(headData);
    } else {
      let headData = {
        Authorization: jwtToken,
        workflowType: "SkillAssessment",
        columnName: sortState,
        direction: direction,
        page: Number(0),
        size: Number(event.target.value),
      };
      getProviderWiseSummery(headData);
    }
  };

  const [pdflength, setpdflength] = useState();
  const [pdfHeader, setPdfHeader] = useState();

  const getProviderWiseSummery = (headData) => {
    setbackdropopen(true);
    axios
      .get(`${base_url.api4}/getProviderWorkflowSummeryReport`, {
        headers: headData,
      })
      .then((response) => {
        headData.size = response.data.data.totalElements;
        setpdflength(response.data.data.totalElements);
        if (response.data.data.totalElements == 0) {
          headData.size = Number(50);
          setpdflength(50);
        } else {
          setpdflength(rows);
        }
        headData.size = Number(50);
        getPdfData(headData);
        setPdfHeader(headData);
        setSubAgentData(response.data.data.content);
        setSubAgentPageNo(response.data.data.totalPages);
        setTotalUser(response.data.data.totalElements);
        setCountNumber(response.data.data.number);
        setNumberOfElements(response.data.data.numberOfElements);
        // setbackdropopen(false);
      })
      .catch((error) => {
        setbackdropopen(false);
        setSubAgentData();
        setSubAgentPageNo(0);
        setTotalUser(0);
        setCountNumber(0);
        setNumberOfElements(0);
        handle403error(error);
      });
  };

  const getPdfData = (headData) => {
    setbackdropopen(true);
    axios
      .get(`${base_url.api4}/getProviderWorkflowSummeryReport`, {
        headers: headData,
      })
      .then((response) => {
        setPdfRow(response.data.data.content);
        setbackdropopen(false);
      })
      .catch((error) => {
        setbackdropopen(false);
        setPdfRow();
        handle403error(error);
      });
  };

  const [searchStatus, setSearchStatus] = useState();
  const [searchData, setSearchData] = useState();
  const searchSubAgentWiseSummery = (e) => {
    setDisplay("none");
    let srchdata = e.target[0].value.trim();
    setSearchData(srchdata);
    setPageNo(0);
    if (srchdata != "") {
      setSearchStatus(true);
      let headData = {
        Authorization: jwtToken,
        workflowType: "SkillAssessment",
        columnName: sortState,
        direction: direction,
        query: srchdata,
        page: Number(0),
        size: Number(rows),
      };
      getProviderWiseSummery(headData);
    } else {
      setSearchStatus(false);
      let headData = {
        Authorization: jwtToken,
        workflowType: "SkillAssessment",
        columnName: sortState,
        direction: direction,
        page: Number(0),
        size: Number(rows),
      };
      getProviderWiseSummery(headData);
    }
  };

  const exportPdfLead = () => {
    let headData = {
      Authorization: jwtToken,
      workflowType: "SkillAssessment",
      columnName: sortState,
      direction: direction,
      page: Number(0),
      size: Number(pdflength),
    };
    setbackdropopen(true);
    axios
      .get(`${base_url.api4}/getProviderWorkflowSummeryReport`, {
        headers: headData,
      })
      .then((response) => {
        setPdfRow(response.data.data.content);
        const doc = new jsPDF();
        // autoTable(doc, { html: '#myTable' })
        var col = ["Provider Name", "Count"];
        var rows = [];
        response.data.data.content?.forEach((element) => {
          var temp = [element.institution_name, element.total];
          rows.push(temp);
        });
        doc.autoTable(col, rows, { pageBreak: "auto" });
        // doc.autoTable(col, rows, { pageBreak: 'auto' });
        doc.save("SkillAssessmentProviderReport.pdf");
        setbackdropopen(false);
      })
      .catch((error) => {
        setbackdropopen(false);
        setPdfRow();
        handle403error(error);
      });
  };

  const sortLeadData = (e) => {
    let sortFilter = e.target.id.trim();
    if (direction == "DESC") {
      setDirection("ASC");
    } else if (direction == "ASC") {
      setDirection("DESC");
    }
    if (searchStatus) {
      let headData = {
        Authorization: jwtToken,
        workflowType: "SkillAssessment",
        columnName: sortFilter,
        direction: direction,
        query: searchData,
        page: Number(pageNo),
        size: Number(rows),
      };
      getProviderWiseSummery(headData);
    } else {
      let headData = {
        Authorization: jwtToken,
        workflowType: "SkillAssessment",
        columnName: sortFilter,
        direction: direction,
        page: Number(pageNo),
        size: Number(rows),
      };
      getProviderWiseSummery(headData);
    }
  };

  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            navigate("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };

  return (
    <div>
      <ToastContainer />
      <Backdrop className={classes.backdrop} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <br />
      {/* <Card className="commoncomponent_cardcss"> */}
      {/* <CardBody> */}
      {/* <CardSubtitle className="font-weight-bold"></CardSubtitle> */}
      <div
        class="page-head d-flex justify-content-between p-4"
        style={{ marginTop: "2.9rem", marginBottom: "-20px" }}
      >
        <ul class="breadcrums">
          <h3 class="page-title bold">Skill Assessment Provider Summary</h3>
          <Link to="/" class="b-link">
            Home
          </Link>
          <span className="s_span ">&raquo;</span>
          <a class="b-link">Reports</a>
          <span className="s_span ">&raquo;</span>
          <a class="b-link">Skill Assesment</a>
          <span className="s_span ">&raquo;</span>
          <a class="b-link active">Skill Assessment Provider Summery</a>
        </ul>
        {/* <a href="workload-dashboard.html" class="back-btn">Back</a> */}
      </div>
      <div>
        <div
          className="newlisting_headertabdesign"
          style={{
            padding: "1.5rem",
            borderRight: "1.5rem solid #e9ebf2",
            borderLeft: "1.5rem solid #e9ebf2",
            borderTop: "1.5rem solid #e9ebf2",
            marginBottom: "0",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <form className="master">
                <div style={{ marginTop: "-10px" }}>
                  <label
                    className="area_rowslable_my"
                    style={{ fontSize: "14px" }}
                  >
                    Limit:
                  </label>
                  <br />
                  <FormControl className={classes.formControl}>
                    <select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={rows}
                      onChange={handleRows}
                      className="form-control"
                      style={{ backgroundColor: "#f5f5f9", width: "100px" }}
                    >
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </FormControl>
                </div>
              </form>
            </div>
            {/* <Col md={6}>{null}</Col> */}
            <div style={{ width: "329px" }}>
              <div className="clearsearchclass" style={{ marginTop: "20px" }}>
                <form
                  className="d-flex"
                  id="myForm"
                  onSubmit={(e) => {
                    e.preventDefault();
                    searchSubAgentWiseSummery(e);
                  }}
                >
                  <input
                    className="form-control"
                    placeholder="Search.."
                    name="search"
                  />
                  <button className="btn listpagesearch_button" type="submit">
                    <i className="fa fa-search"></i>
                  </button>
                </form>
              </div>
              {searchStatus ? (
                <a
                  className={classes.clrsrch2}
                  onClick={() => {
                    setDisplay("inline");
                    try {
                      document.getElementById("myForm").reset();
                    } catch (error) {
                      //console.log(error);
                    }
                    setSearchStatus(false);
                    setPageNo(0);
                    let headData = {
                      Authorization: jwtToken,
                      workflowType: "SkillAssessment",
                      columnName: sortState,
                      direction: direction,
                      page: Number(0),
                      size: Number(rows),
                    };
                    getProviderWiseSummery(headData);
                  }}
                >
                  Clear Search
                </a>
              ) : null}
            </div>
          </div>
        </div>
        <div
          className="newlisting_headertabdesign"
          style={{
            padding: "1.5rem",
            border: "1.5rem solid #e9ebf2",
            marginTop: "0",
            marginBottom: "0",
          }}
        >
          <Row className="s_pagi" style={{ marginTop: "5px" }}>
            <Col md={6}>
              {subAgentData?.length ? (
                <p className="s_para" style={{ marginTop: "5px" }}>
                  {countNumber * rows + 1} to{" "}
                  {countNumber * rows + numberofElements} of {totalUser} records
                </p>
              ) : (
                <p className="s_para" style={{ marginTop: "5px" }}>
                  No Record Found
                </p>
              )}
            </Col>
            <Col md={6}>
              <div style={{ display: "flex", justifyContent: "right" }}>
                {check1pointpermission(1072, permissionData, rolepermission) ? (
                  <button
                    className="btn listing_addbutton"
                    onClick={exportPdfLead}
                    style={{ marginLeft: "45px" }}
                  >
                    Export PDF
                  </button>
                ) : null}
                <>
                  {/* {pdfRow != null && pdfRow.length != 0 ? (
                    <>
                      {permissionData?.includes(209) ?
                        <CSVLink
                          filename="SkillAssessmentProviderReport.csv"
                          data={pdfRow ? pdfRow : null}
                          className="btn listing_addbutton"
                        // target="_blank"
                        >
                          Export CSV
                        </CSVLink>
                        : null}
                    </>
                    ): null} */}
                  {dataForDownload != null ? (
                    <>
                      {check1pointpermission(
                        1072,
                        permissionData,
                        rolepermission
                      ) ? (
                        <>
                          <button
                            type="button"
                            className="btn listing_addbutton"
                            style={{ marginLeft: "25px" }}
                            onClick={(e) => handleAction("DOWNLOAD")}
                          >
                            Export CSV
                          </button>
                          <CSVLink
                            data={dataForDownload}
                            filename="SkillAssessmentProviderReport.csv"
                            className="hidden"
                            ref={csvLink}
                            target="_blank"
                          />
                        </>
                      ) : null}
                    </>
                  ) : null}
                </>
              </div>
            </Col>
          </Row>
          <div className="above-scroller" onScroll={scrolldiv}>
            <div className="scroller"></div>
          </div>
          <div className="displaytablerow">
            {/* <Table responsive striped onScroll={scrolltable}> */}
            <Table
              className="workload card-table table-borderless"
              responsive
              striped
              onScroll={scrolltable}
            >
              <thead className="s_sticky">
                <tr>
                  {/* <th>Id</th> */}
                  <th style={{ width: "50px", textAlign: "center" }}>SN </th>
                  <th style={{ minWidth: "360px" }}>
                    NAME {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="institution_name"
                      onClick={sortLeadData}
                      style={{
                        cursor: "pointer",
                        display: `${display1}`,
                        height: "10px",
                      }}
                    />
                  </th>
                  <th style={{ minWidth: "260px" }}>
                    COUNT {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="total"
                      onClick={sortLeadData}
                      style={{
                        cursor: "pointer",
                        display: `${display1}`,
                        height: "10px",
                      }}
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {subAgentData != null
                  ? subAgentData &&
                    subAgentData.map((p, index) => (
                      <tr key={p.id}>
                        {/* <td>{p.id}</td> */}
                        <td style={{ width: "50px", textAlign: "center" }}>
                          {countNumber * rows + 1 + index}
                        </td>
                        <td>{p.institution_name}</td>
                        <td>{p.total}</td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </Table>
            <Row className="s_pagirow">
              <Col md={6}>
                <div>
                  <form>
                    <div style={{ float: "left" }}>
                      <FormControl
                        variant="standard"
                        style={{
                          display: "inline-block",
                          marginRight: "25px",
                          minWidth: "50px",
                        }}
                      >
                        <select
                          className={classes.selectlimit}
                          labelId="simple-select-label"
                          id="simple-select"
                          value={rows}
                          onChange={handleRows}
                        >
                          <option value={10}>10</option>
                          <option value={20}>20</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                        </select>
                      </FormControl>
                      {subAgentData?.length ? (
                        <div className="s_spanPage">
                          {countNumber * rows + 1} to{" "}
                          {countNumber * rows + numberofElements} of {totalUser}{" "}
                          records
                        </div>
                      ) : (
                        <div className="s_spanPage">No Record Found</div>
                      )}
                    </div>
                  </form>
                </div>
              </Col>
              <Col md={6}>
                <div className={classes.root} style={{ float: "right" }}>
                  <Pagination
                    count={subAgentPageNo}
                    onChange={handlePageLead}
                    shape="rounded"
                  />
                </div>
              </Col>
            </Row>
          </div>
        </div>
        {/* </CardBody> */}
        {/* </Card> */}
      </div>
    </div>
  );
}

export default SkillAssessmentProviderReport;
