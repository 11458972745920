import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Card,
  CardBody,
  CardSubtitle,
  CardText,
  Col,
  Row,
  Button,
  Table,
} from "reactstrap";
import moment from "moment";
import axios from "axios";
import base_url from "../../../api/bootapi";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import Modal from "react-modal";
import { Typography } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import "../../css/Responsive.css";
import {
  getworkflowType,
  setdashboardcheck,
  selectUser,
  gettabvalue,
  logout,
} from "../../features/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectdashboard } from "../../features/dashboardSlice";
import DateRangePicker from "react-bootstrap-daterangepicker";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { useStyles, customStyles } from "../../../Utilities/CSSUtilities";
import { CSVLink, CSVDownload } from "react-csv";
import jsPDF from "jspdf";
import { checkreportpermission } from "../../Permission/permission";
import sort from "../../images/sort.svg";

function SkillAssessmentReport(props) {
  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  const user = useSelector(selectUser);
  const isw_user_role = localStorage.getItem("isw_user_role");
  const dispatch = useDispatch();
  const [deleteEnquiry, setDeleteEnquiry] = useState(null);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalIsOpen2, setIsOpen2] = React.useState(false);
  const [backdropopen, setbackdropopen] = useState(false);
  const navigate = useNavigate();
  const [sortState, setSortState] = useState("enquiry_id");
  const [direction, setdirection] = useState("DESC");
  const dashboardvalue = useSelector(selectdashboard);
  const [rows, setrows] = React.useState(50);
  const [pdfRow, setPdfRow] = useState();
  const [pageno, setpageno] = useState(0);
  const [enquiryData, setEnquiry] = useState(null);
  const [display1, setdisplay] = useState("inline");
  const [paginationpageno, setpaginationpageno] = useState(1);
  const ipaddress = localStorage.getItem("AdminIpAddress");
  const isw_user_name = localStorage.getItem("isw_user_name");
  const isw_adminId = localStorage.getItem("isw_adminId");
  const isw_user_email = localStorage.getItem("isw_user_email");
  const [deletedUser, setdeletedUser] = useState();
  const [dateFilter, setDateFilter] = useState();
  const [totalUser, settotalUser] = useState();
  const [countnumber, setcountnumber] = useState();
  const [numberofElements, setnumberofElements] = useState();
  const [permissionData, setPermissionData] = useState();
  const [selectedOffice, setselectedOffice] = useState(
    Number(user?.isw_user_role) === 1 || Number(user?.isw_user_role) === 6
      ? "all"
      : user?.isw_available_Office[0]?.officeId
  );

  let new_office_id;

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        navigate("/");
      }
    });
    const office_obj = [];
    for (let i = 0; i < user?.isw_available_Office?.length; i++) {
      const element = user?.isw_available_Office?.[i];
      //console.log(element);
      office_obj.push(element);
    }
    let new_id = Array.prototype.map
      .call(office_obj, function (item) {
        return item.officeId;
      })
      .join(",");
    //console.log("Line number 98", new_id);
    new_office_id = new_id;
    getPermissions();
  }, []);

  useEffect(() => {
    if (localStorage.getItem("admissionWorkflowPageNo")) {
      let currentPage = localStorage.getItem("admissionWorkflowPageNo");
      setpageno(currentPage);
      setpaginationpageno(Number(currentPage) + 1);
      getAllEnquiry(currentPage);
    } else {
      setpageno(0);
      setpaginationpageno(1);
      getAllEnquiry(0);
    }
  }, [rows]);

  useEffect(() => {
    let width = document.querySelector("table")?.offsetWidth;
    if (document.querySelector(".scroller")) {
      document.querySelector(".scroller").style.width = `${width}px`;
    }
  }, [enquiryData]);

  let scrolling = false;
  const scrolldiv = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow .table-responsive");
    table.scrollTo(scroller.scrollLeft, 0);
  };

  const scrolltable = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow  .table-responsive");
    scroller.scrollTo(table.scrollLeft, 0);
  };

  const handleRows = (event) => {
    setrows(event.target.value);
  };

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    // var available_resource = resource_obj.includes(75);
    // var available_permission = permission_obj.includes(652) || permission_obj.includes(653);
    if (checkreportpermission(permission_obj, resource_obj)) {
      dispatch(setdashboardcheck(75));
      //if (available_permission) {
      getCurrentStage(selectedOffice);
      getAllProvider(selectedOffice);
      getAllIntake(selectedOffice);
      getConsltant();
      getClientSource();
      getSubAgent();
      getCourse();
      getAllOffice();
      // } else {
      //   navigate("/errorPage");
      // }
    } else {
      navigate("/errorPage");
    }
  };

  const [allOffice, setOffice] = useState();
  const getAllOffice = () => {
    axios
      .get(`${base_url.api1}/findAllOffice`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        setOffice(response.data.data);
      })
      .catch((error) => {
        setOffice();
        handle403error(error);
      });
  };

  const [allClientSource, setClientSource] = useState();
  const getClientSource = () => {
    axios
      .get(`${base_url.api1}/getAllClientSourceUsingQuery`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        setClientSource(response.data.data);
      })
      .catch((error) => {
        setClientSource();
        handle403error(error);
      });
  };

  const [allCourses, setCourses] = useState();
  const getCourse = () => {
    axios
      .get(`${base_url.api2}/findAllCourse`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        setCourses(response.data.data);
      })
      .catch((error) => {
        setCourses();
        handle403error(error);
      });
  };

  const [subAgent, setSubAgent] = useState();
  const getSubAgent = () => {
    axios
      .get(`${base_url.api3}/findAgentsForDropDownList`, {
        headers: {
          Authorization: jwtToken,
          agentType: "Sub Agent",
        },
      })
      .then((response) => {
        setSubAgent(response.data.data);
      })
      .catch((error) => {
        setSubAgent();
        handle403error(error);
      });
  };

  const [assigneeData, setAssigneeData] = useState();
  const getConsltant = () => {
    axios
      .get(`${base_url.api2}/getUserByRoleId`, {
        headers: {
          Authorization: jwtToken,
          roleId: 4,
          direction: "ASC",
          columnName: "firstName",
        },
      })
      .then((res) => {
        let apiData = res.data.data;
        const consultant_obj = [];
        for (let i = 0; i < apiData.length; i++) {
          let data = {
            value: apiData[i].id,
            label: apiData[i].firstName + " " + apiData[i].lastName,
          };
          consultant_obj.push(data);
        }
        setAssigneeData(consultant_obj);
      })
      .catch((error) => {
        setAssigneeData();
        handle403error(error);
      });
  };

  const [getpageno, setgetpageno] = useState();
  const handlePage = (event, value) => {
    setpaginationpageno(value);
    setpageno(value - 1);
    if (assigneeState) {
      let headData = {
        Authorization: jwtToken,
        workflowType: "SkillAssessment",
        assigneeId: selectedAssigneeData,
        courseId: courseData,
        subAgentId: subAgentId,
        currentStage: selectedCurrentStage,
        officeId: selectedOfficeId,
        intake: selectedIntake,
        followUpStartDate: followStartDate,
        followUpEndDate: followEndDate,
        clientSource: clientSourceData,
        institutionId: selectedProvider,
        query: searchData,
        columnName: sortState,
        direction: direction,
        page: Number(value - 1),
        size: Number(rows),
      };
      getAllData(headData);
    } else {
      let headData = {
        Authorization: jwtToken,
        workflowType: "SkillAssessment",
        courseId: courseData,
        subAgentId: subAgentId,
        currentStage: selectedCurrentStage,
        officeId: selectedOfficeId,
        intake: selectedIntake,
        followUpStartDate: followStartDate,
        followUpEndDate: followEndDate,
        clientSource: clientSourceData,
        institutionId: selectedProvider,
        query: searchData,
        columnName: sortState,
        direction: direction,
        page: Number(value - 1),
        size: Number(rows),
      };
      getAllData(headData);
    }
  };

  const [allCurrentStage, setallCurrentStage] = useState();
  const getCurrentStage = (selectedOffice) => {
    if (selectedOffice === "all" || selectedOffice == null) {
      axios
        .get(`${base_url.api2}/getByWorkflowTypeBasedOnCurrentStage`, {
          headers: {
            Authorization: jwtToken,
            workflowType: "SkillAssessment",
          },
        })
        .then((response) => {
          setallCurrentStage(response.data.data);
        })
        .catch((error) => {
          setallCurrentStage();
          handle403error(error);
        });
    } else {
      axios
        .get(
          `${base_url.api2}/getByWorkflowTypeAndOfficeIdBasedOnCurrentStage`,
          {
            headers: {
              Authorization: jwtToken,
              workflowType: "SkillAssessment",
              officeId: Number(selectedOffice),
            },
          }
        )
        .then((response) => {
          setallCurrentStage(response.data.data);
        })
        .catch((error) => {
          setallCurrentStage();
          handle403error(error);
        });
    }
  };

  const [allProvider, setAllProvider] = useState();
  const getAllProvider = (selectedOffice) => {
    if (selectedOffice === "all") {
      axios
        .get(`${base_url.api2}/getByWorkflowTypeBasedOnInstitution`, {
          headers: {
            Authorization: jwtToken,
            workflowType: "SkillAssessment",
          },
        })
        .then((response) => {
          setAllProvider(response.data.data);
        })
        .catch((error) => {
          setAllProvider([]);
          handle403error(error);
        });
    } else {
      axios
        .get(
          `${base_url.api2}/getByWorkflowTypeAndOfficeIdBasedOnInstitution`,
          {
            headers: {
              Authorization: jwtToken,
              workflowType: "SkillAssessment",
              officeId: Number(selectedOffice),
            },
          }
        )
        .then((response) => {
          setAllProvider(response.data.data);
        })
        .catch((error) => {
          setAllProvider([]);
          handle403error(error);
        });
    }
  };

  const [allIntake, setAllIntake] = useState();
  const getAllIntake = (selectedOffice) => {
    if (selectedOffice === "all") {
      axios
        .get(`${base_url.api2}/getByWorkflowTypeBasedOnIntake`, {
          headers: {
            Authorization: jwtToken,
            workflowType: "SkillAssessment",
          },
        })
        .then((response) => {
          let intakeData = response.data.data;
          let intakeObj = [];
          for (let i = 0; i < intakeData.length; i++) {
            let data = {
              name: moment(intakeData[i].intake).format("YYYY-MM"),
              val: moment(intakeData[i].intake).format("YYYYMM"),
              intake: intakeData[i].intake,
            };
            intakeObj.push(data);
          }
          let newintakeObj = intakeObj.sort(function (a, b) {
            return b.val - a.val;
          });
          setAllIntake(newintakeObj);
        })
        .catch((error) => {
          setAllIntake();
          handle403error(error);
        });
    } else {
      axios
        .get(`${base_url.api2}/getByWorkflowTypeAndOfficeIdBasedOnIntake`, {
          headers: {
            Authorization: jwtToken,
            workflowType: "SkillAssessment",
            officeId: Number(selectedOffice),
          },
        })
        .then((response) => {
          let intakeData = response.data.data;
          let intakeObj = [];
          for (let i = 0; i < intakeData.length; i++) {
            let data = {
              name: moment(intakeData[i].intake).format("YYYY-MM"),
              val: moment(intakeData[i].intake).format("YYYYMM"),
              intake: intakeData[i].intake,
            };
            intakeObj.push(data);
          }
          let newintakeObj = intakeObj.sort(function (a, b) {
            return b.val - a.val;
          });
          setAllIntake(newintakeObj);
        })
        .catch((error) => {
          setAllIntake();
          handle403error(error);
        });
    }
  };

  const getAllEnquiry = (value1) => {
    setbackdropopen(true);
    if (
      Number(user?.isw_user_role) === 1 ||
      Number(user?.isw_user_role) === 6
    ) {
      if (value1 == parseInt(value1)) {
        // globalGetAllEnquiry(value1);
        if (assigneeState) {
          let headData = {
            Authorization: jwtToken,
            workflowType: "SkillAssessment",
            assigneeId: selectedAssigneeData,
            courseId: courseData,
            subAgentId: subAgentId,
            currentStage: selectedCurrentStage,
            officeId: selectedOfficeId,
            intake: selectedIntake,
            followUpStartDate: followStartDate,
            followUpEndDate: followEndDate,
            clientSource: clientSourceData,
            institutionId: selectedProvider,
            query: searchData,
            columnName: sortState,
            direction: direction,
            page: Number(value1),
            size: Number(rows),
          };
          getAllData(headData);
        } else {
          let headData = {
            Authorization: jwtToken,
            workflowType: "SkillAssessment",
            assigneeId: null,
            courseId: courseData,
            subAgentId: subAgentId,
            currentStage: selectedCurrentStage,
            officeId: selectedOfficeId,
            intake: selectedIntake,
            followUpStartDate: followStartDate,
            followUpEndDate: followEndDate,
            clientSource: clientSourceData,
            institutionId: selectedProvider,
            query: searchData,
            columnName: sortState,
            direction: direction,
            page: Number(value1),
            size: Number(rows),
          };
          getAllData(headData);
        }
      } else {
        // globalGetAllEnquiry(pageno);
        if (assigneeState) {
          let headData = {
            Authorization: jwtToken,
            workflowType: "SkillAssessment",
            assigneeId: selectedAssigneeData,
            courseId: courseData,
            subAgentId: subAgentId,
            currentStage: selectedCurrentStage,
            officeId: selectedOfficeId,
            intake: selectedIntake,
            followUpStartDate: followStartDate,
            followUpEndDate: followEndDate,
            clientSource: clientSourceData,
            institutionId: selectedProvider,
            query: searchData,
            columnName: sortState,
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          };
          getAllData(headData);
        } else {
          let headData = {
            Authorization: jwtToken,
            workflowType: "SkillAssessment",
            assigneeId: null,
            courseId: courseData,
            subAgentId: subAgentId,
            currentStage: selectedCurrentStage,
            officeId: selectedOfficeId,
            intake: selectedIntake,
            followUpStartDate: followStartDate,
            followUpEndDate: followEndDate,
            clientSource: clientSourceData,
            institutionId: selectedProvider,
            query: searchData,
            columnName: sortState,
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          };
          getAllData(headData);
        }
      }
    } else {
      if (value1 == parseInt(value1)) {
        // globalGetAllEnquiryOfficeId(value1);
        if (assigneeState) {
          let headData = {
            Authorization: jwtToken,
            workflowType: "SkillAssessment",
            assigneeId: selectedAssigneeData,
            courseId: courseData,
            subAgentId: subAgentId,
            currentStage: selectedCurrentStage,
            officeId: selectedOfficeId,
            intake: selectedIntake,
            followUpStartDate: followStartDate,
            followUpEndDate: followEndDate,
            clientSource: clientSourceData,
            institutionId: selectedProvider,
            query: searchData,
            columnName: sortState,
            direction: direction,
            page: Number(value1),
            size: Number(rows),
          };
          getAllData(headData);
        } else {
          let headData = {
            Authorization: jwtToken,
            workflowType: "SkillAssessment",
            assigneeId: null,
            courseId: courseData,
            subAgentId: subAgentId,
            currentStage: selectedCurrentStage,
            officeId: selectedOfficeId,
            intake: selectedIntake,
            followUpStartDate: followStartDate,
            followUpEndDate: followEndDate,
            clientSource: clientSourceData,
            institutionId: selectedProvider,
            query: searchData,
            columnName: sortState,
            direction: direction,
            page: Number(value1),
            size: Number(rows),
          };
          getAllData(headData);
        }
      } else {
        // globalGetAllEnquiryOfficeId(pageno);
        if (assigneeState) {
          let headData = {
            Authorization: jwtToken,
            workflowType: "SkillAssessment",
            assigneeId: selectedAssigneeData,
            courseId: courseData,
            subAgentId: subAgentId,
            currentStage: selectedCurrentStage,
            officeId: selectedOfficeId,
            intake: selectedIntake,
            followUpStartDate: followStartDate,
            followUpEndDate: followEndDate,
            clientSource: clientSourceData,
            institutionId: selectedProvider,
            query: searchData,
            columnName: sortState,
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          };
          getAllData(headData);
        } else {
          let headData = {
            Authorization: jwtToken,
            workflowType: "SkillAssessment",
            assigneeId: null,
            courseId: courseData,
            subAgentId: subAgentId,
            currentStage: selectedCurrentStage,
            officeId: selectedOfficeId,
            intake: selectedIntake,
            followUpStartDate: followStartDate,
            followUpEndDate: followEndDate,
            clientSource: clientSourceData,
            institutionId: selectedProvider,
            query: searchData,
            columnName: sortState,
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          };
          getAllData(headData);
        }
      }
    }
  };

  const classes = useStyles();

  const viewWorkflow = (p, e) => {
    localStorage.setItem("admissionWorkflowPageNo", pageno);
    localStorage.setItem("selected_client_username", p.clientUsername);
    localStorage.setItem("selected_client_id", p.clientId);
    localStorage.setItem("prev_path2", "/admission-enquiry");
    localStorage.setItem("prev_path", "/admission-enquiry");
    dispatch(getworkflowType(p.workflowType));
    dispatch(gettabvalue(1));
    if (!p.workflowId) {
      navigate(
        "/view-enquiry-workflowList/" + p.clientId + "/" + p.clientUsername
      );
    } else {
      navigate(
        "/view-enquiry-workflow/" +
          p.clientId +
          "/" +
          p.enquiryId +
          "/" +
          p.clientUsername
      );
    }
  };

  const [searchStatus, setSearchStatus] = useState();
  const [searchData, setSearchData] = useState(null);
  const submitSearch = (e) => {
    setbackdropopen(true);
    let srchdata = e.target[0].value.trim();
    setSearchData(srchdata);
    setpageno(0);
    setSearchStatus(true);

    if (assigneeState) {
      let headData = {
        Authorization: jwtToken,
        workflowType: "SkillAssessment",
        assigneeId: selectedAssigneeData,
        courseId: courseData,
        subAgentId: subAgentId,
        currentStage: selectedCurrentStage,
        officeId: selectedOfficeId,
        intake: selectedIntake,
        followUpStartDate: followStartDate,
        followUpEndDate: followEndDate,
        clientSource: clientSourceData,
        institutionId: selectedProvider,
        query: srchdata,
        columnName: sortState,
        direction: direction,
        page: Number(0),
        size: Number(rows),
      };
      getAllData(headData);
    } else {
      let headData = {
        Authorization: jwtToken,
        workflowType: "SkillAssessment",
        courseId: courseData,
        subAgentId: subAgentId,
        currentStage: selectedCurrentStage,
        officeId: selectedOfficeId,
        intake: selectedIntake,
        followUpStartDate: followStartDate,
        followUpEndDate: followEndDate,
        clientSource: clientSourceData,
        institutionId: selectedProvider,
        query: srchdata,
        columnName: sortState,
        direction: direction,
        page: Number(0),
        size: Number(rows),
      };
      getAllData(headData);
    }
  };
  const sortWorkflow = (e) => {
    setSortState(e.target.id);
    if (direction == "DESC") {
      setdirection("ASC");
    } else if (direction == "ASC") {
      setdirection("DESC");
    }
    // getAllEnquiry(pageno);
    if (isw_user_role == 1 || isw_user_role == 6) {
      // globalGetAllEnquiry(pageno, e.target.id);
      if (assigneeState) {
        let headData = {
          Authorization: jwtToken,
          workflowType: "SkillAssessment",
          assigneeId: selectedAssigneeData,
          courseId: courseData,
          subAgentId: subAgentId,
          currentStage: selectedCurrentStage,
          officeId: selectedOfficeId,
          intake: selectedIntake,
          followUpStartDate: followStartDate,
          followUpEndDate: followEndDate,
          clientSource: clientSourceData,
          institutionId: selectedProvider,
          query: searchData,
          columnName: e.target.id,
          direction: direction,
          page: Number(pageno),
          size: Number(rows),
        };
        getAllData(headData);
      } else {
        let headData = {
          Authorization: jwtToken,
          workflowType: "SkillAssessment",
          courseId: courseData,
          subAgentId: subAgentId,
          currentStage: selectedCurrentStage,
          officeId: selectedOfficeId,
          intake: selectedIntake,
          followUpStartDate: followStartDate,
          followUpEndDate: followEndDate,
          clientSource: clientSourceData,
          institutionId: selectedProvider,
          query: searchData,
          columnName: e.target.id,
          direction: direction,
          page: Number(pageno),
          size: Number(rows),
        };
        getAllData(headData);
      }
    } else {
      // globalGetAllEnquiryOfficeId(pageno, e.target.id);
      if (assigneeState) {
        let headData = {
          Authorization: jwtToken,
          workflowType: "SkillAssessment",
          assigneeId: selectedAssigneeData,
          courseId: courseData,
          subAgentId: subAgentId,
          currentStage: selectedCurrentStage,
          officeId: selectedOfficeId,
          intake: selectedIntake,
          followUpStartDate: followStartDate,
          followUpEndDate: followEndDate,
          clientSource: clientSourceData,
          institutionId: selectedProvider,
          query: searchData,
          columnName: e.target.id,
          direction: direction,
          page: Number(pageno),
          size: Number(rows),
        };
        getAllData(headData);
      } else {
        let headData = {
          Authorization: jwtToken,
          workflowType: "SkillAssessment",
          courseId: courseData,
          subAgentId: subAgentId,
          currentStage: selectedCurrentStage,
          officeId: selectedOfficeId,
          intake: selectedIntake,
          followUpStartDate: followStartDate,
          followUpEndDate: followEndDate,
          clientSource: clientSourceData,
          institutionId: selectedProvider,
          query: searchData,
          columnName: e.target.id,
          direction: direction,
          page: Number(pageno),
          size: Number(rows),
        };
        getAllData(headData);
      }
    }
  };

  // const [selectedOfficeId, setSelectedOfficeId] = useState(
  //   user?.isw_available_Office[0]?.officeId
  // );
  const [selectedOfficeId, setSelectedOfficeId] = useState(null);
  const [officeSelected, setOfficeSelected] = useState(false);
  const handleChangeOffice = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("value");

    getCurrentStage(optionElementId);
    getAllProvider(optionElementId);
    getAllIntake(optionElementId);

    if (optionElementId == "all") {
      setOfficeSelected(false);
      setSelectedOfficeId(null);
      setselectedOffice(null);
      if (assigneeState) {
        let headData = {
          Authorization: jwtToken,
          workflowType: "SkillAssessment",
          assigneeId: selectedAssigneeData,
          courseId: courseData,
          subAgentId: subAgentId,
          currentStage: selectedCurrentStage,
          officeId: null,
          intake: selectedIntake,
          followUpStartDate: followStartDate,
          followUpEndDate: followEndDate,
          clientSource: clientSourceData,
          institutionId: selectedProvider,
          query: searchData,
          columnName: sortState,
          direction: direction,
          page: Number(0),
          size: Number(rows),
        };
        getAllData(headData);
      } else {
        let headData = {
          Authorization: jwtToken,
          workflowType: "SkillAssessment",
          courseId: courseData,
          subAgentId: subAgentId,
          currentStage: selectedCurrentStage,
          officeId: null,
          intake: selectedIntake,
          followUpStartDate: followStartDate,
          followUpEndDate: followEndDate,
          clientSource: clientSourceData,
          institutionId: selectedProvider,
          query: searchData,
          columnName: sortState,
          direction: direction,
          page: Number(0),
          size: Number(rows),
        };
        getAllData(headData);
      }
    } else {
      setOfficeSelected(true);
      setSelectedOfficeId(optionElementId);
      setselectedOffice(optionElementId);
      if (assigneeState) {
        let headData = {
          Authorization: jwtToken,
          workflowType: "SkillAssessment",
          assigneeId: selectedAssigneeData,
          courseId: courseData,
          subAgentId: subAgentId,
          currentStage: selectedCurrentStage,
          officeId: optionElementId,
          intake: selectedIntake,
          followUpStartDate: followStartDate,
          followUpEndDate: followEndDate,
          clientSource: clientSourceData,
          institutionId: selectedProvider,
          query: searchData,
          columnName: sortState,
          direction: direction,
          page: Number(0),
          size: Number(rows),
        };
        getAllData(headData);
      } else {
        let headData = {
          Authorization: jwtToken,
          workflowType: "SkillAssessment",
          courseId: courseData,
          subAgentId: subAgentId,
          currentStage: selectedCurrentStage,
          officeId: optionElementId,
          intake: selectedIntake,
          followUpStartDate: followStartDate,
          followUpEndDate: followEndDate,
          clientSource: clientSourceData,
          institutionId: selectedProvider,
          query: searchData,
          columnName: sortState,
          direction: direction,
          page: Number(0),
          size: Number(rows),
        };
        getAllData(headData);
      }
    }
  };

  //Filter based Pagination-----------------------------------
  const [selectedCurrentStage, setSelectedCurrentStage] = useState(null);
  const [currentStageState, setCurrentStageState] = useState();
  const handleChangeCurrentStage = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementName = optionElement.getAttribute("value");

    if (optionElementName == "") {
      setCurrentStageState(false);
      setSelectedCurrentStage(null);
      if (assigneeState) {
        let headData = {
          Authorization: jwtToken,
          workflowType: "SkillAssessment",
          assigneeId: selectedAssigneeData,
          courseId: courseData,
          subAgentId: subAgentId,
          currentStage: null,
          officeId: selectedOfficeId,
          intake: selectedIntake,
          followUpStartDate: followStartDate,
          followUpEndDate: followEndDate,
          clientSource: clientSourceData,
          institutionId: selectedProvider,
          query: searchData,
          columnName: sortState,
          direction: direction,
          page: Number(0),
          size: Number(rows),
        };
        getAllData(headData);
      } else {
        let headData = {
          Authorization: jwtToken,
          workflowType: "SkillAssessment",
          courseId: courseData,
          subAgentId: subAgentId,
          currentStage: null,
          officeId: selectedOfficeId,
          intake: selectedIntake,
          followUpStartDate: followStartDate,
          followUpEndDate: followEndDate,
          clientSource: clientSourceData,
          institutionId: selectedProvider,
          query: searchData,
          columnName: sortState,
          direction: direction,
          page: Number(0),
          size: Number(rows),
        };
        getAllData(headData);
      }
    } else {
      setCurrentStageState(true);
      setSelectedCurrentStage(optionElementName);
      if (assigneeState) {
        let headData = {
          Authorization: jwtToken,
          workflowType: "SkillAssessment",
          assigneeId: selectedAssigneeData,
          courseId: courseData,
          subAgentId: subAgentId,
          currentStage: optionElementName,
          officeId: selectedOfficeId,
          intake: selectedIntake,
          followUpStartDate: followStartDate,
          followUpEndDate: followEndDate,
          clientSource: clientSourceData,
          institutionId: selectedProvider,
          query: searchData,
          columnName: sortState,
          direction: direction,
          page: Number(0),
          size: Number(rows),
        };
        getAllData(headData);
      } else {
        let headData = {
          Authorization: jwtToken,
          workflowType: "SkillAssessment",
          courseId: courseData,
          subAgentId: subAgentId,
          currentStage: optionElementName,
          officeId: selectedOfficeId,
          intake: selectedIntake,
          followUpStartDate: followStartDate,
          followUpEndDate: followEndDate,
          clientSource: clientSourceData,
          institutionId: selectedProvider,
          query: searchData,
          columnName: sortState,
          direction: direction,
          page: Number(0),
          size: Number(rows),
        };
        getAllData(headData);
      }
    }
    // setFilterPagination("CurrentStage");

    // if (selectedOffice === "all" || selectedOffice == null) {
    //   if (optionElementName == "") {
    //     setFilterPaginationData(false);
    //     globalGetAllEnquiry(pageno);
    //   } else {
    //     setFilterPaginationData(true);
    //     globalGetEnquiryByCurrentStage(optionElementName, 0);
    //   }
    // } else {
    //   if (optionElementName == "") {
    //     setFilterPaginationData(false);
    //     globalGetAllEnquiryOfficeId(pageno);
    //   } else {
    //     setFilterPaginationData(true);
    //     globalGetEnquiryByCurrentStageOfficeId(optionElementName, 0);
    //   }
    // }
  };

  const [selectedProvider, setSelectedProvider] = useState(null);
  const [providerState, setProviderState] = useState();
  const handleChangeProvider = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("value");

    if (optionElementId == "") {
      setProviderState(false);
      setSelectedProvider(null);
      if (assigneeState) {
        let headData = {
          Authorization: jwtToken,
          workflowType: "SkillAssessment",
          assigneeId: selectedAssigneeData,
          courseId: courseData,
          subAgentId: subAgentId,
          currentStage: selectedCurrentStage,
          officeId: selectedOfficeId,
          intake: selectedIntake,
          followUpStartDate: followStartDate,
          followUpEndDate: followEndDate,
          clientSource: clientSourceData,
          institutionId: null,
          query: searchData,
          columnName: sortState,
          direction: direction,
          page: Number(0),
          size: Number(rows),
        };
        getAllData(headData);
      } else {
        let headData = {
          Authorization: jwtToken,
          workflowType: "SkillAssessment",
          courseId: courseData,
          subAgentId: subAgentId,
          currentStage: selectedCurrentStage,
          officeId: selectedOfficeId,
          intake: selectedIntake,
          followUpStartDate: followStartDate,
          followUpEndDate: followEndDate,
          clientSource: clientSourceData,
          institutionId: null,
          query: searchData,
          columnName: sortState,
          direction: direction,
          page: Number(0),
          size: Number(rows),
        };
        getAllData(headData);
      }
    } else {
      setProviderState(true);
      setSelectedProvider(optionElementId);
      if (assigneeState) {
        let headData = {
          Authorization: jwtToken,
          workflowType: "SkillAssessment",
          assigneeId: selectedAssigneeData,
          courseId: courseData,
          subAgentId: subAgentId,
          currentStage: selectedCurrentStage,
          officeId: selectedOfficeId,
          intake: selectedIntake,
          followUpStartDate: followStartDate,
          followUpEndDate: followEndDate,
          clientSource: clientSourceData,
          institutionId: optionElementId,
          query: searchData,
          columnName: sortState,
          direction: direction,
          page: Number(0),
          size: Number(rows),
        };
        getAllData(headData);
      } else {
        let headData = {
          Authorization: jwtToken,
          workflowType: "SkillAssessment",
          courseId: courseData,
          subAgentId: subAgentId,
          currentStage: selectedCurrentStage,
          officeId: selectedOfficeId,
          intake: selectedIntake,
          followUpStartDate: followStartDate,
          followUpEndDate: followEndDate,
          clientSource: clientSourceData,
          institutionId: optionElementId,
          query: searchData,
          columnName: sortState,
          direction: direction,
          page: Number(0),
          size: Number(rows),
        };
        getAllData(headData);
      }
    }
    // setFilterPagination("ChangeProvider");

    // if (selectedOffice === "all" || selectedOffice == null) {
    //   if (optionElementId == "") {
    //     setFilterPaginationData(false);
    //     globalGetAllEnquiry(pageno);
    //   } else {
    //     setFilterPaginationData(true);
    //     globalGetEnquiryByInstitutionId(optionElementId, 0);
    //   }
    // } else {
    //   if (optionElementId == "") {
    //     setFilterPaginationData(false);
    //     globalGetAllEnquiryOfficeId(pageno, sortState, selectedOffice);
    //   } else {
    //     setFilterPaginationData(true);
    //     globalGetEnquiryByInstitutionIdofficeId(optionElementId, 0);
    //   }
    // }
  };

  const [selectedIntake, setSelectedIntake] = useState(null);
  const [intakeState, setIntakeState] = useState();
  const handleChangeIntake = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementName = optionElement.getAttribute("value");

    if (optionElementName == "") {
      setIntakeState(false);
      setSelectedIntake(null);
      if (assigneeState) {
        let headData = {
          Authorization: jwtToken,
          workflowType: "SkillAssessment",
          assigneeId: selectedAssigneeData,
          courseId: courseData,
          subAgentId: subAgentId,
          currentStage: selectedCurrentStage,
          officeId: selectedOfficeId,
          intake: null,
          followUpStartDate: followStartDate,
          followUpEndDate: followEndDate,
          clientSource: clientSourceData,
          institutionId: selectedProvider,
          query: searchData,
          columnName: sortState,
          direction: direction,
          page: Number(0),
          size: Number(rows),
        };
        getAllData(headData);
      } else {
        let headData = {
          Authorization: jwtToken,
          workflowType: "SkillAssessment",
          courseId: courseData,
          subAgentId: subAgentId,
          currentStage: selectedCurrentStage,
          officeId: selectedOfficeId,
          intake: null,
          followUpStartDate: followStartDate,
          followUpEndDate: followEndDate,
          clientSource: clientSourceData,
          institutionId: selectedProvider,
          query: searchData,
          columnName: sortState,
          direction: direction,
          page: Number(0),
          size: Number(rows),
        };
        getAllData(headData);
      }
    } else {
      setIntakeState(true);
      setSelectedIntake(optionElementName);
      if (assigneeState) {
        let headData = {
          Authorization: jwtToken,
          workflowType: "SkillAssessment",
          assigneeId: selectedAssigneeData,
          courseId: courseData,
          subAgentId: subAgentId,
          currentStage: selectedCurrentStage,
          officeId: selectedOfficeId,
          intake: optionElementName,
          followUpStartDate: followStartDate,
          followUpEndDate: followEndDate,
          clientSource: clientSourceData,
          institutionId: selectedProvider,
          query: searchData,
          columnName: sortState,
          direction: direction,
          page: Number(0),
          size: Number(rows),
        };
        getAllData(headData);
      } else {
        let headData = {
          Authorization: jwtToken,
          workflowType: "SkillAssessment",
          courseId: courseData,
          subAgentId: subAgentId,
          currentStage: selectedCurrentStage,
          officeId: selectedOfficeId,
          intake: optionElementName,
          followUpStartDate: followStartDate,
          followUpEndDate: followEndDate,
          clientSource: clientSourceData,
          institutionId: selectedProvider,
          query: searchData,
          columnName: sortState,
          direction: direction,
          page: Number(0),
          size: Number(rows),
        };
        getAllData(headData);
      }
    }

    // setFilterPagination("ChangeIntake");

    // if (isw_user_role == 1 || isw_user_role == 6) {
    //   if (optionElementName == "") {
    //     setFilterPaginationData(false);
    //     globalGetAllEnquiry(pageno);
    //   } else {
    //     setFilterPaginationData(true);
    //     globalGetEnquiryByIntake(optionElementName, 0);
    //   }
    // } else {
    //   if (optionElementName == "") {
    //     setFilterPaginationData(false);
    //     globalGetAllEnquiryOfficeId(pageno, sortState, selectedOffice);
    //   } else {
    //     setFilterPaginationData(true);
    //     globalGetEnquiryByIntakeOfficeId(optionElementName, 0);
    //   }
    // }
  };

  const [subAgentState, setSubAgentState] = useState();
  const [subAgentId, setSubAgentId] = useState(null);
  const handleChangeSubAgent = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("id");
    if (optionElementId == "") {
      setSubAgentId(null);
      setSubAgentState(false);
      if (assigneeState) {
        let headData = {
          Authorization: jwtToken,
          workflowType: "SkillAssessment",
          assigneeId: selectedAssigneeData,
          courseId: courseData,
          subAgentId: null,
          currentStage: selectedCurrentStage,
          officeId: selectedOfficeId,
          intake: selectedIntake,
          followUpStartDate: followStartDate,
          followUpEndDate: followEndDate,
          clientSource: clientSourceData,
          institutionId: selectedProvider,
          query: searchData,
          columnName: sortState,
          direction: direction,
          page: Number(0),
          size: Number(rows),
        };
        getAllData(headData);
      } else {
        let headData = {
          Authorization: jwtToken,
          workflowType: "SkillAssessment",
          courseId: courseData,
          subAgentId: null,
          currentStage: selectedCurrentStage,
          officeId: selectedOfficeId,
          intake: selectedIntake,
          followUpStartDate: followStartDate,
          followUpEndDate: followEndDate,
          clientSource: clientSourceData,
          institutionId: selectedProvider,
          query: searchData,
          columnName: sortState,
          direction: direction,
          page: Number(0),
          size: Number(rows),
        };
        getAllData(headData);
      }
    } else {
      setSubAgentId(optionElementId);
      setSubAgentState(true);
      if (assigneeState) {
        let headData = {
          Authorization: jwtToken,
          workflowType: "SkillAssessment",
          assigneeId: selectedAssigneeData,
          courseId: courseData,
          subAgentId: optionElementId,
          currentStage: selectedCurrentStage,
          officeId: selectedOfficeId,
          intake: selectedIntake,
          followUpStartDate: followStartDate,
          followUpEndDate: followEndDate,
          clientSource: clientSourceData,
          institutionId: selectedProvider,
          query: searchData,
          columnName: sortState,
          direction: direction,
          page: Number(0),
          size: Number(rows),
        };
        getAllData(headData);
      } else {
        let headData = {
          Authorization: jwtToken,
          workflowType: "SkillAssessment",
          courseId: courseData,
          subAgentId: optionElementId,
          currentStage: selectedCurrentStage,
          officeId: selectedOfficeId,
          intake: selectedIntake,
          followUpStartDate: followStartDate,
          followUpEndDate: followEndDate,
          clientSource: clientSourceData,
          institutionId: selectedProvider,
          query: searchData,
          columnName: sortState,
          direction: direction,
          page: Number(0),
          size: Number(rows),
        };
        getAllData(headData);
      }
    }
  };

  const [clientSourceState, setClientSourceState] = useState();
  const [clientSourceData, setClientSourceData] = useState(null);
  const handleChangeClientSource = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("value");

    if (optionElementId == "") {
      setClientSourceData(null);
      setClientSourceState(false);
      if (assigneeState) {
        let headData = {
          Authorization: jwtToken,
          workflowType: "SkillAssessment",
          assigneeId: selectedAssigneeData,
          courseId: courseData,
          subAgentId: subAgentId,
          currentStage: selectedCurrentStage,
          officeId: selectedOfficeId,
          intake: selectedIntake,
          followUpStartDate: followStartDate,
          followUpEndDate: followEndDate,
          clientSource: null,
          institutionId: selectedProvider,
          query: searchData,
          columnName: sortState,
          direction: direction,
          page: Number(0),
          size: Number(rows),
        };
        getAllData(headData);
      } else {
        let headData = {
          Authorization: jwtToken,
          workflowType: "SkillAssessment",
          courseId: courseData,
          subAgentId: subAgentId,
          currentStage: selectedCurrentStage,
          officeId: selectedOfficeId,
          intake: selectedIntake,
          followUpStartDate: followStartDate,
          followUpEndDate: followEndDate,
          clientSource: null,
          institutionId: selectedProvider,
          query: searchData,
          columnName: sortState,
          direction: direction,
          page: Number(0),
          size: Number(rows),
        };
        getAllData(headData);
      }
    } else {
      setClientSourceData(optionElementId);
      setClientSourceState(true);
      if (assigneeState) {
        let headData = {
          Authorization: jwtToken,
          workflowType: "SkillAssessment",
          assigneeId: selectedAssigneeData,
          courseId: courseData,
          subAgentId: subAgentId,
          currentStage: selectedCurrentStage,
          officeId: selectedOfficeId,
          intake: selectedIntake,
          followUpStartDate: followStartDate,
          followUpEndDate: followEndDate,
          clientSource: optionElementId,
          institutionId: selectedProvider,
          query: searchData,
          columnName: sortState,
          direction: direction,
          page: Number(0),
          size: Number(rows),
        };
        getAllData(headData);
      } else {
        let headData = {
          Authorization: jwtToken,
          workflowType: "SkillAssessment",
          courseId: courseData,
          subAgentId: subAgentId,
          currentStage: selectedCurrentStage,
          officeId: selectedOfficeId,
          intake: selectedIntake,
          followUpStartDate: followStartDate,
          followUpEndDate: followEndDate,
          clientSource: optionElementId,
          institutionId: selectedProvider,
          query: searchData,
          columnName: sortState,
          direction: direction,
          page: Number(0),
          size: Number(rows),
        };
        getAllData(headData);
      }
    }
  };

  const [courseState, setCourseState] = useState();
  const [courseData, setCourseData] = useState(null);
  const handleChangeCourse = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("value");

    if (optionElementId == "") {
      setCourseData(null);
      setCourseState(false);
      if (assigneeState) {
        let headData = {
          Authorization: jwtToken,
          workflowType: "SkillAssessment",
          assigneeId: selectedAssigneeData,
          courseId: null,
          subAgentId: subAgentId,
          currentStage: selectedCurrentStage,
          officeId: selectedOfficeId,
          intake: selectedIntake,
          followUpStartDate: followStartDate,
          followUpEndDate: followEndDate,
          clientSource: clientSourceData,
          institutionId: selectedProvider,
          query: searchData,
          columnName: sortState,
          direction: direction,
          page: Number(0),
          size: Number(rows),
        };
        getAllData(headData);
      } else {
        let headData = {
          Authorization: jwtToken,
          workflowType: "SkillAssessment",
          courseId: null,
          subAgentId: subAgentId,
          currentStage: selectedCurrentStage,
          officeId: selectedOfficeId,
          intake: selectedIntake,
          followUpStartDate: followStartDate,
          followUpEndDate: followEndDate,
          clientSource: clientSourceData,
          institutionId: selectedProvider,
          query: searchData,
          columnName: sortState,
          direction: direction,
          page: Number(0),
          size: Number(rows),
        };
        getAllData(headData);
      }
    } else {
      setCourseData(optionElementId);
      setCourseState(true);
      if (assigneeState) {
        let headData = {
          Authorization: jwtToken,
          workflowType: "SkillAssessment",
          assigneeId: selectedAssigneeData,
          courseId: optionElementId,
          subAgentId: subAgentId,
          currentStage: selectedCurrentStage,
          officeId: selectedOfficeId,
          intake: selectedIntake,
          followUpStartDate: followStartDate,
          followUpEndDate: followEndDate,
          clientSource: clientSourceData,
          institutionId: selectedProvider,
          query: searchData,
          columnName: sortState,
          direction: direction,
          page: Number(0),
          size: Number(rows),
        };
        getAllData(headData);
      } else {
        let headData = {
          Authorization: jwtToken,
          workflowType: "SkillAssessment",
          courseId: optionElementId,
          subAgentId: subAgentId,
          currentStage: selectedCurrentStage,
          officeId: selectedOfficeId,
          intake: selectedIntake,
          followUpStartDate: followStartDate,
          followUpEndDate: followEndDate,
          clientSource: clientSourceData,
          institutionId: selectedProvider,
          query: searchData,
          columnName: sortState,
          direction: direction,
          page: Number(0),
          size: Number(rows),
        };
        getAllData(headData);
      }
    }
  };

  const [datesRange, setDatesRange] = useState();
  const [followStartDate, setFollowStartDate] = useState(null);
  const [followEndDate, setFollowEndDate] = useState(null);
  const [dateValue, setDateValue] = useState("Click to open");
  const handleApply = (event, picker) => {
    setbackdropopen(true);
    setDateValue(
      `${picker.startDate.format("DD-MM-YYYY")} to ${picker.endDate.format(
        "DD-MM-YYYY"
      )}`
    );
    setpageno(0);
    setDateFilter(true);
    //console.log(event, picker);
    let data = {
      followUpStartDate: picker.startDate.format("YYYY-MM-DD"),
      followUpEndDate: picker.endDate.format("YYYY-MM-DD"),
    };
    setFollowStartDate(picker.startDate.format("YYYY-MM-DD"));
    setFollowEndDate(picker.endDate.format("YYYY-MM-DD"));
    setDatesRange(data);
    setpageno(0);

    if (assigneeState) {
      let headData = {
        Authorization: jwtToken,
        workflowType: "SkillAssessment",
        assigneeId: selectedAssigneeData,
        courseId: courseData,
        subAgentId: subAgentId,
        currentStage: selectedCurrentStage,
        officeId: selectedOfficeId,
        intake: selectedIntake,
        followUpStartDate: picker.startDate.format("YYYY-MM-DD"),
        followUpEndDate: picker.endDate.format("YYYY-MM-DD"),
        clientSource: clientSourceData,
        institutionId: selectedProvider,
        query: searchData,
        columnName: sortState,
        direction: direction,
        page: Number(0),
        size: Number(rows),
      };
      getAllData(headData);
    } else {
      let headData = {
        Authorization: jwtToken,
        workflowType: "SkillAssessment",
        courseId: courseData,
        subAgentId: subAgentId,
        currentStage: selectedCurrentStage,
        officeId: selectedOfficeId,
        intake: selectedIntake,
        followUpStartDate: picker.startDate.format("YYYY-MM-DD"),
        followUpEndDate: picker.endDate.format("YYYY-MM-DD"),
        clientSource: clientSourceData,
        institutionId: selectedProvider,
        query: searchData,
        columnName: sortState,
        direction: direction,
        page: Number(0),
        size: Number(rows),
      };
      getAllData(headData);
    }
  };

  const [assigneeState, setAssigneeState] = useState();
  const [selectedAssigneeData, setSelectedAssigneeData] = useState();
  const onSelectWorkload = (selectedList, selectedItem) => {
    const consultant_obj = [];
    for (let i = 0; i < selectedList.length; i++) {
      consultant_obj.push(selectedList[i].value);
    }
    //console.log("Office id --",consultant_obj);
    if (consultant_obj.length > 0) {
      setSelectedAssigneeData(consultant_obj.toString());
      setAssigneeState(true);

      let headData = {
        Authorization: jwtToken,
        workflowType: "SkillAssessment",
        assigneeId: consultant_obj.toString(),
        courseId: courseData,
        subAgentId: subAgentId,
        currentStage: selectedCurrentStage,
        officeId: selectedOfficeId,
        intake: selectedIntake,
        followUpStartDate: followStartDate,
        followUpEndDate: followEndDate,
        clientSource: clientSourceData,
        institutionId: selectedProvider,
        query: searchData,
        columnName: sortState,
        direction: direction,
        page: Number(0),
        size: Number(rows),
      };
      getAllData(headData);
    } else {
      setAssigneeState(false);
      let headData = {
        Authorization: jwtToken,
        workflowType: "SkillAssessment",
        courseId: courseData,
        subAgentId: subAgentId,
        currentStage: selectedCurrentStage,
        officeId: selectedOfficeId,
        intake: selectedIntake,
        followUpStartDate: followStartDate,
        followUpEndDate: followEndDate,
        clientSource: clientSourceData,
        institutionId: selectedProvider,
        query: searchData,
        columnName: sortState,
        direction: direction,
        page: Number(0),
        size: Number(rows),
      };
      getAllData(headData);
    }
  };

  const getAllData = (headData) => {
    setbackdropopen(true);
    axios
      .get(`${base_url.api2}/getEnquiryByWorkflowType`, {
        headers: headData,
      })
      .then((response) => {
        headData.size = response.data.data.totalElements;
        if (response.data.data.totalElements == 0) {
          headData.size = Number(50);
          getPdfData(headData);
        } else {
          headData.size = Number(50);
          getPdfData(headData);
        }
        setgetpageno(response.data.data.totalPages);
        settotalUser(response.data.data.totalElements);
        setcountnumber(response.data.data.number);
        setnumberofElements(response.data.data.numberOfElements);
        setEnquiry(response.data.data.content);
        setbackdropopen(false);
      })
      .catch((error) => {
        setgetpageno(0);
        settotalUser(0);
        setcountnumber(0);
        setnumberofElements(0);
        setEnquiry();
        setbackdropopen(false);
        handle403error(error);
      });
  };

  const getPdfData = (headData) => {
    setbackdropopen(true);
    axios
      .get(`${base_url.api2}/getEnquiryByWorkflowType`, {
        headers: headData,
      })
      .then((response) => {
        setPdfRow(response.data.data.content);
        setbackdropopen(false);
      })
      .catch((error) => {
        setPdfRow();
        setbackdropopen(false);
        handle403error(error);
      });
  };

  const exportPdfSkillAssessment = () => {
    const doc = new jsPDF();
    // autoTable(doc, { html: '#myTable' })
    var col = [
      "Client Details",
      "Assignee",
      "Course Applied",
      "Office",
      "Source",
      "Created",
    ];
    var rows = [];
    pdfRow?.forEach((element) => {
      var temp = [
        element.clientName +
          "\n" +
          element.clientUsername +
          "\n" +
          "Phone: " +
          element.clientPhoneNumber,
        element.consultantName,
        "Provider: " +
          element.institutionName +
          "\n\n" +
          "Course: " +
          element.courseName +
          "\n\n" +
          "Intake: " +
          element.intake,
        element.officeName,
        element.enquirySource,
        moment(element.created).format("DD/MM/YYYY hh:mm:ss a"),
      ];
      rows.push(temp);
    });
    doc.autoTable(col, rows, { pageBreak: "auto" });
    // doc.autoTable(col, rows, { pageBreak: 'auto' });
    doc.save("SkillAssessment-Reports.pdf");
  };

  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            navigate("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };
  return (
    <div>
      <ToastContainer />
      <Backdrop className={classes.backdrop} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* <div className="commoncomponent_cardcss"> */}
      <div
        class="page-head d-flex justify-content-between p-4"
        style={{ marginTop: "5rem" }}
      >
        <ul class="breadcrums">
          <h3 class="page-title bold">SkillAssessment Report</h3>
          <Link to="/" class="b-link">
            Home
          </Link>
          -<a class="b-link">Reports</a>-<a class="b-link">Skill Assesment</a>-
          <a class="b-link active">Skill Assement Report</a>
        </ul>
        {/* <a href="workload-dashboard.html" class="back-btn">Back</a> */}
      </div>
      <div>
        <Row>
          {/* <Col md={6}>
              <CardText
                className={classes.headtext}
                style={{ marginTop: "-10px" }}
              >
                <strong>SkillAssessment Report</strong>
              </CardText>
            </Col> */}
          {/* <Col md={6}>
              <Row>
                <Col md={3}></Col>
                <Col md={3}></Col>
                <Col md={3}>
                  {permissionData?.includes(652) ? (
                    <button
                      className="btn listing_addbutton"
                      onClick={exportPdfSkillAssessment}
                    >
                      Export PDF
                    </button>
                  ) : null}
                </Col>
                <Col md={3}>
                  {enquiryData != null ? (
                    <>
                      {permissionData?.includes(653) ? (
                        <CSVLink
                          filename="SkillAssessment-Reports.csv"
                          data={enquiryData}
                          className="btn listing_addbutton"
                        // target="_blank"
                        >
                          Export CSV
                        </CSVLink>
                      ) : null}
                    </>
                  ) : null}
                </Col>
              </Row>
            </Col> */}
        </Row>
        <div
          className="newlisting_headertabdesign"
          style={{
            padding: "1.5rem",
            borderRight: "1.5rem solid #e9ebf2",
            borderLeft: "1.5rem solid #e9ebf2",
            borderTop: "1.5rem solid #e9ebf2",
            marginBottom: "0",
          }}
        >
          <Row>
            <Col md={6}>
              <form className="master">
                <div style={{ marginTop: "-10px", marginRight: "14px" }}>
                  <label className="area_rowslable">Limit</label>
                  <br />
                  <FormControl className={classes.formControl}>
                    <select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={rows}
                      onChange={handleRows}
                      style={{
                        border: "2px solid #ced4da ",
                        paddingTop: "5px",
                        backgroundColor: "#f5f5f9",
                      }}
                    >
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </FormControl>
                </div>
              </form>
            </Col>
            <Col md={6}>{null}</Col>
          </Row>

          <div className="form-group">
            <Row className="workflowcol">
              <Col>
                <label className="area_rowslable">Branch</label>
                <select
                  className={classes.selectdrop}
                  onChange={handleChangeOffice}
                  name="branch"
                  style={{ backgroundcolor: "#f5f5f9" }}
                >
                  {/* <option value="">- Select Branch -</option> */}
                  {Number(user?.isw_user_role) === 1 ||
                  Number(user?.isw_user_role) == 6 ? (
                    <option value="all">Show All</option>
                  ) : null}
                  {user?.isw_available_Office?.map((p, index) => (
                    <option key={index} id={p.officeName} value={p.officeId}>
                      {p.officeName}
                      {" - "}
                      {p.officeCode}
                    </option>
                  ))}
                </select>
              </Col>
              <Col>
                <label className="area_rowslable">Current Stage</label>
                <select
                  className={classes.selectdrop}
                  onChange={handleChangeCurrentStage}
                  name="currentStage"
                  style={{ backgroundcolor: "#f5f5f9" }}
                >
                  <option value="">- Select Current Stage -</option>
                  {allCurrentStage?.map((p, index) => (
                    <option key={index} value={p.currentStage}>
                      {p.currentStage}
                    </option>
                  ))}
                </select>
              </Col>
              <Col>
                <label className="area_rowslable">Provider</label>
                <select
                  className={classes.selectdrop}
                  onChange={handleChangeProvider}
                  name="institutionId"
                  style={{ backgroundcolor: "#f5f5f9" }}
                >
                  <option value="">- Select Provider -</option>
                  {allProvider?.map((p, index) => (
                    <option key={index} value={p.institutionId}>
                      {p.institutionName}
                    </option>
                  ))}
                </select>
              </Col>
              <Col>
                <label className="area_rowslable">Intake</label>
                <select
                  className={classes.selectdrop}
                  onChange={handleChangeIntake}
                  name="intake"
                  style={{ backgroundcolor: "#f5f5f9" }}
                >
                  <option value="">- Select Intake -</option>
                  {allIntake?.map((p, index) => (
                    <option key={index} value={p.intake}>
                      {p.name}
                    </option>
                  ))}
                </select>
              </Col>
              <Col>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <label
                    className="area_rowslable_my"
                    style={{ fontWeight: "bold" }}
                  >
                    Follow Up Date
                  </label>
                  <DateRangePicker onApply={handleApply}>
                    <p
                      className="datepickerrangevaluetext"
                      style={{ backgroundcolor: "#f5f5f9" }}
                    >
                      {dateValue}
                    </p>
                  </DateRangePicker>
                  {dateFilter ? (
                    <div style={{ float: "left" }}>
                      <a
                        className={classes.clrsrch}
                        onClick={() => {
                          setDateValue("Click to open");
                          setdisplay("inline");
                          setdirection("DESC");
                          setDateFilter(false);
                          let headData = {
                            Authorization: jwtToken,
                            workflowType: "SkillAssessment",
                            assigneeId: selectedAssigneeData,
                            courseId: courseData,
                            subAgentId: subAgentId,
                            currentStage: selectedCurrentStage,
                            officeId: selectedOfficeId,
                            intake: selectedIntake,
                            followUpStartDate: null,
                            followUpEndDate: null,
                            clientSource: clientSourceData,
                            institutionId: selectedProvider,
                            query: searchData,
                            columnName: sortState,
                            direction: direction,
                            page: Number(0),
                            size: Number(rows),
                          };
                          getAllData(headData);
                        }}
                      >
                        Clear Search
                      </a>
                    </div>
                  ) : null}
                </div>
              </Col>
              <Col className="mt-4">
                <div className="clearsearchclass">
                  <form
                    id="myForm"
                    className="d-flex"
                    onSubmit={(e) => {
                      e.preventDefault();
                      submitSearch(e);
                    }}
                  >
                    <input
                      className="form-control"
                      placeholder="Search.."
                      name="search"
                    />
                    <button className="btn listpagesearch_button" type="submit">
                      <i className="fa fa-search"></i>
                    </button>
                  </form>
                </div>
                {searchStatus ? (
                  <a
                    className={classes.clrsrch}
                    onClick={() => {
                      setdisplay("inline");
                      setpageno(0);
                      setSearchData(null);
                      setSearchStatus(false);
                      let headData = {
                        Authorization: jwtToken,
                        workflowType: "SkillAssessment",
                        assigneeId: selectedAssigneeData,
                        courseId: courseData,
                        subAgentId: subAgentId,
                        currentStage: selectedCurrentStage,
                        officeId: selectedOfficeId,
                        intake: selectedIntake,
                        followUpStartDate: followStartDate,
                        followUpEndDate: followEndDate,
                        clientSource: clientSourceData,
                        institutionId: selectedProvider,
                        query: searchData,
                        columnName: sortState,
                        direction: direction,
                        page: Number(0),
                        size: Number(rows),
                      };
                      getAllData(headData);
                      try {
                        document.getElementById("myForm").reset();
                      } catch (error) {
                        //console.log(error);
                      }
                    }}
                  >
                    Clear Search
                  </a>
                ) : null}
              </Col>
            </Row>
          </div>

          <div className="form-group">
            <Row className="workflowcol">
              <Col md={4}>
                <label className="area_rowslable">Consultant</label>
                <Select
                  isMulti
                  name="assigneeId"
                  options={assigneeData}
                  className="header_dropdown"
                  style={{ backgroundcolor: "#f5f5f9" }}
                  // styles={styles}
                  // controlShouldRenderValue = {consultantState?.length <='2'?true:false}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={onSelectWorkload}
                />
              </Col>
              <Col md={2}>
                <label className="area_rowslable">Sub Agent</label>
                <select
                  className={classes.selectdrop}
                  name="subagent"
                  onChange={handleChangeSubAgent}
                >
                  <option value="">- Select sub agent -</option>
                  {subAgent?.map((cuntitem) => (
                    <option
                      key={cuntitem.id}
                      value={cuntitem.username}
                      id={cuntitem.id}
                    >
                      {cuntitem.name}
                    </option>
                  ))}
                </select>
              </Col>
              <Col md={2}>
                <label className="area_rowslable">Client Source</label>
                <select
                  native
                  id="demo-simple-select3"
                  className={classes.selectdrop}
                  onChange={handleChangeClientSource}
                  style={{ backgroundcolor: "#f5f5f9" }}
                >
                  <option value="">- Select Source -</option>
                  {allClientSource?.map((cuntitem) => (
                    <option
                      key={cuntitem.id}
                      value={cuntitem.name}
                      id={cuntitem.id}
                    >
                      {cuntitem.name}
                    </option>
                  ))}
                </select>
              </Col>
              <Col md={4}>
                <label className="area_rowslable">Course</label>
                <select
                  native
                  id="demo-simple-select3"
                  className={classes.selectdrop}
                  onChange={handleChangeCourse}
                  style={{ backgroundcolor: "#f5f5f9" }}
                >
                  <option value="">- Select Course -</option>
                  {allCourses?.map((cuntitem) => (
                    <option
                      key={cuntitem.course_id}
                      value={cuntitem.name}
                      id={cuntitem.course_id}
                    >
                      {cuntitem.name}
                    </option>
                  ))}
                </select>
              </Col>
            </Row>
          </div>
        </div>
        <div
          className="newlisting_headertabdesign"
          style={{
            padding: "1.5rem",
            border: "1.5rem solid #e9ebf2",
            marginTop: "0",
            marginBottom: "0",
          }}
        >
          <Row>
            <Col md={6}>
              {enquiryData?.length ? (
                <p style={{ marginTop: "15px" }}>
                  {countnumber * rows + 1} to{" "}
                  {countnumber * rows + numberofElements} of {totalUser} records
                </p>
              ) : (
                <p className="formfooterrecordstyle">No Record Found</p>
              )}
            </Col>
            <Col md={6}>
              <Row>
                <Col md={3}></Col>
                <Col md={3}></Col>
                <Col md={3}>
                  {permissionData?.includes(202) ? (
                    <button
                      className="btn listing_addbutton"
                      onClick={exportPdfSkillAssessment}
                    >
                      Export PDF
                    </button>
                  ) : null}
                </Col>
                <Col md={3}>
                  {pdfRow != null ? (
                    <>
                      {permissionData?.includes(203) ? (
                        <CSVLink
                          filename="SkillAssessment-Reports.csv"
                          data={pdfRow}
                          className="btn listing_addbutton"
                          // target="_blank"
                        >
                          Export CSV
                        </CSVLink>
                      ) : null}
                    </>
                  ) : null}
                </Col>
              </Row>
            </Col>
          </Row>
          <div className="above-scroller" onScroll={scrolldiv}>
            <div className="scroller"></div>
          </div>
          <div className="displaytablerow">
            {/* <Table responsive striped onScroll={scrolltable}> */}
            <Table
              className="workload card-table table-borderless"
              responsive
              striped
              onScroll={scrolltable}
            >
              <thead>
                <tr>
                  <th style={{ width: "60px", textAlign: "center" }}>SN</th>
                  <th style={{ minWidth: "160px" }}>
                    CLIENT {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="client_name"
                      onClick={sortWorkflow}
                      style={{ cursor: "pointer", display: `${display1}` }}
                    />
                  </th>
                  <th style={{ minWidth: "115px" }}>
                    SOURCE {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="enquiry_source"
                      onClick={sortWorkflow}
                      style={{ cursor: "pointer", display: `${display1}` }}
                    />
                  </th>
                  <th style={{ minWidth: "100px" }}>
                    STATUS {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="enquiry_status"
                      onClick={sortWorkflow}
                      style={{ cursor: "pointer", display: `${display1}` }}
                    />
                  </th>
                  <th style={{ minWidth: "170px" }}>
                    CURRENT STAGE {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="current_stage"
                      onClick={sortWorkflow}
                      style={{ cursor: "pointer", display: `${display1}` }}
                    />
                  </th>
                  <th style={{ minWidth: "200px" }}>
                    PROVIDER {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="institution_name"
                      onClick={sortWorkflow}
                      style={{ cursor: "pointer", display: `${display1}` }}
                    />
                  </th>
                  <th style={{ minWidth: "175px" }}>
                    COURSE {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="course_name"
                      onClick={sortWorkflow}
                      style={{ cursor: "pointer", display: `${display1}` }}
                    />
                  </th>
                  <th style={{ minWidth: "104px" }}>
                    INTAKE {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="intake"
                      onClick={sortWorkflow}
                      style={{ cursor: "pointer", display: `${display1}` }}
                    />
                  </th>
                  <th style={{ minWidth: "120px" }}>
                    BRANCH {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="office_name"
                      onClick={sortWorkflow}
                      style={{ cursor: "pointer", display: `${display1}` }}
                    />
                  </th>
                  <th style={{ minWidth: "140px" }}>
                    CONSULTANT {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="consultant_name"
                      onClick={sortWorkflow}
                      style={{ cursor: "pointer", display: `${display1}` }}
                    />
                  </th>
                  <th style={{ minWidth: "140px" }}>
                    SUBAGENT {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="sub_agent"
                      onClick={sortWorkflow}
                      style={{ cursor: "pointer", display: `${display1}` }}
                    />
                  </th>
                  <th style={{ minWidth: "140px" }}>
                    SUPERAGENT {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="super_agent"
                      onClick={sortWorkflow}
                      style={{ cursor: "pointer", display: `${display1}` }}
                    />
                  </th>
                  <th style={{ minWidth: "180px" }}>
                    UPDATED ON {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="updated"
                      onClick={sortWorkflow}
                      style={{ cursor: "pointer", display: `${display1}` }}
                    />
                  </th>
                  <th style={{ minWidth: "180px" }}>
                    FOLLOWUP DATE {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="follow_up_date"
                      onClick={sortWorkflow}
                      style={{ cursor: "pointer", display: `${display1}` }}
                    />
                  </th>
                  <th style={{ minWidth: "160px" }}>
                    REMARKS {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="remarks"
                      onClick={sortWorkflow}
                      style={{ cursor: "pointer", display: `${display1}` }}
                    />
                  </th>
                </tr>
              </thead>
              {enquiryData != null ? (
                <tbody>
                  {enquiryData?.map((p, index) => (
                    <tr key={p.enquiryId}>
                      <td
                        style={{
                          width: "50px",
                          textAlign: "center",
                        }}
                      >
                        {countnumber * rows + 1 + index}
                      </td>
                      <td>
                        <Link
                          onClick={(e) => viewWorkflow(p, e)}
                          to={
                            `/view-enquiry-workflow/` +
                            p.clientId +
                            `/` +
                            p.enquiryId +
                            `/` +
                            p.clientUsername
                          }
                        >
                          {p.clientName}
                        </Link>
                        <br />
                        {p.clientUsername}
                      </td>
                      <td>{p.enquirySource}</td>
                      {p.enquiryStatus === "Discontinued" ? (
                        <td class="text-danger">{p.enquiryStatus}</td>
                      ) : (
                        <>
                          {p.enquiryStatus === "Completed" ? (
                            <td style={{ color: "green" }}>
                              {p.enquiryStatus}
                            </td>
                          ) : (
                            <td>{p.enquiryStatus}</td>
                          )}
                        </>
                      )}
                      <td>{p.currentStage}</td>
                      <td>{p.institutionName}</td>
                      <td>{p.courseName}</td>
                      <td>{p.intake}</td>
                      <td>{p.officeCode}</td>
                      <td>
                        {p.consultantName?.split(" ( ")[0]}
                        <br />
                        {p.consultantName?.split(" ( ")[1]?.slice(0, -1)}
                      </td>
                      <td>
                        {p.subAgent?.split(" (")[1]?.slice(0, -1)}
                        <br />
                        {p.subAgent?.split(" (")[0]}
                      </td>
                      <td>
                        {p.superAgent?.split(" (")[1]?.slice(0, -1)}
                        <br />
                        {p.superAgent?.split(" (")[0]}
                      </td>
                      <td>
                        {p.updated
                          ? moment(p.updated.split(".")[0]).format("DD-MMM-YY")
                          : null}
                        <br />
                        {p.updated
                          ? moment(p.updated.split(".")[0]).format("hh.mm a ")
                          : null}
                      </td>
                      <td>
                        {p.followUpDate
                          ? moment(p.followUpDate.split(".")[0]).format(
                              "DD-MMM-YY"
                            )
                          : null}
                        <br />
                        {p.followUpDate
                          ? moment(p.followUpDate.split(".")[0]).format(
                              "hh.mm a"
                            )
                          : null}
                      </td>
                      <td>{p.remarks}</td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <Typography>{null}</Typography>
              )}
            </Table>
            <Row>
              <Col md={6}>
                {enquiryData?.length ? (
                  <p style={{ marginTop: "15px" }}>
                    {countnumber * rows + 1} to{" "}
                    {countnumber * rows + numberofElements} of {totalUser}{" "}
                    records
                  </p>
                ) : (
                  <p className="formfooterrecordstyle">No Record Found</p>
                )}
              </Col>
              <Col md={6}>
                <div className={classes.root}>
                  <Pagination
                    page={paginationpageno}
                    count={getpageno}
                    onChange={handlePage}
                    shape="rounded"
                  />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
}

export default SkillAssessmentReport;
