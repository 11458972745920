import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import calender from "../../images/workload/calendar.svg";
import { customStyles } from "../../../Utilities/CSSUtilities";
import DatePicker from "react-datepicker";

function AdmissionAppointmentModal({
  openmodalappointment,
  onSubmitAppointment,
  selecteddate,
  datetimelocal,
  setdate,
  modalIsopen4,
  isApplicationClosed,
}) {
  const [appointmentmodal, setappointmentmodal] = useState(false);

  useEffect(() => {
    if (!modalIsopen4) {
      closeappointmentmodal();
    }
  }, [modalIsopen4]);

  const openappointmentmodal = () => {
    setappointmentmodal(true);
  };
  const closeappointmentmodal = () => setappointmentmodal(false);
  return (
    <>
      <img
        src={calender}
        title="appointment"
        alt=""
        style={{ margin: "1px" }}
        onClick={openappointmentmodal}
      />
      <Modal
        isOpen={isApplicationClosed ? false : appointmentmodal}
        style={customStyles}
        onRequestClose={openappointmentmodal}
        contentLabel="Example Modal"
      >
        <div>
          <h2>Create Appointment</h2>
          <hr />
          <div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                onSubmitAppointment(e);
                setappointmentmodal(false);
              }}
            >
              <label>Appointment Note</label>
              <input type="text" className="form-control" />
              <hr />
              <label>Appointment Date</label>
              <DatePicker
                selected={selecteddate ? new Date(selecteddate) : null}
                onChange={(date) => setdate(date)}
                minDate={new Date(datetimelocal)}
                showTimeSelect
                timeFormat="p"
                timeIntervals={15}
                dateFormat="Pp"
              />
              <br />
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: "5px",
                }}
              >
                <button
                  className="btn btn-secondary"
                  onClick={closeappointmentmodal}
                >
                  Cancel
                </button>
                <button className="btn listing_addbutton ml-1" type="submit">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default AdmissionAppointmentModal;
