import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardText, Col, Row, Button } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";
import base_url from "../../api/bootapi";
// import SunEditor from "suneditor-react";
// import "suneditor/dist/css/suneditor.min.css";
import { useStyles } from "../../Utilities/CSSUtilities";
import Modal from "react-modal";
import { ToastContainer, toast } from "react-toastify";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Pagination from "@mui/material/Pagination";
import { logout, selectUser } from "../features/userSlice";
import moment from "moment";
import { check1pointpermission } from "../Permission/permission";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { editorConfiguration } from "../../Utilities/CSSUtilities";

export const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    marginTop: "70px",
    minWidth: "800px",
    maxWidth: "800px",
    minHeight: "800px",
    maxHeight: "800px",
    transform: "translate(-50%, -50%)",
  },
};

export const customStyles5 = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    marginTop: "70px",
    minWidth: "800px",
    maxWidth: "800px",
    minHeight: "800px",
    maxHeight: "800px",
    transform: "translate(-50%, -50%)",
    padding: "0px 20px 20px",
  },
};

export const customStyles2 = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    marginTop: "70px",
    minWidth: "500px",
    maxWidth: "500px",
    minHeight: "500px",
    maxHeight: "600px",
    transform: "translate(-50%, -50%)",
  },
};

export const customStyles3 = {
  content: {
    top: "55%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    minWidth: "500px",
    maxWidth: "500px",
    minHeight: "800px",
    maxHeight: "800px",
    transform: "translate(-50%, -50%)",
  },
};

export const customStyles4 = {
  content: {
    top: "55%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    minWidth: "500px",
    maxWidth: "500px",
    minHeight: "800px",
    maxHeight: "800px",
    transform: "translate(-50%, -50%)",
  },
};

const EnquiryNotesDisplay = () => {
  const user = useSelector(selectUser);
  const isw_user_name = localStorage.getItem("isw_user_name");
  const dispatch = useDispatch();
  const editorRef = useRef();
  const [backdropopen, setbackdropopen] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const classes = useStyles();
  const [pageno, setpageno] = useState(0);
  const [totalUser, settotalUser] = useState();
  const [countnumber, setcountnumber] = useState();
  const [numberofElements, setnumberofElements] = useState();
  const [clientnotes, setclientnotes] = useState();
  const [getpageno, setgetpageno] = useState();
  const [descriptionState2, setdescriptionState2] = useState(null);
  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  const selected_client_id =
    params.client_id || localStorage.getItem("selected_client_id");
  // const selected_client_id = localStorage.getItem("selected_client_id");
  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);
  const [opennotemodal, setopennotemodal] = useState(false);
  const [showaddnotebutton, setshowaddnotebutton] = useState(true);
  const [noteworkflowtype, setnoteworkflowtype] = useState("");
  const [getremarks, setremarks] = useState();

  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            navigate("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        navigate("/");
      }
    });
    // setbackdropopen(true);
    getPermissions();
  }, []);

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);

    if (check1pointpermission(306, permission_obj, resource_obj)) {
      getclientreport(pageno);
      getproforma();
    } else {
      navigate("/errorPage");
    }
  };

  const getclientreport = (pageno) => {
    axios
      .get(`${base_url.api3}/getClientNotesByClientIdPagination`, {
        headers: {
          Authorization: jwtToken,
          clientId: selected_client_id,
          columnName: "lastUpdatedOn",
          direction: "DESC",
          page: pageno,
          // size: 6,
        },
      })
      .then((res) => {
        setclientnotes(res.data.data.content);
        //console.log("190---->", res.data.data.content);
        setgetpageno(res.data.data.totalPages);
        settotalUser(res.data.data.totalElements);
        setcountnumber(res.data.data.number);
        setnumberofElements(res.data.data.numberOfElements);
      })
      .catch((err) => {
        setclientnotes(null);
        setgetpageno(null);
        settotalUser(null);
        setcountnumber(null);
        setnumberofElements(null);
      });
  };
  const handlepage = (event, value) => {
    setpageno(value - 1);
    getclientreport(value - 1);
  };
  const [clientnotesdetail, setclientnotesdetail] = useState();
  const [openeditmodal, setopeneditmodal] = useState(false);
  const [openviewmodal, setopenviewmodal] = useState(false);
  const seteditnote = (p) => {
    //console.log("212--->", p);

    const currentDate = new Date().toLocaleString();

    const updatedNote = `<p><strong>Added By:</strong> ${isw_user_name}</p>
                        <p><strong>Added On:</strong> ${currentDate}</p>
                        ${p.note}`;

    const updatedPara = { ...p, note: updatedNote };

    //console.log("224--->", updatedPara);

    setclientnotesdetail(updatedPara);
    setopeneditmodal(true);
  };
  const setviewnote = (p) => {
    setclientnotesdetail(p);
    setopenviewmodal(true);
  };
  const changeNotes = (e) => {
    clientnotesdetail.note = e;
    setdescriptionState2(clientnotesdetail.note);
    //console.log(clientnotesdetail.note);
    let data = clientnotesdetail.note;
    //console.log(typeof data);
    data.replace("<br>", "<p>");
    //console.log(data);
    //console.log("<br>" +<>in</>+ clientnotesdetail.note);
  };
  //console.log(clientnotesdetail);

  const editnote = (e) => {
    //console.log(e);
    setbackdropopen(true);
    const isoDateString = new Date().toISOString();
    const datenow = isoDateString.split(".")[0];
    let note;
    if (workloaddescriptionvalue) {
      note = workloaddescriptionvalue;

      //console.log(note);
    } else {
      note = workloaddescriptionvalue2;
      //console.log(note);
    }
    const data = {
      id: clientnotesdetail?.id,
      addedBy: isw_user_name,
      // note: descriptionState2,
      note: note,
      workflowType: clientnotesdetail?.workflowType,
      lastUpdatedOn: datenow,
    };
    axios
      .post(`${base_url.api3}/updateClientNotesById`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        //console.log(res.data.data.note);

        let getmynote = res.data.data.note;
        // window.location.reload();
        toast.success("Note Updated");

        console.log("278--->After Toast");

        let auditdata = {
          addedBy: isw_user_name,
          clientId: selected_client_id,
          id: 0,
          //note: descriptionState2,
          note: getmynote,
          workflowType: clientnotesdetail?.workflowType,
          lastUpdatedOn: data.lastUpdatedOn,
        };
        //console.log(auditdata);
        axios
          .post(`${base_url.api3}/saveAuditNotes`, auditdata, {
            headers: {
              Authorization: jwtToken,
            },
          })
          .then((response) => {
            //console.log(response);

            axios
              .get(`${base_url.api3}/getAuditNotesByClientId`, {
                headers: {
                  Authorization: jwtToken,
                  clientId: selected_client_id,
                  columnName: "last_updated_on",
                  direction: "DESC",
                  page: pageno,
                  size: 3,
                },
              })
              .then((response) => {
                //console.log(response);
                setworkloaddescriptionvalue(null);
                setworkloaddescriptionvalue2(null);
              });
          })
          .catch((error) => {
            //console.log(error);
            handle403error(error);
          });

        setbackdropopen(false);
        setopeneditmodal(false);
        getclientreport(pageno);
        settemplate(null);
      });
  };

  const submitNote = (e) => {
    const isoDateString = new Date().toISOString();
    const datenow = isoDateString.split(".")[0];
    let note;
    if (workloaddescvalue) {
      note = workloaddescvalue;
    } else {
      note = workloaddescvalue2;
    }
    const data = {
      addedBy: isw_user_name,
      clientId: selected_client_id,
      createdOn: datenow,
      id: 0,
      //note: descriptionState2,
      note: note,
      workflowType: noteworkflowtype,
      lastUpdatedOn: datenow,
    };
    //console.log(data);
    postClientNote(data);
  };

  const handleImageUploadBefore = (files, info, uploadHandler) => {
    //console.log(files, info);
  };

  const postClientNote = (data) => {
    axios
      .post(`${base_url.api3}/saveClientNotes`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        toast.success("Notes Added");

        let auditdata = {
          addedBy: isw_user_name,
          clientId: selected_client_id,
          createdOn: data.createdOn,
          id: 0,
          //note: descriptionState2,
          note: data.note,
          workflowType: noteworkflowtype,
          lastUpdatedOn: data.lastUpdatedOn,
        };
        //console.log(auditdata);
        axios
          .post(`${base_url.api3}/saveAuditNotes`, auditdata, {
            headers: {
              Authorization: jwtToken,
            },
          })
          .then((response) => {
            //console.log(response);

            axios
              .get(`${base_url.api3}/getAuditNotesByClientId`, {
                headers: {
                  Authorization: jwtToken,
                  clientId: selected_client_id,
                  columnName: "last_updated_on",
                  direction: "DESC",
                },
              })
              .then((response) => {
                //console.log(response);
              });
          })
          .catch((error) => {
            //console.log(error);
            handle403error(error);
          });

        getclientreport(pageno);
        setopennotemodal(false);
      })
      .catch((err) => {
        //console.log("No Data Available");
      });
  };

  const [workloaddescriptionvalue2, setworkloaddescriptionvalue2] =
    useState(null);
  const [workloaddescriptionvalue, setworkloaddescriptionvalue] =
    useState(null);

  const [workloaddescvalue2, setworkloaddescvalue2] = useState(null);
  const [workloaddescvalue, setworkloaddescvalue] = useState(null);

  // Proforma templates
  const [template, settemplate] = useState(null);
  const [firsttemplate, setfirsttemplate] = useState(null);
  const [loadermodal, setloadermodal] = useState(true);
  const [proformadetails, setproformadetails] = useState();
  const getproforma = () => {
    axios
      .get(`${base_url.api2}/getAllProforma`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        //console.log("Proforma res", res);
        const data = res.data.data;
        const filteredData = data.filter((item) => item.status === 1);
        setproformadetails(filteredData);
        setTimeout(() => {
          setloadermodal(true);
        }, 2000);
      })
      .catch((err) => {
        setloadermodal(true);
        handle403error(err);
      });
  };

  const goBack = () => {
    if (params.enquiry_id) {
      var prev_path = localStorage.getItem("prev_path2");
      navigate(prev_path);
    } else if (params.client_id) {
      navigate("/client-list");
    }
  };

  let id = document.getElementsByClassName(
    "se-wrapper-inner se-wrapper-wysiwyg sun-editor-editable"
  );
  //console.log(id);
  //let paragraph = id.getElementsByTagName("br");
  //console.log(paragraph);

  return (
    <div>
      <ToastContainer />

      <Backdrop className={classes.backdrop} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Card className="p-3">
        <div>
          <div>
            <div>
              <CardText
                className={classes.headtext}
                style={{ fontSize: "23px", textAlign: "left" }}
              >
                <strong>Notes</strong>
              </CardText>
            </div>
            <div class="d-flex justify-content-end">
              {showaddnotebutton ? (
                <>
                  {check1pointpermission(
                    307,
                    permissionData,
                    rolepermission
                  ) ? (
                    <button
                      className="outline-button"
                      onClick={() => setopennotemodal(true)}
                      style={{ marginRight: "10px" }}
                    >
                      Add Note
                    </button>
                  ) : null}
                </>
              ) : null}
              <Modal
                isOpen={opennotemodal}
                onRequestClose={() => setopennotemodal(false)}
                style={customStyles}
                contentLabel="Example Modal"
                shouldCloseOnOverlayClick={false}
              >
                <div>
                  <div>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        submitNote(e);
                      }}
                      className="s_ckeditor"
                    >
                      <Row style={{ marginTop: "10px" }}>
                        <Col style={{ marginTop: "10px" }}>
                          <h4>Add Note</h4>
                        </Col>
                        <Col md={6} className="d-flex justify-content-end">
                          <button
                            className="btn btn-secondary cancel_margin"
                            onClick={() => setopennotemodal(false)}
                            style={{ margin: "5px" }}
                          >
                            Cancel
                          </button>
                          <button
                            className="btn listing_addbutton ml-1"
                            type="submit"
                            style={{ margin: "5px", width: "80px" }}
                          >
                            Save
                          </button>
                          {/* <p
                          onClick={() => setopennotemodal(false)}
                          style={{ cursor: "pointer",marginLeft:"220px" }}
                          className="mb-0"
                        >
                          X
                        </p> */}
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <label>Title</label>
                          <input
                            className="form-control"
                            // value={noteworkflowtype}
                            style={{ backgroundColor: "#f5f5f9" }}
                            onChange={(e) =>
                              setnoteworkflowtype(e.target.value)
                            }
                            required
                          />
                        </Col>
                        <Col md={6}>
                          <label>Templates</label>
                          <select
                            onChange={(e) => setfirsttemplate(e.target.value)}
                            style={{
                              width: "100%",
                              height: "40px",
                              backgroundColor: "f5f5f9",
                            }}
                          >
                            <option value="">- Select Template -</option>
                            {proformadetails?.map((item, index) => (
                              <option key={index} value={item.template}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                        </Col>
                      </Row>
                      <label style={{ marginTop: "30px" }}>Note</label>
                      {/* <SunEditor
                        rows={12}
                        ref={editorRef}
                        style={{ backgroundColor: "#f5f5f9" }}
                        height="600"
                        // setContents={descriptionState2}
                        onChange={(e) => setdescriptionState2(e)}
                        onImageUploadBefore={handleImageUploadBefore}
                        required
                        setOptions={{
                          buttonList: [
                            [
                              "bold",
                              "underline",
                              "italic",
                              "strike",
                              "list",
                              "align",
                              "fontSize",
                              "formatBlock",
                            ]
                          ]
                        }}
                      /> */}

                      {firsttemplate === null ? (
                        <CKEditor
                          editor={ClassicEditor}
                          config={editorConfiguration}
                          data={workloaddescvalue2}
                          onReady={(editor) => {
                            //console.log("Editor is ready to use!", editor);
                            // editor.execute("heading", { value: "heading6" });
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setworkloaddescvalue2(data);
                          }}
                        />
                      ) : (
                        <CKEditor
                          editor={ClassicEditor}
                          config={editorConfiguration}
                          data={firsttemplate}
                          onReady={(editor) => {
                            //console.log("Editor is ready to use!", editor);
                            // editor.execute("heading", { value: "heading6" });
                            // setloadermodal(true);
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setworkloaddescvalue(data);
                          }}
                        />
                      )}
                      <br />
                      {/* <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                padding: "5px",
                              }}
                            >
                              <button
                                className="btn btn-secondary cancel_margin"
                                style={{ margin: "5px" }}
                                onClick={() => setopennotemodal(false)}
                              >
                                Cancel
                              </button>
                              <button
                                className="btn listing_addbutton"
                                type="submit"
                                style={{ margin: "5px" }}
                              >
                                Submit
                              </button>
                        </div> */}
                    </form>
                  </div>
                </div>
              </Modal>
              <button className="sent-button " onClick={goBack}>
                ClientList
              </button>
            </div>
          </div>

          <br />
          <br />
          {/* <ul className="nav nav-tabs">
              <li className="nav-item ">
                <a
                  className="nav-link active"
                  data-toggle="tab"
                  href="#clientnotes"
                >
                  Client Notes
                </a>
              </li>
              <li className="nav-item ">
                <a className="nav-link" data-toggle="tab" href="#workloadnotes">
                  Workload Notes
                </a>
              </li>
            </ul> */}
          {/* <div
              className="tab-content"
              style={{ border: "1px solid  #dee2e6" }}
            > */}
          {/* <div id="clientnotes" className="container tab-pane active"> */}
          <div
            // className="workflowdashboard_clientnote"
            style={{ margin: "20px 0" }}
          >
            <div style={{ display: "flex", flexWrap: "wrap", rowGap: "20px" }}>
              {clientnotes?.map((p, index) => (
                <div
                  className="col-md-4 "
                  key={index + 1}
                  style={{ height: "260px" }}
                >
                  <div
                    className="enquirynotesdashboard_note"
                    style={{ cursor: "pointer", maxWidth: "95%" }}
                    onDoubleClick={() => setviewnote(p)}
                  >
                    <p
                      className="font-weight-bold"
                      style={{
                        marginBottom: "0.4rem",
                        lineHeight: "1",
                        fontSize: "20px",
                      }}
                    >
                      {p.workflowType}
                    </p>
                    <div className="sangam_html">
                      <div dangerouslySetInnerHTML={{ __html: p.note }} />
                    </div>
                  </div>
                  <div
                    className="workflowdashboard_absolute"
                    style={{ cursor: "pointer", width: "94%" }}
                    onDoubleClick={() => setviewnote(p)}
                  >
                    <div>
                      Last Modified: {moment(p.lastUpdatedOn).format("lll")}
                    </div>
                    <div style={{ position: "relative" }}>
                      <div
                        className="workflowdashboard_fa-ellipsis"
                        data-toggle="dropdown"
                      >
                        <i className="fa fa-ellipsis-v " aria-hidden="true"></i>
                      </div>
                      <div className="dropdown-menu workflowdashboarddropdown">
                        {check1pointpermission(
                          308,
                          permissionData,
                          rolepermission
                        ) ? (
                          <p onClick={() => seteditnote(p)}>Edit</p>
                        ) : null}
                        <p onClick={() => setviewnote(p)}>View</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <Modal
              isOpen={openeditmodal}
              onRequestClose={() => setopeneditmodal(false)}
              style={customStyles}
              contentLabel="Example Modal"
              shouldCloseOnOverlayClick={false}
            >
              <div>
                <div>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      editnote(e);
                    }}
                    className="s_ckeditor"
                  >
                    <Row style={{ marginTop: "10px" }}>
                      <Col style={{ marginTop: "10px" }}>
                        <h4>Edit Note </h4>
                      </Col>
                      <Col md={6}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            padding: "5px",
                          }}
                        >
                          <button
                            className="btn btn-secondary cancel_margin"
                            style={{ margin: "5px" }}
                            onClick={() => setopeneditmodal(false)}
                          >
                            Cancel
                          </button>
                          <button
                            className="btn listing_addbutton"
                            type="submit"
                            style={{ margin: "5px", width: "80px" }}
                          >
                            Save
                          </button>
                        </div>
                        {/* <p
                          onClick={() => setopeneditmodal(false)}
                          style={{ cursor: "pointer",marginLeft:"220px" }}
                          className="mb-0"
                        >
                          X
                        </p> */}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <label>Title</label>
                        <input
                          className="form-control"
                          value={clientnotesdetail?.workflowType}
                          required
                          onChange={(e) => {
                            let name = "workflowType";
                            setclientnotesdetail((prev) => {
                              return {
                                ...prev,
                                [name]: e.target.value,
                              };
                            });
                          }}
                        />
                      </Col>

                      <Col md={6}>
                        <label>Templates</label>
                        <select
                          onChange={(e) => settemplate(e.target.value)}
                          style={{
                            width: "100%",
                            height: "40px",
                            backgroundColor: "f5f5f9",
                          }}
                        >
                          <option value="">- Select Template -</option>
                          {proformadetails?.map((item, index) => (
                            <option key={index} value={item.template}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </Col>
                    </Row>

                    <label style={{ marginTop: "30px" }}>Note</label>
                    {/* <SunEditor
                      rows={5}
                      ref={editorRef}
                      height="600"
                      setContents={clientnotesdetail?.note}
                      style={{ lineheight: "initial !important" }}
                      onChange={(e) =>
                        // clientnotesdetail?.note = e;
                        // setdescriptionState2(e)
                        changeNotes(e)
                      }
                      setOptions={{
                        buttonList: [
                          [
                            "bold",
                            "underline",
                            "italic",
                            "strike",
                            "list",
                            "align",
                            "fontSize",
                            "formatBlock",
                          ]
                        ]
                      }}
                      required
                    /> */}
                    {/* <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            padding: "5px",
                          }}
                        >
                          <button
                            className="btn btn-secondary cancel_margin"
                            style={{ margin: "5px" }}
                            onClick={() => setopeneditmodal(false)}
                          >
                            Cancel
                          </button>
                          <button
                            className="btn listing_addbutton"
                            type="submit"
                            style={{ margin: "5px" }}
                          >
                            Submit
                          </button>
                    </div> */}

                    {template === null ? (
                      <CKEditor
                        editor={ClassicEditor}
                        config={editorConfiguration}
                        data={clientnotesdetail?.note}
                        onReady={(editor) => {
                          //console.log("Editor is ready to use!", editor);
                          // editor.execute("heading", { value: "heading6" });
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setworkloaddescriptionvalue2(data);
                          setremarks(data);
                        }}
                      />
                    ) : (
                      <CKEditor
                        editor={ClassicEditor}
                        config={editorConfiguration}
                        data={template}
                        onReady={(editor) => {
                          //console.log("Editor is ready to use!", editor);
                          // editor.execute("heading", { value: "heading6" });
                          // setloadermodal(true);
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setworkloaddescriptionvalue(data);
                          setremarks(data);
                        }}
                      />
                    )}

                    <Row>
                      <Col style={{ marginTop: "10px" }}></Col>
                      <Col md={6}></Col>
                    </Row>
                  </form>
                </div>
              </div>
            </Modal>
            <Modal
              isOpen={openviewmodal}
              onRequestClose={() => setopenviewmodal(false)}
              style={customStyles5}
              contentLabel="Example Modal"
              shouldCloseOnOverlayClick={false}
            >
              <Row
                style={{
                  paddingTop: "10px",
                  position: "sticky",
                  top: 0,
                  left: 0,
                  right: 0,
                  backgroundColor: "white",
                  width: "100%",
                }}
              >
                <Col style={{ marginTop: "10px" }}>
                  <h4> {clientnotesdetail?.workflowType}</h4>
                </Col>
                <Col md={6}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      padding: "5px",
                    }}
                  >
                    <button
                      className="btn btn-secondary cancel_margin"
                      style={{ margin: "5px" }}
                      onClick={() => setopenviewmodal(false)}
                    >
                      Close
                    </button>
                  </div>
                </Col>
              </Row>
              <div
                dangerouslySetInnerHTML={{
                  __html: clientnotesdetail?.note,
                }}
              />
            </Modal>
          </div>
          {/* <Row>
            <Col md={6}>
              {clientnotes?.length ? (
                <p style={{ marginTop: "15px" }}>
                  {countnumber * 6 + 1} to {countnumber * 6 + numberofElements}{" "}
                  of {totalUser} records
                </p>
              ) : (
                <p className="formfooterrecordstyle">No Record Found</p>
              )}
            </Col>
            <Col md={6}>
              <div className={classes.root}>
                <Pagination count={getpageno} onChange={handlepage} shape="rounded" />
              </div>
            </Col>
          </Row> */}
          {/* </div> */}
          {/* <div id="workloadnotes" className="container tab-pane">
                <div style={{ margin: "20px 0" }}>
                  <div className="workflowdashboard_clientnote">
                    <div style={{ position: "relative" }}>
                      <Table responsive striped>
                        <thead>
                          <tr>
                            <th style={{ width: "20px" }}>SN</th>
                            <th>Title</th>
                            <th style={{ textAlign: "end" }}>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {workloadnotes?.map((p, index) => (
                            <tr key={index + 1}>
                              <td style={{ width: "20px" }}>
                                {workloadcount * 20 + index + 1}
                              </td>
                              <td>{p.title}</td>
                              <td style={{ textAlign: "end", width: "20px" }}>
                                <div
                                  data-toggle="dropdown"
                                  style={{ cursor: "pointer" }}
                                >
                                  <i
                                    className="fa fa-ellipsis-v mr-4"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                                <div className="dropdown-menu workflowdashboarddropdown">
                                  <p onClick={() => seteditworkloadnote(p)}>
                                    Edit
                                  </p>
                                  <p onClick={() => setviewworkloadnote(p)}>
                                    View
                                  </p>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
                <Modal
                  isOpen={workloadviewmodal}
                  onRequestClose={() => setworkloadviewmodal(false)}
                  style={customStyles}
                  contentLabel="Example Modal"
                >
                  <h3>{workloadtemplatedesign?.title}</h3>
                  <div
                    id="modal"
                    dangerouslySetInnerHTML={{
                      __html: workloadtemplatedesign?.notes,
                    }}
                  />
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => setworkloadviewmodal(false)}
                  >
                    Close
                  </Button>
                </Modal>
                <Modal
                  isOpen={viewworkloadmodal}
                  onRequestClose={() => setviewworkloadmodal(false)}
                  style={customStyles}
                  contentLabel="Example Modal"
                >
                  <div>
                    <div>
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          workloadnoteeditsubmit(e);
                        }}
                      >
                        <Row>
                          <Col md={6}>
                            <h2>Note</h2>
                          </Col>
                          <Col md={6}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                padding: "5px",
                              }}
                            >
                              <button
                                className="cancel_margin btn btn-primary"
                                style={{ margin: "5px" }}
                                onClick={() => setviewworkloadmodal(false)}
                              >
                                Cancel
                              </button>
                              <button
                                type="submit"
                                style={{ margin: "5px" }}
                                className="btn btn-primary"
                              >
                                Save
                              </button>
                            </div>
                          </Col>
                        </Row>
                        <hr className="dashboardh" />
                        <Row>
                          <Col md={9}>
                            <label>Title</label>
                            <br />
                            <input
                              value={workloadtitlevalue}
                              onChange={(e) =>
                                setworkloadtitlevalue(e.target.value)
                              }
                              className="form-control underline-input"
                              placeholder="Title Here"
                              style={{ width: "100%" }}
                            />
                          </Col>
                          <Col md={3} className="form-group notesformmgroup">
                            <label>Templates</label>
                            <select
                              onChange={(e) => settemplate(e.target.value)}
                              style={{ width: "100%", height: "40px" }}
                            >
                              <option value="">- Select Template -</option>
                              {proformadetails?.map((item, index) => (
                                <option key={index} value={item.template}>
                                  {item.name}
                                </option>
                              ))}
                            </select>
                          </Col>
                        </Row>
                        <br />

                        <label style={{ marginTop: "20px" }}>Note</label>
                        {template === null ? (
                          <CKEditor
                            editor={Editor}
                            config={editorConfiguration}
                            data={workloaddescriptionvalue2}
                            onReady={(editor) => {
                              //console.log("Editor is ready to use!", editor);
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setworkloaddescriptionvalue2(data);
                            }}
                          />
                        ) : (
                          <CKEditor
                            editor={Editor}
                            config={editorConfiguration}
                            data={template}
                            onReady={(editor) => {
                              //console.log("Editor is ready to use!", editor);
                              // setloadermodal(true);
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setworkloaddescriptionvalue(data);
                            }}
                          />
                        )}
                      </form>
                    </div>
                  </div>
                </Modal>
                <Row>
                  <Col md={6}>
                    {workloadnotes?.length ? (
                      <p style={{ marginTop: "15px" }}>
                        {workloadcount * 20 + 1} to{" "}
                        {workloadcount * 20 + workloadnumberelements} of{" "}
                        {totalworkload} records
                      </p>
                    ) : (
                      <p className="formfooterrecordstyle">No Record Found</p>
                    )}
                  </Col>
                  <Col md={6}>
                    <div className={classes.root}>
                      <Pagination
                        count={getworkloadpageno}
                        onChange={handleworkloadpage}
                      />
                    </div>
                  </Col>
                </Row>
              </div> */}
          {/* </div> */}
        </div>
      </Card>
    </div>
  );
};

export default EnquiryNotesDisplay;
