// AppointmentIcon.jsx
import React from "react";
import { ReactComponent as AppointmentIconSvg } from "./AppointmentIcon.svg";

const AppointmentIcon = () => (
  <i className="icon">
    <AppointmentIconSvg />
  </i>
);

export default AppointmentIcon;