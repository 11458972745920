import React, { useEffect, useState } from "react";
import axios from "axios";
import base_url from "../../api/bootapi";
import { Card, Col, Row, Table, Button } from "reactstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  getworkflowType,
  logout,
  selectUser,
  getOfficeId,
} from "../features/userSlice";
import Modal from "react-modal";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import { useForm } from "react-hook-form";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Pagination from "@mui/material/Pagination";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import {
  useStyles,
  customStyles,
  addcustomStyles,
} from "../../Utilities/CSSUtilities";
import DatePicker from "react-datepicker";
import callsaveauditapi from "../../services/auditservice";
import sort from "./../images/sort.svg";
import { check1pointpermission } from "../Permission/permission";

const WorkflowListing = (props) => {
  const user = useSelector(selectUser);
  const userDefaultOfficeId = useSelector(getOfficeId);
  const classes = useStyles();
  const navigate = useNavigate();
  const jwttoken = "Bearer " + localStorage.getItem("isw_user_token");
  const dispatch = useDispatch();
  let params = useParams();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [pageno, setpageno] = useState(0);
  const [getpageno, setgetpageno] = useState();
  const [sortState, setsortState] = useState("created");
  const [direction, setdirection] = useState("DESC");
  const [backdropopen, setbackdropOpen] = React.useState(false);
  const [editenquirydetails, seteditenquirydetails] = useState();
  const isw_user_name = localStorage.getItem("isw_user_name");
  const isw_adminId = localStorage.getItem("isw_adminId");
  const isw_user_email = localStorage.getItem("isw_user_email");
  const [totalUser, settotalUser] = useState();
  const [countnumber, setcountnumber] = useState();
  const [numberofElements, setnumberofElements] = useState();
  const [followdate, setfollowdate] = useState(null);
  const [deadlinedate, setdeadlinedate] = useState(null);
  const [visaexpirydate, setvisaexpirydate] = useState(null);
  const [isbranchselected, setIsBranchSelected] = useState(true);

  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        navigate("/");
      }
    });
    getPermissions();
  }, []);

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);

    if (check1pointpermission(306, permission_obj, resource_obj)) {
      getClient();
    } else {
      navigate("/errorPage");
    }
  };

  const [workflowdata, setworkflowdata] = useState();

  const handlepage = (event, value) => {
    setpageno(value - 1);
    getallworkflow(value - 1);
  };

  const getallworkflow = (value1) => {
    let selectedCLientId;
    if (props.clientId != null) {
      selectedCLientId = props.clientId;
      //console.log(props.clientId);
    } else {
      selectedCLientId = clientdetails.id;
    }
    //console.log(clientdetails.id);
    axios
      .get(`${base_url.api2}/getEnquiryByClientIdAndWorkflowType`, {
        headers: {
          Authorization: jwttoken,
          page: Number(value1),
          size: 50,
          columnName: sortState,
          direction: direction,
          clientId: selectedCLientId,
          workflowType: props.workflowType,
        },
      })
      .then((res) => {
        setworkflowdata(res.data.data.content);
        setgetpageno(res.data.data.totalPages);
        settotalUser(res.data.data.totalElements);
        setcountnumber(res.data.data.number);
        setnumberofElements(res.data.data.numberOfElements);
      })
      .catch((error) => {
        handle403error(error);
      });
  };

  const sortClient = (e) => {
    setsortState(e.target.id);
    if (direction == "DESC") {
      setdirection("ASC");
    } else if (direction == "ASC") {
      setdirection("DESC");
    }
    axios
      .get(`${base_url.api2}/getEnquiryByClientIdAndWorkflowType`, {
        headers: {
          Authorization: jwttoken,
          page: Number(pageno),
          size: 50,
          columnName: e.target.id,
          direction: direction,
          clientId: clientdetails.id,
          workflowType: props.workflowType,
        },
      })
      .then((response) => {
        setworkflowdata(response.data.data.content);
        setgetpageno(response.data.data.totalPages);
      })
      .catch((error) => {
        toast.error(JSON.stringify(error.response.data.message));
        handle403error(error);
      });
  };
  const [allintake, setIntake] = useState();
  const getAllintake = () => {
    axios
      .get(`${base_url.api1}/getAllIntake`, {
        headers: {
          Authorization: jwttoken,
        },
      })
      .then((res) => {
        let intakeData = res.data.data;
        let intakeObj = [];
        for (let i = 0; i < intakeData.length; i++) {
          let data = {
            createdIntake: moment(intakeData[i].createdIntake).format(
              "YYYY-MM"
            ),
            id: intakeData[i].id,
            name: intakeData[i].name,
            status: intakeData[i].status,
          };
          intakeObj.push(data);
        }
        let newintakeObj = intakeObj.sort(function (a, b) {
          return b.val - a.val;
        });
        setIntake(newintakeObj);
      })
      .catch((err) => {
        toast.error(JSON.stringify(err?.response?.data?.message));
        handle403error(err);
      });
  };

  const [clientStatusText, setclientStatusText] = useState();
  const [clientstatus, setclientstatus] = useState();

  const [clientdetails, setclientdetails] = useState();
  const [client_out_details, setclient_out_details] = useState();
  const getClient = () => {
    axios
      .get(`${base_url.api3}/getClientByUsername`, {
        headers: {
          Authorization: jwttoken,
          username: params.client_name,
        },
      })
      .then((res) => {
        setclientdetails(res.data.data);
        let client2 = res.data.data;
        setclient_out_details(client2);
        setclientstatus(res.data.data.status);
        if (res.data.data.status === 1) setclientStatusText("Unarchive");
        else setclientStatusText("Archive");
        getallworkflow(pageno);
      })
      .catch((err) => {
        handle403error(err);
      });
  };

  const [userrole, setuserrole] = useState();
  const [providerBranches, setProviderBranches] = useState();
  const getconsultant = () => {
    axios
      .get(`${base_url.api2}/getUsersByRoleId`, {
        headers: {
          Authorization: jwttoken,
          roleId: 4,
          direction: "ASC",
          columnName: "firstName",
          status: 1,
        },
      })
      .then((res) => {
        //console.log("User by role", res.data.data);
        setuserrole(res.data.data);
      })
      .catch((error) => {
        toast.error(JSON.stringify(error.response.data.message));
        handle403error(error);
      });
  };

  const [allconsultant, setConsultant] = useState();
  const getConsultant = (officeId) => {
    axios
      .get(`${base_url.api2}/getUsersSelectByOfficeId`, {
        headers: {
          Authorization: jwttoken,
          officeId: Number(officeId),
        },
      })
      .then((res) => {
        setConsultant(res.data.data);
      })
      .catch((error) => {
        setConsultant(null);
        toast.error(JSON.stringify(error.response.data.message));
        handle403error(error);
      });
  };

  const [allcollege, setallcollege] = useState();
  const getallCollege = () => {
    if (
      props.workflowType === "Admission" ||
      props.workflowType === "SkillAssessment"
    ) {
      axios
        .get(`${base_url.api2}/findAllInstitutionAndBranches`, {
          headers: {
            Authorization: jwttoken,
          },
        })
        .then((response) => {
          //console.log("Dispatch College response", response);

          let enqData = response.data.data;
          let arr = [];
          enqData.forEach((ele) => {
            if (ele.status === 1) {
              arr.push(ele);
            }
            //console.log("arr------>", arr);
          });
          let groupedoptions = [];
          for (let i = 0; i < arr.length; i++) {
            let options = [];
            // let branchdata = arr[i].institutionBranchData;
            let institutionid = arr[i].institutionId;
            let name = arr[i].name;
            let cricosId = arr[i].cricos_provider_code;
            let option = {};
            if (cricosId) {
              option = {
                id: institutionid,
                value: name,
                label: cricosId + "-" + name,
                institutionId: institutionid,
              };
            } else {
              option = {
                id: institutionid,
                value: name,
                label: name,
                institutionId: institutionid,
              };
            }

            options.push(option);
            //console.log(option);

            let groupedoption = {
              label: response.data.data[i].name,
              options,
            };
            groupedoptions.push(groupedoption);
            //  //console.log(groupedoption);
          }
          setallcollege(groupedoptions);
        })
        .catch((error) => {
          toast.error(JSON.stringify(error.response.data.message));
          handle403error(error);
        });
    } else if (props.workflowType === "Migration") {
      axios
        .get(`${base_url.api1}/getAllMigrationProvider`, {
          headers: {
            Authorization: jwttoken,
          },
        })
        .then((response) => {
          var client_college = response.data.data;
          setallcollege(client_college);
        })
        .catch((error) => {
          toast.error(JSON.stringify(error.response.data.message));
          handle403error(error);
        });
    } else {
      axios
        .get(`${base_url.api1}/getAllHealthCoverType`, {
          headers: {
            Authorization: jwttoken,
          },
        })
        .then((response) => {
          var client_college = response.data.data;
          setallcollege(client_college);
        })
        .catch((error) => {
          toast.error(JSON.stringify(error.response.data.message));
          handle403error(error);
        });
    }
  };

  const getProviderBranch = (id) => {
    axios
      .get(`${base_url.api2}/getBranchesByInstitutionId`, {
        headers: {
          Authorization: jwttoken,
          institutionId: id,
        },
      })
      .then((res) => {
        console.log("Branches---->", res);
        setProviderBranches(res.data.data);
      })
      .catch((err) => {
        handle403error(err);
      });
  };
  const [collegeindex, setcollegeindex] = useState();
  const [parentindex, setparentindex] = useState();
  const getadmissioncollege = (p) => {
    axios
      .get(`${base_url.api2}/findAllInstitutionAndBranches`, {
        headers: {
          Authorization: jwttoken,
        },
      })
      .then(
        (response) => {
          //console.log("Dispatch College response", response);
          let groupedoptions = [];
          for (let i = 0; i < response.data.data.length; i++) {
            let options = [];
            let branchdata = response.data.data[i].institutionBranchData;
            let chk = false;
            let index;
            for (let j = 0; j < branchdata.length; j++) {
              if (p.institutionName === branchdata[j].branchName) {
                //console.log("Entering");
                setcollegeindex(j);
                index = j;
                chk = true;
              }
              let option = {
                id: `${branchdata[j].branchId}(${branchdata[j].commissionPercentage})`,
                value: branchdata[j].branchName,
                label: branchdata[j].branchName,
              };
              options.push(option);
            }

            let groupedoption = {
              label: response.data.data[i].name,
              options,
            };
            groupedoptions.push(groupedoption);
            if (chk === true) {
              var a = groupedoptions.indexOf(groupedoption);
              //console.log(groupedoptions[a].options[index]);
              setparentindex(a);
            }
          }
          setallcollege(groupedoptions);
          axios
            .get(`${base_url.api2}/findCourseByInstitutionBranchId`, {
              headers: {
                institutionBranchId: Number(p.institutionId),
              },
            })
            .then((response) => {
              setFetchcourses(response.data.data);
            })
            .catch((error) => {
              setFetchcourses(null);
              toast.error("No Course Available for the Provider");
              handle403error(error);
            });
          openeditenqyuiry(p);
          setbackdropOpen(false);
        },
        (error) => {
          openeditenqyuiry(p);
          setbackdropOpen(false);
          toast.error(JSON.stringify(error.response.data.message));
          //console.log(error);
        }
      );
  };
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalIsOpen2, setIsOpen2] = React.useState(false);
  const [enqyuiryeditdetails, setenqyuiryeditdetails] = React.useState(false);
  // const [admissionStatus, setAdmissionStatus] = React.useState();
  // const [migrationStatus, setMigrationStatus] = React.useState();
  // const [skillStatus, setSkillStatus] = React.useState();
  // const [insuranceStatus, setInsuraceStatus] = React.useState();

  const closeModal = () => {
    setIsOpen(false);
    // setSelectedOfficeName(null);
    setIsOpen2(false);
  };

  const opencreateenquiry = () => {
    setIsOpen(true);
  };
  const openeditenqyuiry = (p) => {
    setIsOpen2(true);
    setenqyuiryeditdetails(p);
  };
  const [workflowName, setworkflowName] = useState();
  const handleChangeWorkflowType = () => {
    axios
      .get(`${base_url.api2}/getWorkflowByWorkflowType`, {
        headers: {
          Authorization: jwttoken,
          workflowType: props.workflowType,
        },
      })
      .then((response) => {
        //console.log(response);
        setworkflowName(response.data.data);
      })
      .catch((error) => {
        handle403error(error);
      });
  };

  const [workflowid, setworkflowId] = useState();
  const [headinstitutionid, setheadinstitutionid] = useState();
  const handleChangeworkflowName = (event) => {
    //console.log("coming here");
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("id");
    //console.log(optionElementId);
    setworkflowId(optionElementId);
  };
  const [selectedcollege, setSelectedCollege] = useState(null);
  const [commissionpercentage, setcommissionpercentage] = useState();
  const [selectedcollegename, setSelectedCollegename] = useState(null);
  const [fetchcourses, setFetchcourses] = useState({});
  const onSelect = (selectedList, selectedItem) => {
    const provider = selectedList.id;
    setSelectedCollege(selectedList.id);
    // setcommissionpercentage(provider[1].slice(0, -1));
    setSelectedCollegename(selectedList.value);
    setheadinstitutionid(selectedList.institutionId);
    if (selectedList.value) {
      console.log("Hello");
      if (selectedList.value) {
        setIsBranchSelected(false);
        getProviderBranch(selectedList.institutionId);
      } else {
        setIsBranchSelected(true);
      }
    }
    axios
      .get(`${base_url.api2}/findCourseByInstitutionBranchId`, {
        headers: {
          institutionBranchId: Number(provider),
        },
      })
      .then((response) => {
        setFetchcourses(response.data.data);
      })
      .catch((error) => {
        setFetchcourses(null);
        toast.error("No Course Available for the Provider");
        handle403error(error);
      });
  };
  const handleChange = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const selectedcollegeId = optionElement.getAttribute("id");
    setSelectedCollege(selectedcollegeId);
  };
  const getdatabyworkflowType = () => {
    if (props.workflowType === "Migration") {
      axios
        .get(`${base_url.api1}/getAllVisaType`, {
          headers: {
            Authorization: jwttoken,
          },
        })
        .then((res) => {
          //console.log("Migration response", res);
          setFetchcourses(res.data.data);
        })
        .catch((error) => {
          handle403error(error);
          toast.error(JSON.stringify(error.response.data.message));
        });
    } else if (props.workflowType === "Insurance") {
      axios
        .get(`${base_url.api1}/findAllInsurance`, {
          headers: {
            Authorization: jwttoken,
          },
        })
        .then((res) => {
          //console.log("Insurance response", res);
          setFetchcourses(res.data.data);
        })
        .catch((error) => {
          handle403error(error);
          toast.error(JSON.stringify(error.response.data.message));
        });
    }
  };
  const [selectedcourse, setSelectedcourse] = useState(null);
  const [selectedcourseunit, setSelectedcourseunit] = useState(null);

  const [selectedProviderBranchId, setSelectedProviderBranchId] =
    useState(null);
  const handleChange2 = (event) => {
    //console.log("coming in handleChange2");
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const selectedcourseId = optionElement.getAttribute("id");
    const selectedcourseunit = optionElement.getAttribute("numberofunit");
    setSelectedcourse(selectedcourseId);

    //console.log(selectedcourseId);
    setSelectedcourseunit(selectedcourseunit);
  };

  const handleChangeProviderBranch = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const branchId = optionElement.getAttribute("id");
    console.log("622---->", branchId);
    setSelectedProviderBranchId(branchId);
  };

  const [selecteduserrole, setselecteduserrole] = useState(null);
  const handleChange4 = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const selectedConsultantId = optionElement.getAttribute("id");
    //console.log(selectedConsultantId);
    setselecteduserrole(selectedConsultantId);
  };

  const [selectedOffice, setSelectedOfficeId] = useState(null);
  const [selectedOfficeN, setSelectedOfficeName] = useState(null);
  const [branchselected, setBranchSelected] = useState(true);
  const [selectedOfficecode, setSelectedOfficecode] = useState(null);

  useEffect(() => {
    // Fetch the default branch data from localStorage

    const defaultBranchDataString = localStorage.getItem("default_branch_data");
    const default_branch_id = localStorage.getItem("default_branch_id");

    const defaultBranchData = JSON.parse(defaultBranchDataString);

    // If default branch data is available, set the officeid to its value
    if (defaultBranchData && defaultBranchData?.firstOfficeId) {
      setSelectedOfficeId(defaultBranchData?.firstOfficeId);
      setSelectedOfficeName(defaultBranchData?.firstOfficeName);
      setSelectedOfficecode(defaultBranchData?.firstOfficeCode);
      getConsultant(defaultBranchData?.firstOfficeId);
    }
    console.log("78---->", defaultBranchData);
  }, [userDefaultOfficeId?.firstOfficeId]);

  const handleOffice = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const selectedOfficeId = optionElement.getAttribute("id");
    const selectedOfficeName = optionElement.getAttribute("value");
    const optionElementofficecode = optionElement.getAttribute("officeCode");

    if (selectedOfficeName) {
      setBranchSelected(false);
      getConsultant(selectedOfficeId);
    } else {
      setBranchSelected(true);
    }
    //console.log(selectedOfficeId);
    //console.log(selectedOfficeName);
    //console.log(optionElementofficecode);
    setSelectedOfficeId(selectedOfficeId);
    setSelectedOfficeName(selectedOfficeName);
    setSelectedOfficecode(optionElementofficecode);
  };

  const [selectedAssigne, setSelectedAssigne] = useState(null);
  const [selectedAssigneN, setSelectedAssigneN] = useState(null);
  const [selectedAssigneconsultantN, setSelectedAssigneConsultantN] =
    useState(null);
  const handleAssigne = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const selectedAssigneId = optionElement.getAttribute("id");
    const selectedAssignename = optionElement.getAttribute("consultantname");
    const selectedAssigneconsultantname = optionElement.getAttribute("value");
    setSelectedAssigne(selectedAssigneId);
    setSelectedAssigneN(selectedAssignename);
    setSelectedAssigneConsultantN(selectedAssigneconsultantname);
  };

  const submitcreateenquiry = (data2) => {
    //console.log(data2);
    console.log("637--->", data2);
    setbackdropOpen(true);
    const isoDateString = new Date().toISOString();
    const datenow = isoDateString.split(".")[0];
    const selected_client_id = localStorage.getItem("selected_client_id");
    let enquiryId = 0;
    let consultantId = Number(selectedAssigne);
    let consultantName = selectedAssigneN;
    let consultantBranchId = Number(selectedOffice);
    let consultantBranchName = selectedOfficeN;
    let providerBranch = data2.branch;
    let providerBranchId = selectedProviderBranchId;
    // let clientId = Number(selected_client_id);
    let clientId = params.client_id;
    let clientUsername = params.client_name;
    let clientPhoneNumber = null;
    let officeId;
    let officeName;
    let officeCode;
    if (clientdetails != null) {
      clientPhoneNumber = Number(clientdetails.clientDetail.phone);
      officeId = clientdetails.officeId;
      officeName = clientdetails.officeName;
      officeCode = clientdetails.officeCode;
    }
    // let commissionPercentage = Number(commissionpercentage);
    let clientName;
    if (clientdetails.middleName) {
      clientName =
        clientdetails.firstName +
        " " +
        clientdetails.middleName +
        " " +
        clientdetails.lastName;
    } else {
      clientName = clientdetails.firstName + " " + clientdetails.lastName;
    }
    let institutionId = Number(selectedcollege);
    let sourceType = "walkin";
    let enquirySource = "walkin";
    // let consultantName = data2.consultantName;
    let deadLineDate = moment(deadlinedate).format("YYYY-MM-DDThh:mm:ss");
    let enquiryLeadType = "HotLead";
    let enquiryStatus = "Commenced";
    // if (props.workflowType === "Admission" || "Insurance")
    // {
    //   enquiryStatus = data2.enquiryStatus;
    // } else{
    // }
    //console.log(data2.enquiryStatus);
    let followUpDate = moment(followdate).format("YYYY-MM-DD");
    // let followByDate = moment(data2.followByDate).format("YYYY-MM-DDThh:mm:ss");
    // let followByDate = null;
    let remarks = data2.remarks;
    let subAgent = clientdetails.clientDetail.subAgent
      ? `${clientdetails.clientDetail.subAgent} (${clientdetails.clientDetail.subAgentName})`
      : null;
    let subAgentId = clientdetails.clientDetail.subAgentId;

    let superAgent = clientdetails.clientDetail.superAgent
      ? `${clientdetails.clientDetail.superAgent} (${clientdetails.clientDetail.superAgentName})`
      : null;
    let superAgentId = clientdetails.clientDetail.superAgentId;
    let institutionName;
    let courseId = null;
    let visaType = null;
    let insuranceType = null;
    let insuranceTypeId = null;
    let courseName = null;
    let visaId = null;
    let insuranceId = null;
    let headInstitutionId;
    let intake;
    let status;
    let numberOfUnits;
    let asssessmentType = data2.asssessmentType;
    let nominatedOccupation = data2.nominatedOccupation;
    let assementBody = data2.assementBody;
    if (props.workflowType === "Admission") {
      courseId = selectedcourse.toString();
      institutionName = selectedcollegename;
      headInstitutionId = headinstitutionid;
      courseName = data2.courseName;
      numberOfUnits = Number(selectedcourseunit);
    } else if (props.workflowType === "SkillAssessment") {
      assementBody = data2.assementBody;
      asssessmentType = data2.asssessmentType;
    } else if (props.workflowType === "Migration") {
      visaId = Number(selectedcourse);
      institutionName = data2.migrationProvider;
      visaType = data2.visaType;
    } else if (props.workflowType === "Insurance") {
      insuranceId = Number(selectedcourse);
      institutionId = Number(selectedcourse);
      institutionName = data2.insuranceProvider;
      insuranceType = data2.insuranceType;
      insuranceTypeId = Number(selectedcourse);
    }

    if (props.workflowType === "SkillAssessment" || "Migration") {
      intake = null;
    } else {
      intake = data2.intake;
    }
    status = 1;
    let workflowId = Number(workflowid);
    let workflowType = props.workflowType;
    let workflowName = data2.workflowName;
    let created = datenow;
    let createdBy = isw_user_name + " (" + isw_user_email + ")";
    let createdDate = moment(datenow).format("YYYY-MM-DD");
    let updated = datenow;
    let visaExpiryDate = visaexpirydate
      ? moment(visaexpirydate).format("YYYY-MM-DDThh:mm:ss")
      : null;

    if (followUpDate === "Invalid date") {
      followUpDate = null;
    }
    if (deadLineDate === "Invalid date") {
      deadLineDate = null;
    }
    let data = null;
    if (props.workflowType === "Admission") {
      data = {
        assementBody,
        asssessmentType,
        consultantBranchId,
        consultantBranchName,
        clientId,
        clientName,
        clientPhoneNumber,
        clientUsername,
        consultantId,
        consultantName,
        deadLineDate,
        courseId,
        // commissionPercentage,
        courseName,
        created,
        createdBy,
        createdDate,
        enquiryId,
        enquiryLeadType,
        enquirySource,
        enquiryStatus,
        // followByDate,
        followUpDate,
        headInstitutionId,
        numberOfUnits,
        nominatedOccupation,
        remarks,
        subAgent,
        subAgentId,
        superAgentId,
        superAgent,
        institutionId,
        institutionName,
        intake,
        officeCode,
        officeId,
        officeName,
        sourceType,
        status,
        updated,
        branchId: providerBranchId,
        branchName: providerBranch,
        prospect: 1,
      };
    } else if (props.workflowType === "SkillAssessment") {
      data = {
        assementBody,
        asssessmentType,
        consultantBranchId,
        consultantBranchName,
        clientId,
        clientName,
        clientPhoneNumber,
        clientUsername,
        consultantId,
        consultantName,
        deadLineDate,
        courseId,
        // commissionPercentage,
        courseName,
        created,
        createdBy,
        createdDate,
        enquiryId,
        enquiryLeadType,
        enquirySource,
        enquiryStatus,
        // followByDate,
        followUpDate,
        headInstitutionId,
        numberOfUnits,
        nominatedOccupation,
        remarks,
        subAgent,
        subAgentId,
        superAgentId,
        superAgent,
        institutionId,
        institutionName,
        intake,
        officeCode,
        officeId,
        officeName,
        sourceType,
        status,
        updated,
        prospect: 1,
      };
    } else if (props.workflowType === "Migration") {
      data = {
        consultantBranchId,
        consultantBranchName,
        clientId,
        clientName,
        clientPhoneNumber,
        clientUsername,
        consultantId,
        consultantName,
        created,
        createdBy,
        createdDate,
        deadLineDate,
        enquiryId,
        enquiryLeadType,
        enquirySource,
        enquiryStatus,
        // followByDate,
        followUpDate,
        remarks,
        subAgent,
        subAgentId,
        superAgentId,
        superAgent,
        institutionId,
        institutionName,
        intake,
        officeCode,
        officeId,
        officeName,
        visaId,
        visaType,
        visaExpiryDate,
        sourceType,
        status,
        updated,
        prospect: 1,
      };
    } else if (props.workflowType === "Insurance") {
      data = {
        consultantBranchId,
        consultantBranchName,
        clientId,
        clientName,
        clientPhoneNumber,
        clientUsername,
        consultantId,
        consultantName,
        created,
        createdBy,
        createdDate,
        deadLineDate,
        enquiryId,
        enquiryLeadType,
        enquirySource,
        enquiryStatus,
        // followByDate,
        followUpDate,
        remarks,
        subAgent,
        subAgentId,
        superAgentId,
        superAgent,
        institutionId,
        institutionName,
        insuranceId,
        insuranceType,
        insuranceTypeId,
        intake,
        officeCode,
        officeId,
        officeName,
        sourceType,
        status,
        updated,
        prospect: 1,
      };
    }
    //console.log(data);
    closeModal();
    axios
      .post(`${base_url.api2}/createEnquiry`, data, {
        headers: {
          Authorization: jwttoken,
        },
      })
      .then((res) => {
        //console.log(res);
        // toast.success("Success");
        let data3 = {
          clientId: Number(res.data.data.clientId),
          enquiryId: Number(res.data.data.enquiryId),
          enquiryWorkflowId: Number(0),
          workflowId,
          workflowName,
          workflowType,
          status,
        };
        axios
          .post(`${base_url.api2}/createEnquiryWorkflow`, data3, {
            headers: {
              Authorization: jwttoken,
            },
          })
          .then((res) => {
            //console.log(res);
            setbackdropOpen(false);
            toast.success("Success");

            if (res.data.data.enquiryWorkflowSteps.length) {
              let data = {
                enquiryId: res.data.data.enquiryId,
                currentStageId: res.data.data.enquiryWorkflowSteps[0].id,
              };
              axios
                .post(`${base_url.api2}/updateEnquiryByEnquiryId`, data, {
                  headers: {
                    Authorization: jwttoken,
                  },
                })
                .then((response) => {
                  //console.log(response.data.data);
                })
                .catch((error) => {
                  //console.log(error);
                  handle403error(error);
                });
            } else {
              return null;
            }
            let logs = isw_user_name + ` Created ${workflowType} Enquiry .`;
            callsaveauditapi(logs, dispatch, navigate);

            getallworkflow(pageno);
          })
          .catch((err) => {
            //console.log(err);
            // toast.error(JSON.stringify(err?.response?.data?.message));
          });
      })
      .catch((err) => {
        handle403error(err);
      });
    axios
      .post(
        `${base_url.api3}/updateClientByUsername`,
        {
          username: client_out_details.username,
          prospect: 1,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: jwttoken,
            // enquiryLeadType: selectedLeadType,
          },
        }
      )
      .then((res) => {
        //console.log("Update", res);
      })
      .catch((e) => {
        //console.log(e);
      });
  };
  const viewWorkflow = (p) => {
    dispatch(getworkflowType(p.workflowType));
    // dispatch(gettabvalue(1));
    const client_name = params.client_name;
    localStorage.setItem(
      "prev_path2",
      "/view-enquiry-workflowList/" + p.clientId + "/" + client_name
    );
    navigate({
      pathname:
        "/view-enquiry-workflow/" +
        p.clientId +
        "/" +
        p.enquiryId +
        "/" +
        p.clientUsername,
      state: p,
    });

    const refresh = localStorage.getItem("refreshing");
    if (refresh === "refresh") {
      // setbackdropOpen(true);
      props.setrefreshit("refresh");
      // setTimeout(() => {
      //   window.location.reload();
      // }, 300);
    } else {
      localStorage.setItem("refreshing", "refresh");
    }
  };
  const goBack = () => {
    if (params.enquiry_id) {
      //console.log(params.enquiry_id);
      var prev_path = localStorage.getItem("prev_path2");
      navigate(prev_path);
    } else if (params.client_id) {
      var prev_path = localStorage.getItem("prev_path");
      //console.log(params.client_id);
      navigate(prev_path);
    }
  };

  // const submiteditenquiry = (data2) => {
  //   const isoDateString = new Date().toISOString();
  //   const datenow = isoDateString.split(".")[0];
  //   let enquiryId = editenquirydetails.enquiryId;
  //   let consultantId = Number(selecteduserrole);
  //   let clientId = Number(editenquirydetails.clientId);
  //   let clientUsername = editenquirydetails.clientUsername;
  //   let clientPhoneNumber = editenquirydetails.clientPhoneNumber;
  //   let deadLineDate = moment(data2.deadLineDate).format("YYYY-MM-DDThh:mm:ss");
  //   let officeId = editenquirydetails.officeId;
  //   let officeName = editenquirydetails.officeName;
  //   let commissionPercentage = Number(editenquirydetails.commissionPercentage);
  //   let clientName = editenquirydetails.clientName;
  //   let sourceType = "walkin";
  //   let enquirySource = "walkin";
  //   let consultantName = data2.consultantName;
  //   let enquiryLeadType = "HotLead";
  //   let enquiryStatus = editenquirydetails.enquiryStatus;
  //   let followUpDate = moment(data2.followUpDate).format("YYYY-MM-DD");
  //   // let followByDate = moment(data2.followByDate).format("YYYY-MM-DDThh:mm:ss");
  //   let remarks = data2.remarks;
  //   let subAgent = editenquirydetails.subAgent;
  //   let superAgent = editenquirydetails.superAgent;
  //   let institutionId = Number(editenquirydetails.institutionId);
  //   let institutionName = editenquirydetails.institutionName;
  //   let courseId = editenquirydetails.courseId;
  //   let courseName = editenquirydetails.courseName;
  //   let status = editenquirydetails.status;
  //   let intake = data2.intake;
  //   let workflowId = Number(workflowid);
  //   let workflowType = editenquirydetails.workflowType;
  //   let workflowName = data2.workflowName;
  //   // if (followByDate === "Invalid date") {
  //   //   followByDate = null;
  //   // }
  //   if (followUpDate === "Invalid date") {
  //     followUpDate = null;
  //   }
  //   if (deadLineDate === "Invalid date") {
  //     deadLineDate = null;
  //   }
  //   let created = datenow;
  //   let updated = datenow;
  //   let data = {
  //     clientId,
  //     clientName,
  //     clientPhoneNumber,
  //     clientUsername,
  //     consultantId,
  //     consultantName,
  //     courseId,
  //     commissionPercentage,
  //     courseName,
  //     created,
  //     deadLineDate,
  //     enquiryId,
  //     enquiryLeadType,
  //     enquirySource,
  //     enquiryStatus,
  //     // followByDate,
  //     followUpDate,
  //     remarks,
  //     subAgent,
  //     superAgent,
  //     institutionId,
  //     institutionName,
  //     intake,
  //     officeId,
  //     officeName,
  //     sourceType,
  //     status,
  //     updated,
  //   };
  //   axios
  //     .post(`${base_url.api2}/updateEnquiryByEnquiryId`, data, {
  //       headers: {
  //         Authorization: jwttoken,
  //       },
  //     })
  //     .then((res) => {
  //       //console.log(res);
  //       toast.success("Success");
  //       let data3 = {
  //         clientId: Number(res.data.data.clientId),
  //         enquiryId: Number(res.data.data.enquiryId),
  //         enquiryWorkflowId: Number(0),
  //         workflowId,
  //         workflowName,
  //         workflowType,
  //         status,
  //       };
  //       axios
  //         .post(`${base_url.api2}/createEnquiryWorkflow`, data3, {
  //           headers: {
  //             Authorization: jwttoken,
  //           },
  //         })
  //         .then((res) => {
  //           //console.log(res);
  //           toast.success("Success");

  //           let createdByName = isw_user_name + " (" + isw_user_email + ")";
  //           let auditdata = {
  //             createdById: isw_adminId,
  //             createdByName: createdByName,
  //             id: 0,
  //             log: isw_user_name + `Created ${workflowType} Enquiry .`,
  //           };
  //           axios
  //             .post(`${base_url.api4}/saveAudit`, auditdata, {
  //               headers: {
  //                 Authorization: jwttoken,
  //               },
  //             })
  //             .then((response) => {
  //               //console.log(response);
  //             })
  //             .catch((error) => {
  //               //console.log(error);
  //               // toast.error(JSON.stringify(error.response.data.message));
  //             });

  //           getallworkflow(pageno);
  //         })
  //         .catch((err) => {
  //           //console.log(err);
  //           // toast.error(JSON.stringify(err?.response?.data?.message));
  //         });
  //       closeModal();
  //     })
  //     .catch((err) => {
  //       //console.log(err);
  //       // toast.error(JSON.stringify(err?.response?.data?.message));
  //     });
  // };
  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            navigate("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };

  const gotoWorkload = () => {
    if (props.workflowType === "Admission") {
      navigate("/admission-enquiry");
    } else if (props.workflowType === "Migration") {
      navigate("/migration-enquiry");
    } else if (props.workflowType === "Insurance") {
      navigate("/insurance-enquiry");
    } else if (props.workflowType === "SkillAssessment") {
      navigate("/skillAssessment-enquiry");
    }
  };
  return (
    <div>
      <ToastContainer />
      <Backdrop className={classes.backdrop} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Card className="p-3">
        <div>
          <div className="d-flex justify-content-end">
            <div>{null}</div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginBottom: "20px",
                marginTop: "10px",
              }}
            >
              {check1pointpermission(314, permissionData, rolepermission) &&
                clientstatus === 1 ? (
                <button
                  className="outline-button"
                  style={{ textTransform: "capitalize", marginRight: "5px" }}
                  onClick={() => {
                    handleChangeWorkflowType();
                    // getApplicationStatus();
                    getClient();
                    setworkflowId();
                    setSelectedAssigne();
                    getallCollege();
                    getAllintake();
                    getconsultant();
                    getdatabyworkflowType();
                    opencreateenquiry();
                  }}
                >
                  Add
                </button>
              ) : null}
              <button
                className="outline-button"
                style={{ marginRight: "5px", textTransform: "capitalize" }}
                onClick={gotoWorkload}
              >
                Workload
              </button>
              <button
                className="sent-button"
                style={{ marginRight: "5px", textTransform: "capitalize" }}
                onClick={goBack}
              >
                Client List
              </button>
            </div>
          </div>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={addcustomStyles}
            contentLabel="Example Modal"
          >
            <form onSubmit={handleSubmit(submitcreateenquiry)}>
              <div className="form-inner-admin">
                <h2 style={{ fontWeight: "bolder", marginLeft: "10px" }}>
                  {props.workflowType === "SkillAssessment"
                    ? "Skill Assessment"
                    : props.workflowType}
                </h2>
                <div className="form-group">
                  <Row>
                    {/* <Col md={4}>
                        <label>Selected Client:</label>
                        <input
                          type="text"
                          className="form-control underline-input"
                          value={params.client_name}
                          readOnly
                        />
                      </Col> */}
                    {/* <Col md={4}>
                        <label>Workflow Type:</label>
                        <input
                          type="text"
                          className="form-control underline-input"
                          value={props.workflowType}
                          readOnly
                        />
                      </Col> */}
                    <Col md={4}>
                      <label>
                        Workflow<span style={{ color: "red" }}>*</span>:
                      </label>
                      <select
                        className={classes.selectdrop}
                        onInput={handleChangeworkflowName}
                        name="workflowName"
                        style={{ backgroundColor: "#f5f5f9" }}
                        ref={register({ required: true })}
                      >
                        <option value="">- Select Workflow -</option>
                        {workflowName?.map((cuntitem) => (
                          <option
                            key={cuntitem.workflowId}
                            id={cuntitem.workflowId}
                            value={cuntitem.workflowName}
                          >
                            {cuntitem.workflowName}
                          </option>
                        ))}
                      </select>
                      {errors.workflowName && (
                        <p className="errormessage">
                          Workflow Name is Required
                        </p>
                      )}
                    </Col>
                    <Col md={4}>
                      <label className="area_rowslable_my">
                        Branch<span style={{ color: "red" }}>*</span>:
                      </label>
                      <select
                        className={classes.selectdrop}
                        onInput={handleOffice}
                        // required
                        defaultValue={selectedOfficeN}
                        name="officeName"
                        style={{ backgroundColor: "#f5f5f9" }}
                        ref={register({ required: true })}
                      >
                        <option value=""> Branch </option>
                        {/* {allOffice?.map((cuntitem) => (
                            <option
                              key={cuntitem.officeId}
                              id={cuntitem.officeId}
                              value={cuntitem.officeName}
                            >
                              {cuntitem.officeName}
                            </option>
                          ))} */}
                        {user?.isw_available_Office?.map((p, index) => (
                          <option
                            key={p.officeId}
                            id={p.officeId}
                            value={p.officeName}
                            officeCode={p.officeCode}
                          >
                            {p.officeName}
                            {" - "}
                            {p.officeCode}
                          </option>
                        ))}
                      </select>
                      {errors.officeName && (
                        <p className="errormessage">Branch is Required</p>
                      )}
                    </Col>
                    <Col md={4}>
                      <label className="area_rowslable_my">
                        Consultant<span style={{ color: "red" }}>*</span>:
                      </label>
                      <select
                        className={classes.selectdrop}
                        onInput={handleAssigne}
                        // required
                        //disabled={branchselected}
                        name="assigneeName"
                        style={{ backgroundColor: "#f5f5f9" }}
                        ref={register({ required: true })}
                      >
                        <option value="">- Select Consultant -</option>
                        {allconsultant != null ? (
                          allconsultant.map((cuntitem) => (
                            <option
                              key={cuntitem.id}
                              id={cuntitem.id}
                              value={cuntitem.email}
                              consultantname={`${cuntitem.firstName + " " + cuntitem.lastName
                                } ( ${cuntitem.email})`}
                            >
                              {cuntitem.firstName} {cuntitem.lastName} (
                              {cuntitem.email})
                            </option>
                          ))
                        ) : (
                          <option value="">No Consultant Available</option>
                        )}
                      </select>
                      {errors.assigneeName && (
                        <p className="errormessage">Consultant is Required</p>
                      )}
                    </Col>
                    {/* </Row>
                  </div>
                  <div className="form-group">
                    <Row> */}
                    {/* <Col md={4}>
                        <label>
                          Enquiry Lead Type
                          <span style={{ color: "red" }}>*</span>:
                        </label>
                        <select
                          className={classes.selectdrop}
                          name="enquiryLeadType"
                          ref={register('enquiryLeadType',{ required: true })}
                        >
                          <option value="HotLead">Hot Lead</option>
                          <option value="Converted">Converted Lead</option>
                          <option value="ColdLead">Cold Lead</option>
                          <option value="WarmLead">Warm Lead</option>
                        </select>
                        {errors.enquiryLeadType && (
                          <p className="errormessage">
                            Enquiry Lead Type is Required
                          </p>
                        )}
                      </Col> */}
                    {/* <Col md={4}>
                        <label>
                          Enquiry Source<span style={{ color: "red" }}>*</span>:
                        </label>
                        <select
                          className={classes.selectdrop}
                          name="enquirySource"
                          ref={register('enquirySource',{ required: true })}
                        >
                          <option value="">- Select Enquiry Type -</option>
                          <option value="online">Online</option>
                          <option value="walkin">Walk In</option>
                        </select>
                        {errors.enquirySource && (
                          <p className="errormessage">
                            Enquiry Source is Required
                          </p>
                        )}
                      </Col> */}
                    {/* 
                    <Col md={4}>
                      <label>
                        Consultant<span style={{ color: "red" }}>*</span>:
                      </label>
                      <select
                        className={classes.selectdrop}
                        style={{
                          backgroundColor: "#f5f5f9",
                        }}
                        onInput={handleChange4}
                        name="consultantName"
                        ref={register({ required: true })}
                      >
                        <option value="">- Select Consultant -</option>
                        {userrole?.map((cuntitem) => (
                          <option
                            key={cuntitem.id}
                            id={cuntitem.id}
                            value={`${cuntitem.first_name + " " + cuntitem.last_name
                              } ( ${cuntitem.email})`}
                          >
                            {cuntitem.first_name + " " + cuntitem.last_name}
                          </option>
                        ))}
                      </select>
                      {errors.consultantName && (
                        <p className="errormessage">
                          Consultant Name is Required
                        </p>
                      )}
                    </Col> */}
                  </Row>
                </div>
                <div className="form-group">
                  <Row>
                    <Col md={4}>
                      {props.workflowType === "Admission" ? (
                        <>
                          <label>
                            Provider<span style={{ color: "red" }}>*</span>:
                          </label>
                          <Select
                            options={allcollege}
                            styles={customSelectStyles}
                            onChange={onSelect}
                          />
                        </>
                      ) : (
                        <>
                          {props.workflowType === "SkillAssessment" ? (
                            <>
                              <label>Assessment Body:</label>
                              <input
                                className="form-control"
                                name="assementBody"
                                style={{ backgroundColor: "#f5f5f9" }}
                                placeholder="Enter Assessment Body"
                                ref={register}
                              />
                            </>
                          ) : (
                            <>
                              {props.workflowType === "Migration" ? (
                                <>
                                  <label>
                                    Migration Provider
                                    <span style={{ color: "red" }}>*</span>:
                                  </label>

                                  <select
                                    className={classes.selectdrop}
                                    onInput={handleChange}
                                    name="migrationProvider"
                                    style={{ backgroundColor: "#f5f5f9" }}
                                    ref={register({
                                      required: true,
                                    })}
                                  >
                                    <option value="">
                                      - Select Provider -
                                    </option>
                                    {allcollege?.map((cuntitem) => (
                                      <option
                                        key={cuntitem.id}
                                        id={cuntitem.id}
                                        value={cuntitem.name}
                                      >
                                        {cuntitem.name}
                                      </option>
                                    ))}
                                  </select>
                                  {errors.migrationProvider && (
                                    <p className="errormessage">
                                      Migration Provider is Required
                                    </p>
                                  )}
                                </>
                              ) : (
                                <>
                                  <label>
                                    Provider
                                    <span style={{ color: "red" }}>*</span>:
                                  </label>
                                  <select
                                    className={classes.selectdrop}
                                    onInput={handleChange2}
                                    name="insuranceProvider"
                                    style={{ backgroundColor: "#f5f5f9" }}
                                    ref={register({
                                      required: true,
                                    })}
                                  >
                                    <option value="">
                                      - Select Insurance Provider -
                                    </option>
                                    {fetchcourses?.length
                                      ? fetchcourses &&
                                      fetchcourses.map((cuntitem) => (
                                        <option
                                          key={cuntitem.id}
                                          id={cuntitem.id}
                                          value={cuntitem.name}
                                        >
                                          {cuntitem.name}
                                        </option>
                                      ))
                                      : null}
                                  </select>
                                  {errors.insuranceProvider && (
                                    <p className="errormessage">
                                      Insurance Provider is Required
                                    </p>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </Col>
                    {props.workflowType === "Admission" ? (
                      <>
                        <Col md={4}>
                          <label>
                            Provider Branch
                            <span style={{ color: "red" }}>*</span>:
                          </label>
                          <select
                            className={classes.selectdrop}
                            onInput={handleChangeProviderBranch}
                            disabled={isbranchselected}
                            name="branch"
                            ref={register}
                            style={{
                              backgroundColor: "#f5f5f9",
                            }}
                          >
                            <option value="">- Select Provider Branch-</option>
                            {providerBranches?.length
                              ? providerBranches &&
                              providerBranches.map((cuntitem) => (
                                <option
                                  key={cuntitem.branchId}
                                  id={cuntitem.branchId}
                                  value={cuntitem.branchName}
                                  style={{ width: "350px" }}
                                >
                                  {cuntitem.branchName}
                                </option>
                              ))
                              : null}
                          </select>
                        </Col>
                      </>
                    ) : null}
                    {props.workflowType === "Admission" ? (
                      <>
                        <Col md={4}>
                          <label>
                            Course<span style={{ color: "red" }}>*</span>:
                          </label>
                          <select
                            className={classes.selectdrop}
                            onInput={handleChange2}
                            name="courseName"
                            style={{ backgroundColor: "#f5f5f9" }}
                            ref={register({ required: true })}
                          >
                            <option value="">- Select Course -</option>
                            {fetchcourses?.length
                              ? fetchcourses &&
                              fetchcourses.map((cuntitem) => (
                                <option
                                  key={cuntitem.courseId}
                                  id={cuntitem.courseId}
                                  value={cuntitem.name}
                                  numberofunit={cuntitem.numberOfUnit}
                                >
                                  {cuntitem.name}
                                </option>
                              ))
                              : null}
                          </select>
                          {errors.courseName && (
                            <p className="errormessage">
                              CourseName is Required
                            </p>
                          )}
                        </Col>
                      </>
                    ) : (
                      <>
                        {props.workflowType === "SkillAssessment" ? (
                          <Col md={4}>
                            <label>
                              Type of Assessment
                              <span style={{ color: "red" }}>*</span>:
                            </label>
                            <select
                              className={classes.selectdrop}
                              name="asssessmentType"
                              style={{ backgroundColor: "#f5f5f9" }}
                              ref={register({
                                required: true,
                              })}
                            >
                              <option value="">
                                - Select Type of Assessment -
                              </option>
                              <option value="FullSkillAssessment">
                                Full Skills Assessment
                              </option>
                              <option value="ProvisionalAssessment">
                                Provisional Assessment
                              </option>
                              <option value="EmploymentAssessment">
                                Employment Assessment
                              </option>
                              <option value="Review">Review</option>
                            </select>

                            {errors.asssessmentType && (
                              <p className="errormessage">
                                Type of Assessment is Required
                              </p>
                            )}
                          </Col>
                        ) : (
                          <>
                            {props.workflowType === "Migration" ? (
                              <Col md={4}>
                                <label>
                                  VisaType
                                  <span style={{ color: "red" }}>*</span>:
                                </label>
                                <select
                                  className={classes.selectdrop}
                                  onInput={handleChange2}
                                  name="visaType"
                                  style={{ backgroundColor: "#f5f5f9" }}
                                  ref={register({ required: true })}
                                >
                                  <option value="">- Select Visa Type -</option>
                                  {fetchcourses?.length
                                    ? fetchcourses &&
                                    fetchcourses.map((cuntitem) => (
                                      <option
                                        key={cuntitem.id}
                                        id={cuntitem.id}
                                        value={cuntitem.name}
                                      >
                                        {cuntitem.name}
                                      </option>
                                    ))
                                    : null}
                                </select>
                                {errors.visaType && (
                                  <p className="errormessage">
                                    Visa Type is Required
                                  </p>
                                )}
                              </Col>
                            ) : (
                              <Col md={4}>
                                <label>
                                  InsuranceType
                                  <span style={{ color: "red" }}>*</span>:
                                </label>
                                <select
                                  className={classes.selectdrop}
                                  onInput={handleChange2}
                                  name="insuranceType"
                                  style={{ backgroundColor: "#f5f5f9" }}
                                  ref={register({
                                    required: true,
                                  })}
                                >
                                  <option value="">
                                    - Select InsuranceType -
                                  </option>
                                  {allcollege?.length
                                    ? allcollege &&
                                    allcollege.map((cuntitem) => (
                                      <option
                                        key={cuntitem.id}
                                        id={cuntitem.id}
                                        value={cuntitem.name}
                                      >
                                        {cuntitem.name}
                                      </option>
                                    ))
                                    : null}
                                </select>
                                {errors.insuranceType && (
                                  <p className="errormessage">
                                    Insurance Type is Required
                                  </p>
                                )}
                              </Col>
                            )}
                          </>
                        )}
                      </>
                    )}
                    {props.workflowType === "Migration" ? (
                      <Col md={4}>
                        <label>Visa Expiry Date</label>

                        <DatePicker
                          selected={
                            visaexpirydate ? new Date(visaexpirydate) : null
                          }
                          onChange={(date) => setvisaexpirydate(date)}
                          dateFormat="dd-MM-yyyy"
                          showYearDropdown
                          style={{ backgroundColor: "#f5f5f9" }}
                          dropdownMode="select"
                          placeholderText="Select Date"
                        />
                      </Col>
                    ) : null}
                    {props.workflowType === "Insurance" ? (
                      <Col md={4}>
                        <label>DeadLine Date</label>

                        <DatePicker
                          selected={
                            deadlinedate ? new Date(deadlinedate) : null
                          }
                          onChange={(date) => setdeadlinedate(date)}
                          dateFormat="dd-MM-yyyy"
                          showYearDropdown
                          dropdownMode="select"
                          placeholderText="Select Date"
                        />
                      </Col>
                    ) : null}
                    {props.workflowType === "SkillAssessment" ? (
                      <>
                        <Col md={4}>
                          <label>Nominated Occupation:</label>
                          <input
                            className="form-control"
                            name="nominatedOccupation"
                            style={{ backgroundColor: "#f5f5f9" }}
                            placeholder="Enter Nominated Occupation"
                            ref={register}
                          />
                        </Col>
                        {/* <Col md={4}>
                          <label>Assessment Body:</label>
                          <input
                            className="form-control"
                            name="assementBody"
                            style={{ backgroundColor: "#f5f5f9" }}
                            placeholder="Enter Assessment Body"
                            ref={register('assementBody')}
                          />
                        </Col> */}
                      </>
                    ) : null}
                  </Row>
                </div>
                <div className="form-group">
                  <div className="d-flex justify-content-start">
                    {props.workflowType !== "Insurance" ? (
                      <Col md={4}>
                        <label>DeadLine Date</label>

                        <DatePicker
                          selected={
                            deadlinedate ? new Date(deadlinedate) : null
                          }
                          onChange={(date) => setdeadlinedate(date)}
                          dateFormat="dd-MM-yyyy"
                          showYearDropdown
                          dropdownMode="select"
                          placeholderText="Select Date"
                        />
                      </Col>
                    ) : null}
                    {props.workflowType === "SkillAssessment" ||
                      "Migration" ? null : (
                      <Col md={4}>
                        <label>Intake:</label>
                        <select
                          className={classes.selectdrop}
                          name="intake"
                          style={{ backgroundColor: "#f5f5f9" }}
                          ref={register}
                        >
                          <option value="">- Select Intake -</option>
                          {allintake?.map((cuntitem, index) => (
                            <option
                              key={cuntitem.id}
                              id={cuntitem.id}
                              value={cuntitem.name}
                            >
                              {cuntitem.createdIntake}
                            </option>
                          ))}
                        </select>
                      </Col>
                    )}
                    <Col md={4}>
                      <label>FollowUp Date</label>
                      <DatePicker
                        selected={followdate ? new Date(followdate) : null}
                        onChange={(date) => setfollowdate(date)}
                        dateFormat="dd-MM-yyyy"
                        showYearDropdown
                        dropdownMode="select"
                        placeholderText="Select Date"
                        minDate={new Date()}
                      />
                    </Col>
                  </div>
                  <br />
                </div>
                {props.workflowType === "SkillAssessment" ? (
                  <div className="form-group">
                    <Row>
                      {/* <Col md={4}>
                        <label>
                          Type of Assessment
                          <span style={{ color: "red" }}>*</span>:
                        </label>
                        <select
                          className={classes.selectdrop}
                          name="asssessmentType"
                          style={{ backgroundColor: "#f5f5f9" }}
                          ref={register('asssessmentType',{ required: true })}
                        >
                          <option value="">
                            - Select Type of Assessment -
                          </option>
                          <option value="FullSkillAssessment">
                            Full Skills Assessment
                          </option>
                          <option value="ProvisionalAssessment">
                            Provisional Assessment
                          </option>
                          <option value="EmploymentAssessment">
                            Employment Assessment
                          </option>
                          <option value="Review">Review</option>
                        </select>
                        {errors.asssessmentType && (
                          <p className="errormessage">
                            Type of Assessment is Required
                          </p>
                        )}
                      </Col> */}
                      <Col md={12}>
                        <label>Remarks</label>
                        <textarea
                          className="form-control underline-input"
                          name="remarks"
                          style={{
                            backgroundColor: "#f5f5f9",
                            height: "100px",
                          }}
                          ref={register}
                          rows={2}
                        />
                      </Col>
                    </Row>
                  </div>
                ) : null}
                {props.workflowType === "SkillAssessment" ? null : (
                  <div className="form-group">
                    <Row>
                      <Col md={12}>
                        <label>Remarks</label>
                        <textarea
                          className="form-control underline-input"
                          name="remarks"
                          style={{
                            backgroundColor: "#f5f5f9",
                            height: "100px",
                          }}
                          ref={register}
                          rows={2}
                        />
                      </Col>
                    </Row>
                  </div>
                )}
              </div>
              <div className="d-flex justify-content-end mt-2">
                <button
                  className="outline-button"
                  // variant="outlined"
                  onClick={closeModal}
                  style={{ marginRight: "10px" }}
                >
                  Cancel
                </button>
                <button type="submit" className="sent-button">
                  Create
                </button>
              </div>
            </form>
          </Modal>
          <Table className="workload table-borderless" responsive striped>
            <thead>
              <tr>
                <th style={{ width: "50px", textAlign: "left" }}>SN</th>

                {props.workflowType === "SkillAssessment" ? (
                  <th style={{ minWidth: "150px" }}>
                    TYPE OF ASSESSMENT{" "}
                    <img
                      src={sort}
                      className="sorting"
                      id="asssessmentType"
                      onClick={sortClient}
                      style={{ cursor: "pointer" }}
                    />
                  </th>
                ) : null}

                {/* {props.workflowType === "Admission" ||
                props.workflowType === "SkillAssessment" ? (
                  <th style={{ minWidth: "109px" }}>
                    Course{" "}
                    <UnfoldMoreIcon
                      id="courseName"
                      onClick={sortClient}
                      style={{ cursor: "pointer" }}
                    />
                  </th>
                ) : (
                  <>
                    {props.workflowType === "Migration" ? (
                      <th style={{ minWidth: "135px" }}>
                        Visa Type{" "}
                        <UnfoldMoreIcon
                          id="visaType"
                          onClick={sortClient}
                          style={{ cursor: "pointer" }}
                        />
                      </th>
                    ) : (
                      <th style={{ minWidth: "109px" }}>
                        Type{" "}
                        <UnfoldMoreIcon
                          id="insuranceType"
                          onClick={sortClient}
                          style={{ cursor: "pointer" }}
                        />
                      </th>
                    )}
                  </>
                )} */}

                {props.workflowType === "Admission" ? (
                  <th style={{ minWidth: "172px" }}>
                    PROVIDER{" "}
                    <img
                      src={sort}
                      className="sorting"
                      id="institutionName"
                      onClick={sortClient}
                      style={{ cursor: "pointer" }}
                    />
                  </th>
                ) : null}

                {props.workflowType === "Migration" ? (
                  <th style={{ minWidth: "172px" }}>
                    VISA OFFICE{" "}
                    <img
                      src={sort}
                      className="sorting"
                      id="institutionName"
                      onClick={sortClient}
                      style={{ cursor: "pointer" }}
                    />
                  </th>
                ) : null}
                {props.workflowType === "Insurance" ? (
                  <th style={{ minWidth: "172px" }}>
                    PROVIDER{" "}
                    <img
                      src={sort}
                      className="sorting"
                      id="institutionName"
                      onClick={sortClient}
                      style={{ cursor: "pointer" }}
                    />
                  </th>
                ) : null}

                {props.workflowType === "Admission" ||
                  props.workflowType === "SkillAssessment" ? (
                  <th style={{ minWidth: "130px" }}>
                    COURSE{" "}
                    <img
                      src={sort}
                      className="sorting"
                      id="courseName"
                      onClick={sortClient}
                      style={{ cursor: "pointer" }}
                    />
                  </th>
                ) : (
                  <>
                    {props.workflowType === "Migration" ? (
                      <th style={{ minWidth: "165px" }}>
                        VISA TYPE{" "}
                        <img
                          src={sort}
                          className="sorting"
                          id="visaType"
                          onClick={sortClient}
                          style={{ cursor: "pointer" }}
                        />
                      </th>
                    ) : (
                      <th style={{ minWidth: "130px" }}>
                        TYPE{" "}
                        <img
                          src={sort}
                          className="sorting"
                          id="insuranceType"
                          onClick={sortClient}
                          style={{ cursor: "pointer" }}
                        />
                      </th>
                    )}
                  </>
                )}

                {props.workflowType === "SkillAssessment" ? (
                  <th style={{ minWidth: "172px" }}>
                    NOMINATED OCCUPATION{" "}
                    <img
                      src={sort}
                      className="sorting"
                      id="nominatedOccupation"
                      onClick={sortClient}
                      style={{ cursor: "pointer" }}
                    />
                  </th>
                ) : null}

                {/* {props.workflowType === "Admission" ||
                props.workflowType === "SkillAssessment" ? (
                  <th style={{ minWidth: "172px" }}>
                    Provider Name{" "}
                    <UnfoldMoreIcon
                      id="institutionName"
                      onClick={sortClient}
                      style={{ cursor: "pointer" }}
                    />
                  </th>
                ) : (
                  <>
                    {props.workflowType === "Migration" ? (
                      <th style={{ minWidth: "172px" }}>
                        Visa Office{" "}
                        <UnfoldMoreIcon
                          id="institutionName"
                          onClick={sortClient}
                          style={{ cursor: "pointer" }}
                        />
                      </th>
                    ) : (
                      <th style={{ minWidth: "172px" }}>
                        Provider Name{" "}
                        <UnfoldMoreIcon
                          id="institutionName"
                          onClick={sortClient}
                          style={{ cursor: "pointer" }}
                        />
                      </th>
                    )}
                  </>
                )} */}

                {props.workflowType === "SkillAssessment" ? (
                  <th style={{ minWidth: "105px" }}>
                    ASSESMENT BODY{" "}
                    <img
                      src={sort}
                      className="sorting"
                      id="assementBody"
                      onClick={sortClient}
                      style={{ cursor: "pointer" }}
                    />
                  </th>
                ) : (
                  <th style={{ minWidth: "105px" }}>
                    INTAKE{" "}
                    <img
                      src={sort}
                      className="sorting"
                      id="intake"
                      onClick={sortClient}
                      style={{ cursor: "pointer" }}
                    />
                  </th>
                )}
                <th style={{ minWidth: "103px" }}>
                  STATUS{" "}
                  <img
                    src={sort}
                    className="sorting"
                    id="enquiryStatus"
                    onClick={sortClient}
                    style={{ cursor: "pointer" }}
                  />
                </th>
                <th style={{ minWidth: "155px" }}>
                  CURRENTSTAGE{" "}
                  <img
                    src={sort}
                    className="sorting"
                    id="currentStage"
                    onClick={sortClient}
                    style={{ cursor: "pointer" }}
                  />
                </th>
                <th style={{ minWidth: "125px" }}>
                  STARTED{" "}
                  <img
                    src={sort}
                    className="sorting"
                    id="created"
                    onClick={sortClient}
                    style={{ cursor: "pointer" }}
                  />
                </th>
                <th style={{ minWidth: "155px" }}>
                  LAST UPDATED{" "}
                  <img
                    src={sort}
                    className="sorting"
                    id="updated"
                    onClick={sortClient}
                    style={{ cursor: "pointer" }}
                  />
                </th>
                {/* <th style={{ minWidth: "150px" }}>View Workflow</th> */}
              </tr>
            </thead>
            <tbody>
              {workflowdata?.map((p, index) => (
                <tr key={index}>
                  <td style={{ width: "50px", textAlign: "left" }}>
                    {index + 1}
                  </td>

                  {/* <th>{p.workflowName}</th> */}
                  {props.workflowType === "SkillAssessment" ? (
                    <td>
                      <>
                        {check1pointpermission(
                          315,
                          permissionData,
                          rolepermission
                        ) && clientstatus === 1 ? (
                          <Link
                            style={{ textDecoration: "none" }}
                            onClick={() => viewWorkflow(p)}
                            to={
                              `/view-enquiry-workflow/` +
                              p.clientId +
                              `/` +
                              p.enquiryId +
                              `/` +
                              p.clientUsername
                            }
                          >
                            {p.asssessmentType}
                          </Link>
                        ) : (
                          <>{p.asssessmentType}</>
                        )}
                      </>
                    </td>
                  ) : null}

                  {props.workflowType === "SkillAssessment" ? (
                    <td>{p.nominatedOccupation}</td>
                  ) : (
                    <td>
                      <Link
                        style={{ textDecoration: "none" }}
                        onClick={() => viewWorkflow(p)}
                        to={
                          `/view-enquiry-workflow/` +
                          p.clientId +
                          `/` +
                          p.enquiryId +
                          `/` +
                          p.clientUsername
                        }
                      >
                        {p.institutionName}
                      </Link>
                    </td>
                  )}

                  {props.workflowType === "Admission" ||
                    props.workflowType === "SkillAssessment" ? (
                    <td>
                      {p.workflowId ? (
                        <>
                          {check1pointpermission(
                            315,
                            permissionData,
                            rolepermission
                          ) && clientstatus === 1 ? (
                            <Link
                              style={{ textDecoration: "none" }}
                              onClick={() => viewWorkflow(p)}
                              to={
                                `/view-enquiry-workflow/` +
                                p.clientId +
                                `/` +
                                p.enquiryId +
                                `/` +
                                p.clientUsername
                              }
                            >
                              {p.courseName}
                            </Link>
                          ) : (
                            <>{p.courseName}</>
                          )}
                        </>
                      ) : (
                        <>
                          {check1pointpermission(
                            315,
                            permissionData,
                            rolepermission
                          ) && clientstatus === 1 ? (
                            <Link
                              style={{ textDecoration: "none" }}
                              onClick={() => {
                                setworkflowId();
                                setSelectedAssigne();
                                seteditenquirydetails(p);
                                handleChangeWorkflowType();
                                openeditenqyuiry(p);
                                // getadmissioncollege(p);
                                getAllintake();
                                getconsultant();
                              }}
                              to={
                                `/view-enquiry-workflow/` +
                                p.clientId +
                                `/` +
                                p.enquiryId +
                                `/` +
                                p.clientUsername
                              }
                            >
                              {p.courseName}
                            </Link>
                          ) : (
                            <>{p.courseName}</>
                          )}
                        </>
                      )}
                    </td>
                  ) : (
                    <>
                      {props.workflowType === "Migration" ? (
                        <td>
                          {p.workflowId ? (
                            <>
                              {check1pointpermission(
                                315,
                                permissionData,
                                rolepermission
                              ) && clientstatus === 1 ? (
                                <Link
                                  style={{ textDecoration: "none" }}
                                  onClick={() => viewWorkflow(p)}
                                  to={
                                    `/view-enquiry-workflow/` +
                                    p.clientId +
                                    `/` +
                                    p.enquiryId +
                                    `/` +
                                    p.clientUsername
                                  }
                                >
                                  {p.visaType}
                                </Link>
                              ) : (
                                <>{p.visaType}</>
                              )}
                            </>
                          ) : (
                            <>
                              {check1pointpermission(
                                315,
                                permissionData,
                                rolepermission
                              ) && clientstatus === 1 ? (
                                <Link
                                  style={{ textDecoration: "none" }}
                                  onClick={() => {
                                    setworkflowId();
                                    setSelectedAssigne();
                                    seteditenquirydetails(p);
                                    handleChangeWorkflowType();
                                    openeditenqyuiry(p);
                                    // getadmissioncollege(p);
                                    getAllintake();
                                    getconsultant();
                                  }}
                                  to={
                                    `/view-enquiry-workflow/` +
                                    p.clientId +
                                    `/` +
                                    p.enquiryId +
                                    `/` +
                                    p.clientUsername
                                  }
                                >
                                  {p.visaType}
                                </Link>
                              ) : (
                                <>{p.visaType}</>
                              )}
                            </>
                          )}
                        </td>
                      ) : (
                        <td>
                          {p.workflowId ? (
                            <>
                              {check1pointpermission(
                                315,
                                permissionData,
                                rolepermission
                              ) && clientstatus === 1 ? (
                                <Link
                                  style={{ textDecoration: "none" }}
                                  onClick={() => viewWorkflow(p)}
                                  to={
                                    `/view-enquiry-workflow/` +
                                    p.clientId +
                                    `/` +
                                    p.enquiryId +
                                    `/` +
                                    p.clientUsername
                                  }
                                >
                                  {p.insuranceType}
                                </Link>
                              ) : (
                                <>{p.insuranceType}</>
                              )}
                            </>
                          ) : (
                            <>
                              {check1pointpermission(
                                315,
                                permissionData,
                                rolepermission
                              ) && clientstatus === 1 ? (
                                <Link
                                  style={{ textDecoration: "none" }}
                                  onClick={() => {
                                    setworkflowId();
                                    setSelectedAssigne();
                                    seteditenquirydetails(p);
                                    handleChangeWorkflowType();
                                    openeditenqyuiry(p);
                                    // getadmissioncollege(p);
                                    getAllintake();
                                    getconsultant();
                                  }}
                                >
                                  {p.insuranceType}
                                </Link>
                              ) : (
                                <>{p.insuranceType}</>
                              )}
                            </>
                          )}
                        </td>
                      )}
                    </>
                  )}

                  {props.workflowType === "SkillAssessment" ? (
                    <td>{p.assementBody}</td>
                  ) : (
                    <td>{p.intake}</td>
                  )}
                  {p.enquiryStatus === "Discontinued" ||
                    p.enquiryStatus === "DiscontinueComplete" ||
                    p.enquiryStatus === "Closed" ? (
                    <td ><p className="redbackground">{p.enquiryStatus}</p></td>
                  ) : p.enquiryStatus === "Completed" ? (
                    <td ><p className="greenbackground">{p.enquiryStatus}</p></td>
                  ) : p.enquiryStatus === "DiscontinueProcessing" ? (
                    <td ><p className="orangebackground">{p.enquiryStatus}</p></td>
                  ) : (
                    <td style={{ textAlign: "center" }}>{p.enquiryStatus}</td>
                  )}
                  <td>{p.currentStage}</td>
                  <td>
                    {moment(p.created).format("DD-MMM-YY")}
                    {/* <br />
                    {moment(p.created).format("hh.mm a")} */}
                  </td>
                  <td>
                    {moment(p.updated).format("DD-MMM-YY")}
                    {/* <br />
                    {moment(p.updated).format("hh.mm a")} */}
                  </td>
                  {/* <th> */}
                  {/* {p.workflowId ? (
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => viewWorkflow(p)}
                        >
                          View Workflow
                        </Button>
                      ) : (
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => {
                            setworkflowId();
                            setselecteduserrole();
                            seteditenquirydetails(p);
                            handleChangeWorkflowType();
                            openeditenqyuiry(p);
                            // getadmissioncollege(p);
                            getAllintake();
                            getconsultant();
                          }}
                        >
                          Assign Workflow
                        </Button>
                      )} */}
                  {/* <Modal
                      isOpen={modalIsOpen2}
                      onRequestClose={closeModal}
                      style={customStyles}
                      contentLabel="Example Modal"
                    >
                      <form onSubmit={handleSubmit(submiteditenquiry)}>
                        <div className="form-inner-admin">
                          <h2
                            style={{
                              fontWeight: "bolder",
                              marginLeft: "10px",
                            }}
                          >
                            {props.workflowType}
                          </h2>
                          <div className="form-group">
                            <Row>
                              <Col md={4}>
                                <label>
                                  Workflow Name
                                  <span style={{ color: "red" }}>*</span>:
                                </label>
                                <select
                                  className={classes.selectdrop}
                                  onChange={handleChangeworkflowName}
                                  name="workflowName"
                                  ref={register('workflowName',{ required: true })}
                                >
                                  <option value="">- Select Workflow -</option>
                                  {workflowName?.map((cuntitem) => (
                                    <option
                                      key={cuntitem.workflowId}
                                      id={cuntitem.workflowId}
                                      value={cuntitem.workflowName}
                                    >
                                      {cuntitem.workflowName}
                                    </option>
                                  ))}
                                </select>
                                {errors.workflowName && (
                                  <p className="errormessage">
                                    Workflow Name is Required
                                  </p>
                                )}
                              </Col>
                              <Col md={4}>
                                <label>
                                   Status
                                  <span style={{ color: "red" }}>*</span>:
                                </label>
                                <input
                                  type="text"
                                  value={p.enquiryStatus}
                                  className="form-control underline-input"
                                  readOnly
                                />
                              </Col>
                              <Col md={4}>
                                <lable style={{ color: "cornflowerblue" }}>
                                  Consultant
                                  <span style={{ color: "red" }}>*</span>:
                                </lable>
                                <select
                                  className={classes.selectdrop}
                                  style={{ marginTop: "10px" }}
                                  onChange={handleChange4}
                                  name="consultantName"
                                  ref={register('consultantName',{ required: true })}
                                >
                                  <option value="">
                                    - Select Consultant -
                                  </option>
                                  {userrole?.map((cuntitem) => (
                                    <option
                                      key={cuntitem.id}
                                      id={cuntitem.id}
                                      value={`${
                                        cuntitem.firstName +
                                        "" +
                                        cuntitem.lastName
                                      }`}
                                    >
                                      {cuntitem.firstName +
                                        " " +
                                        cuntitem.lastName}
                                    </option>
                                  ))}
                                </select>
                                {errors.consultantName && (
                                  <p className="errormessage">
                                    Consultant Name is Required
                                  </p>
                                )}
                              </Col>
                            </Row>
                          </div>
                          <div className="form-group">
                            <Row>
                              <Col md={4}>
                                <label>
                                  Provider
                                  <span style={{ color: "red" }}>*</span>:
                                </label>
                                <input
                                  type="text"
                                  value={p.institutionName}
                                  className="form-control underline-input"
                                  ref={register('provider')}
                                  name="provider"
                                  readOnly
                                />
                              </Col>
                              <Col md={4}>
                                <label>
                                  Course
                                  <span style={{ color: "red" }}>*</span>:
                                </label>
                                <input
                                  ref={register('course')}
                                  className="form-control underline-input"
                                  name="course"
                                  value={p.courseName}
                                  readOnly
                                  type="text"
                                />
                              </Col>
                              <Col md={4}>
                                <label>FollowUp Date</label>
                                <input
                                  className="form-control underline-input"
                                  type="date"
                                  name="followUpDate"
                                  ref={register('followUpDate')}
                                />
                              </Col>
                            </Row>
                          </div>
                          <div className="form-group">
                            <Row>
                              <Col md={4}>
                                <label>DeadLine Date</label>
                                <input
                                  className="form-control underline-input"
                                  type="date"
                                  name="deadLineDate"
                                  ref={register('deadLineDate')}
                                />
                              </Col>
                              <Col md={4}>
                                <label>Intake :</label>
                                <input
                                  type="text"
                                  value={p.intake}
                                  className="form-control underline-input"
                                  readOnly
                                />
                              </Col>
                            </Row>
                          </div>
                          <div className="form-group">
                            <Row>
                              <Col md={12}>
                                <label>
                                  Remarks
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <textarea
                                  className="form-control underline-input"
                                  name="remarks"
                                  ref={register('remarks',{ required: true })}
                                />
                                {errors.remarks && (
                                  <p className="errormessage">
                                    Remarks is Required
                                  </p>
                                )}
                              </Col>
                            </Row>
                          </div>
                        </div>
                        <Button
                          color="primary"
                          variant="outlined"
                          type="submit"
                          style={{
                            textTransform: "capitalize",
                            float: "right",
                            marginRight: "10px",
                          }}
                        >
                          Update
                        </Button>
                        &nbsp;&nbsp;&nbsp;
                        <Button
                          color="secondary"
                          variant="outlined"
                          onClick={closeModal}
                          style={{
                            textTransform: "capitalize",
                            float: "right",
                            marginRight: "10px",
                          }}
                        >
                          Cancel
                        </Button>
                      </form>
                    </Modal> */}
                  {/* </th> */}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <Row>
          <Col md={6} style={{ textAlign: "left" }}>
            {workflowdata?.length ? (
              <p style={{ marginTop: "15px" }}>
                {countnumber * 20 + 1} to {countnumber * 20 + numberofElements}{" "}
                of {totalUser} records
              </p>
            ) : (
              <p className="formfooterrecordstyle">No Record Found</p>
            )}
          </Col>
          <Col md={6}>
            <div className={classes.root} style={{ float: "right" }}>
              <Pagination
                count={getpageno}
                onChange={handlepage}
                shape="rounded"
              />
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default WorkflowListing;

const customSelectStyles = {
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: "#f5f5f9", // Set your desired background color
    width: "350px", // Adjust the width as needed
  }),
};
