// *****************************************************
// Define configurations for different environments
const configurations = {
  development: {
    api1: "https://api.intstudentworld.com/module1",
    api2: "https://api.intstudentworld.com/module2",
    api3: "https://api.intstudentworld.com/module3",
    api4: "https://api.intstudentworld.com/module4",
  },
  production: {
    api1: "https://api.nepcoms.com/module1",
    api2: "https://api.nepcoms.com/module2",
    api3: "https://api.nepcoms.com/module3",
    api4: "https://api.nepcoms.com/module4",
  },
  test: {
    api1: "https://testcrm.nepcoms.com/module1",
    api2: "https://testcrm.nepcoms.com/module2",
    api3: "https://testcrm.nepcoms.com/module3",
    api4: "https://testcrm.nepcoms.com/module4",
  },
  branch: {
    api1: "https://branch.nepcoms.com/mongolia-module1",
    api2: "https://branch.nepcoms.com/mongolia-module2",
    api3: "https://branch.nepcoms.com/mongolia-module3",
    api4: "https://branch.nepcoms.com/mongolia-module4",
  },
  testbranch: {
    api1: "https://api.testbranch.nepcoms.com/module1",
    api2: "https://api.testbranch.nepcoms.com/module2",
    api3: "https://api.testbranch.nepcoms.com/module3",
    api4: "https://api.testbranch.nepcoms.com/module4",
  },
  philippines: {
    api1: "http://api.philippines.nepcoms.com/module1",
    api2: "http://api.philippines.nepcoms.com/module2",
    api3: "http://api.philippines.nepcoms.com/module3",
    api4: "http://api.philippines.nepcoms.com/module4",
  },
  abc: {
    api1: "https://api.abc.clientelecrm.com/module1",
    api2: "https://api.abc.clientelecrm.com/module2",
    api3: "https://api.abc.clientelecrm.com/module3",
    api4: "https://api.abc.clientelecrm.com/module4",
  },
};

// Set the environment mode
const environment = process.env.REACT_APP_ENVIRONMENT || "abc";

// Select the configuration based on the environment
const base_url = configurations[environment];

export default base_url;
