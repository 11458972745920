import React, { useEffect, useState } from "react";
import "../css/Notes.css";
import axios from "axios";
import base_url from "../../api/bootapi";
import { useParams, Link, useNavigate } from "react-router-dom";
import moment from "moment";
import Modal from "react-modal";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Pagination from "@mui/material/Pagination";
import { logout } from "../features/userSlice";

import { toast } from "react-toastify";
import { Table, Col, Row } from "reactstrap";
import {
  customStyles,
  editorConfiguration,
} from "../../Utilities/CSSUtilities";
import { selectUser } from "./../features/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { check1pointpermission } from "../Permission/permission";

const useStyles = makeStyles((theme) => ({
  root: {
    float: "right",
    "& > *": {
      // marginTop: theme.spacing(2),
    },
  },
  backdrop: {
    // zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "rgba(0,0,0,0.02)",
    // backgroundColor: "transparent",
    opacity: "0.7",
    color: "black",
    zIndex: 1000,
  },
  backdrop2: {
    // zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    zIndex: 1000,
  },
}));
const Notes = (props) => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const classes = useStyles();
  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  const [modalIsOpen2, setisopen2] = useState(false);
  const [modalIsOpen3, setisopen3] = useState(false);
  const [loadermodal, setloadermodal] = useState(true);
  const [template, settemplate] = useState(null);
  const [descriptionState, setdescriptionState] = useState(null);
  const [descriptionState2, setdescriptionState2] = useState(null);
  const [noteid, setnoteid] = useState();
  const [titlevalue, settitlevalue] = useState();
  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getPermissions();
  }, []);

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);

    if (check1pointpermission(306, permission_obj, resource_obj)) {
    } else {
      navigate("/errorPage");
    }
  };

  const closeModal = () => {
    setisopen2(false);
  };
  const [templatedesign, settemplatedesign] = useState();
  const viewTemplate = (elem) => {
    settemplatedesign(elem.target.id);
    setisopen2(true);
  };
  const [proformadetails, setproformadetails] = useState();
  const getproforma = () => {
    axios
      .get(`${base_url.api2}/getAllProforma`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        //console.log("Proforma res", res);
        const data = res.data.data;
        const filteredData = data.filter((item) => item.status === 1);
        setproformadetails(filteredData);
        setTimeout(() => {
          setloadermodal(true);
        }, 2000);
      })
      .catch((err) => {
        setloadermodal(true);
        //console.log(JSON.stringify(err.response.data));
        handle403error(err);
      });
  };
  const submit = (e) => {
    let enquiryId = Number(noteid.enquiryId);
    let title = titlevalue;
    let notes;
    if (descriptionState) {
      notes = descriptionState;
    } else {
      notes = descriptionState2;
    }
    let enquiryWorkflowStepsId = Number(noteid.enquiryWorkflowStepsId);
    let id = noteid.id;
    let data = {
      enquiryId,
      title,
      notes,
      enquiryWorkflowStepsId,
      id,
    };
    //console.log(data);
    postNote(data);
  };
  const postNote = (data) => {
    axios
      .post(`${base_url.api2}/updateNoteById`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        toast.success("Success");
        props.getAllNotes(0);
        setisopen3(false);
      })
      .catch((error) => {
        handle403error(error);
      });
  };
  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            navigate("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };
  return (
    <div className="mainnote">
      <Table responsive striped>
        <thead>
          <tr>
            <th>SN</th>
            <th>Title</th>
            <th>Step</th>
            <th>Action</th>
            <th>Created</th>
          </tr>
        </thead>
        <tbody>
          {props.allnotes?.map((item, index) => (
            <tr key={index + 1}>
              <td>{index + 1}</td>
              <td>{item.title}</td>
              <td>{item.enquiryWorkflowStepsName}</td>
              <td>
                {check1pointpermission(320, permissionData, rolepermission) ? (
                  <>
                    <Link
                      id={item.notes}
                      onClick={() => {
                        // edittemplate
                        setloadermodal(false);
                        settitlevalue(item.title);
                        getproforma();
                        setdescriptionState2(item.notes);
                        setnoteid(item);
                        setisopen3(true);
                      }}
                    >
                      Edit
                    </Link>{" "}
                    <Modal
                      isOpen={modalIsOpen2}
                      onRequestClose={closeModal}
                      style={customStyles}
                      contentLabel="Example Modal"
                    >
                      <div
                        id="modal"
                        dangerouslySetInnerHTML={{ __html: templatedesign }}
                      />
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => setisopen2(false)}
                      >
                        Close
                      </Button>
                    </Modal>
                    &nbsp;|&nbsp;
                  </>
                ) : null}
                {check1pointpermission(319, permissionData, rolepermission) ? (
                  <Link id={item.notes} onClick={viewTemplate}>
                    View
                  </Link>
                ) : null}
                {loadermodal === false ? (
                  <Backdrop className={classes.backdrop} open>
                    <CircularProgress color="inherit" />
                  </Backdrop>
                ) : (
                  <Modal
                    isOpen={modalIsOpen3}
                    onRequestClose={() => setisopen3(false)}
                    style={customStyles}
                    contentLabel="Example Modal"
                  >
                    <div>
                      <div>
                        <form
                          onSubmit={(e) => {
                            e.preventDefault();
                            submit(e);
                          }}
                        >
                          <Row>
                            <Col md={6}>
                              <h2>Note</h2>
                            </Col>
                            <Col md={6}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  padding: "5px",
                                }}
                              >
                                <button
                                  className="cancel_margin btn btn-secondary"
                                  onClick={() => setisopen3(false)}
                                >
                                  Cancel
                                </button>
                                <button
                                  type="submit"
                                  className="btn listing_addbutton ml-1"
                                >
                                  Save
                                </button>
                              </div>
                            </Col>
                          </Row>
                          <hr className="dashboardh" />
                          <Row>
                            <Col md={9}>
                              <label>Title</label>
                              <br />
                              <input
                                type="text"
                                value={titlevalue}
                                onChange={(e) => settitlevalue(e.target.value)}
                                className="form-control underline-input"
                                placeholder="Title Here"
                                style={{ width: "100%" }}
                              />
                            </Col>
                            <Col md={3} className="form-group notesformmgroup">
                              <label>Templates</label>
                              <select
                                onChange={(e) => settemplate(e.target.value)}
                                style={{ width: "100%", height: "40px" }}
                              >
                                <option value="">- Select Template -</option>
                                {proformadetails?.map((item, index) => (
                                  <option key={index} value={item.template}>
                                    {item.name}
                                  </option>
                                ))}
                              </select>
                            </Col>
                          </Row>
                          <br />

                          <label style={{ marginTop: "20px" }}>Note</label>
                          {template === null ? (
                            <CKEditor
                              editor={ClassicEditor}
                              config={editorConfiguration}
                              data={descriptionState2}
                              onReady={(editor) => {
                                //console.log("Editor is ready to use!", editor);
                              }}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                setdescriptionState2(data);
                              }}
                            />
                          ) : (
                            <CKEditor
                              editor={ClassicEditor}
                              config={editorConfiguration}
                              data={template}
                              onReady={(editor) => {
                                //console.log("Editor is ready to use!", editor);
                                // setloadermodal(true);
                              }}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                setdescriptionState(data);
                              }}
                            />
                          )}
                        </form>
                      </div>
                    </div>
                  </Modal>
                )}
              </td>
              <td>
                {item.createdOn
                  ? moment(item.createdOn.split(".")[0]).format("DD-MMM-YY")
                  : null}
                <br />
                {item.createdOn
                  ? moment(item.createdOn.split(".")[0]).format(" hh.mm a")
                  : null}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Row>
        <Col style={{ textAlign: "left" }}>
          {props.allnotes?.length > 0 ? (
            <p style={{ marginTop: "15px" }}>
              {props.notesdata?.number * 1 + 1} to{" "}
              {props.notesdata?.number * 1 + props.notesdata?.numberOfElements}{" "}
              of {props.notesdata?.totalElements} records
            </p>
          ) : (
            <p className="formfooterrecordstyle">No Record Found</p>
          )}
        </Col>
        <Col>
          <div className={classes.root}>
            <Pagination
              count={props.getpageno}
              onChange={props.handlepage}
              shape="rounded"
            />
          </div>
        </Col>
      </Row>

      {/* </div> */}
    </div>
  );
};

export default Notes;
