import React, { useState } from "react";
import Modal from "react-modal";
import { Button } from "reactstrap";
import { customStyles } from "../../../Utilities/CSSUtilities";
import base_url from "../../../api/bootapi";
import axios from "axios";
import callsaveauditapi from "../../../services/auditservice";
import { ToastContainer, toast } from "react-toastify";
import SkillWorkFlowLists from "../../WorkLoad/SkillWorkFlowLists";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const SkillWorkFlowArchive = ({
  username,
  jwtToken,
  handle403error,
  deleteEnquiry,
  pageno,
  deletedUser,
  getallEnquiry,
  setIsOpen,
  currentEnquiryStatus,
}) => {
  const [changestatusmodal, setchangestatusmodal] = useState(false);
  const openmodal = () => setchangestatusmodal(true);
  const isw_user_name = localStorage.getItem("isw_user_name");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const updateUser = () => {
    axios
      .delete(`${base_url.api2}/deleteEnquiryById`, {
        headers: {
          Authorization: jwtToken,
          enquiryId: deleteEnquiry,
        },
      })
      .then((response) => {
        toast.success(response.data.data.message);
        let logs = isw_user_name + " Deleted Enquiry of " + deletedUser;
        callsaveauditapi(logs, dispatch, navigate);
        getallEnquiry(pageno);
        setIsOpen(false);
      })
      .catch((error) => {
        handle403error(error);
      });
  };
  return (
    <>
      {/* <button style={{ cursor: "pointer" }} id={username} onClick={() => openmodal(username)} className="role-deletebtn">
            ARCHIVE
            </button> */}
      <span id={username} onClick={() => openmodal(username)} title="Archive">
        Archive
      </span>
      <Modal
        isOpen={changestatusmodal}
        onRequestClose={() => setchangestatusmodal(false)}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <h4 style={{ marginTop: "10px" }}>
          {" "}
          Are you sure you want to delete current record?
        </h4>
        <div
          style={{
            display: "flex",
            marginTop: "20px",
            justifyContent: "flex-end",
            gap: "10px",
            marginBottom: "10px",
          }}
        >
          <button className="btn listing_addbutton ml-1" onClick={updateUser}>
            Yes
          </button>
          <button
            className="btn btn-secondary cancel_margin"
            onClick={() => setchangestatusmodal(false)}
          >
            No
          </button>
        </div>
      </Modal>
    </>
  );
};

export default SkillWorkFlowArchive;
