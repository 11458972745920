import { ResponsiveBar } from "@nivo/bar";

const Top5CourseChart = ({ data }) => {

  const abbreviateName = (name) => {
    return name.split(' ').map(word => word[0]).join('');
  };

  return (
    <ResponsiveBar
      data={data}
      keys={["enquiryCount"]}
      indexBy="courseName"
      margin={{ top: 50, right: 30, bottom: 50, left: 60 }}
      padding={0.3}
      valueFormat={{ format: ".2s" }}
      colors={{ scheme: "accent" }} // Set color directly
      colorBy="index"
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: -45,
        legend: null,
        legendPosition: "middle",
        legendOffset: 32,
        format: abbreviateName,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "Number of application",
        legendPosition: "middle",
        legendOffset: -40,
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
      //label={false} // Remove count labels from bars
    />
  );
};

export default Top5CourseChart;
