import React, { useEffect, useState } from "react";
import "./App.css";

import { Route, Routes, useNavigate, Link } from "react-router-dom";
import ErrorPage from "./components/ErrorPage";
import Dashboard from "./components/Dashboard/Dashboard";
import MainDashboard from "./components/Dashboard/Body/DashboardBody";
import { selectdashboard } from "./components/features/dashboardSlice";
import ForgotPassword from "./components/ForgotPassword";
import Forgot from "./components/Forgot";
import Profile from "./components/Profile/Profile";
import EditProfile from "./components/Profile/EditProfile";
import AdminUsers from "./components/AllUsers/AdminUsers";
import CreateAdmin from "./components/AllUsers/CreateAdmin";
import EditAdmin from "./components/AllUsers/EditAdmin";
import UserPermissions from "./components/AllUsers/UserPermissions";
import ProspectUsers from "./components/Client/ProspectUsers";
import ProspectList from "./components/Client/ProspectList";
import ClientUsers from "./components/Client/ClientUsers";
import CreateClient from "./components/Client/CreateClient";
import EditClient from "./components/Client/EditClient";
import ClientView from "./components/Client/ClientView";
import ApplicationStatus from "./components/Dashboard/Body/ApplicationStatus";
import ClientEnquiry from "./components/Client/ClientEnquiry";
import Office from "./components/Office/OfficeList";
import CreateOffice from "./components/Office/CreateOffice";
import OfficeAgents from "./components/Office/OfficeAgents";
import AddOfficeUsers from "./components/Office/AddOfficeUsers";
import EditOfficeUser from "./components/Office/EditOfficeUser";
import EditOffice from "./components/Office/EditOffice";
import ViewEnquiryWorkFlow from "./components/Enquiry/ViewEnquiryWorkFlow";
import LeadList from "./components/Leads/LeadList";
import AddBasicClient from "./components/Leads/AddBasicClient";
import CreateClientLead from "./components/Leads/CreateClientLead";
import StartClientSession from "./components/Leads/StartClientSession";
import AllWorkFlowLists from "./components/Masters/Workflow/AllWorkFlowLists";
import ViewWorkflow from "./components/Masters/Workflow/ViewWorkflow";
import VerticalExample from "./components/Masters/Workflow/VerticalExample";
import Colleges from "./components/Provider/Colleges";
import CreateCollege from "./components/Provider/CreateCollege";
import EditCollege from "./components/Provider/EditCollege";
import ApplicationDetail from "./components/Provider/ApplicationDetail";
import Courses from "./components/Courses/CoursesList";
import CreateCourses from "./components/Courses/CreateCourses";
import EditCourses from "./components/Courses/EditCourses";
import Agents from "./components/Agent/AgentList";
import CreateAgent from "./components/Agent/CreateAgent";
import EditAgent from "./components/Agent/EditAgent";
import Roles from "./components/Roles/RoleList";
import ViewPermission from "./components/Roles/ViewPermission";
import AdmisisonWorkFlowLists from "./components/WorkLoad/AdmisisonWorkFlowLists";
import MigrationWorkFlowLists from "./components/WorkLoad/MigrationWorkFlowLists";
import InsuranceWorkFlowLists from "./components/WorkLoad/InsuranceWorkFlowLists";
import SkillWorkFlowLists from "./components/WorkLoad/SkillWorkFlowLists";
import AreaList from "./components/Masters/Area/AreaList";
import CategoryList from "./components/Masters/Category/CategoryList";
import LevelList from "./components/Masters/Level/LevelList";
import LocationList from "./components/Masters/Location/LocationList";
import AdminConfig from "./components/Masters/AdminConfig/AdminConfig";
import IntakeList from "./components/Masters/Intake/IntakeList";
import ClientSource from "./components/Masters/ClientSource/ClientSource";
import GroupPermission from "./components/Masters/GroupPermission/GroupPermission";
import CreateGroupPermission from "./components/Masters/GroupPermission/CreateGroupPermission";
import EditGroupPermission from "./components/Masters/GroupPermission/EditGroupPermission";
import HealthList from "./components/Masters/HealthCoverType/HealthList";
import ServicesList from "./components/Masters/InterestedServices/ServiceList";
import VisaTypeList from "./components/Masters/VisaType/VisaTypeList";
// import ReceiveOthers from "./components/Finance/ReceiveOthers";

import PayableAdmission from "./components/Finance/IncomeSharing/PayableAdmission";
import PayableMigration from "./components/Finance/IncomeSharing/PayableMigration";
import PayableInsurance from "./components/Finance/IncomeSharing/PayableInsurance";
import PayableGeneral from "./components/Finance/IncomeSharing/PayableGeneral";

import ApplicationOnGoing from "./components/Finance/ApplicationOnGoing/ApplicationOnGoing";

import ReceiveGeneral from "./components/Finance/ACReceivable/ReceiveGeneral";
import ReceiveAdmission from "./components/Finance/ACReceivable/ReceiveAdmission";
import ReceiveInsurance from "./components/Finance/ACReceivable/ReceiveInsurance";
import ReceiveMigration from "./components/Finance/ACReceivable/ReceiveMigration";

import InsuranceList from "./components/Masters/Insurance/InsuranceList";
import ProformaList from "./components/Masters/Performa/ProformaList";
import CreateProforma from "./components/Masters/Performa/CreateProforma";
import EditProforma from "./components/Masters/Performa/EditProforma";

import Ambasador from "./components/Ambasador/Ambasador";
import CreateAmbasador from "./components/Ambasador/CreateAmbasador";
import EditAmbasador from "./components/Ambasador/EditAmbasador";

import BrandAmbasador from "./components/Ambasador/BrandAmbasador";
import CreateBrandAmbasador from "./components/Ambasador/CreateBrandAmbasador";
import EditBrandAmbasador from "./components/Ambasador/EditBrandAmbasador";

import ApplicationReports from "./components/Reports/ApplicationReports";
import ClientReports from "./components/Reports/ClientReports";
import ReceivableInvoiceReports from "./components/Reports/ReceivableInvoiceReports";
import PayableInvoiceReports from "./components/Reports/PayableInvoiceReports";
import LeadsReports from "./components/Reports/LeadsReports";

// import ApplicationReportsNew from "./components/ReportsNew/ApplicationReports";
// import ReceivableInvoiceReportsNew from "./components/ReportsNew/ReceivableInvoiceReports";
// import PayableInvoiceReportsNew from "./components/ReportsNew/PayableInvoiceReports";
import ClientReportsNew from "./components/ReportsNew/General/ClientReportsNew";
import TodayLeadsReports from "./components/ReportsNew/General/TodayLeadsReports";
import BranchWiseGeneralReport from "./components/ReportsNew/General/BranchWiseGeneralReport";
import LeadsReportsNew from "./components/ReportsNew/General/LeadsReportsNew";

import AdmissionReport from "./components/ReportsNew/Admission/AdmissionReport";
import SuperAgentReceviableAdmissionSummeryReport from "./components/ReportsNew/Admission/SuperAgentReceviableAdmissionSummeryReport";
import SubAgentPayableAdmissionSummeryReport from "./components/ReportsNew/Admission/SubAgentPayableAdmissionSummeryReport";
import AdmissionProviderReport from "./components/ReportsNew/Admission/AdmissionProviderReport";

import MigrationReport from "./components/ReportsNew/Migration/MigrationReport";
import SubAgentPayableMigrationSummeryReport from "./components/ReportsNew/Migration/SubAgentPayableMigrationSummeryReport";
import MigrationProviderReport from "./components/ReportsNew/Migration/MigrationProviderReport";

import InsuranceReport from "./components/ReportsNew/Insurance/InsuranceReport";
import SubAgentPayableInsuranceSummeryReport from "./components/ReportsNew/Insurance/SubAgentPayableInsuranceSummeryReport";
import InsuranceProviderReport from "./components/ReportsNew/Insurance/InsuranceProviderReport";

import SkillAssessmentReport from "./components/ReportsNew/SkillAssessment/SkillAssessmentReport";
import SubAgentPayableSkillAssessmentSummeryReport from "./components/ReportsNew/SkillAssessment/SubAgentPayableSkillAssessmentSummeryReport";
import SkillAssessmentProviderReport from "./components/ReportsNew/SkillAssessment/SkillAssessmentProviderReport";

import GrossPrintInvoice from "./components/Invoice/GrossPrintInvoice";
import GeneralPrintInvoice from "./components/Invoice/GeneralPrintInvoice";
import PayablePrintInvoice from "./components/Invoice/PayablePrintInvoice";
import Audit from "./components/Audit/Audit";
import Modal from "react-modal";
import moment from "moment";
import Inbox from "./components/Inbox/Inbox";
import ReceivedInbox from "./components/Inbox/ReceivedInbox";
import "./components/css/LoginForm.css";
import axios from "axios";
import base_url from "./api/bootapi";
import { useDispatch, useSelector } from "react-redux";
import {
  login,
  logout,
  selectUser,
  setdashboardcheck,
  setOfficeId,
} from "./components/features/userSlice";
import { getAdmin, selectAdmin } from "./components/features/adminSlice";
import { getClient } from "./components/features/clientSlice";
import { getcollege } from "./components/features/collegeSlice";
import { getcourse } from "./components/features/courseSlice";
import AddInvoiceDetails from "./components/Invoice/AddInvoiceDetails1";
import Invoice from "./components/Invoice/Invoice";
import EditInvoice from "./components/Invoice/EditInvoice";
import ViewInvoice from "./components/Invoice/ViewInvoice";
import ViewEnquiryWorkflowList from "./components/Enquiry/ViewEnquiryWorkflowList";
import MigrationProviderList from "./components/Masters/MigrationProvider/MigrationProviderList";
import PrintInvoice from "./components/Invoice/PrintInvoice";
import { useForm } from "react-hook-form";
import QuotationList from "./components/Quotation/QuotationList";
import CreateQuotation from "./components/Quotation/CreateQuotation";
import EditQuotation from "./components/Quotation/EditQuotation";
import PrintQuotation from "./components/Quotation/PrintQuotation";

import ViewOffice from "./components/Office/ViewOffice";
import ViewCollege from "./components/Provider/ViewCollege";
import CourseReport from "./components/Courses/CourseReport";
import ViewAgent from "./components/Agent/ViewAgent";
import CourseView from "./components/Courses/CourseView";

import { useStyles, customStyles } from "./Utilities/CSSUtilities";
import { Col, Row, Button } from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import "./App.css";

import nepcomlogo from "./components/images/nepcoms-logo 1.png";
import studentlogo from "./components/images/studentworld.png";
import logosvg from "./components/images/login-illus.svg";
import WorkflowFiles from "./components/Enquiry/WorkflowFiles";
import { ToastContainer, toast } from "react-toastify";
import "./components/css/LoginForm.css";
import PageNotFound from "./components/PageNotFound";
import Appointment from "./components/Dashboard/Body/AppointmentCalendar";
import CreateProspect from "./components/Client/CreateProspect";
import LogIn from "./LogIn";
import Register from "./Register";

Modal.setAppElement("#root");

function App() {
  const user = useSelector(selectUser);
  const dashboardvalue = useSelector(selectdashboard);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const default_branch_id = localStorage.getItem("default_branch_id");

  return (
    <div className="App">
      <ToastContainer limit={1} />
      {!user ? (
        <Routes>
          <Route path="/" element={<LogIn />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgotpage" element={<Forgot />} />
          <Route
            path="/forgot-password/:email/:token"
            element={<ForgotPassword />}
          />

          <Route
            path="*"
            element={
              <>
                <PageNotFound />
              </>
            }
          />
        </Routes>
      ) : (
        <>
          <Routes>
            <Route
              path="/"
              element={
                <Dashboard
                  Comp={MainDashboard}
                  sidebarvalue="dashboard"
                  dashboardcheck
                />
              }
            />

            <Route
              path="/create-proforma"
              element={
                <Dashboard Comp={CreateProforma} sidebarvalue="proforma" />
              }
            />
            <Route
              path="/proforma"
              element={
                <Dashboard Comp={ProformaList} sidebarvalue="proforma" />
              }
            />
            <Route
              path="/edit-proforma/:proforma_id"
              element={
                <Dashboard Comp={EditProforma} sidebarvalue="proforma" />
              }
            />

            <Route
              path="/leads-list"
              element={<Dashboard Comp={LeadList} sidebarvalue="lead" />}
            />

            <Route
              path="/aip"
              element={<Dashboard Comp={LeadList} sidebarvalue="aip" />}
            />

            <Route
              path="/add-basic-client"
              element={<Dashboard Comp={AddBasicClient} sidebarvalue="lead" />}
            />
            <Route
              path="/start-client-session/:temp_client_id"
              element={
                <Dashboard Comp={StartClientSession} sidebarvalue="lead" />
              }
            />
            <Route
              path="/create-client-lead/:temp_client_id/:client_username"
              element={
                <Dashboard Comp={CreateClientLead} sidebarvalue="lead" />
              }
            />
            <Route
              path="/aip-list"
              element={<Dashboard Comp={ProspectUsers} sidebarvalue="AIP" />}
            />
            <Route
              path="/prospect-list"
              element={<Dashboard Comp={ProspectList} sidebarvalue="AIP" />}
            />
            <Route
              path="/appointment"
              element={<Dashboard Comp={Appointment} sidebarvalue="AIP" />}
            />
            <Route
              path="/client-list"
              element={<Dashboard Comp={ClientUsers} sidebarvalue="client" />}
            />
            <Route
              path="/edit-client/:client_username"
              element={<Dashboard Comp={EditClient} sidebarvalue="client" />}
            />
            <Route
              path="/client-view/:client_username"
              element={<Dashboard Comp={ClientView} sidebarvalue="client" />}
            />
            <Route
              path="/create-client"
              element={<Dashboard Comp={CreateClient} sidebarvalue="client" />}
            />

            <Route
              path="/create-prospect"
              element={
                <Dashboard Comp={CreateProspect} sidebarvalue="client" />
              }
            />

            <Route
              path="/admission-enquiry"
              element={
                <Dashboard
                  Comp={AdmisisonWorkFlowLists}
                  sidebarvalue="admission"
                />
              }
            />
            <Route
              path="/migration-enquiry"
              element={
                <Dashboard
                  Comp={MigrationWorkFlowLists}
                  sidebarvalue="migration"
                />
              }
            />
            <Route
              path="/insurance-enquiry"
              element={
                <Dashboard
                  Comp={InsuranceWorkFlowLists}
                  sidebarvalue="insurance"
                />
              }
            />
            <Route
              path="/skillAssessment-enquiry"
              element={
                <Dashboard
                  Comp={SkillWorkFlowLists}
                  sidebarvalue="skillassessment"
                />
              }
            />
            <Route
              path="/areas"
              element={<Dashboard Comp={AreaList} sidebarvalue="area" />}
            />
            <Route
              path="/category"
              element={
                <Dashboard Comp={CategoryList} sidebarvalue="category" />
              }
            />
            <Route
              path="/levels"
              element={<Dashboard Comp={LevelList} sidebarvalue="level" />}
            />
            <Route
              path="/migrationprovider"
              element={
                <Dashboard
                  Comp={MigrationProviderList}
                  sidebarvalue="migrationprovider"
                />
              }
            />

            <Route
              path="/intake"
              element={<Dashboard Comp={IntakeList} sidebarvalue="intake" />}
            />
            <Route
              path="/client-source"
              element={
                <Dashboard Comp={ClientSource} sidebarvalue="clientsource" />
              }
            />

            <Route
              path="/group-permissions"
              element={
                <Dashboard
                  Comp={GroupPermission}
                  sidebarvalue="grouppermission"
                />
              }
            />
            <Route
              path="/create-group-permissions"
              element={
                <Dashboard
                  Comp={CreateGroupPermission}
                  sidebarvalue="grouppermission"
                />
              }
            />
            <Route
              path="/edit-group-permissions/:group_id"
              element={
                <Dashboard
                  Comp={EditGroupPermission}
                  sidebarvalue="grouppermission"
                />
              }
            />
            <Route
              path="/healthcovertype"
              element={
                <Dashboard Comp={HealthList} sidebarvalue="healthcovertype" />
              }
            />
            <Route
              path="/interestedservices"
              element={
                <Dashboard
                  Comp={ServicesList}
                  sidebarvalue="interestedservices"
                />
              }
            />
            <Route
              path="/visatype"
              element={
                <Dashboard Comp={VisaTypeList} sidebarvalue="visatype" />
              }
            />
            <Route
              path="/sent-mails"
              element={<Dashboard Comp={Inbox} sidebarvalue="sent" />}
            />
            <Route
              path="/received-mails"
              element={
                <Dashboard Comp={ReceivedInbox} sidebarvalue="received" />
              }
            />
            <Route
              path="/view-enquiry-workflow/:client_id/:enquiry_id/:client_name"
              element={
                <Dashboard Comp={ViewEnquiryWorkFlow} sidebarvalue="client" />
              }
            />
            <Route
              path="/view-enquiry-workflowList/:client_id/:client_name"
              element={
                <Dashboard
                  Comp={ViewEnquiryWorkflowList}
                  sidebarvalue="client"
                />
              }
            />
            <Route
              path="/add-invoice-details/:client_id"
              element={
                <Dashboard Comp={AddInvoiceDetails} sidebarvalue="client" />
              }
            />
            <Route
              path="/edit-invoice-details/:client_id/:invoice_id"
              element={<Dashboard Comp={EditInvoice} sidebarvalue="client" />}
            />

            <Route
              path="/view-invoice-details/:client_id/:invoice_id"
              element={<Dashboard Comp={ViewInvoice} sidebarvalue="client" />}
            />
            <Route
              path="/commisionnetprintinvoice/:client_id/:invoice_id"
              element={<PrintInvoice />}
            />
            <Route
              path="/commisiongrossprintinvoice/:client_id/:invoice_id"
              element={<GrossPrintInvoice />}
            />
            <Route
              path="/payableprintinvoice/:client_id/:invoice_id"
              element={<PayablePrintInvoice />}
            />
            <Route
              path="/generalinvoiceprintinvoice/:client_id/:invoice_id"
              element={<GeneralPrintInvoice />}
            />
            <Route
              path="/quotations"
              element={
                <Dashboard Comp={QuotationList} sidebarvalue="quotation" />
              }
            />
            <Route
              path="/create-quotation"
              element={
                <Dashboard Comp={CreateQuotation} sidebarvalue="quotation" />
              }
            />

            <Route
              path="/edit-quotation/:quotation_id"
              element={
                <Dashboard Comp={EditQuotation} sidebarvalue="quotation" />
              }
            />
            <Route
              path="/printquotation/:quotation_id"
              element={<PrintQuotation />}
            />
            <Route
              path="/users"
              element={
                <Dashboard Comp={AdminUsers} sidebarvalue="manageuser" />
              }
            />
            <Route
              path="/create-users"
              element={
                <Dashboard Comp={CreateAdmin} sidebarvalue="manageuser" />
              }
            />

            <Route
              path="/edit-users/:admin_username"
              element={<Dashboard Comp={EditAdmin} sidebarvalue="manageuser" />}
            />
            <Route
              path="/user-permissions/:admin_id/:admin_email"
              element={
                <Dashboard Comp={UserPermissions} sidebarvalue="manageuser" />
              }
            />
            <Route
              path="/office"
              element={<Dashboard Comp={Office} sidebarvalue="managebranch" />}
            />
            <Route
              path="/create-office"
              element={
                <Dashboard Comp={CreateOffice} sidebarvalue="managebranch" />
              }
            />
            <Route
              path="/view-office/:branch_id"
              element={
                <Dashboard Comp={ViewOffice} sidebarvalue="managebranch" />
              }
            />

            <Route
              path="/office-agents/:office_id"
              element={
                <Dashboard Comp={OfficeAgents} sidebarvalue="managebranch" />
              }
            />
            <Route
              path="/create-office-users/:office_id"
              element={
                <Dashboard Comp={AddOfficeUsers} sidebarvalue="managebranch" />
              }
            />
            <Route
              path="/edit-office/:office_ids"
              element={
                <Dashboard Comp={EditOffice} sidebarvalue="managebranch" />
              }
            />
            <Route
              path="/agent"
              element={<Dashboard Comp={Agents} sidebarvalue="agent" />}
            />
            <Route
              path="/view-agent/:agent_id/:agent_email"
              element={<Dashboard Comp={ViewAgent} sidebarvalue="agent" />}
            />
            <Route
              path="/create-agent"
              element={<Dashboard Comp={CreateAgent} sidebarvalue="agent" />}
            />
            <Route
              path="/edit-agent/:agent_id/:agent_email"
              element={<Dashboard Comp={EditAgent} sidebarvalue="agent" />}
            />
            <Route
              path="/provider"
              element={<Dashboard Comp={Colleges} sidebarvalue="provider" />}
            />
            <Route
              path="/create-provider"
              element={
                <Dashboard Comp={CreateCollege} sidebarvalue="provider" />
              }
            />
            <Route
              path="/view-provider/:provider_id"
              element={<Dashboard Comp={ViewCollege} sidebarvalue="provider" />}
            />

            <Route
              path="/edit-provider/:provider_id"
              element={<Dashboard Comp={EditCollege} sidebarvalue="provider" />}
            />
            <Route
              path="/application-details"
              element={
                <Dashboard Comp={ApplicationDetail} sidebarvalue="provider" />
              }
            />
            <Route
              path="/course"
              element={<Dashboard Comp={Courses} sidebarvalue="course" />}
            />
            <Route
              path="/course-report/:course_id"
              element={<Dashboard Comp={CourseView} sidebarvalue="course" />}
            />
            <Route
              path="/create-course"
              element={<Dashboard Comp={CreateCourses} sidebarvalue="course" />}
            />
            <Route
              path="/edit-course/:course_id"
              element={<Dashboard Comp={EditCourses} sidebarvalue="course" />}
            />
            <Route
              path="/application-ongoing"
              element={
                <Dashboard
                  Comp={ApplicationOnGoing}
                  sidebarvalue="applicationongoing"
                />
              }
            />
            <Route
              path="/acc-admission"
              element={
                <Dashboard
                  Comp={ReceiveAdmission}
                  sidebarvalue="accadmission"
                />
              }
            />

            <Route
              path="/acc-insurance"
              element={
                <Dashboard
                  Comp={ReceiveInsurance}
                  sidebarvalue="accrecinsurance"
                />
              }
            />
            <Route
              path="/acc-migration"
              element={
                <Dashboard
                  Comp={ReceiveMigration}
                  sidebarvalue="accrecmigration"
                />
              }
            />
            <Route
              path="/acc-general"
              element={
                <Dashboard Comp={ReceiveGeneral} sidebarvalue="accrecgeneral" />
              }
            />
            <Route
              path="/acc-payable-admission"
              element={
                <Dashboard
                  Comp={PayableAdmission}
                  sidebarvalue="accpayableadmission"
                />
              }
            />
            <Route
              path="/acc-payable-insurance"
              element={
                <Dashboard
                  Comp={PayableInsurance}
                  sidebarvalue="accpayableinsurance"
                />
              }
            />
            <Route
              path="/acc-payable-migration"
              element={
                <Dashboard
                  Comp={PayableMigration}
                  sidebarvalue="accpayablemigration"
                />
              }
            />
            <Route
              path="/acc-payable-general"
              element={
                <Dashboard
                  Comp={PayableGeneral}
                  sidebarvalue="accpayablegeneral"
                />
              }
            />
            <Route
              path="/insurance"
              element={
                <Dashboard
                  Comp={InsuranceList}
                  sidebarvalue="settinginsurance"
                />
              }
            />
            <Route
              path="/ambassador"
              element={<Dashboard Comp={Ambasador} sidebarvalue="ambassador" />}
            />
            <Route
              path="/edit-ambassador/:ambassador_email"
              element={
                <Dashboard Comp={EditAmbasador} sidebarvalue="ambassador" />
              }
            />
            <Route
              path="/create-ambassador"
              element={
                <Dashboard Comp={CreateAmbasador} sidebarvalue="ambassador" />
              }
            />
            <Route
              path="/brand-ambassador"
              element={
                <Dashboard Comp={BrandAmbasador} sidebarvalue="brandamb" />
              }
            />
            <Route
              path="/edit-brand-ambassador/:brand_ambassador_id"
              element={
                <Dashboard Comp={EditBrandAmbasador} sidebarvalue="brandamb" />
              }
            />
            <Route
              path="/create-brand-ambassador"
              element={
                <Dashboard
                  Comp={CreateBrandAmbasador}
                  sidebarvalue="brandamb"
                />
              }
            />
            <Route
              path="/audit"
              element={<Dashboard Comp={Audit} sidebarvalue="audit" />}
            />
            <Route
              path="/application-report"
              element={
                <Dashboard
                  Comp={ApplicationReports}
                  sidebarvalue="applicationreport"
                />
              }
            />
            <Route
              path="/receivable-invoice-report"
              element={
                <Dashboard
                  Comp={ReceivableInvoiceReports}
                  sidebarvalue="receivablereport"
                />
              }
            />

            <Route
              path="/payable-invoice-report"
              element={
                <Dashboard
                  Comp={PayableInvoiceReports}
                  sidebarvalue="payablereport"
                />
              }
            />
            <Route
              path="/today-lead-report"
              element={
                <Dashboard
                  Comp={TodayLeadsReports}
                  sidebarvalue="todayLeadReport"
                />
              }
            />
            <Route
              path="/leads-report"
              element={
                <Dashboard Comp={LeadsReportsNew} sidebarvalue="leadsreport" />
              }
            />
            <Route
              path="/client-report"
              element={
                <Dashboard
                  Comp={ClientReportsNew}
                  sidebarvalue="clientreport"
                />
              }
            />
            <Route
              path="/branch-wise-general-summery-report"
              element={
                <Dashboard
                  Comp={BranchWiseGeneralReport}
                  sidebarvalue="branchWiseSummeryReport"
                />
              }
            />
            <Route
              path="/admisison-report"
              element={
                <Dashboard
                  Comp={AdmissionReport}
                  sidebarvalue="admissionSubReport"
                />
              }
            />
            <Route
              path="/superAgent-receivable-admission-summery-report"
              element={
                <Dashboard
                  Comp={SuperAgentReceviableAdmissionSummeryReport}
                  sidebarvalue="superAgentReceviableAdmissionSummeryReport"
                />
              }
            />
            <Route
              path="/subAgent-payable-admission-summery-report"
              element={
                <Dashboard
                  Comp={SubAgentPayableAdmissionSummeryReport}
                  sidebarvalue="subAgentPayableAdmissionSummeryReport"
                />
              }
            />
            <Route
              path="/admission-provider-summery-report"
              element={
                <Dashboard
                  Comp={AdmissionProviderReport}
                  sidebarvalue="branchWiseAdmissionSummeryReport"
                />
              }
            />
            <Route
              path="/migration-report"
              element={
                <Dashboard
                  Comp={MigrationReport}
                  sidebarvalue="migrationSubReport"
                />
              }
            />

            <Route
              path="/subAgent-payable-migration-summery-report"
              element={
                <Dashboard
                  Comp={SubAgentPayableMigrationSummeryReport}
                  sidebarvalue="subAgentPayableMigrationSummeryReport"
                />
              }
            />
            <Route
              path="/migration-provider-summery-report"
              element={
                <Dashboard
                  Comp={MigrationProviderReport}
                  sidebarvalue="migrationProviderSummeryReport"
                />
              }
            />
            <Route
              path="/insurance-report"
              element={
                <Dashboard
                  Comp={InsuranceReport}
                  sidebarvalue="insurenceSubReport"
                />
              }
            />
            <Route
              path="/subAgent-payable-insurence-summery-report"
              element={
                <Dashboard
                  Comp={SubAgentPayableInsuranceSummeryReport}
                  sidebarvalue="subAgentPayableInsurenceSummeryReport"
                />
              }
            />
            <Route
              path="/insurence-provider-summery-report"
              element={
                <Dashboard
                  Comp={InsuranceProviderReport}
                  sidebarvalue="insurenceProviderSummeryReport"
                />
              }
            />
            <Route
              path="/skillAssessment-report"
              element={
                <Dashboard
                  Comp={SkillAssessmentReport}
                  sidebarvalue="skillAssessmentSubReport"
                />
              }
            />
            <Route
              path="/subAgent-payable-skill-assessment-summery-report"
              element={
                <Dashboard
                  Comp={SubAgentPayableSkillAssessmentSummeryReport}
                  sidebarvalue="subAgentPayableSkillAssessmentSummeryReport"
                />
              }
            />
            <Route
              path="/skill-assessment-provider-summery-report"
              element={
                <Dashboard
                  Comp={SkillAssessmentProviderReport}
                  sidebarvalue="skillAssessmentProviderSummeryReport"
                />
              }
            />
            <Route
              path="/profile"
              element={<Dashboard Comp={Profile} sidebarvalue="dashboard" />}
            />
            <Route
              path="/edit-profile/:admin_username"
              element={
                <Dashboard Comp={EditProfile} sidebarvalue="dashboard" />
              }
            />
            <Route
              path="/invoice"
              element={<Dashboard Comp={Invoice} sidebarvalue="client" />}
            />
            <Route
              path="/workflow"
              element={
                <Dashboard Comp={AllWorkFlowLists} sidebarvalue="workflow" />
              }
            />
            <Route
              path="/applicationstatus"
              element={
                <Dashboard
                  Comp={ApplicationStatus}
                  sidebarvalue="applicationstatus"
                />
              }
            />
            <Route
              path="/view-workflow/:workflow_id"
              element={
                <Dashboard Comp={ViewWorkflow} sidebarvalue="workflow" />
              }
            />

            <Route
              path="/edit-office-user/:office_id/:user_email"
              element={<Dashboard Comp={EditOfficeUser} sidebarvalue="agent" />}
            />
            <Route
              path="/view-client-enquiry/:client_id/:client_name"
              element={<Dashboard Comp={ClientEnquiry} sidebarvalue="client" />}
            />
            <Route
              path="/roles"
              element={<Dashboard Comp={Roles} sidebarvalue="managerole" />}
            />
            <Route
              path="/view-permission/:role_id/:role_name"
              element={
                <Dashboard Comp={ViewPermission} sidebarvalue="course" />
              }
            />
            <Route
              path="/locations"
              element={
                <Dashboard Comp={LocationList} sidebarvalue="location" />
              }
            />
            <Route
              path="/admin-config"
              element={
                <Dashboard Comp={AdminConfig} sidebarvalue="adminconfig" />
              }
            />
            <Route path="/register" element={<Register />} />
            <Route
              element={<Dashboard Comp={ErrorPage} sidebarvalue="dashboard" />}
            />
            <Route path="/register" element={<Register />} />
            <Route
              path="*"
              element={
                <>
                  {" "}
                  <PageNotFound />{" "}
                </>
              }
            />
          </Routes>
        </>
      )}
    </div>
  );
}

export default App;
