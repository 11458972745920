import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "../css/EnquiryWorkflowStepDetails.css";
import { Col, InputGroup, Row } from "reactstrap";
import Modal from "react-modal";
import axios from "axios";
import base_url from "../../api/bootapi";
import { toast } from "react-toastify";
import EmailIcon from "@mui/icons-material/Email";
import PostAddIcon from "@mui/icons-material/PostAdd";
import Swal from "sweetalert2";
import * as pdfjsLib from "pdfjs-dist/build/pdf";

import AttachmentIcon from "@mui/icons-material/Attachment";
import { makeStyles } from "@mui/styles";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { withStyles } from "@mui/styles";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import CheckIcon from "@mui/icons-material/Check";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Tooltip from "@mui/material/Tooltip";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { selectUser, logout } from "../features/userSlice";
import { useForm } from "react-hook-form";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import DatePicker from "react-datepicker";
import {
  // editorConfiguration,
  customStyles,
  customStylesAttachment,
} from "../../Utilities/CSSUtilities";
import email from "../images/workload/email.svg";
import notes from "../images/workload/notes.svg";
import attachfile from "../images/workload/attach-file.svg";
import calender from "../images/workload/calendar.svg";
import { check1pointpermission } from "../Permission/permission";
import AdmissionEmailModal from "../Modal/Masters/AdmissionEmailModal";
import AdmissionNoteModal from "../Modal/Masters/AdmissionNoteModal";
import AdmissionAttachmentModal from "../Modal/Masters/AdmissionAttachmentModal";
import AdmissionAppointmentModal from "../Modal/Masters/AdmissionAppointmentModal";
import visaGranted from "../../assets/images/visaGranted.webp";

pdfjsLib.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.min.mjs`;

const useStyles = makeStyles((theme) => ({
  backdrop: {
    // zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "rgba(0,0,0,0.02)",
    // backgroundColor: "transparent",
    opacity: "0.7",
    color: "black",
    zIndex: 1000,
  },
  backdrop2: {
    // zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    zIndex: 1000,
  },
  btnclr: {
    color: "white",
    padding: "10px 15px",
    backgroundColor: "#17b978",
    borderRadius: "8px",
  },
  btnclr2: {
    color: "white",
    padding: "10px 15px",
    backgroundColor: "gray",
    borderRadius: "8px",
  },
}));

const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    // color: theme.palette.common.black,
  },
  tooltip: {
    // backgroundColor: theme.palette.common.black,
  },
}));

function BootstrapTooltip(props) {
  const classes2 = useStylesBootstrap();

  return <Tooltip arrow classes={classes2} {...props} />;
}

const editorConfiguration = {
  toolbar: {
    items: [
      "heading",
      "|",
      "bold",
      "italic",
      "horizontalLine",
      "underline",
      "link",
      "bulletedList",
      "numberedList",
      "todoList",
      "|",
      "outdent",
      "indent",
      "alignment",
      "|",
      // "imageUpload",
      "imageInsert",
      "blockQuote",
      "fontBackgroundColor",
      "fontColor",
      "insertTable",
      "fontSize",
      "fontFamily",
      // "htmlEmbed",
      // "mediaEmbed",
      "codeBlock",
      // "code",
      "undo",
      "redo",
    ],
  },
  language: "en",
  image: {
    toolbar: [
      "imageTextAlternative",
      "imageStyle:full",
      "imageStyle:side",
      "linkImage",
    ],
  },
  table: {
    contentToolbar: [
      "tableColumn",
      "tableRow",
      "mergeTableCells",
      "tableCellProperties",
      "tableProperties",
    ],
  },
  licenseKey: "",
};

const EnquiryWorkflowStepDetails = (props) => {
  const startDateRegex =
    /(?:Course Start Date|Course Start Date:|Commencement Date|Commencement Date:)\s*\n*\s*((\d{2}\/\d{2}\/\d{4})|(?:\d{1,2}\s+(?:January|February|March|April|May|June|July|August|September|October|November|December)\s+\d{4}))/;

  const endDateRegex =
    /(?:Course End Date|Course End Date:|Completion Date|Completion Date:)\s*\n*\s*((\d{2}\/\d{2}\/\d{4})|(?:\d{1,2}\s+(?:January|February|March|April|May|June|July|August|September|October|November|December)\s+\d{4}))/;

  //console.log("184---->", props?.getNoOfCount);
  //console.log("185---->", props?.getNoOfProspect);
  const appCountValues = props?.getNoOfCount.map((item) => item.appCount);
  // Accessing the first (and only) element of the resulting array
  const appCountValue = appCountValues[0];

  console.log("189---->ClientCountValue", appCountValue); // Output: 2

  const prospectCountValues = props?.getNoOfProspect.map(
    (item) => item.appCount
  );
  // Accessing the first (and only) element of the resulting array
  const prospectCountValue = prospectCountValues[0];
  console.log("194---->prospectCountValue", prospectCountValue); // Output: 2

  const isoDateString = new Date().toISOString();
  const datetimelocal = isoDateString.split(".")[0];
  let params = useParams();
  const user = useSelector(selectUser);
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  // const selected_client_id = localStorage.getItem("selected_client_id");
  const selected_client_id =
    params.client_id || localStorage.getItem("selected_client_id");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [descriptionState, setdescriptionState] = useState(null);
  const [descriptionState2, setdescriptionState2] = useState(null);
  const [template, settemplate] = useState(null);
  const [log, setlogs] = useState();
  const [loadermodal, setloadermodal] = useState(true);
  const [radioattachmentvalue, setradioattachmentvalue] =
    React.useState("miscellaneous_doc");
  const [notestitle, setnotestitle] = useState();
  const [emailworkflowstepid, setemailworkflowstepid] = useState();
  const [filesizemore, setfilesizemore] = useState(false);
  const [selecteddate, setdate] = useState(null);
  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showRefundProcess, setShowRefundProcess] = useState(false);
  const [updatedStatus, setupdatedStatus] = useState();
  console.log("242---->", updatedStatus);
  const [isApplicationClosed, setisApplicationClosed] = useState();
  const handleRefundClick = () => {
    setShowRefundProcess(true);
    // Add your refund processing logic here if needed
  };
  const [error, setError] = useState(null);

  const extractTextFromPage = async (page) => {
    try {
      const textContent = await page.getTextContent();
      const textItems = textContent.items.map((item) => item.str);
      return textItems;
    } catch (error) {
      setError(error);
    }
  };

  const extractDate = (text, regex, setState) => {
    try {
      const match = text.match(regex);
      console.log("match-->", match);
      return match && match[1] ? match[1] : null;
    } catch (error) {
      setError(error);
      return "";
    }
  };

  const possibleFormats = [
    "DD MMM YYYY",
    "D MMM YYYY",
    "DD MMMM YYYY",
    "D MMMM YYYY",
    "DD/MM/YYYY",
    "D/MM/YYYY",
    "DD-MM-YYYY",
    "D-MM-YYYY",
    "YYYY-MM-DDThh:mm:ss", // This format might be included for consistency, but isn't typically an input format
  ];

  const extractTextFromPdf = async (file) => {
    try {
      const reader = new FileReader();
      reader.onload = async () => {
        const typedarray = new Uint8Array(reader.result);
        const pdf = await pdfjsLib.getDocument(typedarray).promise;

        let fullText = "";
        const numPages = pdf.numPages;

        for (let i = 1; i <= numPages; i++) {
          const page = await pdf.getPage(i);
          const pageTextItems = await extractTextFromPage(page);
          const pageText = pageTextItems.join("\n");
          fullText += `${pageText}\n`;
        }

        console.log("Full text--->", fullText);

        const extractedStartDate = await extractDate(fullText, startDateRegex);
        const extractedEndDate = await extractDate(fullText, endDateRegex);

        const startDateMoment = moment(
          extractedStartDate,
          possibleFormats,
          true
        );
        const endDateMoment = moment(extractedEndDate, possibleFormats, true);

        if (startDateMoment.isValid() && endDateMoment.isValid()) {
          Swal.fire({
            title: "Confirmation",
            text: `We have found the course start and end dates in the uploaded document. Do you want to update the course start and end dates? Start Date: ${extractedStartDate}, End Date: ${extractedEndDate}`,
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "Yes, update it!",
            cancelButtonText: "No, cancel!",
          }).then((result) => {
            if (result.isConfirmed) {
              props.updateCourseDate(startDateMoment, endDateMoment);
            }
          });
        }
      };
      reader.readAsArrayBuffer(file);
    } catch (error) {
      setError(error);
    }
  };

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success mr-3",
      cancelButton: "btn btn-outline-success",
    },
    buttonsStyling: false,
  });
  const getLogsByEnquiryId = () => {
    console.log("43--->", props.enquiryId);
    if (props.enquiryId) {
      axios
        .get(`${base_url.api2}/getLogsByEnquiryId`, {
          headers: {
            Authorization: jwtToken,
            enquiryId: props.enquiryId,
          },
        })
        .then((res) => {
          setbackdropopen(false);
          console.log("51--->", res.data.data);
          const data = res.data.data;
          data.sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn));
          console.log("51--->", data);

          const log_obj = [];
          for (let i = 0; i < 2; i++) {
            const editor_name = data[i].log.split(" - ")[1];
            const logData = data[i].log.split(" - ")[0];
            data[i].editorName = editor_name;
            data[i].log = logData;
            log_obj.push(res.data.data[i]);
          }
          //console.log(log_obj);
          //console.log(res.data.data);
          setlogs(log_obj);
        })
        .catch((err) => {
          setbackdropopen(false);
          toast.error(JSON.stringify(err?.response?.data?.message));
          handle403error(err);
          setlogs([]);
        });
    }
  };

  useEffect(() => {
    getLogsByEnquiryId();
    if (props?.workflowSteps.length > 0) {
      let chk = false;
      props?.workflowSteps.forEach((element, index) => {
        if (props?.workflowSteps[index - 1]?.status === 1) {
          setExpanded(`panel${index + 1}`);
          chk = true;
        }
      });
      if (chk === false) {
        setExpanded("panel1");
      }
    }
    //console.log("237---->", props?.displayStatus);
    if (
      // props?.displayStatus === "Refund Processing" ||
      // props?.displayStatus === "Refund Complete" ||
      props?.displayStatus === "Steps Completed"
    ) {
      setHideRefundSteps(false);
      setButtonVisible(false);
    } else {
      setHideRefundSteps(true);
      setButtonVisible(true);
    }
    console.log("263---->", props.workflowSteps);

    // Visa Refused and granted

    const visaIndex = props.workflowSteps.findIndex(
      (workflow) => workflow.workflowStepsName === "Visa Decision Received"
    );
    if (visaIndex !== -1) {
      const currentStep = props.workflowSteps[visaIndex];
      const previousStep = props.workflowSteps[visaIndex - 1];
      if (
        currentStep.status === 0 &&
        previousStep &&
        previousStep.status === 1
      ) {
        setVisaDecision(false);
      } else {
        setVisaDecision(true);
      }
    }

    getPermissions();
    getClientDetails();

    getCurrentWorkflow();
  }, [
    props.workflowSteps,
    props.displayStatus,
    props?.getNoOfCount,
    props?.getNoOfProspect,
  ]);

  useEffect(() => {
    getproforma();
  }, []);

  useEffect(() => {
    getEnquiryDetails();
  }, [isApplicationClosed]);

  const getEnquiryDetails = () => {
    console.log("423 coming here");
    setbackdropopen(true);
    axios
      .get(`${base_url.api2}/getEnquiryById`, {
        headers: {
          Authorization: jwtToken,
          enquiryId: params.enquiry_id,
        },
      })
      .then((resp) => {
        setupdatedStatus(resp?.data?.data?.enquiryStatus);
        setImgGranted(resp?.data?.data?.visaStatus);
        setisApplicationClosed(resp?.data?.data?.close);
        setbackdropopen(false);
      })
      .catch((error) => {
        console.log("Error", error);
        setbackdropopen(false);
      });
  };

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);

    if (check1pointpermission(306, permission_obj, resource_obj)) {
    } else {
      navigate("/errorPage");
    }
  };

  //console.log("255--->", params);
  const [imgGranted, setImgGranted] = useState();
  const [allDetails, setclientDetail] = useState({});
  const [client_out_details, setclient_out_details] = useState();
  const getClientDetails = () => {
    axios
      .get(`${base_url.api3}/getClientByUsername`, {
        headers: {
          Authorization: jwtToken,
          username: params.client_name,
        },
      })
      .then((response) => {
        //console.log(response.data.data.lastLogin);
        if (response.data.status_code == "200") {
          let client = response.data.data.clientDetail;
          //console.log(client);
          let client2 = response.data.data;
          setclient_out_details(client2);
          setclientDetail({
            addedOn: client.addedOn,
            address: client.address,
            alias: client.alias,
            ban: client.ban,
            city: client.city,
            clientSource: client.clientSource,
            comment: client.comment,
            company: client.company,
            contactPreference: client.contactPreference,
            country: client.country,
            currentlyStudyingInstitutionName:
              client.currentlyStudyingInstitutionName,
            dob: client.dob,
            email: client.email,
            firstName: client.firstName,
            gender: client.gender,
            id: client.id,
            insuranceExpiryDate: client.insuranceExpiryDate,
            insuranceIssueDate: client.insuranceIssueDate,
            insuranceProvider: client.insuranceProvider,
            insuranceType: client.insuranceType,
            intake: client.intake,
            lastName: client.lastName,
            membershipExpiry: client.membershipExpiry,
            membershipNumber: client.membershipNumber,
            middleName: client.middleName,
            nationality: client.nationality,
            passport: client.passport,
            passportExpiryDate: client.passportExpiryDate,
            passportIssueDate: client.passportIssueDate,
            passportNumber: client.passportNumber,
            phone: client2.phone,
            secondaryphone: client2.secondaryphone,
            photo: client.photo,
            pincode: client.pincode,
            policyNumber: client.policyNumber,
            secondaryEmail: client.secondaryEmail,
            state: client.state,
            status: client.status,
            street: client.street,
            subAgent: client.subAgent,
            superAgent: client.superAgent,
            username: client.username,
            usi: client.usi,
            visaExpiry: client.visaExpiry,
            visaIssueDate: client.visaIssueDate,
            visaType: client.visaType,
            subAgentName: client.subAgentName,
            superAgentName: client.superAgentName,
            subAgentId: client.subAgentId,
            superAgentId: client.superAgentId,
          });

          //console.log(allDetails);
        }
      })
      .catch((err) => handle403error(err));
  };

  const handleRadioChange = (event) => {
    //console.log(event.target.value);
    setradioattachmentvalue(event.target.value);
  };
  const handleChange = (panel, id) => (event, newExpanded) => {
    // console.log("coming here");
    // setbackdropopen(true);
    localStorage.setItem("selected_workflow_step_id", id);
    getLogsByEnquiryId();
    setExpanded(newExpanded ? panel : false);
    setlogs(null);
  };

  const [workFlow, setWorkFlow] = useState();

  // const getlogsbyworkflowstep = (id) => {
  //   //console.log(id);
  //   axios
  //     .get(`${base_url.api2}/getLogsByEnquiryWorkflowStepsId`, {
  //       headers: {
  //         Authorization: jwtToken,
  //         enquiryWorkflowStepsId: Number(id),
  //       },
  //     })
  //     .then((res) => {
  //       setbackdropopen(false);
  //       const log_obj = [];
  //       for (let i = 0; i < res.data.data.length; i++) {
  //         const editor_name = res.data.data[i].log.split(" - ")[1];
  //         const logData = res.data.data[i].log.split(" - ")[0];
  //         res.data.data[i].editorName = editor_name;
  //         res.data.data[i].log = logData;
  //         log_obj.push(res.data.data[i]);
  //       }
  //       //console.log(log_obj);
  //       //console.log(res.data.data);
  //       setlogs(log_obj);
  //     })
  //     .catch((error) => {
  //       setbackdropopen(false);
  //       handle403error(error);
  //       setlogs([]);
  //     });
  // };

  var subtitle;
  const [noteid, setnoteid] = useState();
  const [workflowstepname, setworkflowstepname] = useState();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalIsOpen2, setIsOpen2] = React.useState(false);
  const [modalIsOpen3, setIsOpen3] = React.useState(false);

  const openModal3 = () => {
    setIsOpen3(true);
  };
  const openModal = (id, name) => {
    //console.log(id);
    setnoteid(id);
    setworkflowstepname(name);
    setIsOpen(true);
  };

  const afterOpenModal = () => {
    subtitle.style.color = "#f00";
  };

  const [modalIsopen4, setIsOpen4] = useState(false);

  const closeModal = (id) => {
    setIsOpen(false);
    setIsOpen2(false);
    setIsOpen3(false);
    setIsOpen4(false);
    // localStorage.getItem('selected_workflow_step_id');
  };

  const [selectedstepId, setselectedstepId] = useState(null);
  const [attachments, setattachments] = useState(null);
  const openModal2 = (id) => {
    localStorage.setItem("selected_workflow_step_id", id);
    // setradioattachmentvalue();
    // setnotes([]);
    //console.log(id);
    setselectedstepId(id);
    // setnoteid(id);
    setIsOpen2(true);
    getattachment(id);
  };
  const [appointmentenquirystepid, setappointmentenquirystepid] = useState();
  const openmodalappointment = (id) => {
    setdate(null);
    setappointmentenquirystepid(id);
    setIsOpen4(true);
  };
  const attachment_obj3 = [];
  const [attachment_obj, setattachmentobj] = useState();
  const getattachment = (id) => {
    axios
      .get(`${base_url.api2}/getAttachmentsByEnquiryWorkflowStepsId`, {
        headers: {
          Authorization: jwtToken,
          enquiryWorkflowStepsId: Number(id),
        },
      })
      .then((res) => {
        setattachments(res.data.data);
        props.getAttachments();
      })
      .catch((error) => {
        handle403error(error);
      });
  };

  //console.log("465----->", client_out_details);

  const handleWorkFlowChange = (newWorkFlowData) => {
    const newData = newWorkFlowData;
    props.workFlowDataChange(newData);
  };

  const getCurrentWorkflow = () => {
    // let firstStatus = null;
    // let secondStatus = null;
    axios
      .get(`${base_url.api2}/getEnquiryWorkflowByEnquiryId`, {
        headers: {
          Authorization: jwtToken,
          enquiryId: params.enquiry_id, // current user worklow id
        },
      })
      .then((res) => {
        console.log("695--->", res.data.data);
        const activeWorkflow = res.data.data.find(
          (workflow) => workflow.status === 1
        );

        const enquiryWorkflowId = activeWorkflow
          ? activeWorkflow.enquiryWorkflowId
          : null;
        axios
          .get(`${base_url.api2}/getEnquiryWorkflowStepByWorkflowId`, {
            headers: {
              Authorization: jwtToken,
              workflowId: enquiryWorkflowId, // current steps for the user
            },
          })
          .then((res) => {
            console.log("685---->", res.data.data);
            setWorkFlow(res.data.data);
            handleWorkFlowChange(res.data.data);
            // handleFirstStatusChange(firstStatus, secondStatus);
          })
          .catch((err) => {
            //console.log("Error", err);
          });
      })
      .catch((err) => {
        handle403error(err);
      });
    //console.log("497---->");
  };

  // const handleFirstStatusChange = (firstStatus, secondStatus) => {
  //   //console.log("518---> firstStatus", firstStatus);
  //   //console.log("518--->SecondStatus", secondStatus);
  //   if (firstStatus === 0 && secondStatus === 1) {
  //     //console.log("518----->", workFlow[0].workflowStepsStatus);
  //     //console.log("518 name----->", workFlow[0].workflowStepsName);
  //   } else if (firstStatus === 0 && secondStatus === 0) {
  //     //console.log("518----->", workFlow[0].workflowStepsStatus);
  //     //console.log("518 name----->", workFlow[0].workflowStepsName);
  //   }
  // };

  const handleStatusChange = (workflowStatus) => {
    //console.log("546---->", workflowStatus);
    const newStatus = workflowStatus;

    // if (
    //   workflowStatus === "Refund Processing" ||
    //   workflowStatus === "Refund Complete"
    // ) {
    //   setHideRefundSteps(false);
    //   setButtonVisible(false);
    // } else {
    //   setHideRefundSteps(true);
    //   setButtonVisible(true);
    // }

    props.onStatusChange(newStatus);
  };

  const [visaDecision, setVisaDecision] = useState();

  const handleVisaDecision = (status) => {
    let data2 = {
      enquiryId: params.enquiry_id,
      visaStatus: status,
    };
    axios
      .post(`${base_url.api2}/updateEnquiryByEnquiryId`, data2, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        if (res.data.data.visaStatus === 1) {
          setVisaDecision(true);

          Swal.fire({
            title: "Visa Granted",
            text: "Congratulations! Your visa has been granted.",
            icon: "success",
            confirmButtonText: "OK",
            confirmButtonClass: "green-ok-btn",
          });
        } else if (res.data.data.visaStatus === 0) {
          Swal.fire({
            title: "Visa Rejected",
            text: "We regret to inform you that your visa application has been rejected.",
            icon: "error",
            confirmButtonText: "OK",
            confirmButtonClass: "green-ok-btn",
          });
          setVisaDecision(true);
        }
      })
      .catch((e) => {
        setVisaDecision(false);
      });
  };

  const handleCloseApplication = () => {
    if (isApplicationClosed) {
      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: "Do you really want to reopen the application?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, reopen it!",
          cancelButtonText: "No, keep it closed",
        })
        .then((result) => {
          if (result.isConfirmed) {
            closeApplicationApiCall()
              .then((response) => {
                Swal.fire(
                  "Reopened!",
                  "The application has been reopened successfully.",
                  "success"
                );
                getEnquiryDetails();
                props.getEnquiryDetails();
              })
              .catch((error) => {
                Swal.fire(
                  "Error!",
                  "There was a problem reopening the application.",
                  "error"
                );
              });
          }
        });
    } else {
      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: "Do you really want to close the application?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, close it!",
          cancelButtonText: "No, keep it open",
        })
        .then((result) => {
          if (result.isConfirmed) {
            closeApplicationApiCall()
              .then((response) => {
                Swal.fire(
                  "Closed!",
                  "The application has been closed successfully.",
                  "success"
                );
                getEnquiryDetails();
                props.getEnquiryDetails();
              })
              .catch((error) => {
                Swal.fire(
                  "Error!",
                  "There was a problem closing the application.",
                  "error"
                );
              });
          }
        });
    }
  };

  const closeApplicationApiCall = () => {
    if (isApplicationClosed) {
      const data2 = {
        enquiryId: params.enquiry_id,
        close: 0,
      };

      return axios.post(`${base_url.api2}/updateEnquiryByEnquiryId`, data2, {
        headers: {
          Authorization: jwtToken,
        },
      });
    } else {
      const data2 = {
        enquiryId: params.enquiry_id,
        close: 1,
        enquiryStatus: "Closed",
      };

      return axios.post(`${base_url.api2}/updateEnquiryByEnquiryId`, data2, {
        headers: {
          Authorization: jwtToken,
        },
      });
    }
  };

  const handleStepCompletion = (id, index, workflowStepName) => {
    console.log("Coming here 864");
    if (!visaDecision && workflowStepName === "Visa Decision Received") {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success mr-3",
          cancelButton: "btn btn-outline-success",
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons
        .fire({
          title: "Action Required",
          text: 'Please select either Visa to be "Granted" or "Refused" before completing this step.',
          icon: "warning",
          showCloseButton: true,
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: "Granted",
          confirmButtonClass: "btn btn-success",
          cancelButtonText: "Refused",
          cancelButtonClass: "btn btn-outline-success",
          confirmButtonAriaLabel: "Granted",
          cancelButtonAriaLabel: "Refused",
          onClose: () => {
            Swal.close();
          },
        })
        .then((result) => {
          if (result.isConfirmed) {
            createLog(id, index);
            handleVisaDecision(1);
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            createLog(id, index);
            handleVisaDecision(0);
          }
        });
    } else createLog(id, index);
  };

  const createLog = (id1, index) => {
    console.log("575---->", props?.workflowSteps);
    setbackdropopen(true);
    axios
      .get(`${base_url.api2}/getEnquiryById`, {
        headers: {
          Authorization: jwtToken,
          enquiryId: params.enquiry_id,
        },
      })
      .then(
        (resp) => {
          console.log("coming here 915");
          //console.log("Enquiry Response", resp.data.data);
          setupdatedStatus(resp?.data?.data?.enquiryStatus);
          console.log("303----------->", resp?.data?.data?.enquiryStatus);

          const isoDateString = new Date().toISOString();
          const datenow = isoDateString.split(".")[0];
          let createdOn = datenow;
          let enquiryId = Number(params.enquiry_id);
          let currentStageId;
          if (index + 1 < props.workflowSteps.length) {
            currentStageId = Number(props?.workflowSteps[index + 1].id);
          } else if (index < props.workflowSteps.length) {
            currentStageId = Number(props?.workflowSteps[index].id);
          }
          // let enquiryId = Number(props.enquiryId);
          let enquiryWorkflowStepsId = Number(id1);
          let id = 0;
          let logs = "";
          let currentStage;
          let previousStatus;
          let enquiryStatus = null;
          let nextStage;
          console.log(
            "1080--->Previous",
            props?.workflowSteps[index].workflowStepsName
          );
          previousStatus =
            props?.workflowSteps[index].workflowStepsName ||
            "Application Processing";
          //console.log("603------>", props.workflowSteps[index].workflowStepsName);
          if (index + 1 < props.workflowSteps.length) {
            enquiryStatus = "Processing";
            currentStage = props.workflowSteps[index + 1].workflowStepsName;

            if (props.workflowSteps[`${index + 2}`]) {
              nextStage = props.workflowSteps[`${index + 2}`].workflowStepsName;
            } else {
              nextStage = "Steps Completed";
            }
            logs =
              props.workflowSteps[index].workflowStepsName +
              " changed - " +
              user.isw_user_name;
          } else {
            enquiryStatus = "Completed";
            currentStage = "Steps Completed";
            nextStage = "Steps Completed";
            logs = user.isw_user_name + " completed all steps";
          }

          //console.log("Logs", logs);

          if (
            index + 1 < props.workflowSteps.length &&
            props.workflowSteps[index].workflowStepsName ===
            props?.getWorlflowCovertClientDetails?.workflowStepsName
          ) {
            axios
              .post(
                `${base_url.api3}/updateClientByUsername`,
                {
                  username: client_out_details?.username,
                  client: 1,
                },
                {
                  headers: {
                    "Access-Control-Allow-Origin": "*",
                    Authorization: jwtToken,
                    // enquiryLeadType: selectedLeadType,
                  },
                }
              )
              .then((res) => {
                if (prospectCountValue === 1) {
                  console.log("coming in 653");
                  axios
                    .post(
                      `${base_url.api3}/updateClientByUsername`,
                      {
                        username: client_out_details?.username,
                        prospect: 0,
                      },
                      {
                        headers: {
                          "Access-Control-Allow-Origin": "*",
                          Authorization: jwtToken,
                          // enquiryLeadType: selectedLeadType,
                        },
                      }
                    )
                    .then((res) => {
                      //console.log("Update", res);
                    })
                    .catch((e) => {
                      //console.log(e);
                    });
                }
              })
              .catch((e) => {
                //console.log(e);
              });

            console.log("coming in 650");

            let data2 = {
              enquiryId: params.enquiry_id,
              prospect: 0,
            };
            axios
              .post(`${base_url.api2}/updateEnquiryByEnquiryId`, data2, {
                headers: {
                  Authorization: jwtToken,
                },
              })
              .then((res) => {
                //console.log(res);
              })
              .catch((err) => {
                setbackdropopen(false);
                toast.error(JSON.stringify(err?.response?.data?.message));
                handle403error(err);
              });
          }

          let log = logs;
          let data = {
            createdOn,
            enquiryId,
            enquiryWorkflowStepsId,
            id,
            log,
          };

          console.log("status before try", log, data);
          //console.log("entering in line 708", appCountValue, resp?.data?.data?.enquiryStatus)

          console.log("entering in line 750");
          try {
            // const matchingStep = workFlow.find((step) => {
            //   //console.log("current name:", step.workflowStepsName);
            //   //console.log("Current stage:", currentStage);
            //   return step.workflowStepsName === currentStage;
            // });

            console.log("1080--->Workflow", workFlow);

            const prevstat = workFlow.find((step) => {
              console.log(
                "current name: , previous Status:",
                step.workflowStepsName,
                previousStatus
              );
              console.log("Current stage:", currentStage);
              return step.workflowStepsName === previousStatus;
            });

            console.log("status after try 723", prevstat);
            if (prevstat) {
              let workflowCurrentStatus = prevstat.workflowStepsStatus;
              handleStatusChange(workflowCurrentStatus);
              console.log("coming here 650");
              postLogs(
                data,
                currentStage,
                nextStage,
                workflowCurrentStatus,
                currentStageId
              );
              //console.log("coming here 650");
            }
          } catch (e) {
            //console.log("Error", e);
          }
          //console.log("status finished");
        },
        (error) => {
          handle403error(error);
          toast.error(JSON.stringify(error.response.data.message));
        }
      );
  };

  const postLogs = (
    data,
    currentStage,
    nextStage,
    workflowCurrentStatus,
    currentStageId
  ) => {
    axios
      .post(`${base_url.api2}/createLog`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        if (res.data.status_code == 200) {
          // updateEnquiryStage(currentStage, enquiryStatus);

          updateStatus(
            data.enquiryWorkflowStepsId,
            currentStage,
            nextStage,
            workflowCurrentStatus,
            currentStageId
          );
          getLogsByEnquiryId();
          // toast.success("Success");
          //console.log(res.data.data);
        }
      })
      .catch((err) => {
        setbackdropopen(false);
        handle403error(err);
      });
  };
  const updateStatus = (
    id,
    currentStage,
    nextStage,
    workflowCurrentStatus,
    currentStageId
  ) => {
    let status = 1;
    let data = {
      id,
      status,
    };
    //console.log("637 Forward---->Workflow Status", workflowCurrentStatus);
    let data2 = {
      enquiryId: params.enquiry_id,
      currentStage,
      nextStage,
      enquiryStatus: workflowCurrentStatus,
      currentStageId,
    };

    //console.log("797 data2---->Workflow Status", data2);
    axios
      .post(`${base_url.api2}/updateEnquiryByEnquiryId`, data2, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        //console.log("833---->", appCountValue, res?.data?.data.enquiryStatus);
        if (
          appCountValue == 0 &&
          (res?.data?.data.enquiryStatus === "Steps Completed" ||
            res?.data?.data.enquiryStatus === "Discontinue Complete" ||
            res?.data?.data.enquiryStatus === "Refund Complete")
        ) {
          //console.log("entering in line 840", appCountValue, res?.data?.data.enquiryStatus)
          axios
            .post(
              `${base_url.api3}/updateClientByUsername`,
              {
                username: client_out_details?.username,
                client: 0,
              },
              {
                headers: {
                  "Access-Control-Allow-Origin": "*",
                  Authorization: jwtToken,
                  // enquiryLeadType: selectedLeadType,
                },
              }
            )
            .then((res) => {
              //console.log("Update", res);
            })
            .catch((e) => {
              //console.log(e);
            });
        }

        axios
          .post(`${base_url.api2}/updateEnquiryWorkflowStepsStatusById`, data, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              Authorization: jwtToken,
            },
          })
          .then((res) => {
            props.getEnquiry();
          })
          .catch((err) => {
            setbackdropopen(false);
            //console.log(JSON.stringify(err.response.data));
          });
      })
      .catch((err) => {
        setbackdropopen(false);
        toast.error(JSON.stringify(err?.response?.data?.message));
        handle403error(err);
      });
    axios
      .post(`${base_url.api2}/updateEnquiryWorkflowStepsStatusById`, data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        // toast.success('Success')
        props.getEnquiry();
        // props.getEnquiry(props.enquiryId);
      })
      .catch((err) => {
        toast.error(JSON.stringify(err?.response?.data?.message));
        handle403error(err);
      });
  };
  // Proforma templates
  const [proformadetails, setproformadetails] = useState();
  const getproforma = () => {
    axios
      .get(`${base_url.api2}/getAllProforma`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        //console.log("Proforma res", res);
        const data = res.data.data;
        const filteredData = data.filter((item) => item.status === 1);

        setproformadetails(filteredData);
        setTimeout(() => {
          setloadermodal(true);
        }, 2000);
      })
      .catch((err) => {
        setloadermodal(true);
        handle403error(err);
      });
  };
  const submit = (e) => {
    const isoDateString = new Date().toISOString();
    const datenow = isoDateString.split(".")[0];
    let enquiryId = Number(params.enquiry_id);
    let title = notestitle;
    let notes;
    if (descriptionState) {
      notes = descriptionState;
    } else {
      notes = descriptionState2;
    }
    let enquiryWorkflowStepsId = Number(noteid);
    let enquiryWorkflowStepsName = workflowstepname;
    let createdOn = datenow;
    let addedBy = isw_user_name + "( " + user.isw_user_email + " )";
    let id = 0;
    let data = {
      enquiryId,
      title,
      notes,
      enquiryWorkflowStepsId,
      enquiryWorkflowStepsName,
      id,
      createdOn,
      addedBy,
    };
    postNote(data);
  };
  const postNote = (data) => {
    const isoDateString = new Date().toISOString();
    const datenow = isoDateString.split(".")[0];
    // let logs2 = user.isw_user_name + " added Note " + data.title;
    let logs2 = "Added note - By " + user.isw_user_name;

    axios
      .post(`${base_url.api2}/createNotes`, data, {
        headers: {
          Authorization: jwtToken,
          log: logs2,
          enquiryId: Number(params.enquiry_id),
          // enquiryId: Number(props.enquiryId),
          createdOn: datenow,
        },
      })
      .then((res) => {
        toast.success("Success");
        props.getAllNotes(0);
        // props.getAllNotes(props.enquiryId);
        axios
          .get(`${base_url.api2}/getNotesByEnquiryWorkflowStepsId`, {
            headers: {
              Authorization: jwtToken,
              enquiryWorkflowStepsId: Number(noteid),
            },
          })
          .then((res) => {
            //console.log(res.data.data);
            // setnotes(res.data.data);
            setIsOpen(false);
            getLogsByEnquiryId();
          })
          .catch((error) => {
            handle403error(error);
          });
      });
  };
  //console.log(notes);
  const [backdropopen, setbackdropopen] = useState(false);
  const [displayattachmentradio, setdisplayattachmentradio] = useState(false);
  const [attachmentdisplay, setattachmentdisplay] = useState(false);
  const [hideRefundSteps, setHideRefundSteps] = useState();
  const [buttonVisible, setButtonVisible] = useState();
  const handleRefundSteps = () => {
    setHideRefundSteps(false);
    setButtonVisible(false);
  };

  if (buttonVisible === false) {
    // Find the index of the workflow step with the name "Refund Processing"
    const refundProcessingIndex = props.workflowSteps.findIndex(
      (step) => step.workflowStepsName === "Refund Processing"
    );

    // If the "Refund Processing" step exists, update the status of all preceding steps to 1
    if (refundProcessingIndex !== -1) {
      props.workflowSteps.forEach((step, index) => {
        if (index < refundProcessingIndex) {
          step.status = 1;
        }
      });
    }
  }
  const onSubmitAttachment = (data) => {
    if (filesizemore === true) {
      return false;
    }
    let fileToUpload = data.picture;
    console.log("fileupload", fileToUpload[0]);
    let formData = new FormData();

    if (fileToUpload.length) {
      for (let i = 0; i < fileToUpload.length; i++) {
        formData.append("stepfile", fileToUpload[i], fileToUpload[i].name);
      }
      postAttachment(formData, fileToUpload);
    } else {
      setattachmentdisplay(true);
    }
  };

  const postAttachment = (formData, fileToUpload) => {
    //console.log(fileToUpload);
    // let formData = new FormData();
    // let fileName = fileToUpload.name;
    // let logs3 = user.isw_user_name + " added Attachment " + fileName;
    // formData.append("file", fileToUpload, fileToUpload.name);

    const isoDateString = new Date().toISOString();
    const datenow = isoDateString.split(".")[0];

    let logs3 = "Added attachment - By " + user.isw_user_name;
    setbackdropopen(true);

    if (fileToUpload.length) {
      if (radioattachmentvalue != undefined) {
        axios
          .post(`${base_url.api2}/uploadAttachment`, formData, {
            headers: {
              Authorization: jwtToken,
              log: logs3,
              documentType: radioattachmentvalue,
              enquiryId: params.enquiry_id,
              createdOn: datenow,
              clientId: selected_client_id,
              enquiryWorkflowStepsId: Number(selectedstepId),
              createdBy: isw_user_name + " (" + isw_user_email + ")",
            },
          })
          .then((res) => {
            //console.log(res.data.data);
            getattachment(res.data.data[0].enquiryWorkflowStepsId);
            getLogsByEnquiryId();
            setIsOpen2(false);
            setbackdropopen(false);
            toast.success(res.data.message);
            // extractDataFromPDF(fileToUpload[0]);
            extractTextFromPdf(fileToUpload[0]);
          })
          .catch((error) => {
            setbackdropopen(false);
            handle403error(error);
            // toast.error(JSON.stringify(error.response.data.message));
          });
      } else {
        //console.log("select type");
        setdisplayattachmentradio(true);
      }
    }
  };

  const [workloaddescriptionvalue2, setworkloaddescriptionvalue2] =
    useState(null);
  const [workloaddescriptionvalue, setworkloaddescriptionvalue] =
    useState(null);

  const submit3 = (e) => {
    const isoDateString = new Date().toISOString();
    const datenow = isoDateString.split(".")[0];
    let date = datenow;
    let fromEmailId = user.isw_user_email;
    let id = 0;
    let toEmailId = e.target[0].value;
    let subject = e.target[1].value;
    let description;
    if (workloaddescriptionvalue) {
      description = workloaddescriptionvalue;
    } else {
      description = workloaddescriptionvalue2;
    }
    let mailType = "sent";
    const data = {
      date,
      fromEmailId,
      id,
      toEmailId,
      subject,
      mailType,
      description,
    };
    const data3 = {
      createdOn: datenow,
      enquiryId: params.enquiry_id,
      id: 0,
      enquiryWorkflowStepsId: emailworkflowstepid,
      log: `Added Email - By ${user.isw_user_name}`,
    };
    postEmail(data, data3);
  };
  const postEmail = (data, data3) => {
    axios
      .post(`${base_url.api4}/createEmail`, data)
      .then((res) => {
        //console.log("Response from mail", res);

        axios
          .post(`${base_url.api2}/createLog`, data3, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              Authorization: jwtToken,
            },
          })
          .then((res) => {
            //console.log(res);
          })
          .catch((err) => {
            //console.log(err);
          });

        toast.success(res.data.message);
        setIsOpen3(false);
      })
      .catch((error) => {
        toast.error(JSON.stringify(error.response.data.message));
        handle403error(error);
      });
  };
  const isw_adminId = localStorage.getItem("isw_adminId");
  const isw_user_name = localStorage.getItem("isw_user_name");
  const isw_user_email = localStorage.getItem("isw_user_email");

  const onSubmitAppointment = (e) => {
    let appointmentDate = moment(selecteddate).format("YYYY-MM-DDThh:mm:ss");
    let clientId = Number(selected_client_id);
    let clientName = params.client_name;
    let counsellingCreatedBy = Number(isw_adminId);
    let counselorName = isw_user_name;
    let id = 0;
    let notes = e.target[0].value;
    let enquiryId = Number(params.enquiry_id);
    let enquiryWorkflowStepId = Number(appointmentenquirystepid);
    // let logs2 = user.isw_user_name + " added Appointment " + notes;
    let logs2 = "Added appointment - By " + user.isw_user_name;
    let data = {
      appointmentDate,
      clientId,
      clientName,
      counsellingCreatedBy,
      enquiryWorkflowStepId,
      counselorName,
      id,
      notes,
      enquiryId,
    };
    axios
      .post(`${base_url.api4}/addAppointments`, data, {
        headers: {
          Authorization: jwtToken,
          log: logs2,
        },
      })
      .then((res) => {
        toast.success("Appointment Created");
        closeModal();
      })
      .catch((err) => {
        toast.error(JSON.stringify(err?.response?.data?.message));
        handle403error(err);
      });
  };
  const [filesizeMessage, setfilesizeMessage] = useState("");

  const chkfilesize = (e) => {
    if (e.target.files) {
      if (e.target.files.length) {
        let fileSize = 0;
        for (let i = 0; i < e.target.files.length; i++) {
          fileSize += e.target.files[i].size;
        }
        //console.log(fileSize);
        if (fileSize / 1048576 > 5) {
          setfilesizemore(true);
          setfilesizeMessage(
            "Total file size exceeds 5 MB. Please upload smaller files."
          );
        } else {
          setfilesizemore(false);
          setfilesizeMessage("");
        }
      }
    }
  };
  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            navigate("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };

  //console.log("1003---------->", props.workflowSteps);
  //console.log("1004---------->", refundWorkFlowSteps);

  const [workflowName, setworkflowName] = useState();
  const [openadd, setopenadd] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  const handleChangeWorkflowType = () => {
    axios
      .get(`${base_url.api2}/getWorkflowByWorkflowType`, {
        headers: {
          Authorization: jwtToken,
          workflowType: props.workflowtyperedux,
        },
      })
      .then((response) => {
        //console.log(response);
        setworkflowName(response.data.data);
        setopenadd(true);
      })
      .catch((error) => {
        handle403error(error);
      });
  };

  const [workflowid, setworkflowId] = useState(null);
  const [workflowname, setworkflowname] = useState(null);
  const handleChangeworkflowName = (event) => {
    //console.log("coming here");
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("id");
    const optionElementName = optionElement.getAttribute("value");
    //console.log(optionElementId);
    setworkflowId(optionElementId);
    setworkflowname(optionElementName);
    if (optionElementId) {
      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: "Do you really want to add this workflow to the application?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, add it!",
          cancelButtonText: "No, cancel",
        })
        .then((result) => {
          if (result.isConfirmed) {
            axios
              .post(
                `${base_url.api2}/assignNewEnquiryWorkflow`,
                {
                  clientId: Number(selected_client_id),
                  enquiryId: props.enquiryId,
                  enquiryWorkflowId: 0,
                  workflowId: optionElementId,
                  workflowName: optionElementName,
                  workflowType: props.workflowtyperedux,
                  status: 1,
                },
                {
                  headers: {
                    "Access-Control-Allow-Origin": "*",
                    Authorization: jwtToken,
                  },
                }
              )
              .then((response) => {
                window.location.reload();
                console.log("1611-->", response);
                toast.success("Success");
                setworkflowId(null);
                setworkflowname(null);
                setopenadd(false);
              })
              .catch((error) => {
                setworkflowId(null);
                setworkflowname(null);
                handle403error(error);
              });
          }
        });
    }
  };

  return (
    <div>
      <Backdrop className={classes.backdrop2} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {props.workflowSteps !== null ? (
        props.workflowSteps.map((item, index) => (
          <div key={index + 1}>
            {/* {!hideRefundSteps &&
              item.workflowStepsName === "Refund Processing" ? (
              <br />
            ) : null} */}

            <Accordion
              // disabled={
              //   props.workflowSteps[index - 1]?.status === 0 ? true : false
              // }
              square
              expanded={expanded === `panel${index + 2}`}
              onChange={() => {
                if (
                  props.workflowSteps[index - 1]?.status != 0 &&
                  item.status == 0
                ) {
                  handleChange(`panel${index + 1}`, item.id);
                }
              }}
            >
              <div className="accordion_radio">
                {/* {item.status === 1 ? (
                  <CheckIcon
                    className="positioning color"
                    fontSize="small"
                    style={{ marginLeft: "10px" }}
                  />
                ) : (
                  <FiberManualRecordIcon
                    className="positioning"
                    style={{ marginLeft: "10px" }}
                    fontSize="small"
                    color="primary"
                  />
                )} */}
                <AccordionSummary
                  className="accordion_radio2 dummy3_accordion"
                  aria-controls={`panel${index + 1}d-header`}
                  id={`panel${index + 1}d-header`}
                // style={{
                //   display:
                //     hideRefundSteps &&
                //     (item.workflowStepsName === "Refund Processing" ||
                //       item.workflowStepsName === "Refund Complete")
                //       ? "none"
                //       : "block",
                // }}
                >
                  <div className="accordion_name">
                    {!hideRefundSteps ? <br /> : null}
                    <Typography align="left">
                      {item.workflowStepsName}
                    </Typography>
                    {item.workflowStepsName ===
                      props?.getWorlflowCovertClientDetails?.workflowStepsName ? (
                      <Typography align="right">(Convert to Client)</Typography>
                    ) : null}

                    <div className="accordion_namebutton">
                      {/* {(permissionData?.includes(36)) && (props.workflowSteps[index - 1]?.status === 0) ? ( */}

                      <div className="d-flex justify-content-around">
                        {item.workflowStepsName === "Visa Decision Received" &&
                          imgGranted ? (
                          <div className="d-flex justify-content-around">
                            <img
                              src={visaGranted}
                              style={{
                                width: "50px",
                                width: "50px",
                                marginRight: "10px",
                              }}
                            />
                          </div>
                        ) : null}
                        {props.workflowSteps[index - 1]?.status != 0 &&
                          item.status == 0 ? (
                          <>
                            {check1pointpermission(
                              316,
                              permissionData,
                              rolepermission
                            ) ? (
                              <div className="d-flex justify-content-space">
                                <button
                                  variant="outlined"
                                  color="primary"
                                  onClick={() =>
                                    handleStepCompletion(
                                      item.id,
                                      index,
                                      item.workflowStepsName
                                    )
                                  }
                                  className={
                                    isApplicationClosed
                                      ? "step_complete_css"
                                      : "refund-btn"
                                  }
                                  style={{ marginInline: "7px" }}
                                  disabled={isApplicationClosed}
                                >
                                  Step Completed
                                </button>
                                {openadd == false ? (
                                  <button
                                    variant="outlined"
                                    color="primary"
                                    className="refund-btn"
                                    style={{ marginInline: "7px" }}
                                    onClick={() => {
                                      handleChangeWorkflowType();
                                    }}
                                  >
                                    Trigger New Flow
                                  </button>
                                ) : null}
                                {openadd == true ? (
                                  <select
                                    className={classes.selectdrop}
                                    onInput={handleChangeworkflowName}
                                    name="workflowName"
                                    style={{ backgroundColor: "#f5f5f9", width: "200px", marginRight: "10px" }}
                                  >
                                    <option value="">- Select Workflow -</option>
                                    {workflowName?.map((cuntitem) => (
                                      <option
                                        key={cuntitem.workflowId}
                                        id={cuntitem.workflowId}
                                        value={cuntitem.workflowName}
                                      >
                                        {cuntitem.workflowName}
                                      </option>
                                    ))}
                                  </select>
                                ) : null}
                              </div>
                            ) : null}
                          </>
                        ) : null}
                        <div
                          className="mt-2"
                          style={
                            isApplicationClosed ? { display: "none" } : null
                          }
                        >
                          {check1pointpermission(
                            317,
                            permissionData,
                            rolepermission
                          ) ? (
                            <AdmissionEmailModal
                              openModal3={() => {
                                if (
                                  props.workflowSteps[index - 1]?.status === 0
                                ) {
                                  return false;
                                } else {
                                  openModal3();
                                  setemailworkflowstepid(item.id);
                                }
                              }}
                              client_name={params.client_name}
                              modalIsOpen3={modalIsOpen3}
                              setIsOpen3={setIsOpen3}
                              isApplicationClosed={isApplicationClosed}
                              submit3={submit3}
                              jwtToken={jwtToken}
                              handle403error={handle403error}
                              proformadetails={proformadetails}
                              workloaddescriptionvalue={
                                workloaddescriptionvalue
                              }
                              workloaddescriptionvalue2={
                                workloaddescriptionvalue2
                              }
                              setworkloaddescriptionvalue={
                                setworkloaddescriptionvalue
                              }
                              setworkloaddescriptionvalue2={
                                setworkloaddescriptionvalue2
                              }
                            />
                          ) : null}
                          {/* <img src={email} title="Email" alt=""
                          style={{ margin: "1px" }}
                        />} */}

                          {/* {(permissionData?.includes(37)) && (props.workflowSteps[index - 1]?.status === 0) ? ( */}
                          {check1pointpermission(
                            318,
                            permissionData,
                            rolepermission
                          ) ? (
                            <AdmissionNoteModal
                              submit={submit}
                              workflowtyperedux={props.workflowtyperedux}
                              closeModal={closeModal}
                              notestitle={notestitle}
                              setnotestitle={setnotestitle}
                              proformadetails={proformadetails}
                              template={template}
                              settemplate={settemplate}
                              setdescriptionState={setdescriptionState}
                              setdescriptionState2={setdescriptionState2}
                              isApplicationClosed={isApplicationClosed}
                              modalIsOpen={() => {
                                if (
                                  props.workflowSteps[index - 1]?.status === 0
                                ) {
                                  return false;
                                } else {
                                  setloadermodal(false);
                                  openModal(item.id, item.workflowStepsName);
                                }
                              }}
                            />
                          ) : null}
                          {/* <img src={notes} title="Notes" alt=""
                          style={{ margin: "1px" }}
                        />} */}

                          {/* {(permissionData?.includes(40)) && (props.workflowSteps[index - 1]?.status === 0) ? ( */}
                          {check1pointpermission(
                            321,
                            permissionData,
                            rolepermission
                          ) ? (
                            <AdmissionAttachmentModal
                              chkfilesize={chkfilesize}
                              onSubmitAttachment={onSubmitAttachment}
                              attachmentdisplay={attachmentdisplay}
                              filesizemore={filesizemore}
                              radioattachmentvalue={radioattachmentvalue}
                              handleRadioChange={handleRadioChange}
                              displayattachmentradio={displayattachmentradio}
                              filesizeMessage={filesizeMessage}
                              isApplicationClosed={isApplicationClosed}
                              openModal2={openModal2}
                              id={item.id}
                              modalIsOpen2={() => {
                                if (
                                  props.workflowSteps[index - 1]?.status === 0
                                ) {
                                  return false;
                                } else {
                                  setfilesizemore(false);
                                }
                              }}
                            />
                          ) : null}
                          {/*  <img src={attachfile} title="Attachment" alt=""
                          style={{ margin: "1px" }}
                         />} */}

                          {/* {(permissionData?.includes(44)) && (props.workflowSteps[index - 1]?.status === 0) ? ( */}
                          {check1pointpermission(
                            325,
                            permissionData,
                            rolepermission
                          ) ? (
                            <AdmissionAppointmentModal
                              onSubmitAppointment={onSubmitAppointment}
                              selecteddate={selecteddate}
                              datetimelocal={datetimelocal}
                              setdate={setdate}
                              modalIsopen4={modalIsopen4}
                              isApplicationClosed={isApplicationClosed}
                              openmodalappointment={() => {
                                if (
                                  props.workflowSteps[index - 1]?.status === 0
                                ) {
                                  return false;
                                } else {
                                  openmodalappointment(item.id);
                                }
                              }}
                            />
                          ) : null}
                          {/* // <img src={calender} title="appointment" alt=""
                    //   style={{ margin: "1px" }}
                    // />} */}
                        </div>
                      </div>
                    </div>
                  </div>
                </AccordionSummary>
              </div>
              <div className="accordiondetails">
                <div>
                  {log?.map((p, index) => (
                    <div key={index + 1} className="logs">
                      <div>
                        <Typography
                          className="log_item1"
                          color="primary"
                          variant="body1"
                          style={{ lineHeight: "2" }}
                        >
                          {p.log.startsWith(" updated") ? (
                            <span>🕒 {p.log.substring(8)}- </span>
                          ) : (
                            <span>{p.log}- </span>
                          )}
                          {/* {p.log} -{" "} */}
                          <span style={{ fontSize: "10px" }}>
                            {p.editorName}
                          </span>
                        </Typography>
                      </div>
                      <div>
                        <Typography
                          variant="caption"
                          className="log_item2"
                          style={{ paddingRight: "10px" }}
                        >
                          <td>
                            {p.createdOn
                              ? moment(p.createdOn.split(".")[0]).format(
                                "DD-MMM-YY"
                              )
                              : null}
                          </td>
                        </Typography>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Accordion>
          </div>
        ))
      ) : (
        <Typography align="left">No WorkflowSteps Assigned</Typography>
      )}

      {props.workflowSteps?.length > 0 ? (
        <>
          <Row>
            <Col
              style={{ textAlign: "left", marginTop: "10px", display: "none" }}
            >
              {/* {buttonVisible && (
                <button
                  variant="outlined"
                  color="primary"
                  className={
                    isApplicationClosed ? "step_complete_css" : "refund-btn"
                  }
                  onClick={handleRefundSteps}
                  disabled={isApplicationClosed}
                >
                  Process Refund
                </button>
              )} */}
            </Col>
          </Row>
          <Row>
            <Col style={{ textAlign: "left" }}>
              <p style={{ marginTop: "15px" }}>
                {props.workflowSteps?.length} to {props.workflowSteps?.length}{" "}
                of {props.workflowSteps?.length} records
              </p>
            </Col>
          </Row>
        </>
      ) : null}
      <Row>
        <Col style={{ textAlign: "left" }}>
          <button
            variant="outlined"
            color="danger"
            onClick={handleCloseApplication}
            className={
              isApplicationClosed || updatedStatus == "Closed"
                ? "refund-btn"
                : "close_application_css"
            }
            style={{ marginInline: "7px", height: "45px" }}
          >
            {isApplicationClosed || updatedStatus == "Closed"
              ? "Reopen"
              : "Close Application"}
          </button>
        </Col>
      </Row>

      <div>
        <h3>Previous Workflow</h3>
        {props?.workflowStepsArray.length > 0
          ? props?.workflowStepsArray.map((steps, outerIndex) => (
            <div key={outerIndex} className="disablefulldiv">
              {isDisabled && <div className="disableOverlay"></div>}
              <hr />
              {console.log("2035 step array", steps)}

              {steps.map((item, index) => (
                <div key={index}>
                  {/* {!hideRefundSteps &&
                    item.workflowStepsName === "Refund Processing" ? (
                      <br />
                    ) : null} */}

                  <Accordion
                    // disabled={
                    //   props.workflowSteps[index - 1]?.status === 0 ? true : false
                    // }
                    square
                    expanded={expanded === `panel${index + 2}`}
                    onChange={() => {
                      if (
                        props.workflowSteps[index - 1]?.status != 0 &&
                        item.status == 0
                      ) {
                        handleChange(`panel${index + 1}`, item.id);
                      }
                    }}
                  >
                    <div className="accordion_radio">
                      {/* {item.status === 1 ? (
                               <CheckIcon
                                 className="positioning color"
                                 fontSize="small"
                                 style={{ marginLeft: "10px" }}
                               />
                             ) : (
                               <FiberManualRecordIcon
                                 className="positioning"
                                 style={{ marginLeft: "10px" }}
                                 fontSize="small"
                                 color="primary"
                               />
                             )} */}
                      <AccordionSummary
                        className="accordion_radio2 dummy3_accordion"
                        aria-controls={`panel${index + 1}d-header`}
                        id={`panel${index + 1}d-header`}
                      // style={{
                      //   display:
                      //     hideRefundSteps &&
                      //     (item.workflowStepsName === "Refund Processing" ||
                      //       item.workflowStepsName === "Refund Complete")
                      //       ? "none"
                      //       : "block",
                      // }}
                      >
                        <div className="accordion_name">
                          {!hideRefundSteps ? <br /> : null}
                          <Typography align="left">
                            {item.workflowStepsName}
                          </Typography>
                          {item.workflowStepsName ===
                            props?.getWorlflowCovertClientDetails
                              ?.workflowStepsName ? (
                            <Typography align="right">
                              (Convert to Client)
                            </Typography>
                          ) : null}

                          <div className="accordion_namebutton">
                            {/* {(permissionData?.includes(36)) && (props.workflowSteps[index - 1]?.status === 0) ? ( */}

                            <div className="d-flex justify-content-around">
                              {item.workflowStepsName ===
                                "Visa Decision Received" && imgGranted ? (
                                <div className="d-flex justify-content-around">
                                  <img
                                    src={visaGranted}
                                    style={{
                                      width: "50px",
                                      width: "50px",
                                      marginRight: "10px",
                                    }}
                                  />
                                </div>
                              ) : null}
                              {steps[index - 1]?.status != 0 &&
                                item.status == 0 ? (
                                <>
                                  {check1pointpermission(
                                    316,
                                    permissionData,
                                    rolepermission
                                  ) ? (
                                    <div className="d-flex justify-content-space">
                                      <button
                                        variant="outlined"
                                        color="primary"
                                        onClick={() =>
                                          handleStepCompletion(
                                            item.id,
                                            index,
                                            item.workflowStepsName
                                          )
                                        }
                                        className={
                                          isApplicationClosed
                                            ? "step_complete_css"
                                            : "refund-btn"
                                        }
                                        style={{ marginInline: "7px" }}
                                        disabled={isApplicationClosed}
                                      >
                                        Step Completed
                                      </button>
                                      <button
                                        variant="outlined"
                                        color="danger"
                                        onClick={handleCloseApplication}
                                        className={
                                          isApplicationClosed
                                            ? "refund-btn"
                                            : "close_application_css"
                                        }
                                        style={{ marginInline: "7px" }}
                                      >
                                        {isApplicationClosed
                                          ? "Reopen"
                                          : "Close Application"}
                                      </button>
                                    </div>
                                  ) : null}
                                </>
                              ) : null}
                              <div
                                className="mt-2"
                                style={
                                  isApplicationClosed
                                    ? { display: "none" }
                                    : null
                                }
                              >
                                {check1pointpermission(
                                  317,
                                  permissionData,
                                  rolepermission
                                ) ? (
                                  <AdmissionEmailModal
                                    openModal3={() => {
                                      if (
                                        props.workflowSteps[index - 1]
                                          ?.status === 0
                                      ) {
                                        return false;
                                      } else {
                                        openModal3();
                                        setemailworkflowstepid(item.id);
                                      }
                                    }}
                                    client_name={params.client_name}
                                    modalIsOpen3={modalIsOpen3}
                                    setIsOpen3={setIsOpen3}
                                    isApplicationClosed={isApplicationClosed}
                                    submit3={submit3}
                                    jwtToken={jwtToken}
                                    handle403error={handle403error}
                                    workloaddescriptionvalue={
                                      workloaddescriptionvalue
                                    }
                                    workloaddescriptionvalue2={
                                      workloaddescriptionvalue2
                                    }
                                    setworkloaddescriptionvalue={
                                      setworkloaddescriptionvalue
                                    }
                                    setworkloaddescriptionvalue2={
                                      setworkloaddescriptionvalue2
                                    }
                                  />
                                ) : null}
                                {/* <img src={email} title="Email" alt=""
                                       style={{ margin: "1px" }}
                                     />} */}

                                {/* {(permissionData?.includes(37)) && (props.workflowSteps[index - 1]?.status === 0) ? ( */}
                                {check1pointpermission(
                                  318,
                                  permissionData,
                                  rolepermission
                                ) ? (
                                  <AdmissionNoteModal
                                    submit={submit}
                                    workflowtyperedux={
                                      props.workflowtyperedux
                                    }
                                    closeModal={closeModal}
                                    notestitle={notestitle}
                                    setnotestitle={setnotestitle}
                                    proformadetails={proformadetails}
                                    template={template}
                                    settemplate={settemplate}
                                    setdescriptionState={setdescriptionState}
                                    setdescriptionState2={
                                      setdescriptionState2
                                    }
                                    isApplicationClosed={isApplicationClosed}
                                    modalIsOpen={() => {
                                      if (
                                        props.workflowSteps[index - 1]
                                          ?.status === 0
                                      ) {
                                        return false;
                                      } else {
                                        setloadermodal(false);
                                        openModal(
                                          item.id,
                                          item.workflowStepsName
                                        );
                                      }
                                    }}
                                  />
                                ) : null}
                                {/* <img src={notes} title="Notes" alt=""
                                       style={{ margin: "1px" }}
                                     />} */}

                                {/* {(permissionData?.includes(40)) && (props.workflowSteps[index - 1]?.status === 0) ? ( */}
                                {check1pointpermission(
                                  321,
                                  permissionData,
                                  rolepermission
                                ) ? (
                                  <AdmissionAttachmentModal
                                    chkfilesize={chkfilesize}
                                    onSubmitAttachment={onSubmitAttachment}
                                    attachmentdisplay={attachmentdisplay}
                                    filesizemore={filesizemore}
                                    radioattachmentvalue={
                                      radioattachmentvalue
                                    }
                                    handleRadioChange={handleRadioChange}
                                    displayattachmentradio={
                                      displayattachmentradio
                                    }
                                    filesizeMessage={filesizeMessage}
                                    isApplicationClosed={isApplicationClosed}
                                    modalIsOpen2={() => {
                                      if (
                                        props.workflowSteps[index - 1]
                                          ?.status === 0
                                      ) {
                                        return false;
                                      } else {
                                        setfilesizemore(false);
                                        openModal2(item.id);
                                      }
                                    }}
                                  />
                                ) : null}
                                {/*  <img src={attachfile} title="Attachment" alt=""
                                       style={{ margin: "1px" }}
                                      />} */}

                                {/* {(permissionData?.includes(44)) && (props.workflowSteps[index - 1]?.status === 0) ? ( */}
                                {check1pointpermission(
                                  325,
                                  permissionData,
                                  rolepermission
                                ) ? (
                                  <AdmissionAppointmentModal
                                    onSubmitAppointment={onSubmitAppointment}
                                    selecteddate={selecteddate}
                                    datetimelocal={datetimelocal}
                                    setdate={setdate}
                                    modalIsopen4={modalIsopen4}
                                    isApplicationClosed={isApplicationClosed}
                                    openmodalappointment={() => {
                                      if (
                                        props.workflowSteps[index - 1]
                                          ?.status === 0
                                      ) {
                                        return false;
                                      } else {
                                        openmodalappointment(item.id);
                                      }
                                    }}
                                  />
                                ) : null}
                                {/* // <img src={calender} title="appointment" alt=""
                                 //   style={{ margin: "1px" }}
                                 // />} */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </AccordionSummary>
                    </div>
                    {/* <div className="accordiondetails">
                      <div>
                        {log?.map((p, index) => (
                          <div key={index + 1} className="logs">
                            <div>
                              <Typography
                                className="log_item1"
                                color="primary"
                                variant="body1"
                                style={{ lineHeight: "2" }}
                              >
                                {p.log.startsWith(" updated") ? (
                                  <span>🕒 {p.log.substring(8)}- </span>
                                ) : (
                                  <span>{p.log}- </span>
                                )}
                                <span style={{ fontSize: "10px" }}>
                                  {p.editorName}
                                </span>
                              </Typography>
                            </div>
                            <div>
                              <Typography
                                variant="caption"
                                className="log_item2"
                                style={{ paddingRight: "10px" }}
                              >
                                <td>
                                  {p.createdOn
                                    ? moment(
                                      p.createdOn.split(".")[0]
                                    ).format("DD-MMM-YY")
                                    : null}
                                </td>
                              </Typography>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div> */}
                  </Accordion>
                </div>
              ))}
            </div>
          ))
          : null}
      </div>

      <br />
      <br />





      {/* <div
        className="Refund-steps"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          <p style={{ fontSize: "20px", fontWeight: "600" }}>REFUND PROCESS</p>
        </div>
        {!showRefundProcess && (
          <div>
            <button className="refund-btn" onClick={handleRefundClick}>
              Process Refund
            </button>
          </div>
        )}
      </div>

      {refundWorkFlowSteps !== null && showRefundProcess
        ? refundWorkFlowSteps?.map((item, index) => (
            <div key={index + 1}>
              <Accordion
                // disabled={
                //   props.workflowSteps[index - 1]?.status === 0 ? true : false
                // }
                square
                expanded={expanded === `panel${index + 1}`}
                onChange={() => {
                  if (
                    refundWorkFlowSteps[index - 1]?.status != 0 &&
                    item.status == 0
                  ) {
                    handleChange(`panel${index + 1}`, item.workFlowStepsId);
                  }
                }}
              >
                <div className="accordion_radio">
                  {/* {item.status === 1 ? ( */}
      {/* <CheckIcon
                    className="positioning color"
                    fontSize="small"
                    style={{ marginLeft: "10px" }}
                  />
                ) : (
                  <FiberManualRecordIcon
                    className="positioning"
                    style={{ marginLeft: "10px" }}
                    fontSize="small"
                    color="primary"
                  />
                )} */}
      {/* <AccordionSummary
                    className="accordion_radio2 dummy3_accordion"
                    aria-controls={`panel${index + 1}d-header`}
                    id={`panel${index + 1}d-header`}
                  >
                    <div className="accordion_name">
                      <Typography align="left">
                        {item.workflowStepsName}
                      </Typography>
                      <div className="accordion_namebutton">  */}
      {/* {(permissionData?.includes(36)) && (props.workflowSteps[index - 1]?.status === 0) ? ( */}
      {/* {check1pointpermission(
                          317,
                          permissionData,
                          rolepermission
                        ) ? (
                          <AdmissionEmailModal
                            openModal3={() => {
                              if (
                                refundWorkFlowSteps[index - 1]?.status === 0
                              ) {
                                return false;
                              } else {
                                openModal3();
                                setemailworkflowstepid(item.workFlowStepsId);
                              }
                            }}
                            client_name={params.client_name}
                            modalIsOpen3={modalIsOpen3}
                            setIsOpen3={setIsOpen3}
                            submit3={submit3}
                          />
                        ) : null} */}
      {/* <img src={email} title="Email" alt=""
                          style={{ margin: "1px" }}
                        />} */}

      {/* {(permissionData?.includes(37)) && (props.workflowSteps[index - 1]?.status === 0) ? ( */}
      {/* {check1pointpermission(
                          318,
                          permissionData,
                          rolepermission
                        ) ? (
                          <AdmissionNoteModal
                            submit={submit}
                            workflowtyperedux={props.workflowtyperedux}
                            closeModal={closeModal}
                            notestitle={notestitle}
                            setnotestitle={setnotestitle}
                            proformadetails={proformadetails}
                            template={template}
                            setdescriptionState={setdescriptionState}
                            setdescriptionState2={setdescriptionState2}
                            modalIsOpen={() => {
                              if (
                                refundWorkFlowSteps[index - 1]?.status === 0
                              ) {
                                return false;
                              } else {
                                setloadermodal(false);
                                getproforma();
                                openModal(
                                  item.workFlowStepsId,
                                  item.workflowStepsName
                                );
                              }
                            }}
                          />
                        ) : null} */}
      {/* <img src={notes} title="Notes" alt=""
                          style={{ margin: "1px" }}
                        />} */}

      {/* {(permissionData?.includes(40)) && (props.workflowSteps[index - 1]?.status === 0) ? ( */}
      {/* {check1pointpermission(
                          321,
                          permissionData,
                          rolepermission
                        ) ? (
                          <AdmissionAttachmentModal
                            chkfilesize={chkfilesize}
                            onSubmitAttachment={onSubmitAttachment}
                            attachmentdisplay={attachmentdisplay}
                            filesizemore={filesizemore}
                            radioattachmentvalue={radioattachmentvalue}
                            handleRadioChange={handleRadioChange}
                            displayattachmentradio={displayattachmentradio}
                            modalIsOpen2={() => {
                              if (
                                refundWorkFlowSteps[index - 1]?.status === 0
                              ) {
                                return false;
                              } else {
                                setfilesizemore(false);
                                openModal2(item.workFlowStepsId);
                              }
                            }}
                          />
                        ) : null} */}
      {/*  <img src={attachfile} title="Attachment" alt=""
                          style={{ margin: "1px" }}
                         />} */}

      {/* {(permissionData?.includes(44)) && (props.workflowSteps[index - 1]?.status === 0) ? ( */}
      {/* {check1pointpermission(
                          325,
                          permissionData,
                          rolepermission
                        ) ? (
                          <AdmissionAppointmentModal
                            onSubmitAppointment={onSubmitAppointment}
                            selecteddate={selecteddate}
                            datetimelocal={datetimelocal}
                            setdate={setdate}
                            modalIsopen4={modalIsopen4}
                            openmodalappointment={() => {
                              if (
                                refundWorkFlowSteps[index - 1]?.status === 0
                              ) {
                                return false;
                              } else {
                                openmodalappointment(item.workFlowStepsId);
                              }
                            }}
                          />
                        ) : null} */}
      {/* // <img src={calender} title="appointment" alt=""
                    //   style={{ margin: "1px" }}
                    // />} */}

      {/* {refundWorkFlowSteps[index - 1]?.status != 0 &&
                        item.status == 0 ? (
                          <>
                            {check1pointpermission(
                              316,
                              permissionData,
                              rolepermission
                            ) ? (
                              <button
                                variant="outlined"
                                color="primary"
                                onClick={() =>
                                  createLog(item.workFlowStepsId, index)
                                }
                                className="step_complete_css"
                                // style={{ marginTop: "10px", marginBottom: "10px" }}
                              >
                                Step Completed
                              </button>
                            ) : null}
                          </>
                        ) : null}
                      </div>
                    </div>
                  </AccordionSummary>
                </div> */}
      {/* <div className="accordiondetails">
                <div>
                  {log?.map((p, index) => (
                    <div key={index + 1} className="logs">
                      <div>
                        <Typography
                          className="log_item1"
                          color="primary"
                          variant="body1"
                          style={{ lineHeight: "2" }}
                        >
                          {p.log} -{" "}
                          <span style={{ fontSize: "10px" }}>
                            {p.editorName}
                          </span>
                        </Typography>
                      </div>
                      <div>
                        <Typography variant="caption" className="log_item2">
                          <td>
                            {p.createdOn
                              ? moment(p.createdOn.split(".")[0]).format(
                                  "DD-MMM-YY"
                                )
                              : null}
                          </td>
                        </Typography>
                      </div>
                    </div>
                  ))}
                </div>
              </div> */}
      {/* </Accordion>
            </div>
          ))
        : null} */}
    </div>
  );
};

export default EnquiryWorkflowStepDetails;
