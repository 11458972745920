import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Card,
  CardBody,
  CardSubtitle,
  CardText,
  Col,
  Row,
  Button,
  Table,
} from "reactstrap";
import moment from "moment";
import axios from "axios";
import base_url from "../../api/bootapi";
import { ToastContainer, toast } from "react-toastify";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import Select from "react-select";
import Modal from "react-modal";
import "../css/Responsive.css";
import {
  getworkflowType,
  setdashboardcheck,
  selectUser,
  gettabvalue,
  logout,
} from "../features/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectdashboard } from "../features/dashboardSlice";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { useStyles, customStyles } from "../../Utilities/CSSUtilities";
import callsaveauditapi from "../../services/auditservice";
import { checkworkloadpermission } from "../Permission/permission";
import sort from "./../images/sort.svg";
import MigrationArchive from "./../Modal/Masters/MigrationArchive";
import { check1pointpermission } from "./../Permission/permission";
import { CSVLink, CSVDownload } from "react-csv";
import jsPDF from "jspdf";
import MigrationViewMoreModal from "./../Modal/Masters/MigrationViewMoreModal";

function MigrationWorkFlowLists(props) {
  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  const user = useSelector(selectUser);
  const isw_user_role = localStorage.getItem("isw_user_role");
  const dispatch = useDispatch();
  const [deleteEnquiry, setDeleteEnquiry] = useState(null);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalIsOpen2, setIsOpen2] = React.useState(false);
  const [backdropopen, setbackdropopen] = useState(false);
  const navigate = useNavigate();
  const [sortState, setsortState] = useState("enquiryId");
  const [direction, setdirection] = useState("DESC");
  const dashboardvalue = useSelector(selectdashboard);
  const [rows, setrows] = React.useState(50);
  const [pageno, setpageno] = useState(0);
  const [enquiry, setEnquiry] = useState(null);
  const [display1, setdisplay] = useState("inline");
  const [paginationpageno, setpaginationpageno] = useState(1);
  const ipaddress = localStorage.getItem("AdminIpAddress");
  const isw_user_name = localStorage.getItem("isw_user_name");
  const isw_adminId = localStorage.getItem("isw_adminId");
  const isw_user_email = localStorage.getItem("isw_user_email");
  const [deletedUser, setdeletedUser] = useState();
  const [datefilter, setdatefilter] = useState();
  const [visaexpirydatefilter, setvisaexpirydatefilter] = useState();
  const [followupdatefilter, setfollowupdatefilter] = useState();
  const [deadlinedatefilter, setdeadlinedatefilter] = useState();
  const [totalUser, settotalUser] = useState();
  const [countnumber, setcountnumber] = useState();
  const [numberofElements, setnumberofElements] = useState();
  const [allVisaType, setAllVisaType] = useState();
  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);
  const csvLink = useRef();
  const [dataForDownload, setDataForDownload] = useState([]);
  const [bDownloadReady, setDownloadReady] = useState(false);
  const [selectedOffice, setselectedOffice] = useState(
    Number(user?.isw_user_role) === 1 || Number(user?.isw_user_role) === 6
      ? "all"
      : user?.isw_available_Office[0]?.officeId
  );
  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        navigate("/");
      }
    });
    getPermissions();
  }, []);

  useEffect(() => {
    if (localStorage.getItem("migrationWorkflowPageNo")) {
      let currentPage = localStorage.getItem("migrationWorkflowPageNo");
      setpageno(currentPage);
      setpaginationpageno(Number(currentPage) + 1);
      getallEnquiry(currentPage);
    } else {
      setpageno(0);
      setpaginationpageno(1);
      getallEnquiry(0);
    }
  }, [rows]);

  useEffect(() => {
    let width = document.querySelector("table")?.offsetWidth;
    if (document.querySelector(".scroller")) {
      document.querySelector(".scroller").style.width = `${width}px`;
    }
  }, [enquiry]);

  let scrolling = false;
  const scrolldiv = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow .table-responsive");
    table.scrollTo(scroller.scrollLeft, 0);
  };

  const scrolltable = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow  .table-responsive");
    scroller.scrollTo(table.scrollLeft, 0);
  };

  const handleRows = (event) => {
    setrows(event.target.value);
  };
  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);

    if (check1pointpermission(402, permission_obj, resource_obj)) {
      dispatch(setdashboardcheck(8));
      getCurrentStage(selectedOffice);
      getallVisa();
      getConsltant();
    } else {
      navigate("/errorPage");
    }
  };

  useEffect(() => {
    if (csvLink && csvLink.current && bDownloadReady) {
      csvLink.current.link.click();
      setDownloadReady(false);
    }
  }, [bDownloadReady]);

  const handleAction = (actionType) => {
    const office_obj = [];
    for (let i = 0; i < user?.isw_available_Office?.length; i++) {
      const element = user?.isw_available_Office?.[i];
      //console.log(element);
      office_obj.push(element);
    }
    let office_id = Array.prototype.map
      .call(office_obj, function (item) {
        return item.officeId;
      })
      .join(",");
    //console.log(office_id);
    setallofficeid(office_id);
    //console.log(office_id);
    let sorting;
    if (sortState == "createdDate") {
      sorting = "created_date";
    } else if (sortState == "clientUsername") {
      sorting = "client_username";
    } else if (sortState == "visaType") {
      sorting = "visa_type";
    } else if (sortState == "visaExpiryDate") {
      sorting = "visa_expiry_date";
    } else if (sortState == "enquirySource") {
      sorting = "enquiry_source";
    } else if (sortState == "enquiryStatus") {
      sorting = "enquiry_status";
    } else if (sortState == "currentStage") {
      sorting = "current_stage";
    } else if (sortState == "institutionName") {
      sorting = "institution_name";
    } else if (sortState == "consultantName") {
      sorting = "consultant_name";
    } else if (sortState == "followUpDate") {
      sorting = "follow_up_date";
    } else if (sortState == "deadLineDate") {
      sorting = "dead_line_date";
    } else if (sortState == "remarks") {
      sorting = "remarks";
    } else {
      sorting = "client_id";
    }

    if (assigneeState) {
      //console.log("coming--->if line NO 1450");
      axios
        .get(`${base_url.api2}/getEnquiryByWorkflowTypeReports`, {
          headers: {
            Authorization: jwtToken,
            workflowType: "Migration",
            columnName: "enquiry_id",
            assigneeId: selectedAssigneeData,
            direction: direction,
          },
        })
        .then((response) => {
          setbackdropopen(true);
          setDataForDownload(response.data.data);
          setDownloadReady(true);
          setbackdropopen(false);
        })
        .catch((error) => {
          setDataForDownload();
          setDownloadReady(true);
          setbackdropopen(false);
          handle403error(error);
        });
    } else {
      if (isw_user_role == 1 || isw_user_role == 6) {
        //console.log("coming--->else line NO 1496");
        axios
          .get(`${base_url.api2}/getEnquiryByWorkflowTypeReports`, {
            headers: {
              Authorization: jwtToken,
              workflowType: "Migration",
              columnName: "enquiry_id",
              direction: direction,
            },
          })
          .then((response) => {
            setbackdropopen(true);
            setDataForDownload(response.data.data);
            setDownloadReady(true);
            setbackdropopen(false);
          })
          .catch((error) => {
            setDataForDownload();
            setDownloadReady(true);
            setbackdropopen(false);
            handle403error(error);
          });
      } else {
        //console.log("coming--->else line NO 1540");
        axios
          .get(`${base_url.api2}/getEnquiryByWorkflowTypeReports`, {
            headers: {
              Authorization: jwtToken,
              workflowType: "Migration",
              officeId: office_id,
              columnName: "enquiry_id",
              direction: direction,
            },
          })
          .then((response) => {
            //console.log(response.data.data);
            setbackdropopen(true);
            setDataForDownload(response.data.data);
            setDownloadReady(true);
            setbackdropopen(false);
          })
          .catch((error) => {
            setDataForDownload();
            setDownloadReady(true);
            setbackdropopen(false);
            handle403error(error);
          });
      }
    }
  };

  const [pdfRow, setPdfRow] = useState([]);

  const exportPdf = () => {
    const office_obj = [];
    for (let i = 0; i < user?.isw_available_Office?.length; i++) {
      const element = user?.isw_available_Office?.[i];
      //console.log(element);
      office_obj.push(element);
    }
    let office_id = Array.prototype.map
      .call(office_obj, function (item) {
        return item.officeId;
      })
      .join(",");
    //console.log(office_id);
    setallofficeid(office_id);
    //console.log(office_id);
    let sorting;
    if (sortState == "createdDate") {
      sorting = "created_date";
    } else if (sortState == "clientUsername") {
      sorting = "client_username";
    } else if (sortState == "visaType") {
      sorting = "visa_type";
    } else if (sortState == "visaExpiryDate") {
      sorting = "visa_expiry_date";
    } else if (sortState == "enquirySource") {
      sorting = "enquiry_source";
    } else if (sortState == "enquiryStatus") {
      sorting = "enquiry_status";
    } else if (sortState == "currentStage") {
      sorting = "current_stage";
    } else if (sortState == "institutionName") {
      sorting = "institution_name";
    } else if (sortState == "consultantName") {
      sorting = "consultant_name";
    } else if (sortState == "followUpDate") {
      sorting = "follow_up_date";
    } else if (sortState == "deadLineDate") {
      sorting = "dead_line_date";
    } else if (sortState == "remarks") {
      sorting = "remarks";
    } else {
      sorting = "client_id";
    }

    if (assigneeState) {
      //console.log("coming--->if line NO 1450");
      axios
        .get(`${base_url.api2}/getEnquiryByWorkflowTypeReports`, {
          headers: {
            Authorization: jwtToken,
            workflowType: "Migration",
            columnName: "enquiry_id",
            assigneeId: selectedAssigneeData,
            direction: direction,
          },
        })
        .then((response) => {
          setPdfRow(response.data.data);
          const doc = new jsPDF();
          // autoTable(doc, { html: '#myTable' })
          var col = [
            "Client Name",
            "Source",
            "Status",
            "Current Stage",
            // "Institution Name",
            // "Course",
            "Counsellor",
          ];
          var rows = [];
          response.data.data?.forEach((element) => {
            var temp = [
              element.clientName + "\n" + element.clientUsername,
              element.enquirySource,
              element.enquiryStatus,
              element.currentStage,
              // element.institutionName,
              // element.courseName,
              element.consultantName,
            ];
            rows.push(temp);
          });
          doc.autoTable(col, rows, { pageBreak: "auto" });
          // doc.autoTable(col, rows, { pageBreak: 'auto' });
          doc.save("MigrationWorkflow_list.pdf");
          setbackdropopen(false);
        })
        .catch((error) => {
          setPdfRow();
          setbackdropopen(false);
          handle403error(error);
        });
    } else {
      if (isw_user_role == 1 || isw_user_role == 6) {
        //console.log("coming--->else line NO 1496");
        axios
          .get(`${base_url.api2}/getEnquiryByWorkflowTypeReports`, {
            headers: {
              Authorization: jwtToken,
              workflowType: "Migration",
              columnName: "enquiry_id",
              direction: direction,
            },
          })
          .then((response) => {
            setPdfRow(response.data.data);
            const doc = new jsPDF();
            // autoTable(doc, { html: '#myTable' })
            var col = [
              "Client Name",
              "Source",
              "Status",
              "Current Stage",
              // "Institution Name",
              // "Course",
              "Counsellor",
            ];
            var rows = [];
            response.data.data?.forEach((element) => {
              var temp = [
                element.clientName + "\n" + element.clientUsername,
                element.enquirySource,
                element.enquiryStatus,
                element.currentStage,
                // element.institutionName,
                // element.courseName,
                element.consultantName,
              ];
              rows.push(temp);
            });
            doc.autoTable(col, rows, { pageBreak: "auto" });
            // doc.autoTable(col, rows, { pageBreak: 'auto' });
            doc.save("MigrationWorkflow_list.pdf");
            setbackdropopen(false);
          })
          .catch((error) => {
            setPdfRow();
            setbackdropopen(false);
            handle403error(error);
          });
      } else {
        //console.log("coming--->else line NO 1540");
        axios
          .get(`${base_url.api2}/getEnquiryByWorkflowTypeReports`, {
            headers: {
              Authorization: jwtToken,
              workflowType: "Migration",
              officeId: office_id,
              columnName: "enquiry_id",
              direction: direction,
            },
          })
          .then((response) => {
            setPdfRow(response.data.data);
            const doc = new jsPDF();
            // autoTable(doc, { html: '#myTable' })
            var col = [
              "Client Name",
              "Source",
              "Status",
              "Current Stage",
              // "Institution Name",
              // "Course",
              "Counsellor",
            ];
            var rows = [];
            response.data.data?.forEach((element) => {
              var temp = [
                element.clientName + "\n" + element.clientUsername,
                element.enquirySource,
                element.enquiryStatus,
                element.currentStage,
                // element.institutionName,
                // element.courseName,
                element.consultantName,
              ];
              rows.push(temp);
            });
            doc.autoTable(col, rows, { pageBreak: "auto" });
            // doc.autoTable(col, rows, { pageBreak: 'auto' });
            doc.save("MigrationWorkflow_list.pdf");
            setbackdropopen(false);
          })
          .catch((error) => {
            setPdfRow();
            setbackdropopen(false);
            handle403error(error);
          });
      }
    }
  };

  const [assigneeData, setAssigneeData] = useState();
  const getConsltant = (p) => {
    axios
      .get(`${base_url.api2}/getUsersByRoleId`, {
        headers: {
          Authorization: jwtToken,
          roleId: 4,
          direction: "ASC",
          columnName: "firstName",
          status: 1,
        },
      })
      .then((res) => {
        let apiData = res.data.data;
        const consultant_obj = [];
        for (let i = 0; i < apiData.length; i++) {
          let data = {
            value: apiData[i].id,
            label: apiData[i].first_name + " " + apiData[i].last_name,
          };
          consultant_obj.push(data);
        }
        setAssigneeData(consultant_obj);
      })
      .catch((error) => {
        setAssigneeData();
        handle403error(error);
      });
  };

  const getallVisa = () => {
    axios
      .get(`${base_url.api1}/getAllVisaType`)
      .then((res) => {
        setAllVisaType(res.data.data);
      })
      .catch((error) => {
        setAllVisaType();
        handle403error(error);
      });
  };

  const [getpageno, setgetpageno] = useState();
  const handlePage = (event, value) => {
    setpaginationpageno(value);
    setpageno(value - 1);
    if (filterpagination == "CurrentStage") {
      handleChangeCurrentStagePage(value - 1);
    } else if (filterpagination == "visatype") {
      handleChangevisatypePage(value - 1);
    } else if (filterpagination == "ChangeIntake") {
      handleChangeIntakePage(value - 1);
    } else if (assigneeState) {
      onSelectWorkloadPage(value - 1);
    } else {
      getallEnquiry(value - 1);
    }
  };

  const [allCurrentStage, setAllCurrentStage] = useState();
  const getCurrentStage = (selectedOffice) => {
    if (selectedOffice === "all") {
      axios
        .get(`${base_url.api2}/getByWorkflowTypeBasedOnCurrentStage`, {
          headers: {
            Authorization: jwtToken,
            workflowType: "Migration",
          },
        })
        .then((response) => {
          setAllCurrentStage(response.data.data);
        })
        .catch((error) => {
          setAllCurrentStage();
          handle403error(error);
        });
    } else {
      axios
        .get(
          `${base_url.api2}/getByWorkflowTypeAndOfficeIdBasedOnCurrentStage`,
          {
            headers: {
              Authorization: jwtToken,
              workflowType: "Migration",
              officeId: Number(selectedOffice),
            },
          }
        )
        .then((response) => {
          setAllCurrentStage(response.data.data);
        })
        .catch((error) => {
          setAllCurrentStage();
          handle403error(error);
        });
    }
  };

  const getallEnquiry = (value1) => {
    setbackdropopen(true);
    if (
      Number(user?.isw_user_role) === 1 ||
      Number(user?.isw_user_role) === 6
    ) {
      if (value1 == parseInt(value1)) {
        //console.log("coming here");
        globalgetallenquiry(value1);
      } else {
        //console.log("coming here");
        globalgetallenquiry(pageno);
      }
    } else {
      const office_obj = [];
      for (let i = 0; i < user?.isw_available_Office?.length; i++) {
        const element = user?.isw_available_Office?.[i];
        //console.log(element);
        office_obj.push(element);
      }
      let office_id = Array.prototype.map
        .call(office_obj, function (item) {
          return item.officeId;
        })
        .join(",");
      if (value1 == parseInt(value1)) {
        //console.log("coming here");
        globalgetallenquiryofficeid(value1, sortState, office_id);
      } else {
        //console.log("coming here");
        globalgetallenquiryofficeid(pageno, sortState, office_id);
      }
    }
  };
  //console.log(enquiry);

  const deleteenquiry = (elem) => {
    //console.log("Page", pageno);
    axios
      .delete(`${base_url.api2}/deleteEnquiryById`, {
        headers: {
          Authorization: jwtToken,
          enquiryId: deleteEnquiry,
        },
      })
      .then((response) => {
        toast.success(response.data.data.message);
        let logs = isw_user_name + " Deleted Enquiry of " + deletedUser;
        callsaveauditapi(logs, dispatch, navigate);
        getallEnquiry(pageno);
        setIsOpen(false);
      })
      .catch((error) => {
        toast.error(JSON.stringify(error.response.data.message));
        handle403error(error);
      });
  };

  const openModal = (elem, p) => {
    //console.log(elem.target.id);
    setdeletedUser(p.clientUsername);
    setDeleteEnquiry(elem.target.id);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen2(false);
    setIsOpen(false);
  };

  const classes = useStyles();

  const viewWorkflow = (p, e) => {
    localStorage.setItem("migrationWorkflowPageNo", pageno);
    localStorage.setItem("selected_client_username", p.clientUsername);
    localStorage.setItem("selected_client_id", p.clientId);
    localStorage.setItem("prev_path2", "/migration-enquiry");
    localStorage.setItem("prev_path", "/migration-enquiry");
    dispatch(getworkflowType(p.workflowType));
    dispatch(gettabvalue(1));
    if (!p.workflowId) {
      navigate(
        "/view-enquiry-workflowList/" + p.clientId + "/" + p.clientUsername
      );
    } else {
      navigate(
        "/view-enquiry-workflow/" +
          p.clientId +
          "/" +
          p.enquiryId +
          "/" +
          p.clientUsername
      );
    }
  };

  const [searchdata, setsearch] = useState();
  const [searchinput, setsearchinput] = useState();
  const handlesearch = (e, value) => {
    setdisplay("none");
    setpageno(value - 1);
    setpaginationpageno(value);
    axios
      .get(`${base_url.api2}/getEnquiryByWorkflowType`, {
        headers: {
          Authorization: jwtToken,
          query: searchinput,
          page: Number(value - 1),
          size: rows,
          workflowType: "Migration",
        },
      })
      .then((res) => {
        //console.log(res.data.data);
        setgetpageno(res.data.data.totalPages);
        setEnquiry(res.data.data.content);
        setcountnumber(res.data.data.number);
        setsearch(true);
      })
      .catch((error) => {
        setgetpageno(0);
        setEnquiry();
        setcountnumber(0);
        setsearch(true);
        toast.error(JSON.stringify(error.response.data.message));
        handle403error(error);
      });
  };
  const submit4 = (e) => {
    setbackdropopen(true);
    let srchdata = e.target[0].value;
    setsearchinput(srchdata);
    setpageno(0);
    axios
      .get(`${base_url.api2}/getEnquiryByWorkflowType`, {
        headers: {
          Authorization: jwtToken,
          query: srchdata,
          page: Number(0),
          size: rows,
          workflowType: "Migration",
        },
      })
      .then((res) => {
        setgetpageno(res.data.data.totalPages);
        setEnquiry(res.data.data.content);
        settotalUser(res.data.data.totalElements);
        setcountnumber(res.data.data.number);
        setnumberofElements(res.data.data.numberOfElements);
        setrows(res.data.data.size);
        setbackdropopen(false);
        setsearch(true);
      })
      .catch((error) => {
        handle403error(error);
        setgetpageno(0);
        setEnquiry();
        settotalUser(0);
        setcountnumber(0);
        setnumberofElements(0);
        setrows(0);
        setbackdropopen(false);
        setsearch(true);
        if (error.response) {
          toast.error(JSON.stringify(error.response.data.message));
        } else {
          toast.error(JSON.stringify("Something Went Wrong"));
        }
      });
  };
  const sortClient = (e) => {
    setsortState(e.target.id);
    if (direction == "DESC") {
      setdirection("ASC");
    } else if (direction == "ASC") {
      setdirection("DESC");
    }
    // getallEnquiry(pageno);
    if (isw_user_role == 1 || isw_user_role == 6) {
      globalgetallenquiry(pageno, allofficeid, e.target.id);
    } else {
      globalgetallenquiryofficeid(pageno, e.target.id);
    }
  };

  // const [selectedOfficeId, setSelectedOfficeId] = useState(
  //   user?.isw_available_Office[0]?.officeName
  // );
  const [officeSelected, setofficeSelected] = useState(false);
  const [allofficeid, setallofficeid] = useState();

  const handleChangeOffice = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementNameId = optionElement.getAttribute("id");
    const optionElementName = optionElement.getAttribute("value");
    setselectedOffice(optionElementName);
    // setSelectedOfficeId(optionElementNameId);
    setofficeSelected(true);
    setfilterpagination();
    getCurrentStage(optionElementName);
    if (optionElementName == "all") {
      // globalgetallenquiry(pageno);
      const office_obj = [];
      for (let i = 0; i < user?.isw_available_Office?.length; i++) {
        const element = user?.isw_available_Office?.[i];
        //console.log(element);
        office_obj.push(element);
      }
      let office_id = Array.prototype.map
        .call(office_obj, function (item) {
          return item.officeId;
        })
        .join(",");
      //console.log(office_id);
      setallofficeid(office_id);
      //globalgetallenquiry(pageno);
      if (assigneeState) {
        let headData = {
          Authorization: jwtToken,
          workflowType: "Migration",
          columnName: sortState,
          officeId: Array.prototype.map
            .call(office_obj, function (item) {
              return item.officeId;
            })
            .join(","),
          assigneeId: selectedAssigneeData,
          direction: direction,
          page: pageno,
          size: rows,
        };
        //console.log(headData);

        globalgetallenquiry(pageno, office_id);
      } else {
        let headData = {
          Authorization: jwtToken,
          workflowType: "Migration",
          officeId: Array.prototype.map
            .call(office_obj, function (item) {
              return item.officeId;
            })
            .join(","),
          columnName: sortState,
          direction: direction,
          page: pageno,
          size: rows,
        };
        //console.log(headData);
        globalgetallenquiry(pageno, office_id);
      }
    } else {
      globalgetallenquiryofficeid(pageno, sortState, optionElementName);
    }
  };

  //Filter based Pagination-----------------------------------
  const [filterpagination, setfilterpagination] = useState(null);
  const [filterpaginationOfficedata, setfilterpaginationdata] = useState();
  const [selectedCurrentStage, setselectedCurrentStage] = useState();
  const handleChangeCurrentStage = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("id");
    const optionElementName = optionElement.getAttribute("value");
    setselectedCurrentStage(optionElementName);
    setfilterpagination("CurrentStage");
    setofficeSelected(true);
    //console.log(optionElementName);
    if (selectedOffice === "all") {
      if (optionElementName == "") {
        setfilterpaginationdata(false);
        globalgetallenquiry(pageno);
      } else {
        setfilterpaginationdata(true);
        globalgetenquirybycurrentstage(optionElementName, 0);
      }
    } else {
      if (optionElementName == "") {
        setfilterpaginationdata(false);
        globalgetallenquiryofficeid(pageno);
      } else {
        setfilterpaginationdata(true);
        globalgetenquirybycurrentstageofficeid(optionElementName, 0);
      }
    }
  };

  const handleChangeApplicationStatus = (e) => {
    setbackdropopen(true);
    axios
      .get(`${base_url.api2}/getEnquiryByWorkflowType`, {
        headers: {
          Authorization: jwtToken,
          workflowType: "Migration",
          columnName: sortState,
          enquiryStatus: e.target.value,
          direction: direction,
          page: pageno,
          size: rows,
        },
      })
      .then((response) => {
        setgetpageno(response.data.data.totalPages);
        settotalUser(response.data.data.totalElements);
        setcountnumber(response.data.data.number);
        setnumberofElements(response.data.data.numberOfElements);
        setEnquiry(response.data.data.content);
        setdatefilter(false);
        setsearch(false);
        setbackdropopen(false);
        try {
          document.getElementById("myForm").reset();
        } catch (error) {
          //console.log(error);
        }
      })
      .catch((error) => {
        setbackdropopen(false);
        handle403error(error);
      });
  };

  const [selectedvisatype, setselectedvisatype] = useState();
  const handlechangevisatype = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementName = optionElement.getAttribute("value");
    setselectedvisatype(optionElementName);
    setfilterpagination("visatype");
    setofficeSelected(true);
    if (selectedOffice === "all") {
      if (optionElementName == "") {
        globalgetallenquiry(pageno);
      } else {
        globalgetenquirybyvisatype(optionElementName, 0);
      }
    } else {
      if (optionElementName == "") {
        globalgetallenquiryofficeid(pageno);
      } else {
        globalgetenquirybyvisatypeofficeid(optionElementName, 0);
      }
    }
  };
  const handleChangeCurrentStagePage = (value) => {
    if (selectedOffice === "all") {
      globalgetenquirybycurrentstage(selectedCurrentStage, value);
    } else {
      globalgetenquirybycurrentstageofficeid(selectedCurrentStage, value);
    }
  };
  const handleChangevisatypePage = (value) => {
    if (selectedOffice === "all") {
      globalgetenquirybyvisatype(selectedvisatype, value);
    } else {
      globalgetenquirybyvisatypeofficeid(selectedvisatype, value);
    }
  };

  const [selectedIntake, setselectedIntake] = useState();
  const handleChangeIntake = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("id");
    const optionElementName = optionElement.getAttribute("value");
    setselectedIntake(optionElementName);
    setfilterpagination("ChangeIntake");
    setofficeSelected(true);
    if (isw_user_role == 1 || isw_user_role == 6) {
      if (optionElementName == "") {
        setfilterpaginationdata(false);
        globalgetallenquiry(pageno);
      } else {
        setfilterpaginationdata(true);
        globalgetenquirybyintake(optionElementName, 0);
      }
    } else {
      if (optionElementName == "") {
        setfilterpaginationdata(false);
        globalgetallenquiryofficeid(pageno, sortState, selectedOffice);
      } else {
        setfilterpaginationdata(true);
        globalgetenquirybyintakeofficeid(optionElementName, 0);
      }
    }
  };

  const handleChangeIntakePage = (value) => {
    if (selectedOffice === "all") {
      globalgetenquirybyintake(selectedIntake, value);
    } else {
      globalgetenquirybyintakeofficeid(selectedIntake, value);
    }
  };
  const handleEvent = (event, picker) => {
    //console.log(picker.startDate);
  };

  const handleCallback = (start, end, label) => {
    //console.log(start, end, label);
  };

  const [datesrange, setdatesrange] = useState();
  const [datevalue, setdatevalue] = useState("Click to open");
  const handleApply = (event, picker) => {
    setbackdropopen(true);
    setdatevalue(
      `${picker.startDate.format("DD-MM-YYYY")} to ${picker.endDate.format(
        "DD-MM-YYYY"
      )}`
    );
    setpageno(0);
    setdatefilter(true);
    //console.log(event, picker);
    let data = {
      createdStartDate: picker.startDate.format("YYYY-MM-DD"),
      createdEndDate: picker.endDate.format("YYYY-MM-DD"),
    };
    setdatesrange(data);
    setpageno(0);
    let sorting;
    if (sortState == "createdDate") {
      sorting = "created_date";
    } else if (sortState == "clientUsername") {
      sorting = "client_username";
    } else if (sortState == "visaType") {
      sorting = "visa_type";
    } else if (sortState == "visaExpiryDate") {
      sorting = "visa_expiry_date";
    } else if (sortState == "enquirySource") {
      sorting = "enquiry_source";
    } else if (sortState == "enquiryStatus") {
      sorting = "enquiry_status";
    } else if (sortState == "currentStage") {
      sorting = "current_stage";
    } else if (sortState == "institutionName") {
      sorting = "institution_name";
    } else if (sortState == "consultantName") {
      sorting = "consultant_name";
    } else if (sortState == "followUpDate") {
      sorting = "follow_up_date";
    } else if (sortState == "deadLineDate") {
      sorting = "dead_line_date";
    } else if (sortState == "remarks") {
      sorting = "remarks";
    } else {
      sorting = "client_id";
    }

    if (selectedOffice === "all") {
      if (assigneeState) {
        axios
          .get(`${base_url.api2}/getEnquiryByWorkflowType`, {
            headers: {
              Authorization: jwtToken,
              workflowType: "Migration",
              assigneeId: selectedAssigneeData,
              createdStartDate: picker.startDate.format("YYYY-MM-DD"),
              createdEndDate: picker.endDate.format("YYYY-MM-DD"),
              columnName: sorting,
              direction: direction,
              page: Number(0),
              size: Number(rows),
            },
          })
          .then((response) => {
            setgetpageno(response.data.data.totalPages);
            setEnquiry(response.data.data.content);
            settotalUser(response.data.data.totalElements);
            setcountnumber(response.data.data.number);
            setnumberofElements(response.data.data.numberOfElements);
            setbackdropopen(false);
            setsearch(false);
            try {
              document.getElementById("myForm").reset();
            } catch (error) {
              //console.log(error);
            }
          })
          .catch((error) => {
            setgetpageno(0);
            setEnquiry();
            settotalUser(0);
            setcountnumber(0);
            setnumberofElements(0);
            setbackdropopen(false);
            setsearch(false);
            handle403error(error);
          });
      } else {
        axios
          .get(`${base_url.api2}/getEnquiryByWorkflowType`, {
            headers: {
              Authorization: jwtToken,
              workflowType: "Migration",
              columnName: sortState,
              createdStartDate: picker.startDate.format("YYYY-MM-DD"),
              createdEndDate: picker.endDate.format("YYYY-MM-DD"),
              direction: direction,
              page: Number(0),
              size: Number(rows),
            },
          })
          .then((response) => {
            setgetpageno(response.data.data.totalPages);
            setEnquiry(response.data.data.content);
            settotalUser(response.data.data.totalElements);
            setcountnumber(response.data.data.number);
            setnumberofElements(response.data.data.numberOfElements);
            setbackdropopen(false);
            setsearch(false);
            try {
              document.getElementById("myForm").reset();
            } catch (error) {
              //console.log(error);
            }
          })
          .catch((error) => {
            setgetpageno(0);
            setEnquiry();
            settotalUser(0);
            setcountnumber(0);
            setnumberofElements(0);
            setbackdropopen(false);
            setsearch(false);
            handle403error(error);
          });
      }
    } else {
      if (assigneeState) {
        axios
          .get(`${base_url.api2}/getEnquiryByWorkflowType`, {
            headers: {
              Authorization: jwtToken,
              workflowType: "Migration",
              officeId: Number(selectedOffice),
              createdStartDate: picker.startDate.format("YYYY-MM-DD"),
              createdEndDate: picker.endDate.format("YYYY-MM-DD"),
              columnName: sorting,
              assigneeId: selectedAssigneeData,
              direction: direction,
              page: Number(0),
              size: Number(rows),
            },
          })
          .then((response) => {
            setgetpageno(response.data.data.totalPages);
            setEnquiry(response.data.data.content);
            settotalUser(response.data.data.totalElements);
            setcountnumber(response.data.data.number);
            setnumberofElements(response.data.data.numberOfElements);
            setbackdropopen(false);
            setsearch(false);
            try {
              document.getElementById("myForm").reset();
            } catch (error) {
              //console.log(error);
            }
          })
          .catch((error) => {
            setgetpageno(0);
            setEnquiry();
            settotalUser(0);
            setcountnumber(0);
            setnumberofElements(0);
            setbackdropopen(false);
            setsearch(false);
            handle403error(error);
          });
      } else {
        axios
          .get(`${base_url.api2}/getEnquiryByWorkflowType`, {
            headers: {
              Authorization: jwtToken,
              workflowType: "Migration",
              officeId: Number(selectedOffice),
              createdStartDate: picker.startDate.format("YYYY-MM-DD"),
              createdEndDate: picker.endDate.format("YYYY-MM-DD"),
              columnName: sortState,
              direction: direction,
              page: Number(0),
              size: Number(rows),
            },
          })
          .then((response) => {
            setgetpageno(response.data.data.totalPages);
            setEnquiry(response.data.data.content);
            settotalUser(response.data.data.totalElements);
            setcountnumber(response.data.data.number);
            setnumberofElements(response.data.data.numberOfElements);
            setbackdropopen(false);
            setsearch(false);
            try {
              document.getElementById("myForm").reset();
            } catch (error) {
              //console.log(error);
            }
          })
          .catch((error) => {
            setgetpageno(0);
            setEnquiry();
            settotalUser(0);
            setcountnumber(0);
            setnumberofElements(0);
            setbackdropopen(false);
            setsearch(false);
            handle403error(error);
          });
      }
    }
  };

  const [visaexpirydatevalue, setvisaexpirydatevalue] =
    useState("Click to open");
  const handlevisaexpirydateApply = (event, picker) => {
    setbackdropopen(true);
    setvisaexpirydatevalue(
      `${picker.startDate.format("DD-MM-YYYY")} to ${picker.endDate.format(
        "DD-MM-YYYY"
      )}`
    );
    setpageno(0);
    setvisaexpirydatefilter(true);
    let data = {
      visaStartDate: picker.startDate.format("YYYY-MM-DD"),
      visaEndDate: picker.endDate.format("YYYY-MM-DD"),
    };
    let sorting;
    if (sortState == "createdDate") {
      sorting = "created_date";
    } else if (sortState == "clientUsername") {
      sorting = "client_username";
    } else if (sortState == "visaType") {
      sorting = "visa_type";
    } else if (sortState == "visaExpiryDate") {
      sorting = "visa_expiry_date";
    } else if (sortState == "enquirySource") {
      sorting = "enquiry_source";
    } else if (sortState == "enquiryStatus") {
      sorting = "enquiry_status";
    } else if (sortState == "currentStage") {
      sorting = "current_stage";
    } else if (sortState == "institutionName") {
      sorting = "institution_name";
    } else if (sortState == "consultantName") {
      sorting = "consultant_name";
    } else if (sortState == "followUpDate") {
      sorting = "follow_up_date";
    } else if (sortState == "deadLineDate") {
      sorting = "dead_line_date";
    } else if (sortState == "remarks") {
      sorting = "remarks";
    } else {
      sorting = "client_id";
    }

    if (selectedOffice === "all") {
      if (assigneeState) {
        axios
          .get(`${base_url.api2}/getEnquiryByWorkflowType`, {
            headers: {
              Authorization: jwtToken,
              workflowType: "Migration",
              columnName: sorting,
              visaStartDate: picker.startDate.format("YYYY-MM-DD"),
              visaEndDate: picker.endDate.format("YYYY-MM-DD"),
              assigneeId: selectedAssigneeData,
              direction: direction,
              page: Number(0),
              size: Number(rows),
            },
          })
          .then((response) => {
            setgetpageno(response.data.data.totalPages);
            setEnquiry(response.data.data.content);
            settotalUser(response.data.data.totalElements);
            setcountnumber(response.data.data.number);
            setnumberofElements(response.data.data.numberOfElements);
            setbackdropopen(false);
            setsearch(false);
            try {
              document.getElementById("myForm").reset();
            } catch (error) {
              //console.log(error);
            }
          })
          .catch((error) => {
            setgetpageno(0);
            setEnquiry();
            settotalUser(0);
            setcountnumber(0);
            setnumberofElements(0);
            setbackdropopen(false);
            setsearch(false);
            handle403error(error);
          });
      } else {
        axios
          .get(`${base_url.api2}/getEnquiryByWorkflowType`, {
            headers: {
              Authorization: jwtToken,
              workflowType: "Migration",
              columnName: sortState,
              visaStartDate: picker.startDate.format("YYYY-MM-DD"),
              visaEndDate: picker.endDate.format("YYYY-MM-DD"),
              direction: direction,
              page: Number(0),
              size: Number(rows),
            },
          })
          .then((response) => {
            setgetpageno(response.data.data.totalPages);
            setEnquiry(response.data.data.content);
            settotalUser(response.data.data.totalElements);
            setcountnumber(response.data.data.number);
            setnumberofElements(response.data.data.numberOfElements);
            setbackdropopen(false);
            setsearch(false);
            try {
              document.getElementById("myForm").reset();
            } catch (error) {
              //console.log(error);
            }
          })
          .catch((error) => {
            setgetpageno(0);
            setEnquiry();
            settotalUser(0);
            setcountnumber(0);
            setnumberofElements(0);
            setbackdropopen(false);
            setsearch(false);
            handle403error(error);
          });
      }
    } else {
      if (assigneeState) {
        axios
          .get(`${base_url.api2}/getEnquiryByWorkflowType`, {
            headers: {
              Authorization: jwtToken,
              workflowType: "Migration",
              officeId: Number(selectedOffice),
              columnName: sorting,
              visaStartDate: picker.startDate.format("YYYY-MM-DD"),
              visaEndDate: picker.endDate.format("YYYY-MM-DD"),
              assigneeId: selectedAssigneeData,
              direction: direction,
              page: Number(0),
              size: Number(rows),
            },
          })
          .then((response) => {
            setgetpageno(response.data.data.totalPages);
            setEnquiry(response.data.data.content);
            settotalUser(response.data.data.totalElements);
            setcountnumber(response.data.data.number);
            setnumberofElements(response.data.data.numberOfElements);
            setbackdropopen(false);
            setsearch(false);
            try {
              document.getElementById("myForm").reset();
            } catch (error) {
              //console.log(error);
            }
          })
          .catch((error) => {
            setgetpageno(0);
            setEnquiry();
            settotalUser(0);
            setcountnumber(0);
            setnumberofElements(0);
            setbackdropopen(false);
            setsearch(false);
            handle403error(error);
          });
      } else {
        axios
          .get(`${base_url.api2}/getEnquiryByWorkflowType`, {
            headers: {
              Authorization: jwtToken,
              workflowType: "Migration",
              officeId: Number(selectedOffice),
              columnName: sortState,
              visaStartDate: picker.startDate.format("YYYY-MM-DD"),
              visaEndDate: picker.endDate.format("YYYY-MM-DD"),
              direction: direction,
              page: Number(0),
              size: Number(rows),
            },
          })
          .then((response) => {
            setgetpageno(response.data.data.totalPages);
            setEnquiry(response.data.data.content);
            settotalUser(response.data.data.totalElements);
            setcountnumber(response.data.data.number);
            setnumberofElements(response.data.data.numberOfElements);
            setbackdropopen(false);
            setsearch(false);
            try {
              document.getElementById("myForm").reset();
            } catch (error) {
              //console.log(error);
            }
          })
          .catch((error) => {
            setgetpageno(0);
            setEnquiry();
            settotalUser(0);
            setcountnumber(0);
            setnumberofElements(0);
            setbackdropopen(false);
            setsearch(false);
            handle403error(error);
          });
      }
    }
  };

  const [followupdatevalue, setfollowupdatevalue] = useState("Click to open");
  const handlefollowupdateApply = (event, picker) => {
    setbackdropopen(true);
    setfollowupdatevalue(
      `${picker.startDate.format("DD-MM-YYYY")} to ${picker.endDate.format(
        "DD-MM-YYYY"
      )}`
    );
    setpageno(0);
    setfollowupdatefilter(true);
    let sorting;
    if (sortState == "createdDate") {
      sorting = "created_date";
    } else if (sortState == "clientUsername") {
      sorting = "client_username";
    } else if (sortState == "visaType") {
      sorting = "visa_type";
    } else if (sortState == "visaExpiryDate") {
      sorting = "visa_expiry_date";
    } else if (sortState == "enquirySource") {
      sorting = "enquiry_source";
    } else if (sortState == "enquiryStatus") {
      sorting = "enquiry_status";
    } else if (sortState == "currentStage") {
      sorting = "current_stage";
    } else if (sortState == "institutionName") {
      sorting = "institution_name";
    } else if (sortState == "consultantName") {
      sorting = "consultant_name";
    } else if (sortState == "followUpDate") {
      sorting = "follow_up_date";
    } else if (sortState == "deadLineDate") {
      sorting = "dead_line_date";
    } else if (sortState == "remarks") {
      sorting = "remarks";
    } else {
      sorting = "client_id";
    }

    if (selectedOffice === "all") {
      if (assigneeState) {
        axios
          .get(`${base_url.api2}/getEnquiryByWorkflowType`, {
            headers: {
              Authorization: jwtToken,
              workflowType: "Migration",
              followUpStartDate: picker.startDate.format("YYYY-MM-DD"),
              followUpEndDate: picker.endDate.format("YYYY-MM-DD"),
              columnName: sorting,
              assigneeId: selectedAssigneeData,
              direction: direction,
              page: Number(0),
              size: Number(rows),
            },
          })
          .then((response) => {
            setgetpageno(response.data.data.totalPages);
            setEnquiry(response.data.data.content);
            settotalUser(response.data.data.totalElements);
            setcountnumber(response.data.data.number);
            setnumberofElements(response.data.data.numberOfElements);
            setbackdropopen(false);
            setsearch(false);
            try {
              document.getElementById("myForm").reset();
            } catch (error) {
              //console.log(error);
            }
          })
          .catch((error) => {
            setgetpageno(0);
            setEnquiry();
            settotalUser(0);
            setcountnumber(0);
            setnumberofElements(0);
            setbackdropopen(false);
            setsearch(false);
            handle403error(error);
          });
      } else {
        axios
          .get(`${base_url.api2}/getEnquiryByWorkflowType`, {
            headers: {
              Authorization: jwtToken,
              workflowType: "Migration",
              followUpStartDate: picker.startDate.format("YYYY-MM-DD"),
              followUpEndDate: picker.endDate.format("YYYY-MM-DD"),
              columnName: sortState,
              direction: direction,
              page: Number(0),
              size: Number(rows),
            },
          })
          .then((response) => {
            setgetpageno(response.data.data.totalPages);
            setEnquiry(response.data.data.content);
            settotalUser(response.data.data.totalElements);
            setcountnumber(response.data.data.number);
            setnumberofElements(response.data.data.numberOfElements);
            setbackdropopen(false);
            setsearch(false);
            try {
              document.getElementById("myForm").reset();
            } catch (error) {
              //console.log(error);
            }
          })
          .catch((error) => {
            setgetpageno(0);
            setEnquiry();
            settotalUser(0);
            setcountnumber(0);
            setnumberofElements(0);
            setbackdropopen(false);
            setsearch(false);
            handle403error(error);
          });
      }
    } else {
      if (assigneeState) {
        axios
          .get(`${base_url.api2}/getEnquiryByWorkflowType`, {
            headers: {
              Authorization: jwtToken,
              workflowType: "Migration",
              officeId: Number(selectedOffice),
              followUpStartDate: picker.startDate.format("YYYY-MM-DD"),
              followUpEndDate: picker.endDate.format("YYYY-MM-DD"),
              columnName: sorting,
              assigneeId: selectedAssigneeData,
              direction: direction,
              page: Number(0),
              size: Number(rows),
            },
          })
          .then((response) => {
            setgetpageno(response.data.data.totalPages);
            setEnquiry(response.data.data.content);
            settotalUser(response.data.data.totalElements);
            setcountnumber(response.data.data.number);
            setnumberofElements(response.data.data.numberOfElements);
            setbackdropopen(false);
            setsearch(false);
            try {
              document.getElementById("myForm").reset();
            } catch (error) {
              //console.log(error);
            }
          })
          .catch((error) => {
            setgetpageno(0);
            setEnquiry();
            settotalUser(0);
            setcountnumber(0);
            setnumberofElements(0);
            setbackdropopen(false);
            setsearch(false);
            handle403error(error);
          });
      } else {
        axios
          .get(`${base_url.api2}/getEnquiryByWorkflowType`, {
            headers: {
              Authorization: jwtToken,
              workflowType: "Migration",
              officeId: Number(selectedOffice),
              followUpStartDate: picker.startDate.format("YYYY-MM-DD"),
              followUpEndDate: picker.endDate.format("YYYY-MM-DD"),
              columnName: sortState,
              direction: direction,
              page: Number(0),
              size: Number(rows),
            },
          })
          .then((response) => {
            setgetpageno(response.data.data.totalPages);
            setEnquiry(response.data.data.content);
            settotalUser(response.data.data.totalElements);
            setcountnumber(response.data.data.number);
            setnumberofElements(response.data.data.numberOfElements);
            setbackdropopen(false);
            setsearch(false);
            try {
              document.getElementById("myForm").reset();
            } catch (error) {
              //console.log(error);
            }
          })
          .catch((error) => {
            setgetpageno(0);
            setEnquiry();
            settotalUser(0);
            setcountnumber(0);
            setnumberofElements(0);
            setbackdropopen(false);
            setsearch(false);
            handle403error(error);
          });
      }
    }
  };
  const [deadlinedatevalue, setdeadlinedatevalue] = useState("Click to open");
  const handledeadlinedateApply = (event, picker) => {
    setbackdropopen(true);
    setdeadlinedatevalue(
      `${picker.startDate.format("DD-MM-YYYY")} to ${picker.endDate.format(
        "DD-MM-YYYY"
      )}`
    );
    setpageno(0);
    setdeadlinedatefilter(true);
    let data = {
      deadLineStartDate: picker.startDate.format("YYYY-MM-DD"),
      deadLineEndDate: picker.endDate.format("YYYY-MM-DD"),
    };
    let sorting;
    if (sortState == "createdDate") {
      sorting = "created_date";
    } else if (sortState == "clientUsername") {
      sorting = "client_username";
    } else if (sortState == "visaType") {
      sorting = "visa_type";
    } else if (sortState == "visaExpiryDate") {
      sorting = "visa_expiry_date";
    } else if (sortState == "enquirySource") {
      sorting = "enquiry_source";
    } else if (sortState == "enquiryStatus") {
      sorting = "enquiry_status";
    } else if (sortState == "currentStage") {
      sorting = "current_stage";
    } else if (sortState == "institutionName") {
      sorting = "institution_name";
    } else if (sortState == "consultantName") {
      sorting = "consultant_name";
    } else if (sortState == "followUpDate") {
      sorting = "follow_up_date";
    } else if (sortState == "deadLineDate") {
      sorting = "dead_line_date";
    } else if (sortState == "remarks") {
      sorting = "remarks";
    } else {
      sorting = "client_id";
    }

    if (selectedOffice === "all") {
      if (assigneeState) {
        axios
          .get(`${base_url.api2}/getEnquiryByWorkflowType`, {
            headers: {
              Authorization: jwtToken,
              workflowType: "Migration",
              columnName: sorting,
              deadLineStartDate: picker.startDate.format("YYYY-MM-DD"),
              deadLineEndDate: picker.endDate.format("YYYY-MM-DD"),
              assigneeId: selectedAssigneeData,
              direction: direction,
              page: Number(0),
              size: Number(rows),
            },
          })
          .then((response) => {
            setgetpageno(response.data.data.totalPages);
            setEnquiry(response.data.data.content);
            settotalUser(response.data.data.totalElements);
            setcountnumber(response.data.data.number);
            setnumberofElements(response.data.data.numberOfElements);
            setbackdropopen(false);
            setsearch(false);
            try {
              document.getElementById("myForm").reset();
            } catch (error) {
              //console.log(error);
            }
          })
          .catch((error) => {
            setgetpageno(0);
            setEnquiry();
            settotalUser(0);
            setcountnumber(0);
            setnumberofElements(0);
            setbackdropopen(false);
            setsearch(false);
            handle403error(error);
          });
      } else {
        axios
          .get(`${base_url.api2}/getEnquiryByWorkflowType`, {
            headers: {
              Authorization: jwtToken,
              workflowType: "Migration",
              columnName: sortState,
              deadLineStartDate: picker.startDate.format("YYYY-MM-DD"),
              deadLineEndDate: picker.endDate.format("YYYY-MM-DD"),
              direction: direction,
              page: Number(0),
              size: Number(rows),
            },
          })
          .then((response) => {
            setgetpageno(response.data.data.totalPages);
            setEnquiry(response.data.data.content);
            settotalUser(response.data.data.totalElements);
            setcountnumber(response.data.data.number);
            setnumberofElements(response.data.data.numberOfElements);
            setbackdropopen(false);
            setsearch(false);
            try {
              document.getElementById("myForm").reset();
            } catch (error) {
              //console.log(error);
            }
          })
          .catch((error) => {
            setgetpageno(0);
            setEnquiry();
            settotalUser(0);
            setcountnumber(0);
            setnumberofElements(0);
            setbackdropopen(false);
            setsearch(false);
            handle403error(error);
          });
      }
    } else {
      if (assigneeState) {
        axios
          .get(`${base_url.api2}/getEnquiryByWorkflowType`, {
            headers: {
              Authorization: jwtToken,
              workflowType: "Migration",
              officeId: Number(selectedOffice),
              columnName: sorting,
              deadLineStartDate: picker.startDate.format("YYYY-MM-DD"),
              deadLineEndDate: picker.endDate.format("YYYY-MM-DD"),
              assigneeId: selectedAssigneeData,
              direction: direction,
              page: Number(0),
              size: Number(rows),
            },
          })
          .then((response) => {
            setgetpageno(response.data.data.totalPages);
            setEnquiry(response.data.data.content);
            settotalUser(response.data.data.totalElements);
            setcountnumber(response.data.data.number);
            setnumberofElements(response.data.data.numberOfElements);
            setbackdropopen(false);
            setsearch(false);
            try {
              document.getElementById("myForm").reset();
            } catch (error) {
              //console.log(error);
            }
          })
          .catch((error) => {
            setgetpageno(0);
            setEnquiry();
            settotalUser(0);
            setcountnumber(0);
            setnumberofElements(0);
            setbackdropopen(false);
            setsearch(false);
            handle403error(error);
          });
      } else {
        axios
          .get(`${base_url.api2}/getEnquiryByWorkflowType`, {
            headers: {
              Authorization: jwtToken,
              workflowType: "Migration",
              officeId: Number(selectedOffice),
              columnName: sortState,
              deadLineStartDate: picker.startDate.format("YYYY-MM-DD"),
              deadLineEndDate: picker.endDate.format("YYYY-MM-DD"),
              direction: direction,
              page: Number(0),
              size: Number(rows),
            },
          })
          .then((response) => {
            setgetpageno(response.data.data.totalPages);
            setEnquiry(response.data.data.content);
            settotalUser(response.data.data.totalElements);
            setcountnumber(response.data.data.number);
            setnumberofElements(response.data.data.numberOfElements);
            setbackdropopen(false);
            setsearch(false);
            try {
              document.getElementById("myForm").reset();
            } catch (error) {
              //console.log(error);
            }
          })
          .catch((error) => {
            setgetpageno(0);
            setEnquiry();
            settotalUser(0);
            setcountnumber(0);
            setnumberofElements(0);
            setbackdropopen(false);
            setsearch(false);
            handle403error(error);
          });
      }
    }
  };

  const globalgetallenquiry = (
    value,
    office = allofficeid,
    value2 = sortState
  ) => {
    //console.log(office);
    let sorting;
    if (sortState == "createdDate") {
      sorting = "created_date";
    } else if (sortState == "clientUsername") {
      sorting = "client_username";
    } else if (sortState == "visaType") {
      sorting = "visa_type";
    } else if (sortState == "visaExpiryDate") {
      sorting = "visa_expiry_date";
    } else if (sortState == "enquirySource") {
      sorting = "enquiry_source";
    } else if (sortState == "enquiryStatus") {
      sorting = "enquiry_status";
    } else if (sortState == "currentStage") {
      sorting = "current_stage";
    } else if (sortState == "institutionName") {
      sorting = "institution_name";
    } else if (sortState == "consultantName") {
      sorting = "consultant_name";
    } else if (sortState == "followUpDate") {
      sorting = "follow_up_date";
    } else if (sortState == "deadLineDate") {
      sorting = "dead_line_date";
    } else if (sortState == "remarks") {
      sorting = "remarks";
    } else {
      sorting = "client_id";
    }

    if (assigneeState) {
      //console.log("coming--->if line NO 1450");
      axios
        .get(`${base_url.api2}/getEnquiryByWorkflowType`, {
          headers: {
            Authorization: jwtToken,
            workflowType: "Migration",
            columnName: sorting,
            assigneeId: selectedAssigneeData,
            direction: direction,
            page: Number(value),
            size: rows,
          },
        })
        .then((response) => {
          setbackdropopen(true);
          setgetpageno(response.data.data.totalPages);
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          setEnquiry(response.data.data.content);
          setdatefilter(false);
          setvisaexpirydatefilter(false);
          setfollowupdatefilter(false);
          setdeadlinedatefilter(false);
          setsearch(false);
          setbackdropopen(false);
          try {
            document.getElementById("myForm").reset();
          } catch (error) {
            //console.log(error);
          }
        })
        .catch((error) => {
          setgetpageno(0);
          settotalUser(0);
          setcountnumber(0);
          setnumberofElements(0);
          setEnquiry();
          setdatefilter(false);
          setvisaexpirydatefilter(false);
          setfollowupdatefilter(false);
          setdeadlinedatefilter(false);
          setsearch(false);
          setbackdropopen(false);
          handle403error(error);
        });
    } else {
      if (isw_user_role == 1 || isw_user_role == 6) {
        //console.log("coming--->else line NO 1496");
        axios
          .get(`${base_url.api2}/getEnquiryByWorkflowType`, {
            headers: {
              Authorization: jwtToken,
              workflowType: "Migration",
              columnName: value2,
              direction: direction,
              page: Number(value),
              size: rows,
            },
          })
          .then((response) => {
            setbackdropopen(true);
            setgetpageno(response.data.data.totalPages);
            settotalUser(response.data.data.totalElements);
            setcountnumber(response.data.data.number);
            setnumberofElements(response.data.data.numberOfElements);
            setEnquiry(response.data.data.content);
            setdatefilter(false);
            setvisaexpirydatefilter(false);
            setfollowupdatefilter(false);
            setdeadlinedatefilter(false);
            setsearch(false);
            setbackdropopen(false);
            try {
              document.getElementById("myForm").reset();
            } catch (error) {
              //console.log(error);
            }
          })
          .catch((error) => {
            setgetpageno(0);
            settotalUser(0);
            setcountnumber(0);
            setnumberofElements(0);
            setEnquiry();
            setdatefilter(false);
            setvisaexpirydatefilter(false);
            setfollowupdatefilter(false);
            setdeadlinedatefilter(false);
            setsearch(false);
            handle403error(error);
            setbackdropopen(false);
          });
      } else {
        //console.log("coming--->else line NO 1540");
        axios
          .get(`${base_url.api2}/getEnquiryByWorkflowType`, {
            headers: {
              Authorization: jwtToken,
              workflowType: "Migration",
              officeId: office,
              columnName: value2,
              direction: direction,
              page: Number(value),
              size: rows,
            },
          })
          .then((response) => {
            setbackdropopen(true);
            setgetpageno(response.data.data.totalPages);
            settotalUser(response.data.data.totalElements);
            setcountnumber(response.data.data.number);
            setnumberofElements(response.data.data.numberOfElements);
            setEnquiry(response.data.data.content);
            setdatefilter(false);
            setvisaexpirydatefilter(false);
            setfollowupdatefilter(false);
            setdeadlinedatefilter(false);
            setsearch(false);
            setbackdropopen(false);
            try {
              document.getElementById("myForm").reset();
            } catch (error) {
              //console.log(error);
            }
          })
          .catch((error) => {
            setgetpageno(0);
            settotalUser(0);
            setcountnumber(0);
            setnumberofElements(0);
            setEnquiry();
            setdatefilter(false);
            setvisaexpirydatefilter(false);
            setfollowupdatefilter(false);
            setdeadlinedatefilter(false);
            setsearch(false);
            handle403error(error);
            setbackdropopen(false);
          });
      }
    }
  };
  const globalgetallenquiryofficeid = (
    value,
    value2 = sortState,
    officeid = Number(user?.isw_available_Office[0]?.officeId)
  ) => {
    setbackdropopen(true);
    let sorting;
    if (sortState == "createdDate") {
      sorting = "created_date";
    } else if (sortState == "clientUsername") {
      sorting = "client_username";
    } else if (sortState == "visaType") {
      sorting = "visa_type";
    } else if (sortState == "visaExpiryDate") {
      sorting = "visa_expiry_date";
    } else if (sortState == "enquirySource") {
      sorting = "enquiry_source";
    } else if (sortState == "enquiryStatus") {
      sorting = "enquiry_status";
    } else if (sortState == "currentStage") {
      sorting = "current_stage";
    } else if (sortState == "institutionName") {
      sorting = "institution_name";
    } else if (sortState == "consultantName") {
      sorting = "consultant_name";
    } else if (sortState == "followUpDate") {
      sorting = "follow_up_date";
    } else if (sortState == "deadLineDate") {
      sorting = "dead_line_date";
    } else if (sortState == "remarks") {
      sorting = "remarks";
    } else {
      sorting = "client_id";
    }

    if (assigneeState) {
      //console.log("coming--->if line NO 1623");
      axios
        .get(`${base_url.api2}/getEnquiryByWorkflowType`, {
          headers: {
            Authorization: jwtToken,
            workflowType: "Migration",
            officeId: officeid,
            assigneeId: selectedAssigneeData,
            columnName: "enquiryId",
            direction: direction,
            page: Number(value),
            size: rows,
          },
        })
        .then((response) => {
          setgetpageno(response.data.data.totalPages);
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          setEnquiry(response.data.data.content);
          setdatefilter(false);
          setvisaexpirydatefilter(false);
          setfollowupdatefilter(false);
          setdeadlinedatefilter(false);
          setsearch(false);
          setbackdropopen(false);
          try {
            document.getElementById("myForm").reset();
          } catch (error) {
            //console.log(error);
          }
        })
        .catch((error) => {
          setgetpageno(0);
          settotalUser(0);
          setcountnumber(0);
          setnumberofElements(0);
          setEnquiry();
          setdatefilter(false);
          setvisaexpirydatefilter(false);
          setfollowupdatefilter(false);
          setdeadlinedatefilter(false);
          setsearch(false);
          setbackdropopen(false);
          handle403error(error);
        });
    } else {
      //console.log("coming--->else line NO 1668");
      axios
        .get(`${base_url.api2}/getEnquiryByWorkflowType`, {
          headers: {
            Authorization: jwtToken,
            workflowType: "Migration",
            officeId: officeid,
            columnName: "enquiryId",
            direction: direction,
            page: Number(value),
            size: rows,
          },
        })
        .then((response) => {
          setgetpageno(response.data.data.totalPages);
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          setEnquiry(response.data.data.content);
          setdatefilter(false);
          setvisaexpirydatefilter(false);
          setfollowupdatefilter(false);
          setdeadlinedatefilter(false);
          setsearch(false);
          setbackdropopen(false);
          try {
            document.getElementById("myForm").reset();
          } catch (error) {
            //console.log(error);
          }
        })
        .catch((error) => {
          setgetpageno(0);
          settotalUser(0);
          setcountnumber(0);
          setnumberofElements(0);
          setEnquiry();
          setdatefilter(false);
          setvisaexpirydatefilter(false);
          setfollowupdatefilter(false);
          setdeadlinedatefilter(false);
          setsearch(false);
          setbackdropopen(false);
          handle403error(error);
        });
    }
  };
  const globalgetenquirybyintake = (value, value1) => {
    setbackdropopen(true);
    let sorting;
    if (sortState == "createdDate") {
      sorting = "created_date";
    } else if (sortState == "clientUsername") {
      sorting = "client_username";
    } else if (sortState == "visaType") {
      sorting = "visa_type";
    } else if (sortState == "visaExpiryDate") {
      sorting = "visa_expiry_date";
    } else if (sortState == "enquirySource") {
      sorting = "enquiry_source";
    } else if (sortState == "enquiryStatus") {
      sorting = "enquiry_status";
    } else if (sortState == "currentStage") {
      sorting = "current_stage";
    } else if (sortState == "institutionName") {
      sorting = "institution_name";
    } else if (sortState == "consultantName") {
      sorting = "consultant_name";
    } else if (sortState == "followUpDate") {
      sorting = "follow_up_date";
    } else if (sortState == "deadLineDate") {
      sorting = "dead_line_date";
    } else if (sortState == "remarks") {
      sorting = "remarks";
    } else {
      sorting = "client_id";
    }

    if (assigneeState) {
      axios
        .get(`${base_url.api2}/getEnquiryByWorkflowType`, {
          headers: {
            Authorization: jwtToken,
            workflowType: "Migration",
            intake: value,
            assigneeId: selectedAssigneeData,
            columnName: sorting,
            direction: direction,
            page: value1,
            size: rows,
          },
        })
        .then((response) => {
          setgetpageno(response.data.data.totalPages);
          setEnquiry(response.data.data.content);
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          setbackdropopen(false);
          setsearch(false);
          try {
            document.getElementById("myForm").reset();
          } catch (error) {
            //console.log(error);
          }
        })
        .catch((error) => {
          if (error.response) {
            toast.error(JSON.stringify(error.response.data.message));
          } else {
            toast.error("Something went wrong");
          }
          setgetpageno(0);
          setEnquiry();
          settotalUser(0);
          setcountnumber(0);
          setnumberofElements(0);
          setbackdropopen(false);
          setsearch(false);
          handle403error(error);
        });
    } else {
      axios
        .get(`${base_url.api2}/getEnquiryByWorkflowType`, {
          headers: {
            Authorization: jwtToken,
            workflowType: "Migration",
            intake: value,
            columnName: sortState,
            direction: direction,
            page: value1,
            size: rows,
          },
        })
        .then((response) => {
          setgetpageno(response.data.data.totalPages);
          setEnquiry(response.data.data.content);
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          setbackdropopen(false);
          setsearch(false);
          try {
            document.getElementById("myForm").reset();
          } catch (error) {
            //console.log(error);
          }
        })
        .catch((error) => {
          handle403error(error);
          if (error.response) {
            toast.error(JSON.stringify(error.response.data.message));
          } else {
            toast.error("Something went wrong");
          }
          setgetpageno(0);
          setEnquiry();
          settotalUser(0);
          setcountnumber(0);
          setnumberofElements(0);
          setbackdropopen(false);
          setsearch(false);
        });
    }
  };
  const globalgetenquirybyintakeofficeid = (value, value1) => {
    setbackdropopen(true);
    let sorting;
    if (sortState == "createdDate") {
      sorting = "created_date";
    } else if (sortState == "clientUsername") {
      sorting = "client_username";
    } else if (sortState == "visaType") {
      sorting = "visa_type";
    } else if (sortState == "visaExpiryDate") {
      sorting = "visa_expiry_date";
    } else if (sortState == "enquirySource") {
      sorting = "enquiry_source";
    } else if (sortState == "enquiryStatus") {
      sorting = "enquiry_status";
    } else if (sortState == "currentStage") {
      sorting = "current_stage";
    } else if (sortState == "institutionName") {
      sorting = "institution_name";
    } else if (sortState == "consultantName") {
      sorting = "consultant_name";
    } else if (sortState == "followUpDate") {
      sorting = "follow_up_date";
    } else if (sortState == "deadLineDate") {
      sorting = "dead_line_date";
    } else if (sortState == "remarks") {
      sorting = "remarks";
    } else {
      sorting = "client_id";
    }

    if (assigneeState) {
      axios
        .get(`${base_url.api2}/getEnquiryByWorkflowType`, {
          headers: {
            Authorization: jwtToken,
            workflowType: "Migration",
            intake: value,
            assigneeId: selectedAssigneeData,
            officeId: Number(selectedOffice),
            columnName: sorting,
            direction: direction,
            page: value1,
            size: rows,
          },
        })
        .then((response) => {
          setgetpageno(response.data.data.totalPages);
          setEnquiry(response.data.data.content);
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          setbackdropopen(false);
          setsearch(false);
          try {
            document.getElementById("myForm").reset();
          } catch (error) {
            //console.log(error);
          }
        })
        .catch((error) => {
          if (error.response) {
            toast.error(JSON.stringify(error.response.data.message));
          } else {
            toast.error("Something went wrong");
          }
          setgetpageno(0);
          setEnquiry();
          settotalUser(0);
          setcountnumber(0);
          setnumberofElements(0);
          setbackdropopen(false);
          setsearch(false);
          handle403error(error);
        });
    } else {
      axios
        .get(`${base_url.api2}/getEnquiryByWorkflowType`, {
          headers: {
            Authorization: jwtToken,
            workflowType: "Migration",
            intake: value,
            officeId: Number(selectedOffice),
            columnName: sortState,
            direction: direction,
            page: value1,
            size: rows,
          },
        })
        .then((response) => {
          setgetpageno(response.data.data.totalPages);
          setEnquiry(response.data.data.content);
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          setbackdropopen(false);
          setsearch(false);
          try {
            document.getElementById("myForm").reset();
          } catch (error) {
            //console.log(error);
          }
        })
        .catch((error) => {
          if (error.response) {
            toast.error(JSON.stringify(error.response.data.message));
          } else {
            toast.error("Something went wrong");
          }
          handle403error(error);
          setgetpageno(0);
          setEnquiry();
          settotalUser(0);
          setcountnumber(0);
          setnumberofElements(0);
          setbackdropopen(false);
          setsearch(false);
        });
    }
  };
  const globalgetenquirybyvisatype = (value, value1) => {
    setbackdropopen(true);
    let sorting;
    if (sortState == "createdDate") {
      sorting = "created_date";
    } else if (sortState == "clientUsername") {
      sorting = "client_username";
    } else if (sortState == "visaType") {
      sorting = "visa_type";
    } else if (sortState == "visaExpiryDate") {
      sorting = "visa_expiry_date";
    } else if (sortState == "enquirySource") {
      sorting = "enquiry_source";
    } else if (sortState == "enquiryStatus") {
      sorting = "enquiry_status";
    } else if (sortState == "currentStage") {
      sorting = "current_stage";
    } else if (sortState == "institutionName") {
      sorting = "institution_name";
    } else if (sortState == "consultantName") {
      sorting = "consultant_name";
    } else if (sortState == "followUpDate") {
      sorting = "follow_up_date";
    } else if (sortState == "deadLineDate") {
      sorting = "dead_line_date";
    } else if (sortState == "remarks") {
      sorting = "remarks";
    } else {
      sorting = "client_id";
    }

    if (assigneeState) {
      axios
        .get(`${base_url.api2}/getEnquiryByWorkflowType`, {
          headers: {
            Authorization: jwtToken,
            workflowType: "Migration",
            visaType: value,
            assigneeId: selectedAssigneeData,
            columnName: sorting,
            direction: direction,
            page: value1,
            size: rows,
          },
        })
        .then((response) => {
          setgetpageno(response.data.data.totalPages);
          setEnquiry(response.data.data.content);
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          setbackdropopen(false);
          setsearch(false);
          try {
            document.getElementById("myForm").reset();
          } catch (error) {
            //console.log(error);
          }
        })
        .catch((error) => {
          if (error.response) {
            toast.error(JSON.stringify(error.response.data.message));
          } else {
            toast.error("Something went wrong");
          }
          setgetpageno(0);
          setEnquiry();
          settotalUser(0);
          setcountnumber(0);
          setnumberofElements(0);
          setbackdropopen(false);
          setsearch(false);
          handle403error(error);
        });
    } else {
      axios
        .get(`${base_url.api2}/getEnquiryByWorkflowType`, {
          headers: {
            Authorization: jwtToken,
            workflowType: "Migration",
            visaType: value,
            columnName: sortState,
            direction: direction,
            page: value1,
            size: rows,
          },
        })
        .then((response) => {
          setgetpageno(response.data.data.totalPages);
          setEnquiry(response.data.data.content);
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          setbackdropopen(false);
          setsearch(false);
          try {
            document.getElementById("myForm").reset();
          } catch (error) {
            //console.log(error);
          }
        })
        .catch((error) => {
          if (error.response) {
            toast.error(JSON.stringify(error.response.data.message));
          } else {
            toast.error("Something went wrong");
          }
          handle403error(error);
          setgetpageno(0);
          setEnquiry();
          settotalUser(0);
          setcountnumber(0);
          setnumberofElements(0);
          setbackdropopen(false);
          setsearch(false);
        });
    }
  };
  const globalgetenquirybyvisatypeofficeid = (value, value1) => {
    setbackdropopen(true);
    let sorting;
    if (sortState == "createdDate") {
      sorting = "created_date";
    } else if (sortState == "clientUsername") {
      sorting = "client_username";
    } else if (sortState == "visaType") {
      sorting = "visa_type";
    } else if (sortState == "visaExpiryDate") {
      sorting = "visa_expiry_date";
    } else if (sortState == "enquirySource") {
      sorting = "enquiry_source";
    } else if (sortState == "enquiryStatus") {
      sorting = "enquiry_status";
    } else if (sortState == "currentStage") {
      sorting = "current_stage";
    } else if (sortState == "institutionName") {
      sorting = "institution_name";
    } else if (sortState == "consultantName") {
      sorting = "consultant_name";
    } else if (sortState == "followUpDate") {
      sorting = "follow_up_date";
    } else if (sortState == "deadLineDate") {
      sorting = "dead_line_date";
    } else if (sortState == "remarks") {
      sorting = "remarks";
    } else {
      sorting = "client_id";
    }

    if (assigneeState) {
      axios
        .get(`${base_url.api2}/getEnquiryByWorkflowType`, {
          headers: {
            Authorization: jwtToken,
            workflowType: "Migration",
            visaType: value,
            officeId: Number(selectedOffice),
            assigneeId: selectedAssigneeData,
            columnName: sorting,
            direction: direction,
            page: value1,
            size: rows,
          },
        })
        .then((response) => {
          setgetpageno(response.data.data.totalPages);
          setEnquiry(response.data.data.content);
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          setbackdropopen(false);
          setsearch(false);
          try {
            document.getElementById("myForm").reset();
          } catch (error) {
            //console.log(error);
          }
        })
        .catch((error) => {
          if (error.response) {
            toast.error(JSON.stringify(error.response.data.message));
          } else {
            toast.error("Something went wrong");
          }
          handle403error(error);
          setgetpageno(0);
          setEnquiry();
          settotalUser(0);
          setcountnumber(0);
          setnumberofElements(0);
          setbackdropopen(false);
          setsearch(false);
        });
    } else {
      axios
        .get(`${base_url.api2}/getEnquiryByWorkflowType`, {
          headers: {
            Authorization: jwtToken,
            workflowType: "Migration",
            visaType: value,
            officeId: Number(selectedOffice),
            columnName: sortState,
            direction: direction,
            page: value1,
            size: rows,
          },
        })
        .then((response) => {
          setgetpageno(response.data.data.totalPages);
          setEnquiry(response.data.data.content);
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          setbackdropopen(false);
          setsearch(false);
          try {
            document.getElementById("myForm").reset();
          } catch (error) {
            //console.log(error);
          }
        })
        .catch((error) => {
          if (error.response) {
            toast.error(JSON.stringify(error.response.data.message));
          } else {
            toast.error("Something went wrong");
          }
          handle403error(error);
          setgetpageno(0);
          setEnquiry();
          settotalUser(0);
          setcountnumber(0);
          setnumberofElements(0);
          setbackdropopen(false);
          setsearch(false);
        });
    }
  };
  const globalgetenquirybycurrentstage = (value, value1) => {
    setbackdropopen(true);
    let sorting;
    if (sortState == "createdDate") {
      sorting = "created_date";
    } else if (sortState == "clientUsername") {
      sorting = "client_username";
    } else if (sortState == "visaType") {
      sorting = "visa_type";
    } else if (sortState == "visaExpiryDate") {
      sorting = "visa_expiry_date";
    } else if (sortState == "enquirySource") {
      sorting = "enquiry_source";
    } else if (sortState == "enquiryStatus") {
      sorting = "enquiry_status";
    } else if (sortState == "currentStage") {
      sorting = "current_stage";
    } else if (sortState == "institutionName") {
      sorting = "institution_name";
    } else if (sortState == "consultantName") {
      sorting = "consultant_name";
    } else if (sortState == "followUpDate") {
      sorting = "follow_up_date";
    } else if (sortState == "deadLineDate") {
      sorting = "dead_line_date";
    } else if (sortState == "remarks") {
      sorting = "remarks";
    } else {
      sorting = "client_id";
    }

    if (assigneeState) {
      axios
        .get(`${base_url.api2}/getEnquiryByWorkflowType`, {
          headers: {
            Authorization: jwtToken,
            workflowType: "Migration",
            currentStage: value,
            assigneeId: selectedAssigneeData,
            columnName: sorting,
            direction: direction,
            page: value1,
            size: rows,
          },
        })
        .then((response) => {
          setgetpageno(response.data.data.totalPages);
          setEnquiry(response.data.data.content);
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          setbackdropopen(false);
          setsearch(false);
          try {
            document.getElementById("myForm").reset();
          } catch (error) {
            //console.log(error);
          }
        })
        .catch((error) => {
          if (error.response) {
            toast.error(JSON.stringify(error.response.data.message));
          } else {
            toast.error("Something went wrong");
          }
          handle403error(error);
          setgetpageno(0);
          setEnquiry();
          settotalUser(0);
          setcountnumber(0);
          setnumberofElements(0);
          setbackdropopen(false);
          setsearch(false);
        });
    } else {
      axios
        .get(`${base_url.api2}/getEnquiryByWorkflowType`, {
          headers: {
            Authorization: jwtToken,
            workflowType: "Migration",
            currentStage: value,
            columnName: sortState,
            direction: direction,
            page: value1,
            size: rows,
          },
        })
        .then((response) => {
          setgetpageno(response.data.data.totalPages);
          setEnquiry(response.data.data.content);
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          setbackdropopen(false);
          setsearch(false);
          try {
            document.getElementById("myForm").reset();
          } catch (error) {
            //console.log(error);
          }
        })
        .catch((error) => {
          handle403error(error);
          if (error.response) {
            toast.error(JSON.stringify(error.response.data.message));
          } else {
            toast.error("Something went wrong");
          }
          setgetpageno(0);
          setEnquiry();
          settotalUser(0);
          setcountnumber(0);
          setnumberofElements(0);
          setbackdropopen(false);
          setsearch(false);
        });
    }
  };
  const globalgetenquirybycurrentstageofficeid = (value, value1) => {
    setbackdropopen(true);
    let sorting;
    if (sortState == "createdDate") {
      sorting = "created_date";
    } else if (sortState == "clientUsername") {
      sorting = "client_username";
    } else if (sortState == "visaType") {
      sorting = "visa_type";
    } else if (sortState == "visaExpiryDate") {
      sorting = "visa_expiry_date";
    } else if (sortState == "enquirySource") {
      sorting = "enquiry_source";
    } else if (sortState == "enquiryStatus") {
      sorting = "enquiry_status";
    } else if (sortState == "currentStage") {
      sorting = "current_stage";
    } else if (sortState == "institutionName") {
      sorting = "institution_name";
    } else if (sortState == "consultantName") {
      sorting = "consultant_name";
    } else if (sortState == "followUpDate") {
      sorting = "follow_up_date";
    } else if (sortState == "deadLineDate") {
      sorting = "dead_line_date";
    } else if (sortState == "remarks") {
      sorting = "remarks";
    } else {
      sorting = "client_id";
    }

    if (assigneeState) {
      axios
        .get(`${base_url.api2}/getEnquiryByWorkflowType`, {
          headers: {
            Authorization: jwtToken,
            workflowType: "Migration",
            currentStage: value,
            officeId: Number(selectedOffice),
            assigneeId: selectedAssigneeData,
            columnName: sorting,
            direction: direction,
            page: value1,
            size: rows,
          },
        })
        .then((response) => {
          setgetpageno(response.data.data.totalPages);
          setEnquiry(response.data.data.content);
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          // setrows(response.data.data.size);
          setsearch(false);
          setbackdropopen(false);
          try {
            document.getElementById("myForm").reset();
          } catch (error) {
            //console.log(error);
          }
        })
        .catch((error) => {
          if (error.response) {
            toast.error(JSON.stringify(error.response.data.message));
          } else {
            toast.error("Something went wrong");
          }
          handle403error(error);
          setgetpageno(0);
          setEnquiry();
          settotalUser(0);
          setcountnumber(0);
          setnumberofElements(0);
          setsearch(false);
          setbackdropopen(false);
        });
    } else {
      axios
        .get(`${base_url.api2}/getEnquiryByWorkflowType`, {
          headers: {
            Authorization: jwtToken,
            workflowType: "Migration",
            currentStage: value,
            officeId: Number(selectedOffice),
            columnName: sortState,
            direction: direction,
            page: value1,
            size: rows,
          },
        })
        .then((response) => {
          setgetpageno(response.data.data.totalPages);
          setEnquiry(response.data.data.content);
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          // setrows(response.data.data.size);
          setsearch(false);
          setbackdropopen(false);
          try {
            document.getElementById("myForm").reset();
          } catch (error) {
            //console.log(error);
          }
        })
        .catch((error) => {
          if (error.response) {
            toast.error(JSON.stringify(error.response.data.message));
          } else {
            toast.error("Something went wrong");
          }
          handle403error(error);
          setgetpageno(0);
          setEnquiry();
          settotalUser(0);
          setcountnumber(0);
          setnumberofElements(0);
          setsearch(false);
          setbackdropopen(false);
        });
    }
  };

  const [assigneeState, setAssigneeState] = useState();
  const [selectedAssigneeData, setSelectedAssigneeData] = useState();
  const onSelectWorkload = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementName = optionElement.getAttribute("value");
    //console.log(optionElementName);
    let sorting;
    if (sortState == "createdDate") {
      sorting = "created_date";
    } else if (sortState == "clientUsername") {
      sorting = "client_username";
    } else if (sortState == "visaType") {
      sorting = "visa_type";
    } else if (sortState == "visaExpiryDate") {
      sorting = "visa_expiry_date";
    } else if (sortState == "enquirySource") {
      sorting = "enquiry_source";
    } else if (sortState == "enquiryStatus") {
      sorting = "enquiry_status";
    } else if (sortState == "currentStage") {
      sorting = "current_stage";
    } else if (sortState == "institutionName") {
      sorting = "institution_name";
    } else if (sortState == "consultantName") {
      sorting = "consultant_name";
    } else if (sortState == "followUpDate") {
      sorting = "follow_up_date";
    } else if (sortState == "deadLineDate") {
      sorting = "dead_line_date";
    } else if (sortState == "remarks") {
      sorting = "remarks";
    } else {
      sorting = "client_id";
    }

    const consultant_obj = [];
    // for (let i = 0; i < selectedList.length; i++) {
    //   consultant_obj.push(selectedList[i].value);
    // }
    //console.log("Office id --",consultant_obj);
    if (consultant_obj.length > 0) {
      setSelectedAssigneeData(optionElementName);
      setAssigneeState(true);
      //console.log(selectedOffice);
      if (selectedOffice != "all") {
        //Migration Reports
        axios
          .get(`${base_url.api2}/getEnquiryByWorkflowType`, {
            headers: {
              Authorization: jwtToken,
              workflowType: "Migration",
              assigneeId: optionElementName,
              officeId: selectedOffice,
              columnName: sorting,
              direction: direction,
              page: pageno,
              size: Number(rows),
            },
          })
          .then((response) => {
            setgetpageno(response.data.data.totalPages);
            settotalUser(response.data.data.totalElements);
            setcountnumber(response.data.data.number);
            setnumberofElements(response.data.data.numberOfElements);
            setEnquiry(response.data.data.content);
            setdatefilter(false);
            setvisaexpirydatefilter(false);
            setfollowupdatefilter(false);
            setdeadlinedatefilter(false);
            setsearch(false);
            setbackdropopen(false);
          })
          .catch((error) => {
            setgetpageno(0);
            settotalUser(0);
            setcountnumber(0);
            setnumberofElements(0);
            setEnquiry();
            setdatefilter(false);
            setvisaexpirydatefilter(false);
            setfollowupdatefilter(false);
            setdeadlinedatefilter(false);
            setsearch(false);
            handle403error(error);
          });
      } else {
        axios
          .get(`${base_url.api2}/getEnquiryByWorkflowType`, {
            headers: {
              Authorization: jwtToken,
              workflowType: "Migration",
              assigneeId: optionElementName,
              columnName: sorting,
              direction: direction,
              page: pageno,
              size: Number(rows),
            },
          })
          .then((response) => {
            setgetpageno(response.data.data.totalPages);
            settotalUser(response.data.data.totalElements);
            setcountnumber(response.data.data.number);
            setnumberofElements(response.data.data.numberOfElements);
            setEnquiry(response.data.data.content);
            setdatefilter(false);
            setdatefilter(false);
            setvisaexpirydatefilter(false);
            setfollowupdatefilter(false);
            setdeadlinedatefilter(false);
            setsearch(false);
            setbackdropopen(false);
          })
          .catch((error) => {
            setgetpageno(0);
            settotalUser(0);
            setcountnumber(0);
            setnumberofElements(0);
            setEnquiry();
            setdatefilter(false);
            setdatefilter(false);
            setvisaexpirydatefilter(false);
            setfollowupdatefilter(false);
            setdeadlinedatefilter(false);
            setsearch(false);
            handle403error(error);
            setbackdropopen(false);
          });
      }
    } else {
      setAssigneeState(false);
      if (selectedOffice != "all") {
        axios
          .get(`${base_url.api2}/getEnquiryByWorkflowType`, {
            headers: {
              Authorization: jwtToken,
              workflowType: "Migration",
              officeId: selectedOffice,
              columnName: sortState,
              assigneeId: optionElementName,
              direction: direction,
              page: Number(pageno),
              size: Number(rows),
            },
          })
          .then((response) => {
            setgetpageno(response.data.data.totalPages);
            settotalUser(response.data.data.totalElements);
            setcountnumber(response.data.data.number);
            setnumberofElements(response.data.data.numberOfElements);
            setEnquiry(response.data.data.content);
            setdatefilter(false);
            setdatefilter(false);
            setvisaexpirydatefilter(false);
            setfollowupdatefilter(false);
            setdeadlinedatefilter(false);
            setsearch(false);
            setbackdropopen(false);
          })
          .catch((error) => {
            setgetpageno(0);
            settotalUser(0);
            setcountnumber(0);
            setnumberofElements(0);
            setEnquiry();
            setdatefilter(false);
            setdatefilter(false);
            setvisaexpirydatefilter(false);
            setfollowupdatefilter(false);
            setdeadlinedatefilter(false);
            setsearch(false);
            setbackdropopen(false);
            handle403error(error);
          });
      } else {
        axios
          .get(`${base_url.api2}/getEnquiryByWorkflowType`, {
            headers: {
              Authorization: jwtToken,
              workflowType: "Migration",
              columnName: sortState,
              assigneeId: optionElementName,
              direction: direction,
              page: Number(pageno),
              size: Number(rows),
            },
          })
          .then((response) => {
            setgetpageno(response.data.data.totalPages);
            settotalUser(response.data.data.totalElements);
            setcountnumber(response.data.data.number);
            setnumberofElements(response.data.data.numberOfElements);
            setEnquiry(response.data.data.content);
            setdatefilter(false);
            setdatefilter(false);
            setvisaexpirydatefilter(false);
            setfollowupdatefilter(false);
            setdeadlinedatefilter(false);
            setsearch(false);
            setbackdropopen(false);
          })
          .catch((error) => {
            setgetpageno(0);
            settotalUser(0);
            setcountnumber(0);
            setnumberofElements(0);
            setEnquiry();
            setdatefilter(false);
            setdatefilter(false);
            setvisaexpirydatefilter(false);
            setfollowupdatefilter(false);
            setdeadlinedatefilter(false);
            setsearch(false);
            setbackdropopen(false);
            handle403error(error);
          });
      }
    }
  };

  const onSelectWorkloadPage = (pageData) => {
    //console.log("Coming Pagination", pageData);
    let sorting;
    if (sortState == "createdDate") {
      sorting = "created_date";
    } else if (sortState == "clientUsername") {
      sorting = "client_username";
    } else if (sortState == "visaType") {
      sorting = "visa_type";
    } else if (sortState == "visaExpiryDate") {
      sorting = "visa_expiry_date";
    } else if (sortState == "enquirySource") {
      sorting = "enquiry_source";
    } else if (sortState == "enquiryStatus") {
      sorting = "enquiry_status";
    } else if (sortState == "currentStage") {
      sorting = "current_stage";
    } else if (sortState == "institutionName") {
      sorting = "institution_name";
    } else if (sortState == "consultantName") {
      sorting = "consultant_name";
    } else if (sortState == "followUpDate") {
      sorting = "follow_up_date";
    } else if (sortState == "deadLineDate") {
      sorting = "dead_line_date";
    } else if (sortState == "remarks") {
      sorting = "remarks";
    } else {
      sorting = "client_id";
    }

    if (selectedAssigneeData.length > 0) {
      if (selectedOffice != "all") {
        axios
          .get(`${base_url.api2}/getEnquiryByWorkflowType`, {
            headers: {
              Authorization: jwtToken,
              workflowType: "Migration",
              assigneeId: selectedAssigneeData.toString(),
              officeId: selectedOffice,
              columnName: sorting,
              direction: direction,
              page: pageData,
              size: Number(rows),
            },
          })
          .then((response) => {
            setgetpageno(response.data.data.totalPages);
            settotalUser(response.data.data.totalElements);
            setcountnumber(response.data.data.number);
            setnumberofElements(response.data.data.numberOfElements);
            setEnquiry(response.data.data.content);
            setdatefilter(false);
            setdatefilter(false);
            setvisaexpirydatefilter(false);
            setfollowupdatefilter(false);
            setdeadlinedatefilter(false);
            setsearch(false);
            setbackdropopen(false);
          })
          .catch((error) => {
            setgetpageno(0);
            settotalUser(0);
            setcountnumber(0);
            setnumberofElements(0);
            setEnquiry();
            setdatefilter(false);
            setvisaexpirydatefilter(false);
            setfollowupdatefilter(false);
            setdeadlinedatefilter(false);
            setsearch(false);
            handle403error(error);
          });
      } else {
        axios
          .get(`${base_url.api2}/getEnquiryByWorkflowType`, {
            headers: {
              Authorization: jwtToken,
              workflowType: "Migration",
              assigneeId: selectedAssigneeData.toString(),
              columnName: sorting,
              direction: direction,
              page: pageData,
              size: Number(rows),
            },
          })
          .then((response) => {
            setgetpageno(response.data.data.totalPages);
            settotalUser(response.data.data.totalElements);
            setcountnumber(response.data.data.number);
            setnumberofElements(response.data.data.numberOfElements);
            setEnquiry(response.data.data.content);
            setdatefilter(false);
            setvisaexpirydatefilter(false);
            setfollowupdatefilter(false);
            setdeadlinedatefilter(false);
            setsearch(false);
            setbackdropopen(false);
          })
          .catch((error) => {
            setgetpageno(0);
            settotalUser(0);
            setcountnumber(0);
            setnumberofElements(0);
            setEnquiry();
            setvisaexpirydatefilter(false);
            setfollowupdatefilter(false);
            setdeadlinedatefilter(false);
            setdatefilter(false);
            setsearch(false);
            setbackdropopen(false);
            handle403error(error);
          });
      }
    } else {
      setAssigneeState(false);
      if (selectedOffice != "all") {
        //Migration Reports
        axios
          .get(`${base_url.api2}/getEnquiryByWorkflowType`, {
            headers: {
              Authorization: jwtToken,
              workflowType: "Migration",
              officeId: selectedOffice,
              columnName: sortState,
              direction: direction,
              page: Number(pageData),
              size: Number(rows),
            },
          })
          .then((response) => {
            setgetpageno(response.data.data.totalPages);
            settotalUser(response.data.data.totalElements);
            setcountnumber(response.data.data.number);
            setnumberofElements(response.data.data.numberOfElements);
            setEnquiry(response.data.data.content);
            setdatefilter(false);
            setvisaexpirydatefilter(false);
            setfollowupdatefilter(false);
            setdeadlinedatefilter(false);
            setsearch(false);
            setbackdropopen(false);
          })
          .catch((error) => {
            setgetpageno(0);
            settotalUser(0);
            setcountnumber(0);
            setnumberofElements(0);
            setEnquiry();
            setvisaexpirydatefilter(false);
            setfollowupdatefilter(false);
            setdeadlinedatefilter(false);
            setdatefilter(false);
            setsearch(false);
            setbackdropopen(false);
            handle403error(error);
          });
      } else {
        axios
          .get(`${base_url.api2}/getEnquiryByWorkflowType`, {
            headers: {
              Authorization: jwtToken,
              workflowType: "Migration",
              columnName: sortState,
              direction: direction,
              page: Number(pageData),
              size: Number(rows),
            },
          })
          .then((response) => {
            setgetpageno(response.data.data.totalPages);
            settotalUser(response.data.data.totalElements);
            setcountnumber(response.data.data.number);
            setnumberofElements(response.data.data.numberOfElements);
            setEnquiry(response.data.data.content);
            setdatefilter(false);
            setvisaexpirydatefilter(false);
            setfollowupdatefilter(false);
            setdeadlinedatefilter(false);
            setsearch(false);
            setbackdropopen(false);
          })
          .catch((error) => {
            setgetpageno(0);
            settotalUser(0);
            setcountnumber(0);
            setnumberofElements(0);
            setEnquiry();
            setvisaexpirydatefilter(false);
            setfollowupdatefilter(false);
            setdeadlinedatefilter(false);
            setdatefilter(false);
            setsearch(false);
            setbackdropopen(false);
            handle403error(error);
          });
      }
    }
  };

  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            navigate("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };
  return (
    <div>
      <ToastContainer />
      <Backdrop className={classes.backdrop} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* <div className="commoncomponent_cardcss"> */}
      <div
        class="page-head d-flex justify-content-between p-4"
        style={{ marginTop: "5rem", marginBottom: "10px" }}
      >
        <ul class="breadcrums">
          <h3 class="page-title bold">Migration Followup Sheet</h3>
          <Link to="/" class="b-link">
            Home
          </Link>
          <span className="s_span ">&raquo;</span>
          <a class="b-link">Workload</a>
          <span className="s_span ">&raquo;</span>
          <Link to="/migration-enquiry" class="b-link">
            Migration
          </Link>
          <span className="s_span ">&raquo;</span>
          <Link to="/migration-enquiry" class="b-link active">
            Visa
          </Link>
        </ul>
        {/* <a href="workload-dashboard.html" class="back-btn">Back</a> */}
      </div>
      <div>
        {/* <Row>
            <Col md={6}>
              <CardText
                className={classes.headtext}
                style={{ marginTop: "-10px" }}
              >
                <strong>Migration Followup sheet</strong>
              </CardText>
            </Col>
          </Row> */}
        <div
          class="card p-4"
          style={{
            padding: "1.5rem",
            borderRight: "1.5rem solid #e9ebf2",
            borderLeft: "1.5rem solid #e9ebf2",
            borderTop: "1.5rem solid #e9ebf2",
            marginBottom: "0",
          }}
        >
          <div class="form-grp row">
            {/* <Col md={2}>
              <form>
                <div>
                  <label className="area_rowslable">Limit</label>
                  <br />
                  <FormControl className={classes.formControl}>
                    <select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={rows}
                      onChange={handleRows}
                      style={{
                        border: "2px solid #ced4da ",
                        paddingTop: "5px",
                        backgroundColor: "#f5f5f9",
                        width: "220px",
                      }}
                    >
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </FormControl>
                </div>
              </form>
            </Col> */}
            <div class="input-grp col-md-2">
              <label className="area_rowslable">Limit</label>
              <select
                className="form-control"
                name="branch"
                value={rows}
                onChange={handleRows}
                style={{ backgroundColor: "#f5f5f9", width: "95%" }}
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
            <div class="input-grp col-md-2">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <label
                  className="area_rowslable_new"
                  style={{ fontWeight: "bold" }}
                >
                  Created Date
                </label>
                <div
                  className="clearsearchclass"
                  style={{ width: "100%", marginTop: "0px" }}
                >
                  <form
                    // id="myForm"
                    className="d-flex"
                    style={{ position: "relative" }}
                  >
                    <DateRangePicker
                      onEvent={handleEvent}
                      onCallback={handleCallback}
                      onApply={handleApply}
                    >
                      <p
                        className="datepickerrangevaluetextone"
                        style={{
                          backgroundColor: "#f5f5f9",
                          width: "95%",
                          position: "relative",
                        }}
                      >
                        {datevalue}
                      </p>
                    </DateRangePicker>
                    {/* {datefilter ? (
                <div style={{ float: "left",width:"95%" }} className="s_datefilter">
                  <a
                    className={classes.clrsrch}
                    onClick={() => {
                      setdatevalue("Click to open");
                      setdisplay("inline");
                      setdirection("DESC");
                      getallEnquiry(0);
                    }}
                  >
                    Clear Search
                  </a>
                </div>
              ) : null} */}
                    <span
                      className="listpagesearch_button"
                      style={{
                        top: "10px",
                        position: "absolute",
                        right: "20px",
                        pointerEvents: "none",
                      }}
                    >
                      <i className="fa fa-calendar"></i>
                    </span>
                  </form>
                </div>
              </div>
            </div>
            <div class="input-grp col-md-2">
              <label className="area_rowslable">Branch</label>
              <select
                className="form-control"
                onChange={handleChangeOffice}
                name="branch"
                style={{ backgroundColor: "#f5f5f9", width: "95%" }}
              >
                {/* <option value="">- Select Branch -</option> */}
                {Number(user?.isw_user_role) === 1 ||
                Number(user?.isw_user_role) === 6 ||
                Number(user?.isw_user_role) === 10 ||
                Number(user?.isw_user_role) === 3 ||
                Number(user?.isw_user_role) === 2 ||
                Number(user?.isw_user_role) === 5 ||
                Number(user?.isw_user_role) === 4 ? (
                  <option value="all">Show All</option>
                ) : null}
                {user?.isw_available_Office?.map((p, index) => (
                  <option key={index} id={p.officeName} value={p.officeId}>
                    {p.officeName}
                    {" - "}
                    {p.officeCode}
                  </option>
                ))}
              </select>
            </div>
            <div class="input-grp col-md-2">
              <label className="area_rowslable">Consultant</label>
              <select
                className="s_form-control"
                onChange={onSelectWorkload}
                name="assigneeId"
                // options={assigneeData}
                style={{
                  backgroundColor: "#f5f5f9",
                  border: "none",
                  width: "95%",
                }}
              >
                <option value="null">Select Consultant</option>
                {assigneeData?.map((p, index) => (
                  <option key={index} value={p.value}>
                    {p.label}
                  </option>
                ))}
              </select>
            </div>
            <div class="input-grp col-md-2">
              <label className="area_rowslable">App status</label>
              <select
                className="form-control"
                onChange={handleChangeApplicationStatus}
                name="currentStage"
                style={{ backgroundColor: "#f5f5f9", width: "95%" }}
              >
                <option value="null">- Select App Status -</option>
                <option value="OnGoing">OnGoing</option>
                <option value="AppPreparation">App Preparation</option>
                <option value="AppReady">App Ready</option>
                <option value="VisaLodgement">Visa Lodgement</option>
                <option value="Granted">Granted</option>
                <option value="Refused">Refused</option>
                <option value="Discontinued">Discontinued</option>
              </select>
            </div>

            {/* <Col md={2}>
              <label className="area_rowslable">Visa Expiry Date</label>
              <DateRangePicker onApply={handlevisaexpirydateApply}>
                <p className="datepickerrangevaluetext2" style={{ backgroundColor: "#f5f5f9" }}>
                  {visaexpirydatevalue}
                </p>
              </DateRangePicker>
              {visaexpirydatefilter ? (
                <div style={{ float: "left" }}>
                  <a
                    className={classes.clrsrch}
                    onClick={() => {
                      setvisaexpirydatevalue("Click to open");
                      setdisplay("inline");
                      setdirection("DESC");
                      getallEnquiry(0);
                    }}
                  >
                    Clear Search
                  </a>
                </div>
              ) : null}
            </Col> */}
          </div>
          <br />
          <div
            class="form-grp row pt-3"
            style={{ marginTop: "-40px", marginBottom: "-15px" }}
          >
            <div class="input-grp col-md-2">
              <label className="area_rowslable">Current Stage</label>
              <select
                className="form-control"
                onChange={handleChangeCurrentStage}
                name="currentStage"
                style={{ backgroundColor: "#f5f5f9", width: "95%" }}
              >
                <option value="">- Select Current Stage -</option>
                {/* {user?.isw_user_role == 1 ? (
                      <option value="all" style={{fontWeight:"bold"}}>Show All</option>
                    ) : null} */}
                {allCurrentStage?.map((p, index) => (
                  <option key={index} value={p.currentStage}>
                    {p.currentStage}
                  </option>
                ))}
              </select>
            </div>

            <div class="input-grp col-md-2">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <label
                  className="area_rowslable_new"
                  style={{ fontWeight: "bold" }}
                >
                  Follow-up Date
                </label>
                <div
                  className="clearsearchclass"
                  style={{ width: "100%", marginTop: "0px" }}
                >
                  <form
                    // id="myForm"
                    className="d-flex"
                    style={{ position: "relative" }}
                  >
                    <DateRangePicker onApply={handlefollowupdateApply}>
                      <p
                        className="datepickerrangevaluetextone"
                        style={{
                          backgroundColor: "#f5f5f9",
                          width: "95%",
                          position: "relative",
                        }}
                      >
                        {followupdatevalue}
                      </p>
                    </DateRangePicker>
                    {/* {followupdatefilter ? (
                <div style={{ float: "left" }} className="s_datefilter">
                  <a
                    className={classes.clrsrch}
                    onClick={() => {
                      setfollowupdatevalue("Click to open");
                      setdisplay("inline");
                      setdirection("DESC");
                      getallEnquiry(0);
                    }}
                  >
                    Clear Search
                  </a>
                </div>
              ) : null} */}
                    <span
                      className="listpagesearch_button"
                      style={{
                        top: "10px",
                        position: "absolute",
                        right: "20px",
                        pointerEvents: "none",
                      }}
                    >
                      <i className="fa fa-calendar"></i>
                    </span>
                  </form>
                </div>
              </div>
            </div>
            {/* <Col>
              <label className="area_rowslable">Consultant</label>
              <Select
                isMulti
                name="assigneeId"
                style={{ backgroundColor: "#f5f5f9" }}
                options={assigneeData}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={onSelectWorkload}
                required
              />
            </Col> */}
            <div class="input-grp col-md-2">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <label
                  className="area_rowslable_new"
                  style={{ fontWeight: "bold" }}
                >
                  DeadLine Date
                </label>
                <div
                  className="clearsearchclass"
                  style={{ width: "100%", marginTop: "0px" }}
                >
                  <form
                    // id="myForm"
                    className="d-flex"
                    style={{ position: "relative" }}
                  >
                    <DateRangePicker onApply={handledeadlinedateApply}>
                      <p
                        className="datepickerrangevaluetextone"
                        style={{
                          backgroundColor: "#f5f5f9",
                          width: "95%",
                          position: "relative",
                        }}
                      >
                        {deadlinedatevalue}
                      </p>
                    </DateRangePicker>
                    {/* {deadlinedatefilter ? (
                <div style={{ float: "left" }} className="s_datefilter">
                  <a
                    className={classes.clrsrch}
                    onClick={() => {
                      setdeadlinedatevalue("Click to open");
                      setdisplay("inline");
                      setdirection("DESC");
                      getallEnquiry(0);
                    }}
                  >
                    Clear Search
                  </a>
                </div>
              ) : null} */}
                    <span
                      className="listpagesearch_button"
                      style={{
                        top: "10px",
                        position: "absolute",
                        right: "20px",
                        pointerEvents: "none",
                        maxWidth: "40%",
                      }}
                    >
                      <i className="fa fa-calendar"></i>
                    </span>
                  </form>
                </div>
              </div>
            </div>
            <div class="input-grp col-md-2">
              <label className="area_rowslable">Visa Type</label>
              <select
                className="form-control"
                onChange={handlechangevisatype}
                name="visatype"
                style={{ backgroundColor: "#f5f5f9", width: "95%" }}
              >
                <option value="">- Select Visa Type -</option>
                {allVisaType?.map((p, index) => (
                  <option key={index} value={p.name}>
                    {p.name}
                  </option>
                ))}
              </select>
            </div>
            <div class="input-grp col-md-2">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <label
                  className="area_rowslable_new"
                  style={{ fontWeight: "bold" }}
                >
                  Visa Expiry Date
                </label>
                <div
                  className="clearsearchclass"
                  style={{ width: "100%", marginTop: "0px" }}
                >
                  <form
                    // id="myForm"
                    className="d-flex"
                    style={{ position: "relative" }}
                  >
                    <DateRangePicker onApply={handlevisaexpirydateApply}>
                      <p
                        className="datepickerrangevaluetextone"
                        style={{
                          backgroundColor: "#f5f5f9",
                          width: "95%",
                          position: "relative",
                        }}
                      >
                        {visaexpirydatevalue}
                      </p>
                    </DateRangePicker>
                    {/* {visaexpirydatefilter ? (
                <div style={{ float: "left" }} className="s_datefilter">
                  <a
                    className={classes.clrsrch}
                    onClick={() => {
                      setvisaexpirydatevalue("Click to open");
                      setdisplay("inline");
                      setdirection("DESC");
                      getallEnquiry(0);
                    }}
                  >
                    Clear Search
                  </a>
                </div>
              ) : null} */}
                    <span
                      className="listpagesearch_button"
                      style={{
                        top: "10px",
                        position: "absolute",
                        right: "20px",
                        pointerEvents: "none",
                      }}
                    >
                      <i className="fa fa-calendar"></i>
                    </span>
                  </form>
                </div>
              </div>
            </div>

            <div class="input-grp col-md-1.5" style={{ marginTop: "20px" }}>
              <div className="clearsearchclass" style={{ width: "225px" }}>
                <form
                  id="myForm"
                  className="d-flex"
                  onSubmit={(e) => {
                    e.preventDefault();
                    submit4(e);
                  }}
                >
                  <input
                    className="form-control"
                    placeholder="Search.."
                    name="search"
                  />
                  <button className="btn listpagesearch_button" type="submit">
                    <i className="fa fa-search"></i>
                  </button>
                </form>
              </div>
              {searchdata ? (
                <a
                  className={classes.clrsrch}
                  onClick={() => {
                    setdisplay("inline");
                    setpageno(0);
                    getallEnquiry(0);
                  }}
                >
                  Clear Search
                </a>
              ) : null}
            </div>
          </div>
          {/* <br />
          <Row>
            <Col md={4}>
              <label className="area_rowslable">Consultant</label>
              <Select
                isMulti
                name="assigneeId"
                style={{ backgroundColor: "#f5f5f9" }}
                options={assigneeData}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={onSelectWorkload}
                required
              />
            </Col>
          </Row> */}
        </div>
        <div
          className="newlisting_headertabdesign"
          style={{
            padding: "1.5rem",
            border: "1.5rem solid #e9ebf2",
            marginTop: "0",
            marginBottom: "0",
          }}
        >
          <Row className="s_pagi">
            <Col md={6}>
              {enquiry?.length ? (
                <p className="s_para">
                  {countnumber * rows + 1} to{" "}
                  {countnumber * rows + numberofElements} of {totalUser} records
                </p>
              ) : (
                <p className="s_para">No Record Found</p>
              )}
            </Col>
            {check1pointpermission(406, permissionData, rolepermission) ? (
              <Col md={6} style={{ marginTop: "10px" }}>
                <div style={{ display: "flex", justifyContent: "right" }}>
                  <button className="btn listing_addbutton" onClick={exportPdf}>
                    Export PDF
                  </button>

                  <button
                    type="button"
                    className="btn listing_addbutton"
                    style={{ marginLeft: "25px" }}
                    onClick={(e) => handleAction("DOWNLOAD")}
                  >
                    Export CSV
                  </button>
                  <CSVLink
                    data={dataForDownload}
                    filename="MigrationWorkflow_list.csv"
                    className="hidden"
                    ref={csvLink}
                    target="_blank"
                  />
                </div>
              </Col>
            ) : null}
          </Row>
          <div className="above-scroller" onScroll={scrolldiv}>
            <div className="scroller"></div>
          </div>
          <div className="displaytablerow">
            <Table
              className="workload card-table table-borderless"
              responsive
              striped
              onScroll={scrolltable}
            >
              {/* <table
              id="example"
              className="table workload card-table table-borderless table-responsive"
              style={{ width: "100%" }}
            > */}
              <thead className="s_sticky">
                <tr style={{ color: "#6d7290" }}>
                  <th style={{ width: "50px", textAlign: "center" }}>SN</th>
                  <th style={{ minWidth: "95px" }}>
                    DATE {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="createdDate"
                      onClick={sortClient}
                      style={{
                        cursor: "pointer",
                        display: `${display1}`,
                        height: "10px",
                      }}
                    />
                  </th>
                  <th style={{ minWidth: "130px" }}>
                    CLIENT {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="clientUsername"
                      onClick={sortClient}
                      style={{
                        cursor: "pointer",
                        display: `${display1}`,
                        height: "10px",
                      }}
                    />
                  </th>
                  <th style={{ minWidth: "90px" }}>
                    CONTACT {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="client_name"
                      onClick={sortClient}
                      style={{
                        cursor: "pointer",
                        display: `${display1}`,
                        height: "10px",
                      }}
                    />
                  </th>
                  <th style={{ minWidth: "100px" }}>
                    SOURCE {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="enquirySource"
                      onClick={sortClient}
                      style={{
                        cursor: "pointer",
                        display: `${display1}`,
                        height: "10px",
                      }}
                    />
                  </th>
                  <th style={{ minWidth: "85px" }}>
                    STATUS {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="enquiryStatus"
                      onClick={sortClient}
                      style={{
                        cursor: "pointer",
                        display: `${display1}`,
                        height: "10px",
                      }}
                    />
                  </th>
                  <th style={{ minWidth: "115px" }}>
                    VISA TYPE {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="visaType"
                      onClick={sortClient}
                      style={{
                        cursor: "pointer",
                        display: `${display1}`,
                        height: "10px",
                      }}
                    />
                  </th>
                  <th style={{ minWidth: "120px" }}>
                    VISA EXPIRY {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="visaExpiryDate"
                      onClick={sortClient}
                      style={{
                        cursor: "pointer",
                        display: `${display1}`,
                        height: "10px",
                      }}
                    />
                  </th>
                  <th style={{ minWidth: "135px" }}>
                    CURRENT STAGE {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="currentStage"
                      onClick={sortClient}
                      style={{
                        cursor: "pointer",
                        display: `${display1}`,
                        height: "10px",
                      }}
                    />
                  </th>
                  <th style={{ minWidth: "120px" }}>
                    VISA OFFICE {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="institutionName"
                      onClick={sortClient}
                      style={{
                        cursor: "pointer",
                        display: `${display1}`,
                        height: "10px",
                      }}
                    />
                  </th>
                  <th style={{ minWidth: "90px" }}>
                    BRANCH {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="office_name"
                      onClick={sortClient}
                      style={{
                        cursor: "pointer",
                        display: `${display1}`,
                        height: "10px",
                      }}
                    />
                  </th>
                  <th style={{ minWidth: "130px" }}>
                    CONSULTANT {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="consultantName"
                      onClick={sortClient}
                      style={{
                        cursor: "pointer",
                        display: `${display1}`,
                        height: "10px",
                      }}
                    />
                  </th>
                  <th style={{ minWidth: "100px" }}>
                    UPDATED DATE
                    <img
                      src={sort}
                      className="sorting"
                      id="updated"
                      onClick={sortClient}
                      style={{
                        cursor: "pointer",
                        display: `${display1}`,
                        height: "10px",
                      }}
                    />
                  </th>
                  <th style={{ minWidth: "120px" }}>
                    FOLLOW-UP {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="followUpDate"
                      onClick={sortClient}
                      style={{
                        cursor: "pointer",
                        display: `${display1}`,
                        height: "10px",
                      }}
                    />
                  </th>
                  <th style={{ minWidth: "110px" }}>
                    DEADLINE {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="deadLineDate"
                      onClick={sortClient}
                      style={{
                        cursor: "pointer",
                        display: `${display1}`,
                        height: "10px",
                      }}
                    />
                  </th>
                  <th style={{ minWidth: "100px" }}>
                    REMARKS {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="remarks"
                      onClick={sortClient}
                      style={{
                        cursor: "pointer",
                        display: `${display1}`,
                        height: "10px",
                      }}
                    />
                  </th>
                  <th style={{ minWidth: "90px" }}>ACTION</th>
                </tr>
              </thead>
              {enquiry != null ? (
                <tbody>
                  {enquiry?.map((p, index) => (
                    <tr key={p.enquiryId} style={{ color: "#6d7290" }}>
                      <td style={{ textAlign: "center" }}>
                        {countnumber * rows + 1 + index}
                      </td>
                      <td>
                        {p.createdDate
                          ? moment(p.createdDate.split(".")[0]).format(
                              "DD-MMM-YY"
                            )
                          : null}
                        {/* <br />
                        {p.createdDate
                          ? moment(p.createdDate.split(".")[0]).format(
                            "hh.mm a"
                          )
                          : null} */}
                      </td>
                      <td>
                        <Link
                          className="greeneffect"
                          onClick={(e) => viewWorkflow(p, e)}
                          style={{
                            width: "130px",
                            wordWrap: "break-word",
                            display: "inline-block",
                          }}
                          to={
                            `/view-enquiry-workflow/` +
                            p.clientId +
                            `/` +
                            p.enquiryId +
                            `/` +
                            p.clientUsername
                          }
                        >
                          {p.clientName}
                        </Link>
                        <br />
                        <div
                          style={{
                            width: "130px",
                            wordWrap: "break-word",
                            display: "inline-block",
                          }}
                        >
                          {p.clientUsername}
                        </div>
                      </td>
                      <td>
                        <div
                          style={{
                            width: "90px",
                            wordWrap: "break-word",
                            display: "inline-block",
                          }}
                        >
                          {p.clientPhoneNumber == 0 ? " " : p.clientPhoneNumber}
                        </div>
                      </td>
                      <td>{p.enquirySource}</td>
                      {p.enquiryStatus === "Discontinued" ? (
                        <td class="text-danger">
                          <div
                            style={{
                              width: "85px",
                              wordWrap: "break-word",
                              display: "inline-block",
                            }}
                          >
                            {p.enquiryStatus}
                          </div>
                        </td>
                      ) : (
                        <>
                          {p.enquiryStatus === "Completed" ? (
                            <td style={{ color: "green" }}>
                              <div
                                style={{
                                  width: "85px",
                                  wordWrap: "break-word",
                                  display: "inline-block",
                                }}
                              >
                                {p.enquiryStatus}
                              </div>
                            </td>
                          ) : (
                            <td>
                              <div
                                style={{
                                  width: "85px",
                                  wordWrap: "break-word",
                                  display: "inline-block",
                                }}
                              >
                                {p.enquiryStatus}
                              </div>
                            </td>
                          )}
                        </>
                      )}
                      <td>
                        <div
                          style={{
                            width: "115px",
                            wordWrap: "break-word",
                            display: "inline-block",
                          }}
                        >
                          {p.visaType}
                        </div>
                      </td>
                      <td>
                        {p.visaExpiryDate
                          ? moment(p.visaExpiryDate.split(".")[0]).format(
                              "DD-MMM-YY"
                            )
                          : null}
                        {/* <br />
                        {p.visaExpiryDate
                          ? moment(p.visaExpiryDate.split(".")[0]).format(
                              "hh.mm a"
                            )
                          : null} */}
                      </td>
                      <td>
                        <div
                          style={{
                            width: "135px",
                            wordWrap: "break-word",
                            display: "inline-block",
                          }}
                        >
                          {p.currentStage}
                        </div>
                      </td>
                      <td>
                        <div
                          style={{
                            width: "115px",
                            wordWrap: "break-word",
                            display: "inline-block",
                          }}
                        >
                          {p.institutionName}
                        </div>
                      </td>
                      <td>
                        <div
                          style={{
                            width: "90px",
                            wordWrap: "break-word",
                            display: "inline-block",
                          }}
                        >
                          {p.officeName}
                        </div>
                        <br />
                        <div
                          style={{
                            width: "90px",
                            wordWrap: "break-word",
                            display: "inline-block",
                          }}
                        >
                          {p.country}
                        </div>
                      </td>
                      <td>
                        <>
                          <div class="s_tooltip">
                            {p.consultantName != null
                              ? p.consultantName.split(" ")[0]
                              : ""}
                            <span class="s_tooltiptext">
                              {p.consultantName}
                            </span>
                          </div>
                        </>
                      </td>
                      <td>
                        {p.updated
                          ? moment(p.updated.split(".")[0]).format("DD-MMM-YY")
                          : null}
                      </td>
                      <td>
                        {p.followUpDate
                          ? moment(p.followUpDate.split(".")[0]).format(
                              "DD-MMM-YY"
                            )
                          : null}
                        {/* <br />
                        {p.followUpDate
                          ? moment(p.followUpDate.split(".")[0]).format(
                            "hh.mm a"
                          )
                          : null} */}
                      </td>
                      <td>
                        {p.deadLineDate
                          ? moment(p.deadLineDate.split(".")[0]).format(
                              "DD-MMM-YY"
                            )
                          : null}
                        {/* <br />
                        {p.deadLineDate
                          ? moment(p.deadLineDate.split(".")[0]).format(
                            "hh.mm a"
                          )
                          : null} */}
                      </td>
                      <td>
                        <div
                          style={{
                            width: "100px",
                            wordWrap: "break-word",
                            display: "inline-block",
                          }}
                        >
                          {p.clientDetail?.remarks}
                          {p.clientDetail?.remarks != "" ? (
                            <MigrationViewMoreModal
                              p={p}
                              toast={toast}
                              id={p.id}
                              handle403error={handle403error}
                              sortState={sortState}
                              direction={direction}
                              pageno={pageno}
                              rows={rows}
                            />
                          ) : null}
                        </div>
                      </td>
                      {/* <td> */}
                      {/* <div
                          style={{
                            position: "relative",
                            textAlign: "center",
                          }}
                        >
                          <div
                            className="workflowdashboard_fa-ellipsis"
                            data-toggle="dropdown"
                          >
                            <i
                              className="fa fa-ellipsis-v "
                              aria-hidden="true"
                            ></i>
                          </div>
                          <div className="dropdown-menu workflowdashboarddropdown">
                            <p>
                              <Link
                                color="danger"
                                id={p.enquiryId}
                                onClick={(e) => {
                                  e.preventDefault();
                                  openModal(e, p);
                                }}
                              >
                                Archive
                              </Link>
                            </p>
                          </div>
                        </div> */}
                      {/* <button
                          className="role-deletebtn"
                          id={p.enquiryId}
                          onClick={(e) => {
                            e.preventDefault();
                            openModal(e, p);
                          }}
                        >
                          ARCHIVE
                        </button> */}
                      {/* <MigrationArchive
                          deleteEnquiry={p.enquiryId}
                          deletedUser={deletedUser}
                          getallEnquiry={getallEnquiry}
                          setIsOpen={setIsOpen}
                          handle403error={handle403error}
                          jwtToken={jwtToken}
                        /> */}

                      {/* <Modal
                          isOpen={modalIsOpen}
                          onRequestClose={closeModal}
                          style={customStyles}
                          contentLabel="Example Modal"
                        >
                          <h5>
                            Are you sure you want to delete current record?
                          </h5>
                          <div style={{ float: "right" }}>
                            <Button color="danger" onClick={deleteenquiry}>
                              Delete
                            </Button>
                            &nbsp;&nbsp;&nbsp;
                            <Button color="secondary" onClick={closeModal}>
                              Cancel
                            </Button>
                          </div>
                        </Modal> */}
                      {/* </td> */}
                      <td id={p.id}>
                        <div
                          style={{
                            position: "relative",
                            textAlign: "center",
                          }}
                        >
                          <div
                            className="workflowdashboard_fa-ellipsis"
                            data-toggle="dropdown"
                            style={{ width: "50px" }}
                          >
                            <i
                              className="fa fa-ellipsis-v "
                              aria-hidden="true"
                            ></i>
                          </div>
                          <div className="dropdown-menu workflowdashboarddropdown">
                            <ul style={{ margin: "0px" }} className="ulclass">
                              <li>
                                <p>
                                  <Link
                                    id={p.clientUsername}
                                    //onClick={(e) => viewClient(e, p)}
                                    to={`/edit-client/` + p.clientUsername}
                                    title="Edit"
                                    className="aclass"
                                  >
                                    Edit
                                  </Link>
                                </p>
                              </li>
                              <li>
                                <p>
                                  {p.status === 0 ? (
                                    <MigrationArchive
                                      deleteEnquiry={p.enquiryId}
                                      deletedUser={deletedUser}
                                      getallEnquiry={getallEnquiry}
                                      setIsOpen={setIsOpen}
                                      handle403error={handle403error}
                                      jwtToken={jwtToken}
                                    />
                                  ) : (
                                    <MigrationArchive
                                      deleteEnquiry={p.enquiryId}
                                      deletedUser={deletedUser}
                                      getallEnquiry={getallEnquiry}
                                      setIsOpen={setIsOpen}
                                      handle403error={handle403error}
                                      jwtToken={jwtToken}
                                    />
                                  )}
                                </p>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <Typography>{null}</Typography>
              )}
            </Table>
          </div>
          <Row className="s_pagirow">
            <Col md={6}>
              <div>
                <form>
                  <div style={{ float: "left" }}>
                    <FormControl
                      variant="standard"
                      style={{
                        display: "inline-block",
                        marginRight: "25px",
                        minWidth: "50px",
                      }}
                    >
                      <select
                        className={classes.selectlimit}
                        labelId="simple-select-label"
                        id="simple-select"
                        value={rows}
                        onChange={handleRows}
                      >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                    </FormControl>
                    {enquiry?.length ? (
                      <div className="s_spanPage">
                        {countnumber * rows + 1} to{" "}
                        {countnumber * rows + numberofElements} of {totalUser}{" "}
                        records
                      </div>
                    ) : (
                      <div className="s_spanPage">No Record Found</div>
                    )}
                  </div>
                </form>
              </div>
            </Col>
            <Col md={6}>
              <div className={classes.root} style={{ float: "right" }}>
                {searchdata ? (
                  <Pagination
                    page={paginationpageno}
                    count={getpageno}
                    onChange={handlesearch}
                    shape="rounded"
                  />
                ) : (
                  <Pagination
                    page={paginationpageno}
                    count={getpageno}
                    onChange={handlePage}
                    shape="rounded"
                  />
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
}

export default MigrationWorkFlowLists;
