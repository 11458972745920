import React, { useState, useRef } from "react";
import Modal from "react-modal";
import { Button } from "reactstrap";
import { mycustomStyles } from "../../../Utilities/CSSUtilities";
import { customStyles } from "../../../Utilities/CSSUtilities";
import SunEditor from "suneditor-react";
import axios from "axios";
import base_url from "./../../../api/bootapi";
import { ToastContainer, toast } from "react-toastify";

const ClientViewMoreModal = ({
  p,
  id,
  headData,
  handle403error,
  sortState,
  direction,
  pageno,
  rows,
  allclientdetails,
  statusstate,
}) => {
  const [deletemodal, setdeletemodal] = useState(false);
  const [descriptionState2, setdescriptionState2] = useState(null);
  const [rema, setrema] = useState(false);
  const [getpageno, setgetpageno] = useState();
  const [totalUser, settotalUser] = useState();
  const [countnumber, setcountnumber] = useState();
  const [numberofElements, setnumberofElements] = useState();
  const [enquiry, setEnquiry] = useState(null);

  //console.log(p);
  const isw_user_name = localStorage.getItem("isw_user_name");
  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  const closedeletemodal = () => setdeletemodal(false);

  const handleImageUploadBefore = (files, info, uploadHandler) => {
    //console.log(files, info);
  };
  const editorRef = useRef();

  const changeRemarks = (e) => {
    p.remarks = e;
    setdescriptionState2(p.remarks);
  };

  const editRemark = (e) => {
    const isoDateString = new Date().toISOString();
    const datenow = isoDateString.split(".")[0];
    const data = {
      id: id,
      addedBy: isw_user_name,
      updatedOn: datenow,
    };
    axios
      .post(
        `${base_url.api3}/updateRemarkByEmail`,
        {},
        {
          headers: {
            Authorization: jwtToken,
            comment: descriptionState2,
            ExistingEmail: p.email,
          },
        }
      )
      .then((res) => {
        toast.success("Remarks Updated");
        allclientdetails(0, statusstate);
        setdeletemodal(false);
      })
      .catch((err) => {
        handle403error(err);
      });
  };

  const [view, setview] = useState(false);
  const opendeletemodal = () => {
    setdeletemodal(true);
    setrema(p.comment);
  };
  //console.log(p);
  return (
    <>
      <button
        style={{ cursor: "pointer" }}
        className="role-deletebtn"
        onClick={opendeletemodal}
      >
        View
      </button>
      <Modal
        isOpen={deletemodal}
        onRequestClose={closedeletemodal}
        style={mycustomStyles}
        contentLabel="Example Modal"
      >
        <h4 style={{ marginBottom: "20px" }}>Remark</h4>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            editRemark(e);
          }}
        >
          <SunEditor
            rows={12}
            ref={editorRef}
            style={{ backgroundColor: "#eee" }}
            // style={{ minWidth: "600px" , backgroundColor:"#eee" }}
            height="450"
            setContents={rema}
            onChange={(e) => changeRemarks(e)}
            onImageUploadBefore={handleImageUploadBefore}
            setOptions={{
              buttonList: [
                [
                  "bold",
                  "underline",
                  "italic",
                  "strike",
                  "list",
                  "align",
                  "outdent",
                  "align",
                  "subscript",
                  "fontColor",
                  "imageGallery",
                  "template",
                  "video",
                ],
              ],
            }}
            required
          />
          <div
            style={{
              display: "flex",
              marginTop: "20px",
              justifyContent: "flex-end",
              gap: "10px",
            }}
          >
            <button
              color="secondary"
              className="btn btn-secondary cancel_margin"
              onClick={() => setdeletemodal(false)}
              style={{ width: "85px" }}
            >
              Cancel
            </button>
            <button className="btn listing_addbutton ml-1" type="submit">
              Update
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default ClientViewMoreModal;
