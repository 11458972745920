import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    user: null,
    workflowType: null,
    dashboardcheck: 0,
    tabvalue: 0,
    invoiceTabValue: 0,
    compress: false,
    officeId: null,
  },
  reducers: {
    login: (state, action) => {
      state.user = action.payload;
    },
    logout: (state) => {
      state.user = null;
      state.user = null;
      state.workflowType = null;
      state.dashboardcheck = 0;
      state.tabvalue = 0;
      state.invoiceTabValue = 0;
      state.compress = false;
      state.officeId = null;
    },
    getworkflowType: (state, action) => {
      state.workflowType = action.payload;
    },
    gettabvalue: (state, action) => {
      state.tabvalue = action.payload;
    },
    getInvoiceTabValue: (state, action) => {
      state.invoiceTabValue = action.payload;
    },
    removeworkflowType: (state) => {
      state.workflowType = null;
    },
    setdashboardcheck: (state, action) => {
      state.dashboardcheck = action.payload;
    },
    removedashboardcheck: (state) => {
      state.dashboardcheck = null;
    },
    getCompress: (state, action) => {
      state.compress = action.payload;
    },
    setOfficeId: (state, action) => {
      state.officeId = action.payload;
    }
  },
});

export const {
  login,
  logout,
  getworkflowType,
  gettabvalue,
  getInvoiceTabValue,
  removeworkflowType,
  setdashboardcheck,
  removedashboardcheck,
  getCompress,
  setOfficeId
} = userSlice.actions;

export const selectUser = (state) => state.user.user;
export const selectworkflowType = (state) => state.user.workflowType;
export const selecttabvalue = (state) => state.user.tabvalue;
export const selectInvoiceTabValue = (state) => state.user.invoiceTabValue;
export const selectdashboardcheck = (state) => state.user.dashboardcheck;
export const selectCompress = (state) => state.user.compress;
export const getOfficeId = (state) => state.user.officeId;

export default userSlice.reducer;
