import React, { useState } from "react";
import Modal from "react-modal";
import { Col, Row, Button } from "reactstrap";
import axios from "axios";
import base_url from "../../../api/bootapi";
import { customStyles, useStyles } from "../../../Utilities/CSSUtilities";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Select from "react-select";
import callsaveauditapi from "../../../services/auditservice";
import { CountryDropdown } from "react-country-region-selector";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const EditWorkFlowModal = ({
  toast,
  handle403error,
  pageno,
  jwtToken,
  isw_user_name,
  register,
  getallWorkflow,
  p,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [editmodal, seteditmodal] = useState(false);
  const [modalIsOpen3, setIsOpen3] = React.useState(false);
  const [stateCust, setstateCust] = useState();
  const [editworkflowid, seteditworkflowid] = useState(p.workflowId);
  const [editworkflowname, seteditworkflowname] = useState(p.workflowName);
  const [editworkflowtype, seteditworkflowtype] = useState(p.workflowType);
  const [editworkflowstatus, seteditworkflowstatus] = useState(p.status);

  //console.log(p);

  const classes = useStyles();
  const closeeditModal = () => {
    seteditmodal(false);
  };

  const openeditmodal = () => {
    seteditmodal(true);
  };

  const Updateworkflow = (e) => {
    //console.log("entering");
    let data = {
      workflowId: editworkflowid,
      workflowType: editworkflowtype,
      status: editworkflowstatus,
      workflowName: editworkflowname,
    };
    //console.log(data);
    axios
      .post(`${base_url.api2}/updateWorkflowStepOrderByStepId`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        //console.log(response.data.data.data);
        toast.success("Success");
        seteditmodal(false);

        let log = isw_user_name + " Edited Workflow " + editworkflowname + ".";
        callsaveauditapi(log, dispatch, navigate);
        getallWorkflow(pageno);
      })
      .catch((error) => {
        handle403error(error);
        toast.error(JSON.stringify(error.response.message));
        seteditmodal(false);
      });
  };

  return (
    <>
      <span style={{ cursor: "pointer" }} onClick={openeditmodal}>
        Edit
      </span>
      <Modal
        isOpen={editmodal}
        onRequestClose={closeeditModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <h3 style={{ color: "black" }}>WorkFlow</h3>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            Updateworkflow(e);
          }}
        >
          <div>
            <div className="form-group creatadmin">
              <Row>
                <Col md={12}>
                  <label>
                    Name
                    <span style={{ color: "red" }}>*</span>:
                  </label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    value={editworkflowname}
                    onChange={(e) => {
                      seteditworkflowname(e.target.value);
                    }}
                    required
                  // readOnly
                  />
                </Col>
              </Row>
              <br />
              <Row>
                <Col md={12}>
                  <label>
                    Type
                    <span style={{ color: "red" }}>*</span>:
                  </label>
                  <select
                    className={classes.selectdrop}
                    name="workflowType"
                    value={editworkflowtype}
                    onChange={(e) => {
                      seteditworkflowtype(e.target.value);
                    }}
                    ref={register({ required: true })}
                  >
                    <option value="Admission">Admission</option>
                    <option value="Migration">Migration</option>
                    <option value="Insurance">Insurance</option>
                    <option value="SkillAssessment">Skill Assessment</option>
                  </select>
                </Col>
              </Row>
              <br />
              <Row>
                <Col md={12}>
                  <label>
                    Status
                    <span style={{ color: "red" }}></span>:
                  </label>
                  <select
                    className={classes.selectdrop}
                    name="status"
                    value={editworkflowstatus}
                    onChange={(e) => {
                      seteditworkflowstatus(e.target.value);
                    }}
                    required
                  >
                    <option value="1">Active</option>
                    <option value="0">Inactive</option>
                  </select>
                </Col>
              </Row>
              <Row style={{ marginTop: "20px" }}>
                <Col md={12}>
                  <div className="d-flex justify-content-end mt-2">
                    <Button color="secondary" onClick={closeeditModal}>
                      Cancel
                    </Button>
                    <button
                      type="submit"
                      className="btn listing_addbutton ml-1"
                    >
                      Submit
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default EditWorkFlowModal;
