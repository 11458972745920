import React, { useState, useEffect } from "react";
import axios from "axios";
import base_url from "../../api/bootapi";
import "../css/Vertical.css";
import { toast } from "react-toastify";
import { useParams, Link, useNavigate } from "react-router-dom";
import moment from "moment";
import "../css/ViewEnquiryWorkFlow.css";
import PropTypes from "prop-types";
import Badge from "@mui/material/Badge";
import { deepOrange } from "@mui/material/colors";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { makeStyles, withStyles } from "@mui/styles";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";
import EnqyuiryDetails from "./EnqyuiryDetails";
import { useDispatch, useSelector } from "react-redux";
import EnquiryNotesDisplay from "./EnquiryNotesDisplay";

import {
  selectworkflowType,
  selecttabvalue,
  gettabvalue,
  logout,
  selectUser,
} from "../features/userSlice";
import WorkflowListing from "./WorkflowListing";
import InvoiceList from "../Invoice/InvoiceList";
import { selectdashboard } from "../features/dashboardSlice";
import WorkflowDashboard from "./WorkflowDashboard";
import ClientImage from "./../images/client-dashboard/client-image.png";
import Rocket from "./../images/client-dashboard/rocket.svg";
import SnowFlake from "./../images/client-dashboard/snow-flake.svg";
import Coffee from "./../images/client-dashboard/coffee.svg";
import Sun from "./../images/client-dashboard/sun.svg";
import {
  check4pointpermission,
  check1pointpermission,
} from "../Permission/permission";
import Eoi from "./Eoi";
import Pte from "./Pte";

const drawerWidth = 300;
const drawerWidth2 = 60;
const selectheight = "35px";
const selectWidth = "100%";
const headtxt = 30;

function TabPanel(props) {
  //console.log("props.index----------------->", props.index);
  //console.log("props.component------------------>", props.component);
  return (
    <div
      role="tabpanel"
      hidden={props.value !== props.index}
      id={`simple-tabpanel-${props.index}`}
      aria-labelledby={`simple-tab-${props.index}`}
    >
      {props.value === props.index && props.component}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "$ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
    zIndex: "0",
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}))(Badge);
const useStyles = makeStyles((theme) => ({
  toolbar: {
    marginLeft: drawerWidth,
    marginRight: drawerWidth2,
  },
  selectdrop: {
    width: selectWidth,
    height: selectheight,
  },
  headtext: {
    fontSize: headtxt,
  },
  viewheadtext: {
    fontSize: "10",
  },
  createBtn2: {
    color: "white",
    textDecoration: "none",
  },
  createBtn: {
    float: "right",
  },
  modalcss: {
    marginTop: "10%",
  },
  backdrop: {
    // zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    zIndex: 1000,
  },
  dropdownbtn: {
    background: "blue",
    color: "white",
    padding: "5px",
    borderRadius: "5px",
  },
  dropdownbtn2: {
    background: "white",
    color: "black",
  },
  root: {
    flexGrow: 1,
    width: "100%",
    // backgroundColor: theme.palette.background.paper,
  },
  orange: {
    // color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
    // width: theme.spacing(7),
    // height: theme.spacing(7),
  },
}));

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    // color: theme.palette.common.black,
  },
  tooltip: {
    // backgroundColor: theme.palette.common.black,
  },
}));
function BootstrapTooltip(props) {
  const classes2 = useStylesBootstrap();

  return <Tooltip arrow classes={classes2} {...props} />;
}

function ViewEnquiryWorkFlow(props) {
  const [getNoOfCount, SetNoOfCount] = useState([]);
  const [getNoOfProspect, SetNoOfProspect] = useState([]);
  const user = useSelector(selectUser);
  const [backdropopen, setbackdropOpen] = React.useState(false);
  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  const params = useParams();
  const workflowtyperedux = useSelector(selectworkflowType);
  const classes = useStyles();
  const tabvalue = useSelector(selecttabvalue);
  const dispatch = useDispatch();
  const [refreshit, setrefreshit] = useState("refresh");
  //console.log(params);
  const navigate = useNavigate();
  const [value, setValue] = React.useState(tabvalue);
  const [getpageno, setgetpageno] = useState();
  const [pageno, setpageno] = useState(0);
  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);
  const isw_user_name = localStorage.getItem("isw_user_name");
  const [workFlowStepsId, setworkFlowStepsId] = useState();

  const handleChange = (event, newValue) => {
    //console.log("Value", newValue);
    dispatch(gettabvalue(newValue));
    setValue(newValue);
  };

  const handlebackdropopen = () => {
    setbackdropOpen(true);
  };
  const handlebackdropclose = () => {
    setbackdropOpen(false);
  };

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        navigate("/");
      }
    });
    if (refreshit === "refresh") {
      setbackdropOpen(true);
      localStorage.setItem("refreshing", "refresh");
      getEnquiry();
      getWorkflow();
      getClientDetails();
      getAllNotes(pageno);
      getAttachments();
      getPermissions();
    }
  }, [refreshit]);

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);

    if (check1pointpermission(306, permission_obj, resource_obj)) {
    } else {
      navigate("/errorPage");
    }
  };

  const [enquiryId, setenquiryId] = useState();
  const [allnotes, setallnotes] = useState();
  const [notesdata, setnotesdata] = useState();
  const [notesPagination, setpagination] = useState();
  const [attachments, setattachments] = useState();
  const [attachmentPagination, setattachmentPagination] = useState();
  const [workflowSteps, setWorkflowSteps] = useState(null);
  const [content, setcontent] = useState();
  const [workflowName, setworkflowName] = useState();
  const [coursedetail, setcoursedetail] = useState();
  const [clientId, setclientId] = useState();
  const [displayStatus, setDisplayStatus] = useState();

  const handlepage = (event, value) => {
    setpageno(value - 1);
    getAllNotes(value - 1);
  };

  const [enquiryData, setEnquiryData] = useState();
  const [workflowStepsArray, setWorkflowStepsArray] = useState([]);

  const getEnquiry = () => {
    console.log("coming here 270");
    const permission_obj = [];
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);

    setbackdropOpen(true);
    setcontent(null);
    setenquiryId(null);
    setWorkflowSteps(null);
    axios
      .get(`${base_url.api2}/getEnquiryWorkflowByEnquiryId`, {
        headers: {
          Authorization: jwtToken,
          enquiryId: params.enquiry_id,
        },
      })
      .then((res) => {
        axios
          .get(`${base_url.api2}/getEnquiryWorkflowStepByWorkflowId`, {
            headers: {
              Authorization: jwtToken,
              workflowId: res?.data?.data[0].enquiryWorkflowId,
            },
          })
          .then((res) => {
            //console.log("coming here 298", res?.data?.data?.workflowSteps);
            const workflowStepWithClient1 = res?.data?.data?.find(
              (step) => step.workFlowStepsConvertClient === 1
            );
            //console.log("336 workflowStepWithClient1", workflowStepWithClient1);
            if (workflowStepWithClient1) {
              //console.log('333 Workflow step with workFlowStepsConvertClient equal to 1:', workflowStepWithClient1);
              //console.log('334 workFlowStepsId:', workflowStepWithClient1.workFlowStepsId);
              setworkFlowStepsId(workflowStepWithClient1?.workFlowStepsId);
              getAllWorkflowStepByStepId(
                workflowStepWithClient1?.workFlowStepsId
              );
            } else {
              //console.log('No workflow step found with workFlowStepsConvertClient equal to 1');
            }
            setworkflowName(res?.data?.data?.workflowName);
          })
          .catch((err) => {
            //console.log("336 err", err);
            setbackdropOpen(false);
          });

        // console.log("316-------->", res?.data?.data);
        const data = res?.data?.data;
        if (Array.isArray(data) && data.length > 0) {
          const lastIndex = data.length - 1;
          setWorkflowSteps(data[lastIndex].enquiryWorkflowSteps); // Set the steps from the last object
          setclientId(data[lastIndex].clientId); // Set the clientId from the last object

          const filteredData = data.slice(0, -1); // Exclude the last element
          const workflowStepsArray = filteredData.map(
            (item) => item.enquiryWorkflowSteps
          );
          console.log("326----------->", workflowStepsArray);
          setWorkflowStepsArray(workflowStepsArray);
        } else {
          console.error("Unexpected data format", data);
        }

        // const newworkflowdata = res?.data?.data?.enquiryWorkflowSteps;
        // const refundProcessingIndex =
        //   res?.data?.data?.enquiryWorkflowSteps.findIndex(
        //     (step) => step.workflowStepsName === "Refund Processing"
        //   );

        // If the "Refund Processing" step exists, update the status of all preceding steps to 1
        //console.log("287----->", newworkflowdata[refundProcessingIndex].status);

        // if (refundProcessingIndex !== -1) {
        //   if (newworkflowdata[refundProcessingIndex].status === 1) {
        //     newworkflowdata.forEach((step, index) => {
        //       if (index < refundProcessingIndex) {
        //         step.status = 1;
        //       }
        //     });
        //     setWorkflowSteps(newworkflowdata);
        //   } else {
        //     setWorkflowSteps(res?.data?.data?.enquiryWorkflowSteps);
        //   }
        // }

        getEnquiryDetails();
        if (workflowtyperedux == "Admission") {
          setValue(1);
        } else if (workflowtyperedux == "Migration") {
          setValue(2);
        } else if (workflowtyperedux == "Insurance") {
          setValue(3);
        } else if (workflowtyperedux == "SkillAssessment") {
          setValue(4);
        } else if (workflowtyperedux == null) {
          setValue(0);
        } else {
          setValue(0);
        }
      })
      .catch((err) => {
        setbackdropOpen(false);
        handle403error(err);
      });
  };

  const [enquiryvisaDateDiff, setenquiryvisaDateDiff] = useState();

  const getEnquiryDetails = () => {
    axios
      .get(`${base_url.api2}/getEnquiryById`, {
        headers: {
          Authorization: jwtToken,
          enquiryId: params.enquiry_id,
        },
      })
      .then(
        (res) => {
          //console.log("Enquiry Response", res?.data?.data);
          setEnquiryData(res?.data?.data);
          setDisplayStatus(res?.data?.data?.enquiryStatus);
          setcontent(res?.data?.data);
          if (res?.data?.data?.courseName) {
            getCourseId(res?.data?.data?.courseId);
          }

          let isoDateString = new Date().toISOString();
          let datenow = isoDateString.split(".")[0];
          if (res?.data?.data?.visaExpiryDate) {
            let visa_date = moment(res?.data?.data?.visaExpiryDate).format(
              "YYYY-MM-DD"
            );
            let datetoday = moment(datenow).format("YYYY-MM-DD");
            setenquiryvisaDateDiff(
              moment(visa_date).diff(moment(datetoday), "day")
            );

            var sangam = moment(visa_date).diff(moment(datetoday), "day");
            //console.log(sangam);

            var sangamyear = Math.trunc(sangam / 365);
            //console.log("sangamyear------->", sangamyear);
            var sangamdayremaining = Math.trunc(sangam % 365);
            //console.log("sangamdayremaining----------->", sangamdayremaining);
            var sangammonth = Math.trunc(sangamdayremaining / 30);
            //console.log("sangammonth-------->", sangammonth);
            var sangamday = Math.trunc(sangamdayremaining % 30);
            //console.log("sangamday ------->", sangamday);

            if (sangamyear < 0 || sangammonth < 0 || sangamday < 0) {
              setenquiryvisaDateDiff(`(` + "Visa Already Expired" + `)`);
            } else if (sangamyear >= 0 && sangamyear < 1) {
              setenquiryvisaDateDiff(
                `(` +
                  sangammonth +
                  " months " +
                  sangamday +
                  " days" +
                  " remaining" +
                  `)`
              );
            } else {
              setenquiryvisaDateDiff(
                `(` +
                  sangamyear +
                  " years " +
                  sangammonth +
                  " months " +
                  sangamday +
                  " days" +
                  " remaining" +
                  `)`
              );
            }
          }

          setbackdropOpen(false);

          // getworkflowid(res?.data?.data?.enquiryId);
        },
        (error) => {
          setbackdropOpen(false);
          handle403error(error);
          toast.error(JSON.stringify(error.response.data.message));
        }
      );
  };

  const getCourseId = (value) => {
    axios
      .get(`${base_url.api2}/getCourseById`, {
        headers: {
          Authorization: jwtToken,
          courseId: Number(value),
        },
      })
      .then((res) => {
        //console.log("Course Details", res?.data?.data);
        setcoursedetail(res?.data?.data);
        setbackdropOpen(false);
      })
      .catch((error) => {
        setbackdropOpen(false);

        toast.error(JSON.stringify(error.response.data.message));
        handle403error(error);
      });
  };
  const getAllNotes = (value) => {
    // setallnotes(null);
    //console.log("EnquiryId", value1);
    axios
      .get(`${base_url.api2}/getNotesByEnquiryId`, {
        headers: {
          Authorization: jwtToken,
          enquiryId: params.enquiry_id,
          page: value,
          size: 20,
        },
      })
      .then(
        (res) => {
          setnotesdata(res?.data?.data);
          setallnotes(res?.data?.data?.content);
          setgetpageno(res?.data?.data?.totalPages);
        },
        (error) => {
          handle403error(error);
        }
      );
  };

  const getAttachments = () => {
    //console.log("EnquiryId", value1);
    axios
      .get(`${base_url.api2}/getAttachmentsByEnquiryId`, {
        headers: {
          Authorization: jwtToken,
          enquiryId: params.enquiry_id,
          page: 0,
          size: 20,
        },
      })
      .then((res) => {
        for (let j = 0; j < res?.data?.data?.length; j++) {
          res.data.data[j].oldFileName = res.data.data[j].attachmentName;
        }
        //console.log("Attachment Response", res?.data?.data);
        setattachments(res?.data?.data);
        let pages = [];
        for (let i = 0; i < res?.data?.data?.totalPages; i++) {
          pages.push(i + 1);
        }
        setattachmentPagination(pages);
      })
      .catch((error) => {
        handle403error(error);
        setattachments([]);
      });
  };
  //console.log("Attachment", attachmentPagination);
  const [clientDetail1, setclientDetail1] = useState();
  const [visaDateDiff, setvisaDateDiff] = useState();
  const [clientDetail2, setclientDetail2] = useState();
  const getClientDetails = () => {
    axios
      .get(`${base_url.api3}/getClientByUsername`, {
        headers: {
          Authorization: jwtToken,
          username: params.client_name,
        },
      })
      .then((res) => {
        setclientDetail1(res?.data?.data?.clientDetail);
        setclientDetail2(res?.data?.data);
        let isoDateString = new Date().toISOString();
        let datenow = isoDateString.split(".")[0];
        if (res?.data?.data?.clientDetail.visaExpiry) {
          let visa_date = moment(
            res?.data?.data?.clientDetail.visaExpiry
          ).format("YYYY-MM-DD");
          let datetoday = moment(datenow).format("YYYY-MM-DD");
          setvisaDateDiff(moment(visa_date).diff(moment(datetoday), "day"));

          var sangam = moment(visa_date).diff(moment(datetoday), "day");
          //console.log(sangam);

          var sangamyear = Math.trunc(sangam / 365);
          //console.log("sangamyear------->", sangamyear);
          var sangamdayremaining = Math.trunc(sangam % 365);
          //console.log("sangamdayremaining----------->", sangamdayremaining);
          var sangammonth = Math.trunc(sangamdayremaining / 30);
          //console.log("sangammonth-------->", sangammonth);
          var sangamday = Math.trunc(sangamdayremaining % 30);
          //console.log("sangamday ------->", sangamday);

          if (sangamyear < 0 || sangammonth < 0 || sangamday < 0) {
            setvisaDateDiff(`(` + "Visa Already Expired" + `)`);
          } else if (sangamyear >= 0 && sangamyear < 1) {
            setvisaDateDiff(
              `(` +
                sangammonth +
                " months " +
                sangamday +
                " days" +
                " remaining" +
                `)`
            );
          } else {
            setvisaDateDiff(
              `(` +
                sangamyear +
                " years " +
                sangammonth +
                " months " +
                sangamday +
                " days" +
                " remaining" +
                `)`
            );
          }
        }
      })
      .catch((error) => {
        handle403error(error);
      });
  };
  const [allworkflow, setWorkflow] = useState(null);
  const getWorkflow = () => {
    axios
      .get(`${base_url.api2}/getAllWorkflow`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        setWorkflow(response.data.data.content);
      })
      .catch((error) => {
        handle403error(error);
      });
  };

  const [getWorlflowCovertClientDetails, setWorlflowCovertClientDetails] =
    useState();

  const getAllWorkflowStepByStepId = (stepid) => {
    axios
      .get(`${base_url.api2}/getAllWorkflowStepByStepId`, {
        headers: {
          Authorization: jwtToken,
          workflowStepId: stepid,
        },
      })
      .then((response) => {
        //console.log(response);
        setWorlflowCovertClientDetails(response?.data?.data);
        getAppCountOnAppStatus(response?.data?.data?.workflowStepsStatus);
        getprospectCountOnAppStatus(response?.data?.data?.workflowStepsStatus);
      })
      .catch((error) => {
        handle403error(error);
      });
  };

  //console.log("619------------->", getWorlflowCovertClientDetails?.workflowStepsName);

  const getAppCountOnAppStatus = (appStatus) => {
    axios
      .get(`${base_url.api2}/getAppCountOnAppStatus`, {
        headers: {
          aipFlag: 0,
          appStatus: appStatus,
          Authorization: jwtToken,
          ClientId: params.client_id,
        },
      })
      .then((response) => {
        //console.log(response);
        SetNoOfCount(response?.data?.data);
      })
      .catch((error) => {
        handle403error(error);
      });
  };

  const getprospectCountOnAppStatus = (appStatus) => {
    axios
      .get(`${base_url.api2}/getAppCountOnAppStatus`, {
        headers: {
          aipFlag: 1,
          appStatus: appStatus,
          Authorization: jwtToken,
          ClientId: params.client_id,
        },
      })
      .then((response) => {
        //console.log(response);
        SetNoOfProspect(response?.data?.data);
      })
      .catch((error) => {
        handle403error(error);
      });
  };

  const dashboardvalue = useSelector(selectdashboard);
  const updateenquiryleadtype = (value) => {
    setbackdropOpen(true);
    const data = {
      enquiryId: params.enquiry_id,
      enquiryLeadType: value,
    };
    axios
      .post(`${base_url.api2}/updateEnquiryByEnquiryId`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        toast.success("EnquiryLeadType Updated");
        getEnquiryDetails();
      })
      .catch((err) => {
        setbackdropOpen(false);
        handle403error(err);
      });
  };

  const viewClient = (elem) => {
    localStorage.setItem("comingfromwhere", "workflowenquirypage");
    navigate("/client-view/" + params.client_name);
  };

  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            navigate("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };

  return (
    <div className="dummy">
      <Backdrop className={classes.backdrop} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div
        class="page-head d-flex justify-content-between p-4"
        style={{ marginTop: "5rem" }}
      >
        <ul class="breadcrums">
          <h3 class="page-title bold">Manage Workload</h3>
          <Link to="/" class="b-link">
            Home
          </Link>
          -<a class="b-link">Workload Admission</a>-
          <a class="b-link active">Dashboard </a>
        </ul>
      </div>
      <div class="page-card_container p-4">
        <div class="content-wrapper">
          <div class="card client-profile" id="cardClient">
            <div class="minimize-profile">
              <span
                onClick={() => {
                  let card = document.getElementById("cardClient");
                  card.style.display = "none";
                  let cardNum = document.getElementById("cardNumber");
                  cardNum.style.marginLeft = "0px";
                  let right_sp = document.getElementById("right_span");
                  right_sp.style.visibility = "visible";
                }}
              >
                {" "}
                &#171;{" "}
              </span>
            </div>
            <div class="options">
              <input type="checkbox" id="option-checkbox" class="checkbox" />
              <label class="icon" for="option-checkbox">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <rect width="24" height="24" rx="6" fill="white" />
                  <path
                    d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                    stroke="#B1B1CA"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12 8C12.5523 8 13 7.55228 13 7C13 6.44772 12.5523 6 12 6C11.4477 6 11 6.44772 11 7C11 7.55228 11.4477 8 12 8Z"
                    stroke="#B1B1CA"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12 18C12.5523 18 13 17.5523 13 17C13 16.4477 12.5523 16 12 16C11.4477 16 11 16.4477 11 17C11 17.5523 11.4477 18 12 18Z"
                    stroke="#B1B1CA"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </label>
              <div class="options-content">
                <ul>
                  <>
                    <li>
                      <Link
                        // id={p.username}
                        onClick={viewClient}
                        to={`/client-view/` + params.client_name}
                      >
                        Edit Client
                      </Link>
                    </li>
                  </>
                  {/* <li>
                    <a href="#">Option 2</a>
                  </li>
                  <li>
                    <a href="#">Option 3</a>
                  </li> */}
                </ul>
              </div>
            </div>
            <div class="client-img">
              <img src={ClientImage} alt="" />
              <h2 class="mt-2">
                {clientDetail1?.firstName} {clientDetail1?.middleName}{" "}
                {clientDetail1?.lastName}
              </h2>

              <div class="status">
                {content?.enquiryLeadType === "Converted" ? (
                  <a style={{ cursor: "pointer" }}>
                    <img src={Rocket} alt="" />
                  </a>
                ) : (
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() => updateenquiryleadtype("Converted")}
                  >
                    <img src={Rocket} alt="" />
                  </a>
                )}
                {content?.enquiryLeadType === "ColdLead" ? (
                  <a style={{ cursor: "pointer" }}>
                    <img src={SnowFlake} alt="" />
                  </a>
                ) : (
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() => updateenquiryleadtype("ColdLead")}
                  >
                    <img src={SnowFlake} alt="" />
                  </a>
                )}
                {content?.enquiryLeadType === "WarmLead" ? (
                  <a style={{ cursor: "pointer" }}>
                    <img src={Coffee} alt="" />
                  </a>
                ) : (
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() => updateenquiryleadtype("WarmLead")}
                  >
                    <img src={Coffee} alt="" />
                  </a>
                )}
                {content?.enquiryLeadType === "HotLead" ? (
                  <a style={{ cursor: "pointer" }}>
                    <img src={Sun} alt="" />
                  </a>
                ) : (
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() => updateenquiryleadtype("HotLead")}
                  >
                    <img className="cursor" src={Sun} alt="" />
                  </a>
                )}
              </div>
              {/* <div className="dummy_contentlefticons margin">
                  <EmailIcon
                    className="icon-margin dummy_contentlefticon"
                    fontSize="small"
                    color="primary"
                  />
                  {permissionData?.includes(22) ? (
                    <EditIcon
                      className="icon-margin dummy_contentlefticon"
                      fontSize="small"
                      color="primary"
                      style={{ cursor: "pointer" }}
                      onClick={viewClient}
                    />
                  ) : null}
                  <MarkunreadMailboxIcon
                    className="icon-margin dummy_contentlefticon"
                    fontSize="small"
                    color="primary"
                  />
                </div> */}
              <br />
            </div>
            <div class="personal-details">
              <h3>Personal Details</h3>
              <div class="email">
                <p>Email</p>
                <p>
                  <strong>
                    {clientDetail1?.username ? clientDetail1?.username : "-"}
                  </strong>
                </p>
              </div>
              <div class="dob">
                <p>Date of Birth</p>
                <p>
                  <strong>
                    {clientDetail1?.dob
                      ? moment(clientDetail1?.dob).format("DD-MMM-YYYY")
                      : "-"}
                  </strong>
                </p>
              </div>
              <div class="contact">
                <p>Contact</p>
                <p>
                  <strong>
                    {clientDetail1?.phone ? clientDetail1?.phone : "-"}
                  </strong>
                </p>
              </div>
              <div class="address">
                <p>Address</p>
                <p>
                  <strong>
                    {clientDetail1?.address}
                    <br />
                    {clientDetail1?.city}
                    <br />
                    {clientDetail1?.state}
                    <br />
                    {clientDetail1?.country ? clientDetail1?.country : "-"}
                  </strong>
                </p>
              </div>
              <div class="postal">
                <p>Postal Code</p>
                <p>
                  <strong>{clientDetail1?.pincode || "-"}</strong>
                </p>
              </div>
              <div class="visa-expiry">
                <p>Visa Expiry</p>
                <p>
                  <strong>
                    {/* {clientDetail1?.visaExpiry
                      ? moment(clientDetail1?.visaExpiry).format("DD-MMM-YYYY")
                      : "-"}
                    &nbsp;&nbsp;
                    <span style={{ color: "red" }}>
                      {/* {visaDateDiff ? (
                        <>
                          {Number(visaDateDiff) < 0
                            ? `(${-visaDateDiff} days delay)`
                            : `(${visaDateDiff} days remaining)`}
                        </>
                      ) : null} */}
                    {/* {visaDateDiff}
                    </span>  */}

                    {workflowtyperedux === "Migration" ? (
                      <>
                        {content?.visaExpiryDate
                          ? moment(content?.visaExpiryDate).format(
                              "DD-MMM-YYYY"
                            )
                          : "-"}
                        &nbsp;&nbsp;
                        <span style={{ color: "red" }}>
                          {enquiryvisaDateDiff}
                        </span>
                      </>
                    ) : (
                      <>
                        {clientDetail1?.visaExpiry
                          ? moment(clientDetail1?.visaExpiry).format(
                              "DD-MMM-YYYY"
                            )
                          : "-"}
                        &nbsp;&nbsp;
                        <span style={{ color: "red" }}>{visaDateDiff}</span>
                      </>
                    )}
                  </strong>
                </p>
              </div>
              <div class="visa-type">
                <p>Visa Type</p>
                <p>
                  <strong>
                    {/* {clientDetail1?.visaType ? clientDetail1?.visaType : "-"} */}
                    {workflowtyperedux === "Migration" ? (
                      <>{content?.visaType ? content?.visaType : "-"}</>
                    ) : (
                      <>
                        {clientDetail1?.visaType
                          ? clientDetail1?.visaType
                          : "-"}
                      </>
                    )}
                  </strong>
                </p>
              </div>
              <div class="passport">
                <p>Country of Passport</p>
                <p>
                  <strong>
                    {clientDetail1?.passport ? clientDetail1?.passport : "-"}
                  </strong>
                </p>
              </div>
              <div class="passport-number">
                <p>Passport Number</p>
                <p>
                  <strong>
                    {clientDetail1?.passportNumber
                      ? clientDetail1?.passportNumber
                      : "-"}
                  </strong>
                </p>
              </div>
              {workflowtyperedux != "Insurance" ? (
                <div class="sub-agent">
                  <p>Preferred Intake</p>
                  <p>{clientDetail1?.intake ? clientDetail1?.intake : "-"}</p>
                </div>
              ) : null}
              <div class="sub-agent">
                <p>Sub Agent</p>
                <p>
                  <strong>
                    {clientDetail1?.subAgentName
                      ? clientDetail1?.subAgentName
                      : "-"}
                  </strong>
                </p>
              </div>
              <hr />
              <div class="assignee">
                <p>Consultant</p>
                <p>
                  {clientDetail2?.consultantName
                    ? clientDetail2?.consultantName
                    : "-"}
                </p>
              </div>
              <div class="created-by">
                <p>Created By</p>
                <p>
                  {clientDetail2?.createdBy ? clientDetail2?.createdBy : "-"}
                </p>
              </div>
              <div class="created-by">
                <p>Created ON</p>
                <p>
                  {clientDetail1?.addedOn
                    ? moment(clientDetail1?.addedOn).format("DD-MMM-YY")
                    : "-"}
                </p>
              </div>
              <div class="created-by">
                <p>Updated By</p>
                <p>{enquiryData?.updatedBy ? enquiryData?.updatedBy : "-"}</p>
              </div>
              <div class="created-by">
                <p>Updated ON</p>
                <p>
                  {enquiryData?.updated
                    ? moment(enquiryData?.updated).format("DD-MMM-YY")
                    : "-"}
                </p>
              </div>
            </div>
          </div>
          <div class="card client-details" id="cardNumber">
            <div class="maximize-profile">
              <span
                onClick={() => {
                  let cardNum = document.getElementById("cardNumber");
                  cardNum.style.marginLeft = "20px";
                  let right_sp = document.getElementById("right_span");
                  right_sp.style.visibility = "hidden";
                  let card = document.getElementById("cardClient");
                  card.style.display = "block";
                }}
                id="right_span"
                style={{ visibility: "hidden" }}
              >
                {" "}
                &#187;{" "}
              </span>
            </div>
            {/* <AppBar position="static" color="default"> */}
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              <Tab className="tab-bold" label="Dashboard" {...a11yProps(0)} />
              {check1pointpermission(343, permissionData, rolepermission) ? (
                <Tab className="tab-bold" label="Admission" {...a11yProps(1)} />
              ) : (
                <Tab
                  className="tab-bold tabdisplay"
                  label="Admission"
                  {...a11yProps(1)}
                  disabled
                />
              )}
              {check1pointpermission(344, permissionData, rolepermission) ? (
                <Tab className="tab-bold" label="Migration" {...a11yProps(2)} />
              ) : (
                <Tab
                  className="tab-bold tabdisplay"
                  label="Migration"
                  {...a11yProps(2)}
                  disabled
                />
              )}
              {check1pointpermission(345, permissionData, rolepermission) ? (
                <Tab className="tab-bold" label="Insurance" {...a11yProps(3)} />
              ) : (
                <Tab
                  className="tab-bold tabdisplay"
                  label="Insurance"
                  {...a11yProps(3)}
                  disabled
                />
              )}
              {check1pointpermission(346, permissionData, rolepermission) ? (
                <Tab
                  className="tab-bold"
                  label="Skill Assessment"
                  {...a11yProps(4)}
                />
              ) : (
                <Tab
                  className="tab-bold tabdisplay"
                  label="Skill Assessment"
                  {...a11yProps(4)}
                  disabled
                />
              )}
              {check1pointpermission(347, permissionData, rolepermission) ? (
                <Tab className="tab-bold" label="Accounts" {...a11yProps(5)} />
              ) : (
                <Tab
                  className="tab-bold tabdisplay"
                  label="Accounts"
                  {...a11yProps(5)}
                  disabled
                />
              )}
              <Tab className="tab-bold" label="Notes" {...a11yProps(6)} />
              {check1pointpermission(348, permissionData, rolepermission) ? (
                <Tab className="tab-bold" label="EOI" {...a11yProps(7)} />
              ) : (
                <Tab
                  className="tab-bold tabdisplay"
                  label="EOI"
                  {...a11yProps(7)}
                  disabled
                />
              )}
              {check1pointpermission(349, permissionData, rolepermission) ? (
                <Tab className="tab-bold" label="PTE/IELTS" {...a11yProps(8)} />
              ) : (
                <Tab
                  className="tab-bold tabdisplay"
                  label="PTE/IELTS"
                  {...a11yProps(8)}
                  disabled
                />
              )}
            </Tabs>
            {/* </AppBar> */}
            <TabPanel
              value={value}
              index={0}
              component={<WorkflowDashboard />}
            />
            {workflowtyperedux === "Admission" ? (
              <>
                {check1pointpermission(343, permissionData, rolepermission) ? (
                  <TabPanel
                    className="tabpanel"
                    value={value}
                    index={1}
                    component={
                      <EnqyuiryDetails
                        handlebackdropclose={handlebackdropclose}
                        handlebackdropopen={handlebackdropopen}
                        content={content}
                        setrefreshit={setrefreshit}
                        workflowName={workflowName}
                        workflowSteps={workflowSteps}
                        workflowStepsArray={workflowStepsArray}
                        allworkflow={allworkflow}
                        workflowtyperedux={workflowtyperedux}
                        coursedetail={coursedetail}
                        getEnquiry={getEnquiry}
                        allnotes={allnotes}
                        notesdata={notesdata}
                        getpageno={getpageno}
                        handlepage={handlepage}
                        attachments={attachments}
                        attachmentPagination={attachmentPagination}
                        getAllNotes={getAllNotes}
                        getAttachments={getAttachments}
                        displayStatus={displayStatus}
                        setDisplayStatus={setDisplayStatus}
                        getWorlflowCovertClientDetails={
                          getWorlflowCovertClientDetails
                        }
                        getNoOfCount={getNoOfCount}
                        getNoOfProspect={getNoOfProspect}
                      />
                    }
                  />
                ) : null}
              </>
            ) : (
              <>
                {check1pointpermission(343, permissionData, rolepermission) ? (
                  <TabPanel
                    value={value}
                    index={1}
                    component={
                      <WorkflowListing
                        clientId={clientId}
                        workflowType="Admission"
                        setrefreshit={setrefreshit}
                      />
                    }
                  />
                ) : null}
              </>
            )}
            {workflowtyperedux === "Migration" ? (
              <>
                {check1pointpermission(344, permissionData, rolepermission) ? (
                  <TabPanel
                    value={value}
                    index={2}
                    component={
                      <EnqyuiryDetails
                        handlebackdropclose={handlebackdropclose}
                        handlebackdropopen={handlebackdropopen}
                        content={content}
                        setrefreshit={setrefreshit}
                        workflowName={workflowName}
                        workflowSteps={workflowSteps}
                        workflowStepsArray={workflowStepsArray}
                        allworkflow={allworkflow}
                        workflowtyperedux={workflowtyperedux}
                        coursedetail={coursedetail}
                        getEnquiry={getEnquiry}
                        allnotes={allnotes}
                        notesdata={notesdata}
                        getpageno={getpageno}
                        handlepage={handlepage}
                        attachments={attachments}
                        attachmentPagination={attachmentPagination}
                        getAllNotes={getAllNotes}
                        getAttachments={getAttachments}
                        displayStatus={displayStatus}
                        setDisplayStatus={setDisplayStatus}
                        getWorlflowCovertClientDetails={
                          getWorlflowCovertClientDetails
                        }
                        getNoOfCount={getNoOfCount}
                        getNoOfProspect={getNoOfProspect}
                      />
                    }
                  />
                ) : null}
              </>
            ) : (
              <>
                {check1pointpermission(344, permissionData, rolepermission) ? (
                  <TabPanel
                    value={value}
                    index={2}
                    component={
                      <WorkflowListing
                        clientId={clientId}
                        workflowType="Migration"
                        setrefreshit={setrefreshit}
                      />
                    }
                  />
                ) : null}
              </>
            )}
            {workflowtyperedux === "Insurance" ? (
              <>
                {check1pointpermission(345, permissionData, rolepermission) ? (
                  <TabPanel
                    value={value}
                    index={3}
                    component={
                      <EnqyuiryDetails
                        handlebackdropclose={handlebackdropclose}
                        handlebackdropopen={handlebackdropopen}
                        content={content}
                        setrefreshit={setrefreshit}
                        workflowName={workflowName}
                        workflowSteps={workflowSteps}
                        workflowStepsArray={workflowStepsArray}
                        allworkflow={allworkflow}
                        workflowtyperedux={workflowtyperedux}
                        coursedetail={coursedetail}
                        getEnquiry={getEnquiry}
                        allnotes={allnotes}
                        notesdata={notesdata}
                        getpageno={getpageno}
                        handlepage={handlepage}
                        attachments={attachments}
                        attachmentPagination={attachmentPagination}
                        getAllNotes={getAllNotes}
                        getAttachments={getAttachments}
                        displayStatus={displayStatus}
                        setDisplayStatus={setDisplayStatus}
                        getWorlflowCovertClientDetails={
                          getWorlflowCovertClientDetails
                        }
                        getNoOfCount={getNoOfCount}
                        getNoOfProspect={getNoOfProspect}
                      />
                    }
                  />
                ) : null}
              </>
            ) : (
              <>
                {check1pointpermission(345, permissionData, rolepermission) ? (
                  <TabPanel
                    value={value}
                    index={3}
                    component={
                      <WorkflowListing
                        clientId={clientId}
                        workflowType="Insurance"
                        setrefreshit={setrefreshit}
                      />
                    }
                  />
                ) : null}
              </>
            )}
            {workflowtyperedux === "SkillAssessment" ? (
              <>
                {check1pointpermission(346, permissionData, rolepermission) ? (
                  <TabPanel
                    value={value}
                    index={4}
                    component={
                      <EnqyuiryDetails
                        handlebackdropclose={handlebackdropclose}
                        handlebackdropopen={handlebackdropopen}
                        setrefreshit={setrefreshit}
                        content={content}
                        workflowName={workflowName}
                        workflowSteps={workflowSteps}
                        workflowStepsArray={workflowStepsArray}
                        allworkflow={allworkflow}
                        workflowtyperedux={workflowtyperedux}
                        coursedetail={coursedetail}
                        getEnquiry={getEnquiry}
                        allnotes={allnotes}
                        notesdata={notesdata}
                        getpageno={getpageno}
                        handlepage={handlepage}
                        attachments={attachments}
                        attachmentPagination={attachmentPagination}
                        getAllNotes={getAllNotes}
                        getAttachments={getAttachments}
                        displayStatus={displayStatus}
                        setDisplayStatus={setDisplayStatus}
                        getWorlflowCovertClientDetails={
                          getWorlflowCovertClientDetails
                        }
                        getNoOfCount={getNoOfCount}
                        getNoOfProspect={getNoOfProspect}
                      />
                    }
                  />
                ) : null}
              </>
            ) : (
              <>
                {check1pointpermission(346, permissionData, rolepermission) ? (
                  <TabPanel
                    value={value}
                    index={4}
                    component={
                      <WorkflowListing
                        clientId={clientId}
                        workflowType="SkillAssessment"
                        setrefreshit={setrefreshit}
                      />
                    }
                  />
                ) : null}
              </>
            )}
            {check1pointpermission(347, permissionData, rolepermission) ? (
              <TabPanel
                value={value}
                index={5}
                component={
                  <InvoiceList clientId={clientId} workflowType="Migration" />
                }
              />
            ) : null}
            <TabPanel
              value={value}
              index={6}
              component={<EnquiryNotesDisplay clientId={params.client_id} />}
            />
            {check1pointpermission(348, permissionData, rolepermission) ? (
              <TabPanel
                value={value}
                index={7}
                component={<Eoi clientId={params.client_id} />}
              />
            ) : null}
            {check1pointpermission(349, permissionData, rolepermission) ? (
              <TabPanel
                value={value}
                index={8}
                component={<Pte clientId={params.client_id} />}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewEnquiryWorkFlow;
