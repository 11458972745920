import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CardText, Col, Row, Button, Table } from "reactstrap";
import axios from "axios";
import base_url from "../../api/bootapi";
import { ToastContainer, toast } from "react-toastify";
import Modal from "react-modal";
import "../css/Responsive.css";
import { useDispatch, useSelector } from "react-redux";
import { setdashboardcheck, selectUser, logout } from "../features/userSlice";
import { useForm } from "react-hook-form";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import AddIcon from "@mui/icons-material/Add";
import Pagination from "@mui/material/Pagination";
import { useStyles, customStyles } from "../../Utilities/CSSUtilities";
import callsaveauditapi from "../../services/auditservice";
import sort from "./../images/sort.svg";
import RoleDeleteModal from "../Modal/Masters/RoleDeleteModal";
import EditRoleModal from "./../Modal/Masters/EditRoleModal";
import { check1pointpermission } from "./../Permission/permission";

function Roles(props) {
  const user = useSelector(selectUser);
  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalIsOpen2, setIsOpen2] = React.useState(false);
  const [display1, setdisplay] = useState("inline");
  const navigate = useNavigate();
  const [roles, setRoles] = useState(null);
  const dispatch = useDispatch();
  const [pageno, setpageno] = useState(0);
  const [getpageno, setgetpageno] = useState();
  const [totalUser, settotalUser] = useState();
  const [countnumber, setcountnumber] = useState();
  const [numberofElements, setnumberofElements] = useState();
  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);
  const [rows, setrows] = React.useState(50);
  const isw_user_name = localStorage.getItem("isw_user_name");
  const isw_adminId = localStorage.getItem("isw_adminId");
  const isw_user_email = localStorage.getItem("isw_user_email");
  const [backdropopen, setbackdropopen] = useState(false);
  const ipaddress = localStorage.getItem("AdminIpAddress");
  let selectedId = localStorage.getItem("selected_roleId");
  let selectedRame = localStorage.getItem("selected_roleName");

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        navigate("/");
      }
    });
    setbackdropopen(true);
    getPermissions();
  }, []);

  useEffect(() => {
    let width = document.querySelector("table")?.offsetWidth;
    if (document.querySelector(".scroller")) {
      document.querySelector(".scroller").style.width = `${width}px`;
    }
  }, [roles]);

  let scrolling = false;
  const scrolldiv = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow .table-responsive");
    table.scrollTo(scroller.scrollLeft, 0);
  };

  const scrolltable = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow  .table-responsive");
    scroller.scrollTo(table.scrollLeft, 0);
  };

  const handleRows = (event) => {
    setrows(event.target.value);
  };

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);

    if (check1pointpermission(602, permission_obj, resource_obj)) {
      dispatch(setdashboardcheck(20));
      getallRoles(pageno);
    } else {
      navigate("/errorPage");
    }
  };

  const handlepage = (event, value) => {
    setpageno(value - 1);
    getallRoles(value - 1);
  };

  const getallRoles = (value1) => {
    //console.log(value1);
    axios
      .get(`${base_url.api3}/getAllRoles`, {
        headers: {
          // Authorization: jwtToken,
          page: Number(value1),
          size: Number(rows),
        },
      })
      .then((response) => {
        let arr = [];
        for (let i = 0; i < response.data.data.content.length; i++) {
          let obj = {
            id: response.data.data.content[i].id,
            roleName: response.data.data.content[i].roleName,
            roleCode: response.data.data.content[i].roleCode,
            status: response.data.data.content[i].status,
          };
          for (let j = 0; j < response.data.userCount.length; j++) {
            if (
              response.data.userCount[j].roleId ===
              response.data.data.content[i].id
            ) {
              obj["userCount"] = response.data.userCount[j].userCount;
            }
          }
          arr.push(obj);
        }
        setRoles(arr);
        // setRoles(response.data.data.content);
        setbackdropopen(false);

        setgetpageno(response.data.data.totalPages);
        settotalUser(response.data.data.totalElements);
        setcountnumber(response.data.data.number);
        setnumberofElements(response.data.data.numberOfElements);
      })
      .catch((error) => {
        handle403error(error);
        setbackdropopen(false);
        //console.log(error);
      });
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const afterOpenModal = () => {
    //console.log("hello");
    // subtitle.style.color = '#f00';
  };

  const closeModal = () => {
    setIsOpen(false);
    setIsOpen2(false);
    setIsOpen3(false);
  };

  const classes = useStyles();

  // const assignPermissions=(p, e)=>{
  //     //console.log(p);
  //     localStorage.setItem("selected_client_name",p.id);
  //     navigate("/assign-permission/"+p.id+"/"+p.roleName);
  // }
  const viewPermissions = (p, e) => {
    //console.log(p);
    localStorage.setItem("selected_client_name", p.id);
    navigate("/view-permission/" + p.id + "/" + p.roleName);
  };

  const [roleState, setroleState] = useState();
  const EditRole = (e, p) => {
    //console.log(p);
    setIsOpen2(true);
    let data = {
      id: p.id,
      roleName: p.roleName,
      roleCode: p.roleCode,
      status: p.status,
    };
    setroleState(data);
    //console.log(data);
  };

  const inputEvent = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setroleState((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };
  //console.log(roleState);

  const [modalIsOpen3, setIsOpen3] = React.useState(false);
  const [roleState2, setroleState2] = useState();
  const DeleteRole = (e, p) => {
    setIsOpen3(true);
    setroleState2({
      id: p.id,
      roleName: p.roleName,
      roleCode: p.roleCode,
      status: p.status,
    });
  };

  const deleteCurrentRole = (elem) => {
    setbackdropopen(true);
    //console.log(roleState2);
    axios
      .delete(`${base_url.api3}/deleteRoleById`, {
        headers: {
          Authorization: jwtToken,
          roleId: selectedId,
        },
      })
      .then((response) => {
        toast.success(response.data.message);

        let log = isw_user_name + " Deleted the Role " + selectedRame + ".";
        callsaveauditapi(log, dispatch, navigate);
        setIsOpen3(false);
        setroleState2(null);
        getallRoles(pageno);
      })
      .catch((error) => {
        setbackdropopen(false);
        toast.error(JSON.stringify(error.response.data.message));
        handle403error(error);
      });
  };

  const submitRole = (e) => {
    setbackdropopen(true);
    let roleName = e.target[0].value;
    let roleCode = e.target[1].value;
    let status = e.target[2].value;
    let id = Number(roleState?.id);
    let data = {
      roleName,
      roleCode,
      status,
      id,
    };
    //console.log(data);
    axios
      .post(`${base_url.api3}/updateRoleById`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        //console.log(response.data);
        toast.success(response.data.message);

        let log =
          isw_user_name +
          " Updated Role Status" +
          response.data.data.roleName +
          ".";
        callsaveauditapi(log, dispatch, navigate);
        getallRoles(pageno);
        setIsOpen2(false);
      })
      .catch((error) => {
        setbackdropopen(false);
        toast.error(JSON.stringify(error.response.data.message));
        handle403error(error);
      });
  };

  const [lengtherror, setlengtherror] = useState(false);
  const submit = (data2) => {
    setbackdropopen(true);
    if (data2.roleCode.length !== 3) {
      setlengtherror(true);
      return false;
    }
    setlengtherror(false);
    let roleName = data2.roleName;
    let roleCode = data2.roleCode.toUpperCase();
    let status = data2.status;
    let id = 0;
    let data = {
      roleName,
      roleCode,
      status,
      id,
    };
    //console.log(data);
    axios
      .post(`${base_url.api3}/createRole`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        //console.log(response.data);
        toast.success(response.data.message);

        let log =
          isw_user_name + " Created Role " + response.data.data.roleName + ".";
        callsaveauditapi(log, dispatch, navigate);
        getallRoles(pageno);
        setIsOpen(false);
      })
      .catch((error) => {
        setbackdropopen(false);
        toast.error(JSON.stringify(error.response.data.message));
        handle403error(error);
      });
  };

  // Change Status of location

  const changeStatus = (elem, p) => {
    if (p.status === 1) {
      let data = {
        id: elem.target.id,
        status: Number(0),
      };
      axios
        .post(`${base_url.api3}/updateRoleById`, data, {
          headers: {
            Authorization: jwtToken,
          },
        })
        .then((res) => {
          toast.success("Status changed");
          getallRoles(pageno);
        })
        .catch((err) => {
          handle403error(err);
        });
    } else {
      let data = {
        id: elem.target.id,
        status: Number(1),
      };
      axios
        .post(`${base_url.api3}/updateRoleById`, data, {
          headers: {
            Authorization: jwtToken,
          },
        })
        .then((res) => {
          toast.success("Status changed");
          getallRoles(pageno);
        })
        .catch((err) => {
          handle403error(err);
        });
    }
  };
  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            navigate("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };
  return (
    <div>
      <ToastContainer />
      <Backdrop className={classes.backdrop} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* <div className="commoncomponent_cardcss"> */}
      <div
        class="page-head d-flex justify-content-between p-4"
        style={{ marginTop: "4.3rem" }}
      >
        <ul class="breadcrums">
          <h3 class="page-title bold">Roles</h3>
          <Link to="/" class="b-link">
            Home
          </Link>
          <span className="s_span ">&raquo;</span>
          <a class="b-link">Access Level</a>
          <span className="s_span ">&raquo;</span>
          <Link to="/roles" class="b-link active">
            Roles
          </Link>
        </ul>
        {/* <a href="workload-dashboard.html" class="back-btn">Back</a> */}
        <button
          className="sent-button"
          onClick={openModal}
          style={{ float: "right" }}
        >
          Add
        </button>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <form onSubmit={handleSubmit(submit)}>
            <div>
              <h3 style={{ color: "black" }}>Create Role</h3>
              <div className="form-group creatadmin">
                <Row>
                  <Col>
                    <label>Role Name:</label>
                    <input
                      type="text"
                      className="form-control underline-input"
                      name="roleName"
                      ref={register({ required: true })}
                      style={{ backgroundColor: "#f5f5f9" }}
                    />
                    {errors.roleName && (
                      <p className="errormessage">RoleName is Required</p>
                    )}
                  </Col>
                </Row>
              </div>
              <div className="form-group creatadmin">
                <Row>
                  <Col>
                    <label>Role Code:</label>
                    <input
                      type="text"
                      className="form-control underline-input"
                      name="roleCode"
                      maxLength="3"
                      style={{ backgroundColor: "#f5f5f9" }}
                      ref={register({
                        required: true,
                      })}
                    />
                    {errors.roleCode && (
                      <p className="errormessage">RoleCode is Required</p>
                    )}
                    {lengtherror && (
                      <p className="errormessage">
                        RoleCode should be of 3 characters
                      </p>
                    )}
                  </Col>
                </Row>
              </div>
              <div className="form-group creatadmin">
                <Row>
                  <Col>
                    <label>Status:</label>
                    <select
                      className={classes.selectdrop}
                      name="status"
                      style={{ backgroundColor: "#f5f5f9" }}
                      ref={register({ required: true })}
                    >
                      <option id="1" value="1">
                        Active
                      </option>
                      <option id="0" value="0">
                        InActive
                      </option>
                    </select>
                    {errors.status && (
                      <p className="errormessage">Status is Required</p>
                    )}
                  </Col>
                </Row>
              </div>
              <Row className="justify-content-end mt-1">
                <Button color="secondary" onClick={closeModal}>
                  Cancel
                </Button>
                <button type="submit" className="btn listing_addbutton ml-1">
                  Submit
                </button>
              </Row>
            </div>
          </form>
        </Modal>
      </div>
      <div>
        <Row>
          {/* <Col md={6}>
              <CardText
                className={classes.headtext}
                style={{ marginTop: "-10px" }}
              >
                <strong>Roles</strong>
              </CardText>
            </Col> */}
          {/* <Col md={6} className="d-flex justify-content-end">
            <button className="btn listing_addbutton" onClick={openModal}>
              <AddIcon className="listing_addbutton_i" /> Add
            </button>
            <Modal
              isOpen={modalIsOpen}
              onRequestClose={closeModal}
              style={customStyles}
              contentLabel="Example Modal"
            >
              <form onSubmit={handleSubmit(submit)}>
                <div>
                  <h3 style={{ color: "black" }}>Create Role</h3>
                  <div className="form-group creatadmin">
                    <Row>
                      <Col>
                        <label>Role Name:</label>
                        <input
                          type="text"
                          className="form-control underline-input"
                          name="roleName"
                          ref={register('',{ required: true })}
                        />
                        {errors.roleName && (
                          <p className="errormessage">RoleName is Required</p>
                        )}
                      </Col>
                    </Row>
                  </div>
                  <div className="form-group creatadmin">
                    <Row>
                      <Col>
                        <label>Role Code:</label>
                        <input
                          type="text"
                          className="form-control underline-input"
                          name="roleCode"
                          maxLength="3"
                          ref={register('',{
                            required: true,
                          })}
                        />
                        {errors.roleCode && (
                          <p className="errormessage">RoleCode is Required</p>
                        )}
                        {lengtherror && (
                          <p className="errormessage">
                            RoleCode should be of 3 characters
                          </p>
                        )}
                      </Col>
                    </Row>
                  </div>
                  <div className="form-group creatadmin">
                    <Row>
                      <Col>
                        <label>Status:</label>
                        <select
                          className={classes.selectdrop}
                          name="status"
                          ref={register('',{ required: true })}
                        >
                          <option id="1" value="1">
                            Active
                          </option>
                          <option id="0" value="0">
                            InActive
                          </option>
                        </select>
                        {errors.status && (
                          <p className="errormessage">Status is Required</p>
                        )}
                      </Col>
                    </Row>
                  </div>
                  <Row className="justify-content-end mt-1">
                    <Button color="secondary" onClick={closeModal}>
                      Cancel
                    </Button>
                    <button
                      type="submit"
                      className="btn listing_addbutton ml-1"
                    >
                      Submit
                    </button>
                  </Row>
                </div>
              </form>
            </Modal>
          </Col> */}
        </Row>
        <div
          className="newlisting_headertabdesign"
          style={{
            padding: "1.5rem",
            border: "1.5rem solid #e9ebf2",
            marginTop: "0",
            marginBottom: "0",
          }}
        >
          <Row className="s_pagi">
            <Col md={6}>
              {roles?.length ? (
                <p className="s_para">
                  {countnumber * rows + 1} to{" "}
                  {countnumber * rows + numberofElements} of {totalUser} records
                </p>
              ) : (
                <p className="s_para">No Record Found</p>
              )}
            </Col>
            <Col md={6}>
              {/* <button className="btn btn-success" onClick={openModal} style={{ float: "right" }}>
                Add
              </button>
              <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
              >
                <form onSubmit={handleSubmit(submit)}>
                  <div>
                    <h3 style={{ color: "black" }}>Create Role</h3>
                    <div className="form-group creatadmin">
                      <Row>
                        <Col>
                          <label>Role Name:</label>
                          <input
                            type="text"
                            className="form-control underline-input"
                            name="roleName"
                            ref={register('',{ required: true })}
                          />
                          {errors.roleName && (
                            <p className="errormessage">RoleName is Required</p>
                          )}
                        </Col>
                      </Row>
                    </div>
                    <div className="form-group creatadmin">
                      <Row>
                        <Col>
                          <label>Role Code:</label>
                          <input
                            type="text"
                            className="form-control underline-input"
                            name="roleCode"
                            maxLength="3"
                            ref={register('',{
                              required: true,
                            })}
                          />
                          {errors.roleCode && (
                            <p className="errormessage">RoleCode is Required</p>
                          )}
                          {lengtherror && (
                            <p className="errormessage">
                              RoleCode should be of 3 characters
                            </p>
                          )}
                        </Col>
                      </Row>
                    </div>
                    <div className="form-group creatadmin">
                      <Row>
                        <Col>
                          <label>Status:</label>
                          <select
                            className={classes.selectdrop}
                            name="status"
                            ref={register('',{ required: true })}
                          >
                            <option id="1" value="1">
                              Active
                            </option>
                            <option id="0" value="0">
                              InActive
                            </option>
                          </select>
                          {errors.status && (
                            <p className="errormessage">Status is Required</p>
                          )}
                        </Col>
                      </Row>
                    </div>
                    <Row className="justify-content-end mt-1">
                      <Button color="secondary" onClick={closeModal}>
                        Cancel
                      </Button>
                      <button
                        type="submit"
                        className="btn listing_addbutton ml-1"
                      >
                        Submit
                      </button>
                    </Row>
                  </div>
                </form>
              </Modal> */}
            </Col>
          </Row>
          {/* <div className="above-scroller" onScroll={scrolldiv}>
            <div className="scroller"></div>
          </div> */}
          <div className="displaytablerow">
            {/* <Table responsive striped onScroll={scrolltable}> */}
            <Table
              className="workload card-table table-borderless"
              responsive
              striped
              onScroll={scrolltable}
            >
              <thead className="s_sticky">
                <tr>
                  <th style={{ width: "100px", textAlign: "center" }}>SN</th>
                  <th style={{ width: "300px" }}>
                    Name
                    <img
                      src={sort}
                      className="sorting"
                      style={{
                        cursor: "pointer",
                        display: `${display1}`,
                        height: "10px",
                      }}
                    />
                  </th>
                  <th style={{ width: "100px" }}>
                    Code
                    <img
                      src={sort}
                      className="sorting"
                      style={{
                        cursor: "pointer",
                        display: `${display1}`,
                        height: "10px",
                      }}
                    />
                  </th>
                  <th style={{ width: "100px" }}>
                    UserCount
                    <img
                      src={sort}
                      className="sorting"
                      style={{
                        cursor: "pointer",
                        display: `${display1}`,
                        height: "10px",
                      }}
                    />
                  </th>
                  <th style={{ width: "100px" }}>
                    Status
                    <img
                      src={sort}
                      className="sorting"
                      style={{
                        cursor: "pointer",
                        display: `${display1}`,
                        height: "10px",
                      }}
                    />
                  </th>
                  <th style={{ width: "400px" }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {roles != null ? (
                  roles &&
                  roles.map((p, index) => (
                    <tr key={p.id}>
                      <td style={{ width: "50px", textAlign: "left" }}>
                        {index + 1}
                      </td>
                      <td>{p.roleName}</td>
                      <td>{p.roleCode}</td>
                      <td>{p.userCount}</td>
                      {p.id != 6 ? (
                        <td>
                          <Link id={p.id} onClick={(e) => changeStatus(e, p)}>
                            {p.status == "1" ? "Active" : "Inactive"}
                          </Link>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td style={{ display: "flex" }}>
                        <Link
                          className="sent-button"
                          color="primary"
                          onClick={(e) => viewPermissions(p, e)}
                          to={`/view-permission/` + p.id + `/` + p.roleName}
                        >
                          Permissions
                        </Link>
                        {p.id === 1 ||
                        p.id === 2 ||
                        p.id === 3 ||
                        p.id === 4 ||
                        p.id === 5 ||
                        p.id === 6 ? null : (
                          <>
                            &nbsp;
                            {/* <button
                              className="outline-button"
                              // color="primary"
                              onClick={(e) => EditRole(e, p)}
                            >
                              Edit
                            </button> */}
                            <EditRoleModal
                              getallRoles={getallRoles}
                              roleState={roleState}
                              setbackdropopen={setbackdropopen}
                              toast={toast}
                              handle403error={handle403error}
                              pageno={pageno}
                              isw_user_name={isw_user_name}
                              p={p}
                              onClick={(e) => {
                                localStorage.setItem("selectedArea", p);
                                //console.log("hello");
                              }}
                            />
                            &nbsp;
                          </>
                        )}
                        {p.id === 1 ||
                        p.id === 2 ||
                        p.id === 3 ||
                        p.id === 4 ||
                        p.id === 5 ||
                        p.id === 6 ? null : (
                          // <button
                          //   className="role-deletebtn"
                          //   // color="primary"
                          //   onClick={(e) => DeleteRole(e, p)}
                          // >
                          //   Delete
                          // </button>
                          <RoleDeleteModal
                            deleteCurrentRole={deleteCurrentRole}
                            p={p}
                          />
                        )}
                        <Modal
                          isOpen={modalIsOpen3}
                          onRequestClose={closeModal}
                          style={customStyles}
                          contentLabel="Example Modal"
                        >
                          <h4 style={{ marginTop: "10px" }}>
                            Are you sure you want to delete current record?
                          </h4>
                          <Row
                            style={{
                              display: "flex",
                              marginTop: "20px",
                              justifyContent: "flex-end",
                              gap: "10px",
                              marginBottom: "10px",
                            }}
                          >
                            <button
                              className="btn listing_addbutton ml-1"
                              onClick={deleteCurrentRole}
                            >
                              Delete
                            </button>
                            <button
                              className="btn btn-secondary cancel_margin"
                              onClick={closeModal}
                            >
                              Cancel
                            </button>
                          </Row>
                        </Modal>
                        {/* <Modal
                          isOpen={modalIsOpen2}
                          onRequestClose={closeModal}
                          style={customStyles}
                          contentLabel="Example Modal"
                        >
                          <form
                            onSubmit={(e) => {
                              e.preventDefault();
                              submitRole(e);
                            }}
                          >
                            <div>
                              <h3 style={{ color: "black" }}>Role</h3>
                              <div className="form-group creatadmin">
                                <Row>
                                  <Col>
                                    <label>Role Name:</label>
                                    <input
                                      type="text"
                                      className="form-control underline-input"
                                      value={roleState?.roleName}
                                      name="roleName"
                                      onChange={inputEvent}
                                      style={{ backgroundColor: "#f5f5f9" }}
                                      required
                                    />
                                  </Col>
                                </Row>
                              </div>
                              <div className="form-group creatadmin">
                                <Row>
                                  <Col>
                                    <label>Role Code:</label>
                                    <input
                                      type="text"
                                      className="form-control underline-input"
                                      value={roleState?.roleCode}
                                      name="roleCode"
                                      style={{ backgroundColor: "#f5f5f9" }}
                                      onChange={inputEvent}
                                      readOnly
                                    />
                                  </Col>
                                </Row>
                              </div>
                              {roleState?.id === 1 ||
                              roleState?.id === 2 ||
                              roleState?.id === 3 ||
                              roleState?.id === 4 ||
                              roleState?.id === 5 ? (
                                <div className="form-group creatadmin">
                                  <Row>
                                    <Col>
                                      <label>Status:</label>
                                      <select
                                        className={classes.selectdrop}
                                        style={{ backgroundColor: "#f5f5f9" }}
                                      >
                                        <option id="1" value="1">
                                          Active
                                        </option>
                                      </select>
                                    </Col>
                                  </Row>
                                </div>
                              ) : (
                                <div className="form-group creatadmin">
                                  <Row>
                                    <Col>
                                      <label>Status:</label>
                                      <select
                                        className={classes.selectdrop}
                                        value={roleState?.status}
                                        name="status"
                                        style={{ backgroundColor: "#f5f5f9" }}
                                        onChange={inputEvent}
                                      >
                                        <option id="1" value="1">
                                          Active
                                        </option>
                                        <option id="0" value="0">
                                          InActive
                                        </option>
                                      </select>
                                    </Col>
                                  </Row>
                                </div>
                              )}
                              <Row className="justify-content-end">
                                <button
                                  className="outline-button"
                                  style={{ marginRight: "5px" }}
                                  onClick={closeModal}
                                >
                                  Cancel
                                </button>
                                <button type="submit" className="sent-button">
                                  Submit
                                </button>
                              </Row>
                            </div>
                          </form>
                        </Modal> */}
                      </td>
                    </tr>
                  ))
                ) : (
                  <p className={classes.headertekst}>{null}</p>
                )}
              </tbody>
            </Table>
          </div>
          <Row className="s_pagirow">
            <Col md={6}>
              <div>
                <form>
                  <div style={{ float: "left" }}>
                    <FormControl
                      variant="standard"
                      style={{
                        display: "inline-block",
                        marginRight: "25px",
                        minWidth: "50px",
                      }}
                    >
                      <select
                        className={classes.selectlimit}
                        labelId="simple-select-label"
                        id="simple-select"
                        value={rows}
                        onChange={handleRows}
                      >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                    </FormControl>
                    {roles?.length ? (
                      <p className="s_spanPage">
                        {countnumber * rows + 1} to{" "}
                        {countnumber * rows + numberofElements} of {totalUser}{" "}
                        records
                      </p>
                    ) : (
                      <p className="s_spanPage">No Record Found</p>
                    )}
                  </div>
                </form>
              </div>
            </Col>
            <Col md={6}>
              <div className={classes.root} style={{ float: "right" }}>
                <Pagination
                  count={getpageno}
                  onChange={handlepage}
                  shape="rounded"
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
}

export default Roles;
