import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import Pagination from "@mui/material/Pagination";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useNavigate, useParams, Link } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";
import { selectUser } from "../features/userSlice";
import { check1pointpermission } from "../Permission/permission";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
}));

const AgentPaidInvoiceDetail = (props) => {
  const user = useSelector(selectUser);
  const classes = useStyles();
  const navigate = useNavigate();
  const params = useParams();
  const goBack = () => {
    navigate("/agent");
  };
  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);

  useEffect(() => {
    getPermissions();
  }, []);

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);

    if (check1pointpermission(729, permission_obj, resource_obj)) {


    } else {
      navigate("/errorPage");
    }
  };

  const viewinvoice = (val1, val2) => {
    localStorage.setItem(
      "prev_path3",
      "/view-agent/" + params.agent_id + "/" + params.agent_email
    );
    // navigate("/view-invoice-details/" + val1 + "/" + val2);
  };
  return (
    <div style={{ margin: "10px 20px" }}>
      <div className="d-flex justify-content-end">
        <button className="btn listing_addbutton" style={{ marginTop: "-35px", width: "120px", height: "40px", textDecoration: "underline" }} onClick={goBack}>
          Agent List
        </button>
      </div>
      <Table className=" workload card-table table-borderless"
        style={{ marginTop: "20px" }}
        responsive striped>
        <TableHead style={{ backgroundColor: "cornflowerblue" }}>
          <TableRow>
            <TableCell>SN</TableCell>
            <TableCell>Income No</TableCell>
            <TableCell>Income Type</TableCell>
            <TableCell>Invoice Type</TableCell>
            <TableCell>Enquiry Name</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Due Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.agentpaidinvoice?.map((p, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row">
                {index + 1}
              </TableCell>
              <TableCell component="th" scope="row">
                {check1pointpermission(
                  340,
                  permissionData,
                  rolepermission
                ) ? (
                  <Link
                    onClick={(e) => viewinvoice(p, e)}
                    to={`/view-invoice-details/` + p.clientId + `/` + p.id}
                  >{p.id}</Link>
                ) : (
                  <>{p.id}</>
                )}
              </TableCell>
              <TableCell component="th" scope="row">
                {p.incomeType}
              </TableCell>
              <TableCell component="th" scope="row">
                {p.commisionType}
              </TableCell>
              <TableCell component="th" scope="row">
                {p.enquiryName}
              </TableCell>
              <TableCell component="th" scope="row">
                {p.netAmount}
              </TableCell>
              <TableCell component="th" scope="row">
                {moment(p.invoiceDueDate).format("DD-MMM-YY")}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "20px",
        }}
      >
        <Pagination
          count={props.getpaidpageno}
          onChange={props.handlepaidpage}
          shape="rounded"
        />
      </div>
    </div>
  );
};

export default AgentPaidInvoiceDetail;
