import React, { useEffect, useState } from "react";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import base_url from "../../../api/bootapi";
import { useStyles } from "../../../Utilities/CSSUtilities";
import Modal from "react-modal";
import dummyData from "./DummyDataCalendar";
import { ToastContainer, toast } from "react-toastify";

import {
  selectUser,
  setdashboardcheck,
  logout,
} from "../../features/userSlice";
import { useDispatch, useSelector } from "react-redux";

import axios from "axios";
import ViewAppointmentModal from "../../Modal/Masters/ViewAppointmentModal";
import { Link, useNavigate } from "react-router-dom";
import Appointmentmodal from "../../Modal/Masters/AppointmentModal";

const localizer = momentLocalizer(moment);

const AppointmentCalendar = () => {
  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  const isw_adminId = localStorage.getItem("isw_adminId");
  const [getAppointments, setAppointments] = useState([]);
  const [backdropopen, setbackdropopen] = useState(false);
  const classes = useStyles();
  const user2 = useSelector(selectUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedOffice, setSelectedOfficeId] = useState(null);
  const [selectedconsultant, setSelectedconsultantId] = useState(null);
  const [selectedOfficeN, setSelectedOfficeName] = useState(null);
  const [branchselected, setBranchSelected] = useState(true);
  const [selectedOfficecode, setSelectedOfficecode] = useState(null);
  const [allconsultant, setConsultant] = useState();

  const handleOffice = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const selectedOfficeId = optionElement.getAttribute("id");
    const selectedOfficeName = optionElement.getAttribute("value");
    const optionElementofficecode = optionElement.getAttribute("officeCode");

    if (selectedOfficeName) {
      setBranchSelected(false);
      getConsultant(selectedOfficeId);
    } else {
      setBranchSelected(true);
    }

    setSelectedOfficeId(selectedOfficeId);
    setSelectedOfficeName(selectedOfficeName);
    setSelectedOfficecode(optionElementofficecode);

    setbackdropopen(true);
    axios
      .get(`${base_url.api4}/getAppointmentsByFilter`, {
        headers: {
          Authorization: jwtToken,
          columnName: "appointmentDate",
          consultantId: selectedconsultant,
          direction: "ASC",
          officeId: selectedOfficeId,
          page: 0,
          size: 50,
        },
      })
      .then((res) => {
        setAppointments(res.data.data.content);
        console.log("32---->", res.data.data.content);
        setbackdropopen(false);
      })
      .catch((err) => {
        // toast.error("No Appointments Scheduled");
        console.log(err);
        setbackdropopen(false);
      });
  };
  const [tranformedData, setTransformedData] = useState(null);
  const handleAssigne = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const selectedAssigneId = optionElement.getAttribute("id");
    setSelectedconsultantId(optionElement.getAttribute("id"));
    const selectedAssignename = optionElement.getAttribute("consultantname");
    const selectedAssigneconsultantname = optionElement.getAttribute("value");
    console.log("70--->", selectedAssignename);

    // const eventsData = appointments
    //   .filter(
    //     (appointment) => appointment.counselorName === selectedAssignename
    //   )
    //   .map((appointment) => ({
    //     id: appointment.id,
    //     title: `${appointment.notes}`,
    //     start: new Date(appointment.appointmentDate),
    //     end: new Date(appointment.appointmentDate), // Assuming each appointment lasts for 15 minutes
    //   }));
    // setTransformedData(eventsData);

    setbackdropopen(true);
    axios
      .get(`${base_url.api4}/getAppointmentsByFilter`, {
        headers: {
          Authorization: jwtToken,
          columnName: "appointmentDate",
          consultantId: selectedAssigneId,
          direction: "ASC",
          officeId: selectedOffice,
          page: 0,
          size: 50,
        },
      })
      .then((res) => {
        setAppointments(res.data.data.content);
        console.log("32---->", res.data.data.content);
        setbackdropopen(false);
      })
      .catch((err) => {
        // toast.error("No Appointments Scheduled");
        console.log(err);
        setbackdropopen(false);
      });
  };

  const getConsultant = (officeId) => {
    axios
      .get(`${base_url.api2}/getUsersSelectByOfficeId`, {
        headers: {
          Authorization: jwtToken,
          officeId: Number(officeId),
        },
      })
      .then((res) => {
        setConsultant(res.data.data);
      })
      .catch((error) => {
        setConsultant(null);
      });
  };

  useEffect(() => {
    allappointmentsDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const allappointmentsDetails = () => {
    setbackdropopen(true);
    axios
      .get(`${base_url.api4}/getAppointmentsByFilter`, {
        headers: {
          Authorization: jwtToken,
          columnName: "appointmentDate",
          consultantId: null,
          direction: "ASC",
          officeId: null,
          page: 0,
          size: 50,
        },
      })
      .then((res) => {
        setAppointments(res.data.data.content);
        console.log("32---->", res.data.data.content);
        setbackdropopen(false);
      })
      .catch((err) => {
        // toast.error("No Appointments Scheduled");
        console.log(err);
        setbackdropopen(false);
      });
  };

  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const handleEventClick = (event) => {
    setSelectedEvent(event);
  };

  const handleCloseModal = () => {
    setSelectedEvent(null);
    setIsOpen(false);
  };
  // const eventsData = appointments.map((appointment) => ({
  //   id: appointment.id,
  //   title: `${appointment.notes}`,
  //   start: new Date(appointment.appointmentDate),
  //   end: new Date(appointment.appointmentDate), // Assuming each appointment lasts for 15 minutes
  // }));
  const eventsData = getAppointments.map((appointment) => ({
    id: appointment.id,
    title: `${appointment.notes}`,
    start: new Date(appointment.appointmentDate),
    end: new Date(appointment.appointmentDate), // Assuming each appointment lasts for 15 minutes
    appointmentDetails: appointment.notesDetails,
    consultantName: appointment.consultantName,
    clientName: appointment.clientName,
    status: appointment.status,
  }));

  const [selecteddate, setdate] = useState(null);
  const [appointmentenquirystepid, setappointmentenquirystepid] = useState();

  const isoDateString = new Date().toISOString();
  const datetimelocal = isoDateString.split(".")[0];

  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            navigate("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };

  return (
    <div>
      <Backdrop className={classes.backdrop} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer />
      {/* <div className="commoncomponent_cardcss"> */}
      <div
        class="page-head d-flex justify-content-between p-4"
        style={{ marginTop: "4.3rem" }}
      >
        <ul class="breadcrums">
          <h3 class="page-title bold">Manage Appointment </h3>
          <Link to="/" class="b-link">
            Home
          </Link>
          <span className="s_span ">&raquo;</span>
          <a class="b-link">Appointment</a>
          <span className="s_span ">&raquo;</span>
          <Link to="/leads-list" class="b-link active">
            Appointment List
          </Link>
        </ul>
        <Appointmentmodal
          selecteddate={selecteddate}
          datetimelocal={datetimelocal}
          setdate={setdate}
          handle403error={handle403error}
          allappointmentsDetails={allappointmentsDetails}
        />
      </div>
      <div>
        <div
          className="newlisting_headertabdesign"
          style={{
            padding: "2.5rem",
            border: "1.5rem solid #e9ebf2",
            marginTop: "0",
            marginBottom: "0",
          }}
        >
          <div
            style={{
              marginBottom: "30px",
              marginTop: "-18px",
              marginRight: "80px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <div>
              <label className="area_rowslable_my ">
                Branch<span style={{ color: "red" }}>*</span>:
              </label>
              <select
                className={`${classes.selectdrop} form-control`}
                onInput={handleOffice}
                // required
                name="officeName"
                style={{ backgroundColor: "#f5f5f9" }}
                // ref={register({ required: true })}
              >
                <option value=""> Branch </option>
                {user2?.isw_available_Office?.map((p, index) => (
                  <option
                    key={p.officeId}
                    id={p.officeId}
                    value={p.officeName}
                    officeCode={p.officeCode}
                  >
                    {p.officeName}
                    {" - "}
                    {p.officeCode}
                  </option>
                ))}
              </select>
            </div>
            <div style={{ width: "20%", marginInline: "10px" }}>
              <label className="area_rowslable_my">
                Consultant<span style={{ color: "red" }}>*</span>:
              </label>
              <select
                className={`${classes.selectdrop} form-control`}
                onInput={handleAssigne}
                // required
                disabled={branchselected}
                name="assigneeName"
                style={{ backgroundColor: "#f5f5f9" }}
                // ref={register({ required: true })}
              >
                <option value="null">- Select Consultant -</option>
                {allconsultant != null ? (
                  allconsultant.map((cuntitem) => (
                    <option
                      key={cuntitem.id}
                      id={cuntitem.id}
                      value={cuntitem.email}
                      consultantname={`${cuntitem.firstName} ${cuntitem.lastName}`}
                    >
                      {cuntitem.firstName} {cuntitem.lastName} ({cuntitem.email}
                      )
                    </option>
                  ))
                ) : (
                  <option value="">No Consultant Available</option>
                )}
              </select>
            </div>
          </div>
          <div
            style={{
              width: "100%",
              maxWidth: "1500px",
              margin: "0 auto",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              borderRadius: "8px",
              overflow: "visible",
              backgroundColor: "#ffffff",
              marginTop: "35px",
            }}
          >
            <Calendar
              localizer={localizer}
              events={eventsData}
              startAccessor="start"
              endAccessor="end"
              titleAccessor="title"
              descriptionAccessor="desc"
              style={{ height: 800 }}
              onSelectEvent={handleEventClick}
            />
          </div>
          {selectedEvent && (
            <ViewAppointmentModal
              selectedEvent={selectedEvent}
              handleCloseModal={handleCloseModal}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AppointmentCalendar;
