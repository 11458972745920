import React, { useState, useEffect } from "react";
import axios from "axios";
import base_url from "../../api/bootapi";
import { useParams, Link } from "react-router-dom";
import "../css/ViewEnquiryWorkFlow.css";
import PropTypes from "prop-types";
import { deepOrange } from "@mui/material/colors";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector, useDispatch } from "react-redux";
import { selectdashboard } from "../features/dashboardSlice";
import OfficeUserDetail from "./OfficeUserDetail";
import OfficeClientDetail from "./OfficeClientDetail";
import { logout, selectUser, setdashboardcheck } from "../features/userSlice";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  check4pointpermission,
  check1pointpermission,
} from "../Permission/permission";
import ClientImage from "./../images/client-dashboard/client-image.png";

const drawerWidth = 300;
const drawerWidth2 = 60;
const selectheight = "35px";
const selectWidth = "100%";
const headtxt = 30;

function TabPanel(props) {
  return (
    <div
      role="tabpanel"
      hidden={props.value !== props.index}
      id={`simple-tabpanel-${props.index}`}
      aria-labelledby={`simple-tab-${props.index}`}
    >
      {props.value === props.index && props.component}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const ViewOffice = () => {
  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  const params = useParams();
  const [value, setValue] = React.useState(0);
  const dashboardvalue = useSelector(selectdashboard);
  const [stateCust, setstateCust] = useState({});
  const [backdrop, setbackdrop] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        navigate("/");
      }
    });
    getPermissions();
  }, []);

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);

    if (check1pointpermission(608, permission_obj, resource_obj)) {
      //if (available_permission){
      dispatch(setdashboardcheck(21));
      setbackdrop(true);
      getOfficeDetails();
      // } else {
      //   navigate("/errorPage");
      // }
    } else {
      navigate("/errorPage");
    }
  };

  const getOfficeDetails = () => {
    axios
      .get(`${base_url.api1}/getOfficeById`, {
        headers: {
          Authorization: jwtToken,
          officeId: params.branch_id,
        },
      })
      .then((res) => {
        setstateCust(res.data.data);
        setbackdrop(false);
      })
      .catch((err) => {
        setbackdrop(false);
        handle403error(err);
      });
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const createoffice = () => {
    navigate("/create-office");
  };

  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            navigate("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };

  return (
    <>
      <div class="sp lead-page" style={{ marginTop: "50px" }}>
        <div
          class="page-head d-flex justify-content-between p-4"
          style={{ marginTop: "5rem" }}
        >
          <ul class="breadcrums">
            <h3 class="page-title bold">Branch List</h3>
            <Link to="/" class="b-link">
              Home
            </Link>
            -<a class="b-link">Access Level</a>-
            <a class="b-link active">Branch List</a>
          </ul>
          {check1pointpermission(605, permissionData, rolepermission) ? (
            <a
              className="sent-button"
              onClick={createoffice}
              style={{ float: "right" }}
            >
              Add
            </a>
          ) : null}
        </div>
        <div class="page-card_container p-4">
          <div class="content-wrapper">
            <div class="card client-profile" id="cardClient">
              <div class="minimize-profile">
                <span
                  onClick={() => {
                    let card = document.getElementById("cardClient");
                    card.style.display = "none";
                    let cardNum = document.getElementById("cardNumber");
                    cardNum.style.marginLeft = "0px";
                    let right_sp = document.getElementById("right_span");
                    right_sp.style.visibility = "visible";
                  }}
                >
                  {" "}
                  &#171;{" "}
                </span>
              </div>
              <div class="options">
                <input type="checkbox" id="option-checkbox" class="checkbox" />
                <label class="icon" for="option-checkbox">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <rect width="24" height="24" rx="6" fill="white" />
                    <path
                      d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                      stroke="#B1B1CA"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M12 8C12.5523 8 13 7.55228 13 7C13 6.44772 12.5523 6 12 6C11.4477 6 11 6.44772 11 7C11 7.55228 11.4477 8 12 8Z"
                      stroke="#B1B1CA"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M12 18C12.5523 18 13 17.5523 13 17C13 16.4477 12.5523 16 12 16C11.4477 16 11 16.4477 11 17C11 17.5523 11.4477 18 12 18Z"
                      stroke="#B1B1CA"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </label>
                <div class="options-content">
                  <ul>
                    <li>
                      <a href="#">Option 2</a>
                    </li>
                    <li>
                      <a href="#">Option 3</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="client-img">
                <img src={ClientImage} alt="" />
                <h2 class="mt-2">
                  {" "}
                  {stateCust?.officeName} ({stateCust?.officeCode})
                </h2>

                {/* <div class="status">
                  {clientDetail?.enquiryLeadType === "Converted" ? (
                    <a style={{ cursor: "pointer" }}>
                      <img src={Rocket} alt="" />
                    </a>
                  ) : (
                    <a style={{ cursor: "pointer" }} onClick={() => updateenquiryleadtype("Converted")}>
                      <img src={Rocket} alt="" />
                    </a>
                  )}
                  {clientDetail?.enquiryLeadType === "ColdLead" ? (
                    <a style={{ cursor: "pointer" }}>
                      <img src={SnowFlake} alt="" />
                    </a>
                  ) : (
                    <a style={{ cursor: "pointer" }} onClick={() => updateenquiryleadtype("ColdLead")}>
                      <img src={SnowFlake} alt="" />
                    </a>
                  )}
                  {clientDetail?.enquiryLeadType === "WarmLead" ? (
                    <a style={{ cursor: "pointer" }}>
                      <img src={Coffee} alt="" />
                    </a>
                  ) : (
                    <a style={{ cursor: "pointer" }} onClick={() => updateenquiryleadtype("WarmLead")}>
                      <img src={Coffee} alt="" />
                    </a>
                  )}
                  {clientDetail?.enquiryLeadType === "HotLead" ? (
                    <a style={{ cursor: "pointer" }}>
                      <img src={Sun} alt="" />
                    </a>
                  ) : (
                    <a style={{ cursor: "pointer" }} onClick={() => updateenquiryleadtype("HotLead")}>
                      <img className="cursor" src={Sun} alt="" />
                    </a>
                  )}
                </div> */}
                <br />
              </div>
              <div class="personal-details">
                <h3>Personal Details</h3>
                <div class="email">
                  <p>Email</p>
                  <p>
                    <strong>{stateCust?.emailId}</strong>
                  </p>
                </div>
                <div class="dob">
                  <p> Contact No.</p>
                  <p>
                    <strong>{stateCust?.phone}</strong>
                  </p>
                </div>
                <div class="contact">
                  <p>Contact Person</p>
                  <p>
                    <strong>{stateCust?.contactPerson}</strong>
                  </p>
                </div>
                <div class="address">
                  <p>Address</p>
                  <p>
                    <strong>{stateCust?.street}</strong>
                  </p>
                </div>
                <div class="postal">
                  <p> Latitude</p>
                  <p>
                    <strong>{stateCust?.latitude}</strong>
                  </p>
                </div>
                <div class="visa-expiry">
                  <p> Longitude</p>
                  <p>
                    <strong>{stateCust?.longitude}</strong>
                  </p>
                </div>
                <div class="visa-type">
                  <p> PostCode</p>
                  <p>
                    <strong>{stateCust?.zipCode}</strong>
                  </p>
                </div>
              </div>
            </div>
            <div class="card client-details" id="cardNumber">
              <div class="maximize-profile">
                <span
                  onClick={() => {
                    let cardNum = document.getElementById("cardNumber");
                    cardNum.style.marginLeft = "20px";
                    let right_sp = document.getElementById("right_span");
                    right_sp.style.visibility = "hidden";
                    let card = document.getElementById("cardClient");
                    card.style.display = "block";
                  }}
                  id="right_span"
                  style={{ visibility: "hidden" }}
                >
                  &#187;{" "}
                </span>
              </div>
              {/* <AppBar position="static" color="default"> */}
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                <Tab className="tab-bold" label="Users" {...a11yProps(0)} />

                <Tab className="tab-bold" label="Clients" {...a11yProps(1)} />
              </Tabs>
              {/* </AppBar> */}
              <br />
              <TabPanel
                value={value}
                index={0}
                component={
                  <OfficeUserDetail officeName={stateCust?.officeName} />
                }
              />
              <TabPanel
                className="tabpanel"
                value={value}
                index={1}
                component={
                  <OfficeClientDetail officeName={stateCust?.officeName} />
                }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewOffice;
