import React, { useState } from "react";
import Modal from "react-modal";
import { customStyles } from "../../Utilities/CSSUtilities";
import { Col, Row, Button } from "reactstrap";
import PasswordStrengthBar from "react-password-strength-bar";
import axios from "axios";
import base_url from "../../api/bootapi";
import callsaveauditapi from "../../services/auditservice";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { logout } from "../features/userSlice";

const UserModal = ({
  username,
  inputtype,
  chkPassState,
  setchkPassState,
  setinputtype,
  jwtToken,
  toast,
  isw_user_name,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [passwordmodal, setpasswordmodal] = useState(false);
  const [selecteduser, setselecteduser] = useState("");
  const closeModal = () => setpasswordmodal(false);

  const openmodal = (elem) => {
    setselecteduser(elem.target.id);
    setpasswordmodal(true);
  };

  const changePassword = (e) => {
    let email = e.target[0].value;
    let password;
    password = chkPassState.trim();
    let data = {
      email,
      password,
    };
    axios
      .post(`${base_url.api2}/updatePasswordByEmail`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        toast.success(response.data.message);
        let logs =
          isw_user_name +
          " Updated Password for the user " +
          e.target[0].value +
          ".";
        callsaveauditapi(logs, dispatch, navigate);
        closeModal();
      })
      .catch((error) => {
        toast.error(JSON.stringify(error.response.data.message));
        //console.log(JSON.stringify(error.response));
        handle403error(error);
      });
    // } else {
    //   return false;
    // }
  };

  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            navigate("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };

  return (
    <>
      <span style={{ cursor: "pointer" }} id={username} onClick={openmodal}>
        Chg-Pwd
      </span>
      <Modal
        isOpen={passwordmodal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            changePassword(e);
          }}
        >
          <div>
            <h3 style={{ color: "black" }}>Update Password</h3>
            <div className="form-group creatadmin">
              <Row>
                <Col>
                  <label>User Name:</label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    value={selecteduser}
                    readOnly
                    required
                  />
                </Col>
              </Row>
            </div>
            <div className="form-group creatadmin">
              <Row>
                <Col>
                  <label>Password:</label>
                  <div style={{ position: "relative" }}>
                    <input
                      type={inputtype}
                      className="form-control underline-input"
                      value={chkPassState}
                      onChange={(e) => setchkPassState(e.target.value)}
                      required
                    />
                    {inputtype === "password" ? (
                      <i
                        className="fa fa-eye-slash fonticonpositioning"
                        onClick={() => setinputtype("text")}
                        aria-hidden="true"
                      ></i>
                    ) : (
                      <i
                        className="fa fa-eye fonticonpositioning"
                        onClick={() => setinputtype("password")}
                        aria-hidden="true"
                      ></i>
                    )}
                  </div>
                  <PasswordStrengthBar password={chkPassState} />
                </Col>
              </Row>
            </div>
            <div className="form-group creatadmin">
              <div className="d-flex justify-content-end mt-2">
                <Button color="secondary" onClick={closeModal}>
                  Cancel
                </Button>
                <button className="btn listing_addbutton ml-1" type="submit">
                  Update
                </button>
              </div>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default UserModal;
