import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import calender from "../../images/workload/calendar.svg";
import DatePicker from "react-datepicker";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import base_url from "../../../api/bootapi";
import moment from "moment";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../features/userSlice";
import { useForm } from "react-hook-form";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "400px", // Adjust the width as needed
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
    backgroundColor: "#fff",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1000,
  },
};

function Appointmentmodal({ handle403error, allappointmentsDetails }) {
  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  const isw_user_email = localStorage.getItem("isw_user_email");
  const [modalIsopen4, setIsOpen4] = useState(false);
  const selected_client_id = localStorage.getItem("selected_client_id");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const user2 = useSelector(selectUser);
  const [rows, setrows] = React.useState(50);

  const openmodalappointment = () => {
    setIsOpen4(true);
  };

  const closeModal = () => {
    setIsOpen4(false);
  };

  useEffect(() => {
    getConsltant();
  }, [rows]);

  const [appointmentNote, setAppointmentNote] = useState("");
  const [getClientId, setClientId] = useState();
  const [getClientName, setClientName] = useState();
  const [getConsultantName, setConsultantName] = useState();
  const [getConsultantId, setConsultantId] = useState();
  const [selecteddate, setSelectedDate] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [wordEntered, setWordEntered] = useState("");
  const [loadOptions, setLoadOptions] = useState([]);

  const onSubmitAppointment = async (e) => {
    e.preventDefault();

    let appointmentDate = moment(selecteddate).format("YYYY-MM-DDThh:mm:ss");
    let clientId = getClientId;
    let clientName = getClientName;
    let counsellingCreatedBy = getConsultantId;
    let counselorName = getConsultantName;
    let id = 0;
    let notes = appointmentNote;
    let enquiryId = Number(0);
    let enquiryWorkflowStepId = Number(0);
    let logs2 = "Added appointment - By " + user2.isw_user_name;

    let data = {
      appointmentDate,
      clientId,
      clientName,
      counsellingCreatedBy,
      enquiryWorkflowStepId,
      counselorName,
      id,
      notes,
      enquiryId,
    };

    try {
      setbackdropopen(true);
      await axios.post(`${base_url.api4}/addAppointments`, data, {
        headers: {
          Authorization: jwtToken,
          log: logs2,
        },
      });
      setbackdropopen(false);
      allappointmentsDetails();
      toast.success("Appointment Created");
      setAppointmentNote(null);
      setSelectedDate(null);
      setWordEntered(null);
      closeModal();
    } catch (err) {
      setbackdropopen(false);
      allappointmentsDetails();
      toast.error(JSON.stringify(err?.response?.data?.message));
      setAppointmentNote(null);
      setSelectedDate(null);
      handle403error(err);
    }
  };

  const [assigneeData, setAssigneeData] = useState();
  const getConsltant = () => {
    axios
      .get(`${base_url.api2}/getAllUsers`, {
        headers: {
          Authorization: jwtToken,
          direction: "ASC",
          columnName: "firstName",
          status: 1,
          size: Number(50),
        },
      })
      .then((res) => {
        let apiData = res.data.data.content;
        const consultant_obj = [];
        for (let i = 0; i < apiData.length; i++) {
          let data = {
            value: apiData[i].id,
            label: apiData[i].firstName + " " + apiData[i].lastName,
            email: apiData[i].email,
          };
          consultant_obj.push(data);
        }
        //console.log(consultant_obj);
        setAssigneeData(consultant_obj);
      })
      .catch((error) => {
        setAssigneeData();
        handle403error(error);
      });
  };

  const [assigneeState, setAssigneeState] = useState();
  const [selectedAssigneeData, setSelectedAssigneeData] = useState(null);
  const [client_users, setClientDetails] = useState([]);
  const [backdropopen, setbackdropopen] = useState(false);

  const onSelectWorkload = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementName = optionElement.getAttribute("value");
    const optionElementId = optionElement.getAttribute("id");
    setConsultantName(optionElementId);
    setConsultantId(optionElementName);
    const consultant_obj = [];
    if (consultant_obj.length > 0) {
      setSelectedAssigneeData(optionElementName);
      setAssigneeState(true);
      axios
        .get(`${base_url.api3}/searchClientByAllFilter`, {
          headers: {
            Authorization: jwtToken,
            fieldName: "client",
            fieldValue: 1,
            assigneeId: optionElementName,
            columnName: "lastUpdated",
            direction: "DESC",
            page: Number(0),
            size: Number(rows),
          },
        })
        .then((response) => {
          const client_obj = [];
          for (let i = 0; i < response.data.data.content.length; i++) {
            const element = response.data.data.content[i];
            client_obj.push(element);
          }
          const clientdetails = [];
          for (let i = 0; i < client_obj.length; i++) {
            clientdetails.push(client_obj[i].clientDetail);
          }
          setbackdropopen(false);
          setClientDetails(response.data.data.content);
        })
        .catch((error) => {
          setbackdropopen(false);
          setClientDetails(null);
          handle403error(error);
        });
    } else {
      setAssigneeState(false);
      axios
        .get(`${base_url.api3}/searchClientByAllFilter`, {
          headers: {
            Authorization: jwtToken,
            fieldName: "client",
            fieldValue: 1,
            assigneeId: optionElementName,
            columnName: "lastUpdated",
            direction: "DESC",
            page: Number(0),
            size: Number(rows),
          },
        })
        .then((response) => {
          const client_obj = [];
          for (let i = 0; i < response.data.data.content.length; i++) {
            const element = response.data.data.content[i];
            client_obj.push(element);
          }
          const clientdetails = [];
          for (let i = 0; i < client_obj.length; i++) {
            clientdetails.push(client_obj[i].clientDetail);
          }
          setbackdropopen(false);
          setClientDetails(response.data.data.content);
          try {
            document.getElementById("myForm").reset();
          } catch (error) {
            //console.log(error);
          }
        })
        .catch((error) => {
          setbackdropopen(false);
          setClientDetails(null);
          handle403error(error);
        });
    }
  };

  const onSelectClient = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementName = optionElement.getAttribute("value");
    const optionElementId = optionElement.getAttribute("id");
    setClientName(optionElementName);
    setClientId(optionElementId);
    console.log(optionElementName, optionElementId);
  };

  let canceltoken;

  const clearInput = () => {
    setFilteredData([]);
    setWordEntered("");
  };

  const handleInputChange = (event) => {
    const searchWord = event.target.value;
    setWordEntered(searchWord);
    if (searchWord) {
      if (typeof canceltoken !== typeof undefined) {
        canceltoken.cancel("Cancelling previous request");
      }
      canceltoken = axios.CancelToken.source();
      axios
        .get(`${base_url.api3}/searchClientSelect`, {
          headers: {
            Authorization: jwtToken,
            searchQuery: searchWord,
          },
          cancelToken: canceltoken.token,
        })
        .then((res) => {
          const updatedData = res.data.data.filter(
            (person) => person.status !== 0
          );
          const office_obj = updatedData.map((person) => {
            const sp = person.middleName
              ? `${person.firstName} ${person.middleName} ${person.lastName} (${person.email})`
              : `${person.firstName} ${person.lastName} (${person.email})`;

            return {
              value: person.id,
              label: sp,
              email: person.email,
              status: person.status,
            };
          });

          setLoadOptions(office_obj);
          setFilteredData(office_obj);
        })
        .catch((err) => {
          handle403error(err);
        });
    } else {
      setLoadOptions([]);
    }
  };

  const handleItemClick = (label, id, email) => {
    setWordEntered(label);
    setClientId(id);
    setClientName(email);
    setFilteredData([]);
  };

  return (
    <>
      <div style={{ marginTop: "auto" }}>
        <Link
          class="sent-button"
          style={{ fontWeight: "revert", textDecoration: "none" }}
          onClick={openmodalappointment}
        >
          Add Appointment
        </Link>
      </div>
      <Modal
        isOpen={modalIsopen4}
        style={customStyles}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
      >
        <div>
          <h2>Create Appointment</h2>
          <hr />
          <div>
            <form onSubmit={onSubmitAppointment}>
              <label>
                Appointment Note<span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                name="appointmentNote"
                className="form-control"
                value={appointmentNote}
                onChange={(e) => setAppointmentNote(e.target.value)}
                required
              />
              <hr />
              <label>
                Appointment Date<span style={{ color: "red" }}>*</span>
              </label>
              <DatePicker
                selected={selecteddate}
                onChange={(date) => setSelectedDate(date)}
                minDate={new Date()}
                showTimeSelect
                timeFormat="p"
                timeIntervals={15}
                dateFormat="Pp"
                required
              />
              <br />
              <label>
                Consultant<span style={{ color: "red" }}>*</span>
              </label>
              <select
                className="form-control"
                onChange={onSelectWorkload}
                name="assigneeId"
                style={{ width: "100%", backgroundColor: "#f5f5f9" }}
                required
              >
                <option value="">Select Consultant</option>
                {assigneeData?.map((p, index) => (
                  <option key={index} id={p.email} value={p.value}>
                    {p.label}
                  </option>
                ))}
              </select>
              <label>
                Client<span style={{ color: "red" }}></span>
              </label>
              <div className="searchInputs">
                <input
                  type="text"
                  value={wordEntered}
                  placeholder="Student Name"
                  // onChange={handleFilter}
                  onChange={handleInputChange}
                  className="header_dropdown"
                />
                <div className="searchIcon">
                  {filteredData.length === 0 ? (
                    <SearchOutlinedIcon />
                  ) : (
                    <CloseOutlinedIcon id="clearBtn" onClick={clearInput} />
                  )}
                </div>
              </div>
              {filteredData.length != 0 && (
                <div className="dataResult">
                  <>
                    {filteredData.map((val, key) => {
                      if (val.status == 1) {
                        return (
                          <p
                            onClick={() =>
                              handleItemClick(val.label, val.value, val.email)
                            }
                            style={{ color: "black" }}
                          >
                            {val.label}
                          </p>
                        );
                      }
                    })}
                  </>
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: "5px",
                }}
              >
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={closeModal}
                >
                  Cancel
                </button>
                <button className="btn listing_addbutton ml-1" type="submit">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default Appointmentmodal;
