import React, { useEffect, useState } from "react";
import axios from "axios";
import base_url from "../../api/bootapi";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import "../css/Notes.css";
import moment from "moment";
import Pagination from "@mui/material/Pagination";
import { Table } from "reactstrap";
import { useDispatch } from "react-redux";
import { logout } from "../features/userSlice";
import { useNavigate } from "react-router-dom";
const WorkflowLogs = (props) => {
  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  const params = useParams();
  const [logs, setLogs] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [documenttotalUser, setdocumenttotalUser] = useState();
  const [documentcountnumber, setdocumentcountnumber] = useState();
  const [documentnumberofElements, setdocumentnumberofElements] = useState();
  const [documentdetails, setdocumentdetails] = useState([]);
  const [documentgetpageno, setdocumentgetpageno] = useState();

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        navigate("/");
      }
    });
    getLogsByEnquiryId();
  }, []);

  const handlepage = (event, value) => {
    let data = logs.slice((value - 1) * 20, value * 20);
    setdocumentcountnumber(Number(value - 1));
    setdocumentdetails(data);
    setdocumentnumberofElements(data.length);
  };

  const getLogsByEnquiryId = () => {
    console.log("43--->", props.enquiryId);
    if (props.enquiryId) {
      axios
        .get(`${base_url.api2}/getLogsByEnquiryId`, {
          headers: {
            Authorization: jwtToken,
            enquiryId: props.enquiryId,
          },
        })
        .then((res) => {
          console.log("51--->", res.data.data);
          setLogs(res.data.data);
          const data = res.data.data;
          data.sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn));
          console.log("51--->", data);
          const data2 = data.slice(0, 20) || [];
          setdocumentcountnumber(Number(0));
          setdocumentdetails(
            res.data.data.length > 0 ? res.data.data.slice(0, 20) : []
          );
          setdocumentnumberofElements(data2.length);
          setdocumentgetpageno(Math.ceil(Number(res.data.data.length) / 20));
          setdocumenttotalUser(res.data.data.length);
        })
        .catch((err) => {
          toast.error(JSON.stringify(err?.response?.data?.message));
          handle403error(err);
        });
    }
  };

  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            navigate("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };
  return (
    <div>
      <Table responsive striped>
        <thead>
          <tr>
            <th style={{ width: "10%" }}>SN</th>
            <th style={{ width: "60%" }}>Log</th>
            <th style={{ width: "35%" }}>Date</th>
          </tr>
        </thead>
        <tbody>
          {documentdetails?.map((item, index) => (
            <tr key={index + 1}>
              <td>{index + 1}</td>
              <td>{item.log}</td>
              <td>
                {moment(item.createdOn).format("MMMM DD, YYYY, h:mm:ss A")}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          {documentdetails?.length ? (
            <p>
              {documentcountnumber * 20 + 1} to{" "}
              {documentcountnumber * 20 + documentnumberofElements} of{" "}
              {documenttotalUser} records
            </p>
          ) : (
            <p>No Record Found </p>
          )}
        </div>
        <Pagination
          count={documentgetpageno}
          onChange={handlepage}
          shape="rounded"
        />
      </div>
    </div>
  );
};

export default WorkflowLogs;
