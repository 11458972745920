// DashboardIcon.jsx
import React from "react";
import { ReactComponent as DashboardIconSvg } from "./DashboardIcon.svg";

const DashboardIcon = () => (
  <i className="icon">
    <DashboardIconSvg />
  </i>
);

export default DashboardIcon;

//explicit return
/* import React from "react";
import { ReactComponent as DashboardIconSvg } from "./DashboardIcon.svg";

const DashboardIcon = () => {
  return (
    <i className="icon">
      <DashboardIconSvg />
    </i>
  );
};

export default DashboardIcon; */
