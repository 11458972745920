import React, { useState, useRef } from "react";
import Modal from "react-modal";
import { Button } from "reactstrap";
import { mycustomStyles } from "../../../Utilities/CSSUtilities";
// import close from "../src/components/images/closeicon.png";
import close from "./../../../../src/components/images/closeicon.png";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import axios from "axios";
import base_url from "./../../../api/bootapi";
import { fontFamily } from "@mui/system";

const LeadViewMoreModal = ({
  p,
  toast,
  id,
  headData,
  handle403error,
  getallLeads,
  sortState,
  direction,
  pageno,
  rows,
}) => {
  const [deletemodal, setdeletemodal] = useState(false);
  const [descriptionState2, setdescriptionState2] = useState(null);
  const [rema, setrema] = useState(false);
  const [getpageno, setgetpageno] = useState();
  const [totalUser, settotalUser] = useState();
  const [countnumber, setcountnumber] = useState();
  const [numberofElements, setnumberofElements] = useState();
  const [enquiry, setEnquiry] = useState(null);
  const opendeletemodal = () => {
    setdeletemodal(true);
    setrema(p.remarks);
  };
  //console.log(p);
  const isw_user_name = localStorage.getItem("isw_user_name");
  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  const closedeletemodal = () => setdeletemodal(false);

  const handleImageUploadBefore = (files, info, uploadHandler) => {
    //console.log(files, info);
  };
  const editorRef = useRef();

  const changeRemarks = (e) => {
    p.remarks = e;
    setdescriptionState2(p.remarks);
  };

  const editRemark = (e) => {
    const isoDateString = new Date().toISOString();
    const datenow = isoDateString.split(".")[0];
    const data = {
      id: id,
      addedBy: isw_user_name,
      remarks: descriptionState2,
      updatedOn: datenow,
    };
    axios
      .post(`${base_url.api3}/updateClientTempById`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        toast.success("Remarks Updated");
        getallLeads(
          (headData = {
            Authorization: jwtToken,
            columnName: sortState,
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
            status: 1,
          })
        );
        setdeletemodal(false);
      })
      .catch((err) => {
        handle403error(err);
      });
  };

  return (
    <>
      <button
        style={{ cursor: "pointer" }}
        className="role-deletebtn"
        onClick={opendeletemodal}
      >
        View
      </button>
      <Modal
        isOpen={deletemodal}
        onRequestClose={closedeletemodal}
        style={mycustomStyles}
        contentLabel="Example Modal"
        shouldCloseOnOverlayClick={false}
      >
        <h4 style={{ marginBottom: "20px" }}>Remark</h4>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            editRemark(e);
          }}
        >
          <SunEditor
            rows={12}
            ref={editorRef}
            style={{ backgroundColor: "#eee" }}
            // style={{ minWidth: "600px" , backgroundColor:"#eee" }}
            height="450"
            setContents={rema}
            onChange={(e) => changeRemarks(e)}
            onImageUploadBefore={handleImageUploadBefore}
            setOptions={{
              buttonList: [
                [
                  "bold",
                  "underline",
                  "italic",
                  "strike",
                  "list",
                  "align",
                  "outdent",
                  "align",
                  "subscript",
                  "fontColor",
                ],
              ],
            }}
            required
          />
          {/* {rema ? (
                <textarea
                style={{ minWidth: "600px" , backgroundColor:"#eee" }}
                className="form-control"
                value={rema}
                rows={10}
                readonly
                />
                ) : (
                <p> No Record Available</p>
            )} */}
          <div
            style={{
              display: "flex",
              marginTop: "20px",
              justifyContent: "flex-end",
              gap: "10px",
            }}
          >
            <button
              className="btn btn-secondary cancel_margin"
              onClick={() => setdeletemodal(false)}
              style={{ width: "85px" }}
            >
              Cancel
            </button>
            <button className="btn listing_addbutton ml-1" type="submit">
              Update
            </button>
          </div>
        </form>
      </Modal>
      {/* <Modal
        isOpen={deletemodal}
        onRequestClose={closedeletemodal}
        style={mycustomStyles}
        contentLabel="Example Modal"
      >
        <div
          style={{
            textAlign: "right",
            padding: "15px 25px",
          }}
        >
          <img
            className="closei"
            src={close}
            onClick={() => setdeletemodal(false)}
          />
        </div>
        <div style={{ padding: "15px 35px 30px" }}>
          <p style={{ fontSize: '20px' }}>Remark</p>
          {rema ? (
            <textarea
              style={{ backgroundColor: "#F3F3F3", resize: "none", borderRadius: "8px", minWidth: "600px" }}
              className="form-control"
              value={rema}
              placeholder="Enter Remark"
              rows={10}
              readonly
            />
          ) : (
            <p> No Record Available</p>
          )}
          <div
            style={{
              display: "flex",
              marginTop: "30px",
              gap: "8px",
              justifyContent: "flex-end",
            }}
          >
            <button
              style={{ backgroundColor: '#F082AC',color: "#fff",padding:"8px 15px",outline:"none",borderRadius:"8px",border:"none" }}
              onClick={() => setdeletemodal(false)}
            >
              Cancel
            </button>
            <button style={{ backgroundColor: '#6F93F6', color: "#fff",padding:"8px 15px",outline:"none",borderRadius:"8px",border:"none" }} type="submit">
              Update
            </button>
          </div>
        </div>
      </Modal> */}
    </>
  );
};

export default LeadViewMoreModal;
