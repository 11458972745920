import React, { useState, useEffect } from "react";
import "../css/AddInvoiceDetails.css";
import "../css/Responsive.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useParams, useNavigate, Link } from "react-router-dom";
import axios from "axios";
import base_url from "../../api/bootapi";
import { toast } from "react-toastify";
import moment from "moment";
import { selectdashboard } from "../features/dashboardSlice";
import attachmenturl from "../../api/attachmenturl";
import { Button } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { logout, selectUser, gettabvalue } from "../features/userSlice";
import Modal from "react-modal";
import { customStyles } from "../../Utilities/CSSUtilities";
import { check1pointpermission } from "../Permission/permission";
import { textAlign } from "@mui/system";

const selectheight = "35px";
const selectWidth = "100%";
const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
  },
  selectdrop: {
    width: selectWidth,
    height: selectheight,
  },
  backdrop: {
    // zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    zIndex: 1000,
  },
}));
const ViewInvoice = () => {
  const isoDateString = new Date().toISOString();
  const datenow = isoDateString.split(".")[0];
  const user = useSelector(selectUser);
  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  const isw_user_name = localStorage.getItem("isw_user_name");
  const isw_user_role = localStorage.getItem("isw_user_role");
  const classes = useStyles();
  const [newline, setnewline] = useState([]);
  const [backdropopen, setbackdropopen] = useState(false);
  const params = useParams();
  const [commision, setcommision] = useState();
  const [modalIsOpen, setisopen] = useState(false);
  const [rollbackmodalIsOpen, setrollbackisopen] = useState(false);
  const [paidmodalIsOpen, setpaidisopen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [clientDetail1, setclientDetail1] = useState();
  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);
  const [enquiryDetails, setEnquiryDetails] = useState();
  const [userDetails, setUserDetails] = useState();
  const [userEmail, setUserEmail] = useState();

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        navigate("/");
      }
    });
    getPermissions();
    console.log("80--->", params);
  }, []);

  useEffect(() => {
    if (params.invoice_id) {
      // document.title = params.invoice_id;
      document.title = "Preview_invoice_" + params.invoice_id;
    } else {
      <title>Nepcoms Services</title>;
    }
  }, [params.invoice_id]);

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);

    if (
      check1pointpermission(340, permission_obj, resource_obj) ||
      check1pointpermission(504, permission_obj, resource_obj)
    ) {
      setbackdropopen(true);
      getClientDetails();
      getinvoicedetails();
      getinvoicepaymentdetails();
      getRefNumber();
    } else {
      navigate("/errorPage");
    }
  };

  const getClientDetails = () => {
    axios
      .get(`${base_url.api3}/getClientById`, {
        headers: {
          Authorization: jwtToken,
          clientId: params.client_id,
        },
      })
      .then((res) => {
        //console.log(res.data.data);
        setclientDetail1(res.data.data);
      })
      .catch((error) => {
        handle403error(error);
      });
  };

  const [stateCust, setInvoiceState] = useState();
  const [stateCust2, setstateCust2] = useState();
  const getinvoicedetails = () => {
    axios
      .get(`${base_url.api2}/getInvoiceById`, {
        headers: {
          Authorization: jwtToken,
          invoiceId: params.invoice_id,
        },
      })
      .then((res) => {
        axios
          .get(`${base_url.api2}/getEnquiryById`, {
            headers: {
              Authorization: jwtToken,
              enquiryId: Number(res.data.data.enquiryId),
            },
          })
          .then((resp) => {
            setEnquiryDetails(resp.data.data);
            setUserEmail(resp.data.data.clientUsername);
          })
          .catch((err) => {
            setEnquiryDetails(null);

            handle403error(err);
          });

        axios
          .get(`${base_url.api2}/getUserById`, {
            headers: {
              Authorization: jwtToken,
              id: Number(res.data.data.invoiceCreatedBy),
            },
          })
          .then((resp) => {
            setUserDetails(resp.data.data);
          })
          .catch((err) => {
            setUserDetails(null);
            handle403error(err);
          });

        let invoiceDetails = [];
        for (let i = 0; i < res.data.data.invoiceDetails.length; i++) {
          invoiceDetails.push({
            amount: res.data.data.invoiceDetails[i].amount,
            commissionPercentage:
              res.data.data.invoiceDetails[i].commissionPercentage,
            commissionAmount: res.data.data.invoiceDetails[i].commissionAmount,
            description: res.data.data.invoiceDetails[i].description,
            id: res.data.data.invoiceDetails[i].id,
            quantity: res.data.data.invoiceDetails[i].quantity,
            taxAmount: res.data.data.invoiceDetails[i].taxAmount,
            taxPercentage: res.data.data.invoiceDetails[i].taxPercentage,
            totalAmount: res.data.data.invoiceDetails[i].totalAmount,
          });
        }
        setInvoiceState({
          clientId: res.data.data.clientId,
          clientUsername: res.data.data.clientUsername,
          agentName: res.data.data.agentName,
          commisionType: res.data.data.commisionType,
          comment: res.data.data.comment,
          courseName: res.data.data.courseName,
          courseId: res.data.data.courseId,
          currency: res.data.data.currency,
          discountAmount: res.data.data.discountAmount,
          discountPercentage: res.data.data.discountPercentage,
          discountPercentageType: res.data.data.discountPercentageType,
          enquiryId: res.data.data.enquiryId,
          enquiryName: res.data.data.enquiryName,
          externalId: res.data.data.externalId,
          id: res.data.data.id,
          incomeSharing: res.data.data.incomeSharing,
          incomeType: res.data.data.incomeType,
          institutionId: res.data.data.institutionId,
          intake: res.data.data.intake,
          invoiceCreatedBy: res.data.data.invoiceCreatedBy,
          invoiceCreatedDate: res.data.data.invoiceCreatedDate,
          invoiceDetails,
          invoiceDueDate: res.data.data.invoiceDueDate,
          invoicePaidDate: res.data.data.invoicePaidDate,
          invoicePath: res.data.data.invoicePath,
          invoiceStatus: res.data.data.invoiceStatus,
          invoiceSubType: res.data.data.invoiceSubType,
          invoiceType: res.data.data.invoiceType,
          netAmount: res.data.data.netAmount,
          netIncome: res.data.data.netIncome,
          serviceProvider: res.data.data.serviceProvider,
          totalAmountIncludingTax: res.data.data.totalAmountIncludingTax,
          totalCommission: res.data.data.totalCommission,
          totalDue: res.data.data.totalDue,
          totalPaid: res.data.data.totalPaid,
          totalTax: res.data.data.totalTax,
          workflowType: res.data.data.workflowType,
        });
        setcommision(res.data.data.commisionType);
        for (let i = 0; i < res.data.data.invoiceDetails.length; i++) {
          setnewline((prev) => [
            ...prev,
            {
              id: 0,
              value: 0,
            },
          ]);
        }
      });
  };
  const getinvoicepaymentdetails = () => {
    axios
      .get(`${base_url.api2}/getInvoicePaymentByInvoiceId`, {
        headers: {
          Authorization: jwtToken,
          invoiceId: params.invoice_id,
          columnName: "date",
          direction: "DESC",
          page: 0,
          size: 20,
        },
      })
      .then((res) => {
        setstateCust2(res.data.data);
        setbackdropopen(false);
      })
      .catch((err) => {
        handle403error(err);
        setbackdropopen(false);
      });
  };
  const goBack = () => {
    dispatch(gettabvalue(4));
    navigate(
      "/view-enquiry-workflowList/" + params.client_id + "/" + userEmail
    );
    // navigate(prev_path);
  };
  const printIframe = (id) => {
    //console.log(id);
    const iframe = document.frames
      ? document.frames[id]
      : document.getElementById(id);
    const iframeWindow = iframe.contentWindow || iframe;

    iframe.focus();
    iframeWindow.print();
    return false;
  };
  const dashboardvalue = useSelector(selectdashboard);
  const editinvoice = () => {
    navigate(
      "/edit-invoice-details/" + params.client_id + "/" + params.invoice_id
    );
  };
  const refundinvoice = () => {
    let data = {
      id: params.invoice_id,
    };
    axios
      .post(`${base_url.api2}/updateInvoiceStatusByInvoiceId`, data, {
        headers: {
          Authorization: jwtToken,
          invoiceStatus: "Cancelled",
        },
      })
      .then((res) => {
        toast.success("Invoice Refunded");
        setisopen(false);
        getinvoicedetails();
      })
      .catch((err) => {
        if (err.response) {
          toast.error(JSON.stringify(err?.response?.data?.message));
        } else {
          toast.error("Something Went Wrong");
        }
        handle403error(err);
      });
  };
  const rollbackinvoice = () => {
    let data = {
      id: params.invoice_id,
    };
    axios
      .post(`${base_url.api2}/updateInvoiceStatusByInvoiceId`, data, {
        headers: {
          Authorization: jwtToken,
          invoiceStatus: "Active",
        },
      })
      .then((res) => {
        toast.success("Refund Rollback Service Successful");
        setrollbackisopen(false);
        getinvoicedetails();
      })
      .catch((err) => {
        if (err.response) {
          toast.error(JSON.stringify(err?.response?.data?.message));
        } else {
          toast.error("Something Went Wrong");
        }
        handle403error(err);
      });
  };
  const changetopaidinvoice = () => {
    let data = {
      id: params.invoice_id,
    };
    axios
      .post(`${base_url.api2}/updateInvoiceStatusByInvoiceId`, data, {
        headers: {
          Authorization: jwtToken,
          invoiceStatus: "Paid",
        },
      })
      .then((res) => {
        toast.success("Invoice Paid");
        setpaidisopen(false);
        getinvoicedetails();
      })
      .catch((err) => {
        if (err.response) {
          toast.error(JSON.stringify(err?.response?.data?.message));
        } else {
          toast.error("Something Went Wrong");
        }
        handle403error(err);
      });
  };

  const downloadAll = (applicationFormPath) => {
    setbackdropopen(true);
    let file_name =
      applicationFormPath.attachmentPath.split("amazonaws.com/")[1];
    //console.log(file_name);
    const header = {
      method: "GET",
      headers: {
        Authorization: jwtToken,
        fileName: `${params?.client_id}/${file_name}`,
      },
    };

    var url = `${base_url.api2}/downloadInvoicePaymentByFileName`;
    fetch(url, header)
      .then((response) => readAllChunks(response))
      .then(async function (response) {
        //console.log(response);
        const downloadFileType = localStorage.getItem(
          "invoice_attachment_file_content_type"
        );
        //console.log(downloadFileType);
        var blob = new Blob(response, { type: downloadFileType });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = file_name;
        link.click();
        setbackdropopen(false);
      })
      .catch((error) => {
        setbackdropopen(false);
        //console.log(error);
        handle403error(error);
      });
  };

  const readAllChunks = (readableStream) => {
    localStorage.setItem(
      "invoice_attachment_file_content_type",
      readableStream.headers.get("content-type")
    );
    const reader = readableStream.body.getReader();
    const chunks = [];

    function pump() {
      return reader
        .read()
        .then(({ value, done }) => {
          if (done) {
            return chunks;
          }
          chunks.push(value);
          return pump();
        })
        .catch((err) => {
          handle403error(err);
        });
    }
    return pump();
  };

  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            navigate("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };

  const [getParentInvoice, setParentInvoice] = useState();

  const getRefNumber = () => {
    axios
      .get(`${base_url.api2}/getParentInvoiceIdByPayableId`, {
        headers: {
          Authorization: jwtToken,
          payableId: params.invoice_id,
        },
      })
      .then((res) => {
        //console.log(res.data.data);
        setParentInvoice(res.data.data.invoiceId);
      })
      .catch((error) => {
        handle403error(error);
      });
  };

  return (
    <>
      {stateCust?.invoiceType === "Payable" ? (
        <iframe
          id="receipt"
          src={
            window.location.origin +
            `/admin/#/payableprintinvoice/${params.client_id}/${params.invoice_id}`
          }
          // src="/printinvoice"
          style={{ display: "none" }}
          title="Receipt"
        />
      ) : (
        <>
          {commision === "General Invoice" ? (
            <iframe
              id="receipt"
              src={
                window.location.origin +
                `/admin/#/generalinvoiceprintinvoice/${params.client_id}/${params.invoice_id}`
              }
              // src="/printinvoice"
              style={{ display: "none" }}
              title="Receipt"
            />
          ) : (
            <>
              {commision === "commissionInvoiceGross" ? (
                <iframe
                  id="receipt"
                  src={
                    window.location.origin +
                    `/admin/#/commisiongrossprintinvoice/${params.client_id}/${params.invoice_id}`
                  }
                  // src="/printinvoice"
                  style={{ display: "none" }}
                  title="Receipt"
                />
              ) : (
                <iframe
                  id="receipt"
                  src={
                    window.location.origin +
                    `/admin/#/commisionnetprintinvoice/${params.client_id}/${params.invoice_id}`
                  }
                  // src="/printinvoice"
                  style={{ display: "none" }}
                  title="Receipt"
                />
              )}
            </>
          )}
        </>
      )}
      <Backdrop className={classes.backdrop} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* <div className="commoncomponent_cardcss addinvoice"> */}
      <div
        class="page-head d-flex justify-content-between p-4"
        style={{ marginTop: "4.6rem", marginBottom: "10px" }}
      >
        <ul class="breadcrums">
          <h3 class="page-title bold">Client Invoice</h3>
          <Link to="/" class="b-link">
            Home
          </Link>
          -<a class="b-link">Client</a>-<a class="b-link active">Invoice</a>
        </ul>
        {/* <div class="buttons">
          <Link to="/sent-mails" class="sent-button">Sent</Link>
        </div> */}
      </div>
      <div style={{ padding: "1.5rem", border: "1.5rem solid #e9ebf2" }}>
        {/* <form> */}
        <div className="addinvoice_header justify-content-end">
          {/* <Typography variant="h5" color="primary">
            Client Invoice
          </Typography> */}
          <div className="addinvoice_headerbutton">
            {stateCust?.invoiceType === "Payable" &&
            stateCust?.invoiceStatus === "Paid" ? (
              <>
                {/* {permissionData?.includes(214) ? (
                  <Button
                    className="btn listing_addbutton ml-1"
                    onClick={() => setpaidisopen(true)}
                  >
                    Change status to paid
                  </Button>
                ) : null} */}
              </>
            ) : (
              <>
                {stateCust?.invoiceType === "Payable" ? (
                  <>
                    {check1pointpermission(
                      505,
                      permissionData,
                      rolepermission
                    ) ? (
                      <Button
                        className="btn listing_addbutton ml-1"
                        onClick={() => setpaidisopen(true)}
                      >
                        Change status to paid
                      </Button>
                    ) : null}
                  </>
                ) : (
                  <>
                    {check1pointpermission(
                      506,
                      permissionData,
                      rolepermission
                    ) ? (
                      <Button
                        className="btn listing_addbutton ml-1"
                        onClick={editinvoice}
                      >
                        Edit Invoice
                      </Button>
                    ) : null}
                  </>
                )}
              </>
            )}
            {stateCust?.invoiceStatus === "Cancelled" ? (
              <>
                {Number(isw_user_role) === 1 ||
                Number(isw_user_role) === 5 ||
                Number(isw_user_role) === 6 ? (
                  <>
                    {check1pointpermission(
                      507,
                      permissionData,
                      rolepermission
                    ) ? (
                      <button
                        className="btn listing_addbutton ml-1"
                        onClick={() => setrollbackisopen(true)}
                      >
                        Refund RollBack
                      </button>
                    ) : null}
                  </>
                ) : null}
              </>
            ) : (
              <>
                {stateCust?.invoiceStatus === "Paid" ? (
                  <>
                    {/* {permissionData?.includes(216) ? (
                <Button
                  className="btn listing_addbutton ml-1"
                  onClick={() => setisopen(true)}
                >
                  Refund Invoice
                </Button>
              ) : null} */}
                  </>
                ) : (
                  <>
                    {check1pointpermission(
                      507,
                      permissionData,
                      rolepermission
                    ) ? (
                      <Button
                        className="btn listing_addbutton ml-1"
                        onClick={() => setisopen(true)}
                      >
                        Refund Invoice
                      </Button>
                    ) : null}
                  </>
                )}
              </>
            )}
            <Modal
              isOpen={rollbackmodalIsOpen}
              onRequestClose={() => setrollbackisopen(false)}
              style={customStyles}
              contentLabel="Example Modal"
            >
              <h5>Are you sure you want to apply refund rollback ?</h5>
              <div style={{ float: "right" }}>
                <Button
                  style={{ float: "right", marginRight: "10px" }}
                  color="danger"
                  onClick={rollbackinvoice}
                >
                  Yes
                </Button>
                &nbsp;&nbsp;&nbsp;
                <Button
                  style={{ float: "right", marginRight: "10px" }}
                  color="secondary"
                  onClick={() => setrollbackisopen(false)}
                >
                  No
                </Button>
              </div>
            </Modal>
            <Modal
              isOpen={modalIsOpen}
              onRequestClose={() => setisopen(false)}
              style={customStyles}
              contentLabel="Example Modal"
            >
              <h5>Are you sure you want to Refund current invoice ?</h5>
              <div style={{ float: "right" }}>
                <Button
                  style={{ float: "right", marginRight: "10px" }}
                  color="danger"
                  onClick={refundinvoice}
                >
                  Yes
                </Button>
                &nbsp;&nbsp;&nbsp;
                <Button
                  style={{ float: "right", marginRight: "10px" }}
                  color="secondary"
                  onClick={() => setisopen(false)}
                >
                  No
                </Button>
              </div>
            </Modal>
            <Modal
              isOpen={paidmodalIsOpen}
              onRequestClose={() => setpaidisopen(false)}
              style={customStyles}
              contentLabel="Example Modal"
            >
              <h5>Are you sure you want to change status to paid ?</h5>
              <div style={{ float: "right" }}>
                <Button
                  style={{ float: "right", marginRight: "10px" }}
                  color="danger"
                  onClick={changetopaidinvoice}
                >
                  Yes
                </Button>
                &nbsp;&nbsp;&nbsp;
                <Button
                  style={{ float: "right", marginRight: "10px" }}
                  color="secondary"
                  onClick={() => setpaidisopen(false)}
                >
                  No
                </Button>
              </div>
            </Modal>
            <Button
              className="btn listing_addbutton ml-1"
              onClick={() => printIframe("receipt")}
            >
              Preview & Print
            </Button>

            {userEmail ? (
              <Button className="btn listing_addbutton ml-1" onClick={goBack}>
                Invoice List
              </Button>
            ) : null}
          </div>
        </div>
        <div className="addinvoice_details">
          <div style={{ flex: "0.6" }}>
            <div style={{ flex: "1" }}>
              <div className="addinvoice_clientdetail">
                {/* <Typography
                  className="first_of_type2"
                  style={{ fontWeight: "bold" }}
                > */}
                <Typography className="first_of_type2">Name:</Typography>
                {/* </Typography> */}
                <Typography className="second_of_type" variant="body2">
                  {/* {stateCust?.invoiceType === "Payable"
                    ? stateCust?.agentName
                    : stateCust?.clientUsername} */}
                  {stateCust?.invoiceType === "Payable"
                    ? stateCust?.agentName
                    : clientDetail1?.firstName +
                      " " +
                      clientDetail1?.middleName +
                      " " +
                      clientDetail1?.lastName}
                  {/* {clientDetail1?.firstName + " "+ clientDetail1?.lastName} */}
                </Typography>
              </div>
              <div className="addinvoice_clientdetail">
                <Typography className="first_of_type2">DOB:</Typography>
                {clientDetail1?.clientDetail.dob !== null ? (
                  <Typography className="second_of_type" variant="body2">
                    {moment(clientDetail1?.clientDetail.dob).format(
                      "DD-MM-YYYY"
                    )}
                  </Typography>
                ) : (
                  "-"
                )}
              </div>
              <div className="addinvoice_clientdetail">
                <Typography className="first_of_type2">Consultant:</Typography>
                <Typography className="second_of_type" variant="body2">
                  {clientDetail1?.consultantName}
                </Typography>
              </div>
              {/* <div className="addinvoice_clientdetail">
                  <Typography
                    className="first_of_type2"
                    style={{ fontWeight: "bold" }}
                  >
                    Service:
                  </Typography>
                  <Typography className="second_of_type" variant="body2">
                    {enquiryDetails?.courseName}
                  </Typography>
                </div> */}
              <div className="addinvoice_clientdetail">
                <Typography
                  className="first_of_type2"
                  style={{ fontWeight: "bold" }}
                >
                  Provider:
                </Typography>
                <Typography className="second_of_type" variant="body2">
                  {stateCust?.serviceProvider}
                </Typography>
              </div>
              <div className="addinvoice_clientdetail">
                <Typography
                  className="first_of_type2"
                  style={{ fontWeight: "bold" }}
                >
                  Student Id:
                </Typography>
                {stateCust?.externalid !== "undefined" ? (
                  <Typography className="second_of_type" variant="body2">
                    {stateCust?.externalId}
                  </Typography>
                ) : null}
              </div>
            </div>
          </div>
          <div className="viewinvoice_headerdatedetails">
            <div
              className="viewinvoice_row2"
              style={{ textAlign: "left", marginLeft: "15px" }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                {stateCust?.invoiceStatus === "Paid" ? (
                  <h5
                    variant="body1"
                    className="addinvoice_status2"
                    style={{ width: "fit-content", width: "105px" }}
                  >
                    {stateCust?.invoiceStatus}
                  </h5>
                ) : (
                  <h5
                    variant="body1"
                    className="addinvoice_status"
                    style={{ width: "fit-content", width: "105px" }}
                  >
                    {stateCust?.invoiceStatus}
                  </h5>
                )}
              </div>
              {stateCust?.incomeType === "Payable" ? (
                <>
                  <div className="row">
                    <Typography
                      className="col-md-5"
                      style={{ fontWeight: "bold" }}
                    >
                      Bill No
                    </Typography>
                    <div className="col-md-7">{params.invoice_id}</div>
                  </div>
                  <div className="row">
                    <Typography
                      className="col-md-5"
                      style={{ fontWeight: "bold" }}
                    >
                      Invoice No
                    </Typography>
                    <div className="col-md-7">{getParentInvoice}</div>
                  </div>
                </>
              ) : (
                <div className="row">
                  <Typography
                    className="col-md-5"
                    style={{ fontWeight: "bold" }}
                  >
                    Invoice No
                  </Typography>
                  <div className="col-md-7">{params.invoice_id}</div>
                </div>
              )}
              <div className="row">
                <Typography className="col-md-5" style={{ fontWeight: "bold" }}>
                  Invoice Date
                </Typography>
                <div className="col-md-7">
                  {moment(stateCust?.invoiceCreatedDate).format("DD-MM-YYYY")}
                </div>
              </div>
              <div>
                <div className="row">
                  <Typography
                    className="col-md-5"
                    style={{ fontWeight: "bold" }}
                  >
                    Invoice Due Date
                  </Typography>
                  <div className="col-md-7">
                    {stateCust?.invoiceDueDate != null
                      ? moment(stateCust?.invoiceDueDate).format("DD-MM-YYYY")
                      : null}
                  </div>
                </div>
              </div>
              <div className="row">
                <Typography className="col-md-5" style={{ fontWeight: "bold" }}>
                  Currency
                </Typography>
                <div className="col-md-7">{stateCust?.currency}</div>
              </div>
              {/* <div className="row">
                  <Typography
                    className="col-md-5"
                    style={{ fontWeight: "bold" }}
                  >
                    Created Date
                  </Typography>
                  <div className="col-md-7">
                    {stateCust?.invoiceCreatedDate?moment(stateCust?.invoiceCreatedDate).format("DD MMM YYYY hh.mm a"):null}
                  </div>
                </div> */}
              <div className="row">
                <Typography className="col-md-5" style={{ fontWeight: "bold" }}>
                  Created By
                </Typography>
                <div className="col-md-7">
                  {userDetails?.firstName != null &&
                  userDetails?.lastName != null
                    ? userDetails?.firstName + " " + userDetails?.lastName
                    : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row viewinvoice_row">
          <div className="col-md-4">
            <div className="row">
              <div className="col-md-4" style={{ fontWeight: "bold" }}>
                A/C Type
              </div>
              <div className="col-md-8">{stateCust?.incomeType}</div>
            </div>
            <div className="row">
              <div className="col-md-4" style={{ fontWeight: "bold" }}>
                Comission Type
              </div>
              <div className="col-md-8">{stateCust?.commisionType}</div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="row">
              <div className="col-md-4" style={{ fontWeight: "bold" }}>
                Invoice Sub Type
              </div>
              <div className="col-md-8">
                {stateCust?.invoiceSubType === "null"
                  ? ""
                  : stateCust?.invoiceSubType}
              </div>
            </div>
            <div className="row">
              <div className="col-md-4" style={{ fontWeight: "bold" }}>
                Intake
              </div>
              <div className="col-md-8">{stateCust?.intake}</div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="row">
              <div className="col-md-5" style={{ fontWeight: "bold" }}>
                Enquiry Name
              </div>
              <div className="col-md-7">
                {stateCust?.invoiceSubType
                  ? stateCust?.courseName
                  : stateCust?.serviceProvider
                  ? stateCust?.serviceProvider
                  : ""}

                {/* {stateCust?.invoiceSubType === "Admission" ||
                  stateCust?.invoiceSubType === "Admission"
                    ? stateCust?.courseName
                    : stateCust?.serviceProvider
                    ? stateCust?.serviceProvider
                    : ""} */}
              </div>
            </div>
            <div className="row">
              <div className="col-md-5" style={{ fontWeight: "bold" }}>
                Workflow
              </div>
              <div className="col-md-7">{stateCust?.workflowType}</div>
            </div>
          </div>
        </div>
        {stateCust?.invoiceType === "Payable" ? (
          <div className="invoice_adddetails quotation_table">
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead style={{ backgroundColor: "cornflowerblue" }}>
                  <TableRow>
                    <TableCell align="center">SN</TableCell>{" "}
                    <TableCell align="right" style={{ padding: "0" }}>
                      Commission Amount
                    </TableCell>
                    <TableCell align="right">Tax(%)</TableCell>
                    <TableCell align="right">Tax Amount</TableCell>
                    <TableCell align="right">Total Amount</TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {newline?.map((p, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row" align="center">
                        {index + 1}
                      </TableCell>
                      <TableCell align="right" style={{ padding: "0" }}>
                        {stateCust?.invoiceDetails[index]?.commissionAmount
                          ? stateCust?.invoiceDetails[
                              index
                            ]?.commissionAmount.toFixed(2)
                          : Number(0).toFixed(2)}
                      </TableCell>
                      <TableCell align="right">
                        {stateCust?.invoiceDetails[index]?.taxPercentage
                          ? stateCust?.invoiceDetails[
                              index
                            ]?.taxPercentage.toFixed(2)
                          : Number(0).toFixed(2)}
                      </TableCell>
                      <TableCell align="right">
                        {stateCust?.invoiceDetails[index]?.taxAmount
                          ? stateCust?.invoiceDetails[index]?.taxAmount.toFixed(
                              2
                            )
                          : Number(0).toFixed(2)}
                      </TableCell>
                      <TableCell align="right">
                        {/* {stateCust?.invoiceDetails[index]?.amount
                          ? stateCust?.invoiceDetails[index]?.amount.toFixed(2)
                          : Number(0).toFixed(2)} */}
                        {stateCust?.totalDue.toFixed(2) || 0.0}
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{ cursor: "pointer" }}
                      ></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ) : (
          <div className="invoice_adddetails quotation_table">
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead style={{ backgroundColor: "cornflowerblue" }}>
                  <TableRow>
                    <TableCell align="center">SN</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell align="right">Amount</TableCell>
                    {commision !== "General Invoice" ? (
                      <>
                        <TableCell align="right">Commission(%)</TableCell>
                        <TableCell align="right" style={{ padding: "0" }}>
                          Commission Amount
                        </TableCell>
                      </>
                    ) : null}
                    <TableCell align="right">Tax(%)</TableCell>

                    <TableCell align="right">Tax Amount</TableCell>
                    {commision === "General Invoice" ? (
                      <TableCell align="right">Total Amount</TableCell>
                    ) : null}
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {newline?.map((p, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row" align="center">
                        {index + 1}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {stateCust?.invoiceDetails[index]?.description}
                      </TableCell>
                      <TableCell align="right">
                        {stateCust?.invoiceDetails[index]?.amount.toFixed(2)}
                      </TableCell>
                      {commision !== "General Invoice" ? (
                        <>
                          <TableCell align="right">
                            {stateCust?.invoiceDetails[
                              index
                            ]?.commissionPercentage.toFixed(2)}
                          </TableCell>
                          <TableCell align="right" style={{ padding: "0" }}>
                            {stateCust?.invoiceDetails[
                              index
                            ]?.commissionAmount.toFixed(2)}
                          </TableCell>
                        </>
                      ) : null}
                      <TableCell align="right">
                        {stateCust?.invoiceDetails[
                          index
                        ]?.taxPercentage.toFixed(2)}
                      </TableCell>
                      <TableCell align="right">
                        {stateCust?.invoiceDetails[index]?.taxAmount.toFixed(2)}
                      </TableCell>
                      {commision === "General Invoice" ? (
                        <TableCell align="right">
                          {stateCust?.invoiceDetails[
                            index
                          ]?.totalAmount.toFixed(2)}
                        </TableCell>
                      ) : null}
                      <TableCell
                        align="right"
                        style={{ cursor: "pointer" }}
                      ></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}

        <div className="addinvoice_revenuedetails">
          <div>
            {stateCust?.invoiceType === "Payable" ||
            commision === "General Invoice" ? null : (
              <div style={{ display: "flex" }}>
                <Typography style={{ marginRight: "10px" }}>
                  Discount:
                </Typography>
                <Typography>
                  {stateCust?.discountAmount || 0} (
                  {stateCust?.discountPercentageType === "Percentage"
                    ? `${stateCust?.discountPercentage}%`
                    : stateCust?.discountPercentageType}
                  )
                </Typography>
              </div>
            )}
            <div className="addinvoice_revenuedetailsleft">
              <div
                className="invoice_detailsbelowleftbelow"
                style={{ width: "318px", height: "127px" }}
              >
                <Typography className="font-weight-bold">Total Paid</Typography>
                <Typography
                  className="netrevenue"
                  variant="h3"
                  style={{ marginRight: "10px", marginTop: "15px" }}
                >
                  {stateCust?.totalPaid
                    ? stateCust?.totalPaid.toFixed(2)
                    : Number(0).toFixed(2)}
                </Typography>
              </div>
              {stateCust?.invoiceType === "Payable" ? null : (
                <div
                  className="invoice_detailsbelowleftbelow"
                  style={{ width: "318px", height: "127px" }}
                >
                  <Typography className="font-weight-bold">
                    Net Income
                  </Typography>
                  <Typography
                    className="netrevenue"
                    variant="h3"
                    color="primary"
                    style={{ marginRight: "10px", marginTop: "15px" }}
                  >
                    {Number(stateCust?.netIncome).toFixed(2)}
                  </Typography>
                </div>
              )}
            </div>
            <Typography
              className="first_of_type2"
              style={{ fontWeight: "bold" }}
            >
              Remark:
            </Typography>
            <div style={{ width: "97%" }}>
              <textarea
                className="form-control"
                value={stateCust?.comment}
                name="comment"
                rows="5"
                style={{ backgroundColor: "#f5f5f9" }}
                readonly
              />
            </div>
          </div>
          <div className="addinvoice_revenuedetailsright">
            {stateCust?.invoiceType === "Payable" ? null : (
              <>
                {commision === "commissionInvoiceGross" ? null : (
                  <div className="addinvoice_revenuedetailright viewinvoice_row">
                    <Typography
                      variant="body2"
                      className="addinvoice_revenuedetailrightfirst font-weight-bold"
                    >
                      Total Amount:
                    </Typography>
                    <div
                      className="addinvoice_detailinput"
                      style={{ justifyContent: "flex-end" }}
                    >
                      {stateCust?.netAmount || 0.0}
                    </div>
                  </div>
                )}
              </>
            )}
            {commision !== "General Invoice" ? (
              <div className="addinvoice_revenuedetailright viewinvoice_row">
                <Typography
                  variant="body2"
                  className="addinvoice_revenuedetailrightfirst font-weight-bold"
                >
                  Commission:
                </Typography>
                <div
                  className="addinvoice_detailinput"
                  style={{ justifyContent: "flex-end" }}
                >
                  {stateCust?.totalCommission.toFixed(2) || 0.0}
                </div>
              </div>
            ) : null}
            <div className="addinvoice_revenuedetailright  viewinvoice_row">
              <Typography
                variant="body2"
                className="addinvoice_revenuedetailrightfirst font-weight-bold"
              >
                Tax:
              </Typography>
              <div
                className="addinvoice_detailinput"
                style={{ justifyContent: "flex-end" }}
              >
                {stateCust?.totalTax.toFixed(2) || 0.0}
              </div>
            </div>

            {stateCust?.invoiceType === "Payable" ? null : (
              <>
                {commision === "commissionInvoiceNet" ? (
                  <div className="addinvoice_revenuedetailright viewinvoice_row">
                    <Typography
                      variant="body2"
                      className="addinvoice_revenuedetailrightfirst font-weight-bold"
                    >
                      Discount:
                    </Typography>
                    <div
                      className="addinvoice_detailinput"
                      style={{ justifyContent: "flex-end" }}
                    >
                      {stateCust?.discountAmount.toFixed(2) || 0.0}
                    </div>
                  </div>
                ) : null}
              </>
            )}
            {stateCust?.invoiceType === "Payable" ? null : (
              <div className="addinvoice_revenuedetailright">
                <Typography
                  variant="body2"
                  className="addinvoice_revenuedetailrightfirst font-weight-bold"
                >
                  {stateCust?.commisionType === "commissionInvoiceGross"
                    ? "Total Amount"
                    : stateCust?.commisionType === "commissionInvoiceNet"
                    ? "Total amount paid to provider"
                    : "Total amount (Inc Tax)"}
                  :
                </Typography>
                <div
                  className="addinvoice_detailinput"
                  style={{ justifyContent: "flex-end" }}
                >
                  {stateCust?.totalAmountIncludingTax.toFixed(2) || 0.0}
                </div>
                {/* <Button variant="outlined">None</Button> */}
              </div>
            )}
            <div className="addinvoice_revenuedetailright viewinvoice_row">
              <Typography
                variant="body2"
                className="addinvoice_revenuedetailrightfirst font-weight-bold"
              >
                Total Due:
              </Typography>

              <div
                className="addinvoice_detailinput"
                style={{ justifyContent: "flex-end" }}
              >
                {stateCust?.totalDue.toFixed(2) || 0.0}
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div
          style={{ display: "flex", padding: "5px" }}
          className="quotation_table"
        >
          <div style={{ flex: "1", margin: "5px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginRight: "20px",
              }}
            >
              <Typography color="primary" variant="body1">
                Payment Received
              </Typography>
            </div>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead style={{ backgroundColor: "cornflowerblue" }}>
                  <TableRow>
                    <TableCell align="center">SN</TableCell>
                    <TableCell>Amount Paid</TableCell>
                    <TableCell align="left">Payment Method</TableCell>
                    <TableCell align="left">Date</TableCell>
                    <TableCell align="left">Notes</TableCell>
                    <TableCell align="right">Attachment</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {stateCust2?.map((p, index) => (
                    <TableRow key={index}>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell>{p.amount}</TableCell>
                      <TableCell align="left">{p.paymentMode}</TableCell>
                      <TableCell align="left">
                        {p.date === "Invalid date"
                          ? moment(datenow).format("DD-MM-YYYY")
                          : moment(p.date).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell align="left">{p.notes}</TableCell>
                      <TableCell align="right">
                        <a
                          onClick={() => {
                            downloadAll(p);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          {p.attachmentName}
                        </a>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
        <hr />
        <div>
          {stateCust?.invoiceType === "Payable" ? null : (
            <>
              <Typography
                color="primary"
                variant="body1"
                style={{ textAlign: "left" }}
              >
                Income Sharing
              </Typography>
              <div className="invoice_adddetails quotation_table">
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead style={{ backgroundColor: "cornflowerblue" }}>
                      <TableRow>
                        <TableCell align="center">SN</TableCell>
                        <TableCell>Agent</TableCell>
                        <TableCell>Bill No</TableCell>
                        <TableCell>Sharing Type</TableCell>
                        {/* <TableCell align="right">Amount</TableCell>
                        <TableCell align="right">Commission(%)</TableCell> */}
                        <TableCell align="right" style={{ padding: "0" }}>
                          Commission Amount
                        </TableCell>
                        <TableCell align="right">Tax(%)</TableCell>

                        <TableCell align="right">Tax Amount</TableCell>
                        <TableCell align="right">Total Amount</TableCell>
                        <TableCell align="right">Note</TableCell>
                        <TableCell align="right"></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {stateCust?.incomeSharing?.map((p, index) => (
                        <TableRow key={index}>
                          <TableCell component="th" scope="row" align="center">
                            {index + 1}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {p.incomeSharingName}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {p.payableInvoiceId}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {p.incomeSharingType}
                          </TableCell>
                          {/* {commision === "General Invoice" ? (
                            <TableCell align="right">
                              {stateCust?.netAmount.toFixed(2)}
                            </TableCell>
                          ) : (
                            <TableCell align="right">
                              {stateCust?.totalCommission -
                                stateCust?.discountAmount.toFixed(2)}
                            </TableCell>
                          )}
                          <TableCell align="right">
                            {p.incomeSharingCommissionPercent}
                          </TableCell> */}
                          <TableCell align="right" style={{ padding: "0" }}>
                            {Number(p.sharingCommissionAmount).toFixed(2)}
                          </TableCell>
                          <TableCell align="right">
                            {p.sharingTaxPercentage.toFixed(2)}
                          </TableCell>
                          <TableCell align="right">
                            {Number(p.sharingTaxAmount).toFixed(2)}
                          </TableCell>
                          <TableCell align="right">
                            {Number(p.incomeSharingAmount).toFixed(2)}
                          </TableCell>
                          <TableCell align="right">{p.notes}</TableCell>
                          <TableCell
                            align="right"
                            style={{ cursor: "pointer" }}
                          ></TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </>
          )}

          <div className="d-flex justify-content-end my-2">
            {/* {stateCust?.invoiceType === "Payable" ? (
              <>
                {permissionData?.includes(493) ? (
                  <Button className="btn listing_addbutton">
                    Change status to paid
                  </Button>
                ) : null}
              </>
            ) : (
              <>
                {permissionData?.includes(215) ? (
                  <Button
                    className="btn listing_addbutton"
                    onClick={editinvoice}
                  >
                    Edit Invoice
                  </Button>
                ) : null}
              </>
            )}
            {permissionData?.includes(216) ? (
              <Button
                className="btn listing_addbutton ml-1"
                onClick={refundinvoice}
              >
                Refund Invoice
              </Button>
            ) : null} */}

            {stateCust?.invoiceType === "Payable" &&
            stateCust?.invoiceStatus === "Paid" ? (
              <>
                {/* {permissionData?.includes(214) ? (
                  <Button
                    className="btn listing_addbutton ml-1"
                    onClick={() => setpaidisopen(true)}
                  >
                    Change status to paid
                  </Button>
                ) : null} */}
              </>
            ) : (
              <>
                {stateCust?.invoiceType === "Payable" ? (
                  <>
                    {check1pointpermission(
                      505,
                      permissionData,
                      rolepermission
                    ) ? (
                      <Button
                        className="btn listing_addbutton ml-1"
                        onClick={() => setpaidisopen(true)}
                      >
                        Change status to paid
                      </Button>
                    ) : null}
                  </>
                ) : (
                  <>
                    {check1pointpermission(
                      506,
                      permissionData,
                      rolepermission
                    ) ? (
                      <Button
                        className="btn listing_addbutton ml-1"
                        onClick={editinvoice}
                      >
                        Edit Invoice
                      </Button>
                    ) : null}
                  </>
                )}
              </>
            )}
            {stateCust?.invoiceStatus === "Cancelled" ? (
              <>
                {Number(isw_user_role) === 1 ||
                Number(isw_user_role) === 5 ||
                Number(isw_user_role) === 6 ? (
                  <>
                    {check1pointpermission(
                      507,
                      permissionData,
                      rolepermission
                    ) ? (
                      <button
                        className="btn listing_addbutton ml-1"
                        onClick={() => setrollbackisopen(true)}
                      >
                        Refund RollBack
                      </button>
                    ) : null}
                  </>
                ) : null}
              </>
            ) : (
              <>
                {stateCust?.invoiceStatus === "Paid" ? (
                  <>
                    {/* {permissionData?.includes(216) ? (
                <Button
                  className="btn listing_addbutton ml-1"
                  onClick={() => setisopen(true)}
                >
                  Refund Invoice
                </Button>
              ) : null} */}
                  </>
                ) : (
                  <>
                    {check1pointpermission(
                      507,
                      permissionData,
                      rolepermission
                    ) ? (
                      <Button
                        className="btn listing_addbutton ml-1"
                        onClick={() => setisopen(true)}
                      >
                        Refund Invoice
                      </Button>
                    ) : null}
                  </>
                )}
              </>
            )}
            <Button
              className="btn listing_addbutton ml-1"
              onClick={() => printIframe("receipt")}
            >
              Preview & Print
            </Button>

            {userEmail ? (
              <Button className="btn listing_addbutton ml-1" onClick={goBack}>
                Invoice List
              </Button>
            ) : null}
          </div>
        </div>
        <hr />
      </div>
      {/* </form> */}
      {/* </div> */}
    </>
  );
};

export default ViewInvoice;
