import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import userReducer from "../features/userSlice";
import adminReducer from "../features/adminSlice";
import collegeReducer from "../features/collegeSlice";
import courseReducer from "../features/courseSlice";
import clientReducer from "../features/clientSlice";
import dashboardReducer from "../features/dashboardSlice";
import invoiceReducer from "../features/invoiceSlice";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import logger from 'redux-logger';

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user"],
};
const reducers = combineReducers({
  user: userReducer,
  admin: adminReducer,
  college: collegeReducer,
  course: courseReducer,
  client: clientReducer,
  dashboard: dashboardReducer,
  invoice: invoiceReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,

  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }).concat(logger),
});
export const persistor = persistStore(store);
