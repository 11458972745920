import { ResponsiveBar } from "@nivo/bar";

const ClientCountChart = ({ data }) => {

    // Function to abbreviate institution names
    const abbreviateName = (name) => {
      return name.split(/\s|-/).map(word => word.substring(0, 3).toUpperCase()).join('-');
    };

  return (
    <ResponsiveBar
      data={data}
      keys={["clientCount"]}
      indexBy="officeName"
      margin={{ top: 50, right: 30, bottom: 50, left: 60 }}
      padding={0.3}
      valueFormat={{ format: ".2s" }}
      colors={{ scheme: "accent" }} // Set color scheme to use custom color
      colorBy="index"
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: -45,
        legend: null,
        legendPosition: "middle",
        legendOffset: 32,
        format: abbreviateName,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "Client Count",
        legendPosition: "middle",
        legendOffset: -40,
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
  
    />
  );
};

export default ClientCountChart;
