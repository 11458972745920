import React, { useEffect, useState } from "react";
import { CardText, Col, Row, Button, Table } from "reactstrap";
import "./../css/Responsive.css";
import { ToastContainer, toast } from "react-toastify";
import Pagination from "@mui/material/Pagination";
import { Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import base_url from "../../api/bootapi";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, setdashboardcheck, logout } from "../features/userSlice";
import { Link, useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { useForm } from "react-hook-form";
import { selectdashboard } from "../features/dashboardSlice";
import { customStyles, useStyles } from "../../Utilities/CSSUtilities";
import callsaveauditapi from "../../services/auditservice";
import sort from "./../images/sort.svg";
import InboxReplyModal from "./../Modal/Masters/InboxReplyModal";
import InboxViewModal from "./../Modal/Masters/InboxViewModal";
import {
  check4pointpermission,
  check1pointpermission,
} from "./../Permission/permission";

const Inbox = () => {
  const classes = useStyles();
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  const isw_user_role = localStorage.getItem("isw_user_role");
  const isw_user_email = localStorage.getItem("isw_user_email");
  const ipaddress = localStorage.getItem("AdminIpAddress");
  const isw_user_name = localStorage.getItem("isw_user_name");
  const isw_adminId = localStorage.getItem("isw_adminId");

  const [pageno, setpageno] = useState(0);
  const [getpageno, setgetpageno] = useState();
  const [rows, setrows] = React.useState(50);
  const [inbox, setInbox] = useState();
  const dispatch = useDispatch();
  const [searchinput, setsearchinput] = useState();
  const [sortState, setsortState] = useState("date");
  const [direction, setdirection] = useState("DESC");
  const [display1, setdisplay] = useState("inline");
  const [clickedBtn, setclickedBtn] = useState();
  const [setComment, setsetComment] = useState();
  const [setSubject, setsetSubject] = useState();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [attachmentState, setattachmentState] = useState();
  const [totalUser, settotalUser] = useState();
  const [countnumber, setcountnumber] = useState();
  const [numberofElements, setnumberofElements] = useState();
  const dashboardvalue = useSelector(selectdashboard);
  const [filesizemore, setfilesizemore] = useState(false);
  const [backdropopen, setbackdropopen] = useState(false);
  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);

  const handlepage = (event, value) => {
    setpageno(value - 1);
    getInbox(value - 1);
  };

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        navigate("/");
      }
    });
    getPermissions();
  }, [rows]);

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);

    if (check1pointpermission(102, permission_obj, resource_obj)) {
      getInbox(pageno);
      dispatch(setdashboardcheck(3));
    } else {
      //console.log('No access to the page');
      navigate("/errorPage");
    }
  };
  // const check4pointpermission = (
  //   a,
  //   b,
  //   c,
  //   d,
  //   permissionData,
  //   rolepermission
  // ) => {
  //   for (let i = a; i <= b; i++) {
  //     if (permissionData.includes(i)) {
  //       return true;
  //     }
  //   }
  //   if (checkrolepermission(c, d, rolepermission)) {
  //     return true;
  //   }
  //   return false;
  // };
  const checkrolepermission = (x, y, rolepermission) => {
    for (let i = x; i <= y; i++) {
      if (rolepermission.includes(i)) {
        return true;
      }
    }
    return false;
  };
  const [searchdata, setsearch] = useState();

  const getInbox = (value1) => {
    setbackdropopen(true);
    if (isw_user_role === "1" || isw_user_role === "6") {
      axios
        .get(`${base_url.api4}/getAllByMailType`, {
          headers: {
            Authorization: jwtToken,
            columnName: sortState,
            mailType: "sent",
            page: Number(value1),
            size: Number(rows),
            direction: direction,
            fromEmailId: isw_user_email,
          },
        })
        .then((res) => {
          setgetpageno(res.data.data.totalPages);
          settotalUser(res.data.data.totalElements);
          setcountnumber(res.data.data.number);
          setnumberofElements(res.data.data.numberOfElements);
          setInbox(res.data.data.content);
          setsearch(false);
          try {
            document.getElementById("myForm").reset();
          } catch (error) {
            //console.log(error);
          }
          setbackdropopen(false);
        })
        .catch((error) => {
          setbackdropopen(false);
          toast.error(JSON.stringify(error.response.data.message));
          handle403error(error);
        });
    } else {
      axios
        .get(`${base_url.api4}/getByMailTypeAndFromEmailId`, {
          headers: {
            Authorization: jwtToken,
            fromEmailId: isw_user_email,
            columnName: sortState,
            mailType: "sent",
            direction: direction,
            page: Number(value1),
            size: Number(rows),
          },
        })
        .then((res) => {
          setgetpageno(res.data.data.totalPages);
          settotalUser(res.data.data.totalElements);
          setInbox(res.data.data.content);
          setbackdropopen(false);
        })
        .catch((error) => {
          setbackdropopen(false);
          toast.error(JSON.stringify(error.response.data.message));
          handle403error(error);
        });
    }
  };
  const handleRows = (event) => {
    setrows(event.target.value);
  };
  const submitrows = (e) => {
    setbackdropopen(true);
    if (searchinput != null) {
      axios
        .get(`${base_url.api4}/getByMailTypeAndFromEmailId`, {
          headers: {
            Authorization: jwtToken,
            fromEmailId: isw_user_email,
            columnName: sortState,
            mailType: "sent",
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          },
        })
        .then((response) => {
          setgetpageno(response.data.data.totalPages);
          setInbox(response.data.data.content);
          setbackdropopen(false);
        })
        .catch((error) => {
          setbackdropopen(false);
          toast.error(JSON.stringify(error.response.data.message));
          handle403error(error);
        });
    } else {
      getInbox(0);
    }
  };

  const handlesearch = (e, value) => {
    setbackdropopen(true);
    // setdisplay("display");
    setpageno(value - 1);
    //console.log(value);
    if (isw_user_role === "1" || isw_user_role === "6") {
      axios
        .get(`${base_url.api4}/searchEmail`, {
          headers: {
            Authorization: jwtToken,
            searchQuery: searchinput,
            columnName: sortState,
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          },
        })
        .then((res) => {
          //console.log(res);
          setgetpageno(res.data.data.totalPages);
          setInbox(res.data.data.content);
          setbackdropopen(false);
        })
        .catch((error) => {
          setbackdropopen(false);
          toast.error(JSON.stringify(error.response.data.message));
          handle403error(error);
        });
    } else {
      axios
        .get(`${base_url.api4}/getByMailTypeAndFromEmailId`, {
          headers: {
            Authorization: jwtToken,
            fromEmailId: isw_user_email,
            columnName: sortState,
            mailType: "sent",
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          },
        })
        .then((res) => {
          setgetpageno(res.data.data.totalPages);
          setInbox(res.data.data.content);
          setbackdropopen(false);
        })
        .catch((error) => {
          setbackdropopen(false);
          handle403error(error);
          toast.error(JSON.stringify(error.response.data.message));
        });
    }
  };
  const [client_admin_users, setClientDetails] = useState(null);
  const submit = (e) => {
    setbackdropopen(true);
    let srchdata = e.target[0].value;
    setsearchinput(srchdata);
    setpageno(0);
    if (isw_user_role === "1" || isw_user_role === "6") {
      axios
        .get(`${base_url.api4}/searchEmailByMailType`, {
          headers: {
            Authorization: jwtToken,
            searchQuery: srchdata,
            columnName: sortState,
            direction: direction,
            mailType: "sent",
            page: Number(0),
            size: Number(rows),
          },
        })
        .then((res) => {
          setgetpageno(res.data.data.totalPages);
          setInbox(res.data.data.content);
          settotalUser(res.data.data.totalElements);
          setcountnumber(res.data.data.number);
          setnumberofElements(res.data.data.numberOfElements);
          setsearch(true);
          setbackdropopen(false);
        })
        .catch((error) => {
          setbackdropopen(false);
          handle403error(error);
          toast.error(JSON.stringify(error.response.data.message));
        });
    } else {
      axios
        .get(`${base_url.api4}/getByMailTypeAndFromEmailId`, {
          headers: {
            Authorization: jwtToken,
            fromEmailId: isw_user_email,
            page: Number(pageno),
            size: Number(rows),
            mailType: "sent",
            columnName: sortState,
            direction: direction,
          },
        })
        .then((res) => {
          setgetpageno(res.data.data.totalPages);
          setInbox(res.data.data.content);
          settotalUser(res.data.data.totalElements);
          setcountnumber(res.data.data.number);
          setnumberofElements(res.data.data.numberOfElements);
          setbackdropopen(false);
        })
        .catch((error) => {
          setbackdropopen(false);
          handle403error(error);
          toast.error(JSON.stringify(error.response.data.message));
        });
    }
  };

  const sortClient = (e) => {
    setbackdropopen(true);
    setsortState(e.target.id);
    if (direction == "DESC") {
      setdirection("ASC");
    } else if (direction == "ASC") {
      setdirection("DESC");
    }
    axios
      .get(`${base_url.api4}/getByMailTypeAndFromEmailId`, {
        headers: {
          Authorization: jwtToken,
          fromEmailId: isw_user_email,
          columnName: sortState,
          mailType: "sent",
          direction: direction,
          page: Number(pageno),
          size: Number(rows),
        },
      })
      .then((res) => {
        setgetpageno(res.data.data.totalPages);
        setInbox(res.data.data.content);
        setbackdropopen(false);
      })
      .catch((error) => {
        handle403error(error);
      });
  };

  const [selectemail, setselectedmail] = useState({});
  const [modalIsOpen3, setIsOpen3] = React.useState(false);
  const openView = (p) => {
    //console.log(p);
    setselectedmail(p);
    setIsOpen3(true);
  };

  const [modalIsOpen4, setIsOpen4] = React.useState(false);
  const openReply = (p) => {
    //console.log(p);
    setselectedmail(p);
    setIsOpen4(true);
  };

  const closeModal = () => {
    setIsOpen3(false);
    setIsOpen4(false);
  };

  const submitReply = (data) => {
    setbackdropopen(true);
    let data2 = {
      description: setComment,
      fromEmailId: isw_user_email,
      mailType: "sent",
      subject: setSubject,
      status: Number(1),
      toEmailId: selectemail.toEmailId,
    };
    //console.log(data2);
    let formData = new FormData();
    let fileToUpload = data.picture.item(0);
    if (fileToUpload != null) {
      if (filesizemore === true) {
        setbackdropopen(false);
        return false;
      } else {
        formData.append("emailAttachmentFile", fileToUpload, fileToUpload.name);
        //console.log("Formdata", formData);
        setattachmentState(true);
        axios
          .post(`${base_url.api4}/uploadEmailAttachment`, formData, {
            headers: {
              Authorization: jwtToken,
              fromEmailId: isw_user_email,
              toEmailId: selectemail.toEmailId,
              mailType: "sent",
              subject: setSubject,
              status: Number(1),
              description: setComment,
            },
          })
          .then((response) => {
            setbackdropopen(false);
            toast.success(response.data.message);
            let logs =
              isw_user_name +
              " Uploaded Attachment & Sent Reply to " +
              selectemail.toEmailId;
            callsaveauditapi(logs, dispatch, navigate);

            setIsOpen4(false);
            getInbox(pageno);
          })
          .catch((error) => {
            setbackdropopen(false);
            toast.error(JSON.stringify(error.response.data.message));
            handle403error(error);
          });
      }
    } else {
      //console.log(data);
      setattachmentState(false);
      axios
        .post(`${base_url.api4}/createEmail`, data2)
        .then((response) => {
          setbackdropopen(false);
          toast.success(response.data.message);
          let logs = isw_user_name + " Send Reply to " + selectemail.toEmailId;
          callsaveauditapi(logs, dispatch, navigate);
          setIsOpen4(false);
          getInbox(pageno);
        })
        .catch((error) => {
          setbackdropopen(false);
          toast.error(JSON.stringify(error.response.data.message));
          handle403error(error);
        });
    }
  };

  const chkfilesize = (e) => {
    if (e.target.files[0].size / 1048576 > 4) {
      setfilesizemore(true);
    } else {
      setfilesizemore(false);
    }
  };

  const downloadAll = (emailAttachmentPath, toEmailId, fromEmailId) => {
    setbackdropopen(true);
    let file_name =
      emailAttachmentPath.split("amazonaws.com/")[
        emailAttachmentPath.split("amazonaws.com/").length - 1
      ];
    const header = {
      method: "GET",
      headers: {
        Authorization: jwtToken,
        toEmailId: toEmailId,
        fromEmailId: fromEmailId,
        fileName: file_name,
      },
    };

    var url = `${base_url.api4}/downloadEmailAttachment`;
    fetch(url, header)
      .then((response) => readAllChunks(response))
      .then(async function (response) {
        //console.log(response);
        const downloadFileType = localStorage.getItem(
          "email_attachment_file_content_type"
        );
        //console.log(downloadFileType);
        var blob = new Blob(response, { type: downloadFileType });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = file_name;
        link.click();
        setbackdropopen(false);
      })
      .catch((error) => {
        setbackdropopen(false);
        //console.log(error);
        handle403error(error);
      });
  };

  const readAllChunks = (readableStream) => {
    localStorage.setItem(
      "email_attachment_file_content_type",
      readableStream.headers.get("content-type")
    );
    const reader = readableStream.body.getReader();
    const chunks = [];

    function pump() {
      return reader
        .read()
        .then(({ value, done }) => {
          if (done) {
            return chunks;
          }
          chunks.push(value);
          return pump();
        })
        .catch((error) => {
          handle403error(error);
        });
    }
    return pump();
  };

  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            navigate("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };

  let scrolling = false;
  const scrolldiv = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow .table-responsive");
    table.scrollTo(scroller.scrollLeft, 0);
  };

  const scrolltable = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow  .table-responsive");
    scroller.scrollTo(table.scrollLeft, 0);
  };
  return (
    <div>
      <ToastContainer />
      <Backdrop className={classes.backdrop} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* <div className="commoncomponent_cardcss"> */}
      <div
        class="page-head d-flex justify-content-between p-4"
        style={{ marginTop: "4.3rem", marginBottom: "-20px" }}
      >
        <ul class="breadcrums">
          <h3 class="page-title bold">Mail Sent</h3>
          <Link to="/" class="b-link">
            Home
          </Link>
          <span className="s_span ">&raquo;</span>
          <a class="b-link">Mail</a>
          <span className="s_span ">&raquo;</span>
          <Link to="/sent-mails" class="b-link active">
            Sent
          </Link>
        </ul>
        {check1pointpermission(101, permissionData, rolepermission) ? (
          <Link to="/received-mails" class="sent-button">
            Mail Received
          </Link>
        ) : null}
      </div>
      <div>
        <Row>
          {/* <Col md={6}>
              <CardText
                className={classes.headtext}
                style={{ marginTop: "-10px" }}
              >
                <strong>Sent</strong>
              </CardText>
            </Col> */}
        </Row>
        <div
          className="newlisting_headertabdesign"
          style={{
            padding: "1.5rem",
            borderRight: "1.5rem solid #e9ebf2",
            borderLeft: "1.5rem solid #e9ebf2",
            borderTop: "1.5rem solid #e9ebf2",
            marginBottom: "0",
          }}
        >
          <Row>
            <Col md={3} style={{ marginTop: "10px" }}>
              <form
                className="master"
                onSubmit={(e) => {
                  e.preventDefault();
                  submitrows(e);
                }}
              >
                <div style={{ marginTop: "-10px", width: "fit-content" }}>
                  {/* <label className="area_rowslable">Limit</label>
                  <br />
                  <FormControl className={classes.formControl}>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={rows}
                      onChange={handleRows}
                      style={{
                        border: "2px solid #ced4da ",
                        paddingTop: "5px",
                        backgroundColor: "#f5f5f9",
                      }}
                    >
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={20}>20</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                      <MenuItem value={100}>100</MenuItem>
                    </Select>
                  </FormControl> */}{" "}
                  <form className="master">
                    <div style={{ marginTop: "-10px", marginRight: "24px" }}>
                      <label
                        className="area_rowslable"
                        style={{ marginBottom: "0.5rem" }}
                      >
                        Limit
                      </label>
                      <br />
                      <FormControl
                        variant="standard"
                        sx={{
                          m: 1,
                          minWidth: 180,
                          marginTop: 0,
                          maxHeight: 180,
                        }}
                      >
                        <select
                          className={classes.s_mail_limit}
                          labelId="simple-select-label"
                          id="simple-select"
                          value={rows}
                          onChange={handleRows}
                        >
                          <option value={10}>10</option>
                          <option value={20}>20</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                        </select>
                      </FormControl>
                    </div>
                  </form>
                </div>
              </form>
            </Col>
            <Col md={5}>{null}</Col>
            <Col md={4}>
              <div className="clearsearchclass" style={{ marginTop: "30px" }}>
                <form
                  id="myForm"
                  className="d-flex"
                  onSubmit={(e) => {
                    e.preventDefault();
                    submit(e);
                  }}
                >
                  <input
                    className="form-control"
                    placeholder="Search.."
                    name="search"
                    style={{ marginLeft: "0px", width: "100%" }}
                  />
                  <button className="btn listpagesearch_button" type="submit">
                    <i
                      class="fa fa-search"
                      type="submit"
                      style={{ color: "#6d7290" }}
                    ></i>
                  </button>
                </form>
              </div>
              {searchdata ? (
                <a
                  className={classes.clrsrch}
                  onClick={() => {
                    setpageno(0);
                    getInbox(0);
                  }}
                >
                  Clear Search
                </a>
              ) : null}
            </Col>
          </Row>
        </div>
        <div
          className="newlisting_headertabdesign"
          style={{
            padding: "1.5rem",
            border: "1.5rem solid #e9ebf2",
            marginTop: "0",
            marginBottom: "0",
          }}
        >
          <Row className="s_pagi">
            <Col md={6}>
              {inbox?.length > 0 ? (
                <p className="s_para">
                  {countnumber * rows + 1} to{" "}
                  {countnumber * rows + numberofElements} of {totalUser} records
                </p>
              ) : (
                <p className="s_para">No Record Found</p>
              )}
            </Col>
          </Row>
          <div className="above-scroller" onScroll={scrolldiv}>
            <div className="scroller"></div>
          </div>
          <Table
            className="workload card-table table-borderless"
            responsive
            striped
          >
            {/* <table
            id="example"
            className="table workload card-table table-borderless table-responsive"
            style={{ width: "100%" }}
          > */}
            <thead className="s_sticky">
              <tr>
                <th style={{ width: "50px", textAlign: "center" }}>SN</th>
                {/* <th>Sender</th> */}
                <th style={{ minWidth: "200px" }}>
                  RECEIVER {/* <UnfoldMoreIcon */}
                  <img
                    src={sort}
                    className="sorting"
                    id="toEmailId"
                    onClick={sortClient}
                    style={{
                      cursor: "pointer",
                      display: `${display1}`,
                      height: "10px",
                    }}
                  />
                </th>
                <th style={{ minWidth: "200px" }}>
                  SUBJECT {/* <UnfoldMoreIcon */}
                  <img
                    src={sort}
                    className="sorting"
                    id="subject"
                    onClick={sortClient}
                    style={{
                      cursor: "pointer",
                      display: `${display1}`,
                      height: "10px",
                    }}
                  />
                </th>
                <th style={{ minWidth: "200px" }}>
                  SENT TIME {/* <UnfoldMoreIcon */}
                  <img
                    src={sort}
                    className="sorting"
                    id="date"
                    onClick={sortClient}
                    style={{
                      cursor: "pointer",
                      display: `${display1}`,
                      height: "10px",
                    }}
                  />
                </th>
                <th style={{ minWidth: "200pxs" }}>
                  ATTACHMENTS {/* <UnfoldMoreIcon */}
                  <img
                    src={sort}
                    className="sorting"
                    id="emailAttachmentPath"
                    onClick={sortClient}
                    style={{
                      cursor: "pointer",
                      display: `${display1}`,
                      height: "10px",
                    }}
                  />
                </th>
                <th>ACTION</th>
              </tr>
            </thead>
            {inbox !== null ? (
              <tbody>
                {inbox?.map((p, index) => (
                  <tr key={index}>
                    <td style={{ textAlign: "center" }}>{index + 1}</td>
                    {/* <th>{p.fromEmailId}</th> */}
                    <td>{p.toEmailId}</td>
                    <td>{p.subject}</td>
                    <td>
                      {p.date
                        ? moment(p.date.split(".")[0]).format(
                            "DD-MMM-YYYY hh.mm a"
                          )
                        : null}
                    </td>
                    <th>
                      {p.emailAttachmentPath != null ? (
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            downloadAll(
                              p.emailAttachmentPath,
                              p.toEmailId,
                              p.fromEmailId
                            );
                          }}
                        >
                          View
                        </a>
                      ) : (
                        ""
                      )}
                    </th>
                    <th>
                      {/* <a
                        className="sent-button"
                        id={p.id}
                        onClick={() => openReply(p)}
                      >
                        Reply
                      </a> */}
                      <InboxReplyModal
                        register={register}
                        setbackdropopen={setbackdropopen}
                        getInbox={getInbox}
                        handle403error={handle403error}
                        pageno={pageno}
                        toast={toast}
                        p={p}
                        id={p.id}
                        jwtToken={jwtToken}
                      />
                      &nbsp;&nbsp;&nbsp;
                      {/* <a
                        className="sent-button"
                        id={p.id}
                        onClick={() => openView(p)}
                      >
                        View
                      </a> */}
                      <InboxViewModal selectemail={selectemail} p={p} />
                      {/* <Modal
                        isOpen={modalIsOpen3}
                        // onAfterOpen={afterOpenModal}
                        onRequestClose={closeModal}
                        style={customStyles}
                        contentLabel="Example Modal"
                      >
                        <div>
                          <h4>
                            <strong>View Message</strong>
                          </h4>
                          <div className="form-group creatadmin">
                            <Row>
                              <Col>
                                <h4>
                                  <strong>Receiver:</strong>
                                </h4>
                                <p>{selectemail.toEmailId}</p>
                              </Col>
                              <Col>
                                <h4>
                                  <strong>Subject:</strong>
                                </h4>
                                <p>{selectemail.subject}</p>
                              </Col>
                            </Row>
                          </div>
                          <div className="form-group creatadmin">
                            <Row>
                              <Col>
                                <h4>
                                  <strong>Description:</strong>
                                </h4>
                                <p>{selectemail.description}</p>
                              </Col>
                            </Row>
                          </div>
                        </div>
                        <div className="form-group creatadmin">
                          <div className="d-flex justify-content-end">
                            <Button color="secondary" onClick={closeModal}>
                              Close
                            </Button>
                          </div>
                        </div>
                      </Modal> */}
                      {/* <Modal
                        isOpen={modalIsOpen4}
                        // onAfterOpen={afterOpenModal}
                        onRequestClose={closeModal}
                        style={customStyles}
                        contentLabel="Example Modal"
                      >
                        <form onSubmit={handleSubmit(submitReply)}>
                          <div>
                            <h3 style={{ color: "black" }}>Send Reply</h3>
                            <div className="form-group creatadmin">
                              <Row>
                                <Col>
                                  <label>Receiver:</label>
                                  <input
                                    type="text"
                                    ref={register}
                                    className="form-control underline-input"
                                    value={selectemail.toEmailId}
                                    readOnly
                                    required
                                  />
                                </Col>
                              </Row>
                            </div>
                            <div className="form-group creatadmin">
                              <Row>
                                <Col>
                                  <label>Subject:</label>
                                  <input
                                    type="text"
                                    ref={register}
                                    className="form-control underline-input"
                                    onChange={(e) => {
                                      setsetSubject(e.target.value);
                                    }}
                                    required
                                  />
                                </Col>
                              </Row>
                            </div>
                            <div className="form-group creatadmin">
                              <Row>
                                <Col>
                                  <label>Description:</label>
                                  <TextareaAutosize
                                    rowsMax={6}
                                    ref={register}
                                    style={{ height: "100px" }}
                                    className="form-control underline-input"
                                    onChange={(e) => {
                                      setsetComment(e.target.value);
                                    }}
                                    required
                                  />
                                </Col>
                              </Row>
                            </div>
                            <div className="form-group creatadmin">
                              <Row>
                                <Col>
                                  <label>Attachment:</label>
                                  <input
                                    ref={register}
                                    type="file"
                                    name="picture"
                                    onChange={(e) => chkfilesize(e)}
                                    accept=".jpg, .jpeg, .bmp, .gif, .png, .pdf, .doc, .docx"
                                  />
                                  {filesizemore && (
                                    <p className="errormessage">
                                      File Size cannot be more than 4 mb
                                    </p>
                                  )}
                                </Col>
                              </Row>
                            </div>
                            <div className="form-group creatadmin">
                              <Row className="justify-content-end">
                                <div>
                                  <Button
                                    color="secondary"
                                    onClick={closeModal}
                                  >
                                    Cancel
                                  </Button>
                                </div>
                                <div>
                                  <button
                                    type="submit"
                                    className="btn listing_addbutton ml-1"
                                  >
                                    Send
                                  </button>
                                </div>
                              </Row>
                            </div>
                          </div>
                        </form>
                      </Modal> */}
                    </th>
                  </tr>
                ))}
              </tbody>
            ) : (
              <Typography>{null}</Typography>
            )}
          </Table>
          <Row className="s_pagirow">
            <Col md={6}>
              <div>
                <form>
                  <div style={{ float: "left" }}>
                    <FormControl
                      variant="standard"
                      style={{
                        display: "inline-block",
                        marginRight: "25px",
                        minWidth: "50px",
                      }}
                    >
                      <select
                        className={classes.selectlimit}
                        labelId="simple-select-label"
                        id="simple-select"
                        value={rows}
                        onChange={handleRows}
                      >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                    </FormControl>
                    {inbox?.length > 0 ? (
                      <div className="s_spanPage">
                        {countnumber * rows + 1} to{" "}
                        {countnumber * rows + numberofElements} of {totalUser}{" "}
                        records
                      </div>
                    ) : (
                      <div className="s_spanPage">No Record Found</div>
                    )}
                  </div>
                </form>
              </div>
            </Col>
            <Col md={6}>
              <div className={classes.root} style={{ float: "right" }}>
                {searchdata ? (
                  <Pagination
                    count={getpageno}
                    onChange={handlesearch}
                    shape="rounded"
                  />
                ) : (
                  <Pagination
                    count={getpageno}
                    onChange={handlepage}
                    shape="rounded"
                  />
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default Inbox;
