import React, { useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import Modal from "react-modal";
import { customStyles } from "../../Utilities/CSSUtilities";
import { Col, Row, Button } from "reactstrap";
import PasswordStrengthBar from "react-password-strength-bar";
import { toast } from "react-toastify";
import axios from "axios";
import base_url from "../../api/bootapi";
import callsaveauditapi from "../../services/auditservice";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { logout } from "../features/userSlice";

const AdminChangePassword = ({ handleaccountclose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [modalIsOpen, setIsOpen] = useState(false);
  const isw_user_email = localStorage.getItem("isw_user_email");
  const [inputtype, setinputtype] = useState("password");
  const [chkPassState, setchkPassState] = useState();
  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  const isw_user_name = localStorage.getItem("isw_user_name");

  const changepassword = (e) => {
    e.preventDefault(); // Prevent the form from submitting

    let email = e.target[0].value;
    let password = chkPassState.trim();

    // Minimum length check
    if (password.length < 8) {
      toast.error("Password must be at least 8 characters long.");
      return;
    }

    // Regex format check
    const regexPattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}$/;
    if (!regexPattern.test(password)) {
      toast.error(
        "Password must have at least one uppercase letter, one lowercase letter, and one digit."
      );
      return;
    }

    // Proceed with the API call
    let data = {
      email,
      password,
    };
    //console.log("55", data);
    axios
      .post(`${base_url.api2}/updatePasswordByEmail`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        toast.success("Password Changed Successfully");
        let log = isw_user_name + " Updated passowrd of User " + email + ".";
        callsaveauditapi(log, dispatch, navigate);
        setIsOpen(false);
      })
      .catch((err) => {
        setIsOpen(false);
        let log =
          isw_user_name + " failed to Updated passowrd of User " + email + ".";
        callsaveauditapi(log, dispatch, navigate);
        handle403error(err);
      });
  };

  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            navigate("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };

  return (
    <>
      <li>
        <a class="dropdown-item"
          onClick={() => {
            handleaccountclose();
            setIsOpen(true);
          }}
        >
          Change Password
        </a>
      </li>
      <Modal
        isOpen={modalIsOpen}
        style={customStyles}
        onRequestClose={() => setIsOpen(false)}
        contentLabel="Example"
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            changepassword(e);
          }}
        >
          <div>
            <h3 style={{ color: "black" }}>Change Password</h3>
            <div className="form-group createadmin">
              <Row>
                <Col>
                  <label>User Name:</label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    value={isw_user_email}
                    readOnly
                  />
                </Col>
              </Row>
            </div>
            <div className="form-group creatadmin">
              <Row>
                <Col>
                  <label>Password:</label>
                  <div style={{ position: "relative" }}>
                    <input
                      type={inputtype}
                      value={chkPassState}
                      onChange={(e) => setchkPassState(e.target.value)}
                      className="form-control underline-input"
                      required
                    />
                    {inputtype === "password" ? (
                      <i
                        className="fa fa-eye-slash fonticonpositioning"
                        onClick={() => setinputtype("text")}
                        aria-hidden="true"
                      ></i>
                    ) : (
                      <i
                        className="fa fa-eye fonticonpositioning"
                        onClick={() => setinputtype("password")}
                        aria-hidden="true"
                      ></i>
                    )}
                  </div>
                  <PasswordStrengthBar password={chkPassState} />
                </Col>
              </Row>
            </div>
            <div className="form-group creatadmin">
              <Row>
                <Col md={12}>
                  <div className="d-flex justify-content-end mt-2">
                    <Button
                      className="secondary"
                      onClick={() => setIsOpen(false)}
                    >
                      Cancel
                    </Button>
                    <button
                      type="submit"
                      className="btn listing_addbutton ml-1"
                    >
                      Update
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default AdminChangePassword;
