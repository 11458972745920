import React, { useEffect, useState, useRef } from "react";
import "../css/EnqyuiryDetails.css";
import axios from "axios";
import base_url from "../../api/bootapi";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useParams, useNavigate, Link } from "react-router-dom";
import { Col, Row, Button } from "reactstrap";
import EnquiryWorkflowStepDetails from "./EnquiryWorkflowStepDetails";
import moment from "moment";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Notes from "./Notes";
import Attachments from "./Attachments";
import Appointments from "./Appointments";
import { useDispatch, useSelector } from "react-redux";
import { getworkflowType, logout, selectUser } from "../features/userSlice";
import DatePicker from "react-datepicker";
import { customStyles } from "../../Utilities/CSSUtilities";
import Modal from "react-modal";
import PaymentSchedule from "./PaymentSchedule";
import callsaveauditapi from "../../services/auditservice";
import { useForm } from "react-hook-form";
import { check1pointpermission } from "../Permission/permission";
import WorkflowLogs from "./WorkflowLogs";

import EditImage from "./../images/enquiry/edit.png";
function TabPanel(props) {
  return (
    <div
      role="tabpanel"
      hidden={props.value !== props.index}
      id={`scrollable-auto-tabpanel-${props.index}`}
      aria-labelledby={`scrollable-auto-tab-${props.index}`}
    >
      {props.value === props.index && props.component}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    // backgroundColor: theme.palette.background.paper,
  },
  table: {
    minWidth: 650,
  },
}));

const EnqyuiryDetails = (props) => {
  console.log("73------>", props?.workflowSteps);
  const params = useParams();
  const jwttoken = "Bearer " + localStorage.getItem("isw_user_token");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [paymentschedulemodal, setpaymentschedulemodal] = useState(false);

  const [paymentschedulelines, setpaymentschedulelines] = useState([
    {
      amount: 0,
      commissionPercentage: 0,
      dueDate: null,
      enquiryId: Number(params.enquiry_id),
      id: 0,
      paidStatus: false,
      semester: "",
    },
  ]);
  // ----------------->useRef Paymentschedule End<----------------------

  const user = useSelector(selectUser);
  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  const navigate = useNavigate();
  const [allintake, setIntake] = useState();
  const [subagentlist, setsubagentlist] = useState();
  const [superagentlist, setsuperagentlist] = useState();
  const classes = useStyles();
  const [externalId, setexternalId] = useState();
  const [prevexternalId, setprevexternalId] = useState();
  const [discontReason, setDiscontReason] = useState();
  const [defaultConsultant, setDefaultConsultant] = useState();
  const [startdate, setstartdate] = useState(null);
  const [prevstartdate, setprevstartdate] = useState(null);
  const [enddate, setenddate] = useState(null);
  const [prevenddate, setprevenddate] = useState(null);
  const [policystartdate, setpolicystartdate] = useState(null);
  const [policyenddate, setpolicyenddate] = useState(null);
  const [followup, setfollowup] = useState(null);
  const [prevfollowup, setprevfollowup] = useState(null);
  const [visaExpiry, setvisaExpiry] = useState(null);
  const [prevvisaExpiry, setprevvisaExpiry] = useState(null);
  const [deadline, setdeadline] = useState(null);
  const [prevdeadline, setprevdeadline] = useState(null);
  // const [followby, setfollowby] = useState();
  const [remarks, setremarks] = useState();
  const [prevremarks, setprevremarks] = useState();
  const [intakevalue, setIntakeValue] = useState();
  const [previntakevalue, setprevIntakeValue] = useState();
  const [coursefees, setcoursefees] = useState();
  const [prevcoursefees, setprevcoursefees] = useState();
  const [applicationfees, setapplicationfees] = useState();
  const [prevapplicationfees, setprevapplicationfees] = useState();
  const [materialFee, setmaterialFee] = useState();
  const [prevmaterialFee, setprevmaterialFee] = useState();
  const [otherFee, setotherFee] = useState();
  const [prevotherFee, setprevotherFee] = useState();
  const [discount, setdiscount] = useState();
  const [prevdiscount, setprevdiscount] = useState();
  const [totalnumberofunits, settotalnumberofunits] = useState();
  const [prevtotalnumberofunits, setprevtotalnumberofunits] = useState();
  const [totalunitstudy, settotalunitstudy] = useState();
  const [prevtotalunitstudy, setprevtotalunitstudy] = useState();
  const [scholarship, setscholarship] = useState();
  const [prevscholarship, setprevscholarship] = useState();
  const [subagent, setsubagent] = useState();
  const [prevsubagent, setprevsubagent] = useState();
  const [superagent, setsuperagent] = useState();
  const [prevsuperagent, setprevsuperagent] = useState();
  const [unitexemtion, setunitexemtion] = useState();
  const [prevunitexemtion, setprevunitexemtion] = useState();
  const [enquiryStatus, setenquiryStatus] = useState();
  const [prevenquiryStatus, setprevenquiryStatus] = useState();
  const [currentAssessment, setCurrentAssessment] = useState();
  // const selected_client_id = localStorage.getItem("selected_client_id");
  const selected_client_id =
    params.client_id || localStorage.getItem("selected_client_id");
  const isoDateString = new Date().toISOString();
  const datenow = isoDateString.split(".")[0];
  const dispatch = useDispatch();

  const isw_user_name = localStorage.getItem("isw_user_name");
  const isw_adminId = localStorage.getItem("isw_adminId");
  const isw_user_email = localStorage.getItem("isw_user_email");
  const ipaddress = localStorage.getItem("AdminIpAddress");
  const [deleteuser, setDeleteuser] = useState();
  const [ClientId, setClientId] = useState();
  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);
  // const [admissionStatus, setAdmissionStatus] = useState();
  // const [migrationStatus, setMigrationStatus] = useState();
  // const [skillStatus, setSkillStatus] = useState();
  // const [insuranceStatus, setInsuraceStatus] = useState();
  const [isApplicationClosed, setisApplicationClosed] = useState();
  const [workflowStatus, setWorkflowStatus] = useState();

  useEffect(() => {
    window.addEventListener(
      "storage",
      function (e) {
        if (e.key === null) {
          dispatch(logout());
          navigate("/");
        }
        // getCurrentWorkflow();
      },
      [workFlow, props.displayStatus]
    );

    // if (props.refreshit === "refresh") {
    props.setrefreshit("Notrefresh");
    // }
    getPermissions();
    setexternalId(props.content?.externalId);
    setprevexternalId(props.content?.externalId);
    setDiscontReason(props.content?.enquiryDiscontinueReason);
    setstartdate(props.content?.courseStartDate);
    setprevstartdate(props.content?.courseStartDate);
    setenddate(props.content?.courseEndDate);
    setprevenddate(props.content?.courseEndDate);
    setpolicystartdate(props.content?.policyStartDate);
    setpolicyenddate(props.content?.policyEndDate);
    setfollowup(props.content?.followUpDate);
    setprevfollowup(props.content?.followUpDate);
    setvisaExpiry(props.content?.visaExpiryDate);
    setprevvisaExpiry(props.content?.visaExpiryDate);
    setdeadline(props.content?.deadLineDate);
    setprevdeadline(props.content?.deadLineDate);
    setunitexemtion(props.content?.unitExmtion);
    setprevunitexemtion(props.content?.unitExmtion);
    setremarks(props.content?.remarks);
    setprevremarks(props.content?.remarks);
    setsubagent(props.content?.subAgentId);
    setprevsubagent(props.content?.subAgent);
    setsuperagent(props.content?.superAgentId);
    setprevsuperagent(props.content?.superAgent);
    setenquiryStatus(props.content?.enquiryStatus);
    setprevenquiryStatus(props.content?.enquiryStatus);
    setCurrentAssessment(props.content?.asssessmentType);
    setIntakeValue(props.content?.intake);
    setprevIntakeValue(props.content?.intake);
    // setcoursefees(props.content?.fees);
    setcoursefees(props.content?.fees);
    setprevcoursefees(props.content?.fees);
    setapplicationfees(props.content?.appFees);
    setprevapplicationfees(props.content?.appFees);
    //setapplicationfees(enquiryData?.map);
    setmaterialFee(props.content?.materialFee);
    setprevmaterialFee(props.content?.materialFee);
    setotherFee(props.content?.otherFee);
    setprevotherFee(props.content?.otherFee);
    // setdiscount(props.content?.discount);
    setdiscount(props.content?.discount);
    setprevdiscount(props.content?.discount);
    settotalnumberofunits(props.content?.numberOfUnits);
    setprevtotalnumberofunits(props.content?.numberOfUnits);
    settotalunitstudy(props.content?.numberToBeStudied);
    setprevtotalunitstudy(props.content?.numberToBeStudied);
    setscholarship(props.content?.scholarship);
    setprevscholarship(props.content?.scholarship);
    setDefaultConsultant(props.content?.consultantName);
    getsubagent();
    getsuperagent();
    getAllIntake();
    getClientDetails();
    getEnquiryDetails();
    // getInstituteBranchData();
  }, [props.content]);

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);

    if (check1pointpermission(306, permission_obj, resource_obj)) {
      getEnquiryDetails();
    } else {
      navigate("/errorPage");
    }
  };
  const [workFlow, setWorkFlow] = useState();
  // const [currentenquiryStatus, setcurrentenquiryStatus] = useState();
  const [enquiryData, setEnquiryData] = useState();
  const getEnquiryDetails = () => {
    const permission_obj = [];
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);

    //console.log(props.content);
    axios
      .get(`${base_url.api2}/getEnquiryById`, {
        headers: {
          Authorization: jwtToken,
          enquiryId: Number(params.enquiry_id),
        },
      })
      .then((res) => {
        setEnquiryData(res.data.data);
        props?.setDisplayStatus(res?.data?.data?.enquiryStatus);
        setDeleteuser(res.data.data.clientName);
        setClientId(res.data.data.clientId);
        setisApplicationClosed(res.data.data.close);
      })
      .catch((err) => {
        handle403error(err);
      });
  };

  const handleWorkFlowData = (workFlowData) => {
    setWorkFlow(workFlowData);
  };

  const handleStatusChange = (newStatus) => {
    props?.setDisplayStatus(newStatus);
  };

  // const getCurrentWorkflow = () => {
  //   axios
  //     .get(`${base_url.api2}/getEnquiryWorkflowByEnquiryId`, {
  //       headers: {
  //         Authorization: jwtToken,
  //         enquiryId: params.enquiry_id, // current user worklow id
  //       },
  //     })
  //     .then((res) => {
  //       //console.log("478--->", res.data.data.workflowId);
  //       axios
  //         .get(`${base_url.api2}/getWorkflowStepByWorkflowId`, {
  //           headers: {
  //             Authorization: jwtToken,
  //             workflowId: res.data.data.workflowId, // current steps for the user
  //           },
  //         })
  //         .then((res) => {
  //           //console.log("488---->", res.data.data.workflowSteps);
  //           setWorkFlow(res?.data?.data?.workflowSteps);
  //         })
  //         .catch((err) => {
  //           //console.log("Error", err);
  //         });
  //     })
  //     .catch((err) => {
  //       handle403error(err);
  //     });
  //   //console.log("497---->");
  // };

  const getAllIntake = () => {
    axios
      .get(`${base_url.api1}/getAllIntake`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        let intakeData = res.data.data;
        let intakeObj = [];
        for (let i = 0; i < intakeData.length; i++) {
          let data = {
            createdIntake: moment(intakeData[i].createdIntake).format(
              "YYYY-MM"
            ),
            val: moment(intakeData[i].name).format("YYYYMM"),
            id: intakeData[i].id,
            name: intakeData[i].name,
            status: intakeData[i].status,
          };
          intakeObj.push(data);
        }
        let newintakeObj = intakeObj.sort(function (a, b) {
          return b.val - a.val;
        });
        setIntake(newintakeObj);
      })
      .catch((err) => handleerror(err));
  };
  const getsubagent = () => {
    axios
      .get(`${base_url.api3}/findAgentsForDropDownList`, {
        headers: {
          Authorization: jwtToken,
          agentType: "Sub Agent",
        },
      })
      .then(
        (response) => {
          setsubagentlist(response.data.data);
        },
        (error) => {
          // toast.error(JSON.stringify(error.response.data.message));
          //console.log(JSON.stringify(error.response));
        }
      );
  };
  const getsuperagent = () => {
    axios
      .get(`${base_url.api3}/findAgentsForDropDownList`, {
        headers: {
          Authorization: jwtToken,
          agentType: "Super Agent",
        },
      })
      .then(
        (response) => {
          setsuperagentlist(response.data.data);
        },
        (error) => {
          // toast.error(JSON.stringify(error.response.data.message));
          //console.log(JSON.stringify(error.response));
        }
      );
  };
  const [value, setValue] = React.useState(0);
  let count = 0;
  for (let i = 0; i < props.workflowSteps?.length; i++) {
    if (props.workflowSteps[i].status === 1) {
      count += 1;
    }
  }
  const percentage =
    Math.round((count / props.workflowSteps?.length) * 100 * 100) / 100;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const updateEnquiryStatus = (e) => {
    let data = {
      enquiryId: Number(params.enquiry_id),
      enquiryStatus: e.target[0].value,
      enquiryDiscontinueReason: discontReason,
      updatedBy: isw_user_name,
      updated: datenow,
    };
    axios
      .post(`${base_url.api2}/updateEnquiryByEnquiryId`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        toast.success("Enquiry Status Updated");

        let data = {
          createdOn: datenow,
          enquiryId: Number(params.enquiry_id),
          enquiryWorkflowStepsId: Number(enquiryData?.currentStageId),
          id: 0,
          log:
            " updated application status to " +
            res.data.data.enquiryStatus +
            " - By " +
            isw_user_name,
        };

        axios
          .post(`${base_url.api2}/createLog`, data, {
            headers: {
              Authorization: jwtToken,
            },
          })
          .then((res) => {
            //console.log(res);
          })
          .catch((err) => {
            //console.log(err);
          });
        let logs =
          isw_user_name +
          " updated application status of (" +
          deleteuser +
          ") " +
          prevenquiryStatus +
          " to " +
          enquiryStatus +
          ".";
        callsaveauditapi(logs, dispatch, navigate);
        props.getEnquiry();
      })
      .catch((err) => {
        handle403error(err);
      });
  };
  const updateExternalId = (e) => {
    let data = {
      enquiryId: Number(params.enquiry_id),
      externalId: e.target[0].value,
      updatedBy: isw_user_name,
      updated: datenow,
    };
    //console.log("External", data);
    axios
      .post(`${base_url.api2}/updateEnquiryByEnquiryId`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        //console.log("External Id", res);
        if (props.workflowtyperedux === "Insurance") {
          toast.success("Policy No. Updated");

          let data = {
            createdOn: datenow,
            enquiryId: Number(params.enquiry_id),
            enquiryWorkflowStepsId: Number(enquiryData?.currentStageId),
            id: 0,
            log:
              " updated Policy No. to " +
              res.data.data.externalId +
              " - By " +
              isw_user_name,
          };

          axios
            .post(`${base_url.api2}/createLog`, data, {
              headers: {
                Authorization: jwtToken,
              },
            })
            .then((res) => {
              //console.log(res);
            })
            .catch((err) => {
              //console.log(err);
            });
          let logs =
            isw_user_name +
            " updated application status of (" +
            deleteuser +
            ") " +
            prevexternalId +
            " to " +
            externalId +
            ".";
          callsaveauditapi(logs, dispatch, navigate);
          props.getEnquiry();
        } else {
          toast.success("Student Id Updated");

          let data = {
            createdOn: datenow,
            enquiryId: Number(params.enquiry_id),
            enquiryWorkflowStepsId: Number(enquiryData?.currentStageId),
            id: 0,
            log:
              " updated Student Id to " +
              res.data.data.externalId +
              " - By " +
              isw_user_name,
          };

          axios
            .post(`${base_url.api2}/createLog`, data, {
              headers: {
                Authorization: jwtToken,
              },
            })
            .then((res) => {
              //console.log(res);
            })
            .catch((err) => {
              //console.log(err);
            });
          let logs =
            isw_user_name +
            " updated Student Id of (" +
            deleteuser +
            ") " +
            prevexternalId +
            " to " +
            externalId +
            ".";
          callsaveauditapi(logs, dispatch, navigate);
        }
        props.getEnquiry();
      })
      .catch((err) => {
        handle403error(err);
      });
  };

  // const getApplicationStatus = () => {
  //   //console.log("PROPS IS", props.workflowtyperedux);
  //   if (props.workflowtyperedux === "Admission") {
  //     axios
  //       .get(`${base_url.api1}/getAllApplicationStatusByType`, {
  //         headers: {
  //           Authorization: jwtToken,
  //           applicationType: "admission",
  //         },
  //       })
  //       .then((response) => {
  //         //console.log("Admission Data 11 >>>", response.data.data);
  //         setAdmissionStatus(response.data.data);
  //       })
  //       .catch((error) => {
  //         handle403error(error);
  //       });
  //   }
  // };

  // const getApplicationStatus = () => {
  //   if (props.workflowtyperedux === "Admission") {
  //     axios
  //       .get(`${base_url.api1}/getAllApplicationStatusByType`, {
  //         headers: {
  //           Authorization: jwtToken,
  //           applicationType: "admission",
  //         },
  //       })
  //       .then((response) => {
  //         //console.log("Admission Data>>>", response.data.data);
  //         setAdmissionStatus(response.data.data);
  //       })
  //       .catch((error) => {
  //         handle403error(error);
  //       });
  //   } else if (props.workflowtyperedux === "Migration") {
  //     axios
  //       .get(`${base_url.api1}/getAllApplicationStatusByType`, {
  //         headers: {
  //           Authorization: jwtToken,
  //           applicationType: "migration",
  //         },
  //       })
  //       .then((response) => {
  //         setMigrationStatus(response.data.data);
  //       })
  //       .catch((error) => {
  //         handle403error(error);
  //       });
  //   } else if (props.workflowtyperedux === "Insurance") {
  //     axios
  //       .get(`${base_url.api1}/getAllApplicationStatusByType`, {
  //         headers: {
  //           Authorization: jwtToken,
  //           applicationType: "insurance",
  //         },
  //       })
  //       .then((response) => {
  //         setInsuraceStatus(response.data.data);
  //       })
  //       .catch((error) => {
  //         handle403error(error);
  //       });
  //   } else {
  //     axios
  //       .get(`${base_url.api1}/getAllApplicationStatusByType`, {
  //         headers: {
  //           Authorization: jwtToken,
  //           applicationType: "skillassessment",
  //         },
  //       })
  //       .then((response) => {
  //         setSkillStatus(response.data.data);
  //       })
  //       .catch((error) => {
  //         handle403error(error);
  //       });
  //   }
  // };

  const getWorkflowStatus = () => {
    axios
      .get(`${base_url.api2}/getEnquiryWorkflowStatusList`, {
        headers: {
          Authorization: jwtToken,
          enquiryId: Number(params.enquiry_id),
        },
      })
      .then((response) => {
        console.log("654---->", response.data.data);
        setWorkflowStatus(response.data.data);
      })
      .catch((error) => {
        handle403error(error);
      });
  };

  const updateAssessment = (e) => {
    let data = {
      enquiryId: Number(params.enquiry_id),
      asssessmentType: e.target[0].value,
      updatedBy: isw_user_name,
      updated: datenow,
    };
    axios
      .post(`${base_url.api2}/updateEnquiryByEnquiryId`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        toast.success("Assessment Type Updated");

        let data = {
          createdOn: datenow,
          enquiryId: Number(params.enquiry_id),
          enquiryWorkflowStepsId: Number(enquiryData?.currentStageId),
          id: 0,
          log:
            " updated Assessment Type to " +
            res.data.data.asssessmentType +
            " - By " +
            isw_user_name,
        };

        axios
          .post(`${base_url.api2}/createLog`, data, {
            headers: {
              Authorization: jwtToken,
            },
          })
          .then((res) => {
            //console.log(res);
          })
          .catch((err) => {
            //console.log(err);
          });
        let logs =
          isw_user_name + " updated Assessment Type of " + deleteuser + " .";
        callsaveauditapi(logs, dispatch, navigate);
        props.getEnquiry();
      })
      .catch((err) => {
        handle403error(err);
      });
  };

  const updatecoursestartdate = (e) => {
    let data = {
      enquiryId: Number(params.enquiry_id),
      courseStartDate: moment(startdate).format("YYYY-MM-DDThh:mm:ss"),
      updatedBy: isw_user_name,
      updated: datenow,
    };
    axios
      .post(`${base_url.api2}/updateEnquiryByEnquiryId`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        toast.success("CourseStart Date Updated");

        let data = {
          createdOn: datenow,
          enquiryId: Number(params.enquiry_id),
          enquiryWorkflowStepsId: Number(enquiryData?.currentStageId),
          id: 0,
          log:
            " updated Course Start Date to " +
            moment(res.data.data.courseStartDate).format("YYYY-MM-DD") +
            " - By " +
            isw_user_name,
        };

        axios
          .post(`${base_url.api2}/createLog`, data, {
            headers: {
              Authorization: jwtToken,
            },
          })
          .then((res) => {
            //console.log(res);
          })
          .catch((err) => {
            //console.log(err);
          });
        let logs =
          isw_user_name +
          " updated Course Start Date of (" +
          deleteuser +
          ") " +
          moment(prevstartdate).format("DD-MMM-YY") +
          " to " +
          moment(startdate).format("DD-MMM-YY") +
          ".";
        callsaveauditapi(logs, dispatch, navigate);
        props.getEnquiry();
      })
      .catch((err) => {
        handle403error(err);
      });
  };
  const updatecourseenddate = (e) => {
    let data = {
      enquiryId: Number(params.enquiry_id),
      courseEndDate: moment(enddate).format("YYYY-MM-DDThh:mm:ss"),
      updatedBy: isw_user_name,
      updated: datenow,
    };
    axios
      .post(`${base_url.api2}/updateEnquiryByEnquiryId`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        toast.success("CourseEnd Date Updated");

        let data = {
          createdOn: datenow,
          enquiryId: Number(params.enquiry_id),
          enquiryWorkflowStepsId: Number(enquiryData?.currentStageId),
          id: 0,
          log:
            " updated Course End Date to " +
            moment(res.data.data.courseEndDate).format("YYYY-MM-DD") +
            " - By " +
            isw_user_name,
        };

        axios
          .post(`${base_url.api2}/createLog`, data, {
            headers: {
              Authorization: jwtToken,
            },
          })
          .then((res) => {
            //console.log(res);
          })
          .catch((err) => {
            //console.log(err);
          });
        let logs =
          isw_user_name +
          " updated Course End Date of (" +
          deleteuser +
          ") " +
          moment(prevenddate).format("DD-MMM-YY") +
          " to " +
          moment(enddate).format("DD-MMM-YY") +
          ".";
        callsaveauditapi(logs, dispatch, navigate);
        props.getEnquiry();
      })
      .catch((err) => {
        handle403error(err);
      });
  };

  const updateCourseDate = (startDateMoment, endDateMoment) => {
    const formattedStartDate = startDateMoment.format("YYYY-MM-DDThh:mm:ss");
    const formattedEndDate = endDateMoment.format("YYYY-MM-DDThh:mm:ss");
    const datenowFormatted = moment(datenow).format("YYYY-MM-DDThh:mm:ss");

    let data = {
      enquiryId: Number(params.enquiry_id),
      courseStartDate: formattedStartDate,
      courseEndDate: formattedEndDate,
      updatedBy: isw_user_name,
      updated: datenowFormatted,
    };

    axios
      .post(`${base_url.api2}/updateEnquiryByEnquiryId`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        toast.success("Course Start Date and End Date Updated");

        let logData = {
          createdOn: datenowFormatted,
          enquiryId: Number(params.enquiry_id),
          enquiryWorkflowStepsId: Number(enquiryData?.currentStageId),
          id: 0,
          log:
            " updated Course Start Date to " +
            startDateMoment.format("YYYY-MM-DD") +
            " and Course End Date to " +
            endDateMoment.format("YYYY-MM-DD") +
            " - By " +
            isw_user_name,
        };

        axios
          .post(`${base_url.api2}/createLog`, logData, {
            headers: {
              Authorization: jwtToken,
            },
          })
          .then((res) => {
            //console.log(res);
          })
          .catch((err) => {
            //console.log(err);
          });

        let logs = `${isw_user_name} updated Course Start Date`;

        if (prevstartdate) {
          logs += ` from ${moment(prevstartdate).format("DD-MMM-YY")}`;
        }
        logs += ` to ${startDateMoment.format("DD-MMM-YY")}`;

        if (prevenddate) {
          logs += ` and Course End Date from ${moment(prevenddate).format(
            "DD-MMM-YY"
          )} to ${endDateMoment.format("DD-MMM-YY")}.`;
        } else {
          logs += ` and Course End Date to ${endDateMoment.format(
            "DD-MMM-YY"
          )}.`;
        }

        console.log("754---->logs", logs);

        callsaveauditapi(logs, dispatch, navigate);

        props.getEnquiry();
      })
      .catch((err) => {
        handle403error(err);
      });
  };

  const updatepolicystartdate = (e) => {
    let data = {
      enquiryId: Number(params.enquiry_id),
      policyStartDate: moment(policystartdate).format("YYYY-MM-DDThh:mm:ss"),
      updatedBy: isw_user_name,
      updated: datenow,
    };
    axios
      .post(`${base_url.api2}/updateEnquiryByEnquiryId`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        toast.success("Policy Start Date Updated");

        let data = {
          createdOn: datenow,
          enquiryId: Number(params.enquiry_id),
          enquiryWorkflowStepsId: Number(enquiryData?.currentStageId),
          id: 0,
          log:
            " updated Policy Start Date to " +
            moment(res.data.data.policyStartDate).format("YYYY-MM-DD") +
            " - By " +
            isw_user_name,
        };

        axios
          .post(`${base_url.api2}/createLog`, data, {
            headers: {
              Authorization: jwtToken,
            },
          })
          .then((res) => {
            //console.log(res);
            props.getEnquiry();
          })
          .catch((err) => {
            //console.log(err);
          });
        let logs =
          isw_user_name +
          "  updated Policy Start Date of the user " +
          deleteuser +
          " .";
        callsaveauditapi(logs, dispatch, navigate);
        props.getEnquiry();
      })
      .catch((err) => {
        handle403error(err);
      });
  };
  const updatepolicyenddate = (e) => {
    let data = {
      enquiryId: Number(params.enquiry_id),
      policyEndDate: moment(policyenddate).format("YYYY-MM-DDThh:mm:ss"),
      updatedBy: isw_user_name,
      updated: datenow,
    };
    axios
      .post(`${base_url.api2}/updateEnquiryByEnquiryId`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        toast.success("PolicyEnd Date Updated");

        let data = {
          createdOn: datenow,
          enquiryId: Number(params.enquiry_id),
          enquiryWorkflowStepsId: Number(enquiryData?.currentStageId),
          id: 0,
          log:
            " updated Policy End Date to " +
            moment(res.data.data.policyEndDate).format("YYYY-MM-DD") +
            " - By " +
            isw_user_name,
        };

        axios
          .post(`${base_url.api2}/createLog`, data, {
            headers: {
              Authorization: jwtToken,
            },
          })
          .then((res) => {
            //console.log(res);
            props.getEnquiry();
          })
          .catch((err) => {
            //console.log(err);
          });
        let logs =
          isw_user_name +
          " updated Policy End Date of the user " +
          deleteuser +
          " .";
        callsaveauditapi(logs, dispatch, navigate);
        props.getEnquiry();
      })
      .catch((err) => {
        handle403error(err);
      });
  };

  const updatefollowupdate = (e) => {
    let data = {
      enquiryId: Number(params.enquiry_id),
      followUpDate: moment(followup).format("YYYY-MM-DD"),
      updatedBy: isw_user_name,
      updated: datenow,
    };
    axios
      .post(`${base_url.api2}/updateEnquiryByEnquiryId`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        toast.success("FollowUp Date Updated");

        let data = {
          createdOn: datenow,
          enquiryId: Number(params.enquiry_id),
          enquiryWorkflowStepsId: Number(enquiryData?.currentStageId),
          id: 0,
          log:
            " updated Follow Up Date to " +
            moment(res.data.data.followUpDate).format("YYYY-MM-DD") +
            " - By " +
            isw_user_name,
        };

        axios
          .post(`${base_url.api2}/createLog`, data, {
            headers: {
              Authorization: jwtToken,
            },
          })
          .then((res) => {
            //console.log(res);
          })
          .catch((err) => {
            //console.log(err);
          });
        let logs =
          isw_user_name +
          " has updated Follow Up Date of (" +
          deleteuser +
          ") " +
          moment(prevfollowup).format("DD-MMM-YY") +
          " to " +
          moment(followup).format("DD-MMM-YY") +
          ".";
        callsaveauditapi(logs, dispatch, navigate);
        props.getEnquiry();
      })
      .catch((err) => {
        handle403error(err);
      });
  };

  const updatevisaexpirydate = (e) => {
    let data = {
      enquiryId: Number(params.enquiry_id),
      visaExpiryDate: moment(visaExpiry).format("YYYY-MM-DD"),
      updatedBy: isw_user_name,
      updated: datenow,
    };
    axios
      .post(`${base_url.api2}/updateEnquiryByEnquiryId`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        toast.success("VisaExpiry Date Updated");

        let data = {
          createdOn: datenow,
          enquiryId: Number(params.enquiry_id),
          enquiryWorkflowStepsId: Number(enquiryData?.currentStageId),
          id: 0,
          log:
            " updated VisaExpiry Date to " +
            moment(res.data.data.visaExpiryDate).format("YYYY-MM-DD") +
            " - By " +
            isw_user_name,
        };

        axios
          .post(`${base_url.api2}/createLog`, data, {
            headers: {
              Authorization: jwtToken,
            },
          })
          .then((res) => {
            //console.log(res);
          })
          .catch((err) => {
            //console.log(err);
          });
        let logs =
          isw_user_name +
          " has updated VisaExpiry Date of (" +
          deleteuser +
          ") " +
          moment(prevvisaExpiry).format("DD-MMM-YY") +
          " to " +
          moment(visaExpiry).format("DD-MMM-YY") +
          ".";
        callsaveauditapi(logs, dispatch, navigate);
        props.getEnquiry();
      })
      .catch((err) => {
        handle403error(err);
      });
  };

  const updatedeadlinedate = (e) => {
    let data = {
      enquiryId: Number(params.enquiry_id),
      deadLineDate: moment(deadline).format("YYYY-MM-DD"),
      updatedBy: isw_user_name,
      updated: datenow,
    };
    axios
      .post(`${base_url.api2}/updateEnquiryByEnquiryId`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        toast.success("DeadLine Date Updated");

        let data = {
          createdOn: datenow,
          enquiryId: Number(params.enquiry_id),
          enquiryWorkflowStepsId: Number(enquiryData?.currentStageId),
          id: 0,
          log:
            " updated DeadLine Date to " +
            moment(res.data.data.deadLineDate).format("YYYY-MM-DD") +
            " - By " +
            isw_user_name,
        };

        axios
          .post(`${base_url.api2}/createLog`, data, {
            headers: {
              Authorization: jwtToken,
            },
          })
          .then((res) => {
            //console.log(res);
          })
          .catch((err) => {
            //console.log(err);
          });
        let logs =
          isw_user_name +
          " has updated DeadLine Date of (" +
          deleteuser +
          ") " +
          moment(prevdeadline).format("DD-MMM-YY") +
          " to " +
          moment(deadline).format("DD-MMM-YY") +
          ".";
        callsaveauditapi(logs, dispatch, navigate);
        props.getEnquiry();
      })
      .catch((err) => {
        handle403error(err);
      });
  };
  // const updatefollowbydate = (e) => {
  //   let data = {
  //     enquiryId: Number(params.enquiry_id),
  //     followByDate: moment(e.target[0].value).format("YYYY-MM-DDThh:mm:ss"),
  //   };
  //   axios
  //     .post(`${base_url.api2}/updateEnquiryByEnquiryId`, data, {
  //       headers: {
  //         Authorization: jwtToken,
  //       },
  //     })
  //     .then((res) => {
  //       toast.success("FollowBy Date Updated");
  //       props.getEnquiry();
  //     })
  //     .catch((err) => {
  //       //console.log(err);
  //     });
  // };
  const updateremarks = (e) => {
    let data = {
      enquiryId: Number(params.enquiry_id),
      remarks: e.target[0].value,
      updatedBy: isw_user_name,
      updated: datenow,
    };
    axios
      .post(`${base_url.api2}/updateEnquiryByEnquiryId`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        toast.success("Remarks Updated");

        let data = {
          createdOn: datenow,
          enquiryId: Number(params.enquiry_id),
          enquiryWorkflowStepsId: Number(enquiryData?.currentStageId),
          id: 0,
          log:
            " updated Remarks " +
            res.data.data.remarks +
            " - By " +
            isw_user_name,
        };

        axios
          .post(`${base_url.api2}/createLog`, data, {
            headers: {
              Authorization: jwtToken,
            },
          })
          .then((res) => {
            //console.log(res);
          })
          .catch((err) => {
            //console.log(err);
          });
        let logs =
          isw_user_name +
          " updated Remarks of the user (" +
          deleteuser +
          ") " +
          prevremarks +
          " to " +
          remarks +
          ".";
        callsaveauditapi(logs, dispatch, navigate);
        props.getEnquiry();
      })
      .catch((err) => {
        handle403error(err);
      });
  };
  const updateintakeenquiry = (e) => {
    let data = {
      enquiryId: Number(params.enquiry_id),
      intake: e.target[0].value,
      updatedBy: isw_user_name,
      updated: datenow,
    };
    axios
      .post(`${base_url.api2}/updateEnquiryByEnquiryId`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        toast.success("Intake Updated");

        let data = {
          createdOn: datenow,
          enquiryId: Number(params.enquiry_id),
          enquiryWorkflowStepsId: Number(enquiryData?.currentStageId),
          id: 0,
          log:
            " updated Intake to " +
            res.data.data.intake +
            " - By " +
            isw_user_name,
        };

        axios
          .post(`${base_url.api2}/createLog`, data, {
            headers: {
              Authorization: jwtToken,
            },
          })
          .then((res) => {
            //console.log(res);
          })
          .catch((err) => {
            //console.log(err);
          });
        let logs =
          isw_user_name +
          " updated Intake of the user (" +
          deleteuser +
          ") " +
          previntakevalue +
          " to " +
          intakevalue +
          ".";
        callsaveauditapi(logs, dispatch, navigate);
        props.getEnquiry();
      })
      .catch((err) => {
        handle403error(err);
      });
  };
  const updateunitexemtion = (e) => {
    let numberToBeStudied = props.coursedetail?.semester
      ? Number(props.content.numberOfUnits) - Number(e.target[0].value)
      : // /Number(props.coursedetail?.semester)
      0;
    let data = {
      enquiryId: Number(params.enquiry_id),
      numberToBeStudied,
      unitExmtion: Number(e.target[0].value),
      updatedBy: isw_user_name,
      updated: datenow,
    };
    axios
      .post(`${base_url.api2}/updateEnquiryByEnquiryId`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        toast.success("Unit Exemption");

        let data = {
          createdOn: datenow,
          enquiryId: Number(params.enquiry_id),
          enquiryWorkflowStepsId: Number(enquiryData?.currentStageId),
          id: 0,
          log:
            " updated Unit Exemption " +
            res.data.data.unitExmtion +
            " - By " +
            isw_user_name,
        };

        axios
          .post(`${base_url.api2}/createLog`, data, {
            headers: {
              Authorization: jwtToken,
            },
          })
          .then((res) => {
            //console.log(res);
          })
          .catch((err) => {
            //console.log(err);
          });
        let logs =
          isw_user_name +
          " updated Unit Exemption of the user (" +
          deleteuser +
          ") " +
          prevunitexemtion +
          " to " +
          unitexemtion +
          ".";
        callsaveauditapi(logs, dispatch, navigate);
        props.getEnquiry();
      })
      .catch((err) => {
        handle403error(err);
      });
  };

  const updatecoursefees = (e) => {
    let data = {
      enquiryId: Number(params.enquiry_id),
      fees: Number(e.target[0].value),
      updatedBy: isw_user_name,
      updated: datenow,
    };
    axios
      .post(`${base_url.api2}/updateEnquiryByEnquiryId`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        toast.success("Course Fees");

        let data = {
          createdOn: datenow,
          enquiryId: Number(params.enquiry_id),
          enquiryWorkflowStepsId: Number(enquiryData?.currentStageId),
          id: 0,
          log:
            " updated Course Fees " +
            res.data.data.fees +
            " - By " +
            isw_user_name,
        };

        axios
          .post(`${base_url.api2}/createLog`, data, {
            headers: {
              Authorization: jwtToken,
            },
          })
          .then((res) => {
            //console.log(res);
          })
          .catch((err) => {
            //console.log(err);
          });
        let logs =
          isw_user_name +
          " updated Course Fees of the user (" +
          deleteuser +
          ") " +
          prevcoursefees +
          " to " +
          coursefees +
          ".";
        callsaveauditapi(logs, dispatch, navigate);
        props.getEnquiry();
      })
      .catch((err) => {
        handle403error(err);
      });
  };
  const updateapplicationfees = (e) => {
    let data = {
      enquiryId: Number(params.enquiry_id),
      appFees: Number(e.target[0].value),
      updatedBy: isw_user_name,
      updated: datenow,
    };
    axios
      .post(`${base_url.api2}/updateEnquiryByEnquiryId`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        toast.success("Application Fees");

        let data = {
          createdOn: datenow,
          enquiryId: Number(params.enquiry_id),
          enquiryWorkflowStepsId: Number(enquiryData?.currentStageId),
          id: 0,
          log:
            " updated Application Fees " +
            res.data.data.appFees +
            " - By " +
            isw_user_name,
        };

        axios
          .post(`${base_url.api2}/createLog`, data, {
            headers: {
              Authorization: jwtToken,
            },
          })
          .then((res) => {
            //console.log(res);
          })
          .catch((err) => {
            //console.log(err);
          });
        let logs =
          isw_user_name +
          " updated Application Fees of the user (" +
          deleteuser +
          ") " +
          prevapplicationfees +
          " to " +
          applicationfees +
          ".";
        callsaveauditapi(logs, dispatch, navigate);
        props.getEnquiry();
      })
      .catch((err) => {
        handle403error(err);
      });
  };

  const updatematerialfees = (e) => {
    let data = {
      enquiryId: Number(params.enquiry_id),
      materialFee: Number(e.target[0].value),
      updatedBy: isw_user_name,
      updated: datenow,
    };
    axios
      .post(`${base_url.api2}/updateEnquiryByEnquiryId`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        toast.success("Material Fees");

        let data = {
          createdOn: datenow,
          enquiryId: Number(params.enquiry_id),
          enquiryWorkflowStepsId: Number(enquiryData?.currentStageId),
          id: 0,
          log:
            " updated MaterialFee Fees " +
            res.data.data.appFees +
            " - By " +
            isw_user_name,
        };

        axios
          .post(`${base_url.api2}/createLog`, data, {
            headers: {
              Authorization: jwtToken,
            },
          })
          .then((res) => {
            //console.log(res);
          })
          .catch((err) => {
            //console.log(err);
          });
        let logs =
          isw_user_name +
          " updated Material Fees of the user (" +
          deleteuser +
          ") " +
          prevmaterialFee +
          " to " +
          materialFee +
          ".";
        callsaveauditapi(logs, dispatch, navigate);
        props.getEnquiry();
      })
      .catch((err) => {
        handle403error(err);
      });
  };

  const updateotherfees = (e) => {
    let data = {
      enquiryId: Number(params.enquiry_id),
      otherFee: Number(e.target[0].value),
      updatedBy: isw_user_name,
      updated: datenow,
    };
    axios
      .post(`${base_url.api2}/updateEnquiryByEnquiryId`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        toast.success("Other Fees");

        let data = {
          createdOn: datenow,
          enquiryId: Number(params.enquiry_id),
          enquiryWorkflowStepsId: Number(enquiryData?.currentStageId),
          id: 0,
          log:
            " updated Other Fees " +
            res.data.data.appFees +
            " - By " +
            isw_user_name,
        };

        axios
          .post(`${base_url.api2}/createLog`, data, {
            headers: {
              Authorization: jwtToken,
            },
          })
          .then((res) => {
            //console.log(res);
          })
          .catch((err) => {
            //console.log(err);
          });
        let logs =
          isw_user_name +
          " updated Other Fees of the user (" +
          deleteuser +
          ") " +
          prevotherFee +
          " to " +
          otherFee +
          ".";
        callsaveauditapi(logs, dispatch, navigate);
        props.getEnquiry();
      })
      .catch((err) => {
        handle403error(err);
      });
  };

  const updatediscount = (e) => {
    let data = {
      enquiryId: Number(params.enquiry_id),
      discount: Number(e.target[0].value),
      updatedBy: isw_user_name,
      updated: datenow,
    };
    axios
      .post(`${base_url.api2}/updateEnquiryByEnquiryId`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        toast.success("Discount");

        let data = {
          createdOn: datenow,
          enquiryId: Number(params.enquiry_id),
          enquiryWorkflowStepsId: Number(enquiryData?.currentStageId),
          id: 0,
          log:
            " updated discount " +
            res.data.data.discount +
            " - By " +
            isw_user_name,
        };

        axios
          .post(`${base_url.api2}/createLog`, data, {
            headers: {
              Authorization: jwtToken,
            },
          })
          .then((res) => {
            //console.log(res);
          })
          .catch((err) => {
            //console.log(err);
          });
        let logs =
          isw_user_name +
          " updated discount of the user (" +
          deleteuser +
          ") " +
          prevdiscount +
          " to " +
          discount +
          ".";
        callsaveauditapi(logs, dispatch, navigate);
        props.getEnquiry();
      })
      .catch((err) => {
        handle403error(err);
      });
  };

  const updatescholarship = (e) => {
    let data = {
      enquiryId: Number(params.enquiry_id),
      scholarship: Number(e.target[0].value),
      updatedBy: isw_user_name,
      updated: datenow,
    };
    axios
      .post(`${base_url.api2}/updateEnquiryByEnquiryId`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        toast.success("scholarship");

        let data = {
          createdOn: datenow,
          enquiryId: Number(params.enquiry_id),
          enquiryWorkflowStepsId: Number(enquiryData?.currentStageId),
          id: 0,
          log:
            " updated scholarship " +
            res.data.data.scholarship +
            " - By " +
            isw_user_name,
        };

        axios
          .post(`${base_url.api2}/createLog`, data, {
            headers: {
              Authorization: jwtToken,
            },
          })
          .then((res) => {
            //console.log(res);
          })
          .catch((err) => {
            //console.log(err);
          });
        let logs =
          isw_user_name +
          " updated scholarship of the user (" +
          deleteuser +
          ") " +
          prevscholarship +
          " to " +
          scholarship +
          ".";
        callsaveauditapi(logs, dispatch, navigate);
        props.getEnquiry();
      })
      .catch((err) => {
        handle403error(err);
      });
  };

  const updatetotalnumberofunits = (e) => {
    let data = {
      enquiryId: Number(params.enquiry_id),
      numberOfUnits: Number(e.target[0].value),
      updatedBy: isw_user_name,
      updated: datenow,
    };
    axios
      .post(`${base_url.api2}/updateEnquiryByEnquiryId`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        toast.success("Total Number of Units");

        let data = {
          createdOn: datenow,
          enquiryId: Number(params.enquiry_id),
          enquiryWorkflowStepsId: Number(enquiryData?.currentStageId),
          id: 0,
          log:
            " updated total number of units " +
            res.data.data.numberOfUnits +
            " - By " +
            isw_user_name,
        };

        axios
          .post(`${base_url.api2}/createLog`, data, {
            headers: {
              Authorization: jwtToken,
            },
          })
          .then((res) => {
            //console.log(res);
          })
          .catch((err) => {
            //console.log(err);
          });
        let logs =
          isw_user_name +
          " updated total number of units of the user (" +
          deleteuser +
          ") " +
          prevtotalnumberofunits +
          " to " +
          totalnumberofunits +
          ".";
        callsaveauditapi(logs, dispatch, navigate);
        props.getEnquiry();
      })
      .catch((err) => {
        handle403error(err);
      });
  };

  const updatetotalunitstudy = (e) => {
    let data = {
      enquiryId: Number(params.enquiry_id),
      numberToBeStudied: Number(e.target[0].value),
      updatedBy: isw_user_name,
      updated: datenow,
    };
    axios
      .post(`${base_url.api2}/updateEnquiryByEnquiryId`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        toast.success("Total Unit Study");

        let data = {
          createdOn: datenow,
          enquiryId: Number(params.enquiry_id),
          enquiryWorkflowStepsId: Number(enquiryData?.currentStageId),
          id: 0,
          log:
            " updated total unit to study " +
            res.data.data.numberToBeStudied +
            " - By " +
            isw_user_name,
        };

        axios
          .post(`${base_url.api2}/createLog`, data, {
            headers: {
              Authorization: jwtToken,
            },
          })
          .then((res) => {
            //console.log(res);
          })
          .catch((err) => {
            //console.log(err);
          });
        let logs =
          isw_user_name +
          " updated total unit to study of the user " +
          deleteuser +
          " .";
        callsaveauditapi(logs, dispatch, navigate);
        props.getEnquiry();
      })
      .catch((err) => {
        handle403error(err);
      });
  };

  const [subagentid, setsubagentid] = useState();
  const [newsubagentname, setnewsubagentname] = useState();
  const subagentchange = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("id");
    const optionElementvalue = optionElement.getAttribute("value");
    setsubagentid(optionElementId);
    setsubagent(optionElementvalue);
    setnewsubagentname(optionElementId);
  };
  const [superagentid, setsuperagentid] = useState();
  const [newsuperagentname, setnewsuperagentname] = useState();
  const superagentchange = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("id");
    const optionElementvalue = optionElement.getAttribute("value");
    setsuperagent(optionElementvalue);
    setsuperagentid(optionElementId);
    setnewsuperagentname(optionElementId);
  };

  const updateSubAgent = (e) => {
    let data;
    if (e.target[0].value == "") {
      data = {
        enquiryId: Number(params.enquiry_id),
        subAgentId: null,
        subAgent: "0",
        updatedBy: isw_user_name,
        updated: datenow,
      };
    } else {
      data = {
        enquiryId: Number(params.enquiry_id),
        subAgentId: Number(e.target[0].value),
        subAgent: subagentid,
        updatedBy: isw_user_name,
        updated: datenow,
      };
    }
    //console.log("End Date", data);
    axios
      .post(`${base_url.api2}/updateEnquiryByEnquiryId`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        toast.success("SubAgent Updated");

        let data = {
          createdOn: datenow,
          enquiryId: Number(params.enquiry_id),
          enquiryWorkflowStepsId: Number(enquiryData?.currentStageId),
          id: 0,
          log:
            " updated Sub Agent to " +
            res.data.data.subAgent +
            " - By " +
            isw_user_name,
        };

        axios
          .post(`${base_url.api2}/createLog`, data, {
            headers: {
              Authorization: jwtToken,
            },
          })
          .then((res) => {
            //console.log(res);
          })
          .catch((err) => {
            //console.log(err);
          });
        let logs =
          isw_user_name +
          " updated Sub Agent of the user (" +
          deleteuser +
          ") " +
          prevsubagent +
          " to " +
          newsubagentname +
          ".";
        callsaveauditapi(logs, dispatch, navigate);
        props.getEnquiry();
      })
      .catch((err) => handleerror(err));
  };

  const updateSuperAgent = (e) => {
    let data;
    if (e.target[0].value == "") {
      data = {
        enquiryId: Number(params.enquiry_id),
        superAgentId: null,
        superAgent: "0",
        updatedBy: isw_user_name,
        updated: datenow,
      };
    } else {
      data = {
        enquiryId: Number(params.enquiry_id),
        superAgentId: Number(e.target[0].value),
        superAgent: subagentid,
        updatedBy: isw_user_name,
        updated: datenow,
      };
    }
    axios
      .post(`${base_url.api2}/updateEnquiryByEnquiryId`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        //console.log("SuperAgent", res);
        toast.success("SuperAgent Updated");

        let data = {
          createdOn: datenow,
          enquiryId: Number(params.enquiry_id),
          enquiryWorkflowStepsId: Number(enquiryData?.currentStageId),
          id: 0,
          log:
            " updated Super Agent to " +
            res.data.data.superAgent +
            " - By " +
            isw_user_name,
        };

        axios
          .post(`${base_url.api2}/createLog`, data, {
            headers: {
              Authorization: jwtToken,
            },
          })
          .then((res) => {
            //console.log(res);
          })
          .catch((err) => {
            //console.log(err);
          });
        let logs =
          isw_user_name +
          " updated Super Agent of the user (" +
          deleteuser +
          ") " +
          prevsuperagent +
          " to " +
          newsuperagentname +
          ".";
        callsaveauditapi(logs, dispatch, navigate);
        props.getEnquiry();
      })
      .catch((err) => handleerror(err));
  };

  const handleerror = (err) => {
    if (err.response) {
      toast.error(JSON.stringify(err?.response?.data?.message));
    } else {
      toast.error("Something Went Wrong");
    }
    handle403error(err);
  };

  const gotoWorkload = () => {
    if (props.workflowtyperedux === "Admission") {
      navigate("/admission-enquiry");
    } else if (props.workflowtyperedux === "Migration") {
      navigate("/migration-enquiry");
    } else if (props.workflowtyperedux === "Insurance") {
      navigate("/insurance-enquiry");
    } else if (props.workflowtyperedux === "SkillAssessment") {
      navigate("/skillAssessment-enquiry");
    }
  };
  const gotolistofenquiry = () => {
    if (props.workflowtyperedux === "Admission") {
      dispatch(getworkflowType("Admission"));
    } else if (props.workflowtyperedux === "Migration") {
      dispatch(getworkflowType("Migration"));
    } else if (props.workflowtyperedux === "Insurance") {
      dispatch(getworkflowType("Insurance"));
    } else if (props.workflowtyperedux === "SkillAssessment") {
      dispatch(getworkflowType("SkillAssessment"));
    }
    navigate(
      "/view-enquiry-workflowList/" +
      selected_client_id +
      "/" +
      params.client_name
    );
  };

  const gotoClientList = () => {
    navigate("/client-list");
  };
  const goToPreviousStep = () => {
    const isoDateString = new Date().toISOString();
    const datenow = isoDateString.split(".")[0];
    let itemindex = 0;
    for (let i = 0; i < props.workflowSteps.length; i++) {
      if (props.workflowSteps[i].status === 0) {
        itemindex = i - 1;
        break;
      }
    }
    if (itemindex >= 0) {
      props.handlebackdropopen();
      let data = {
        createdOn: datenow,
        enquiryId: Number(params.enquiry_id),
        enquiryWorkflowStepsId: Number(props.workflowSteps[itemindex].id),
        id: 0,
        log:
          " moved back to " +
          props.workflowSteps[itemindex].workflowStepsName +
          " - By " +
          isw_user_name,
      };
      let workflowCurrentStatus = "";
      //console.log("637 Workflow---->", workFlow);
      try {
        const matchingStep = workFlow.find(
          (step) =>
            step.workflowStepsName ===
            props.workflowSteps[itemindex].workflowStepsName
        );

        if (matchingStep) {
          workflowCurrentStatus = matchingStep.workflowStepsStatus;
          props?.setDisplayStatus(workflowCurrentStatus);
          //console.log("637 Backward workflowstaus,", workflowCurrentStatus);
        }
      } catch (e) {
        //console.log("637 error", e);
      }

      //console.log("637 Backward display", workflowCurrentStatus);

      let data2 = {
        enquiryId: Number(params.enquiry_id),
        currentStage: props.workflowSteps[itemindex].workflowStepsName,
        currentStageId: props.workflowSteps[itemindex].id,
        nextStage: props.workflowSteps[itemindex + 1].workflowStepsName,
        enquiryStatus: workflowCurrentStatus,
      };

      let data3 = {
        id: data.enquiryWorkflowStepsId,
        status: 0,
      };

      axios
        .post(`${base_url.api2}/updateEnquiryByEnquiryId`, data2, {
          headers: {
            Authorization: jwtToken,
          },
        })
        .then((res) => {
          axios
            .post(`${base_url.api2}/createLog`, data, {
              headers: {
                Authorization: jwtToken,
              },
            })
            .then((res) => {
              axios
                .post(
                  `${base_url.api2}/updateEnquiryWorkflowStepsStatusById`,
                  data3,
                  {
                    headers: {
                      "Access-Control-Allow-Origin": "*",
                      Authorization: jwtToken,
                    },
                  }
                )
                .then((res) => {
                  props.getEnquiry();
                })
                .catch((err) => {
                  props.handlebackdropclose(false);
                });
            })
            .catch((err) => {
              props.handlebackdropclose(false);
            });
        })
        .catch((err) => {
          props.handlebackdropclose(false);
        });
    } else {
      toast.error("You are already in step one!! Can't go back");
    }
  };

  const submitpaymentschedule = (data2) => {
    let data = [];
    data2.forEach((element, index) => {
      data.push({
        amount: element.amount,
        commissionPercentage: element.commissionPercentage,
        dueDate: moment(element.dueDate).format("YYYY-MM-DDThh:mm:ss"),
        enquiryId: element.enquiryId,
        id: 0,
        orderStatus: Number(index),
        paidStatus: element.paidStatus,
        semester: element.semester,
        clientId: Number(ClientId),
      });
    });
    //console.log(data);
    axios
      .post(`${base_url.api2}/updateEnquirySemesterByEnquiryId`, data, {
        headers: {
          Authorization: jwtToken,
          enquiryId: params.enquiry_id,
        },
      })
      .then((res) => {
        toast.success("Create Payment Schedule");
        setpaymentschedulemodal(false);
      });
  };

  const getSemesterData = () => {
    axios
      .get(`${base_url.api2}/getEnquirySemesterByEnquiryId`, {
        headers: {
          Authorization: jwtToken,
          enquiryId: Number(params.enquiry_id),
        },
      })
      .then((res) => {
        //console.log(res.data.data.content);
        let semesterData = res.data.data.content;
        let semesterDataToShow = [];
        for (let i = 0; i < semesterData.length; i++) {
          let data = {
            amount: Number(semesterData[i].amount),
            commissionPercentage: Number(semesterData[i].commissionPercentage),
            dueDate: moment(semesterData[i].dueDate).format(
              "YYYY-MM-DDThh:mm:ss"
            ),
            enquiryId: Number(semesterData[i].enquiryId),
            id: Number(semesterData[i].id),
            paidStatus: semesterData[i].paidStatus,
            semester: semesterData[i].semester,
          };
          semesterDataToShow.push(data);
        }
        setpaymentschedulelines(semesterDataToShow);
        setpaymentschedulemodal(true);
      })
      .catch((err) => {
        if (props.coursedetail?.commission) {
          if (props.content?.numberOfUnits > 0) {
            setpaymentschedulelines([
              {
                amount:
                  (Number(props.coursedetail?.fees) +
                    Number(props.coursedetail?.appFees)) /
                  props.content?.numberOfUnits,
                commissionPercentage: props.coursedetail?.commission,
                dueDate: datenow,
                enquiryId: Number(params.enquiry_id),
                id: 0,
                paidStatus: false,
                semester: "",
              },
            ]);
          } else {
            setpaymentschedulelines([
              {
                amount: 0,
                commissionPercentage: props.coursedetail?.commission,
                dueDate: datenow,
                enquiryId: Number(params.enquiry_id),
                id: 0,
                paidStatus: false,
                semester: "",
              },
            ]);
          }
        } else {
          if (props.content?.numberOfUnits > 0) {
            setpaymentschedulelines([
              {
                amount:
                  (Number(props.coursedetail?.fees) +
                    Number(props.coursedetail?.appFees)) /
                  props.content?.numberOfUnits,
                commissionPercentage: props.content?.commissionPercentage,
                dueDate: datenow,
                enquiryId: Number(params.enquiry_id),
                id: 0,
                paidStatus: false,
                semester: "",
              },
            ]);
          } else {
            setpaymentschedulelines([
              {
                amount: 0,
                commissionPercentage: props.content?.commissionPercentage,
                dueDate: datenow,
                enquiryId: Number(params.enquiry_id),
                id: 0,
                paidStatus: false,
                semester: "",
              },
            ]);
          }
        }
        setpaymentschedulemodal(true);
      });
    //console.log(paymentschedulelines);
  };

  const closeModal = () => {
    setAssigneeModal(false);
  };

  const [newConsultant, setConsultant] = useState();
  const [assigneeModal, setAssigneeModal] = React.useState(false);
  const getConsultant = () => {
    axios
      .get(`${base_url.api2}/getUserByRoleId`, {
        headers: {
          Authorization: jwttoken,
          roleId: 4,
          direction: "ASC",
          columnName: "firstName",
        },
      })
      .then(
        (res) => {
          setConsultant(res.data.data);
        },
        (error) => {
          setConsultant();
          toast.error(JSON.stringify(error.response.data.message));
        }
      );
  };

  const [selectedConsultant, setSelectedConsultant] = useState(null);
  const handleConsultant = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const selectedAssigneeId = optionElement.getAttribute("id");
    const selectedcourseName = optionElement.getAttribute("name");
    //console.log(selectedAssigneeId);
    //console.log(selectedcourseName);
    setDefaultConsultant(event.target.value);
    setSelectedConsultant(selectedAssigneeId);
  };

  const updateAssignee = (data2) => {
    props.handlebackdropopen();
    let data = {
      enquiryId: Number(params.enquiry_id),
      consultantId: selectedConsultant,
      consultantName: data2.consultantName,
      updatedBy: isw_user_name,
      updated: datenow,
    };
    axios
      .post(`${base_url.api2}/updateEnquiryByEnquiryId`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        toast.success("Consultant Updated");

        let data = {
          createdOn: datenow,
          enquiryId: Number(params.enquiry_id),
          enquiryWorkflowStepsId: Number(enquiryData?.currentStageId),
          id: 0,
          log:
            " updated Assigee to " +
            data2.consultantName +
            " - By " +
            isw_user_name,
        };

        axios
          .post(`${base_url.api2}/createLog`, data, {
            headers: {
              Authorization: jwtToken,
            },
          })
          .then((res) => {
            //console.log(res);
          })
          .catch((err) => {
            //console.log(err);
          });
        let logs =
          isw_user_name +
          " updated Assigee of Enquiry " +
          params.enquiry_id +
          " of client " +
          deleteuser +
          " to " +
          data2.consultantName +
          " .";
        callsaveauditapi(logs, dispatch, navigate);
        props.getEnquiry();
        setAssigneeModal(false);
      })
      .catch((err) => {
        handle403error(err);
      });
  };

  const [clientDetail1, setclientDetail1] = useState();
  const [clientDetail2, setclientDetail2] = useState();

  const getClientDetails = () => {
    // getApplicationStatus();
    getWorkflowStatus();
    axios
      .get(`${base_url.api3}/getClientByUsername`, {
        headers: {
          Authorization: jwtToken,
          username: params.client_name,
        },
      })
      .then(
        (res) => {
          setclientDetail1(res.data.data.clientDetail);
          setclientDetail2(res.data.data);
        },
        (error) => {
          handle403error(error);
        }
      );
  };

  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            navigate("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };

  return (
    <>
      <div className="dummy2">
        <div className="dummy2_header">
          {/* <div>
          <h5 style={{ color: "#17b978" }}>{props.workflowName}</h5>
        </div> */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flex: "0.9",
            }}
          >
            <div class="progress-wrapper w-100">
              {isNaN(percentage) ? (
                <>
                  <div class="progress w-100">
                    <div
                      class="progress-bar bg-success"
                      role="progressbar"
                      style={{ width: "0%" }}
                    ></div>
                  </div>
                  <p>0% Complete</p>
                </>
              ) : (
                <>
                  <div class="progress w-100">
                    <div
                      class="progress-bar bg-success"
                      role="progressbar"
                      style={{ width: `${percentage}%` }}
                    ></div>
                  </div>
                  <p>{percentage}% Complete</p>
                </>
              )}
            </div>
            {/* {props.content?.enquiryStatus === "Discontinued" ? (
            <Typography
              color="secondary"
              variant="body1"
              className="dummy2_typography"
            >
              <FiberManualRecordIcon fontSize="small" color="secondary" />
              {props.content?.enquiryStatus}
            </Typography>
          ) : (
            <Typography
              color="primary"
              variant="body1"
              className="dummy2_typography"
            >
              <FiberManualRecordIcon fontSize="small" color="primary" />
              {props.content?.enquiryStatus}
            </Typography>
          )} */}
          </div>
          <div style={{ marginRight: "10px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                gap: "10px",
              }}
            >
              <button className="outline-button" onClick={gotolistofenquiry}>
                LIST
              </button>
              <button className="outline-button" onClick={gotoWorkload}>
                WORKLOAD
              </button>
              {props.workflowSteps?.length > 0 ? (
                <>
                  {check1pointpermission(
                    316,
                    permissionData,
                    rolepermission
                  ) ? (
                    <button
                      className={
                        isApplicationClosed
                          ? "step_complete_css"
                          : "outline-button"
                      }
                      onClick={goToPreviousStep}
                      style={{
                        display:
                          props?.displayStatus === "Steps Completed"
                            ? "none"
                            : "block",
                      }}
                      disabled={isApplicationClosed}
                    >
                      BACK TO PREVIOUS STAGE
                    </button>
                  ) : null}
                </>
              ) : null}
              <button className="sent-button" onClick={gotoClientList}>
                CLIENT LIST
              </button>
            </div>
          </div>
        </div>

        <hr />
        <div class="receipt">
          <div
            class="top-header row"
            style={{
              backgroundColor: isApplicationClosed || enquiryStatus == "Closed" ? "#c15f5f" : " #6d7290",
            }}
          >
            <div class="col-md-4">
              <p>
                {props.workflowtyperedux === "Admission" ||
                  props.workflowtyperedux === "SkillAssessment" ? (
                  <>
                    <h3>Course Name :</h3>
                    <p>
                      {props.content?.courseName}
                      {"-"}
                      {props.coursedetail?.cricos}
                    </p>
                  </>
                ) : (
                  <>
                    {props.workflowtyperedux === "Migration" ? (
                      <>
                        <h3>Visa Type</h3>
                        <p>
                          {props.content?.visaType}
                          {/* {clientDetail1?.visaType ? clientDetail1?.visaType : "-"} */}
                        </p>
                      </>
                    ) : (
                      <>
                        {props.workflowtyperedux === "Insurance" ? (
                          <>
                            <h3>Insurance Type</h3>
                            <p>{props.content?.insuranceType}</p>
                          </>
                        ) : null}
                      </>
                    )}
                  </>
                )}
              </p>
            </div>
            <div class="col-md-4">
              <h3>
                {props.workflowtyperedux === "Migration"
                  ? "Migration Provider"
                  : "Provider"}
              </h3>
              <p>{props.content?.institutionName}</p>
            </div>
            <div class="col-md-4">
              <h3>
                {props.workflowtyperedux === "Admission" &&
                  props.content?.branchName
                  ? "Branch"
                  : null}
              </h3>
              <p>{props.content?.branchName}</p>
            </div>
          </div>
          <div class="content">
            <div class="row">
              <div class="col-md-4">
                <h3>Client ID :</h3>
                <p>{selected_client_id}</p>
              </div>
              <div class="col-md-4">
                {props.workflowtyperedux === "Insurance" ? (
                  <h3>Policy No.</h3>
                ) : (
                  <h3>Student Id</h3>
                )}
                <p>{props.content?.externalId}</p>
              </div>
              <div class="col-md-4">
                <h3>Current Stage :</h3>
                <p>{props.content?.currentStage}</p>
              </div>
              <div class="col-md-4">
                <h3>
                  Consultant :
                  <button
                    type="button"
                    style={{
                      border: "none",
                      background: "none",
                      padding: 0,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      getConsultant();
                      setAssigneeModal(true);
                    }}
                  >
                    <img
                      src={EditImage}
                      width="20px"
                      alt="Edit"
                    />
                  </button>
                </h3>
                <Modal
                  isOpen={isApplicationClosed ? false : assigneeModal}
                  onRequestClose={closeModal}
                  style={customStyles}
                  contentLabel="Example Modal"
                >
                  <form onSubmit={handleSubmit(updateAssignee)}>
                    <div className="form-inner-admin">
                      <h2
                        id="updateConsultant"
                        style={{
                          fontWeight: "bolder",
                          marginLeft: "10px",
                          fontSize: "23px",
                        }}
                      >
                        Update Consultant
                      </h2>
                      <div className="form-group">
                        <Row>
                          <Col md={12}>
                            <select
                              className={classes.selectdrop}
                              style={{ marginTop: "10px", width: "100%" }}
                              onInput={handleConsultant}
                              name="consultantName"
                              value={defaultConsultant}
                              ref={register({ required: true })}
                            >
                              <option value="">- Select Consultant -</option>
                              {newConsultant?.map((cuntitem) => (
                                <option
                                  key={cuntitem.id}
                                  id={cuntitem.id}
                                  name={
                                    cuntitem.firstName + " " + cuntitem.lastName
                                  }
                                  value={`${cuntitem.firstName + " " + cuntitem.lastName
                                    } ( ${cuntitem.email})`}
                                // value={cuntitem.firstName + " " + cuntitem.lastName}
                                >
                                  {cuntitem.firstName + " " + cuntitem.lastName}
                                </option>
                              ))}
                            </select>
                            {errors.consultantName && (
                              <p className="errormessage">
                                Consultant Name is Required
                              </p>
                            )}
                          </Col>
                        </Row>
                      </div>
                    </div>
                    <div className="d-flex justify-content-end mt-2">
                      <Button
                        color="secondary"
                        variant="outlined"
                        onClick={closeModal}
                      >
                        Cancel
                      </Button>
                      <button
                        type="submit"
                        className="btn listing_addbutton ml-1"
                      >
                        Update
                      </button>
                    </div>
                  </form>
                </Modal>
                <p>{props.content?.consultantName}</p>
              </div>
              <div class="col-md-4">
                <h3>Created :</h3>
                <p>{moment(props.content?.created).format("DD-MMM-YY")}</p>
              </div>
              <div class="col-md-4">
                <h3>Updated :</h3>
                <p>{moment(props.content?.updated).format("DD-MMM-YY")}</p>
              </div>
            </div>
          </div>

          <hr style={{ borderTopWidth: "2.2px" }} />
          <div className="row">
            <div className="col-md-4">
              {props.workflowtyperedux === "Insurance" ||
                props.workflowtyperedux === "SkillAssessment" ||
                props.workflowtyperedux === "Migration" ? null : (
                <div className="summary">
                  <h3>Summary</h3>
                  <>
                    {/* {enquiryData?.map((p,index) => ( */}
                    <ul>
                      {enquiryData?.fees != null || coursefees !== "" ? (
                        <li>
                          <span>Course Fees :</span>{" "}
                          <strong>{enquiryData?.fees || "NA"}</strong>
                        </li>
                      ) : (
                        <li>
                          <span>Course Fees :</span> <strong> NA</strong>
                        </li>
                      )}

                      {enquiryData?.appFees != null ||
                        applicationfees !== "" ? (
                        <li>
                          <span>Application Fees :</span>{" "}
                          <strong>{enquiryData?.appFees || "NA"}</strong>
                        </li>
                      ) : (
                        <li>
                          {/* <span>Application Fees :</span> <strong>{props.coursedetail?.appFees || "NA"}</strong> */}
                          <span>Application Fees :</span> <strong>NA</strong>
                        </li>
                      )}

                      {enquiryData?.materialFee != null ||
                        materialFee !== "" ? (
                        <li>
                          <span>Material Fees :</span>{" "}
                          <strong>{enquiryData?.materialFee || "NA"}</strong>
                        </li>
                      ) : (
                        <li>
                          {/* <span>Material Fees :</span> <strong>{props.coursedetail?.materialFee || "NA"}</strong> */}
                          <span>Material Fees :</span> <strong>NA</strong>
                        </li>
                      )}

                      {enquiryData?.otherFee != null || otherFee !== "" ? (
                        <li>
                          <span>Other Fees :</span>{" "}
                          <strong>{enquiryData?.otherFee || "NA"}</strong>
                        </li>
                      ) : (
                        <li>
                          {/* <span>Other Fees :</span> <strong>{props.coursedetail?.otherFee || "NA"}</strong> */}
                          <span>Other Fees :</span> <strong>NA</strong>
                        </li>
                      )}

                      {(enquiryData?.appFees && enquiryData?.fees) !== null ? (
                        <li>
                          <span style={{ fontWeight: "bold" }}>
                            Total Fees :
                          </span>{" "}
                          <strong>
                            {Number(enquiryData?.fees) +
                              Number(enquiryData?.appFees) +
                              Number(enquiryData?.materialFee) +
                              Number(enquiryData?.otherFee)}
                          </strong>
                        </li>
                      ) : (enquiryData?.appFees && enquiryData?.fees) ==
                        null ? (
                        <li>
                          <span style={{ fontWeight: "bold" }}>
                            Total Fees :
                          </span>{" "}
                          <strong>
                            {Number(enquiryData.coursedetail?.fees) +
                              Number(enquiryData.coursedetail?.appFees) +
                              Number(enquiryData?.materialFee) +
                              Number(enquiryData?.otherFee)}
                          </strong>
                        </li>
                      ) : enquiryData?.appFees != null &&
                        enquiryData?.fees == null ? (
                        <li>
                          <span style={{ fontWeight: "bold" }}>
                            Total Fees :
                          </span>{" "}
                          <strong>
                            {Number(enquiryData?.appFees) +
                              Number(enquiryData.coursedetail?.fees) +
                              Number(enquiryData?.materialFee) +
                              Number(enquiryData?.otherFee)}
                          </strong>
                        </li>
                      ) : enquiryData?.appFees == null &&
                        enquiryData?.fees != null ? (
                        <li>
                          <span style={{ fontWeight: "bold" }}>
                            Total Fees :
                          </span>{" "}
                          <strong>
                            {Number(enquiryData?.fees) +
                              Number(enquiryData.coursedetail?.appFees) +
                              Number(enquiryData?.materialFee) +
                              Number(enquiryData?.otherFee)}
                          </strong>
                        </li>
                      ) : (
                        <li>
                          <span style={{ fontWeight: "bold" }}>
                            Total Fees :
                          </span>
                          <strong>{Number(enquiryData?.fees)}</strong>
                        </li>
                      )}
                      <hr style={{ borderTopWidth: "2.5px" }} />

                      <li>
                        <span>Discount :</span>{" "}
                        <strong>{enquiryData?.discount}</strong>
                      </li>
                      <li>
                        <span>Scholarship :</span>{" "}
                        <strong>{enquiryData?.scholarship}</strong>
                      </li>
                      <hr style={{ borderTopWidth: "1.5px" }} />
                      {/* {((enquiryData?.numberToBeStudied && enquiryData?.fees) !== null) ? (
                        <li>
                          <span style={{fontWeight:"bold"}}>Net Fees :</span> <strong>
                            {((Number(enquiryData?.fees) +
                              Number(enquiryData?.numberToBeStudied))-(Number(enquiryData?.discount)))|| "NA"}</strong>
                        </li>
                      ) : ((enquiryData?.numberToBeStudied && enquiryData?.fees) == null) ? (
                        <li>
                          <span style={{fontWeight:"bold"}}>Net Fees :</span> <strong>
                            {((Number(props.coursedetail?.fees) -
                              Number(props.coursedetail?.numberToBeStudied))-(Number(enquiryData?.discount)))|| "NA"}</strong>
                        </li>
                      ) : (enquiryData?.numberToBeStudied != null && enquiryData?.fees == null) ? (
                        <li>
                          <span style={{fontWeight:"bold"}}>Net Fees :</span> <strong>
                            {(Number(props.coursedetail?.fees)) -(Number(enquiryData?.numberToBeStudied)
                              -(Number(enquiryData?.discount)))|| "NA"}</strong>
                        </li>
                      ) : (enquiryData?.numberToBeStudied == null && enquiryData?.fees != null) ? (
                        <li>
                          <span style={{fontWeight:"bold"}}>Net Fees :</span> <strong>
                            {((Number(enquiryData?.fees) -
                              Number(props.coursedetail?.numberToBeStudied))-(Number(enquiryData?.discount)))|| "NA"}</strong>
                        </li>
                      ) : */}
                      <li>
                        <span style={{ fontWeight: "bold" }}>Net Fees :</span>
                        <strong>
                          {Number(enquiryData?.fees) -
                            (Number(enquiryData?.scholarship) +
                              Number(enquiryData?.discount))}
                        </strong>
                      </li>
                      {/* } */}
                      {/* <li>
                        <span>Net Fees :</span> <strong></strong>
                      </li> */}
                      <hr style={{ borderTopWidth: "1.5px" }} />
                      <li>
                        <span>Total no. of Units :</span>
                        <strong>{enquiryData?.numberOfUnits || "NA"}</strong>
                      </li>
                      <li>
                        <span>Unit exemption :</span>{" "}
                        <strong>{enquiryData?.unitExmtion || "NA"}</strong>
                      </li>
                      <hr />
                      {/* <li>
                        <span style={{fontWeight:"bold"}}>Total Unit Study</span> <strong>{props.content?.numberToBeStudied || "NA"}</strong>
                      </li> */}
                      {(enquiryData?.unitExmtion &&
                        enquiryData?.numberOfUnits) !== null ? (
                        <li>
                          <span style={{ fontWeight: "bold" }}>
                            Total Unit Study :
                          </span>{" "}
                          <strong>
                            {Number(enquiryData?.numberOfUnits) -
                              Number(enquiryData?.unitExmtion)}
                          </strong>
                        </li>
                      ) : (enquiryData?.unitExmtion &&
                        enquiryData?.numberOfUnits) == null ? (
                        <li>
                          <span style={{ fontWeight: "bold" }}>
                            Total Unit Study :
                          </span>{" "}
                          <strong>
                            {Number(enquiryData?.numberOfUnits) -
                              Number(enquiryData?.unitExmtion)}
                          </strong>
                        </li>
                      ) : enquiryData?.unitExmtion != null &&
                        enquiryData?.numberOfUnits == null ? (
                        <li>
                          <span style={{ fontWeight: "bold" }}>
                            Total Unit Study :
                          </span>{" "}
                          <strong>
                            {Number(enquiryData?.unitExmtion) -
                              Number(enquiryData?.numberOfUnits)}
                          </strong>
                        </li>
                      ) : enquiryData?.unitExmtion == null &&
                        enquiryData?.numberOfUnits != null ? (
                        <li>
                          <span style={{ fontWeight: "bold" }}>
                            Total Unit Study :
                          </span>{" "}
                          <strong>
                            {Number(enquiryData?.numberOfUnits) +
                              Number(enquiryData?.unitExmtion)}
                          </strong>
                        </li>
                      ) : (
                        <li>
                          <span style={{ fontWeight: "bold" }}>
                            Total Unit Study :
                          </span>
                          <strong>
                            {Number(enquiryData?.numberToBeStudied)}
                          </strong>
                        </li>
                      )}
                    </ul>
                    {/* ))} */}
                  </>
                </div>
              )}
              <div className="summary">
                {props.workflowtyperedux === "SkillAssessment" ? (
                  <>
                    <div
                      className="dummy2_middlecontent2header"
                      style={{ textAlign: "left" }}
                    >
                      <label>Type of Assessment</label>
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          updateAssessment(e);
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <select
                            value={currentAssessment}
                            className="form-control"
                            style={{ backgroundColor: "#f5f5f9" }}
                            onChange={(e) =>
                              setCurrentAssessment(e.target.value)
                            }
                            name="currentAssessment"
                          >
                            <option value="">
                              - Select Type of Assessment -
                            </option>
                            <option value="FullSkillAssessment">
                              Full Skills Assessment
                            </option>
                            <option value="ProvisionalAssessment">
                              Provisional Assessment
                            </option>
                            <option value="EmploymentAssessment">
                              Employment Assessment
                            </option>
                            <option value="Review">Review</option>
                          </select>
                          <button
                            className="outline-button"
                            type="submit"
                            disabled={isApplicationClosed}
                          >
                            Update
                          </button>
                        </div>
                      </form>
                    </div>
                    <br />
                  </>
                ) : null}

                {check1pointpermission(326, permissionData, rolepermission) ? (
                  <>
                    <div
                      className="dummy2_middlecontent2header"
                      style={{ textAlign: "left" }}
                    >
                      <label>Status</label>
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          updateEnquiryStatus(e);
                        }}
                      >
                        {props.workflowtyperedux === "Admission" ? (
                          <>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <select
                                value={enquiryStatus}
                                style={{ backgroundColor: "#f5f5f9" }}
                                className="form-control"
                                onChange={(e) =>
                                  setenquiryStatus(e.target.value)
                                }
                                name="enquiryStatus"
                              >
                                {workflowStatus?.map((item, index) => (
                                  <option
                                    key={index}
                                    value={item.workflow_steps_status}
                                  >
                                    {item.workflow_steps_status}
                                  </option>
                                ))}
                              </select>
                              <button
                                className="outline-button"
                                type="submit"
                                disabled={isApplicationClosed}
                              >
                                Update
                              </button>
                            </div>
                          </>
                        ) : props.workflowtyperedux === "Migration" ? (
                          <>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <select
                                value={enquiryStatus}
                                style={{ backgroundColor: "#f5f5f9" }}
                                className="form-control"
                                onChange={(e) =>
                                  setenquiryStatus(e.target.value)
                                }
                                name="enquiryStatus"
                              >
                                {workflowStatus?.map((item, index) => (
                                  <option
                                    key={index}
                                    value={item.workflow_steps_status}
                                  >
                                    {item.workflow_steps_status}
                                  </option>
                                ))}
                              </select>
                              <button
                                className="outline-button"
                                type="submit"
                                disabled={isApplicationClosed}
                              >
                                Update
                              </button>
                            </div>
                          </>
                        ) : props.workflowtyperedux === "SkillAssessment" ? (
                          <>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <select
                                value={enquiryStatus}
                                style={{ backgroundColor: "#f5f5f9" }}
                                className="form-control"
                                onChange={(e) =>
                                  setenquiryStatus(e.target.value)
                                }
                                name="enquiryStatus"
                              >
                                {workflowStatus?.map((item, index) => (
                                  <option
                                    key={index}
                                    value={item.workflow_steps_status}
                                  >
                                    {item.workflow_steps_status}
                                  </option>
                                ))}
                              </select>
                              <button
                                className="outline-button"
                                type="submit"
                                disabled={isApplicationClosed}
                              >
                                Update
                              </button>
                            </div>
                          </>
                        ) : props.workflowtyperedux === "Insurance" ? (
                          <>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <select
                                value={enquiryStatus}
                                style={{ backgroundColor: "#f5f5f9" }}
                                className="form-control"
                                onChange={(e) =>
                                  setenquiryStatus(e.target.value)
                                }
                                name="enquiryStatus"
                              >
                                {workflowStatus?.map((item, index) => (
                                  <option
                                    key={index}
                                    value={item.workflow_steps_status}
                                  >
                                    {item.workflow_steps_status}
                                  </option>
                                ))}
                              </select>
                              <button
                                className="outline-button"
                                type="submit"
                                disabled={isApplicationClosed}
                              >
                                Update
                              </button>
                            </div>
                          </>
                        ) : null}
                      </form>
                    </div>
                    <br />
                  </>
                ) : null}

                {enquiryStatus === "DiscontinueProcessing" ||
                  enquiryStatus === "DiscontinueComplete" ? (
                  <>
                    <div
                      className="dummy2_middlecontent2header"
                      style={{ textAlign: "left" }}
                    >
                      <label>Discontinue Reason</label>
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <input
                          type="text"
                          value={discontReason}
                          style={{ backgroundColor: "#f5f5f9" }}
                          name="enquiryDiscontinueReason"
                          onChange={(e) => setDiscontReason(e.target.value)}
                          className="form-control"
                          ref={register({ required: true })}
                        />
                        {/* {errors.enquiryDiscontinueReason && (
                          <p className="errormessage">Reason is Required</p>
                         )} */}
                      </div>
                    </div>
                    <br />
                  </>
                ) : null}

                {check1pointpermission(327, permissionData, rolepermission) ? (
                  <>
                    <div
                      className="dummy2_middlecontent2header"
                      style={{ textAlign: "left" }}
                    >
                      {props.workflowtyperedux === "Insurance" ? (
                        <label>Policy No.</label>
                      ) : props.workflowtyperedux === "Admission" ||
                        props.workflowtyperedux === "SkillAssessment" ? (
                        <label>Student Id</label>
                      ) : null}

                      {props.workflowtyperedux === "Migration" ? null : (
                        <form
                          onSubmit={(e) => {
                            e.preventDefault();
                            updateExternalId(e);
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <input
                              type="text"
                              value={externalId}
                              style={{ backgroundColor: "#f5f5f9" }}
                              name="externalid"
                              onChange={(e) => setexternalId(e.target.value)}
                              className="form-control"
                            />
                            <button
                              className="outline-button"
                              type="submit"
                              disabled={isApplicationClosed}
                            >
                              Update
                            </button>
                          </div>
                        </form>
                      )}
                    </div>
                    <br />
                  </>
                ) : null}

                {props.workflowtyperedux === "Insurance" ? (
                  <>
                    {check1pointpermission(
                      330,
                      permissionData,
                      rolepermission
                    ) ? (
                      <>
                        <div
                          className="dummy2_middlecontent2header"
                          style={{ textAlign: "left" }}
                        >
                          <label>Policy Start Date</label>
                          <form
                            onSubmit={(e) => {
                              e.preventDefault();
                              updatepolicystartdate(e);
                            }}
                          >
                            <div
                              style={{
                                justifyContent: "flex-end",
                                display: "flex",
                              }}
                            >
                              <div style={{ width: "100%" }}>
                                <DatePicker
                                  selected={
                                    policystartdate
                                      ? new Date(policystartdate)
                                      : null
                                  }
                                  onChange={(date) => setpolicystartdate(date)}
                                  dateFormat="dd-MM-yyyy"
                                  showYearDropdown
                                  dropdownMode="select"
                                  placeholderText="Select Date"
                                />
                              </div>
                              <button
                                className="outline-button"
                                type="submit"
                                disabled={isApplicationClosed}
                              >
                                Update
                              </button>
                            </div>
                          </form>
                        </div>
                        <br />
                      </>
                    ) : null}

                    {check1pointpermission(
                      331,
                      permissionData,
                      rolepermission
                    ) ? (
                      <>
                        <div
                          className="dummy2_middlecontent2header"
                          style={{ textAlign: "left" }}
                        >
                          <label>Policy End Date</label>
                          <form
                            onSubmit={(e) => {
                              e.preventDefault();
                              updatepolicyenddate(e);
                            }}
                          >
                            <div
                              style={{
                                justifyContent: "flex-end",
                                display: "flex",
                              }}
                            >
                              <div style={{ width: "100%" }}>
                                <DatePicker
                                  selected={
                                    policyenddate
                                      ? new Date(policyenddate)
                                      : null
                                  }
                                  onChange={(date) => setpolicyenddate(date)}
                                  dateFormat="dd-MM-yyyy"
                                  showYearDropdown
                                  dropdownMode="select"
                                  style={{ backgroundColor: "#f5f5f9" }}
                                  placeholderText="Select Date"
                                />
                              </div>
                              <button
                                className="outline-button"
                                type="submit"
                                disabled={isApplicationClosed}
                              >
                                Update
                              </button>
                            </div>
                          </form>
                        </div>
                        <br />
                      </>
                    ) : null}
                  </>
                ) : props.workflowtyperedux === "Admission" ||
                  props.workflowtyperedux === "SkillAssessment" ? (
                  <>
                    {check1pointpermission(
                      328,
                      permissionData,
                      rolepermission
                    ) ? (
                      <>
                        <div
                          className="dummy2_middlecontent2header"
                          style={{ textAlign: "left" }}
                        >
                          <label>Course Start Date</label>
                          <form
                            onSubmit={(e) => {
                              e.preventDefault();
                              updatecoursestartdate(e);
                            }}
                          >
                            <div
                              style={{
                                justifyContent: "flex-end",
                                display: "flex",
                              }}
                            >
                              <div style={{ width: "100%" }}>
                                <DatePicker
                                  selected={
                                    startdate ? new Date(startdate) : null
                                  }
                                  onChange={(date) => setstartdate(date)}
                                  dateFormat="dd-MM-yyyy"
                                  showYearDropdown
                                  style={{ backgroundColor: "#f5f5f9" }}
                                  dropdownMode="select"
                                  placeholderText="Select Date"
                                />
                              </div>
                              <button
                                className="outline-button"
                                type="submit"
                                disabled={isApplicationClosed}
                              >
                                Update
                              </button>
                            </div>
                          </form>
                        </div>
                        <br />
                      </>
                    ) : null}

                    {check1pointpermission(
                      329,
                      permissionData,
                      rolepermission
                    ) ? (
                      <>
                        <div
                          className="dummy2_middlecontent2header mr-0"
                          style={{ textAlign: "left" }}
                        >
                          <label>Course End Date</label>
                          <form
                            onSubmit={(e) => {
                              e.preventDefault();
                              updatecourseenddate(e);
                            }}
                          >
                            <div
                              style={{
                                justifyContent: "flex-end",
                                display: "flex",
                              }}
                            >
                              <div style={{ width: "100%" }}>
                                <DatePicker
                                  selected={enddate ? new Date(enddate) : null}
                                  onChange={(date) => setenddate(date)}
                                  dateFormat="dd-MM-yyyy"
                                  showYearDropdown
                                  style={{ backgroundColor: "#f5f5f9" }}
                                  dropdownMode="select"
                                  placeholderText="Select Date"
                                />
                              </div>
                              <button
                                className="outline-button"
                                type="submit"
                                disabled={isApplicationClosed}
                              >
                                Update
                              </button>
                            </div>
                          </form>
                        </div>
                        <br />
                      </>
                    ) : null}
                  </>
                ) : null}

                {check1pointpermission(333, permissionData, rolepermission) ? (
                  <>
                    <div
                      className="dummy2_middlecontent2header"
                      style={{ textAlign: "left" }}
                    >
                      <label>SubAgent</label>
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          updateSubAgent(e);
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <select
                            value={subagent}
                            className="form-control"
                            onChange={subagentchange}
                            name="subagent"
                            style={{ backgroundColor: "#f5f5f9" }}
                          >
                            <option value="0">- Select SubAgent -</option>
                            {subagentlist?.map((cuntitem) => (
                              <option
                                key={cuntitem.id}
                                id={`${cuntitem.username} (${cuntitem.name})`}
                                value={cuntitem.id}
                              >
                                {cuntitem.name}
                              </option>
                            ))}
                          </select>
                          <button
                            className="outline-button"
                            type="submit"
                            disabled={isApplicationClosed}
                          >
                            Update
                          </button>
                        </div>
                      </form>
                    </div>
                    <br />
                  </>
                ) : null}

                {check1pointpermission(334, permissionData, rolepermission) ? (
                  <div
                    className="dummy2_middlecontent2header"
                    style={{ textAlign: "left" }}
                  >
                    <label>SuperAgent</label>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        updateSuperAgent(e);
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <select
                          value={superagent}
                          className="form-control"
                          name="subagent"
                          onChange={superagentchange}
                          style={{ backgroundColor: "#f5f5f9" }}
                        >
                          <option value="0">- Select SuperAgent -</option>
                          {superagentlist?.map((cuntitem) => (
                            <option
                              key={cuntitem.id}
                              id={`${cuntitem.username} (${cuntitem.name})`}
                              value={cuntitem.id}
                            >
                              {cuntitem.name}
                            </option>
                          ))}
                        </select>
                        <button
                          className="outline-button"
                          type="submit"
                          disabled={isApplicationClosed}
                        >
                          Update
                        </button>
                      </div>
                    </form>
                  </div>
                ) : null}
                <br />
                {props.workflowtyperedux === "Migration" ||
                  props.workflowtyperedux === "SkillAssessment" ||
                  props.workflowtyperedux === "Admission" ? (
                  <>
                    {check1pointpermission(
                      335,
                      permissionData,
                      rolepermission
                    ) ? (
                      <div
                        className="dummy2_middlecontent2header"
                        style={{ textAlign: "left" }}
                      >
                        <label>VisaExpiry Date</label>
                        <form
                          onSubmit={(e) => {
                            e.preventDefault();
                            updatevisaexpirydate(e);
                          }}
                        >
                          <div
                            style={{
                              justifyContent: "flex-end",
                              display: "flex",
                            }}
                          >
                            <div style={{ width: "100%" }}>
                              <DatePicker
                                selected={
                                  visaExpiry ? new Date(visaExpiry) : null
                                }
                                onChange={(date) => setvisaExpiry(date)}
                                dateFormat="dd-MM-yyyy"
                                showYearDropdown
                                dropdownMode="select"
                                style={{ backgroundColor: "#f5f5f9" }}
                                placeholderText="Select Date"
                              />
                            </div>
                            <button
                              className="outline-button"
                              type="submit"
                              disabled={isApplicationClosed}
                            >
                              Update
                            </button>
                          </div>
                        </form>
                      </div>
                    ) : null}
                  </>
                ) : null}
                <br />
                {check1pointpermission(335, permissionData, rolepermission) ? (
                  <div
                    className="dummy2_middlecontent2header"
                    style={{ textAlign: "left" }}
                  >
                    <label>FollowUp Date</label>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        updatefollowupdate(e);
                      }}
                    >
                      <div
                        style={{ justifyContent: "flex-end", display: "flex" }}
                      >
                        <div style={{ width: "100%" }}>
                          <DatePicker
                            selected={followup ? new Date(followup) : null}
                            onChange={(date) => setfollowup(date)}
                            dateFormat="dd-MM-yyyy"
                            showYearDropdown
                            dropdownMode="select"
                            style={{ backgroundColor: "#f5f5f9" }}
                            placeholderText="Select Date"
                            minDate={new Date()}
                          />
                        </div>
                        <button
                          className="outline-button"
                          type="submit"
                          disabled={isApplicationClosed}
                        >
                          Update
                        </button>
                      </div>
                    </form>
                  </div>
                ) : null}
                <br />
                {check1pointpermission(335, permissionData, rolepermission) ? (
                  <div
                    className="dummy2_middlecontent2header"
                    style={{ textAlign: "left" }}
                  >
                    <label>Deadline Date</label>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        updatedeadlinedate(e);
                      }}
                    >
                      <div
                        style={{ justifyContent: "flex-end", display: "flex" }}
                      >
                        <div style={{ width: "100%" }}>
                          <DatePicker
                            selected={deadline ? new Date(deadline) : null}
                            onChange={(date) => setdeadline(date)}
                            dateFormat="dd-MM-yyyy"
                            showYearDropdown
                            dropdownMode="select"
                            style={{ backgroundColor: "#f5f5f9" }}
                            placeholderText="Select Date"
                          />
                        </div>
                        <button
                          className="outline-button"
                          type="submit"
                          disabled={isApplicationClosed}
                        >
                          Update
                        </button>
                      </div>
                    </form>
                  </div>
                ) : null}
                <br />
                {check1pointpermission(336, permissionData, rolepermission) ? (
                  <div
                    className="dummy2_middlecontent2header"
                    style={{ textAlign: "left" }}
                  >
                    <label>Remarks</label>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        updateremarks(e);
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <textarea
                          type="text"
                          value={remarks}
                          name="remarks"
                          style={{ backgroundColor: "#f5f5f9" }}
                          onChange={(e) => setremarks(e.target.value)}
                          className="form-control"
                        />
                        <button
                          className="outline-button"
                          type="submit"
                          style={{ height: "45px" }}
                          disabled={isApplicationClosed}
                        >
                          Update
                        </button>
                      </div>
                    </form>
                  </div>
                ) : null}
                <br />
                {props.workflowtyperedux === "Insurance" ||
                  props.workflowtyperedux === "SkillAssessment" ||
                  props.workflowtyperedux === "Admission" ? (
                  <>
                    {check1pointpermission(
                      337,
                      permissionData,
                      rolepermission
                    ) ? (
                      <div
                        className="dummy2_middlecontent2header"
                        style={{ textAlign: "left" }}
                      >
                        <label>Applied Intakes</label>
                        <form
                          onSubmit={(e) => {
                            e.preventDefault();
                            updateintakeenquiry(e);
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            {/* <select
                              className="form-control"
                              name="intake"
                              value={intakevalue}
                              style={{ backgroundColor: "#f5f5f9" }}
                              onChange={(e) => setIntakeValue(e.target.value)}
                            >
                              <option value="">- Select Intake -</option>
                              {allintake?.map((cuntitem, index) => (
                                <option
                                  key={cuntitem.id}
                                  id={cuntitem.id}
                                  value={cuntitem.name}
                                >
                                  {cuntitem.createdIntake}
                                </option>
                              ))}
                            </select> */}

                            <DatePicker
                              selected={intakevalue ? new Date(intakevalue) : null}
                              onChange={(date) => {
                                setIntakeValue(date);
                                //console.log(moment(date).format('MMM-yyyy'));
                              }}
                              dateFormat="MMM-yyyy"
                              showMonthYearPicker
                              dropdownMode="select"
                              placeholderText="Select Date"
                            />

                            <button
                              className="outline-button"
                              type="submit"
                              disabled={isApplicationClosed}
                            >
                              Update
                            </button>
                          </div>
                        </form>
                      </div>
                    ) : null}
                  </>
                ) : null}

                <br />
                {props.workflowtyperedux === "Insurance" ||
                  props.workflowtyperedux === "SkillAssessment" ||
                  props.workflowtyperedux === "Migration" ? null : (
                  <>
                    {check1pointpermission(
                      332,
                      permissionData,
                      rolepermission
                    ) ? (
                      <>
                        <div
                          className="dummy2_middlecontent2header"
                          style={{ textAlign: "left" }}
                        >
                          <label>Course Fees</label>
                          <form
                            onSubmit={(e) => {
                              e.preventDefault();
                              updatecoursefees(e);
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <input
                                type="number"
                                value={coursefees}
                                name="courseenddate"
                                style={{ backgroundColor: "#f5f5f9" }}
                                onChange={(e) => setcoursefees(e.target.value)}
                                className="form-control"
                              />
                              <button
                                className="outline-button"
                                type="submit"
                                disabled={isApplicationClosed}
                              >
                                Update
                              </button>
                            </div>
                          </form>
                        </div>
                        <br />
                        <div
                          className="dummy2_middlecontent2header"
                          style={{ textAlign: "left" }}
                        >
                          <label>Application Fees</label>
                          <form
                            onSubmit={(e) => {
                              e.preventDefault();
                              updateapplicationfees(e);
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <input
                                type="number"
                                value={applicationfees}
                                name="appFees"
                                style={{ backgroundColor: "#f5f5f9" }}
                                onChange={(e) =>
                                  setapplicationfees(e.target.value)
                                }
                                className="form-control"
                              />
                              <button
                                className="outline-button"
                                type="submit"
                                disabled={isApplicationClosed}
                              >
                                Update
                              </button>
                            </div>
                          </form>
                        </div>
                        <br />
                        <div
                          className="dummy2_middlecontent2header"
                          style={{ textAlign: "left" }}
                        >
                          <label>Material Fees</label>
                          <form
                            onSubmit={(e) => {
                              e.preventDefault();
                              updatematerialfees(e);
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <input
                                type="number"
                                value={materialFee}
                                name="materialFee"
                                style={{ backgroundColor: "#f5f5f9" }}
                                onChange={(e) => setmaterialFee(e.target.value)}
                                className="form-control"
                              />
                              <button
                                className="outline-button"
                                type="submit"
                                disabled={isApplicationClosed}
                              >
                                Update
                              </button>
                            </div>
                          </form>
                        </div>
                        <br />
                        <div
                          className="dummy2_middlecontent2header"
                          style={{ textAlign: "left" }}
                        >
                          <label>other Fees</label>
                          <form
                            onSubmit={(e) => {
                              e.preventDefault();
                              updateotherfees(e);
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <input
                                type="number"
                                value={otherFee}
                                name="otherFee"
                                style={{ backgroundColor: "#f5f5f9" }}
                                onChange={(e) => setotherFee(e.target.value)}
                                className="form-control"
                              />
                              <button
                                className="outline-button"
                                type="submit"
                                disabled={isApplicationClosed}
                              >
                                Update
                              </button>
                            </div>
                          </form>
                        </div>
                        <br />
                        <div
                          className="dummy2_middlecontent2header"
                          style={{ textAlign: "left" }}
                        >
                          <label>Discount</label>
                          <form
                            onSubmit={(e) => {
                              e.preventDefault();
                              updatediscount(e);
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <input
                                type="number"
                                value={discount}
                                name="courseenddate"
                                style={{ backgroundColor: "#f5f5f9" }}
                                onChange={(e) => setdiscount(e.target.value)}
                                className="form-control"
                              />
                              <button
                                className="outline-button"
                                type="submit"
                                disabled={isApplicationClosed}
                              >
                                Update
                              </button>
                            </div>
                          </form>
                        </div>
                        <br />
                        <div
                          className="dummy2_middlecontent2header"
                          style={{ textAlign: "left" }}
                        >
                          <label>Scholarship</label>
                          <form
                            onSubmit={(e) => {
                              e.preventDefault();
                              updatescholarship(e);
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <input
                                type="number"
                                value={scholarship}
                                name="courseenddate"
                                style={{ backgroundColor: "#f5f5f9" }}
                                onChange={(e) => setscholarship(e.target.value)}
                                className="form-control"
                              />
                              <button
                                className="outline-button"
                                type="submit"
                                disabled={isApplicationClosed}
                              >
                                Update
                              </button>
                            </div>
                          </form>
                        </div>
                        <br />
                        <div
                          className="dummy2_middlecontent2header"
                          style={{ textAlign: "left" }}
                        >
                          <label>Total Number of Units</label>
                          <form
                            onSubmit={(e) => {
                              e.preventDefault();
                              updatetotalnumberofunits(e);
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <input
                                type="number"
                                value={totalnumberofunits}
                                name="courseenddate"
                                style={{ backgroundColor: "#f5f5f9" }}
                                onChange={(e) =>
                                  settotalnumberofunits(e.target.value)
                                }
                                className="form-control"
                              />
                              <button
                                className="outline-button"
                                type="submit"
                                disabled={isApplicationClosed}
                              >
                                Update
                              </button>
                            </div>
                          </form>
                        </div>
                      </>
                    ) : null}
                    <br />
                    {props.workflowtyperedux === "Insurance" ||
                      props.workflowtyperedux === "SkillAssessment" ? null : (
                      <>
                        {check1pointpermission(
                          332,
                          permissionData,
                          rolepermission
                        ) ? (
                          <div
                            className="dummy2_middlecontent2header"
                            style={{ textAlign: "left" }}
                          >
                            <label>Unit Exemption</label>
                            <form
                              onSubmit={(e) => {
                                e.preventDefault();
                                updateunitexemtion(e);
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <input
                                  type="number"
                                  value={unitexemtion}
                                  name="courseenddate"
                                  style={{ backgroundColor: "#f5f5f9" }}
                                  onChange={(e) =>
                                    setunitexemtion(e.target.value)
                                  }
                                  className="form-control"
                                />
                                <button
                                  className="outline-button"
                                  type="submit"
                                  disabled={isApplicationClosed}
                                >
                                  Update
                                </button>
                              </div>
                            </form>
                          </div>
                        ) : null}
                      </>
                    )}
                    <br />
                    <hr />
                    {props.workflowtyperedux === "Insurance" ||
                      props.workflowtyperedux === "SkillAssessment" ? null : (
                      <>
                        {check1pointpermission(
                          338,
                          permissionData,
                          rolepermission
                        ) ? (
                          <button
                            className="sent-button"
                            type="submit"
                            style={{ width: "300px", height: "57px" }}
                            onClick={() => getSemesterData()}
                            disabled={isApplicationClosed}
                          >
                            Payment Schedule
                          </button>
                        ) : null}
                        <Modal
                          isOpen={
                            isApplicationClosed ? false : paymentschedulemodal
                          }
                          onRequestClose={() => setpaymentschedulemodal(false)}
                          style={customStyles}
                          contentLabel="Example Modal"
                        >
                          <h5>Payment Schedule</h5>
                          <hr />
                          <PaymentSchedule
                            data={paymentschedulelines}
                            datenow={datenow}
                            params={params}
                            setpaymentschedulemodal={setpaymentschedulemodal}
                            submitpaymentschedule={submitpaymentschedule}
                          />
                        </Modal>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
            {props.workflowSteps == null ? (
              <div className="col-md-8">
                <Typography variant="h5" color="primary">
                  No Workflow Assigned
                </Typography>
              </div>
            ) : (
              <div className="col-md-8">
                <AppBar position="static">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                  >
                    <Tab
                      label="ACTIVITIES"
                      aria-label="phone"
                      sx={{ fontWeight: "bold" }}
                      {...a11yProps(0)}
                    />

                    <Tab
                      label="ATTACHMENTS"
                      sx={{ fontWeight: "bold" }}
                      aria-label="favorite"
                      {...a11yProps(1)}
                    />
                    {props.workflowtyperedux === "Admission" ? (
                      <Tab
                        label="COMMENTS"
                        sx={{ fontWeight: "bold" }}
                        aria-label="person"
                        {...a11yProps(2)}
                      />
                    ) : (
                      <Tab
                        label="NOTES"
                        sx={{ fontWeight: "bold" }}
                        aria-label="person"
                        {...a11yProps(2)}
                      />
                    )}
                    <Tab
                      label="APPOINTMENTS"
                      sx={{ fontWeight: "bold" }}
                      aria-label="person"
                      {...a11yProps(3)}
                    />
                    <Tab
                      label="LOGS"
                      sx={{ fontWeight: "bold" }}
                      aria-label="person"
                      {...a11yProps(4)}
                    />
                  </Tabs>
                </AppBar>
                <TabPanel
                  value={value}
                  index={0}
                  component={
                    <EnquiryWorkflowStepDetails
                      enquiryId={props?.content?.enquiryId}
                      workflowSteps={props.workflowSteps}
                      workflowStepsArray={props.workflowStepsArray}
                      getEnquiry={props.getEnquiry}
                      getAllNotes={props.getAllNotes}
                      getAttachments={props.getAttachments}
                      workflowtyperedux={props.workflowtyperedux}
                      onStatusChange={handleStatusChange}
                      workFlowDataChange={handleWorkFlowData}
                      displayStatus={props?.displayStatus}
                      getWorlflowCovertClientDetails={
                        props?.getWorlflowCovertClientDetails
                      }
                      getNoOfCount={props?.getNoOfCount}
                      getNoOfProspect={props?.getNoOfProspect}
                      getEnquiryDetails={getEnquiryDetails}
                      setstartdate={setstartdate}
                      setenddate={setenddate}
                      updateCourseDate={updateCourseDate}
                      startdate={startdate}
                      enddate={enddate}
                    />
                  }
                />

                <TabPanel
                  value={value}
                  index={1}
                  component={
                    <Attachments
                      attachments={props.attachments}
                      attachmentPagination={props.attachmentPagination}
                      getAttachments={props.getAttachments}
                    />
                  }
                />
                <TabPanel
                  value={value}
                  index={2}
                  component={
                    <Notes
                      enquiryId={props?.content?.enquiryId}
                      allnotes={props.allnotes}
                      notesdata={props.notesdata}
                      getAllNotes={props.getAllNotes}
                      getpageno={props.getpageno}
                      handlepage={props.handlepage}
                    />
                  }
                />
                <TabPanel
                  value={value}
                  index={3}
                  component={
                    <Appointments
                      enquiryId={props?.content?.enquiryId}
                      allnotes={props.allnotes}
                      notesPagination={props.notesPagination}
                    />
                  }
                />
                <TabPanel
                  value={value}
                  index={4}
                  component={
                    <WorkflowLogs enquiryId={props?.content?.enquiryId} />
                  }
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default EnqyuiryDetails;
