const dummyData = [
  {
    id: 1,
    appointmentDate: "2024-05-25T12:00:00",
    clientId: 1001,
    clientName: "client1@gmail.com",
    counsellingCreatedBy: 101,
    counselorName: "John Doe",
    notes: "Meeting with client",
    enquiryId: 2001,
    enquiryWorkflowStepId: null,
    status: null,
    appointmentDetail: "Meeting to discuss client's requirements.",
  },
  {
    id: 2,
    appointmentDate: "2024-04-09T14:30:00.000+00:00",
    clientId: 1002,
    clientName: "client2@gmail.com",
    counsellingCreatedBy: 102,
    counselorName: "Jane Smith",
    notes: "Follow-up session",
    enquiryId: 2002,
    enquiryWorkflowStepId: null,
    status: null,
    appointmentDetail: "Follow-up session to review progress.",
  },
  // Add more dummy data objects as needed
];

export default dummyData;
