import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  Card,
  CardSubtitle,
  CardText,
  Col,
  Row,
  Button,
  Table,
} from "reactstrap";
import moment from "moment";
import axios from "axios";
import base_url from "./../../api/bootapi";
import { ToastContainer, toast } from "react-toastify";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Pagination from "@mui/material/Pagination";
import Modal from "react-modal";
import "./../css/Responsive.css";
import { useDispatch, useSelector } from "react-redux";
import { gettabvalue, getworkflowType, logout } from "../features/userSlice";
import { useStyles, customStyles } from "../../Utilities/CSSUtilities";

function Enquiry(props) {
  const params = useParams();
  const navigate = useNavigate();
  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  //console.log(jwtToken);
  const username = localStorage.getItem("isw_user_email");
  const { buttonLabel, className } = props;
  const [deleteuser, setDeleteuser] = useState(null);
  //console.log(params.client_id);
  const [pageno, setpageno] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        navigate("/");
      }
    });
    getEnquiry(pageno);
  }, []);

  const createenquiry = () => {
    navigate("/create-enquiry/" + params.client_id + "/" + params.client_name);
  };

  const [getpageno, setgetpageno] = useState();
  const handlepage = (event, value) => {
    setpageno(value - 1);
    getEnquiry(value - 1);
  };

  const [client_enquiry, setClientDetails] = useState(null);
  const getEnquiry = (value1) => {
    axios
      .get(`${base_url.api2}/getEnquiryByClientId`, {
        headers: {
          Authorization: jwtToken,
          clientId: params.client_id,
          page: Number(value1),
          size: 50,
        },
      })
      .then((response) => {
        //console.log(response.data.data);
        setgetpageno(response.data.data.totalPages);
        setClientDetails(response.data.data.content);
        localStorage.setItem(
          "current_client_name",
          response.data.data.content[0].clientUsername
        );
      })
      .catch((error) => {
        toast.error("No Enquiry Present");
        handle403error(error);
      });
  };

  const deleteCurrentUser = (elem) => {
    axios
      .delete(`${base_url.api2}/deleteEnquiryById`, {
        headers: {
          Authorization: jwtToken,
          enquiryId: deleteuser,
        },
      })
      .then((response) => {
        setIsOpen(false);
        toast.success(response.data.message);
        getEnquiry(pageno);
        setClientDetails(null);
      })
      .catch((error) => {
        toast.error(JSON.stringify(error.response.data.message));
        handle403error(error);
      });
  };
  const classes = useStyles();

  const goback = () => {
    // props.navigate.replace("/client-list");
    navigate("/client-list");
  };

  //use letter-----------------------------------------------------------------------------------
  // const [divmodal, setDivModal] = useState(false);
  const showWorkflow = (p, e) => {
    //console.log(p);
    localStorage.setItem("selected_client_username", p.clientUsername);
    localStorage.setItem("selected_client_id", p.clientId);
    localStorage.setItem("selected_institutionId", p.institutionId);
    localStorage.setItem(
      "prev_path",
      "/view-client-enquiry/" + p.clientId + "/" + p.clientUsername
    );
    dispatch(getworkflowType(p.workflowType));
    dispatch(gettabvalue(0));
    navigate({
      pathname:
        "/view-enquiry-workflowList/" +
        p.clientId +
        "/" +
        p.enquiryId +
        "/" +
        p.clientUsername,
      state: p,
    });
  };

  var subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const openModal = (elem) => {
    //console.log(elem.target.id);
    setDeleteuser(elem.target.id);
    setIsOpen(true);
  };

  const afterOpenModal = () => {
    subtitle.style.color = "#f00";
  };

  const closeModal = () => {
    setIsOpen(false);
    setIsOpen3(false);
    setIsOpen2(false);
  };
  const [clientId, setclientId] = useState();
  const [enquiryId, setenquiryId] = useState();
  const [modalIsOpen3, setIsOpen3] = React.useState(false);
  const openAssignWorkflow = (p) => {
    //console.log(p);
    setclientId(p.clientId);
    setenquiryId(p.enquiryId);
    setIsOpen3(true);
  };

  const assignWorkflowSubmit = (e, p) => {
    //console.log("PageNo", pageno);
    let data = {
      clientId: Number(clientId),
      enquiryId: Number(enquiryId),
      enquiryWorkflowId: Number(0),
      workflowId: Number(workflowid),
      workflowType: e.target[0].value,
      workflowName: e.target[1].value,
      status: Number(e.target[2].value),
    };
    axios
      .post(`${base_url.api2}/createEnquiryWorkflow`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        toast.success("Success");

        if (response.data.data.enquiryWorkflowSteps.length) {
          let data = {
            enquiryId: response.data.data.enquiryId,
            currentStageId: response.data.data.enquiryWorkflowSteps[0].id,
          };
          axios
            .post(`${base_url.api2}/updateEnquiryByEnquiryId`, data, {
              headers: {
                Authorization: jwtToken,
              },
            })
            .then((response) => {
              //console.log(response.data.data);
            })
            .catch((error) => {
              //console.log(error);
              handle403error(error);
            });
        } else {
          return null;
        }

        getEnquiry(pageno);
        setIsOpen3(false);
        setIsOpen(false);
      })
      .catch((error) => {
        toast.error(JSON.stringify(error.response.data.message));
        handle403error(error);
      });
  };

  const [workflowName, setworkflowName] = useState();
  const handleChangeWorkflowType = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("value");
    // setSelectedrole(optionElementId);
    //console.log(optionElementId);
    axios
      .get(`${base_url.api2}/getWorkflowByWorkflowType`, {
        headers: {
          Authorization: jwtToken,
          workflowType: optionElementId,
        },
      })
      .then((response) => {
        //console.log(response);
        setworkflowName(response.data.data);
      })
      .catch((error) => {
        // toast.error(JSON.stringify(error.response.data.message));
        //console.log(JSON.stringify(error.response));
        handle403error(error);
      });
  };

  const [workflowid, setworkflowId] = useState();
  const handleChangeworkflowName = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("id");
    //console.log(optionElementId);
    setworkflowId(optionElementId);
  };
  const [selecteduserrole, setselecteduserrole] = useState(null);
  const handleChange4 = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const selectedcourseId = optionElement.getAttribute("id");
    //console.log(selectedcourseId);
    setselecteduserrole(selectedcourseId);
  };

  const [userrole, setuserrole] = useState();
  const [modalIsOpen2, setIsOpen2] = React.useState(false);
  const [assignconsultantid, setassignconsultantid] = useState();
  const openModal2 = (p) => {
    setassignconsultantid(p.enquiryId);
    axios
      .get(`${base_url.api2}/getUserByRoleId`, {
        headers: {
          Authorization: jwtToken,
          roleId: 4,
          direction: "ASC",
          columnName: "firstName",
        },
      })
      .then((res) => {
        //console.log("User by role", res.data.data);
        setuserrole(res.data.data);
      })
      .catch((error) => {
        // toast.error(JSON.stringify(error.response.data.message));
        //console.log(JSON.stringify(error.response));
        handle403error(error);
      });
    setIsOpen2(true);
  };
  const submitConsultant = (e, p) => {
    let enquiryId = Number(assignconsultantid);
    let consultantId = Number(selecteduserrole);
    let consultantName = e.target[0].value;
    let data = {
      enquiryId,
      consultantId,
      consultantName,
    };
    //console.log(data);
    postConsultant(data);
  };
  const postConsultant = (data) => {
    //console.log("Page", pageno);
    axios
      .post(`${base_url.api2}/updateEnquiryByEnquiryId`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        //console.log(response.data.data);
        toast.success("Success");
        setIsOpen2(false);
        getEnquiry(pageno);
        //console.log(response);
      })
      .catch((error) => {
        toast.error(JSON.stringify(error.response.data.message));
        handle403error(error);
      });
  };

  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            navigate("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };
  return (
    <div>
      <ToastContainer />
      <Card className="commoncomponent_cardcss">
        <CardSubtitle className="font-weight-bold"></CardSubtitle>
        <div>
          <Row>
            <Col md={4}>
              <button className="btn listing_addbutton" onClick={goback}>
                &#x2630; List
              </button>
            </Col>
            <Col md={4}>
              <center>
                <CardText className={classes.headtext}>
                  <strong>Client Enquiry</strong>
                </CardText>
              </center>
            </Col>
            <Col md={4}>
              <button className="btn listing_addbutton" onClick={createenquiry}>
                Create Enquiry
              </button>
            </Col>
          </Row>
          <Table responsive striped>
            <thead>
              <tr>
                <th>College Name</th>
                <th>Course Name</th>
                <th>Created</th>
                <th>Updated</th>
                <th>Action</th>
              </tr>
            </thead>
            {/* {(client_enquiry==null)?
                      <Backdrop className={classes.backdrop} open>
                          <CircularProgress color="inherit" />
                      </Backdrop>:( */}
            <tbody>
              {client_enquiry != null ? (
                client_enquiry.map((p) => (
                  <tr key={p.enquiryId}>
                    {/* <td>{params.client_name}</td> */}
                    <td>{p.institutionName}</td>
                    <td>{p.courseName}</td>
                    <td>
                      {p.created
                        ? moment(p.created.split(".")[0]).format("DD-MMM-YY")
                        : null}
                    </td>
                    <td>
                      {p.updated
                        ? moment(p.updated.split(".")[0]).format("DD-MMM-YY")
                        : null}
                    </td>
                    <td id={p.enquiryId}>
                      {p.workflowId == null ? (
                        <Link
                          id={p.enquiryId}
                          onClick={(e) => openAssignWorkflow(p, e)}
                        >
                          Assign Workflow
                        </Link>
                      ) : (
                        <>
                          {p.consultantId === null ? (
                            <Link onClick={() => openModal2(p)}>
                              Assign Consultant
                            </Link>
                          ) : (
                            <Link onClick={(e) => showWorkflow(p, e)}>
                              View Workflow
                            </Link>
                          )}
                        </>
                      )}
                      {/* &nbsp;&nbsp;
                        {p.workflowId == null ? null : (
                          <>
                            {p.consultantId==null?(
                              <Link onClick={() => openModal2(p)}>
                                Assign Consultant
                              </Link>
                            ) : null                            
                            }
                          </>
                        )} */}
                      &nbsp;&nbsp;
                      <Modal
                        isOpen={modalIsOpen2}
                        onAfterOpen={afterOpenModal}
                        onRequestClose={closeModal}
                        style={customStyles}
                        contentLabel="Example Modal"
                      >
                        <h2 ref={(_subtitle) => (subtitle = _subtitle)}>
                          Assign Consultant
                        </h2>
                        <hr />
                        <form
                          onSubmit={(e) => {
                            e.preventDefault();
                            submitConsultant(e, p);
                          }}
                        >
                          {userrole == null ? (
                            <Backdrop className={classes.backdrop} open>
                              <CircularProgress color="inherit" />
                            </Backdrop>
                          ) : (
                            <div className="form-group creatadmin">
                              <Row>
                                <Col md={12}>
                                  <label>Select Consultant Name:</label>
                                  <select
                                    className={classes.selectdrop}
                                    onChange={handleChange4}
                                    required
                                  >
                                    <option value="">
                                      - Select Consultant -
                                    </option>
                                    {userrole.map((cuntitem) => (
                                      <option
                                        key={cuntitem.id}
                                        id={cuntitem.id}
                                        value={`${
                                          cuntitem.firstName +
                                          " " +
                                          cuntitem.lastName
                                        }`}
                                      >
                                        {cuntitem.firstName +
                                          " " +
                                          cuntitem.lastName}
                                      </option>
                                    ))}
                                  </select>
                                </Col>
                              </Row>
                            </div>
                          )}
                          {/* </div> */}
                          <button
                            type="submit"
                            className="btn listing_addbutton"
                          >
                            Submit
                          </button>
                          &nbsp;&nbsp;&nbsp;
                          <Button color="secondary" onClick={closeModal}>
                            Cancel
                          </Button>
                        </form>
                      </Modal>
                      <Modal
                        isOpen={modalIsOpen3}
                        // onAfterOpen={afterOpenModal}
                        onRequestClose={closeModal}
                        style={customStyles}
                        contentLabel="Example Modal"
                      >
                        <form
                          onSubmit={(e) => {
                            e.preventDefault();
                            assignWorkflowSubmit(e, p);
                          }}
                        >
                          <div className="form-inner-admin">
                            <h3 style={{ color: "black" }}>Assign Workflow</h3>
                            <div className="form-group creatadmin">
                              <Row>
                                <Col>
                                  <label>Select Workflow Type:</label>
                                  <select
                                    className={classes.selectdrop}
                                    onChange={handleChangeWorkflowType}
                                    required
                                  >
                                    <option value="">
                                      - Select Workflow Type -
                                    </option>
                                    <option value="Admission">Admission</option>
                                    <option value="Migration">Migration</option>
                                    <option value="Insurance">Insurance</option>
                                    <option value="SkillAssessment">
                                      Skill Assessment
                                    </option>
                                  </select>
                                </Col>
                              </Row>
                            </div>
                            <div className="form-group creatadmin">
                              <Row>
                                <Col>
                                  <label>Workflow Name:</label>
                                  <select
                                    className={classes.selectdrop}
                                    onChange={handleChangeworkflowName}
                                    required
                                  >
                                    <option value="">
                                      - Select Workflow -
                                    </option>
                                    {workflowName?.map((cuntitem) => (
                                      <option
                                        key={cuntitem.workflowId}
                                        id={cuntitem.workflowId}
                                        value={cuntitem.workflowName}
                                      >
                                        {cuntitem.workflowName}
                                      </option>
                                    ))}
                                  </select>
                                </Col>
                              </Row>
                            </div>
                            <div className="form-group creatadmin">
                              <Row>
                                <Col>
                                  <label>Status:</label>
                                  <select
                                    className={classes.selectdrop}
                                    required
                                  >
                                    <option id="1" value="1">
                                      Active
                                    </option>
                                    <option id="0" value="0">
                                      InActive
                                    </option>
                                  </select>
                                </Col>
                              </Row>
                            </div>
                            <div className="form-group creatadmin">
                              <Row className="justify-content-end">
                                <div>
                                  <button
                                    type="submit"
                                    className="btn listing_addbutton"
                                  >
                                    Update
                                  </button>
                                </div>
                                <div>
                                  <Button
                                    color="secondary"
                                    onClick={closeModal}
                                  >
                                    Cancel
                                  </Button>
                                </div>
                              </Row>
                            </div>
                          </div>
                        </form>
                      </Modal>
                      <Link id={p.enquiryId} onClick={openModal}>
                        Delete
                      </Link>
                      <Modal
                        isOpen={modalIsOpen}
                        onAfterOpen={afterOpenModal}
                        onRequestClose={closeModal}
                        style={customStyles}
                        contentLabel="Example Modal"
                      >
                        <h2 ref={(_subtitle) => (subtitle = _subtitle)}>
                          Delete Enquiry ?
                        </h2>
                        <Button color="danger" onClick={deleteCurrentUser}>
                          Delete
                        </Button>
                        &nbsp;&nbsp;&nbsp;
                        <Button color="secondary" onClick={closeModal}>
                          Cancel
                        </Button>
                      </Modal>
                    </td>
                  </tr>
                ))
              ) : (
                <p className={classes.headertekst}>No Enquiry Available</p>
              )}
            </tbody>
            {/* )} */}
          </Table>
        </div>
        <div className={classes.root}>
          <Pagination count={getpageno} onChange={handlepage} shape="rounded" />
        </div>
      </Card>
    </div>
  );
}

export default Enquiry;
