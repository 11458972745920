import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
import PersonIcon from "@mui/icons-material/Person";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import Typography from "@mui/material/Typography";
import "./../../css/MainDashboard.css";
import { Card, CardSubtitle, CardText, Col, Row, Button } from "reactstrap";
import Chart from "react-google-charts";
import axios from "axios";
import base_url from "../../../api/bootapi";
import { useSelector, useDispatch } from "react-redux";
import { selectUser, logout } from "../../features/userSlice";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

const MainDashboardBodytop = (props) => {
  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            navigate("/");
            // toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };

  const user = useSelector(selectUser);
  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        navigate("/");
      }
    });
    // getclientoncity();
    getclientoncbranch();
    console.log("51--->", props.totalclient);
  }, []);
  // const getOverallClientCount = () => {
  //   axios
  //     .get(`${base_url.api1}/getTotalClientCount`, {
  //       headers: {
  //         Authorization: jwtToken,
  //       },
  //     })
  //     .then((res) => {
  //       console.log("60--->Client", res.data.data[0].totalClientCount);
  //       setTotalClientCount(res.data.data[0].totalClientCount);
  //     })
  //     .catch((e) => {
  //       console.log("Error", e);
  //     });
  // };

  // const [data, setdata] = useState([["City", "Client_Count"]]);
  // const getclientoncity = () => {
  //   axios
  //     .get(`${base_url.api1}/getAllClientCountBasedOnCity`, {
  //       headers: {
  //         Authorization: jwtToken,
  //       },
  //     })
  //     .then((res) => {
  //       console.log(res);
  //       for (let i = 0; i < res.data.data.length; i++) {
  //         let newarr = [];
  //         newarr[0] = res.data.data[i].city;
  //         newarr[1] = Number(res.data.data[i].clientCount);
  //         // console.log(newarr);
  //         setdata((prev) => [...prev, newarr]);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const [data2, setdata2] = useState([["Country", "Client_Count"]]);

  const [data3, setdata3] = useState([["Office", "Client_Count"]]);
  const getclientoncbranch = () => {
    axios
      .get(`${base_url.api1}/getAllClientCountBasedOnOffice`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        console.log(res);
        for (let i = 0; i < res.data.data.length; i++) {
          let newarr = [];
          newarr[0] = res.data.data[i].officeName;
          newarr[1] = Number(res.data.data[i].clientCount);
          setdata3((prev) => [...prev, newarr]);
        }
      })
      .catch((err) => {
        console.log(err);
        handle403error(err);
      });
  };

  const renderListItems = () => {
    switch (props.selectedWorkLoad) {
      case "Admission":
        return (
          <>
            <li class="tab-blue">
              <p class="count">{props.aipTotal || 0}</p>
              <p class="label">Application Processing</p>
            </li>
            <li class="tab-red">
              <p class="count">{props.offerLetterCount || 0}</p>
              <p class="label">Offer Letter Received</p>
            </li>
            <li class="tab-cyan">
              <p class="count">{props.enquiryCount?.["COE Received"] || 0}</p>
              <p class="label">COE Received</p>
            </li>
            <li class="tab-purple">
              <p class="count">{props.enquiryCount?.["Course Ongoing"] || 0}</p>
              <p class="label">Course Ongoing</p>
            </li>
            <li class="tab-pink">
              <p class="count">
                {props.enquiryCount?.["RefundProcessing"] || 0}
              </p>
              <p class="label">Refund Processing</p>
            </li>
          </>
        );
      case "Insurance":
        return (
          <>
            <li className="tab-blue">
              <p className="count">{props.enquiryCount?.["Completed"] || 0}</p>
              <p className="label">Completed</p>
            </li>
            <li className="tab-red">
              <p className="count">{props.enquiryCount?.["Processing"] || 0}</p>
              <p className="label">Processing</p>
            </li>
            <li className="tab-purple">
              <p className="count">{props.enquiryCount?.["OnGoing"] || 0}</p>
              <p className="label">OnGoing</p>
            </li>
            <li className="tab-pink">
              <p className="count">
                {props.enquiryCount?.["RefundProcessing"] || 0}
              </p>
              <p className="label">RefundProcessing</p>
            </li>
          </>
        );
      case "Skill Assessment":
        return (
          <>
            <li className="tab-blue">
              <p className="count">{props.enquiryCount?.["Processing"] || 0}</p>
              <p className="label">Processing</p>
            </li>
            <li className="tab-red">
              <p className="count">
                {props.enquiryCount?.["Discontinued"] || 0}
              </p>
              <p className="label">Discontinued</p>
            </li>
            <li className="tab-cyan">
              <p className="count">{props.enquiryCount?.["Completed"] || 0}</p>
              <p className="label">Completed</p>
            </li>
            <li className="tab-blue">
              <p className="count">{props.enquiryCount?.["Enquiry"] || 0}</p>
              <p className="label">Enquiry</p>
            </li>
          </>
        );
      case "Migration":
        return (
          <>
            <li className="tab-blue">
              <p className="count">{props.enquiryCount?.["App Ready"] || 0}</p>
              <p className="label">App Ready</p>
            </li>
            <li className="tab-red">
              <p className="count">{props.enquiryCount?.["Processing"] || 0}</p>
              <p className="label">Processing</p>
            </li>
            <li className="tab-cyan">
              <p className="count">
                {props.enquiryCount?.["VisaLodgement"] || 0}
              </p>
              <p className="label">VisaLodgement</p>
            </li>
            <li className="tab-purple">
              <p className="count">{props.enquiryCount?.["Granted"] || 0}</p>
              <p className="label">Granted</p>
            </li>
            <li className="tab-pink">
              <p className="count">{props.enquiryCount?.["Refused"] || 0}</p>
              <p className="label">Refused</p>
            </li>
          </>
        );
      default:
        return null;
    }
  };
  //console.log(props.enquiryCount);
  return (
    <>
      <ul class="short-info-tabs">
        {/* <Row>
          <div className="d-flex justify-content-between">
            <h3 class="page-title" style={{ color: "#6d7290", fontWeight: "200" }}>
              Welcome, Good Morning
            </h3>
          </div>
        </Row> */}
        <br />
        <li class="tab-yellow">
          <p class="count">{props.leadtotal || 0}</p>
          <p class="label">Lead</p>
        </li>
        {renderListItems()}
        <li class="tab-magenta">
          <p class="count">{props.totalclient || 0}</p>
          <p class="label">Overall Clients </p>
        </li>
        <li class="tab-green">
          <p class="count">{props.enquiryCount?.["Completed"] || 0}</p>
          <p class="label">Overall Archieved </p>
        </li>
      </ul>
      {/* {user.isw_user_role === 1 || user.isw_user_role === 6 ?
        <div className="bodytop">
          <div className="row">
            <div className="col-md-6 ">
              <div className="body_top">
                <Chart
                  width={"100%"}
                  height={"300px"}
                  chartType="Bar"
                  loader={<div>Loading Chart</div>}
                  data={data2}
                  options={{
                    title: "Client Based on Country",
                  }}
                  rootProps={{ "data-testid": "1" }}
                />
              </div>
            </div>
            <div className="col-md-6 ">
              <div className="body_topright">
                <Chart
                  width={"100%"}
                  height={"300px"}
                  // chartType="PieChart"
                  chartType="Bar"
                  loader={<div>Loading Chart</div>}
                  data={data3}
                  options={{
                    title: "Client Based on Branch",
                  }}
                  rootProps={{ "data-testid": "1" }}
                />
              </div>
            </div>
          </div>
        </div>
      :null} */}
    </>
  );
};

export default MainDashboardBodytop;
