import React, { useState, useEffect } from "react";

import "../css/AddInvoiceDetails.css";
import "../css/Responsive.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/system";
import { useParams } from "react-router-dom";
import axios from "axios";
import base_url from "../../api/bootapi";
import { toast } from "react-toastify";
import moment from "moment";
import { useStyles, customStyles } from "../../Utilities/CSSUtilities";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../features/userSlice";
import { useNavigate } from "react-router-dom";

const PrintQuotation = () => {
  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  const classes = useStyles();
  const params = useParams();
  const [newline, setnewline] = useState([]);
  const [stateCust, setstateCust] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [logokey, setlogokey] = useState();
  const [addresskey, setaddresskey] = useState();

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        navigate("/");
      }
    });
    getquotation();
    getadminaddress();
    getaddresslogo();
    getInvoicelogo();
    getadminbankdetails();
  }, []);

  const [configkeybank, setconfigkeybank] = useState();
  const getadminbankdetails = () => {
    axios
      .get(`${base_url.api3}/getByConfigKey`, {
        headers: {
          configKey: "isw_bank_details",
        },
      })
      .then((res) => {
        setconfigkeybank(res.data.data.configValue);
      })
      .catch((err) => {
        //console.log(err);
        handle403error(err);
      });
  };
  const [configkey, setconfigkey] = useState();
  const getadminaddress = () => {
    axios
      .get(`${base_url.api3}/getByConfigKey`, {
        headers: {
          configKey: "isw_address",
        },
      })
      .then((res) => {
        setconfigkey(res.data.data.configValue);
      })
      .catch((err) => {
        //console.log(err);
        handle403error(err);
      });
  };

  const getaddresslogo = () => {
    axios
      .get(`${base_url.api3}/getByConfigKey`, {
        headers: {
          configKey: "invoice_address_logo",
        },
      })
      .then((res) => {
        setlogokey(res.data.data.configValue);
        //console.log("configValue------->", res.data.data.configValue);
      })
      .catch((err) => {
        //console.log(err);
        handle403error(err);
      });
  };

  const getInvoicelogo = () => {
    axios
      .get(`${base_url.api3}/getByConfigKey`, {
        headers: {
          configKey: "invoice_logo",
        },
      })
      .then((res) => {
        setaddresskey(res.data.data.configValue);
        //console.log("configValue------->", res.data.data.configValue);
      })
      .catch((err) => {
        //console.log(err);
        handle403error(err);
      });
  };
  const getquotation = () => {
    axios
      .get(`${base_url.api2}/getQuotationById`, {
        headers: {
          Authorization: jwtToken,
          quotationId: params.quotation_id,
        },
      })
      .then((res) => {
        //console.log(res);
        let quotationDetails = [];
        for (let i = 0; i < res.data.data.quotationDetails.length; i++) {
          setnewline((prev) => [
            ...prev,
            {
              id: 0,
              description: res.data.data.quotationDetails[i].description,
              amount: res.data.data.quotationDetails[i].amount,
              taxPercentage: res.data.data.quotationDetails[i].taxPercentage,
              taxAmount: res.data.data.quotationDetails[i].taxAmount,
              totalAmount: res.data.data.quotationDetails[i].totalAmount,
            },
          ]);
          quotationDetails.push({
            amount: res.data.data.quotationDetails[i].amount,
            description: res.data.data.quotationDetails[i].description,
            id: 0,
            taxAmount: res.data.data.quotationDetails[i].taxAmount,
            taxPercentage: res.data.data.quotationDetails[i].taxPercentage,
            totalAmount: res.data.data.quotationDetails[i].totalAmount,
          });
        }
        setstateCust({
          address: res.data.data.address,
          clientId: res.data.data.clientId,
          clientUsername: res.data.data.clientUsername,
          clientName: res.data.data.clientName,
          currency: res.data.data.currency,
          id: res.data.data.id,
          quotationDate: res.data.data.quotationDate,
          quotationDetails,
          quotationNumber: res.data.data.quotationNumber,
          quotationDueDate: res.data.data.quotationDueDate,
          totalAmount: res.data.data.totalAmount,
        });
      })
      .catch((err) => {
        //console.log(err);
        handle403error(err);
      });
  };

  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            navigate("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };

  return (
    <div style={{ padding: "30px", background: "white" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <div dangerouslySetInnerHTML={{ __html: configkey }} />
        </div>
        <img
          src="https://nepcoms.com/wp-content/uploads/2018/03/Nepcoms-logo.png"
          style={{ width: "200px", height: "60px" }}
        />
      </div>
      <hr />
      <div
        className="print_header"
        style={{
          display: "flex",
          marginBottom: "20px",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ padding: "20px 0" }}>
          {/* <div style={{ flex: "0.4", padding: "20px" }}> */}
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography style={{ flex: 0.3, fontWeight: "bolder" }}>
              Name:
            </Typography>
            <Typography style={{ flex: 0.7 }} variant="body2">
              {stateCust?.clientName}
            </Typography>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography style={{ flex: 0.3, fontWeight: "bolder" }}>
              Email:
            </Typography>
            <Typography style={{ flex: 0.7 }} variant="body2">
              {stateCust?.clientUsername}
            </Typography>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography style={{ flex: 0.3, fontWeight: "bolder" }}>
              Address:
            </Typography>
            <Typography style={{ flex: 0.7 }} variant="body2">
              {stateCust?.address}
            </Typography>
          </div>
        </div>
        <div
          style={{
            // flex: "0.4",
            width: "280px",
            right: "30px",
            margin: "10px 0",
            paddingTop: "20px",
            paddingBottom: "20px",
            marginRight: "-30px",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              variant="body1"
              style={{ flex: "0.5", margin: "0 10px", fontWeight: "bolder" }}
            >
              Quotaion No:
            </Typography>
            <Typography variant="body1" style={{ flex: "0.5" }}>
              #{stateCust?.quotationNumber}
            </Typography>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              variant="body1"
              style={{ flex: "0.5", margin: "0 10px", fontWeight: "bolder" }}
            >
              Quotaion Date:
            </Typography>
            <Typography variant="body1" style={{ flex: "0.5" }}>
              {moment(stateCust?.quotationDate).format("DD-MMM-YY")}
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="body1"
              style={{ flex: "0.5", margin: "0 10px", fontWeight: "bolder" }}
            >
              Due Date:
            </Typography>
            <Typography variant="body1" style={{ flex: "0.5" }}>
              {moment(stateCust?.quotationDueDate).format("DD-MMM-YY")}
            </Typography>
          </div>
        </div>
      </div>
      <div
        className="print_middle"
        style={{ marginBottom: "20px", border: "1px solid" }}
      >
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: "bolder" }}>
                  Description
                </TableCell>
                <TableCell style={{ fontWeight: "bolder" }} align="right">
                  Amount
                </TableCell>
                <TableCell style={{ fontWeight: "bolder" }} align="right">
                  Tax (%)
                </TableCell>
                <TableCell style={{ fontWeight: "bolder" }} align="right">
                  Tax Amount
                </TableCell>
                <TableCell style={{ fontWeight: "bolder" }} align="right">
                  Total Amount
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {newline.map((p, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    <Typography>{p.description}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography>{p.amount}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography>{p.taxPercentage}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography>{p.taxAmount}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography>{p.totalAmount}</Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableHead
            // style={{ backgroundColor: "darkgray" }}
            >
              <TableRow>
                <TableCell align="right" colSpan="4">
                  <Typography
                    style={{ fontWeight: "bolder" }}
                  >{`Total Amount (${stateCust?.currency}):`}</Typography>
                </TableCell>
                <TableCell
                  align="right"
                  style={{
                    borderTop: "2px solid",
                    borderBottom: "6px double",
                  }}
                >
                  <Typography
                    style={{
                      fontWeight: "bolder",
                    }}
                  >
                    {stateCust?.totalAmount}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
      </div>
      <div
        className="print_footer"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div
          className="print_footerbankdetails"
          style={{ flex: 1, border: "1px solid black" }}
        >
          <div
            style={{
              borderBottom: "1px solid black",
              height: "50px",
              display: "flex",
              alignItems: "center",
              // background: "darkgray",
            }}
          >
            <Typography style={{ fontWeight: "bolder", marginLeft: "20px" }}>
              Payment Details
            </Typography>
          </div>
          <div style={{ margin: "20px" }}>
            <div dangerouslySetInnerHTML={{ __html: configkeybank }} />
            {/* {configkeybank} */}
            {/* <Typography variant="body1">Bank Details</Typography>
            <Typography variant="body1">
              Account Name: Nepcoms Services
            </Typography>
            <Typography variant="body1">BSB: 062005</Typography>
            <Typography variant="body1">A/C: 10912751</Typography>
            <Typography variant="body1">Bank: Commonwealth bank</Typography> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrintQuotation;
