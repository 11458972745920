import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CardText, Col, Row, Button, Table } from "reactstrap";
import axios from "axios";
import base_url from "./../../../api/bootapi";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  selectUser,
  setdashboardcheck,
  logout,
} from "./../../features/userSlice";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Pagination from "@mui/material/Pagination";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import AddIcon from "@mui/icons-material/Add";
import Modal from "react-modal";
import "./../../css/Responsive.css";
import { useForm } from "react-hook-form";
import { customStyles, useStyles } from "../../../Utilities/CSSUtilities";
import callsaveauditapi from "../../../services/auditservice";
import {
  check1pointpermission,
  check4pointpermission,
  checksettingpermission,
} from "../../Permission/permission";
import sort from "../../images/sort.svg";
import DeleteModal from "../../Modal/Masters/DeleteModal";
import EditApplication from "../../Modal/Masters/EditApplication";

const ApplicationStatus = () => {
  const classes = useStyles();
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  // console.log(alladmin);
  const navigate = useNavigate();
  const [backdropopen, setbackdropopen] = useState(false);
  const jwtToken = "Bearer " + user.isw_user_token;
  const [deleteuser, setDeleteuser] = useState(null);
  const [pageno, setpageno] = useState(0);
  const [getpageno, setgetpageno] = useState();
  const [rows, setrows] = React.useState(50);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const isw_user_name = localStorage.getItem("isw_user_name");
  const isw_adminId = localStorage.getItem("isw_adminId");
  const isw_user_email = localStorage.getItem("isw_user_email");
  const [totalUser, settotalUser] = useState();
  const [countnumber, setcountnumber] = useState();
  const [numberofElements, setnumberofElements] = useState();
  const ipaddress = localStorage.getItem("AdminIpAddress");
  const [sortState, setsortState] = useState("name");
  const [direction, setdirection] = useState("ASC");
  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);
  const [allClientSource, setAllClientSource] = useState();
  const [allApplicationStatus, setAllApplicationStatus] = useState();
  const [display1, setdisplay] = useState("inline");

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        navigate("/");
      }
    });
    getPermissions();
  }, [rows]);

  useEffect(() => {
    let width = document.querySelector("table")?.offsetWidth;
    if (document.querySelector(".scroller")) {
      document.querySelector(".scroller").style.width = `${width}px`;
    }
  }, []);

  let scrolling = false;
  const scrolldiv = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow .table-responsive");
    table.scrollTo(scroller.scrollLeft, 0);
  };

  const scrolltable = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow  .table-responsive");
    scroller.scrollTo(table.scrollLeft, 0);
  };

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);

    if (check4pointpermission(1575, 1580, permission_obj, resource_obj)) {
      //if (available_permission) {
      getAllApplicationStatus(pageno);
      dispatch(setdashboardcheck(46));
      // } else {
      //   navigate("/errorPage");
      // }
    } else {
      navigate("/errorPage");
    }
  };

  const handlepage = (event, value) => {
    setpageno(value - 1);
    getAllApplicationStatus(value - 1);
  };

  const handleRows = (event) => {
    setrows(event.target.value);
  };

  const [searchdata, setsearch] = useState();

  const getAllApplicationStatus = (value1) => {
    setbackdropopen(true);
    axios
      .get(`${base_url.api1}/getAllApplicationStatusWithPage`, {
        headers: {
          Authorization: jwtToken,
          columnName: sortState,
          direction: direction,
          page: Number(value1),
          size: Number(rows),

          // applicationType: "Admission",
        },
      })
      .then((response) => {
        console.log("APPLICATION STATUS>>>", response.data.data);
        setgetpageno(response.data.data.totalPages);
        settotalUser(response.data.data.totalElements);
        setcountnumber(response.data.data.number);
        setnumberofElements(response.data.data.numberOfElements);
        // setAllClientSource(response.data.data.content);
        setAllApplicationStatus(response.data.data.content);
        try {
          document.getElementById("myForm").reset();
        } catch (error) {
          console.log(error);
        }
        setbackdropopen(false);
        setsearch(false);
      })
      .catch((error) => {
        // toast.error(JSON.stringify(error.response.data.message));
        console.log("Error", error);
        setbackdropopen(false);
        handle403error(error);
      });
  };

  const deleteCurrentUser = (id, name) => {
    axios
      .delete(`${base_url.api1}/deleteApplicationStatusById`, {
        headers: {
          Authorization: jwtToken,
          applicationStatusId: id,
        },
      })
      .then((response) => {
        toast.success(response.data.message);

        let log = isw_user_name + " Deleted Client Source " + name + ".";
        callsaveauditapi(log, dispatch, navigate);
        getAllApplicationStatus(pageno);
      })
      .catch((error) => {
        // toast.error(JSON.stringify(error.response.data.message));
        handle403error(error);
      });
  };

  const [modalIsOpen2, setIsOpen2] = React.useState(false);
  const openModal2 = () => {
    setIsOpen2(true);
  };
  const createApplicationStatus = (data2) => {
    let name = data2.name.trim();
    let type = data2.docType;
    let status = Number(data2.status);
    let id = 0;
    let data = {
      name,
      status,
      id,
      type,
    };
    axios
      .post(`${base_url.api1}/addNewApplicationStatus`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        console.log(response.data.data);
        toast.success("Success");

        let log =
          isw_user_name +
          " Created Client Source " +
          response.data.data.name +
          ".";
        callsaveauditapi(log, dispatch, navigate);
        getAllApplicationStatus(pageno);
        setIsOpen2(false);
      })
      .catch((error) => {
        toast.error(JSON.stringify(error.response.data.message));
        handle403error(error);
      });
  };

  const [editproName, seteditproName] = useState();
  const [editSourceStatus, seteditSourceStatus] = useState();
  const [editDocType, setEditDocType] = useState();
  const [isclosemodal, setisclosemodal] = useState(true);

  const editUser = (id, name) => {
    setisclosemodal(false);
    let data = {
      id: Number(id),
      name: editproName.trim(),
      type: editDocType,
      status: Number(editSourceStatus),
    };
    axios
      .post(`${base_url.api1}/updateApplicationStatusById`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        toast.success("Success");
        setisclosemodal(true);
        let log = isw_user_name + " Updated Client Source " + name + ".";
        callsaveauditapi(log, dispatch, navigate);
        getAllApplicationStatus(pageno);
      })
      .catch((error) => {
        handle403error(error);
        toast.error(JSON.stringify(error.response.data.message));
      });
  };

  const changeStatus = (elem, p) => {
    if (p.status === 1) {
      let data = {
        id: elem.target.id,
        status: Number(0),
      };
      axios
        .post(`${base_url.api1}/updateApplicationStatusById`, data, {
          headers: {
            Authorization: jwtToken,
          },
        })
        .then((res) => {
          toast.success("Status changed");
          getAllApplicationStatus(pageno);
        })
        .catch((err) => {
          handle403error(err);
        });
    } else {
      let data = {
        id: elem.target.id,
        status: Number(1),
      };
      axios
        .post(`${base_url.api1}/updateApplicationStatusById`, data, {
          headers: {
            Authorization: jwtToken,
          },
        })
        .then((res) => {
          toast.success("Status changed");
          getAllApplicationStatus(pageno);
        })
        .catch((err) => {
          handle403error(err);
        });
    }
  };

  const closeModal = () => {
    setIsOpen2(false);
  };

  const submitrows = (e) => {
    getAllApplicationStatus(0);
  };

  // Search Data by name

  const [searchinput, setsearchinput] = useState();
  const submitsearch = (e) => {
    setbackdropopen(true);
    let srchdata = e.target[0].value;
    setsearchinput(srchdata);
    setpageno(0);
    axios
      .get(`${base_url.api1}/searchApplicationStatus`, {
        headers: {
          Authorization: jwtToken,
          columnName: "name",
          direction: "DESC",
          page: Number(0),
          size: Number(rows),
          searchQuery: srchdata,
        },
      })
      .then((res) => {
        setgetpageno(res.data.data.totalPages);
        setsearch(true);
        setAllApplicationStatus(res.data.data.content);
        try {
          document.getElementById("myForm").reset();
        } catch (error) {
          console.log(error);
        }
        settotalUser(res.data.data.totalElements);
        setcountnumber(res.data.data.number);
        setbackdropopen(false);
        setnumberofElements(res.data.data.numberOfElements);
      })
      .catch((err) => {
        setbackdropopen(false);
        setAllApplicationStatus();
        handle403error(err);
      });
  };

  const searchbystatus = (event) => {
    setbackdropopen(true);
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const value = optionElement.getAttribute("value");
    if (value === "all") {
      getAllApplicationStatus(pageno);
    } else {
      axios
        .get(`${base_url.api1}/getApplicationStatusByStatus`, {
          headers: {
            Authorization: jwtToken,
            status: Number(value),
            page: Number(pageno),
            size: Number(rows),
          },
        })
        .then((response) => {
          setgetpageno(response.data.data.totalPages);
          setAllApplicationStatus(response.data.data.content);
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          setsearch(false);
          setbackdropopen(false);
          try {
            document.getElementById("myForm").reset();
          } catch (error) {
            console.log(error);
          }
        })
        .catch((err) => {
          setbackdropopen(false);
          handle403error(err);
        });
    }
  };

  const sortClient = (e) => {
    console.log(e.target.id);
    setsortState(e.target.id);
    if (direction == "DESC") {
      setdirection("ASC");
    } else if (direction == "ASC") {
      setdirection("DESC");
    }
    // getallInsurance(pageno);
    axios
      .get(`${base_url.api1}/getAllApplicationStatus`, {
        headers: {
          Authorization: jwtToken,
          columnName: e.target.id,
          direction: direction,
          page: Number(pageno),
          size: Number(rows),
        },
      })
      .then((response) => {
        setgetpageno(response.data.data.totalPages);
        settotalUser(response.data.data.totalElements);
        setcountnumber(response.data.data.number);
        setnumberofElements(response.data.data.numberOfElements);
        setAllApplicationStatus(response.data.data.content);
        try {
          document.getElementById("myForm").reset();
        } catch (error) {
          console.log(error);
        }
        setbackdropopen(false);
        setsearch(false);
      })
      .catch((error) => {
        toast.error(JSON.stringify(error.response.data.message));
        setbackdropopen(false);
        handle403error(error);
      });
  };

  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            navigate("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };
  return (
    <div>
      <ToastContainer />
      <Backdrop className={classes.backdrop} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* <div className="commoncomponent_cardcss"> */}
      <div
        class="page-head d-flex justify-content-between p-4"
        style={{ marginTop: "4.3rem", marginBottom: "-20px" }}
      >
        <ul class="breadcrums">
          <h3 class="page-title bold">Manage Application Status</h3>
          <Link to="/" class="b-link">
            Home
          </Link>
          <span className="s_span ">&raquo;</span>
          <a class="b-link">Settings</a>
          <span className="s_span ">&raquo;</span>
          <a class="b-link active">Application Status</a>
        </ul>
        {/* <a href="workload-dashboard.html" class="back-btn">Back</a> */}
        {check1pointpermission(1576, permissionData, rolepermission) ? (
          <a
            className="sent-button"
            onClick={openModal2}
            style={{ float: "right", height: "40px" }}
          >
            Add
          </a>
        ) : null}
        <Modal
          isOpen={modalIsOpen2}
          // onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <h2>
            <strong>Application Status</strong>
          </h2>
          <form onSubmit={handleSubmit(createApplicationStatus)}>
            <div>
              <div className="form-group creatadmin">
                <Row>
                  <Col md={12}>
                    <label>
                      Name<span style={{ color: "red" }}>*</span>:
                    </label>
                    <input
                      type="text"
                      name="name"
                      className="form-control underline-input"
                      ref={register({ required: true })}
                    />
                    {errors.name && (
                      <p className="errormessage">Name is Required</p>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col md={12} className="mt-2">
                    <label>
                      Application Type<span style={{ color: "red" }}>*</span>:
                    </label>
                    <select
                      name="docType"
                      className="form-control underline-input"
                      ref={register({ required: true })}
                    >
                      <option value="">Select an option</option>
                      <option value="Admission">Admission</option>
                      <option value="Migration">Migration</option>
                      <option value="Insurance">Insurance</option>
                      <option value="SkillAssessment">Skill Assessment</option>
                    </select>
                    {errors.docType && (
                      <p className="errormessage">
                        Application Type is Required
                      </p>
                    )}
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col md={12}>
                    <label>
                      Status<span style={{ color: "red" }}>*</span>:
                    </label>
                    <select
                      name="status"
                      className={classes.selectdrop}
                      ref={register({ required: true })}
                    >
                      <option value="1">Active</option>
                      <option value="0">Inactive</option>
                    </select>
                    {errors.status && (
                      <p className="errormessage">Status is Required</p>
                    )}
                  </Col>
                </Row>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "2px",
                }}
              >
                <Button color="secondary" onClick={() => setIsOpen2(false)}>
                  Cancel
                </Button>
                <button type="submit" className="btn listing_addbutton ml-1">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </Modal>
      </div>
      <div>
        <Row></Row>
        <div
          className="newlisting_headertabdesign"
          style={{
            padding: "1.5rem",
            border: "1.5rem solid #e9ebf2",
            marginBottom: "0",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div md={6}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                  alignItems: "center",
                }}
              >
                <div>
                  <form
                    className="master"
                    onSubmit={(e) => {
                      e.preventDefault();
                      submitrows(e);
                    }}
                  >
                    <div style={{ marginTop: "-10px", marginRight: "20px" }}>
                      <label className="area_rowslable">Limit</label>
                      <br />
                      <FormControl
                        variant="standard"
                        sx={{
                          m: 1,
                          minWidth: 180,
                          marginTop: 0,
                          maxHeight: 180,
                        }}
                      >
                        <select
                          className="s_client_setting"
                          labelId="simple-select-label"
                          id="simple-select"
                          value={rows}
                          onChange={handleRows}
                        >
                          <option value={10}>10</option>
                          <option value={20}>20</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                        </select>
                      </FormControl>
                    </div>
                  </form>
                </div>

                <div style={{ marginLeft: "6px", marginTop: "-10px" }}>
                  <label className="area_rowslable">Status</label>

                  <select
                    className="form-control"
                    style={{ backgroundColor: "#f5f5f9" }}
                    onChange={searchbystatus}
                  >
                    <option value="all">All</option>
                    <option value="1">Active</option>
                    <option value="0">Inactive</option>
                  </select>
                </div>
              </div>
            </div>
            <div md={3}>{null}</div>
            <div md={3} className="mt-2">
              <div className="clearsearchclass">
                <form
                  id="myForm"
                  className="d-flex"
                  onSubmit={(e) => {
                    e.preventDefault();
                    submitsearch(e);
                  }}
                >
                  <input
                    className="form-control"
                    placeholder="Search.."
                    name="search"
                  />
                  <button className="btn listpagesearch_button" type="submit">
                    <i className="fa fa-search"></i>
                  </button>
                </form>
              </div>
              {searchdata ? (
                <a
                  className={classes.clrsrch}
                  onClick={() => {
                    setpageno(0);
                    getAllApplicationStatus(0);
                  }}
                >
                  Clear Search
                </a>
              ) : null}
            </div>
          </div>
        </div>
        <div
          className="newlisting_headertabdesign"
          style={{
            padding: "1.5rem",
            border: "1.5rem solid #e9ebf2",
            marginTop: "-25px",
            marginBottom: "0",
          }}
        >
          <Row className="s_pagi">
            <Col md={6}>
              {allApplicationStatus?.length ? (
                <p className="s_para">
                  {countnumber * rows + 1} to{" "}
                  {countnumber * rows + numberofElements} of {totalUser} records
                </p>
              ) : (
                <p className="s_para">No Record Found</p>
              )}
            </Col>
            <Col md={6} className="d-flex justify-content-end"></Col>
          </Row>
          <div className="above-scroller" onScroll={scrolldiv}>
            <div className="scroller"></div>
          </div>
          <div className="displaytablerow">
            {/* <Table responsive striped onScroll={scrolltable}> */}
            <Table
              className="workload card-table table-borderless"
              responsive
              striped
              onScroll={scrolltable}
            >
              <thead className="s_sticky">
                <tr>
                  <th style={{ textAlign: "center", minWidth: "50px" }}>SN</th>
                  <th style={{ textAlign: "center", width: "25%" }}>
                    NAME
                    <img
                      src={sort}
                      className="sorting"
                      id="name"
                      onClick={sortClient}
                      style={{
                        cursor: "pointer",
                        display: `${display1}`,
                        height: "10px",
                      }}
                    />
                  </th>
                  <th style={{ textAlign: "center", width: "25%" }}>
                    Application Type
                  </th>
                  <th style={{ textAlign: "center", width: "25%" }}>
                    STATUS
                    <img
                      src={sort}
                      className="sorting"
                      id="status"
                      onClick={sortClient}
                      style={{
                        cursor: "pointer",
                        display: `${display1}`,
                        height: "10px",
                      }}
                    />
                  </th>
                  {check4pointpermission(
                    1577,
                    1579,
                    permissionData,
                    rolepermission
                  ) ? (
                    <th style={{ textAlign: "center", width: "25%" }}>
                      ACTION
                    </th>
                  ) : null}
                </tr>
              </thead>

              <tbody>
                {allApplicationStatus != null ? (
                  allApplicationStatus &&
                  allApplicationStatus?.map((p, index) => (
                    <tr key={p.id}>
                      <td>{index + 1}</td>
                      <td style={{ width: "30%" }}>{p.name}</td>
                      <td>{p.type}</td>
                      <td style={{ width: "30%" }}>
                        {check1pointpermission(
                          1580,
                          permissionData,
                          rolepermission
                        ) ? (
                          <span
                            style={{ cursor: "pointer" }}
                            id={p.id}
                            onClick={(e) => changeStatus(e, p)}
                          >
                            {p.status == 1 ? "Active" : "Inactive"}
                          </span>
                        ) : (
                          <>{p.status == 1 ? "Active" : "Inactive"}</>
                        )}
                      </td>

                      <td id={p.id} style={{ width: "175px" }}>
                        {check1pointpermission(
                          1578,
                          permissionData,
                          rolepermission
                        ) ? (
                          <>
                            <EditApplication
                              header={"Application Status"}
                              editLevel={editUser}
                              editname={editproName}
                              setname={seteditproName}
                              editType={editDocType}
                              setType={setEditDocType}
                              editstatus={editSourceStatus}
                              setstatus={seteditSourceStatus}
                              classes={classes}
                              name={p.name}
                              id={p.id}
                              status={p.status}
                              type={p.type}
                              isclosemodal={isclosemodal}
                            />
                            &nbsp;|&nbsp;
                          </>
                        ) : null}
                        {check1pointpermission(
                          1579,
                          permissionData,
                          rolepermission
                        ) ? (
                          <DeleteModal
                            id={p.id}
                            name={p.name}
                            deleteCurrentUser={deleteCurrentUser}
                          />
                        ) : null}
                      </td>
                    </tr>
                  ))
                ) : (
                  <p className={classes.headertekst}>{null}</p>
                )}
              </tbody>
            </Table>
          </div>
          <Row className="s_pagirow">
            <Col md={6}>
              <div>
                <form>
                  <div style={{ float: "left" }}>
                    <FormControl
                      variant="standard"
                      style={{
                        display: "inline-block",
                        marginRight: "25px",
                        minWidth: "50px",
                      }}
                    >
                      <select
                        className={classes.selectlimit}
                        labelId="simple-select-label"
                        id="simple-select"
                        value={rows}
                        onChange={handleRows}
                      >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                    </FormControl>
                    {allApplicationStatus?.length ? (
                      <div className="s_spanPage">
                        {countnumber * rows + 1} to{" "}
                        {countnumber * rows + numberofElements} of {totalUser}{" "}
                        records
                      </div>
                    ) : (
                      <div className="s_spanPage">No Record Found</div>
                    )}
                  </div>
                </form>
              </div>
            </Col>
            <Col md={6}>
              <div className={classes.root} style={{ float: "right" }}>
                <Pagination
                  count={getpageno}
                  onChange={handlepage}
                  shape="rounded"
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default ApplicationStatus;
