import React, { useState, useEffect } from "react";
import "../css/AddInvoiceDetails.css";
import "../css/Responsive.css";
import { Button, Input, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";
import SelectCurrency from "react-select-currency";
import { useParams, useNavigate, Link } from "react-router-dom";
import axios from "axios";
import base_url from "../../api/bootapi";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { gettabvalue, logout, selectUser } from "../features/userSlice";
import DatePicker from "react-datepicker";

import callsaveauditapi from "../../services/auditservice";
import { check1pointpermission } from "../Permission/permission";
import Modal from "react-modal";
import { customStyles } from "../../Utilities/CSSUtilities";

const selectheight = "35px";
const selectWidth = "100%";
const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
  },
  selectdrop: {
    width: selectWidth,
    height: selectheight,
  },
  backdrop: {
    // zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    zIndex: 1000,
  },
}));
const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    // color: theme.palette.common.black,
  },
  tooltip: {
    // backgroundColor: theme.palette.common.black,
  },
}));
function BootstrapTooltip(props) {
  const classes2 = useStylesBootstrap();

  return <Tooltip arrow classes={classes2} {...props} />;
}
const AddInvoiceDetails = () => {
  const isoDateString = new Date().toISOString();
  const datenow2 = isoDateString.split(".")[0];
  const [created_date, setCreatedDate] = useState(
    moment(datenow2).format("YYYY-MM-DD")
  );
  const [backdropopen, setbackdropopen] = useState(false);
  const datenow = isoDateString.split(".")[0];
  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  const isw_user_name = localStorage.getItem("isw_user_name");
  const classes = useStyles();
  const dispatch = useDispatch();
  const [newline, setnewline] = useState([
    {
      description: "",
      amount: "",
      commissionAmount: "",
      commissionPercentage: "",
      taxpercent: "10",
      taxamount: "",
      totalamount: "",
    },
  ]);
  const [incomesharing, setincomeSharing] = useState([
    {
      id: 0,
      incomeShareHolderId: 0,
      incomeSharingType: "IncomeSharing",
      incomeSharingAmount: 0,
      incomesharingcommissionamount: 0,
      incomesharingreferalpercentage: 0, // storing value for incomesharingamount not used in calculation
      incomesharingcommissionpercentage: 0, // storing value for incomesharingamount not used in calculation
      incomeSharingName: "",
      incomeSharingtaxpercent: 10,
      incomeSharingtaxamount: 0,
      incomeSharingAmountOriginal: 0,
      incomeSharingemail: "",
      note: "",
      payableInvoiceId: 0,
    },
  ]);

  const [paymentReceive, setPaymentReceive] = useState([
    {
      id: 0,
      amount: "",
      date: datenow,
      invoiceId: "",
      notes: "",
      paymentMode: "EFT",
      status: 1,
      filedata: null,
    },
  ]);

  const [currency1, setcurrency] = useState("AUD");
  const params = useParams();
  const [amountvalues, setamountvalues] = useState([]);
  const [taxamountvalues, settaxamountvalues] = useState([]);
  const [commissionamountvalues, setcommissionamountvalues] = useState([]);
  const [totalamountvalues, settotalamountvalues] = useState([]);
  const [totalamt, settotalamt] = useState(0);
  const [totalAmountPaid, setTotalPaid] = useState(0);
  const [totaltax, settotaltax] = useState(0);
  const [totalcommission, settotalcommission] = useState(0);
  const [netincomevalue, setnetincomevalue] = useState(0);
  const [originalnetincomevalue, setoriginalnetincomevalue] = useState(0);
  const [netincomedataentries, setnetincomedataentries] = useState([]);
  const [totalamttax, settotalamttax] = useState(0);
  const [service, setservice] = useState();
  const [enquiryinstitutionid, setenquiryinstitutionid] = useState();
  const [enquirycoursename, setenquirycoursename] = useState();
  const [enquirycourseid, setenquirycourseid] = useState();
  const [payable, setpayable] = useState("Receivable");
  const [discountpercent, setdiscountpercent] = useState("Fixed");
  const [commision, setcommision] = useState();
  const [incomeSubType, setIncomeSubType] = useState("Admission");
  const [discountAmount, setdiscountAmount] = useState(0);
  const [discountpercentamount, setdiscountpercentamount] = useState(0);
  const [enquiryname2, setenquiryname2] = useState();
  const [externalid, setenquiryid] = useState("");
  const isw_adminId = localStorage.getItem("isw_adminId");
  const selected_client_username = localStorage.getItem(
    "selected_client_username"
  );
  const [enquiryIntake, setEnquiryIntake] = useState();

  const [enquiryOffice, setEnquiryOffice] = useState();
  const [enquiryWorkflowType, setEnquiryWorkflowType] = useState();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const date = moment();
  const datepast16 = date.add(14, "days").format("YYYY-MM-DD");
  const [duedate, setduedate] = useState(datepast16);
  const [paidinvoicedate, setpaidinvoicedate] = useState(null);
  const [selectedAgent, setSelectedAgent] = useState();
  const [selectedAgentName, setSelectedAgentName] = useState();
  const [getinvoiceId, setinvoiceId] = useState();
  const user = useSelector(selectUser);

  const [statusmodal, setstatusmodal] = useState(false);
  const closestatusModal = () => setstatusmodal(false);
  const openstatusmodal = () => {
    setstatusmodal(true);
  };

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        navigate("/");
      }
    });
    hideListItems(user.isw_role_permissions);
    getClientDetails();
    getEnquiry();
    // getAllAgent();
    getIntake();
    getPermissions();
  }, []);

  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);

    if (check1pointpermission(306, permission_obj, resource_obj)) {
    } else {
      navigate("/errorPage");
    }
  };

  const [clientDetail1, setclientDetail1] = useState();
  const getClientDetails = () => {
    axios
      .get(`${base_url.api3}/getClientById`, {
        headers: {
          Authorization: jwtToken,
          clientId: params.client_id,
        },
      })
      .then((res) => {
        setclientDetail1(res.data.data);
        if (res.data.data.clientDetail?.superAgent.length > 0) {
          getAllAgent(res.data.data.clientDetail?.superAgent);
        } else {
          getAllAgent(res.data.data.clientDetail?.subAgent);
        }
        //console.log("Agent data-->", res.data.data.clientDetail);
        setbackdropopen(false);
      })
      .catch((error) => {
        getAllAgent();
        handle403error(error);
      });
  };
  const [enquiryname, setEnquiryName] = useState();
  const [showEnquiryName, setShowEnquiryName] = useState();
  const getEnquiry = () => {
    axios
      .get(`${base_url.api2}/findEnquiryByClientId`, {
        headers: {
          Authorization: jwtToken,
          clientId: params.client_id,
        },
      })
      .then((res) => {
        let enqData = res.data.data;
        let arr = [];
        enqData.forEach((ele) => {
          if (
            (ele.workflowType === "Admission" &&
              ele.courseName != null &&
              ele.enquiryStatus !== "DiscontinueComplete") ||
            (ele.workflowType === "SkillAssessment" && ele.courseName != null)
          ) {
            ele.showName = ele.courseName;
            ele.showId = ele.courseId;
            arr.push(ele);
          }
        });
        setShowEnquiryName(arr);

        setEnquiryName(res.data.data);
        setbackdropopen(false);
      })
      .catch((error) => {
        handle403error(error);
      });
  };

  const [agent, setagent] = useState([]);
  const [backupfirstincomesharing, setbackupfirstincomesharing] = useState([]);
  const getAllAgent = (val) => {
    axios
      .get(`${base_url.api3}/findAmbassadorAndAgents`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          for (let j = 0; j < res.data.ambassadorData.length; j++) {
            setagent((prev) => [
              ...prev,
              {
                value: `${res.data.ambassadorData[j].firstName} ${res.data.ambassadorData[j].lastname} (Ambassador)`,
                id: res.data.ambassadorData[j].ambassadorId,
                email: res.data.ambassadorData[j].email,
                commissionPercentage: 0,
                referalPercentage: 0,
              },
            ]);
          }
          for (let i = 0; i < res.data.agentData.length; i++) {
            setagent((prev) => [
              ...prev,
              {
                value: `${res.data.agentData[i].name} (Agent)`,
                id: res.data.agentData[i].agentId,
                commissionPercentage:
                  res.data.agentData[i].commissionPercentage,
                referalPercentage: res.data.agentData[i].referalPercentage,
                email: res.data.agentData[i].email,
              },
            ]);
            if (val === res.data.agentData[i].email) {
              if (val == null || val == "") {
                setbackupfirstincomesharing([
                  {
                    id: 0,
                    incomeShareHolderId: res.data.agentData[i].agentId,
                    incomeSharingType: "IncomeSharing",
                    incomeSharingAmount:
                      Number(res.data.agentData[i].commissionPercentage) || 0,
                    incomesharingcommissionamount: 0,
                    incomesharingreferalpercentage:
                      Number(res.data.agentData[i].referalPercentage) || 0,
                    incomesharingcommissionpercentage:
                      Number(res.data.agentData[i].commissionPercentage) || 0,
                    incomeSharingName: "",
                    incomeSharingtaxpercent: 10,
                    incomeSharingtaxamount: 0,
                    incomeSharingAmountOriginal: 0,
                    incomeSharingemail: "",
                    note: "",
                    payableInvoiceId: 0,
                  },
                ]);
                setincomeSharing([
                  {
                    id: 0,
                    incomeShareHolderId: res.data.agentData[i].agentId,
                    incomeSharingType: "IncomeSharing",
                    incomeSharingAmount:
                      Number(res.data.agentData[i].commissionPercentage) || 0,
                    incomesharingcommissionamount: 0,
                    incomesharingreferalpercentage:
                      Number(res.data.agentData[i].referalPercentage) || 0,
                    incomesharingcommissionpercentage:
                      Number(res.data.agentData[i].commissionPercentage) || 0,
                    incomeSharingName: "",
                    incomeSharingtaxpercent: 10,
                    incomeSharingtaxamount: 0,
                    incomeSharingAmountOriginal: 0,
                    incomeSharingemail: "",
                    note: "",
                    payableInvoiceId: 0,
                  },
                ]);
              } else {
                setbackupfirstincomesharing([
                  {
                    id: 0,
                    incomeShareHolderId: res.data.agentData[i].agentId,
                    incomeSharingType: "IncomeSharing",
                    incomeSharingAmount:
                      Number(res.data.agentData[i].commissionPercentage) || 0,
                    incomesharingcommissionamount: 0,
                    incomesharingreferalpercentage:
                      Number(res.data.agentData[i].referalPercentage) || 0,
                    incomesharingcommissionpercentage:
                      Number(res.data.agentData[i].commissionPercentage) || 0,
                    incomeSharingName: `${res.data.agentData[i].name} (Agent)`,
                    incomeSharingtaxpercent: 10,
                    incomeSharingtaxamount: 0,
                    incomeSharingAmountOriginal: 0,
                    incomeSharingemail: res.data.agentData[i].email,
                    note: "",
                    payableInvoiceId: 0,
                  },
                ]);
                setincomeSharing([
                  {
                    id: 0,
                    incomeShareHolderId: res.data.agentData[i].agentId,
                    incomeSharingType: "IncomeSharing",
                    incomeSharingAmount:
                      Number(res.data.agentData[i].commissionPercentage) || 0,
                    incomesharingcommissionamount: 0,
                    incomesharingreferalpercentage:
                      Number(res.data.agentData[i].referalPercentage) || 0,
                    incomesharingcommissionpercentage:
                      Number(res.data.agentData[i].commissionPercentage) || 0,
                    incomeSharingName: `${res.data.agentData[i].name} (Agent)`,
                    incomeSharingtaxpercent: 10,
                    incomeSharingtaxamount: 0,
                    incomeSharingAmountOriginal: 0,
                    incomeSharingemail: res.data.agentData[i].email,
                    note: "",
                    payableInvoiceId: 0,
                  },
                ]);
              }
            }
          }

          // setagent(res.data.data);
        }
        setbackdropopen(false);
      })
      .catch((error) => {
        handle403error(error);
      });
  };

  //console.log("agent-->", incomesharing);

  const [intakeList, setIntakeList] = useState();
  const getIntake = () => {
    axios
      .get(`${base_url.api1}/getAllIntake`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        let intakeData = res.data.data;
        let intakeObj = [];
        for (let i = 0; i < intakeData.length; i++) {
          let data = {
            createdIntake: moment(intakeData[i].createdIntake).format(
              "YYYY-MM"
            ),
            val: moment(intakeData[i].name).format("YYYYMM"),
            id: intakeData[i].id,
            name: intakeData[i].name,
            status: intakeData[i].status,
          };
          intakeObj.push(data);
        }
        let newintakeObj = intakeObj.sort(function (a, b) {
          return b.val - a.val;
        });
        setIntakeList(newintakeObj);
        setbackdropopen(false);
      })
      .catch((error) => {
        // toast.error(JSON.stringify(error.response.data.message));
        //console.log(JSON.stringify(error.response));
        handle403error(error);
      });
  };

  const [statusoption, setstatus] = useState("Unpaid");
  const handleStatus = (event) => {
    setstatus(event.target.value);
  };
  const selectcurrency = (e) => {
    setcurrency(e.target.value);
  };

  const hideListItems = (elem) => {
    const permission_obj = [];
    for (let i = 0; i < elem.length; i++) {
      permission_obj.push(elem[i].resourceId);
    }
    setrolepermission(permission_obj);
  };

  const checkrolepermission = (x, y) => {
    for (let i = x; i <= y; i++) {
      if (rolepermission.includes(i)) {
        return true;
      }
    }
    return false;
  };

  const handleincomesubtype = (e) => {
    setIncomeSubType(e.target.value);
    let arr = [];
    if (e.target.value === "Migration") {
      if (enquiryname) {
        enquiryname.forEach((ele) => {
          if (ele.workflowType === "Migration") {
            ele.showName = ele.visaType;
            ele.showId = ele.visaId;
            arr.push(ele);
          }
        });
      }
    } else if (e.target.value === "Insurance") {
      if (enquiryname) {
        enquiryname.forEach((ele) => {
          if (ele.workflowType === "Insurance") {
            ele.showName = ele.insuranceType;
            ele.showId = ele.insuranceId;
            arr.push(ele);
          }
        });
      }
    } else if (e.target.value === "General") {
      if (enquiryname) {
        enquiryname.forEach((ele) => {
          if (ele.workflowType === "SkillAssessment") {
            ele.showName = ele.asssessmentType;
            ele.showId = ele.courseId;
            arr.push(ele);
          }
        });
      }
    } else {
      enquiryname.forEach((ele) => {
        if (
          (ele.workflowType === "Admission" &&
            ele.courseName != null &&
            ele.enquiryStatus !== "DiscontinueComplete") ||
          (ele.workflowType === "SkillAssessment" && ele.courseName != null)
        ) {
          ele.showName = ele.courseName;
          ele.showId = ele.courseId;
          arr.push(ele);
        }
      });
    }
    setShowEnquiryName(arr);
    if (commision !== "General Invoice") {
      if (e.target.value === "Migration" || e.target.value === "Insurance") {
        setcommision("");
        cleardata();
      }
    }
  };
  const addClick = () => {
    setamountvalues((prev) => [...prev, ""]);
    settaxamountvalues((prev) => [...prev, ""]);
    settotalamountvalues((prev) => [...prev, ""]);
    setcommissionamountvalues((prev) => [...prev, ""]);
  };
  const addPaymentClick = () => {
    setTotalPaidValues((prev) => [...prev, ""]);
  };

  const handlelinechange = (e, i) => {
    var lines = [...newline];
    lines[i].description = e.target.value;
    setnewline(lines);
  };
  const handletaxpercentchange = (e, i) => {
    var lines = [...newline];
    lines[i].taxpercent = Number(e.target.value);
    if (commision === "General Invoice") {
      lines[i].taxamount =
        (Number(lines[i].amount) * Number(lines[i].taxpercent)) / 100;
    } else {
      lines[i].taxamount =
        (Number(lines[i].commissionAmount) * Number(lines[i].taxpercent)) / 100;
    }
    lines[i].totalamount =
      Number(lines[i].amount) +
      Number(lines[i].taxamount) +
      Number(lines[i].commissionAmount);
    // For tax amount
    let taxamount = [...taxamountvalues];
    taxamount[i] = Number(lines[i].taxamount);
    settaxamountvalues(taxamount);
    settotaltax(taxamount?.reduce((amount, item) => item + amount, 0));

    // For total amount includingtax
    if (commision === "General Invoice") {
      let totalamount = [...totalamountvalues];
      totalamount[i] = Number(lines[i].totalamount);
      settotalamountvalues(totalamount);
      settotalamttax(totalamount?.reduce((amount, item) => item + amount, 0));
    }
    setnewline(lines);
  };
  const handleamountChange = (e, i) => {
    var lines = [...newline];
    lines[i].amount = Number(e.target.value);

    if (commision === "General Invoice") {
      lines[i].taxamount =
        (Number(lines[i].amount) * Number(lines[i].taxpercent)) / 100;
      lines[i].totalamount =
        Number(lines[i].amount) + Number(lines[i].taxamount);
    } else {
      lines[i].commissionAmount =
        (Number(lines[i].amount) * Number(lines[i].commissionPercentage)) / 100;
      lines[i].taxamount =
        (Number(lines[i].commissionAmount) * Number(lines[i].taxpercent)) / 100;
    }
    // For tax amount
    let taxamount = [...taxamountvalues];
    taxamount[i] = Number(lines[i].taxamount);
    settaxamountvalues(taxamount);
    settotaltax(taxamount?.reduce((amount, item) => item + amount, 0));

    // For amount excluding tax
    let amount = [...amountvalues];
    amount[i] = Number(lines[i].amount);
    setamountvalues(amount);
    settotalamt(amount?.reduce((amount, item) => item + amount, 0));

    // For Commission Amount
    let commissionamount = [...commissionamountvalues];
    commissionamount[i] = Number(lines[i].commissionAmount);
    setcommissionamountvalues(commissionamount);
    settotalcommission(
      commissionamount?.reduce((amount, item) => item + amount, 0)
    );

    // For discount amount

    let discountamount = 0;
    if (discountpercent !== "Fixed") {
      if (commision !== "General Invoice") {
        let totalAmountfordiscount = Number(
          amount?.reduce((amount, item) => item + amount, 0)
        );
        // let totalAmountfordiscount = Number(
        //   commissionamount?.reduce((amount, item) => item + amount, 0)
        // );
        discountamount =
          (Number(totalAmountfordiscount) * Number(discountpercentamount)) /
          100;
        setdiscountAmount(discountamount);
      }
    } else if (commision !== "General Invoice") {
      discountamount = discountAmount;
    }
    if (commision === "General Invoice") {
      setoriginalnetincomevalue(
        amount?.reduce((amount, item) => item + amount, 0)
      );
      if (
        incomesharing.length > 0 &&
        incomesharing[0].incomesharingcommissionamount > 0
      ) {
        changeincomesharingdata(
          amount?.reduce((amount, item) => item + amount, 0)
        );
      } else {
        setnetincomevalue(amount?.reduce((amount, item) => item + amount, 0));
      }
    } else {
      setoriginalnetincomevalue(
        commissionamount?.reduce((amount, item) => item + amount, 0) -
        discountamount
      );
      if (
        incomesharing.length > 0 &&
        incomesharing[0].incomesharingcommissionamount > 0
      ) {
        changeincomesharingdata(
          commissionamount?.reduce((amount, item) => item + amount, 0) -
          discountamount
        );
      } else {
        setnetincomevalue(
          commissionamount?.reduce((amount, item) => item + amount, 0) -
          discountamount
        );
      }
    }

    // For total amount includingtax
    if (commision === "General Invoice") {
      let totalamount = [...totalamountvalues];
      totalamount[i] = Number(lines[i].totalamount);
      settotalamountvalues(totalamount);
      settotalamttax(totalamount?.reduce((amount, item) => item + amount, 0));
    }

    setnewline(lines);
  };
  const handlecommisionChange = (e, i) => {
    var lines = [...newline];
    lines[i].commissionPercentage = Number(e.target.value);
    lines[i].commissionAmount =
      (Number(lines[i].amount) * Number(lines[i].commissionPercentage)) / 100;
    lines[i].taxamount =
      (lines[i].commissionAmount * lines[i].taxpercent) / 100;

    let commissionamount = [...commissionamountvalues];
    commissionamount[i] = Number(lines[i].commissionAmount);
    setcommissionamountvalues(commissionamount);
    settotalcommission(
      commissionamount?.reduce((amount, item) => item + amount, 0)
    );
    // For total amount includingtax
    if (commision === "General Invoice") {
      let totalamount = [...totalamountvalues];
      totalamount[i] = Number(lines[i].totalamount);
      settotalamountvalues(totalamount);
      settotalamttax(totalamount?.reduce((amount, item) => item + amount, 0));
    }

    //for tax amount
    let taxamount = [...taxamountvalues];
    taxamount[i] = Number(lines[i].taxamount);
    settaxamountvalues(taxamount);
    settotaltax(taxamount?.reduce((amount, item) => item + amount, 0));

    // For discount amount
    // let discountamount = 0;
    // if (discountpercent !== "Fixed") {
    //   if (commision !== "General Invoice") {
    //     let totalAmountfordiscount = Number(
    //       commissionamount?.reduce((amount, item) => item + amount, 0)
    //     );
    //     discountamount =
    //       (Number(totalAmountfordiscount) * Number(discountpercentamount)) /
    //       100;
    //     setdiscountAmount(discountamount);
    //   }
    // } else if (commision !== "General Invoice") {
    //   discountamount = discountAmount;
    // }
    let discountamount = discountAmount;
    setoriginalnetincomevalue(
      commissionamount?.reduce((amount, item) => item + amount, 0) -
      discountamount
    );
    if (
      incomesharing.length > 0 &&
      incomesharing[0].incomesharingcommissionamount > 0
    ) {
      changeincomesharingdata(
        commissionamount?.reduce((amount, item) => item + amount, 0) -
        discountamount
      );
    } else {
      setnetincomevalue(
        commissionamount?.reduce((amount, item) => item + amount, 0) -
        discountamount
      );
    }
    setnewline(lines);
  };

  //Changing IncomeSharing Data based on change in invoicedata
  const changeincomesharingdata = (value) => {
    let lines = [...incomesharing];
    let val2 = [...netincomedataentries];

    for (let i = 0; i < lines.length; i++) {
      // lines[i].incomesharingcommissionamount =
      //   (Number(value) * Number(lines[i].incomeSharingAmount)) / 100;
      // lines[i].incomeSharingtaxamount =
      //   (Number(lines[i].incomesharingcommissionamount) *
      //     Number(lines[i].incomeSharingtaxpercent)) /
      //   100;
      // lines[i].incomeSharingAmountOriginal =
      //   Number(lines[i].incomesharingcommissionamount) +
      //   Number(lines[i].incomeSharingtaxamount);
      val2[i] = Number(lines[i].incomesharingcommissionamount);
    }
    setnetincomedataentries(val2);
    setnetincomevalue(value - val2?.reduce((amount, item) => item + amount, 0));
    // setincomeSharing(lines);
  };
  const handlecommisionChange2 = (e) => {
    setcommision(e.target.value);
    cleardata();
  };

  const cleardata = () => {
    setnewline([
      {
        description: "",
        amount: "",
        commissionAmount: "",
        commissionPercentage: "",
        taxpercent: "10",
        taxamount: "",
        totalamount: "",
      },
    ]);
    if (backupfirstincomesharing.length > 0) {
      setincomeSharing(backupfirstincomesharing);
    } else {
      setincomeSharing([
        {
          id: 0,
          incomeShareHolderId: 0,
          incomeSharingType: "IncomeSharing",
          incomeSharingAmount: 0,
          incomesharingcommissionamount: 0,
          incomesharingreferalpercentage: 0, // storing value for incomesharingamount not used in calculation
          incomesharingcommissionpercentage: 0, // storing value for incomesharingamount not used in calculation
          incomeSharingName: "",
          incomeSharingtaxpercent: 10,
          incomeSharingtaxamount: 0,
          incomeSharingAmountOriginal: 0,
          incomeSharingemail: "",
          note: "",
          payableInvoiceId: 0,
        },
      ]);
    }
    setamountvalues([]);
    settaxamountvalues([]);
    settotalamountvalues([]);
    setcommissionamountvalues([]);
    settotalamt(0);
    settotaltax(0);
    setdiscountpercent("Fixed");
    setdiscountAmount(0);
    setdiscountpercentamount(0);
    settotalcommission(0);
    setnetincomevalue(0);
    setoriginalnetincomevalue(0);
    settotalamttax(0);
  };

  // Handling Discount

  const handleDiscountpercent = (e) => {
    setdiscountpercentamount(e.target.value);
    let discountamount = 0;
    if (commision !== "General Invoice") {
      if (discountpercent === "Fixed") {
        setdiscountAmount(e.target.value);
        setoriginalnetincomevalue(totalcommission - Number(e.target.value));
        if (
          incomesharing.length > 0 &&
          incomesharing[0].incomesharingcommissionamount > 0
        ) {
          changeincomesharingdata(
            Number(totalcommission - Number(e.target.value))
          );
        } else {
          setnetincomevalue(Number(totalcommission - Number(e.target.value)));
        }
      } else {
        discountamount = (Number(totalamt) * Number(e.target.value)) / 100;
        // (Number(totalcommission) * Number(e.target.value)) / 100;
        setdiscountAmount(discountamount);
        setoriginalnetincomevalue(Number(totalcommission - discountamount));
        if (
          incomesharing.length > 0 &&
          incomesharing[0].incomesharingcommissionamount > 0
        ) {
          changeincomesharingdata(Number(totalcommission - discountamount));
        } else {
          setnetincomevalue(Number(totalcommission - discountamount));
        }
      }
    }
  };
  const handleDiscountfixed = (e) => {
    setdiscountpercentamount(0);
    setoriginalnetincomevalue(Number(totalcommission));
    if (
      incomesharing.length > 0 &&
      incomesharing[0].incomesharingcommissionamount > 0
    ) {
      changeincomesharingdata(Number(totalcommission));
    } else {
      setnetincomevalue(Number(netincomevalue) + Number(discountAmount));
    }
    setdiscountAmount(0);
    setdiscountpercent(e.target.value);
  };

  const handleincomesharingname = (e, i) => {
    const index = e.target.selectedIndex;
    const optionElement = e.target.childNodes[index];
    const selectedcourseId = optionElement.getAttribute("id");
    const selectedcommisionpercentage = optionElement.getAttribute(
      "commissionpercentage"
    );
    const selectedreferalpercentage =
      optionElement.getAttribute("referalpercentage");
    const selectedcommisionemail = optionElement.getAttribute("email");

    //---------------------------> Original Income Sharing Array
    let lines = [...incomesharing];
    lines[i].incomeSharingName = e.target.value;
    lines[i].incomeShareHolderId = Number(selectedcourseId);
    lines[i].incomeSharingemail = selectedcommisionemail;
    if (lines[i].incomeSharingType === "IncomeSharing") {
      lines[i].incomeSharingAmount = Number(selectedcommisionpercentage);
    } else {
      lines[i].incomeSharingAmount = Number(selectedreferalpercentage);
    }
    lines[i].incomesharingreferalpercentage = Number(selectedreferalpercentage);
    lines[i].incomesharingcommissionpercentage = Number(
      selectedcommisionpercentage
    );
    // ------------------------>Calculating Income amount
    // let incomeamount =
    //   (Number(originalnetincomevalue) * Number(lines[i].incomeSharingAmount)) /
    //   100;
    // lines[i].incomeSharingtaxamount =
    //   (incomeamount * lines[i].incomeSharingtaxpercent) / 100;
    // lines[i].incomesharingcommissionamount = incomeamount;
    // lines[i].incomeSharingAmountOriginal =
    //   incomeamount + (incomeamount * lines[i].incomeSharingtaxpercent) / 100;

    // let val2 = [...netincomedataentries];
    // val2[i] = Number(incomeamount);
    // setnetincomedataentries(val2);
    // setnetincomevalue(
    //   originalnetincomevalue - val2?.reduce((amount, item) => item + amount, 0)
    // );

    setincomeSharing(lines);
  };

  const handleincomesharingtype = (e, i) => {
    var lines = [...incomesharing];
    lines[i].incomeSharingType = e.target.value;
    if (e.target.value === "IncomeSharing") {
      lines[i].incomeSharingAmount = lines[i].incomesharingcommissionpercentage;
    } else {
      lines[i].incomeSharingAmount = lines[i].incomesharingreferalpercentage;
    }
    // ------------------------>Calculating Income amount
    // let incomeamount =
    //   (Number(originalnetincomevalue) * Number(lines[i].incomeSharingAmount)) /
    //   100;
    // lines[i].incomeSharingtaxamount =
    //   (incomeamount * lines[i].incomeSharingtaxpercent) / 100;
    // lines[i].incomesharingcommissionamount = incomeamount;
    // lines[i].incomeSharingAmountOriginal =
    //   incomeamount + (incomeamount * lines[i].incomeSharingtaxpercent) / 100;

    // let val2 = [...netincomedataentries];
    // val2[i] = Number(incomeamount);
    // setnetincomedataentries(val2);
    // setnetincomevalue(
    //   originalnetincomevalue - val2?.reduce((amount, item) => item + amount, 0)
    // );

    setincomeSharing(lines);
  };
  // const handleincomesharingamount = (e, i) => {
  //   var lines = [...incomesharing];
  //   lines[i].incomeSharingAmount = Number(e.target.value);
  //   let incomeamount =
  //     (Number(originalnetincomevalue) * Number(e.target.value)) / 100;
  //   lines[i].incomesharingcommissionamount = incomeamount;
  //   lines[i].incomeSharingtaxamount =
  //     (incomeamount * lines[i].incomeSharingtaxpercent) / 100;
  //   lines[i].incomeSharingAmountOriginal =
  //     incomeamount + (incomeamount * lines[i].incomeSharingtaxpercent) / 100;
  //   let val2 = [...netincomedataentries];
  //   val2[i] = Number(incomeamount);
  //   setnetincomedataentries(val2);
  //   setnetincomevalue(
  //     originalnetincomevalue - val2?.reduce((amount, item) => item + amount, 0)
  //   );
  //   setincomeSharing(lines);
  // };
  const handleincomesharingComissionamount = (e, i) => {
    var lines = [...incomesharing];
    lines[i].incomesharingcommissionamount = Number(e.target.value);
    // let incomeamount =
    //   (Number(e.target.value) / Number(originalnetincomevalue)) * 100;
    // lines[i].incomeSharingAmount = incomeamount;
    lines[i].incomeSharingtaxamount =
      (lines[i].incomesharingcommissionamount *
        lines[i].incomeSharingtaxpercent) /
      100;
    lines[i].incomeSharingAmountOriginal =
      lines[i].incomesharingcommissionamount +
      (lines[i].incomesharingcommissionamount *
        lines[i].incomeSharingtaxpercent) /
      100;
    let val2 = [...netincomedataentries];
    val2[i] = Number(lines[i].incomesharingcommissionamount);
    setnetincomedataentries(val2);
    setnetincomevalue(
      originalnetincomevalue - val2?.reduce((amount, item) => item + amount, 0)
    );
    setincomeSharing(lines);
  };
  const handleincomesharingtax = (e, i) => {
    var lines = [...incomesharing];
    lines[i].incomeSharingtaxpercent = Number(e.target.value);
    let incomeamount =
      (Number(lines[i].incomesharingcommissionamount) *
        Number(e.target.value)) /
      100;
    lines[i].incomeSharingtaxamount = incomeamount;
    lines[i].incomeSharingAmountOriginal =
      incomeamount + Number(lines[i].incomesharingcommissionamount);
    setincomeSharing(lines);
  };
  const handleincomesharingnote = (e, i) => {
    var lines = [...incomesharing];
    lines[i].note = e.target.value;
    setincomeSharing(lines);
  };

  // Payment Received Change-------------------------------------------
  const [totalPaidValues, setTotalPaidValues] = useState([]);
  const handleTotalPaid = (e, i) => {
    var lines = [...paymentReceive];
    lines[i].amount = Number(e.target.value);
    setPaymentReceive(lines);

    let total_paid_data = [...totalPaidValues];
    total_paid_data[i] = lines[i].amount;
    setTotalPaidValues(total_paid_data);

    let order_price_data2 = 0;
    let order_price_data = paymentReceive.map((p) => {
      order_price_data2 += Number(p.amount);
      let data2 = order_price_data2;
      return data2;
    });
    let order_price = order_price_data[order_price_data.length - 1];
    setTotalPaid(order_price);
  };

  const handlePaymentReceiveDate = (date, i) => {
    var lines = [...paymentReceive];
    lines[i].date = date;
    setPaymentReceive(lines);
  };

  const handlePaymentReceiveType = (e, i) => {
    var lines = [...paymentReceive];
    lines[i].paymentMode = e.target.value;
    setPaymentReceive(lines);
  };

  const handlePaymentReceiveNotes = (e, i) => {
    var lines = [...paymentReceive];
    lines[i].notes = e.target.value;
    setPaymentReceive(lines);
  };

  // const [filesData, setFiles] = useState([]);
  const handlePaymentReceiveAttachment = (e, i) => {
    var lines = [...paymentReceive];
    // let file = e.target.files[0];
    lines[i].filedata = e.target.files;

    // formData.append("invoiceAttachment-"+i, e.target.files[0], e.target.files[0].name);
    // lines[i].attachment = formData.append("invoiceAttachment", e.target.files[0], e.target.files[0].name);
    // lines[i].attachmentName = e.target.files[0].name;
    setPaymentReceive(lines);
  };
  // Payment Received Change-------------------------------------------

  const changeService = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const selectedenquiryId = optionElement.getAttribute("id");
    const selectedenquirylabel = optionElement.getAttribute("workflowtype");
    const selectedenquiryintake = optionElement.getAttribute("intake");
    const selectedenquiryoffice = optionElement.getAttribute("officeid");
    const selectedenquiryexternalid = optionElement.getAttribute("externalid");
    const selectedenquirycommission = optionElement.getAttribute(
      "commissionpercentage"
    );
    const selectedenquiryinstitution =
      optionElement.getAttribute("institutionname");
    const selectedenquiryinstitutionid =
      optionElement.getAttribute("institutionid");
    const selectedenquirycourse = optionElement.getAttribute("coursename");
    const selectedenquirycourseid = optionElement.getAttribute("courseid");
    setenquirycoursename(selectedenquirycourse);
    setenquirycourseid(selectedenquirycourseid);
    const selectedenquirysuperagent = optionElement.getAttribute("superagent");
    const selectedEnquirySubAgent = optionElement.getAttribute("subagent");
    const selectedEnquiryAssessmentBody =
      optionElement.getAttribute("assementBody");
    setEnquiryIntake(selectedenquiryintake);
    setEnquiryOffice(selectedenquiryoffice);
    setEnquiryWorkflowType(selectedenquirylabel);
    if (selectedenquiryId !== null) {
      setenquiryname2(selectedenquiryId);
      if (incomeSubType == "General") {
        setservice(selectedEnquiryAssessmentBody);
      } else {
        setservice(selectedenquiryinstitution);
      }
      setenquiryinstitutionid(selectedenquiryinstitutionid);
      setenquiryid(selectedenquiryexternalid);
      var lines = [...newline];
      var lines2 = [...incomesharing];
      lines = [
        {
          description: "",
          amount: "",
          commissionAmount: "",
          commissionPercentage: "",
          taxpercent: "10",
          taxamount: "",
          totalamount: "",
        },
      ];
      if (backupfirstincomesharing.length > 0) {
        lines2 = backupfirstincomesharing;
      } else {
        lines2 = [
          {
            id: 0,
            incomeShareHolderId: 0,
            incomeSharingType: "IncomeSharing",
            incomeSharingAmount: 0,
            incomesharingcommissionamount: 0,
            incomesharingreferalpercentage: 0, // storing value for incomesharingamount not used in calculation
            incomesharingcommissionpercentage: 0, // storing value for incomesharingamount not used in calculation
            incomeSharingName: "",
            incomeSharingtaxpercent: 0,
            incomeSharingtaxamount: 0,
            incomeSharingAmountOriginal: 0,
            incomeSharingemail: "",
            note: "",
            payableInvoiceId: 0,
          },
        ];
      }
      if (selectedenquirycommission !== null) {
        lines[0].commissionPercentage = Number(selectedenquirycommission);
      } else {
        lines[0].commissionPercentage = 0;
      }
      setnewline(lines);
      setamountvalues([]);
      settaxamountvalues([]);
      settotalamountvalues([]);
      setcommissionamountvalues([]);
      settotalamt(0);
      settotaltax(0);
      setdiscountpercent("Fixed");
      setdiscountAmount(0);
      setdiscountpercentamount(0);
      settotalcommission(0);
      setnetincomevalue(0);
      setoriginalnetincomevalue(0);
      settotalamttax(0);
      if (selectedenquirysuperagent?.split(" (")[1]?.slice(0, -1)) {
        var particularsuperagent = agent.find((element) => {
          if (
            element.value ===
            `${selectedenquirysuperagent?.split(" (")[1]?.slice(0, -1)} (Agent)`
          ) {
            return element;
          }
        });
        if (particularsuperagent) {
          //console.log(particularsuperagent.email);
          setSelectedAgent(particularsuperagent.email);
          setSelectedAgentName(particularsuperagent.value);
          lines2[0].incomeSharingName = particularsuperagent.value;
          //console.log("1129---->", particularsuperagent.value);
          lines2[0].incomeShareHolderId = Number(particularsuperagent.id);
          lines2[0].incomeSharingemail = particularsuperagent.email;
          if (lines2[0].incomeSharingType === "IncomeSharing") {
            lines2[0].incomeSharingAmount = Number(
              particularsuperagent.commissionPercentage
            );
          } else {
            lines2[0].incomeSharingAmount = Number(
              particularsuperagent.referalPercentage
            );
          }
          lines2[0].incomesharingreferalpercentage = Number(
            particularsuperagent.referalPercentage
          );
          lines2[0].incomesharingcommissionpercentage = Number(
            particularsuperagent.commissionPercentage
          );
        }
      }

      if (selectedEnquirySubAgent?.split(" (")[1]?.slice(0, -1)) {
        var particularSubAgent = agent.find((element) => {
          if (
            element.value ===
            `${selectedEnquirySubAgent?.split(" (")[1]?.slice(0, -1)} (Agent)`
          ) {
            return element;
          }
        });
        if (particularSubAgent.value != null) {
          lines2[0].incomeSharingName = particularSubAgent.value;
          lines2[0].incomeShareHolderId = Number(particularSubAgent.id);
          lines2[0].incomeSharingemail = particularSubAgent.email;
          lines2[0].incomeSharingAmount = Number(
            particularSubAgent.incomeSharingType === "IncomeSharing"
              ? particularSubAgent.commissionPercentage
              : particularSubAgent.referalPercentage
          );
          lines2[0].incomesharingreferalpercentage = Number(
            particularSubAgent.referalPercentage
          );
          lines2[0].incomesharingcommissionpercentage = Number(
            particularSubAgent.commissionPercentage
          );
          setSelectedAgent(particularSubAgent.email);
        }
      }
      setincomeSharing(lines2);
    }
  };

  // Check the length of the agent array and set the selected agent accordingly
  const isSingleAgent = agent && agent.length === 1;

  const onSubmit = (data2) => {
    if (Number(totalAmountPaid) === 0) {
      if (statusoption === "Paid" || statusoption === "PartiallyPaid") {
        openstatusmodal();
        return;
      }
    } else {
      if (Number(gettotaldue()) > 0) {
        if (statusoption === "Unpaid" || statusoption === "Paid") {
          openstatusmodal();
          return;
        }
      } else {
        if (statusoption === "Unpaid" || statusoption === "PartiallyPaid") {
          openstatusmodal();
          return;
        }
      }
    }
    setbackdropopen(true);
    const isoDateString = new Date().toISOString();
    const datenow = isoDateString.split(".")[0];
    let clientId = Number(params.client_id);
    let clientEmail = clientDetail1?.email;
    let clientUsername =
      `${clientDetail1?.firstName}` +
      ` ` +
      `${clientDetail1?.middleName}` +
      ` ` +
      `${clientDetail1?.lastName}`;
    let commisionType = data2.commisionType;
    let currency = currency1;
    let comment = data2.comment;
    // tax = currency1;
    let discountAmount2 = Number(discountAmount);
    let discountPercentageType = discountpercent;
    let discountPercentage = Number(discountpercentamount);
    let enquiryId = Number(data2.enquiryId);
    let enquiryName = enquiryname2;
    let externalId = externalid;
    let id = 0;
    let incomeSharing = [];
    let incomeSharedAmount = 0;
    if (
      incomesharing.length > 0 &&
      incomesharing[0]?.incomesharingcommissionamount > 0
    ) {
      for (let i = 0; i < incomesharing.length; i++) {
        let obj = {
          id: 0,
          incomeShareHolderId: incomesharing[i].incomeShareHolderId,
          incomeSharingAmount: incomesharing[i].incomeSharingAmountOriginal,
          incomeSharingCommissionPercent: incomesharing[i].incomeSharingAmount,
          incomeSharingName: incomesharing[i].incomeSharingName,
          incomeSharingType: incomesharing[i].incomeSharingType,
          notes: incomesharing[i].note,
          sharingCommissionAmount:
            incomesharing[i].incomesharingcommissionamount,
          sharingTaxPercentage: incomesharing[i].incomeSharingtaxpercent,
          sharingTaxAmount: incomesharing[i].incomeSharingtaxamount,
          agentIncomeSharingCommissionPercent:
            incomesharing[i].incomesharingcommissionpercentage,
          agentIncomeSharingReferalPercent:
            incomesharing[i].incomesharingreferalpercentage,
        };
        incomeSharing.push(obj);
        incomeSharedAmount += Number(
          incomesharing[i].incomeSharingAmountOriginal
        );
      }
    }
    let incomeType = data2.incomeType;
    let institutionId = Number(enquiryinstitutionid);
    let intake = enquiryIntake;
    let invoiceCreatedBy = Number(isw_adminId);
    let invoiceCreatedDate = created_date;
    let addedOn = datenow;
    let updatedOn = datenow;
    let invoiceDetails = [];
    let appenddescription = "";
    for (let i = 0; i < newline.length; i++) {
      let descriptiondata = `description-${i}`;
      let amountdata = `amount-${i}`;
      let commisionpercentagedata = `commissionPercentage-${i}`;
      let commisionamountdata = `commissionAmount-${i}`;
      let taxamountdata = `taxAmount-${i}`;
      let taxPercentagedata = `taxPercentage-${i}`;
      let amount = Number(data2[amountdata]);
      let commissionPercentage = Number(data2[commisionpercentagedata]);
      let commissionAmount = Number(data2[commisionamountdata]);
      let description = data2[descriptiondata];
      appenddescription = appenddescription + " " + description;
      let taxAmount = Number(data2[taxamountdata]);
      let taxPercentage = Number(data2[taxPercentagedata]);
      let totalAmount = Number(totalamountvalues[i]);
      let id = 0;
      let quantity = 1;
      invoiceDetails.push({
        amount,
        commissionPercentage,
        commissionAmount,
        description,
        id,
        quantity,
        taxAmount,
        taxPercentage,
        totalAmount,
      });
    }
    let duplicateinvoicedetails = invoiceDetails;
    let invoiceDueDate = duedate
      ? moment(duedate).format("YYYY-MM-DDThh:mm:ss")
      : null;
    let invoicePaidDate = paidinvoicedate
      ? moment(data2.paidinvoicedate).format("YYYY-MM-DDThh:mm:ss")
      : null;
    let invoiceSubType = data2.invoiceSubType;
    let invoiceType = data2.incomeType;
    let netAmount = Number(totalamt);
    let netIncome = netincomevalue;
    let officeId = Number(enquiryOffice);
    let serviceProvider = service;
    let totalAmountIncludingTax;
    if (commision === "General Invoice") {
      totalAmountIncludingTax = Number(totalamttax);
    } else if (commision === "commissionInvoiceNet") {
      totalAmountIncludingTax = Number(totalamt - totaltax - totalcommission);
    } else {
      totalAmountIncludingTax = Number(totaltax + totalcommission);
    }
    let totalDue;
    if (commision === "General Invoice") {
      totalDue =
        Number(totalamttax) - Number(totalAmountPaid) - Number(discountAmount);
    } else if (commision === "commissionInvoiceNet") {
      totalDue =
        Number(totalamt) - Number(totalAmountPaid) - Number(discountAmount);
    } else {
      totalDue =
        Number(totalcommission) + Number(totaltax) - Number(totalAmountPaid);
    }
    let totalCommission = Number(totalcommission);

    // let totalPaid = Number(data2.paymentPaid);
    let totalPaid = Number(totalAmountPaid);
    let totalTax = Number(totaltax);
    let workflowType = enquiryWorkflowType;
    let amount = parseFloat(totalAmountPaid).toFixed(2);
    let data = null;
    let agentUsername = selectedAgent;
    data = {
      agentUsername,
      clientId,
      clientEmail,
      clientUsername,
      commisionType,
      courseId: Number(enquirycourseid),
      courseName: enquirycoursename,
      currency,
      comment,
      discountAmount: discountAmount2,
      discountPercentage,
      discountPercentageType,
      enquiryId,
      enquiryName,
      externalId,
      id,
      incomeSharing,
      incomeType,
      incomeSharedAmount,
      institutionId,
      intake,
      invoiceCreatedBy,
      invoiceCreatedDate,
      invoiceDetails,
      invoiceDueDate,
      invoicePaidDate,
      invoiceSubType,
      netAmount,
      netIncome,
      officeId,
      serviceProvider,
      totalAmountIncludingTax,
      totalCommission,
      totalDue,
      totalPaid,
      totalTax,
      workflowType,
      addedOn,
      updatedOn,
    };
    if (invoicePaidDate === "Invalid date") {
      delete data["invoicePaidDate"];
    }

    axios
      .post(`${base_url.api2}/createInvoice`, data, {
        headers: {
          Authorization: jwtToken,
          invoiceStatus: statusoption,
          invoiceType: invoiceType,
        },
      })
      .then((res) => {
        setinvoiceId(res.data.data.id);
        localStorage.setItem("invoiceid", res.data.data.id);
        if (res.data.status_code == 200) {
          let payableinvoicestorage = [];
          if (
            incomeSharing.length > 0 &&
            incomeSharing[0]?.sharingCommissionAmount > 0
          ) {
            for (let i = 0; i < incomesharing.length; i++) {
              callincomesharingapi(
                data,
                incomesharing[i].incomeSharingAmountOriginal,
                incomesharing[i].incomeSharingName,
                incomesharing[i].incomeSharingAmount,
                incomesharing[i].incomesharingcommissionamount,
                incomesharing[i].incomeSharingtaxpercent,
                incomesharing[i].incomeSharingtaxamount,
                appenddescription,
                incomesharing[i].incomeSharingemail,
                i,
                payableinvoicestorage,
                res.data.data.id,
                duplicateinvoicedetails
              );
            }
          }
          if (amount !== "NaN") {
            toast.success("Success");
            let formData = new FormData();
            let arr_obj = [];
            let attachmentpresent = false;
            if (
              paymentReceive.length > 0 &&
              Number(paymentReceive[0].amount) > 0
            ) {
              paymentReceive.forEach((ele, i) => {
                // let attachmentname = [];
                if (ele.filedata && Number(ele.amount) > 0) {
                  // for (let i = 0; i < ele.filedata.length; i++) {
                  //   attachmentpresent = true;
                  //   formData.append(
                  //     "invoiceAttachment",
                  //     ele.filedata[i],
                  //     ele.filedata[i].name
                  //   );
                  // }
                  for (let i = 0; i < ele.filedata.length; i++) {
                    attachmentpresent = true;
                    // attachmentname.push(ele.filedata[i].name);
                    formData.append(
                      "invoiceAttachment",
                      ele.filedata[i],
                      ele.filedata[i].name
                    );
                  }
                }
                let obj = {};
                if (Number(ele.amount) > 0) {
                  obj = {
                    amount: ele.amount,
                    // attachmentName: attachmentname,
                    attachmentName: ele.filedata
                      ? ele["filedata"][0].name
                      : null,
                    date: ele.date
                      ? moment(ele.date).format("YYYY-MM-DDThh:mm:ss")
                      : datenow,
                    id: 0,
                    invoiceId: res.data.data.id,
                    notes: ele.notes,
                    paymentMode: ele.paymentMode,
                    status: 0,
                  };
                  arr_obj.push(obj);
                }
              });
              // if (attachmentpresent) {
              const json = JSON.stringify(arr_obj);
              const blob = new Blob([json], {
                type: "application/json",
              });
              formData.append("invoiceAttachmentData", blob);

              if (arr_obj.length > 0) {
                if (attachmentpresent) {
                  axios
                    .post(
                      `${base_url.api2}/uploadMultipleInvoiceAttachment`,
                      formData,
                      {
                        headers: {
                          Accept: "application/json",
                          "Content-Type": "multipart/form-data",
                          Authorization: jwtToken,
                          clientId: Number(params.client_id),
                          invoiceId: res.data.data.id,
                        },
                      }
                    )
                    .then((res) => {
                      const loginvoiceid = localStorage.getItem("invoiceid");
                      setbackdropopen(false);
                      if (res.data.status_code == 200) {
                        let log =
                          isw_user_name +
                          ` Created Invoice for ${selected_client_username}  Added Invoice ${loginvoiceid} .`;
                        callsaveauditapi(log, dispatch, navigate);
                        goBack();
                      } else {
                        toast.error(res.message);
                      }
                    })
                    .catch((err) => {
                      setbackdropopen(false);
                      toast.error(JSON.stringify(err?.response?.data?.message));
                    });
                } else {
                  uploadWithoutAttachment(
                    formData,
                    Number(params.client_id),
                    res.data.data.id
                  );
                }
              } else {
                setbackdropopen(false);
                // toast.success("Success");
                goBack();
              }
            } else {
              setbackdropopen(false);
              // toast.success("Success");
              goBack();
            }
          } else {
            setbackdropopen(false);
            toast.success("Success");
            goBack();
          }
        } else {
          toast.error(res.message);
          setbackdropopen(false);
        }
      })
      .catch((err) => {
        setbackdropopen(false);
        toast.error(JSON.stringify(err));
        handle403error(err);
      });
  };

  const uploadWithoutAttachment = (formdata, client_id, invoice_id) => {
    axios
      .post(`${base_url.api2}/uploadMultipleInvoiceAttachment`, formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
          Authorization: jwtToken,
          clientId: Number(client_id),
          invoiceId: invoice_id,
        },
      })
      .then((res) => {
        setbackdropopen(false);
        const loginvoiceid = localStorage.getItem("invoiceid");
        if (res.data.status_code == 200) {
          let log = ` Created Invoice for ${selected_client_username}  Added Invoice ${loginvoiceid} .`;
          callsaveauditapi(log, dispatch, navigate);
          goBack();
        } else {
          toast.error(res.message);
        }
      })
      .catch((err) => {
        setbackdropopen(false);
        toast.error(JSON.stringify(err?.response?.data?.message));
        handle403error(err);
      });
  };

  const callincomesharingapi = (
    data,
    amount,
    description,
    commissionPercentage,
    commissionAmount,
    taxPercentage,
    taxAmount,
    appenddescription,
    agentemail,
    i,
    payableinvoicestorage,
    invoice_id,
    duplicateinvoicedetails
  ) => {
    let invoiceDetails = [
      {
        amount: originalnetincomevalue,
        description: appenddescription,
        commissionPercentage,
        commissionAmount,
        id: 0,
        taxPercentage,
        taxAmount,
      },
    ];
    let data2 = {
      clientId: data.clientId,
      clientEmail: data.clientEmail,
      agentUsername: agentemail,
      agentName: description,
      courseId: Number(enquirycourseid),
      courseName: enquirycoursename,
      clientUsername: data.clientUsername,
      commisionType: data.commisionType,
      currency: data.currency,
      enquiryId: data.enquiryId,
      enquiryName: data.enquiryName,
      externalId: data.externalId,
      id: 0,
      incomeType: "Payable",
      institutionId: data.institutionId,
      intake: data.intake,
      invoiceCreatedBy: data.invoiceCreatedBy,
      invoiceCreatedDate: data.invoiceCreatedDate,
      invoiceDueDate: data.invoiceDueDate,
      invoicePaidDate: data.invoicePaidDate,
      invoiceSubType: data.invoiceSubType,
      officeId: data.officeId,
      serviceProvider: data.serviceProvider,
      workflowType: data.workflowType,
      invoiceDetails,
      netAmount: originalnetincomevalue,
      totalTax: taxAmount,
      totalCommission: commissionAmount,
      totalAmount: amount,
      totalDue: commissionAmount + taxAmount,
      addedOn: datenow,
      updatedOn: datenow,
    };
    if (statusoption === "Paid") {
      axios
        .post(`${base_url.api2}/createInvoice`, data2, {
          headers: {
            Authorization: jwtToken,
            invoiceStatus: "Active",
            invoiceType: "Payable",
          },
        })
        .then((res) => {
          let arr = {
            id: res.data.data.id,
            agentUsername: res.data.data.agentUsername,
          };
          payableinvoicestorage.push(arr);
          if (i === incomesharing.length - 1) {
            payableinvoicestorage.forEach((value) => {
              incomesharing.forEach((val2) => {
                if (val2.incomeSharingemail === value.agentUsername) {
                  val2.payableInvoiceId = value.id;
                }
              });
            });
            let incomenow = [];
            if (
              incomesharing.length > 0 &&
              incomesharing[0]?.incomesharingcommissionamount > 0
            ) {
              for (let i = 0; i < incomesharing.length; i++) {
                let obj = {
                  id: 0,
                  incomeShareHolderId: incomesharing[i].incomeShareHolderId,
                  incomeSharingAmount:
                    incomesharing[i].incomeSharingAmountOriginal,
                  incomeSharingCommissionPercent:
                    incomesharing[i].incomeSharingAmount,
                  incomeSharingName: incomesharing[i].incomeSharingName,
                  sharingCommissionAmount:
                    incomesharing[i].incomesharingcommissionamount,
                  sharingTaxPercentage:
                    incomesharing[i].incomeSharingtaxpercent,
                  sharingTaxAmount: incomesharing[i].incomeSharingtaxamount,
                  payableInvoiceId: incomesharing[i].payableInvoiceId,
                  incomeSharingType: incomesharing[i].incomeSharingType,
                  notes: incomesharing[i].note,
                  agentIncomeSharingCommissionPercent:
                    incomesharing[i].incomesharingcommissionpercentage,
                  agentIncomeSharingReferalPercent:
                    incomesharing[i].incomesharingreferalpercentage,
                };
                incomenow.push(obj);
              }
              let data5 = {
                id: invoice_id,
                incomeSharing: incomenow,
                invoiceDetails: duplicateinvoicedetails,
              };
              axios
                .post(`${base_url.api2}/updateInvoiceByInvoiceId`, data5, {
                  headers: {
                    Authorization: jwtToken,
                    invoiceStatus: statusoption,
                  },
                })
                .then((res) => {
                  //console.log(res);
                });
            }
          }
        })
        .catch((err) => {
          handle403error(err);
        });
    } else {
      axios
        .post(`${base_url.api2}/createInvoice`, data2, {
          headers: {
            Authorization: jwtToken,
            invoiceStatus: "Unpaid",
            invoiceType: "Payable",
          },
        })
        .then((res) => {
          let arr = {
            id: res.data.data.id,
            agentUsername: res.data.data.agentUsername,
          };
          payableinvoicestorage.push(arr);
          if (i === incomesharing.length - 1) {
            payableinvoicestorage.forEach((value) => {
              incomesharing.forEach((val2) => {
                if (val2.incomeSharingemail === value.agentUsername) {
                  val2.payableInvoiceId = value.id;
                }
              });
            });
            let incomenow = [];
            if (
              incomesharing.length > 0 &&
              incomesharing[0]?.incomesharingcommissionamount > 0
            ) {
              for (let i = 0; i < incomesharing.length; i++) {
                let obj = {
                  id: 0,
                  incomeShareHolderId: incomesharing[i].incomeShareHolderId,
                  incomeSharingAmount:
                    incomesharing[i].incomeSharingAmountOriginal,
                  incomeSharingCommissionPercent:
                    incomesharing[i].incomeSharingAmount,
                  incomeSharingName: incomesharing[i].incomeSharingName,
                  sharingCommissionAmount:
                    incomesharing[i].incomesharingcommissionamount,
                  sharingTaxPercentage:
                    incomesharing[i].incomeSharingtaxpercent,
                  sharingTaxAmount: incomesharing[i].incomeSharingtaxamount,
                  payableInvoiceId: incomesharing[i].payableInvoiceId,
                  incomeSharingType: incomesharing[i].incomeSharingType,
                  notes: incomesharing[i].note,
                  agentIncomeSharingCommissionPercent:
                    incomesharing[i].incomesharingcommissionpercentage,
                  agentIncomeSharingReferalPercent:
                    incomesharing[i].incomesharingreferalpercentage,
                };
                incomenow.push(obj);
              }
              let data5 = {
                id: invoice_id,
                incomeSharing: incomenow,
                invoiceDetails: duplicateinvoicedetails,
              };
              axios
                .post(`${base_url.api2}/updateInvoiceByInvoiceId`, data5, {
                  headers: {
                    Authorization: jwtToken,
                    invoiceStatus: statusoption,
                  },
                })
                .then((res) => {
                  //console.log(res);
                });
            }
          }
        })
        .catch((err) => {
          handle403error(err);
        });
    }
  };
  const goBack = () => {
    const prev_path = localStorage.getItem("prev_path3");
    dispatch(gettabvalue(5));
    navigate(prev_path);
  };
  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            navigate("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };

  const gettotaldue = () => {
    let number = 0;
    if (commision === "General Invoice") {
      number = Number(
        totalamttax - totalAmountPaid - Number(discountAmount)
      ).toFixed(2);
    } else if (commision === "commissionInvoiceNet") {
      number = Number(
        totalamt - totalAmountPaid - Number(discountAmount)
      ).toFixed(2);
    } else {
      number = Number(totalcommission + totaltax - totalAmountPaid).toFixed(2);
    }
    return number;
  };

  return (
    <>
      <ToastContainer />
      <Backdrop className={classes.backdrop} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div
        class="page-head d-flex justify-content-between p-4"
        style={{ marginTop: "4.5rem" }}
      >
        <ul class="breadcrums">
          <h3 class="page-title bold">Client Invoice</h3>
          <Link to="/" class="b-link">
            Home
          </Link>
          -<a class="b-link">Client</a>-<a class="b-link active">Invoice</a>
        </ul>
        {checkrolepermission(1, 3) ? (
          <button
            style={{ margin: "5px 0px", height: "40px" }}
            className="sent-button"
            onClick={goBack}
          >
            Invoice List
          </button>
        ) : null}
      </div>
      <div style={{ padding: "1.5rem", border: "1.5rem solid #e9ebf2" }}>
        <div>
          {/* <Backdrop className={classes.backdrop} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop> */}
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="addinvoice_header">
              {/* <Typography
            variant="h5"
            color="primary"
            style={{ marginLeft: "9px" }}
          >
            Client Invoice
          </Typography> */}
              {/* <div className="addinvoice_headerbutton">
            <button
              style={{ margin: "5px" }}
              className="btn listing_addbutton"
              type="submit"
            >
              Save
            </button>

            <button
              style={{ margin: "5px" }}
              className="btn listing_addbutton"
              onClick={goBack}
            >
             Invoice List
            </button>
          </div> */}
            </div>
            <div class="row" style={{ marginBottom: "50px" }}>
              <div class="col-md-4">
                <div class="info-group">
                  <label>Name:</label>
                  <span>
                    {clientDetail1?.firstName} {clientDetail1?.middleName}{" "}
                    {clientDetail1?.lastName}
                  </span>
                </div>
                <div class="info-group">
                  <label>DOB:</label>
                  {clientDetail1?.clientDetail.dob !== null ? (
                    <span>
                      {moment(clientDetail1?.clientDetail.dob).format(
                        "DD-MM-YYYY"
                      )}
                    </span>
                  ) : (
                    "-"
                  )}
                </div>
                <div class="info-group">
                  <label>Consultant:</label>
                  <span>{clientDetail1?.consultantName}</span>
                </div>
                <div class="info-group">
                  <label>
                    {incomeSubType == "General"
                      ? "Assessment Body:"
                      : "Provider:"}
                  </label>
                  <span>{service}</span>
                </div>
                <div class="info-group">
                  <label>Student Id:</label>
                  {externalid !== "undefined" ? (
                    <span>{externalid}</span>
                  ) : null}
                </div>
              </div>
              <div class="col-md-4"></div>
              <div class="col-md-4">
                <div class="info-group justify-content-end">
                  {statusoption === "Paid" ? (
                    <h5>{statusoption}</h5>
                  ) : (
                    <h5>{statusoption}</h5>
                  )}
                </div>
                <form>
                  <div class="info-group flex-wrap">
                    <label class="form-label">Invoice Date</label>
                    <form
                      id="myForm"
                      className="d-flex"
                      style={{ width: "100%", position: "relative" }}
                    >
                      <DatePicker
                        className="form-control underline-input"
                        selected={created_date ? new Date(created_date) : null}
                        onChange={(date) => setCreatedDate(date)}
                        dateFormat="dd-MM-yyyy"
                        showYearDropdown
                        dropdownMode="select"
                        placeholderText="Select Date"
                        style={{ position: "relative" }}
                      />

                      <span
                        className="listpagesearch_button"
                        style={{
                          top: "10px",
                          position: "absolute",
                          right: "10px",
                          pointerEvents: "none",
                        }}
                      >
                        <i className="fa fa-calendar"></i>
                      </span>
                    </form>
                  </div>
                  <div class="info-group flex-wrap">
                    <label class="form-label">Invoice Due Date</label>
                    <form
                      id="myForm"
                      className="d-flex"
                      style={{ width: "100%", position: "relative" }}
                    >
                      <DatePicker
                        className="form-control underline-input"
                        selected={duedate ? new Date(duedate) : null}
                        onChange={(date) => setduedate(date)}
                        dateFormat="dd-MM-yyyy"
                        showYearDropdown
                        dropdownMode="select"
                        placeholderText="Select Date"
                        style={{ position: "relative" }}
                        required
                      />
                      <span
                        className="listpagesearch_button"
                        style={{
                          top: "10px",
                          position: "absolute",
                          right: "10px",
                          pointerEvents: "none",
                        }}
                      >
                        <i className="fa fa-calendar"></i>
                      </span>
                    </form>
                  </div>
                  <div class="info-group flex-wrap">
                    <label class="form-label">Currency</label>
                    <SelectCurrency
                      value={currency1}
                      onChange={selectcurrency}
                      className="form-control underline-input"
                    />
                  </div>
                </form>
              </div>
            </div>

            <hr />

            <div class="filters">
              <form
                class="row"
                style={{ marginTop: "40px", marginBottom: "40px" }}
              >
                <div class="col-md-4" style={{ textAlign: "left" }}>
                  <label class="form-label">A/C Type</label>
                  <br />
                  <select
                    id=""
                    class="form-select"
                    style={{ width: "96%" }}
                    name="incomeType"
                    onInput={(e) => setpayable(e.target.value)}
                    ref={register({ required: true })}
                  >
                    <option value="Receivable">Account Receivable</option>
                    <option value="Payable">Account Payable</option>
                  </select>
                  {errors.incomeType && (
                    <p className="errormessage">A/C Type is Required</p>
                  )}
                </div>
                <div class="col-md-4" style={{ textAlign: "left" }}>
                  <label class="form-label">Income SubType</label>
                  <select
                    id=""
                    class="form-select"
                    style={{ width: "96%" }}
                    name="invoiceSubType"
                    onInput={handleincomesubtype}
                    value={incomeSubType}
                    ref={register({ required: true })}
                  >
                    <option value="">- Select Type -</option>
                    <option value="Admission">Admission</option>
                    <option value="Migration">Migration</option>
                    <option value="Insurance">Insurance</option>
                    <option value="General">Skill Assessment</option>
                  </select>
                  {errors.invoiceSubType && (
                    <p className="errormessage">Invoice Sub Type is Required</p>
                  )}
                </div>
                <div class="col-md-4" style={{ textAlign: "left" }}>
                  <label class="form-label">Enquiry Name</label>
                  <select
                    id=""
                    class="form-select"
                    style={{ width: "96%" }}
                    name="enquiryId"
                    className={classes.selectdrop}
                    onInput={(e) => changeService(e)}
                    ref={register({ required: true })}
                  >
                    <option value="">- Select Enquiry -</option>
                    {showEnquiryName?.map((cuntitem) => (
                      <option
                        key={cuntitem.enquiryId}
                        id={`${cuntitem.workflowName}`}
                        value={cuntitem.enquiryId}
                        institutionname={cuntitem.institutionName}
                        externalid={cuntitem.externalId}
                        commissionpercentage={cuntitem.commissionPercentage}
                        workflowtype={cuntitem.workflowType}
                        intake={cuntitem.intake}
                        officeid={cuntitem.officeId}
                        institutionid={cuntitem.institutionId}
                        superagent={cuntitem.superAgent}
                        subagent={cuntitem.subAgent}
                        coursename={cuntitem.showName}
                        courseid={cuntitem.showId}
                        assementBody={cuntitem.assementBody}
                      >
                        {cuntitem.showName +
                          ` ( ` +
                          cuntitem.institutionName +
                          ` ) `}
                      </option>
                    ))}
                  </select>
                  {errors.enquiryId && (
                    <p className="errormessage">Enquiry Name is Required</p>
                  )}
                </div>
                <div class="col-md-4" style={{ textAlign: "left" }}>
                  <label class="form-label">Invoice Type</label>
                  {payable === "Payable" ? (
                    <>
                      <select
                        id=""
                        class="form-select"
                        style={{ width: "96%" }}
                        name="commisionType"
                        onInput={(e) => handlecommisionChange2(e)}
                        ref={register({ required: true })}
                      >
                        <option value="">- Select Type -</option>
                        <option value="General Invoice">General Invoice</option>
                      </select>
                      {errors.commisionType && (
                        <p className="errormessage">
                          Commission Type is Required
                        </p>
                      )}
                    </>
                  ) : (
                    <>
                      <select
                        name="commisionType"
                        id=""
                        class="form-select"
                        style={{ width: "96%" }}
                        onInput={(e) => handlecommisionChange2(e)}
                        // onChange={(e) => setcommision(e.target.value)}
                        ref={register({ required: true })}
                      >
                        <option value="">- Select Type -</option>
                        <option value="General Invoice">General Invoice</option>
                        {incomeSubType === "Admission" ||
                          incomeSubType === "General" ? (
                          <option value="commissionInvoiceNet">
                            Commission Invoice-Net
                          </option>
                        ) : null}
                        {incomeSubType === "Admission" ||
                          incomeSubType === "General" ? (
                          <option value="commissionInvoiceGross">
                            Commission Invoice-Gross
                          </option>
                        ) : null}
                      </select>
                      {errors.commisionType && (
                        <p className="errormessage">
                          Commission Type is Required
                        </p>
                      )}
                    </>
                  )}
                </div>
                <div class="col-md-4" style={{ textAlign: "left" }}>
                  <label class="form-label">Intake</label>
                  {/* <select
                    id=""
                    class="form-select"
                    style={{ width: "96%" }}
                    name="intake"
                    ref={register({ required: false })}
                    onInput={(e) => setEnquiryIntake(e.target.value)}
                    value={enquiryIntake}
                  >
                    <option value="">- Select Intake -</option>
                    {intakeList?.map((cuntitem) => (
                      <option
                        key={cuntitem.id}
                        value={cuntitem.name}
                        id={cuntitem.id}
                      >
                        {cuntitem.createdIntake}
                      </option>
                    ))}
                  </select> */}
                  <DatePicker
                    selected={enquiryIntake ? new Date(enquiryIntake) : null}
                    onChange={(date) => {
                      setEnquiryIntake(date);
                      //console.log(moment(date).format('MMM-yyyy'));
                    }}
                    dateFormat="MMM-yyyy"
                    showMonthYearPicker
                    dropdownMode="select"
                    placeholderText="Select Date"
                  />
                </div>
                <div class="col-md-4" style={{ textAlign: "left" }}>
                  <label class="form-label">Workflow Type</label>
                  <input
                    type="text"
                    style={{ backgroundColor: "#f5f5f9", width: "96%" }}
                    value={enquiryWorkflowType}
                    className="form-control underline-input"
                    readOnly
                  />
                </div>
              </form>
              <hr style={{ marginTop: "50px" }} />
            </div>

            <br />

            {/* <div className="row viewinvoice_row">
          <div className="col-md-4">
            <div className="row">
              <div className="col-md-4" style={{ fontWeight: "bold" }}>
                A/C Type
              </div>
              <div className="col-md-8" style={{ fontWeight: "bold" }}>
                <select
                  name="incomeType"
                  style={{ maxWidth: "240px" }}
                  className={`form-control underline-input ${classes.selectdrop}`}
                  onChange={(e) => setpayable(e.target.value)}
                  ref={register('incomeType',{ required: true })}
                >
                  <option value="Receivable">Account Receivable</option>
                  <option value="Payable">Account Payable</option>
                </select>
                {errors.incomeType && (
                  <p className="errormessage">A/C Type is Required</p>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-4" style={{ fontWeight: "bold" }}>
                Invoice Type
              </div>
              <div className="col-md-8" style={{ fontWeight: "bold" }}>
                {payable === "Payable" ? (
                  <>
                    <select
                      name="commisionType"
                      className={`form-control underline-input ${classes.selectdrop}`}
                      style={{ maxWidth: "240px" }}
                      onChange={(e) => handlecommisionChange2(e)}
                      ref={register('commisionType',{ required: true })}
                    >
                      <option value="">- Select Type -</option>
                      <option value="General Invoice">General Invoice</option>
                    </select>
                    {errors.commisionType && (
                      <p className="errormessage">
                        Commission Type is Required
                      </p>
                    )}
                  </>
                ) : (
                  <>
                    <select
                      name="commisionType"
                      className={`form-control underline-input ${classes.selectdrop}`}
                      style={{ maxWidth: "240px" }}
                      onChange={(e) => handlecommisionChange2(e)}
                      // onChange={(e) => setcommision(e.target.value)}
                      ref={register('commisionType',{ required: true })}
                    >
                      <option value="">- Select Type -</option>
                      <option value="General Invoice">General Invoice</option>
                      {incomeSubType === "Admission" ||
                      incomeSubType === "General" ? (
                        <option value="commissionInvoiceNet">
                          Commission Invoice-Net
                        </option>
                      ) : null}
                      {incomeSubType === "Admission" ||
                      incomeSubType === "General" ? (
                        <option value="commissionInvoiceGross">
                          Commission Invoice-Gross
                        </option>
                      ) : null}
                    </select>
                    {errors.commisionType && (
                      <p className="errormessage">
                        Commission Type is Required
                      </p>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="row">
              <div className="col-md-4" style={{ fontWeight: "bold" }}>
                Income SubType
              </div>
              <div className="col-md-8" style={{ fontWeight: "bold" }}>
                ------- {payable === "Payable" ? (
                  <>
                    <select
                      name="invoiceSubType"
                      style={{ maxWidth: "240px" }}
                      className={`form-control underline-input ${classes.selectdrop}`}
                      onChange={(e) => setpayable(e.target.value)}
                      ref={register('invoiceSubType')}
                    >
                      <option value="null">- Select Type -</option>
                    </select>
                  </>
                ) : (
                  <> -------
                <select
                  name="invoiceSubType"
                  style={{ maxWidth: "240px" }}
                  className={`form-control underline-input ${classes.selectdrop}`}
                  onChange={handleincomesubtype}
                  value={incomeSubType}
                  ref={register('invoiceSubType',{ required: true })}
                >
                  <option value="">- Select Type -</option>
                  <option value="Admission">Admission</option>
                  <option value="Migration">Migration</option>
                  <option value="Insurance">Insurance</option>
                  <option value="General">Skill Assessment</option>
                </select>
                {errors.invoiceSubType && (
                  <p className="errormessage">Invoice Sub Type is Required</p>
                )}
                ------- </>
                )} -------
              </div>
            </div>
            <div className="row">
              <div className="col-md-4" style={{ fontWeight: "bold" }}>
                Intake
              </div>
              <div className="col-md-8" style={{ fontWeight: "bold" }}>
                <select
                  className={`form-control underline-input ${classes.selectdrop}`}
                  name="intake"
                  ref={register('intake',{ required: false })}
                  onChange={(e) => setEnquiryIntake(e.target.value)}
                  value={enquiryIntake}
                  style={{ maxWidth: "240px" }}
                >
                  <option value="">- Select Intake -</option>
                  {intakeList?.map((cuntitem) => (
                    <option
                      key={cuntitem.id}
                      value={cuntitem.name}
                      id={cuntitem.id}
                    >
                      {cuntitem.createdIntake}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="row">
              <div className="col-md-4" style={{ fontWeight: "bold" }}>
                Enquiry Name
              </div>
              <div className="col-md-8" style={{ fontWeight: "bold" }}>
                <select
                  name="enquiryId"
                  className={classes.selectdrop}
                  onChange={(e) => changeService(e)}
                  ref={register('enquiryId',{ required: true })}
                >
                  <option value="">- Select Enquiry -</option>
                  {showEnquiryName?.map((cuntitem) => (
                    <option
                      key={cuntitem.enquiryId}
                      id={`${cuntitem.workflowName}`}
                      value={cuntitem.enquiryId}
                      institutionname={cuntitem.institutionName}
                      externalid={cuntitem.externalId}
                      commissionpercentage={cuntitem.commissionPercentage}
                      workflowtype={cuntitem.workflowType}
                      intake={cuntitem.intake}
                      officeid={cuntitem.officeId}
                      institutionid={cuntitem.institutionId}
                      superagent={cuntitem.superAgent}
                      coursename={cuntitem.showName}
                      courseid={cuntitem.showId}
                    >
                      {cuntitem.showName}
                      ------- {incomeSubType === "Admission" ||
                      incomeSubType === "General"
                        ? cuntitem.courseName
                        : cuntitem.institutionName
                        ? cuntitem.institutionName
                        : ""} -------
                      ------- {`${cuntitem.workflowName}(${
                        cuntitem.institutionName ? cuntitem.institutionName : ""
                      })`} -------
                    </option>
                  ))}
                </select>
                {errors.enquiryId && (
                  <p className="errormessage">Enquiry Name is Required</p>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-4" style={{ fontWeight: "bold" }}>
                Workflow Type
              </div>
              <div className="col-md-8" style={{ fontWeight: "bold" }}>
                <input
                  type="text"
                  value={enquiryWorkflowType}
                  className="form-control underline-input"
                  readOnly
                />
              </div>
            </div>
          </div>
        </div> */}
            <div
              className="invoice_adddetails quotation_table"
              style={{ marginTop: "20px" }}
            >
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead style={{ backgroundColor: "cornflowerblue" }}>
                    <TableRow>
                      <TableCell align="center">SN</TableCell>
                      <TableCell style={{ width: "25rem" }}>
                        Description
                      </TableCell>
                      <TableCell>Amount</TableCell>
                      {commision !== "General Invoice" ? (
                        <>
                          <TableCell align="right">Commission(%)</TableCell>
                          <TableCell style={{ padding: "0" }}>
                            Commission Amount
                          </TableCell>
                        </>
                      ) : null}
                      <TableCell>Tax(%)</TableCell>
                      <TableCell>Tax Amount</TableCell>
                      {commision === "General Invoice" ? (
                        <TableCell>Total Amount</TableCell>
                      ) : null}
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className="clientgeneralinvoicetables">
                    {newline.map((p, index) => (
                      <TableRow key={index + 1}>
                        <TableCell component="th" scope="row" align="center">
                          {index + 1}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <input
                            placeholder="Enter Description.."
                            value={p.description}
                            style={{ backgroundColor: "#f5f5f9" }}
                            onChange={(e) => handlelinechange(e, index)}
                            className="form-control underline-input"
                            name={`description-${index}`}
                            type="text"
                            ref={register({ required: true })}
                            required
                          />
                        </TableCell>
                        <TableCell align="right">
                          <input
                            placeholder="Enter Amount.."
                            value={p.amount}
                            style={{ backgroundColor: "#f5f5f9" }}
                            name={`amount-${index}`}
                            className="form-control underline-input inputalign"
                            type="number"
                            onWheel={(event) => event.currentTarget.blur()}
                            onChange={(e) => handleamountChange(e, index)}
                            ref={register({ required: true })}
                            required
                          />
                        </TableCell>
                        {commision !== "General Invoice" ? (
                          <>
                            <TableCell align="right">
                              <input
                                placeholder="Enter Commission%.."
                                value={p.commissionPercentage}
                                name={`commissionPercentage-${index}`}
                                className="form-control underline-input inputalign"
                                type="number"
                                style={{ backgroundColor: "#f5f5f9" }}
                                onWheel={(event) => event.currentTarget.blur()}
                                onChange={(e) =>
                                  handlecommisionChange(e, index)
                                }
                                ref={register({
                                  required: true,
                                })}
                                required
                              />
                            </TableCell>
                            <TableCell align="right">
                              <input
                                ref={register}
                                value={Number(p.commissionAmount).toFixed(2)}
                                name={`commissionAmount-${index}`}
                                className="form-control underline-input inputalign"
                                type="number"
                                style={{ backgroundColor: "#f5f5f9" }}
                                onWheel={(event) => event.currentTarget.blur()}
                              />
                            </TableCell>
                          </>
                        ) : null}
                        <TableCell align="right">
                          <input
                            placeholder="Enter Tax%.."
                            value={p.taxpercent}
                            onChange={(e) => handletaxpercentchange(e, index)}
                            name={`taxPercentage-${index}`}
                            type="number"
                            style={{ backgroundColor: "#f5f5f9" }}
                            onWheel={(event) => event.currentTarget.blur()}
                            className="form-control underline-input inputalign"
                            ref={register({ required: true })}
                            required
                          />
                        </TableCell>
                        <TableCell align="right">
                          <input
                            ref={register}
                            value={Number(p.taxamount).toFixed(2)}
                            className="form-control underline-input inputalign"
                            name={`taxAmount-${index}`}
                            type="number"
                            style={{ backgroundColor: "#f5f5f9" }}
                            onWheel={(event) => event.currentTarget.blur()}
                            readOnly
                          />
                        </TableCell>
                        {commision === "General Invoice" ? (
                          <TableCell align="right">
                            <input
                              ref={register}
                              value={Number(p.totalamount).toFixed(2)}
                              className="form-control underline-input inputalign"
                              name={`totalAmount-${index}`}
                              type="number"
                              style={{ backgroundColor: "#f5f5f9" }}
                              onWheel={(event) => event.currentTarget.blur()}
                              readOnly
                            />
                          </TableCell>
                        ) : null}

                        <TableCell
                          align="right"
                          style={{
                            cursor: "pointer",
                            backgroundColor: "#f5f5f9",
                          }}
                          onClick={() => {
                            var lines = [...newline];
                            let amount = [...amountvalues];
                            let taxamount = [...taxamountvalues];
                            let totalamount = [...totalamountvalues];
                            let commissionamount = [...commissionamountvalues];
                            lines.splice(index, 1);
                            amount.splice(index, 1);
                            taxamount.splice(index, 1);
                            totalamount.splice(index, 1);
                            commissionamount.splice(index, 1);
                            setamountvalues(amount);
                            settaxamountvalues(taxamount);
                            settotalamountvalues(totalamount);
                            setcommissionamountvalues(commissionamount);
                            setnewline(lines);
                            let discountamount = 0;
                            let totaldiscountamount = 0;
                            if (discountpercent === "Percentage") {
                              if (commision !== "General Invoice") {
                                totaldiscountamount = Number(
                                  amount?.reduce(
                                    (amount, item) => item + amount,
                                    0
                                  )
                                );
                                discountamount =
                                  (Number(totaldiscountamount) *
                                    Number(discountpercentamount)) /
                                  100;
                              }
                            } else {
                              if (commision !== "General Invoice") {
                                discountamount = Number(discountAmount);
                              }
                            }

                            setdiscountAmount(discountamount);
                            settotalamt(
                              amount?.reduce((amount, item) => item + amount, 0)
                            );
                            settotaltax(
                              taxamount?.reduce(
                                (amount, item) => item + amount,
                                0
                              )
                            );
                            settotalcommission(
                              commissionamount?.reduce(
                                (amount, item) => item + amount,
                                0
                              )
                            );
                            settotalamttax(
                              totalamount?.reduce(
                                (amount, item) => item + amount,
                                0
                              )
                            );
                            if (commision === "General Invoice") {
                              setoriginalnetincomevalue(
                                amount?.reduce(
                                  (amount, item) => item + amount,
                                  0
                                )
                              );
                              if (
                                incomesharing.length > 0 &&
                                incomesharing[0].incomesharingcommissionamount >
                                0
                              ) {
                                changeincomesharingdata(
                                  amount?.reduce(
                                    (amount, item) => item + amount,
                                    0
                                  )
                                );
                              } else {
                                setnetincomevalue(
                                  amount?.reduce(
                                    (amount, item) => item + amount,
                                    0
                                  )
                                );
                              }
                            } else {
                              setoriginalnetincomevalue(
                                commissionamount?.reduce(
                                  (amount, item) => item + amount,
                                  0
                                ) - discountamount
                              );
                              if (
                                incomesharing.length > 0 &&
                                incomesharing[0].incomesharingcommissionamount >
                                0
                              ) {
                                changeincomesharingdata(
                                  commissionamount?.reduce(
                                    (amount, item) => item + amount,
                                    0
                                  ) - discountamount
                                );
                              } else {
                                setnetincomevalue(
                                  commissionamount?.reduce(
                                    (amount, item) => item + amount,
                                    0
                                  ) - discountamount
                                );
                              }
                            }
                          }}
                        >
                          <span
                            style={{
                              background: "red",
                              padding: "0 3px",
                              fontSize: "19px",
                              borderRadius: " 3px",
                              color: "#fff",
                            }}
                          >
                            x
                          </span>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {/* <Typography */}
              <Button
                // color="primary"
                className="sent-button"
                style={{
                  // cursor: "pointer",
                  marginTop: "20px",
                  // width: "fit-content",
                  // border: "1px solid",
                  // padding: "5px",
                  // borderRadius: "7px",
                  textTransform: "capitalize",
                  float: "left",
                }}
                onClick={() => {
                  addClick();
                  setnewline((prev) => [
                    ...prev,

                    {
                      description: "",
                      amount: "",
                      commissionAmount: "",
                      commissionPercentage: "",
                      taxpercent: "10",
                      taxamount: "",
                      totalamount: "",
                    },
                  ]);
                }}
              >
                Add Row
              </Button>
              {/* </Typography> */}
            </div>

            <div class="discount row" style={{ marginTop: "4rem" }}>
              <div className="col-md-6 mt-4">
                {commision !== "General Invoice" ? (
                  <div class="d-flex mr-5">
                    <div>
                      <label
                        class="form-label mb-0"
                        style={{ marginRight: "77px" }}
                      >
                        Discount Type
                      </label>
                      <div class="d-flex align-items-center">
                        <input
                          type="radio"
                          class="me-1"
                          name="discount"
                          id="fixed"
                          value="Fixed"
                          onChange={(e) => handleDiscountfixed(e)}
                          checked={discountpercent === "Fixed"}
                        />
                        <span
                          class="me-3"
                          style={{ marginRight: "10px" }}
                          for="phone"
                        >
                          Fixed
                        </span>
                        <input
                          type="radio"
                          class="me-1"
                          name="discount"
                          id="percentage"
                          onChange={(e) => handleDiscountfixed(e)}
                          value="Percentage"
                          checked={discountpercent === "Percentage"}
                        />
                        <span
                          class="me-3"
                          style={{ marginRight: "10px" }}
                          for="email"
                        >
                          Percentage
                        </span>
                      </div>
                    </div>
                    <input
                      class="form-control"
                      value={discountpercentamount}
                      ref={register}
                      type="number"
                      onWheel={(event) => event.currentTarget.blur()}
                      placeholder="Enter Discount Amount.."
                      name="discountfixedamount"
                      onChange={(e) => handleDiscountpercent(e)}
                    />
                  </div>
                ) : null}
                <div class="d-flex mt-4">
                  <div class="total-box">
                    <span>Total Paid</span>
                    <span class="d-flex justify-content-end value">
                      {totalAmountPaid}
                    </span>
                  </div>
                  <div class="total-box">
                    <span>Net Income</span>
                    <span class="d-flex justify-content-end value">
                      {netincomevalue.toFixed(2)}
                    </span>
                  </div>
                </div>
                <label>Remark:</label>
                <div class="info-group" style={{ width: "99%" }}>
                  <textarea
                    className="form-control"
                    ref={register}
                    name="comment"
                    style={{ backgroundColor: "#f5f5f9" }}
                    rows="5"
                  />
                </div>
              </div>
              <div class="col-md-1"></div>
              <div class="col-md-4">
                {commision === "commissionInvoiceGross" ? null : (
                  <div class="info-group align-items-center">
                    <label>Total Amount:</label>
                    <input
                      class="form-control"
                      name="total_amount"
                      value={Number(totalamt).toFixed(2)}
                      readOnly
                    />
                  </div>
                )}
                {commision !== "General Invoice" ? (
                  <div class="info-group align-items-center">
                    <label>Commission:</label>
                    <input
                      class="form-control"
                      name="total_commission"
                      value={Number(totalcommission).toFixed(2)}
                      readOnly
                    />
                  </div>
                ) : null}
                <div class="info-group align-items-center">
                  <label>Tax:</label>
                  <input
                    type="num"
                    class="form-control"
                    name="total_tax"
                    value={Number(totaltax).toFixed(2)}
                    readOnly
                  />
                </div>
                {commision === "commissionInvoiceNet" ? (
                  <div class="info-group align-items-center">
                    <label>Discount:</label>
                    <input
                      class="form-control"
                      name="total_tax"
                      value={Number(discountAmount).toFixed(2)}
                      readOnly
                    />
                  </div>
                ) : null}
                {commision === "General Invoice" ? (
                  <div class="info-group align-items-center">
                    <label>Total amount (Inc Tax):</label>
                    <input
                      name="total_amountincltax"
                      value={Number(totalamttax).toFixed(2)}
                      class="form-control"
                      readOnly
                    />
                  </div>
                ) : (
                  <>
                    {commision === "commissionInvoiceNet" ? (
                      <div class="info-group align-items-center">
                        <label>Total amount paid to provider:</label>
                        <input
                          name="total_amountincltax"
                          value={Number(
                            totalamt - totaltax - totalcommission
                          ).toFixed(2)}
                          class="form-control"
                          readOnly
                        />
                      </div>
                    ) : (
                      <div class="info-group align-items-center">
                        <label>Total amount:</label>
                        <input
                          name="total_amountincltax"
                          value={Number(totalcommission + totaltax).toFixed(2)}
                          class="form-control"
                          readOnly
                        />
                      </div>
                    )}
                  </>
                )}
                <div class="info-group align-items-center">
                  <label>Total Due:</label>
                  <input
                    name="total_due"
                    value={gettotaldue()}
                    class="form-control"
                    readOnly
                  />
                  {/* {commision === "General Invoice" ? (
                    <input
                      name="total_due"
                      value={Number(
                        totalamttax - totalAmountPaid - Number(discountAmount)
                      ).toFixed(2)}
                      class="form-control"
                      readOnly
                    />
                  ) : (
                    <>
                      {commision === "commissionInvoiceNet" ? (
                        <input
                          name="total_due"
                          value={Number(
                            totalamt - totalAmountPaid - Number(discountAmount)
                          ).toFixed(2)}
                          class="form-control"
                          readOnly
                        />
                      ) : (
                        <input
                          name="total_due"
                          value={Number(
                            totalcommission + totaltax - totalAmountPaid
                          ).toFixed(2)}
                          class="form-control"
                          readOnly
                        />
                      )}
                    </>
                  )} */}
                </div>
              </div>
            </div>

            <hr className="hrmargin" />
            <div style={{ margin: "5px 0", padding: "0 0 0 8px" }}>
              <Typography
                color="primary"
                variant="body1"
                style={{
                  fontWeight: "bolder",
                  textAlign: "left",
                  margin: "8px 3px",
                }}
              >
                Payment Received
              </Typography>
              <div className="invoice_adddetails quotation_table">
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead style={{ backgroundColor: "cornflowerblue" }}>
                      <TableRow>
                        <TableCell>SN</TableCell>
                        <TableCell>Amount</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Note</TableCell>
                        <TableCell>Attachment</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className="clientgeneralinvoicetables">
                      {paymentReceive.map((p, index) => (
                        <TableRow key={index + 1}>
                          <TableCell component="th" scope="row" align="center">
                            {index + 1}
                          </TableCell>
                          <TableCell>
                            <input
                              type="number"
                              style={{ backgroundColor: "#f5f5f9" }}
                              step="0.01"
                              onWheel={(event) => event.currentTarget.blur()}
                              placeholder="Enter Amount.."
                              name={`paymentPaid-${index}`}
                              onChange={(e) => {
                                handleTotalPaid(e, index);
                              }}
                              className="form-control underline-input inputalign"
                              ref={register}
                            />
                          </TableCell>
                          <TableCell>
                            <DatePicker
                              selected={p.date ? new Date(p.date) : null}
                              // onChange={(date) => setpaidinvoicedate(date)}
                              onChange={(date) =>
                                handlePaymentReceiveDate(date, index)
                              }
                              dateFormat="dd-MM-yyyy"
                              showYearDropdown
                              dropdownMode="select"
                              placeholderText="Select Date"
                            />
                          </TableCell>
                          <TableCell>
                            <FormControl className={classes.formControl}>
                              <Select
                                id="demo-simple-select2"
                                // value={paymentoption}
                                value={p.paymentMode}
                                onChange={(e) =>
                                  handlePaymentReceiveType(e, index)
                                }
                              >
                                <MenuItem value="Cheque">Cheque</MenuItem>
                                <MenuItem value="Cash">Cash</MenuItem>
                                <MenuItem value="EFT">EFT</MenuItem>
                              </Select>
                            </FormControl>
                          </TableCell>
                          <TableCell>
                            <textarea
                              name="notes"
                              ref={register}
                              style={{ backgroundColor: "#f5f5f9" }}
                              value={p.notes}
                              placeholder="Enter Note.."
                              onChange={(e) =>
                                handlePaymentReceiveNotes(e, index)
                              }
                              className="form-control"
                              rows={3}
                            />
                            {/* <input
                          name="notes"
                          ref={register('notes')}
                          value={p.notes}
                          onChange={(e) => handlePaymentReceiveNotes(e, index)}
                          className="form-control underline-input"
                          type="textarea"
                        /> */}
                          </TableCell>
                          <TableCell>
                            {/* {index > 0 ? null : ( */}
                            <input
                              type="file"
                              multiple
                              style={{ backgroundColor: "#f5f5f9" }}
                              name="attachment"
                              onChange={(e) =>
                                handlePaymentReceiveAttachment(e, index)
                              }
                            />
                            {/* )} */}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              var lines = [...paymentReceive];
                              var amount = [...totalPaidValues];
                              lines.splice(index, 1);
                              amount.splice(index, 1);
                              setTotalPaid(
                                amount?.reduce(
                                  (amount, item) =>
                                    Number(item) + Number(amount),
                                  0
                                )
                              );
                              setPaymentReceive(lines);
                            }}
                          >
                            <span
                              style={{
                                background: "red",
                                padding: "0 3px",
                                fontSize: "19px",
                                borderRadius: " 3px",
                                color: "#fff",
                              }}
                            >
                              x
                            </span>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Button
                  // color="primary"
                  className="sent-button"
                  style={{
                    // cursor: "pointer",
                    marginTop: "20px",
                    // width: "fit-content",
                    // border: "1px solid",
                    // padding: "5px",
                    // borderRadius: "7px",
                    textTransform: "capitalize",
                    float: "left",
                  }}
                  onClick={() => {
                    addPaymentClick();
                    setPaymentReceive((prev) => [
                      ...prev,
                      {
                        id: 0,
                        amount: "",
                        date: datenow,
                        invoiceId: "",
                        notes: "",
                        paymentMode: "EFT",
                        status: "",
                        filedata: null,
                      },
                    ]);
                  }}
                >
                  Add Row
                </Button>
              </div>
            </div>

            <hr className="hrmargin" />
            <div style={{ margin: "72px 0", padding: "0 0 0 8px" }}>
              {payable === "Payable" ? null : (
                <>
                  <Typography
                    color="primary"
                    variant="body1"
                    style={{ fontWeight: "bolder", textAlign: "left" }}
                  >
                    Income Sharing
                  </Typography>

                  <div className="invoice_adddetails quotation_table mt-2">
                    <TableContainer component={Paper}>
                      <Table
                        className={classes.table}
                        aria-label="simple table"
                      >
                        <TableHead
                          style={{ backgroundColor: "cornflowerblue" }}
                        >
                          <TableRow>
                            <TableCell align="center">SN</TableCell>
                            <TableCell>Agent Name</TableCell>
                            <TableCell>Sharing Type</TableCell>
                            {/* <TableCell>Amount</TableCell>
                            <TableCell align="right">Commission(%)</TableCell> */}
                            <TableCell style={{ padding: "0" }}>
                              Commission Amount
                            </TableCell>
                            <TableCell>Tax(%)</TableCell>
                            <TableCell>Tax Amount</TableCell>
                            <TableCell>Total Amount</TableCell>
                            <TableCell>Note</TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody className="clientgeneralinvoicetables">
                          {incomesharing.map((p, index) => (
                            <TableRow key={index + 1}>
                              <TableCell
                                component="th"
                                scope="row"
                                align="center"
                              >
                                {index + 1}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {Number(p.incomesharingcommissionamount) == 0 ||
                                  p.incomesharingcommissionamount == null ? (
                                  <select
                                    className={`form-control underline-input ${classes.selectdrop}`}
                                    value={p.incomeSharingName}
                                    style={{ backgroundColor: "#f5f5f9" }}
                                    onChange={(e) =>
                                      handleincomesharingname(e, index)
                                    }
                                  >
                                    <option value="">- Select Type -</option>
                                    {/* <option
                            value={clientDetail1?.clientDetail.superAgentName}
                            id={clientDetail1?.clientDetail.superAgentId}
                            email={clientDetail1?.clientDetail.superAgent}
                          >{clientDetail1?.clientDetail.superAgentName}</option> */}

                                    {agent?.map((cuntitem, index) => (
                                      <option
                                        key={index + 1}
                                        value={cuntitem.value}
                                        id={cuntitem.id}
                                        commissionpercentage={
                                          cuntitem.commissionPercentage
                                        }
                                        referalpercentage={
                                          cuntitem.referalPercentage
                                        }
                                        email={cuntitem.email}
                                      >
                                        {cuntitem.value}
                                      </option>
                                    ))}
                                  </select>
                                ) : (
                                  <select
                                    className={`form-control underline-input ${classes.selectdrop}`}
                                    value={p.incomeSharingName}
                                    style={{ backgroundColor: "#f5f5f9" }}
                                    onChange={(e) =>
                                      handleincomesharingname(e, index)
                                    }
                                    required
                                  >
                                    <option value="">- Select Type -</option>
                                    {/* <option
                              value={clientDetail1?.clientDetail.superAgentName}
                              id={clientDetail1?.clientDetail.superAgentId}
                              email={clientDetail1?.clientDetail.superAgent}
                            >{clientDetail1?.clientDetail.superAgentName}</option> */}

                                    {agent?.map((cuntitem, index) => (
                                      <option
                                        key={index + 1}
                                        value={cuntitem.value}
                                        id={cuntitem.id}
                                        commissionpercentage={
                                          cuntitem.commissionPercentage
                                        }
                                        referalpercentage={
                                          cuntitem.referalPercentage
                                        }
                                        email={cuntitem.email}
                                      >
                                        {cuntitem.value}
                                      </option>
                                    ))}
                                  </select>
                                )}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <select
                                  className={`form-control underline-input ${classes.selectdrop}`}
                                  value={p.incomeSharingType}
                                  style={{ backgroundColor: "#f5f5f9" }}
                                  onChange={(e) =>
                                    handleincomesharingtype(e, index)
                                  }
                                >
                                  <option value="IncomeSharing">
                                    Commission Percentage
                                  </option>
                                  <option value="Referal">Referal</option>
                                </select>
                              </TableCell>
                              {/* <TableCell align="right">
                                <input
                                  placeholder="Amount"
                                  style={{ backgroundColor: "#f5f5f9" }}
                                  value={Number(originalnetincomevalue).toFixed(
                                    2
                                  )}
                                  className="form-control underline-input inputalign"
                                  type="number"
                                  onWheel={(event) =>
                                    event.currentTarget.blur()
                                  }
                                  readOnly
                                />
                              </TableCell> */}
                              {/* <TableCell align="right">
                                <input
                                  // placeholder="Enter Commission%.."
                                  style={{ backgroundColor: "#f5f5f9" }}
                                  value={p.incomeSharingAmount}
                                  className="form-control underline-input inputalign"
                                  type="number"
                                  onWheel={(event) =>
                                    event.currentTarget.blur()
                                  }
                                  onChange={(e) =>
                                    handleincomesharingamount(e, index)
                                  }
                                />
                              </TableCell> */}
                              <TableCell align="right">
                                <input
                                  style={{ backgroundColor: "#f5f5f9" }}
                                  value={
                                    p.incomesharingcommissionamount !== 0 &&
                                    Number(p.incomesharingcommissionamount)
                                  }
                                  placeholder="0.00"
                                  className="form-control align_placeholder underline-input inputalign"
                                  type="number"
                                  onWheel={(event) =>
                                    event.currentTarget.blur()
                                  }
                                  onChange={(e) =>
                                    handleincomesharingComissionamount(e, index)
                                  }
                                // readOnly
                                />
                              </TableCell>
                              <TableCell align="right">
                                <select
                                  className={`form-control underline-input ${classes.selectdrop}`}
                                  value={p.incomeSharingtaxpercent}
                                  style={{ backgroundColor: "#f5f5f9" }}
                                  onChange={(e) =>
                                    handleincomesharingtax(e, index)
                                  }
                                >
                                  <option value="0">0</option>
                                  <option value="10">10</option>
                                </select>
                              </TableCell>
                              <TableCell align="right">
                                <input
                                  style={{ backgroundColor: "#f5f5f9" }}
                                  value={Number(
                                    p.incomeSharingtaxamount
                                  ).toFixed(2)}
                                  className="form-control underline-input inputalign"
                                  type="number"
                                  onWheel={(event) =>
                                    event.currentTarget.blur()
                                  }
                                  readOnly
                                />
                              </TableCell>
                              <TableCell align="right">
                                <input
                                  style={{ backgroundColor: "#f5f5f9" }}
                                  value={Number(
                                    p.incomeSharingAmountOriginal
                                  ).toFixed(2)}
                                  className="form-control underline-input inputalign"
                                  type="number"
                                  readOnly
                                />
                              </TableCell>
                              <TableCell align="right">
                                <BootstrapTooltip title={p.note}>
                                  <input
                                    style={{ backgroundColor: "#f5f5f9" }}
                                    value={p.note}
                                    onChange={(e) =>
                                      handleincomesharingnote(e, index)
                                    }
                                    className="form-control underline-input "
                                    type="text"
                                  />
                                </BootstrapTooltip>
                              </TableCell>

                              <TableCell
                                align="right"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  var lines = [...incomesharing];
                                  var lines2 = [...netincomedataentries];
                                  lines.splice(index, 1);
                                  lines2.splice(index, 1);
                                  setincomeSharing(lines);
                                  setnetincomedataentries(lines2);
                                  setnetincomevalue(
                                    originalnetincomevalue -
                                    lines2?.reduce(
                                      (amount, item) => item + amount,
                                      0
                                    )
                                  );
                                }}
                              >
                                <span
                                  style={{
                                    background: "red",
                                    padding: "0 3px",
                                    fontSize: "19px",
                                    borderRadius: " 3px",
                                    color: "#fff",
                                  }}
                                >
                                  x
                                </span>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Button
                      className="sent-button"
                      style={{
                        marginTop: "20px",
                        // width: "fit-content",
                        // border: "1px solid",
                        // padding: "9px",
                        // borderRadius: "7px",
                        textTransform: "capitalize",
                        float: "left",
                      }}
                      onClick={() => {
                        setincomeSharing((prev) => [
                          ...prev,
                          {
                            id: 0,
                            incomeShareHolderId: 0,
                            incomeSharingType: "IncomeSharing",
                            incomeSharingAmount: 0,
                            incomesharingcommissionamount: 0,
                            incomesharingreferalpercentage: 0, // storing value for incomesharingamount not used in calculation
                            incomesharingcommissionpercentage: 0, // storing value for incomesharingamount not used in calculation
                            incomeSharingName: "",
                            incomeSharingtaxpercent: 10,
                            incomeSharingtaxamount: 0,
                            incomeSharingAmountOriginal: 0,
                            incomeSharingemail: "",
                            note: "",
                            payableInvoiceId: "",
                          },
                        ]);
                      }}
                    >
                      Add Row
                    </Button>
                    {/* </Typography> */}
                  </div>
                </>
              )}
              <div
                className="invoice_footer"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  margin: "0 10px",
                }}
              >
                <div
                  style={{
                    margin: "14px 10px",
                    width: "270px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    textAlign: "left",
                  }}
                >
                  <Typography
                    color="primary"
                    variant="body1"
                    style={{
                      marginTop: "6px",
                      marginBottom: "15px",
                      fontWeight: "bolder",
                      marginLeft: "10px",
                    }}
                  >
                    Status
                  </Typography>
                  <FormControl
                    variant="filled"
                    style={{ width: "100%", marginTop: "-10px" }}
                    className={classes.formControl}
                  >
                    {/* <InputLabel id="demo-simple-select-label">Status</InputLabel> */}
                    {totalAmountPaid === Number(netincomevalue) ? (
                      <Select
                        id="demo-simple-select3"
                        value={statusoption}
                        required
                        onChange={(e) => handleStatus(e)}
                        style={{
                          height: "40px",
                        }}
                      >
                        <MenuItem value="Paid">Paid</MenuItem>
                        <MenuItem value="PartiallyPaid">PartiallyPaid</MenuItem>
                        <MenuItem value="Unpaid">Unpaid</MenuItem>
                        <MenuItem value="Refund">Refund</MenuItem>
                        <MenuItem value="Cancelled">Cancelled</MenuItem>
                        {/* <MenuItem value="Active">Active</MenuItem> */}
                      </Select>
                    ) : (
                      <Select
                        id="demo-simple-select3"
                        value={statusoption}
                        required
                        onChange={(e) => handleStatus(e)}
                        style={{ height: "40px" }}
                      >
                        <MenuItem value="Paid">Paid</MenuItem>
                        <MenuItem value="PartiallyPaid">PartiallyPaid</MenuItem>
                        <MenuItem value="Unpaid">Unpaid</MenuItem>
                        <MenuItem value="Refund">Refund</MenuItem>
                        <MenuItem value="Cancelled">Cancelled</MenuItem>
                        {/* <MenuItem value="Active">Active</MenuItem> */}
                      </Select>
                    )}
                  </FormControl>
                </div>
              </div>
              <br />
              <div
                className="invoice_footer"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  margin: "0 10px",
                  marginBottom: "-15px",
                }}
              >
                <button
                  style={{ margin: "5px 10px", height: "40px" }}
                  className="sent-button"
                  type="submit"
                >
                  Save
                </button>
                <button
                  style={{ margin: "5px 0px", height: "40px" }}
                  className="sent-button"
                  onClick={goBack}
                >
                  Invoice List
                </button>
              </div>
            </div>
            {/* </div> */}
            <hr />
          </form>
        </div>
        <Modal
          isOpen={statusmodal}
          onRequestClose={closestatusModal}
          style={customStyles}
        >
          <h3 style={{ color: "#000" }}>Change Status</h3>
          <hr />
          {Number(totalAmountPaid) === 0 ? (
            <h5>
              Your Payment Received field is <strong>null</strong>. Please
              choose <strong>UNPAID</strong> status
            </h5>
          ) : (
            <>
              {Number(gettotaldue()) > 0 ? (
                <h5>
                  Your Due amount is not <strong>Null</strong>. Please choose{" "}
                  <strong>PARTIAL PAYMENT</strong> status
                </h5>
              ) : (
                <h5>
                  Your Due amount is <strong>null</strong>. Please choose{" "}
                  <strong>PAID</strong> status
                </h5>
              )}
            </>
          )}
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-outline-danger"
              onClick={closestatusModal}
            >
              Close
            </button>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default AddInvoiceDetails;
