import React from "react";
import { render } from "react-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import base_url from "./../../../api/bootapi";
import RLDD from "react-list-drag-and-drop/lib/RLDD";
import "./../../css/Vertical.css";
import { ToastContainer, toast } from "react-toastify";
import {
  Card,
  CardBody,
  CardSubtitle,
  CardText,
  Col,
  Row,
  Button,
  Table,
} from "reactstrap";
import Dashboard from "../../Dashboard/Dashboard";
// import Modal from 'react-modal';

const drawerWidth = 300;
const drawerWidth2 = 60;
const selectheight = "35px";
const selectWidth = "100%";
const headtxt = 30;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

class VerticalExample extends React.PureComponent {
  constructor(props) {
    super(props);

    this.jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
    this.current_enquiry_id = localStorage.getItem("current_enquiry_id");
    //console.log(this.current_enquiry_id);
    this.param_workflow_id = props.match.params.workflow_id;
    this.current_client_name = localStorage.getItem("current_client_name");

    this.username = localStorage.getItem("isw_user_email");
    //console.log(this.param_workflow_id);

    this.state = {
      items: [],
    };
    this.stateCust = {
      workFlowList: [],
    };

    this.itemRenderer = this.itemRenderer.bind(this);
    this.handleRLDDChange = this.handleRLDDChange.bind(this);
  }

  componentDidMount() {
    this.UserList();
    this.getAllWorkflowList();
    // this.getEnquiryWorkflowByEnquiry();
  }

  // getEnquiryWorkflowByEnquiryId(){
  //   axios.get(`${base_url.api2}/getEnquiryWorkflowByEnquiryId`, {
  //     headers: {
  //         'Authorization':this.jwtToken,
  //         'enquiryId':this.current_enquiry_id,
  //     }}).then(
  //     (response)=>{
  //       //console.log(response.data.data);
  //       // localStorage.setItem('current_enquiry_workflow_id',response.data.data.enquiryWorkflowId);
  //     },
  //     (error)=>{
  //         //console.log(error);
  //     }
  //   ).catch(error=>{
  //       //console.log(error);
  //       toast.error(error.response.data.message);
  //   })
  // }

  UserList() {
    this.setState({ current_workFlow_id: this.workflow_id });
    axios
      .get(`${base_url.api2}/getWorkflowStepByWorkflowId`, {
        headers: {
          Authorization: this.jwtToken,
          workflowId: this.param_workflow_id,
        },
      })
      .then(
        (response) => {
          //console.log(response.data.data.workflowName);
          const item_obj = [];
          for (let i = 0; i < response.data.data.workflowSteps.length; i++) {
            const element = response.data.data.workflowSteps[i];
            element["id"] = response.data.data.workflowSteps[i].workFlowStepsId;
            item_obj.push(element);
          }
          toast.success("Success");
          this.setState({
            items: response.data.data.workflowSteps,
            currentworkflowname: response.data.data.workflowName,
          });

          this.itemRenderer = this.itemRenderer.bind(this);
          this.handleRLDDChange = this.handleRLDDChange.bind(this);
        },
        (error) => {
          //console.log(error);
        }
      )
      .catch((error) => {
        //console.log(error);
        toast.error(JSON.stringify(error.response.data.message));
      });
    //console.log(this.state.items);
    //   axios.get(`${base_url.api2}/getEnquiryWorkflowByEnquiryId`, {
    //   headers: {
    //       'Authorization':this.jwtToken,
    //       'enquiryId':Number(this.current_enquiry_id),
    //   }}).then(
    //   (response)=>{
    //     //console.log(response.data);
    //     localStorage.setItem('current_enquiry_workflow_id',response.data.data.enquiryWorkflowId);
    //   },
    //   (error)=>{
    //       //console.log(error);
    //   }
    // ).catch(error=>{
    //     //console.log(error);
    //     toast.error(error.response.data.message);
    // })
  }

  getAllWorkflowList() {
    axios
      .get(`${base_url.api2}/getAllWorkflowWithoutSteps`, {
        headers: {
          Authorization: this.jwtToken,
          username: this.username,
        },
      })
      .then(
        (response) => {
          //console.log(response.data.data);
          toast.success("Success");
          this.setState({ allworkflowlist: response.data.data });
        },
        (error) => {
          toast.error(JSON.stringify(error.response.data.message));
        }
      )
      .catch((error) => {
        //console.log(error);
        toast.error(JSON.stringify(error.response.data.message));
      });
  }

  render() {
    const items = this.state.items;
    const { navigate } = this.props;
    const getWorkflowSteps = (workFlow_id) => {
      //console.log(workFlow_id);
      this.setState({ current_workFlow_id: workFlow_id });
      // axios.get(`${base_url}/getWorkflowById`, {
      //     headers: {
      //         'Authorization':this.jwtToken,
      //         'workflowId':workFlow_id,
      //     }}).then(
      //     (response)=>{
      //       //console.log(response.data.data.workflowSteps);
      //       const item_obj=[];
      //       for (let i = 0; i < response.data.data.workflowSteps.length; i++) {
      //         const element = response.data.data.workflowSteps[i];
      //         element["id"] = response.data.data.workflowSteps[i].workFlowStepsId;
      //         item_obj.push(element);
      //       }
      //       this.setState({ items: response.data.data.workflowSteps });
      //       this.itemRenderer = this.itemRenderer.bind(this);
      //       this.handleRLDDChange = this.handleRLDDChange.bind(this);
      //       toast.success("Success");
      //     }
      //   ).catch(error=>{
      //       //console.log(error);
      //       toast.error(error.response.data.message);
      //   })
    };

    const addworkflow = (props) => {
      const { navigate } = this.props;
      //console.log(this.props.navigate.location.pathname);
      //console.log(this.param_workflow_id);
      this.props.navigate.replace({
        pathname: "/addWorkflowSteps/" + this.param_workflow_id,
      });
    };
    const current_client_id = localStorage.getItem("current_client_id");
    const goback = () => {
      this.props.navigate.replace("/show-Workflow/" + this.current_enquiry_id);
    };

    const updateworkflow = () => {
      let workflowSteps = this.state.items;
      let data = {
        workflowId: this.param_workflow_id,
        workflowSteps: workflowSteps,
      };
      //console.log(data);
      axios
        .post(`${base_url.api2}/updateWorkflowStepOrderByStepId`, data, {
          headers: {
            Authorization: this.jwtToken,
          },
        })
        .then(
          (response) => {
            //console.log(response.data.data);
            toast.success("Success");
          },
          (error) => {
            //console.log(error);
            toast.error(JSON.stringify(error.response.data.message));
          }
        )
        .catch((error) => {
          //console.log(error);
          toast.error(JSON.stringify(error.response.data.message));
        });
    };

    const Modal = (props) => {
      // if (!props.show) {
      //     return null
      // }
      return (
        <div className="modal">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Modal Title</h4>
            </div>
            <div className="modal-body">this is body</div>
            <div className="modal-footer">
              <button className="button">close</button>
            </div>
          </div>
        </div>
      );
    };

    return (
      <div className="toolbar">
        <ToastContainer />
        <Dashboard />
        <Card>
          <CardBody>
            <CardSubtitle className="font-weight-bold"></CardSubtitle>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                getWorkflowSteps(e);
              }}
            >
              <div className="form-inner-admin">
                <Row>
                  <Col md={6}>
                    <CardText className="headtext">
                      Work Flow Steps of:- {this.current_client_name}{" "}
                    </CardText>
                  </Col>
                  <Col md={6}>
                    <button
                      style={{ float: "right" }}
                      color="primary"
                      className="createBtn2"
                      onClick={goback}
                    >
                      &#x2630; List
                    </button>
                  </Col>
                </Row>
                <div className="form-group creatadmin">
                  <Row>
                    <Col md={4}>
                      <h4>Work Flow Name:- {this.state.currentworkflowname}</h4>
                    </Col>
                    <Col md={6}>
                      {/* <select style={{width:"100%"}}
                            onChange={e => {
                              let value = e.target.value;
                              getWorkflowSteps(value);
                              }} required>
                                {(this.state.allworkflowlist==null)?"No Work Flow Found":(this.state.allworkflowlist.map((cuntitem) =>
                                <option id={cuntitem.workflow_id} key={cuntitem.workflow_id} value={cuntitem.workflow_id}>{cuntitem.workflow_name}</option>
                                ))}
                            </select> */}
                    </Col>
                  </Row>
                </div>
              </div>
            </form>
            <Row>
              <Col md={6}>
                <button
                  type="submit"
                  className="btn btn-primary cust-btn btn-sm btnmargin"
                  onClick={addworkflow}
                >
                  Add WorkFLow Step
                </button>
              </Col>
              <Col md={6}>
                {this.state.items == "" ? null : (
                  <button
                    type="submit"
                    className="btn btn-primary cust-btn btn-sm btnmargin2"
                    style={{ background: "green" }}
                    onClick={updateworkflow}
                  >
                    Save Workflow Steps
                  </button>
                )}
              </Col>
            </Row>
            <br />
            {this.state.items == "" ? (
              <h3 style={{ marginLeft: "20px" }}>
                <strong>No Steps Created</strong>
              </h3>
            ) : (
              <RLDD
                cssClasses="example"
                items={items}
                itemRenderer={this.itemRenderer}
                onChange={this.handleRLDDChange}
              />
            )}
          </CardBody>
        </Card>
      </div>
    );
  }

  itemRenderer(item, index) {
    item["workflowStepsOrder"] = index;
    const addNotes = (elem) => {
      //console.log("add notes");
    };

    var subtitle;
    const openModal = (elem) => {
      //console.log(elem);
      localStorage.setItem("selected_workflow_step_id", elem.workFlowStepsId);
      localStorage.setItem(
        "selected_workflow_step_name",
        elem.workflowStepsName
      );
      let current_workflow_id = localStorage.getItem("current_workflow_id");
      this.state = {
        selectedstep: elem,
        modalIsOpen: true,
      };

      this.props.navigate.replace({
        pathname: "/add-notes-WorkflowSteps/" + current_workflow_id,
      });
    };

    return (
      <>
        <div className="item">
          <p className="title">{item.workflowStepsName}</p>
          <p className="body">{item.workflowStepsName}</p>
          {/* { (this.state.modalIsOpen==true)? "no error":"error"} */}
          <div className="small2">
            <Button id={item.id} onClick={() => openModal(item)}>
              View Notes
            </Button>
          </div>
          {/* <div className="small">
          item.id: {item.id} - index: {index}
        </div> */}
        </div>
      </>
    );
  }

  handleRLDDChange(reorderedItems) {
    //console.log(reorderedItems);
    this.setState({ items: reorderedItems });
  }
}

export default VerticalExample;
//render(<VerticalExample />, document.getElementById('root'));
