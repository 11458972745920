import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { Row, Col, Button } from "reactstrap";
import {
  customStyles,
  editorConfiguration,
} from "../../../Utilities/CSSUtilities";
import email from "../../images/workload/email.svg";
import Typography from "@mui/material/Typography";
import base_url from "../../../api/bootapi";
import axios from "axios";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";

const AdmissionEmailModal = ({
  openModal3,
  client_name,
  modalIsOpen3,
  submit3,
  isApplicationClosed,
  jwtToken,
  handle403error,
  setworkloaddescriptionvalue,
  setworkloaddescriptionvalue2,
  workloaddescriptionvalue2,
  workloaddescriptionvalue,
  proformadetails,
}) => {
  const [emailmodal, setemailmodal] = useState(false);

  useEffect(() => {
    if (!modalIsOpen3) {
      closeemailmodal();
    }
  }, [modalIsOpen3]);

  const openemailmodal = () => {
    setemailmodal(true);
  };
  const closeemailmodal = () => setemailmodal(false);

  // Proforma templates
  const [template, settemplate] = useState(null);
  const [loadermodal, setloadermodal] = useState(true);

  return (
    <>
      <img
        src={email}
        title="Email"
        alt=""
        style={{ margin: "1px" }}
        onClick={openemailmodal}
      />
      <Modal
        isOpen={isApplicationClosed ? false : emailmodal}
        style={customStyles}
        onRequestClose={openemailmodal}
        contentLabel="Example Modal"
      >
        <div>
          <Typography variant="body1" color="primary">
            Send Email
          </Typography>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              submit3(e);
              setemailmodal(false);
            }}
          >
            <div className="">
              <Row>
                <Col>
                  <label>To</label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    value={client_name}
                    readOnly
                  />
                </Col>
              </Row>
            </div>

            <div className="creatadmin">
              <Row>
                <Col>
                  <label>Subject</label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    required
                  />
                </Col>
              </Row>
            </div>
            <div className="creatadmin">
              <Row>
                <Col>
                  <label>Templates</label>
                  <select
                    onChange={(e) => settemplate(e.target.value)}
                    style={{
                      width: "100%",
                      height: "40px",
                      backgroundColor: "f5f5f9",
                    }}
                  >
                    <option value="">- Select Template -</option>
                    {proformadetails?.map((item, index) => (
                      <option key={index} value={item.template}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </Col>
              </Row>
            </div>
            <div className="creatadmin">
              <Row>
                <Col>
                  <label>Description</label>
                  {template === null ? (
                    <CKEditor
                      editor={ClassicEditor}
                      config={editorConfiguration}
                      data={workloaddescriptionvalue2}
                      onReady={(editor) => {
                        //console.log("Editor is ready to use!", editor);
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setworkloaddescriptionvalue2(data);
                      }}
                    />
                  ) : (
                    <CKEditor
                      editor={ClassicEditor}
                      config={editorConfiguration}
                      data={template}
                      onReady={(editor) => {
                        //console.log("Editor is ready to use!", editor);
                        // setloadermodal(true);
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setworkloaddescriptionvalue(data);
                      }}
                    />
                  )}
                </Col>
              </Row>
            </div>
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                padding: "5px",
              }}
            >
              <button
                className="btn btn-secondary"
                // onClick={closeModal}
                onClick={closeemailmodal}
              >
                Cancel
              </button>
              <button className="btn listing_addbutton ml-1" type="submit">
                Submit
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default AdmissionEmailModal;
