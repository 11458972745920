import React, { useState } from "react";
import Modal from "react-modal";
import { customStyles2 } from "../../Utilities/CSSUtilities";
import DatePicker from "react-datepicker";
import { useForm } from "react-hook-form";
import moment from "moment";
import axios from "axios";
import base_url from "../../api/bootapi";
import { useStyles } from "../../Utilities/CSSUtilities";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import Backdrop from "@mui/material/Backdrop";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { logout, selectUser } from "./../features/userSlice";
import { useParams, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

// const PayableInvoiceStatus = ({ invoiceStatus, amount, taxamount,invoiceId,getAllData,
//   pageno,invoicePaidFromDate,invoicePaidToDate,invoiceSubType,invoiceType,
//   invoiceSuperAgent,invoiceProvider,officeId,commisionType,columnName,direction,page,size
// }) => {
const UpdatePaymentDateModal = (props) => {
  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  const classes = useStyles();
  const [statusmodal, setstatusmodal] = useState(false);
  const [selecteddate, setdate] = useState(null);
  const [paymentmethod, setpaymentmethod] = useState("");
  const [paymentmethoderror, setpaymentmethoderror] = useState(false);
  const [dateerror, setdateerror] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [backdropopen, setbackdropopen] = useState(false);
  const isw_user_name = localStorage.getItem("isw_user_name");
  const handleStatusModal = () => {
    setpaymentmethod("");
    setstatusmodal(!statusmodal);
    setdate(null);
    setpaymentmethoderror(false);
    setdateerror(false);
    setpaidDate(props.invoicePayDate);
    //console.log(props.invoicePayDate);
    let getmypaidDate = props.invoicePayDate;
  };

  const submitinvoice = () => {
    if (!paymentmethod) {
      setpaymentmethoderror(true);
      return;
    }
    setpaymentmethoderror(false);
    if (selecteddate === null) {
      setdateerror(true);
      return;
    }
    setdateerror(false);
  };

  const [showtextMsg, setshowtextMsg] = useState("");
  const [showWarningMsg, setshowWarningMsg] = useState("");
  const [paidmodalIsOpen, setpaidisopen] = useState(false);
  const createPayment = (data2) => {
    // setbackdropopen(true);
    let paymentMethod = data2.paymentMethod;
    let PaymentPayDate;
    if (getpaidDate) {
      PaymentPayDate = moment(getpaidDate).format("YYYY-MM-DD");
    }

    let data = {
      paymentMethod,
      PaymentPayDate,
    };
    //console.log(data);
    axios
      .post(
        `${base_url.api2}/updatePaymentDateByPayableId`,
        {},
        {
          headers: {
            Authorization: jwtToken,
            invoiceId: props.invoiceId,
            // invoicePaidBy: isw_user_name,
            // paymentMethod: data.paymentMethod,
            PaymentPayDate: data.PaymentPayDate,
          },
        }
      )
      .then((response) => {
        //console.log(response.data);
        //console.log(response.data.message);
        if (response.data.status_code === 200) {
          // setshowtextMsg("Payment Updated Successfully");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Payment Date Updated Successfully",
          });
          setstatusmodal(false);
          setbackdropopen(false);
        } else if (response.data.status_code === 404) {
          //setshowWarningMsg("Invoice Not Found");
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Invoice Not Found",
          });
          setstatusmodal(false);
          setbackdropopen(false);
        } else {
          //setshowWarningMsg("Something Went Wrong");
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Something Went Wrong!",
          });
          setstatusmodal(false);
          setbackdropopen(false);
        }
        // setstatusmodal(false);
        // setbackdropopen(false);
        let data = {
          id: props.invoiceId,
        };
        //console.log(data);
        axios
          .post(`${base_url.api2}/updateInvoiceStatusByInvoiceId`, data, {
            headers: {
              Authorization: jwtToken,
              invoiceStatus: "Paid",
            },
          })
          .then((res) => {
            toast.success("Invoice Paid");
            setpaidisopen(false);
            let headData = {
              Authorization: jwtToken,
              invoiceSubType: props.invoiceSubTypeData,
              invoiceStatus: props.invoiceStatusData,
              invoiceType: props.invoiceAccountTypeData,
              invoicePaidFromDate: props.paidStartDate,
              invoicePaidToDate: props.paidEndDate,
              invoiceSuperAgent: props.selectedSubAgent,
              invoiceProvider: props.providerData,
              officeId: props.userOfficeId,
              commisionType: props.invoiceCommisionTypeData,
              columnName: props.sortState,
              direction: props.direction,
              page: Number(props.currentPage),
              size: props.rows,
            };
            //console.log(headData);
            props.getAllData(headData);
          })
          .catch((err) => {
            if (err.response) {
              toast.error(JSON.stringify(err?.response?.data?.message));
            } else {
              toast.error("Something Went Wrong");
            }
            handle403error(err);
          });
      })
      .catch((error) => {
        // setstatusmodal(false);
        // setbackdropopen(false);
        //console.log(JSON.stringify(error));
        handle403error(error);
      });
  };

  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            navigate("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };

  //console.log(props.invoicePayDate);
  const [getpaidDate, setpaidDate] = useState();

  // setpaidDate(props.invoicePayDate);
  //console.log(props);
  //console.log(props.invoicePaidToDate);
  //console.log(props.invoicePaidFromDate);
  //console.log(props.invoiceSubTypeData);
  //console.log(props.invoiceAccountTypeData);
  //console.log(props.invoiceSuperAgent);
  //console.log(props.invoiceProvider);
  //console.log(props.officeId);
  //console.log(props.commisionType);
  //console.log(props.columnName);
  //console.log(props.direction);
  //console.log(props.page);
  //console.log(props.size);

  return (
    <>
      <Backdrop className={classes.backdrop} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <td className="payableinvoicestatus">
        {props.invoicePayDate}{" "}
        {props.check1pointpermission(
          528,
          props.permissionData,
          props.rolepermission
        ) ? (
          <>
            {props.invoicePayDate ? (
              <i class="fa fa-ellipsis-v" onClick={handleStatusModal}></i>
            ) : null}
            <Modal isOpen={statusmodal} style={customStyles2}>
              <form id="myForm" onSubmit={handleSubmit(createPayment)}>
                <div className="d-flex justify-content-between">
                  <p className="mb-0">Payment Details</p>
                  <p
                    onClick={() => {
                      handleStatusModal();
                    }}
                    style={{ cursor: "pointer" }}
                    className="mb-0"
                  >
                    X
                  </p>
                </div>
                <h6></h6>
                <h6 style={{ color: "green" }}>{showtextMsg}</h6>
                <h6 style={{ color: "red" }}>{showWarningMsg}</h6>
                <div className="row mx-0 my-2">
                  <div className="col-md-6">
                    <select
                      className="form-control"
                      name="paymentMethod"
                      onInput={(e) => setpaymentmethod(e.target.value)}
                      ref={register({ required: true })}
                    >
                      <option value="">Select Payment Method</option>
                      <option value="Cheque">Cheque</option>
                      <option value="Cash">Cash</option>
                      <option value="Credit Card">Credit Card</option>
                      <option value="Bank Transfers">Bank Transfers</option>
                    </select>
                    {errors.gender && (
                      <p className="errormessage">Payment Method is Required</p>
                    )}
                  </div>
                  <div className="col-md-6 position-relative">
                    <DatePicker
                      selected={getpaidDate ? new Date(getpaidDate) : null}
                      onChange={(date) => setpaidDate(date)}
                      dateFormat="dd-MM-yyyy"
                      showYearDropdown
                      dropdownMode="select"
                      placeholderText="Select Date"
                    />
                    <span
                      className="listpagesearch_button"
                      style={{
                        top: "10px",
                        position: "absolute",
                        right: "10px",
                        pointerEvents: "none",
                      }}
                    >
                      <i className="fa fa-calendar"></i>
                    </span>
                    {dateerror && (
                      <p className="errormessage">Date is Required</p>
                    )}
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <button className="btn btn-primary">Add</button>
                  <p className="mb-0">Amount : {props.amount}</p>
                  <p className="mb-0">Tax Amount : {props.taxamount}</p>
                </div>
              </form>
            </Modal>
          </>
        ) : null}
      </td>
    </>
  );
};

export default UpdatePaymentDateModal;
