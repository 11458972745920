import React from "react";
import { ResponsivePie } from "@nivo/pie";

const UserCountEnquiryChart = ({ data }) => (
  <ResponsivePie
    data={data.map(([label, value]) => ({ id: label, label, value }))}
    margin={{ top: 10, right: 250, bottom: 10, left: 10 }}
    innerRadius={0.5}
    padAngle={0.7}
    cornerRadius={3}
    colors={{ scheme: "nivo" }}
    borderWidth={1}
    borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
    radialLabelsSkipAngle={10}
    radialLabelsTextXOffset={6}
    radialLabelsTextColor="#333333"
    slicesLabelsSkipAngle={10}
    slicesLabelsTextColor="#333333"
    animate={true}
    motionStiffness={90}
    motionDamping={15}
    enableArcLinkLabels={false}
    arcLabelsTextColor="transparent"
    enableRadialLabels={true}
    radialLabel=".data.label"
    tooltip={({ datum }) => <span>{datum.id}</span>} // Show only the name in the tooltip
    legends={[
      {
        anchor: "right",
        direction: "column",
        justify: false,
        translateX: 100,
        translateY: 0,
        itemsSpacing: 0,
        itemDirection: "left-to-right",
        itemWidth: 80,
        itemHeight: 20,
        itemOpacity: 1,
        symbolSize: 12,
        symbolShape: "circle",
        symbolBorderColor: "rgba(0, 0, 0, .5)",
        symbolBorderWidth: 2,
        effects: [
          {
            on: "hover",
            style: {
              itemOpacity: 1,
            },
          },
        ],
      },
    ]}
  />
);

export default UserCountEnquiryChart;
