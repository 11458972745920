import { createSlice } from '@reduxjs/toolkit';

export const adminSlice = createSlice({
  name: 'admin',
  initialState: {
    admin: [],
  },
  reducers: {
    getAdmin: (state, action) => {
      state.admin = action.payload
    },

    addtoAdmin: (state, action) => {
      state.admin = [...state.admin, action.payload]
    },
    removeAdmin: (state, action) => {
      const index = state.admin.findIndex(
        (adminUser) => adminUser.username === action.payload.id
      )
      let newAdmin = [...state.admin]
      if (index >= 0) {
        newAdmin.splice(index, 1)
      }
      else {
        console.warn('Cant remove')
      }
      return {
        ...state,
        admin: newAdmin
      }
    },
    updateAdmin: (state, action) => {
      const index = state.admin.findIndex(
        (adminUser) => adminUser.username === action.payload.id
      )
      let newAdmin = [...state.admin]
      if (index >= 0) {
        newAdmin.splice(index, 1)
        newAdmin.splice(index, 0, action.payload.data)
      }
      else {
        console.warn('Cant remove')
      }
      // newAdmin.push(action.payload.data)
      return {
        ...state,
        admin: newAdmin
      }
    }
  },
});

export const { getAdmin, addtoAdmin, removeAdmin, updateAdmin } = adminSlice.actions;

export const selectAdmin = state => state.admin.admin;

export default adminSlice.reducer;
