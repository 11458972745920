


import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Table, Card, Col, Row, Button } from "reactstrap";
import Modal from "react-modal";
import { toast } from "react-toastify";
import axios from "axios";
import base_url from "../../api/bootapi";
import attachmenturl from "../../api/attachmenturl";
import { useStyles } from "../../Utilities/CSSUtilities";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Pagination from "@mui/material/Pagination";
import Chart from "react-google-charts";
import { logout, selectUser } from "../features/userSlice";
import moment from "moment";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import close from "../../../src/components/images/closeicon.png";
import {
  customStyles,
  customStylesAttachment,
} from "../../Utilities/CSSUtilities";
import { useForm } from "react-hook-form";
import {
  editorConfiguration,
  customFileStyles,
} from "../../Utilities/CSSUtilities";
import callsaveauditapi from "../../services/auditservice";
import Illustrationwork from "./../images/client-dashboard/Illustrationwork.png";
import FileViewer from "react-file-viewer";
import { CustomErrorComponent } from "custom-error";
import { check1pointpermission } from "../Permission/permission";
import { param } from "jquery";
import WorkflowFiles from "./../Enquiry/WorkflowFiles";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { textAlign } from "@mui/system";

export const customStyles3 = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    minWidth: "700px",
    maxWidth: "900px",
    minHeight: "500px",
    maxHeight: "600px",
    transform: "translate(-50%, -50%)",
  },
};
export const customStyles2 = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    maxWidth: "900px",
    maxHeight: "600px",
    transform: "translate(-50%, -50%)",
  },
};

export const customStyles5 = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    marginTop: "70px",
    minWidth: "800px",
    maxWidth: "800px",
    minHeight: "800px",
    maxHeight: "800px",
    transform: "translate(-50%, -50%)",
  },
};

const WorkflowDashboard = () => {
  const user = useSelector(selectUser);
  const isw_user_name = localStorage.getItem("isw_user_name");
  const isw_user_email = localStorage.getItem("isw_user_email");
  const navigate = useNavigate();
  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  // const selected_client_id = localStorage.getItem("selected_client_id");
  const params = useParams();
  const selected_client_id =
    params.client_id || localStorage.getItem("selected_client_id");
  const [descriptionState2, setdescriptionState2] = useState(null);
  const classes = useStyles();
  const [opennotemodal, setopennotemodal] = useState(false);
  const [noteworkflowtype, setnoteworkflowtype] = useState("");
  const [backdropopen, setbackdropopen] = useState(false);
  const [showaddnotebutton, setshowaddnotebutton] = useState(true);
  const [pageno, setpageno] = useState(0);
  const [totalUser, settotalUser] = useState();
  const [countnumber, setcountnumber] = useState();
  const [numberofElements, setnumberofElements] = useState();
  const [clientnotes, setclientnotes] = useState();
  const [getpageno, setgetpageno] = useState();
  const [viewAttachment, setViewAttachment] = useState(false);

  const [documentpage, setDocPage] = useState(0);
  const [seconddocumentpage, secondsetDocPage] = useState(0);
  const [getDocPage, setgetDocPage] = useState();
  // ---------->Workload notes useState<------------
  const [workloadpageno, setworkloadpageno] = useState(0);
  const [workloadnotes, setworkloadnotes] = useState([]);
  const [getworkloadpageno, setgetworkloadpageno] = useState();
  const [totalworkload, settotalworkload] = useState();
  const [workloadcount, setworklaodcount] = useState();
  const [workloadnumberelements, setworkloadnumberelements] = useState();
  // const [workloadviewmodal, setworkloadviewmodal] = useState(false);
  // const [workloadtemplatedesign, setworkloadtemplatedesign] = useState("");
  const [workloadtitlevalue, setworkloadtitlevalue] = useState("");
  // const [template, settemplate] = useState(null);
  const [workloaddescriptionvalue2, setworkloaddescriptionvalue2] =
    useState("");
  const [workloaddescriptionvalue, setworkloaddescriptionvalue] = useState("");
  const [workloadnoteid, setworkloadnoteid] = useState("");
  const [viewworkloadmodal, setviewworkloadmodal] = useState(false);
  // ---------->Workload notes useState End<------------
  const dispatch = useDispatch();
  const editorRef = useRef();
  const [modalIsOpen2, setIsOpen2] = React.useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [showadddocbutton, setshowaddDocbutton] = useState(false);

  const [totalDocUser, settotalDocUser] = useState();
  const [countDocnumber, setcountDocnumber] = useState();
  const [numberofDocElements, setnumberofDocElements] = useState();
  const [clientDocuments, setclientDocuments] = useState();

  const [clientAllDocuments, setclientAllDocuments] = useState();
  const [documentallpage, setAllDocPage] = useState(0);
  const [getAllDocPage, setgetAllDocPage] = useState();
  const [totalAllDocUser, settotalAllDocUser] = useState();
  const [countAllDocnumber, setcountAllDocnumber] = useState();
  const [numberofAllDocElements, setnumberofAllDocElements] = useState();

  const [admissiondata, setadmissiondata] = useState([
    ["EnquiryStatus", "Count"],
  ]);
  const [migrationdata, setmigrationdata] = useState([
    ["EnquiryStatus", "Count"],
  ]);
  const [insurancedata, setinsurancedata] = useState([
    ["EnquiryStatus", "Count"],
  ]);
  const [skillassessmentdata, setskillassessmentdata] = useState([
    ["EnquiryStatus", "Count"],
  ]);

  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);
  const [getClientId, setClientId] = useState();
  const [get_client_id, set_client_id] = useState();

  useEffect(() => {
    //console.log("Current Page No--->", documentpage);
    //console.log("Current Page No--->", seconddocumentpage);
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        navigate("/");
      }
    });
    setbackdropopen(true);
    getPermissions();
  }, []);

  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            navigate("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);

    if (check1pointpermission(306, permission_obj, resource_obj)) {
      getData();
    } else {
      navigate("/errorPage");
    }
  };

  const getData = () => {
    getStatusAdmissionCount();
    getStatusMigrationCount();
    getStatusInsuranceCount();
    getStatusSkillAssessmentCount();
    //console.log("Current Page No1--->", pageno);
    getclientreport(pageno);
    //console.log("Current Page No2--->", workloadpageno);
    getworkloadnotes(workloadpageno);
    //console.log("Current Page No3--->", documentpage);
    getClientDoc(documentpage);
    getAttachments(seconddocumentpage);
    getAllClientDoc(documentallpage);
    getproforma();
  };

  const closeModal = (id) => {
    // setIsOpen(false);
    setIsOpen2(false);
    // setIsOpen3(false);
    // setIsOpen4(false);
  };

  const viewFile = (elem) => {
    navigate("/viewfile/" + params.client_id);
  };

  const getworkloadnotes = (value) => {
    axios
      .get(`${base_url.api2}/getEnquiryNotesFromClientId`, {
        headers: {
          Authorization: jwtToken,
          clientId: selected_client_id,
          columnName: "createdOn",
          direction: "DESC",
          page: value,
          size: 3,
        },
      })
      .then((res) => {
        const filteredData = res.data.data.content.map(([comment, course]) => ({
          title: comment.title,
          course: course,
          workflowName: comment.enquiryWorkflowStepsName,
          createdDate: comment.createdOn,
        }));
        console.log("261--->", filteredData);
        setworkloadnotes(filteredData);
        setgetworkloadpageno(res.data.data.totalPages);
        settotalworkload(res.data.data.totalElements);
        setworklaodcount(res.data.data.number);
        setworkloadnumberelements(res.data.data.numberOfElements);
        setbackdropopen(false);
      })
      .catch((err) => {
        setbackdropopen(false);
        setworkloadnotes(null);
        setgetworkloadpageno(null);
        settotalworkload(null);
        setworklaodcount(null);
        setworkloadnumberelements(null);
        handle403error(err);
      });
  };

  const handleworkloadpage = (event, value) => {
    setbackdropopen(true);
    setworkloadpageno(value - 1);
    getworkloadnotes(value - 1);
  };

  const handleDocument = (event, value) => {
    setbackdropopen(true);
    setDocPage(value - 1);
    getClientDoc(value - 1);
  };
  const handleAllDocument = (event, value) => {
    setbackdropopen(true);
    setDocPage(value - 1);
    getAllClientDoc(value - 1);
  };

  const handlepage = (event, value) => {
    setpageno(value - 1);
    getclientreport(value - 1);
  };

  const getStatusAdmissionCount = () => {
    axios
      .get(
        `${base_url.api2}/getEnquiryStatusCountFilterByClientIdAndWorkflowType`,
        {
          headers: {
            Authorization: jwtToken,
            clientId: selected_client_id,
            workflowType: "Admission",
          },
        }
      )
      .then((res) => {
        let copiedobj = JSON.parse(JSON.stringify(res.data.data));
        for (let i = 0; i < copiedobj.length; i++) {
          let newarr = [];
          newarr[0] = copiedobj[i].enquiryStatus;
          newarr[1] = Number(copiedobj[i].enquiryCount);
          setadmissiondata((prev) => [...prev, newarr]);
        }
        setbackdropopen(false);
      })
      .catch((err) => {
        setbackdropopen(false);
        //console.log("No Data Available");
        handle403error(err);
      });
  };
  const getStatusMigrationCount = () => {
    axios
      .get(
        `${base_url.api2}/getEnquiryStatusCountFilterByClientIdAndWorkflowType`,
        {
          headers: {
            Authorization: jwtToken,
            clientId: selected_client_id,
            workflowType: "Migration",
          },
        }
      )
      .then((res) => {
        let copiedobj = JSON.parse(JSON.stringify(res.data.data));
        for (let i = 0; i < copiedobj.length; i++) {
          let newarr = [];
          newarr[0] = copiedobj[i].enquiryStatus;
          newarr[1] = Number(copiedobj[i].enquiryCount);
          setmigrationdata((prev) => [...prev, newarr]);
        }
        setbackdropopen(false);
      })
      .catch((err) => {
        setbackdropopen(false);
        //console.log("No Data Available");
        handle403error(err);
      });
  };
  const getStatusInsuranceCount = () => {
    axios
      .get(
        `${base_url.api2}/getEnquiryStatusCountFilterByClientIdAndWorkflowType`,
        {
          headers: {
            Authorization: jwtToken,
            clientId: selected_client_id,
            workflowType: "Insurance",
          },
        }
      )
      .then((res) => {
        let copiedobj = JSON.parse(JSON.stringify(res.data.data));
        for (let i = 0; i < copiedobj.length; i++) {
          let newarr = [];
          newarr[0] = copiedobj[i].enquiryStatus;
          newarr[1] = Number(copiedobj[i].enquiryCount);
          setinsurancedata((prev) => [...prev, newarr]);
        }
        setbackdropopen(false);
      })
      .catch((err) => {
        setbackdropopen(false);
        //console.log("No Data Available");
        handle403error(err);
      });
  };
  const getStatusSkillAssessmentCount = () => {
    axios
      .get(
        `${base_url.api2}/getEnquiryStatusCountFilterByClientIdAndWorkflowType`,
        {
          headers: {
            Authorization: jwtToken,
            clientId: selected_client_id,
            workflowType: "SkillAssessment",
          },
        }
      )
      .then((res) => {
        let copiedobj = JSON.parse(JSON.stringify(res.data.data));
        for (let i = 0; i < copiedobj.length; i++) {
          let newarr = [];
          newarr[0] = copiedobj[i].enquiryStatus;
          newarr[1] = Number(copiedobj[i].enquiryCount);
          setskillassessmentdata((prev) => [...prev, newarr]);
        }
        setbackdropopen(false);
      })
      .catch((err) => {
        setbackdropopen(false);
        //console.log("No Data Available");
        handle403error(err);
      });
  };

  const getclientreport = (pageno) => {
    setbackdropopen(true);
    axios
      .get(`${base_url.api3}/getAuditNotesByClientId`, {
        headers: {
          Authorization: jwtToken,
          clientId: selected_client_id,
          columnName: "last_updated_on",
          direction: "DESC",
          page: pageno,
          size: 100,
        },
      })
      .then((res) => {
        setclientnotes(res.data.data.content);
        console.log("422--->", res.data.data.content);
        setgetpageno(res.data.data.totalPages);
        settotalUser(res.data.data.totalElements);
        setcountnumber(res.data.data.number);
        setnumberofElements(res.data.data.numberOfElements);
        setbackdropopen(false);
      })
      .catch((err) => {
        setclientnotes(null);
        setgetpageno(null);
        settotalUser(null);
        setcountnumber(null);
        setnumberofElements(null);
        //console.log(err);
        handle403error(err);
        setbackdropopen(false);
      });
  };

  const [attachments, setattachments] = useState();
  const [attachmentPagination, setattachmentPagination] = useState();

  const getAttachments = (seconddocumentpage) => {
    //console.log("EnquiryId", value1);
    axios
      .get(`${base_url.api2}/getAttachmentsByEnquiryId`, {
        headers: {
          Authorization: jwtToken,
          enquiryId: params.enquiry_id,
          page: seconddocumentpage,
          size: 20,
        },
      })
      .then((res) => {
        for (let j = 0; j < res.data.data.length; j++) {
          res.data.data[j].oldFileName = res.data.data[j].attachmentName;
        }
        //console.log("Attachment Response", res.data.data);
        setattachments(res.data.data);
        let pages = [];
        for (let i = 0; i < res.data.data.totalPages; i++) {
          pages.push(i + 1);
        }
        setattachmentPagination(pages);
      })
      .catch((error) => {
        setattachments([]);
        handle403error(error);
      });
  };

  const getClientDoc = (documentpage) => {
    setbackdropopen(false);
    axios
      .get(`${base_url.api3}/getAttachmentsByClientId`, {
        headers: {
          Authorization: jwtToken,
          clientId: selected_client_id,
          page: documentpage,
          size: 20,
        },
      })
      .then((res) => {
        setclientDocuments(res.data.data.content);
        setgetDocPage(res.data.data.totalPages);
        settotalDocUser(res.data.data.totalElements);
        setcountDocnumber(res.data.data.number);
        setnumberofDocElements(res.data.data.numberOfElements);
      })
      .catch((err) => {
        setclientDocuments(null);
        setgetDocPage(null);
        settotalDocUser(null);
        setcountDocnumber(null);
        setnumberofDocElements(null);
        //console.log(err);
        handle403error(err);
      });
  };
  // --------------------------- fetch all documents ----------------------------------
  const getAllClientDoc = (documentallpage) => {
    setbackdropopen(false);
    axios
      .get(`${base_url.api2}/getAttachmentsByClientId`, {
        headers: {
          Authorization: jwtToken,
          clientId: selected_client_id,
          page: documentallpage,
          size: 20,
        },
      })
      .then((res) => {
        setclientAllDocuments(res.data.data.content);
        setgetAllDocPage(res.data.data.totalPages);
        settotalAllDocUser(res.data.data.totalElements);
        setcountAllDocnumber(res.data.data.number);
        setnumberofAllDocElements(res.data.data.numberOfElements);
      })
      .catch((err) => {
        setclientAllDocuments(null);
        setgetAllDocPage(null);
        settotalAllDocUser(null);
        setcountAllDocnumber(null);
        setnumberofAllDocElements(null);
        //console.log(err);
        handle403error(err);
      });
  };

  const goBack = () => {
    if (params.enquiry_id) {
      //console.log(params.enquiry_id);
      var prev_path = localStorage.getItem("prev_path2");
      navigate(prev_path);
    } else if (params.client_id) {
      //console.log(params.client_id);
      navigate("/client-list");
    }
  };
  const submitNote = (e) => {
    const isoDateString = new Date().toISOString();
    const datenow = isoDateString.split(".")[0];
    let note;
    if (workloaddescvalue) {
      note = workloaddescvalue;
    } else {
      note = workloaddescvalue2;
    }
    const data = {
      addedBy: isw_user_name,
      clientId: selected_client_id,
      createdOn: datenow,
      id: 0,
      note: note,
      workflowType: noteworkflowtype,
      lastUpdatedOn: datenow,
    };
    //console.log(data);
    postClientNote(data);
  };
  const postClientNote = (data) => {
    axios
      .post(`${base_url.api3}/saveClientNotes`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        toast.success("Notes Added");

        let auditdata = {
          addedBy: isw_user_name,
          clientId: selected_client_id,
          createdOn: data.createdOn,
          id: 0,
          //note: descriptionState2,
          note: data.note,
          workflowType: noteworkflowtype,
          lastUpdatedOn: data.lastUpdatedOn,
        };
        //console.log(auditdata);
        axios
          .post(`${base_url.api3}/saveAuditNotes`, auditdata, {
            headers: {
              Authorization: jwtToken,
            },
          })
          .then((response) => {
            //console.log(response);

            axios
              .get(`${base_url.api3}/getAuditNotesByClientId`, {
                headers: {
                  Authorization: jwtToken,
                  clientId: selected_client_id,
                  columnName: "last_updated_on",
                  direction: "DESC",
                },
              })
              .then((response) => {
                //console.log(response);
              });
          })
          .catch((error) => {
            //console.log(error);
            handle403error(error);
          });

        getclientreport(pageno);
        setopennotemodal(false);
      })
      .catch((err) => {
        //console.log("No Data Available");
        handle403error(err);
      });
  };
  const [clientnotesdetail, setclientnotesdetail] = useState();
  const [openeditmodal, setopeneditmodal] = useState(false);
  // const [openviewmodal, setopenviewmodal] = useState(false);
  const seteditnote = (p) => {
    setclientnotesdetail(p);
    setopeneditmodal(true);
  };
  // const setviewnote = (p) => {
  //   setclientnotesdetail(p);
  //   setopenviewmodal(true);
  // };
  // const setviewworkloadnote = (p) => {
  //   setworkloadtemplatedesign(p);
  //   setworkloadviewmodal(true);
  // };
  // const [deletenoteid, setdeletenoteid] = useState();
  // const [opendeletemodal, setopendeletemodal] = useState(false);
  // const setdeletenote = (p) => {
  //   setdeletenoteid(p.id);
  //   setopendeletemodal(true);
  // };
  const changeNotes = (e) => {
    clientnotesdetail.note = e;
    setdescriptionState2(clientnotesdetail.note);
  };
  const editnote = (e) => {
    const isoDateString = new Date().toISOString();
    const datenow = isoDateString.split(".")[0];
    let note;
    if (workloaddescriptionvalue) {
      note = workloaddescriptionvalue;
      //console.log(note);
    } else {
      note = workloaddescriptionvalue2;
      //console.log(note);
    }
    const data = {
      id: clientnotesdetail?.id,
      addedBy: isw_user_name,
      note: note,
      workflowType: clientnotesdetail?.workflowType,
      lastUpdatedOn: datenow,
    };
    axios
      .post(`${base_url.api3}/updateClientNotesById`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        toast.success("Note Updated");
        setopeneditmodal(false);
        getclientreport(pageno);
      })
      .catch((error) => {
        handle403error(error);
      });
  };
  // const deleteCurrentUser = () => {
  //   axios
  //     .delete(`${base_url.api3}/deleteClientNotesById`, {
  //       headers: {
  //         Authorization: jwtToken,
  //         id: deletenoteid,
  //       },
  //     })
  //     .then((res) => {
  //       toast.success("Note deleted");
  //       setopendeletemodal(false);
  //       getclientreport(pageno);
  //     });
  // };

  const handleImageUploadBefore = (files, info, uploadHandler) => {
    //console.log(files, info);
  };

  const seteditworkloadnote = (p) => {
    settemplate(null);
    setworkloadtitlevalue(p.title);
    setworkloaddescriptionvalue2(p.notes);
    setworkloadnoteid(p);
    setviewworkloadmodal(true);
  };

  const workloadnoteeditsubmit = (e) => {
    let enquiryId = Number(workloadnoteid.enquiryId);
    let title = workloadtitlevalue;
    let notes;
    if (workloaddescriptionvalue) {
      notes = workloaddescriptionvalue;
    } else {
      notes = workloaddescriptionvalue2;
    }
    let enquiryWorkflowStepsId = Number(workloadnoteid.enquiryWorkflowStepsId);
    let id = workloadnoteid.id;
    let data = {
      enquiryId,
      title,
      notes,
      enquiryWorkflowStepsId,
      id,
    };
    postNote(data);
  };
  const postNote = (data) => {
    setbackdropopen(true);
    axios
      .post(`${base_url.api2}/updateNoteById`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        toast.success("Success");
        getworkloadnotes(workloadpageno);
        setviewworkloadmodal(false);
      })
      .catch((err) => {
        setbackdropopen(false);
        handle403error(err);
      });
  };

  const changeAttachmentName = (e) => {
    //console.log(e.target[0].value);
    //console.log(e.target[1].value);
    let enquiryWorkflowStepsAttachments = {
      id: Number(fetchedattachmentId),
      attachmentName: e.target[1].value,
    };
    axios
      .put(
        `${base_url.api2}/updateEnquiryAttachmentDataById`,
        enquiryWorkflowStepsAttachments,
        {
          headers: {
            Authorization: jwtToken,
          },
        }
      )
      .then((response) => {
        response.data.data.oldFileName = oldattachmentName;
        seteditattachment(false);
        //console.log("Current Page No--->", documentpage);
        getClientDoc(documentpage);
        getAllClientDoc(documentallpage);
        let logs =
          isw_user_name +
          " Updated Uploaded File Name of enquiry id" +
          e.target[0].value +
          " to " +
          e.target[1].value +
          ".";
        callsaveauditapi(logs, dispatch, navigate);
      })
      .catch((error) => {
        // toast.error(JSON.stringify(error.response.data.message));
        //console.log(JSON.stringify(error));
        handle403error(error);
      });
  };

  const getPath = (data) => {
    data = data.split("/var/www/html")[1];
    return data;
  };
  const [fetchedattachmentName, setattachmentName] = useState();
  const [oldattachmentName, setoldattachmentName] = useState();
  const [fetchedattachmentId, setattachmentId] = useState();
  const [deleteattachment, setdeleteattachment] = useState(false);
  const [deletedattachment, setdeletedattachment] = useState(false);
  const [deleteid, setdeleteid] = useState();
  const [deletedid, setdeletedid] = useState();
  const [deletePath, setdeletePath] = useState();
  const [deletedPath, setdeletedPath] = useState();
  const [editattachment, seteditattachment] = useState(false);

  const openEditModal = (e, item) => {
    //console.log(item);
    setoldattachmentName(item.oldFileName);
    setattachmentName(item.attachmentName);
    setattachmentId(item.id);
    seteditattachment(true);
  };

  const [filesizemore, setfilesizemore] = useState(false);
  const [filesizeMessage, setfilesizeMessage] = useState("");
  const [attachmentdisplay, setattachmentdisplay] = useState(false);
  const chkfilesize = (e) => {
    if (e.target.files.length) {
      let fileSize = 0;
      for (let i = 0; i < e.target.files.length; i++) {
        fileSize += e.target.files[i].size;
      }
      //console.log(fileSize);
      if (fileSize / 1048576 > 5) {
        setfilesizemore(true);
        setfilesizeMessage("Total file size exceeds 5 MB. Please upload smaller files.");
      } else {
        setfilesizemore(false);
        setfilesizeMessage("");
      }
    }
  };
  const onSubmitAttachment = (data) => {
    //console.log("Current Page No--->", documentpage);
    if (filesizemore === true) {
      return false;
    }
    //console.log(data);
    let fileToUpload = data.picture;
    let formData = new FormData();

    if (fileToUpload.length) {
      for (let i = 0; i < fileToUpload.length; i++) {
        formData.append("stepfile", fileToUpload[i], fileToUpload[i].name);
      }
      formData.append('documentType', data.documentType);
      postAttachment(formData, fileToUpload);
    } else {
      setattachmentdisplay(true);
    }
  };

  const postAttachment = (formData, fileToUpload) => {
    console.log('Current Page No--->', formData.get('documentType'));
    //console.log("Current Page No--->", documentpage);
    const isoDateString = new Date().toISOString();
    const datenow = isoDateString.split(".")[0];

    let logs3 = "Added attachment - By " + isw_user_name;
    setbackdropopen(true);

    if (fileToUpload.length) {
      // if (radioattachmentvalue != undefined) {
      axios
        .post(`${base_url.api3}/uploadAttachment`, formData, {
          headers: {
            Authorization: jwtToken,
            log: logs3,
            documentType: formData.get('documentType'),
            createdOn: datenow,
            clientId: selected_client_id,
            createdBy: isw_user_name + " (" + isw_user_email + ")",
          },
        })
        .then((res) => {
          //console.log(res.data.data);
          //console.log("Current Page No--->", documentpage);
          getClientDoc(documentpage);
          setIsOpen2(false);
          setbackdropopen(false);
          toast.success(res.data.message);
        })
        .catch((error) => {
          setbackdropopen(false);
          //console.log(error);
          // toast.error(JSON.stringify(error.response.data.message));
          handle403error(error);
        });
      // } else {
      //   //console.log("select type");
      //   setdisplayattachmentradio(true);
      // }
    }
  };

  const deletestudentappform = () => {
    let delete_path = deletePath.split("amazonaws.com/")[1];
    axios
      .delete(`${base_url.api3}/deleteAttachmentByAttachmentId`, {
        headers: {
          Authorization: jwtToken,
          attachmentId: deleteid,
          fileName: `${getClientId}/${delete_path}`,
        },
      })
      .then((res) => {
        setdeleteattachment(false);
        //console.log("Current Page No--->", documentpage);
        getClientDoc(documentpage);
      })
      .catch((error) => {
        handle403error(error);
      });
  };

  const deleteappform = () => {
    let deleted_path = deletedPath.split("amazonaws.com/")[1];
    axios
      .delete(`${base_url.api2}/deleteAttachmentByAttachmentId`, {
        headers: {
          Authorization: jwtToken,
          attachmentId: deletedid,
          fileName: `${get_client_id}/${deleted_path}`,
        },
      })
      .then((res) => {
        setdeletedattachment(false);
        //console.log("Current Page No--->", seconddocumentpage);
        getAllClientDoc(documentallpage);
      })
      .catch((error) => {
        handle403error(error);
      });
  };

  const downloadAll = (path, clientId) => {
    setbackdropopen(true);
    let file_name;
    if (path.split("amazonaws.com/")[1]) {
      file_name = path.split("amazonaws.com/")[1];
    } else {
      file_name = path.split("//")[1];
    }

    const header = {
      method: "GET",
      headers: {
        Authorization: jwtToken,
        clientId: clientId,
        fileName: `${clientId}/${file_name}`,
      },
    };

    var url = `${base_url.api3}/downloadAttachmentByClientIdAndFileName`;
    fetch(url, header)
      .then((response) => readAllChunks(response))
      .then(async function (response) {
        const downloadFileType = localStorage.getItem(
          "client_attachment_file_content_type"
        );
        var blob = new Blob(response, { type: downloadFileType });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = file_name;
        link.click();
        setbackdropopen(false);
      })
      .catch((error) => {
        setbackdropopen(false);
        //console.log(error);
        handle403error(error);
      });
  };

  const readAllChunks = (readableStream) => {
    localStorage.setItem(
      "client_attachment_file_content_type",
      readableStream.headers.get("content-type")
    );
    const reader = readableStream.body.getReader();
    const chunks = [];

    function pump() {
      return reader
        .read()
        .then(({ value, done }) => {
          if (done) {
            return chunks;
          }
          chunks.push(value);
          return pump();
        })
        .catch((error) => {
          handle403error(error);
        });
    }
    return pump();
  };

  const [finalFile, setFinalFile] = useState();
  const [finalFileType, setFinalFileType] = useState();
  const openViewModal = (path, clientId) => {
    setbackdropopen(true);

    let file_name = path.split("amazonaws.com/")[1];
    //console.log(path);
    //console.log(file_name);
    let file_type = path.split(".");

    const header = {
      method: "GET",
      headers: {
        Authorization: jwtToken,
        clientId: clientId,
        fileName: `${clientId}/${file_name}`,
      },
    };

    var url = `${base_url.api2}/downloadEnquiryAttachmentByClientIdAndFileName`;
    fetch(url, header)
      .then((response) => readAllChunks(response))
      .then(async function (response) {
        const downloadFileType = localStorage.getItem(
          "enquiry_attachment_file_content_type"
        );
        var blob = new Blob(response, { type: downloadFileType });
        //console.log(blob);
        var link = document.createElement("a");
        //console.log(link);
        link.href = window.URL.createObjectURL(blob);
        //console.log(window.URL.createObjectURL(blob));
        setFinalFile(URL.createObjectURL(blob));
        //console.log(URL.createObjectURL(blob));
        setFinalFileType(file_type[file_type.length - 1]);
        //console.log(file_type[file_type.length - 1]);
        link.download = file_name;
        //console.log(link.download);
        setbackdropopen(false);
        setViewAttachment(true);
      })
      .catch((error) => {
        toast.error("Oops! File not Found");
        setFinalFile("");
        setFinalFileType("");
        setbackdropopen(false);
        handle403error(error);
      });
  };

  useEffect(() => {
    let width = document.querySelector("table")?.offsetWidth;
    if (document.querySelector(".scroller")) {
      document.querySelector(".scroller").style.width = `${width}px`;
    }
  }, [clientAllDocuments]);

  useEffect(() => {
    let width = document.querySelector("table")?.offsetWidth;
    if (document.querySelector(".scroller")) {
      document.querySelector(".scroller").style.width = `${width}px`;
    }
  }, [clientDocuments]);

  let scrolling = false;
  const scrolldiv = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow .table-responsive");
    table.scrollTo(scroller.scrollLeft, 0);
  };

  const scrolltable = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow  .table-responsive");
    scroller.scrollTo(table.scrollLeft, 0);
  };

  const [workloaddescvalue2, setworkloaddescvalue2] = useState(null);
  const [workloaddescvalue, setworkloaddescvalue] = useState(null);

  // Proforma templates
  const [template, settemplate] = useState(null);
  const [firsttemplate, setfirsttemplate] = useState(null);
  const [loadermodal, setloadermodal] = useState(true);
  const [proformadetails, setproformadetails] = useState();
  const [getremarks, setremarks] = useState();

  const getproforma = () => {
    axios
      .get(`${base_url.api2}/getAllProforma`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        //console.log("Proforma res", res);
        const data = res.data.data;
        const filteredData = data.filter((item) => item.status === 1);
        setproformadetails(filteredData);
        setTimeout(() => {
          setloadermodal(true);
        }, 2000);
      })
      .catch((err) => {
        setloadermodal(true);
        handle403error(err);
      });
  };

  const getInitials = (name) => {
    const names = name.split(" ");
    let initials = "";
    if (names.length >= 1) {
      initials += names[0].charAt(0).toUpperCase(); // First name initial
    }
    if (names.length >= 2) {
      initials += names[names.length - 1].charAt(0).toUpperCase(); // Last name initial
    }
    return initials;
  };

  return (
    <div>
      <Backdrop className={classes.backdrop} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <hr></hr>
      <Card className="p-3">
        <div>
          {/* <Row>
            <Col md={6}>{null}</Col>
            <Col md={6} className="d-flex justify-content-end">
              {showaddnotebutton ? (
                <>
                  {permissionData?.includes(26) ? (
                    <button
                      className="outline-button"
                      style={{ height: "50px", marginRight: "5px" }}
                      onClick={() => setopennotemodal(true)}
                    >
                      Add Note
                    </button>
                  ) : null}
                </>
              ) : null}
              <Modal
                isOpen={opennotemodal}
                onRequestClose={() => setopennotemodal(false)}
                style={customStyles3}
                contentLabel="Example Modal"
              >
                <div className="modal-content">
                  <div>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        submitNote(e);
                      }}
                    >
                      <Row>
                        <Col style={{ marginTop: "10px" }}>
                          <h4>Add Note</h4>
                        </Col>
                        <Col md={6} className="d-flex justify-content-end">
                          <button
                            className="outline-button"
                            onClick={() => setopennotemodal(false)}
                          >
                            Cancel
                          </button>
                          <button
                            className="sent-button"
                            type="submit"
                          >
                            Save
                          </button>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12} className="form-group">
                          <label>Title</label>
                          <input
                            className="form-control"
                            // value={noteworkflowtype}
                            style={{ backgroundColor: "f5f5f9" }}
                            onChange={(e) =>
                              setnoteworkflowtype(e.target.value)
                            }
                            required
                          />
                        </Col>
                      </Row>

                      <label>Note</label>
                      <textarea
                            value={descriptionState2}
                            onChange={(e) =>
                              setdescriptionState2(e.target.value)
                            }
                            rows={12}
                            className="form-control"
                            required
                          /> 
                      <SunEditor
                        rows={12}
                        ref={editorRef}
                        height="200"
                        style={{ backgroundColor: "f5f5f9" }}
                        // setContents={descriptionState2}
                        onChange={(e) => setdescriptionState2(e)}
                        onImageUploadBefore={handleImageUploadBefore}
                        required
                      />
                    </form>
                  </div>
                </div>
              </Modal>
              <br />
              <button className="sent-button" style={{ height: "50px", marginRight: "5px" }} onClick={goBack}>
                Client List
              </button>
              {showadddocbutton ? (
                <>
                  {permissionData?.includes(29) ? (
                    <button
                      className="sent-button"
                      onClick={() => setIsOpen2(true)}
                    >
                      Upload Document
                    </button>
                  ) : null}
                </>
              ) : null}
              <Modal
                isOpen={opennotemodal}
                onRequestClose={() => setopennotemodal(false)}
                style={customStyles3}
                contentLabel="Example Modal"
              >
                <div>
                  <div>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        submitNote(e);
                      }}
                    >
                      <Row>
                        <Col style={{ marginTop: "10px" }}>
                          <h4>Add Note</h4>
                        </Col>
                        <Col md={6} className="d-flex justify-content-end">
                          <button
                            className="btn btn-secondary cancel_margin"
                            onClick={() => setopennotemodal(false)}
                          >
                            Cancel
                          </button>
                          <button
                            className="btn listing_addbutton ml-1"
                            type="submit"
                          >
                            Save
                          </button>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12} className="form-group">
                          <label>Title</label>
                          <input
                            className="form-control"
                            // value={noteworkflowtype}
                            style={{ backgroundColor: "#f5f5f9" }}
                            onChange={(e) =>
                              setnoteworkflowtype(e.target.value)
                            }
                            required
                          />
                        </Col>
                      </Row>

                      <label>Note</label>
                      {/* <textarea
                            value={descriptionState2}
                            onChange={(e) =>
                              setdescriptionState2(e.target.value)
                            }
                            rows={12}
                            className="form-control"
                            required
                          /> 
                      <SunEditor
                        rows={12}
                        ref={editorRef}
                        style={{ backgroundColor: "#f5f5f9" }}
                        height="200"
                        // setContents={descriptionState2}
                        onChange={(e) => setdescriptionState2(e)}
                        onImageUploadBefore={handleImageUploadBefore}
                        required
                      />
                    </form>
                  </div>
                </div>
              </Modal>
            </Col>
          </Row> */}
        </div>
        <div className="row">
          <div className="col-md-12">
            <ul className="nav nav-tabs" style={{ border: "none" }}>
              <li
                className="nav-item "
                onClick={() => {
                  setshowaddnotebutton(true);
                  setshowaddDocbutton(false);
                }}
              >
                <a
                  className="nav-link active"
                  data-toggle="tab"
                  href="#clientnotes"
                >
                  Note Log
                </a>
              </li>
              <li
                className="nav-item "
                onClick={() => {
                  setshowaddnotebutton(false);
                  setshowaddDocbutton(false);
                }}
              >
                <a className="nav-link" data-toggle="tab" href="#workloadnotes">
                  App Notes
                </a>
              </li>
              <li
                className="nav-item "
                onClick={() => {
                  setshowaddnotebutton(false);
                  setshowaddDocbutton(true);
                }}
              >
                <a
                  className="nav-link"
                  data-toggle="tab"
                  href="#clientDocuments"
                >
                  Documents
                </a>
              </li>
              <div className="d-flex justify-content-end" style={{ flex: "1" }}>
                {showaddnotebutton ? (
                  <>
                    {check1pointpermission(
                      307,
                      permissionData,
                      rolepermission
                    ) ? (
                      <button
                        className="outline-button"
                        style={{ height: "43px", marginRight: "5px" }}
                        onClick={() => setopennotemodal(true)}
                      >
                        ADD NOTE
                      </button>
                    ) : null}
                  </>
                ) : null}
                <Modal
                  isOpen={opennotemodal}
                  onRequestClose={() => setopennotemodal(false)}
                  style={customStyles3}
                  contentLabel="Example Modal"
                  shouldCloseOnOverlayClick={false}
                >
                  <div className="modal-content">
                    <div>
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          submitNote(e);
                        }}
                      >
                        <Row>
                          <Col style={{ marginTop: "10px" }}>
                            <h4>Add Note</h4>
                          </Col>
                          <Col md={6} className="d-flex justify-content-end">
                            <button
                              className="outline-button"
                              onClick={() => setopennotemodal(false)}
                            >
                              Cancel
                            </button>
                            <button className="sent-button" type="submit">
                              Save
                            </button>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12} className="form-group">
                            <label>Title</label>
                            <input
                              className="form-control"
                              // value={noteworkflowtype}
                              style={{ backgroundColor: "f5f5f9" }}
                              onChange={(e) =>
                                setnoteworkflowtype(e.target.value)
                              }
                              required
                            />
                          </Col>
                        </Row>

                        <label>Note</label>
                        {/* <textarea
                            value={descriptionState2}
                            onChange={(e) =>
                              setdescriptionState2(e.target.value)
                            }
                            rows={12}
                            className="form-control"
                            required
                          /> */}
                        <SunEditor
                          rows={12}
                          ref={editorRef}
                          height="200"
                          style={{ backgroundColor: "f5f5f9" }}
                          // setContents={descriptionState2}
                          onChange={(e) => setdescriptionState2(e)}
                          onImageUploadBefore={handleImageUploadBefore}
                          required
                        />
                      </form>
                    </div>
                  </div>
                </Modal>
                <br />
                {showadddocbutton ? (
                  <>
                    {check1pointpermission(
                      310,
                      permissionData,
                      rolepermission
                    ) ? (
                      <>
                        <button
                          className="outline-button"
                          onClick={() => setIsOpen2(true)}
                        >
                          ADD DOCUMENT
                        </button>
                        <span style={{ width: "12px" }}></span>
                      </>
                    ) : null}
                  </>
                ) : null}
                <button
                  className="sent-button"
                  style={{ height: "43px", marginRight: "5px" }}
                  onClick={goBack}
                >
                  CLIENT LIST
                </button>
                <Modal
                  isOpen={opennotemodal}
                  onRequestClose={() => setopennotemodal(false)}
                  style={customStyles5}
                  contentLabel="Example Modal"
                  shouldCloseOnOverlayClick={false}
                >
                  <div>
                    <div>
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          submitNote(e);
                        }}
                      >
                        <Row style={{ marginTop: "10px" }}>
                          <Col style={{ marginTop: "10px" }}>
                            <h4>Add Note</h4>
                          </Col>
                          <Col md={6} className="d-flex justify-content-end">
                            <button
                              className="btn btn-secondary cancel_margin"
                              onClick={() => setopennotemodal(false)}
                            >
                              Cancel
                            </button>
                            <button
                              className="btn listing_addbutton ml-1"
                              type="submit"
                            >
                              Save
                            </button>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6} style={{ marginTop: "10px" }}>
                            <label>Title</label>
                            <input
                              className="form-control"
                              // value={noteworkflowtype}
                              style={{ backgroundColor: "#f5f5f9" }}
                              onChange={(e) =>
                                setnoteworkflowtype(e.target.value)
                              }
                              required
                            />
                          </Col>
                          <Col md={6} style={{ marginTop: "10px" }}>
                            <label>Templates</label>
                            <select
                              onChange={(e) => setfirsttemplate(e.target.value)}
                              style={{
                                width: "100%",
                                height: "40px",
                                backgroundColor: "f5f5f9",
                              }}
                            >
                              <option value="">- Select Template -</option>
                              {proformadetails?.map((item, index) => (
                                <option key={index} value={item.template}>
                                  {item.name}
                                </option>
                              ))}
                            </select>
                          </Col>
                        </Row>

                        <label style={{ marginTop: "30px" }}>Note</label>
                        {/* <textarea
                            value={descriptionState2}
                            onChange={(e) =>
                              setdescriptionState2(e.target.value)
                            }
                            rows={12}
                            className="form-control"
                            required
                          /> */}

                        {/* <SunEditor
                          rows={12}
                          ref={editorRef}
                          style={{ backgroundColor: "#f5f5f9" }}
                          height="200"
                          // setContents={descriptionState2}
                          onChange={(e) => setdescriptionState2(e)}
                          onImageUploadBefore={handleImageUploadBefore}
                          required
                        /> */}

                        {firsttemplate === null ? (
                          <CKEditor
                            editor={ClassicEditor}
                            config={editorConfiguration}
                            data={workloaddescvalue2}
                            onReady={(editor) => {
                              //console.log("Editor is ready to use!", editor);
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setworkloaddescvalue2(data);
                            }}
                          />
                        ) : (
                          <CKEditor
                            editor={ClassicEditor}
                            config={editorConfiguration}
                            data={firsttemplate}
                            onReady={(editor) => {
                              //console.log("Editor is ready to use!", editor);
                              // setloadermodal(true);
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setworkloaddescvalue(data);
                            }}
                          />
                        )}
                        <br />
                      </form>
                    </div>
                  </div>
                </Modal>
              </div>
            </ul>
            <div
              className="tab-content"
            // style={{ border: "1px solid  #dee2e6" }}
            >
              <div
                id="clientnotes"
                className="tab-pane active"
                style={{ maxWidth: "1000px", marginRight: "50px" }}
              >
                {clientnotes ? (
                  <VerticalTimeline
                    lineColor={"#f5f5f5"}
                    layout={"1-column-left"}
                    style={{ textAlign: "left" }}
                  >
                    {clientnotes.map((item, index) => (
                      <VerticalTimelineElement
                        key={item.id}
                        date={`Last Modified: ${item.lastUpdatedOn}`}
                        iconStyle={{
                          background: "#17B978",
                          color: "#fff",
                        }}
                        contentStyle={{
                          background: "#f5f5f5",
                          color: "#000",
                          boxShadow: "0 0 15px rgba(0, 0, 0, 0.2)",
                          textAlign: "left",
                          marginBottom: "1px",
                        }}
                        contentArrowStyle={{ borderRight: "7px solid  #fff" }}
                        className="vertical-timeline-element-custom"
                        icon={
                          <div
                            style={{
                              lineHeight: "40px",
                              fontSize: "17px",
                            }}
                          >
                            {getInitials(item.addedBy)}
                          </div>
                        }
                      >
                        <h3

                          style={{ fontSize: "20px" }}
                        >
                          {item.workflowType}
                        </h3>
                        <div style={{ overflowY: "auto", maxHeight: "700px" }}>
                          <div
                            dangerouslySetInnerHTML={{ __html: item.note }}
                          />
                        </div>

                      </VerticalTimelineElement>
                    ))}
                  </VerticalTimeline>
                ) : null}
              </div>

              <div id="workloadnotes" className="tab-pane">
                <div style={{ margin: "20px 0" }}>
                  <Table responsive striped>
                    <thead>
                      <tr>
                        <th>SN</th>
                        <th>Title</th>
                        <th>Course</th>
                        <th>Workflow Name</th>
                        <th>Created Date</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {workloadnotes?.map((item, index) => (
                        <tr key={index + 1}>
                          <td>{index + 1}</td>
                          <td>{item.title}</td>
                          <td>{item.course}</td>
                          <td>{item.workflowName}</td>
                          <td>
                            {moment(item.createdDate).format("MMMM DD, YYYY")}
                          </td>
                          <td>
                            {check1pointpermission(
                              309,
                              permissionData,
                              rolepermission
                            ) ? (
                              <div style={{ position: "relative" }}>
                                <div
                                  className="workflowdashboard_fa-ellipsis"
                                  data-toggle="dropdown"
                                >
                                  <i
                                    className="fa fa-ellipsis-v "
                                    aria-hidden="true"
                                  ></i>
                                </div>
                                <div className="dropdown-menu workflowdashboarddropdown">
                                  <p onClick={() => seteditworkloadnote(item)}>
                                    Edit
                                  </p>
                                  {/* <p onClick={() => setviewworkloadnote(p)}>
                                    View
                                  </p> */}
                                </div>
                              </div>
                            ) : null}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <div></div>
                </div>
                {/* <Modal
                    isOpen={workloadviewmodal}
                    onRequestClose={() => setworkloadviewmodal(false)}
                    style={customStyles}
                    contentLabel="Example Modal"
                  >
                    <h3>{workloadtemplatedesign?.title}</h3>
                    <div
                      id="modal"
                      dangerouslySetInnerHTML={{
                        __html: workloadtemplatedesign?.notes,
                      }}
                    />
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => setworkloadviewmodal(false)}
                    >
                      Close
                    </Button>
                  </Modal> */}
                <Modal
                  isOpen={viewworkloadmodal}
                  onRequestClose={() => setviewworkloadmodal(false)}
                  style={customStyles3}
                  contentLabel="Example Modal"
                  shouldCloseOnOverlayClick={false}
                >
                  <div>
                    <div>
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          workloadnoteeditsubmit(e);
                        }}
                      >
                        <Row>
                          <Col md={6}>
                            <h2>Note</h2>
                          </Col>
                          <Col md={6}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                padding: "5px",
                              }}
                            >
                              <button
                                className="cancel_margin btn btn-secondary"
                                onClick={() => setviewworkloadmodal(false)}
                              >
                                Cancel
                              </button>
                              <button
                                type="submit"
                                className="btn listing_addbutton ml-1"
                              >
                                Save
                              </button>
                            </div>
                          </Col>
                        </Row>
                        <hr className="dashboardh" />
                        <Row>
                          <Col md={9}>
                            <label>Title</label>
                            <br />
                            <input
                              value={workloadtitlevalue}
                              onChange={(e) =>
                                setworkloadtitlevalue(e.target.value)
                              }
                              className="form-control underline-input"
                              placeholder="Title Here"
                              style={{ width: "100%" }}
                            />
                          </Col>
                          <Col md={3} className="form-group notesformmgroup">
                            <label>Templates</label>
                            <select
                              onChange={(e) => settemplate(e.target.value)}
                              style={{ width: "100%", height: "40px" }}
                            >
                              <option value="">- Select Template -</option>
                              {proformadetails?.map((item, index) => (
                                <option key={index} value={item.template}>
                                  {item.name}
                                </option>
                              ))}
                            </select>
                          </Col>
                        </Row>
                        <br />

                        <label style={{ marginTop: "20px" }}>Note</label>
                        {template === null ? (
                          <CKEditor
                            editor={ClassicEditor}
                            config={editorConfiguration}
                            data={workloaddescriptionvalue2}
                            onReady={(editor) => {
                              //console.log("Editor is ready to use!", editor);
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setworkloaddescriptionvalue2(data);
                            }}
                          />
                        ) : (
                          <CKEditor
                            editor={ClassicEditor}
                            config={editorConfiguration}
                            data={template}
                            onReady={(editor) => {
                              //console.log("Editor is ready to use!", editor);
                              // setloadermodal(true);
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setworkloaddescriptionvalue(data);
                            }}
                          />
                        )}
                      </form>
                    </div>
                  </div>
                </Modal>
                <Row>
                  <Col md={6} style={{ textAlign: "left" }}>
                    {workloadnotes?.length ? (
                      <p style={{ marginTop: "20px" }}>
                        {workloadcount * 3 + 1} to{" "}
                        {workloadcount * 3 + workloadnumberelements} of{" "}
                        {totalworkload} records
                      </p>
                    ) : (
                      // <p className="formfooterrecordstyle">No Record Found</p>
                      <div class="wrapper">
                        <div class="img-box">
                          <img src={Illustrationwork} alt="" />
                        </div>
                        <div class="content">
                          <h3>No Record Found.</h3>
                          <p>There are no data in the App Notes.</p>
                        </div>
                      </div>
                    )}
                  </Col>
                  <Col md={6}>
                    <div className={classes.root} style={{ float: "right" }}>
                      <Pagination
                        count={getworkloadpageno}
                        onChange={handleworkloadpage}
                        shape="rounded"
                      />
                    </div>
                  </Col>
                </Row>
              </div>
              <div id="clientDocuments" className="tab-pane">
                <div style={{ margin: "20px 0" }}>
                  <div>
                    {/* {clientDocuments?.map((p, index) => ( */}
                    <div
                      className="workflowdashboard_clientnote"
                      // key={index + 1}
                      style={{
                        position: "relative",
                        margin: "20px 0",
                        textAlign: "left",
                      }}
                    >
                      <Table
                        className="workload card-table table-borderless"
                        responsive
                        striped
                        onScroll={scrolltable}
                        class="fixed-header"
                      >
                        <thead>
                          <tr>
                            <th style={{ width: "20px" }}>SN</th>
                            <th style={{ width: "130px" }}>TITLE</th>
                            <th style={{ width: "50px" }}>CREATED</th>
                            <th style={{ width: '50px' }}>TYPE</th>
                            <th style={{ width: "200px", textAlign: "left" }}>
                              ACTION
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {clientDocuments?.map((item, index) => (
                            <tr key={index + 1}>
                              <td>{index + 1}</td>
                              <td>{item.attachmentName}</td>
                              <td>
                                {item.createdOn
                                  ? moment(item.createdOn.split(".")[0]).format(
                                    "DD-MMM-YY"
                                  )
                                  : null}
                                {/* <br />
                                {item.createdOn
                                  ? moment(item.createdOn.split(".")[0]).format(
                                      "hh.mm a"
                                    )
                                  : null}
                                <br /> */}
                              </td>
                              <td>{item.documentType}</td>
                              {/* <td>
                                {permissionData?.includes(30) ||
                                  permissionData?.includes(31) ? (
                                  <div
                                    style={{
                                      position: "relative",
                                      textAlign: "center",
                                    }}
                                  >
                                    <div
                                      className="workflowdashboard_fa-ellipsis"
                                      data-toggle="dropdown"
                                    >
                                      <i
                                        className="fa fa-ellipsis-v "
                                        aria-hidden="true"
                                      ></i>
                                    </div>
                                    <div className="dropdown-menu workflowdashboarddropdown">
                                      {permissionData?.includes(31) ? (
                                        <p
                                          onClick={() => {
                                            setdeleteattachment(true);
                                            setdeleteid(item.id);
                                            setdeletePath(item.path);
                                          }}
                                        >
                                          Delete
                                        </p>
                                      ) : null}
                                      {permissionData?.includes(30) ? (
                                        <p>
                                          <a
                                            onClick={() => {
                                              downloadAll(
                                                item.path,
                                                item.clientId
                                              );
                                            }}
                                          >
                                            Download
                                          </a>
                                        </p>
                                      ) : null}
                                    </div>
                                  </div>
                                ) : null}
                              </td> */}
                              <td>
                                {check1pointpermission(
                                  312,
                                  permissionData,
                                  rolepermission
                                ) ||
                                  check1pointpermission(
                                    311,
                                    permissionData,
                                    rolepermission
                                  ) ? (
                                  <div
                                    style={{
                                      position: "relative",
                                      textAlign: "left",
                                    }}
                                  >
                                    {check1pointpermission(
                                      312,
                                      permissionData,
                                      rolepermission
                                    ) ? (
                                      <a
                                        className="sent-button"
                                        style={{ marginRight: "10px" }}
                                        onClick={() => {
                                          setdeleteattachment(true);
                                          setdeleteid(item.id);
                                          setdeletePath(item.path);
                                          setClientId(item.clientId);
                                        }}
                                      >
                                        Delete
                                      </a>
                                    ) : null}
                                    {check1pointpermission(
                                      311,
                                      permissionData,
                                      rolepermission
                                    ) ? (
                                      <a
                                        className="outline-button"
                                        style={{ marginRight: "10px" }}
                                        onClick={() => {
                                          downloadAll(item.path, item.clientId);
                                        }}
                                      >
                                        Download
                                      </a>
                                    ) : null}
                                    <WorkflowFiles
                                      clientId={item.clientId}
                                      fileName={
                                        item.path.split("amazonaws.com/")[1]
                                      }
                                    />
                                  </div>
                                ) : null}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                    {/* ))} */}
                  </div>
                </div>
                <Modal
                  isOpen={viewAttachment}
                  onRequestClose={() => setViewAttachment(false)}
                  style={customFileStyles}
                  // style={{margin:'20px'}}
                  contentLabel="Example Modal"
                >
                  <div style={{ display: "flex", justifyContent: "end" }}>
                    <span
                      onClick={() => setViewAttachment(false)}
                      style={{
                        cursor: "pointer",
                        fontSize: "20px",
                        borderRadius: "100%",
                        padding: "0 10px",
                        marginBottom: "5px",
                        border: "1px solid",
                      }}
                    >
                      X
                    </span>
                  </div>
                  <div className="workflowdashboard_documentmodal">
                    <FileViewer
                      fileType={finalFileType ? finalFileType : null}
                      filePath={finalFile ? finalFile : null}
                      errorComponent={CustomErrorComponent}
                      onError={() => console.log("Error happened")}
                    />
                    {console.log(finalFile)}
                  </div>
                </Modal>
                <Modal
                  isOpen={deleteattachment}
                  onRequestClose={() => setdeleteattachment(false)}
                  style={customStyles}
                  contentLabel="Example Modal"
                >
                  <h4 style={{ marginTop: "10px" }}>
                    Are you sure you want to delete current record ?{" "}
                  </h4>
                  <div
                    style={{
                      display: "flex",
                      marginTop: "20px",
                      justifyContent: "flex-end",
                      gap: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    <button
                      className="btn listing_addbutton ml-1"
                      onClick={deletestudentappform}
                    >
                      Yes
                    </button>
                    <button
                      className="btn btn-secondary cancel_margin"
                      onClick={() => setdeleteattachment(false)}
                    >
                      No
                    </button>
                  </div>
                </Modal>
                <Modal
                  isOpen={deletedattachment}
                  onRequestClose={() => setdeletedattachment(false)}
                  style={customStyles}
                  contentLabel="Example Modal"
                >
                  <h4 style={{ marginTop: "10px" }}>
                    Are you sure you want to delete current record ?{" "}
                  </h4>
                  <div
                    style={{
                      display: "flex",
                      marginTop: "20px",
                      justifyContent: "flex-end",
                      gap: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    <button
                      className="btn listing_addbutton ml-1"
                      onClick={deleteappform}
                    >
                      Yes
                    </button>
                    <button
                      className="btn btn-secondary cancel_margin"
                      onClick={() => setdeletedattachment(false)}
                    >
                      No
                    </button>
                  </div>
                </Modal>
                <Modal
                  isOpen={editattachment}
                  onRequestClose={() => seteditattachment(false)}
                  style={customStyles}
                  contentLabel="Example Modal"
                >
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      changeAttachmentName(e);
                    }}
                  >
                    <div>
                      <h3 style={{ color: "black" }}>Change Title</h3>
                      <div className="form-group creatadmin">
                        <Row>
                          <Col>
                            <label>Existing File Title:</label>
                            <input
                              type="text"
                              className="form-control underline-input"
                              value={fetchedattachmentName}
                              readOnly
                              required
                            />
                          </Col>
                        </Row>
                      </div>
                      <div className="form-group creatadmin">
                        <Row>
                          <Col>
                            <label>New Title:</label>
                            <div style={{ position: "relative" }}>
                              <input
                                type="text"
                                className="form-control underline-input"
                                required
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <div className="form-group creatadmin">
                        <Row className="justify-content-end mt-2">
                          <button
                            className="btn listing_addbutton"
                            type="submit"
                          >
                            Update
                          </button>
                          <Button
                            color="secondary"
                            onClick={() => {
                              seteditattachment(false);
                            }}
                          >
                            Cancel
                          </Button>
                        </Row>
                      </div>
                    </div>
                  </form>
                </Modal>

                <Row>
                  <Col md={6} style={{ textAlign: "left" }}>
                    {clientDocuments?.length ? (
                      <p style={{ marginTop: "20px" }}>
                        {countDocnumber * 3 + 1} to{" "}
                        {countDocnumber * 3 + numberofDocElements} of{" "}
                        {totalDocUser} records
                      </p>
                    ) : (
                      <p className="formfooterrecordstyle">No Record Found</p>
                    )}
                  </Col>
                  <Col md={6}>
                    <div className={classes.root} style={{ float: "right" }}>
                      <Pagination
                        count={getDocPage}
                        onChange={handleDocument}
                        shape="rounded"
                      />
                    </div>
                  </Col>
                </Row>

                <Modal
                  isOpen={modalIsOpen2}
                  onRequestClose={closeModal}
                  style={customStylesAttachment}
                  contentLabel="Example Modal"
                >
                  <div style={{ marginLeft: "30px", marginRight: "35px" }}>
                    <img className="closei" src={close} onClick={closeModal} />
                    <br />
                    <h4 className="s_upload">Upload Attachment </h4>

                    <div>
                      {/* <form
                        className="form-group dummymargin"
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <div>
                          <label>File:</label>
                          <br />
                          <input
                            ref={register('picture')}
                            type="file"
                            name="picture"
                            // accept=".jpg, .jpeg, .bmp, .gif, .png, .pdf, .docx, ."
                            multiple
                          />
                          {attachmentdisplay && (
                            <p className="errormessage">
                              Attachment is Required
                            </p>
                          )}
                          {filesizemore && (
                            <p className="errormessage">
                              File Size cannot be more than 200 mb
                            </p>
                          )}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            padding: "5px",
                          }}
                        >
                          <button
                            className="btn btn-secondary"
                            onClick={closeModal}
                          >
                            Cancel
                          </button>
                          <button
                            className="btn listing_addbutton ml-1"
                            type="submit"
                          >
                            Submit
                          </button>
                        </div>
                      </form> */}
                      <form
                        class="mt-3"
                        onSubmit={handleSubmit(onSubmitAttachment)}
                      >
                        <label htmlFor="fileUpload" class="form-label">
                          File
                        </label>
                        <input
                          class="form-control"
                          type="file"
                          id="fileUpload"
                          style={{ padding: "10px" }}
                          ref={register}
                          onChange={(e) => chkfilesize(e)}
                          name="picture"
                          accept=".jpg, .jpeg, .bmp, .gif, .png, .pdf,.PDF ."
                          multiple
                        />
                        {filesizemore && (
                          <div style={{ color: 'red', marginTop: '10px' }}>
                            {filesizeMessage}
                          </div>
                        )}
                        {/* <p class="mt-2 mx-3 " className="s_choosefile">
                          <span>Note:</span> All files should be at least 720p
                          and less than 4.0 MB.
                        </p> */}
                        <Col md={4}>
                          <label>
                            Type of Document
                            <span style={{ color: 'red' }}>*</span>:
                          </label>
                          <select
                            className={classes.selectdrop}
                            name="documentType"
                            style={{ backgroundColor: '#f5f5f9' }}
                            ref={register({ required: true })}
                          >
                            <option value="">
                              - Select Type of Document -
                            </option>
                            <option value="application_form">
                              Application Form
                            </option>
                            <option value="Passport">Passport</option>
                            <option value="PTE">PTE</option>
                            <option value="Transcript">Transcript</option>
                            <option value="paymentreceipt">Payment Receipt</option>
                            <option value="offerletter">Offer Letter</option>
                            <option value="coe">Coe</option>
                            <option value="miscellaneous_doc">Others</option>
                          </select>

                          {/* {errors.asssessmentType && (
														<p className="errormessage">
															Type of Assessment is Required
														</p>
													)} */}
                        </Col>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            padding: "5px",
                          }}
                        >
                          <button
                            className="btn btn-secondary"
                            onClick={closeModal}
                          >
                            Cancel
                          </button>
                          <button
                            className={`btn listing_addbutton ml-1 ${filesizemore ? 'disabled-class' : ''}`}
                            type="submit"
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                      <br />
                    </div>
                  </div>
                  {/* <div class="modal documents-modal fade" id="documents-modal" tabindex="-1" >
                  <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                      <div class="d-flex justify-content-between">
                        <h3>Upload Attachment</h3>
                        <button type="button" class="btn-close close-icon" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <form class="mt-3">
                        <label for="fileUpload" class="form-label">File</label>
                        <input class="form-control" type="file" id="fileUpload" />
                        <p class="mt-2 mx-3">
                          <span>Note:</span> All files should be at least
                          720p and less than 4.0 GB.
                        </p>
                      </form>
                      <div class="buttons">
                        <a href="#" data-bs-dismiss="modal">CANCEL</a>
                        <a href="#">SUBMIT</a>
                      </div>
                    </div>
                  </div>
                </div> */}
                </Modal>
                {/* <Attachments
                  attachments={props.attachments}
                  attachmentPagination={props.attachmentPagination}
                  getAttachments={props.getAttachments}
                /> */}
                <hr></hr>
                <div style={{ margin: "20px 0" }}>
                  <div className="above-scroller" onScroll={scrolldiv}>
                    <div className="scroller"></div>
                  </div>
                  <div>
                    {/* {clientDocuments?.map((p, index) => ( */}
                    <div
                      className="workflowdashboard_clientnote"
                      // key={index + 1}
                      style={{
                        position: "relative",
                        margin: "20px 0",
                        textAlign: "left",
                      }}
                    >
                      <Table
                        className="workload card-table table-borderless"
                        responsive
                        striped
                        onScroll={scrolltable}
                        class="fixed-header"
                      >
                        <thead>
                          <tr>
                            <th style={{ width: "10px" }}>SN</th>
                            <th style={{ width: "100px" }}>COURSE</th>
                            <th style={{ width: "50px" }}>STEP</th>
                            <th style={{ width: "10px" }}>TYPE</th>
                            <th style={{ width: "50px" }}>ADD ON</th>
                            <th style={{ width: "300px", textAlign: "left" }}>
                              ACTION
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {clientAllDocuments?.map((item, index) => (
                            <tr key={index + 1}>
                              <td>{index + 1}</td>
                              <td>{item.course_name}</td>
                              <td>{item.steps}</td>
                              <td>{item.attachment_name}</td>
                              <td>
                                {item.created_on
                                  ? moment(
                                    item.created_on.split(".")[0]
                                  ).format("DD-MMM-YY")
                                  : null}
                                {/* <br />
                                {item.createdOn
                                  ? moment(
                                    item.created_on.split(".")[0]
                                  ).format("hh.mm a")
                                  : null}
                                <br /> */}
                              </td>
                              <td>
                                {check1pointpermission(
                                  324,
                                  permissionData,
                                  rolepermission
                                ) ||
                                  check1pointpermission(
                                    323,
                                    permissionData,
                                    rolepermission
                                  ) ? (
                                  <div
                                    style={{
                                      position: "relative",
                                      textAlign: "left",
                                    }}
                                  >
                                    {check1pointpermission(
                                      324,
                                      permissionData,
                                      rolepermission
                                    ) ? (
                                      <a
                                        className="sent-button"
                                        style={{ marginRight: "10px" }}
                                        onClick={() => {
                                          setdeletedattachment(true);
                                          setdeletedid(item.id);
                                          setdeletedPath(item.path);
                                          set_client_id(item.client_id);
                                        }}
                                      >
                                        Delete
                                      </a>
                                    ) : null}
                                    {check1pointpermission(
                                      323,
                                      permissionData,
                                      rolepermission
                                    ) ? (
                                      <a
                                        className="outline-button"
                                        style={{ marginRight: "10px" }}
                                        onClick={() => {
                                          downloadAll(
                                            item.path,
                                            item.client_id
                                          );
                                        }}
                                      >
                                        Download
                                      </a>
                                    ) : null}
                                    <WorkflowFiles
                                      clientId={item.clientId}
                                      fileName={
                                        item.path.split("amazonaws.com/")[1]
                                      }
                                    />
                                  </div>
                                ) : null}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                    {/* ))} */}
                  </div>
                </div>
                <Row>
                  <Col md={6} style={{ marginTop: "20px", textAlign: "left" }}>
                    {clientAllDocuments?.length ? (
                      <p>
                        {countAllDocnumber * 3 + 1} to{" "}
                        {countAllDocnumber * 3 + numberofAllDocElements} of{" "}
                        {totalAllDocUser} records
                      </p>
                    ) : (
                      <p className="formfooterrecordstyle">No Record Found</p>
                    )}
                  </Col>
                  <Col md={6}>
                    <div className={classes.root} style={{ float: "right" }}>
                      <Pagination
                        count={getAllDocPage}
                        onChange={handleAllDocument}
                        shape="rounded"
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
          {/* <div className="col-md-5">
            <div style={{ margin: "20px 0" }}>
              <div>
                <div className="workflowdashboard_chart">
                  <Chart
                    width={"300px"}
                    height={"300px"}
                    chartType="PieChart"
                    loader={<div>Loading Chart</div>}
                    data={admissiondata}
                    options={{
                      title: "Admission",
                      pieHole: 0.4,
                    }}
                    rootProps={{ "data-testid": "1" }}
                  />
                </div>
                <div className="workflowdashboard_chart">
                  <Chart
                    width={"300px"}
                    height={"300px"}
                    chartType="PieChart"
                    loader={<div>Loading Chart</div>}
                    data={migrationdata}
                    options={{
                      title: "Migration",
                      pieHole: 0.4,
                    }}
                    rootProps={{ "data-testid": "1" }}
                  />
                </div>
              </div>
              <div>
                <div className="workflowdashboard_chart">
                  <Chart
                    width={"300px"}
                    height={"300px"}
                    chartType="PieChart"
                    loader={<div>Loading Chart</div>}
                    data={insurancedata}
                    options={{
                      title: "Insurance",
                      pieHole: 0.4,
                    }}
                    rootProps={{ "data-testid": "1" }}
                  />
                </div>
                <div className="workflowdashboard_chart">
                  <Chart
                    width={"300px"}
                    height={"300px"}
                    chartType="PieChart"
                    loader={<div>Loading Chart</div>}
                    data={skillassessmentdata}
                    options={{
                      title: "Skill Assessment",
                      pieHole: 0.4,
                    }}
                    rootProps={{ "data-testid": "1" }}
                  />
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </Card>
    </div>
  );
};

export default WorkflowDashboard;