import React, { useState } from "react";
import Modal from "react-modal";
import { Col, Row, Button } from "reactstrap";
import axios from "axios";
import base_url from "../../../api/bootapi";
import { customStyles, useStyles } from "../../../Utilities/CSSUtilities";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Select from "react-select";
import callsaveauditapi from "../../../services/auditservice";
import { CountryDropdown } from "react-country-region-selector";
import {
  selectUser,
  setdashboardcheck,
  logout,
} from "./../../features/userSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";

const InboxReplyModal = ({
  setbackdropopen,
  toast,
  getInbox,
  handle403error,
  pageno,
  isw_user_name,
  p,
  id,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [editmodal, seteditmodal] = useState(false);
  const [setSubject, setsetSubject] = useState();
  const [setComment, setsetComment] = useState();
  const [selectemail, setselectedmail] = useState({});
  const [filesizemore, setfilesizemore] = useState(false);
  const isw_user_email = localStorage.getItem("isw_user_email");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [attachmentState, setattachmentState] = useState();
  const user = useSelector(selectUser);
  const jwtToken = "Bearer " + user.isw_user_token;
  //console.log(p);

  const classes = useStyles();
  const closeeditModal = () => {
    seteditmodal(false);
  };

  const openeditmodal = (p) => {
    setselectedmail(p);
    seteditmodal(true);
  };

  const submitReply = (data) => {
    setbackdropopen(true);
    let data2 = {
      description: setComment,
      fromEmailId: isw_user_email,
      mailType: "sent",
      subject: setSubject,
      status: Number(1),
      toEmailId: selectemail.toEmailId,
    };
    //console.log(data2);
    let formData = new FormData();
    let fileToUpload = data.picture.item(0);
    if (fileToUpload != null) {
      if (filesizemore === true) {
        setbackdropopen(false);
        return false;
      } else {
        formData.append("emailAttachmentFile", fileToUpload, fileToUpload.name);
        //console.log("Formdata", formData);
        setattachmentState(true);
        axios
          .post(`${base_url.api4}/uploadEmailAttachment`, formData, {
            headers: {
              Authorization: jwtToken,
              fromEmailId: isw_user_email,
              toEmailId: selectemail.toEmailId,
              mailType: "sent",
              subject: setSubject,
              status: Number(1),
              description: setComment,
            },
          })
          .then((response) => {
            setbackdropopen(false);
            toast.success(response.data.message);
            let logs =
              isw_user_name +
              " Uploaded Attachment & Sent Reply to " +
              selectemail.toEmailId;
            callsaveauditapi(logs, dispatch, navigate);

            seteditmodal(false);
            getInbox(pageno);
          })
          .catch((error) => {
            setbackdropopen(false);
            toast.error(JSON.stringify(error.response.data.message));
            handle403error(error);
          });
      }
    } else {
      //console.log(data);
      setattachmentState(false);
      axios
        .post(`${base_url.api4}/createEmail`, data2)
        .then((response) => {
          setbackdropopen(false);
          toast.success(response.data.message);
          let logs = isw_user_name + " Send Reply to " + selectemail.toEmailId;
          callsaveauditapi(logs, dispatch, navigate);
          seteditmodal(false);
          getInbox(pageno);
        })
        .catch((error) => {
          setbackdropopen(false);
          toast.error(JSON.stringify(error.response.data.message));
          handle403error(error);
        });
    }
  };

  const chkfilesize = (e) => {
    if (e.target.files[0].size / 1048576 > 4) {
      setfilesizemore(true);
    } else {
      setfilesizemore(false);
    }
  };

  return (
    <>
      <a className="sent-button" id={p.id} onClick={() => openeditmodal(p)}>
        Reply
      </a>
      <Modal
        isOpen={editmodal}
        onRequestClose={closeeditModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <h3 style={{ color: "black" }}>Send Reply</h3>
        <form onSubmit={handleSubmit(submitReply)}>
          <div>
            <div className="form-group creatadmin">
              <Row>
                <Col md={12}>
                  <label>
                    Receiver:
                    <span style={{ color: "red" }}>*</span>:
                  </label>
                  <input
                    type="text"
                    ref={register}
                    className="form-control underline-input"
                    value={selectemail.toEmailId}
                    readOnly
                    required
                  />
                </Col>
              </Row>
              <br />
              <Row>
                <Col md={12}>
                  <label>
                    Subject
                    <span style={{ color: "red" }}>*</span>:
                  </label>
                  <input
                    type="text"
                    ref={register}
                    className="form-control underline-input"
                    onChange={(e) => {
                      setsetSubject(e.target.value);
                    }}
                    required
                  />
                </Col>
              </Row>
              <br />
              <Row>
                <Col md={12}>
                  <label>
                    Description
                    <span style={{ color: "red" }}></span>:
                  </label>
                  <TextareaAutosize
                    rowsMax={6}
                    ref={register}
                    style={{ height: "100px", backgroundColor: "#f5f5f9" }}
                    className="form-control underline-input"
                    onChange={(e) => {
                      setsetComment(e.target.value);
                    }}
                    required
                  />
                </Col>
              </Row>
              <br />
              <Row>
                <Col md={12}>
                  <label>
                    Attachment
                    <span style={{ color: "red" }}></span>:
                  </label>
                  <input
                    ref={register}
                    type="file"
                    name="picture"
                    style={{ width: "100%" }}
                    onChange={(e) => chkfilesize(e)}
                    accept=".jpg, .jpeg, .bmp, .gif, .png, .pdf, .doc, .docx"
                  />
                  {filesizemore && (
                    <p className="errormessage">
                      File Size cannot be more than 4 mb
                    </p>
                  )}
                </Col>
              </Row>
              <Row style={{ marginTop: "15px" }}>
                <Col md={6}></Col>
                <Col md={6}>
                  <Row className="justify-content-end">
                    <Button color="secondary" onClick={closeeditModal}>
                      Cancel
                    </Button>
                    <button
                      type="submit"
                      className="btn listing_addbutton ml-1"
                    >
                      Send
                    </button>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default InboxReplyModal;
