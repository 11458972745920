import React from "react";
import Modal from "react-modal";
import moment from "moment";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "400px", // Adjust the width as needed
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
    backgroundColor: "#fff",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1000,
  },
};

const ViewAppointmentModal = ({ selectedEvent, handleCloseModal }) => (

  <Modal
    isOpen={selectedEvent !== null}
    onRequestClose={handleCloseModal}
    style={customStyles}
    contentLabel="Appointment Details"
  >
    <h2 style={{ marginBottom: "20px" }}>Appointment Details</h2>
    <p>
      <strong>Title:</strong> {selectedEvent.title}
    </p>
    <p>
      <strong>Start Time:</strong>{" "}
      {moment(selectedEvent.start).format("MMMM Do YYYY, h:mm:ss a")}
    </p>
    <p>
      <strong>End Time:</strong>{" "}
      {moment(selectedEvent.end).format("MMMM Do YYYY, h:mm:ss a")}
    </p>
    <p>
      <strong>Appointment Details:</strong> {selectedEvent.notesDetails}
    </p>
    <p>
      <strong>Counselor Name:</strong> {selectedEvent.consultantName}
    </p>
    <p>
      <strong>Client Name:</strong> {selectedEvent.clientName}
    </p>
    {/* Add more details here */}
    <button
      style={{
        marginTop: "20px",
        padding: "10px 20px",
        backgroundColor: "#007bff",
        color: "#fff",
        border: "none",
        borderRadius: "4px",
        cursor: "pointer",
      }}
      onClick={handleCloseModal}
    >
      Close
    </button>
  </Modal>
);

export default ViewAppointmentModal;
