import React, { useState } from "react";
import Modal from "react-modal";
import { Col, Row, Button } from "reactstrap";
import axios from "axios";
import base_url from "../../../api/bootapi";
import {
  customStyles,
  customStylesAttachment,
} from "../../../Utilities/CSSUtilities";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Select from "react-select";
import callsaveauditapi from "../../../services/auditservice";
import close from "../../../../src/components/images/closeicon.png";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const EditAdminConfig = ({
  configkey,
  toast,
  handle403error,
  getallConfig,
  pageno,
  selectedIswOnlineConsultantData,
  onlineUsers,
  jwtToken,
  isw_user_name,
  configId,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //console.log(configkey);
  const [editmodal, seteditmodal] = useState(false);
  const [modalIsOpen2, setIsOpen2] = useState(false);
  const [consultantmodal, setconsultantmodal] = useState(false);
  const [selectedIswOnlineConsultant, setSelectedIswOnlineConsultant] =
    useState(false);
  const [stateCust, setstateCust] = useState();
  const [
    selectedIswOnlineConsultantData2,
    setSelectedIswOnlineConsultantData2,
  ] = useState();
  const [setConfig, setConfigValue] = useState();
  const [consultantState, setConsultantState] = useState();
  const [consultantLabel, setConsultantLabel] = useState();
  const [selectConfiguser, setselectConfiguser] = useState();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const selectuserbybranch = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementValue = optionElement.getAttribute("value");
    stateCust.configValue = optionElementValue;

    setselectConfiguser(optionElementValue);
  };
  const onSelect = (selectedList, selectedItem) => {
    const consultant_obj = [];
    for (let i = 0; i < selectedList.length; i++) {
      let data = selectedList[i].value;
      consultant_obj.push(data);
    }

    const consultant_Label = [];
    for (let i = 0; i < selectedList.length; i++) {
      let data = selectedList[i].label;
      consultant_Label.push(data);
    }

    const consultant_All = [];
    for (let i = 0; i < selectedList.length; i++) {
      let data = selectedList[i];
      consultant_All.push({
        value: data.value,
        label: data.label,
      });
    }

    setConsultantLabel(consultant_Label);
    setConsultantState(consultant_obj);
    setselectConfiguser(consultant_obj);
    setSelectedIswOnlineConsultantData2(consultant_All);
  };

  const closeeditModal = () => {
    seteditmodal(false);
  };
  const closeconsultantModal = () => {
    setconsultantmodal(false);
  };
  const openeditmodal = () => {
    if (configkey == "iswonline_consultant") {
      setSelectedIswOnlineConsultant(true);
      axios
        .get(`${base_url.api3}/getByConfigKey`, {
          headers: {
            configKey: configkey,
          },
        })
        .then((response) => {
          let data = response.data.data.configFullValue?.split(",");
          let data2 = response.data.data.configValue?.split(",");
          let data3 = [];

          for (let i = 0; i < data.length; i++) {
            data3.push({
              value: data2[i],
              label: data[i],
            });
          }
          setSelectedIswOnlineConsultantData2(data3);
          setstateCust(response.data.data);
        })
        .catch((error) => {
          handle403error(error);
        });
      setconsultantmodal(true);
    } else {
      axios
        .get(`${base_url.api3}/getByConfigKey`, {
          headers: {
            configKey: configkey,
          },
        })
        .then((response) => {
          setstateCust(response.data.data);
        })
        .catch((error) => {
          handle403error(error);
        });
      seteditmodal(true);
    }
  };

  const putConfig = (e) => {
    stateCust.configValue = setConfig;
    if (configkey == "iswonline_consultant") {
      if (selectConfiguser != undefined) {
        stateCust.configValue = consultantState.toString();
        stateCust.configFullValue = consultantLabel.toString();
        axios
          .put(`${base_url.api3}/updateConfigById`, stateCust, {
            headers: {
              Authorization: jwtToken,
            },
          })
          .then((response) => {
            toast.success("Success");

            let log =
              isw_user_name +
              " Updated Admin Config key iswonline consultant" +
              response.data.data.configKey +
              ".";
            callsaveauditapi(log, dispatch, navigate);
            closeconsultantModal();
            getallConfig(pageno);
          })
          .catch((error) => {
            handle403error(error);
          });
      }
    } else {
      axios
        .put(`${base_url.api3}/updateConfigById`, stateCust, {
          headers: {
            Authorization: jwtToken,
          },
        })
        .then((response) => {
          toast.success("Success");

          let log =
            isw_user_name +
            " Updated Admin Config key " +
            response.data.data.configKey +
            ".";
          callsaveauditapi(log, dispatch, navigate);
          closeeditModal();
          getallConfig(pageno);
        })
        .catch((error) => {
          handle403error(error);
        });
    }
  };

  const [filesizemore, setfilesizemore] = useState(false);
  const [attachmentdisplay, setattachmentdisplay] = useState(false);
  const [documentpage, setDocPage] = useState(0);
  const chkfilesize = (e) => {
    if (e.target.files.length) {
      let fileSize = 0;
      for (let i = 0; i < e.target.files.length; i++) {
        fileSize += e.target.files[i].size;
      }
      //console.log(fileSize);
      if (fileSize / 1048576 > 200) {
        setfilesizemore(true);
      } else {
        setfilesizemore(false);
      }
    }
  };
  const onSubmitAttachment = (data) => {
    //console.log("Current Page No--->", documentpage);
    if (filesizemore === true) {
      return false;
    }
    //console.log(data);
    let fileToUpload = data.picture;
    let formData = new FormData();

    if (fileToUpload.length) {
      for (let i = 0; i < fileToUpload.length; i++) {
        formData.append("stepfile", fileToUpload[i], fileToUpload[i].name);
        formData.append("Authorization", jwtToken);
        formData.append("adminConfigId", configId);
      }
      postAttachment(formData, fileToUpload);
    } else {
      setattachmentdisplay(true);
    }
  };

  const postAttachment = (formData, fileToUpload) => {
    //console.log("Current Page No--->", documentpage);
    if (fileToUpload.length) {
      axios
        .post(`${base_url.api3}/uploadLogoAttachment`, formData, {
          headers: {
            Authorization: jwtToken,
            adminConfigId: configId,
          },
        })
        .then((res) => {
          //console.log(res.data.data);
          //console.log("Current Page No--->", documentpage);
          // getClientDoc(documentpage);
          getallConfig(documentpage);
          setIsOpen2(false);
          toast.success(res.data.message);
        })
        .catch((error) => {
          //console.log(error);
          handle403error(error);
        });
    }
  };

  const closeModal = (id) => {
    setIsOpen2(false);
  };
  return (
    <>
      {configkey === "site_logo" ||
      configkey === "invoice_logo" ||
      configkey === "admin_logo" ||
      configkey === "invoice_address_logo" ||
      configkey === "watermark_logo" ? (
        <span style={{ cursor: "pointer" }} onClick={() => setIsOpen2(true)}>
          Edit Logo
        </span>
      ) : (
        <span style={{ cursor: "pointer" }} onClick={openeditmodal}>
          Edit
        </span>
      )}

      <Modal
        isOpen={modalIsOpen2}
        onRequestClose={closeModal}
        style={customStylesAttachment}
        contentLabel="Example Modal"
      >
        <div style={{ marginLeft: "30px", marginRight: "35px" }}>
          <img className="closei" src={close} onClick={closeModal} />
          <br />
          <h4 className="s_upload">Upload Logo</h4>

          <div>
            <form
              class="mt-3"
              onChange={(e) => chkfilesize(e)}
              onSubmit={handleSubmit(onSubmitAttachment)}
            >
              <label for="fileUpload" class="form-label">
                File
              </label>
              <input
                class="form-control"
                type="file"
                id="fileUpload"
                style={{ padding: "10px" }}
                name="picture"
                ref={register}
                accept=".jpg, .jpeg, .bmp, .gif, .png, .pdf,.PDF ."
                multiple
              />
              <p class="mt-2 mx-3 " className="s_choosefile">
                <span>Note:</span> All files should be at least 720p and less
                than 4.0 GB.
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: "5px",
                }}
              >
                <button className="btn btn-secondary" onClick={closeModal}>
                  Cancel
                </button>
                <button className="btn listing_addbutton ml-1" type="submit">
                  Submit
                </button>
              </div>
            </form>
            <br />
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={editmodal}
        onRequestClose={closeeditModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <h2>
          <strong>Config</strong>
        </h2>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            putConfig(e);
          }}
        >
          <div>
            <div className="form-group creatadmin">
              <Row>
                <Col md={12}>
                  <label>
                    Config Key
                    <span style={{ color: "red" }}>*</span>:
                  </label>
                  <input
                    type="text"
                    value={stateCust?.configKey}
                    className="form-control underline-input"
                    readOnly
                  />
                </Col>
              </Row>
              <br />
              <Row>
                <Col md={12}>
                  <label>
                    Key Name
                    <span style={{ color: "red" }}>*</span>:
                  </label>
                  <input
                    type="text"
                    value={stateCust?.configName}
                    className="form-control underline-input"
                    readOnly
                  />
                </Col>
              </Row>
              <br />
              <Row>
                <Col md={12}>
                  <label>
                    Key Value
                    <span style={{ color: "red" }}>*</span>:
                  </label>
                  <TextareaAutosize
                    rowsMax={6}
                    style={{ height: "100px", backgroundColor: "#f5f5f9" }}
                    className="form-control underline-input"
                    defaultValue={stateCust?.configValue}
                    onChange={(e) => {
                      setConfigValue(e.target.value);
                    }}
                    required
                  />
                </Col>
              </Row>
              <Row style={{ marginTop: "10px" }}>
                <Col md={6}></Col>
                <Col md={6} style={{ textAlign: "left" }}>
                  <Row className="justify-content-end">
                    <Button color="secondary" onClick={closeeditModal}>
                      Cancel
                    </Button>
                    <button
                      type="submit"
                      className="btn listing_addbutton ml-1"
                    >
                      Submit
                    </button>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
        </form>
      </Modal>
      <Modal
        isOpen={consultantmodal}
        onRequestClose={closeconsultantModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <h2>
          <strong>Config</strong>
        </h2>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            putConfig(e);
          }}
        >
          <div>
            <div className="form-group creatadmin">
              <Row>
                <Col md={12}>
                  <label>
                    Config Key
                    <span style={{ color: "red" }}>*</span>:
                  </label>
                  <input
                    type="text"
                    value={stateCust?.configKey}
                    className="form-control underline-input"
                    readOnly
                  />
                </Col>
              </Row>
              <br />
              <Row>
                <Col md={12}>
                  <label>
                    Key Name
                    <span style={{ color: "red" }}>*</span>:
                  </label>
                  <input
                    type="text"
                    value={stateCust?.configName}
                    className="form-control underline-input"
                    readOnly
                  />
                </Col>
              </Row>
              <br />
              <Row>
                <Col md={12}>
                  <label>
                    Key Value
                    <span style={{ color: "red" }}>*</span>:
                  </label>
                  <br />
                  {selectedIswOnlineConsultant ? (
                    <Select
                      isMulti
                      name="iswonline_consultant"
                      value={
                        selectedIswOnlineConsultantData2
                          ? selectedIswOnlineConsultantData2
                          : null
                      }
                      options={selectedIswOnlineConsultantData}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={onSelect}
                      required
                    />
                  ) : (
                    <select
                      style={{
                        width: "100%",
                        height: "35px",
                        // paddingLeft: "13px",
                      }}
                      value={stateCust?.configValue}
                      onChange={(e) => {
                        selectuserbybranch(e);
                      }}
                      required
                    >
                      <option value="">-- Select Config User --</option>
                      {onlineUsers?.map((p, index) => (
                        <option
                          key={index}
                          id={p.id}
                          value={p.email}
                          username={p.firstName + " " + p.lastName}
                        >
                          {p.firstName +
                            " " +
                            p.lastName +
                            " (" +
                            p.email +
                            ")"}
                        </option>
                      ))}
                    </select>
                  )}
                </Col>
              </Row>
              <Row
                className="justify-content-end mt-2"
                style={{ marginLeft: "180px", marginTop: "20px" }}
              >
                <Button color="secondary" onClick={closeconsultantModal}>
                  Cancel
                </Button>
                <button
                  className="btn listing_addbutton ml-1"
                  type="submit"
                  style={{ float: "left" }}
                >
                  Submit
                </button>
              </Row>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default EditAdminConfig;
