import React, { useState } from "react";
import Modal from "react-modal";
import { Col, Row, Button } from "reactstrap";
import axios from "axios";
import base_url from "../../../api/bootapi";
import { customStyles, useStyles } from "../../../Utilities/CSSUtilities";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Select from "react-select";
import callsaveauditapi from "../../../services/auditservice";
import { CountryDropdown } from "react-country-region-selector";
import {
  selectUser,
  setdashboardcheck,
  logout,
} from "./../../features/userSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const EditAreaModal = ({
  getAllArea,
  toast,
  handle403error,
  pageno,
  isw_user_name,
  p,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [editmodal, seteditmodal] = useState(false);
  const [modalIsOpen3, setIsOpen3] = React.useState(false);
  const [editAreaId, seteditAreaId] = useState(p.areaId);
  const [editAreaName, seteditAreaName] = useState(p.name);
  const [editAreastatus, seteditAreastatus] = useState(p.status);
  const [editAreacountry, seteditAreacountry] = useState(p.country);
  const user = useSelector(selectUser);
  const jwtToken = "Bearer " + user.isw_user_token;
  //console.log(p);

  const classes = useStyles();
  const closeeditModal = () => {
    seteditmodal(false);
  };

  const openeditmodal = () => {
    seteditmodal(true);
  };

  const updateArea = (e) => {
    let data = {
      areaId: editAreaId,
      name: editAreaName.trim(),
      status: editAreastatus,
      country: editAreacountry,
    };
    //console.log(data);
    axios
      .post(`${base_url.api1}/updateAreaById`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        toast.success("Success");
        seteditmodal(false);

        let log = isw_user_name + " Edited Area " + editAreaName + ".";
        callsaveauditapi(log, dispatch, navigate);

        getAllArea(pageno);
      })
      .catch((error) => {
        handle403error(error);
        toast.error(JSON.stringify(error.response.message));
        seteditmodal(false);
      });
  };

  return (
    <>
      <span style={{ cursor: "pointer" }} onClick={openeditmodal}>
        Edit
      </span>
      <Modal
        isOpen={editmodal}
        onRequestClose={closeeditModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <h2>
          <strong>Area</strong>
        </h2>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            updateArea(e);
          }}
        >
          <div>
            <div className="form-group creatadmin">
              <Row>
                <Col md={12}>
                  <label>
                    Name
                    <span style={{ color: "red" }}>*</span>:
                  </label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    value={editAreaName}
                    onChange={(e) => {
                      seteditAreaName(e.target.value);
                    }}
                    required
                    // readOnly
                  />
                </Col>
              </Row>
              <br />
              <Row>
                <Col md={12}>
                  <label>
                    Country
                    <span style={{ color: "red" }}>*</span>:
                  </label>
                  <CountryDropdown
                    defaultOptionLabel="- Select Country -"
                    className="form-control underline-input countrydropdown2"
                    value={editAreacountry}
                    onChange={(val) => seteditAreacountry(val)}
                    required
                  />
                </Col>
              </Row>
              <br />
              <Row>
                <Col md={12}>
                  <label>
                    Status
                    <span style={{ color: "red" }}></span>:
                  </label>
                  <select
                    className="form-control"
                    name="status"
                    value={editAreastatus}
                    onChange={(e) => {
                      seteditAreastatus(e.target.value);
                    }}
                    required
                  >
                    <option value="1">Active</option>
                    <option value="0">Inactive</option>
                  </select>
                </Col>
              </Row>
              <Row style={{ marginTop: "15px" }}>
                <Col md={6}></Col>
                <Col md={6}>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button color="secondary" onClick={closeeditModal}>
                      Cancel
                    </Button>
                    <button
                      type="submit"
                      className="btn listing_addbutton ml-1"
                    >
                      Submit
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default EditAreaModal;
