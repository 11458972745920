import React from "react";
import { ResponsivePie } from "@nivo/pie";

const PieChart = ({ data }) => {
  return (
    <ResponsivePie
      data={data}
      // width={500}
      // height={300}
      margin={{ top: 10, right: 120, bottom: 10, left: 10 }}
      innerRadius={0.5} //uncommnet this line to make it a donut chart
      padAngle={0.7}
      cornerRadius={3}
      activeOuterRadiusOffset={8}
      borderWidth={1}
      borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
      colors={{ scheme: "nivo" }}
      radialLabelsSkipAngle={10}
      radialLabelsTextXOffset={6}
      radialLabelsTextColor="#333333"
      radialLabelsLinkOffset={0}
      radialLabelsLinkDiagonalLength={12}
      radialLabelsLinkHorizontalLength={24}
      radialLabelsLinkStrokeWidth={1}
      radialLabelsLinkColor={{ from: "color" }}
      slicesLabelsSkipAngle={10}
      slicesLabelsTextColor="#333333"
      animate={true}
      motionStiffness={90}
      motionDamping={15}
      arcLabelsTextColor="transparent"
      enableArcLinkLabels={false}
      enableRadialLabels={true}
      radialLabel=".data.label"
      tooltip={({ datum }) => (
        <span>
          {datum.label}: {datum.value}
        </span>
      )} // Show only the name in the tooltip
      legends={[
        {
          anchor: "right",
          direction: "column",
          justify: false,
          translateX: 100,
          translateY: 0,
          itemsSpacing: 0,
          itemDirection: "left-to-right",
          itemWidth: 80,
          itemHeight: 20,
          itemOpacity: 1,
          symbolSize: 12,
          symbolShape: "circle",
          symbolBorderColor: "rgba(0, 0, 0, .5)",
          symbolBorderWidth: 2,
          effects: [
            {
              on: "hover",
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      enableArcLabels={true}
    />
  );
};

export default PieChart;
