import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, Link } from "react-router-dom";

const DashboardDropdown = ({
  showDashTitle,
  setShowDash,
  check1pointpermission,
  check4pointpermission,
}) => {
  return (
    <div style={{ marginTop: "100px" }}>
      <ul class="nav flex-column main-nav">
        <li class="nav-item">
          <a class="nav-link extended" onClick={() => setShowDash(false)}>
            <ArrowBackIcon onClick={() => setShowDash(false)} />
            {showDashTitle}
          </a>
        </li>
        {showDashTitle === "AC Receivable" && (
          <>
            {check1pointpermission(510) && (
              <li class="nav-item">
                <Link className="nav-link extended" to="/acc-admission">
                  Admission
                </Link>
              </li>
            )}
            {check1pointpermission(511) && (
              <li class="nav-item">
                <Link className="nav-link extended" to="/acc-migration">
                  Migration
                </Link>
              </li>
            )}
            {check1pointpermission(512) && (
              <li class="nav-item">
                <Link className="nav-link extended" to="/acc-insurance">
                  Insurance
                </Link>
              </li>
            )}
            {check1pointpermission(513) && (
              <li class="nav-item">
                <Link className="nav-link extended" to="/acc-general">
                  Skill Assessment
                </Link>
              </li>
            )}
          </>
        )}
        {showDashTitle === "AC Payable" && (
          <>
            {check1pointpermission(515) && (
              <li class="nav-item">
                <Link className="nav-link extended" to="/acc-payable-admission">
                  Admission
                </Link>
              </li>
            )}
            {check1pointpermission(516) && (
              <li class="nav-item">
                <Link className="nav-link extended" to="/acc-payable-migration">
                  Migration
                </Link>
              </li>
            )}
            {check1pointpermission(517) && (
              <li class="nav-item">
                <Link className="nav-link extended" to="/acc-payable-insurance">
                  Insurance
                </Link>
              </li>
            )}
            {check1pointpermission(518) && (
              <li class="nav-item">
                <Link className="nav-link extended" to="/acc-payable-general">
                  Skill Assessment
                </Link>
              </li>
            )}
          </>
        )}
        {showDashTitle === "General" && (
          <>
            {check1pointpermission(1003) && (
              <li class="nav-item">
                <Link className="nav-link extended" to="/today-lead-report">
                  Todays Lead
                </Link>
              </li>
            )}
            {check1pointpermission(1007) && (
              <li class="nav-item">
                <Link className="nav-link extended" to="/leads-report">
                  Lead
                </Link>
              </li>
            )}
            {check1pointpermission(1011) && (
              <li class="nav-item">
                <Link className="nav-link extended" to="/client-report">
                  Clients
                </Link>
              </li>
            )}
            {check1pointpermission(1015) && (
              <li class="nav-item">
                <Link
                  className="nav-link extended"
                  to="/branch-wise-general-summery-report"
                >
                  Branchwise Summary
                </Link>
              </li>
            )}
          </>
        )}
        {showDashTitle === "Admission" && (
          <>
            {check1pointpermission(1020) && (
              <li class="nav-item">
                <Link className="nav-link extended" to="/admisison-report">
                  Admission Report
                </Link>
              </li>
            )}
            {check1pointpermission(1024) && (
              <li class="nav-item">
                <Link
                  className="nav-link extended"
                  to="/superAgent-receivable-admission-summery-report"
                >
                  SuperAgent Receivable
                </Link>
              </li>
            )}
            {check1pointpermission(1028) && (
              <li class="nav-item">
                <Link
                  className="nav-link extended"
                  to="/subAgent-payable-admission-summery-report"
                >
                  SubAgent Summery
                </Link>
              </li>
            )}
            {check1pointpermission(1032) && (
              <li class="nav-item">
                <Link
                  className="nav-link extended"
                  to="/admission-provider-summery-report"
                >
                  Provider Summery
                </Link>
              </li>
            )}
          </>
        )}
        {showDashTitle === "Migration" && (
          <>
            {check1pointpermission(1037) && (
              <li class="nav-item">
                <Link className="nav-link extended" to="/migration-report">
                  Migration Report
                </Link>
              </li>
            )}
            {check1pointpermission(1041) && (
              <li class="nav-item">
                <Link
                  className="nav-link extended"
                  to="/subAgent-payable-migration-summery-report"
                >
                  SubAgent Summery
                </Link>
              </li>
            )}
            {check1pointpermission(1045) && (
              <li class="nav-item">
                <Link
                  className="nav-link extended"
                  to="/migration-provider-summery-report"
                >
                  Migration Provider Summery
                </Link>
              </li>
            )}
          </>
        )}

        {/* {showDashTitle === "Migration WorkLoad" && (
          <>
            <li class="nav-item">
              <Link className="nav-link extended" to="/migration-enquiry">
                Migration
              </Link>
            </li>

            <li class="nav-item">
              <Link className="nav-link extended" to="/skillAssessment-enquiry">
                Skill Assessment
              </Link>
            </li>

            <li class="nav-item">
              <Link className="nav-link extended" to="/migration-enquiry">
                EOI
              </Link>
            </li>
          </>
        )} */}

        {showDashTitle === "Insurance" && (
          <>
            {check1pointpermission(1050) && (
              <li class="nav-item">
                <Link className="nav-link extended" to="/insurance-report">
                  Insurance Report
                </Link>
              </li>
            )}
            {check1pointpermission(1054) && (
              <li class="nav-item">
                <Link
                  className="nav-link extended"
                  to="/subAgent-payable-insurence-summery-report"
                >
                  SubAgent Summery
                </Link>
              </li>
            )}
            {check1pointpermission(1058) && (
              <li class="nav-item">
                <Link
                  className="nav-link extended"
                  to="/insurence-provider-summery-report"
                >
                  Insurance Provider Summery
                </Link>
              </li>
            )}
          </>
        )}
        {showDashTitle === "Skill Assessment" && (
          <>
            {check1pointpermission(1067) && (
              <li class="nav-item">
                <Link
                  className="nav-link extended"
                  to="/subAgent-payable-skill-assessment-summery-report"
                >
                  SubAgent Summery
                </Link>
              </li>
            )}
            {check1pointpermission(1071) && (
              <li class="nav-item">
                <Link
                  className="nav-link extended"
                  to="/skill-assessment-provider-summery-report"
                >
                  SK Provider Summery
                </Link>
              </li>
            )}
          </>
        )}
        {showDashTitle === "Setting" && (
          <>
            {check4pointpermission(1501, 1503) && (
              <li class="nav-item">
                <Link className="nav-link extended" to="/admin-config">
                  Admin Config
                </Link>
              </li>
            )}
            {check4pointpermission(1504, 1508) && (
              <li class="nav-item">
                <Link className="nav-link extended" to="/areas">
                  Area
                </Link>
              </li>
            )}
            {check4pointpermission(1509, 1513) && (
              <li class="nav-item">
                <Link className="nav-link extended" to="/category">
                  Category
                </Link>
              </li>
            )}
            {check4pointpermission(1514, 1518) && (
              <li class="nav-item">
                <Link className="nav-link extended" to="/client-source">
                  Client Source
                </Link>
              </li>
            )}
            {check4pointpermission(1519, 1522) && (
              <li class="nav-item">
                <Link className="nav-link extended" to="/group-permissions">
                  Group Permissions
                </Link>
              </li>
            )}
            {check4pointpermission(1523, 1527) && (
              <li class="nav-item">
                <Link className="nav-link extended" to="/healthcovertype">
                  HealthCoverType
                </Link>
              </li>
            )}
            {check4pointpermission(1528, 1532) && (
              <li class="nav-item">
                <Link className="nav-link extended" to="/insurance">
                  Insurance
                </Link>
              </li>
            )}
            {check4pointpermission(1533, 1537) && (
              <li class="nav-item">
                <Link className="nav-link extended" to="/intake">
                  Intake
                </Link>
              </li>
            )}
            {check4pointpermission(1538, 1542) && (
              <li class="nav-item">
                <Link className="nav-link extended" to="/interestedservices">
                  Interested Services
                </Link>
              </li>
            )}
            {check4pointpermission(1543, 1547) && (
              <li class="nav-item">
                <Link className="nav-link extended" to="/locations">
                  Location
                </Link>
              </li>
            )}
            {check4pointpermission(1548, 1552) && (
              <li class="nav-item">
                <Link className="nav-link extended" to="/levels">
                  Level
                </Link>
              </li>
            )}
            {check4pointpermission(1553, 1557) && (
              <li class="nav-item">
                <Link className="nav-link extended" to="/migrationprovider">
                  Migration Provider
                </Link>
              </li>
            )}
            {check4pointpermission(1558, 1563) && (
              <li class="nav-item">
                <Link className="nav-link extended" to="/proforma">
                  Proforma
                </Link>
              </li>
            )}
            {check4pointpermission(1564, 1568) && (
              <li class="nav-item">
                <Link className="nav-link extended" to="/visatype">
                  Visa Type
                </Link>
              </li>
            )}
            {check4pointpermission(1569, 1574) && (
              <li class="nav-item">
                <Link className="nav-link extended" to="/workflow">
                  Workflow
                </Link>
              </li>
            )}
            {check4pointpermission(1575, 1580) && (
              <li class="nav-item">
                <Link className="nav-link extended" to="/applicationstatus">
                  Application Status
                </Link>
              </li>
            )}
          </>
        )}
      </ul>
    </div>
  );
};

export default DashboardDropdown;
