import React, { useEffect, useState } from "react";
import { Row, Col, Button, Card, CardBody, Table } from "reactstrap";
import { useParams, useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import axios from "axios";
import base_url from "./../../api/bootapi";
import { useStyles } from "../../Utilities/CSSUtilities";
import { useSelector, useDispatch } from "react-redux";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Pagination from "@mui/material/Pagination";
import { logout } from "../features/userSlice";

const OfficeClientDetail = (props) => {
  const params = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const [rows, setrows] = React.useState(20);
  const [pageno, setpageno] = useState(0);
  const [getpageno, setgetpageno] = useState();
  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  const [user_lists, setUserList] = useState(null);
  const [totalUser, settotalUser] = useState();
  const [countnumber, setcountnumber] = useState();
  const [numberofElements, setnumberofElements] = useState();
  const [backdrop, setbackdrop] = useState(false);
  const dispatch = useDispatch();
  const [paginationpageno, setpaginationpageno] = useState(1);

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        navigate("/");
      }
    });
    setbackdrop(true);
    getClients();
  }, []);

  useEffect(() => {
    let width = document.querySelector("table")?.offsetWidth;
    if (document.querySelector(".scroller")) {
      document.querySelector(".scroller").style.width = `${width}px`;
    }
  }, [user_lists]);

  let scrolling = false;
  const scrolldiv = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow .table-responsive");
    table.scrollTo(scroller.scrollLeft, 0);
  };

  const scrolltable = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow  .table-responsive");
    scroller.scrollTo(table.scrollLeft, 0);
  };

  const handlepage = (event, value) => {
    setpaginationpageno(value);
    setpageno(value - 1);
    getallUserlist(value - 1);
  };

  const getClients = () => {
    if (localStorage.getItem("officeClientPageNo")) {
      let currentPage = localStorage.getItem("officeClientPageNo");
      setpageno(currentPage);
      setpaginationpageno(Number(currentPage) + 1);
      getallUserlist(currentPage);
    } else {
      setpageno(0);
      setpaginationpageno(1);
      getallUserlist(0);
    }
  };
  const getallUserlist = (value1) => {
    if (value1) {
      axios
        .get(`${base_url.api3}/getClientByOfficeId`, {
          headers: {
            Authorization: jwtToken,
            officeId: params.branch_id,
            columnName: "addedOn",
            direction: "DESC",
            page: Number(value1),
            size: Number(rows),
          },
        })
        .then(
          (response) => {
            //console.log(response.data.data);
            setUserList(response.data.data.content);
            setgetpageno(response.data.data.totalPages);
            settotalUser(response.data.data.totalElements);
            setcountnumber(response.data.data.number);
            setnumberofElements(response.data.data.numberOfElements);
            setbackdrop(false);
          },
          (error) => {
            setbackdrop(false);
            toast.error(JSON.stringify(error.response.data.message));
            handle403error(error);
          }
        );
    }
  };

  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            navigate("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };

  const goback = () => {
    navigate("/office");
  };

  const editClient = (elem) => {
    var client_username = elem.target.id;
    // props.navigate.replace({ pathname: '/edit-Client/'+client_username })
    navigate("/edit-Client/" + client_username);
  };
  return (
    <div>
      <hr />
      <Backdrop className={classes.backdrop} open={backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <div>
        <Row>
          <Col md={6} style={{ textAlign: "left" }}>
            <h5>
              <strong>Branch:- {props.officeName}</strong>
            </h5>
          </Col>
          <Col md={6} className="d-flex justify-content-end">
            <button className="sent-button" onClick={goback}>
              List
            </button>
          </Col>
        </Row>
        <br />
        <div className="above-scroller" onScroll={scrolldiv}>
          <div className="scroller"></div>
        </div>
        <div className="displaytablerow">
          <Table
            className=" workload card-table table-borderless"
            responsive
            striped
            onScroll={scrolltable}
          >
            <thead className="s_sticky">
              <th style={{ minWidth: "50px" }}>SN</th>
              <th style={{ minWidth: "100px" }}>NAME</th>
              <th style={{ minWidth: "120px" }}>ADDRESS</th>
              <th style={{ minWidth: "120px" }}>COUNTRY</th>
              <th style={{ minWidth: "50px" }}>STATUS</th>
              <th style={{ minWidth: "120px" }}>VISA TYPE</th>
              <th style={{ minWidth: "120px" }}>CREATED</th>
            </thead>
            <tbody>
              {user_lists?.map((p, index) => (
                <tr key={index + 1}>
                  <td style={{ minWidth: "50px" }}>{index + 1}</td>
                  <td>
                    <Link
                      id={p.username}
                      onClick={(e) => editClient(e, p)}
                      to={`/edit-client/` + p.username}
                      title="Edit"
                    >
                      {p.firstName + " " + p.lastName}
                    </Link>
                    <br />
                    {p.email}
                  </td>
                  <td>
                    {p.state}
                    <br />
                    PostCode:- {p.pincode}
                  </td>
                  <td>{p.country}</td>
                  {p.status === 1 ? <td>Active</td> : <td>InActive</td>}
                  <td>{p.visaType}</td>
                  <td style={{ textAlign: "left" }}>
                    {p.addedOn
                      ? moment(p.addedOn.split(".")[0]).format("DD-MMM-YY")
                      : null}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
      <hr />
      <Row>
        <Col md={6} style={{ textAlign: "left" }}>
          {user_lists?.length ? (
            <p style={{ marginTop: "15px" }}>
              {countnumber * rows + 1} to{" "}
              {countnumber * rows + numberofElements} of {totalUser} records
            </p>
          ) : (
            <p className="formfooterrecordstyle">No Record Found</p>
          )}
        </Col>
        <Col md={6}>
          <div className={classes.root} style={{ float: "right" }}>
            <Pagination
              page={paginationpageno}
              count={getpageno}
              onChange={handlepage}
              shape="rounded"
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default OfficeClientDetail;
