import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "./css/LoginForm.css";
import axios from "axios";
import base_url from "./../api/bootapi";
import { ToastContainer, toast } from "react-toastify";

function ForgotPassword(props) {
  let params = useParams();
  const navigate = useNavigate();
  const [details, setDetails] = useState({ username: "", password: "" });
  const [inputtype2, setinputtype2] = useState("password");

  const forgotPassword = (e) => {
    //console.log(e.target[0].value);
    //console.log(e.target[1].value);
    // "Access-Control-Allow-Origin": "*",
    axios
      .get(`${base_url.api2}/setNewUserPassword`, {
        headers: {
          email: e.target[0].value.trim(),
          verificationToken: params.token.trim(),
          newPassword: e.target[1].value.trim(),
        },
      })
      .then((response) => {
        toast.success("Password Reset Successful");
        navigate("/");
      })
      .catch((error) => {
        toast.error("Token Error");
      });
  };

  return (
    <div className="loginform">
      <ToastContainer />
      {/* <div className="form-inner"> */}
      <form
        onSubmit={(e) => {
          e.preventDefault();
          forgotPassword(e);
        }}
      >
        <div className="form-inner forgot_form_width">
          <h2 className="forgot_form_head">Forgot Password</h2>
          {/*ERROR! */}
          <div className="form-group">
            <label>Username/Email</label>
            <input
              type="text"
              className="form-control underline-input forminput2"
              value={params.email}
              readOnly
            />
          </div>
          <div className="form-group mt-10">
            <label>New Password</label>
            <div style={{ position: "relative" }}>
              <input
                type={inputtype2}
                className="form-control underline-input forminput2"
                name="password"
                id="password"
                placeholder="Password"
                onChange={(e) =>
                  setDetails({ ...details, password: e.target.value })
                }
              />
              {inputtype2 === "password" ? (
                <i
                  className="fa fa-eye-slash fonticonpositioning"
                  onClick={() => setinputtype2("text")}
                  aria-hidden="true"
                ></i>
              ) : (
                <i
                  className="fa fa-eye fonticonpositioning"
                  onClick={() => setinputtype2("password")}
                  aria-hidden="true"
                ></i>
              )}
            </div>
          </div>
          <div className="form-group text-center mt-40">
            <input
              type="submit"
              className="btn btn-full btn-dutch b-0 br-5 text-uppercase login_access"
              value="Submit"
            />
          </div>
        </div>
      </form>
      {/* </div> */}
    </div>
  );
}

export default ForgotPassword;
