import React, { useState } from "react";
import Modal from "react-modal";
import { customStyles } from "../../Utilities/CSSUtilities";
import { Col, Row, Button } from "reactstrap";
import PasswordStrengthBar from "react-password-strength-bar";
import axios from "axios";
import base_url from "../../api/bootapi";
import callsaveauditapi from "../../services/auditservice";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../features/userSlice";

import { toast } from "react-toastify";

const AgentEmailModal = ({
  username,
  jwtToken,
  toast,
  isw_user_name,
  getallagentlist,
  pageno,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [passwordmodal, setpasswordmodal] = useState(false);
  const [selecteduser, setselecteduser] = useState("");
  const closeModal = () => setpasswordmodal(false);
  const openmodal = (elem) => {
    setselecteduser(elem);
    setpasswordmodal(true);
  };

  const changeAgentEmail = (e) => {
    let email = e.target[0].value;
    let change_email = e.target[1].value;
    //console.log(email+" new email is : "+change_email);
    // password = chkPassState.trim();
    let data = {};
    axios
      .post(`${base_url.api3}/OverWriteAgentEmail`, data, {
        headers: {
          Authorization: jwtToken,
          ExistingEmail: email,
          UpdateEmail: change_email,
        },
      })
      .then((response) => {
        toast.success(response.data.message);
        let logs =
          isw_user_name +
          " Client Email change for the user " +
          e.target[1].value +
          ".";
        callsaveauditapi(logs, dispatch, navigate);
        getallagentlist(pageno);
        closeModal();
      })
      .catch((error) => {
        toast.error(JSON.stringify(error.response.data.message));
        //console.log(JSON.stringify(error.response));
        handle403error(error);
      });
    // } else {
    //   return false;
    // }
  };
  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            navigate("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };

  return (
    <>
      <span
        id={username}
        onClick={() => openmodal(username)}
        style={{ cursor: "pointer" }}
      >
        Change Agent Email
      </span>
      <Modal
        isOpen={passwordmodal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            changeAgentEmail(e);
          }}
        >
          <div>
            <h3 style={{ color: "black" }}>Change Agent Email</h3>
            <div className="form-group creatadmin">
              <Row>
                <Col>
                  <label>Existing Email:</label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    value={selecteduser}
                    name="existing_email"
                    readOnly
                    required
                  />
                </Col>
              </Row>
            </div>
            <div className="form-group creatadmin">
              <Row>
                <Col>
                  <label>New Email:</label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="new_email"
                    required
                  />
                </Col>
              </Row>
            </div>
            <div className="form-group creatadmin">
              <div className="d-flex justify-content-end mt-2">
                <Button color="secondary" onClick={closeModal}>
                  Cancel
                </Button>
                <button className="btn listing_addbutton ml-1" type="submit">
                  Update
                </button>
              </div>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default AgentEmailModal;
