import React, { useEffect, useState } from "react";
import axios from "axios";
import base_url from "../../api/bootapi";
import { Card, CardText, Col, Row, Table } from "reactstrap";
import { useNavigate, Link } from "react-router-dom";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getworkflowType, logout, selectUser } from "../features/userSlice";
import FormControl from "@mui/material/FormControl";
import Pagination from "@mui/material/Pagination";
import { makeStyles } from "@mui/styles";
import { check1pointpermission } from "../Permission/permission";
import { toast } from "react-toastify";

const headtxt = 30;

const useStyles = makeStyles((theme) => ({
  headtext: {
    fontSize: headtxt,
  },
  root: {
    float: "right",
    "& > *": {
      // marginTop: theme.spacing(2),
    },
  },
}));
const InvoiceList = (props) => {
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  // const selected_client_id = localStorage.getItem("selected_client_id");
  const params = useParams();
  const selected_client_id =
    params.client_id || localStorage.getItem("selected_client_id");
  const [sortState, setsortState] = useState("id");
  const [direction, setdirection] = useState("DESC");
  const [rows, setrows] = React.useState(20);
  const [pageno, setpageno] = useState(0);
  const [countnumber, setcountnumber] = useState();
  const [numberofElements, setnumberofElements] = useState();
  const [totalUser, settotalUser] = useState();
  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        navigate("/");
      }
    });
    getPermissions();
    getallworkflow();
    getAllInvoice(pageno);
    getClient();
  }, [rows]);

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);

    if (check1pointpermission(306, permission_obj, resource_obj)) {
    } else {
      navigate("/errorPage");
    }
  };

  const [workflowdata, setworkflowdata] = useState();
  const [getpageno, setgetpageno] = useState();
  const handlepage = (event, value) => {
    setpageno(value - 1);
    //console.log(value - 1);
    getAllInvoice(value - 1);
  };

  const [allInvoice, setAllInvoice] = useState(null);
  const getAllInvoice = (value1) => {
    axios
      .get(`${base_url.api2}/getInvoiceByClientId`, {
        headers: {
          Authorization: jwtToken,
          clientId: selected_client_id,
          columnName: sortState,
          direction: direction,
          page: Number(value1),
          size: Number(rows),
        },
      })
      .then((response) => {
        //console.log(response);
        let invoice_obj = [];
        if (response.data.data.content) {
          for (let i = 0; i < response.data.data.content.length; i++) {
            if (response.data.data.content[i].invoiceStatus == "Paid") {
              response.data.data.content[i].invoiceColor = "green";
            } else if (
              response.data.data.content[i].invoiceStatus == "Unpaid"
            ) {
              response.data.data.content[i].invoiceColor = "red";
            } else if (
              response.data.data.content[i].invoiceStatus == "PartiallyPaid"
            ) {
              response.data.data.content[i].invoiceColor = "blue";
            } else {
              response.data.data.content[i].invoiceColor = "black";
            }
            invoice_obj.push(response.data.data.content[i]);
          }
        }
        setAllInvoice(invoice_obj);
        setgetpageno(response.data.data.totalPages);
        setcountnumber(response.data.data.number);
        setnumberofElements(response.data.data.numberOfElements);
        settotalUser(response.data.data.totalElements);
        //   setsearch(false);
        try {
          document.getElementById("myForm").reset();
        } catch (error) {
          //console.log(error);
        }
      })
      .catch((error) => {
        // toast.error(JSON.stringify(error.response.data.message));
        // toast.error("No Invoice Found!");
        handle403error(error);
      });
  };

  const classes = useStyles();

  const getallworkflow = () => {
    axios
      .get(`${base_url.api2}/getEnquiryWorkflowByClientIdAndWorkflowType`, {
        headers: {
          Authorization: jwtToken,
          clientId: props.clientId,
          workflowType: props.workflowType,
        },
      })
      .then((res) => {
        //console.log(res.data.data);
        setworkflowdata(res.data.data);
      })
      .catch((error) => {
        handle403error(error);
      });
  };

  const handleRows = (event) => {
    setrows(event.target.value);
  };

  const addInvoice = (e) => {
    dispatch(getworkflowType("Accounts"));

    if (params.enquiry_id) {
      localStorage.setItem("selected_enquiry_id", params.enquiry_id);
    }
    // if (params.enquiry_id != null) {
    //   localStorage.setItem(
    //     "prev_path3",
    //     "/view-enquiry-workflow/"+params.client_id+"/"+params.enquiry_id + "/" + params.client_name
    //   );
    // } else if (params.client_id != null) {
    //   localStorage.setItem(
    //     "prev_path3",
    //     "/view-enquiry-workflowList/"+params.client_id+"/"+params.client_name
    //   );
    // }

    localStorage.setItem(
      "prev_path3",
      "/view-enquiry-workflowList/" +
        params.client_id +
        "/" +
        params.client_name
    );

    navigate("/add-invoice-details/" + selected_client_id);
  };

  const viewinvoice = (p, e) => {
    dispatch(getworkflowType("Accounts"));
    const selected_client_username = localStorage.getItem(
      "selected_client_username"
    );
    // if (params.enquiry_id != null) {
    //   localStorage.setItem(
    //     "prev_path3",
    //     "/view-enquiry-workflow/"+params.client_id+"/"+params.enquiry_id + "/" + params.client_name
    //   );
    // } else if (params.client_id != null) {
    //   localStorage.setItem(
    //     "prev_path3",
    //     "/view-enquiry-workflowList/"+params.client_id +"/"+params.client_name
    //   );
    // }

    localStorage.setItem(
      "prev_path3",
      "/view-enquiry-workflowList/" +
        params.client_id +
        "/" +
        params.client_name
    );

    navigate({
      pathname: "/view-invoice-details/" + selected_client_id + "/" + p.id,
    });
  };
  // const editinvoice = (p, e) => {
  //   const selected_client_username = localStorage.getItem(
  //     "selected_client_username"
  //   );
  //   if (params.enquiry_id != null) {
  //     localStorage.setItem(
  //       "prev_path3",
  //       "/view-enquiry-workflow/" + params.enquiry_id + "/" + params.client_name
  //     );
  //   } else if (params.client_id != null) {
  //     localStorage.setItem(
  //       "prev_path3",
  //       "/view-enquiry-workflowList/" +
  //         params.client_id +
  //         "/" +
  //         params.client_name
  //     );
  //   }
  //   navigate({
  //     pathname: "/edit-invoice-details/" + selected_client_id + "/" + p.id,
  //   });
  // };
  // const refundinvoice = (p, e) => {
  //   let data = {
  //     id: p.id,
  //   };
  //   //console.log(data);
  //   axios
  //     .post(`${base_url.api2}/updateInvoiceStatusByInvoiceId`, data, {
  //       headers: {
  //         Authorization: jwtToken,
  //         invoiceStatus: "Cancelled",
  //       },
  //     })
  //     .then((res) => {
  //       //console.log("Invoice res", res);
  //       toast.success("Invoice Refunded");
  //     })
  //     .catch((err) => {
  //       toast.error(JSON.stringify(err?.response?.data?.message));
  //     });
  // };
  const goBack = () => {
    if (params.enquiry_id) {
      //console.log(params.enquiry_id);
      var prev_path = localStorage.getItem("prev_path2");
      navigate(prev_path);
    } else if (params.client_id) {
      //console.log(params.client_id);
      navigate("/client-list");
    }
  };

  useEffect(() => {
    let width = document.querySelector("table")?.offsetWidth;
    if (document.querySelector(".scroller")) {
      document.querySelector(".scroller").style.width = `${width}px`;
    }
  }, [allInvoice]);

  let scrolling = false;
  const scrolldiv = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow .table-responsive");
    table.scrollTo(scroller.scrollLeft, 0);
  };

  const scrolltable = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow  .table-responsive");
    scroller.scrollTo(table.scrollLeft, 0);
  };

  const [clientStatusText, setclientStatusText] = useState();
  const [clientstatus, setclientstatus] = useState();

  const [clientdetails, setclientdetails] = useState();
  const jwttoken = "Bearer " + localStorage.getItem("isw_user_token");
  const getClient = () => {
    axios
      .get(`${base_url.api3}/getClientByUsername`, {
        headers: {
          Authorization: jwttoken,
          username: params.client_name,
        },
      })
      .then((res) => {
        setclientdetails(res.data.data);
        setclientstatus(res.data.data.status);
        if (res.data.data.status === 1) setclientStatusText("Unarchive");
        else setclientStatusText("Archive");
        getallworkflow(pageno);
      })
      .catch((err) => {
        handle403error(err);
      });
  };

  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            navigate("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };

  return (
    <div>
      <Card className="p-3">
        <div>
          <div>
            <div>
              <CardText
                className={classes.headtext}
                style={{ fontSize: "23px", textAlign: "left" }}
              >
                <strong>Invoice</strong>
              </CardText>
            </div>
            <div className="d-flex justify-content-end">
              {check1pointpermission(339, permissionData, rolepermission) &&
              clientstatus === 1 ? (
                <button
                  id={selected_client_id}
                  onClick={addInvoice}
                  className="outline-button"
                  style={{ marginRight: "10px" }}
                >
                  Add Invoice
                </button>
              ) : null}
              <button className="sent-button" onClick={goBack}>
                Client List
              </button>
            </div>
          </div>

          <br />
          <br />
          <div className="above-scroller" onScroll={scrolldiv}>
            <div className="scroller"></div>
          </div>
          <div className="displaytablerow">
            {allInvoice != null ? (
              <Table responsive striped onScroll={scrolltable}>
                <thead>
                  <tr>
                    <th style={{ width: "50px", textAlign: "center" }}>SN</th>
                    <th style={{ minWidth: "150px" }}>INVOICE NO</th>
                    <th style={{ minWidth: "150px" }}>INVOICE TYPE</th>
                    <th style={{ minWidth: "175px" }}>ISSUE DATE</th>
                    <th style={{ minWidth: "175px" }}>PROVIDER</th>
                    <th style={{ minWidth: "150px" }}>ENQUIRY NAME</th>
                    <th style={{ minWidth: "150px", textAlign: "center" }}>
                      DISCOUNT GIVEN
                    </th>
                    <th style={{ minWidth: "150px", textAlign: "center" }}>
                      INCOME SHARED
                    </th>
                    <th style={{ minWidth: "150px", textAlign: "center" }}>
                      TOTAL AMT
                    </th>
                    <th style={{ minWidth: "150px" }}>STATUS</th>
                    <th>ACTION</th>
                  </tr>
                </thead>
                <tbody>
                  {allInvoice?.map((p, index) => (
                    <tr key={index}>
                      <td
                        style={{
                          width: "50px",
                          textAlign: "center",
                          backgroundColor:
                            p.invoiceStatus === "Cancelled" ? "#e65a5a" : "",
                        }}
                      >
                        {index + 1}
                      </td>
                      <td
                        style={{
                          backgroundColor:
                            p.invoiceStatus === "Cancelled" ? "#e65a5a" : "",
                        }}
                      >
                        {check1pointpermission(
                          340,
                          permissionData,
                          rolepermission
                        ) ||
                        check1pointpermission(
                          504,
                          permissionData,
                          rolepermission
                        ) ? (
                          <Link
                            className={
                              p.invoiceStatus === "Cancelled"
                                ? "cancelled-text"
                                : "grayeffect"
                            }
                            onClick={(e) => viewinvoice(p, e)}
                            to={
                              `/view-invoice-details/` +
                              selected_client_id +
                              `/` +
                              p.id
                            }
                          >
                            {p.id}
                          </Link>
                        ) : (
                          <>{p.id}</>
                        )}
                      </td>
                      {p.commisionType === "commissionInvoiceNet" ? (
                        <td
                          style={{
                            backgroundColor:
                              p.invoiceStatus === "Cancelled" ? "#e65a5a" : "",
                          }}
                        >
                          Commission Invoice-Net
                        </td>
                      ) : (
                        <>
                          {p.commisionType === "commissionInvoiceGross" ? (
                            <td
                              style={{
                                backgroundColor:
                                  p.invoiceStatus === "Cancelled"
                                    ? "#e65a5a"
                                    : "",
                              }}
                            >
                              Commission Invoice-Gross
                            </td>
                          ) : (
                            <td
                              style={{
                                backgroundColor:
                                  p.invoiceStatus === "Cancelled"
                                    ? "#e65a5a"
                                    : "",
                              }}
                            >
                              {p.commisionType}
                            </td>
                          )}
                        </>
                      )}
                      <td
                        style={{
                          backgroundColor:
                            p.invoiceStatus === "Cancelled" ? "#e65a5a" : "",
                        }}
                      >
                        {p.invoiceCreatedDate
                          ? moment(p.invoiceCreatedDate.split(".")[0]).format(
                              "DD-MMM-YY"
                            )
                          : null}
                        {/* <br />
                      {p.invoiceCreatedDate
                        ? moment(p.invoiceCreatedDate.split(".")[0]).format(
                          "hh.mm a"
                        )
                        : null} */}
                      </td>
                      <td
                        style={{
                          backgroundColor:
                            p.invoiceStatus === "Cancelled" ? "#e65a5a" : "",
                        }}
                      >
                        {p.serviceProvider}
                      </td>
                      <td
                        style={{
                          backgroundColor:
                            p.invoiceStatus === "Cancelled" ? "#e65a5a" : "",
                        }}
                      >
                        {p.courseName ? (
                          p.courseName
                        ) : (
                          <>{p.insuranceType ? p.insuranceType : p.visaType}</>
                        )}
                      </td>
                      <td
                        style={{
                          textAlign: "right",
                          backgroundColor:
                            p.invoiceStatus === "Cancelled" ? "#e65a5a" : "",
                        }}
                      >
                        {p.discountAmount}
                      </td>
                      {/* <td>{p.incomeSharedAmount ? p.incomeSharedAmount : 0}</td> */}
                      <td
                        style={{
                          textAlign: "right",
                          backgroundColor:
                            p.invoiceStatus === "Cancelled" ? "#e65a5a" : "",
                        }}
                      >
                        {p.incomeSharedAmountData
                          ? p.incomeSharedAmountData.toFixed(2)
                          : 0}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          backgroundColor:
                            p.invoiceStatus === "Cancelled" ? "#e65a5a" : "",
                        }}
                      >
                        {p.totalAmountIncludingTax}
                      </td>
                      <td
                        style={{
                          color: p.invoiceColor,
                          backgroundColor:
                            p.invoiceStatus === "Cancelled" ? "#e65a5a" : "",
                        }}
                      >
                        {p.invoiceStatus}
                      </td>
                      <td
                        style={{
                          backgroundColor:
                            p.invoiceStatus === "Cancelled" ? "#e65a5a" : "",
                        }}
                      >
                        {check1pointpermission(
                          340,
                          permissionData,
                          rolepermission
                        ) ||
                        check1pointpermission(
                          504,
                          permissionData,
                          rolepermission
                        ) ? (
                          <button
                            className="btn listing_addbutton"
                            onClick={(e) => viewinvoice(p, e)}
                          >
                            View
                          </button>
                        ) : (
                          <button className="btn listing_addbutton">
                            No Permission
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <div>
                <p>No Invoice Found</p>
              </div>
            )}
          </div>
          <Row className="s_pagirow">
            <Col md={6}>
              <div>
                <form>
                  <div style={{ float: "left" }}>
                    <FormControl
                      variant="standard"
                      style={{
                        display: "inline-block",
                        marginRight: "25px",
                        minWidth: "50px",
                      }}
                    >
                      <select
                        className={classes.selectlimit}
                        labelId="simple-select-label"
                        id="simple-select"
                        value={rows}
                        onChange={handleRows}
                      >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                    </FormControl>
                    {allInvoice?.length ? (
                      <div className="s_spanPage">
                        {countnumber * rows + 1} to{" "}
                        {countnumber * rows + numberofElements} of {totalUser}{" "}
                        records
                      </div>
                    ) : (
                      <span className="s_spanPage">No Record Found</span>
                    )}
                  </div>
                </form>
              </div>
            </Col>
            <Col md={6}>
              {allInvoice != null ? (
                <div className={classes.root} style={{ float: "right" }}>
                  {/* {searchdata ? (
                  <Pagination count={getpageno} onChange={handlesearch} />
                ) : ( */}
                  <Pagination
                    count={getpageno}
                    onChange={handlepage}
                    shape="rounded"
                  />
                  {/* )} */}
                </div>
              ) : null}
            </Col>
          </Row>
        </div>
      </Card>
    </div>
  );
};

export default InvoiceList;
