import React, { useState, useEffect } from "react";
import notes from "../../images/workload/notes.svg";
import { Col, InputGroup, Row } from "reactstrap";
import Modal from "react-modal";
import attachfile from "../../images/workload/attach-file.svg";
import { customStylesAttachment } from "../../../Utilities/CSSUtilities";
import { useForm } from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
function AdmissionAttachmentModal({
  modalIsOpen2,
  chkfilesize,
  onSubmitAttachment,
  attachmentdisplay,
  filesizemore,
  radioattachmentvalue,
  handleRadioChange,
  displayattachmentradio,
  filesizeMessage,
  isApplicationClosed,
  id,
  openModal2,
}) {
  const [attachmodal, setattachmodal] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    if (!modalIsOpen2) {
      closeattachmodal();
    }
  }, [modalIsOpen2]);

  const openattachmodal = () => {
    console.log("39---->", id);
    openModal2(id);
    setattachmodal(true);
  };
  const closeattachmodal = () => setattachmodal(false);

  const handleFormSubmit = (data) => {
    onSubmitAttachment(data);
    setattachmodal(false);
  };

  return (
    <>
      <img
        src={attachfile}
        title="Attachment"
        alt=""
        style={{ margin: "1px" }}
        onClick={openattachmodal}
      />
      <Modal
        isOpen={isApplicationClosed ? false : attachmodal}
        onRequestClose={openattachmodal}
        style={customStylesAttachment}
        contentLabel="Example Modal"
      >
        <div>
          <h4>Attachment</h4>
          <hr />
          <div>
            <form
              className="form-group dummymargin"
              style={{
                display: "flex",
                flexDirection: "column",
              }}
              onChange={(e) => chkfilesize(e)}
              onSubmit={handleSubmit(handleFormSubmit)}
            >
              <div>
                <label>File:</label>
                <br />
                <input
                  ref={register}
                  type="file"
                  name="picture"
                  accept=".jpg, .jpeg, .bmp, .gif, .png, .pdf,.PDF, ."
                  multiple
                />
                {attachmentdisplay && (
                  <p className="errormessage">Attachment is Required</p>
                )}
                {filesizemore && (
                  <div style={{ color: "red", marginTop: "10px" }}>
                    {filesizeMessage}
                  </div>
                )}
              </div>
              <hr />
              <div className="dummy3_attachment_radio">
                <label>File Type:</label>
                <FormControl component="fieldset">
                  <br />
                  <RadioGroup
                    aria-label="gender"
                    name="attachmenttype"
                    style={{ display: "flex" }}
                    value={radioattachmentvalue}
                    onChange={handleRadioChange}
                  >
                    <Row className="adminusers_row">
                      <Col className="">
                        <FormControlLabel
                          value="coe"
                          control={<Radio color="primary" />}
                          label="COE"
                        />
                        <FormControlLabel
                          value="offer_letter"
                          control={<Radio color="primary" />}
                          label="Offer Letter"
                        />
                        <FormControlLabel
                          value="insurance_health_cover"
                          control={<Radio color="primary" />}
                          label="Insurance Health Cover"
                        />
                      </Col>
                    </Row>
                    <Row className="adminusers_row">
                      <Col>
                        <FormControlLabel
                          value="visa"
                          control={<Radio color="primary" />}
                          label="Visa"
                        />
                        <FormControlLabel
                          value="miscellaneous_doc"
                          control={<Radio color="primary" />}
                          label="Miscellaneous"
                        />
                      </Col>
                    </Row>
                  </RadioGroup>
                </FormControl>
                {displayattachmentradio && (
                  <p className="errormessage">Attachment Type is Required</p>
                )}
              </div>
              <hr />
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: "5px",
                }}
              >
                <button
                  className="outline-button"
                  onClick={closeattachmodal}
                  style={{ marginRight: "10px" }}
                >
                  Cancel
                </button>

                <button
                  className={`sent-button ${
                    filesizemore ? "disabled-class" : ""
                  }`}
                  type="submit"
                  disabled={filesizemore}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default AdmissionAttachmentModal;
