import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  CardText,
  Col,
  Row,
  Button,
  Table,
  Label,
  CarouselCaption,
} from "reactstrap";
import axios from "axios";
import base_url from "../../api/bootapi";
import { ToastContainer, toast } from "react-toastify";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Pagination from "@mui/material/Pagination";
import AddIcon from "@mui/icons-material/Add";
import moment from "moment";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import {
  logout,
  gettabvalue,
  getworkflowType,
  selectUser,
  setdashboardcheck,
} from "../features/userSlice";
import "./../css/Responsive.css";
import DateRangePicker from "react-bootstrap-daterangepicker";
import PasswordStrengthBar from "react-password-strength-bar";
import { customStyles, useStyles } from "../../Utilities/CSSUtilities";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import callsaveauditapi from "../../services/auditservice";
import { getclientpermission } from "../Permission/permission";
import sort from "./../images/sort.svg";
import ClientEmailModal from "../Modal/ClientEmailModal";
import ArchiveModal from "../Modal/Masters/ArchiveModal";
import ClientViewMoreModal from "./../Modal/Masters/ClientViewMoreModal";
import {
  check4pointpermission,
  check1pointpermission,
} from "./../Permission/permission";
import { CSVLink, CSVDownload } from "react-csv";
import jsPDF from "jspdf";
import "./NewStyle.css";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
function ProspectList(props) {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const jwtToken = "Bearer " + user.isw_user_token;
  const [backdropopen, setbackdropopen] = useState(false);
  const [inputtype, setinputtype] = useState("password");
  const classes = useStyles();
  const [client_users, setClientDetails] = useState(null);
  const [pageno, setpageno] = useState(0);
  const [paginationpageno, setpaginationpageno] = useState(1);
  const [sortState, setsortState] = useState("id");
  const [direction, setdirection] = useState("DESC");
  const [remarkmodelopen, setremarkmodelopen] = useState(false);
  const [remarkcontent, setremarkcontent] = useState();
  const [datefilter, setdatefilter] = useState();
  const [statusstate, setstatusstate] = useState(1);
  const [totalUser, settotalUser] = useState();
  const [countnumber, setcountnumber] = useState();
  const [numberofElements, setnumberofElements] = useState();
  const [rows, setrows] = React.useState(50);
  const [clientstatus, setclientstatus] = useState();
  const [clientstatususername, setclientstatususername] = useState();
  const [changestatusmodal, setchangestatusmodal] = useState(false);
  const ipaddress = localStorage.getItem("AdminIpAddress");
  const isw_user_name = localStorage.getItem("isw_user_name");
  const isw_adminId = localStorage.getItem("isw_adminId");
  const isw_user_email = localStorage.getItem("isw_user_email");
  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);
  const [modalIsOpenEmail, setIsOpenEmail] = React.useState(false);
  const [selectedOffice, setselectedOffice] = useState(
    Number(user?.isw_user_role) === 1 || Number(user?.isw_user_role) === 6
      ? "all"
      : user?.isw_available_Office[0]?.officeId
  );

  const [dataForDownload, setDataForDownload] = useState([]);
  const [bDownloadReady, setDownloadReady] = useState(false);
  const csvLink = useRef();

  useEffect(() => {
    if (csvLink && csvLink.current && bDownloadReady) {
      csvLink.current.link.click();
      setDownloadReady(false);
    }
  }, [bDownloadReady]);

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        navigate("/");
      }
    });
    getPermissions();
    getConsltant();
    getAllIntake(selectedOffice);
  }, [rows]);

  useEffect(() => {
    let width = document.querySelector("table")?.offsetWidth;
    if (document.querySelector(".scroller")) {
      document.querySelector(".scroller").style.width = `${width}px`;
    }
  }, [client_users]);

  let scrolling = false;
  const scrolldiv = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow .table-responsive");
    table.scrollTo(scroller.scrollLeft, 0);
  };

  const scrolltable = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow  .table-responsive");
    scroller.scrollTo(table.scrollLeft, 0);
  };

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);

    if (check1pointpermission(301, permission_obj, resource_obj)) {
      dispatch(setdashboardcheck(5));
      if (localStorage.getItem("clientPageNo")) {
        let currentPage = localStorage.getItem("clientPageNo");
        setpageno(currentPage);
        setpaginationpageno(Number(currentPage) + 1);
        allclientdetails(currentPage, statusstate);
      } else {
        setpageno(0);
        setpaginationpageno(1);
        allclientdetails(0, statusstate);
      }
    } else {
      //console.log('No access to the page');
      navigate("/errorPage");
    }
  };

  const handleAction = (actionType) => {
    setbackdropopen(true);
    //console.log(user.isw_user_role);

    // if (user.isw_user_role === 1 || user.isw_user_role === 6) {
    if (userOfficeId === "all") {
      //console.log("coming in if");
      axios
        .get(`${base_url.api3}/searchClientByAllFilter`, {
          headers: {
            Authorization: jwtToken,
            fieldName: "prospect",
            fieldValue: 0,
            status: statusstate,
            columnName: "lastUpdated",
            direction: direction,
            size: 20000,
            prospect: 0,
          },
        })
        .then((response) => {
          setbackdropopen(true);
          setDataForDownload(response.data.data.content);
          setDownloadReady(true);
          setbackdropopen(false);
        })
        .catch((error) => {
          setDataForDownload();
          setDownloadReady(true);
          setbackdropopen(false);
          handle403error(error);
        });
    } else {
      //console.log("coming in else");
      const office_obj = [];
      for (let i = 0; i < user?.isw_available_Office?.length; i++) {
        const element = user?.isw_available_Office?.[i];
        //console.log(element);
        office_obj.push(element);
      }
      //globalgetallenquiry(pageno);
      let office_id = Array.prototype.map
        .call(office_obj, function (item) {
          return item.officeId;
        })
        .join(",");
      //console.log(office_id);
      setallofficeid(office_id);

      axios
        .get(`${base_url.api3}/searchClientByAllFilter`, {
          headers: {
            Authorization: jwtToken,
            fieldName: "prospect",
            fieldValue: 0,
            status: statusstate,
            officeId: office_id,
            // officeId: Number(user.isw_available_Office[0].officeId),
            columnName: "lastUpdated",
            direction: direction,
            size: 20000,
            prospect: 0,
          },
        })
        .then((response) => {
          setbackdropopen(true);
          setDataForDownload(response.data.data.content);
          setDownloadReady(true);
          setbackdropopen(false);
        })
        .catch((error) => {
          setDataForDownload();
          setDownloadReady(true);
          setbackdropopen(false);
          handle403error(error);
        });
    }
  };

  const [pdfRow, setPdfRow] = useState([]);

  const exportPdf = () => {
    setbackdropopen(true);
    //console.log(user.isw_user_role);

    // if (user.isw_user_role === 1 || user.isw_user_role === 6) {
    if (userOfficeId === "all") {
      //console.log("coming in if");
      axios
        .get(`${base_url.api3}/searchClientByAllFilter`, {
          headers: {
            Authorization: jwtToken,
            fieldName: "prospect",
            fieldValue: 0,
            status: statusstate,
            columnName: "lastUpdated",
            direction: direction,
            size: 20000,
            prospect: 0,
          },
        })
        .then((response) => {
          setPdfRow(response.data.data.content);
          const doc = new jsPDF();
          // autoTable(doc, { html: '#myTable' })
          var col = [
            "Created Date",
            "Client Details",
            "Address",
            "Source",
            "Office",
            "Consultant",
            "Session",
            "Created By",
          ];
          var rows = [];
          response.data.data.content?.forEach((element) => {
            var temp = [
              moment(element.addedOn).format("DD/MM/YYYY"),
              element.firstName +
                " " +
                element.middleName +
                " " +
                element.lastName +
                "\n" +
                element.email +
                "\n" +
                "Phone: " +
                element.phone,
              "Country: " + element.country + "\n" + "City: " + element.city,
              element.clientSource,
              element.officeName,
              element.consultantName,
              "SST: " +
                moment(element.startTime).format("DD/MM/YYYY hh:mm:ss") +
                "\n" +
                "SET: " +
                moment(element.endTime).format("DD/MM/YYYY hh:mm:ss"),
              element.createdBy,
            ];
            rows.push(temp);
          });
          doc.autoTable(col, rows, { pageBreak: "auto" });
          // doc.autoTable(col, rows, { pageBreak: 'auto' });
          doc.save("Clients_list.pdf");
          setbackdropopen(false);
        })
        .catch((error) => {
          setPdfRow();
          setbackdropopen(false);
          handle403error(error);
        });
    } else {
      //console.log("coming in else");
      const office_obj = [];
      for (let i = 0; i < user?.isw_available_Office?.length; i++) {
        const element = user?.isw_available_Office?.[i];
        //console.log(element);
        office_obj.push(element);
      }
      //globalgetallenquiry(pageno);
      let office_id = Array.prototype.map
        .call(office_obj, function (item) {
          return item.officeId;
        })
        .join(",");
      //console.log(office_id);
      setallofficeid(office_id);

      axios
        .get(`${base_url.api3}/searchClientByAllFilter`, {
          headers: {
            Authorization: jwtToken,
            fieldName: "prospect",
            fieldValue: 0,
            status: statusstate,
            officeId: office_id,
            // officeId: Number(user.isw_available_Office[0].officeId),
            columnName: "lastUpdated",
            direction: direction,
            size: 20000,
            prospect: 0,
          },
        })
        .then((response) => {
          setPdfRow(response.data.data.content);
          const doc = new jsPDF();
          // autoTable(doc, { html: '#myTable' })
          var col = [
            "Created Date",
            "Client Details",
            "Address",
            "Source",
            "Office",
            "Consultant",
            "Session",
            "Created By",
          ];
          var rows = [];
          response.data.data.content?.forEach((element) => {
            var temp = [
              moment(element.addedOn).format("DD/MM/YYYY"),
              element.firstName +
                " " +
                element.middleName +
                " " +
                element.lastName +
                "\n" +
                element.email +
                "\n" +
                "Phone: " +
                element.phone,
              "Country: " + element.country + "\n" + "City: " + element.city,
              element.clientSource,
              element.officeName,
              element.consultantName,
              "SST: " +
                moment(element.startTime).format("DD/MM/YYYY hh:mm:ss") +
                "\n" +
                "SET: " +
                moment(element.endTime).format("DD/MM/YYYY hh:mm:ss"),
              element.createdBy,
            ];
            rows.push(temp);
          });
          doc.autoTable(col, rows, { pageBreak: "auto" });
          // doc.autoTable(col, rows, { pageBreak: 'auto' });
          doc.save("Clients_list.pdf");
          setbackdropopen(false);
        })
        .catch((error) => {
          setPdfRow();
          setbackdropopen(false);
          handle403error(error);
        });
    }
  };

  const handleRows = (event) => {
    setrows(event.target.value);
  };
  const [getpageno, setgetpageno] = useState();
  const handlepage = (event, value) => {
    setpaginationpageno(value);
    setpageno(value - 1);
    allclientdetails(value - 1, statusstate);
  };

  const [userOfficeId, setuserOfficeId] = useState(
    user.isw_user_role === 1 || user.isw_user_role === 6
      ? "all"
      : user?.isw_available_Office[0]?.officeId
  );

  const allclientdetails = (value1, statusstate) => {
    setbackdropopen(true);
    //console.log(user.isw_user_role);

    // if (user.isw_user_role === 1 || user.isw_user_role === 6) {
    if (userOfficeId === "all") {
      //console.log("coming in if");
      axios
        .get(`${base_url.api3}/searchClientByAllFilter`, {
          headers: {
            Authorization: jwtToken,
            fieldName: "prospect",
            fieldValue: 0,
            status: statusstate,
            columnName: "lastUpdated",
            direction: direction,
            page: Number(value1),
            size: Number(rows),
            prospect: 0,
          },
        })
        .then((response) => {
          setbackdropopen(false);
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          setClientDetails(response.data.data.content);
          setsearch(false);
          setdatefilter(false);
          setgetpageno(response.data.data.totalPages);
          try {
            document.getElementById("myForm").reset();
          } catch (error) {
            //console.log(error);
          }
        })
        .catch((error) => {
          setbackdropopen(false);
          setClientDetails(null);
          handle403error(error);
        });
    } else {
      //console.log("coming in else");
      const office_obj = [];
      for (let i = 0; i < user?.isw_available_Office?.length; i++) {
        const element = user?.isw_available_Office?.[i];
        //console.log(element);
        office_obj.push(element);
      }
      //globalgetallenquiry(pageno);
      let office_id = Array.prototype.map
        .call(office_obj, function (item) {
          return item.officeId;
        })
        .join(",");
      //console.log(office_id);
      setallofficeid(office_id);

      axios
        .get(`${base_url.api3}/searchClientByAllFilter`, {
          headers: {
            Authorization: jwtToken,
            fieldName: "prospect",
            fieldValue: 0,
            status: statusstate,
            officeId: office_id,
            // officeId: Number(user.isw_available_Office[0].officeId),
            columnName: "lastUpdated",
            direction: direction,
            page: Number(value1),
            size: Number(rows),
            prospect: 0,
          },
        })
        .then((response) => {
          setbackdropopen(false);
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          setClientDetails(response.data.data.content);
          setsearch(false);
          setdatefilter(false);
          setgetpageno(response.data.data.totalPages);
          try {
            document.getElementById("myForm").reset();
          } catch (error) {
            //console.log(error);
          }
        })
        .catch((error) => {
          setbackdropopen(false);
          setClientDetails(null);
          handle403error(error);
        });
    }
  };

  const [allofficeid, setallofficeid] = useState();

  const handleChangeOffice = (event) => {
    setbackdropopen(true);
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementName = optionElement.getAttribute("value");
    setuserOfficeId(
      optionElementName === "all"
        ? optionElementName
        : Number(optionElementName)
    );
    setpaginationpageno(1);
    if (optionElementName === "all") {
      if (user.isw_user_role === 1 || user.isw_user_role === 6) {
        axios
          .get(`${base_url.api3}/searchClientByAllFilter`, {
            headers: {
              Authorization: jwtToken,
              fieldName: "prospect",
              fieldValue: 0,
              status: statusstate,
              columnName: "lastUpdated",
              direction: direction,
              page: 0,
              size: Number(rows),
              prospect: 0,
            },
          })
          .then((response) => {
            setClientDetails(response.data.data.content);
            setsearch(false);
            setbackdropopen(false);
            setdatefilter(false);
            settotalUser(response.data.data.totalElements);
            setcountnumber(response.data.data.number);
            setnumberofElements(response.data.data.numberOfElements);
            setgetpageno(response.data.data.totalPages);
            try {
              document.getElementById("myForm").reset();
            } catch (error) {
              //console.log(error);
            }
          })
          .catch((error) => {
            setbackdropopen(false);
            setClientDetails(null);
            handle403error(error);
          });
      } else {
        const office_obj = [];
        for (let i = 0; i < user?.isw_available_Office?.length; i++) {
          const element = user?.isw_available_Office?.[i];
          //console.log(element);
          office_obj.push(element);
        }
        //globalgetallenquiry(pageno);
        let office_id = Array.prototype.map
          .call(office_obj, function (item) {
            return item.officeId;
          })
          .join(",");
        //console.log(office_id);
        setallofficeid(office_id);
        //console.log("hii if");
        axios
          .get(`${base_url.api3}/searchClientByAllFilter`, {
            headers: {
              Authorization: jwtToken,
              fieldName: "prospect",
              fieldValue: 0,
              status: statusstate,
              officeId: office_id,
              columnName: "lastUpdated",
              direction: direction,
              page: 0,
              size: Number(rows),
              prospect: 0,
            },
          })
          .then((response) => {
            setClientDetails(response.data.data.content);
            setsearch(false);
            setbackdropopen(false);
            setdatefilter(false);
            settotalUser(response.data.data.totalElements);
            setcountnumber(response.data.data.number);
            setnumberofElements(response.data.data.numberOfElements);
            setgetpageno(response.data.data.totalPages);
            try {
              document.getElementById("myForm").reset();
            } catch (error) {
              //console.log(error);
            }
          })
          .catch((error) => {
            setbackdropopen(false);
            setClientDetails(null);
            handle403error(error);
          });
      }
    } else {
      //console.log("hii else", optionElementName);
      const office_obj = [];
      for (let i = 0; i < user?.isw_available_Office?.length; i++) {
        const element = user?.isw_available_Office?.[i];
        //console.log(element);
        office_obj.push(element);
      }
      //globalgetallenquiry(pageno);
      let office_id = Array.prototype.map
        .call(office_obj, function (item) {
          return item.officeId;
        })
        .join(",");
      //console.log(office_id);
      setallofficeid(office_id);

      axios
        .get(`${base_url.api3}/searchClientByAllFilter`, {
          headers: {
            Authorization: jwtToken,
            fieldName: "prospect",
            fieldValue: 0,
            status: statusstate,
            officeId: optionElementName,
            columnName: "lastUpdated",
            direction: direction,
            page: 0,
            size: Number(rows),
            prospect: 0,
          },
        })
        .then((response) => {
          setbackdropopen(false);
          setClientDetails(response.data.data.content);
          setsearch(false);
          setdatefilter(false);
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          setgetpageno(response.data.data.totalPages);
          try {
            document.getElementById("myForm").reset();
          } catch (error) {
            //console.log(error);
          }
        })
        .catch((error) => {
          setbackdropopen(false);
          setClientDetails(null);
          handle403error(error);
        });
    }
  };

  const createClient = () => {
    localStorage.setItem("client_prev_path", "/prospect-list");
    navigate("/create-prospect");
  };

  const handleChangeTableStatus = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("id");
    const optionElementName = optionElement.getAttribute("value");
    setstatusstate(Number(optionElementName));
    //console.log(optionElementName);
    allclientdetails(pageno, optionElementName);
  };
  const changeClientEmail = (elem, p) => {
    // seteditLevelId(elem.target.id);
    // seteditLevelName(p.name);
    // seteditLevelstatus(p.status);
    setIsOpenEmail(true);
  };
  const deleteUser = () => {
    let status1;
    if (clientstatus === 0) {
      status1 = 1;
    } else {
      status1 = 0;
    }
    let data = {
      username: clientstatususername,
      status: status1,
    };
    axios
      .post(`${base_url.api3}/updateClientByUsername`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        toast.success("Status Changed");
        setuserStatus(response.data.data.status);
        setchangestatusmodal(false);
        let logs =
          isw_user_name +
          " Updated status of Client " +
          response.data.data.email +
          " to " +
          response.data.data.status +
          ".";
        callsaveauditapi(logs, dispatch, navigate);
        allclientdetails(pageno, statusstate);
      })
      .catch((error) => {
        toast.error(JSON.stringify(error.response.data.message));
        handle403error(error);
      });
  };
  // const editClient = (elem) => {
  //   var client_username = elem.target.id;
  //   // props.navigate.replace({ pathname: '/edit-Client/'+client_username })
  //   navigate("/edit-Client/" + client_username);
  // };

  const viewClient = (elem, p) => {
    localStorage.setItem("clientPageNo", pageno);
    var client_username = elem.target.id;
    localStorage.setItem("comingfromwhere", "userspage");
    if (client_username == "") {
      // props.navigate.replace({ pathname: '/edit-Client/'+client_username })
      //console.log("coming if");
      navigate("/client-view/" + p.email);
    } else {
      //console.log("coming else");
      navigate("/client-view/" + client_username);
    }
  };

  const viewEnquiry = (p, e) => {
    localStorage.setItem("clientPageNo", pageno);
    dispatch(getworkflowType("Admission"));
    dispatch(gettabvalue(0));
    var name = p.firstName + " " + p.lastName;
    // navigate("/view-client-enquiry/" + p.id + "/" + p.email);

    localStorage.setItem("selected_client_id", p.id);
    localStorage.setItem("selected_client_username", p.email);

    localStorage.setItem("prev_path", "/client-list");
    navigate("/view-enquiry-workflowList/" + p.id + "/" + p.email);
  };

  var subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalIsOpen2, setIsOpen2] = React.useState(false);
  const [userStatus, setuserStatus] = useState();

  const afterOpenModal = () => {
    subtitle.style.color = "#f00";
  };

  const closeModal = () => {
    setIsOpen(false);
    setIsOpen2(false);
  };

  const [searchdata, setsearch] = useState();
  const [searchinput, setsearchinput] = useState();
  const handlesearch = (e, value) => {
    //console.log(datesrange);
    setpaginationpageno(value);
    setbackdropopen(true);
    setpageno(value - 1);
    if (datefilter) {
      axios
        .get(`${base_url.api3}/searchClientByAllFilter`, {
          headers: {
            // addedOnFromDate: datesrange.addedOnFromDate,
            // addedOnToDate: datesrange.addedOnToDate,
            fieldName: "prospect",
            fieldValue: 0,
            Authorization: jwtToken,
            columnName: "lastUpdated",
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
            prospect: 0,
          },
        })
        .then((response) => {
          const client_obj = [];
          for (let i = 0; i < response.data.data.content.length; i++) {
            const element = response.data.data.content[i];
            client_obj.push(element);
          }
          const clientdetails = [];
          for (let i = 0; i < client_obj.length; i++) {
            clientdetails.push(client_obj[i].clientDetail);
          }
          setbackdropopen(false);
          setcountnumber(response.data.data.number);
          setClientDetails(response.data.data.content);
          setsearch(false);
          setgetpageno(response.data.data.totalPages);
          try {
            document.getElementById("myForm").reset();
          } catch (error) {
            //console.log(error);
          }
        })
        .catch((error) => {
          setbackdropopen(false);
          setClientDetails(null);
          handle403error(error);
        });
    } else {
      axios
        .get(`${base_url.api3}/searchClientByAllFilter`, {
          headers: {
            Authorization: jwtToken,
            fieldName: "prospect",
            fieldValue: 0,
            searchQuery: searchinput,
            columnName: "lastUpdated",
            page: Number(value - 1),
            size: Number(rows),
            prospect: 0,
          },
        })
        .then((response) => {
          const client_obj = [];
          for (let i = 0; i < response.data.data.content.length; i++) {
            const element = response.data.data.content[i];
            client_obj.push(element);
          }
          setbackdropopen(false);
          setcountnumber(response.data.data.number);
          setClientDetails(response.data.data.content);
        })
        .catch((error) => {
          setbackdropopen(false);
          setClientDetails(null);
          handle403error(error);
        });
    }
  };
  const [display1, setdisplay] = useState("inline");
  const submitSearch = (e) => {
    setbackdropopen(true);
    setdisplay("none");
    let srchdata = e.target[0].value.trim();
    setsearchinput(srchdata);
    setpageno(0);
    //console.log("Search", srchdata);
    if (user.isw_user_role === 1 || user.isw_user_role === 6) {
      axios
        .get(`${base_url.api3}/searchClientByAllFilter`, {
          headers: {
            Authorization: jwtToken,
            fieldName: "prospect",
            fieldValue: 0,
            searchQuery: srchdata,
            columnName: "lastUpdated",
            direction: direction,
            page: Number(0),
            size: Number(rows),
            prospect: 0,
          },
        })
        .then((response) => {
          const client_obj = [];
          for (let i = 0; i < response.data.data.content.length; i++) {
            const element = response.data.data.content[i];
            client_obj.push(element);
          }

          setbackdropopen(false);
          setClientDetails(response.data.data.content);
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          setgetpageno(response.data.data.totalPages);
          setsearch(true);
        })
        .catch((error) => {
          setbackdropopen(false);
          setClientDetails(null);
          handle403error(error);
        });
    } else {
      axios
        .get(`${base_url.api3}/searchClientByAllFilter`, {
          headers: {
            Authorization: jwtToken,
            fieldName: "prospect",
            fieldValue: 0,
            searchQuery: srchdata,
            columnName: "lastUpdated",
            officeId: Number(userOfficeId),
            direction: direction,
            page: Number(0),
            size: Number(rows),
            prospect: 0,
          },
        })
        .then((response) => {
          setbackdropopen(false);
          const client_obj = [];
          for (let i = 0; i < response.data.data.content.length; i++) {
            const element = response.data.data.content[i];
            client_obj.push(element);
          }
          setClientDetails(response.data.data.content);
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          setgetpageno(response.data.data.totalPages);
          setsearch(true);
        })
        .catch((error) => {
          setbackdropopen(false);
          setClientDetails(null);
          handle403error(error);
        });
    }
  };

  const [selectedclient, setselectedclient] = useState();

  const [chkPassState, setchkPassState] = useState();
  const submitNewPassword = (e) => {
    let username = e.target[0].value;
    let password = chkPassState.trim();
    let data = {
      username,
      password,
    };
    axios
      .post(`${base_url.api3}/updateClientPasswordByEmail`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        //console.log(res.data.data);
        toast.success(res.data.message);
        let logs =
          isw_user_name +
          " Updated password of Client " +
          res.data.data.email +
          ".";
        callsaveauditapi(logs, dispatch, navigate);

        setIsOpen2(false);
      })
      .catch((error) => {
        toast.error(JSON.stringify(error.response.data.message));
        handle403error(error);
      });
  };

  const sortClient = (e) => {
    setbackdropopen(true);
    setsortState(e.target.id);
    if (direction == "DESC") {
      setdirection("ASC");
    } else if (direction == "ASC") {
      setdirection("DESC");
    }
    // getallEnquiry(pageno);
    axios
      .get(`${base_url.api3}/searchClientByAllFilter`, {
        headers: {
          Authorization: jwtToken,
          fieldName: "prospect",
          fieldValue: 0,
          status: statusstate,
          columnName: "lastUpdated",
          direction: direction,
          page: Number(pageno),
          size: Number(rows),
          prospect: 0,
        },
      })
      .then((response) => {
        setbackdropopen(false);
        setcountnumber(response.data.data.number);
        setClientDetails(response.data.data.content);
        setsearch(false);
        setgetpageno(response.data.data.totalPages);
        try {
          document.getElementById("myForm").reset();
        } catch (error) {
          //console.log(error);
        }
      })
      .catch((error) => {
        setbackdropopen(false);
        setClientDetails(null);
        handle403error(error);
      });
  };

  const handleEvent = (event, picker) => {
    //console.log(picker.startDate);
  };

  const handleCallback = (start, end, label) => {
    //console.log(start, end, label);
  };

  const [datesrange, setdatesrange] = useState();
  const [datevalue, setdatevalue] = useState("dd-mm-yyyy");
  const handleApply = (event, picker) => {
    setbackdropopen(true);
    setdatevalue(
      `${picker.startDate.format("DD-MM-YYYY")} to ${picker.endDate.format(
        "DD-MM-YYYY"
      )}`
    );
    setpageno(0);
    setpaginationpageno(1);
    let data = {
      addedOnFromDate: picker.startDate.format("YYYY-MM-DD"),
      addedOnToDate: picker.endDate.format("YYYY-MM-DD"),
    };
    setdatesrange(data);
    if (user.isw_user_role === 1 || user.isw_user_role === 6) {
      axios
        .get(`${base_url.api3}/searchClientByAllFilter`, {
          headers: {
            addedOnFromDate: picker.startDate.format("YYYY-MM-DD"),
            addedOnToDate: picker.endDate.format("YYYY-MM-DD"),
            Authorization: jwtToken,
            fieldName: "prospect",
            fieldValue: 0,
            columnName: "lastUpdated",
            direction: direction,
            page: Number(0),
            size: Number(rows),
            prospect: 0,
          },
        })
        .then((response) => {
          const client_obj = [];
          for (let i = 0; i < response.data.data.content.length; i++) {
            const element = response.data.data.content[i];
            client_obj.push(element);
          }
          const clientdetails = [];
          for (let i = 0; i < client_obj.length; i++) {
            clientdetails.push(client_obj[i].clientDetail);
          }
          setbackdropopen(false);
          setClientDetails(response.data.data.content);
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          setsearch(false);
          setdatefilter(true);
          setgetpageno(response.data.data.totalPages);
          try {
            document.getElementById("myForm").reset();
          } catch (error) {
            //console.log(error);
          }
        })
        .catch((error) => {
          setbackdropopen(false);
          setClientDetails(null);
          handle403error(error);
        });
    } else {
      axios
        .get(`${base_url.api3}/searchClientByAllFilter`, {
          headers: {
            addedOnFromDate: picker.startDate.format("YYYY-MM-DD"),
            addedOnToDate: picker.endDate.format("YYYY-MM-DD"),
            Authorization: jwtToken,
            fieldName: "prospect",
            fieldValue: 0,
            officeId: Number(userOfficeId),
            columnName: "lastUpdated",
            direction: direction,
            page: Number(0),
            size: Number(rows),
            prospect: 0,
          },
        })
        .then((response) => {
          const client_obj = [];
          for (let i = 0; i < response.data.data.content.length; i++) {
            const element = response.data.data.content[i];
            client_obj.push(element);
          }
          const clientdetails = [];
          for (let i = 0; i < client_obj.length; i++) {
            clientdetails.push(client_obj[i].clientDetail);
          }
          setbackdropopen(false);
          setClientDetails(response.data.data.content);
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          setsearch(false);
          setdatefilter(true);
          setgetpageno(response.data.data.totalPages);
          try {
            document.getElementById("myForm").reset();
          } catch (error) {
            //console.log(error);
          }
        })
        .catch((error) => {
          setbackdropopen(false);
          setClientDetails(null);
          handle403error(error);
        });
    }
  };

  const [allIntake, setAllIntake] = useState();
  const getAllIntake = (selectedOffice) => {
    if (selectedOffice === "all") {
      axios
        .get(`${base_url.api2}/getByWorkflowTypeBasedOnIntake`, {
          headers: {
            Authorization: jwtToken,
            workflowType: "Admission",
          },
        })
        .then((response) => {
          let intakeData = response.data.data;
          let intakeObj = [];
          for (let i = 0; i < intakeData.length; i++) {
            let data = {
              name: moment(intakeData[i].intake).format("YYYY-MM"),
              val: moment(intakeData[i].intake).format("YYYYMM"),
              intake: intakeData[i].intake,
            };
            intakeObj.push(data);
          }
          let newintakeObj = intakeObj.sort(function (a, b) {
            return b.val - a.val;
          });
          setAllIntake(newintakeObj);
        })
        .catch((error) => {
          setAllIntake();
          handle403error(error);
        });
    } else {
      axios
        .get(`${base_url.api2}/getByWorkflowTypeAndOfficeIdBasedOnIntake`, {
          headers: {
            Authorization: jwtToken,
            workflowType: "Admission",
            officeId: Number(selectedOffice),
          },
        })
        .then((response) => {
          let intakeData = response.data.data;
          let intakeObj = [];
          for (let i = 0; i < intakeData.length; i++) {
            let data = {
              name: moment(intakeData[i].intake).format("YYYY-MM"),
              val: moment(intakeData[i].intake).format("YYYYMM"),
              intake: intakeData[i].intake,
            };
            intakeObj.push(data);
          }
          let newintakeObj = intakeObj.sort(function (a, b) {
            return b.val - a.val;
          });
          setAllIntake(newintakeObj);
        })
        .catch((error) => {
          setAllIntake();
          handle403error(error);
        });
    }
  };

  const [selectedIntake, setSelectedIntake] = useState(null);
  const [intakeState, setIntakeState] = useState();
  const handleChangeIntake = (date) => {
    const intakeDate = date ? format(date, "MMM-yyyy") : null;
    setbackdropopen(true);

    axios
      .get(`${base_url.api3}/searchClientByAllFilter`, {
        headers: {
          Authorization: jwtToken,
          fieldName: "prospect",
          fieldValue: 0,
          columnName: "lastUpdated",
          intake: intakeDate,
          direction: direction,
          page: Number(0),
          size: Number(rows),
          prospect: 0,
        },
      })
      .then((response) => {
        const client_obj = [];
        for (let i = 0; i < response.data.data.content.length; i++) {
          const element = response.data.data.content[i];
          client_obj.push(element);
        }
        const clientdetails = [];
        for (let i = 0; i < client_obj.length; i++) {
          clientdetails.push(client_obj[i].clientDetail);
        }
        setbackdropopen(false);
        setClientDetails(response.data.data.content);
        settotalUser(response.data.data.totalElements);
        setcountnumber(response.data.data.number);
        setnumberofElements(response.data.data.numberOfElements);
        setsearch(false);
        setdatefilter(true);
        setgetpageno(response.data.data.totalPages);
        try {
          document.getElementById("myForm").reset();
        } catch (error) {
          //console.log(error);
        }
      })
      .catch((error) => {
        setbackdropopen(false);
        setClientDetails(null);
        handle403error(error);
      });
  };

  const [visaexpirydatevalue, setvisaexpirydatevalue] =
    useState("Click to open");
  const handlevisaexpirydateApply = (event, picker) => {
    setbackdropopen(true);
    setvisaexpirydatevalue(
      `${picker.startDate.format("DD-MM-YYYY")} to ${picker.endDate.format(
        "DD-MM-YYYY"
      )}`
    );
    //console.log(event, picker);
    setpageno(0);
    let data = {
      visaStartDate: picker.startDate.format("YYYY-MM-DD"),
      visaEndDate: picker.endDate.format("YYYY-MM-DD"),
    };
    if (user.isw_user_role === 1 || user.isw_user_role === 6) {
      axios
        .get(`${base_url.api3}/searchClientByAllFilter`, {
          headers: {
            visaStartDate: picker.startDate.format("YYYY-MM-DD"),
            visaEndDate: picker.endDate.format("YYYY-MM-DD"),
            Authorization: jwtToken,
            fieldName: "prospect",
            fieldValue: 0,
            columnName: "lastUpdated",
            direction: direction,
            page: Number(0),
            size: Number(rows),
            prospect: 0,
          },
        })
        .then((response) => {
          const client_obj = [];
          for (let i = 0; i < response.data.data.content.length; i++) {
            const element = response.data.data.content[i];
            client_obj.push(element);
          }
          const clientdetails = [];
          for (let i = 0; i < client_obj.length; i++) {
            clientdetails.push(client_obj[i].clientDetail);
          }
          setbackdropopen(false);
          setClientDetails(response.data.data.content);
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          setsearch(false);
          setdatefilter(true);
          setgetpageno(response.data.data.totalPages);
          try {
            document.getElementById("myForm").reset();
          } catch (error) {
            //console.log(error);
          }
        })
        .catch((error) => {
          setbackdropopen(false);
          setClientDetails(null);
          handle403error(error);
        });
    } else {
      axios
        .get(`${base_url.api3}/searchClientByAllFilter`, {
          headers: {
            visaStartDate: picker.startDate.format("YYYY-MM-DD"),
            visaEndDate: picker.endDate.format("YYYY-MM-DD"),
            Authorization: jwtToken,
            fieldName: "prospect",
            fieldValue: 0,
            officeId: Number(userOfficeId),
            columnName: "lastUpdated",
            direction: direction,
            page: Number(0),
            size: Number(rows),
            prospect: 0,
          },
        })
        .then((response) => {
          const client_obj = [];
          for (let i = 0; i < response.data.data.content.length; i++) {
            const element = response.data.data.content[i];
            client_obj.push(element);
          }
          const clientdetails = [];
          for (let i = 0; i < client_obj.length; i++) {
            clientdetails.push(client_obj[i].clientDetail);
          }
          setbackdropopen(false);
          setClientDetails(response.data.data.content);
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          setsearch(false);
          setdatefilter(true);
          setgetpageno(response.data.data.totalPages);
          try {
            document.getElementById("myForm").reset();
          } catch (error) {
            //console.log(error);
          }
        })
        .catch((error) => {
          setbackdropopen(false);
          setClientDetails(null);
          handle403error(error);
        });
    }
  };

  const [assigneeData, setAssigneeData] = useState();
  const getConsltant = () => {
    axios
      .get(`${base_url.api2}/getUsersByRoleId`, {
        headers: {
          Authorization: jwtToken,
          roleId: 4,
          direction: "ASC",
          columnName: "firstName",
          status: 1,
        },
      })
      .then((res) => {
        let apiData = res.data.data;
        const consultant_obj = [];
        for (let i = 0; i < apiData.length; i++) {
          let data = {
            value: apiData[i].id,
            label: apiData[i].first_name + " " + apiData[i].last_name,
          };
          consultant_obj.push(data);
        }
        //console.log(consultant_obj);
        setAssigneeData(consultant_obj);
      })
      .catch((error) => {
        setAssigneeData();
        handle403error(error);
      });
  };

  const [assigneeState, setAssigneeState] = useState();
  const [selectedAssigneeData, setSelectedAssigneeData] = useState(null);
  const onSelectWorkload = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementName = optionElement.getAttribute("value");
    //console.log(optionElementName);
    const consultant_obj = [];
    if (consultant_obj.length > 0) {
      setSelectedAssigneeData(optionElementName);
      setAssigneeState(true);
      axios
        .get(`${base_url.api3}/searchClientByAllFilter`, {
          headers: {
            Authorization: jwtToken,
            fieldName: "prospect",
            fieldValue: 0,
            assigneeId: optionElementName,
            columnName: "lastUpdated",
            direction: direction,
            page: Number(0),
            size: Number(rows),
            prospect: 0,
          },
        })
        .then((response) => {
          const client_obj = [];
          for (let i = 0; i < response.data.data.content.length; i++) {
            const element = response.data.data.content[i];
            client_obj.push(element);
          }
          const clientdetails = [];
          for (let i = 0; i < client_obj.length; i++) {
            clientdetails.push(client_obj[i].clientDetail);
          }
          setbackdropopen(false);
          setClientDetails(response.data.data.content);
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          setsearch(false);
          setdatefilter(true);
          setgetpageno(response.data.data.totalPages);
          try {
            document.getElementById("myForm").reset();
          } catch (error) {
            //console.log(error);
          }
        })
        .catch((error) => {
          setbackdropopen(false);
          setClientDetails(null);
          handle403error(error);
        });
    } else {
      setAssigneeState(false);
      axios
        .get(`${base_url.api3}/searchClientByAllFilter`, {
          headers: {
            Authorization: jwtToken,
            fieldName: "prospect",
            fieldValue: 0,
            assigneeId: optionElementName,
            columnName: "lastUpdated",
            direction: direction,
            page: Number(0),
            size: Number(rows),
            prospect: 0,
          },
        })
        .then((response) => {
          const client_obj = [];
          for (let i = 0; i < response.data.data.content.length; i++) {
            const element = response.data.data.content[i];
            client_obj.push(element);
          }
          const clientdetails = [];
          for (let i = 0; i < client_obj.length; i++) {
            clientdetails.push(client_obj[i].clientDetail);
          }
          setbackdropopen(false);
          setClientDetails(response.data.data.content);
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          setsearch(false);
          setdatefilter(true);
          setgetpageno(response.data.data.totalPages);
          try {
            document.getElementById("myForm").reset();
          } catch (error) {
            //console.log(error);
          }
        })
        .catch((error) => {
          setbackdropopen(false);
          setClientDetails(null);
          handle403error(error);
        });
    }
  };

  const [sourceData, setsourceData] = useState("");

  const handleChangesouce = (e) => {
    setbackdropopen(true);
    setsourceData(e.target.value);
    if (e.target.value == "") {
      axios
        .get(`${base_url.api3}/searchClientByAllFilter`, {
          headers: {
            Authorization: jwtToken,
            fieldName: "prospect",
            fieldValue: 0,
            columnName: "lastUpdated",
            direction: direction,
            page: Number(0),
            size: Number(rows),
            prospect: 0,
          },
        })
        .then((response) => {
          const client_obj = [];
          for (let i = 0; i < response.data.data.content.length; i++) {
            const element = response.data.data.content[i];
            client_obj.push(element);
          }
          const clientdetails = [];
          for (let i = 0; i < client_obj.length; i++) {
            clientdetails.push(client_obj[i].clientDetail);
          }
          setbackdropopen(false);
          setClientDetails(response.data.data.content);
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          setsearch(false);
          setdatefilter(true);
          setgetpageno(response.data.data.totalPages);
          try {
            document.getElementById("myForm").reset();
          } catch (error) {
            //console.log(error);
          }
        })
        .catch((error) => {
          setbackdropopen(false);
          setClientDetails(null);
          handle403error(error);
        });
    } else {
      axios
        .get(`${base_url.api3}/searchClientByAllFilter`, {
          headers: {
            Authorization: jwtToken,
            fieldName: "prospect",
            fieldValue: 0,
            columnName: "lastUpdated",
            clientSource: e.target.value,
            direction: direction,
            page: Number(0),
            size: Number(rows),
            prospect: 0,
          },
        })
        .then((response) => {
          const client_obj = [];
          for (let i = 0; i < response.data.data.content.length; i++) {
            const element = response.data.data.content[i];
            client_obj.push(element);
          }
          const clientdetails = [];
          for (let i = 0; i < client_obj.length; i++) {
            clientdetails.push(client_obj[i].clientDetail);
          }
          setbackdropopen(false);
          setClientDetails(response.data.data.content);
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          setsearch(false);
          setdatefilter(true);
          setgetpageno(response.data.data.totalPages);
          try {
            document.getElementById("myForm").reset();
          } catch (error) {
            //console.log(error);
          }
        })
        .catch((error) => {
          setbackdropopen(false);
          setClientDetails(null);
          handle403error(error);
        });
    }
  };

  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            navigate("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };
  return (
    <div>
      <ToastContainer />
      <Backdrop className={classes.backdrop} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* <div className="commoncomponent_cardcss"> */}
      <div
        class="page-head d-flex justify-content-between breadcrums-bar"
        style={{ marginTop: "4.1rem", marginBottom: "-30px" }}
      >
        <ul class="breadcrums">
          <h3 class="page-title bold">Manage Candidates</h3>
          <Link to="/" class="b-link">
            Home
          </Link>
          <span className="s_span ">&raquo;</span>
          <a class="b-link active">Candidate</a>
          <span className="s_span ">&raquo;</span>
          <Link to="/client-list" class="b-link active">
            List
          </Link>
        </ul>
        <div className="progress-container">
          <div className="step-container">
            <div className="circle completed">
              <span className="check-mark">✓</span>
            </div>
            <div className="step-text">Lead</div>
          </div>
          <div className="line"></div>
          <div className="step-container">
            <div className="circle completed">
              <span className="check-mark">✓</span>
            </div>
            <div className="step-text">Canditate</div>
          </div>
          <div className="line"></div>
          <div className="step-container">
            <div className="circle incomplete">
              <span className="step-number">3</span>
            </div>
            <div className="step-text">AIP</div>
          </div>
          <div className="line"></div>
          <div className="step-container">
            <div className="circle incomplete">
              <span className="step-number">4</span>
            </div>
            <div className="step-text">Client</div>
          </div>
        </div>
        {check1pointpermission(302, permissionData, rolepermission) ? (
          <a
            className="sent-button"
            onClick={createClient}
            style={{ height: "fit-content" }}
          >
            Add Candidate
          </a>
        ) : null}
      </div>
      <div className="head-breadcrum">
        <div
          class="card p-4"
          style={{
            padding: "1.5rem",
            borderRight: "1.5rem solid #e9ebf2",
            borderLeft: "1.5rem solid #e9ebf2",
            borderTop: "1.5rem solid #e9ebf2",
          }}
        >
          <div class="form-grp row">
            <div class="input-grp col-md-2">
              <label for="" className="s_label_color">
                Limit
              </label>
              <br />
              <select
                className="form-control"
                labelId="simple-select-label"
                id="simple-select"
                value={rows}
                onChange={handleRows}
                style={{ width: "95%", backgroundColor: "#f5f5f9" }}
                class="form-select"
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div>
            <div class="input-grp col-md-2" style={{ marginTop: "10px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <label
                  className="area_rowslable_my"
                  style={{ fontWeight: "bold" }}
                >
                  Date
                </label>
                <div
                  className="clearsearchclass"
                  style={{ width: "100%", marginTop: "0px" }}
                >
                  <form className="d-flex" style={{ position: "relative" }}>
                    <DateRangePicker
                      onEvent={handleEvent}
                      onCallback={handleCallback}
                      onApply={handleApply}
                    >
                      <p
                        className="datepickerrangevaluetext"
                        style={{
                          backgroundColor: "#f5f5f9",
                          width: "95%",
                          position: "relative",
                        }}
                      >
                        {datevalue}
                      </p>
                    </DateRangePicker>
                    <span
                      className="listpagesearch_button"
                      style={{
                        top: "10px",
                        position: "absolute",
                        right: "20px",
                        pointerEvents: "none",
                      }}
                    >
                      <i className="fa fa-calendar"></i>
                    </span>
                  </form>
                </div>
              </div>
            </div>
            <div class="input-grp col-md-2">
              <label className="s_label_color">Branch</label>
              <br />
              <select
                className="s_form-control"
                onChange={handleChangeOffice}
                name="branch"
                style={{ width: "95%", backgroundColor: "#f5f5f9" }}
              >
                {Number(user?.isw_user_role) === 1 ||
                Number(user?.isw_user_role) === 6 ||
                Number(user?.isw_user_role) === 10 ||
                Number(user?.isw_user_role) === 3 ||
                Number(user?.isw_user_role) === 2 ||
                Number(user?.isw_user_role) === 5 ||
                Number(user?.isw_user_role) === 4 ? (
                  <option value="all">Show All</option>
                ) : null}
                {user?.isw_available_Office?.map((p, index) => (
                  <option key={index} value={p.officeId}>
                    {p.officeName}
                    {" - "}
                    {p.officeCode}
                  </option>
                ))}
              </select>
            </div>
            <div class="input-grp col-md-2">
              <label className="s_label_color">Status</label>
              <br />
              <select
                className="s_form-control"
                onChange={handleChangeTableStatus}
                style={{ width: "95%", backgroundColor: "#f5f5f9" }}
              >
                <option value="1">Unarchive</option>
                <option value="0">Archive</option>
              </select>
            </div>
            <div class="input-grp col-md-2">
              <label className="s_label_color">Source</label>
              <select
                className="form-control"
                name="clientSource"
                value={sourceData}
                style={{ backgroundColor: "#f5f5f9" }}
                onChange={handleChangesouce}
              >
                <option value="">- Select Source -</option>
                <option value="In-house App">In-house App</option>
                <option value="Referer">Referral</option>
                <option value="Social link">Social Media</option>
                <option value="Sub Agent">Sub Agent</option>
                <option value="Website">Website</option>
              </select>
            </div>
          </div>
          <div class="form-grp row pt-3" style={{ marginTop: "-10px" }}>
            <div class="input-grp col-md-2">
              <label className="s_label_color">Intake</label>
              {/* <select
                className="s_form-control"
                onChange={handleChangeIntake}
                name="intake"
                style={{ width: "95%", backgroundColor: "#f5f5f9" }}
              >
                <option value="">Select Intake</option>
                {allIntake?.map((p, index) => (
                  <option key={index} value={p.intake}>
                    {p.name}
                  </option>
                ))}
              </select> */}
              <DatePicker
                selected={selectedIntake ? new Date(selectedIntake) : null}
                onChange={(date) => {
                  setSelectedIntake(date);
                  handleChangeIntake(date);
                }}
                dateFormat="MMM-yyyy"
                showMonthYearPicker
                dropdownMode="select"
                placeholderText="Select Intake"
                style={{ width: "95%", backgroundColor: "#f5f5f9" }}
              />
            </div>
            <div class="input-grp col-md-2" style={{ marginTop: "10px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <label
                  className="area_rowslable_my"
                  style={{ fontWeight: "bold" }}
                >
                  Visa Expiry Date
                </label>
                <div
                  className="clearsearchclass"
                  style={{ width: "100%", marginTop: "0px" }}
                >
                  <form className="d-flex" style={{ position: "relative" }}>
                    <DateRangePicker onApply={handlevisaexpirydateApply}>
                      <p
                        className="datepickerrangevaluetext"
                        style={{
                          backgroundColor: "#f5f5f9",
                          width: "95%",
                          position: "relative",
                        }}
                      >
                        {visaexpirydatevalue}
                      </p>
                    </DateRangePicker>
                    <span
                      className="listpagesearch_button"
                      style={{
                        top: "10px",
                        position: "absolute",
                        right: "20px",
                        pointerEvents: "none",
                      }}
                    >
                      <i className="fa fa-calendar"></i>
                    </span>
                  </form>
                </div>
              </div>
            </div>
            <div class="input-grp col-md-2">
              <label className="s_label_color">Consultant</label>
              <select
                className="s_form-control"
                onChange={onSelectWorkload}
                name="assigneeId"
                style={{ width: "95%", backgroundColor: "#f5f5f9" }}
              >
                <option value="null">Select Consultant</option>
                {assigneeData?.map((p, index) => (
                  <option key={index} value={p.value}>
                    {p.label}
                  </option>
                ))}
              </select>
            </div>
            <div class="input-grp col-md-2">
              <div
                className="clearsearchclass"
                style={{ width: "95%", marginTop: "36px" }}
              >
                {/* <label className="area_rowslable">Lead</label> */}
                <form
                  id="myForm"
                  className="d-flex"
                  onSubmit={(e) => {
                    e.preventDefault();
                    submitSearch(e);
                  }}
                >
                  <input
                    className="form-control"
                    placeholder="Type to Search"
                    name="search"
                  />
                  <button className="btn listpagesearch_button" type="submit">
                    <i
                      className="fa fa-search"
                      style={{ color: "#6d7290" }}
                    ></i>
                  </button>
                </form>
              </div>
              {searchdata ? (
                <a
                  className={classes.clrsrch}
                  onClick={() => {
                    setdisplay("inline");
                    setpageno(0);
                    allclientdetails(0, statusstate);
                  }}
                >
                  Clear Search
                </a>
              ) : null}
            </div>
          </div>
        </div>
        <div className="newlisting_headertabdesigntwo">
          <Row className="s_pagi">
            <Col md={6}>
              {client_users?.length ? (
                <p className="s_para">
                  {countnumber * rows + 1} to{" "}
                  {countnumber * rows + numberofElements} of {totalUser} records
                </p>
              ) : (
                <p className="s_para">No Record Found</p>
              )}
            </Col>
            {/* <Col md={6} className="d-flex justify-content-end">
              {permissionData?.includes(21) ? (
                <button
                  className="btn btn-success"
                  onClick={createClient}
                  style={{ float: "right", height: "40px", backgroundColor: "#198754" }}
                >
                  Add
                </button>
              ) : null}
            </Col> */}
            {check1pointpermission(341, permissionData, rolepermission) ? (
              <Col md={6} style={{ marginTop: "10px" }}>
                <div style={{ display: "flex", justifyContent: "right" }}>
                  <button className="btn listing_addbutton" onClick={exportPdf}>
                    Export PDF
                  </button>

                  <button
                    type="button"
                    style={{ marginLeft: "25px" }}
                    className="btn listing_addbutton"
                    onClick={(e) => handleAction("DOWNLOAD")}
                  >
                    Export CSV
                  </button>
                  <CSVLink
                    data={dataForDownload}
                    filename="Clients-List.csv"
                    className="hidden"
                    ref={csvLink}
                    target="_blank"
                  />
                </div>
              </Col>
            ) : null}
          </Row>
          <div className="above-scroller" onScroll={scrolldiv}>
            <div className="scroller"></div>
          </div>
          {/* <div class="above-scrollbar" style="width: calc(100% - 10px); border: none 0px RED;overflow-x: scroll;">
            <div style="height: 0px; width: 1260px; padding: 10px;"></div>
          </div> */}
          {/* <div class="above-scroller" style={{width:"calc(100% - 10px)",border:"none 0px RED",overflowX:"scroll" }}>
            <div style={{height:"0px",width:"1260px",padding:"10px"}}></div>
          </div> */}
          <div className="displaytablerow">
            {/* <div class="table-responsive" style={{maxHeight:"480px",overflow:"auto"}}> */}
            <Table
              className="workload card-table table-borderless"
              responsive
              onScroll={scrolltable}
              style={{ maxHeight: "480px", overflow: "auto" }}
              class="fixed-header"
            >
              {/* <table
              id="example"
              className="table workload card-table table-borderless table-responsive"
              style={{ width: "100%" }}
              onScroll={scrolltable}
            > */}
              <thead className="s_sticky">
                <tr className="s_tr">
                  <th style={{ textAlign: "center", width: "50px" }}>SN</th>
                  {/* <th style={{ minWidth: "140px" }}>
                    LEAD TYPE{" "}
                    <img src={sort}
                      id="enquiryLeadType"
                      className="sorting"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}` }}
                    />
                  </th> */}
                  <th style={{ minWidth: "95px" }}>
                    DATE
                    <img
                      src={sort}
                      className="sorting"
                      id="addedOn"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}` }}
                    />
                  </th>
                  <th style={{ minWidth: "125px" }}>
                    CLIENT{" "}
                    <img
                      src={sort}
                      id="firstName"
                      className="sorting"
                      onClick={sortClient}
                      style={{
                        cursor: "pointer",
                        display: `${display1}`,
                        height: "10px",
                      }}
                    />
                  </th>
                  <th style={{ minWidth: "90px" }}>
                    CONTACT {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="state"
                      onClick={sortClient}
                      style={{
                        cursor: "pointer",
                        display: `${display1}`,
                        height: "10px",
                      }}
                    />
                  </th>
                  <th style={{ minWidth: "80px" }}>
                    COUNTRY {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="country"
                      onClick={sortClient}
                      style={{
                        cursor: "pointer",
                        display: `${display1}`,
                        height: "10px",
                      }}
                    />
                  </th>
                  <th style={{ minWidth: "90px" }}>
                    SOURCE {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="clientSource"
                      onClick={sortClient}
                      style={{
                        cursor: "pointer",
                        display: `${display1}`,
                        height: "10px",
                      }}
                    />
                  </th>
                  {/* <th style={{ minWidth: "120px" }}>
                    CITY 
                    <img
                      src={sort}
                      className="sorting"
                      id="city"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}`, height: "10px" }}
                    />
                  </th> */}
                  <th style={{ minWidth: "90px" }}>
                    BRANCH {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="officeName"
                      onClick={sortClient}
                      style={{
                        cursor: "pointer",
                        display: `${display1}`,
                        height: "10px",
                      }}
                    />
                  </th>
                  <th style={{ minWidth: "130px" }}>
                    CONSULTANT{/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="consultantName"
                      onClick={sortClient}
                      style={{
                        cursor: "pointer",
                        display: `${display1}`,
                        height: "10px",
                      }}
                    />
                  </th>
                  <th style={{ minWidth: "120px" }}>
                    VISA EXPIRY {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="visaexpiry"
                      onClick={sortClient}
                      style={{
                        cursor: "pointer",
                        display: `${display1}`,
                        height: "10px",
                      }}
                    />
                  </th>
                  <th style={{ minWidth: "120px" }}>
                    SUBAGENT {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="sub_agent"
                      onClick={sortClient}
                      style={{
                        cursor: "pointer",
                        display: `${display1}`,
                        height: "10px",
                      }}
                    />
                  </th>
                  <th style={{ minWidth: "120px" }}>
                    SUPERAGENT {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="super_agent"
                      onClick={sortClient}
                      style={{
                        cursor: "pointer",
                        display: `${display1}`,
                        height: "10px",
                      }}
                    />
                  </th>
                  <th style={{ minWidth: "105px" }}>
                    LAST UPDATED {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="super_agent"
                      onClick={sortClient}
                      style={{
                        cursor: "pointer",
                        display: `${display1}`,
                        height: "10px",
                      }}
                    />
                  </th>
                  <th>
                    <div
                      style={{
                        width: "80px",
                        wordWrap: "break-word",
                        display: "inline-block",
                      }}
                    >
                      REMARKS
                    </div>
                  </th>
                  <th style={{ minWidth: "50px" }}>Created By</th>
                  {check1pointpermission(303, permissionData, rolepermission) ||
                  check1pointpermission(305, permissionData, rolepermission) ? (
                    <>
                      {Number(user?.isw_user_role) === 1 ||
                      Number(user?.isw_user_role) === 4 ||
                      Number(user?.isw_user_role) === 5 ||
                      Number(user?.isw_user_role) === 6 ? (
                        <th style={{ minWidth: "90px" }}>ACTION</th>
                      ) : null}
                    </>
                  ) : null}
                </tr>
              </thead>
              <>
                <tbody>
                  <>
                    {client_users != null ? (
                      client_users &&
                      client_users?.map((p, index) => (
                        <>
                          {p.status === 1 ? (
                            <tr key={index + 1} className="s_tr">
                              <td
                                style={{ textAlign: "center", width: "50px" }}
                              >
                                {countnumber * rows + 1 + index}
                              </td>
                              {/* <td>
                                          {p.enquiryLeadType}
                                          <i class="icon">
                                            <svg width="18" height="18" viewBox="0 0 18 18" fill="#17B978" >
                                              <path d="M13.5 2.25H4.5C4.00755 2.25 3.51991 2.347 3.06494 2.53545C2.60997 2.72391 2.19657 3.00013 1.84835 3.34835C1.14509 4.05161 0.75 5.00544 0.75 6V12C0.75 12.9946 1.14509 13.9484 1.84835 14.6516C2.19657 14.9999 2.60997 15.2761 3.06494 15.4645C3.51991 15.653 4.00755 15.75 4.5 15.75H13.5C13.9925 15.75 14.4801 15.653 14.9351 15.4645C15.39 15.2761 15.8034 14.9999 16.1517 14.6516C16.4999 14.3034 16.7761 13.89 16.9646 13.4351C17.153 12.9801 17.25 12.4925 17.25 12V6C17.25 5.50754 17.153 5.01991 16.9646 4.56494C16.7761 4.10996 16.4999 3.69657 16.1517 3.34835C15.8034 3.00013 15.39 2.72391 14.9351 2.53545C14.4801 2.347 13.9925 2.25 13.5 2.25ZM13.9125 6.6225L9.41251 9.6225C9.29005 9.70314 9.14664 9.74612 9.00001 9.74612C8.85338 9.74612 8.70997 9.70314 8.58751 9.6225L4.0875 6.6225C3.99554 6.57317 3.91489 6.50519 3.8507 6.42291C3.78651 6.34064 3.74019 6.24587 3.71472 6.14467C3.68924 6.04347 3.68516 5.93807 3.70275 5.83521C3.72033 5.73235 3.75919 5.63428 3.81683 5.54729C3.87447 5.4603 3.94964 5.3863 4.03751 5.33002C4.12539 5.27373 4.22404 5.23641 4.32717 5.22043C4.43029 5.20444 4.53562 5.21016 4.63641 5.23721C4.7372 5.26425 4.83123 5.31204 4.9125 5.3775L9.00001 8.1L13.0875 5.3775C13.1688 5.31204 13.2628 5.26425 13.3636 5.23721C13.4644 5.21016 13.5697 5.20444 13.6728 5.22043C13.776 5.23641 13.8746 5.27373 13.9625 5.33002C14.0504 5.3863 14.1255 5.4603 14.1832 5.54729C14.2408 5.63428 14.2797 5.73235 14.2973 5.83521C14.3148 5.93807 14.3108 6.04347 14.2853 6.14467C14.2598 6.24587 14.2135 6.34064 14.1493 6.42291C14.0851 6.50519 14.0045 6.57317 13.9125 6.6225Z" />
                                            </svg>
                                          </i>
                                        </td> */}
                              <td>
                                {p.addedOn
                                  ? moment(p.addedOn.split("T")[0]).format(
                                      "DD-MMM-YY"
                                    )
                                  : null}
                              </td>
                              <td>
                                {check1pointpermission(
                                  306,
                                  permissionData,
                                  rolepermission
                                ) ? (
                                  <Link
                                    className="greeneffect"
                                    id={p.username}
                                    onClick={(e) => viewEnquiry(p, e)}
                                    style={{
                                      width: "120px",
                                      wordWrap: "break-word",
                                      display: "inline-block",
                                    }}
                                    to={
                                      `/view-enquiry-workflowList/` +
                                      p.id +
                                      `/` +
                                      p.email
                                    }
                                  >
                                    {p.firstName +
                                      " " +
                                      p.middleName +
                                      " " +
                                      p.lastName}
                                  </Link>
                                ) : (
                                  <>
                                    {p.firstName +
                                      " " +
                                      p.middleName +
                                      " " +
                                      p.lastName}
                                  </>
                                )}
                                <br />
                                <div
                                  style={{
                                    width: "120px",
                                    wordWrap: "break-word",
                                    display: "inline-block",
                                  }}
                                >
                                  {p.email}
                                </div>
                              </td>
                              <td>
                                <div
                                  style={{
                                    width: "90px",
                                    wordWrap: "break-word",
                                    display: "inline-block",
                                  }}
                                >
                                  {p.phone == 0 ? " " : p.phone}
                                </div>
                                <br />
                                <div
                                  style={{
                                    width: "90px",
                                    wordWrap: "break-word",
                                    display: "inline-block",
                                  }}
                                >
                                  {p.secondaryPhone == 0
                                    ? " "
                                    : p.secondaryPhone}
                                </div>
                              </td>
                              <td>
                                <div
                                  style={{
                                    width: "90px",
                                    wordWrap: "break-word",
                                    display: "inline-block",
                                  }}
                                >
                                  {p.city}
                                </div>
                                <br />
                                <div
                                  style={{
                                    width: "90px",
                                    wordWrap: "break-word",
                                    display: "inline-block",
                                  }}
                                >
                                  {p.country}
                                </div>
                              </td>
                              <td>
                                <div
                                  style={{
                                    width: "80px",
                                    wordWrap: "break-word",
                                    display: "inline-block",
                                  }}
                                >
                                  {p.clientSource}
                                </div>
                              </td>
                              {/* <td>{p?.clientDetail?.phone}</td> */}

                              <td>
                                <div
                                  style={{
                                    width: "90px",
                                    wordWrap: "break-word",
                                    display: "inline-block",
                                  }}
                                >
                                  {p.officeName}
                                </div>
                                <br />
                                <div
                                  style={{
                                    width: "90px",
                                    wordWrap: "break-word",
                                    display: "inline-block",
                                  }}
                                >
                                  {p.officeCountry}
                                </div>
                              </td>
                              <td>
                                <>
                                  <div class="s_tooltip">
                                    {p.consultantName != null
                                      ? p.consultantName.split(" ")[0]
                                      : ""}
                                    <span class="s_tooltiptext">
                                      {p.consultantEmail}
                                    </span>
                                  </div>
                                </>
                              </td>
                              <td>
                                {p.visaExpiry
                                  ? moment(p.visaExpiry.split("T")[0]).format(
                                      "DD-MMM-YY"
                                    )
                                  : null}
                              </td>
                              <td>
                                <div
                                  style={{
                                    width: "115px",
                                    wordWrap: "break-word",
                                    display: "inline-block",
                                  }}
                                >
                                  {p.subAgent}
                                </div>
                                {/* <br />
                        <div style={{width:"130px", wordWrap:"break-word",display:"inline-block"}}>{p.subAgent?.split(" (")[0]}</div> */}
                              </td>
                              <td>
                                <div
                                  style={{
                                    width: "115px",
                                    wordWrap: "break-word",
                                    display: "inline-block",
                                  }}
                                >
                                  {p.superAgent}
                                </div>
                                {/* <br />
                        <div style={{width:"130px", wordWrap:"break-word",display:"inline-block"}}>{p.superAgent?.split(" (")[0]}</div> */}
                              </td>
                              {/* <td>
                                <div style={{ width: "100px", wordWrap: "break-word", display: "inline-block" }} >{p.consultantName}</div>
                                <br />
                                <div style={{ width: "100px", wordWrap: "break-word", display: "inline-block" }} >{p.consultantEmail}</div>
                              </td> */}
                              {/* {p.status === 1 ? <td>Active</td> : <td>InActive</td>} */}
                              <td>
                                {p.lastUpdated
                                  ? moment(p.lastUpdated.split("T")[0]).format(
                                      "DD-MMM-YY"
                                    )
                                  : null}
                              </td>
                              <td>
                                <ClientViewMoreModal
                                  p={p}
                                  id={p.id}
                                  handle403error={handle403error}
                                  sortState={sortState}
                                  direction={direction}
                                  pageno={pageno}
                                  rows={rows}
                                  allclientdetails={allclientdetails}
                                  statusstate={statusstate}
                                />
                              </td>
                              {/* <td><div style={{ width: "120px", wordWrap: "break-word", display: "inline-block" }}>{p.createdBy}</div></td> */}
                              <td>
                                <>
                                  <div class="s_tooltip">
                                    {p.createdBy != null
                                      ? p.createdBy.split(" ")[0]
                                      : ""}
                                    <span class="s_tooltiptext">
                                      {p.createdBy}
                                    </span>
                                  </div>
                                </>
                              </td>
                              <Modal
                                isOpen={remarkmodelopen}
                                onRequestClose={() => setremarkmodelopen(false)}
                                style={customStyles}
                                contentLabel="Example Modal"
                              >
                                <h4>Remark</h4>
                                {remarkcontent ? (
                                  <textarea
                                    style={{ minWidth: "600px" }}
                                    className="form-control"
                                    value={remarkcontent}
                                    rows={10}
                                    readonly
                                  />
                                ) : (
                                  <p> No Record Available</p>
                                )}
                                <div
                                  className="s_newdiv"
                                  style={{
                                    display: "flex",
                                    margin: "10px 0",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  <Button
                                    color="secondary"
                                    onClick={() => setremarkmodelopen(false)}
                                  >
                                    Cancel
                                  </Button>
                                </div>
                              </Modal>

                              <td id={p.id}>
                                {Number(user?.isw_user_role) === 1 ||
                                Number(user?.isw_user_role) === 4 ||
                                Number(user?.isw_user_role) === 5 ||
                                Number(user?.isw_user_role) === 6 ||
                                Number(user?.isw_user_role) === 10 ? (
                                  <div
                                    style={{
                                      position: "relative",
                                      textAlign: "center",
                                    }}
                                  >
                                    <div
                                      className="workflowdashboard_fa-ellipsis"
                                      data-toggle="dropdown"
                                      style={{ width: "50px" }}
                                    >
                                      <i
                                        className="fa fa-ellipsis-v "
                                        aria-hidden="true"
                                      ></i>
                                    </div>
                                    <div
                                      // class="s_dropname"
                                      className="dropdown-menu sang_workflowdashboarddropdown"
                                    >
                                      <ul
                                        style={{ margin: "0px" }}
                                        className="ulclass"
                                      >
                                        {check1pointpermission(
                                          303,
                                          permissionData,
                                          rolepermission
                                        ) ? (
                                          <li>
                                            <p>
                                              {/* <Link
                                                        id={p.username}
                                                        onClick={(e) => viewClient(e, p)}
                                                        to={`/client-view/` + p.username}
                                                      >
                                                        Edit
                                                      </Link> */}
                                              <Link
                                                id={p.username}
                                                onClick={(e) =>
                                                  viewClient(e, p)
                                                }
                                                to={
                                                  `/edit-client/` + p.username
                                                }
                                                title="Edit"
                                                className="aclass"
                                              >
                                                Edit
                                              </Link>
                                            </p>
                                          </li>
                                        ) : null}
                                        {check1pointpermission(
                                          342,
                                          permissionData,
                                          rolepermission
                                        ) ? (
                                          <li>
                                            <p>
                                              {/* {user.isw_user_role === 1 || user.isw_user_role === 6 ? ( */}
                                              <ClientEmailModal
                                                username={p.username}
                                                inputtype={inputtype}
                                                chkPassState={chkPassState}
                                                setchkPassState={
                                                  setchkPassState
                                                }
                                                setinputtype={setinputtype}
                                                jwtToken={jwtToken}
                                                toast={toast}
                                                isw_user_name={isw_user_name}
                                                allclientdetails={
                                                  allclientdetails
                                                }
                                                statusstate={statusstate}
                                                pageno={pageno}
                                              />
                                              {/* ) : null} */}
                                            </p>
                                          </li>
                                        ) : null}
                                        {check1pointpermission(
                                          305,
                                          permissionData,
                                          rolepermission
                                        ) ? (
                                          <li>
                                            <p>
                                              {p.status === 0 ? (
                                                // <Link
                                                //   id={p.username}
                                                //   onClick={() => {
                                                //     // deleteUser(p.status, p.username)
                                                //     setclientstatus(p.status);
                                                //     setclientstatususername(p.username);
                                                //     setchangestatusmodal(true);
                                                //   }}
                                                // >
                                                //   Unarchive
                                                // </Link>
                                                // <a
                                                //   id={p.username}
                                                //   style={{ cursor: "pointer" }}
                                                //   onClick={() => {
                                                //     // deleteUser(p.status, p.username)
                                                //     setclientstatus(p.status);
                                                //     setclientstatususername(p.username);
                                                //     setchangestatusmodal(true);
                                                //   }}
                                                //   title="Unarchive"
                                                // >
                                                //   <i class="icon">
                                                //     <svg
                                                //       width="18"
                                                //       height="18"
                                                //       viewBox="0 0 18 18"
                                                //       fill="#17B978"
                                                //     >
                                                //       <path
                                                //         d="M5.69629 9.6289V18.126C5.69629 18.4173 5.93906 18.6439 6.21421 18.6439H17.7703C18.0616 18.6439 18.2882 18.4012 18.2882 18.126V9.6289H5.69629ZM14.6142 12.8821C14.6142 13.1734 14.3714 13.4 14.0963 13.4H9.8882C9.59687 13.4 9.37028 13.1572 9.37028 12.8821V11.8301C9.37028 11.5387 9.61305 11.3121 9.8882 11.3121H14.0963C14.3876 11.3121 14.6142 11.5549 14.6142 11.8301V12.8821Z"
                                                //         fill="#17B978"
                                                //       />
                                                //       <path
                                                //         d="M18.4659 5.35607H5.51792C5.22659 5.35607 5 5.59885 5 5.87399V8.93295H19V5.89018C18.9838 5.59885 18.7572 5.35607 18.4659 5.35607Z"
                                                //         fill="#17B978"
                                                //       />
                                                //     </svg>
                                                //   </i>
                                                // </a>
                                                <ArchiveModal
                                                  status={p.status}
                                                  username={p.username}
                                                  jwtToken={jwtToken}
                                                  handle403error={
                                                    handle403error
                                                  }
                                                  allclientdetails={
                                                    allclientdetails
                                                  }
                                                  statusstate={statusstate}
                                                  pageno={pageno}
                                                />
                                              ) : (
                                                // <Link
                                                //   id={p.username}
                                                //   onClick={() => {
                                                //     // deleteUser(p.status, p.username)
                                                //     setclientstatus(p.status);
                                                //     setclientstatususername(p.username);
                                                //     setchangestatusmodal(true);
                                                //   }}
                                                // >
                                                //   Archive
                                                // </Link>
                                                // <a
                                                //   id={p.username}
                                                //   style={{ cursor: "pointer" }}
                                                //   onClick={() => {
                                                //     // deleteUser(p.status, p.username)
                                                //     setclientstatus(p.status);
                                                //     setclientstatususername(p.username);
                                                //     setchangestatusmodal(true);
                                                //   }}
                                                //   title="Archive"
                                                // >
                                                //   <i class="icon">
                                                //     <svg
                                                //       width="18"
                                                //       height="18"
                                                //       viewBox="0 0 18 18"
                                                //       fill="#17B978"
                                                //     >
                                                //       <path
                                                //         d="M5.69629 9.6289V18.126C5.69629 18.4173 5.93906 18.6439 6.21421 18.6439H17.7703C18.0616 18.6439 18.2882 18.4012 18.2882 18.126V9.6289H5.69629ZM14.6142 12.8821C14.6142 13.1734 14.3714 13.4 14.0963 13.4H9.8882C9.59687 13.4 9.37028 13.1572 9.37028 12.8821V11.8301C9.37028 11.5387 9.61305 11.3121 9.8882 11.3121H14.0963C14.3876 11.3121 14.6142 11.5549 14.6142 11.8301V12.8821Z"
                                                //         fill="#17B978"
                                                //       />
                                                //       <path
                                                //         d="M18.4659 5.35607H5.51792C5.22659 5.35607 5 5.59885 5 5.87399V8.93295H19V5.89018C18.9838 5.59885 18.7572 5.35607 18.4659 5.35607Z"
                                                //         fill="#17B978"
                                                //       />
                                                //     </svg>
                                                //   </i>
                                                // </a>
                                                <ArchiveModal
                                                  status={p.status}
                                                  username={p.username}
                                                  jwtToken={jwtToken}
                                                  handle403error={
                                                    handle403error
                                                  }
                                                  allclientdetails={
                                                    allclientdetails
                                                  }
                                                  statusstate={statusstate}
                                                  pageno={pageno}
                                                />
                                              )}
                                            </p>
                                          </li>
                                        ) : null}
                                      </ul>
                                    </div>
                                  </div>
                                ) : null}

                                <Modal
                                  isOpen={changestatusmodal}
                                  onRequestClose={() =>
                                    setchangestatusmodal(false)
                                  }
                                  style={customStyles}
                                  contentLabel="Example Modal"
                                >
                                  <h5>
                                    Are you sure you want to{" "}
                                    {clientstatus === 1
                                      ? "Archive"
                                      : "Unarchive"}{" "}
                                    current record ?{" "}
                                  </h5>
                                  <div style={{ float: "right" }}>
                                    <Button
                                      class="s_danger"
                                      color="danger"
                                      onClick={deleteUser}
                                    >
                                      Yes
                                    </Button>
                                    &nbsp;&nbsp;&nbsp;
                                    <Button
                                      className="s_danger"
                                      color="secondary"
                                      onClick={() =>
                                        setchangestatusmodal(false)
                                      }
                                    >
                                      No
                                    </Button>
                                  </div>
                                </Modal>
                                <Modal
                                  isOpen={modalIsOpen2}
                                  onRequestClose={closeModal}
                                  style={customStyles}
                                  contentLabel="Example Modal"
                                >
                                  <form
                                    onSubmit={(e) => {
                                      e.preventDefault();
                                      submitNewPassword(e);
                                    }}
                                  >
                                    <div className="form-inner-admin">
                                      <h3 style={{ color: "black" }}>
                                        Update Password
                                      </h3>
                                      <div className="form-group creatadmin">
                                        <Row>
                                          <Col>
                                            <label>User Name:</label>
                                            <input
                                              type="text"
                                              className="form-control underline-input"
                                              value={selectedclient}
                                              readOnly
                                              required
                                            />
                                          </Col>
                                        </Row>
                                      </div>
                                      <div className="form-group creatadmin">
                                        <Row>
                                          <Col>
                                            <label>Password:</label>
                                            <div
                                              style={{ position: "relative" }}
                                            >
                                              <input
                                                type={inputtype}
                                                className="form-control underline-input"
                                                value={chkPassState}
                                                onChange={(e) =>
                                                  setchkPassState(
                                                    e.target.value
                                                  )
                                                }
                                                required
                                              />
                                              {inputtype === "password" ? (
                                                <i
                                                  className="fa fa-eye-slash fonticonpositioning"
                                                  onClick={() =>
                                                    setinputtype("text")
                                                  }
                                                  aria-hidden="true"
                                                ></i>
                                              ) : (
                                                <i
                                                  className="fa fa-eye fonticonpositioning"
                                                  onClick={() =>
                                                    setinputtype("password")
                                                  }
                                                  aria-hidden="true"
                                                ></i>
                                              )}
                                            </div>
                                            <PasswordStrengthBar
                                              password={chkPassState}
                                            />
                                          </Col>
                                        </Row>
                                      </div>
                                      <div className="form-group creatadmin">
                                        <Row className="justify-content-end">
                                          <div>
                                            <Button
                                              type="submit"
                                              className="btn listing_addbutton"
                                            >
                                              Update
                                            </Button>
                                          </div>
                                          <div>
                                            <Button
                                              color="secondary"
                                              onClick={closeModal}
                                            >
                                              Cancel
                                            </Button>
                                          </div>
                                        </Row>
                                      </div>
                                    </div>
                                  </form>
                                </Modal>
                                <Modal
                                  isOpen={modalIsOpen}
                                  onAfterOpen={afterOpenModal}
                                  onRequestClose={closeModal}
                                  style={customStyles}
                                  contentLabel="Example Modal"
                                >
                                  <h2
                                    ref={(_subtitle) => (subtitle = _subtitle)}
                                  >
                                    Change Status ?
                                  </h2>
                                  {p.status === 0 ? (
                                    <Button color="danger" onClick={deleteUser}>
                                      Unarchive
                                    </Button>
                                  ) : (
                                    <Button color="danger" onClick={deleteUser}>
                                      Archive
                                    </Button>
                                  )}
                                  &nbsp;&nbsp;&nbsp;
                                  <Button
                                    color="secondary"
                                    onClick={closeModal}
                                  >
                                    Cancel
                                  </Button>
                                </Modal>
                              </td>
                            </tr>
                          ) : (
                            <>
                              {client_users != null ? (
                                <tr key={index + 1} className="s_inactive">
                                  <td
                                    style={{
                                      textAlign: "center",
                                      width: "50px",
                                    }}
                                  >
                                    {countnumber * rows + 1 + index}
                                  </td>
                                  <td>
                                    {p.addedOn
                                      ? moment(p.addedOn.split("T")[0]).format(
                                          "DD-MMM-YY"
                                        )
                                      : null}
                                  </td>
                                  {/* <td>
                                        {p.enquiryLeadType}
                                        <i class="icon">
                                          <svg width="18" height="18" viewBox="0 0 18 18" fill="#17B978" >
                                            <path d="M13.5 2.25H4.5C4.00755 2.25 3.51991 2.347 3.06494 2.53545C2.60997 2.72391 2.19657 3.00013 1.84835 3.34835C1.14509 4.05161 0.75 5.00544 0.75 6V12C0.75 12.9946 1.14509 13.9484 1.84835 14.6516C2.19657 14.9999 2.60997 15.2761 3.06494 15.4645C3.51991 15.653 4.00755 15.75 4.5 15.75H13.5C13.9925 15.75 14.4801 15.653 14.9351 15.4645C15.39 15.2761 15.8034 14.9999 16.1517 14.6516C16.4999 14.3034 16.7761 13.89 16.9646 13.4351C17.153 12.9801 17.25 12.4925 17.25 12V6C17.25 5.50754 17.153 5.01991 16.9646 4.56494C16.7761 4.10996 16.4999 3.69657 16.1517 3.34835C15.8034 3.00013 15.39 2.72391 14.9351 2.53545C14.4801 2.347 13.9925 2.25 13.5 2.25ZM13.9125 6.6225L9.41251 9.6225C9.29005 9.70314 9.14664 9.74612 9.00001 9.74612C8.85338 9.74612 8.70997 9.70314 8.58751 9.6225L4.0875 6.6225C3.99554 6.57317 3.91489 6.50519 3.8507 6.42291C3.78651 6.34064 3.74019 6.24587 3.71472 6.14467C3.68924 6.04347 3.68516 5.93807 3.70275 5.83521C3.72033 5.73235 3.75919 5.63428 3.81683 5.54729C3.87447 5.4603 3.94964 5.3863 4.03751 5.33002C4.12539 5.27373 4.22404 5.23641 4.32717 5.22043C4.43029 5.20444 4.53562 5.21016 4.63641 5.23721C4.7372 5.26425 4.83123 5.31204 4.9125 5.3775L9.00001 8.1L13.0875 5.3775C13.1688 5.31204 13.2628 5.26425 13.3636 5.23721C13.4644 5.21016 13.5697 5.20444 13.6728 5.22043C13.776 5.23641 13.8746 5.27373 13.9625 5.33002C14.0504 5.3863 14.1255 5.4603 14.1832 5.54729C14.2408 5.63428 14.2797 5.73235 14.2973 5.83521C14.3148 5.93807 14.3108 6.04347 14.2853 6.14467C14.2598 6.24587 14.2135 6.34064 14.1493 6.42291C14.0851 6.50519 14.0045 6.57317 13.9125 6.6225Z" />
                                          </svg>
                                        </i>
                                      </td> */}
                                  <td>
                                    {check1pointpermission(
                                      306,
                                      permissionData,
                                      rolepermission
                                    ) ? (
                                      <Link
                                        className="greeneffect"
                                        id={p.username}
                                        onClick={(e) => viewEnquiry(p, e)}
                                        style={{
                                          width: "120px",
                                          wordWrap: "break-word",
                                          display: "inline-block",
                                        }}
                                        to={
                                          `/view-enquiry-workflowList/` +
                                          p.id +
                                          `/` +
                                          p.email
                                        }
                                      >
                                        {p.firstName +
                                          " " +
                                          p.middleName +
                                          " " +
                                          p.lastName}
                                      </Link>
                                    ) : (
                                      <div
                                        style={{
                                          width: "120px",
                                          wordWrap: "break-word",
                                          display: "inline-block",
                                        }}
                                      >
                                        {p.firstName +
                                          " " +
                                          p.middleName +
                                          " " +
                                          p.lastName}
                                      </div>
                                    )}
                                    <br />
                                    <div
                                      style={{
                                        width: "120px",
                                        wordWrap: "break-word",
                                        display: "inline-block",
                                      }}
                                    >
                                      {p.email}
                                    </div>
                                  </td>
                                  <td>
                                    <div
                                      style={{
                                        width: "90px",
                                        wordWrap: "break-word",
                                        display: "inline-block",
                                      }}
                                    >
                                      {p.phone == 0 ? " " : p.phone}
                                    </div>
                                    <br />
                                    <div
                                      style={{
                                        width: "90px",
                                        wordWrap: "break-word",
                                        display: "inline-block",
                                      }}
                                    >
                                      {p.secondaryPhone == 0
                                        ? " "
                                        : p.secondaryPhone}
                                    </div>
                                  </td>
                                  <td>
                                    <div
                                      style={{
                                        width: "90px",
                                        wordWrap: "break-word",
                                        display: "inline-block",
                                      }}
                                    >
                                      {p.city}
                                    </div>
                                    <br />
                                    <div
                                      style={{
                                        width: "90px",
                                        wordWrap: "break-word",
                                        display: "inline-block",
                                      }}
                                    >
                                      {p.country}
                                    </div>
                                  </td>
                                  <td>{p.clientSource}</td>
                                  {/* <td>{p?.clientDetail?.phone}</td> */}

                                  <td>
                                    <div
                                      style={{
                                        width: "90px",
                                        wordWrap: "break-word",
                                        display: "inline-block",
                                      }}
                                    >
                                      {p.officeName}
                                    </div>
                                    <br />
                                    <div
                                      style={{
                                        width: "90px",
                                        wordWrap: "break-word",
                                        display: "inline-block",
                                      }}
                                    >
                                      {p.officeCountry}
                                    </div>
                                  </td>
                                  <td>
                                    <>
                                      <div class="s_tooltip">
                                        {p.consultantName != null
                                          ? p.consultantName.split(" ")[0]
                                          : ""}
                                        <span class="s_tooltiptext">
                                          {p.consultantEmail}
                                        </span>
                                      </div>
                                    </>
                                  </td>
                                  <td>
                                    {p.visaExpiry
                                      ? moment(
                                          p.visaExpiry.split("T")[0]
                                        ).format("DD-MMM-YY")
                                      : null}
                                  </td>
                                  <td>
                                    <div
                                      style={{
                                        width: "115px",
                                        wordWrap: "break-word",
                                        display: "inline-block",
                                      }}
                                    >
                                      {p.subAgent}
                                    </div>
                                    {/* <br />
                        <div style={{width:"130px", wordWrap:"break-word",display:"inline-block"}}>{p.subAgent?.split(" (")[0]}</div> */}
                                  </td>
                                  <td>
                                    <div
                                      style={{
                                        width: "115px",
                                        wordWrap: "break-word",
                                        display: "inline-block",
                                      }}
                                    >
                                      {p.superAgent}
                                    </div>
                                    {/* <br />
                        <div style={{width:"130px", wordWrap:"break-word",display:"inline-block"}}>{p.superAgent?.split(" (")[0]}</div> */}
                                  </td>
                                  {/* <td>
                                    <div style={{ width: "100px", wordWrap: "break-word", display: "inline-block" }} >{p.consultantName}</div>
                                    <br />
                                    <div style={{ width: "100px", wordWrap: "break-word", display: "inline-block" }} >{p.consultantEmail}</div>
                                  </td> */}
                                  {/* {p.status === 1 ? <td>Active</td> : <td>InActive</td>} */}
                                  <td>
                                    {p.lastUpdated
                                      ? moment(
                                          p.lastUpdated.split("T")[0]
                                        ).format("DD-MMM-YY")
                                      : null}
                                  </td>
                                  <td>
                                    <>
                                      {p.clientDetail?.comment}
                                      {p.clientDetail?.comment != "" ? (
                                        // <span
                                        //   className="leadellipsisspan"
                                        //   onClick={() => {
                                        //     setremarkmodelopen(true);
                                        //     setremarkcontent(p.clientDetail.comment);
                                        //   }}
                                        // >
                                        //   View More
                                        // </span>
                                        // <button
                                        //   // className="leadellipsisspan"
                                        //   className="role-deletebtn"
                                        //   onClick={() => {
                                        //     setremarkmodelopen(true);
                                        //     setremarkcontent(p.clientDetail.comment);
                                        //   }}
                                        // >
                                        //   View More
                                        // </button>
                                        <ClientViewMoreModal p={p} />
                                      ) : null}
                                    </>
                                  </td>
                                  <>
                                    <td>
                                      <>
                                        <div class="s_tooltip">
                                          {p.createdBy != null
                                            ? p.createdBy.split(" ")[0]
                                            : ""}
                                          <span class="s_tooltiptext">
                                            {p.createdBy}
                                          </span>
                                        </div>
                                      </>
                                    </td>
                                    {/* <td><div style={{ width: "120px", wordWrap: "break-word", display: "inline-block" }}>{p.createdBy}</div></td> */}
                                  </>
                                  <Modal
                                    isOpen={remarkmodelopen}
                                    onRequestClose={() =>
                                      setremarkmodelopen(false)
                                    }
                                    style={customStyles}
                                    contentLabel="Example Modal"
                                  >
                                    <h4>Remark</h4>
                                    {remarkcontent ? (
                                      <textarea
                                        style={{ minWidth: "600px" }}
                                        className="form-control"
                                        value={remarkcontent}
                                        rows={10}
                                        readonly
                                      />
                                    ) : (
                                      <p> No Record Available</p>
                                    )}
                                    <div
                                      className="s_newdiv"
                                      style={{
                                        display: "flex",
                                        margin: "10px 0",
                                        justifyContent: "flex-end",
                                      }}
                                    >
                                      <Button
                                        color="secondary"
                                        onClick={() =>
                                          setremarkmodelopen(false)
                                        }
                                      >
                                        Cancel
                                      </Button>
                                    </div>
                                  </Modal>

                                  {Number(user?.isw_user_role) === 1 ||
                                  Number(user?.isw_user_role) === 4 ||
                                  Number(user?.isw_user_role) === 5 ||
                                  Number(user?.isw_user_role) === 6 ||
                                  Number(user?.isw_user_role) === 10 ? (
                                    <td id={p.id}>
                                      <div
                                        style={{
                                          position: "relative",
                                          textAlign: "center",
                                        }}
                                      >
                                        <div
                                          className="workflowdashboard_fa-ellipsis"
                                          data-toggle="dropdown"
                                          style={{ width: "50px" }}
                                        >
                                          <i
                                            className="fa fa-ellipsis-v "
                                            aria-hidden="true"
                                          ></i>
                                        </div>
                                        <div
                                          //class="s_dropname"
                                          className="dropdown-menu sang_workflowdashboarddropdown"
                                        >
                                          <ul
                                            style={{ margin: "0px" }}
                                            className="ulclass"
                                          >
                                            {check1pointpermission(
                                              303,
                                              permissionData,
                                              rolepermission
                                            ) ? (
                                              <li>
                                                <p>
                                                  {/* <Link
                                                      id={p.username}
                                                      onClick={(e) => viewClient(e, p)}
                                                      to={`/client-view/` + p.username}
                                                    >
                                                      Edit
                                                    </Link> */}
                                                  <Link
                                                    id={p.username}
                                                    onClick={(e) =>
                                                      viewClient(e, p)
                                                    }
                                                    to={
                                                      `/edit-client/` +
                                                      p.username
                                                    }
                                                    title="Edit"
                                                    className="aclass"
                                                  >
                                                    Edit
                                                  </Link>
                                                </p>
                                              </li>
                                            ) : null}

                                            {check1pointpermission(
                                              342,
                                              permissionData,
                                              rolepermission
                                            ) ? (
                                              <li>
                                                <p>
                                                  {/* {user.isw_user_role === 1 || user.isw_user_role === 6 ? ( */}
                                                  <ClientEmailModal
                                                    username={p.username}
                                                    inputtype={inputtype}
                                                    chkPassState={chkPassState}
                                                    setchkPassState={
                                                      setchkPassState
                                                    }
                                                    setinputtype={setinputtype}
                                                    jwtToken={jwtToken}
                                                    toast={toast}
                                                    isw_user_name={
                                                      isw_user_name
                                                    }
                                                    allclientdetails={
                                                      allclientdetails
                                                    }
                                                    statusstate={statusstate}
                                                    pageno={pageno}
                                                  />
                                                  {/* ) : null} */}
                                                </p>
                                              </li>
                                            ) : null}
                                            {check1pointpermission(
                                              305,
                                              permissionData,
                                              rolepermission
                                            ) ? (
                                              <li>
                                                <p>
                                                  {p.status === 0 ? (
                                                    // <Link
                                                    //   id={p.username}
                                                    //   onClick={() => {
                                                    //     // deleteUser(p.status, p.username)
                                                    //     setclientstatus(p.status);
                                                    //     setclientstatususername(p.username);
                                                    //     setchangestatusmodal(true);
                                                    //   }}
                                                    // >
                                                    //   Unarchive
                                                    // </Link>
                                                    // <a
                                                    //   id={p.username}
                                                    //   style={{ cursor: "pointer" }}
                                                    //   onClick={() => {
                                                    //     // deleteUser(p.status, p.username)
                                                    //     setclientstatus(p.status);
                                                    //     setclientstatususername(p.username);
                                                    //     setchangestatusmodal(true);
                                                    //   }}
                                                    //   title="Unarchive"
                                                    // >
                                                    //   <i class="icon">
                                                    //     <svg
                                                    //       width="18"
                                                    //       height="18"
                                                    //       viewBox="0 0 18 18"
                                                    //       fill="#17B978"
                                                    //     >
                                                    //       <path
                                                    //         d="M5.69629 9.6289V18.126C5.69629 18.4173 5.93906 18.6439 6.21421 18.6439H17.7703C18.0616 18.6439 18.2882 18.4012 18.2882 18.126V9.6289H5.69629ZM14.6142 12.8821C14.6142 13.1734 14.3714 13.4 14.0963 13.4H9.8882C9.59687 13.4 9.37028 13.1572 9.37028 12.8821V11.8301C9.37028 11.5387 9.61305 11.3121 9.8882 11.3121H14.0963C14.3876 11.3121 14.6142 11.5549 14.6142 11.8301V12.8821Z"
                                                    //         fill="#17B978"
                                                    //       />
                                                    //       <path
                                                    //         d="M18.4659 5.35607H5.51792C5.22659 5.35607 5 5.59885 5 5.87399V8.93295H19V5.89018C18.9838 5.59885 18.7572 5.35607 18.4659 5.35607Z"
                                                    //         fill="#17B978"
                                                    //       />
                                                    //     </svg>
                                                    //   </i>
                                                    // </a>
                                                    <ArchiveModal
                                                      status={p.status}
                                                      username={p.username}
                                                      jwtToken={jwtToken}
                                                      handle403error={
                                                        handle403error
                                                      }
                                                      allclientdetails={
                                                        allclientdetails
                                                      }
                                                      statusstate={statusstate}
                                                      pageno={pageno}
                                                    />
                                                  ) : (
                                                    // <Link
                                                    //   id={p.username}
                                                    //   onClick={() => {
                                                    //     // deleteUser(p.status, p.username)
                                                    //     setclientstatus(p.status);
                                                    //     setclientstatususername(p.username);
                                                    //     setchangestatusmodal(true);
                                                    //   }}
                                                    // >
                                                    //   Archive
                                                    // </Link>
                                                    // <a
                                                    //   id={p.username}
                                                    //   style={{ cursor: "pointer" }}
                                                    //   onClick={() => {
                                                    //     // deleteUser(p.status, p.username)
                                                    //     setclientstatus(p.status);
                                                    //     setclientstatususername(p.username);
                                                    //     setchangestatusmodal(true);
                                                    //   }}
                                                    //   title="Archive"
                                                    // >
                                                    //   <i class="icon">
                                                    //     <svg
                                                    //       width="18"
                                                    //       height="18"
                                                    //       viewBox="0 0 18 18"
                                                    //       fill="#17B978"
                                                    //     >
                                                    //       <path
                                                    //         d="M5.69629 9.6289V18.126C5.69629 18.4173 5.93906 18.6439 6.21421 18.6439H17.7703C18.0616 18.6439 18.2882 18.4012 18.2882 18.126V9.6289H5.69629ZM14.6142 12.8821C14.6142 13.1734 14.3714 13.4 14.0963 13.4H9.8882C9.59687 13.4 9.37028 13.1572 9.37028 12.8821V11.8301C9.37028 11.5387 9.61305 11.3121 9.8882 11.3121H14.0963C14.3876 11.3121 14.6142 11.5549 14.6142 11.8301V12.8821Z"
                                                    //         fill="#17B978"
                                                    //       />
                                                    //       <path
                                                    //         d="M18.4659 5.35607H5.51792C5.22659 5.35607 5 5.59885 5 5.87399V8.93295H19V5.89018C18.9838 5.59885 18.7572 5.35607 18.4659 5.35607Z"
                                                    //         fill="#17B978"
                                                    //       />
                                                    //     </svg>
                                                    //   </i>
                                                    // </a>
                                                    <ArchiveModal
                                                      status={p.status}
                                                      username={p.username}
                                                      jwtToken={jwtToken}
                                                      handle403error={
                                                        handle403error
                                                      }
                                                      allclientdetails={
                                                        allclientdetails
                                                      }
                                                      statusstate={statusstate}
                                                      pageno={pageno}
                                                    />
                                                  )}
                                                </p>
                                              </li>
                                            ) : null}
                                          </ul>
                                        </div>
                                      </div>
                                    </td>
                                  ) : null}
                                  <Modal
                                    isOpen={changestatusmodal}
                                    onRequestClose={() =>
                                      setchangestatusmodal(false)
                                    }
                                    style={customStyles}
                                    contentLabel="Example Modal"
                                  >
                                    <h5>
                                      Are you sure you want to{" "}
                                      {clientstatus === 1
                                        ? "Archive"
                                        : "Unarchive"}{" "}
                                      current record ?{" "}
                                    </h5>
                                    <div style={{ float: "right" }}>
                                      <Button
                                        class="s_danger"
                                        color="danger"
                                        onClick={deleteUser}
                                      >
                                        Yes
                                      </Button>
                                      &nbsp;&nbsp;&nbsp;
                                      <Button
                                        className="s_danger"
                                        color="secondary"
                                        onClick={() =>
                                          setchangestatusmodal(false)
                                        }
                                      >
                                        No
                                      </Button>
                                    </div>
                                  </Modal>
                                  <Modal
                                    isOpen={modalIsOpen2}
                                    onRequestClose={closeModal}
                                    style={customStyles}
                                    contentLabel="Example Modal"
                                  >
                                    <form
                                      onSubmit={(e) => {
                                        e.preventDefault();
                                        submitNewPassword(e);
                                      }}
                                    >
                                      <div className="form-inner-admin">
                                        <h3 style={{ color: "black" }}>
                                          Update Password
                                        </h3>
                                        <div className="form-group creatadmin">
                                          <Row>
                                            <Col>
                                              <label>User Name:</label>
                                              <input
                                                type="text"
                                                className="form-control underline-input"
                                                value={selectedclient}
                                                readOnly
                                                required
                                              />
                                            </Col>
                                          </Row>
                                        </div>
                                        <div className="form-group creatadmin">
                                          <Row>
                                            <Col>
                                              <label>Password:</label>
                                              <div
                                                style={{ position: "relative" }}
                                              >
                                                <input
                                                  type={inputtype}
                                                  className="form-control underline-input"
                                                  value={chkPassState}
                                                  onChange={(e) =>
                                                    setchkPassState(
                                                      e.target.value
                                                    )
                                                  }
                                                  required
                                                />
                                                {inputtype === "password" ? (
                                                  <i
                                                    className="fa fa-eye-slash fonticonpositioning"
                                                    onClick={() =>
                                                      setinputtype("text")
                                                    }
                                                    aria-hidden="true"
                                                  ></i>
                                                ) : (
                                                  <i
                                                    className="fa fa-eye fonticonpositioning"
                                                    onClick={() =>
                                                      setinputtype("password")
                                                    }
                                                    aria-hidden="true"
                                                  ></i>
                                                )}
                                              </div>
                                              <PasswordStrengthBar
                                                password={chkPassState}
                                              />
                                            </Col>
                                          </Row>
                                        </div>
                                        <div className="form-group creatadmin">
                                          <Row className="justify-content-end">
                                            <div>
                                              <Button
                                                type="submit"
                                                className="btn listing_addbutton"
                                              >
                                                Update
                                              </Button>
                                            </div>
                                            <div>
                                              <Button
                                                color="secondary"
                                                onClick={closeModal}
                                              >
                                                Cancel
                                              </Button>
                                            </div>
                                          </Row>
                                        </div>
                                      </div>
                                    </form>
                                  </Modal>
                                  <Modal
                                    isOpen={modalIsOpen}
                                    onAfterOpen={afterOpenModal}
                                    onRequestClose={closeModal}
                                    style={customStyles}
                                    contentLabel="Example Modal"
                                  >
                                    <h2
                                      ref={(_subtitle) =>
                                        (subtitle = _subtitle)
                                      }
                                    >
                                      Change Status ?
                                    </h2>
                                    {p.status === 0 ? (
                                      <Button
                                        color="danger"
                                        onClick={deleteUser}
                                      >
                                        Unarchive
                                      </Button>
                                    ) : (
                                      <Button
                                        color="danger"
                                        onClick={deleteUser}
                                      >
                                        Archive
                                      </Button>
                                    )}
                                    &nbsp;&nbsp;&nbsp;
                                    <Button
                                      color="secondary"
                                      onClick={closeModal}
                                    >
                                      Cancel
                                    </Button>
                                  </Modal>
                                </tr>
                              ) : (
                                <p className={classes.headertekst}>{null}</p>
                              )}
                            </>
                          )}
                        </>
                      ))
                    ) : (
                      <p className={classes.headertekst}>{null}</p>
                    )}
                  </>
                </tbody>
              </>
              {/* )} */}
            </Table>
            {/* </div> */}
          </div>
          <Row className="s_pagirow">
            <Col md={6}>
              <div>
                <form>
                  <div style={{ float: "left", clear: "both" }}>
                    <FormControl
                      variant="standard"
                      style={{
                        display: "inline-block",
                        marginRight: "25px",
                        minWidth: "50px",
                      }}
                    >
                      <select
                        className={classes.selectlimit}
                        labelId="simple-select-label"
                        id="simple-select"
                        value={rows}
                        onChange={handleRows}
                      >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                    </FormControl>
                    {client_users?.length ? (
                      <div
                        className="s_spanPage"
                        style={{ display: "inline-block" }}
                      >
                        {countnumber * rows + 1} to{" "}
                        {countnumber * rows + numberofElements} of {totalUser}{" "}
                        records
                      </div>
                    ) : (
                      <span
                        className="s_spanPage"
                        style={{ display: "inline-block" }}
                      >
                        No Record Found
                      </span>
                    )}
                  </div>
                </form>
              </div>
            </Col>
            <Col md={6}>
              <div className={classes.root} style={{ float: "right" }}>
                {/* {searchdata || datefilter ? (
                  <Pagination
                    page={paginationpageno}
                    count={getpageno}
                    onChange={handlesearch}
                    shape="rounded"
                  />
                ) : ( */}
                <Pagination
                  page={paginationpageno}
                  count={getpageno}
                  onChange={handlepage}
                  shape="rounded"
                />
                {/* )}{" "} */}
              </div>
            </Col>
          </Row>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
}

export default ProspectList;
