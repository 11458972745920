import React, { useState, useEffect } from "react";
import { Card, CardText, Col, Row, Button } from "reactstrap";
import axios from "axios";
import base_url from "./../../api/bootapi";
import { ToastContainer, toast } from "react-toastify";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import "./../css/Responsive.css";
import { useDispatch } from "react-redux";
import { useStyles } from "../../Utilities/CSSUtilities";
import { logout } from "../features/userSlice";
import {
  check4pointpermission,
  check1pointpermission,
} from "../Permission/permission";

function Profile(props) {
  //console.log(props);
  // const adminstatus = props.navigate.location.state.data.status;
  // const userdata=props.navigate.location.state.data;

  const [client_admin_users, setClientDetails] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        navigate("/");
      }
    });
    const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
    const username = localStorage.getItem("isw_user_email");
    axios
      .get(`${base_url.api2}/getUserByUsername`, {
        headers: {
          Authorization: jwtToken,
          username: username,
        },
      })
      .then((response) => {
        //console.log(response);
        var client_admin = response.data;
        setClientDetails(client_admin);
      })
      .catch((error) => {
        toast.error(JSON.stringify(error.response.data.message));
        //console.log(error);
        handle403error(error);
      });
    //console.log(props);
    window.onpopstate = () => {
      //console.log(props);
      //console.log(userdata);
      // props.navigate('/dashboard');
      // props.navigate.replace({ pathname: '/profile', state:userdata});
    };
  }, []);
  //console.log(client_admin_users);

  //console.log(props.navigate.location.state.data);
  // const birthdate = moment(props.navigate.location.state.data.dob).format('DD-MM-YYYY');
  const editProfile = () => {
    navigate(`/edit-profile/${client_admin_users.data.email}`);
  };

  const classes = useStyles();

  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            navigate("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };
  return (
    <div>
      {/* <Dashboard/> */}
      <ToastContainer />
      {/* <Card className="commoncomponent_cardcss"> */}
      <div
        className="page-head d-flex justify-content-between p-4 "
        style={{ marginTop: "5rem" }}
      >
        <div className="breadcrums">
          <h3 className="page-title bold">Profile</h3>
          {/* <a href="index.html" className="b-link">Home</a>
          -
          <a href="client-dashboard.html" className="b-link">Client</a>
          -
          <a href="#" className="b-link active">Client</a> */}
        </div>
        {/* <a onClick={goback} className="back-btn">Back</a> */}
      </div>
      <div style={{ padding: "1.5rem", border: "1.5rem solid #e9ebf2" }}>
        <Row>
          {/* <Col md={12}>
              <CardText
                className={classes.headtext}
                style={{ marginTop: "-10px" }}
              >
                <strong>Profile</strong>
              </CardText>
            </Col> */}
        </Row>
        <div className="form-inner-admin">
          {/*ERROR! */}
          <div className="form-group creatadmin">
            <Row>
              <Col md={3}>
                <label>First Name:</label>
                {client_admin_users == null ? (
                  <Backdrop className={classes.backdrop} open>
                    <CircularProgress color="inherit" />
                  </Backdrop>
                ) : (
                  <p className="form-control">
                    {client_admin_users.data.firstName}
                  </p>
                )}
              </Col>
              <Col md={3}>
                <label>Last Name:</label>
                {client_admin_users == null ? (
                  <Backdrop className={classes.backdrop} open>
                    <CircularProgress color="inherit" />
                  </Backdrop>
                ) : (
                  <p className="form-control">
                    {client_admin_users.data.lastName}
                  </p>
                )}
              </Col>
              <Col md={3}>
                <label>Role:</label>
                {client_admin_users == null ? (
                  <Backdrop className={classes.backdrop} open>
                    <CircularProgress color="inherit" />
                  </Backdrop>
                ) : (
                  <p className="form-control">
                    {client_admin_users.data.roleName}
                  </p>
                )}
              </Col>
              <Col md={3}>
                <label>Email / Username:</label>
                {client_admin_users == null ? (
                  <Backdrop className={classes.backdrop} open>
                    <CircularProgress color="inherit" />
                  </Backdrop>
                ) : (
                  <p className="form-control">
                    {client_admin_users.data.email}
                  </p>
                )}
              </Col>
            </Row>
          </div>
          <div className="form-group creatadmin">
            <Row>
              <Col md={3}>
                <label>Contact No:</label>
                {client_admin_users == null ? (
                  <Backdrop className={classes.backdrop} open>
                    <CircularProgress color="inherit" />
                  </Backdrop>
                ) : (
                  <p
                    className="form-control"
                    style={{ backgroundColor: "#f5f5f9" }}
                  >
                    {client_admin_users.data.phone}
                  </p>
                )}
              </Col>
              <Col md={3}>
                <label>Status:</label>
                {client_admin_users == null ? (
                  <Backdrop className={classes.backdrop} open>
                    <CircularProgress color="inherit" />
                  </Backdrop>
                ) : (
                  <p
                    className="form-control"
                    style={{ backgroundColor: "#f5f5f9" }}
                  >
                    {client_admin_users.data.status == 0
                      ? "Inactive"
                      : "Active"}
                  </p>
                )}
              </Col>
              <Col md={3}>
                <label>Address:</label>
                {client_admin_users == null ? (
                  <Backdrop className={classes.backdrop} open>
                    <CircularProgress color="inherit" />
                  </Backdrop>
                ) : (
                  <p
                    className="form-control"
                    style={{ backgroundColor: "#f5f5f9" }}
                  >
                    {client_admin_users.data.address}
                  </p>
                )}
              </Col>
              <Col md={3}>
                <label>Country:</label>
                {client_admin_users == null ? (
                  <Backdrop className={classes.backdrop} open>
                    <CircularProgress color="inherit" />
                  </Backdrop>
                ) : (
                  <p
                    className="form-control"
                    style={{ backgroundColor: "#f5f5f9" }}
                  >
                    {client_admin_users.data.country}
                  </p>
                )}
              </Col>
            </Row>
          </div>
          <div className="form-group creatadmin">
            <Row>
              <Col md={3}>
                <label>PostCode:</label>
                {client_admin_users == null ? (
                  <Backdrop className={classes.backdrop} open>
                    <CircularProgress color="inherit" />
                  </Backdrop>
                ) : (
                  <p
                    className="form-control"
                    style={{ backgroundColor: "#f5f5f9" }}
                  >
                    {client_admin_users.data.pincode}
                  </p>
                )}
              </Col>
              <Col md={9}>
                <button
                  // className={classes.createBtn2}
                  style={{ float: "right", marginTop: "4%" }}
                  className="btn listing_addbutton ml-1"
                  onClick={editProfile}
                >
                  Edit
                </button>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      {/* </Card> */}
    </div>
  );
}

export default Profile;
