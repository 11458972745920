import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Card, CardText, Col, Row } from "reactstrap";
import axios from "axios";
import base_url from "../../api/bootapi";
import attachmenturl from "../../api/attachmenturl";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setdashboardcheck, selectUser, logout } from "../features/userSlice";
import { selectcollege, updatecollege } from "../features/collegeSlice";
import "../css/Responsive.css";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import Modal from "react-modal";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Pagination from "@mui/material/Pagination";
import { useForm } from "react-hook-form";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import SelectCurrency from "react-select-currency";
import {
  getproviderpermission,
  check4pointpermission,
  check1pointpermission,
} from "../Permission/permission";
import {
  useStyles,
  customStyles,
  customStylesProviderBranch,
} from "../../Utilities/CSSUtilities";
import callsaveauditapi from "../../services/auditservice";
import { Button } from "reactstrap";

const editorConfiguration = {
  toolbar: {
    items: [
      "heading",
      "|",
      "bold",
      "italic",
      "horizontalLine",
      "underline",
      "link",
      "bulletedList",
      "numberedList",
      "todoList",
      "|",
      "outdent",
      "indent",
      "alignment",
      "|",
      // "imageUpload",
      // "imageInsert",
      "blockQuote",
      "fontBackgroundColor",
      "fontColor",
      "insertTable",
      "fontSize",
      "fontFamily",
      "htmlEmbed",
      // "mediaEmbed",
      "codeBlock",
      "code",
      "undo",
      "redo",
    ],
  },
  language: "en",
  // image: {
  //   toolbar: [
  //     "imageTextAlternative",
  //     "imageStyle:full",
  //     "imageStyle:side",
  //     "linkImage",
  //   ],
  // },
  table: {
    contentToolbar: [
      "tableColumn",
      "tableRow",
      "mergeTableCells",
      "tableCellProperties",
      "tableProperties",
    ],
  },
  licenseKey: "",
};
function EditCollege(props) {
  const user = useSelector(selectUser);
  const [openapplicationform, setopenapplicationform] = useState(false);
  const [opentransactionalform, setopentransactionalform] = useState(false);
  let params = useParams();
  const navigate = useNavigate();
  const college = useSelector(selectcollege);
  const dispatch = useDispatch();
  const [backdrop, setbackdrop] = useState(false);
  const [country1, setcountry] = useState();
  const [region, setregion] = useState();
  const provider_id = params.provider_id;
  const [currency1, setcurrency] = useState("");
  const particularCollege = [];
  const [descriptionState, setdescriptionState] = useState(null);
  const [country3, setcountry3] = useState();
  const [region3, setregion3] = useState();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [providerName, setproviderName] = useState();
  const [institutionerror, setinstitutionerror] = useState(false);
  const [institutionBranches, setinstitutionBranches] = useState();
  const [branchagreementopen, setbranchagreementopen] = useState(false);
  const [branchagreementfiles, setbranchagreementfiles] = useState();
  const [fileinstitutionId, setfileinstitutionid] = useState();
  const [filebranchId, setfilebranchId] = useState();
  const [pageno, setpageno] = useState(0);
  const [getpageno, setgetpageno] = useState();
  const [applicationformlistdeleteindex, setapplicationformlistdeleteindex] =
    useState([]);
  const [transactionalformdeleteindex, settransactionalformdeleteindex] =
    useState([]);
  const [checkboxstate, setState] = React.useState({
    university: false,
    higherEducation: false,
    vet: false,
    elicos: false,
    shortCourse: false,
    pyCourse: false,
  });
  const [appformfilesize, setappformfilesize] = useState(false);
  const [logofilesize, setlogofilesize] = useState(false);
  const [bannerfilesize, setbannerfilesize] = useState(false);
  const [transacrionalfilesize, settransacrionalfilesize] = useState(false);
  const [agreementfilesize, setagreementfilesize] = useState(false);
  const [editagreementsize, seteditagreementsize] = useState(false);
  const ipaddress = localStorage.getItem("AdminIpAddress");
  const [modalApplicationDeleteOpen, setApplicationDeleteOpen] =
    React.useState(false);
  const [
    modalTransactionalAgreementDeleteOpen,
    setTransactionalAgreementDeleteOpen,
  ] = React.useState(false);

  const selectcurrency = (e) => {
    //console.log(e.target.value);
    setcurrency(e.target.value);
  };
  const handleChange = (event) => {
    setState({ ...checkboxstate, [event.target.name]: event.target.checked });
  };
  college.map((item) => {
    if (item.institutionId == provider_id) {
      particularCollege.push(item);
    }
  });
  //console.log(particularCollege);
  //console.log('College id',params);
  const jwtToken = "Bearer " + user.isw_user_token;
  const username = user.isw_user_email;
  const classes = useStyles();
  const [country2, setcountry2] = useState();
  const [region2, setregion2] = useState();
  const isw_user_name = localStorage.getItem("isw_user_name");
  const isw_adminId = localStorage.getItem("isw_adminId");
  const isw_user_email = localStorage.getItem("isw_user_email");

  const [stateCust, setstateCust] = useState({});
  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        navigate("/");
      }
    });
    getPermissions();
  }, []);

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);

    if (check1pointpermission(704, permission_obj, resource_obj)) {
      //if (available_permission){
      dispatch(setdashboardcheck(22));
      // setbackdrop(true);
      getinstitutionbranches();
      getCollegeById();
      // } else {
      //   navigate("/errorPage");
      // }
    } else {
      navigate("/errorPage");
    }
  };

  const getinstitutionbranches = () => {
    axios
      .get(`${base_url.api2}/getBranchesByInstitutionId`, {
        headers: {
          Authorization: jwtToken,
          institutionId: params.provider_id,
        },
      })
      .then((res) => {
        setinstitutionBranches(res.data.data);
      })
      .catch((err) => {
        handle403error(err);
      });
    setbackdrop(false);
  };
  const selectCountry3 = (val) => {
    setcountry3(val);
  };
  const selectRegion3 = (val) => {
    setregion3(val);
  };
  const [modalisopen, setisopen] = useState(false);
  const [modalisopen2, setisopen2] = useState(false);
  const [modalisopen3, setisopen3] = useState(false);
  const [coursedetails, setcoursedetails] = useState();
  const [institutionid, setinstitutionid] = useState();
  const [descriptionstate2, setdescriptionstate2] = useState();
  const openModal = (index) => {
    setisopen(true);
    setinstitutionid(index);
    setcountry2(institutionBranches[index].country);
    setregion2(institutionBranches[index].state);
    setdescriptionstate2(institutionBranches[index].branchContactDetails);
  };
  const openModal2 = () => {
    setisopen2(true);
    setcountry3();
    setregion3();
  };
  const [selectedinstitutionbranchid, setselectedinstitutionbranchid] =
    useState();
  const openModal3 = (index) => {
    setisopen3(true);
    setselectedinstitutionbranchid(index);
    axios
      .get(`${base_url.api2}/getCourseByInstitutionBranchId`, {
        headers: {
          institutionBranchId: Number(index),
          columnName: "name",
          direction: "ASC",
          page: pageno,
          size: 10,
        },
      })
      .then((res) => {
        setcoursedetails(res.data.data.content);
        setgetpageno(res.data.data.totalPages);
      })
      .catch((err) => {
        handle403error(err);
      });
  };
  const handlepage = (event, value) => {
    setpageno(value - 1);
    axios
      .get(`${base_url.api2}/getCourseByInstitutionBranchId`, {
        headers: {
          institutionBranchId: Number(selectedinstitutionbranchid),
          columnName: "name",
          direction: "ASC",
          page: value - 1,
          size: 10,
        },
      })
      .then((res) => {
        setcoursedetails(res.data.data.content);
        setgetpageno(res.data.data.totalPages);
      })
      .catch((err) => {
        handle403error(err);
      });
  };
  const selectCountry2 = (val) => {
    setcountry2(val);
  };
  const selectRegion2 = (val) => {
    setregion2(val);
  };

  const [applicationformlist, setapplicationformlist] = useState();
  const [transactionalarrangementlist, settransactionalarrangementlist] =
    useState();
  const getCollegeById = () => {
    axios
      .get(`${base_url.api2}/getInstitutionById`, {
        headers: {
          Authorization: jwtToken,
          institutionId: params.provider_id,
        },
      })
      .then((res) => {
        let college = res.data.data;
        setcurrency(college.currency);
        setapplicationformlist(college.applicationForms);
        settransactionalarrangementlist(college.transactionalArrangements);
        setproviderName(college.name.split("(")[0]);
        setstateCust({
          abn: college.abn,
          acn: college.acn,
          addedOn: college.addedOn,
          address: college.address,
          applicationFormLink: college.applicationFormLink,
          can: college.can,
          city: college.city,
          contactPerson: college.contactPerson,
          country: college.country,
          cricosProviderCode: college.cricosProviderCode,
          currency: college.currency,
          designation: college.designation,
          email: college.email,
          fax: college.fax,
          institutionCode: college.institutionCode,
          institutionId: college.institutionId,
          institutionType: college.institutionType,
          latitude: college.latitude,
          logo: college.logo,
          banner: college.banner,
          longitude: college.longitude,
          mobile: college.mobile,
          name: college.name,
          overview: college.overview,
          pan: college.pan,
          phoneNumber: college.phoneNumber,
          remarks: college.remarks,
          rtoNumber: college.rtoNumber,
          state: college.state,
          status: college.status,
          street: college.street,
          transactionalArrangementName: college.transactionalArrangementName,
          university: college.university,
          updatedOn: college.updatedOn,
          website: college.website,
          zipCode: college.zipCode,
        });
        setbackdrop(false);
        setcountry(college.country);

        setregion(college.state);
        if (college.institutionType) {
          let institutionarray = college.institutionType.split(",");
          for (let i = 0; i < institutionarray.length; i++) {
            const name = institutionarray[i];
            setState((prev) => {
              return {
                ...prev,
                [name]: true,
              };
            });
          }
        }
      })
      .catch((err) => {
        setbackdrop(false);
        handle403error(err);
      });
  };

  const inputEvent = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setstateCust((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };
  const inputEvent2 = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    const newarr = [...institutionBranches];
    newarr[institutionid][name] = value;
    setinstitutionBranches(newarr);
  };
  const selectCountry = (val) => {
    setcountry(val);
  };
  const selectRegion = (val) => {
    setregion(val);
  };
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const [baseimage, setbaseimage] = useState("");
  const uploadLogo = async (data) => {
    const files = data.target.files;
    console.log("204----->", files);
    if (files.length > 0) {
      let formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append("stepfile", files[i], files[i].name);
      }
      postLogo(formData, files);
    }
  };

  const postLogo = (formData, fileToUpload) => {
    if (fileToUpload.length) {
      axios
        .post(`${base_url.api2}/uploadFileUrl`, formData, {
          headers: {
            Authorization: jwtToken,
          },
        })
        .then((res) => {
          console.log("223--->", res.data.data);
          stateCust.logo = res.data.data;
          setbaseimage(res.data.data);
          // toast.success(res.data.message);
        })
        .catch((error) => {
          handle403error(error);
          // toast.error(JSON.stringify(error.response.data.message));
        });
    }
  };

  const [baseimagetwo, setbaseimagetwo] = useState("");
  const uploadBanner = async (data) => {
    const files = data.target.files;
    console.log("212----->", files);

    if (files.length > 0) {
      let formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append("stepfile", files[i], files[i].name);
      }
      postAttachment(formData, files);
    }
  };

  const postAttachment = (formData, fileToUpload) => {
    if (fileToUpload.length) {
      axios
        .post(`${base_url.api2}/uploadFileUrl`, formData, {
          headers: {
            Authorization: jwtToken,
          },
        })
        .then((res) => {
          console.log("232--->", res.data.data);
          stateCust.banner = res.data.data;
          setbaseimagetwo(res.data.data);
          // toast.success(res.data.message);
        })
        .catch((error) => {
          handle403error(error);
          // toast.error(JSON.stringify(error.response.data.message));
        });
    }
  };

  const putProvider = (data2) => {
    if (
      appformfilesize ||
      logofilesize ||
      bannerfilesize ||
      transacrionalfilesize
    ) {
      return;
    }
    const isoDateString = new Date().toISOString();
    const datenow = isoDateString.split(".")[0];
    stateCust.updatedOn = datenow;
    stateCust.country = country1;
    stateCust.currency = currency1;
    stateCust.state = region;
    let formData = new FormData();
    let formData2 = new FormData();
    let multiplefiles = data2.appForm;
    let fileToUpload = data2.transactionalArrangement;

    if (stateCust.institutionCode == 0) {
      stateCust.institutionCode = null;
    }
    if (stateCust.remarks == "") {
      stateCust.remarks = null;
    }

    // if (applicationformlistdeleteindex.length > 0) {
    //   axios.delete(`${base_url.api2}/deleteInstitutionApplicationForm`, {
    //     headers: {
    //       Authorization: jwtToken,
    //     },
    //     data: applicationformlistdeleteindex,
    //   });
    // }
    // if (transactionalformdeleteindex.length > 0) {
    //   axios.delete(`${base_url.api2}/deleteInstitutionTransactionalAgreement`, {
    //     headers: {
    //       Authorization: jwtToken,
    //     },
    //     data: transactionalformdeleteindex,
    //   });
    // }
    if (multiplefiles.length) {
      for (let i = 0; i < multiplefiles.length; i++) {
        formData2.append(
          "applicationForms",
          multiplefiles[i],
          multiplefiles[i].name
        );
      }
    }
    if (fileToUpload.length) {
      for (let i = 0; i < fileToUpload.length; i++) {
        formData.append(
          "transactionalArrangement",
          fileToUpload[i],
          fileToUpload[i].name
        );
      }
    }
    delete stateCust["applicationForms"];
    delete stateCust["transactionalArrangement"];
    let institutionTypearray = [];
    let arr = Object.keys(checkboxstate);
    for (let i = 0; i < arr.length; i++) {
      let key = arr[i];
      if (checkboxstate[key]) {
        institutionTypearray.push(key);
      }
    }
    let institutionType;
    for (let i = 0; i < institutionTypearray.length; i++) {
      if (i === 0) {
        institutionType = institutionTypearray[i];
      } else {
        institutionType += `,${institutionTypearray[i]}`;
      }
    }
    stateCust.institutionType = institutionType;
    //console.log(stateCust);
    if (institutionType) {
      axios
        .put(`${base_url.api2}/updateInstitutionByIntitutionId`, stateCust, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: jwtToken,
          },
        })
        .then(
          (response) => {
            //console.log(response.data.data);
            if (fileToUpload.length) {
              axios
                .post(
                  `${base_url.api2}/uploadTransactionalArrangements`,
                  formData,
                  {
                    headers: {
                      Authorization: jwtToken,
                      institutionId: response.data.data.institutionId,
                    },
                  }
                )
                .then((res) => {
                  toast.success("Sucess");
                })
                .catch((err) => {
                  //console.log(err);
                  handle403error(err);
                });
            }
            if (multiplefiles.length) {
              axios
                .post(
                  `${base_url.api2}/uploadInstitutionApplicationForm`,
                  formData2,
                  {
                    headers: {
                      Authorization: jwtToken,
                      institutionId: response.data.data.institutionId,
                    },
                  }
                )
                .then((res) => {
                  toast.success("Sucess");
                })
                .catch((err) => {
                  //console.log(err);
                  handle403error(err);
                });
            }
            dispatch(
              updatecollege({
                id: provider_id,
                data: response.data.data,
              })
            );
            let logs =
              isw_user_name + " Update Provider " + stateCust.name + ".";
            callsaveauditapi(logs, dispatch, navigate);
            toast.success("Success");
            navigate("/provider");
          },
          (error) => {
            handle403error(error);
          }
        );
    } else {
      setinstitutionerror(true);
    }
  };
  const submitBranch = (data2) => {
    if (editagreementsize) {
      return;
    }
    setbackdrop(true);
    institutionBranches[institutionid].country = country2;
    institutionBranches[institutionid].state = region2;
    institutionBranches[institutionid].branchContactDetails = descriptionstate2;
    institutionBranches[institutionid].commissionPercentage = Number(
      institutionBranches[institutionid].commissionPercentage
    );
    let formData = new FormData();
    let multiplefiles = data2.attachment;
    if (multiplefiles.length) {
      for (let i = 0; i < multiplefiles.length; i++) {
        formData.append(
          "transactionalAgreement",
          multiplefiles[i],
          multiplefiles[i].name
        );
      }
    }
    let data;
    data = institutionBranches[institutionid];
    axios
      .post(`${base_url.api2}/updateInstitutionBranchesById`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        toast.success("Branch Updated");
        if (multiplefiles.length) {
          axios
            .post(
              `${base_url.api2}/uploadBranchTransactionalAgreement`,
              formData,
              {
                headers: {
                  Authorization: jwtToken,
                  institutionId: Number(res.data.data.institutionId),
                  branchId: res.data.data.branchId,
                },
              }
            )
            .then((res) => {
              toast.success("Branch Updated");
              setbackdrop(false);
              setisopen(false);
              getinstitutionbranches();
            })
            .catch((err) => {
              setbackdrop(false);
              toast.error("Error uploading Agreement");
              handle403error(err);
            });
        }
        if (!multiplefiles.length) {
          setisopen(false);
          setbackdrop(false);
          getinstitutionbranches();
        }
      })
      .catch((err) => {
        setbackdrop(false);
        handle403error(err);
      });
  };
  const createBranch = (data2) => {
    if (agreementfilesize) {
      return;
    }
    setbackdrop(true);
    let formData = new FormData();
    let multiplefiles = data2.attachment;
    if (multiplefiles.length) {
      for (let i = 0; i < multiplefiles.length; i++) {
        formData.append(
          "transactionalAgreement",
          multiplefiles[i],
          multiplefiles[i].name
        );
      }
    }
    let data = {
      branchId: 0,
      institutionId: Number(params.provider_id),
      branchName: providerName + "-" + data2.branchname,
      branchAddress: data2.branchaddress,
      branchContactDetails: descriptionState,
      city: data2.city,
      state: region3,
      country: country3,
      commissionPercentage: Number(data2.commissionpercentage),
      lattitude: data2.latitude,
      logo: stateCust.logo,
      banner: stateCust.banner,
      longitude: data2.longitude,
    };
    //console.log("Data", data);
    axios
      .post(`${base_url.api2}/createInstitutionBranch`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        toast.success("Branch Created");
        if (multiplefiles.length) {
          axios
            .post(
              `${base_url.api2}/uploadBranchTransactionalAgreement`,
              formData,
              {
                headers: {
                  Authorization: jwtToken,
                  institutionId: Number(res.data.data.institutionId),
                  branchId: res.data.data.branchId,
                },
              }
            )
            .then((res) => {
              toast.success("Branch Created");
              setbackdrop(false);
              getinstitutionbranches();
              setisopen2(false);
            })
            .catch((err) => {
              setbackdrop(false);
              //console.log(err);
              handle403error(err);
            });
        }
        if (!multiplefiles.length) {
          setbackdrop(false);
          getinstitutionbranches();
          setisopen2(false);
        }
      })
      .catch((err) => {
        setbackdrop(false);
        handle403error(err);
        toast.error(JSON.stringify(err?.response?.data?.message));
      });
  };
  const goback = () => {
    // navigate("/provider");
    navigate(-1);
  };

  const closeModal = () => {
    setApplicationDeleteOpen(false);
    setTransactionalAgreementDeleteOpen(false);
  };

  // const deletestudentappform = (p, index) => {
  //   var lines = [...applicationformlist];
  //   lines.splice(index, 1);
  //   //console.log(lines);
  //   //console.log(p.id);
  //   setapplicationformlistdeleteindex((prev) => [...prev, p.id]);
  //   setapplicationformlist(lines);
  // };

  const [deleteId, setApplicationDeleteId] = useState();
  const [deleteFileName, setApplicationDeleteFileName] = useState();
  const [transactionalAgreementDeleteId, setTransactionalAgreementDeleteId] =
    useState();
  const deletestudentappform = (p, index) => {
    //console.log(deleteId);
    let delete_path = deleteFileName.split("amazonaws.com/")[1];
    setbackdrop(true);
    axios
      .delete(`${base_url.api2}/deleteInstitutionApplicationForm`, {
        headers: {
          Authorization: jwtToken,
        },
        data: [deleteId],
      })
      .then((response) => {
        var lines = [...applicationformlist];
        lines.splice(index, 1);
        setapplicationformlistdeleteindex((prev) => [...prev, p.id]);
        setapplicationformlist(lines);
        //console.log(response);
        setApplicationDeleteOpen(false);
        setbackdrop(false);
      })
      .catch((error) => {
        handle403error(error);
        //console.log(error);
      });
  };

  const deletetransactionalagreement = (p, index) => {
    // var lines = [...transactionalarrangementlist];
    // lines.splice(index, 1);
    //console.log(lines);
    //console.log(p);
    // settransactionalformdeleteindex((prev) => [...prev, p.id]);
    // settransactionalarrangementlist(lines);
    setbackdrop(true);
    axios
      .delete(`${base_url.api2}/deleteInstitutionTransactionalAgreement`, {
        headers: {
          Authorization: jwtToken,
        },
        data: [transactionalAgreementDeleteId],
      })
      .then((response) => {
        var lines = [...transactionalarrangementlist];
        lines.splice(index, 1);
        settransactionalformdeleteindex((prev) => [...prev, p.id]);
        settransactionalarrangementlist(lines);
        //console.log(response);
        setTransactionalAgreementDeleteOpen(false);
        setbackdrop(false);
      })
      .catch((error) => {
        handle403error(error);
      });
  };

  const chkappfilesize = (e) => {
    var chk = false;
    const numberfiles = e.target.files;
    for (let i = 0; i < numberfiles.length; i++) {
      if (numberfiles[i].size / 1048576 > 4) {
        chk = true;
      }
    }
    setappformfilesize(chk);
  };
  const chklogosize = (e) => {
    var chk = false;
    const numberfiles = e.target.files;
    for (let i = 0; i < numberfiles.length; i++) {
      if (numberfiles[i].size / 1048576 > 4) {
        chk = true;
      }
    }
    setlogofilesize(chk);
  };
  const chkbannersize = (e) => {
    var chk = false;
    const numberfiles = e.target.files;
    for (let i = 0; i < numberfiles.length; i++) {
      if (numberfiles[i].size / 1048576 > 4) {
        chk = true;
      }
    }
    setbannerfilesize(chk);
  };
  const chktransactionalsize = (e) => {
    var chk = false;
    const numberfiles = e.target.files;
    for (let i = 0; i < numberfiles.length; i++) {
      if (numberfiles[i].size / 1048576 > 4) {
        chk = true;
      }
    }
    settransacrionalfilesize(chk);
  };
  const chkagreementsize = (e) => {
    var chk = false;
    const numberfiles = e.target.files;
    for (let i = 0; i < numberfiles.length; i++) {
      if (numberfiles[i].size / 1048576 > 4) {
        chk = true;
      }
    }
    setagreementfilesize(chk);
  };
  const chkeditagreementsize = (e) => {
    var chk = false;
    const numberfiles = e.target.files;
    for (let i = 0; i < numberfiles.length; i++) {
      if (numberfiles[i].size / 1048576 > 4) {
        chk = true;
      }
    }
    seteditagreementsize(chk);
  };

  const downloadAll = (applicationFormPath) => {
    setbackdrop(true);
    let file_name =
      applicationFormPath.split("amazonaws.com/")[
      applicationFormPath.split("amazonaws.com/").length - 1
      ];
    const header = {
      method: "GET",
      headers: {
        Authorization: jwtToken,
        institutionId: provider_id,
        fileName: file_name,
      },
    };

    var url = `${base_url.api2}/downloadInstitutionApplicationFormByInstitutionIdAndFileName`;
    fetch(url, header)
      .then((response) => readAllChunks(response))
      .then(async function (response) {
        //console.log(response);
        const downloadFileType = localStorage.getItem(
          "provider_application_attachment_file_content_type"
        );
        //console.log(downloadFileType);
        var blob = new Blob(response, { type: downloadFileType });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = file_name;
        link.click();
        setbackdrop(false);
      })
      .catch((error) => {
        setbackdrop(false);
        //console.log(error);
        handle403error(error);
      });
  };

  const downloadAllTransactionalAgreement = (applicationFormPath) => {
    setbackdrop(true);
    let file_name =
      applicationFormPath.split("amazonaws.com/")[
      applicationFormPath.split("amazonaws.com/").length - 1
      ];
    const header = {
      method: "GET",
      headers: {
        Authorization: jwtToken,
        institutionId: provider_id,
        fileName: file_name,
      },
    };

    var url = `${base_url.api2}/downloadInstitutionTransactionalAgreementByInstitutionIdAndFileName`;
    fetch(url, header)
      .then((response) => readAllChunks(response))
      .then(async function (response) {
        //console.log(response);
        const downloadFileType = localStorage.getItem(
          "provider_application_attachment_file_content_type"
        );
        //console.log(downloadFileType);
        var blob = new Blob(response, { type: downloadFileType });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = file_name;
        link.click();
        setbackdrop(false);
      })
      .catch((error) => {
        setbackdrop(false);
        //console.log(error);
        handle403error(error);
      });
  };

  const readAllChunks = (readableStream) => {
    localStorage.setItem(
      "provider_application_attachment_file_content_type",
      readableStream.headers.get("content-type")
    );
    const reader = readableStream.body.getReader();
    const chunks = [];

    function pump() {
      return reader
        .read()
        .then(({ value, done }) => {
          if (done) {
            return chunks;
          }
          chunks.push(value);
          return pump();
        })
        .catch((err) => {
          handle403error(err);
        });
    }
    return pump();
  };

  const downloadAllBranchFile = (branchTransactionalAgreementPath) => {
    setbackdrop(true);
    let file_name =
      branchTransactionalAgreementPath.split("amazonaws.com/")[
      branchTransactionalAgreementPath.split("amazonaws.com/").length - 1
      ];
    const header = {
      method: "GET",
      headers: {
        Authorization: jwtToken,
        instituteId: provider_id,
        branchId: filebranchId,
        fileName: file_name,
      },
    };

    var url = `${base_url.api2}/downloadBranchTransactionalAgreementByBranchIdAndFileName`;
    fetch(url, header)
      .then((response) => readAllChunksBranch(response))
      .then(async function (response) {
        //console.log(response);
        const downloadFileType = localStorage.getItem(
          "provider_branch_application_attachment_file_content_type"
        );
        //console.log(downloadFileType);
        var blob = new Blob(response, { type: downloadFileType });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = file_name;
        link.click();
        setbackdrop(false);
      })
      .catch((error) => {
        setbackdrop(false);
        //console.log(error);
        handle403error(error);
      });
  };

  const readAllChunksBranch = (readableStream) => {
    localStorage.setItem(
      "provider_branch_application_attachment_file_content_type",
      readableStream.headers.get("content-type")
    );
    const reader = readableStream.body.getReader();
    const chunks = [];

    function pump() {
      return reader
        .read()
        .then(({ value, done }) => {
          if (done) {
            return chunks;
          }
          chunks.push(value);
          return pump();
        })
        .catch((err) => {
          handle403error(err);
        });
    }
    return pump();
  };

  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            navigate("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };
  return (
    <div>
      <ToastContainer />
      <Backdrop className={classes.backdrop} open={backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* <Dashboard /> */}
      {/* <Card className="commoncomponent_cardcss"> */}
      <div
        className="page-head d-flex justify-content-between p-4 "
        style={{ marginTop: "4.3rem" }}
      >
        <div className="breadcrums">
          <h3 className="page-title bold">Provider</h3>
          {/* <a href="index.html" className="b-link">Home</a>
          -
          <a href="client-dashboard.html" className="b-link">Client</a>
          -
          <a href="#" className="b-link active">Client</a> */}
        </div>
        <a onClick={goback} className="sent-button">
          List
        </a>
      </div>
      <div style={{ padding: "1.5rem", border: "1.5rem solid #e9ebf2" }}>
        <form onSubmit={handleSubmit(putProvider)}>
          <div className="form-inner-admin form_inner_admin">
            {/* <Row>
            <Col md={6}>
              <CardText className={classes.headtext}>
                <strong>Provider</strong>
              </CardText>
            </Col>
            <Col md={6} className="d-flex justify-content-end">
              <Button className="btn listing_addbutton" onClick={goback}>
                &#x2630; List
              </Button>
            </Col>
          </Row> */}
            {/* <div class="client_container2">
                <span>Provider Details</span>
              </div> */}
            <div style={{ display: "flex", marginTop: "10px" }}>
              <p className="provider_containertitle">Provider Details</p>
              {/* <hr style={{ flex: "1", marginLeft: "20px" }} /> */}
            </div>
            <div className="form-group creatadmin">
              <Row>
                <Col md={3}>
                  <label className="area_rowslable_my">
                    Name<span style={{ color: "red" }}>*</span>:
                  </label>
                  <input
                    className="form-control underline-input"
                    name="name"
                    onChange={inputEvent}
                    value={stateCust.name}
                    type="text"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register({ required: true })}
                  />
                  {errors.name && (
                    <p className="errormessage">Name is Required</p>
                  )}
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">
                    Cricos Provider Code
                    <span style={{ color: "red" }}></span>:
                  </label>
                  <input
                    className="form-control underline-input"
                    name="cricosProviderCode"
                    onChange={inputEvent}
                    style={{ backgroundColor: "#f5f5f9" }}
                    value={stateCust.cricosProviderCode}
                    type="text"
                    ref={register({ required: false })}
                  />
                  {/* {errors.cricosProviderCode && (
                    <p className="errormessage">
                      Cricos Provider Code is Required
                    </p>
                  )} */}
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">
                    RTO Number(VET National Code):
                  </label>
                  <input
                    className="form-control underline-input"
                    name="rtoNumber"
                    onChange={inputEvent}
                    style={{ backgroundColor: "#f5f5f9" }}
                    value={stateCust.rtoNumber}
                    type="text"
                    ref={register({ required: false })}
                  />
                  {errors.rtoNumber && (
                    <p className="errormessage">RTO Number is Required</p>
                  )}
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">Provider Code:</label>
                  <input
                    className="form-control underline-input"
                    name="institutionCode"
                    style={{ backgroundColor: "#f5f5f9" }}
                    onChange={inputEvent}
                    value={stateCust.institutionCode}
                    type="text"
                    ref={register}
                  />
                </Col>
              </Row>
            </div>
            <div className="form-group provideradmin">
              <Row>
                <Col md={3}>
                  <label className="area_rowslable_my">ABN:</label>
                  <input
                    className="form-control underline-input"
                    name="abn"
                    onChange={inputEvent}
                    style={{ backgroundColor: "#f5f5f9" }}
                    value={stateCust.abn}
                    type="text"
                    ref={register({ required: false })}
                  />
                  {errors.abn && (
                    <p className="errormessage">ABN is Required</p>
                  )}
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">ACN:</label>
                  <input
                    className="form-control underline-input"
                    name="acn"
                    onChange={inputEvent}
                    value={stateCust.acn}
                    style={{ backgroundColor: "#f5f5f9" }}
                    type="text"
                    ref={register({ required: false })}
                  />
                  {errors.acn && (
                    <p className="errormessage">ACN is Required</p>
                  )}
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">PAN:</label>
                  <input
                    className="form-control underline-input"
                    name="pan"
                    onChange={inputEvent}
                    style={{ backgroundColor: "#f5f5f9" }}
                    value={stateCust.pan}
                    type="text"
                    ref={register}
                  />
                  {/* {errors.pan && (
                      <p className="errormessage">PAN is Required</p>
                    )} */}
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">CAN:</label>
                  <input
                    className="form-control underline-input"
                    name="can"
                    onChange={inputEvent}
                    value={stateCust.can}
                    style={{ backgroundColor: "#f5f5f9" }}
                    type="text"
                    ref={register}
                  />
                  {/* {errors.can && (
                      <p className="errormessage">CAN is Required</p>
                    )} */}
                </Col>
              </Row>
            </div>
            <div className="form-group creatadmin">
              <Row>
                <Col md={3}>
                  <label className="area_rowslable_my">
                    Currency<span style={{ color: "red" }}>*</span>:
                  </label>

                  <SelectCurrency
                    value={currency1}
                    onChange={selectcurrency}
                    style={{ width: "100%", backgroundColor: "#f5f5f9" }}
                    required
                  />
                  {/* <input
                      className="form-control underline-input"
                      name="currency"
                      onChange={inputEvent}
                      value={stateCust.currency}
                      type="text"
                      ref={register('currency',{ required: true })}
                    />
                    {errors.currency && (
                      <p className="errormessage">Currency is Required</p>
                    )} */}
                </Col>
                <Col md={9}>
                  <label className="area_rowslable_my">
                    Provider Type<span style={{ color: "red" }}>*</span>:
                  </label>
                  <br />

                  <Row style={{ gap: "5px" }}>
                    {/* <FormControlLabel
                          control={
                            <Checkbox
                              checked={checkboxstate.university}
                              onChange={handleChange}
                              color="primary"
                              name="university"
                            />
                          }
                          label="University"
                        /> */}
                    <Col>
                      <div class="containerthree">
                        <input
                          checked={checkboxstate.university}
                          onChange={handleChange}
                          type="checkbox"
                          id="flexCheckDefault"
                          name="university"
                        />
                        <label
                          className="area_rowslable_my"
                          class="form-check-label boxes"
                          for="flexCheckDefault"
                          style={{
                            marginLeft: "0px",
                            fontSize: "14px",
                            fontWeight: "bold",
                            color: "#071a52",
                          }}
                        >
                          University
                        </label>
                        <label
                          className="area_rowslable_my"
                          class="checkmarkthree"
                          for="flexCheckDefault"
                          style={{ marginTop: "10px" }}
                        ></label>
                      </div>
                    </Col>

                    {/* <FormControlLabel
                          control={
                            <Checkbox
                              checked={checkboxstate.higherEducation}
                              onChange={handleChange}
                              color="primary"
                              name="higherEducation"
                            />
                          }
                          label="Higher Education"
                        /> */}
                    <Col>
                      <div class="containerfour">
                        <input
                          checked={checkboxstate.higherEducation}
                          onChange={handleChange}
                          type="checkbox"
                          name="higherEducation"
                          id="flexCheck"
                        />
                        <label
                          className="area_rowslable_my"
                          class="checkmarkfour"
                          for="flexCheck"
                          style={{ marginTop: "10px" }}
                        ></label>
                        <label
                          className="area_rowslable_my"
                          class="form-check-label boxes"
                          for="flexCheck"
                          style={{
                            marginLeft: "0px",
                            fontSize: "14px",
                            fontWeight: "bold",
                            color: "#071a52",
                          }}
                        >
                          Higher Education
                        </label>
                      </div>
                    </Col>
                    {/* <FormControlLabel
                          control={
                            <Checkbox
                              checked={checkboxstate.vet}
                              onChange={handleChange}
                              color="primary"
                              name="vet"
                            />
                          }
                          label="VET"
                        /> */}
                    <Col>
                      <div class="containerfive">
                        <input
                          checked={checkboxstate.vet}
                          onChange={handleChange}
                          type="checkbox"
                          name="vet"
                          id="flexCheckChecked"
                        />
                        <label
                          className="area_rowslable_my"
                          class="checkmarkfive"
                          for="flexCheckChecked"
                          style={{ marginTop: "10px" }}
                        ></label>
                        <label
                          className="area_rowslable_my"
                          class="form-check-label boxes"
                          for="flexCheckChecked"
                          style={{
                            marginLeft: "0px",
                            fontSize: "14px",
                            fontWeight: "bold",
                            color: "#071a52",
                          }}
                        >
                          VET
                        </label>
                      </div>
                    </Col>
                    {/* <FormControlLabel
                          control={
                            <Checkbox
                              checked={checkboxstate.elicos}
                              onChange={handleChange}
                              color="primary"
                              name="elicos"
                            />
                          }
                          label="ELICOS"
                        /> */}
                    <Col>
                      <div class="containersix">
                        <input
                          checked={checkboxstate.elicos}
                          onChange={handleChange}
                          type="checkbox"
                          name="elicos"
                          id="flexChecked"
                        />
                        <label
                          className="area_rowslable_my"
                          class="checkmarksix"
                          for="flexChecked"
                          style={{ marginTop: "10px" }}
                        ></label>
                        <label
                          className="area_rowslable_my"
                          class="form-check-label boxes"
                          for="flexChecked"
                          style={{
                            marginLeft: "0px",
                            fontSize: "14px",
                            fontWeight: "bold",
                            color: "#071a52",
                          }}
                        >
                          ELICOS
                        </label>
                      </div>
                    </Col>
                    {/* <FormControlLabel
                          control={
                            <Checkbox
                              checked={checkboxstate.shortCourse}
                              onChange={handleChange}
                              color="primary"
                              name="shortCourse"
                            />
                          }
                          label="Short Course"
                        /> */}
                    <Col>
                      <div class="containerseven">
                        <input
                          checked={checkboxstate.shortCourse}
                          onChange={handleChange}
                          type="checkbox"
                          name="shortCourse"
                          id="CheckChecked"
                        />
                        <label
                          className="area_rowslable_my"
                          class="checkmarkseven"
                          for="CheckChecked"
                          style={{ marginTop: "10px" }}
                        ></label>
                        <label
                          className="area_rowslable_my"
                          class="form-check-label boxes"
                          for="CheckChecked"
                          style={{
                            marginLeft: "0px",
                            fontSize: "14px",
                            fontWeight: "bold",
                            color: "#071a52",
                          }}
                        >
                          Short Course
                        </label>
                      </div>
                    </Col>
                    {/* <FormControlLabel
                          control={
                            <Checkbox
                              checked={checkboxstate.pyCourse}
                              onChange={handleChange}
                              color="primary"
                              name="pyCourse"
                            />
                          }
                          label="PY Course"
                        /> */}
                    <Col>
                      <div class="containereight">
                        <input
                          checked={checkboxstate.pyCourse}
                          onChange={handleChange}
                          type="checkbox"
                          name="pyCourse"
                          id="fChecked"
                        />
                        <label
                          className="area_rowslable_my"
                          class="checkmarkeight"
                          for="fChecked"
                          style={{ marginTop: "10px" }}
                        ></label>
                        <label
                          className="area_rowslable_my"
                          class="form-check-label boxes"
                          for="fChecked"
                          style={{
                            marginLeft: "0px",
                            fontSize: "14px",
                            fontWeight: "bold",
                            color: "#071a52",
                          }}
                        >
                          PY Course
                        </label>
                      </div>
                    </Col>
                  </Row>

                  {institutionerror && (
                    <p
                      className="errormessage"
                      style={{ fontWeight: "bolder" }}
                    >
                      InstitutionType is Required
                    </p>
                  )}
                </Col>
              </Row>
            </div>
            {/* <div class="client_container2">
                <span>Provider Address</span>
              </div> */}
            <div style={{ display: "flex", marginTop: "10px" }}>
              <p className="provider_containertitle">Address</p>
              {/* <hr style={{ flex: "1", marginLeft: "20px" }} /> */}
            </div>
            <div className="form-group creatadmin">
              <Row>
                <Col md={3}>
                  <label className="area_rowslable_my">
                    Street<span style={{ color: "red" }}></span>:
                  </label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="street"
                    onChange={inputEvent}
                    style={{ backgroundColor: "#f5f5f9" }}
                    value={stateCust.street}
                    ref={register({ required: false })}
                  />
                  {/* {errors.street && (
                    <p className="errormessage">Street is Required</p>
                  )} */}
                </Col>

                <Col md={3}>
                  <label className="area_rowslable_my">
                    Country<span style={{ color: "red" }}>*</span>:
                  </label>
                  <CountryDropdown
                    defaultOptionLabel="- Select Country -"
                    className="countrydropdown2 form-control underline-input"
                    value={country1}
                    style={{ backgroundColor: "#f5f5f9" }}
                    onChange={(val) => selectCountry(val)}
                    required
                  />
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">
                    State<span style={{ color: "red" }}>*</span>:
                  </label>
                  {country1 == "Nepal" ? (
                    <select
                      className={classes.selectdrop}
                      name="gender"
                      style={{ backgroundColor: "#f5f5f9" }}
                      value={region}
                      onChange={(e) => selectRegion(e.target.value)}
                    >
                      <option value="Province No. 1">Province No. 1</option>
                      <option value="Madhesh Province">Madhesh Province</option>
                      <option value="Bagmati Province">Bagmati Province</option>
                      <option value="Gandaki Province">Gandaki Province</option>
                      <option value="Lumbini Province">Lumbini Province</option>
                      <option value="Karnali Province">Karnali Province</option>
                      <option value="Sudurpashchim Province">
                        Sudurpashchim Province
                      </option>
                    </select>
                  ) : (
                    <RegionDropdown
                      defaultOptionLabel="- Select State -"
                      className="countrydropdown2 form-control underline-input"
                      country={country1}
                      value={region}
                      style={{ backgroundColor: "#f5f5f9" }}
                      onChange={(val) => selectRegion(val)}
                      required
                    />
                  )}
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">
                    City<span style={{ color: "red" }}>*</span>:
                  </label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="city"
                    onChange={inputEvent}
                    style={{ backgroundColor: "#f5f5f9" }}
                    value={stateCust.city}
                    ref={register({ required: true })}
                  />
                  {errors.city && (
                    <p className="errormessage">City is Required</p>
                  )}
                </Col>
              </Row>
            </div>
            <div className="form-group creatadmin">
              <Row>
                <Col md={3}>
                  <label className="area_rowslable_my">
                    PostCode<span style={{ color: "red" }}>*</span>:
                  </label>
                  <input
                    type="number"
                    onWheel={(event) => event.currentTarget.blur()}
                    className="form-control underline-input"
                    name="zipCode"
                    style={{ backgroundColor: "#f5f5f9" }}
                    onChange={inputEvent}
                    value={stateCust.zipCode}
                    ref={register({ required: true })}
                  />
                  {errors.zipCode && (
                    <p className="errormessage">PostCode is Required</p>
                  )}
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">
                    Website<span style={{ color: "red" }}></span>:
                  </label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="website"
                    style={{ backgroundColor: "#f5f5f9" }}
                    onChange={inputEvent}
                    value={stateCust.website}
                    ref={register({ required: false })}
                  />
                  {/* {errors.website && (
                    <p className="errormessage">Website is Required</p>
                  )} */}
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">Latitude:</label>
                  <input
                    type="number"
                    onWheel={(event) => event.currentTarget.blur()}
                    className="form-control underline-input"
                    name="latitude"
                    style={{ backgroundColor: "#f5f5f9" }}
                    onChange={inputEvent}
                    value={stateCust.latitude}
                    step="any"
                    ref={register}
                  />
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">Longitude:</label>
                  <input
                    type="number"
                    onWheel={(event) => event.currentTarget.blur()}
                    className="form-control underline-input"
                    name="longitude"
                    style={{ backgroundColor: "#f5f5f9" }}
                    onChange={inputEvent}
                    value={stateCust.longitude}
                    step="any"
                    ref={register}
                  />
                </Col>
              </Row>
            </div>
            <div className="form-group creatadmin">
              <Row>
                <Col md={3}>
                  <label className="area_rowslable_my">
                    Student App Form Link:
                  </label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="applicationFormLink"
                    style={{ backgroundColor: "#f5f5f9" }}
                    onChange={inputEvent}
                    value={stateCust.applicationFormLink}
                    ref={register}
                  />
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">
                    Student App Form:
                    {check1pointpermission(
                      710,
                      permissionData,
                      rolepermission
                    ) ? (
                      <span
                        style={{ color: "blue", cursor: "pointer" }}
                        onClick={() => setopenapplicationform(true)}
                      >
                        View Files
                      </span>
                    ) : null}
                  </label>
                  <Modal
                    isOpen={openapplicationform}
                    onRequestClose={() => setopenapplicationform(false)}
                    style={customStyles}
                    contentLabel="Example Modal"
                  >
                    <div>
                      <h5>Application Forms</h5>
                      <hr />
                      <TableContainer
                        style={{ marginTop: "10px" }}
                        component={Paper}
                      >
                        <Table
                          className={classes.table}
                          aria-label="simple table"
                        >
                          <TableHead
                            style={{ backgroundColor: "cornflowerblue" }}
                          >
                            <TableRow>
                              <TableCell>SN</TableCell>
                              <TableCell>Name</TableCell>
                              <TableCell align="right">View</TableCell>
                              <TableCell align="right">Delete</TableCell>
                            </TableRow>
                          </TableHead>
                          {applicationformlist?.length != null ? (
                            <TableBody>
                              {applicationformlist?.map((p, index) => (
                                <TableRow key={index}>
                                  <TableCell component="th" scope="row">
                                    {index + 1}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    {p.applicationFormName}
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    align="right"
                                  >
                                    {check1pointpermission(
                                      711,
                                      permissionData,
                                      rolepermission
                                    ) ? (
                                      <a
                                        onClick={() => {
                                          downloadAll(p.applicationFormPath);
                                        }}
                                        style={{ cursor: "pointer" }}
                                      >
                                        View
                                      </a>
                                    ) : null}
                                  </TableCell>
                                  {check1pointpermission(
                                    712,
                                    permissionData,
                                    rolepermission
                                  ) ? (
                                    <TableCell
                                      align="right"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // deletestudentappform(p, index);
                                        setApplicationDeleteId(p.id);
                                        setApplicationDeleteFileName(
                                          p.applicationFormPath
                                        );
                                        setApplicationDeleteOpen(true);
                                      }}
                                    >
                                      Delete
                                    </TableCell>
                                  ) : null}
                                  <Modal
                                    isOpen={modalApplicationDeleteOpen}
                                    onRequestClose={closeModal}
                                    style={customStyles}
                                    contentLabel="Example Modal"
                                  >
                                    <h4 style={{ marginTop: "10px" }}>
                                      Are you sure you want to Delete the
                                      Application Form ?
                                    </h4>
                                    <div
                                      style={{
                                        display: "flex",
                                        marginTop: "20px",
                                        justifyContent: "flex-end",
                                        gap: "10px",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      <button
                                        className="btn listing_addbutton ml-1"
                                        onClick={() => {
                                          deletestudentappform(p);
                                        }}
                                      >
                                        Delete
                                      </button>
                                      <button
                                        className="btn btn-secondary cancel_margin"
                                        onClick={closeModal}
                                      >
                                        Cancel
                                      </button>
                                    </div>
                                  </Modal>
                                </TableRow>
                              ))}
                            </TableBody>
                          ) : (
                            "No Files Available"
                          )}
                        </Table>
                      </TableContainer>
                      <button
                        type="submit"
                        className="btn btn-secondary"
                        style={{
                          marginRight: "20px",
                          marginTop: "20px",
                          float: "right",
                        }}
                        onClick={() => setopenapplicationform(false)}
                      >
                        Close
                      </button>
                    </div>
                  </Modal>
                  <input
                    type="file"
                    className="form-control underline-input"
                    onChange={chkappfilesize}
                    name="appForm"
                    style={{ backgroundColor: "#f5f5f9" }}
                    multiple
                    ref={register}
                  />
                  {appformfilesize && (
                    <p className="errormessage">
                      File Size cannot be more than 4 mb
                    </p>
                  )}
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">Logo:</label>
                  <img src={stateCust.logo} alt="" height="50px" />
                  <input
                    type="file"
                    className="form-control underline-input"
                    style={{ backgroundColor: "#f5f5f9" }}
                    onChange={(data) => {
                      uploadLogo(data);
                      chklogosize(data);
                    }}
                    accept=".jpg, .jpeg, .bmp, .gif, .png"
                  />
                  {logofilesize && (
                    <p className="errormessage">
                      File Size cannot be more than 4 mb
                    </p>
                  )}
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">banner:</label>
                  <img src={stateCust.banner} alt="" height="50px" />
                  <input
                    type="file"
                    className="form-control underline-input"
                    style={{ backgroundColor: "#f5f5f9" }}
                    onChange={(data) => {
                      uploadBanner(data);
                      chkbannersize(data);
                    }}
                    accept=".jpg, .jpeg, .bmp, .gif, .png"
                  />
                  {bannerfilesize && (
                    <p className="errormessage">
                      File Size cannot be more than 4 mb
                    </p>
                  )}
                </Col>
              </Row>
            </div>
            <div className="form-group creatadmin2">
              <Row>
                <Col md={3}>
                  <label className="area_rowslable_my">
                    Transactional Arrangement:
                  </label>
                  {check1pointpermission(
                    713,
                    permissionData,
                    rolepermission
                  ) ? (
                    <span
                      style={{ color: "blue", cursor: "pointer" }}
                      onClick={() => setopentransactionalform(true)}
                    >
                      View Files
                    </span>
                  ) : null}
                  <Modal
                    isOpen={opentransactionalform}
                    onRequestClose={() => setopentransactionalform(false)}
                    style={customStyles}
                    contentLabel="Example Modal"
                  >
                    <div>
                      <h5>Transactional Arrangement</h5>
                      <hr />
                      <TableContainer
                        style={{ marginTop: "10px" }}
                        component={Paper}
                      >
                        <Table
                          className={classes.table}
                          aria-label="simple table"
                        >
                          <TableHead
                            style={{ backgroundColor: "cornflowerblue" }}
                          >
                            <TableRow>
                              <TableCell>SN</TableCell>
                              <TableCell>Name</TableCell>
                              <TableCell align="right">View</TableCell>
                              <TableCell align="right">Delete</TableCell>
                            </TableRow>
                          </TableHead>
                          {transactionalarrangementlist?.length != null ? (
                            <TableBody>
                              {transactionalarrangementlist?.map((p, index) => (
                                <TableRow key={index}>
                                  <TableCell component="th" scope="row">
                                    {index + 1}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    {p.transactionalArrangementName}
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    align="right"
                                  >
                                    {permissionData?.includes(103) ? (
                                      <a
                                        onClick={() => {
                                          downloadAllTransactionalAgreement(
                                            p.transactionalArrangementName
                                          );
                                        }}
                                        style={{ cursor: "pointer" }}
                                      >
                                        View
                                      </a>
                                    ) : null}
                                  </TableCell>
                                  {check1pointpermission(
                                    715,
                                    permissionData,
                                    rolepermission
                                  ) ? (
                                    <TableCell
                                      align="right"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // deletetransactionalagreement(p, index);
                                        setTransactionalAgreementDeleteId(p.id);
                                        setTransactionalAgreementDeleteOpen(
                                          true
                                        );
                                      }}
                                    >
                                      Delete
                                    </TableCell>
                                  ) : null}
                                  <Modal
                                    isOpen={
                                      modalTransactionalAgreementDeleteOpen
                                    }
                                    onRequestClose={closeModal}
                                    style={customStyles}
                                    contentLabel="Example Modal"
                                  >
                                    <h4 style={{ marginTop: "10px" }}>
                                      Are you sure you want to Delete the
                                      Transactional Agreement ?
                                    </h4>
                                    <div
                                      style={{
                                        display: "flex",
                                        marginTop: "20px",
                                        justifyContent: "flex-end",
                                        gap: "10px",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      <button
                                        className="btn listing_addbutton ml-1"
                                        onClick={() => {
                                          deletetransactionalagreement(
                                            p,
                                            index
                                          );
                                        }}
                                      >
                                        Delete
                                      </button>
                                      <button
                                        className="btn btn-secondary cancel_margin"
                                        onClick={closeModal}
                                      >
                                        Cancel
                                      </button>
                                    </div>
                                  </Modal>
                                </TableRow>
                              ))}
                            </TableBody>
                          ) : (
                            "No Files Available"
                          )}
                        </Table>
                      </TableContainer>
                      <button
                        type="submit"
                        className="btn btn-secondary"
                        style={{
                          marginRight: "20px",
                          marginTop: "20px",
                          float: "right",
                        }}
                        onClick={() => setopentransactionalform(false)}
                      >
                        Close
                      </button>
                    </div>
                  </Modal>
                  <input
                    type="file"
                    multiple
                    onChange={chktransactionalsize}
                    className="form-control underline-input"
                    name="transactionalArrangement"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register}
                  />
                  {transacrionalfilesize && (
                    <p className="errormessage">
                      File Size cannot be more than 4 mb
                    </p>
                  )}
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">University:</label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="university"
                    onChange={inputEvent}
                    style={{ backgroundColor: "#f5f5f9" }}
                    value={stateCust.university}
                    ref={register}
                  />
                </Col>
                <Col md={6}>
                  <label className="area_rowslable_my">Overview:</label>
                  <textarea
                    type="text"
                    className="form-control underline-input"
                    name="overview"
                    style={{ backgroundColor: "#f5f5f9" }}
                    onChange={inputEvent}
                    value={stateCust.overview}
                    ref={register({ required: false })}
                  />
                  {errors.overview && (
                    <p className="errormessage">Overview is Required</p>
                  )}
                </Col>
              </Row>
            </div>
            {/* <div class="client_container2">
                <span>Provider Contact Details</span>
              </div> */}
            <div style={{ display: "flex", marginTop: "10px" }}>
              <p className="provider_containertitle">Contact Details</p>
              {/* <hr style={{ flex: "1", marginLeft: "20px" }} /> */}
            </div>
            <div className="form-group creatadmin2">
              <Row>
                <Col md={3}>
                  <label className="area_rowslable_my">Contact Person:</label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="contactPerson"
                    onChange={inputEvent}
                    style={{ backgroundColor: "#f5f5f9" }}
                    value={stateCust.contactPerson}
                    ref={register}
                  />
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">Designation:</label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="designation"
                    onChange={inputEvent}
                    style={{ backgroundColor: "#f5f5f9" }}
                    value={stateCust.designation}
                    ref={register}
                  />
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">Phone:</label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="phoneNumber"
                    onChange={inputEvent}
                    style={{ backgroundColor: "#f5f5f9" }}
                    value={stateCust.phoneNumber}
                    ref={register}
                  />
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">Mobile:</label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="mobile"
                    style={{ backgroundColor: "#f5f5f9" }}
                    onChange={inputEvent}
                    value={stateCust.mobile}
                    ref={register}
                  />
                </Col>
              </Row>
            </div>
            <div className="form-group creatadmin2">
              <Row>
                <Col md={3}>
                  <label className="area_rowslable_my">Email:</label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="email"
                    style={{ backgroundColor: "#f5f5f9" }}
                    onChange={inputEvent}
                    value={stateCust.email}
                    ref={register}
                  />
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">Status:</label>
                  <select
                    className={classes.selectdrop}
                    name="status"
                    ref={register}
                    style={{ backgroundColor: "#f5f5f9" }}
                    onInput={inputEvent}
                    value={stateCust.status}
                  >
                    <option value="1">Active</option>
                    <option value="0">Inactive</option>
                  </select>
                </Col>
                <Col md={6}>
                  <label className="area_rowslable_my">Remarks:</label>
                  <br />
                  <textarea
                    type="text"
                    style={{ width: "100%", backgroundColor: "#f5f5f9" }}
                    className="form-control underline-input"
                    name="remarks"
                    value={stateCust.remarks}
                    onChange={inputEvent}
                    ref={register}
                  />
                </Col>
              </Row>
            </div>
          </div>
          <Row>
            <Col md={6}>{null}</Col>
            <Col md={6}>
              <Row className="justify-content-end">
                <button type="submit" className="btn listing_addbutton ml-1">
                  Update
                </button>
              </Row>
            </Col>
          </Row>
        </form>
      </div>
      <div
        style={{
          padding: "1.5rem",
          border: "1.5rem solid #e9ebf2",
          marginTop: "0",
          marginBottom: "0",
        }}
      >
        <Row>
          <Col md={6}>
            <p className="provider_containertitle">Provider Branches</p>
          </Col>
          <Col md={6} className="d-flex justify-content-end my-1">
            <button
              className="btn listing_addbutton ml-1"
              onClick={() => openModal2()}
            >
              Add Branches
            </button>
          </Col>
        </Row>
        <Modal
          isOpen={modalisopen2}
          onRequestClose={() => setisopen2(false)}
          style={customStylesProviderBranch}
          // className="modalcontent"
          contentLabel="Example Modal"
        >
          <div>
            {/* <hr /> */}
            <form onSubmit={handleSubmit(createBranch)}>
              <Row>
                <Col md={6}>
                  <h5>Provider Branch</h5>
                </Col>
                <Col md={6}>
                  <div className="d-flex justify-content-end">
                    {/* <Row className="branch_buttonrow"> */}
                    <button
                      className="btn btn-secondary"
                      onClick={() => setisopen2(false)}
                      style={{ marginLeft: "20px" }}
                    >
                      Close
                    </button>
                    <button
                      type="submit"
                      className="btn listing_addbutton ml-1"
                    >
                      Create Branch
                    </button>
                    {/* </Row> */}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <label className="area_rowslable_my">
                    Branch Name<span style={{ color: "red" }}>*</span>:
                  </label>
                  <input
                    name="branchname"
                    // onChange={inputEvent}
                    // value={stateCust.id}
                    ref={register({ required: true })}
                    style={{ backgroundColor: "#f5f5f9" }}
                    type="text"
                    className="form-control underline-input"
                  />
                  {errors.branchname && (
                    <p className="errormessage">Name is Required</p>
                  )}
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">
                    {" "}
                    Address<span style={{ color: "red" }}>*</span>:
                  </label>
                  <input
                    name="branchaddress"
                    // onChange={inputEvent}
                    // value={stateCust.id}
                    ref={register({ required: true })}
                    style={{ backgroundColor: "#f5f5f9" }}
                    type="textarea"
                    className="form-control underline-input"
                  />
                  {errors.branchaddress && (
                    <p className="errormessage">Address is Required</p>
                  )}
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">
                    Country<span style={{ color: "red" }}>*</span>:
                  </label>
                  <CountryDropdown
                    defaultOptionLabel="- Select Country -"
                    className="countrydropdown2 form-control underline-input"
                    value={country3}
                    style={{ backgroundColor: "#f5f5f9" }}
                    onChange={(val) => selectCountry3(val)}
                    required
                  />
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">
                    State<span style={{ color: "red" }}>*</span>:
                  </label>
                  {country3 == "Nepal" ? (
                    <select
                      className={classes.selectdrop}
                      name="gender"
                      value={region3}
                      style={{ backgroundColor: "#f5f5f9" }}
                      onChange={(e) => selectRegion3(e.target.value)}
                    >
                      <option value="">- Select Province -</option>
                      <option value="Province No. 1">Province No. 1</option>
                      <option value="Madhesh Province">Madhesh Province</option>
                      <option value="Bagmati Province">Bagmati Province</option>
                      <option value="Gandaki Province">Gandaki Province</option>
                      <option value="Lumbini Province">Lumbini Province</option>
                      <option value="Karnali Province">Karnali Province</option>
                      <option value="Sudurpashchim Province">
                        Sudurpashchim Province
                      </option>
                    </select>
                  ) : (
                    <RegionDropdown
                      defaultOptionLabel="- Select State -"
                      className="countrydropdown2 form-control underline-input"
                      country={country3}
                      value={region3}
                      onChange={(val) => selectRegion3(val)}
                      required
                    />
                  )}
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <label className="area_rowslable_my">
                    City<span style={{ color: "red" }}>*</span>:
                  </label>
                  <input
                    name="city"
                    // onChange={inputEvent}
                    // value={stateCust.id}
                    ref={register({ required: true })}
                    style={{ backgroundColor: "#f5f5f9" }}
                    type="textarea"
                    className="form-control underline-input"
                  />
                  {errors.city && (
                    <p className="errormessage">City is Required</p>
                  )}
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">Latitude:</label>
                  <input
                    name="latitude"
                    // onChange={inputEvent}
                    // value={stateCust.id}
                    ref={register({ required: false })}
                    type="number"
                    style={{ backgroundColor: "#f5f5f9" }}
                    onWheel={(event) => event.currentTarget.blur()}
                    className="form-control underline-input"
                    step="any"
                  />
                  {errors.latitude && (
                    <p className="errormessage">Latitude is Required</p>
                  )}
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">Longitude:</label>
                  <input
                    name="longitude"
                    // onChange={inputEvent}
                    // value={stateCust.id}
                    ref={register({ required: false })}
                    style={{ backgroundColor: "#f5f5f9" }}
                    type="number"
                    onWheel={(event) => event.currentTarget.blur()}
                    className="form-control underline-input"
                    step="any"
                  />
                  {errors.longitude && (
                    <p className="errormessage">Longitude is Required</p>
                  )}
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">Commission %:</label>
                  <input
                    name="commissionpercentage"
                    style={{ textAlign: "right", backgroundColor: "#f5f5f9" }}
                    // onChange={inputEvent}
                    // value={stateCust.id}
                    ref={register({ required: false })}
                    type="number"
                    onWheel={(event) => event.currentTarget.blur()}
                    className="form-control underline-input"
                  />
                  {errors.commissionpercentage && (
                    <p className="errormessage">Commission % is Required</p>
                  )}
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <label className="area_rowslable_my">Agreement:</label>
                  <input
                    name="attachment"
                    multiple
                    ref={register}
                    type="file"
                    style={{ backgroundColor: "#f5f5f9" }}
                    onChange={chkagreementsize}
                    // accept=".pdf, .doc, .docx"
                    className="form-control underline-input"
                  />
                  {agreementfilesize && (
                    <p className="errormessage">
                      File Size cannot be more than 4 mb
                    </p>
                  )}
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <label
                    className="area_rowslable_my"
                    style={{ marginTop: "15px", marginBottom: "15px" }}
                  >
                    Contact Details
                    <span style={{ color: "red" }}>*</span>:
                  </label>
                  <CKEditor
                    editor={ClassicEditor}
                    config={editorConfiguration}
                    style={{ backgroundColor: "#f5f5f9" }}
                    data="<p style='font-size:10px'>Type your Content</p>"
                    onReady={(editor) => {
                      //console.log("Editor is ready to use!", editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setdescriptionState(data);
                    }}
                  />
                </Col>
              </Row>
              {/* <Row className="branch_buttonrow">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={() => setisopen2(false)}
                    style={{ marginLeft: "20px" }}
                  >
                    Close
                  </button>
                  <button
                    type="submit"
                    style={{ marginLeft: "20px" }}
                    className="btn btn-primary"
                  >
                    Create Branch
                  </button>
                </Row> */}
            </form>
          </div>
        </Modal>
        <div className="invoice_adddetails quotation_table">
          <TableContainer style={{ marginTop: "10px" }} component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead style={{ backgroundColor: "cornflowerblue" }}>
                <TableRow>
                  <TableCell align="center">SN</TableCell>
                  <TableCell>Branch Name</TableCell>
                  <TableCell align="right">Address</TableCell>
                  <TableCell align="right">Commission %</TableCell>
                  <TableCell align="right">Attachments</TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {institutionBranches?.map((p, index) => (
                  <TableRow key={index}>
                    <TableCell align="center" component="th" scope="row">
                      {index + 1}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {p.branchName}
                    </TableCell>
                    <TableCell align="right">{p.branchAddress}</TableCell>

                    <TableCell align="right">
                      {p.commissionPercentage}
                    </TableCell>
                    <TableCell align="right">
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setbranchagreementopen(true);
                          setfileinstitutionid(p.institutionId);
                          setfilebranchId(p.branchId);
                          setbranchagreementfiles(
                            p.branchTransactionalAgreement
                          );
                        }}
                      >
                        View
                      </a>
                      <Modal
                        isOpen={branchagreementopen}
                        onRequestClose={() => setbranchagreementopen(false)}
                        style={customStyles}
                        contentLabel="Example Modal"
                      >
                        <div>
                          <h5>Application Forms</h5>
                          <hr />
                          <TableContainer
                            style={{ marginTop: "10px" }}
                            component={Paper}
                          >
                            <Table
                              className={classes.table}
                              aria-label="simple table"
                            >
                              <TableHead
                                style={{
                                  backgroundColor: "cornflowerblue",
                                }}
                              >
                                <TableRow>
                                  <TableCell>SN</TableCell>
                                  <TableCell>Name</TableCell>
                                  <TableCell align="right">View</TableCell>
                                </TableRow>
                              </TableHead>
                              {branchagreementfiles?.length != null ? (
                                <TableBody>
                                  {branchagreementfiles?.map((p, index) => (
                                    <TableRow key={index}>
                                      <TableCell component="th" scope="row">
                                        {index + 1}
                                      </TableCell>
                                      <TableCell component="th" scope="row">
                                        {p.branchTransactionalAgreementName}
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        align="right"
                                      >
                                        {check1pointpermission(
                                          707,
                                          permissionData,
                                          rolepermission
                                        ) ? (
                                          <a
                                            onClick={() => {
                                              downloadAllBranchFile(
                                                p.branchTransactionalAgreementPath
                                              );
                                            }}
                                            style={{ cursor: "pointer" }}
                                          >
                                            {p.branchTransactionalAgreementName}
                                          </a>
                                        ) : null}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              ) : (
                                "No Files Available"
                              )}
                            </Table>
                          </TableContainer>
                          <button
                            className="btn btn-secondary"
                            style={{
                              marginRight: "20px",
                              marginTop: "20px",
                              float: "right",
                            }}
                            onClick={() => setbranchagreementopen(false)}
                          >
                            Close
                          </button>
                        </div>
                      </Modal>
                    </TableCell>
                    {check1pointpermission(
                      708,
                      permissionData,
                      rolepermission
                    ) ? (
                      <TableCell
                        align="right"
                        onClick={() => openModal3(p.branchId)}
                        style={{ cursor: "pointer" }}
                      >
                        View Courses
                      </TableCell>
                    ) : (
                      <TableCell>{null}</TableCell>
                    )}
                    {check1pointpermission(
                      716,
                      permissionData,
                      rolepermission
                    ) ? (
                      <TableCell
                        align="right"
                        onClick={() => openModal(index)}
                        style={{ cursor: "pointer" }}
                      >
                        Edit
                      </TableCell>
                    ) : (
                      <TableCell>{null}</TableCell>
                    )}
                    <Modal
                      isOpen={modalisopen}
                      onRequestClose={() => setisopen(false)}
                      style={customStylesProviderBranch}
                      contentLabel="Example Modal"
                    >
                      <div>
                        {/* <h5>Edit Provider Branch</h5> */}
                        {/* <hr /> */}
                        <form onSubmit={handleSubmit(submitBranch)}>
                          <Row>
                            <Col md={6}>
                              <h5>Edit Provider Branch</h5>
                            </Col>
                            <Col md={6}>
                              <div className="d-flex justify-content-end">
                                {/* <Row className="branch_buttonrow"> */}
                                <button
                                  className="btn btn-secondary"
                                  style={{ marginLeft: "20px" }}
                                  onClick={() => setisopen(false)}
                                >
                                  Close
                                </button>
                                <button
                                  type="submit"
                                  className="btn listing_addbutton ml-1"
                                >
                                  Update Branch
                                </button>
                                {/* </Row> */}
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={3}>
                              <label className="area_rowslable_my">
                                Branch Name:
                              </label>
                              <input
                                name="branchName"
                                // onChange={inputEvent}
                                value={stateCust.id}
                                value={
                                  institutionBranches[institutionid]?.branchName
                                }
                                onChange={inputEvent2}
                                type="text"
                                style={{ backgroundColor: "#f5f5f9" }}
                                className="form-control underline-input"
                                required
                              />
                            </Col>
                            <Col md={3}>
                              <label className="area_rowslable_my">
                                Branch Address:
                              </label>
                              <input
                                name="branchAddress"
                                // onChange={inputEvent}
                                value={stateCust.id}
                                style={{ backgroundColor: "#f5f5f9" }}
                                value={
                                  institutionBranches[institutionid]
                                    ?.branchAddress
                                }
                                onChange={inputEvent2}
                                type="textarea"
                                className="form-control underline-input"
                                required
                              />
                            </Col>
                            <Col md={3}>
                              <label className="area_rowslable_my">
                                Country:
                              </label>
                              <CountryDropdown
                                defaultOptionLabel="- Select Country -"
                                className="countrydropdown2 form-control underline-input"
                                value={country2}
                                style={{ backgroundColor: "#f5f5f9" }}
                                onChange={(val) => selectCountry2(val)}
                                required
                              />
                            </Col>
                            <Col md={3}>
                              <label className="area_rowslable_my">
                                State:
                              </label>
                              {country2 == "Nepal" ? (
                                <select
                                  className={classes.selectdrop}
                                  name="gender"
                                  style={{ backgroundColor: "#f5f5f9" }}
                                  value={region2}
                                  onChange={(e) =>
                                    selectRegion2(e.target.value)
                                  }
                                >
                                  <option value="Province No. 1">
                                    Province No. 1
                                  </option>
                                  <option value="Madhesh Province">
                                    Madhesh Province
                                  </option>
                                  <option value="Bagmati Province">
                                    Bagmati Province
                                  </option>
                                  <option value="Gandaki Province">
                                    Gandaki Province
                                  </option>
                                  <option value="Lumbini Province">
                                    Lumbini Province
                                  </option>
                                  <option value="Karnali Province">
                                    Karnali Province
                                  </option>
                                  <option value="Sudurpashchim Province">
                                    Sudurpashchim Province
                                  </option>
                                </select>
                              ) : (
                                <RegionDropdown
                                  className="countrydropdown2"
                                  defaultOptionLabel="- Select State -"
                                  className="form-control underline-input"
                                  country={country2}
                                  style={{ backgroundColor: "#f5f5f9" }}
                                  value={region2}
                                  onChange={(val) => selectRegion2(val)}
                                  required
                                />
                              )}
                            </Col>
                          </Row>

                          <Row>
                            <Col md={3}>
                              <label className="area_rowslable_my">City:</label>
                              <input
                                name="city"
                                // onChange={inputEvent}
                                value={stateCust.id}
                                type="textarea"
                                style={{ backgroundColor: "#f5f5f9" }}
                                onChange={inputEvent2}
                                value={institutionBranches[institutionid]?.city}
                                className="form-control underline-input"
                                required
                              />
                            </Col>
                            <Col md={3}>
                              <label className="area_rowslable_my">
                                Latitude:
                              </label>
                              <input
                                name="lattitude"
                                // onChange={inputEvent}
                                value={stateCust.id}
                                style={{ backgroundColor: "#f5f5f9" }}
                                value={
                                  institutionBranches[institutionid]?.lattitude
                                }
                                onChange={inputEvent2}
                                type="number"
                                className="form-control underline-input"
                                onWheel={(event) => event.currentTarget.blur()}
                                step="any"
                              />
                            </Col>
                            <Col md={3}>
                              <label className="area_rowslable_my">
                                Longitude:
                              </label>
                              <input
                                name="longitude"
                                // onChange={inputEvent}
                                value={stateCust.id}
                                style={{ backgroundColor: "#f5f5f9" }}
                                value={
                                  institutionBranches[institutionid]?.longitude
                                }
                                onChange={inputEvent2}
                                type="number"
                                className="form-control underline-input"
                                onWheel={(event) => event.currentTarget.blur()}
                                step="any"
                              />
                            </Col>
                            <Col md={3}>
                              <label className="area_rowslable_my">
                                Commission %:
                              </label>
                              <input
                                name="commissionPercentage"
                                // onChange={inputEvent}
                                value={stateCust.id}
                                value={
                                  institutionBranches[institutionid]
                                    ?.commissionPercentage
                                }
                                onChange={inputEvent2}
                                type="number"
                                style={{
                                  textAlign: "right",
                                  backgroundColor: "#f5f5f9",
                                }}
                                onWheel={(event) => event.currentTarget.blur()}
                                className="form-control underline-input"
                                required
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col md={3}>
                              <label className="area_rowslable_my">
                                Agreement:
                              </label>

                              <input
                                name="attachment"
                                multiple
                                ref={register}
                                type="file"
                                style={{ backgroundColor: "#f5f5f9" }}
                                onChange={chkeditagreementsize}
                                // accept=".pdf, .doc, .docx"
                                className="form-control underline-input"
                              />
                              {editagreementsize && (
                                <p className="errormessage">
                                  File Size cannot be more than 4 mb
                                </p>
                              )}
                            </Col>
                          </Row>
                          <Row>
                            <Col md={12}>
                              <label
                                className="area_rowslable_my"
                                style={{
                                  marginTop: "15px",
                                  marginBottom: "15px",
                                }}
                              >
                                Contact Details:
                              </label>
                              <CKEditor
                                editor={ClassicEditor}
                                config={editorConfiguration}
                                data={
                                  descriptionstate2
                                  // institutionBranches[institutionid]
                                  //   ?.branchContactDetails
                                }
                                onReady={(editor) => {
                                  console.log(
                                    "Editor is ready to use!",
                                    editor
                                  );
                                }}
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  setdescriptionstate2(data);
                                }}
                              />
                            </Col>
                          </Row>
                          {/* <Row> */}

                          {/* <Col md={3}>
                            <label className="area_rowslable_my" >Agreement:</label>
                          <input
                            name="attachment"
                            onChange={inputEvent}
                            value={stateCust.id}
                            type="file"
                            accept=".pdf, .doc, .docx"
                            className="form-control underline-input"
                          />
                        </Col> */}
                          {/* </Row> */}
                          {/* <Row className="branch_buttonrow">
                            <button
                              type="submit"
                              className="btn btn-primary"
                              style={{ marginLeft: "20px" }}
                              onClick={() => setisopen(false)}
                            >
                              Close
                            </button>
                            <button
                              type="submit"
                              style={{ marginLeft: "20px" }}
                              className="btn btn-primary"
                            >
                              Update Branch
                            </button>
                          </Row> */}
                        </form>
                      </div>
                    </Modal>
                    <Modal
                      isOpen={modalisopen3}
                      onRequestClose={() => setisopen3(false)}
                      style={customStyles}
                      contentLabel="Example Modal"
                    >
                      <div>
                        <h5>Courses</h5>
                        <hr />
                        <TableContainer
                          style={{ marginTop: "10px" }}
                          component={Paper}
                        >
                          <Table
                            className={classes.table}
                            aria-label="simple table"
                          >
                            <TableHead
                              style={{ backgroundColor: "cornflowerblue" }}
                            >
                              <TableRow>
                                <TableCell>SN</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell align="right">Code</TableCell>
                                <TableCell align="right">App Fees</TableCell>
                                <TableCell align="right">Duration</TableCell>
                              </TableRow>
                            </TableHead>
                            {coursedetails?.length != null ? (
                              <>
                                <TableBody>
                                  {coursedetails?.map((p, index) => (
                                    <TableRow key={index}>
                                      <TableCell component="th" scope="row">
                                        {index + 1}
                                      </TableCell>
                                      <TableCell component="th" scope="row">
                                        {p.name}
                                      </TableCell>
                                      <TableCell component="th" scope="row">
                                        {p.courseCode}
                                      </TableCell>
                                      <TableCell align="right">
                                        {p.appFees}
                                      </TableCell>
                                      <TableCell align="right">
                                        {p.duration}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </>
                            ) : (
                              "No Course Available"
                            )}
                          </Table>
                        </TableContainer>
                        <Row>
                          <Col md={6}>{null}</Col>
                          <Col md={6}>
                            <div className={classes.root}>
                              <Pagination
                                count={getpageno}
                                onChange={handlepage}
                                shape="rounded"
                              />
                            </div>
                          </Col>
                        </Row>
                        <button
                          type="submit"
                          className="btn btn-secondary"
                          style={{
                            marginRight: "20px",
                            marginTop: "20px",
                            float: "right",
                          }}
                          onClick={() => setisopen3(false)}
                        >
                          Close
                        </button>
                      </div>
                    </Modal>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
      {/* </Card> */}
    </div>
  );
}

export default EditCollege;
