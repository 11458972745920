import React, { useEffect } from "react";
import Modal from "react-modal";
import { Button } from "reactstrap";
import { useForm } from "react-hook-form";
import { customStyles } from "../../../Utilities/CSSUtilities";

Modal.setAppElement("#root");

const AddWorkFlowStepModal = ({
  isOpen,
  onClose,
  onSubmit,
  applicationStatus,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const handleFormSubmit = (data) => {
    onSubmit(data);
    reset();
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} style={customStyles}>
      <div className="modal-content">
        <span
          className="close"
          onClick={onClose}
          style={{ textAlign: "right", cursor: "pointer" }}
        >
          &times;
        </span>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <div
            className="form-group creatadmin"
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className="mb-4">
              <label htmlFor="stepName">
                Workflow Step Name<span style={{ color: "red" }}>*</span>:
              </label>
              <input
                type="text"
                id="stepName"
                name="stepName"
                className="form-control underline-input"
                ref={register({ required: true })}
              />
              {errors.stepName && (
                <span className="error" style={{ color: "red" }}>
                  Step Name is required
                </span>
              )}
            </div>
            <div className="mb-5">
              <label htmlFor="workflowStepsStatus">
                Workflow Step Status<span style={{ color: "red" }}>*</span>:
              </label>
              <select
                className="form-control underline-input"
                id="workflowStepsStatus"
                name="workflowStepsStatus"
                style={{ backgroundColor: "#f5f5f9" }}
                ref={register({ required: true })}
              >
                <option value="">- Select Application Status -</option>
                {!applicationStatus ? (
                  <option>Loading...</option>
                ) : (
                  applicationStatus.map((item, index) => (
                    <option key={index} value={item.name}>
                      {item.name}
                    </option>
                  ))
                )}
              </select>
              {errors.workflowStepsStatus && (
                <p className="errormessage">Step Status is Required</p>
              )}
            </div>
            <div
              className="modal-buttons mb-4"
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button color="secondary" type="button" onClick={onClose}>
                Cancel
              </Button>
              <Button
                type="submit"
                color="success"
                className="btn listing_addbutton ml-1"
              >
                Submit
              </Button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default AddWorkFlowStepModal;
