import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CardText, Col, Row, Button, Table } from "reactstrap";
import axios from "axios";
import base_url from "./../../../api/bootapi";
import { ToastContainer, toast } from "react-toastify";
import Modal from "react-modal";
import Pagination from "@mui/material/Pagination";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import AddIcon from "@mui/icons-material/Add";
import "./../../css/Responsive.css";
import { useDispatch, useSelector } from "react-redux";
import {
  setdashboardcheck,
  selectUser,
  logout,
} from "./../../features/userSlice";
import { useForm } from "react-hook-form";
import { customStyles, useStyles } from "../../../Utilities/CSSUtilities";
import callsaveauditapi from "../../../services/auditservice";
import {
  check1pointpermission,
  check4pointpermission,
  checksettingpermission,
} from "../../Permission/permission";
import sort from "../../images/sort.svg";
import DeleteModal from "../../Modal/Masters/DeleteModal";
import EditWorkFlowModal from "../../Modal/Masters/EditWorkFlowModal";

function AllWorkFlowLists(props) {
  const user = useSelector(selectUser);
  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  const [backdropopen, setbackdropopen] = useState(false);
  const [allWorkFlowList, setAllWorkFlowList] = useState(null);
  const classes = useStyles();
  const [workflowdata, setworkflowdata] = useState();
  const ipaddress = localStorage.getItem("AdminIpAddress");
  const navigate = useNavigate();
  const [pageno, setpageno] = useState(0);
  const [getpageno, setgetpageno] = useState();
  const [sortState, setsortState] = useState("workflowId");
  const [direction, setdirection] = useState("DESC");
  const [rows, setrows] = React.useState(50);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const isw_user_name = localStorage.getItem("isw_user_name");
  const isw_adminId = localStorage.getItem("isw_adminId");
  const isw_user_email = localStorage.getItem("isw_user_email");
  const [totalUser, settotalUser] = useState();
  const [countnumber, setcountnumber] = useState();
  const [numberofElements, setnumberofElements] = useState();
  const [paginationpageno, setpaginationpageno] = useState(1);
  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        navigate("/");
      }
    });
    getPermissions();
  }, [rows]);

  useEffect(() => {
    let width = document.querySelector("table")?.offsetWidth;
    if (document.querySelector(".scroller")) {
      document.querySelector(".scroller").style.width = `${width}px`;
    }
  }, [allWorkFlowList]);

  let scrolling = false;
  const scrolldiv = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow .table-responsive");
    table.scrollTo(scroller.scrollLeft, 0);
  };

  const scrolltable = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow  .table-responsive");
    scroller.scrollTo(table.scrollLeft, 0);
  };

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);

    if (check4pointpermission(1569, 1574, permission_obj, resource_obj)) {
      //if (available_permission) {
      dispatch(setdashboardcheck(43));

      if (localStorage.getItem("workflowPageNo")) {
        let currentPage = localStorage.getItem("workflowPageNo");
        setpageno(currentPage);
        setpaginationpageno(Number(currentPage) + 1);
        getallWorkflow(currentPage);
      } else {
        setpageno(0);
        setpaginationpageno(1);
        getallWorkflow(0);
      }
      // } else {
      //   navigate("/errorPage");
      // }
    } else {
      navigate("/errorPage");
    }
  };

  const handlepage = (event, value) => {
    setpageno(value);
    getallWorkflow(value - 1);
    setpaginationpageno(value);
  };

  const getallWorkflow = (value1) => {
    setbackdropopen(true);
    axios
      .get(`${base_url.api2}/getAllWorkflow`, {
        headers: {
          Authorization: jwtToken,
          columnName: sortState,
          direction: direction,
          page: Number(value1),
          size: Number(rows),
        },
      })
      .then((response) => {
        setAllWorkFlowList(response.data.data.content);
        setgetpageno(response.data.data.totalPages);
        settotalUser(response.data.data.totalElements);
        setcountnumber(response.data.data.number);
        setnumberofElements(response.data.data.numberOfElements);
        setsearch(false);
        setworkflowdata("all");
        try {
          document.getElementById("myForm").reset();
        } catch (error) {
          //console.log(error);
        }
        setbackdropopen(false);
      })
      .catch((error) => {
        setbackdropopen(false);
        // toast.error(JSON.stringify(error.response.data.message));
        handle403error(error);
      });
  };

  const afterOpenModal = () => {
    //console.log("hello");
    // subtitle.style.color = '#f00';
  };

  const closeModal = () => {
    setIsOpen2(false);
  };

  const [modalIsOpen2, setIsOpen2] = React.useState(false);
  const addWorkflow = (e) => {
    setIsOpen2(true);
    //console.log(e.target.id);
  };

  const viewWorkflow = (id, e) => {
    localStorage.setItem("workflowPageNo", pageno);
    localStorage.setItem("current_allWorkFlowList_id", id);
    //console.log(id);
    navigate({ pathname: "/view-workflow/" + id });
  };

  const deleteWorkflow = (id, name) => {
    axios
      .delete(`${base_url.api2}/deleteWorkflowById`, {
        headers: {
          Authorization: jwtToken,
          workflowId: id,
        },
      })
      .then((response) => {
        toast.success(response.data.data.message);

        let log = isw_user_name + " Deleted WorkFlow " + name + ".";
        callsaveauditapi(log, dispatch, navigate);
        getallWorkflow(pageno);
      })
      .catch((error) => {
        toast.error(JSON.stringify(error.response.data.message));
        handle403error(error);
      });
  };

  const createNewWorkflow = (data2) => {
    let workflowName = data2.name.trim();
    let workflowType = data2.workflowType;
    let status = data2.status;
    let workflowId = 0;
    let data = {
      workflowId,
      workflowType,
      status,
      workflowName,
    };
    //console.log(data);
    axios
      .post(`${base_url.api2}/createWorkflow`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        if (response.data.status_code == 200) {
          //console.log(response.data);
          toast.success(response.data.message);

          let log =
            isw_user_name +
            " Created Workflow " +
            response.data.data.workflowName +
            " of Type " +
            response.data.data.workflowType +
            ".";
          callsaveauditapi(log, dispatch, navigate);
          getallWorkflow(0);
          setIsOpen2(false);
        } else {
          toast.error(response.message);
        }
      })
      .catch((error) => {
        toast.error(JSON.stringify(error.response.data.message));
        handle403error(error);
      });
  };

  const [searchdata, setsearch] = useState();
  const [searchinput, setsearchinput] = useState();
  const submitSearch = (e) => {
    setbackdropopen(true);
    let srchdata = e.target[0].value;
    setsearchinput(srchdata);
    setworkflowdata("all");
    setpageno(0);
    axios
      .get(`${base_url.api2}/searchWorkflow`, {
        headers: {
          Authorization: jwtToken,
          searchQuery: srchdata,
          page: Number(0),
          size: Number(rows),
        },
      })
      .then((response) => {
        setAllWorkFlowList(response.data.data.content);
        setgetpageno(response.data.data.totalPages);
        settotalUser(response.data.data.totalElements);
        setcountnumber(response.data.data.number);
        setnumberofElements(response.data.data.numberOfElements);
        setsearch(true);
        setbackdropopen(false);
      })
      .catch((error) => {
        setbackdropopen(false);
        toast.error(JSON.stringify(error.response.data.message));
        handle403error(error);
      });
  };

  const handlesearch = (e, value) => {
    setbackdropopen(true);
    //console.log(value);
    setpaginationpageno(value);
    axios
      .get(`${base_url.api2}/searchWorkflow`, {
        headers: {
          Authorization: jwtToken,
          searchQuery: searchinput,
          page: Number(pageno),
          size: Number(rows),
        },
      })
      .then((response) => {
        setAllWorkFlowList(response.data.data.content);
        setgetpageno(response.data.data.totalPages);
        setsearch(true);
        setbackdropopen(false);
      })
      .catch((error) => {
        setbackdropopen(false);
        toast.error(JSON.stringify(error.response.data.message));
        handle403error(error);
      });
  };
  const submitrows = (e) => {
    // if (e.target[0].value=='100') {
    //     getallWorkflow(0);
    // }
    setbackdropopen(true);
    if (searchinput != null) {
      axios
        .get(`${base_url.api2}/searchWorkflow`, {
          headers: {
            Authorization: jwtToken,
            searchQuery: searchinput,
            page: Number(pageno),
            size: Number(rows),
          },
        })
        .then((response) => {
          //console.log(response.data.data);
          var client_admin = response.data.data.content;
          setAllWorkFlowList(response.data.data.content);
          setgetpageno(response.data.data.totalPages);
          setsearch(true);
          setgetpageno(response.data.data.totalPages);
          setbackdropopen(false);
        })
        .catch((error) => {
          setbackdropopen(false);
          toast.error(JSON.stringify(error.response.data.message));
          handle403error(error);
        });
    } else {
      // getallWorkflow(pageno);
      getallWorkflow(0);
    }
  };

  const sortClient = (e) => {
    setbackdropopen(true);
    setsortState(e.target.id);
    if (direction == "DESC") {
      setdirection("ASC");
    } else if (direction == "ASC") {
      setdirection("DESC");
    }
    axios
      .get(`${base_url.api2}/getAllWorkflow`, {
        headers: {
          Authorization: jwtToken,
          columnName: e.target.id,
          direction: direction,
          page: Number(pageno),
          size: Number(rows),
        },
      })
      .then((response) => {
        //console.log(response.data.data);
        setgetpageno(response.data.data.totalPages);
        setAllWorkFlowList(response.data.data.content);
        setsearch(false);
        setbackdropopen(false);
        try {
          document.getElementById("myForm").reset();
        } catch (error) {
          //console.log(error);
        }
      })
      .catch((error) => {
        setbackdropopen(false);
        // toast.error(JSON.stringify(error.response.data.message));
        handle403error(error);
      });
  };
  const handleRows = (event) => {
    setrows(event.target.value);
  };

  // Change Status of location

  const changeStatus = (elem, p) => {
    if (p.status === 1) {
      let data = {
        workflowId: elem.target.id,
        status: Number(0),
      };
      axios
        .post(`${base_url.api2}/updateWorkflowStepOrderByStepId`, data, {
          headers: {
            Authorization: jwtToken,
          },
        })
        .then((res) => {
          toast.success("Status changed");
          getallWorkflow(pageno);
        })
        .catch((err) => {
          handle403error(err);
        });
    } else {
      let data = {
        workflowId: elem.target.id,
        status: Number(1),
      };
      axios
        .post(`${base_url.api2}/updateWorkflowStepOrderByStepId`, data, {
          headers: {
            Authorization: jwtToken,
          },
        })
        .then((res) => {
          toast.success("Status changed");
          getallWorkflow(pageno);
        })
        .catch((err) => {
          handle403error(err);
        });
    }
  };
  const [modalIsOpen3, setIsOpen3] = React.useState(false);
  const [editworkflowid, seteditworkflowid] = useState();
  const [editworkflowname, seteditworkflowname] = useState();
  const [editworkflowtype, seteditworkflowtype] = useState();
  const [editworkflowstatus, seteditworkflowstatus] = useState();
  const openeditWorkflow = (elem, p) => {
    seteditworkflowid(p.workflowId);
    seteditworkflowname(p.workflowName);
    seteditworkflowtype(p.workflowType);
    seteditworkflowstatus(p.status);
    setIsOpen3(true);
    // navigate("/edit-area/" + area_Id);
  };
  const Updateworkflow = (e) => {
    let data = {
      workflowId: editworkflowid,
      workflowType: editworkflowtype,
      status: editworkflowstatus,
      workflowName: editworkflowname,
    };
    //console.log(data);
    axios
      .post(`${base_url.api2}/updateWorkflowStepOrderByStepId`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        //console.log(response.data.data.data);
        toast.success("Success");
        setIsOpen3(false);

        let log = isw_user_name + " Edited Workflow " + editworkflowname + ".";
        callsaveauditapi(log, dispatch, navigate);
        getallWorkflow(pageno);
      })
      .catch((error) => {
        handle403error(error);
        toast.error(JSON.stringify(error.response.message));
      });
  };

  const searchbyworkflowtype = (e) => {
    setbackdropopen(true);
    setworkflowdata(e.target.value);
    if (e.target.value === "all") {
      getallWorkflow(pageno);
    } else {
      axios
        .get(`${base_url.api2}/getWorkflowByWorkflowTypePaging`, {
          headers: {
            Authorization: jwtToken,
            workflowType: e.target.value,
            columnName: sortState,
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          },
        })
        .then((response) => {
          setAllWorkFlowList(response.data.data.content);
          setgetpageno(response.data.data.totalPages);
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          setsearch(false);
          try {
            document.getElementById("myForm").reset();
          } catch (error) {
            //console.log(error);
          }
          setbackdropopen(false);
        })
        .catch((error) => {
          setAllWorkFlowList(null);
          setbackdropopen(false);
          handle403error(error);
          toast.error(JSON.stringify(error.response.data.message));
        });
    }
  };

  const searchbystatus = (event) => {
    setbackdropopen(true);
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const value = optionElement.getAttribute("value");
    if (value === "all") {
      getallWorkflow(pageno);
    } else {
      axios
        .get(`${base_url.api2}/getWorkflowByStatus`, {
          headers: {
            Authorization: jwtToken,
            status: Number(value),
            columnName: sortState,
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          },
        })
        .then((response) => {
          setgetpageno(response.data.data.totalPages);
          setAllWorkFlowList(response.data.data.content);
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          setsearch(false);
          setbackdropopen(false);
          try {
            document.getElementById("myForm").reset();
          } catch (error) {
            //console.log(error);
          }
        })
        .catch((err) => {
          setbackdropopen(false);
          handle403error(err);
        });
    }
  };

  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            navigate("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };
  return (
    <div>
      <ToastContainer />
      <Backdrop className={classes.backdrop} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* <div className="commoncomponent_cardcss"> */}
      <div
        class="page-head d-flex justify-content-between p-4"
        style={{ marginTop: "4.3rem", marginBottom: "-20px" }}
      >
        <ul class="breadcrums">
          <h3 class="page-title bold">WorkFlow</h3>
          <Link to="/" class="b-link">
            Home
          </Link>
          &raquo;<a class="b-link">Settings</a>&raquo;
          <a class="b-link active">WorkFlow</a>
        </ul>
        {/* <a href="workload-dashboard.html" class="back-btn">Back</a> */}
        {check1pointpermission(1570, permissionData, rolepermission) ? (
          <a className="sent-button" onClick={addWorkflow}>
            Add
          </a>
        ) : null}
        <Modal
          isOpen={modalIsOpen2}
          // onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <form onSubmit={handleSubmit(createNewWorkflow)}>
            <div>
              <h3 style={{ color: "black" }}>Workflow</h3>
              <div className="form-group creatadmin">
                <Row>
                  <Col>
                    <label>
                      Name
                      <span style={{ color: "red" }}>*</span>:
                    </label>
                    <input
                      type="text"
                      name="name"
                      className="form-control underline-input"
                      ref={register({ required: true })}
                    />
                    {errors.name && (
                      <p className="errormessage">Name is Required</p>
                    )}
                  </Col>
                </Row>
              </div>
              <div className="form-group creatadmin">
                <Row>
                  <Col>
                    <label>
                      Type
                      <span style={{ color: "red" }}>*</span>:
                    </label>
                    <select
                      className={classes.selectdrop}
                      name="workflowType"
                      ref={register({ required: true })}
                    >
                      <option value="Admission">Admission</option>
                      <option value="Migration">Migration</option>
                      <option value="Insurance">Insurance</option>
                      <option value="SkillAssessment">Skill Assessment</option>
                    </select>
                    {errors.workflowType && (
                      <p className="errormessage">WorkflowType is Required</p>
                    )}
                  </Col>
                </Row>
              </div>
              <div className="form-group creatadmin">
                <Row>
                  <Col>
                    <label>
                      Status<span style={{ color: "red" }}>*</span>:
                    </label>
                    <select
                      className={classes.selectdrop}
                      ref={register({ required: true })}
                      name="status"
                    >
                      <option id="1" value="1">
                        Active
                      </option>
                      <option id="0" value="0">
                        InActive
                      </option>
                    </select>
                    {errors.status && (
                      <p className="errormessage">Status is Required</p>
                    )}
                  </Col>
                </Row>
              </div>
              <div className="form-group creatadmin">
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button color="secondary" onClick={() => setIsOpen2(false)}>
                    Cancel
                  </Button>
                  <button type="submit" className="btn listing_addbutton ml-1">
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
        </Modal>
      </div>
      <div>
        <Row></Row>
        <div
          className="newlisting_headertabdesign"
          style={{
            padding: "1.5rem",
            border: "1.5rem solid #e9ebf2",
            marginBottom: "0",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div md={9}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                  alignItems: "flex-start",
                }}
              >
                <div>
                  <form
                    className="master"
                    onSubmit={(e) => {
                      e.preventDefault();
                      submitrows(e);
                    }}
                  >
                    <div style={{ width: "fit-content", marginRight: "7px" }}>
                      <label className="area_rowslable">Limit </label>
                      <br />
                      <FormControl
                        variant="standard"
                        sx={{
                          m: 1,
                          minWidth: 180,
                          marginTop: 0,
                          maxHeight: 180,
                        }}
                      >
                        <select
                          className="s_client_limit"
                          labelId="simple-select-label"
                          id="simple-select"
                          value={rows}
                          onChange={handleRows}
                        >
                          <option value={10}>10</option>
                          <option value={20}>20</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                        </select>
                      </FormControl>
                    </div>
                  </form>
                </div>
                <div style={{ marginRight: "10px" }}>
                  <label className="area_rowslable">Workflow Type</label>
                  <select
                    className="form-control"
                    value={workflowdata}
                    style={{ backgroundColor: "#f5f5f9" }}
                    onChange={searchbyworkflowtype}
                  >
                    <option value="all">- Select Workflow -</option>
                    <option value="Admission">Admission</option>
                    <option value="Migration">Migration</option>
                    <option value="Insurance">Insurance</option>
                    <option value="SkillAssessment">Skill Assessment</option>
                  </select>
                  <br />
                  {workflowdata !== "all" ? (
                    <a
                      className={classes.clrsrch}
                      onClick={() => getallWorkflow(pageno)}
                    >
                      Clear Search
                    </a>
                  ) : null}
                </div>
                <div>
                  <label className="area_rowslable">Status</label>

                  <select
                    className="form-control"
                    style={{ backgroundColor: "#f5f5f9" }}
                    onChange={searchbystatus}
                  >
                    <option value="all">All</option>
                    <option value="1">Active</option>
                    <option value="0">Inactive</option>
                  </select>
                </div>
              </div>
            </div>
            <div md={3} className="mt-2">
              <div className="clearsearchclass">
                <form
                  id="myForm"
                  className="d-flex"
                  onSubmit={(e) => {
                    e.preventDefault();
                    submitSearch(e);
                  }}
                >
                  <input
                    className="form-control"
                    placeholder="Search.."
                    name="search"
                  />
                  <button className="btn listpagesearch_button" type="submit">
                    <i className="fa fa-search"></i>
                  </button>
                </form>
              </div>
              {searchdata ? (
                <a
                  className={classes.clrsrch}
                  onClick={() => {
                    setpageno(0);
                    getallWorkflow(0);
                  }}
                >
                  Clear Search
                </a>
              ) : null}
            </div>
          </div>
        </div>
        <div
          className="newlisting_headertabdesign"
          style={{
            padding: "1.5rem",
            border: "1.5rem solid #e9ebf2",
            marginTop: "-25px",
            marginBottom: "0",
          }}
        >
          <Row className="s_pagi">
            <Col md={6}>
              {allWorkFlowList?.length ? (
                <p className="s_para">
                  {countnumber * rows + 1} to{" "}
                  {countnumber * rows + numberofElements} of {totalUser} records
                </p>
              ) : (
                <p className="s_para">No Record Found</p>
              )}
            </Col>
            <Col md={6} className="d-flex justify-content-end"></Col>
          </Row>
          {/* <div className="above-scroller" onScroll={scrolldiv}>
            <div className="scroller"></div>
          </div> */}
          <div className="displaytablerow">
            {/* <Table responsive striped onScroll={scrolltable}> */}
            <Table
              className="workload card-table table-borderless"
              responsive
              striped
              onScroll={scrolltable}
            >
              <thead className="s_sticky">
                <tr>
                  <th style={{ textAlign: "left", width: "50px" }}>SN</th>

                  <th style={{ width: "65%" }}>
                    NAME{" "}
                    <img
                      src={sort}
                      className="sorting"
                      id="workflowName"
                      onClick={sortClient}
                    />
                  </th>
                  <th style={{ width: "100px" }}>
                    TYPE{" "}
                    <img
                      src={sort}
                      className="sorting"
                      id="workflowType"
                      onClick={sortClient}
                    />
                  </th>
                  <th style={{ minWidth: "120px" }}>
                    STATUS
                    <img
                      src={sort}
                      className="sorting"
                      id="status"
                      onClick={sortClient}
                    />
                  </th>
                  {check4pointpermission(
                    1571,
                    1573,
                    permissionData,
                    rolepermission
                  ) ? (
                    <th style={{ width: "200px" }}>ACTION</th>
                  ) : null}
                </tr>
              </thead>
              <tbody>
                {allWorkFlowList != null ? (
                  allWorkFlowList &&
                  allWorkFlowList.map((p, index) => (
                    <tr key={p.workflowId}>
                      <td style={{ textAlign: "left" }}>{index + 1}</td>
                      <td>{p.workflowName}</td>
                      <td>{p.workflowType}</td>
                      <td>
                        {check1pointpermission(
                          1574,
                          permissionData,
                          rolepermission
                        ) ? (
                          <Link
                            id={p.workflowId}
                            onClick={(e) => changeStatus(e, p)}
                          >
                            {p.status == 1 ? "Active" : "Inactive"}
                          </Link>
                        ) : (
                          <>{p.status == 1 ? "Active" : "Inactive"}</>
                        )}
                      </td>
                      {check4pointpermission(
                        1571,
                        1573,
                        permissionData,
                        rolepermission
                      ) ? (
                        <td style={{ width: "200px" }}>
                          {check1pointpermission(
                            1571,
                            permissionData,
                            rolepermission
                          ) ? (
                            <>
                              <Link
                                color="primary"
                                onClick={(e) => viewWorkflow(p.workflowId, e)}
                                to={`/view-workflow/` + p.workflowId}
                              >
                                View Steps
                              </Link>
                              &nbsp;|&nbsp;
                            </>
                          ) : null}
                          {check1pointpermission(
                            1572,
                            permissionData,
                            rolepermission
                          ) ? (
                            <>
                              {/* <Link
                                color="primary"
                                onClick={(e) => openeditWorkflow(e, p)}
                              >

                              </Link> */}
                              <EditWorkFlowModal
                                register={register}
                                getallWorkflow={getallWorkflow}
                                toast={toast}
                                pageno={pageno}
                                jwtToken={jwtToken}
                                isw_user_name={isw_user_name}
                                handle403error={handle403error}
                                p={p}
                              />
                              &nbsp;|&nbsp;
                            </>
                          ) : null}
                          {check1pointpermission(
                            1573,
                            permissionData,
                            rolepermission
                          ) ? (
                            <DeleteModal
                              name={p.workflowName}
                              id={p.workflowId}
                              deleteCurrentUser={deleteWorkflow}
                            />
                          ) : null}
                        </td>
                      ) : null}
                    </tr>
                  ))
                ) : (
                  <p className={classes.headertekst}>{null}</p>
                )}
              </tbody>
            </Table>
          </div>
          <Row className="s_pagirow">
            <Col md={6}>
              <div>
                <form>
                  <div style={{ float: "left" }}>
                    <FormControl
                      variant="standard"
                      style={{
                        display: "inline-block",
                        marginRight: "25px",
                        minWidth: "50px",
                      }}
                    >
                      <select
                        className={classes.selectlimit}
                        labelId="simple-select-label"
                        id="simple-select"
                        value={rows}
                        onChange={handleRows}
                      >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                    </FormControl>
                    {allWorkFlowList?.length ? (
                      <div className="s_spanPage">
                        {countnumber * rows + 1} to{" "}
                        {countnumber * rows + numberofElements} of {totalUser}{" "}
                        records
                      </div>
                    ) : (
                      <span className="s_spanPage">No Record Found</span>
                    )}
                  </div>
                </form>
              </div>
            </Col>
            <Col md={6}>
              <div className={classes.root} style={{ float: "right" }}>
                {searchdata ? (
                  <Pagination
                    page={paginationpageno}
                    count={getpageno}
                    onChange={handlesearch}
                    shape="rounded"
                  />
                ) : (
                  <Pagination
                    page={paginationpageno}
                    count={getpageno}
                    onChange={handlepage}
                    shape="rounded"
                  />
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
}

export default AllWorkFlowLists;
