import React from "react";
import axios from "axios";
import base_url from "./../../../api/bootapi";
import RLDD from "react-list-drag-and-drop/lib/RLDD";
import "./../../css/Vertical.css";
import { ToastContainer, toast } from "react-toastify";
import { Card, Col, Row, Button } from "reactstrap";
// import Modal from 'react-modal';
import "./../../css/Responsive.css";
import "./ViewWorkflow.css";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useNavigate, useParams } from "react-router-dom";
import AddWorkFlowStepModal from "../../Modal/Masters/AddWorkFlowStepModal";

const withWorkflowParams = (WrappedComponent) => {
  return (props) => {
    const { workflow_id } = useParams();
    return <WrappedComponent {...props} workflow_id={workflow_id} />;
  };
};

class ViewWorkflow extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
    };

    this.state = {
      applicationStatus: "",
    };

    this.state = {
      workFlowStepsIdforConvertToClient: "",
    };

    this.jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
    this.current_enquiry_id = localStorage.getItem("current_enquiry_id");
    // this.param_workflow_id = props?.match?.params?.workflow_id;
    this.current_client_name = localStorage.getItem("current_client_name");
    this.username = localStorage.getItem("isw_user_email");

    // Now you can access workflow_id from props
    this.param_workflow_id = props.workflow_id;

    //console.log("34---->", this.param_workflow_id);

    this.state = {
      items: [],
      selectedworkflowname: [],
    };
    this.stateCust = {
      workFlowList: [],
    };

    this.itemRenderer = this.itemRenderer.bind(this);
    this.handleRLDDChange = this.handleRLDDChange.bind(this);
  }

  componentDidMount() {
    this.UserList();
    // this.UserListtwo();
    this.getAllWorkflowList();
  }

  UserList() {
    this.setState({ current_workFlow_id: this.workflow_id });
    axios
      .get(`${base_url.api2}/getWorkflowStepByWorkflowId`, {
        headers: {
          Authorization: this.jwtToken,
          workflowId: this.param_workflow_id,
        },
      })
      .then(
        (response) => {
          //console.log(response.data.data);
          this.setState({
            current_workFlow_name: response.data.data.workflowName,
          });
          this.setState({
            current_workFlow_Type: response.data.data.workflowType,
          });

          //console.log("82----->", response.data.data.workflowType);

          axios
            .get(`${base_url.api1}/getAllApplicationStatusByType`, {
              headers: {
                Authorization: this.jwtToken,
                applicationType: response.data.data.workflowType,
              },
            })
            .then((response) => {
              //console.log("82 All Status ------>>>", response.data.data);
              // setAdmissionStatus(response.data.data);
              this.setState({ applicationStatus: response.data.data });
            })
            .catch((error) => {
              //console.log("Error", error);
            });

          //console.log("82 After All APPlication");

          const item_obj = [];
          for (let i = 0; i < response.data.data.workflowSteps.length; i++) {
            const element = response.data.data.workflowSteps[i];
            element["id"] = response.data.data.workflowSteps[i].workFlowStepsId;
            item_obj.push(element);
          }
          let j = response.data.data.workflowSteps.length;
          //console.log(j);
          if (j == 0) {
            this.setState({ workflow_step_order: 1 });
          } else {
            this.setState({
              workflow_step_order:
                response.data.data.workflowSteps[j - 1].workflowStepsOrder + 1,
            });
          }
          this.setState({
            items: response.data.data.workflowSteps,
            currentworkflowname: response.data.data.workflowName,
          });

          // this.itemRenderer = this.itemRenderer.bind(this);
          // this.handleRLDDChange = this.handleRLDDChange.bind(this);
        },
        (error) => {
          //console.log(error);
        }
      )
      .catch((error) => {
        //console.log(error);
        toast.error(JSON.stringify(error.response.data.message));
        toast.error("No Steps Found");
      });
  }

  UserListtwo() {
    this.setState({ current_workFlow_id: this.workflow_id });
    axios
      .get(`${base_url.api2}/getWorkflowStepByWorkflowIdWithOrder`, {
        headers: {
          Authorization: this.jwtToken,
          workflowId: this.param_workflow_id,
        },
      })
      .then(
        (response) => {
          //console.log(response.data.data);

          const item_obj = [];
          for (let i = 0; i < response.data.data.length; i++) {
            const element = response.data.data[i];
            element["id"] = response.data.data[i].workFlowStepsId;
            item_obj.push(element);
          }
          let j = response.data.data.length;
          //console.log(j);
          if (j == 0) {
            this.setState({ workflow_step_order: 1 });
          } else {
            this.setState({
              workflow_step_order:
                response.data.data[j - 1].workflowStepsOrder + 1,
            });
          }
          this.setState({
            items: response.data.data,
          });

          this.itemRenderer = this.itemRenderer.bind(this);
          this.handleRLDDChange = this.handleRLDDChange.bind(this);
        },
        (error) => {
          //console.log(error);
        }
      )
      .catch((error) => {
        //console.log(error);
        // toast.error(JSON.stringify(error.res+ponse.data.message));
        toast.error("No Steps Found");
      });
  }
  openModal = () => {
    this.setState({ isModalOpen: true });
  };

  closeModal = () => {
    this.setState({ isModalOpen: false });
  };

  getAllWorkflowList() {
    axios
      .get(`${base_url.api2}/getAllWorkflowWithoutSteps`, {
        headers: {
          Authorization: this.jwtToken,
          username: this.username,
        },
      })
      .then(
        (response) => {
          //console.log(response.data.data);
          // toast.success("Success");
          this.setState({ allworkflowlist: response.data.data });
        },
        (error) => {
          //console.log(error);
        }
      )
      .catch((error) => {
        //console.log(error);
        toast.error(JSON.stringify(error.response.data.message));
      });
  }

  render() {
    const isw_user_name = localStorage.getItem("isw_user_name");
    const isw_adminId = localStorage.getItem("isw_adminId");
    const isw_user_email = localStorage.getItem("isw_user_email");

    const items = this.state.items;
    const { navigate } = this.props;

    const getWorkflowSteps = (workFlow_id) => {
      //console.log("175----->", this.param_workflow_id);
      this.setState({ current_workFlow_id: this.param_workflow_id });
      axios
        .get(`${base_url.api2}/getWorkflowStepByWorkflowId`, {
          headers: {
            Authorization: this.jwtToken,
            workflowId: workFlow_id,
          },
        })
        .then((response) => {
          //console.log(response.data.data.workflowSteps);
          this.setState({
            selectedworkflowname: response.data.data.workflowName,
          });
          //console.log(
          //   "216---->INSIDE GETWORKFLOW",
          //   response.data.data.workflowType
          // );
          // getApplicationStatus(response.data.data.workflowType);
          const item_obj = [];
          for (let i = 0; i < response.data.data.workflowSteps.length; i++) {
            const element = response.data.data.workflowSteps[i];
            element["id"] = response.data.data.workflowSteps[i].workFlowStepsId;
            item_obj.push(element);
          }
          let j = response.data.data.workflowSteps.length;
          //console.log(j);
          if (j == 0) {
            this.setState({ workflow_step_order: 1 });
          } else {
            this.setState({
              workflow_step_order:
                response.data.data.workflowSteps[j - 1].workflowStepsOrder + 1,
            });
          }
          // this.setState({ workflow_step_order:response.data.data.workflowSteps[j-1].workflowStepsOrder+1 });
          // toast.success("Success");
          this.setState({
            items: response.data.data.workflowSteps,
            currentworkflowname: response.data.data.workflowName,
          });

          this.setState({ items: response.data.data.workflowSteps });
          this.itemRenderer = this.itemRenderer.bind(this);
          this.handleRLDDChange = this.handleRLDDChange.bind(this);
          // toast.success("Success");
        })
        .catch((error) => {
          //console.log(error);
          toast.error(JSON.stringify(error.response.data.message));
        });
    };
    //console.log(this.state);

    const addWorkflowSteps = (data) => {
      // if (e.target[0].value == "") {
      //   this.setState({ added_step: "New Step Name is Required" });
      // }
      if (!data.stepName.trim() || !data.workflowStepsStatus) {
        this.setState({ added_step: "New Step Name is Required" });
      } else {
        this.setState({ added_step: null });
        let workFlowStepsId = 0;
        let workflowStepsName = data.stepName.trim();
        let workflowStepsOrder = Number(this.state.workflow_step_order);
        let workflowStepsStatus = data.workflowStepsStatus;
        let workflowSteps = [
          {
            workFlowStepsId,
            workflowStepsName,
            workflowStepsOrder,
            workflowStepsStatus,
          },
        ];
        let data2 = {
          status: 1,
          workflowId: Number(this.param_workflow_id),
          workflowName: this.state.current_workFlow_name,
          workflowSteps,
          workflowType: this.state.current_workFlow_Type,
        };
        //console.log(data2);

        axios
          .post(`${base_url.api2}/createWorkflow`, data2, {
            headers: {
              Authorization: this.jwtToken,
              workflowId: Number(this.param_workflow_id),
            },
          })
          .then((response) => {
            //console.log(response.data.data);
            this.closeModal();
            getWorkflowSteps(this.param_workflow_id);
            // try {
            //   document.getElementById("myForm").reset();
            // } catch (error) {
            //   //console.log(error);
            // }
            // toast.success("Success");
          })
          .catch((error) => {
            //console.log(error);
            toast.error(JSON.stringify(error.response.data.message));
          });
      }
    };
    const current_client_id = localStorage.getItem("current_client_id");

    // Access window.location.origin
    const origin = window.location.origin;
    console.log("340-------->", origin);

    const goback = () => {
      //console.log("300--->",this.props);
      // this.props.history.replace("/workflow");
      window.location.replace(origin + `/admin/#/workflow`);
    };

    const updateworkflow = () => {
      this.setState({ added_step: null });
      let workflowSteps = this.state.items;
      let workflowType = this.state.current_workFlow_Type;
      let data = {
        workflowId: this.param_workflow_id,
        workflowSteps: workflowSteps,
        workflowType: workflowType,
      };
      //console.log(data);
      axios
        .post(`${base_url.api2}/updateWorkflowStepOrderByStepId`, data, {
          headers: {
            Authorization: this.jwtToken,
          },
        })
        .then(
          (response) => {
            //console.log(response.data.data);
            toast.success("Success");
          },
          (error) => {
            //console.log(error);
          }
        )
        .catch((error) => {
          //console.log(error);
          toast.error(JSON.stringify(error.response.data.message));
        });

      let newdata = {
        workFlowStepsConvertClient: 1,
        workFlowStepsId: this.state.workFlowStepsIdforConvertToClient,
      };
      //console.log(data);
      axios
        .post(`${base_url.api2}/updateWorkflowStepByStepId`, newdata, {
          headers: {
            Authorization: this.jwtToken,
          },
        })
        .then(
          (response) => {
            //console.log(response);
            toast.success("Success");
          },
          (error) => {
            //console.log("390----->", error);
          }
        )
        .catch((error) => {
          //console.log(error);
          toast.error(JSON.stringify(error.response.data.message));
        });
    };

    return (
      <div>
        <ToastContainer />
        {/* <Dashboard /> */}
        <Card className="commoncomponent_cardcss">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              getWorkflowSteps(e);
            }}
          >
            <div className="form-inner-admin">
              <Row>
                <Col md={8} style={{ textAlign: "left" }}>
                  <h5>
                    <strong>
                      Work Flow Steps of :- {this.state.current_workFlow_name}{" "}
                    </strong>
                  </h5>
                </Col>
                <Col md={4} className="d-flex justify-content-end">
                  <button className="btn listing_addbutton" onClick={goback}>
                    &#x2630; List
                  </button>
                </Col>
              </Row>
              <div className="form-group creatadmin">
                <Row>
                  {/* <Col md={2} >
                              <h5>Select Work Flow </h5>
                            </Col>
                            <Col md={6}>
                                <input type="text" className="form-control underline-input" value={this.state.current_workFlow_name} readOnly required /> */}
                  {/* <select style={{width:"100%", padding:"10px"}}
                            onChange={e => {
                              let value = e.target.value;
                              getWorkflowSteps(value);
                              }} required>
                                  <option>{}</option>
                                {(this.state.allworkflowlist==null)?"No Work Flow Found":(this.state.allworkflowlist.map((cuntitem) =>
                                <option id={cuntitem.workflow_id} key={cuntitem.workflow_id} value={cuntitem.workflow_id}>{cuntitem.workflow_name}</option>
                                ))}
                            </select> */}
                  {/* </Col> */}
                </Row>
              </div>
            </div>
          </form>
          <div className="d-flex justify-content-end">
            {/* <form
              id="myForm"
              onSubmit={(e) => {
                e.preventDefault();
                addWorkflowSteps(e);
              }}
            >
              <Row>
                <Col md={7}>
                  <input
                    type="text"
                    className="form-control underline-input"
                    style={{ marginLeft: "20px" }}
                    placeholder="Add New Steps Here"
                    // required
                  />
                  <p style={{ color: "red", marginLeft: "20px" }}>
                    {this.state.added_step}
                  </p>
                </Col>
                <Col md={2}>
                  <Button
                    type="submit"
                    color="primary"
                    style={{ marginLeft: "20px" }}
                  >
                    Add Step
                  </Button>
                </Col>
               
              </Row>
            </form> */}
            <Button onClick={this.openModal} color="success" className="mr-3">
              Add Steps
            </Button>

            <AddWorkFlowStepModal
              isOpen={this.state.isModalOpen}
              onClose={this.closeModal}
              onSubmit={addWorkflowSteps}
              applicationStatus={this.state.applicationStatus}
              className="mr-3"
            />
            {this.state.items == "" ? null : (
              <Button type="submit" color="secondary" onClick={updateworkflow}>
                Update
              </Button>
            )}
          </div>
          <br />
          {this.state.items == "" ? (
            <h5 style={{ marginLeft: "20px", textAlign: "left" }}>
              <strong>No Steps Created</strong>
            </h5>
          ) : (
            <>
              <div
                className="heading"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "0px",
                  fontSize: "20px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    marginLeft: "50px",
                    justifyContent: "space-between",
                    gap: "400px",
                    fontWeight: "600",
                  }}
                >
                  <div>Workflow Steps</div>
                  <div style={{ marginLeft: "160px" }}>Application Status</div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "10px",
                    fontWeight: "600",
                  }}
                >
                  <div style={{ marginRight: "130px" }}>Convert to Client</div>
                  <div style={{ marginRight: "30px" }}>Delete</div>
                </div>
              </div>
              <RLDD
                cssClasses="example"
                items={items}
                itemRenderer={this.itemRenderer}
                onChange={this.handleRLDDChange}
              />
            </>
          )}
        </Card>
      </div>
    );
  }

  itemRenderer(item, index) {
    item["workflowStepsOrder"] = index;
    const deleteStep = (e) => {
      axios
        .delete(`${base_url.api2}/deleteWorkflowStepById`, {
          headers: {
            Authorization: this.jwtToken,
            workflowStepId: Number(e),
          },
        })
        .then(
          (response) => {
            //console.log(response.data.message);
            this.UserList();
            toast.success(response.data.message);
          },
          (error) => {
            //console.log(error);
            toast.error(JSON.stringify(error.response.data.message));
          }
        )
        .catch((error) => {
          //console.log(error);
          toast.error(JSON.stringify(error.response.data.message));
        });
    };

    // Define the selectStep function
    const selectStep = (id) => {
      // Handle the selection logic here
      //console.log("Step selected with ID:", id);
      this.setState({ workFlowStepsIdforConvertToClient: Number(id) });
    };

    const enable = (id, id2) => {
      console.log(
        "559--->",
        id,
        this.state.workFlowStepsIdforConvertToClient,
        id === this.state.workFlowStepsIdforConvertToClient
      );
      if (this.state.workFlowStepsIdforConvertToClient === undefined) {
        return id2 === 1;
      } else {
        return id === this.state.workFlowStepsIdforConvertToClient;
      }
    };
    //console.log("558--->", this.state.workFlowStepsIdforConvertToClient);

    return (
      <>
        {/* <div className="item" value={item.id} onClick={() => addNotes(item.id)} key={item.id}> */}

        <div style={{ fontSize: "14px" }}>
          <div className="item">
            <div className="left-part">
              <div style={{ marginTop: "-15px", fontSize: "" }}>
                <DragIndicatorIcon className="lefticon" />
              </div>
              <div>
                <p className="title">{item.workflowStepsName}</p>
              </div>
            </div>
            <div className="middle-part">
              <p className="title">{item.workflowStepsStatus}</p>
            </div>
            <div className="right-part">
              <div style={{ marginRight: "210px" }}>
                <input
                  type="radio"
                  name="selectStep"
                  id={item.id}
                  onClick={(e) => selectStep(e.target.id)}
                  checked={enable(item.id, item.workFlowStepsConvertClient)}
                />
                <label htmlFor={item.id}></label>
              </div>
              <div style={{ marginRight: "30px" }}>
                <DeleteForeverIcon
                  className="dlt"
                  id={item.id}
                  onClick={() => deleteStep(item.id)}
                  style={{ marginTop: "-3px" }}
                />
              </div>
            </div>
          </div>
          {/* <p className="body">{item.workflowStepsName}</p> */}
          {/* { (this.state.modalIsOpen==true)? "no error":"error"} */}
          {/* <div className="small">
          item.id: {item.id} - index: {index}
        </div> */}
          <div className="small2">
            {/* <DragIndicatorIcon className="dragindicator"/> */}

            {/* <Button
              id={item.id}
              onClick={() => deleteStep(item.id)}
              variant="outlined"
              color="danger"
            >
              <DeleteForeverIcon variant="outlined" />
            </Button> */}
          </div>
        </div>
      </>
    );
  }

  handleRLDDChange(reorderedItems) {
    //console.log(reorderedItems);
    this.setState({ items: reorderedItems });
  }
}

export default withWorkflowParams(ViewWorkflow);
//render(<VerticalExample />, document.getElementById('root'));
