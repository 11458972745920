import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import "./../../css/Responsive.css";
import "./../../css/MainDashboard.css";
import base_url from "../../../api/bootapi";
import MainDashboardBodytop from "./MainDashboardBodytop";
import MainDashboardBodymiddle from "./MainDashboardBodymiddle";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import {
  selectUser,
  logout,
  selectCompress,
  getOfficeId,
} from "../../features/userSlice";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import {
  getagentpermission,
  check4pointpermission,
  check1pointpermission,
} from "../../Permission/permission";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
const useStyles = makeStyles((theme) => ({
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    // marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: 100,
    color: "#fff",
  },
}));
const MainDashboard = (props) => {
  const classes = useStyles();
  const user = useSelector(selectUser);
  const userDefaultOfficeId = useSelector(getOfficeId);
  const compress = useSelector(selectCompress);
  console.log(compress);
  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  const isw_adminId = localStorage.getItem("isw_adminId");
  const [officeid, setofficeid] = useState();
  const [selectedWorkLoad, setSelectedWorkLoad] = useState("Admission");
  const [offerLetterCount, setOfferLetterCount] = useState();

  const officeIds = user?.isw_available_Office?.map((item) => item.officeId);
  const allOfficeIds = officeIds ? officeIds.join(",") : [];

  const [currOfficeId, setCurrOfficeId] = useState();
  const [aipTotal, setAipTotal] = useState();

  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            navigate("/");
            // toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };

  // Default value "all" or whatever default value you prefer

  useEffect(() => {
    // Fetch the default branch data from localStorage

    const defaultBranchDataString = localStorage.getItem("default_branch_data");
    const default_branch_id = localStorage.getItem("default_branch_id");

    const defaultBranchData = JSON.parse(defaultBranchDataString);

    // If default branch data is available, set the officeid to its value
    if (defaultBranchData && defaultBranchData?.firstOfficeId) {
      setofficeid(defaultBranchData?.firstOfficeId);
      setCurrOfficeId(defaultBranchData?.firstOfficeId);
      getalllead(defaultBranchData?.firstOfficeId);
      getallclient(defaultBranchData?.firstOfficeId);
      getAipCount(defaultBranchData?.firstOfficeId);
      getEnquiryCount(defaultBranchData?.firstOfficeId);
    }
    console.log("78---->", defaultBranchData);

    getOfferLetterCount(defaultBranchData?.firstOfficeId);
  }, [userDefaultOfficeId?.firstOfficeId]);

  useEffect(() => {
    setbackdropopen(true);
    getEnquiryCount(currOfficeId);

    console.log("87---->", currOfficeId);
  }, [selectedWorkLoad, currOfficeId]);

  const [backdropopen, setbackdropopen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [enquirycount, setEnquiryCount] = useState();
  const [topprovider, settopprovider] = useState();
  const [topcourse, settopcourse] = useState();
  const [enquirybyfollowdate, setenquirybyfollowdate] = useState();
  const [pageno, setpageno] = useState(0);
  const [clientcountbyuser, setclientcountbyuser] = useState();
  const [getpageno, setgetpageno] = useState();
  const [totalClientCount, setTotalClientCount] = useState();

  const handleChange = (event) => {
    setSelectedWorkLoad(event.target.value);
  };

  const handlepage = (event, value) => {
    setpageno(value - 1);
  };

  const handleOffice = (event) => {
    setbackdropopen(true);
    setCurrOfficeId(event.target.value);
    setofficeid(event.target.value);
    getallclient(event.target.value);
    getalllead(event.target.value);
    getEnquiryCount(event.target.value);
    getadmissionEnquiryWorkflowsteps(event.target.value);
    getmigrationEnquiryWorkflowsteps(event.target.value);
    getinsuranceEnquiryWorkflowsteps(event.target.value);
    getclientcountbyuser(event.target.value);
    getOfferLetterCount(event.target.value);
    getskillassessmentEnquiryWorkflowsteps(event.target.value);
    getAipCount(event.target.value);
    // getclientoncountry(event.target.value);
  };

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        navigate("/");
      }
    });
    setbackdropopen(true);
    getPermissions();
    getFollowUpdate();
    console.log(greetings);
  }, []);

  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);

  const getAipCount = (val) => {
    if (val === "all") {
      //console.log("coming in if");
      axios
        .get(`${base_url.api3}/searchClientByAllFilter`, {
          headers: {
            Authorization: jwtToken,
            fieldName: "prospect",
            fieldValue: 1,
            status: 1,
            columnName: "lastUpdated",
            direction: "DESC",
            size: 20,
          },
        })
        .then((response) => {
          console.log("256--->", response.data.data.totalElements);
          setAipTotal(response.data.data.totalElements);
        })
        .catch((error) => {
          setbackdropopen(false);
          handle403error(error);
        });
    } else {
      axios
        .get(`${base_url.api3}/searchClientByAllFilter`, {
          headers: {
            Authorization: jwtToken,
            fieldName: "prospect",
            fieldValue: 1,
            status: 1,
            officeId: val,
            columnName: "lastUpdated",
            direction: "DESC",
            size: 20,
          },
        })
        .then((response) => {
          console.log("256--->", response.data.data.totalElements);
          setAipTotal(response.data.data.totalElements);
        })
        .catch((error) => {
          setbackdropopen(false);
          handle403error(error);
        });
    }
  };

  const getOfferLetterCount = (val) => {
    if (val) {
      if (val === "all") {
        axios
          .get(`${base_url.api1}/getOfferLetterReceivedCount`, {
            headers: {
              Authorization: jwtToken,
              workflowName: selectedWorkLoad,
              officeId: allOfficeIds,
            },
          })
          .then((res) => {
            console.log(
              "60--->Offer Letter Recieved",
              res.data.data[0].offerLetterCount
            );
            setOfferLetterCount(res.data.data[0].offerLetterCount);
          })
          .catch((e) => {
            console.log("Error", e);
          });
      } else {
        axios
          .get(`${base_url.api1}/getOfferLetterReceivedCount`, {
            headers: {
              Authorization: jwtToken,
              officeId: val,
              workflowName: selectedWorkLoad,
            },
          })
          .then((res) => {
            console.log(
              "60--->Offer Letter Recieved",
              res.data.data[0].offerLetterCount
            );
            setOfferLetterCount(res.data.data[0].offerLetterCount);
          })
          .catch((e) => {
            console.log("Error", e);
          });
      }
    }
  };

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);

    // if (check4pointpermission(1, 11, permission_obj, resource_obj)) {
    gettopprovider();
    gettopcourse();
    getallClientApplicationReminder();
    getallClientVisaReminder();
    getallClientdeadlineReminder();
    getallOverdueInvoice();
    if (user?.isw_user_role === 1 || user?.isw_user_role === 6) {
      // setofficeid("all");
      // setofficeid(defaultBranchData?.firstOfficeId);
      // getallclient("all");
      // getalllead("all");
      // getEnquiryCount("all");
      getallappointment("all");

      getclientcountbyuser("all");
      getadmissionEnquiryWorkflowsteps("all");
      getmigrationEnquiryWorkflowsteps("all");
      getinsuranceEnquiryWorkflowsteps("all");
      getskillassessmentEnquiryWorkflowsteps("all");
      // getclientoncountry("all");
    } else {
      const defaultBranchDataString = localStorage.getItem(
        "default_branch_data"
      );
      let defaultBranchData;
      try {
        defaultBranchData = JSON.parse(defaultBranchDataString);
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
      // setofficeid(user?.isw_available_Office[0]?.officeId);\
      // setofficeid(defaultBranchData?.firstOfficeId);
      getallclient(user?.isw_available_Office[0]?.officeId);
      getalllead(user?.isw_available_Office[0]?.officeId);
      getAipCount(user?.isw_available_Office[0]?.officeId);
      // getclientoncountry(user?.isw_available_Office[0]?.officeId);
      getEnquiryCount(defaultBranchData?.firstOfficeId);
      getclientcountbyuser(user?.isw_available_Office[0]?.officeId);
      getallappointment("counsellor");
      getadmissionEnquiryWorkflowsteps(user?.isw_available_Office[0]?.officeId);
      getmigrationEnquiryWorkflowsteps(user?.isw_available_Office[0]?.officeId);
      getinsuranceEnquiryWorkflowsteps(user?.isw_available_Office[0]?.officeId);
      getskillassessmentEnquiryWorkflowsteps(
        user?.isw_available_Office[0]?.officeId
      );
    }
    // } else {
    //   navigate("/errorPage");
    // }
  };
  // const getclientoncountry = (value) => {
  //   if (value === "all") {
  //     axios
  //       .get(`${base_url.api1}/getAllClientCountBasedOnCountries`, {
  //         headers: {
  //           Authorization: jwtToken,
  //         },
  //       })
  //       .then((res) => {
  //         console.log("Total Client--->", res.data.totalCount[0].clientCount);
  //         setTotalClientCount(res.data.totalCount[0].clientCount);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         handle403error(err);
  //       });
  //   } else {
  //     axios
  //       .get(`${base_url.api1}/getAllClientCountBasedOnCountries`, {
  //         headers: {
  //           Authorization: jwtToken,
  //           officeId: value,
  //         },
  //       })
  //       .then((res) => {
  //         console.log("Total Client--->", res.data.totalCount[0].clientCount);
  //         setTotalClientCount(res.data.totalCount[0].clientCount);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         handle403error(err);
  //       });
  //   }
  // };
  const getclientcountbyuser = (val) => {
    if (val === "all") {
      axios
        .get(`${base_url.api2}/clientConsultantCount`, {
          headers: {
            Authorization: jwtToken,
          },
        })
        .then((res) => {
          setclientcountbyuser(res.data.data);
        })
        .catch((err) => {
          console.error("Axios error:", err);
          handle403error(err);
        });
    } else {
      axios
        .get(`${base_url.api2}/clientConsultantCount`, {
          headers: {
            Authorization: jwtToken,
            officeId: val,
          },
        })
        .then((res) => {
          setclientcountbyuser(res.data.data);
        })
        .catch((err) => {
          console.error("Axios error:", err);
          handle403error(err);
        });
    }
  };

  const getFollowUpdate = () => {
    axios
      .get(`${base_url.api3}/getApplicationFollowUpReminder`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        console.log("392---->", res.data.data);
        const today = new Date();
        const filtered = res?.data?.data?.filter((item) => {
          const followUpDate = new Date(item.followUpDate);
          return followUpDate >= today;
        });
        console.log("filtered---------->", filtered);
        setenquirybyfollowdate(filtered);
      })
      .catch((err) => {
        console.log(err);
        handle403error(err);
      });
  };

  const gettopprovider = () => {
    axios
      .get(`${base_url.api1}/getTop5InstitutionCount`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        settopprovider(res.data.data);
      })
      .catch((err) => {
        console.error("Axios error:", err);
        handle403error(err);
      });
  };
  const gettopcourse = () => {
    axios
      .get(`${base_url.api1}/getTop5CourseCount`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        settopcourse(res.data.data);
      })
      .catch((err) => {
        console.error("Axios error:", err);
        handle403error(err);
      });
  };
  const [clienttotal, setclienttotal] = useState();
  const getallclient = (value) => {
    if (value === "all") {
      axios
        .get(`${base_url.api3}/getAllClients`, {
          headers: {
            Authorization: jwtToken,
            officeId: allOfficeIds,
            columnName: "addedOn",
            direction: "DESC",
            page: 0,
            size: 20,
          },
        })
        .then((res) => {
          // console.log("Client", res);
          setclienttotal(res.data.data.totalElements);
        })
        .catch((err) => {
          setclienttotal(0);
          console.log(err);
          handle403error(err);
        });
    } else if (value) {
      axios
        .get(`${base_url.api3}/getClientByOfficeId`, {
          headers: {
            Authorization: jwtToken,
            officeId: Number(value),
            columnName: "addedOn",
            direction: "DESC",
            page: 0,
            size: 20,
          },
        })
        .then((res) => {
          console.log("Client---->", res.data.data.totalElements);
          setclienttotal(res.data.data.totalElements);
        })
        .catch((err) => {
          setclienttotal(0);
          console.log(err);
          handle403error(err);
        });
    }
  };

  const getEnquiryCount = (value) => {
    setbackdropopen(true);
    // if (value === "all") {
    //   axios
    //     .get(`${base_url.api2}/getCountBasedOnEnquiryStatus`, {
    //       headers: {
    //         Authorization: jwtToken,
    //         officeId: value,
    //         workflowType: selectedWorkLoad,
    //       },
    //     })
    //     .then((res) => {
    //       console.log("Client", res);
    //       var result = res.data.data;
    //       console.log(res.data.data);
    //       var obj = {};
    //       for (let i = 0; i < result.length; i++) {
    //         obj[result[i]["enquiryStatus"]] = result[i]["enquiryCount"];
    //       }
    //       // console.log(obj);
    //       setEnquiryCount(obj);
    //       setbackdropopen(false);
    //       // console.log(enquirycount);
    //     })
    //     .catch((err) => {
    //       setEnquiryCount();
    //       console.log(err);
    //       handle403error(err);
    //       setbackdropopen(false);
    //     });
    // } else {
    if (value === "all") {
      axios
        .get(`${base_url.api2}/getCountBasedOnEnquiryStatus`, {
          headers: {
            Authorization: jwtToken,

            workflowType: selectedWorkLoad,
          },
        })
        .then((res) => {
          console.log("Client", res);
          var result = res.data.data;
          console.log("Enquiry Count--->", res.data.data);
          var obj = {};
          for (let i = 0; i < result.length; i++) {
            obj[result[i]["enquiryStatus"]] = result[i]["enquiryCount"];
          }
          console.log(obj);
          setEnquiryCount(obj);
          console.log("429----->OfficeID", obj);
          console.log(enquirycount);
          setbackdropopen(false);
        })
        .catch((err) => {
          setclienttotal();
          console.log(err);
          handle403error(err);
          setbackdropopen(false);
        });
    } else {
      axios
        .get(`${base_url.api2}/getCountBasedOnEnquiryStatus`, {
          headers: {
            Authorization: jwtToken,
            officeId: value,
            workflowType: selectedWorkLoad,
          },
        })
        .then((res) => {
          console.log("Client", res);
          var result = res.data.data;
          console.log("Enquiry Count--->", res.data.data);
          var obj = {};
          for (let i = 0; i < result.length; i++) {
            obj[result[i]["enquiryStatus"]] = result[i]["enquiryCount"];
          }
          console.log(obj);
          setEnquiryCount(obj);
          console.log("429----->OfficeID", obj);
          console.log(enquirycount);
          setbackdropopen(false);
        })
        .catch((err) => {
          setclienttotal();
          console.log(err);
          handle403error(err);
          setbackdropopen(false);
        });
    }
    // }
  };
  const [appointment, setappointment] = useState();
  const getallappointment = (value) => {
    if (value === "all") {
      axios
        .get(`${base_url.api4}/getDashboardAppointments`, {
          headers: {
            Authorization: jwtToken,
            status: 1,
          },
        })
        .then((res) => {
          // console.log("Appointment", res);
          setappointment(res.data.data);
        })
        .catch((err) => {
          console.log(err);
          handle403error(err);
        });
    } else {
      axios
        .get(`${base_url.api4}/getAppointmentsByCounsellingCreatedBy`, {
          headers: {
            Authorization: jwtToken,
            counsellingCreatedBy: isw_adminId,
            columnName: "appointmentDate",
            direction: "DESC",
            page: 0,
            size: 100,
          },
        })
        .then((res) => {
          // console.log("Appointment", res);
          setappointment(res.data.data.content);
        })
        .catch((err) => {
          // toast.error("No Appointments Scheduled");
          console.log(err);
          handle403error(err);
        });
    }
  };
  const [clienttemptotal, setclienttemptotal] = useState();
  const getalllead = (value) => {
    if (value) {
      if (value === "all") {
        axios
          .get(`${base_url.api3}/getAllClientTempWithPage`, {
            headers: {
              Authorization: jwtToken,
              officeId: allOfficeIds,
              page: 0,
              size: 1,
              columnName: "addedOn",
              direction: "DESC",
              status: 1,
            },
          })
          .then((res) => {
            console.log("576--->", res.data);
            setclienttemptotal(res.data.data.numberOfElements);
          })
          .catch((err) => {
            setclienttemptotal(0);
            console.log(err);
            handle403error(err);
          });
      } else {
        axios
          .get(`${base_url.api3}/getAllClientTempWithPage`, {
            headers: {
              Authorization: jwtToken,
              officeId: Number(value),
              columnName: "addedOn",
              direction: "DESC",
              page: 0,
              size: 1,
              status: 1,
            },
          })
          .then((res) => {
            console.log("576--->", res.data.data.totalElements);
            setclienttemptotal(res.data.data.totalElements);
          })
          .catch((err) => {
            setclienttemptotal(0);
            console.log(err);
            handle403error(err);
          });
      }
    }
  };
  const [admissionenquiryworkflowsteps, setadmissionenquiryworkflowsteps] =
    useState();
  const getadmissionEnquiryWorkflowsteps = (value) => {
    if (value === "all") {
      axios
        .get(
          `${base_url.api1}/getEnquiryCountByWorkflowTypeBasedOnCurrentStage`,
          {
            headers: {
              Authorization: jwtToken,
              workflowType: "Admission",
            },
          }
        )
        .then((res) => {
          setadmissionenquiryworkflowsteps(res.data.data);
        })
        .catch((err) => {
          console.error("Axios error:", err);
          handle403error(err);
        });
    } else {
      axios
        .get(
          `${base_url.api1}/getEnquiryCountByWorkflowTypeAndOfficeIdBasedOnCurrentStage`,
          {
            headers: {
              Authorization: jwtToken,
              officeId: Number(value),
              workflowType: "Admission",
            },
          }
        )
        .then((res) => {
          // console.log("admissionenquiryworkflowtype", res);
          setadmissionenquiryworkflowsteps(res.data.data);
        })
        .catch((err) => {
          console.log(err);
          handle403error(err);
        });
    }
  };
  const [migrationenquiryworkflowsteps, setmigrationenquiryworkflowsteps] =
    useState();
  const getmigrationEnquiryWorkflowsteps = (value) => {
    if (value === "all") {
      axios
        .get(
          `${base_url.api1}/getEnquiryCountByWorkflowTypeBasedOnCurrentStage`,
          {
            headers: {
              Authorization: jwtToken,
              workflowType: "Migration",
            },
          }
        )
        .then((res) => {
          setmigrationenquiryworkflowsteps(res.data.data);
        })
        .catch((err) => {
          console.error("Axios error:", err);
          handle403error(err);
        });
    } else {
      axios
        .get(
          `${base_url.api1}/getEnquiryCountByWorkflowTypeAndOfficeIdBasedOnCurrentStage`,
          {
            headers: {
              Authorization: jwtToken,
              officeId: Number(value),
              workflowType: "Migration",
            },
          }
        )
        .then((res) => {
          console.log("migrationenquiryworkflowtype", res);
          setmigrationenquiryworkflowsteps(res.data.data);
        })
        .catch((err) => {
          console.log(err);
          handle403error(err);
        });
    }
  };
  const [insuranceenquiryworkflowsteps, setinsuranceenquiryworkflowsteps] =
    useState();
  const getinsuranceEnquiryWorkflowsteps = (value) => {
    if (value === "all") {
      axios
        .get(
          `${base_url.api1}/getEnquiryCountByWorkflowTypeBasedOnCurrentStage`,
          {
            headers: {
              Authorization: jwtToken,
              workflowType: "Insurance",
            },
          }
        )
        .then((res) => {
          setinsuranceenquiryworkflowsteps(res.data.data);
        })
        .catch((err) => {
          console.error("Axios error:", err);
          handle403error(err);
        });
    } else {
      axios
        .get(
          `${base_url.api1}/getEnquiryCountByWorkflowTypeAndOfficeIdBasedOnCurrentStage`,
          {
            headers: {
              Authorization: jwtToken,
              officeId: Number(value),
              workflowType: "Insurance",
            },
          }
        )
        .then((res) => {
          // console.log("insurncceenquiryworkflowtype", res);
          setinsuranceenquiryworkflowsteps(res.data.data);
        })
        .catch((err) => {
          console.log(err);
          handle403error(err);
        });
    }
  };
  const [
    skillassessmentenquiryworkflowsteps,
    setskillassessmentenquiryworkflowsteps,
  ] = useState();
  const getskillassessmentEnquiryWorkflowsteps = (value) => {
    if (value === "all") {
      axios
        .get(
          `${base_url.api1}/getEnquiryCountByWorkflowTypeBasedOnCurrentStage`,
          {
            headers: {
              Authorization: jwtToken,
              workflowType: "SkillAssessment",
            },
          }
        )
        .then((res) => {
          setskillassessmentenquiryworkflowsteps(res.data.data);
          setbackdropopen(false);
        })
        .catch((err) => {
          setbackdropopen(false);
          console.error("Axios error:", err);
          handle403error(err);
        });
    } else {
      axios
        .get(
          `${base_url.api1}/getEnquiryCountByWorkflowTypeAndOfficeIdBasedOnCurrentStage`,
          {
            headers: {
              Authorization: jwtToken,
              officeId: Number(value),
              workflowType: "SkillAssessmentre",
            },
          }
        )
        .then((res) => {
          console.log("skillenquiryworkflowtype", res);
          setbackdropopen(false);

          setskillassessmentenquiryworkflowsteps(res.data.data);
        })
        .catch((err) => {
          console.log(err);
          setbackdropopen(false);
          handle403error(err);
        });
    }
  };

  const [applicationreminder, setapplicationreminder] = useState();
  const getallClientApplicationReminder = (value) => {
    axios
      .get(`${base_url.api3}/getClientApplicationReminder`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        const today = new Date();
        const filtered = res?.data?.data?.filter((item) => {
          const courseEndDate = new Date(item.CourseEndDate);
          return courseEndDate >= today;
        });
        console.log("filtered---------->", filtered);
        setapplicationreminder(filtered);
      })
      .catch((err) => {
        console.log(err);
        handle403error(err);
      });
  };

  const [overdueInvoice, setoverdueInvoice] = useState();
  const getallOverdueInvoice = (value) => {
    axios
      .get(`${base_url.api2}/OverdueInvoice`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        setoverdueInvoice(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        handle403error(err);
      });
  };

  const [visreminder, setvisareminder] = useState();
  const getallClientVisaReminder = (value) => {
    axios
      .get(`${base_url.api3}/getClientVisaExpiryReminder`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        console.log(res.data.data);
        setvisareminder(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        handle403error(err);
      });
  };

  const [deadlinereminder, setdeadlinereminder] = useState();
  const getallClientdeadlineReminder = (value) => {
    axios
      .get(`${base_url.api3}/getApplicationDeadlineReminder`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        console.log(res.data.data);
        setdeadlinereminder(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        handle403error(err);
      });
  };

  let date = new Date();
  let hours = date.getHours();
  let greetings;

  if (hours >= 18) {
    greetings = "Good Evening";
  } else if (hours >= 12) {
    greetings = "Good Afternoon";
  } else if (hours >= 0) {
    greetings = "Good Morning";
  } else {
    greetings = "";
  }

  return (
    <div className="dashboardcomponent_cardcss">
      <Backdrop className={classes.backdrop} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* {check1pointpermission(1, permissionData, rolepermission) ? ( */}
      <div className="page-head p-4">
        <div className="d-flex justify-content-between align-items-center mb-4 ">
          <h3 class="page-title">Welcome,{greetings}</h3>
          <div
            className="d-flex"
            style={{
              flexDirection: "column",
              textAlign: "right",
              paddingRight: "1.5rem",
            }}
          >
            {/* <Col md={6}>{null}</Col> */}
            {/* <Col md={6}> */}
            {/* <label style={{ color: "#071a52", fontSize: "14px", marginBottom: '0.5rem' }}>Overview and Stats</label> */}
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ marginRight: "10px" }}>
                <select
                  value={selectedWorkLoad}
                  onChange={handleChange}
                  className="form-select select-active"
                  style={{ backgroundColor: "#f5f5f9" }}
                >
                  <option value="Admission">Admission</option>
                  <option value="Migration">Migration</option>
                  <option value="Insurance">Insurance</option>
                  <option value="Skill Assessment">Skill Assessment</option>
                </select>
              </div>
              <div>
                <select
                  value={officeid || ""} // Set value to officeid if available, otherwise an empty string
                  className="form-select select-active"
                  style={{ backgroundColor: "#f5f5f9" }}
                  onChange={(e) => handleOffice(e)}
                >
                  {user?.isw_user_role === 1 ||
                  user?.isw_user_role === 6 ||
                  user?.isw_user_role === 10 ||
                  user?.isw_user_role === 4 ? (
                    <option value="all">Select All</option>
                  ) : null}
                  {user?.isw_available_Office?.map((p, index) => (
                    <option
                      key={index + 1}
                      value={p.officeId}
                    >{`${p.officeName}-${p.officeCode}`}</option>
                  ))}
                </select>
              </div>
            </div>

            {/* </Col> */}
          </div>
        </div>
        <div className="maindash_body">
          <MainDashboardBodytop
            totalclient={clienttotal}
            leadtotal={clienttemptotal}
            enquiryCount={enquirycount}
            selectedWorkLoad={selectedWorkLoad}
            offerLetterCount={offerLetterCount}
            totalClientCount={totalClientCount}
            aipTotal={aipTotal}
          />

          {/* <MainDashboardBodybottom /> */}
        </div>
      </div>
      {/* ) : null} */}
      <MainDashboardBodymiddle
        // workflowType={selectedworkflow}
        handlepage={handlepage}
        getpageno={getpageno}
        officeid={officeid}
        appointment={appointment}
        applicationreminder={applicationreminder}
        visreminder={visreminder}
        deadlinereminder={deadlinereminder}
        overdueInvoice={overdueInvoice}
        clientcountbyuser={clientcountbyuser}
        topprovider={topprovider}
        topcourse={topcourse}
        enquirybyfollowdate={enquirybyfollowdate}
        admissionenquiryworkflowsteps={admissionenquiryworkflowsteps}
        migrationenquiryworkflowsteps={migrationenquiryworkflowsteps}
        insuranceenquiryworkflowsteps={insuranceenquiryworkflowsteps}
        skillassessmentenquiryworkflowsteps={
          skillassessmentenquiryworkflowsteps
        }
      />
    </div>
  );
};

export default MainDashboard;
