let alldatapermission = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 101, 102, 201, 202, 301, 302, 303, 304, 305, 306, 307, 308, 309, 310, 311, 312,
  314, 315, 316, 317, 318, 319, 320, 321, 322, 323, 324, 325, 326, 327, 328,
  329, 330, 331, 332, 333, 334, 335, 336, 337, 338, 339, 340, 341, 342, 343, 344, 345, 346, 347, 348, 349, 401, 402, 403,
  404, 405, 406, 407, 408, 502, 503, 504, 505, 506, 507, 508, 510, 511, 512, 513, 515, 516, 517,
  518, 519, 528, 601, 602, 604, 605, 606, 607, 608, 609, 610, 611, 612, 702, 703, 704,
  705, 706, 707, 708, 709, 710, 711, 712, 713, 714, 715, 716, 718, 719, 720,
  721, 722, 723, 725, 726, 727, 728, 729, 730, 731, 732, 901, 902, 903, 904, 905, 1003, 1004, 1005,
  1007, 1008, 1009, 1011, 1012, 1013, 1015, 1016, 1017, 1020, 1021, 1022, 1024,
  1025, 1026, 1028, 1029, 1030, 1032, 1033, 1034, 1037, 1038, 1039, 1041, 1042,
  1043, 1045, 1046, 1047, 1050, 1051, 1052, 1054, 1055, 1056, 1058, 1059, 1060,
  1063, 1064, 1065, 1067, 1068, 1069, 1071, 1072, 1073, 1201, 1202, 1203, 1204,
  1300, 1400, 1502, 1503, 1505, 1506, 1507, 1508, 1510, 1511, 1512, 1513, 1515,
  1516, 1517, 1518, 1520, 1521, 1522, 1524, 1525, 1526, 1527, 1529, 1530, 1531,
  1532, 1534, 1535, 1536, 1537, 1539, 1540, 1541, 1542, 1544, 1545, 1546, 1547,
  1549, 1550, 1551, 1552, 1554, 1555, 1556, 1557, 1559, 1560, 1561, 1562, 1563,
  1565, 1566, 1567, 1568, 1570, 1571, 1572, 1573, 1574, 1576, 1577, 1578, 1579, 1580, 1700,
];

export const getclientpermission = (permissionData, resource_obj) => {
  for (let i = 9; i <= 48; i++) {
    if (permissionData?.includes(i)) {
      return true;
    }
  }
  if (resource_obj.includes(5)) return true;
  return false;
};
export const getagentpermission = (permissionData, resource_obj) => {
  for (let i = 49; i <= 59; i++) {
    if (permissionData?.includes(i)) {
      return true;
    }
  }
  if (resource_obj.includes(99)) return true;
  return false;
};
export const getofficepermission = (permissionData, resource_obj) => {
  for (let i = 0; i <= 8; i++) {
    if (permissionData?.includes(i)) {
      return true;
    }
  }
  if (resource_obj.includes(66)) return true;
  return false;
};
export const getquotationpermission = (permissionData, resource_obj) => {
  for (let i = 217; i <= 220; i++) {
    if (permissionData?.includes(i)) {
      return true;
    }
  }
  if (resource_obj.includes(168)) return true;
  return false;
};
export const getcoursepermission = (permissionData, resource_obj) => {
  for (let i = 75; i <= 79; i++) {
    if (permissionData?.includes(i)) {
      return true;
    }
  }
  if (resource_obj.includes(92)) return true;
  return false;
};
export const getproviderpermission = (permissionData, resource_obj) => {
  for (let i = 60; i <= 74; i++) {
    if (permissionData?.includes(i)) {
      return true;
    }
  }
  if (resource_obj.includes(77)) return true;
  return false;
};
export const checkworkloadpermission = (resource_obj) => {
  if (checkrolepermission(resource_obj, 47, 50)) {
    return true;
  }
  return false;
};
export const checkFinancepermission = (permissionData, resource_obj) => {
  if (checkrolepermission(resource_obj, 52, 62)) {
    return true;
  }
  if (checkuserpermission(permissionData, 210, 216)) {
    return true;
  }

  return false;
};
export const checkreportpermission = (permissionData, resource_obj) => {
  if (checkuserpermission(permissionData, 154, 209)) {
    return true;
  }
  if (checkrolepermission(resource_obj, 111, 166)) {
    return true;
  }
  return false;
};
export const checksettingpermission = (permissionData, resource_obj) => {
  if (checkuserpermission(permissionData, 80, 153)) {
    return true;
  }
  if (checkrolepermission(resource_obj, 175, 249)) {
    return true;
  }
  return false;
};
const checkrolepermission = (rolepermission, x, y) => {
  for (let i = x; i <= y; i++) {
    if (rolepermission.includes(i)) {
      return true;
    }
  }
  return false;
};
const checkuserpermission = (userpermission, x, y) => {
  for (let i = x; i <= y; i++) {
    if (userpermission.includes(i)) {
      return true;
    }
  }
  return false;
};

export const checknew6pointpermission = (
  a,
  b,
  c,
  d,
  e,
  f,
  permissionData,
  rolepermission
) => {
  for (let i = a; i <= b; i++) {
    if (permissionData.includes(i)) {
      return true;
    }
  }
  for (let i = c; i <= d; i++) {
    if (permissionData.includes(i)) {
      return true;
    }
  }
  for (let i = 0; i < 257; i++) {
    if (permissionData.includes(i)) return false;
  }
  if (checkrolepermission(rolepermission, e, f)) {
    return true;
  }
  return false;
};
export const check4pointpermission = (a, b, permissionData, rolepermission) => {
  for (let i = a; i <= b; i++) {
    if (permissionData.includes(i)) {
      return true;
    }
  }
  for (let i = 0; i < alldatapermission.length; i++) {
    if (permissionData.includes(alldatapermission[i])) return false;
    // if (permissionData.includes(i)) return false;
  }
  if (checkrolepermission(rolepermission, a, b)) {
    return true;
  }
  return false;
};
export const check1pointpermission = (a, permissionData, rolepermission) => {
  let superAdminUserRole = localStorage.getItem("isw_user_role");
  if (Number(superAdminUserRole) === 6) return true
  if (permissionData.includes(a)) return true;
  for (let i = 0; i < alldatapermission.length; i++) {
    if (permissionData.includes(alldatapermission[i])) return false;
    // if (permissionData.includes(i)) return false;
  }
  if (rolepermission.includes(a)) return true;
  return false;
};
