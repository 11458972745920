import React, { useEffect } from "react";
import Pagination from '@mui/material/Pagination';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import { useNavigate, Link } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  root: {
    float: "right",
    "& > *": {
      // marginTop: theme.spacing(2),
    },
  },
}));
const HeadInstitutionCourse = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const goBack = () => {
    navigate("/provider");
  };

  useEffect(() => {
    let width = document.querySelector("table")?.offsetWidth;
    if (document.querySelector(".scroller")) {
      document.querySelector(".scroller").style.width = `${width}px`;
    }
  }, [props.headInstitutionCourses]);

  let scrolling = false;
  const scrolldiv = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow .table-responsive");
    table.scrollTo(scroller.scrollLeft, 0);
  };

  const scrolltable = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow  .table-responsive");
    scroller.scrollTo(table.scrollLeft, 0);
  };

  const viewReportpage = (elem) => {
    var course_id = elem.target.id;
    navigate("/course-report/" + course_id);
  };

  return (
    <div style={{ margin: "0 20px" }}>
      <div className="d-flex justify-content-end my-1">
        <button className="btn listing_addbutton" onClick={goBack}>
          {/* &#x2630;  */}
          List
        </button>
      </div>
      <TableContainer style={{ marginTop: "10px" }} component={Paper}>
        <Table
          className="workload card-table table-borderless"
          responsive
          striped>
          <TableHead style={{ backgroundColor: "cornflowerblue" }}>
            <TableRow>
              <TableCell>SN</TableCell>
              <TableCell>Course</TableCell>
              <TableCell>Cricos</TableCell>
              <TableCell>Provider Branch</TableCell>
              <TableCell>App Fees</TableCell>
              <TableCell>Duration</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.headInstitutionCourses?.map((p, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {index + 1}
                </TableCell>
                <TableCell component="th" scope="row">
                  <Link
                    className="greeneffect"
                    id={p.course_id}
                    onClick={viewReportpage}
                    to={`/course-report/` + p.course_id}
                  >
                    {p.name}
                  </Link>
                </TableCell>
                <TableCell>{p.cricos}</TableCell>
                <TableCell>{p.institution_name}</TableCell>
                <TableCell>{p.app_fees}</TableCell>
                <TableCell>{p.duration}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default HeadInstitutionCourse;
