import React, { useState, useEffect } from "react";
import Modal from "react-modal";

import { Col, Row } from "reactstrap";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Pagination from "@mui/material/Pagination";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import base_url from "../../api/bootapi";
import { selectUser } from "../features/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import attachmenturl from "../../api/attachmenturl";
import { toast } from "react-toastify";

import { logout } from "../features/userSlice";
import { useStyles } from "../../Utilities/CSSUtilities";
import {
  getproviderpermission,
  check4pointpermission,
  check1pointpermission,
} from "../Permission/permission";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    minWidth: "400px",
    maxWidth: "900px",
    maxHeight: "500px",
    marginRight: "-50%",
    transform: "translate(-36%, -50%)",
  },
};
const BranchesDetail = (props) => {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [country2, setcountry2] = useState();
  const [region2, setregion2] = useState();
  const [modalisopen, setisopen] = useState(false);
  const [modalisopen3, setisopen3] = useState(false);
  const [coursedetails, setcoursedetails] = useState();
  const [descriptionstate2, setdescriptionstate2] = useState();
  const [institutionid, setinstitutionid] = useState();
  const [branchagreementopen, setbranchagreementopen] = useState(false);
  const [branchagreementfiles, setbranchagreementfiles] = useState();
  const [fileinstitutionId, setfileinstitutionid] = useState();
  const [filebranchId, setfilebranchId] = useState();
  const jwtToken = "Bearer " + user.isw_user_token;
  const navigate = useNavigate();
  const [selectedinstitutionbranchid, setselectedinstitutionbranchid] =
    useState();
  const [pageno, setpageno] = useState(0);
  const [getpageno, setgetpageno] = useState();
  const [backdrop, setbackdrop] = useState(false);
  let params = useParams();
  const provider_id = params.provider_id;
  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);

  useEffect(() => {
    getPermissions();
  }, []);

  useEffect(() => {
    let width = document.querySelector("table")?.offsetWidth;
    if (document.querySelector(".scroller")) {
      document.querySelector(".scroller").style.width = `${width}px`;
    }
  }, [props.institutionBranches]);

  let scrolling = false;
  const scrolldiv = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow .table-responsive");
    table.scrollTo(scroller.scrollLeft, 0);
  };

  const scrolltable = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow  .table-responsive");
    scroller.scrollTo(table.scrollLeft, 0);
  };

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);

    if (check1pointpermission(706, permission_obj, resource_obj)) {
    } else {
      navigate("/errorPage");
    }
  };

  const openModal3 = (index) => {
    setisopen3(true);
    setselectedinstitutionbranchid(index);
    axios
      .get(`${base_url.api2}/getCourseByInstitutionBranchId`, {
        headers: {
          institutionBranchId: Number(index),
          columnName: "name",
          direction: "ASC",
          page: pageno,
          size: 10,
        },
      })
      .then((res) => {
        setcoursedetails(res.data.data.content);
        setgetpageno(res.data.data.totalPages);
      })
      .catch((err) => {
        //console.log(err);
        handle403error(err);
      });
  };

  const handlepage = (event, value) => {
    setpageno(value - 1);
    axios
      .get(`${base_url.api2}/getCourseByInstitutionBranchId`, {
        headers: {
          institutionBranchId: Number(selectedinstitutionbranchid),
          columnName: "name",
          direction: "ASC",
          page: value - 1,
          size: 10,
        },
      })
      .then((res) => {
        setcoursedetails(res.data.data.content);
        setgetpageno(res.data.data.totalPages);
      })
      .catch((err) => {
        //console.log(err);
        handle403error(err);
      });
  };
  const openModal = (index) => {
    setisopen(true);
    setinstitutionid(index);
    setcountry2(props.institutionBranches[index].country);
    setregion2(props.institutionBranches[index].state);
    setdescriptionstate2(props.institutionBranches[index].branchContactDetails);
  };

  const goBack = () => {
    navigate("/provider");
  };

  const downloadAllBranchFile = (branchTransactionalAgreementPath) => {
    setbackdrop(true);
    let file_name =
      branchTransactionalAgreementPath.split("amazonaws.com/")[
        branchTransactionalAgreementPath.split("amazonaws.com/").length - 1
      ];
    const header = {
      method: "GET",
      headers: {
        Authorization: jwtToken,
        instituteId: provider_id,
        branchId: filebranchId,
        fileName: file_name,
      },
    };

    var url = `${base_url.api2}/downloadBranchTransactionalAgreementByBranchIdAndFileName`;
    fetch(url, header)
      .then((response) => readAllChunksBranch(response))
      .then(async function (response) {
        //console.log(response);
        const downloadFileType = localStorage.getItem(
          "provider_branch_application_attachment_file_content_type"
        );
        //console.log(downloadFileType);
        var blob = new Blob(response, { type: downloadFileType });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = file_name;
        link.click();
        setbackdrop(false);
      })
      .catch((error) => {
        setbackdrop(false);
        //console.log(error);
        handle403error(error);
      });
  };

  const readAllChunksBranch = (readableStream) => {
    localStorage.setItem(
      "provider_branch_application_attachment_file_content_type",
      readableStream.headers.get("content-type")
    );
    const reader = readableStream.body.getReader();
    const chunks = [];

    function pump() {
      return reader
        .read()
        .then(({ value, done }) => {
          if (done) {
            return chunks;
          }
          chunks.push(value);
          return pump();
        })
        .catch((err) => {
          handle403error(err);
        });
    }
    return pump();
  };

  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            navigate("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };
  return (
    <div style={{ margin: "0 20px" }}>
      <Backdrop className={classes.backdrop} open={backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="d-flex justify-content-end my-1">
        <button className="btn listing_addbutton" onClick={goBack}>
          {/* &#x2630; */}
          List
        </button>
      </div>
      <TableContainer style={{ marginTop: "10px" }} component={Paper}>
        <Table
          className="workload card-table table-borderless"
          responsive
          striped
        >
          <TableHead style={{ backgroundColor: "cornflowerblue" }}>
            <TableRow>
              <TableCell>SN</TableCell>
              <TableCell>Branch Name</TableCell>
              <TableCell align="right">Address</TableCell>
              <TableCell align="right">Commission %</TableCell>
              <TableCell align="right">Attachment</TableCell>
              <TableCell align="right">Courses</TableCell>
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.institutionBranches?.map((p, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {index + 1}
                </TableCell>
                <TableCell component="th" scope="row">
                  {p.branchName}
                </TableCell>
                <TableCell align="right">{p.branchAddress}</TableCell>

                <TableCell align="right">{p.commissionPercentage}</TableCell>
                <TableCell align="right">
                  {check1pointpermission(
                    707,
                    permissionData,
                    rolepermission
                  ) ? (
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setbranchagreementopen(true);
                        setfileinstitutionid(p.institutionId);
                        setfilebranchId(p.branchId);
                        setbranchagreementfiles(p.branchTransactionalAgreement);
                      }}
                    >
                      View
                    </a>
                  ) : null}
                  <Modal
                    isOpen={branchagreementopen}
                    onRequestClose={() => setbranchagreementopen(false)}
                    style={customStyles}
                    contentLabel="Example Modal"
                  >
                    <div>
                      <h5>Application Forms</h5>
                      <hr />
                      <TableContainer
                        style={{ marginTop: "10px" }}
                        component={Paper}
                      >
                        <Table
                          className={classes.table}
                          aria-label="simple table"
                        >
                          <TableHead
                            style={{
                              backgroundColor: "cornflowerblue",
                            }}
                          >
                            <TableRow>
                              <TableCell>SN</TableCell>
                              <TableCell>Name</TableCell>
                              <TableCell align="right">View</TableCell>
                            </TableRow>
                          </TableHead>
                          {branchagreementfiles?.length != null ? (
                            <TableBody>
                              {branchagreementfiles?.map((p, index) => (
                                <TableRow key={index}>
                                  <TableCell component="th" scope="row">
                                    {index + 1}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    {p.branchTransactionalAgreementName}
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    align="right"
                                  >
                                    <a
                                      onClick={() => {
                                        downloadAllBranchFile(
                                          p.branchTransactionalAgreementPath
                                        );
                                      }}
                                      style={{ cursor: "pointer" }}
                                    >
                                      {p.branchTransactionalAgreementName}
                                    </a>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          ) : (
                            "No Files Available"
                          )}
                        </Table>
                      </TableContainer>
                      <button
                        type="submit"
                        className="btn btn-secondary"
                        style={{
                          marginRight: "20px",
                          marginTop: "20px",
                          float: "right",
                        }}
                        onClick={() => setbranchagreementopen(false)}
                      >
                        Close
                      </button>
                    </div>
                  </Modal>
                </TableCell>
                {check1pointpermission(708, permissionData, rolepermission) ? (
                  <TableCell
                    align="right"
                    onClick={() => openModal3(p.branchId)}
                    style={{ cursor: "pointer" }}
                  >
                    View
                  </TableCell>
                ) : null}
                {check1pointpermission(709, permissionData, rolepermission) ? (
                  <TableCell
                    align="right"
                    onClick={() => openModal(index)}
                    style={{ cursor: "pointer" }}
                  >
                    View
                  </TableCell>
                ) : null}
                <Modal
                  isOpen={modalisopen}
                  onRequestClose={() => setisopen(false)}
                  style={customStyles}
                  contentLabel="Example Modal"
                >
                  <div>
                    <form>
                      <Row>
                        <Col md={6}>
                          <h5>Provider Branch</h5>
                        </Col>
                        <Col md={6}>
                          <div style={{ float: "right" }}>
                            {/* <Row className="branch_buttonrow"> */}
                            <button
                              type="submit"
                              className="btn btn-secondary"
                              style={{ marginLeft: "20px" }}
                              onClick={() => setisopen(false)}
                            >
                              Close
                            </button>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={3}>
                          <label>Branch Name:</label>
                          <input
                            name="branchName"
                            value={
                              props.institutionBranches[institutionid]
                                ?.branchName
                            }
                            type="text"
                            className="form-control underline-input"
                            readOnly
                          />
                        </Col>
                        <Col md={3}>
                          <label>Branch Address:</label>
                          <input
                            name="branchAddress"
                            value={
                              props.institutionBranches[institutionid]
                                ?.branchAddress
                            }
                            type="textarea"
                            className="form-control underline-input"
                            readOnly
                          />
                        </Col>
                        <Col md={3}>
                          <label>Country:</label>
                          <input
                            className="form-control underline-input"
                            name="can"
                            value={country2}
                            type="text"
                            readOnly
                          />
                        </Col>
                        <Col md={3}>
                          <label>State:</label>
                          <input
                            className="form-control underline-input"
                            name="can"
                            value={region2}
                            type="text"
                            readOnly
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col md={3}>
                          <label>City:</label>
                          <input
                            name="city"
                            type="textarea"
                            value={
                              props.institutionBranches[institutionid]?.city
                            }
                            className="form-control underline-input"
                            readOnly
                          />
                        </Col>
                        <Col md={3}>
                          <label>Latitude:</label>
                          <input
                            name="lattitude"
                            value={
                              props.institutionBranches[institutionid]
                                ?.lattitude
                            }
                            type="number"
                            className="form-control underline-input"
                            onWheel={(event) => event.currentTarget.blur()}
                            step="any"
                            readOnly
                          />
                        </Col>
                        <Col md={3}>
                          <label>Longitude:</label>
                          <input
                            name="longitude"
                            value={
                              props.institutionBranches[institutionid]
                                ?.longitude
                            }
                            type="number"
                            className="form-control underline-input"
                            onWheel={(event) => event.currentTarget.blur()}
                            step="any"
                            readOnly
                          />
                        </Col>
                        <Col md={3}>
                          <label>Commission %:</label>
                          <input
                            name="commissionPercentage"
                            value={
                              props.institutionBranches[institutionid]
                                ?.commissionPercentage
                            }
                            type="number"
                            style={{ textAlign: "right" }}
                            onWheel={(event) => event.currentTarget.blur()}
                            className="form-control underline-input"
                            readOnly
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <label
                            style={{
                              marginTop: "15px",
                              marginBottom: "15px",
                            }}
                          >
                            Contact Details:
                          </label>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: descriptionstate2,
                            }}
                          />
                        </Col>
                      </Row>
                    </form>
                  </div>
                </Modal>
                <Modal
                  isOpen={modalisopen3}
                  onRequestClose={() => setisopen3(false)}
                  style={customStyles}
                  contentLabel="Example Modal"
                >
                  <div>
                    <h5>Courses</h5>
                    <hr />
                    <TableContainer
                      style={{ marginTop: "10px" }}
                      component={Paper}
                    >
                      <Table
                        className={classes.table}
                        aria-label="simple table"
                      >
                        <TableHead
                          style={{ backgroundColor: "cornflowerblue" }}
                        >
                          <TableRow>
                            <TableCell>SN</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell align="right">Code</TableCell>
                            <TableCell align="right">App Fees</TableCell>
                            <TableCell align="right">Duration</TableCell>
                          </TableRow>
                        </TableHead>
                        {coursedetails?.length != null ? (
                          <>
                            <TableBody>
                              {coursedetails?.map((p, index) => (
                                <TableRow key={index}>
                                  <TableCell component="th" scope="row">
                                    {index + 1}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    {p.name}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    {p.courseCode}
                                  </TableCell>
                                  <TableCell align="right">
                                    {p.appFees}
                                  </TableCell>
                                  <TableCell align="right">
                                    {p.duration}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </>
                        ) : (
                          "No Course Available"
                        )}
                      </Table>
                    </TableContainer>
                    <Row>
                      <Col md={6}>{null}</Col>
                      <Col md={6}>
                        <div className={classes.root}>
                          <Pagination
                            count={getpageno}
                            onChange={handlepage}
                            shape="rounded"
                          />
                        </div>
                      </Col>
                    </Row>
                    <button
                      type="submit"
                      className="btn btn-secondary"
                      style={{
                        marginRight: "20px",
                        marginTop: "20px",
                        float: "right",
                      }}
                      onClick={() => setisopen3(false)}
                    >
                      Close
                    </button>
                  </div>
                </Modal>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default BranchesDetail;
