import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { Card, CardSubtitle, CardText, Col, Row, Button } from "reactstrap";
import axios from "axios";
import base_url from "./../../api/bootapi";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import "./../css/LoginForm.css";
import { selectAdmin } from "./../features/adminSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, setdashboardcheck, logout } from "../features/userSlice";
import "./../css/Responsive.css";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import Select from "react-select";
import { useStyles } from "../../Utilities/CSSUtilities";
import DatePicker from "react-datepicker";
import callsaveauditapi from "../../services/auditservice";
import { check1pointpermission } from "../Permission/permission";

function EditAdmin(props) {
  let params = useParams();
  const admin = useSelector(selectAdmin);
  const navigate = useNavigate();
  const username2 = params.admin_username;
  const [country1, setcountry] = useState();
  const [region, setregion] = useState();
  const user = useSelector(selectUser);
  const [setComment, setsetComment] = useState();
  const [selecteddate, setdate] = useState(null);
  const particularUser = [];
  const ipaddress = localStorage.getItem("AdminIpAddress");
  admin.map((item) => {
    if (item.username === username2) {
      particularUser.push(item);
    }
  });
  //console.log("Particular User", particularUser);
  const dispatch = useDispatch();
  //console.log(params.admin_username);
  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  const username = localStorage.getItem("isw_user_email");
  const prev_path = localStorage.getItem("adminPage");
  const classes = useStyles();
  const [stateCust, setstateCust] = useState({});
  const [availableroles, setAvailableroles] = useState(null);
  const [officeState, setOffice] = useState();
  const [officeState2, setOffice2] = useState(null);
  const [officeset1, setofficeset1] = useState(true);
  const isw_user_role = localStorage.getItem("isw_user_role");

  const isw_user_name = localStorage.getItem("isw_user_name");
  const isw_adminId = localStorage.getItem("isw_adminId");
  const isw_user_email = localStorage.getItem("isw_user_email");

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        navigate("/");
      }
    });
    getPermissions();
  }, []);

  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);

    if (check1pointpermission(610, permission_obj, resource_obj)) {
      dispatch(setdashboardcheck(19));
      getUser();
      // getRoles();
      getallSpecificRoles();
      getOffice();
    } else {
      navigate("/errorPage");
    }
  };

  const getRoles = () => {
    axios
      .get(`${base_url.api3}/listAllRoles`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        //console.log(response.data.data.content);
        setAvailableroles(response.data.data);
      })
      .catch((error) => {
        handle403error(error);
      });
  };
  //console.log(availableroles);

  const [specificroles, setSpecificRoles] = useState();
  const getallSpecificRoles = () => {
    axios
      .get(`${base_url.api3}/getActiveSpecificRoles`)
      .then((res) => {
        setSpecificRoles(res.data.data);
      })
      .catch((err) => {
        handle403error(err);
      });
  };

  const [availableoffice, setAvailableOffice] = useState(null);
  const getOffice = () => {
    axios
      .get(`${base_url.api1}/findAllOffice`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        let ofc_data = response.data.data;
        const office_obj = [];
        for (let i = 0; i < ofc_data.length; i++) {
          const ofc_data2 = ofc_data[i];
          //console.log(ofc_data2);
          let data = {
            value: ofc_data[i].officeId,
            label: ofc_data[i].officeName,
            country: ofc_data[i].country,
            city: ofc_data[i].city,
            officeCode: ofc_data[i].officeCode,
          };
          office_obj.push(data);
        }
        //console.log(response.data.data);
        setAvailableOffice(office_obj);
      })
      .catch((error) => {
        handle403error(error);
      });
  };

  const inputEvent = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setstateCust((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const getUser = () => {
    axios
      .get(`${base_url.api2}/getUserByUsername`, {
        headers: {
          Authorization: jwtToken,
          username: username2,
        },
      })
      .then((response) => {
        let userData = response.data.data;
        let ofc_data = response.data.userOfficeData;
        let ofcData_obj = [];
        for (let i = 0; i < ofc_data.length; i++) {
          const element = ofc_data[i];
          let data = {
            value: ofc_data[i].officeId,
            label: ofc_data[i].officeName,
            country: ofc_data[i].country,
            city: ofc_data[i].city,
            officeCode: ofc_data[i].officeCode,
          };
          ofcData_obj.push(data);
        }
        if (ofcData_obj.length == 0) {
          setofficeset1(true);
        } else {
          setofficeset1(false);
        }
        setOffice(ofcData_obj);
        setOffice2(ofcData_obj);
        setcountry(userData.country);
        setregion(userData.state);
        setstateCust({
          username: userData.username,
          firstName: userData.firstName,
          lastName: userData.lastName,
          gender: userData.gender,
          email: userData.email,
          phone: userData.phone,
          address: userData.address,
          street: userData.street,
          pincode: userData.pincode,
          city: userData.city,
          country: userData.country,
          state: userData.state,
          nationality: userData.nationality,
          passport: userData.passport,
          roleId: userData.roleId,
          roleCode: userData.roleCode,
          photo: userData.photo,
          dob: userData.dob,
          officeId: userData.officeId,
          status: userData.status,
          ownerUser: userData.ownerUser,
          addedOn: userData.addedOn,
          updatedOn: userData.updatedOn,
          lastLogin: userData.lastLogin,
          roleName: userData.roleName,
          id: userData.id,
          comment: userData.comment,
        });
        setdate(userData.dob);
        if (userData.roleId == 6) {
          setAvailableroles([
            {
              id: 6,
              roleCode: "SADM",
              roleName: "Super Admin",
              status: 1,
            },
          ]);
        } else {
          getRoles();
        }
      })
      .catch((error) => {
        handle403error(error);
      });
  };
  //console.log(availableroles);

  const selectCountry = (val) => {
    setcountry(val);
  };
  const selectRegion = (val) => {
    setregion(val);
  };

  const putCustomer = (e) => {
    const isoDateString = new Date().toISOString();
    const datenow = isoDateString.split(".")[0];
    stateCust.updatedOn = datenow;
    stateCust.country = country1;
    stateCust.nationality = country1;
    stateCust.passport = country1;
    stateCust.state = region;
    stateCust.roleId = Number(stateCust.roleId);
    stateCust.roleName = stateCust.roleName;
    // if (selecteddate) {
    //   stateCust.dob = moment(selecteddate).format("YYYY-MM-DDThh:mm:ss");
    // }
    stateCust.dob = selecteddate
      ? moment(selecteddate).format("YYYY-MM-DDThh:mm:ss")
      : null;
    let officeIdarray = [];
    const ofc_obj = [];
    //console.log(officeState2.length);
    if (officeset1 == false) {
      // setofficeset1(false);
      for (let i = 0; i < officeState2.length; i++) {
        let data = {
          officeId: Number(officeState[i].value),
          officeName: officeState[i].label,
          country: officeState[i].country,
          city: officeState[i].city,
          officeCode: officeState[i].officeCode,
        };
        officeIdarray.push(officeState[i].value);
        ofc_obj.push(data);
      }
      stateCust.officeId = officeIdarray.join(",");
      //console.log(ofc_obj);
      stateCust.office = ofc_obj;
      stateCust.pincode = Number(stateCust.pincode);
      stateCust.comment = setComment;

      //console.log("From Statecust is", stateCust);
      if (ofc_obj != 0) {
        axios
          .post(`${base_url.api2}/updateUserByEmail`, stateCust, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              Authorization: jwtToken,
            },
          })
          .then((response) => {
            toast.success("Success");
            let logs =
              isw_user_name +
              " Updated User " +
              response.data.data.firstName +
              " " +
              response.data.data.lastName +
              " of with Role " +
              response.data.data.roleName +
              ".";
            callsaveauditapi(logs, dispatch, navigate);
            // navigate("/users");
            navigate(prev_path);
          })
          .catch((error) => {
            handle403error(error);
            toast.error(JSON.stringify(error.response.data.message));
          });
      } else {
        setofficeset1(true);
      }
    } else {
      setofficeset1(true);
    }
    // for (let i = 0; i < officeState2.length; i++) {
    //   let data ={
    //     officeId: Number(officeState[i].value),
    //     officeName: officeState[i].label,
    //     country: officeState[i].country,
    //     city: officeState[i].city,
    //   }
    //   ofc_obj.push(data);
    // }
    //console.log(ofc_obj);

    //console.log(officeset1);
    //console.log(officeState2);
    //console.log(officeState);
    //console.log(stateCust.office);
  };

  const onSelect = (selectedList, selectedItem) => {
    const ofc_obj = [];
    for (let i = 0; i < selectedList.length; i++) {
      let data = {
        officeId: Number(selectedList[i].value),
        officeName: selectedList[i].label,
        country: availableoffice[i].country,
        city: availableoffice[i].city,
        officeCode: availableoffice[i].officeCode,
      };
      ofc_obj.push(data);
    }
    //console.log(selectedList);
    if (ofc_obj.length == 0) {
      setofficeset1(true);
      setOffice(null);
      setOffice2(null);
    } else {
      setofficeset1(false);
      setOffice2(ofc_obj);
      setOffice(selectedList);
    }

    // To restrict multiple----------------------------------
    // if (
    //   stateCust?.roleId == "1" ||
    //   stateCust?.roleId == "2" ||
    //   stateCust?.roleId == "3" ||
    //   stateCust?.roleId == "4" ||
    //   stateCust?.roleId == "5" ||
    //   stateCust?.roleId == "6"
    // ) {
    //   for (let i = 0; i < selectedList.length; i++) {
    //     let data = {
    //       officeId: Number(selectedList[i].value),
    //       officeName: selectedList[i].label,
    //       country: availableoffice[i].country,
    //       city: availableoffice[i].city,
    //       officeCode: availableoffice[i].officeCode,
    //     };
    //     ofc_obj.push(data);
    //   }
    //   //console.log(selectedList);
    //   if (ofc_obj.length == 0) {
    //     setofficeset1(true);
    //     setOffice(null);
    //     setOffice2(null);
    //   } else {
    //     setofficeset1(false);
    //     setOffice2(ofc_obj);
    //     setOffice(selectedList);
    //   }
    // } else {
    //   //console.log(selectedList);
    //   if (selectedList.length < 2) {
    //     for (let i = 0; i < selectedList.length; i++) {
    //       let data = {
    //         officeId: Number(selectedList[i].value),
    //         officeName: selectedList[i].label,
    //         country: availableoffice[i].country,
    //         city: availableoffice[i].city,
    //         officeCode: availableoffice[i].officeCode,
    //       };
    //       ofc_obj.push(data);
    //     }
    //     //console.log(selectedList);
    //     if (ofc_obj.length == 0) {
    //       setofficeset1(true);
    //       setOffice(null);
    //       setOffice2(null);
    //     } else {
    //       setofficeset1(false);
    //       setOffice2(ofc_obj);
    //       setOffice(selectedList);
    //     }
    //   } else {
    //     return null;
    //   }
    // }
    // To restrict multiple----------------------------------
  };

  const handleChangeOffice = (selectedList, selectedItem) => {
    //console.log(selectedList);
    //   const index = event.target.selectedIndex;
    //   const optionElement = event.target.childNodes[index];
    //   const optionElementId = optionElement.getAttribute("id");
    //   const optionElementName = optionElement.getAttribute("value");
    //   const optionElementCountry = optionElement.getAttribute("country");
    //   const optionElementCity = optionElement.getAttribute("city");
    //   const client_obj = [];
    //   let data = {
    //     officeId: Number(optionElementId),
    //     officeName: optionElementName,
    //     country: optionElementCountry,
    //     city: optionElementCity,
    //   };
    //   client_obj.push(data);
    //   //console.log(client_obj);
    //   if (optionElementName == "") {
    //     setofficeset1(true);
    //     setOffice(null);
    //     setOffice2(null);
    //   } else {
    //     setofficeset1(false);
    //     setOffice2(client_obj);
    //     // setOffice(selectedList);
    //   }
  };

  const [selectedrole, setSelectedrole] = useState(4);
  const [defaultRole, setdefaultRole] = useState("Consultant");
  const handleChangeRole = (event) => {
    setdefaultRole(event.target.value);
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("id");
    const optionElementValue = optionElement.getAttribute("value");
    stateCust.roleId = optionElementId;
    stateCust.roleName = optionElementValue;
    setSelectedrole(optionElementId);
    setdefaultRole(optionElementValue);
    // stateCust.roleId
    //console.log(optionElementId);
    //console.log(optionElementValue);
  };

  const goback = () => {
    // navigate("/users");
    navigate(prev_path);
  };

  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            navigate("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };

  return (
    <div>
      <ToastContainer />
      {/* <Dashboard/> */}
      {/* <Card className="commoncomponent_cardcss"> */}
      {/* {<ReactBootStrap.Spinner animation="border" />} */}
      <div
        className="page-head d-flex justify-content-between p-4 "
        style={{ marginTop: "4.3rem", marginBottom: "0px" }}
      >
        <div className="breadcrums">
          <h3 className="page-title bold">Edit User</h3>
          <Link to="/" className="b-link">
            Home
          </Link>
          <span className="s_span ">&raquo;</span>
          <a className="b-link">Access Level</a>
          <span className="s_span ">&raquo;</span>
          <a className="b-link active">User Edit</a>
        </div>
        <a onClick={goback} className="sent-button">
          List
        </a>
      </div>
      <div style={{ padding: "1.5rem", border: "1.5rem solid #e9ebf2" }}>
        <CardSubtitle className="font-weight-bold"></CardSubtitle>
        {availableroles == null ? (
          <Backdrop className={classes.backdrop} open>
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              putCustomer(e);
            }}
          >
            <div className="form-inner-admin">
              {/* <Row>
                <Col md={6}>
                  <CardText className={classes.headtext}>
                    <strong>User</strong>
                  </CardText>
                </Col>
                <Col md={6} className="d-flex justify-content-end">
                  <button className="btn listing_addbutton" onClick={goback}>
                    &#x2630; List
                  </button>
                </Col>
              </Row> */}
              <div className="form-group creatadmin">
                <Row>
                  <Col md={3}>
                    <label className="area_rowslable_my">
                      First Name<span style={{ color: "red" }}>*</span>:
                    </label>
                    <input
                      type="text"
                      value={stateCust.firstName}
                      name="firstName"
                      onChange={inputEvent}
                      style={{ backgroundColor: "#f5f5f9" }}
                      className="form-control form-control-sm"
                      required
                    />
                  </Col>
                  <Col md={3}>
                    <label className="area_rowslable_my">
                      Last Name<span style={{ color: "red" }}>*</span>:
                    </label>
                    <input
                      type="text"
                      value={stateCust.lastName}
                      name="lastName"
                      onChange={inputEvent}
                      style={{ backgroundColor: "#f5f5f9" }}
                      className="form-control form-control-sm"
                      required
                    />
                  </Col>
                  <Col md={3}>
                    <label className="area_rowslable_my">
                      Gender<span style={{ color: "red" }}>*</span>:
                    </label>
                    <select
                      defaultValue={stateCust.gender}
                      className={classes.selectdrop}
                      name="gender"
                      value={stateCust.gender}
                      onInput={inputEvent}
                      style={{ backgroundColor: "#f5f5f9" }}
                      // id={stateCust.gender}
                      required
                    >
                      <option value="Female">Female</option>
                      <option value="Male">Male</option>
                      <option value="Others">Others</option>
                    </select>
                  </Col>
                  <Col md={3}>
                    <label className="area_rowslable_my">
                      Email / Username<span style={{ color: "red" }}>*</span>:
                    </label>
                    <input
                      type="email"
                      value={stateCust.email}
                      name="username"
                      style={{ backgroundColor: "#f5f5f9" }}
                      onChange={(e) => {
                        const value = e.target.value;
                        stateCust.email = value;
                        inputEvent(e);
                      }}
                      className="form-control form-control-sm"
                      readOnly
                    />
                  </Col>
                </Row>
              </div>
              <div className="form-group creatadmin">
                <Row>
                  <Col md={3}>
                    <label className="area_rowslable_my">Contact No.:</label>
                    <input
                      type="text"
                      value={stateCust.phone}
                      name="phone"
                      style={{ backgroundColor: "#f5f5f9" }}
                      onChange={inputEvent}
                      className="form-control form-control-sm"
                    />
                  </Col>
                  <Col md={3}>
                    <label className="area_rowslable_my">
                      Address<span style={{ color: "red" }}>*</span>:
                    </label>
                    <input
                      type="text"
                      value={stateCust.address}
                      name="address"
                      style={{ backgroundColor: "#f5f5f9" }}
                      onChange={inputEvent}
                      className="form-control form-control-sm"
                      required
                    />
                  </Col>
                  <Col md={3}>
                    <label className="area_rowslable_my">
                      Country<span style={{ color: "red" }}>*</span>:
                    </label>
                    <CountryDropdown
                      defaultOptionLabel="- Select Country -"
                      className="form-control underline-input countrydropdown2"
                      value={country1}
                      style={{ backgroundColor: "#f5f5f9" }}
                      onChange={(val) => selectCountry(val)}
                      required
                    />
                  </Col>
                  <Col md={3}>
                    <label className="area_rowslable_my">
                      State<span style={{ color: "red" }}>*</span>:
                    </label>
                    {country1 == "Nepal" ? (
                      <select
                        className={classes.selectdrop}
                        name="gender"
                        value={region}
                        style={{ backgroundColor: "#f5f5f9" }}
                        onInput={(e) => selectRegion(e.target.value)}
                      >
                        <option value="Province No. 1">Province No. 1</option>
                        <option value="Madhesh Province">
                          Madhesh Province
                        </option>
                        <option value="Bagmati Province">
                          Bagmati Province
                        </option>
                        <option value="Gandaki Province">
                          Gandaki Province
                        </option>
                        <option value="Lumbini Province">
                          Lumbini Province
                        </option>
                        <option value="Karnali Province">
                          Karnali Province
                        </option>
                        <option value="Sudurpashchim Province">
                          Sudurpashchim Province
                        </option>
                      </select>
                    ) : (
                      <RegionDropdown
                        defaultOptionLabel="- Select State -"
                        className="form-control underline-input countrydropdown2"
                        country={country1}
                        value={region}
                        style={{ backgroundColor: "#f5f5f9" }}
                        onChange={(val) => selectRegion(val)}
                        required
                      />
                    )}
                  </Col>
                </Row>
              </div>
              <div className="form-group creatadmin">
                <Row>
                  <Col md={3}>
                    <label className="area_rowslable_my">
                      City<span style={{ color: "red" }}>*</span>:
                    </label>
                    <input
                      type="text"
                      value={stateCust.city}
                      name="city"
                      onChange={inputEvent}
                      style={{ backgroundColor: "#f5f5f9" }}
                      className="form-control form-control-sm"
                      required
                    />
                  </Col>
                  <Col md={3}>
                    <label className="area_rowslable_my">PostCode:</label>
                    <input
                      type="number"
                      value={stateCust.pincode}
                      name="pincode"
                      onChange={inputEvent}
                      style={{ backgroundColor: "#f5f5f9" }}
                      className="form-control form-control-sm"
                    />
                  </Col>
                  <Col md={3} style={{ position: "relative" }}>
                    <label className="area_rowslable_my">Date of Birth:</label>
                    <DatePicker
                      selected={selecteddate ? new Date(selecteddate) : null}
                      onChange={(date) => setdate(date)}
                      dateFormat="dd-MM-yyyy"
                      showYearDropdown
                      dropdownMode="select"
                      style={{ backgroundColor: "#f5f5f9" }}
                      placeholderText="Select Date"
                    />
                  </Col>
                  <Col md={3}>
                    <label className="area_rowslable_my">
                      Role<span style={{ color: "red" }}>*</span>:
                    </label>
                    {isw_user_role == 1 || isw_user_role == 6 ? (
                      <select
                        defaultValue={stateCust.roleName}
                        value={stateCust.roleName}
                        className={classes.selectdrop}
                        id={stateCust.roleId}
                        name="roleName"
                        style={{ backgroundColor: "#f5f5f9" }}
                        onInput={(e) => {
                          handleChangeRole(e);
                          const index = e.target.selectedIndex;
                          const optionElement = e.target.childNodes[index];
                          const optionElementId =
                            optionElement.getAttribute("id");
                          const optionElementvalue =
                            optionElement.getAttribute("value");
                          const optionElementvalue2 =
                            optionElement.getAttribute("roleCode");
                          //console.log(optionElementvalue2);
                          setstateCust.roleId = optionElementId;
                          setstateCust.roleName = optionElementvalue;
                          stateCust.roleCode = optionElementvalue2;
                          inputEvent(e);
                        }}
                        required
                      >
                        {availableroles.map((cuntitem) => (
                          <option
                            key={cuntitem.id}
                            id={cuntitem.id}
                            roleCode={cuntitem.roleCode}
                            value={cuntitem.roleName}
                          >
                            {cuntitem.roleName}-{cuntitem.roleCode}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <select
                        defaultValue={stateCust.roleName}
                        value={stateCust.roleName}
                        className={classes.selectdrop}
                        id={stateCust.roleId}
                        style={{ backgroundColor: "#f5f5f9" }}
                        name="roleName"
                        onInput={(e) => {
                          handleChangeRole(e);
                          const index = e.target.selectedIndex;
                          const optionElement = e.target.childNodes[index];
                          const optionElementId =
                            optionElement.getAttribute("id");
                          const optionElementvalue =
                            optionElement.getAttribute("value");
                          const optionElementvalue2 =
                            optionElement.getAttribute("roleCode");
                          //console.log(optionElementvalue2);
                          setstateCust.roleId = optionElementId;
                          setstateCust.roleName = optionElementvalue;
                          stateCust.roleCode = optionElementvalue2;
                          inputEvent(e);
                        }}
                        required
                      >
                        {specificroles?.map((cuntitem) => (
                          <option
                            key={cuntitem.id}
                            id={cuntitem.id}
                            roleCode={cuntitem.roleCode}
                            value={cuntitem.roleName}
                          >
                            {cuntitem.roleName}
                          </option>
                        ))}
                      </select>
                    )}
                  </Col>
                </Row>
              </div>
              <div className="form-group creatadmin">
                <Row>
                  <Col md={3}>
                    <label className="area_rowslable_my">
                      Status<span style={{ color: "red" }}>*</span>:
                    </label>
                    {stateCust?.roleId == 6 ? (
                      <select
                        defaultValue={
                          stateCust.status == 1 ? "Active" : "InActive"
                        }
                        className={classes.selectdrop}
                        name="status"
                        value={stateCust.status}
                        onInput={inputEvent}
                        style={{ backgroundColor: "#f5f5f9" }}
                        required
                      >
                        <option value="1">Active</option>
                      </select>
                    ) : (
                      <select
                        defaultValue={
                          stateCust.status == 1 ? "Active" : "InActive"
                        }
                        className={classes.selectdrop}
                        name="status"
                        value={stateCust.status}
                        onInput={inputEvent}
                        style={{ backgroundColor: "#f5f5f9" }}
                        required
                      >
                        <option value="1">Active</option>
                        <option value="0">InActive</option>
                      </select>
                    )}
                  </Col>
                  <Col
                    md={9}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <label className="area_rowslable_my">
                      Select Branch<span style={{ color: "red" }}>*</span>:
                    </label>
                    {/* {stateCust?.roleId == "1" ||
                    stateCust?.roleId == "2" ||
                    stateCust?.roleId == "3" ||
                    stateCust?.roleId == "4" ||
                    stateCust?.roleId == "5" ||
                    stateCust?.roleId == "6" ? ( */}
                    <Select
                      value={officeState}
                      isMulti={true}
                      name="officeName"
                      options={availableoffice}
                      className="brach_dropdown"
                      classNamePrefix="select"
                      onChange={onSelect}
                    />
                    {/* ) : (
                      <Select
                        value={officeState}
                        isMulti={false}
                        name="officeName"
                        options={availableoffice}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        // onChange={(v) => (v.length < 2 ? setOffice(v) : null)}
                        onChange={onSelect}
                      />
                    )} */}
                    <br />
                    {officeset1 ? (
                      <p style={{ color: "red", fontSize: "10px" }}>
                        Select Atleast One Branch
                      </p>
                    ) : null}
                  </Col>
                </Row>
              </div>
              <div className="form-group creatadmin">
                <Row>
                  <Col md={12}>
                    <label className="area_rowslable_my">Remarks:</label>
                    <TextareaAutosize
                      rowsMax={6}
                      style={{ height: "100px", backgroundColor: "#f5f5f9" }}
                      defaultValue={stateCust?.comment}
                      className="form-control underline-input"
                      onChange={(e) => {
                        //console.log(e.target.value);
                        setsetComment(e.target.value);
                      }}
                    />
                  </Col>
                </Row>
              </div>
            </div>
            <div className="d-flex justify-content-end">
              <button className="btn listing_addbutton" type="submit">
                Update
              </button>
            </div>
          </form>
        )}
        {/* </Card> */}
      </div>
    </div>
  );
}

export default EditAdmin;
