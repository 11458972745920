import React, { useState, useEffect } from "react";
import notes from "../../images/workload/notes.svg";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Col, InputGroup, Row } from "reactstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Modal from "react-modal";
import { customStyles } from "../../../Utilities/CSSUtilities";
const editorConfiguration = {
  toolbar: {
    items: [
      "heading",
      "|",
      "bold",
      "italic",
      "horizontalLine",
      "underline",
      "link",
      "bulletedList",
      "numberedList",
      "todoList",
      "|",
      "outdent",
      "indent",
      "alignment",
      "|",
      // "imageUpload",
      "imageInsert",
      "blockQuote",
      "fontBackgroundColor",
      "fontColor",
      "insertTable",
      "fontSize",
      "fontFamily",
      // "htmlEmbed",
      // "mediaEmbed",
      "codeBlock",
      // "code",
      "undo",
      "redo",
    ],
  },
  language: "en",
  image: {
    toolbar: [
      "imageTextAlternative",
      "imageStyle:full",
      "imageStyle:side",
      "linkImage",
    ],
  },
  table: {
    contentToolbar: [
      "tableColumn",
      "tableRow",
      "mergeTableCells",
      "tableCellProperties",
      "tableProperties",
    ],
  },
  licenseKey: "",
};

function AdmissionNoteModal({
  workflowtyperedux,
  modalIsOpen,
  closeModal,
  notestitle,
  setnotestitle,
  settemplate,
  proformadetails,
  template,
  setdescriptionState2,
  setdescriptionState,
  submit,
  isApplicationClosed,
}) {
  const [notemodal, setnotemodal] = useState(false);

  useEffect(() => {
    if (!modalIsOpen) {
      closenotemodal();
    }
  }, [modalIsOpen]);

  const opennotemodal = () => {
    setnotemodal(true);
  };
  const closenotemodal = () => setnotemodal(false);

  //console.log("91--->", proformadetails);
  return (
    <>
      <img
        src={notes}
        title={workflowtyperedux === "Admission" ? "Comments" : "Notes"}
        alt=""
        style={{ margin: "1px" }}
        onClick={opennotemodal}
      />
      <Modal
        isOpen={isApplicationClosed ? false : notemodal}
        onRequestClose={opennotemodal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div>
          <div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                submit(e);
                setnotemodal(false);
              }}
            >
              <Row>
                <Col>
                  <h4>Add Note</h4>
                </Col>
                <Col md={6}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      padding: "5px",
                    }}
                  >
                    <button
                      className="btn btn-secondary cancel_margin"
                      onClick={closenotemodal}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn listing_addbutton ml-1"
                      type="submit"
                    >
                      Save
                    </button>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={9}>
                  <label>Title</label>
                  <br />
                  <input
                    type="text"
                    value={notestitle}
                    onChange={(e) => setnotestitle(e.target.value)}
                    className="form-control underline-input"
                    placeholder="Title Here"
                    style={{
                      width: "100%",
                      backgroundColor: "f5f5f9",
                    }}
                  />
                </Col>
                <Col md={3} className="form-group notesformmgroup">
                  <label>Templates</label>
                  <select
                    onChange={(e) => settemplate(e.target.value)}
                    style={{
                      width: "100%",
                      height: "40px",
                      backgroundColor: "f5f5f9",
                    }}
                  >
                    <option value="">- Select Template -</option>
                    {proformadetails?.map((item, index) => (
                      <option key={index} value={item.template}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </Col>
              </Row>
              <label>Note</label>
              {template === "null" ? (
                <CKEditor
                  editor={ClassicEditor}
                  config={editorConfiguration}
                  style={{ backgroundColor: "#f5f5f9" }}
                  data="<p></p>"
                  onReady={(editor) => {
                    //console.log("Editor is ready to use!", editor);
                    // setloadermodal(true);
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setdescriptionState2(data);
                  }}
                />
              ) : (
                <CKEditor
                  editor={ClassicEditor}
                  config={editorConfiguration}
                  data={template}
                  style={{ backgroundColor: "#f5f5f9" }}
                  onReady={(editor) => {
                    //console.log("Editor is ready to use!", editor);
                    // setloadermodal(true);
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setdescriptionState(data);
                  }}
                />
              )}
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default AdmissionNoteModal;
