import React, { useState } from "react";
import Modal from "react-modal";
import { Button } from "reactstrap";
import { customStyles } from "../../../Utilities/CSSUtilities";
import { Link } from "react-router-dom";

const DeleteAgentModal = ({ deleteCurrentUser, name, id, p, deleteuser }) => {
  const [deletemodal, setdeletemodal] = useState(false);
  const opendeletemodal = () => {
    setdeletemodal(true);
    //console.log(p.agentId);
    //console.log(name);
    localStorage.setItem("selected_agentId", p.agentId);
    localStorage.setItem("selected_agentName", name);
  };
  const closedeletemodal = () => setdeletemodal(false);
  const submitdelete = () => {
    deleteCurrentUser(p.agentId, name);
    closedeletemodal();
  };

  return (
    <>
      <span style={{ cursor: "pointer" }} onClick={opendeletemodal}>
        Delete
      </span>
      <Modal
        isOpen={deletemodal}
        onRequestClose={closedeletemodal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <h4 style={{ marginTop: "10px" }}>
          Are you sure you want to delete current record ?
        </h4>
        <div
          style={{
            display: "flex",
            marginTop: "20px",
            justifyContent: "flex-end",
            gap: "10px",
            marginBottom: "10px",
          }}
        >
          <button className="btn listing_addbutton ml-1" onClick={submitdelete}>
            Delete
          </button>
          <button
            className="btn btn-secondary cancel_margin"
            onClick={closedeletemodal}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </>
  );
};

export default DeleteAgentModal;
