import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Card, CardSubtitle, CardText, Col, Row, Button } from "reactstrap";
import axios from "axios";
import base_url from "./../../api/bootapi";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "./../css/LoginForm.css";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, logout } from "../features/userSlice";
import { selectClient, updateClient } from "./../features/clientSlice";
import "./../css/Responsive.css";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import { useStyles } from "../../Utilities/CSSUtilities";
import callsaveauditapi from "../../services/auditservice";
import {
  check4pointpermission,
  check1pointpermission,
} from "./../Permission/permission";
import { format } from 'date-fns';

const EditClient = () => {
  let params = useParams();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const client = useSelector(selectClient);
  const username2 = params.client_username;
  const particularClient = [];
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const ipaddress = localStorage.getItem("AdminIpAddress");
  const navigate = useNavigate();
  client?.map((item) => {
    if (item.username === username2) {
      particularClient.push(item);
    }
  });
  const [value, setValue] = React.useState("");
  const user2 = useSelector(selectUser);
  const isoDateString = new Date().toISOString();
  const datenow = isoDateString.split(".")[0];

  const [dateofbirthdate, setdateofbirthdate] = useState(null);
  const [passportissuedate, setpassportissuedate] = useState(null);
  const [passportexpirydate, setpassportexpirydate] = useState(null);
  const [visaissuedate, setvisaissuedate] = useState(null);
  const [visaexpirydate, setvisaexpirydate] = useState(null);
  const [healthcoverdate, sethealthcoverdate] = useState(null);
  const [insuranceexpirydate, setinsuranceexpirydate] = useState(null);
  const [membershipexpirydate, setmembershipexpirydate] = useState(null);
  const [intakedate, setintakedate] = useState(null);

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  const username = localStorage.getItem("isw_user_email");
  const isw_user_role = localStorage.getItem("isw_user_role");
  const classes = useStyles();

  const [stateCust, setstateCust] = useState({});
  const [clientDetail, setclientDetail] = useState({});
  const [region, setregion] = useState();
  const [country1, setcountry] = useState();
  const [country1Passport, setcountryPassport] = useState();

  const isw_user_name = localStorage.getItem("isw_user_name");
  const isw_adminId = localStorage.getItem("isw_adminId");
  const isw_user_email = localStorage.getItem("isw_user_email");

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        navigate("/");
      }
    });
    getPermissions();
  }, []);

  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);

    if (check1pointpermission(303, permission_obj, resource_obj)) {
      // if (available_permission ){
      let id = params.client_username;
      getIntake();
      getClientDetails();
      getHealth();
      getsubagent();
      getsuperagent();
      getHealthCover();
      getVisa();
      getClientSource();
      getOffice();
      // } else {
      //   navigate("/errorPage");
      // }
    } else {
      //console.log('No access to the page');
      navigate("/errorPage");
    }
  };
  const [availableoffice, setAvailableOffice] = useState(null);
  const getOffice = () => {
    axios
      .get(`${base_url.api1}/findAllOffice`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        //console.log(response.data.data);
        setAvailableOffice(response.data.data);
      })
      .catch((error) => {
        handle403error(error);
      });
  };
  const [allClientSource, setClientSource] = useState();
  const getClientSource = () => {
    axios
      .get(`${base_url.api1}/getAllClientSourceUsingQuery`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        setClientSource(response.data.data);
      })
      .catch((error) => {
        handle403error(error);
      });
  };

  const [allHealth, setallHealth] = useState();
  const getHealth = () => {
    axios
      .get(`${base_url.api1}/findAllInsurance`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        setallHealth(response.data.data);
      })
      .catch((error) => {
        handle403error(error);
      });
  };

  const [allVisa, setallVisa] = useState();
  const getVisa = () => {
    axios
      .get(`${base_url.api1}/getAllVisaType`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        setallVisa(response.data.data);
      })
      .catch((error) => {
        handle403error(error);
      });
  };
  const [allHealthCover, setallHealthCover] = useState();
  const getHealthCover = () => {
    axios
      .get(`${base_url.api1}/getAllHealthCoverType`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        setallHealthCover(response.data.data);
      })
      .catch((error) => {
        handle403error(error);
      });
  };
  const [allIntake, setAllIntake] = useState();
  const getIntake = () => {
    axios
      .get(`${base_url.api1}/getAllIntake`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        let intakeData = response.data.data;
        let intakeObj = [];
        for (let i = 0; i < intakeData.length; i++) {
          let data = {
            createdIntake: moment(intakeData[i].createdIntake).format(
              "YYYY-MM"
            ),
            val: moment(intakeData[i].name).format("YYYYMM"),
            id: intakeData[i].id,
            name: intakeData[i].name,
            status: intakeData[i].status,
          };
          intakeObj.push(data);
        }
        let newintakeObj = intakeObj.sort(function (a, b) {
          return b.val - a.val;
        });
        setAllIntake(newintakeObj);
      })
      .catch((error) => {
        handle403error(error);
      });
  };

  const [subagent, setsubagent] = useState();
  const getsubagent = () => {
    axios
      .get(`${base_url.api3}/findAgentsForDropDownList`, {
        headers: {
          Authorization: jwtToken,
          agentType: "Sub Agent",
        },
      })
      .then((response) => {
        setsubagent(response.data.data);
      })
      .catch((error) => {
        handle403error(error);
      });
  };

  const [superagent, setsuperagent] = useState();
  const getsuperagent = () => {
    axios
      .get(`${base_url.api3}/findAgentsForDropDownList`, {
        headers: {
          Authorization: jwtToken,
          agentType: "Super Agent",
        },
      })
      .then((response) => {
        setsuperagent(response.data.data);
      })
      .catch((error) => {
        handle403error(error);
      });
  };

  const [selectedOfficeid, setselectedOfficeid] = useState(null);
  const [selectedOfficename, setselectedOfficename] = useState(null);
  const [selectedOfficecode, setselectedOfficecode] = useState(null);
  const [availableconsultant, setConsultant] = useState();

  const handleChangeOffice = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("id");
    const optionElementName = optionElement.getAttribute("value");
    const optionElementCode = optionElement.getAttribute("officecode");
    //console.log(optionElementId);
    //console.log(optionElementName);
    //console.log(optionElementCode);
    setselectedOfficeid(optionElementId);
    setselectedOfficename(optionElementName);
    setselectedOfficecode(optionElementCode);
    axios
      .get(`${base_url.api2}/getUsersSelectByOfficeIdAndStatus`, {
        headers: {
          Authorization: jwtToken,
          officeId: Number(optionElementId),
          status: 1,
        },
      })
      .then((response) => {
        setConsultant(response.data.data);
        //console.log(response.data.data);
      })
      .catch((error) => {
        toast.error(JSON.stringify(error.response.data.message));
        handle403error(error);
      });
  };
  const [selectedConsultantid, setselectedConsultantid] = useState(null);
  const [selectedConsultantName, setselectedConsultantName] = useState(null);
  const [
    selectedConsultantconsultantemail,
    setselectedConsultantconsultantemail,
  ] = useState(null);
  const handleChangeConsultant = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("id");
    const optionElementName = optionElement.getAttribute("value");
    const optionElementconsultantName =
      optionElement.getAttribute("consultantname");
    setselectedConsultantid(optionElementId);
    setselectedConsultantName(optionElementconsultantName);
    setselectedConsultantconsultantemail(optionElementName);
  };
  const [selectedhealthname, setselectedhealthname] = useState(null);
  const handleChangeHealth = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("id");
    const optionElementName = optionElement.getAttribute("value");
    //console.log(optionElementName);
    // setselectedhealthid(optionElementId);
    setselectedhealthname(optionElementName);
  };

  const [selectedClientSource, setselectedClientSource] = useState(null);
  const handleClientSource = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("id");
    const optionElementName = optionElement.getAttribute("value");
    //console.log(optionElementName);
    // setselectedhealthid(optionElementId);
    setselectedClientSource(optionElementName);
  };

  // const [selectedhealthid, setselectedhealthid] = useState(null);
  const [selectedIntakename, setselectedIntakename] = useState(null);
  const handleChangeIntake = (date) => {
    const intakeDate = date ? format(date, 'MMM-yyyy') : null;
    setselectedIntakename(intakeDate);
  };

  const [getclientId, setclientId] = useState(null);

  const getClientDetails = () => {
    axios
      .get(`${base_url.api3}/getClientByUsername`, {
        headers: {
          Authorization: jwtToken,
          username: params.client_username,
        },
      })
      .then((response) => {
        //console.log(response.data.data.lastLogin);
        if (response.data.status_code == "200") {
          let client = response.data.data.clientDetail;
          //console.log(client);
          let client2 = response.data.data;
          setclientId(response.data.data.id);
          //console.log(client2.consultantEmail);
          setclientDetail({
            addedOn: client.addedOn,
            address: client.address,
            alias: client.alias,
            ban: client.ban,
            city: client.city,
            clientSource: client.clientSource,
            comment: client.comment,
            company: client.company,
            contactPreference: client.contactPreference,
            country: client.country,
            currentlyStudyingInstitutionName:
              client.currentlyStudyingInstitutionName,
            dob: client.dob,
            email: client.email,
            firstName: client.firstName,
            gender: client.gender,
            id: client.id,
            insuranceExpiryDate: client.insuranceExpiryDate,
            insuranceIssueDate: client.insuranceIssueDate,
            insuranceProvider: client.insuranceProvider,
            insuranceType: client.insuranceType,
            intake: client.intake,
            lastName: client.lastName,
            membershipExpiry: client.membershipExpiry,
            membershipNumber: client.membershipNumber,
            middleName: client.middleName,
            nationality: client.nationality,
            passport: client.passport,
            passportExpiryDate: client.passportExpiryDate,
            passportIssueDate: client.passportIssueDate,
            passportNumber: client.passportNumber,
            phone: client2.phone,
            secondaryphone: client2.secondaryphone,
            photo: client.photo,
            pincode: client.pincode,
            policyNumber: client.policyNumber,
            secondaryEmail: client.secondaryEmail,
            state: client.state,
            status: client.status,
            street: client.street,
            subAgent: client.subAgent,
            superAgent: client.superAgent,
            username: client.username,
            usi: client.usi,
            visaExpiry: client.visaExpiry,
            visaIssueDate: client.visaIssueDate,
            visaType: client.visaType,
            subAgentName: client.subAgentName,
            superAgentName: client.superAgentName,
            subAgentId: client.subAgentId,
            superAgentId: client.superAgentId,
          });
          setstateCust({
            addedOn: client.addedOn,
            address: client.address,
            alias: client.alias,
            ban: client.ban,
            city: client.street,
            clientDetail: clientDetail,
            clientSource: client2.clientSource,
            consultantId: client2.consultantId,
            consultantName: client2.consultantName,
            consultantEmail: client2.consultantEmail,
            contactPreference: client.contactPreference,
            country: client.country,
            email: client2.email,
            firstName: client.firstName,
            id: client2.id,
            enquiryLeadType: client2.enquiryLeadType,
            insuranceExpiryDate: client.insuranceExpiryDate,
            insuranceIssueDate: client.insuranceIssueDate,
            insuranceProvider: client.insuranceProvider,
            insuranceType: client.insuranceType,
            lastLogin: client2.lastLogin,
            lastName: client.lastName,
            middleName: client.middleName,
            officeId: client2.officeId,
            officeName: client2.officeName,
            officeCode: client2.officeCode,
            passwordRecoveryCode: client2.passwordRecoveryCode,
            passwordRecoveryDate: client2.passwordRecoveryDate,
            pincode: client.pincode,
            policyNumber: client.policyNumber,
            roleId: client2.roleId,
            secondaryEmail: client2.secondaryEmail,
            state: client.state,
            status: client.status,
            updatedOn: client2.updatedOn,
            username: client.username,
            visaExpiry: client.visaExpiry,
            visaIssueDate: client.visaIssueDate,
            visaType: client.visaType,
            clientRelation: client2.clientRelation,
            clientRelativeName: client2.clientRelativeName,
            clientRelativeEmail: client2.clientRelativeEmail,
            clientRelativePhone: client2.clientRelativePhone,
          });
          setdateofbirthdate(client.dob);
          setintakedate(client.intake);
          setpassportissuedate(client.passportIssueDate);
          setpassportexpirydate(client.passportExpiryDate);
          setvisaissuedate(client.visaIssueDate);
          setvisaexpirydate(client.visaExpiry);
          sethealthcoverdate(client.insuranceIssueDate);
          setinsuranceexpirydate(client.insuranceExpiryDate);
          setmembershipexpirydate(client.membershipExpiry);
          setcountry(client.country);
          setselectedClientSource(client2.clientSource);
          setcountryPassport(client.passport);
          setregion(client.state);
          setselectedhealthname(client.insuranceType);
          setValue(client.contactPreference);
          getconsultantdetails(client2.officeId);
          let deposit = response.data.data.deposit;
          if (deposit === 1) {
            setState((prev) => {
              return {
                ...prev,
                deposit: true,
              };
            });
          }
        }
      })
      .catch((err) => handle403error(err));
  };

  const getconsultantdetails = (id) => {
    axios
      .get(`${base_url.api2}/getUsersSelectByOfficeIdAndStatus`, {
        headers: {
          Authorization: jwtToken,
          officeId: Number(id),
          status: 1,
        },
      })
      .then((response) => {
        setConsultant(response.data.data);
        //console.log(response.data.data);
      })
      .catch((error) => {
        handle403error(error);
      });
  };
  const inputEvent = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setclientDetail((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
    stateCust.clientDetail = clientDetail;
    if (name === "status") {
      stateCust.status = value;
    }
  };

  const inputClient = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setstateCust((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const [errorsecondaryemail, seterrorsecondaryemail] = useState(false);

  const putCustomer = (e) => {
    if (clientDetail.secondaryEmail === clientDetail.email) {
      seterrorsecondaryemail(true);
      return false;
    }
    seterrorsecondaryemail(false);
    clientDetail.nationality = clientDetail.country;
    clientDetail.address = clientDetail.street;
    clientDetail.contactPreference = value;
    clientDetail.intake = intakedate
      ? moment(intakedate).format("MMM-yyyy")
      : null;;
    clientDetail.clientSource = selectedClientSource;
    clientDetail.passport = country1Passport;
    clientDetail.country = country1;
    clientDetail.state = region;
    clientDetail.phone = clientDetail.phone;
    clientDetail.secondaryphone = clientDetail.secondaryphone;
    clientDetail.dob = dateofbirthdate
      ? moment(dateofbirthdate).format("YYYY-MM-DDThh:mm:ss")
      : null;
    stateCust.insuranceExpiryDate = insuranceexpirydate
      ? moment(insuranceexpirydate).format("YYYY-MM-DDThh:mm:ss")
      : null;
    stateCust.insuranceIssueDate = healthcoverdate
      ? moment(healthcoverdate).format("YYYY-MM-DDThh:mm:ss")
      : null;
    stateCust.membershipExpiry = membershipexpirydate
      ? moment(membershipexpirydate).format("YYYY-MM-DDThh:mm:ss")
      : null;
    stateCust.passportExpiryDate = passportexpirydate
      ? moment(passportexpirydate).format("YYYY-MM-DDThh:mm:ss")
      : null;
    stateCust.passportIssueDate = passportissuedate
      ? moment(passportissuedate).format("YYYY-MM-DDThh:mm:ss")
      : null;
    stateCust.visaIssueDate = visaissuedate
      ? moment(visaissuedate).format("YYYY-MM-DDThh:mm:ss")
      : null;
    stateCust.visaExpiry = visaexpirydate
      ? moment(visaexpirydate).format("YYYY-MM-DDThh:mm:ss")
      : null;
    clientDetail.insuranceExpiryDate = insuranceexpirydate
      ? moment(insuranceexpirydate).format("YYYY-MM-DDThh:mm:ss")
      : null;
    clientDetail.insuranceIssueDate = healthcoverdate
      ? moment(healthcoverdate).format("YYYY-MM-DDThh:mm:ss")
      : null;
    clientDetail.membershipExpiry = membershipexpirydate
      ? moment(membershipexpirydate).format("YYYY-MM-DDThh:mm:ss")
      : null;
    clientDetail.passportExpiryDate = passportexpirydate
      ? moment(passportexpirydate).format("YYYY-MM-DDThh:mm:ss")
      : null;
    clientDetail.passportIssueDate = passportissuedate
      ? moment(passportissuedate).format("YYYY-MM-DDThh:mm:ss")
      : null;
    clientDetail.visaIssueDate = visaissuedate
      ? moment(visaissuedate).format("YYYY-MM-DDThh:mm:ss")
      : null;
    clientDetail.visaExpiry = visaexpirydate
      ? moment(visaexpirydate).format("YYYY-MM-DDThh:mm:ss")
      : null;

    let data3 = {
      addedOn: clientDetail.addedOn,
      address: clientDetail.address,
      alias: clientDetail.alias,
      ban: clientDetail.ban,
      city: clientDetail.city,
      phone: clientDetail.phone,
      secondaryphone: clientDetail.secondaryphone,
      clientDetail: clientDetail,
      clientSource: selectedClientSource,
      consultantId: stateCust.consultantId,
      consultantName: stateCust.consultantName,
      consultantEmail: stateCust.consultantEmail,
      contactPreference: clientDetail.contactPreference,
      country: country1,
      email: clientDetail.email,
      firstName: clientDetail.firstName,
      id: stateCust.id,
      insuranceExpiryDate: clientDetail.insuranceExpiryDate,
      insuranceIssueDate: clientDetail.insuranceIssueDate,
      insuranceProvider: clientDetail.insuranceProvider,
      insuranceType: selectedhealthname,
      lastLogin: stateCust.lastLogin,
      lastName: clientDetail.lastName,
      membershipExpiry: clientDetail.membershipExpiry,
      middleName: clientDetail.middleName,
      officeId: stateCust.officeId,
      officeName: stateCust.officeName,
      passportExpiryDate: stateCust.passportExpiryDate,
      passportIssueDate: stateCust.passportIssueDate,
      passwordRecoveryCode: stateCust.passwordRecoveryCode,
      passwordRecoveryDate: stateCust.passwordRecoveryDate,
      pincode: clientDetail.pincode,
      policyNumber: clientDetail.policyNumber,
      roleId: Number(stateCust.roleId),
      secondaryEmail: clientDetail.secondaryEmail,
      state: region,
      status: clientDetail.status,
      updatedOn: stateCust.updatedOn,
      username: clientDetail.email,
      visaExpiry: clientDetail.visaExpiry,
      visaIssueDate: clientDetail.visaIssueDate,
      visaType: clientDetail.visaType,
      clientRelation: stateCust.clientRelation,
      clientRelativeName: stateCust.clientRelativeName,
      clientRelativeEmail: stateCust.clientRelativeEmail,
      clientRelativePhone: stateCust.clientRelativePhone,
      deposit: checkBtn,
      updatedOn: datenow,
    };
    if (!stateCust.consultantId) {
      data3.officeId = Number(selectedOfficeid);
      data3.officeName = selectedOfficename;
      data3.consultantName = selectedConsultantName;
      data3.consultantEmail = selectedConsultantconsultantemail;
      data3.consultantId = Number(selectedConsultantid);
    }
    //console.log(clientDetail);
    //console.log(data3);

    axios
      .post(`${base_url.api3}/updateClientByUsername`, data3, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: jwtToken,
          enquiryLeadType: stateCust.enquiryLeadType,
        },
      })
      .then((response) => {
        toast.success("Success");
        dispatch(
          updateClient({
            id: clientDetail.username,
            data: response.data.data.clientDetail,
          })
        );
        let logs =
          isw_user_name +
          " Updated Client " +
          response.data.data.firstName +
          " " +
          response.data.data.lastName +
          "(" +
          response.data.data.email +
          ").";
        callsaveauditapi(logs, dispatch, navigate);
        navigate(
          "/view-enquiry-workflowList/" +
          getclientId +
          "/" +
          params.client_username
        );
      })
      .catch((error) => {
        handle403error(error);
      });
  };

  const goback = () => {
    // navigate("/client-list");
    navigate("/client-view/" + params.client_username);
  };

  const selectCountry = (val) => {
    setcountry(val);
  };
  const selectRegion = (val) => {
    setregion(val);
  };

  const selectPassportCountry = (val) => {
    setcountryPassport(val);
  };

  const [checkboxstate, setState] = React.useState({
    deposit: false,
  });
  const checkboxchange = (event) => {
    setState({ ...checkboxstate, [event.target.name]: event.target.checked });
  };

  const [checkBtn, setcheckBtn] = useState();

  function getCheckboxStatus(event) {
    var status = document.getElementById("flexCheckDefault").checked;
    if (status) {
      setState({ ...checkboxstate, [event.target.name]: event.target.checked });
      //console.log(event.target.checked, status);
      setcheckBtn(1);
    } else {
      setState({
        ...checkboxstate,
        [event.target.name]: event.target.unchecked,
      });
      //console.log(event.target.checked, status);
      setcheckBtn(0);
    }
  }

  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            navigate("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };

  return (
    <div>
      <ToastContainer />
      {/* <Card className="commoncomponent_cardcss"> */}
      <CardSubtitle className="font-weight-bold"></CardSubtitle>
      <div
        className="page-head d-flex justify-content-between p-4 "
        style={{ marginTop: "5rem" }}
      >
        <div className="breadcrums">
          <h3 className="page-title bold">Clients</h3>
          {/* <a href="index.html" className="b-link">Home</a>
          -
          <a href="client-dashboard.html" className="b-link">Client</a>
          -
          <a href="#" className="b-link active">Client</a> */}
        </div>
        <a onClick={goback} className="back-btn">
          Back
        </a>
      </div>
      <div style={{ padding: "1.5rem", border: "1.5rem solid #ccc" }}>
        {/* <Row>
          <Col md={6}>
            <CardText className={classes.headtext}>
              <strong>Client</strong>
            </CardText>
          </Col>
          <Col md={6} className="d-flex justify-content-end">
            <button className="btn listing_addbutton" onClick={goback}>
              &#x2630; List
            </button>
          </Col>
        </Row> */}
        {/* <CardText className={classes.headtext}>Create Admin User</CardText> */}
        <form onSubmit={handleSubmit(putCustomer)}>
          <div className="form-inner-admin">
            <div className="form-group creatadmin2">
              {/* <Row>
                    <Col md={6}> */}
              <div style={{ display: "flex", marginTop: "10px" }}>
                <p className="provider_containertitle">Office Use</p>
                {/* <hr style={{ flex: "1", marginLeft: "20px" }} /> */}
              </div>
              {/* </Col>
                    <Col md={6}>
                      <Button
                        style={{float:"right"}}
                        className={classes.createBtn}
                        color="primary"
                        // onClick={createClient}
                      >
                        Click Here To Edit Client
                      </Button>
                    </Col>
                  </Row> */}
              <Row>
                <Col md={3}>
                  <label className="area_rowslable_my">ID:</label>
                  <input
                    className="form-control underline-input"
                    name="clientid"
                    onChange={inputEvent}
                    value={stateCust.id}
                    type="text"
                    readOnly
                  />
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">
                    Branch<span style={{ color: "red" }}>*</span>:
                  </label>
                  <select
                    type="text"
                    value={stateCust.officeId}
                    name="officeName"
                    style={{ backgroundColor: "#f5f5f9" }}
                    onInput={(e) => {
                      handleChangeOffice(e);
                      const index = e.target.selectedIndex;
                      const optionElement = e.target.childNodes[index];
                      const optionElementId = optionElement.getAttribute("id");
                      const optionElementName =
                        optionElement.getAttribute("value");
                      const optionElementCode =
                        optionElement.getAttribute("officecode");
                      stateCust.officeId = optionElementId;
                      stateCust.officeName = optionElementName;
                      stateCust.officeCode = optionElementCode;
                      inputEvent(e);
                    }}
                    ref={register({ required: true })}
                    className={classes.selectdrop}
                  >
                    <option value="">- Select Branch -</option>
                    {isw_user_role == "1" ||
                      isw_user_role == "2" ||
                      isw_user_role == "3" ||
                      isw_user_role == "4" ||
                      isw_user_role == "5" ||
                      isw_user_role == "6" ? (
                      <>
                        {availableoffice?.map((p, index) => (
                          <option
                            key={p.officeId}
                            id={p.officeId}
                            value={p.officeId}
                            officecode={p.officeCode}
                          >
                            {p.officeName}
                          </option>
                        ))}
                      </>
                    ) : (
                      <>
                        {user2?.isw_available_Office?.map((p, index) => (
                          <option
                            key={p.officeId}
                            id={p.officeId}
                            value={p.officeId}
                            officecode={p.officeCode}
                          >
                            {p.officeName}
                          </option>
                        ))}
                      </>
                    )}
                  </select>
                  {errors.branch && (
                    <p className="errormessage">Branch is Required</p>
                  )}
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">
                    Consultant<span style={{ color: "red" }}>*</span>:
                  </label>
                  <select
                    value={stateCust.consultantEmail}
                    className={classes.selectdrop}
                    name="consultantName"
                    style={{ backgroundColor: "#f5f5f9" }}
                    onInput={(e) => {
                      handleChangeConsultant(e);
                      const index = e.target.selectedIndex;
                      const optionElement = e.target.childNodes[index];
                      const optionElementId = optionElement.getAttribute("id");
                      const optionElementName =
                        optionElement.getAttribute("value");
                      const optionElementconsultantName =
                        optionElement.getAttribute("consultantname");
                      stateCust.consultantId = optionElementId;
                      stateCust.consultantName = optionElementconsultantName;
                      stateCust.consultantEmail = optionElementName;
                      inputEvent(e);
                    }}
                    ref={register({ required: true })}
                    required
                  >
                    <option value="">- Select Consultant -</option>
                    {availableconsultant?.map((cuntitem) => (
                      <option
                        key={cuntitem.id}
                        value={cuntitem.email}
                        consultantname={`${cuntitem.first_name} ${cuntitem.last_name}`}
                        id={cuntitem.id}
                      >
                        {cuntitem.first_name} {cuntitem.last_name}
                      </option>
                    ))}
                  </select>
                  {errors.consultant && (
                    <p className="errormessage">Consultant is Required</p>
                  )}
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">
                    Client Source<span style={{ color: "red" }}>*</span>:
                  </label>
                  <select
                    name="clientSource"
                    style={{ backgroundColor: "#f5f5f9" }}
                    className={classes.selectdrop}
                    onInput={handleClientSource}
                    value={selectedClientSource}
                    ref={register({ required: true })}
                  // required
                  >
                    <option value="">- Select ClientSource -</option>
                    {allClientSource?.map((cuntitem) => (
                      <option
                        key={cuntitem.id}
                        value={cuntitem.name}
                        id={cuntitem.id}
                      >
                        {cuntitem.name}
                      </option>
                    ))}
                  </select>
                  {/* <input
                        type="text"
                        className="form-control underline-input"
                        name="clientSource"
                        id="clientSource"
                        onChange={inputEvent}
                        value={clientDetail.clientSource}
                        ref={register('clientSource',{ required: true })}
                      /> */}
                  {errors.clientSource && (
                    <p className="errormessage">ClientSource is Required</p>
                  )}
                </Col>
              </Row>
            </div>
            <div style={{ display: "flex", marginTop: "10px" }}>
              <p className="provider_containertitle">Personal Details</p>
              {/* <hr style={{ flex: "1", marginLeft: "20px" }} /> */}
            </div>
            <div className="form-group creatadmin2">
              <Row>
                <Col md={3}>
                  <label className="area_rowslable_my">
                    First Name<span style={{ color: "red" }}>*</span>:
                  </label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="firstName"
                    style={{ backgroundColor: "#f5f5f9" }}
                    onChange={inputEvent}
                    value={clientDetail.firstName}
                    ref={register({ required: true })}
                  />
                  {errors.firstName && (
                    <p className="errormessage">FirstName is Required</p>
                  )}
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">Middle Name:</label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="middleName"
                    style={{ backgroundColor: "#f5f5f9" }}
                    onChange={inputEvent}
                    ref={register}
                    value={clientDetail.middleName}
                  />
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">
                    Last Name<span style={{ color: "red" }}>*</span>:
                  </label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="lastName"
                    style={{ backgroundColor: "#f5f5f9" }}
                    onChange={inputEvent}
                    value={clientDetail.lastName}
                    ref={register({ required: true })}
                  />
                  {errors.lastName && (
                    <p className="errormessage">Lastname is Required</p>
                  )}
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">Sub Agent:</label>
                  <select
                    className={classes.selectdrop}
                    name="subAgent"
                    style={{ backgroundColor: "#f5f5f9" }}
                    onInput={(event) => {
                      const index = event.target.selectedIndex;
                      const optionElement = event.target.childNodes[index];
                      const optionElementId = optionElement.getAttribute("id");
                      const optionElementrealId =
                        optionElement.getAttribute("realid");
                      //console.log(optionElementId);
                      clientDetail.subAgentName = optionElementId;
                      clientDetail.subAgentId = optionElementrealId;
                      inputEvent(event);
                    }}
                    ref={register}
                    value={clientDetail.subAgent}
                  >
                    <option value="">- Select sub agent -</option>
                    {subagent?.map((cuntitem) => (
                      <option
                        key={cuntitem.id}
                        value={cuntitem.username}
                        id={cuntitem.name}
                        realid={cuntitem.id}
                      >
                        {cuntitem.name}
                      </option>
                    ))}
                  </select>
                </Col>
              </Row>
            </div>
            <div className="form-group creatadmin2">
              <Row>
                <Col md={3}>
                  <label className="area_rowslable_my">
                    Gender<span style={{ color: "red" }}>*</span>:
                  </label>
                  <select
                    className={classes.selectdrop}
                    ref={register({ required: true })}
                    name="gender"
                    style={{ backgroundColor: "#f5f5f9" }}
                    onInput={inputEvent}
                    value={clientDetail.gender}
                  >
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </select>
                  {errors.gender && (
                    <p className="errormessage">Gender is Required</p>
                  )}
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">Date Of Birth:</label>
                  <DatePicker
                    selected={
                      dateofbirthdate ? new Date(dateofbirthdate) : null
                    }
                    onChange={(date) => setdateofbirthdate(date)}
                    dateFormat="dd-MM-yyyy"
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="Select Date"
                  />
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">Alias:</label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="alias"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register}
                    onChange={inputEvent}
                    value={clientDetail.alias}
                  />
                </Col>
              </Row>
            </div>
            <div style={{ display: "flex", marginTop: "10px" }}>
              <p className="provider_containertitle">Contact Details</p>
              {/* <hr style={{ flex: "1", marginLeft: "20px" }} /> */}
            </div>
            <div className="form-group creatadmin2">
              <Row>
                <Col md={3}>
                  <label className="area_rowslable_my">
                    Email<span style={{ color: "red" }}>*</span>:
                  </label>
                  <input
                    type="email"
                    className="form-control underline-input"
                    name="email"
                    style={{ backgroundColor: "#f5f5f9" }}
                    onChange={inputEvent}
                    value={clientDetail.email}
                    ref={register({ required: true })}
                    readOnly
                  />
                  {errors.email && (
                    <p className="errormessage">Email is Required</p>
                  )}
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">Secondary Email:</label>
                  <input
                    type="email"
                    className="form-control underline-input"
                    name="secondaryEmail"
                    style={{ backgroundColor: "#f5f5f9" }}
                    onChange={inputEvent}
                    value={clientDetail.secondaryEmail}
                    ref={register}
                  />
                  {errorsecondaryemail && (
                    <p className="errormessage">
                      SecondaryEmail and Email should not be same{" "}
                    </p>
                  )}
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">Phone (primary):</label>
                  <input
                    type="number"
                    onWheel={(event) => event.currentTarget.blur()}
                    className="form-control underline-input"
                    name="phone"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register}
                    onChange={inputEvent}
                    value={clientDetail.phone}
                  />
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">
                    Phone (secondary):
                  </label>
                  <input
                    type="number"
                    onWheel={(event) => event.currentTarget.blur()}
                    className="form-control underline-input"
                    name="secondaryphone"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register}
                    onChange={inputEvent}
                    value={clientDetail.secondaryphone}
                  />
                </Col>
              </Row>
            </div>
            <div className="form-group creatadmin2">
              <Row>
                <Col md={3}>
                  <label className="area_rowslable_my">
                    Contact Preference
                  </label>
                  <br />
                  <br />
                  <div
                    class="form-check checkbox-group"
                    style={{ gap: "10px", marginTop: "-10px" }}
                  >
                    <input
                      type="radio"
                      name="contact"
                      id="phone"
                      value="phone"
                      style={{ backgroundColor: "#f5f5f9" }}
                      onChange={(e) => setValue(e.target.value)}
                      checked={value === "phone"}
                    />
                    <label
                      className="area_rowslable_my"
                      for="phone"
                      style={{ marginTop: "7px" }}
                    >
                      Phone
                    </label>
                  </div>
                  <div
                    class="form-check checkbox-group"
                    style={{ gap: "10px", marginTop: "-20px" }}
                  >
                    <input
                      type="radio"
                      name="contact"
                      id="email"
                      style={{ backgroundColor: "#f5f5f9" }}
                      onChange={(e) => setValue(e.target.value)}
                      value="email"
                      checked={value === "email"}
                    />
                    <label
                      className="area_rowslable_my"
                      for="email"
                      style={{ marginTop: "7px" }}
                    >
                      Email
                    </label>
                  </div>
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">Deposit Box</label>
                  <br />
                  <div class="container" style={{ marginTop: "0px" }}>
                    <input
                      type="checkbox"
                      checked={checkboxstate.deposit}
                      onChange={getCheckboxStatus}
                      id="flexCheckDefault"
                      name="deposit"
                    />
                    <label
                      className="area_rowslable_my"
                      class="checkmark"
                      for="flexCheckDefault"
                      style={{ marginTop: "10px" }}
                    ></label>
                    <label
                      className="area_rowslable_my"
                      class="form-check-label boxes"
                      for="flexCheckDefault"
                      style={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: "#071a52",
                      }}
                    >
                      Deposit
                    </label>
                  </div>
                  <br />
                </Col>
              </Row>
            </div>
            <div style={{ display: "flex", marginTop: "10px" }}>
              <p className="provider_containertitle">Address</p>
              {/* <hr style={{ flex: "1", marginLeft: "20px" }} /> */}
            </div>
            <div className="form-group creatadmin2">
              <Row>
                <Col md={3}>
                  <label className="area_rowslable_my">
                    Street<span style={{ color: "red" }}>*</span>:
                  </label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="street"
                    style={{ backgroundColor: "#f5f5f9" }}
                    onChange={inputEvent}
                    value={clientDetail.street}
                    ref={register({ required: true })}
                  />
                  {errors.street && (
                    <p className="errormessage">Street is Required</p>
                  )}
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">
                    City/Suburb<span style={{ color: "red" }}>*</span>:
                  </label>
                  <input
                    type="text"
                    style={{ backgroundColor: "#f5f5f9" }}
                    className="form-control underline-input"
                    name="city"
                    onChange={inputEvent}
                    value={clientDetail.city}
                    ref={register({ required: true })}
                  />
                  {errors.city && (
                    <p className="errormessage">City is Required</p>
                  )}
                </Col>

                <Col md={3}>
                  <label className="area_rowslable_my">
                    Country<span style={{ color: "red" }}>*</span>:
                  </label>
                  <CountryDropdown
                    defaultOptionLabel="- Select Country -"
                    className="form-control underline-input countrydropdown2"
                    value={country1}
                    style={{ backgroundColor: "#f5f5f9" }}
                    onChange={(val) => selectCountry(val)}
                    required
                  />
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">
                    State<span style={{ color: "red" }}>*</span>:
                  </label>
                  {country1 == "Nepal" ? (
                    <select
                      className={classes.selectdrop}
                      name="gender"
                      style={{ backgroundColor: "#f5f5f9" }}
                      value={region}
                      onChange={(e) => selectRegion(e.target.value)}
                    >
                      <option value="Province No. 1">Province No. 1</option>
                      <option value="Madhesh Province">Madhesh Province</option>
                      <option value="Bagmati Province">Bagmati Province</option>
                      <option value="Gandaki Province">Gandaki Province</option>
                      <option value="Lumbini Province">Lumbini Province</option>
                      <option value="Karnali Province">Karnali Province</option>
                      <option value="Sudurpashchim Province">
                        Sudurpashchim Province
                      </option>
                    </select>
                  ) : (
                    <RegionDropdown
                      defaultOptionLabel="- Select State -"
                      className="form-control underline-input countrydropdown2"
                      country={country1}
                      value={region}
                      onChange={(val) => selectRegion(val)}
                      required
                    />
                  )}
                </Col>
              </Row>
            </div>
            <div className="form-group creatadmin2">
              <Row>
                <Col md={3}>
                  <label className="area_rowslable_my">
                    PostCode<span style={{ color: "red" }}>*</span>:
                  </label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    style={{ backgroundColor: "#f5f5f9" }}
                    name="pincode"
                    onChange={inputEvent}
                    value={clientDetail.pincode}
                    ref={register({ required: true })}
                  />
                  {errors.pincode && (
                    <p className="errormessage">PostCode is Required</p>
                  )}
                </Col>
              </Row>
            </div>
            <div style={{ display: "flex", marginTop: "10px" }}>
              <p className="provider_containertitle">
                Passport/Visa Information
              </p>
              {/* <hr style={{ flex: "1", marginLeft: "20px" }} /> */}
            </div>
            <div className="form-group creatadmin2">
              <Row>
                <Col md={3}>
                  <label className="area_rowslable_my">
                    Country of Passport:
                  </label>
                  <CountryDropdown
                    defaultOptionLabel="- Select Country -"
                    className="form-control underline-input countrydropdown2"
                    value={country1Passport}
                    style={{ backgroundColor: "#f5f5f9" }}
                    onChange={(val) => selectPassportCountry(val)}
                  />
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">Passport Number:</label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="passportNumber"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register}
                    onChange={inputEvent}
                    value={clientDetail.passportNumber}
                  />
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">
                    Passport Issue Date:
                  </label>
                  <DatePicker
                    selected={
                      passportissuedate ? new Date(passportissuedate) : null
                    }
                    onChange={(date) => setpassportissuedate(date)}
                    dateFormat="dd-MM-yyyy"
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="Select Date"
                  />
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">
                    Passport Expiry Date:
                  </label>
                  <DatePicker
                    selected={
                      passportexpirydate ? new Date(passportexpirydate) : null
                    }
                    onChange={(date) => setpassportexpirydate(date)}
                    minDate={new Date(passportissuedate)}
                    dateFormat="dd-MM-yyyy"
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="Select Date"
                  />
                </Col>
              </Row>
            </div>
            <div className="form-group creatadmin2">
              <Row>
                <Col md={3}>
                  <label className="area_rowslable_my">Visa Type:</label>
                  <select
                    className={classes.selectdrop}
                    onInput={(e) => {
                      handleChangeHealth(e);
                      inputEvent(e);
                    }}
                    ref={register}
                    name="visaType"
                    style={{ backgroundColor: "#f5f5f9" }}
                    value={clientDetail.visaType}
                  // required
                  >
                    <option value="">- Select Visa Type -</option>
                    {allVisa?.map((cuntitem) => (
                      <option
                        key={cuntitem.id}
                        value={cuntitem.name}
                        id={cuntitem.id}
                      >
                        {cuntitem.name}
                      </option>
                    ))}
                  </select>
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">Visa Issue Date:</label>
                  <DatePicker
                    selected={visaissuedate ? new Date(visaissuedate) : null}
                    onChange={(date) => setvisaissuedate(date)}
                    dateFormat="dd-MM-yyyy"
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="Select Date"
                  />
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">Visa Expiry:</label>
                  <DatePicker
                    selected={visaexpirydate ? new Date(visaexpirydate) : null}
                    onChange={(date) => setvisaexpirydate(date)}
                    minDate={new Date(visaissuedate)}
                    dateFormat="dd-MM-yyyy"
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="Select Date"
                  />
                </Col>
              </Row>
            </div>
            <div style={{ display: "flex", marginTop: "10px" }}>
              <p className="provider_containertitle">Health Insurance</p>
              {/* <hr style={{ flex: "1", marginLeft: "20px" }} /> */}
            </div>
            <div className="form-group creatadmin2">
              <Row>
                <Col md={3}>
                  <label className="area_rowslable_my">
                    Insurance Provider:
                  </label>
                  <select
                    className={classes.selectdrop}
                    onInput={inputEvent}
                    ref={register}
                    name="insuranceProvider"
                    style={{ backgroundColor: "#f5f5f9" }}
                    value={clientDetail.insuranceProvider}
                  // required
                  >
                    <option value="">- Select Insurance Provider -</option>
                    {allHealth?.map((cuntitem) => (
                      <option
                        key={cuntitem.id}
                        value={cuntitem.name}
                        id={cuntitem.id}
                      >
                        {cuntitem.name}
                      </option>
                    ))}
                  </select>
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">Policy Number:</label>
                  <input
                    type="string"
                    ref={register}
                    className="form-control underline-input"
                    name="policyNumber"
                    onChange={inputEvent}
                    style={{ backgroundColor: "#f5f5f9" }}
                    value={clientDetail.policyNumber}
                  />
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">
                    Health Cover Type:
                  </label>
                  <select
                    className={classes.selectdrop}
                    ref={register}
                    name="insuranceType"
                    onInput={inputEvent}
                    style={{ backgroundColor: "#f5f5f9" }}
                    value={clientDetail?.insuranceType}
                  >
                    <option value="">- Select Health Cover Type -</option>
                    {allHealthCover?.map((cuntitem) => (
                      <option
                        key={cuntitem.id}
                        value={cuntitem.name}
                        id={cuntitem.id}
                      >
                        {cuntitem.name}
                      </option>
                    ))}
                  </select>
                </Col>
              </Row>
            </div>
            <div className="form-group creatadmin2">
              <Row>
                <Col md={3}>
                  <label className="area_rowslable_my">
                    Insurance Issue Date:
                  </label>
                  <DatePicker
                    selected={
                      healthcoverdate ? new Date(healthcoverdate) : null
                    }
                    onChange={(date) => sethealthcoverdate(date)}
                    dateFormat="dd-MM-yyyy"
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="Select Date"
                  />
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">
                    Insurance Expiry Date:
                  </label>
                  <DatePicker
                    selected={
                      insuranceexpirydate ? new Date(insuranceexpirydate) : null
                    }
                    onChange={(date) => setinsuranceexpirydate(date)}
                    dateFormat="dd-MM-yyyy"
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="Select Date"
                  />
                </Col>
              </Row>
            </div>
            <div style={{ display: "flex", marginTop: "10px" }}>
              <p className="provider_containertitle">Agent</p>
              {/* <hr style={{ flex: "1", marginLeft: "20px" }} /> */}
            </div>
            <div className="form-group creatadmin2">
              <Row>
                <Col md={3}>
                  <label className="area_rowslable_my">Super Agent:</label>
                  <select
                    className={classes.selectdrop}
                    style={{ backgroundColor: "#f5f5f9" }}
                    name="superAgent"
                    onInput={(event) => {
                      const index = event.target.selectedIndex;
                      const optionElement = event.target.childNodes[index];
                      const optionElementId = optionElement.getAttribute("id");
                      const optionElementrealId =
                        optionElement.getAttribute("realid");
                      //console.log(optionElementId);
                      clientDetail.superAgentName = optionElementId;
                      clientDetail.superAgentId = optionElementrealId;
                      inputEvent(event);
                    }}
                    ref={register}
                    value={clientDetail.superAgent}
                  >
                    <option value="">- Select super agent -</option>
                    {superagent?.map((cuntitem) => (
                      <option
                        key={cuntitem.id}
                        value={cuntitem.username}
                        id={cuntitem.name}
                        realid={cuntitem.id}
                      >
                        {cuntitem.name}
                      </option>
                    ))}
                  </select>
                </Col>
              </Row>
            </div>
            <div style={{ display: "flex", marginTop: "10px" }}>
              <p className="provider_containertitle">Others</p>
              {/* <hr style={{ flex: "1", marginLeft: "20px" }} /> */}
            </div>
            <div className="form-group creatadmin2">
              <Row>
                <Col md={3}>
                  <label className="area_rowslable_my">USI:</label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="usi"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register}
                    onChange={inputEvent}
                    value={clientDetail.usi}
                  />
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">
                    Membership Number:
                  </label>
                  <input
                    type="number"
                    style={{ backgroundColor: "#f5f5f9" }}
                    onWheel={(event) => event.currentTarget.blur()}
                    className="form-control underline-input"
                    name="membershipNumber"
                    onChange={inputEvent}
                    ref={register}
                    value={clientDetail.membershipNumber}
                  />
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">
                    Membership Expiry Date:
                  </label>
                  <DatePicker
                    selected={
                      membershipexpirydate
                        ? new Date(membershipexpirydate)
                        : null
                    }
                    onChange={(date) => setmembershipexpirydate(date)}
                    dateFormat="dd-MM-yyyy"
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="Select Date"
                  />
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">
                    Enquiry Lead Type<span style={{ color: "red" }}>*</span>:
                  </label>
                  <select
                    className={classes.selectdrop}
                    value={stateCust?.enquiryLeadType}
                    style={{ backgroundColor: "#f5f5f9" }}
                    name="enquiryLeadType"
                    onInput={(e) => {
                      setstateCust((prev) => {
                        return {
                          ...prev,
                          enquiryLeadType: e.target.value,
                        };
                      });
                    }}
                    ref={register({ required: true })}
                  >
                    <option value="HotLead">Hot Lead</option>
                    <option value="Converted">Converted Lead</option>
                    <option value="ColdLead">Cold Lead</option>
                    <option value="WarmLead">Warm Lead</option>
                  </select>
                  {errors.enquiryLeadType && (
                    <p className="errormessage">
                      Enquiry Lead Type is Required
                    </p>
                  )}
                </Col>
              </Row>
            </div>
            <div className="form-group creatadmin2">
              <Row>
                <Col md={3}>
                  <label className="area_rowslable_my">Comment:</label>
                  <textarea
                    type="textarea"
                    className="form-control"
                    name="comment"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register}
                    onChange={inputEvent}
                    value={clientDetail.comment}
                  />
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">Institution Name:</label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="currentlyStudyingInstitutionName"
                    onChange={inputEvent}
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register}
                    value={clientDetail.currentlyStudyingInstitutionName}
                  />
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">Intake:</label>
                  {/* <select
                    className={classes.selectdrop}
                    name="intake"
                    ref={register}
                    style={{ backgroundColor: "#f5f5f9" }}
                    onInput={handleChangeIntake}
                    value={clientDetail.intake}
                  >
                    <option value="">- Select Intake -</option>

                    {allIntake?.map((cuntitem) => (
                      <option
                        key={cuntitem.id}
                        value={cuntitem.name}
                        id={cuntitem.id}
                      >
                        {cuntitem.createdIntake}
                      </option>
                    ))}
                  </select> */}
                  <DatePicker
                    selected={
                      intakedate ? new Date(intakedate) : null
                    }
                    onChange={(date) => {
                      setintakedate(date);
                      setselectedIntakename(date);
                      handleChangeIntake(date);
                    }}
                    dateFormat="MMM-yyyy"  // Format for display
                    showMonthYearPicker
                    dropdownMode="select"
                    placeholderText="Select Date"
                    style={{ backgroundColor: "#f5f5f9" }}
                  />
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">Status:</label>
                  <select
                    className={classes.selectdrop}
                    name="status"
                    value={clientDetail?.status}
                    style={{ backgroundColor: "#f5f5f9" }}
                    onInput={inputEvent}
                    ref={register}
                  >
                    <option value="1">Active</option>
                    <option value="0">Inactive</option>
                  </select>
                </Col>
              </Row>
            </div>
            <div style={{ display: "flex", marginTop: "10px" }}>
              <p className="provider_containertitle">
                Client Emergency Contact Details
              </p>
              {/* <hr style={{ flex: "1", marginLeft: "20px" }} /> */}
            </div>
            <div className="form-group creatadmin2">
              <Row>
                <Col md={3}>
                  <label className="area_rowslable_my">Name:</label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="clientRelativeName"
                    ref={register}
                    style={{ backgroundColor: "#f5f5f9" }}
                    onChange={inputClient}
                    value={stateCust.clientRelativeName}
                  />
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">
                    Relationship With Client:
                  </label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="clientRelation"
                    ref={register}
                    style={{ backgroundColor: "#f5f5f9" }}
                    onChange={inputClient}
                    value={stateCust.clientRelation}
                  />
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">Email:</label>
                  <input
                    type="email"
                    className="form-control underline-input"
                    name="clientRelativeEmail"
                    ref={register}
                    onChange={inputClient}
                    style={{ backgroundColor: "#f5f5f9" }}
                    value={stateCust.clientRelativeEmail}
                  />
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">Phone:</label>
                  <input
                    type="number"
                    onWheel={(event) => event.currentTarget.blur()}
                    className="form-control underline-input"
                    name="clientRelativePhone"
                    ref={register}
                    onChange={inputClient}
                    style={{ backgroundColor: "#f5f5f9" }}
                    value={stateCust.clientRelativePhone}
                  />
                </Col>
              </Row>
            </div>

            <div className="d-flex justify-content-end mt-2">
              <button type="submit" className="btn listing_addbutton">
                Update
              </button>
            </div>
          </div>
        </form>
      </div>
      {/* </Card> */}
    </div>
  );
};

export default EditClient;
