import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Card, CardSubtitle, CardText, Col, Row, Button } from "reactstrap";
import axios from "axios";
import base_url from "./../../api/bootapi";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "./../css/LoginForm.css";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, logout } from "../features/userSlice";
import { selectClient, updateClient } from "./../features/clientSlice";
import "./../css/Responsive.css";
import { useForm } from "react-hook-form";
import Modal from "react-modal";
import PasswordStrengthBar from "react-password-strength-bar";
import { customStyles, useStyles } from "../../Utilities/CSSUtilities";
import DatePicker from "react-datepicker";
import callsaveauditapi from "../../services/auditservice";
import { getclientpermission } from "../Permission/permission";
import {
  check4pointpermission,
  check1pointpermission,
} from "./../Permission/permission";

const ClientView = () => {
  let params = useParams();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const client = useSelector(selectClient);
  const username2 = params.client_username;
  const particularClient = [];
  const comingfromwhere = localStorage.getItem("comingfromwhere");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [inputtype, setinputtype] = useState("password");
  const navigate = useNavigate();
  client?.map((item) => {
    if (item.username === username2) {
      particularClient.push(item);
    }
  });
  const [value, setValue] = React.useState("");

  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  const classes = useStyles();
  const [stateCust, setstateCust] = useState({});
  const [clientDetail, setclientDetail] = useState({});
  const [region, setregion] = useState();
  const [country1, setcountry] = useState();
  const [country1Passport, setcountryPassport] = useState();

  const isw_user_name = localStorage.getItem("isw_user_name");
  const isw_adminId = localStorage.getItem("isw_adminId");
  const isw_user_email = localStorage.getItem("isw_user_email");
  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        navigate("/");
      }
    });
    getPermissions();
  }, []);

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);

    if (check1pointpermission(301, permission_obj, resource_obj)) {
      //if (available_permission){
      let id = params.client_username;
      getClientDetails();
      // } else {
      //   navigate("/errorPage");
      // }
    } else {
      //console.log('No access to the page');
      navigate("/errorPage");
    }
  };

  const getClientDetails = () => {
    axios
      .get(`${base_url.api3}/getClientByUsername`, {
        headers: {
          Authorization: jwtToken,
          username: params.client_username,
        },
      })
      .then((response) => {
        //console.log(response.data.data.lastLogin);
        if (response.data.status_code == "200") {
          let client = response.data.data.clientDetail;
          //console.log(client);
          let client2 = response.data.data;
          //console.log("client2", response.data.data);
          setclientDetail({
            addedOn: client.addedOn,
            address: client.address,
            alias: client.alias,
            ban: client.ban,
            city: client.city,
            clientSource: client.clientSource,
            comment: client.comment,
            company: client.company,
            contactPreference: client.contactPreference,
            country: client.country,
            currentlyStudyingInstitutionName:
              client.currentlyStudyingInstitutionName,
            dob: client.dob,
            email: client.email,
            firstName: client.firstName,
            gender: client.gender,
            id: client.id,
            insuranceExpiryDate: client.insuranceExpiryDate,
            insuranceIssueDate: client.insuranceIssueDate,
            insuranceProvider: client.insuranceProvider,
            insuranceType: client.insuranceType,
            intake: client.intake,
            lastName: client.lastName,
            membershipExpiry: client.membershipExpiry,
            membershipNumber: client.membershipNumber,
            middleName: client.middleName,
            nationality: client.nationality,
            passport: client.passport,
            passportExpiryDate: client.passportExpiryDate,
            passportIssueDate: client.passportIssueDate,
            passportNumber: client.passportNumber,
            phone: client.phone,
            secondaryphone: client2.secondaryphone,
            photo: client.photo,
            pincode: client.pincode,
            policyNumber: client.policyNumber,
            secondaryEmail: client.secondaryEmail,
            state: client.state,
            status: client.status,
            street: client.street,
            subAgent: client.subAgent,
            superAgent: client.superAgent,
            username: client.username,
            usi: client.usi,
            visaExpiry: client.visaExpiry,
            visaIssueDate: client.visaIssueDate,
            visaType: client.visaType,
            subAgentName: client.subAgentName,
            superAgentName: client.superAgentName,
          });
          setstateCust({
            addedOn: client.addedOn,
            address: client.address,
            alias: client.alias,
            ban: client.ban,
            city: client.street,
            clientDetail: clientDetail,
            clientSource: client2.clientSource,
            consultantId: client2.consultantId,
            consultantName: client2.consultantName,
            contactPreference: client.contactPreference,
            country: client.country,
            email: client2.email,
            enquiryLeadType: client2.enquiryLeadType,
            firstName: client.firstName,
            id: client2.id,
            insuranceExpiryDate: client.insuranceExpiryDate,
            insuranceIssueDate: client.insuranceIssueDate,
            insuranceProvider: client.insuranceProvider,
            insuranceType: client.insuranceType,
            lastLogin: client2.lastLogin,
            lastName: client.lastName,
            middleName: client.middleName,
            officeId: client2.officeId,
            officeName: client2.officeName,
            // password: client2.password,
            passwordRecoveryCode: client2.passwordRecoveryCode,
            passwordRecoveryDate: client2.passwordRecoveryDate,
            pincode: client.pincode,
            policyNumber: client.policyNumber,
            roleId: client2.roleId,
            secondaryEmail: client2.secondaryEmail,
            state: client.state,
            status: client.status,
            updatedOn: client2.updatedOn,
            username: client.username,
            visaExpiry: client.visaExpiry,
            visaIssueDate: client.visaIssueDate,
            visaType: client.visaType,
            clientRelation: client2.clientRelation,
            clientRelativeName: client2.clientRelativeName,
            clientRelativeEmail: client2.clientRelativeEmail,
            clientRelativePhone: client2.clientRelativePhone,
          });
          setcountry(client.country);
          setcountryPassport(client.passport);
          setregion(client.state);
          setValue(client.contactPreference);
        }
      })
      .catch((error) => {
        handle403error(error);
      });
  };

  const prev_path = localStorage.getItem("edit_prev_path");
  const goback = () => {
    if (comingfromwhere === "userspage") {
      navigate("/client-list");
    } else if (comingfromwhere === "adminpage") {
      navigate(prev_path);
    } else {
      navigate(
        `/view-enquiry-workflowList/${stateCust.id}/${params.client_username}`
      );
    }
  };

  const editClient = (elem) => {
    var client_username = elem.target.id;
    // props.navigate.replace({ pathname: '/edit-Client/'+client_username })
    navigate("/edit-Client/" + client_username);
  };

  const [selectedclient, setselectedclient] = useState();
  const [modalIsOpen2, setIsOpen2] = React.useState(false);
  const openModal2 = (elem) => {
    //console.log("Username", elem.target.id);
    setselectedclient(elem.target.id);
    setIsOpen2(true);
  };

  const closeModal = () => {
    setIsOpen2(false);
  };

  const [chkPassState, setchkPassState] = useState();

  const updatePassword = (e) => {
    let username = e.target[0].value;
    let password;
    // if (chkPass == false) {
    password = chkPassState.trim();
    let data = {
      username,
      password,
    };
    axios
      .post(`${base_url.api3}/updateClientPasswordByEmail`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        //console.log(res.data.data);
        toast.success(res.data.message);
        let logs =
          isw_user_name +
          " Updated passowrd of Client " +
          res.data.data.email +
          ".";
        callsaveauditapi(logs, dispatch, navigate);

        setIsOpen2(false);
      })
      .catch((error) => {
        toast.error(JSON.stringify(error.response.data.message));
        handle403error(error);
      });
    // }
  };

  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            navigate("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };

  return (
    <div>
      <ToastContainer />
      {/* <Card className="commoncomponent_cardcss"> */}
      <CardSubtitle className="font-weight-bold"></CardSubtitle>
      <div
        className="page-head d-flex justify-content-between p-4 "
        style={{ marginTop: "5rem" }}
      >
        <div className="breadcrums">
          <h3 className="page-title bold">View Clients</h3>
        </div>
        <a onClick={goback} className="back-btn">
          Back
        </a>
      </div>
      <div style={{ padding: "1.5rem", border: "1.5rem solid #ccc" }}>
        {/* <Row>
            <Col md={6}>
              <CardText className={classes.headtext}>
                <strong>View Client</strong>
              </CardText>
            </Col>
            <Col md={6} className="d-flex justify-content-end">
              <button className="btn listing_addbutton" onClick={goback}>
                &#x2630; List
              </button>
            </Col>
          </Row> */}

        <div style={{ display: "flex", gap: "10px" }}>
          {check1pointpermission(303, permissionData, rolepermission) && (
            <button
              className="btn listing_addbutton"
              id={clientDetail.email}
              onClick={editClient}
            >
              Edit Client
            </button>
          )}
          {check1pointpermission(304, permissionData, rolepermission) && (
            <button
              className="btn listing_addbutton"
              id={clientDetail.email}
              onClick={openModal2}
            >
              Update Password
            </button>
          )}
        </div>

        <Modal
          isOpen={modalIsOpen2}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();
              updatePassword(e);
            }}
          >
            <div>
              <h3 style={{ color: "black" }}>Update Password</h3>
              <div className="form-group creatadmin">
                <Row>
                  <Col>
                    <label className="area_rowslable_my">User Name:</label>
                    <input
                      type="text"
                      className="form-control underline-input"
                      value={selectedclient}
                      readOnly
                      required
                    />
                  </Col>
                </Row>
              </div>
              <div className="form-group creatadmin">
                <Row>
                  <Col>
                    <label className="area_rowslable_my">Password:</label>
                    <div style={{ position: "relative" }}>
                      <input
                        type={inputtype}
                        value={chkPassState}
                        autoComplete="off"
                        style={{ backgroundColor: "#f5f5f9" }}
                        className="form-control underline-input"
                        onChange={(e) => setchkPassState(e.target.value)}
                        required
                      />
                      {inputtype === "password" ? (
                        <i
                          className="fa fa-eye-slash fonticonpositioning"
                          onClick={() => setinputtype("text")}
                          aria-hidden="true"
                        ></i>
                      ) : (
                        <i
                          className="fa fa-eye fonticonpositioning"
                          onClick={() => setinputtype("password")}
                          aria-hidden="true"
                        ></i>
                      )}
                    </div>
                    <PasswordStrengthBar password={chkPassState} />
                  </Col>
                </Row>
              </div>
              <div className="form-group creatadmin">
                <div style={{ display: "flex", gap: "10px", justifyContent: "flex-end" }}>
                  <button className="btn listing_addbutton mr-1" type="submit">
                    Update
                  </button>
                  <Button color="secondary" onClick={closeModal}>
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </Modal>
        {/* <CardText className={classes.headtext}>Create Admin User</CardText> */}
        <form>
          <div className="form-inner-admin">
            <div className="form-group creatadmin2">
              <div style={{ display: "flex", marginTop: "10px" }}>
                <p className="provider_containertitle">Office Use</p>
                {/* <hr style={{ flex: "1", marginLeft: "20px" }} /> */}
              </div>
              <Row>
                <Col md={3}>
                  <label className="area_rowslable_my">ID:</label>
                  <input
                    className="form-control underline-input"
                    name="clientid"
                    value={stateCust.id}
                    type="text"
                    readOnly
                  />
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">
                    Branch<span style={{ color: "red" }}>*</span>:
                  </label>
                  <input
                    type="text"
                    value={stateCust.officeName}
                    name="officeName"
                    ref={register}
                    className="form-control form-control-sm"
                    readOnly
                  />
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">
                    Select Consultant<span style={{ color: "red" }}>*</span>:
                  </label>
                  <input
                    type="text"
                    value={stateCust.consultantName}
                    name="consultantName"
                    ref={register}
                    className="form-control form-control-sm"
                    readOnly
                  />
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">
                    Client Source<span style={{ color: "red" }}>*</span>:
                  </label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="clientSource"
                    id="clientSource"
                    value={stateCust.clientSource}
                    ref={register({ required: true })}
                    readOnly
                  />
                  {errors.clientSource && (
                    <p className="errormessage">ClientSource is Required</p>
                  )}
                </Col>
              </Row>
            </div>
            <div style={{ display: "flex", marginTop: "10px" }}>
              <p className="provider_containertitle">Personal Details</p>
              {/* <hr style={{ flex: "1", marginLeft: "20px" }} /> */}
            </div>
            <div className="form-group creatadmin2">
              <Row>
                <Col md={4}>
                  <label className="area_rowslable_my">
                    First Name<span style={{ color: "red" }}>*</span>:
                  </label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="firstName"
                    value={clientDetail.firstName}
                    ref={register({ required: true })}
                    readOnly
                  />
                  {errors.firstName && (
                    <p className="errormessage">FirstName is Required</p>
                  )}
                </Col>
                <Col md={4}>
                  <label className="area_rowslable_my">Middle Name:</label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="middleName"
                    ref={register}
                    value={clientDetail.middleName}
                    readOnly
                  />
                </Col>
                <Col md={4}>
                  <label className="area_rowslable_my">
                    Last Name<span style={{ color: "red" }}>*</span>:
                  </label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="lastName"
                    value={clientDetail.lastName}
                    ref={register({ required: true })}
                    readOnly
                  />
                  {errors.lastName && (
                    <p className="errormessage">Lastname is Required</p>
                  )}
                </Col>
              </Row>
            </div>
            <div className="form-group creatadmin2">
              <Row>
                <Col md={4}>
                  <label className="area_rowslable_my">
                    Gender<span style={{ color: "red" }}>*</span>:
                  </label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="gender"
                    id="gender"
                    value={clientDetail.gender}
                    ref={register({ required: true })}
                    readOnly
                  />
                  {errors.gender && (
                    <p className="errormessage">Gender is Required</p>
                  )}
                </Col>
                <Col md={4}>
                  <label className="area_rowslable_my">Date Of Birth:</label>
                  <DatePicker
                    selected={
                      clientDetail?.dob ? new Date(clientDetail?.dob) : null
                    }
                    dateFormat="dd-MM-yyyy"
                    showYearDropdown
                    dropdownMode="select"
                    placeholder="Select Date"
                    style={{ backgroundColor: "#f5f5f9" }}
                    readOnly
                  />
                </Col>
                <Col md={4}>
                  <label className="area_rowslable_my">Alias:</label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="alias"
                    ref={register}
                    value={clientDetail.alias}
                    readOnly
                  />
                </Col>
              </Row>
            </div>
            <div style={{ display: "flex", marginTop: "10px" }}>
              <p className="provider_containertitle">Contact Details</p>
              {/* <hr style={{ flex: "1", marginLeft: "20px" }} /> */}
            </div>
            <div className="form-group creatadmin2">
              <Row>
                <Col md={3}>
                  <label className="area_rowslable_my">
                    Email<span style={{ color: "red" }}>*</span>:
                  </label>
                  <input
                    type="email"
                    className="form-control underline-input"
                    name="email"
                    autoComplete="off"
                    value={clientDetail.email}
                    ref={register({ required: true })}
                    readOnly
                  />
                  {errors.email && (
                    <p className="errormessage">Email is Required</p>
                  )}
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">Secondary Email:</label>
                  <input
                    type="email"
                    autoComplete="off"
                    className="form-control underline-input"
                    name="secondaryEmail"
                    value={clientDetail.secondaryEmail}
                    ref={register({ required: true })}
                    readOnly
                  />
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">Phone (primary):</label>
                  <input
                    type="number"
                    onWheel={(event) => event.currentTarget.blur()}
                    className="form-control underline-input"
                    name="phone"
                    ref={register}
                    value={clientDetail.phone}
                    readOnly
                  />
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">
                    Phone (secondary):
                  </label>
                  <input
                    type="number"
                    onWheel={(event) => event.currentTarget.blur()}
                    className="form-control underline-input"
                    name="secondaryphone"
                    ref={register}
                    value={clientDetail.secondaryphone}
                    readOnly
                  />
                </Col>
              </Row>
            </div>
            <div className="form-group creatadmin2">
              <Row>
                <Col md={3}>
                  <label className="area_rowslable_my">
                    Contact Preference
                  </label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="contact"
                    ref={register}
                    value={value}
                    readOnly
                  />
                </Col>
              </Row>
            </div>
            <div style={{ display: "flex", marginTop: "10px" }}>
              <p className="provider_containertitle">Address</p>
              {/* <hr style={{ flex: "1", marginLeft: "20px" }} /> */}
            </div>
            <div className="form-group creatadmin2">
              <Row>
                <Col md={3}>
                  <label className="area_rowslable_my">
                    Street<span style={{ color: "red" }}>*</span>:
                  </label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="street"
                    value={clientDetail.street}
                    ref={register({ required: true })}
                    readOnly
                  />
                  {errors.street && (
                    <p className="errormessage">Street is Required</p>
                  )}
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">
                    City/Suburb<span style={{ color: "red" }}>*</span>:
                  </label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="city"
                    value={clientDetail.city}
                    ref={register({ required: true })}
                    readOnly
                  />
                  {errors.city && (
                    <p className="errormessage">City is Required</p>
                  )}
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">
                    Country<span style={{ color: "red" }}>*</span>:
                  </label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="country"
                    value={country1}
                    ref={register({ required: true })}
                    readOnly
                  />
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">
                    State<span style={{ color: "red" }}>*</span>:
                  </label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="state"
                    value={region}
                    ref={register({ required: true })}
                    readOnly
                  />
                </Col>
              </Row>
            </div>
            <div className="form-group creatadmin2">
              <Row>
                <Col md={3}>
                  <label className="area_rowslable_my">
                    PostCode<span style={{ color: "red" }}>*</span>:
                  </label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="pincode"
                    value={clientDetail.pincode}
                    ref={register({ required: true })}
                    readOnly
                  />
                  {errors.pincode && (
                    <p className="errormessage">PostCode is Required</p>
                  )}
                </Col>
              </Row>
            </div>
            <div style={{ display: "flex", marginTop: "10px" }}>
              <p className="provider_containertitle">
                Passport/Visa Information
              </p>
              {/* <hr style={{ flex: "1", marginLeft: "20px" }} /> */}
            </div>
            <div className="form-group creatadmin2">
              <Row>
                <Col md={3}>
                  <label className="area_rowslable_my">
                    Country of Passport:
                  </label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="passport"
                    value={country1Passport}
                    ref={register({ required: true })}
                    readOnly
                  />
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">Passport Number:</label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="passportNumber"
                    ref={register}
                    value={clientDetail.passportNumber}
                    readOnly
                  />
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">
                    Passport Issue Date:
                  </label>
                  <DatePicker
                    selected={
                      clientDetail?.passportIssueDate
                        ? new Date(clientDetail?.passportIssueDate)
                        : null
                    }
                    dateFormat="dd-MM-yyyy"
                    readOnly
                  />
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">
                    Passport Expiry Date:
                  </label>
                  <DatePicker
                    selected={
                      clientDetail?.passportExpiryDate
                        ? new Date(clientDetail?.passportExpiryDate)
                        : null
                    }
                    dateFormat="dd-MM-yyyy"
                    readOnly
                  />
                </Col>
              </Row>
            </div>
            <div className="form-group creatadmin2">
              <Row>
                <Col md={3}>
                  <label className="area_rowslable_my">Visa Type:</label>
                  <input
                    type="text"
                    ref={register}
                    className="form-control underline-input"
                    name="visaType"
                    value={clientDetail.visaType}
                    readOnly
                  />
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">Visa Issue Date:</label>
                  <DatePicker
                    selected={
                      clientDetail?.visaIssueDate
                        ? new Date(clientDetail?.visaIssueDate)
                        : null
                    }
                    dateFormat="dd-MM-yyyy"
                    readOnly
                  />
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">Visa Expiry:</label>
                  <DatePicker
                    selected={
                      clientDetail?.visaExpiry
                        ? new Date(clientDetail?.visaExpiry)
                        : null
                    }
                    dateFormat="dd-MM-yyyy"
                    readOnly
                  />
                </Col>
              </Row>
            </div>
            <div style={{ display: "flex", marginTop: "10px" }}>
              <p className="provider_containertitle">Health Insurance</p>
              {/* <hr style={{ flex: "1", marginLeft: "20px" }} /> */}
            </div>
            <div className="form-group creatadmin2">
              <Row>
                <Col md={4}>
                  <label className="area_rowslable_my">
                    Insurance Provider:
                  </label>
                  <input
                    type="text"
                    ref={register}
                    className="form-control underline-input"
                    name="insuranceProvider"
                    value={clientDetail.insuranceProvider}
                    readOnly
                  />
                </Col>
                <Col md={4}>
                  <label className="area_rowslable_my">Policy Number:</label>
                  <input
                    type="string"
                    ref={register}
                    className="form-control underline-input"
                    name="policyNumber"
                    value={clientDetail.policyNumber}
                    readOnly
                  />
                </Col>
                <Col md={4}>
                  <label className="area_rowslable_my">Insurance Type:</label>
                  <input
                    type="string"
                    ref={register}
                    className="form-control underline-input"
                    name="policyNumber"
                    value={clientDetail.insuranceType}
                    readOnly
                  />
                </Col>
              </Row>
            </div>
            <div className="form-group creatadmin2">
              <Row>
                <Col md={4}>
                  <label className="area_rowslable_my">
                    Insurance Issue Date:
                  </label>
                  <DatePicker
                    selected={
                      clientDetail?.insuranceIssueDate
                        ? new Date(clientDetail?.insuranceIssueDate)
                        : null
                    }
                    dateFormat="dd-MM-yyyy"
                    readOnly
                  />
                </Col>
                <Col md={4}>
                  <label className="area_rowslable_my">
                    Insurance Expiry Date:
                  </label>
                  <DatePicker
                    selected={
                      clientDetail?.insuranceExpiryDate
                        ? new Date(clientDetail?.insuranceExpiryDate)
                        : null
                    }
                    dateFormat="dd-MM-yyyy"
                    readOnly
                  />
                </Col>
              </Row>
            </div>
            <div style={{ display: "flex", marginTop: "10px" }}>
              <p className="provider_containertitle">Agent</p>
              {/* <hr style={{ flex: "1", marginLeft: "20px" }} /> */}
            </div>
            <div className="form-group creatadmin2">
              <Row>
                <Col md={4}>
                  <label className="area_rowslable_my">Sub Agent:</label>
                  <input
                    type="string"
                    ref={register}
                    className="form-control underline-input"
                    name="policyNumber"
                    value={clientDetail?.subAgentName}
                    readOnly
                  />
                </Col>
                <Col md={4}>
                  <label className="area_rowslable_my">Super Agent:</label>
                  <input
                    type="string"
                    ref={register}
                    className="form-control underline-input"
                    name="policyNumber"
                    value={clientDetail?.superAgentName}
                    readOnly
                  />
                </Col>
              </Row>
            </div>
            <div style={{ display: "flex", marginTop: "10px" }}>
              <p className="provider_containertitle">Others</p>
              {/* <hr style={{ flex: "1", marginLeft: "20px" }} /> */}
            </div>
            <div className="form-group creatadmin2">
              <Row>
                <Col md={3}>
                  <label className="area_rowslable_my">USI:</label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="usi"
                    ref={register}
                    value={clientDetail.usi}
                    readOnly
                  />
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">
                    Membership Number:
                  </label>
                  <input
                    type="number"
                    onWheel={(event) => event.currentTarget.blur()}
                    className="form-control underline-input"
                    name="membershipNumber"
                    ref={register}
                    value={clientDetail.membershipNumber}
                    readOnly
                  />
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">
                    Membership Expiry Date:
                  </label>
                  <DatePicker
                    selected={
                      clientDetail?.membershipExpiry
                        ? new Date(clientDetail?.membershipExpiry)
                        : null
                    }
                    dateFormat="dd-MM-yyyy"
                    readOnly
                  />
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">
                    Enquiry Lead Type:
                  </label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="enquiryleadtype"
                    ref={register}
                    value={stateCust?.enquiryLeadType}
                    readOnly
                  />
                </Col>
              </Row>
            </div>
            <div className="form-group creatadmin2">
              <Row>
                <Col md={3}>
                  <label className="area_rowslable_my">Comment:</label>
                  <textarea
                    type="textarea"
                    className="form-control"
                    name="comment"
                    ref={register}
                    value={clientDetail.comment}
                    readOnly
                  />
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">Institution Name:</label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="currentlyStudyingInstitutionName"
                    ref={register}
                    value={clientDetail.currentlyStudyingInstitutionName}
                    readOnly
                  />
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">Intake:</label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="intake"
                    ref={register}
                    value={clientDetail.intake}
                    readOnly
                  />
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">Status</label>
                  {clientDetail?.status === 1 ? (
                    <input
                      type="text"
                      className="form-control underline-input"
                      value="Active"
                      readOnly
                    />
                  ) : (
                    <input
                      type="text"
                      className="form-control underline-input"
                      value="Inactive"
                      readOnly
                    />
                  )}
                </Col>
              </Row>
            </div>
            <div style={{ display: "flex", marginTop: "10px" }}>
              <p className="provider_containertitle">
                Client Emergency Contact Details
              </p>
              {/* <hr style={{ flex: "1", marginLeft: "20px" }} /> */}
            </div>
            <div className="form-group creatadmin2">
              <Row>
                <Col md={3}>
                  <label className="area_rowslable_my">Name:</label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="clientRelativeName"
                    readOnly
                    value={stateCust.clientRelativeName}
                  />
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">
                    Relationship With Client:
                  </label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="clientRelation"
                    readOnly
                    value={stateCust.clientRelation}
                  />
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">Email:</label>
                  <input
                    type="email"
                    className="form-control underline-input"
                    name="clientRelativeEmail"
                    value={stateCust.clientRelativeEmail}
                    readOnly
                  />
                </Col>
                <Col md={3}>
                  <label className="area_rowslable_my">Phone:</label>
                  <input
                    type="number"
                    onWheel={(event) => event.currentTarget.blur()}
                    className="form-control underline-input"
                    name="clientRelativePhone"
                    value={stateCust.clientRelativePhone}
                    readOnly
                  />
                </Col>
              </Row>
            </div>
          </div>
        </form>
      </div>
      {/* </Card> */}
    </div>
  );
};

export default ClientView;
