import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Card, Col, Row, Button, Table } from "reactstrap";
import moment from "moment";
import axios from "axios";
import base_url from "./../../api/bootapi";
import { ToastContainer, toast } from "react-toastify";
import Modal from "react-modal";
import "./../css/Responsive.css";
import { useDispatch, useSelector } from "react-redux";
import { setdashboardcheck, selectUser, logout } from "../features/userSlice";
import PasswordStrengthBar from "react-password-strength-bar";
import Pagination from "@mui/material/Pagination";
import { makeStyles } from "@mui/styles";
import callsaveauditapi from "../../services/auditservice";
import { getofficepermission } from "../Permission/permission";

const drawerWidth = 300;
const drawerWidth2 = 60;
const selectheight = "35px";
const selectWidth = "100%";
const headtxt = 30;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    minWidth: "400px",
    transform: "translate(-50%, -50%)",
  },
};

const useStyles = makeStyles((theme) => ({
  toolbar: {
    marginLeft: drawerWidth,
    marginRight: drawerWidth2,
  },
  selectdrop: {
    width: selectWidth,
    height: selectheight,
  },
  headtext: {
    fontSize: headtxt,
  },
  createBtn2: {
    color: "white",
    float: "right",
    textDecoration: "none",
  },
  createBtn: {
    float: "left",
  },
  modalcss: {
    marginTop: "10%",
  },
  backdrop: {
    // zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    zIndex: 1000,
  },
  srch: {
    float: "right",
  },
  srchbtn: {
    padding: "1px 10px",
    marginTop: "8px",
    marginRight: "16px",
    background: "#ddd",
    fontSize: "17px",
    cursor: "pointer",
  },
  clrsrch: {
    float: "right",
    marginTop: "5px",
    marginRight: "15px",
    cursor: "pointer",
  },
}));

function OfficeAgents(props) {
  let params = useParams();
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const officeId = params.office_id;
  const navigate = useNavigate();
  localStorage.setItem("selected_office_id", params.office_id);
  const isw_user_role = localStorage.getItem("isw_user_role");
  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  const [officeName, setOfficeName] = useState(null);
  const [deleteuser, setDeleteuser] = useState(null);
  const [chkPassState, setchkPassState] = useState();
  const [inputtype, setinputtype] = useState("password");
  const isw_user_name = localStorage.getItem("isw_user_name");
  const isw_adminId = localStorage.getItem("isw_adminId");
  const isw_user_email = localStorage.getItem("isw_user_email");

  const [sortState, setsortState] = useState("id");
  const [direction, setdirection] = useState("DESC");
  const [rows, setrows] = React.useState(50);
  const [pageno, setpageno] = useState(0);
  const [getpageno, setgetpageno] = useState();
  const [totalUser, settotalUser] = useState();
  const [paginationpageno, setpaginationpageno] = useState(1);
  const ipaddress = localStorage.getItem("AdminIpAddress");
  const [user_lists, setUserList] = useState(null);

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        navigate("/");
      }
    });
    getPermissions();
  }, []);

  useEffect(() => {
    let width = document.querySelector("table")?.offsetWidth;
    if (document.querySelector(".scroller")) {
      document.querySelector(".scroller").style.width = `${width}px`;
    }
  }, [user_lists]);

  let scrolling = false;
  const scrolldiv = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow .table-responsive");
    table.scrollTo(scroller.scrollLeft, 0);
  };

  const scrolltable = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow  .table-responsive");
    scroller.scrollTo(table.scrollLeft, 0);
  };

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }

    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    // var available_resource = resource_obj.includes(21);
    if (getofficepermission(permission_obj, resource_obj)) {
      dispatch(setdashboardcheck(21));
      getOffice();

      if (localStorage.getItem("officeAgentPageNo")) {
        let currentPage = localStorage.getItem("officeAgentPageNo");
        setpageno(currentPage);
        setpaginationpageno(Number(currentPage) + 1);
        getallUserlist(currentPage);
      } else {
        setpageno(0);
        setpaginationpageno(1);
        getallUserlist(0);
      }
    } else {
      navigate("/errorPage");
    }
  };

  const createofficeUsers = () => {
    navigate("/create-office-users/" + officeId);
  };

  const getallUserlist = (value1) => {
    axios
      .get(`${base_url.api2}/getUsersByOfficeIdPagination`, {
        headers: {
          Authorization: jwtToken,
          officeId: officeId,
          columnName: sortState,
          direction: direction,
          page: Number(value1),
          size: Number(rows),
        },
      })
      .then((response) => {
        //console.log(response.data.data);
        setUserList(response.data.data.content);
        setgetpageno(response.data.data.totalPages);
        settotalUser(response.data.data.totalElements);
        setsearch(false);
        try {
          document.getElementById("myForm").reset();
        } catch (error) {
          //console.log(error);
        }
      })
      .catch((error) => {
        toast.error(JSON.stringify(error.response.data.message));
        handle403error(error);
      });
  };
  //console.log(user_lists);

  const handlepage = (event, value) => {
    setpaginationpageno(value);
    setpageno(value - 1);
    getallUserlist(value - 1);
  };

  const getOffice = () => {
    axios
      .get(`${base_url.api1}/getOfficeById`, {
        headers: {
          Authorization: jwtToken,
          officeId: officeId,
        },
      })
      .then((response) => {
        setOfficeName(response.data.data.officeName);
      })
      .catch((error) => {
        handle403error(error);
      });
  };

  const deleteUser = (elem) => {
    axios
      .delete(`${base_url.api2}/deleteUserByUsername`, {
        headers: {
          Authorization: jwtToken,
          username: deleteuser,
        },
      })
      .then((response) => {
        //console.log(response.data.data.message);
        getallUserlist(pageno);

        let log = isw_user_name + " Deleted Agent " + deleteusername + ".";
        callsaveauditapi(log, dispatch, navigate);
        setIsOpen(!modalIsOpen);
        toast.success(response.data.message);
        getallUserlist(pageno);
        // window.location.reload(false);
      })
      .catch((error) => {
        toast.error(JSON.stringify(error.response.data.message));
        handle403error(error);
      });
  };
  const editUser = (elem, p) => {
    localStorage.setItem("officeAgentPageNo", pageno);
    var user_email = elem.target.id;
    //console.log(pageno);
    navigate("/edit-office-user/" + params.office_id + "/" + user_email);
  };

  const goback = () => {
    // props.navigate.replace("/office");
    navigate("/office");
  };

  const classes = useStyles();

  var subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [deleteusername, setdeleteusername] = useState();
  const openDeleteModal = (elem, p) => {
    setdeleteusername(p.email);
    //console.log(p.email);
    setDeleteuser(p.email);
    setIsOpen(true);
  };

  const [selecteduser, setselecteduser] = useState();
  const [modalIsOpen2, setIsOpen2] = React.useState(false);
  const openPasswordModal = (elem) => {
    setselecteduser(elem.target.id);
    setIsOpen2(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setIsOpen2(false);
  };

  const openStatusModal = (elem, p) => {
    if (p.status === 1) {
      //console.log("called inactive");
      let data = {
        email: elem.target.id,
        status: Number(0),
      };
      axios
        .post(`${base_url.api2}/updateUserDataByEmail`, data, {
          headers: {
            Authorization: jwtToken,
          },
        })
        .then((response) => {
          getallUserlist();
          toast.success(
            "User " + p.firstName + " " + p.lastName + " Status: Inactive"
          );

          let log =
            isw_user_name +
            " Made Status of " +
            p.firstName +
            " " +
            p.lastName +
            " Inactive.";
          callsaveauditapi(log, dispatch, navigate);
        })
        .catch((error) => {
          toast.error(JSON.stringify(error.response.data.message));
          handle403error(error);
        });
    } else {
      //console.log("called active");
      let data = {
        email: elem.target.id,
        status: Number(1),
      };
      axios
        .post(`${base_url.api2}/updateUserDataByEmail  `, data, {
          headers: {
            Authorization: jwtToken,
          },
        })
        .then((response) => {
          toast.success(
            "User " + p.firstName + " " + p.lastName + " Status: Active"
          );
          getallUserlist();

          let log =
            isw_user_name +
            " Made Status of " +
            p.firstName +
            " " +
            p.lastName +
            " Active.";
          callsaveauditapi(log, dispatch, navigate);
        })
        .catch((error) => {
          toast.error(JSON.stringify(error.response.data.message));
          handle403error(error);
        });
    }
  };

  const [searchdata, setsearch] = useState();
  const submit = (e) => {
    let srchdata = e.target[0].value;
    //console.log(srchdata);
    axios
      .get(`${base_url.api3}/searchAgent`, {
        headers: {
          Authorization: jwtToken,
          searchQuery: srchdata,
        },
      })
      .then((response) => {
        //console.log(response.data.data);
        setUserList(response.data.data.content);
        setsearch(true);
      })
      .catch((error) => {
        toast.error(JSON.stringify(error.response.data.message));
        handle403error(error);
      });
  };

  const changePassword = (e) => {
    let email = e.target[0].value;
    let password;
    password = chkPassState.trim();
    let data = {
      email,
      password,
    };
    axios
      .post(`${base_url.api2}/updatePasswordByEmail`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        toast.success(response.data.message);

        let log =
          isw_user_name +
          " Updated Password for the User " +
          e.target[0].value +
          ".";
        callsaveauditapi(log, dispatch, navigate);
        setIsOpen2(false);
      })
      .catch((error) => {
        toast.error(JSON.stringify(error.response.data.message));
        handle403error(error);
      });
  };
  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            navigate("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };
  return (
    <div>
      <ToastContainer />
      {/* <Dashboard/> */}
      <Card className="commoncomponent_cardcss">
        <hr />
        <div>
          <Row>
            <Col md={6}>
              <h5>
                <strong>Branch:- {officeName}</strong>
              </h5>
            </Col>
            <Col md={6} className="d-flex justify-content-end">
              <button
                className="btn listing_addbutton mr-1"
                onClick={createofficeUsers}
              >
                Add User
              </button>
              <button className="btn listing_addbutton mr-1" onClick={goback}>
                &#x2630; List
              </button>
            </Col>
          </Row>
          <br />
          <div className="above-scroller" onScroll={scrolldiv}>
            <div className="scroller"></div>
          </div>
          <div className="displaytablerow">
            <Table
              className=" workload card-table table-borderless"
              responsive
              striped
              onScroll={scrolltable}
            >
              <thead>
                <tr>
                  <th style={{ minWidth: "50px", textAlign: "center" }}>SN</th>
                  <th style={{ width: "100px" }}>NAME</th>
                  <th style={{ minWidth: "120px" }}>CONTACT</th>
                  <th style={{ width: "120px" }}>COUNTRY</th>
                  <th style={{ width: "50px" }}>ROLE</th>
                  <th style={{ width: "120px" }}>CREATED</th>
                  <th style={{ width: "120px" }}>LAST LOGIN</th>
                  <th style={{ width: "80px" }}>STATUS</th>
                  <th style={{ minWidth: "150px" }}>ACTION</th>
                </tr>
              </thead>
              <tbody>
                {user_lists != null
                  ? user_lists.map((p, index) => (
                      <tr key={p.id}>
                        <td style={{ minWidth: "50px", textAlign: "center" }}>
                          {index + 1}
                        </td>
                        <td>
                          {p.firstName + " " + p.lastName}
                          <br />
                          {p.email}
                        </td>
                        <td>{p.phone}</td>
                        <td>{p.country}</td>
                        <td>{p.roleCode}</td>
                        <td>
                          {p.addedOn
                            ? moment(p.addedOn.split(".")[0]).format(
                                "DD-MMM-YY"
                              )
                            : null}
                          <br />
                          {p.addedOn
                            ? moment(p.addedOn.split(".")[0]).format("hh.mm a")
                            : null}
                        </td>
                        <td>
                          {p.lastLogin ? (
                            moment(p.lastLogin.split(".")[0]).format(
                              "DD-MMM-YY"
                            )
                          ) : (
                            <span style={{ color: "brown" }}>
                              Never Logged In
                            </span>
                          )}
                          <br />
                          {p.lastLogin
                            ? moment(p.lastLogin.split(".")[0]).format(
                                "hh.mm a"
                              )
                            : null}
                        </td>
                        {isw_user_role != "6" ? (
                          <td>
                            {p.roleId != "1" && p.roleId != "6" ? (
                              <Link
                                id={p.username}
                                onClick={(e) => {
                                  openStatusModal(e, p);
                                }}
                              >
                                {p.status == "1" ? "Active" : "Inactive"}
                              </Link>
                            ) : (
                              <>{p.status == "1" ? "Active" : "Inactive"}</>
                            )}
                          </td>
                        ) : (
                          <td>
                            <Link
                              id={p.username}
                              onClick={(e) => {
                                openStatusModal(e, p);
                              }}
                            >
                              {p.status == "1" ? "Active" : "Inactive"}
                            </Link>
                          </td>
                        )}
                        {/* <Link
                              id={p.username}
                              onClick={(e) => {
                                openStatusModal(e, p);
                              }}
                            >
                              {p.status == "1" ? "Active" : "Inactive"}
                            </Link> */}
                        {isw_user_role == "6" ? (
                          <td id={p.username}>
                            <Link
                              id={p.username}
                              onClick={(e) => {
                                editUser(e, p);
                              }}
                            >
                              Edit
                            </Link>
                            &nbsp;&nbsp;|&nbsp;&nbsp;
                            <Link id={p.username} onClick={openPasswordModal}>
                              Chg-Pwd
                            </Link>
                            &nbsp;&nbsp;|&nbsp;&nbsp;
                            {p.roleId != "6" ? (
                              <>
                                <Link id={p.username} onClick={openDeleteModal}>
                                  Delete
                                </Link>
                              </>
                            ) : null}
                          </td>
                        ) : (
                          <td id={p.username}>
                            {p.roleId != "6" ? (
                              <>
                                <Link
                                  id={p.username}
                                  onClick={(e) => {
                                    editUser(e, p);
                                  }}
                                >
                                  Edit
                                </Link>
                              </>
                            ) : null}
                            &nbsp;&nbsp;
                            {p.roleId != "1" && p.roleId != "6" ? (
                              <>
                                |&nbsp;&nbsp;
                                <Link
                                  id={p.username}
                                  onClick={openPasswordModal}
                                >
                                  Chg-Pwd
                                </Link>
                              </>
                            ) : null}
                            &nbsp;&nbsp;
                            {p.roleId != "1" && p.roleId != "6" ? (
                              <>
                                |&nbsp;&nbsp;
                                <Link
                                  id={p.id}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    openDeleteModal(e, p);
                                  }}
                                >
                                  Delete
                                </Link>
                              </>
                            ) : null}
                          </td>
                        )}

                        <Modal
                          isOpen={modalIsOpen2}
                          onRequestClose={closeModal}
                          style={customStyles}
                          contentLabel="Example Modal"
                        >
                          <form
                            onSubmit={(e) => {
                              e.preventDefault();
                              changePassword(e);
                            }}
                          >
                            <div>
                              <h3 style={{ color: "black" }}>
                                Update Password
                              </h3>
                              <div className="form-group creatadmin">
                                <Row>
                                  <Col>
                                    <label>User Name:</label>
                                    <input
                                      type="text"
                                      className="form-control underline-input"
                                      value={selecteduser}
                                      readOnly
                                    />
                                  </Col>
                                </Row>
                              </div>
                              <div className="form-group creatadmin">
                                <Row>
                                  <Col>
                                    <label>Password:</label>
                                    <div style={{ position: "relative" }}>
                                      <input
                                        type={inputtype}
                                        className="form-control underline-input"
                                        value={chkPassState}
                                        onChange={(e) =>
                                          setchkPassState(e.target.value)
                                        }
                                        required
                                      />
                                      {inputtype === "password" ? (
                                        <i
                                          className="fa fa-eye-slash fonticonpositioning"
                                          onClick={() => setinputtype("text")}
                                          aria-hidden="true"
                                        ></i>
                                      ) : (
                                        <i
                                          className="fa fa-eye fonticonpositioning"
                                          onClick={() =>
                                            setinputtype("password")
                                          }
                                          aria-hidden="true"
                                        ></i>
                                      )}
                                    </div>
                                    <PasswordStrengthBar
                                      password={chkPassState}
                                    />
                                  </Col>
                                </Row>
                              </div>
                              <div className="form-group creatadmin">
                                <Row className="justify-content-end">
                                  <button
                                    className="btn listing_addbutton ml-1"
                                    type="submit"
                                  >
                                    Update
                                  </button>
                                  <Button
                                    color="secondary"
                                    onClick={closeModal}
                                  >
                                    Cancel
                                  </Button>
                                </Row>
                              </div>
                            </div>
                          </form>
                        </Modal>
                        <Modal
                          isOpen={modalIsOpen}
                          onRequestClose={closeModal}
                          style={customStyles}
                          contentLabel="Example Modal"
                        >
                          <h4 style={{ marginTop: "10px" }}>
                            Are you sure you want to delete current record ?
                          </h4>
                          <Row
                            style={{
                              display: "flex",
                              marginTop: "20px",
                              justifyContent: "flex-end",
                              gap: "10px",
                              marginBottom: "10px",
                            }}
                          >
                            <button
                              className="btn listing_addbutton ml-1"
                              onClick={deleteUser}
                            >
                              Delete
                            </button>
                            <button
                              className="btn btn-secondary cancel_margin"
                              onClick={closeModal}
                            >
                              Cancel
                            </button>
                          </Row>
                        </Modal>
                      </tr>
                    ))
                  : "No Agent Available"}
              </tbody>
            </Table>
          </div>
        </div>
        <Row>
          <Col md={6}>{null}</Col>
          <Col md={6}>
            <div style={{ float: "right" }} className={classes.root}>
              {/* {searchdata || datefilter ? (
                  <Pagination count={getpageno} onChange={handlesearch} />
                ) : ( */}
              {/* <Pagination count={getpageno} onChange={handlepage} /> */}
              <Pagination
                page={paginationpageno}
                count={getpageno}
                onChange={handlepage}
                shape="rounded"
              />
              {/* )} */}
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  );
}

export default OfficeAgents;
