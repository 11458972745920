import React, { useState, useEffect } from "react";
import { Card, CardText, Col, Row, Button } from "reactstrap";
import axios from "axios";
import base_url from "./../../api/bootapi";
import { toast } from "react-toastify";
import moment from "moment";
import { useParams, useNavigate } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@mui/styles";
import "./../css/LoginForm.css";
import { useDispatch, useSelector } from "react-redux";
import { addtoAdmin } from "./../features/adminSlice";
import "./../css/Responsive.css";
import { setdashboardcheck, selectUser, logout } from "../features/userSlice";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import DatePicker from "react-datepicker";
import { useForm } from "react-hook-form";
import PasswordStrengthBar from "react-password-strength-bar";
import callsaveauditapi from "../../services/auditservice";
import {
  check4pointpermission,
  check1pointpermission,
} from "../Permission/permission";

const drawerWidth = 300;
const drawerWidth2 = 60;
const selectheight = "35px";
const selectWidth = "100%";
const headtxt = 30;

const useStyles = makeStyles((theme) => ({
  toolbar: {
    marginLeft: drawerWidth,
    marginRight: drawerWidth2,
  },
  selectdrop: {
    width: selectWidth,
    height: selectheight,
  },
  headtext: {
    fontSize: headtxt,
  },
  createBtn2: {
    color: "white",
    textDecoration: "none",
    marginLeft: 20,
  },
  createBtn: {
    float: "right",
  },
  backdrop: {
    // zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    zIndex: 1000,
  },
}));

function AddOfficeAgents(props) {
  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  const classes = useStyles();
  const dispatch = useDispatch();
  let params = useParams();
  const office_id = params.office_id;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [country1, setcountry] = useState();
  const [region, setregion] = useState();
  const [officeset1, setofficeset1] = useState(true);
  const user = useSelector(selectUser);
  const [officeState, setOffice] = useState();
  const [dateofbirthdate, setdateofbirthdate] = useState(null);
  const isw_user_name = localStorage.getItem("isw_user_name");
  const isw_adminId = localStorage.getItem("isw_adminId");
  const isw_user_email = localStorage.getItem("isw_user_email");
  const [inputtype, setinputtype] = useState("password");
  const [rolecode, setrolecode] = useState();
  const isw_user_role_name = localStorage.getItem("isw_user_role_name");
  const isw_user_role = localStorage.getItem("isw_user_role");
  const ipaddress = localStorage.getItem("AdminIpAddress");
  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        navigate("/");
      }
    });
    getPermissions();
  }, []);

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);

    if (check1pointpermission(609, permission_obj, resource_obj)) {
      dispatch(setdashboardcheck(19));
      getRoles();
      getOffice();
      getallSpecificRoles();
    } else {
      navigate("/errorPage");
    }
  };

  const [specificroles, setSpecificRoles] = useState();
  const getallSpecificRoles = () => {
    axios
      .get(`${base_url.api3}/getActiveSpecificRoles`)
      .then((res) => {
        setSpecificRoles(res.data.data);
      })
      .catch((err) => {
        handle403error(err);
      });
  };

  const [availableroles, setAvailableroles] = useState(null);
  const getRoles = () => {
    axios
      .get(`${base_url.api3}/listAllRoles`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        setAvailableroles(response.data.data);
      })
      .catch((error) => {
        handle403error(error);
      });
  };

  const [officeName, setofficeName] = useState();
  const getOffice = () => {
    axios
      .get(`${base_url.api1}/getOfficeById`, {
        headers: {
          Authorization: jwtToken,
          officeId: office_id,
        },
      })
      .then((response) => {
        setofficeName(response.data.data.officeName);
        setOffice([
          {
            officeCode: response.data.data.officeCode,
            officeId: response.data.data.officeId,
            officeName: response.data.data.officeName,
            country: response.data.data.country,
            city: response.data.data.city,
          },
        ]);
      })
      .catch((error) => {
        handle403error(error);
      });
  };

  const selectCountry = (val) => {
    setcountry(val);
  };
  const selectRegion = (val) => {
    setregion(val);
  };

  const [selectedrole, setSelectedrole] = useState(4);
  const [defaultRole, setdefaultRole] = useState("Consultant");
  const handleChange = (event) => {
    setdefaultRole(event.target.value);
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("id");
    const optionElementValue = optionElement.getAttribute("value");
    const optionElementValue2 = optionElement.getAttribute("roleCode");

    setSelectedrole(optionElementId);
    setdefaultRole(optionElementValue);
    setrolecode(optionElementValue2);
  };

  const onSubmit = (data2) => {
    const isoDateString = new Date().toISOString();
    const datenow = isoDateString.split(".")[0];
    let office = officeState;
    let officeId = Number(officeState[0].officeId);
    let firstName = data2.firstName.trim();
    let lastName = data2.lastName.trim();
    let gender = data2.gender;
    let username = data2.email.trim();
    let email = data2.email.trim();
    // let password = data2.password.trim();
    let password = chkPassState.trim();
    // if (chkPass == false) {
    //   password = chkPassState.trim();
    // }
    let phone = data2.phone;
    let address = data2.address;
    let street = data2.address;
    let country = country1;
    let nationality = country1;
    let passport = country1;
    let state = region;
    let city = data2.city;
    let dob = dateofbirthdate
      ? moment(dateofbirthdate).format("YYYY-MM-DDThh:mm:ss")
      : null;
    let roleId = Number(selectedrole);
    let roleCode = rolecode;
    let roleName = defaultRole; //e.target[11].value;
    let pincode = Number(data2.pincode);
    let status = Number(data2.status);
    let ownerUser = 0;
    let addedOn = datenow;
    let updatedOn = datenow;
    let id = 0;
    let photo = "string";
    let data = {
      id,
      firstName,
      lastName,
      gender,
      username,
      password,
      phone,
      dob,
      roleId,
      roleCode,
      office,
      officeId,
      roleName,
      status,
      country,
      email,
      ownerUser,
      nationality,
      passport,
      addedOn,
      updatedOn,
      photo,
      street,
      city,
      address,
      pincode,
      state,
    };
    //console.log(data);
    // if (chkPass == false) {
    postUser(data);
    // } else {
    //   return false;
    // }
  };

  const postUser = (data) => {
    axios
      .post(`${base_url.api2}/createUserWithOffice`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        toast.success("Success");
        dispatch(addtoAdmin(response.data.data));

        let log =
          isw_user_name +
          " Created User " +
          response.data.data.firstName +
          " " +
          response.data.data.lastName +
          " for the office " +
          response.data.userOfficeData[0].officeName +
          ".";
        callsaveauditapi(log, dispatch, navigate);
        navigate("/office-agents/" + office_id);
      })
      .catch((error) => {
        toast.error(JSON.stringify(error.response.data.message));
        handle403error(error);
      });
  };

  const [chkPass, setchkPass] = useState();
  const [chkPassState, setchkPassState] = useState();
  const handlePassword = (e) => {
    var decimal =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
    //console.log(e.target.value);
    if (e.target.value.match(decimal)) {
      setchkPass(false);
      setchkPassState(e.target.value);
      return true;
    } else {
      setchkPass(true);
      // setchkPassState(e.target.value);
      return false;
    }
  };

  const goback = () => {
    navigate(-1);
    // navigate("/office-agents/" + office_id);
  };

  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            navigate("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };

  return (
    <div>
      {/* <Dashboard/> */}
      <Card className="commoncomponent_cardcss">
        <div>
          <Row>
            <Col md={6} style={{ textAlign: "left" }}>
              <CardText className={classes.headtext}>
                <strong>Create Users</strong>
              </CardText>
            </Col>
            <Col md={6} className="d-flex justify-content-end">
              <button className="btn listing_addbutton" onClick={goback}>
                &#x2630; List
              </button>
            </Col>
          </Row>
          {/* <CardText className={classes.headtext}>Create Admin User</CardText> */}
          <form id="myForm" onSubmit={handleSubmit(onSubmit)}>
            <div className="form-inner-admin">
              <div className="form-group creatadmin">
                <Row>
                  <Col md={4}>
                    <label className="area_rowslable_my">
                      First Name<span style={{ color: "red" }}>*</span>:
                    </label>
                    <input
                      type="text"
                      className="form-control underline-input"
                      name="firstName"
                      ref={register({ required: true })}
                    />
                    {errors.firstName && (
                      <p className="errormessage">FirstName is Required</p>
                    )}
                  </Col>
                  <Col md={4}>
                    <label className="area_rowslable_my">
                      Last Name<span style={{ color: "red" }}>*</span>:
                    </label>
                    <input
                      type="text"
                      className="form-control underline-input"
                      name="lastName"
                      ref={register({ required: true })}
                    />
                    {errors.lastName && (
                      <p className="errormessage">LastName is Required</p>
                    )}
                  </Col>
                  <Col md={4}>
                    <label className="area_rowslable_my">
                      Gender<span style={{ color: "red" }}>*</span>:
                    </label>
                    <select
                      className={classes.selectdrop}
                      name="gender"
                      ref={register({ required: true })}
                    >
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Other">Other</option>
                    </select>
                    {errors.gender && (
                      <p className="errormessage">Gender is Required</p>
                    )}
                  </Col>
                </Row>
              </div>
              <div className="form-group creatadmin">
                <Row>
                  <Col md={4}>
                    <label className="area_rowslable_my">
                      Email/Username<span style={{ color: "red" }}>*</span>:
                    </label>
                    <input
                      type="email"
                      autoComplete="off"
                      className="form-control underline-input"
                      name="email"
                      ref={register({ required: true })}
                    />
                    {errors.email && (
                      <p className="errormessage">Email is Required</p>
                    )}
                  </Col>
                  <Col md={4}>
                    <label className="area_rowslable_my">
                      Password<span style={{ color: "red" }}>*</span>:
                    </label>
                    {/* <input
                        type="password"
                        className="form-control underline-input"
                        onChange={handlePassword}
                        // ref={register('password',{
                        //   required: true,
                        //   minLength: {
                        //     value: 8,
                        //     message: "Password must have at least 8 characters",
                        //   },
                        // })}
                        name="password"
                        required
                      />
                      {chkPass ? (
                        <p className="errormessage">
                          Password must have at least 8 characters with at least
                          one lowercase letter, one uppercase letter, one
                          numeric digit, and one special character
                        </p>
                      ) : null} */}
                    <div style={{ position: "relative" }}>
                      <input
                        type={inputtype}
                        autoComplete="off"
                        className="form-control underline-input"
                        value={chkPassState}
                        onChange={(e) => setchkPassState(e.target.value)}
                        required
                      />
                      {inputtype === "password" ? (
                        <i
                          className="fa fa-eye-slash fonticonpositioning"
                          onClick={() => setinputtype("text")}
                          aria-hidden="true"
                        ></i>
                      ) : (
                        <i
                          className="fa fa-eye fonticonpositioning"
                          onClick={() => setinputtype("password")}
                          aria-hidden="true"
                        ></i>
                      )}
                    </div>
                    <PasswordStrengthBar password={chkPassState} />

                    {/* {errors.password && (
                        <p className="errormessage">
                          Password must have at least 8 characters
                        </p>
                      )} */}
                  </Col>
                  <Col md={4}>
                    <label className="area_rowslable_my">
                      Contact No.<span style={{ color: "red" }}></span>:
                    </label>
                    <input
                      type="number"
                      onWheel={(event) => event.currentTarget.blur()}
                      className="form-control underline-input"
                      name="phone"
                      ref={register({ required: false })}
                    />
                  </Col>
                </Row>
              </div>
              <div className="form-group creatadmin">
                <Row>
                  <Col md={4}>
                    <label className="area_rowslable_my">
                      Address<span style={{ color: "red" }}>*</span>:
                    </label>
                    <input
                      type="text"
                      className="form-control underline-input"
                      name="address"
                      ref={register({ required: true })}
                    />
                    {errors.address && (
                      <p className="errormessage">Address is Required</p>
                    )}
                  </Col>
                  <Col md={4}>
                    <label className="area_rowslable_my">
                      Country<span style={{ color: "red" }}>*</span>:
                    </label>
                    <CountryDropdown
                      className="form-control underline-input countrydropdown2"
                      defaultOptionLabel="- Select Country -"
                      value={country1}
                      onChange={(val) => selectCountry(val)}
                      required
                    />
                  </Col>
                  <Col md={2}>
                    <label className="area_rowslable_my">
                      State<span style={{ color: "red" }}>*</span>:
                    </label>
                    {country1 == "Nepal" ? (
                      <select
                        className={classes.selectdrop}
                        name="gender"
                        onInput={(e) => selectRegion(e.target.value)}
                      >
                        <option value="">- Select Province -</option>
                        <option value="Province No. 1">Province No. 1</option>
                        <option value="Madhesh Province">
                          Madhesh Province
                        </option>
                        <option value="Bagmati Province">
                          Bagmati Province
                        </option>
                        <option value="Gandaki Province">
                          Gandaki Province
                        </option>
                        <option value="Lumbini Province">
                          Lumbini Province
                        </option>
                        <option value="Karnali Province">
                          Karnali Province
                        </option>
                        <option value="Sudurpashchim Province">
                          Sudurpashchim Province
                        </option>
                      </select>
                    ) : (
                      <RegionDropdown
                        className="form-control underline-input countrydropdown2"
                        defaultOptionLabel="- Select State -"
                        country={country1}
                        value={region}
                        onChange={(val) => selectRegion(val)}
                        required
                      />
                    )}
                  </Col>
                  <Col md={2}>
                    <label className="area_rowslable_my">
                      City<span style={{ color: "red" }}>*</span>:
                    </label>
                    <input
                      type="text"
                      className="form-control underline-input"
                      name="city"
                      ref={register({ required: true })}
                    />
                    {errors.city && (
                      <p className="errormessage">City is Required</p>
                    )}
                  </Col>
                </Row>
              </div>
              <div className="form-group creatadmin">
                <Row>
                  <Col md={4}>
                    <label className="area_rowslable_my">
                      Date Of Birth<span style={{ color: "red" }}></span>:
                    </label>

                    <DatePicker
                      selected={
                        dateofbirthdate ? new Date(dateofbirthdate) : null
                      }
                      onChange={(date) => setdateofbirthdate(date)}
                      dateFormat="dd-MM-yyyy"
                      showYearDropdown
                      dropdownMode="select"
                      placeholderText="Select Date"
                    />
                  </Col>
                  <Col md={4}>
                    <label className="area_rowslable_my">
                      Role<span style={{ color: "red" }}>*</span>:
                    </label>
                    {isw_user_role == 1 || isw_user_role == 6 ? (
                      <select
                        defaultValue={defaultRole}
                        value={defaultRole}
                        className={classes.selectdrop}
                        onInput={(e) => {
                          //console.log(e.target.value);
                          handleChange(e);
                        }}
                        name="role"
                        ref={register({ required: true })}
                      >
                        <option value="">- Select Role -</option>
                        {availableroles == null ? (
                          <Backdrop className={classes.backdrop} open>
                            <CircularProgress color="inherit" />
                          </Backdrop>
                        ) : (
                          availableroles.map((cuntitem) => (
                            <option
                              key={cuntitem.roleId}
                              id={cuntitem.id}
                              value={cuntitem.roleName}
                              roleCode={cuntitem.roleCode}
                            >
                              {cuntitem.roleName}
                            </option>
                          ))
                        )}
                      </select>
                    ) : (
                      <select
                        defaultValue={defaultRole}
                        value={defaultRole}
                        className={classes.selectdrop}
                        onInput={(e) => {
                          //console.log(e.target.value);
                          handleChange(e);
                        }}
                        name="role"
                        ref={register({ required: true })}
                      >
                        <option value="">- Select Role -</option>
                        {specificroles == null ? (
                          <Backdrop className={classes.backdrop} open>
                            <CircularProgress color="inherit" />
                          </Backdrop>
                        ) : (
                          specificroles.map((cuntitem) => (
                            <option
                              key={cuntitem.roleId}
                              id={cuntitem.id}
                              value={cuntitem.roleName}
                              roleCode={cuntitem.roleCode}
                            >
                              {cuntitem.roleName}
                            </option>
                          ))
                        )}
                      </select>
                    )}
                    {errors.role && (
                      <p className="errormessage">Role is Required</p>
                    )}
                  </Col>
                  <Col md={2}>
                    <label className="area_rowslable_my">
                      PostCode<span style={{ color: "red" }}>*</span>:
                    </label>
                    <input
                      type="number"
                      onWheel={(event) => event.currentTarget.blur()}
                      className="form-control underline-input"
                      name="pincode"
                      ref={register({ required: true })}
                    />
                    {errors.pincode && (
                      <p className="errormessage">Pincode is Required</p>
                    )}
                  </Col>
                  <Col md={2}>
                    <label className="area_rowslable_my">
                      Status<span style={{ color: "red" }}>*</span>:
                    </label>
                    <select
                      className={classes.selectdrop}
                      name="status"
                      ref={register({ required: true })}
                    >
                      <option value="1">Active</option>
                      <option value="0">Inactive</option>
                    </select>
                    {errors.status && (
                      <p className="errormessage">Status is Required</p>
                    )}
                  </Col>
                </Row>
              </div>
              <Row>
                <Col md={6}>{null}</Col>
                <Col md={6} className="d-flex justify-content-end">
                  <Button
                    color="secondary"
                    onClick={() => {
                      try {
                        document.getElementById("myForm").reset();
                      } catch (error) {
                        //console.log(error);
                      }
                    }}
                  >
                    Reset
                  </Button>
                  <button type="submit" className="btn listing_addbutton ml-1">
                    Submit
                  </button>
                </Col>
              </Row>
              {/* <Button
                  color="primary"
                  type="submit"
                  style={{ marginTop: "20px" }}
                >
                  Submit
                </Button> */}
            </div>
          </form>
        </div>
      </Card>
    </div>
  );
}

export default AddOfficeAgents;
