import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../css/AddInvoiceDetails.css";
import "../css/Responsive.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import base_url from "../../api/bootapi";
import attachmenturl from "../../api/attachmenturl";
import { toast } from "react-toastify";
import moment from "moment";
import { useForm } from "react-hook-form";
import { Button } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { logout, selectUser, gettabvalue } from "../features/userSlice";
import DatePicker from "react-datepicker";
import callsaveauditapi from "../../services/auditservice";
import { check1pointpermission } from "../Permission/permission";
import Modal from "react-modal";
import { customStyles } from "../../Utilities/CSSUtilities";
import { param } from "jquery";

const selectheight = "35px";
const selectWidth = "100%";
const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
  },
  selectdrop: {
    width: selectWidth,
    height: selectheight,
  },
  backdrop: {
    // zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    zIndex: 1000,
  },
}));

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    // color: theme.palette.common.black,
  },
  tooltip: {
    // backgroundColor: theme.palette.common.black,
  },
}));
function BootstrapTooltip(props) {
  const classes2 = useStylesBootstrap();

  return <Tooltip arrow classes={classes2} {...props} />;
}

const EditInvoice = () => {
  const isoDateString = new Date().toISOString();
  const datenow = isoDateString.split(".")[0];
  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  const isw_user_name = localStorage.getItem("isw_user_name");
  const isw_adminId = localStorage.getItem("isw_adminId");
  const classes = useStyles();
  const [backdropopen, setbackdropopen] = useState(false);
  const [newline, setnewline] = useState([]);
  const [incomesharing, setincomeSharing] = useState([]);
  const [paymentoption, setpaymentoption] = React.useState("");
  const [currency1, setcurrency] = useState();
  const params = useParams();
  const [amountvalues, setamountvalues] = useState([]);
  const [taxamountvalues, settaxamountvalues] = useState([]);
  const [totalamountvalues, settotalamountvalues] = useState([]);
  const [commissionamountvalues, setcommissionamountvalues] = useState([]);
  const [totalamt, settotalamt] = useState();
  const [totalcommission, settotalcommission] = useState(0);
  const [totalpaid, settotalpaid] = useState();
  // const [totalpaidvalues, setTotalPaidValues] = useState(["", ""]);
  const [totalpaidvalues, setTotalPaidValues] = useState([]);
  const [totaltax, settotaltax] = useState();
  const [totalamttax, settotalamttax] = useState();
  const [netincomevalue, setnetincomevalue] = useState();
  const [originalnetincomevalue, setoriginalnetincomevalue] = useState(0);
  const [discountpercent, setdiscountpercent] = useState();
  const [commision, setcommision] = useState();
  const [discountAmount, setdiscountAmount] = useState(0);
  const [discountpercentamount, setdiscountpercentamount] = useState(0);
  const isw_user_email = localStorage.getItem("isw_user_email");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const selected_client_username = localStorage.getItem(
    "selected_client_username"
  );
  const ipaddress = localStorage.getItem("AdminIpAddress");
  const [netincomedataentries, setnetincomedataentries] = useState([]);
  const [getdueAmount, setdueAmount] = useState();
  const [statusoption, setstatus] = useState("Unpaid");

  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [deleteinvoices, setdeleteinvoices] = useState([]);
  // const [deletePayment, setDeletePayment] = useState([]);
  const [duedate, setduedate] = useState(null);
  const [invoiceDate, setInvoiceDate] = useState(null);
  const [paidinvoicedate, setpaidinvoicedate] = useState(null);
  const [enquiryDetails, setEnquiryDetails] = useState();
  const [userDetails, setUserDetails] = useState();
  const user = useSelector(selectUser);
  const [userEmail, setUserEmail] = useState();

  const [statusmodal, setstatusmodal] = useState(false);
  const closestatusModal = () => setstatusmodal(false);
  const openstatusmodal = () => {
    setstatusmodal(true);
  };

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        navigate("/");
      }
    });
    hideListItems(user.isw_role_permissions);
    getPermissions();
    console.log("143", params);
  }, []);

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);

    if (check1pointpermission(506, permission_obj, resource_obj)) {
      setbackdropopen(true);
      getClientDetails();
      getenquiry();
      getinvoicedetails();
      getinvoicepaymentdetails();
      getAllAgent();
      getIntake();
      // getRefNumber();
    } else {
      navigate("/errorPage");
    }
  };

  const [clientDetail1, setclientDetail1] = useState();
  const getClientDetails = () => {
    axios
      .get(`${base_url.api3}/getClientById`, {
        headers: {
          Authorization: jwtToken,
          clientId: params.client_id,
        },
      })
      .then((res) => {
        //console.log(res.data.data.officeId);
        setclientDetail1(res.data.data);
      })
      .catch((error) => {
        toast.error(JSON.stringify(error.response.data.message));
        handle403error(error);
      });
  };
  const [enquiryname, setenquiryname] = useState();
  const getenquiry = () => {
    axios
      .get(`${base_url.api2}/getEnquiryByClientId`, {
        headers: {
          Authorization: jwtToken,
          clientId: params.client_id,
          columnName: "created",
          direction: "DESC",
          page: 0,
          size: 100,
        },
      })
      .then((res) => {
        //console.log("Enquiry", res.data.data);
        setenquiryname(res.data.data.content);
      })
      .catch((error) => {
        handle403error(error);
      });
  };
  const [agent, setagent] = useState([]);
  const getAllAgent = () => {
    axios
      .get(`${base_url.api3}/findAmbassadorAndAgents`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          for (let i = 0; i < res.data.agentData?.length; i++) {
            setagent((prev) => [
              ...prev,
              {
                value: `${res.data.agentData[i].name} (Agent)`,
                id: res.data.agentData[i].agentId,
                commissionPercentage:
                  res.data.agentData[i].commissionPercentage,
                referalPercentage: res.data.agentData[i].referalPercentage,
                email: res.data.agentData[i].email,
              },
            ]);
          }
          for (let j = 0; j < res.data.ambassadorData?.length; j++) {
            setagent((prev) => [
              ...prev,
              {
                value: `${res.data.ambassadorData[j].firstName} ${res.data.ambassadorData[j].lastname} (Ambassador)`,
                id: res.data.ambassadorData[j].ambassadorId,
                email: res.data.ambassadorData[j].email,
                commissionPercentage: 0,
                referalPercentage: 0,
              },
            ]);
          }
        }
      })
      .catch((error) => {
        handle403error(error);
      });
  };
  const [stateCust, setstateCust] = useState();
  const [paymentReceivedData, setPaymentReceivedData] = useState([]);
  const [IncomeSharePayableId, setIncomeSharePayableId] = useState([]);
  const getinvoicedetails = () => {
    axios
      .get(`${base_url.api2}/getInvoiceById`, {
        headers: {
          Authorization: jwtToken,
          invoiceId: params.invoice_id,
        },
      })
      .then((res) => {
        axios
          .get(`${base_url.api2}/getEnquiryById`, {
            headers: {
              Authorization: jwtToken,
              enquiryId: Number(res.data.data.enquiryId),
            },
          })
          .then((resp) => {
            setEnquiryDetails(resp.data.data);
            setUserEmail(resp.data.data.clientUsername);
            //console.log(res.data.data.totalDue);
            setdueAmount(res.data.data.totalDue);
            //console.log(res.data.data.incomeSharing);
            let IncomesharedArray = res.data.data.incomeSharing;
            for (let i = 0; i < IncomesharedArray.length; i++) {
              //console.log(IncomesharedArray[i].payableInvoiceId);
              IncomeSharePayableId.push(IncomesharedArray[i].payableInvoiceId);
            }
            //console.log(IncomeSharePayableId);
          })
          .catch((err) => {
            setEnquiryDetails(null);
            //console.log(err);
            handle403error(err);
          });

        axios
          .get(`${base_url.api2}/getUserById`, {
            headers: {
              Authorization: jwtToken,
              id: Number(res.data.data.invoiceCreatedBy),
            },
          })
          .then((resp) => {
            setUserDetails(resp.data.data);
          })
          .catch((err) => {
            setUserDetails(null);
            //console.log(err);
            handle403error(err);
          });

        console.log("304---->", res.data.data.incomeSharing);
        for (let j = 0; j < res.data.data.incomeSharing.length; j++) {
          setincomeSharing((prev) => [
            ...prev,
            {
              id: 0,
              incomeShareHolderId:
                res.data.data.incomeSharing[j].incomeShareHolderId,
              incomeSharingName:
                res.data.data.incomeSharing[j].incomeSharingName,
              incomeSharingAmount:
                res.data.data.incomeSharing[j].incomeSharingCommissionPercent,
              incomesharingcommissionamount:
                res.data.data.incomeSharing[j].sharingCommissionAmount,
              incomeSharingtaxpercent:
                res.data.data.incomeSharing[j].sharingTaxPercentage,
              incomeSharingtaxamount:
                res.data.data.incomeSharing[j].sharingTaxAmount,
              incomeSharingAmountOriginal:
                res.data.data.incomeSharing[j].incomeSharingAmount,
              incomeSharingemail: "",
              note: res.data.data.incomeSharing[j].notes,
              incomesharingcommissionpercentage: "",
              incomesharingreferalpercentage:
                res.data.data.incomeSharing[j].notes,
              incomeSharingType:
                res.data.data.incomeSharing[j].incomeSharingType,
              payableInvoiceId: res.data.data.incomeSharing[j].payableInvoiceId,

              incomesharingcommissionpercentage:
                res.data.data.incomeSharing[j]
                  .agentIncomeSharingCommissionPercent,
              incomesharingreferalpercentage:
                res.data.data.incomeSharing[j].agentIncomeSharingReferalPercent,
            },
          ]);
          setnetincomedataentries((prev) => [
            ...prev,
            res.data.data.incomeSharing[j].sharingCommissionAmount,
          ]);
        }
        if (res.data.data.commisionType === "General Invoice") {
          setoriginalnetincomevalue(res.data.data.netAmount);
        } else {
          setoriginalnetincomevalue(
            res.data.data.totalCommission - res.data.data.discountAmount
          );
        }
        let invoiceDetails = [];
        for (let i = 0; i < res.data.data.invoiceDetails.length; i++) {
          setnewline((prev) => [
            ...prev,
            {
              id: 0,
              commissionPercentage:
                res.data.data.invoiceDetails[i].commissionPercentage,
              commissionAmount:
                res.data.data.invoiceDetails[i].commissionAmount,
              quantity: res.data.data.invoiceDetails[i].quantity,
              description: res.data.data.invoiceDetails[i].description,
              amount: res.data.data.invoiceDetails[i].amount,
              taxPercentage: res.data.data.invoiceDetails[i].taxPercentage,
              taxAmount: res.data.data.invoiceDetails[i].taxAmount,
              totalAmount: res.data.data.invoiceDetails[i].totalAmount,
            },
          ]);
          setamountvalues((prev) => [
            ...prev,
            res.data.data.invoiceDetails[i].amount,
          ]);
          setcommissionamountvalues((prev) => [
            ...prev,
            res.data.data.invoiceDetails[i].commissionAmount,
          ]);
          settaxamountvalues((prev) => [
            ...prev,
            res.data.data.invoiceDetails[i].taxAmount,
          ]);

          settotalamountvalues((prev) => [
            ...prev,
            res.data.data.invoiceDetails[i].totalAmount,
          ]);
          invoiceDetails.push({
            amount: res.data.data.invoiceDetails[i].amount,
            commissionPercentage:
              res.data.data.invoiceDetails[i].commissionPercentage,
            commissionAmount: res.data.data.invoiceDetails[i].commissionAmount,
            description: res.data.data.invoiceDetails[i].description,
            id: 0,
            quantity: res.data.data.invoiceDetails[i].quantity,
            taxAmount: res.data.data.invoiceDetails[i].taxAmount,
            taxPercentage: res.data.data.invoiceDetails[i].taxPercentage,
            totalAmount: res.data.data.invoiceDetails[i].totalAmount,
          });
        }

        setstateCust({
          clientId: res.data.data.clientId,
          clientUsername: res.data.data.clientUsername,
          agentName: res.data.data.agentName,
          commisionType: res.data.data.commisionType,
          currency: res.data.data.currency,
          comment: res.data.data.comment,
          courseName: res.data.data.courseName,
          courseId: res.data.data.courseId,
          discountAmount: res.data.data.discountAmount,
          discountPercentage: res.data.data.discountPercentage,
          discountPercentageType: res.data.data.discountPercentageType,
          enquiryId: res.data.data.enquiryId,
          enquiryName: res.data.data.enquiryName,
          externalId: res.data.data.externalId,
          id: res.data.data.id,
          incomeSharing: res.data.data.incomeSharing,
          incomeType: res.data.data.incomeType,
          institutionId: res.data.data.institutionId,
          intake: res.data.data.intake,
          incomeSharedAmount: res.data.data.incomeSharedAmount,
          invoiceCreatedBy: res.data.data.invoiceCreatedBy,
          invoiceCreatedDate: res.data.data.invoiceCreatedDate,
          invoiceDetails,
          invoiceDueDate: res.data.data.invoiceDueDate,
          invoicePaidDate: res.data.data.invoicePaidDate,
          invoicePath: res.data.data.invoicePath,
          invoiceStatus: res.data.data.invoiceStatus,
          invoiceSubType: res.data.data.invoiceSubType,
          invoiceType: res.data.data.invoiceType,
          netAmount: res.data.data.netAmount,
          netIncome: res.data.data.netIncome,
          officeId: res.data.data.officeId,
          serviceProvider: res.data.data.serviceProvider,
          totalAmountIncludingTax: res.data.data.totalAmountIncludingTax,
          totalCommission: res.data.data.totalCommission,
          totalDue: res.data.data.totalDue,
          totalPaid: res.data.data.totalPaid,
          totalTax: res.data.data.totalTax,
          workflowType: res.data.data.workflowType,
          updatedOn: datenow,
        });
        setEnquiryIntake(res.data.data.intake);
        setInvoiceDate(res.data.data.invoiceCreatedDate);
        setduedate(res.data.data.invoiceDueDate);
        setnetincomevalue(res.data.data.netIncome);
        //console.log(res.data.data.netIncome);
        //console.log(res.data.data.totalPaid);
        //console.log(res.data.data.totalDue);
        settotalpaid(res.data.data.totalPaid);
        setstatus(res.data.data.invoiceStatus);
        setcommision(res.data.data.commisionType);
        setdiscountpercentamount(res.data.data.discountPercentage);
        setdiscountAmount(res.data.data.discountAmount);
        setdiscountpercent(res.data.data.discountPercentageType);
        totalpaidvalues[0] = Number(res.data.data.totalPaid);
        settotalamt(res.data.data.netAmount);
        settotaltax(res.data.data.totalTax);
        settotalcommission(res.data.data.totalCommission);
        settotalamttax(res.data.data.totalAmountIncludingTax);
        setcurrency(res.data.data.currency);
        setbackdropopen(false);
      })
      .catch((err) => {
        setbackdropopen(false);
        handle403error(err);
      });
  };
  const handleStatus = (event) => {
    setstatus(event.target.value);
  };
  //   //console.log("Currency", currency1);
  const getinvoicepaymentdetails = () => {
    axios
      .get(`${base_url.api2}/getInvoicePaymentByInvoiceId`, {
        headers: {
          Authorization: jwtToken,
          invoiceId: params.invoice_id,
          columnName: "date",
          direction: "DESC",
          page: 0,
          size: 20,
        },
      })
      .then((res) => {
        //console.log(res);
        let arr = [];
        res.data.data.forEach((ele) => {
          let obj = {
            id: ele.id,
            amount: ele.amount,
            date: ele.date === "Invalid date" ? datenow : ele.date,
            invoiceId: ele.invoiceId,
            notes: ele.notes,
            paymentMode: ele.paymentMode,
            status: 1,
            filedata: null,
            attachmentName: ele.attachmentName,
            attachmentPath: ele.attachmentPath,
          };
          arr.push(obj);
        });
        if (arr.length > 0) {
          setPaymentReceivedData(arr);
        } else {
          setPaymentReceivedData([
            {
              id: 0,
              amount: "",
              date: datenow,
              invoiceId: "",
              notes: "",
              paymentMode: "EFT",
              status: 1,
              filedata: null,
              attachmentName: "",
              attachmentPath: "",
            },
          ]);
        }
        let total_paid_obj = [];
        for (let i = 0; i < res.data.data.length; i++) {
          total_paid_obj.push(res.data.data[i].amount);
        }
        setTotalPaidValues(total_paid_obj);
        // setbackdropopen(false);
      })
      .catch((err) => {
        // setbackdropopen(false);
        handle403error(err);
      });
  };
  const handleChange = (event) => {
    setpaymentoption(event.target.value);
  };

  const hideListItems = (elem) => {
    const permission_obj = [];
    for (let i = 0; i < elem.length; i++) {
      permission_obj.push(elem[i].resourceId);
    }
    setrolepermission(permission_obj);
  };

  const checkrolepermission = (x, y) => {
    for (let i = x; i <= y; i++) {
      if (rolepermission.includes(i)) {
        return true;
      }
    }
    return false;
  };

  const inputEvent = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setstateCust((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };
  const addClick = () => {
    setamountvalues((prev) => [...prev, ""]);
    settaxamountvalues((prev) => [...prev, ""]);
    settotalamountvalues((prev) => [...prev, ""]);
    setcommissionamountvalues((prev) => [...prev, ""]);
  };
  const handlelinechange = (e, i) => {
    var lines = [...newline];
    lines[i].description = e.target.value;
    setnewline(lines);
  };
  const handletaxpercentchange = (e, i) => {
    var lines = [...newline];
    lines[i].taxPercentage = Number(e.target.value);
    if (commision === "General Invoice") {
      lines[i].taxAmount = (
        (Number(lines[i].amount) * Number(lines[i].taxPercentage)) /
        100
      ).toFixed(2);
    } else {
      lines[i].taxAmount = (
        (Number(lines[i].commissionAmount) * Number(lines[i].taxPercentage)) /
        100
      ).toFixed(2);
    }

    // For tax amount
    let taxamount = [...taxamountvalues];
    taxamount[i] = Number(lines[i].taxAmount);
    settaxamountvalues(taxamount);
    settotaltax(taxamount?.reduce((amount, item) => item + amount, 0));

    // For total amount includingtax
    if (commision === "General Invoice") {
      lines[i].totalAmount =
        Number(lines[i].amount) +
        Number(lines[i].taxAmount) +
        Number(lines[i].commissionAmount);
      let totalamount = [...totalamountvalues];
      totalamount[i] = Number(lines[i].totalAmount);
      settotalamountvalues(totalamount);
      settotalamttax(totalamount?.reduce((amount, item) => item + amount, 0));
    }
    setnewline(lines);
  };
  const handleamountChange = (e, i) => {
    var lines = [...newline];
    lines[i].amount = Number(e.target.value);
    //console.log(lines[i].amount);

    if (commision === "General Invoice") {
      lines[i].taxAmount = (
        (Number(lines[i].amount) * Number(lines[i].taxPercentage)) /
        100
      ).toFixed(2);
      lines[i].totalAmount =
        Number(lines[i].amount) + Number(lines[i].taxAmount);
    } else {
      lines[i].commissionAmount = (
        (Number(lines[i].amount) * Number(lines[i].commissionPercentage)) /
        100
      ).toFixed(2);
      lines[i].taxAmount = (
        (Number(lines[i].commissionAmount) * Number(lines[i].taxPercentage)) /
        100
      ).toFixed(2);
    }

    // For tax amount
    let taxamount = [...taxamountvalues];
    taxamount[i] = Number(lines[i].taxAmount);
    settaxamountvalues(taxamount);
    settotaltax(taxamount?.reduce((amount, item) => item + amount, 0));

    // For Commission Amount
    let commissionamount = [...commissionamountvalues];
    commissionamount[i] = Number(lines[i].commissionAmount);
    setcommissionamountvalues(commissionamount);
    settotalcommission(
      commissionamount?.reduce((amount, item) => item + amount, 0)
    );

    // For amount excluding tax
    let amount = [...amountvalues];
    amount[i] = Number(lines[i].amount);
    setamountvalues(amount);
    settotalamt(amount?.reduce((amount, item) => item + amount, 0));

    // For discount amount
    let discountamount = 0;
    if (discountpercent !== "Fixed") {
      if (commision !== "General Invoice") {
        let totalAmountfordiscount = Number(
          amount?.reduce((amount, item) => item + amount, 0)
        );
        // let totalAmountfordiscount = Number(
        //   commissionamount?.reduce((amount, item) => item + amount, 0)
        // );
        discountamount = (
          (Number(totalAmountfordiscount) * Number(discountpercentamount)) /
          100
        ).toFixed(2);
        setdiscountAmount(discountamount);
      }
    } else if (commision !== "General Invoice") {
      discountamount = discountAmount;
    }

    if (commision === "General Invoice") {
      setoriginalnetincomevalue(
        amount?.reduce((amount, item) => item + amount, 0)
      );
      if (
        incomesharing.length > 0 &&
        incomesharing[0].incomesharingcommissionamount > 0
      ) {
        changeincomesharingdata(
          amount?.reduce((amount, item) => item + amount, 0)
        );
      } else {
        setnetincomevalue(amount?.reduce((amount, item) => item + amount, 0));
      }
    } else {
      setoriginalnetincomevalue(
        commissionamount?.reduce((amount, item) => item + amount, 0) -
        discountamount
      );
      if (
        incomesharing.length > 0 &&
        incomesharing[0].incomesharingcommissionamount > 0
      ) {
        changeincomesharingdata(
          commissionamount?.reduce((amount, item) => item + amount, 0) -
          discountamount
        );
      } else {
        setnetincomevalue(
          commissionamount?.reduce((amount, item) => item + amount, 0) -
          discountamount
        );
      }
    }
    // For total amount includingtax
    if (commision === "General Invoice") {
      lines[i].totalAmount =
        Number(lines[i].amount) +
        Number(lines[i].taxAmount) +
        Number(lines[i].commissionAmount);
      let totalamount = [...totalamountvalues];
      totalamount[i] = Number(lines[i].totalAmount);
      settotalamountvalues(totalamount);
      settotalamttax(totalamount?.reduce((amount, item) => item + amount, 0));
    }
    setnewline(lines);
  };
  const handlecommisionChange = (e, i) => {
    var lines = [...newline];
    lines[i].commissionPercentage = Number(e.target.value);
    lines[i].commissionAmount = Number(
      (Number(lines[i].amount) * Number(lines[i].commissionPercentage)) / 100
    );
    lines[i].taxAmount =
      (lines[i].commissionAmount * lines[i].taxPercentage) / 100;
    let commissionamount = [...commissionamountvalues];
    commissionamount[i] = Number(lines[i].commissionAmount);
    setcommissionamountvalues(commissionamount);
    settotalcommission(
      commissionamount?.reduce((amount, item) => item + amount, 0)
    );
    // For discount amount
    // let discountamount = 0;
    // if (discountpercent !== "Fixed") {
    //   if (commision !== "General Invoice") {
    //     let totalAmountfordiscount = Number(
    //       commissionamount?.reduce((amount, item) => item + amount, 0)
    //     );
    //     discountamount =
    //       (Number(totalAmountfordiscount) * Number(discountpercentamount)) /
    //       100;
    //     setdiscountAmount(discountamount);
    //   }
    // } else if (commision !== "General Invoice") {
    //   discountamount = discountAmount;
    // }
    let discountamount = discountAmount;
    //For tax amount
    let taxamount = [...taxamountvalues];
    taxamount[i] = Number(lines[i].taxAmount);
    settaxamountvalues(taxamount);
    settotaltax(taxamount?.reduce((amount, item) => item + amount, 0));
    setoriginalnetincomevalue(
      commissionamount?.reduce((amount, item) => item + amount, 0) -
      discountamount
    );
    if (
      incomesharing.length > 0 &&
      incomesharing[0].incomesharingcommissionamount > 0
    ) {
      changeincomesharingdata(
        commissionamount?.reduce((amount, item) => item + amount, 0) -
        discountamount
      );
    } else {
      setnetincomevalue(
        commissionamount?.reduce((amount, item) => item + amount, 0) -
        discountamount
      );
    }
    setnewline(lines);
  };

  // Changing Payment Received
  const handleNewPaymentReceivedAmount = (e, i) => {
    var lines = [...paymentReceivedData];
    lines[i].amount = e.target.value;
    setPaymentReceivedData(lines);

    let values = [...totalpaidvalues];
    //console.log(values);
    values[i] = e.target.value;
    setTotalPaidValues(values);
    settotalpaid(
      values?.reduce((amount, item) => Number(item) + Number(amount), 0)
    );
  };
  //console.log(totalpaidvalues);

  const handlePaymentReceivedNotes = (e, i) => {
    var lines = [...paymentReceivedData];
    lines[i].notes = e.target.value;
    setPaymentReceivedData(lines);
  };
  const handlePaymentReceiveAttachment = (e, i) => {
    var lines = [...paymentReceivedData];
    lines[i].filedata = e.target.files;
    lines[i].attachmentName = "";
    lines[i].attachmentPath = "";
    setPaymentReceivedData(lines);
  };
  const handlePaymentReceivedMode = (e, i) => {
    var lines = [...paymentReceivedData];
    lines[i].paymentMode = e.target.value;
    setPaymentReceivedData(lines);
  };

  const handlePaymentReceiveDate = (date, i) => {
    var lines = [...paymentReceivedData];
    lines[i].date = date;
    setPaymentReceivedData(lines);
  };
  // Changing Payment Received

  //Changing IncomeSharing Data based on change in invoicedata
  const changeincomesharingdata = (value) => {
    let lines = [...incomesharing];
    let val2 = [...netincomedataentries];

    for (let i = 0; i < lines.length; i++) {
      // lines[i].incomesharingcommissionamount =
      //   (Number(value) * Number(lines[i].incomeSharingAmount)) / 100;
      // lines[i].incomeSharingtaxamount =
      //   (Number(lines[i].incomesharingcommissionamount) *
      //     Number(lines[i].incomeSharingtaxpercent)) /
      //   100;
      // lines[i].incomeSharingAmountOriginal =
      //   Number(lines[i].incomesharingcommissionamount) +
      //   Number(lines[i].incomeSharingtaxamount);
      val2[i] = Number(lines[i].incomesharingcommissionamount);
    }
    setnetincomedataentries(val2);
    setnetincomevalue(value - val2?.reduce((amount, item) => item + amount, 0));
    // setincomeSharing(lines);
  };
  // Handeling Discount

  const handleDiscountpercent = (e) => {
    setdiscountpercentamount(e.target.value);
    let discountamount = 0;
    if (commision !== "General Invoice") {
      if (discountpercent === "Fixed") {
        setdiscountAmount(e.target.value);
        setoriginalnetincomevalue(
          Number(totalcommission - Number(e.target.value))
        );
        if (
          incomesharing.length > 0 &&
          incomesharing[0].incomesharingcommissionamount > 0
        ) {
          changeincomesharingdata(
            Number(totalcommission - Number(e.target.value))
          );
        } else {
          setnetincomevalue(Number(totalcommission - Number(e.target.value)));
        }
      } else {
        discountamount = (Number(totalamt) * Number(e.target.value)) / 100;
        // (Number(totalcommission) * Number(e.target.value)) / 100;
        setdiscountAmount(discountamount);
        setoriginalnetincomevalue(Number(totalcommission - discountamount));
        if (
          incomesharing.length > 0 &&
          incomesharing[0].incomesharingcommissionamount > 0
        ) {
          changeincomesharingdata(Number(totalcommission - discountamount));
        } else {
          setnetincomevalue(Number(totalcommission - discountamount));
        }
      }
    }
  };
  const handleDiscountfixed = (e) => {
    stateCust.discountPercentageType = e.target.value;
    setdiscountpercentamount(0);
    setoriginalnetincomevalue(Number(totalcommission));
    if (
      incomesharing.length > 0 &&
      incomesharing[0].incomesharingcommissionamount > 0
    ) {
      changeincomesharingdata(Number(totalcommission));
    } else {
      setnetincomevalue(Number(netincomevalue) + Number(discountAmount));
    }
    setdiscountAmount(0);
    setdiscountpercent(e.target.value);
  };
  const handleincomesharingname = (e, i) => {
    const index = e.target.selectedIndex;
    const optionElement = e.target.childNodes[index];
    const selectedcourseId = optionElement.getAttribute("id");
    const selectedcommisionpercentage = optionElement.getAttribute(
      "commissionpercentage"
    );
    const selectedreferalpercentage =
      optionElement.getAttribute("referalpercentage");
    const selectedcommisionemail = optionElement.getAttribute("email");

    //---------------------------> Original Income Sharing Array
    let lines = [...incomesharing];
    lines[i].incomeSharingName = e.target.value;
    //console.log(e.target.value);
    //console.log(lines[i].incomeSharingName);
    lines[i].incomeShareHolderId = Number(selectedcourseId);
    lines[i].incomeSharingemail = selectedcommisionemail;
    if (lines[i].incomeSharingType === "IncomeSharing") {
      lines[i].incomeSharingAmount = Number(selectedcommisionpercentage);
    } else {
      lines[i].incomeSharingAmount = Number(selectedreferalpercentage);
    }
    lines[i].incomesharingreferalpercentage = Number(selectedreferalpercentage);
    lines[i].incomesharingcommissionpercentage = Number(
      selectedcommisionpercentage
    );
    // lines[i].incomeSharingAmount = Number(selectedcommisionpercentage);
    // ------------------------>Calculating Income amount
    // let incomeamount =
    //   (Number(originalnetincomevalue) * Number(lines[i].incomeSharingAmount)) /
    //   100;
    // lines[i].incomeSharingtaxamount =
    //   (incomeamount * lines[i].incomeSharingtaxpercent) / 100;
    // lines[i].incomesharingcommissionamount = Number(incomeamount);
    // lines[i].incomeSharingAmountOriginal =
    //   incomeamount + (incomeamount * lines[i].incomeSharingtaxpercent) / 100;

    // let val2 = [...netincomedataentries];
    // val2[i] = Number(incomeamount);
    // setnetincomedataentries(val2);
    // setnetincomevalue(
    //   originalnetincomevalue - val2?.reduce((amount, item) => item + amount, 0)
    // );
    //console.log(lines);
    setincomeSharing(lines);
  };

  const handleincomesharingtype = (e, i) => {
    var lines = [...incomesharing];
    lines[i].incomeSharingType = e.target.value;
    if (e.target.value === "IncomeSharing") {
      lines[i].incomeSharingAmount = lines[i].incomesharingcommissionpercentage;
    } else {
      lines[i].incomeSharingAmount = lines[i].incomesharingreferalpercentage;
    }
    // ------------------------>Calculating Income amount
    // let incomeamount =
    //   (Number(originalnetincomevalue) * Number(lines[i].incomeSharingAmount)) /
    //   100;
    // lines[i].incomeSharingtaxamount =
    //   (incomeamount * lines[i].incomeSharingtaxpercent) / 100;
    // lines[i].incomesharingcommissionamount = incomeamount;
    // lines[i].incomeSharingAmountOriginal =
    //   incomeamount + (incomeamount * lines[i].incomeSharingtaxpercent) / 100;

    // let val2 = [...netincomedataentries];
    // val2[i] = Number(incomeamount);
    // setnetincomedataentries(val2);
    // setnetincomevalue(
    //   originalnetincomevalue - val2?.reduce((amount, item) => item + amount, 0)
    // );

    setincomeSharing(lines);
  };

  // const handleincomesharingamount = (e, i) => {
  //   var lines = [...incomesharing];
  //   lines[i].incomeSharingAmount = Number(e.target.value);
  //   let incomeamount =
  //     (Number(originalnetincomevalue) * Number(e.target.value)) / 100;
  //   lines[i].incomesharingcommissionamount = Number(incomeamount);
  //   lines[i].incomeSharingtaxamount =
  //     (incomeamount * lines[i].incomeSharingtaxpercent) / 100;
  //   lines[i].incomeSharingAmountOriginal =
  //     incomeamount + (incomeamount * lines[i].incomeSharingtaxpercent) / 100;
  //   let val2 = [...netincomedataentries];
  //   val2[i] = Number(incomeamount);
  //   setnetincomedataentries(val2);
  //   setnetincomevalue(
  //     originalnetincomevalue - val2?.reduce((amount, item) => item + amount, 0)
  //   );
  //   setincomeSharing(lines);
  // };

  const handleincomesharingComissionamount = (e, i) => {
    var lines = [...incomesharing];
    lines[i].incomesharingcommissionamount = Number(e.target.value);
    // let incomeamount =
    //   (Number(e.target.value) / Number(originalnetincomevalue)) * 100;
    // lines[i].incomeSharingAmount = incomeamount;
    lines[i].incomeSharingtaxamount =
      (lines[i].incomesharingcommissionamount *
        lines[i].incomeSharingtaxpercent) /
      100;
    lines[i].incomeSharingAmountOriginal =
      lines[i].incomesharingcommissionamount +
      (lines[i].incomesharingcommissionamount *
        lines[i].incomeSharingtaxpercent) /
      100;
    let val2 = [...netincomedataentries];
    val2[i] = Number(lines[i].incomesharingcommissionamount);
    setnetincomedataentries(val2);
    setnetincomevalue(
      originalnetincomevalue - val2?.reduce((amount, item) => item + amount, 0)
    );
    setincomeSharing(lines);
  };

  const handleincomesharingtax = (e, i) => {
    var lines = [...incomesharing];
    lines[i].incomeSharingtaxpercent = Number(e.target.value);
    let incomeamount =
      (Number(lines[i].incomesharingcommissionamount) *
        Number(e.target.value)) /
      100;
    lines[i].incomeSharingtaxamount = Number(incomeamount);
    lines[i].incomeSharingAmountOriginal =
      incomeamount + Number(lines[i].incomesharingcommissionamount);
    setincomeSharing(lines);
  };
  const handleincomesharingnote = (e, i) => {
    var lines = [...incomesharing];
    lines[i].note = e.target.value;
    setincomeSharing(lines);
  };
  const onSubmit = (data2) => {
    //console.log(stateCust?.invoiceStatus);
    if (Number(totalpaid) === 0) {
      if (statusoption === "Paid" || statusoption === "PartiallyPaid") {
        openstatusmodal();
        return;
      }
    } else {
      if (Number(gettotaldue()) > 0) {
        if (statusoption === "Unpaid" || statusoption === "Paid") {
          openstatusmodal();
          return;
        }
      } else {
        if (statusoption === "Unpaid" || statusoption === "PartiallyPaid") {
          openstatusmodal();
          return;
        }
      }
    }
    setbackdropopen(true);
    let appendvalue = "";
    newline.forEach((val) => {
      appendvalue += val.description + " ";
    });
    stateCust.invoiceDetails = newline;
    stateCust.netAmount = Number(totalamt);
    stateCust.netIncome = Number(netincomevalue);
    stateCust.discountAmount = Number(discountAmount);
    stateCust.discountPercentage = Number(discountpercentamount);
    stateCust.discountPercentageType = discountpercent;
    stateCust.enquiryId = Number(stateCust.enquiryId);
    if (stateCust.invoiceType === "Payable") {
      stateCust.totalAmountIncludingTax = Number(0);
    } else if (commision === "General Invoice") {
      stateCust.totalAmountIncludingTax = Number(totalamttax);
    } else if (commision === "commissionInvoiceNet") {
      stateCust.totalAmountIncludingTax = Number(
        totalamt - totaltax - totalcommission
      );
    } else {
      stateCust.totalAmountIncludingTax = Number(totaltax + totalcommission);
    }
    stateCust.totalPaid = Number(totalpaid);
    stateCust.totalTax = Number(totaltax);
    stateCust.totalCommission = Number(totalcommission);
    if (stateCust.invoiceType === "Payable") {
      stateCust.totalDue =
        Number(totalcommission) + Number(totaltax) - Number(totalpaid);
    } else if (commision === "General Invoice") {
      stateCust.totalDue = Number(totalamttax - totalpaid - discountAmount);
    } else if (commision === "commissionInvoiceNet") {
      stateCust.totalDue = Number(totalamt - totalpaid - discountAmount);
    } else {
      stateCust.totalDue =
        Number(totalcommission) + Number(totaltax) - Number(totalpaid);
    }

    stateCust.currency = currency1;
    stateCust.invoiceStatus = statusoption;
    stateCust.invoiceDueDate =
      moment(duedate).format("YYYY-MM-DDThh:mm:ss") === "Invalid date"
        ? null
        : moment(duedate).format("YYYY-MM-DDThh:mm:ss");
    stateCust.invoiceCreatedDate =
      moment(invoiceDate).format("YYYY-MM-DDThh:mm:ss") === "Invalid date"
        ? null
        : moment(invoiceDate).format("YYYY-MM-DDThh:mm:ss");
    // let attachmentstate;
    // let fileToUpload = data2.attachment.item(0);
    // let formData = new FormData();
    // if (fileToUpload != null) {
    //   formData.append("invoiceAttachment", fileToUpload, fileToUpload.name);
    //   attachmentstate = true;
    // } else {
    //   attachmentstate = false;
    // }

    // let notes = data2.notes;
    // let amount = parseFloat(data2.totalPaid).toFixed(2);
    // let paymentMode = paymentoption;
    // let status = 1;

    let invoiceStatus = stateCust.invoiceStatus;
    stateCust.updatedOn = datenow;
    delete stateCust["invoiceType"];
    delete stateCust["invoiceStatus"];
    let payableinvoicestorage = [];
    let incomeSharedAmount = 0;

    if (
      incomesharing.length > 0 &&
      incomesharing[0]?.incomesharingcommissionamount > 0
    ) {
      for (let i = 0; i < incomesharing.length; i++) {
        let data5;
        if (incomesharing[i].incomeSharingemail) {
          data5 = {
            clientId: stateCust.clientId,
            agentUsername: incomesharing[i].incomeSharingemail,
            agentName: incomesharing[i].incomeSharingName,
            clientUsername: stateCust.clientUsername,
            commisionType: stateCust.commisionType,
            currency: stateCust.currency,
            enquiryId: stateCust.enquiryId,
            enquiryName: stateCust.enquiryName,
            externalId: stateCust.externalId,
            id: 0,
            incomeType: "Payable",
            institutionId: stateCust.institutionId,
            intake: stateCust.intake,
            invoiceCreatedBy: stateCust.invoiceCreatedBy,
            invoiceCreatedDate: stateCust.invoiceCreatedDate,
            invoiceDueDate: stateCust.invoiceDueDate,
            invoicePaidDate: stateCust.invoicePaidDate,
            invoiceSubType: stateCust.invoiceSubType,
            officeId: stateCust.officeId,
            serviceProvider: stateCust.serviceProvider,
            workflowType: stateCust.workflowType,
            updatedOn: stateCust.updatedOn,
            invoiceDetails: [
              {
                amount: Number(originalnetincomevalue),
                description: appendvalue,
                commissionPercentage: Number(
                  incomesharing[i].incomeSharingAmount
                ),
                commissionAmount: Number(
                  incomesharing[i].incomesharingcommissionamount
                ),
                id: 0,
                taxPercentage: Number(incomesharing[i].incomeSharingtaxpercent),
                taxAmount: Number(incomesharing[i].incomeSharingtaxamount),
              },
            ],
            netAmount: Number(originalnetincomevalue),
            totalTax: Number(incomesharing[i].incomeSharingtaxamount),
            totalCommission: Number(
              incomesharing[i].incomesharingcommissionamount
            ),
            totalAmount: Number(incomesharing[i].incomeSharingAmountOriginal),
            totalDue: Number(
              Number(incomesharing[i].incomesharingcommissionamount) +
              Number(incomesharing[i].incomeSharingtaxamount)
            ),
          };
          axios
            .post(`${base_url.api2}/createInvoice`, data5, {
              headers: {
                Authorization: jwtToken,
                invoiceStatus: "Active",
                invoiceType: "Payable",
              },
            })
            .then((res2) => {
              console.log(
                "coming in if for Create Invoice Api ----> Line no 1153"
              );
              let arr = {
                id: res2.data.data.id,
                agentUsername: res2.data.data.agentUsername,
              };
              payableinvoicestorage.push(arr);
              if (i === incomesharing.length - 1) {
                payableinvoicestorage.forEach((value) => {
                  incomesharing.forEach((val2) => {
                    if (val2.incomeSharingemail === value.agentUsername) {
                      val2.payableInvoiceId = value.id;
                    }
                  });
                });
                let incomeSharing = [];
                if (
                  incomesharing.length > 0 &&
                  incomesharing[0]?.incomesharingcommissionamount > 0
                ) {
                  for (let i = 0; i < incomesharing.length; i++) {
                    let obj = {
                      id: 0,
                      incomeShareHolderId: incomesharing[i].incomeShareHolderId,
                      incomeSharingAmount: Number(
                        incomesharing[i].incomeSharingAmountOriginal
                      ),
                      incomeSharingCommissionPercent:
                        incomesharing[i].incomeSharingAmount,
                      incomeSharingName: incomesharing[i].incomeSharingName,
                      sharingCommissionAmount: Number(
                        incomesharing[i].incomesharingcommissionamount
                      ),
                      sharingTaxPercentage: Number(
                        incomesharing[i].incomeSharingtaxpercent
                      ),
                      notes: incomesharing[i].note,
                      incomeSharingType: incomesharing[i].incomeSharingType,
                      sharingTaxAmount: incomesharing[i].incomeSharingtaxamount,
                      payableInvoiceId: incomesharing[i].payableInvoiceId,
                      agentIncomeSharingCommissionPercent:
                        incomesharing[i].incomesharingcommissionpercentage,
                      agentIncomeSharingReferalPercent:
                        incomesharing[i].incomesharingreferalpercentage,
                    };
                    incomeSharing.push(obj);
                    incomeSharedAmount += Number(
                      incomesharing[i].incomeSharingAmountOriginal
                    );
                  }
                  stateCust.incomeSharedAmount = incomeSharedAmount;
                  stateCust.incomeSharing = incomeSharing;
                }
                //console.log("coming here1");
                callinvoiceapi(
                  stateCust,
                  // amount,
                  // notes,
                  // paymentMode,
                  // status,
                  // formData,
                  // attachmentstate,
                  invoiceStatus
                );
              }
              if (IncomeSharePayableId.length > 0) {
                //console.log("coming here");
                axios.post(
                  `${base_url.api2}/deleteInvoiceById`,
                  IncomeSharePayableId,
                  {
                    headers: {
                      Authorization: jwtToken,
                    },
                  }
                );
              }
            })
            .catch((err) => {
              setbackdropopen(false);
              if (err.response) {
                toast.error(err?.response?.data?.message);
              } else {
                toast.error("Something Went Wrong");
              }
              handle403error(err);
            });
        } else {
          console.log("1269--->", incomesharing[i]);
          data5 = {
            updatedOn: datenow,
            id: incomesharing[i].payableInvoiceId,
            invoiceDetails: [
              {
                amount: Number(originalnetincomevalue),
                description: appendvalue,
                commissionPercentage: Number(
                  incomesharing[i].incomeSharingAmount
                ),
                commissionAmount: Number(
                  incomesharing[i].incomesharingcommissionamount
                ),
                id: 0,
                taxPercentage: Number(incomesharing[i].incomeSharingtaxpercent),
                taxAmount: Number(incomesharing[i].incomeSharingtaxamount),
              },
            ],
            netAmount: Number(originalnetincomevalue),
            totalTax: Number(incomesharing[i].incomeSharingtaxamount),
            totalCommission: Number(
              incomesharing[i].incomesharingcommissionamount
            ),
            totalAmount: Number(incomesharing[i].incomeSharingAmountOriginal),
            totalDue: Number(
              Number(incomesharing[i].incomesharingcommissionamount) +
              Number(incomesharing[i].incomeSharingtaxamount)
            ),
          };
          //console.log(data5);
          if (invoiceStatus === "Cancelled") {
            axios
              .post(`${base_url.api2}/updateInvoiceStatusByInvoiceId`, data5, {
                headers: {
                  Authorization: jwtToken,
                  invoiceStatus: "Cancelled",
                },
              })
              .then((res3) => {
                console.log(
                  "coming in else for updateInvoiceByInvoiceId ----> Line no 1263",
                  data5
                );
                if (i === incomesharing.length - 1) {
                  payableinvoicestorage.forEach((value) => {
                    incomesharing.forEach((val2) => {
                      if (val2.incomeSharingemail === value.agentUsername) {
                        val2.payableInvoiceId = value.id;
                      }
                    });
                  });

                  let incomeSharing = [];
                  if (
                    incomesharing.length > 0 &&
                    incomesharing[0]?.incomesharingcommissionamount > 0
                  ) {
                    for (let i = 0; i < incomesharing.length; i++) {
                      let obj = {
                        id: 0,
                        incomeShareHolderId: Number(
                          incomesharing[i].incomeShareHolderId
                        ),
                        incomeSharingAmount: Number(
                          incomesharing[i].incomeSharingAmountOriginal
                        ),
                        incomeSharingCommissionPercent: Number(
                          incomesharing[i].incomeSharingAmount
                        ),
                        incomeSharingName: incomesharing[i].incomeSharingName,
                        sharingCommissionAmount: Number(
                          incomesharing[i].incomesharingcommissionamount
                        ),
                        sharingTaxPercentage: Number(
                          incomesharing[i].incomeSharingtaxpercent
                        ),
                        sharingTaxAmount: Number(
                          incomesharing[i].incomeSharingtaxamount
                        ),
                        payableInvoiceId: Number(
                          incomesharing[i].payableInvoiceId
                        ),
                        notes: incomesharing[i].note,
                        incomeSharingType: incomesharing[i].incomeSharingType,
                        agentIncomeSharingCommissionPercent:
                          incomesharing[i].incomesharingcommissionpercentage,
                        agentIncomeSharingReferalPercent:
                          incomesharing[i].incomesharingreferalpercentage,
                      };
                      incomeSharedAmount += Number(
                        incomesharing[i].incomeSharingAmountOriginal
                      );
                      incomeSharing.push(obj);
                    }
                    stateCust.incomeSharedAmount = incomeSharedAmount;
                    stateCust.incomeSharing = incomeSharing;
                  }

                  //console.log("coming here2");
                  callinvoiceapi(
                    stateCust,
                    // amount,
                    // notes,
                    // paymentMode,
                    // status,
                    // formData,
                    // attachmentstate,
                    invoiceStatus
                  );
                }
              })
              .catch((err) => {
                setbackdropopen(false);
                if (err.response) {
                  toast.error(err?.response?.data?.message);
                } else {
                  toast.error("Something Went Wrong");
                }
                handle403error(err);
              });
          } else if (invoiceStatus === "Paid") {
            axios
              .post(`${base_url.api2}/updateInvoiceStatusByInvoiceId`, data5, {
                headers: {
                  Authorization: jwtToken,
                  invoiceStatus: "Active",
                },
              })
              .then((res3) => {
                console.log(
                  "coming in else for updateInvoiceByInvoiceId ----> Line no 1263",
                  data5
                );
                if (i === incomesharing.length - 1) {
                  payableinvoicestorage.forEach((value) => {
                    incomesharing.forEach((val2) => {
                      if (val2.incomeSharingemail === value.agentUsername) {
                        val2.payableInvoiceId = value.id;
                      }
                    });
                  });

                  let incomeSharing = [];
                  if (
                    incomesharing.length > 0 &&
                    incomesharing[0]?.incomesharingcommissionamount > 0
                  ) {
                    for (let i = 0; i < incomesharing.length; i++) {
                      let obj = {
                        id: 0,
                        incomeShareHolderId: Number(
                          incomesharing[i].incomeShareHolderId
                        ),
                        incomeSharingAmount: Number(
                          incomesharing[i].incomeSharingAmountOriginal
                        ),
                        incomeSharingCommissionPercent: Number(
                          incomesharing[i].incomeSharingAmount
                        ),
                        incomeSharingName: incomesharing[i].incomeSharingName,
                        sharingCommissionAmount: Number(
                          incomesharing[i].incomesharingcommissionamount
                        ),
                        sharingTaxPercentage: Number(
                          incomesharing[i].incomeSharingtaxpercent
                        ),
                        sharingTaxAmount: Number(
                          incomesharing[i].incomeSharingtaxamount
                        ),
                        payableInvoiceId: Number(
                          incomesharing[i].payableInvoiceId
                        ),
                        notes: incomesharing[i].note,
                        incomeSharingType: incomesharing[i].incomeSharingType,
                        agentIncomeSharingCommissionPercent:
                          incomesharing[i].incomesharingcommissionpercentage,
                        agentIncomeSharingReferalPercent:
                          incomesharing[i].incomesharingreferalpercentage,
                      };
                      incomeSharedAmount += Number(
                        incomesharing[i].incomeSharingAmountOriginal
                      );
                      incomeSharing.push(obj);
                    }
                    stateCust.incomeSharedAmount = incomeSharedAmount;
                    stateCust.incomeSharing = incomeSharing;
                  }

                  //console.log("coming here2");
                  callinvoiceapi(
                    stateCust,
                    // amount,
                    // notes,
                    // paymentMode,
                    // status,
                    // formData,
                    // attachmentstate,
                    invoiceStatus
                  );
                }
              })
              .catch((err) => {
                setbackdropopen(false);
                if (err.response) {
                  toast.error(err?.response?.data?.message);
                } else {
                  toast.error("Something Went Wrong");
                }
                handle403error(err);
              });
          } else {
            axios
              .post(`${base_url.api2}/updateInvoiceByInvoiceId`, data5, {
                headers: {
                  Authorization: jwtToken,
                  invoiceStatus: "Unpaid",
                },
              })
              .then((res3) => {
                console.log(
                  "coming in else for updateInvoiceByInvoiceId ----> Line no 1263"
                );
                if (i === incomesharing.length - 1) {
                  payableinvoicestorage.forEach((value) => {
                    incomesharing.forEach((val2) => {
                      if (val2.incomeSharingemail === value.agentUsername) {
                        val2.payableInvoiceId = value.id;
                      }
                    });
                  });

                  let incomeSharing = [];
                  if (
                    incomesharing.length > 0 &&
                    incomesharing[0]?.incomesharingcommissionamount > 0
                  ) {
                    for (let i = 0; i < incomesharing.length; i++) {
                      let obj = {
                        id: 0,
                        incomeShareHolderId: Number(
                          incomesharing[i].incomeShareHolderId
                        ),
                        incomeSharingAmount: Number(
                          incomesharing[i].incomeSharingAmountOriginal
                        ),
                        incomeSharingCommissionPercent: Number(
                          incomesharing[i].incomeSharingAmount
                        ),
                        incomeSharingName: incomesharing[i].incomeSharingName,
                        sharingCommissionAmount: Number(
                          incomesharing[i].incomesharingcommissionamount
                        ),
                        sharingTaxPercentage: Number(
                          incomesharing[i].incomeSharingtaxpercent
                        ),
                        sharingTaxAmount: Number(
                          incomesharing[i].incomeSharingtaxamount
                        ),
                        payableInvoiceId: Number(
                          incomesharing[i].payableInvoiceId
                        ),
                        notes: incomesharing[i].note,
                        incomeSharingType: incomesharing[i].incomeSharingType,
                        agentIncomeSharingCommissionPercent:
                          incomesharing[i].incomesharingcommissionpercentage,
                        agentIncomeSharingReferalPercent:
                          incomesharing[i].incomesharingreferalpercentage,
                      };
                      incomeSharedAmount += Number(
                        incomesharing[i].incomeSharingAmountOriginal
                      );
                      incomeSharing.push(obj);
                    }
                    stateCust.incomeSharedAmount = incomeSharedAmount;
                    stateCust.incomeSharing = incomeSharing;
                  }

                  //console.log("coming here2");
                  callinvoiceapi(
                    stateCust,
                    // amount,
                    // notes,
                    // paymentMode,
                    // status,
                    // formData,
                    // attachmentstate,
                    invoiceStatus
                  );
                }
              })
              .catch((err) => {
                setbackdropopen(false);
                if (err.response) {
                  toast.error(err?.response?.data?.message);
                } else {
                  toast.error("Something Went Wrong");
                }
                handle403error(err);
              });
          }
        }
      }
    } else {
      //console.log("coming here3");
      stateCust.incomeSharing = [];
      callinvoiceapi(
        stateCust,
        // amount,
        // notes,
        // paymentMode,
        // status,
        // formData,
        // attachmentstate,
        invoiceStatus
      );
    }
  };

  const callinvoiceapi = (
    stateCust,
    // amount,
    // notes,
    // paymentMode,
    // status,
    // formData,
    // attachmentstate,
    invoiceStatus
  ) => {
    //console.log(paymentReceivedData);
    //console.log(stateCust);
    //console.log(deletePayment);
    //console.log("coming here4");
    axios
      .post(`${base_url.api2}/updateInvoiceByInvoiceId`, stateCust, {
        headers: {
          Authorization: jwtToken,
          invoiceStatus: invoiceStatus,
        },
      })
      .then((res) => {
        //console.log("Delete Invoice Array", deleteinvoices);
        console.log(
          "coming  for update Api updateInvoiceByInvoiceId ----> Line no 1376"
        );
        if (deleteinvoices.length > 0) {
          axios.post(`${base_url.api2}/deleteInvoiceById`, deleteinvoices, {
            headers: {
              Authorization: jwtToken,
            },
          });
        }
        // if (deletePayment.length > 0) {
        //   axios.post(
        //     `${base_url.api2}/deleteInvoicePaymentById`,
        //     deletePayment,
        //     {
        //       headers: {
        //         Authorization: jwtToken,
        //       },
        //     }
        //   );
        // }

        // if (amount !== "NaN") {
        if (totalpaid !== "NaN") {
          toast.success("Success");
          let formData = new FormData();
          let arr_obj = [];
          let attachmentpresent = false;
          paymentReceivedData.forEach((ele, i) => {
            //console.log("File data for", i, " - ", ele["filedata"][0]);
            if (ele.filedata && Number(ele.amount) > 0) {
              attachmentpresent = true;
              formData.append(
                "invoiceAttachment",
                ele["filedata"][0],
                ele["filedata"][0].name
              );
            }
            if (Number(ele.amount) > 0) {
              let obj = {
                amount: ele.amount,
                attachmentName: ele.filedata
                  ? ele["filedata"][0].name
                  : ele.attachmentName,
                attachmentPath: ele.attachmentPath,
                date: ele.date
                  ? moment(ele.date).format("YYYY-MM-DDThh:mm:ss")
                  : datenow,
                id: ele.id,
                invoiceId: ele.invoiceId,
                notes: ele.notes,
                paymentMode: ele.paymentMode,
                status: 0,
              };
              arr_obj.push(obj);
            }
          });
          // if (attachmentpresent) {
          const json = JSON.stringify(arr_obj);
          const blob = new Blob([json], {
            type: "application/json",
          });
          formData.append("invoiceAttachmentData", blob);
          // }
          if (arr_obj.length > 0) {
            axios
              .post(
                `${base_url.api2}/uploadMultipleInvoiceAttachment`,
                formData,
                {
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "multipart/form-data",
                    Authorization: jwtToken,
                    clientId: Number(params.client_id),
                    invoiceId: Number(params.invoice_id),
                  },
                }
              )
              .then((res) => {
                if (res.data.status_code == 200) {
                  let log =
                    isw_user_name +
                    ` Created Invoice for ${selected_client_username}.`;
                  callsaveauditapi(log, dispatch, navigate);
                  goBack();
                } else {
                  toast.error(res.message);
                }
              })
              .catch((err) => {
                toast.error(JSON.stringify(err?.response?.data?.message));
                handle403error(err);
              });
          } else {
            axios
              .post(
                `${base_url.api2}/uploadMultipleInvoiceAttachment`,
                formData,
                {
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "multipart/form-data",
                    Authorization: jwtToken,
                    clientId: Number(params.client_id),
                    invoiceId: Number(params.invoice_id),
                  },
                }
              )
              .then((res) => {
                if (res.data.status_code == 200) {
                  let log =
                    isw_user_name +
                    ` Created Invoice for ${selected_client_username}.`;
                  callsaveauditapi(log, dispatch, navigate);
                  goBack();
                } else {
                  toast.error(res.message);
                }
              })
              .catch((err) => {
                toast.error(JSON.stringify(err?.response?.data?.message));
                handle403error(err);
              });
          }
        } else {
          toast.success("Success");
          goBack();
        }
      })
      .catch((err) => {
        handle403error(err);
        setbackdropopen(false);
        if (err.response) {
          toast.error(err?.response?.data?.message);
        } else {
          toast.error("Something Went Wrong");
        }
      });
  };

  // const updateInvoicePayment = (e, p) => {
  //   axios
  //     .post(`${base_url.api2}/updateInvoicePaymentById`, p, {
  //       headers: {
  //         Authorization: jwtToken,
  //       },
  //     })
  //     .then(
  //       (res) => {
  //         let data = {
  //           id: params.invoice_id,
  //           totalPaid: totalpaid,
  //         };
  //         axios
  //           .post(`${base_url.api2}/updateInvoiceByInvoiceId`, data, {
  //             headers: {
  //               Authorization: jwtToken,
  //               invoiceStatus: stateCust?.invoiceStatus,
  //             },
  //           })
  //           .then(
  //             (res) => {
  //               getPermission();
  //             },
  //             (error) => {
  //               toast.error(JSON.stringify(error.data.message));
  //             }
  //           );
  //       },
  //       (error) => {
  //         toast.error(JSON.stringify(error.data.message));
  //       }
  //     );
  // };
  // const uploadWithoutAttachment = (paymentReceivedData) => {
  //   axios
  //     .post(`${base_url.api2}/createInvoicePayment`, paymentReceivedData, {
  //       headers: {
  //         Authorization: jwtToken,
  //       },
  //     })
  //     .then((res) => {
  //       if (res.data.status_code == 200) {
  //         toast.success("Success");
  //         setbackdropopen(false);

  //         let createdByName = isw_user_name + " (" + isw_user_email + ")";
  //         let auditdata = {
  //           adminIp: ipaddress,
  //           createdById: isw_adminId,
  //           createdByName: createdByName,
  //           id: 0,
  //           log:
  //             isw_user_name +
  //             `Updated Invoice for ${selected_client_username}.`,
  //         };
  //         axios
  //           .post(`${base_url.api4}/saveAudit`, auditdata, {
  //             headers: {
  //               Authorization: jwtToken,
  //             },
  //           })
  //           .then((response) => {
  //             //console.log(response);
  //           })
  //           .catch((error) => {
  //             toast.error(JSON.stringify(error.response.data.message));
  //           });
  //         goBack();
  //       } else {
  //         toast.error(res.message);
  //       }
  //     })
  //     .catch((err) => {
  //       setbackdropopen(false);

  //       toast.error(JSON.stringify(err?.response?.data?.message));
  //     });
  // };

  // const uploadAttachment = (
  //   id,
  //   amount,
  //   notes,
  //   paymentMode,
  //   status,
  //   formData
  // ) => {
  //   axios
  //     .post(`${base_url.api2}/uploadInvoiceAttachment`, formData, {
  //       headers: {
  //         Authorization: jwtToken,
  //         invoiceId: Number(id),
  //         amount: amount,
  //         notes: notes,
  //         paymentMode: paymentMode,
  //         status: status,
  //       },
  //     })
  //     .then((res) => {
  //       if (res.data.status_code == 200) {
  //         toast.success("Success");
  //         setbackdropopen(false);

  //         let createdByName = isw_user_name + " (" + isw_user_email + ")";
  //         let auditdata = {
  //           adminIp: ipaddress,
  //           createdById: isw_adminId,
  //           createdByName: createdByName,
  //           id: 0,
  //           log:
  //             isw_user_name +
  //             `Updated Invoice for ${selected_client_username}.`,
  //         };
  //         axios
  //           .post(`${base_url.api4}/saveAudit`, auditdata, {
  //             headers: {
  //               Authorization: jwtToken,
  //             },
  //           })
  //           .then((response) => {
  //             //console.log(response);
  //           })
  //           .catch((error) => {
  //             toast.error(JSON.stringify(error.response.data.message));
  //           });
  //         // goBack();
  //       } else {
  //         setbackdropopen(false);

  //         toast.error(res.message);
  //       }
  //     })
  //     .catch((err) => {
  //       toast.error(JSON.stringify(err?.response?.data?.message));
  //     });
  // };
  const goBack = () => {
    navigate(-1);
  };
  const handleInvoiceList = () => {
    dispatch(gettabvalue(4));
    navigate(
      "/view-enquiry-workflowList/" + params.client_id + "/" + userEmail
    );
  };

  const downloadAll = (applicationFormPath) => {
    setbackdropopen(true);
    let file_name =
      applicationFormPath.attachmentPath.split("amazonaws.com/")[1];
    //console.log(file_name);
    const header = {
      method: "GET",
      headers: {
        Authorization: jwtToken,
        fileName: `${params?.client_id}/${file_name}`,
      },
    };

    var url = `${base_url.api2}/downloadInvoicePaymentByFileName`;
    fetch(url, header)
      .then((response) => readAllChunks(response))
      .then(async function (response) {
        //console.log(response);
        const downloadFileType = localStorage.getItem(
          "invoice_attachment_file_content_type"
        );
        //console.log(downloadFileType);
        var blob = new Blob(response, { type: downloadFileType });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = file_name;
        link.click();
        setbackdropopen(false);
      })
      .catch((error) => {
        setbackdropopen(false);
        //console.log(error);
        handle403error(error);
      });
  };

  const readAllChunks = (readableStream) => {
    localStorage.setItem(
      "invoice_attachment_file_content_type",
      readableStream.headers.get("content-type")
    );
    const reader = readableStream.body.getReader();
    const chunks = [];

    function pump() {
      return reader
        .read()
        .then(({ value, done }) => {
          if (done) {
            return chunks;
          }
          chunks.push(value);
          return pump();
        })
        .catch((err) => {
          handle403error(err);
        });
    }
    return pump();
  };

  const [enquiryIntake, setEnquiryIntake] = useState();
  const [intakeList, setIntakeList] = useState();
  const getIntake = () => {
    axios
      .get(`${base_url.api1}/getAllIntake`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        let intakeData = res.data.data;
        let intakeObj = [];
        for (let i = 0; i < intakeData.length; i++) {
          let data = {
            createdIntake: moment(intakeData[i].createdIntake).format(
              "YYYY-MM"
            ),
            val: moment(intakeData[i].name).format("YYYYMM"),
            id: intakeData[i].id,
            name: intakeData[i].name,
            status: intakeData[i].status,
          };
          intakeObj.push(data);
        }
        let newintakeObj = intakeObj.sort(function (a, b) {
          return b.val - a.val;
        });
        setIntakeList(newintakeObj);
      })
      .catch((error) => {
        // toast.error(JSON.stringify(error.response.data.message));
        //console.log(JSON.stringify(error.response));
        handle403error(error);
      });
  };

  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            navigate("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };

  const [getParentInvoice, setParentInvoice] = useState();

  // const getRefNumber = () => {
  //   axios
  //     .get(`${base_url.api2}/getParentInvoiceIdByPayableId`, {
  //       headers: {
  //         Authorization: jwtToken,
  //         payableId: params.invoice_id,
  //       },
  //     })
  //     .then((res) => {
  //       //console.log(res.data.data);
  //       setParentInvoice(res.data.data.invoiceId);
  //     })
  //     .catch((error) => {
  //       handle403error(error);
  //     });
  // };

  const gettotaldue = () => {
    let number = 0;
    if (commision === "General Invoice") {
      number = Number(totalamttax - totalpaid - Number(discountAmount)).toFixed(
        2
      );
    } else if (commision === "commissionInvoiceNet") {
      number = Number(totalamt - totalpaid - Number(discountAmount)).toFixed(2);
    } else {
      number = Number(totalcommission + totaltax - totalpaid).toFixed(2);
    }
    return number;
  };

  return (
    <>
      <div
        class="page-head d-flex justify-content-between p-4"
        style={{ marginTop: "4.4rem" }}
      >
        <ul class="breadcrums">
          <h3 class="page-title bold"> Edit Client Invoice</h3>
          <Link to="/" class="b-link">
            Home
          </Link>
          -<a class="b-link">Client</a>-<a class="b-link active">Invoice</a>
        </ul>
        {checkrolepermission(1, 3) && userEmail ? (
          <Button
            style={{ margin: "5px 0px", height: "40px" }}
            onClick={handleInvoiceList}
            className="btn listing_addbutton"
          >
            Invoice List
          </Button>
        ) : null}
      </div>
      <div style={{ padding: "1.5rem", border: "1.5rem solid #e9ebf2" }}>
        {/* <div className="commoncomponent_cardcss addinvoice"> */}
        <Backdrop className={classes.backdrop} open={backdropopen}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="addinvoice_header">
            {/* <Typography
              variant="h5"
              color="primary"
              style={{ marginLeft: "9px" }}
            >
              Edit Client Invoice
            </Typography> */}
            {/* <div className="addinvoice_headerbutton">
              <button
                style={{ margin: "5px" }}
                className="btn listing_addbutton"
                type="submit"
              >
                Save
              </button>

              <Button
                style={{ margin: "5px" }}
                className="btn listing_addbutton"
                onClick={goBack}
              >
                 Invoice List
              </Button>
            </div> */}
          </div>

          <div class="row" style={{ marginBottom: "50px" }}>
            <div class="col-md-4">
              <div class="info-group">
                <label>Name:</label>
                <span>
                  {/* {stateCust?.invoiceType === "Payable"
                    ? stateCust?.agentName
                    : stateCust?.clientUsername} */}
                  {stateCust?.invoiceType === "Payable"
                    ? stateCust?.agentName
                    : clientDetail1?.firstName +
                    " " +
                    clientDetail1?.middleName +
                    " " +
                    clientDetail1?.lastName}
                </span>
              </div>
              <div class="info-group">
                <label>DOB:</label>
                {clientDetail1?.clientDetail.dob !== null ? (
                  <span>
                    {moment(clientDetail1?.clientDetail.dob).format(
                      "DD-MM-YYYY"
                    )}
                  </span>
                ) : (
                  "-"
                )}
              </div>
              <div class="info-group">
                <label>Consultant:</label>
                <span>{clientDetail1?.consultantName}</span>
              </div>
              <div class="info-group">
                <label>Provider:</label>
                <span>{stateCust?.serviceProvider}</span>
              </div>
              <div class="info-group">
                <label>Student Id:</label>
                {stateCust?.externalid !== "undefined" ? (
                  <span>{stateCust?.externalId}</span>
                ) : null}
              </div>
            </div>
            <div class="col-md-4"></div>
            <div class="col-md-4">
              <div class="info-group justify-content-end">
                {stateCust?.invoiceStatus === "Paid" ? (
                  <h5>{stateCust?.invoiceStatus}</h5>
                ) : (
                  <h5>{stateCust?.invoiceStatus}</h5>
                )}
              </div>
              <form>
                {stateCust?.incomeType === "Payable" ? (
                  <>
                    <div class="info-group flex-wrap">
                      <label class="form-label">Bill No</label>
                      <input
                        className="form-control underline-input"
                        type="text"
                        value={params.invoice_id}
                        readOnly
                      />
                    </div>
                    <div class="info-group flex-wrap">
                      <label class="form-label">Invoice No</label>
                      <input
                        className="form-control underline-input"
                        type="text"
                        value={getParentInvoice}
                        readOnly
                      />
                    </div>
                  </>
                ) : (
                  <div class="info-group flex-wrap">
                    <label class="form-label">Invoice No</label>
                    <input
                      className="form-control underline-input"
                      type="text"
                      value={params.invoice_id}
                      readOnly
                    />
                  </div>
                )}
                <div class="info-group flex-wrap">
                  <label class="form-label">Invoice Date</label>
                  <form
                    id="myForm"
                    className="d-flex"
                    style={{ width: "100%", position: "relative" }}
                  >
                    <DatePicker
                      className="form-control underline-input"
                      selected={invoiceDate ? new Date(invoiceDate) : null}
                      onChange={(date) => setInvoiceDate(date)}
                      dateFormat="dd-MM-yyyy"
                      showYearDropdown
                      dropdownMode="select"
                      placeholderText="Select Date"
                      style={{ position: "relative" }}
                    // readOnly
                    />

                    <span
                      className="listpagesearch_button"
                      style={{
                        top: "10px",
                        position: "absolute",
                        right: "10px",
                        pointerEvents: "none",
                      }}
                    >
                      <i className="fa fa-calendar"></i>
                    </span>
                  </form>
                </div>
                <div class="info-group flex-wrap">
                  <label class="form-label">Invoice Due Date</label>
                  <form
                    id="myForm"
                    className="d-flex"
                    style={{ width: "100%", position: "relative" }}
                  >
                    <DatePicker
                      className="form-control underline-input"
                      selected={duedate ? new Date(duedate) : null}
                      onChange={(date) => setduedate(date)}
                      dateFormat="dd-MM-yyyy"
                      showYearDropdown
                      dropdownMode="select"
                      placeholderText="Select Date"
                      style={{ position: "relative" }}
                      required
                    />
                    <span
                      className="listpagesearch_button"
                      style={{
                        top: "10px",
                        position: "absolute",
                        right: "10px",
                        pointerEvents: "none",
                      }}
                    >
                      <i className="fa fa-calendar"></i>
                    </span>
                  </form>
                </div>
                <div class="info-group flex-wrap">
                  <label class="form-label">Currency</label>
                  <input
                    className="form-control underline-input"
                    type="text"
                    value={currency1}
                    readOnly
                  />
                </div>
                <div class="info-group flex-wrap">
                  <label class="form-label">Created By</label>
                  <input
                    className="form-control underline-input"
                    type="text"
                    value={
                      userDetails?.firstName != null &&
                        userDetails?.lastName != null
                        ? userDetails?.firstName + " " + userDetails?.lastName
                        : null
                    }
                    readOnly
                  />
                </div>
              </form>
            </div>
          </div>

          <hr />
          <div className="row viewinvoice_row">
            <div className="col-md-4">
              <div className="row">
                <div className="col-md-4">A/C Type</div>
                <div className="col-md-8">
                  <input
                    value={stateCust?.incomeType}
                    className="form-control underline-input"
                    readOnly
                    style={{ width: "240px" }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">Invoice Type</div>
                <div className="col-md-8">
                  <input
                    value={stateCust?.commisionType}
                    className="form-control underline-input"
                    readOnly
                    style={{ width: "240px" }}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row">
                <div className="col-md-4">Income SubType</div>
                <div className="col-md-8">
                  <input
                    value={
                      stateCust?.invoiceSubType === "null"
                        ? ""
                        : stateCust?.invoiceSubType
                    }
                    className="form-control underline-input"
                    readOnly
                    style={{ width: "240px" }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">Intake</div>
                <div className="col-md-8">
                  {/* <select
                    className={classes.selectdrop}
                    name="intake"
                    ref={register({ required: false })}
                    style={{
                      width: "240px",
                      backgroundColor: "#f5f5f9",
                      border: "none",
                    }}
                    onInput={(e) => {
                      stateCust.intake = e.target.value;
                      setEnquiryIntake(e.target.value);
                    }}
                    value={stateCust?.intake}
                  >
                    {intakeList?.map((cuntitem) => (
                      <option
                        key={cuntitem.id}
                        value={cuntitem.name}
                        id={cuntitem.id}
                      >
                        {cuntitem.createdIntake}
                      </option>
                    ))}
                  </select> */}
                  <DatePicker
                    selected={enquiryIntake ? new Date(enquiryIntake) : null}
                    onChange={(date) => {
                      setEnquiryIntake(date);
                      stateCust.intake = moment(date).format('MMM-yyyy');
                    }}
                    dateFormat="MMM-yyyy"
                    showMonthYearPicker
                    dropdownMode="select"
                    placeholderText="Select Date"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row">
                <div className="col-md-4">Enquiry Name</div>
                <div className="col-md-8">
                  <input
                    value={
                      stateCust?.invoiceSubType
                        ? stateCust?.courseName
                        : stateCust?.serviceProvider
                          ? stateCust?.serviceProvider
                          : ""
                    }
                    className="form-control"
                    readOnly
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">Workflow Type</div>
                <div className="col-md-8">
                  <input
                    type="text"
                    value={stateCust?.workflowType}
                    className="form-control underline-input"
                    readOnly
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="invoice_adddetails quotation_table">
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead style={{ backgroundColor: "cornflowerblue" }}>
                  <TableRow>
                    <TableCell align="center">SN</TableCell>
                    <TableCell style={{ width: "25rem" }}>
                      Description
                    </TableCell>
                    <TableCell>Amount</TableCell>
                    {commision !== "General Invoice" ? (
                      <>
                        <TableCell align="right">Commission(%)</TableCell>
                        <TableCell style={{ padding: "0" }}>
                          Commission Amount
                        </TableCell>
                      </>
                    ) : null}
                    <TableCell>Tax(%)</TableCell>
                    <TableCell>Tax Amount</TableCell>
                    {commision === "General Invoice" ? (
                      <TableCell align="right">Total Amount</TableCell>
                    ) : null}
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="clientgeneralinvoicetables">
                  {newline?.map((p, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row" align="center">
                        {index + 1}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <input
                          placeholder="Enter Description.."
                          value={p.description}
                          className="form-control underline-input"
                          name="description"
                          style={{ backgroundColor: "#f5f5f9" }}
                          onChange={(e) => handlelinechange(e, index)}
                          type="text"
                          required
                        />
                      </TableCell>
                      <TableCell align="right">
                        <input
                          placeholder="Enter Amount.."
                          value={p.amount}
                          name="amount"
                          style={{ backgroundColor: "#f5f5f9" }}
                          className="form-control underline-input inputalign"
                          type="number"
                          onWheel={(event) => event.currentTarget.blur()}
                          onChange={(e) => handleamountChange(e, index)}
                          required
                        />
                      </TableCell>
                      {commision !== "General Invoice" ? (
                        <>
                          <TableCell align="right">
                            <input
                              placeholder="Enter Commission%.."
                              value={p.commissionPercentage}
                              name="commissionPercentage"
                              style={{ backgroundColor: "#f5f5f9" }}
                              className="form-control underline-input inputalign"
                              type="number"
                              onWheel={(event) => event.currentTarget.blur()}
                              onChange={(e) => handlecommisionChange(e, index)}
                              required
                            />
                          </TableCell>
                          <TableCell align="right">
                            <input
                              value={Number(p.commissionAmount).toFixed(2)}
                              name="commissionAmount"
                              style={{ backgroundColor: "#f5f5f9" }}
                              className="form-control underline-input inputalign"
                              type="number"
                              onWheel={(event) => event.currentTarget.blur()}
                              readOnly
                            />
                          </TableCell>
                        </>
                      ) : null}
                      <TableCell align="right">
                        <input
                          placeholder="Enter Tax%.."
                          style={{ backgroundColor: "#f5f5f9" }}
                          value={p.taxPercentage}
                          name="taxPercentage"
                          type="number"
                          onWheel={(event) => event.currentTarget.blur()}
                          onChange={(e) => handletaxpercentchange(e, index)}
                          className="form-control underline-input inputalign"
                          required
                        />
                      </TableCell>
                      <TableCell align="right">
                        <input
                          value={Number(p.taxAmount).toFixed(2)}
                          style={{ backgroundColor: "#f5f5f9" }}
                          className="form-control underline-input inputalign"
                          name="taxAmount"
                          type="number"
                          onWheel={(event) => event.currentTarget.blur()}
                          readOnly
                        />
                      </TableCell>
                      {commision === "General Invoice" ? (
                        <TableCell align="right">
                          <input
                            value={Number(p.totalAmount).toFixed(2)}
                            style={{ backgroundColor: "#f5f5f9" }}
                            className="form-control underline-input inputalign"
                            name="totalAmount"
                            type="number"
                            onWheel={(event) => event.currentTarget.blur()}
                            readOnly
                          />
                        </TableCell>
                      ) : null}

                      <TableCell
                        align="right"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          var lines = [...newline];
                          let amount = [...amountvalues];
                          let taxamount = [...taxamountvalues];
                          let totalamount = [...totalamountvalues];
                          let commissionamount = [...commissionamountvalues];
                          lines.splice(index, 1);
                          amount.splice(index, 1);
                          taxamount.splice(index, 1);
                          totalamount.splice(index, 1);
                          commissionamount.splice(index, 1);
                          setamountvalues(amount);
                          settaxamountvalues(taxamount);
                          settotalamountvalues(totalamount);
                          setcommissionamountvalues(commissionamount);
                          setnewline(lines);
                          let discountamount = 0;
                          let totaldiscountamount = 0;
                          if (discountpercent === "Percentage") {
                            if (commision !== "General Invoice") {
                              totaldiscountamount = Number(
                                amount?.reduce(
                                  (amount, item) => item + amount,
                                  0
                                )
                              );
                              discountamount =
                                (Number(totaldiscountamount) *
                                  Number(discountpercentamount)) /
                                100;
                            }
                          } else {
                            if (commision !== "General Invoice") {
                              discountamount = Number(discountAmount);
                            }
                          }
                          setdiscountAmount(discountamount);
                          settotalamt(
                            amount?.reduce((amount, item) => item + amount, 0)
                          );
                          settotaltax(
                            taxamount?.reduce(
                              (amount, item) => item + amount,
                              0
                            )
                          );
                          settotalcommission(
                            commissionamount?.reduce(
                              (amount, item) => item + amount,
                              0
                            )
                          );
                          settotalamttax(
                            totalamount?.reduce(
                              (amount, item) => item + amount,
                              0
                            )
                          );
                          if (commision === "General Invoice") {
                            setoriginalnetincomevalue(
                              amount?.reduce((amount, item) => item + amount, 0)
                            );
                            if (
                              incomesharing.length > 0 &&
                              incomesharing[0].incomesharingcommissionamount > 0
                            ) {
                              changeincomesharingdata(
                                amount?.reduce(
                                  (amount, item) => item + amount,
                                  0
                                )
                              );
                            } else {
                              setnetincomevalue(
                                amount?.reduce(
                                  (amount, item) => item + amount,
                                  0
                                )
                              );
                            }
                          } else {
                            setoriginalnetincomevalue(
                              commissionamount?.reduce(
                                (amount, item) => item + amount,
                                0
                              ) - discountamount
                            );
                            if (
                              incomesharing.length > 0 &&
                              incomesharing[0].incomesharingcommissionamount > 0
                            ) {
                              changeincomesharingdata(
                                commissionamount?.reduce(
                                  (amount, item) => item + amount,
                                  0
                                ) - discountamount
                              );
                            } else {
                              setnetincomevalue(
                                commissionamount?.reduce(
                                  (amount, item) => item + amount,
                                  0
                                ) - discountamount
                              );
                            }
                          }
                        }}
                      >
                        <span
                          style={{
                            background: "red",
                            padding: "0 3px",
                            fontSize: "19px",
                            borderRadius: " 3px",
                            color: "#fff",
                          }}
                        >
                          x
                        </span>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Button
              // color="primary"
              className="sent-button"
              style={{
                // cursor: "pointer",
                marginTop: "20px",
                // width: "fit-content",
                // border: "1px solid",
                // padding: "5px",
                // borderRadius: "7px",
                float: "left",
              }}
              onClick={() => {
                addClick();
                setnewline((prev) => [
                  ...prev,

                  {
                    id: 0,
                    quantity: 1,
                    description: "",
                    amount: "",
                    commissionAmount: "",
                    commissionPercentage: "",
                    taxPercentage: "",
                    taxAmount: "",
                    totalAmount: "",
                  },
                ]);
              }}
            >
              {/* ➕  */}
              Add Row
            </Button>
          </div>
          <div
            className="addinvoice_revenuedetails"
            style={{ marginTop: "74px" }}
          >
            <div>
              {stateCust?.invoiceType === "Payable" ? null : (
                <>
                  {commision !== "General Invoice" ? (
                    <div style={{ display: "flex" }}>
                      <div style={{ marginLeft: "15px" }}>
                        <label
                          style={{ fontWeight: "bold", marginRight: "50px" }}
                        >
                          Discount Type
                        </label>
                        <br />
                        <input
                          type="radio"
                          name="discount"
                          id="fixed"
                          value="Fixed"
                          onChange={(e) => handleDiscountfixed(e)}
                          checked={discountpercent === "Fixed"}
                        />
                        <label for="phone" style={{ marginRight: "10px" }}>
                          Fixed
                        </label>
                        <input
                          type="radio"
                          name="discount"
                          id="percentage"
                          onChange={(e) => handleDiscountfixed(e)}
                          value="Percentage"
                          checked={discountpercent === "Percentage"}
                        />
                        <label for="email">Percentage</label> <br />
                      </div>
                      <input
                        ref={register({ required: true })}
                        value={discountpercentamount}
                        type="number"
                        onWheel={(event) => event.currentTarget.blur()}
                        style={{
                          width: "200px",
                          marginLeft: "15px",
                          backgroundColor: "#f5f5f9",
                        }}
                        placeholder="Enter Discount Amount.."
                        name="discountfixedamount"
                        className="form-control inputalign"
                        onChange={(e) => handleDiscountpercent(e)}
                        required
                      />
                    </div>
                  ) : null}
                </>
              )}

              <div className="addinvoice_revenuedetailsleft">
                <div
                  className="invoice_detailsbelowleftbelow"
                  style={{ width: "318px", height: "127px" }}
                >
                  <Typography>Total Paid</Typography>
                  <Typography
                    className="netrevenue"
                    variant="h3"
                    style={{ marginRight: "10px", marginTop: "15px" }}
                  >
                    {totalpaid}
                  </Typography>
                </div>
                {stateCust?.invoiceType === "Payable" ? null : (
                  <div
                    className="invoice_detailsbelowleftbelow"
                    style={{ width: "318px", height: "127px" }}
                  >
                    <Typography>Net Income</Typography>
                    {commision === "General Invoice" ? (
                      <Typography
                        className="netrevenue"
                        variant="h3"
                        color="primary"
                        style={{ marginRight: "10px", marginTop: "15px" }}
                      >
                        {Number(netincomevalue)?.toFixed(2)}
                      </Typography>
                    ) : (
                      <Typography
                        className="netrevenue"
                        variant="h3"
                        color="primary"
                      >
                        {Number(netincomevalue)?.toFixed(2)}
                      </Typography>
                    )}
                  </div>
                )}
              </div>
              <label>Remark:</label>
              <div class="info-group" style={{ width: "95%" }}>
                <textarea
                  className="form-control"
                  value={stateCust?.comment}
                  ref={register}
                  onChange={inputEvent}
                  style={{ backgroundColor: "#f5f5f9" }}
                  name="comment"
                  rows="5"
                />
              </div>
            </div>
            <div
              className="addinvoice_revenuedetailsright"
              style={{ marginBottom: "229px" }}
            >
              {stateCust?.invoiceType === "Payable" ||
                commision === "commissionInvoiceGross" ? null : (
                <div className="addinvoice_revenuedetailright">
                  <Typography
                    variant="body2"
                    className="addinvoice_revenuedetailrightfirst"
                  >
                    Total Amount:
                  </Typography>
                  <div className="addinvoice_detailinput">
                    <input
                      name="total_amount"
                      style={{ backgroundColor: "#f5f5f9" }}
                      value={Number(totalamt)?.toFixed(2)}
                      className="form-control underline-input inputalign inputalign"
                      readOnly
                    />
                  </div>
                </div>
              )}

              {commision !== "General Invoice" ? (
                <div className="addinvoice_revenuedetailright">
                  <Typography
                    variant="body2"
                    className="addinvoice_revenuedetailrightfirst"
                  >
                    Commission:
                  </Typography>
                  <div className="addinvoice_detailinput">
                    <input
                      name="total_commission"
                      style={{ backgroundColor: "#f5f5f9" }}
                      value={Number(totalcommission)?.toFixed(2)}
                      className="form-control underline-input inputalign"
                      readOnly
                    />
                  </div>
                </div>
              ) : null}
              <div className="addinvoice_revenuedetailright">
                <Typography
                  variant="body2"
                  className="addinvoice_revenuedetailrightfirst"
                >
                  Tax:
                </Typography>
                <div className="addinvoice_detailinput">
                  <input
                    name="total_tax"
                    style={{ backgroundColor: "#f5f5f9" }}
                    value={Number(totaltax)?.toFixed(2)}
                    className="form-control underline-input inputalign"
                    readOnly
                  />
                </div>
              </div>

              {stateCust?.invoiceType === "Payable" ? null : (
                <>
                  {commision === "commissionInvoiceNet" ? (
                    <div className="addinvoice_revenuedetailright">
                      <Typography
                        variant="body2"
                        className="addinvoice_revenuedetailrightfirst"
                      >
                        Discount:
                      </Typography>
                      <div className="addinvoice_detailinput">
                        <input
                          name="total_tax"
                          style={{ backgroundColor: "#f5f5f9" }}
                          value={Number(discountAmount)?.toFixed(2)}
                          className="form-control underline-input inputalign"
                          readOnly
                        />
                      </div>
                    </div>
                  ) : null}
                </>
              )}
              {stateCust?.invoiceType === "Payable" ? null : (
                <>
                  {commision === "General Invoice" ? (
                    <div className="addinvoice_revenuedetailright">
                      <Typography
                        variant="body2"
                        className="addinvoice_revenuedetailrightfirst"
                      >
                        Total amount (Inc Tax):
                      </Typography>
                      <div className="addinvoice_detailinput">
                        <input
                          name="total_amountincltax"
                          style={{ backgroundColor: "#f5f5f9" }}
                          value={Number(totalamttax)?.toFixed(2)}
                          className="form-control underline-input inputalign"
                          readOnly
                        />
                      </div>
                    </div>
                  ) : (
                    <>
                      {commision === "commissionInvoiceNet" ? (
                        <div className="addinvoice_revenuedetailright">
                          <Typography
                            variant="body2"
                            className="addinvoice_revenuedetailrightfirst"
                          >
                            Total amount paid to provider:
                          </Typography>
                          <div className="addinvoice_detailinput">
                            <input
                              name="total_amountincltax"
                              style={{ backgroundColor: "#f5f5f9" }}
                              value={Number(
                                totalamt - totalcommission - totaltax
                              )?.toFixed(2)}
                              className="form-control underline-input inputalign"
                              readOnly
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="addinvoice_revenuedetailright">
                          <Typography
                            variant="body2"
                            className="addinvoice_revenuedetailrightfirst"
                          >
                            Total amount:
                          </Typography>
                          <div className="addinvoice_detailinput">
                            <input
                              name="total_amountincltax"
                              style={{ backgroundColor: "#f5f5f9" }}
                              value={Number(
                                totalcommission + totaltax
                              )?.toFixed(2)}
                              className="form-control underline-input inputalign"
                              readOnly
                            />
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </>
              )}

              <div className="addinvoice_revenuedetailright">
                <Typography
                  variant="body2"
                  className="addinvoice_revenuedetailrightfirst"
                >
                  Total Due:
                </Typography>
                <div className="addinvoice_detailinput">
                  {stateCust?.invoiceType === "Payable" ? (
                    <input
                      name="total_due"
                      style={{ backgroundColor: "#f5f5f9" }}
                      value={Number(
                        totalcommission + totaltax - totalpaid
                      )?.toFixed(2)}
                      className="form-control underline-input inputalign"
                      readOnly
                    />
                  ) : (
                    <>
                      {commision === "General Invoice" ? (
                        <input
                          name="total_due"
                          style={{ backgroundColor: "#f5f5f9" }}
                          value={(
                            Number(totalamttax) -
                            Number(totalpaid) -
                            Number(discountAmount)
                          )?.toFixed(2)}
                          className="form-control underline-input inputalign"
                          readOnly
                        />
                      ) : (
                        <>
                          {commision === "commissionInvoiceNet" ? (
                            <input
                              name="total_due"
                              style={{ backgroundColor: "#f5f5f9" }}
                              value={Number(
                                totalamt - totalpaid - Number(discountAmount)
                              )?.toFixed(2)}
                              className="form-control underline-input inputalign"
                              readOnly
                            />
                          ) : (
                            <input
                              name="total_due"
                              style={{ backgroundColor: "#f5f5f9" }}
                              value={Number(
                                totalcommission + totaltax - totalpaid
                              ).toFixed(2)}
                              className="form-control underline-input inputalign"
                              readOnly
                            />
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <hr className="hrmargin" />
          <div style={{ display: "flex", padding: "5px" }}>
            <div style={{ flex: "1", margin: "5px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginRight: "20px",
                }}
              >
                <Typography
                  color="primary"
                  variant="body1"
                  style={{ fontWeight: "bolder" }}
                >
                  Payment Received
                </Typography>
              </div>
              <TableContainer component={Paper} className="quotation_table">
                <Table className={classes.table} aria-label="simple table">
                  <TableHead style={{ backgroundColor: "cornflowerblue" }}>
                    <TableRow>
                      <TableCell>SN</TableCell>
                      <TableCell>Amount</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Notes</TableCell>
                      <TableCell>Attachment</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paymentReceivedData?.map((p, index) => (
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>
                          {/* {p.amount} */}
                          <input
                            placeholder="Enter Amount.."
                            value={p.amount}
                            style={{ backgroundColor: "#f5f5f9" }}
                            className="form-control underline-input"
                            name="amount"
                            onChange={(e) => {
                              handleNewPaymentReceivedAmount(e, index);
                            }}
                            type="number"
                          />
                        </TableCell>
                        <TableCell>
                          <DatePicker
                            selected={p.date ? new Date(p.date) : null}
                            onChange={(date) =>
                              handlePaymentReceiveDate(date, index)
                            }
                            style={{ backgroundColor: "#f5f5f9" }}
                            dateFormat="dd-MM-yyyy"
                            showYearDropdown
                            dropdownMode="select"
                            placeholderText="Select Date"
                          />
                        </TableCell>
                        <TableCell>
                          <Select
                            id="demo-simple-select2"
                            value={p.paymentMode}
                            onChange={(e) =>
                              handlePaymentReceivedMode(e, index)
                            }
                          >
                            <MenuItem value="Cheque">Cheque</MenuItem>
                            <MenuItem value="Cash">Cash</MenuItem>
                            <MenuItem value="EFT">EFT</MenuItem>
                          </Select>
                        </TableCell>
                        <TableCell>
                          {/* {p.notes} */}
                          <textarea
                            name="notes"
                            placeholder="Enter Note.."
                            style={{ backgroundColor: "#f5f5f9" }}
                            value={p.notes}
                            onChange={(e) =>
                              handlePaymentReceivedNotes(e, index)
                            }
                            className="form-control"
                            rows={3}
                          />

                          {/* <input
                          placeholder="Enter Description.."
                          value={p.notes}
                          className="form-control underline-input"
                          name="notes"
                          onChange={(e) => handlePaymentReceivedNotes(e, index)}
                          type="text"
                          // required
                        /> */}
                        </TableCell>
                        <TableCell>
                          <input
                            type="file"
                            style={{ backgroundColor: "#f5f5f9" }}
                            name="attachment"
                            onChange={(e) =>
                              handlePaymentReceiveAttachment(e, index)
                            }
                          />
                          <br />
                          {p.attachmentName && (
                            <a
                              onClick={() => {
                                downloadAll(p);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              Download
                            </a>
                          )}
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{ cursor: "pointer", width: "50px" }}
                          onClick={() => {
                            var lines = [...paymentReceivedData];
                            let amount = [...totalpaidvalues];
                            lines.splice(index, 1);
                            amount.splice(index, 1);
                            // if (p.id > 0) {
                            //   deletePayment.push(p.id);
                            // }
                            setTotalPaidValues(amount);
                            setPaymentReceivedData(lines);
                            settotalpaid(
                              amount?.reduce(
                                (amount, item) => Number(item) + Number(amount),
                                0
                              )
                            );
                          }}
                        >
                          <span
                            style={{
                              background: "red",
                              padding: "0 3px",
                              fontSize: "19px",
                              borderRadius: " 3px",
                              color: "#fff",
                              marginRight: "10px",
                            }}
                          >
                            x
                          </span>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Button
                // color="primary"
                className="sent-button"
                style={{
                  // cursor: "pointer",
                  marginTop: "20px",
                  // width: "fit-content",
                  // border: "1px solid",
                  // padding: "5px",
                  // borderRadius: "7px",
                  float: "left",
                }}
                onClick={() => {
                  setPaymentReceivedData((prev) => [
                    ...prev,
                    {
                      id: 0,
                      amount: "",
                      date: datenow,
                      invoiceId: params.invoice_id,
                      notes: "",
                      paymentMode: "EFT",
                      status: 1,
                      filedata: null,
                      attachmentName: "",
                      attachmentPath: "",
                    },
                  ]);
                }}
              >
                Add Row
              </Button>
            </div>
          </div>
          {/* </div> */}
          <hr className="hrmargin" />
          <div>
            {stateCust?.invoiceType === "Payable" ? null : (
              <>
                <Typography
                  color="primary"
                  variant="body1"
                  style={{ fontWeight: "bolder", textAlign: "left" }}
                >
                  Income Sharing
                </Typography>
                <div className="invoice_adddetails quotation_table">
                  <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead style={{ backgroundColor: "cornflowerblue" }}>
                        <TableRow>
                          <TableCell align="center">SN</TableCell>
                          <TableCell>Agent Name</TableCell>
                          <TableCell>Sharing Type</TableCell>
                          {/* <TableCell>Amount</TableCell>
                          <TableCell align="right">Commission(%)</TableCell> */}
                          <TableCell style={{ padding: "0" }}>
                            Commission Amount
                          </TableCell>
                          <TableCell>Tax(%)</TableCell>
                          <TableCell>Tax Amount</TableCell>
                          <TableCell>Total Amount</TableCell>
                          <TableCell>Note</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody className="clientgeneralinvoicetables">
                        {incomesharing.map((p, index) => (
                          <TableRow key={index + 1}>
                            <TableCell
                              component="th"
                              scope="row"
                              align="center"
                            >
                              {index + 1}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <select
                                className={`form-control underline-input ${classes.selectdrop}`}
                                value={p.incomeSharingName}
                                style={{ backgroundColor: "#f5f5f9" }}
                                onChange={(e) =>
                                  handleincomesharingname(e, index)
                                }
                                required
                              >
                                <option value="">- Select Type -</option>
                                {/* <option
                                  value={p.incomeSharingName}
                                  id={p.incomeShareHolderId}
                                  commissionpercentage={p.agentIncomeSharingCommissionPercent}
                                  referalpercentage={p.agentIncomeSharingReferalPercent}
                                >{p.incomeSharingName}</option> */}
                                {agent?.map((cuntitem, index) => (
                                  <option
                                    key={index + 1}
                                    value={cuntitem.value}
                                    id={cuntitem.id}
                                    commissionpercentage={
                                      cuntitem.commissionPercentage
                                    }
                                    referalpercentage={
                                      cuntitem.referalPercentage
                                    }
                                    email={cuntitem.email}
                                  >
                                    {cuntitem.value}
                                  </option>
                                ))}
                              </select>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <select
                                style={{ backgroundColor: "#f5f5f9" }}
                                className={`form-control underline-input ${classes.selectdrop}`}
                                value={p.incomeSharingType}
                                onChange={(e) =>
                                  handleincomesharingtype(e, index)
                                }
                              >
                                <option value="IncomeSharing">
                                  Commission Percentage
                                </option>
                                <option value="Referal">Referal</option>
                              </select>
                            </TableCell>
                            {/* <TableCell align="right">
                              <input
                                placeholder="Amount"
                                value={Number(originalnetincomevalue).toFixed(
                                  2
                                )}
                                className="form-control underline-input inputalign"
                                type="number"
                                style={{ backgroundColor: "#f5f5f9" }}
                                onWheel={(event) => event.currentTarget.blur()}
                                readOnly
                              />
                            </TableCell>
                            <TableCell align="right">
                              <input
                                placeholder="Enter Commission%.."
                                value={p.incomeSharingAmount}
                                className="form-control underline-input inputalign"
                                type="number"
                                style={{ backgroundColor: "#f5f5f9" }}
                                onWheel={(event) => event.currentTarget.blur()}
                                onChange={(e) =>
                                  handleincomesharingamount(e, index)
                                }
                              />
                            </TableCell> */}
                            <TableCell align="right">
                              <input
                                style={{ backgroundColor: "#f5f5f9" }}
                                value={Number(p.incomesharingcommissionamount)}
                                className="form-control underline-input inputalign"
                                type="number"
                                onWheel={(event) => event.currentTarget.blur()}
                                onChange={(e) =>
                                  handleincomesharingComissionamount(e, index)
                                }
                              // readOnly
                              />
                            </TableCell>
                            <TableCell align="right">
                              <select
                                className={`form-control underline-input ${classes.selectdrop}`}
                                value={p.incomeSharingtaxpercent}
                                style={{ backgroundColor: "#f5f5f9" }}
                                onChange={(e) =>
                                  handleincomesharingtax(e, index)
                                }
                              >
                                <option value="0">0</option>
                                <option value="10">10</option>
                              </select>
                            </TableCell>
                            <TableCell align="right">
                              <input
                                style={{ backgroundColor: "#f5f5f9" }}
                                value={Number(p.incomeSharingtaxamount).toFixed(
                                  2
                                )}
                                className="form-control underline-input inputalign"
                                type="number"
                                onWheel={(event) => event.currentTarget.blur()}
                                readOnly
                              />
                            </TableCell>
                            <TableCell align="right">
                              <input
                                style={{ backgroundColor: "#f5f5f9" }}
                                value={Number(
                                  p.incomeSharingAmountOriginal
                                ).toFixed(2)}
                                className="form-control underline-input inputalign"
                                type="number"
                                readOnly
                              />
                            </TableCell>
                            <TableCell align="right">
                              <BootstrapTooltip title={p.note}>
                                <input
                                  style={{ backgroundColor: "#f5f5f9" }}
                                  value={p.note}
                                  onChange={(e) =>
                                    handleincomesharingnote(e, index)
                                  }
                                  className="form-control underline-input "
                                  type="text"
                                />
                              </BootstrapTooltip>
                            </TableCell>
                            <TableCell
                              align="right"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                var lines = [...incomesharing];
                                var lines2 = [...netincomedataentries];
                                if (lines[index].payableInvoiceId > 0) {
                                  //console.log(lines[index].payableInvoiceId);
                                  deleteinvoices.push(
                                    lines[index].payableInvoiceId
                                  );
                                }
                                lines.splice(index, 1);
                                lines2.splice(index, 1);
                                setincomeSharing(lines);
                                setnetincomedataentries(lines2);
                                setnetincomevalue(
                                  originalnetincomevalue -
                                  lines2?.reduce(
                                    (amount, item) => item + amount,
                                    0
                                  )
                                );
                              }}
                            >
                              <span
                                style={{
                                  background: "red",
                                  padding: "0 3px",
                                  fontSize: "19px",
                                  borderRadius: " 3px",
                                  color: "#fff",
                                }}
                              >
                                x
                              </span>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Button
                    // color="primary"
                    className="sent-button"
                    style={{
                      // cursor: "pointer",
                      marginTop: "20px",
                      // width: "fit-content",
                      // border: "1px solid",
                      // padding: "5px",
                      // borderRadius: "7px",
                      float: "left",
                    }}
                    onClick={() => {
                      setincomeSharing((prev) => [
                        ...prev,
                        {
                          id: 0,
                          incomeShareHolderId: 0,
                          incomeSharingType: "IncomeSharing",
                          incomeSharingAmount: 0,
                          incomesharingcommissionamount: 0,
                          incomesharingreferalpercentage: 0, // storing value for incomesharingamount not used in calculation
                          incomesharingcommissionpercentage: 0, // storing value for incomesharingamount not used in calculation
                          incomeSharingName: "",
                          incomeSharingtaxpercent: 10,
                          incomeSharingtaxamount: 0,
                          incomeSharingAmountOriginal: 0,
                          incomeSharingemail: "",
                          note: "",
                          payableInvoiceId: 0,
                        },
                      ]);
                    }}
                  >
                    Add Row
                  </Button>
                </div>
              </>
            )}

            <div
              className="invoice_footer"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                margin: "0 10px",
              }}
            >
              <div
                style={{
                  margin: "14px 10px",
                  width: "270px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  textAlign: "left",
                }}
              >
                <Typography
                  color="primary"
                  variant="body1"
                  style={{
                    marginTop: "6px",
                    marginBottom: "15px",
                    fontWeight: "bolder",
                    marginLeft: "10px",
                  }}
                >
                  Status
                </Typography>
                <FormControl
                  variant="filled"
                  style={{ width: "100%", marginTop: "-10px" }}
                  className={classes.formControl}
                >
                  <>
                    <Select
                      id="demo-simple-select3"
                      name="invoiceStatus"
                      value={statusoption}
                      required
                      onChange={(e) => {
                        handleStatus(e);
                        inputEvent(e);
                      }}
                      style={{ height: "40px" }}
                    >
                      <MenuItem value="Paid">Paid</MenuItem>
                      <MenuItem value="PartiallyPaid">PartiallyPaid</MenuItem>
                      <MenuItem value="Unpaid">Unpaid</MenuItem>
                      <MenuItem value="Refund">Refund</MenuItem>
                      <MenuItem value="Cancelled">Cancelled</MenuItem>
                      {/* <MenuItem value="Active">Active</MenuItem> */}
                    </Select>
                  </>
                </FormControl>
                <div className="d-flex justify-content-end">
                  <button
                    style={{ margin: "5px 10px", height: "40px" }}
                    type="submit"
                    className="btn listing_addbutton"
                  >
                    Save
                  </button>

                  {userEmail ? (
                    <Button
                      style={{ margin: "5px 0px", height: "40px" }}
                      onClick={handleInvoiceList}
                      className="btn listing_addbutton"
                    >
                      Invoice List
                    </Button>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <hr />
        </form>
        <Modal
          isOpen={statusmodal}
          onRequestClose={closestatusModal}
          style={customStyles}
        >
          <h3 style={{ color: "#000" }}>Change Status</h3>
          <hr />
          {Number(totalpaid) === 0 ? (
            <h5>
              Your Payment Received field is <strong>null</strong>. Please
              choose <strong>UNPAID</strong> status
            </h5>
          ) : (
            <>
              {Number(gettotaldue()) > 0 ? (
                <h5>
                  Your Due amount is not <strong>Null</strong>. Please choose{" "}
                  <strong>PARTIAL PAYMENT</strong> status
                </h5>
              ) : (
                <h5>
                  Your Due amount is <strong>null</strong>. Please choose{" "}
                  <strong>PAID</strong> status
                </h5>
              )}
            </>
          )}
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-outline-danger"
              onClick={closestatusModal}
            >
              Close
            </button>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default EditInvoice;
