import React, { useState, useEffect } from "react";
import { Navbar, Nav } from "react-bootstrap";
import AdminChangePassword from "../ModalComponent/AdminChangePassword";
import Select from "react-select";
import axios from "axios";
import base_url from "./../../api/bootapi";
import { CardText, Col, Row, Table } from "reactstrap";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Button from "@mui/material/Button";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
// import { Link } from '@mui/material';
import { Link } from "react-router-dom";
// import chroma from 'chroma-js';
import "./SearchBar.css";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { logout, setdashboardcheck, selectUser } from "../features/userSlice";
import { ToastContainer, toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 1000,
    color: "#fff",
  },
}));

const Header = ({ compress, handlecompress, handleerror, navigate }) => {
  const user = useSelector(selectUser);
  let canceltoken;
  const classes = useStyles();
  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  const [loadOptions, setLoadOptions] = useState([]);
  const [defaultLoadOptions, setDefaultLoadOptions] = useState();
  const [officeData, setOfficeData] = useState();
  const [backdropopen, setbackdropopen] = useState(false);
  const isw_user_email = localStorage.getItem("isw_user_email");
  const dispatch = useDispatch();

  useEffect(() => {
    getOfficeData();
    getClientData();
  }, []);

  const getOfficeData = () => {
    let ofc_obj = [];
    for (let i = 0; i < user?.isw_available_Office.length; i++) {
      ofc_obj.push(user.isw_available_Office[i].officeId);
    }
    setOfficeData(ofc_obj);
  };

  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            navigate("/");
            // toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };

  const colourStyles3 = {
    control: (styles) => ({ ...styles, backgroundColor: "white" }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);
      return {
        ...styles,
        // backgroundColor: isDisabled ? 'red' : 'blue',
        color: data.status == 1 ? "black" : "red",
        cursor: isDisabled ? "not-allowed" : "default",
      };
    },
  };

  const getClientData = () => {
    setbackdropopen(true);
    if (user?.isw_user_role === 1 || user?.isw_user_role === 6) {
      axios
        .get(`${base_url.api3}/findAllClients`, {
          headers: {
            Authorization: jwtToken,
          },
        })
        .then((res) => {
          let data = res.data.data;
          //console.log(data);
          const office_obj = [];
          for (let i = 0; i < data.length; i++) {
            let data2 = {
              value: data[i].id,
              label: `${data[i].first_name} ${data[i].last_name} (${data[i].email})`,
              email: data[i].email,
              status: data[i].status,
            };
            office_obj.push(data2);
          }
          setLoadOptions(office_obj);
          //console.log(office_obj);
          setbackdropopen(false);
          setDefaultLoadOptions(office_obj);
        })
        .catch((err) => {
          //console.log(err);
          setbackdropopen(false);
          handle403error(err);
        });
    } else {
      const office_objTwo = [];
      for (let i = 0; i < user?.isw_available_Office?.length; i++) {
        const element = user?.isw_available_Office?.[i];
        //console.log(element);
        office_objTwo.push(element);
      }
      let new_id = Array.prototype.map
        .call(office_objTwo, function (item) {
          return item.officeId;
        })
        .join(",");
      //console.log(new_id);

      const officeIds = office_objTwo
        ? Array.prototype.map
            .call(office_objTwo, function (item) {
              return item.officeId;
            })
            .join(",")
        : null;
      if (officeIds) {
        axios
          .get(`${base_url.api3}/getClientByOfficeId`, {
            headers: {
              Authorization: jwtToken,
              officeId: officeIds,
              columnName: "addedOn",
              direction: "DESC",
              page: 0,
              size: 20,
            },
          })
          .then((res) => {
            let data = res.data.data.content;
            //console.log(data);
            const office_obj = [];
            for (let i = 0; i < data.length; i++) {
              let data2 = {
                value: data[i].id,
                label: `${data[i].firstName} ${data[i].lastName} (${data[i].email})`,
                email: data[i].email,
                status: data[i].status,
              };
              office_obj.push(data2);
            }
            setbackdropopen(false);
            setLoadOptions(office_obj);
            //console.log("sangam Data------------------------------->", office_obj);
            setDefaultLoadOptions(office_obj);
          })
          .catch((err) => {
            setbackdropopen(false);
            //console.log(err);
            handle403error(err);
          });
      }
    }
    setbackdropopen(false);
  };

  const handleInputChange = (event) => {
    //console.log(loadOptions);
    //console.log(defaultLoadOptions);
    const searchWord = event.target.value;
    setWordEntered(searchWord);
    if (searchWord) {
      if (user?.isw_user_role === 1 || user?.isw_user_role === 6) {
        //console.log(newvalue);
        if (typeof canceltoken !== typeof undefined) {
          canceltoken.cancel("Cancelling previous request");
        }
        canceltoken = axios.CancelToken.source();
        axios
          .get(`${base_url.api3}/searchClientSelect`, {
            headers: {
              Authorization: jwtToken,
              searchQuery: searchWord,
            },
            cancelToken: canceltoken.token,
          })
          .then((res) => {
            let data = res.data.data;
            //console.log(data);
            const office_obj = [];
            for (let i = 0; i < data.length; i++) {
              //console.log(data[i]["firstName"] + data[i]["middleName"] + data[i]["lastName"] + '(' + data[i]["email"] + `)`);
              let sp = "";
              if (data[i]["middleName"] == "") {
                sp =
                  data[i]["firstName"] +
                  " " +
                  data[i]["lastName"] +
                  " " +
                  "(" +
                  data[i]["email"] +
                  `)`;
              } else {
                sp =
                  data[i]["firstName"] +
                  " " +
                  data[i]["middleName"] +
                  " " +
                  data[i]["lastName"] +
                  " " +
                  `(` +
                  data[i]["email"] +
                  `)`;
              }
              let data2 = {
                value: data[i].id,
                label: sp,
                //label: `${data[i].first_name} ${data[i].last_name} (${data[i].email})`,
                email: data[i].email,
                status: data[i].status,
              };
              office_obj.push(data2);
              if (data.length - 1 == i) {
                setLoadOptions(office_obj);
                setFilteredData(office_obj);
                //console.log("office_obj in if", office_obj);
              }
            }
          })
          .catch((err) => {
            //console.log(err);
            handle403error(err);
          });
      } else {
        //console.log("Coming here");
        //console.log(newvalue);
        if (typeof canceltoken !== typeof undefined) {
          canceltoken.cancel("Cancelling previous request");
        }
        canceltoken = axios.CancelToken.source();
        axios
          .get(`${base_url.api3}/searchClientByAllOfficeId`, {
            headers: {
              Authorization: jwtToken,
              searchQuery: searchWord,
              officeId: officeData,
              columnName: "addedOn",
              direction: "DESC",
              page: 0,
              size: 50,
            },
            cancelToken: canceltoken.token,
          })
          .then((res) => {
            let data = res.data.data.content;
            //console.log(data);
            const office_obj = [];
            for (let i = 0; i < data.length; i++) {
              //console.log(data[i]["firstName"] + data[i]["middleName"] + data[i]["lastName"] + '(' + data[i]["email"] + `)`);
              let dp = "";
              if (data[i]["middleName"] == "") {
                dp =
                  data[i]["firstName"] +
                  " " +
                  data[i]["lastName"] +
                  " " +
                  "(" +
                  data[i]["email"] +
                  `)`;
              } else {
                dp =
                  data[i]["firstName"] +
                  " " +
                  data[i]["middleName"] +
                  " " +
                  data[i]["lastName"] +
                  " " +
                  `(` +
                  data[i]["email"] +
                  `)`;
              }
              let data2 = {
                value: data[i].id,
                label: dp,
                //label: `${data[i].first_name} ${data[i].last_name} (${data[i].email})`,
                email: data[i].email,
                status: data[i].status,
              };
              //console.log("data2 in else", data2);
              office_obj.push(data2);
            }
            setLoadOptions(office_obj);
            setFilteredData(office_obj);
            //console.log("office_obj in else", office_obj);
          })
          .catch((err) => {
            //console.log(err);
            handle403error(err);
          });
      }
    } else {
      setLoadOptions();
    }
  };

  const onSelect = (selectedList, selectedItem) => {
    //console.log(loadOptions);
    //console.log(defaultLoadOptions);
    navigate(
      "/view-enquiry-workflowList/" +
        selectedList.value +
        "/" +
        selectedList.email
    );
    window.location.reload();
  };

  const [openaccountmenu, setopenaccountmenu] = useState(null);
  const isw_first_name = localStorage.getItem("isw_first_name");
  const isw_user_role_code = localStorage.getItem("isw_user_role_code");

  const handleClick = (event) => setopenaccountmenu(event.currentTarget);
  const handleaccountclose = () => setopenaccountmenu(null);

  //console.log(window.location);
  //console.log(window.location.origin);
  const handleProfile = () => {
    navigate("/profile");
  };
  const handleadmission = () => {
    navigate("/admission-enquiry");
  };

  const editUser = (elem) => {
    localStorage.setItem("adminPage", "/users");
    var admin_username = elem.target.id;
    navigate("/edit-users/" + isw_user_email);
  };

  const [filteredData, setFilteredData] = useState([]);

  const [wordEntered, setWordEntered] = useState("");

  const handleFilter = (event) => {
    const searchWord = event.target.value;
    setWordEntered(searchWord);
    const newFilter = loadOptions.filter((value) => {
      return value.label.toLowerCase().includes(searchWord.toLowerCase());
    });
    if (searchWord === "") {
      setFilteredData([]);
    } else {
      setFilteredData(newFilter);
    }
  };

  const clearInput = () => {
    setFilteredData([]);
    setWordEntered("");
  };

  const handleLinkClick = (val) => {
    // Perform any additional actions here before navigation
    // ...

    // Navigate to the new page
    navigate(`/view-enquiry-workflowList/${val.value}/${val.email}`);

    // Reload the current window
    window.location.reload();
  };

  return (
    <Navbar
      collapseOnSelect
      className={compress ? "nav_customstylecompress" : "nav_customstyle"}
    >
      <ToastContainer />
      <Backdrop className={classes.backdrop} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {compress ? (
        <ArrowForwardIosIcon
          onClick={handlecompress}
          className="header-menu-toggle-icon"
          style={{ cursor: "pointer" }}
        />
      ) : null}
      {/* <MenuIcon onClick={handlecompress} style={{ cursor: "pointer" }} /> */}
      {/* <Row style={{ width: "330px", height:"40px" }}>
        <Col style={{ width: "330px", height:"40px",padding:"0px",margin:"0px" }}> */}
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <div className="nav-header">
        <div className="nav-select">
          {/* <Select
            // cacheOptions
            name="clientName"
            placeholder="Student Name Here"
            // styles={customStyles2}
            styles={colourStyles3}
            options={loadOptions ? loadOptions : defaultLoadOptions}
            defaultOptions={defaultLoadOptions ? defaultLoadOptions : null}
            onInputChange={handleInputChange}
            onChange={onSelect}
            className="header_dropdown"
          /> */}
          <div className="search">
            <div className="searchInputs">
              <input
                type="text"
                value={wordEntered}
                placeholder="Student Name Here"
                // onChange={handleFilter}
                onChange={handleInputChange}
                className="header_dropdown"
              />
              <div className="searchIcon">
                {filteredData.length === 0 ? (
                  <SearchOutlinedIcon />
                ) : (
                  <CloseOutlinedIcon id="clearBtn" onClick={clearInput} />
                )}
              </div>
            </div>
            {filteredData.length != 0 && (
              <div className="dataResult">
                <>
                  {filteredData.map((val, key) => {
                    if (val.status == 1) {
                      return (
                        <Link
                          className="dataItem"
                          style={{ textDecoration: "none", color: "black" }}
                          to={
                            "/view-enquiry-workflowList/" +
                            val.value +
                            "/" +
                            val.email
                          }
                          onClick={() => handleLinkClick(val)}
                        >
                          <p style={{ color: "black" }}>{val.label}</p>
                          {/* {val.label} */}
                        </Link>
                      );
                    } else {
                      return (
                        <Link
                          className="dataItem"
                          style={{ textDecoration: "none", color: "red" }}
                          to={
                            "/view-enquiry-workflowList/" +
                            val.value +
                            "/" +
                            val.email
                          }
                          onClick={() => handleLinkClick(val)}
                        >
                          <p style={{ color: "red" }}>{val.label}</p>
                          {/* {val.label} */}
                        </Link>
                      );
                    }
                  })}
                </>
              </div>
            )}
          </div>
        </div>

        {/* </Col>
      </Row> */}

        {/* <Navbar.Collapse id="responsive-navbar-nav"> */}

        <div className="nav-username">
          {/* <i class="icon">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"  >
              <path
                d="M21.3 14.88L20.3 13.88C20.2055 13.7874 20.1303 13.6769 20.0788 13.5551C20.0274 13.4332 20.0006 13.3023 20 13.17V9C20 6.87827 19.1572 4.84344 17.6569 3.34315C16.1566 1.84286 14.1217 1 12 1C9.87827 1 7.84344 1.84286 6.34315 3.34315C4.84286 4.84344 4 6.87827 4 9V13.17C3.99945 13.3023 3.97265 13.4332 3.92116 13.5551C3.86966 13.6769 3.79449 13.7874 3.7 13.88L2.7 14.88C2.25333 15.3374 2.00227 15.9507 2 16.59C2 17.2292 2.25391 17.8422 2.70587 18.2941C3.15784 18.7461 3.77083 19 4.41 19H8C8 20.0609 8.42143 21.0783 9.17157 21.8284C9.92172 22.5786 10.9391 23 12 23C13.0609 23 14.0783 22.5786 14.8284 21.8284C15.5786 21.0783 16 20.0609 16 19H19.59C20.2292 19 20.8422 18.7461 21.2941 18.2941C21.7461 17.8422 22 17.2292 22 16.59C21.9977 15.9507 21.7467 15.3374 21.3 14.88ZM12 21C11.4696 21 10.9609 20.7893 10.5858 20.4142C10.2107 20.0392 10 19.5304 10 19H14C14 19.5304 13.7893 20.0392 13.4142 20.4142C13.0391 20.7893 12.5304 21 12 21Z"
                fill="#071A52" />
            </svg>

          </i> */}
          {/* <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <AccountCircleIcon />
            <div>
              <p class="labelHead">Hi,<strong>{isw_first_name}</strong></p>
            </div>
          </Button> */}
        </div>
      </div>

      {/* <Menu
        id="simple-menu"
        anchorEl={openaccountmenu}
        keepMounted
        open={Boolean(openaccountmenu)}
        onClose={handleaccountclose}
      >
        <MenuItem onClick={() => navigate("/profile")}>Profile</MenuItem>
        <AdminChangePassword handleaccountclose={handleaccountclose} />
        <MenuItem>Settings</MenuItem>
        <MenuItem onClick={handleerror}>Logout</MenuItem>
      </Menu> */}
      {/* </Navbar.Collapse> */}
      <div class="dropdown profile-drop">
        <button
          style={{ border: "hidden", backgroundColor: "rgb(245, 245, 249)" }}
          class="profile-drop-btn"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <div
            class="profile d-flex"
            style={{
              backgroundColor: "#f5f5f9",
              marginRight: "2rem",
              marginTop: "5px",
            }}
          >
            <AccountCircleIcon />
            <p class="labelHead" style={{ marginTop: "7px", fontSize: "14px" }}>
              Hi,<strong>{isw_first_name}</strong>
            </p>
          </div>
        </button>

        <ul class="dropdown-menu">
          <li>
            <a class="dropdown-item" onClick={handleProfile}>
              My Profile
            </a>
          </li>
          <AdminChangePassword handleaccountclose={handleaccountclose} />
          <li>
            <a class="dropdown-item" onClick={editUser}>
              Settings
            </a>
          </li>
          <li>
            <a class="dropdown-item" onClick={handleerror}>
              Logout
            </a>
          </li>
          {/* <li><a class="dropdown-item" onClick={handleadmission}>My admission</a></li> */}
        </ul>
      </div>
    </Navbar>
  );
};

export default Header;
