import React, { useState } from "react";
import Modal from "react-modal";
import { Col, Row, Button } from "reactstrap";
import axios from "axios";
import base_url from "../../../api/bootapi";
import { customStyles, useStyles } from "../../../Utilities/CSSUtilities";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Select from "react-select";
import callsaveauditapi from "../../../services/auditservice";
import { CountryDropdown } from "react-country-region-selector";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const EditInsuranceModal = ({
  toast,
  handle403error,
  pageno,
  jwtToken,
  isw_user_name,
  selectedinsurance,
  inputEvent,
  getallInsurance,
  editInvoice,
  id,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [editmodal, seteditmodal] = useState(false);
  const [modalIsOpen3, setIsOpen3] = React.useState(false);
  const [stateCust, setstateCust] = useState();

  const classes = useStyles();
  const closeeditModal = () => {
    seteditmodal(false);
  };

  const openeditmodal = () => {
    seteditmodal(true);
    editInvoice(id);
  };

  const submitedit = (e) => {
    selectedinsurance.name = selectedinsurance.name.trim();
    axios
      .post(`${base_url.api1}/updateInsuranceById`, selectedinsurance, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        if (response.data.status_code == 200) {
          let log =
            isw_user_name +
            " Updated Insurance from " +
            response.data.data.name +
            ".";
          callsaveauditapi(log, dispatch, navigate);
          toast.success(response.data.message);
          getallInsurance(pageno);
          seteditmodal(false);
        } else {
          toast.error(response.message);
        }
      })
      .catch((error) => {
        toast.error(JSON.stringify(error.response.data.message));
        handle403error(error);
        seteditmodal(false);
      });
  };

  return (
    <>
      <span style={{ cursor: "pointer" }} onClick={openeditmodal}>
        Edit
      </span>
      <Modal
        isOpen={editmodal}
        onRequestClose={closeeditModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <h3 style={{ color: "black" }}>Insurance</h3>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            submitedit(e);
          }}
        >
          <div>
            <div className="form-group creatadmin">
              <Row>
                <Col md={12}>
                  <label>
                    Name
                    <span style={{ color: "red" }}>*</span>:
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={selectedinsurance.name}
                    onChange={inputEvent}
                    className="form-control underline-input"
                    required
                  />
                </Col>
              </Row>
              <br />
              <Row>
                <Col md={12}>
                  <label>
                    Insurance %:
                    <span style={{ color: "red" }}>*</span>:
                  </label>
                  <input
                    type="number"
                    onWheel={(event) => event.currentTarget.blur()}
                    name="percentage"
                    value={selectedinsurance.percentage}
                    onChange={inputEvent}
                    className="form-control underline-input"
                    required
                  />
                </Col>
              </Row>
              <br />
              <Row>
                <Col md={12}>
                  <label>
                    Status
                    <span style={{ color: "red" }}></span>:
                  </label>
                  <select
                    defaultValue={
                      selectedinsurance.status == 1 ? "Active" : "InActive"
                    }
                    className="form-control"
                    name="status"
                    value={selectedinsurance.status}
                    onChange={inputEvent}
                    required
                  >
                    <option value="1">Active</option>
                    <option value="0">InActive</option>
                  </select>
                </Col>
              </Row>
              <Row style={{ marginTop: "20px" }}>
                <Col md={6}></Col>
                <Col md={6} style={{ textAlign: "right" }}>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button color="secondary" onClick={closeeditModal}>
                      Cancel
                    </Button>
                    <button
                      type="submit"
                      className="btn listing_addbutton ml-1"
                    >
                      Submit
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default EditInsuranceModal;
