import React from "react";
import "../css/Invoice.css";
import { withStyles, makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { green } from '@mui/material/colors';
import { Button, Input, Typography } from '@mui/material';
import "../css/Responsive.css";
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const ColorButton = withStyles((theme) => ({
  root: {
    // color: theme.palette.getContrastText(green[500]),
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
}))(Button);

const Invoice = () => {
  const classes = useStyles();
  return (
    <div className="invoice responsive">
      <div className="invoice_header">
        <Typography variant="h5" color="primary">
          Gross Claim Invoice
        </Typography>
        <div className="invoice_headerbutton">
          <ColorButton
            style={{ margin: "5px" }}
            color="primary"
            variant="contained"
          >
            Preview & Print
          </ColorButton>
          <ColorButton
            style={{ margin: "5px" }}
            color="primary"
            variant="contained"
          >
            Add Payment
          </ColorButton>
          <Button style={{ margin: "5px" }} variant="outlined">
            Edit
          </Button>
          <Button style={{ margin: "5px" }} variant="contained" color="primary">
            Send
          </Button>
        </div>
      </div>
      <div className="invoice_details">
        <div className="invoice_detailleft">
          <div className="invoice_detailleftfirst">
            <Typography variant="h6">Provider Details</Typography>
            <hr />
            <div className="invoice_detailleftprovider">
              <Typography className="first_of_type">Name:</Typography>
              <Typography className="second_of_type" variant="body2">
                Victoria University
              </Typography>
            </div>
            <div className="invoice_detailleftprovider">
              <Typography className="first_of_type">Address:</Typography>
              <Typography className="second_of_type" variant="body2">
                Behrampur
              </Typography>
            </div>
            <div className="invoice_detailleftprovider">
              <Typography className="first_of_type">Contact:</Typography>
              <Typography className="second_of_type" variant="body2">
                82653222
              </Typography>
            </div>
            <div className="invoice_detailleftprovider">
              <Typography className="first_of_type">Service:</Typography>
              <Typography className="second_of_type" variant="body2">
                Onshore Australian
              </Typography>
            </div>
          </div>
          <div className="invoice_detailleftsecond">
            <Typography variant="h6">Client Details</Typography>
            <hr />
            <div className="invoice_detailleftprovider">
              <Typography className="first_of_type">Name:</Typography>
              <Typography className="second_of_type" variant="body2">
                Prajal Twanabasu
              </Typography>
            </div>
            <div className="invoice_detailleftprovider">
              <Typography className="first_of_type">DOB:</Typography>
              <Typography className="second_of_type" variant="body2">
                22/8/1990
              </Typography>
            </div>
            <div className="invoice_detailleftprovider">
              <Typography className="first_of_type">Provider:</Typography>
              <Typography className="second_of_type" variant="body2">
                Victoria University
              </Typography>
            </div>
            <div className="invoice_detailleftprovider">
              <Typography className="first_of_type">Course:</Typography>
              <Typography className="second_of_type" variant="body2">
                Bachelor of Information Technology
              </Typography>
            </div>
            <div className="invoice_detailleftprovider">
              <Typography className="first_of_type">Branch:</Typography>
              <Typography className="second_of_type" variant="body2">
                Head Office
              </Typography>
            </div>
            <div className="invoice_detailleftprovider">
              <Typography className="first_of_type">Consultant:</Typography>
              <Typography className="second_of_type" variant="body2">
                Grishma Karmacharya
              </Typography>
            </div>
            <div className="invoice_detailleftprovider">
              <Typography className="first_of_type">Workflow:</Typography>
              <Typography className="second_of_type" variant="body2">
                Onshore Australian
              </Typography>
            </div>
          </div>
        </div>
        <div className="invoice_detailright">
          <div className="invoice_detailleftprovider">
            <Typography>Invoice:</Typography>
            <Typography variant="body2">#1663</Typography>
          </div>
          <div className="invoice_detailleftprovider">
            <Typography>Invoice Date:</Typography>
            <Typography variant="body2">2021-11-30</Typography>
          </div>
          <div className="invoice_detailleftprovider">
            <Typography>Invoice Due Date:</Typography>
            <Typography variant="body2">2021-11-30</Typography>
          </div>
          <div className="invoice_detailleftprovider">
            <Typography>Created By:</Typography>
            <Typography variant="body2">Satyan Singh</Typography>
          </div>
        </div>
      </div>
      <div className="invoice_details">
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead style={{ backgroundColor: "cornflowerblue" }}>
              <TableRow>
                <TableCell style={{ width: "25rem" }}>Description</TableCell>
                <TableCell align="right">Total Fee</TableCell>
                <TableCell align="right">Commission Percent</TableCell>
                <TableCell align="right">Commission Amount</TableCell>
                <TableCell align="right">Tax(%)</TableCell>
                <TableCell align="right">Tax Amount</TableCell>
                <TableCell align="right">Total Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow key="1">
                <TableCell component="th" scope="row">
                  BIT 6
                </TableCell>
                <TableCell align="right">9,720.00</TableCell>
                <TableCell align="right">14.26</TableCell>
                <TableCell align="right">1386.10</TableCell>
                <TableCell align="right">10% (GST)</TableCell>
                <TableCell align="right">138.51</TableCell>
                <TableCell align="right">1523.61</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className="invoice_detailsbelow">
        <div className="invoice_detailsbelowleft">
          <div className="invoice_detailsbelowleftup">
            <Typography
              style={{
                flex: "0.3",
                fontSize: "13px",
                fontWeight: "600",
              }}
            >
              Discount Given to Client:
            </Typography>
            <div className="invoice_detailinput" style={{ flex: "0.4" }}>
              <Input className="form-control underline-input" />
              <Button variant="outlined">AUD</Button>
            </div>
            <Input
              style={{ flex: "0.3" }}
              className="form-control underline-input"
            />
          </div>
          <div className="invoice_detailsbelowleftbelow">
            <Typography>Net Revenue</Typography>
            <Typography className="netrevenue" variant="h3" color="primary">
              484.78
            </Typography>
          </div>
        </div>
        <div className="invoice_detailsbelowright">
          <div className="invoice_belowrightdetail">
            <Typography
              variant="body2"
              className="invoice_belowrightdetailfirst"
            >
              Commission Claimed:
            </Typography>
            <div className="invoice_detailinput">
              <Input
                value="1679"
                className="form-control underline-input"
                readOnly
              />
              <Button variant="outlined">AUD</Button>
            </div>
          </div>
          <div className="invoice_belowrightdetail">
            <Typography
              variant="body2"
              className="invoice_belowrightdetailfirst"
            >
              Tax:
            </Typography>
            <div className="invoice_detailinput">
              <Input
                value="167"
                className="form-control underline-input"
                readOnly
              />
              <Button variant="outlined">AUD</Button>
            </div>
          </div>
          <div className="invoice_belowrightdetail">
            <Typography
              variant="body2"
              className="invoice_belowrightdetailfirst"
            >
              Total Amount (incl Tax):
            </Typography>
            <div className="invoice_detailinput">
              <Input
                value="1679"
                className="form-control underline-input"
                readOnly
              />
              <Button variant="outlined">AUD</Button>
            </div>
          </div>
          <div className="invoice_belowrightdetail">
            <Typography
              variant="body2"
              className="invoice_belowrightdetailfirst"
            >
              Total Paid:
            </Typography>
            <div className="invoice_detailinput">
              <Input
                value="1679"
                className="form-control underline-input"
                readOnly
              />
              <Button variant="outlined">AUD</Button>
            </div>
          </div>
          <div className="invoice_belowrightdetail">
            <Typography
              variant="body2"
              className="invoice_belowrightdetailfirst"
            >
              Total Due:
            </Typography>
            <div className="invoice_detailinput">
              <Input
                value="0"
                className="form-control underline-input"
                readOnly
              />
              <Button variant="outlined">AUD</Button>
            </div>
          </div>
          <div className="invoice_belowrightdetail">
            <Typography
              className="invoice_belowrightdetailfirst"
              variant="body2"
            >
              Payment Option:
            </Typography>
            <Typography style={{ flex: "0.5", textAlign: "right" }}>
              Default Bank Detail
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invoice;
